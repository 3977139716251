import { Component, OnInit, TemplateRef } from '@angular/core';
import { MasterServices, AlertService, TransactionServices, CompanyService, DealersService } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { Location, DatePipe } from '@angular/common';
import { productmodel, ProspectModel, contactmodel } from './ProspectModel';
import { SearchAccountWiseMachineDetailModel } from '../../Master/AccountWiseMachineDetailMaster/SearchAccountWiseMachineDetailModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TeamModel } from '../Teams/TeamModel';
import * as moment from 'moment';
import { SearchSourceModel } from 'src/app/Master/SourceMaster/SearchSourceModel';

declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: 'Prospect.html'
})
export class Prospect implements OnInit {
  model = new ProspectModel();
  model1 :any;
  sendmodel = new ProspectModel();
  promodel = new productmodel();
  contactmo = new contactmodel();
  createleadchecked: boolean = false;
  newcallchecked: boolean = false;
  dropmodel = new DropDownModel(null, null);
  SearchAccomodel = new SearchAccountWiseMachineDetailModel();
  flagmodule = new CoustomerflagModel();
  sub: any;
  user = new User();
  serviceNO: string;
  serviceID: number;
  teammodel = new TeamModel();
  pagename: string;
  userList2: any;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  serialnoFlag: boolean;
  productcatelst: DropDownModel[] = [];
  productList2: any;
  productmap = new Map();
  localitymap = new Map();
  lstlocality: DropDownModel[] = [];
  lstlocalitys: DropDownModel[] = [];
  lstlocalityforproduct: DropDownModel[] = [];
  lstindustry: DropDownModel[] = [];
  lstcustomertype: DropDownModel[] = [];
  lstconstutution: DropDownModel[] = [];
  lstsourcedetails: DropDownModel[] = [];
  keyword = 'name';
  keyword2 = 'name';
  keyword3 = 'name';
  keyword4 = 'name';
  keyword5 = 'name';
  keyword6 = 'name';
  keyword1 = 'productName';
  pincodelist = [];
  pincodelist1 = [];
  pincodelist2 = [];
  pincodelist3 = [];
  supervisiorAuth1 = [];
  prodcat: any;
  saveButton: boolean
  call: String;
  machiestatuslst: DropDownModel[] = [];
  // public machiestatuslst: DropDownModel[] = [
  //   { id: 0, "name": "Select Status" },
  //   { id: 2, "name": "Under Installation" },
  //   { id: 3, "name": "Within warranty" },
  //   { id: 4, "name": "Out of Warranty and AMC" },
  //   { id: 5, "name": "Within AMC" },
  // ];
  mcstatuscodelst: DropDownModel[] = [];

  // machiestatuslstforcall: DropDownModel[] = [];

  lstpriority: DropDownModel[] = [];
  Leads: string;

  lstsource: DropDownModel[] = [];
  lstsource1: SearchSourceModel[] = [];
  lstassigned: DropDownModel[] = [];
  lstleadassigned: DropDownModel[] = [];
  lstassigneddealer:DropDownModel[] = [];
  lstleadassigneddealer:DropDownModel[] = [];
  designationlst: DropDownModel[] = [];
  annualrevenuelst: DropDownModel[] = [];
  noofemplst: DropDownModel[] = [];
  serviceCallStatusName: string;

  productcomp: boolean = false;
  productmodel: boolean = false;

  lstaddress: DropDownModel[] = [];
  currentdate: Date = new Date();
  timer: NodeJS.Timeout;
  maxtime: any = 30
  forflag: string;

  productID: String = null;
  serviceCallID: String = null;
  leadID: String = null;
  machineid: String = null;

  description = new Map();
  lstindex: number;
  productcategoryid: number;
  productname: string;
  productid: number;
  addr: string;
  pincode: string;
  localityID: number;
  areaID: number;
  locality: string;
  city: string;
  state: string;
  country: string;
  locale: string;
  areaname: string;
  regionname: string;
  googlemap: string;

  warrentyEndDate: Date;
  srlno: string;
  amcdEndDate: Date;
  edit: boolean = false;
  amcddateflag: boolean = false;
  warrentydateflag: boolean = false;
  searchstring: string;
  lstcustomerexits: ProspectModel[] = [];
  addedflag: boolean = false;

  constructor(private Masterservice: MasterServices,
    private modalService: BsModalService,
    private alertService: AlertService,
    private userService: MasterServices,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private CompanyService: CompanyService,
    private datePipe: DatePipe,
    private dealersService: DealersService,
    private transactionService: TransactionServices,
    private notifyService: NotificationService) { }

  public machiestatuslstforcall: DropDownModel[] = [
    { id: 0, "name": "Select Working status" },
    { id: 1, "name": "Not Working" },
    { id: 2, "name": "Partially Working" },

  ];

  ngOnInit() {
    this.pagename = "New Prospect";
    this.lstsourcedetails.push({ id: 0, name: "Select Source Name" });
    this.lstsource.push({ id: 0, name: "Select Source" });
    this.lstassigned.push({ id: 0, name: "Select Assigned To" });
    this.lstleadassigned.push({ id: 0, name: "Select Assigned To" });
    this.lstlocalitys.push({ id: 0, name: "Select Locality" });

    this.lstpriority.push({ id: 0, name: "Select Priority" });
    this.lstpriority.push({ id: 1, name: "High" });
    this.lstpriority.push({ id: 2, name: "Medium" });
    this.lstpriority.push({ id: 3, name: "Low" });

    this.model.userid = Number(localStorage.getItem("userid"));
    // this.model.lstcontact.push({
    //   id: null,
    //   contactname: "",
    //   contactdesignation: "",
    //   contactdesignationID: null,
    //   contactmobile: "",
    //   contactemail: "",
    //   added: false,
    //   checkselect: false,
    // });


    this.route.queryParams.subscribe(params => {
      this.forflag = atob(params["flag"]);
      this.model.accountid = + atob(params["id"]);
      this.searchstring = params["searchstring"];
      this.serviceCallID = params["serviceCallnumber"];
      this.productID = params['productid'];
      this.machineid = params['machineid'];
      this.leadID = params["leadid"];
    });

    if (this.machineid != null) {
      this.machineid = atob(this.machineid.toString())
      this.model.machineid = Number(this.machineid);
    }
    if (this.productID != null) {
      this.productID = atob(this.productID.toString())
      this.model.productID = Number(this.productID);
    }
    if (this.searchstring != null) {
      localStorage.setItem("searchstring", this.searchstring);
    }
    if (this.forflag == "createlead") {
      this.pagename = "Create Lead";
      this.edit = false;
    }
    if (this.forflag == "edit") {
      this.pagename = "Account";
      this.edit = true;
    }
    if (this.forflag == "addprospect") {
      this.pagename = "Add Account";
      this.edit = true;
    }
    if (this.forflag == "newprospect") {
      this.pagename = "New Prospect"
      this.edit = true;
    }
    if (this.forflag == "bookservicecall") {
      this.pagename = "Book Service Call";
      this.model.serviceCallID = Number(this.serviceCallID);
      this.edit = false;
    }
    if (this.forflag == "reassinglead") {
      this.pagename = "Create Lead";
      this.edit = false;
    }
    if (this.model.accountid != null) {
      this.getdetais();
    }

    this.model.lstproductintersted.push({
      accountid: null,
      id: null,
      productid: null,
      name: null,
      productCategoryID: null,
      categoryname: null,
      description: null,
      purdate: new Date(),
      location: null,
      added: false,
      categoriesdescription: null,
      address: null,
      pincode: null,
      localityID: null,
      areaID: null,
      locality: null,
      city: null,
      state: null,
      country: null,
      locale: null,
      areaname: null,
      regionname: null,
      checkselect: false,
      amcWarrantyEndDate: null,
      amcEndDate: null,
      warrentyEndDate: null,
      serviceCallStatusName: null,
      installationDate: new Date(),
      googlemap: null,
      srlno: null,
      serialnoFlag: false,
      status: null,
      serviceCallStatusID: null,
    })

    this.getcustomertype();
    this.getIndustrytype();
    this.getDesignation();
    this.getConstitution();
    this.getsource();
    this.getanualrevanue();
    this.getflag();
    this.getmachinestatus();
    this.getProductcat();

    // this.getservicecallmachinestatus()
    // if (this.model.lstcontact != null)
    // {
    //   alert(this.model.lstcontact.length)
    //   this.model.lstcontact[this.lstindex].checkselect = true;
    // }

  }

  getdetais() {
    this.transactionService.getCustomerDetails(this.model)
      .subscribe(data => {
        this.model = data;
        for (let i = 0; i < this.model.lstproduct.length; i++) {
          if (this.model.lstproduct[i].serviceCallStatusName == "O") {
            this.model.lstproduct[i].serviceCallStatusName = "Out of Warrenty"
          } else if (this.model.lstproduct[i].serviceCallStatusName == "W") {
            this.model.lstproduct[i].serviceCallStatusName = "In Warrenty"
          }
          this.model.serviceCallStatusID = this.model.lstproduct[i].serviceCallStatusID;
        }
        this.model.localeID = data.localeID
        // alert(this.model.localeID);
        this.lstlocality = [];
        this.lstlocality.push({ id: 0, name: "Select Locality" });
        this.transactionService.getlocality(this.model)
          .subscribe(data => {
            data.forEach(item => {
              this.lstlocality.push({ id: item.id, name: item.name });
            });
          });
        if (this.forflag == "createlead") {
          this.model.createlead = true;
        }
        if (this.forflag == "bookservicecall") {
          this.model.newcall = true;
        }
        if (this.forflag == "reassinglead") {
          this.model.createlead = true;
        }
        // this.getAssignedto(this.model.areaID);
        if (data.callcommitdate != null) {
          this.model.callcommitdate = new Date(data.callcommitdate)
        } else {
          //  this.model.callcommitdate = new Date()
          this.model.callcommitdate = moment(new Date(), "DD/MM/YYYY").add(this.model.customervalue, 'days').toDate()
        }

        if (this.model.lstcontact.length == 1) {
          this.model.lstcontact.forEach(element => {
            element.checkselect = true;
            if (element.id == this.model.contactID) {
              element.checkselect = true;
            }
          });
        }


        if (this.model.customertypeID != null) {
          this.user.trnid = this.model.customertypeID;
          this.Masterservice.getAlldataBytypeid(this.user)
            .subscribe(value => {
              this.model.numberofempflag = value.chknoofemployee;
              this.model.numberofempMandatory = value.mandchkemp;
              this.model.annualturnoverflag = value.chkanualtrnovr;
              this.model.annualturnoverMandatory = value.mandchktrnovr;
              this.model.industrytypebyCtypeflag = value.chkindstype;
              this.model.industrytypeMandatory = value.mandindtype;
              this.model.industrytypename = value.industrytypename;
              this.model.industrytypeID = value.industryid;
              this.model.designationMandatory = value.mandDesignation;
              this.model.designationid = value.designationid;
              this.model.designationname = value.designationname;
              this.model.chksuperVisiorAuth = value.chksupervisingAuth;
              this.model.mandSupervisiorAuth = value.manSupervising;
              this.model.chkdesignation = value.chkdesignation;

              if (this.model.designationname != null) {
                this.model.lstcontact[0].contactdesignation = this.model.designationname;
              } else {
                this.model.lstcontact[0].contactdesignation = null;
              }

            });
        }


        this.model.lstproductintersted.push({
          accountid: null,
          id: null,
          productid: null,
          name: null,
          productCategoryID: null,
          categoryname: null,
          description: null,
          purdate: new Date(),
          location: null,
          added: false,
          categoriesdescription: null,
          address: null,
          pincode: null,
          localityID: null,
          areaID: null,
          locality: null,
          city: null,
          state: null,
          country: null,
          locale: null,
          areaname: null,
          regionname: null,
          serviceCallStatusName: null,
          installationDate: new Date(),
          checkselect: false,
          amcWarrantyEndDate: null,
          amcEndDate: null,
          warrentyEndDate: null,
          googlemap: null,
          srlno: null,
          serialnoFlag: false,
          status: null,
          serviceCallStatusID: null,
        })
        if (this.productID != null) {
          data.lstproduct.forEach(element => {
            if (element.productid == Number(this.productID)) {
              element.checkselect = true;
              this.model.productID = element.productid
              this.model.acwiseid = element.id
            }
          });
        }
        if (this.createleadchecked) {
          this.model.createlead = true;
          this.edit = false;
          this.forflag = "createlead"
          this.pagename = "Create Lead";
        }
        else if (this.newcallchecked) {
          this.model.newcall = true;
          this.edit = false;
          this.forflag = "bookservicecall";
          this.pagename = "Book Service Call"
        }

        this.getAssignedto(this.model.areaID);

        if (this.leadID != null) {
          this.user.trnid = Number(this.leadID)
          this.transactionService.getDataByOpportunityId(this.user)
            .subscribe(data => {
              this.lstleadassigned = data.memberlst;
              this.model.lstproductintersted = data.lstproductintersted;
              this.model.sourceID = data.sourceID;
              this.model.sourcedetailID = data.sourcedetailID;


              this.model.remark = data.remarks;
              this.lstsourcedetails = [];
              let user = new User();
              user.trnid = this.model.sourceID;
              this.lstsourcedetails.push({ id: 0, name: "Select Source Name" });
              this.Masterservice.getsourceDetailBysourceid(user)
                .subscribe(data => {
                  data.forEach(item => {
                    this.lstsourcedetails.push({ id: item.id, name: item.name });
                  });
                });
              this.model.lstcontact.forEach(element => {
                if (element.id == data.contactID) {
                  element.checkselect = true;
                }
              });
            });
        }
        // this.model.lstproductintersted.push({
        //   accountid: null,
        //   id: null,
        //   productid: null,
        //   name: null,
        //   productCategoryID: null,
        //   categoryname: null,
        //   description: null,
        //   purdate: new Date(),
        //   location: null,
        //   added: false,
        //   categoriesdescription: null,
        //   address: null,
        //   pincode: null,
        //   localityID: null,
        //   areaID: null,
        //   locality: null,
        //   city: null,
        //   state: null,
        //   country: null,
        //   locale: null,
        //   areaname: null,
        //   regionname: null,
        //   serviceCallStatusName: null,
        //   installationDate: new Date(),
        //   checkselect: false,
        //   amcWarrantyEndDate: null,
        //   amcEndDate: null,
        //   warrentyEndDate: null,
        //   googlemap: null,
        //   srlno: null,
        //   serialnoFlag: false,
        //   status: null,
        // });
        let l1 = (<HTMLInputElement>document.getElementById('lable1'));
        l1.className = "active";
        let l2 = (<HTMLInputElement>document.getElementById('lable2'));
        l2.className = "active";
        let l3 = (<HTMLInputElement>document.getElementById('lable3'));
        l3.className = "active";
        let l4 = (<HTMLInputElement>document.getElementById('lable4'));
        l4.className = "active";
        let l5 = (<HTMLInputElement>document.getElementById('lable5'));
        l5.className = "active";
        let l6 = (<HTMLInputElement>document.getElementById('lable6'));
        l6.className = "active";
        let l7 = (<HTMLInputElement>document.getElementById('lable7'));
        l7.className = "active";
        let l8 = (<HTMLInputElement>document.getElementById('lable8'));
        l8.className = "active";
        
        let l9 = (<HTMLInputElement>document.getElementById('unit'));
        l9.className = "active";
        let l10 = (<HTMLInputElement>document.getElementById('buildin'));
        l10.className = "active";
        let l11 = (<HTMLInputElement>document.getElementById('address'));
        l11.className = "active";
        
        // else{
        //   this.router.navigate(["Call Center Dashboard"]);
        // }

      });
    // this.lstsource1.forEach(item =>{
    //   this.model.checkselect = item.chkselect
    // });

  }
  getmachinestatus() {

    this.machiestatuslst.push({ id: 0, "name": "Select Machine Status" })
    this.Masterservice.getmcstatus(this.user)
      .subscribe((value) => {

        value.mcstatuslst.forEach(element => {
          if (element.name != "Machine Under Installation") {
            this.machiestatuslst.push({
              id: element.id,
              name: element.name
            })
            // this.machiestatuslstforcall.push({
            //   id: element.id,
            //   name: element.name
            // })
          }
          // value.servicecallmcstatuslst.forEach(element => {})
        });
        this.mcstatuscodelst = value.mcstatuscodelst;
      });


  }

  // getservicecallmachinestatus() {
  //   this.machiestatuslstforcall = [];
  //   this.machiestatuslstforcall.push({ id: 0, "name": "Select Working Status" })

  //   this.Masterservice.getservicecallstatus(this.user)
  //     .subscribe((value) => {
  //       value.forEach(element => {
  //         this.machiestatuslstforcall.push({
  //           id: element.id,
  //           name: element.name
  //         })
  //       });
  //     });
  // }
  onSelectmachinestatusforcall(value) {
    this.model.mcstatus = value;
    this.Masterservice.getservicecallstatus(this.user)
      .subscribe((value) => {
        value.forEach(element => {
          this.machiestatuslstforcall.push({
            id: element.id,
            name: element.name
          })
        });
      });
  }

  getflag() {
    this.flagmodule.flagname = "Product Compulsory";
    this.CompanyService.getmoduleflag(this.flagmodule).subscribe((value) => {
      if (value.flagresult == "Y") {
        this.productcomp = true;
      }
    });
    this.flagmodule.flagname = "Product Model";
    this.CompanyService.getmoduleflag(this.flagmodule).subscribe((value) => {
      if (value.flagresult == "Y") {
        this.productmodel = true;
      }
    });
  }

  getanualrevanue() {
    this.annualrevenuelst = [];
    this.noofemplst = [];
    this.noofemplst.push({ "id": 0, "name": "Select No. Of Employees" });
    this.annualrevenuelst.push({ "id": 0, "name": "Select Annual Revenue" });
    this.Masterservice.getindustorylst(this.user)
      .subscribe((value) => {
        value.annualrevenuelst.forEach(element => {
          this.annualrevenuelst.push({
            id: element.id,
            name: element.name
          })
        });
        value.noofemplst.forEach(element => {
          this.noofemplst.push({
            id: element.id,
            name: element.name
          })
        });
      });
  }

  getsource() {
    this.forflag == 'addprospect'
    this.lstsource = [];
    this.lstsource1 = [];
    let user = new User();
    this.lstsource.push({ id: 0, name: "Select Source" });
    this.Masterservice.getAllSource(user)
      .subscribe(data => {
        data.forEach(item => {
          this.lstsource.push({ id: item.id, name: item.name });
          this.lstsource1 = data;
        });
      });

  }
  onSelectannualrevenue(id) {
    this.model.annualturnover = id;
  }
  onSelectnoofemple(id) {
    this.model.numberofemp = id;
  }


  getcustomertype() {
    this.lstcustomertype = [];
    let user = new User();
    this.lstcustomertype.push({ id: 0, name: "Select Customer Type" });
    user.sendstring = "prospect";
    this.Masterservice.getAllCustomertypes(user)
      .subscribe(data => {
        data.forEach(item => {
          this.lstcustomertype.push({ id: item.id, name: item.name });
        });
      });
  }
  onSelectsource(id) {
    this.lstsourcedetails = [];
    let user = new User();
    user.trnid = id;
    this.model.sourceID = id;
    this.lstsourcedetails.push({ id: 0, name: "Select Source Name" });
    this.Masterservice.getsourceDetailBysourceid(user)
      .subscribe(data => {
        data.forEach(item => {
          this.lstsourcedetails.push({ id: item.id, name: item.name });
        });
      });
  }
  getPincodeLike($event) {
    let userId = (<HTMLInputElement>document.getElementById('pincode1')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getpincodelike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
    if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
      this.lstlocality = [];
    }
  }

  getPincodeLikeforproduct($event) {
    let userId = (<HTMLInputElement>document.getElementById('pincode2')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getpincodelike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadpincodeforproduct(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadpincodeforproduct(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
    if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
      this.lstlocality = [];
    }
  }


  getPincodeLikes($event) {
    let userId = (<HTMLInputElement>document.getElementById('resAllos')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getpincodelike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectPincode(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectPincode(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
    if (this.model.pincode1.length < 6 || this.model.pincode1.length > 6) {
      this.lstlocalitys = [];
    }
  }


  onSelectcategory(id) {
    this.promodel.productCategoryID = id;
  }
  getProductLike() {

    if (this.promodel.productCategoryID == null) {
      this.notifyService.showRroor("Please Select Product Category", "Opps");
      return;
    }

    let userId = (<HTMLInputElement>document.getElementById('productname')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    this.user.productcategoryId = this.promodel.productCategoryID;
    var obj = this;
    if (userId.length >= 3) {
      this.dealersService.getProductLike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "productcatname": item.productcatname, "productName": item.productName, "productid": item.productid, "currencyName": item.currencyName, "mrp": item.mrp, "description": item.description, "serialnoFlag": item.serialnoFlag }));
          let map = new Map();

          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].productName, this.userList2[i].productid)
            this.description.set(this.userList2[i].productName, this.userList2[i].description)
            searchDataObj[this.userList2[i].productName] = null;
            if (i == 0) {
              names = this.userList2[i].productName;
            } else {
              names = names + "," + this.userList2[i].productName;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect1(map.get(txt), txt);

              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect1(map.get(txt), txt);

              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }


  }

  typeaheadOnSelect1(productid, name) {
    this.promodel.productid = productid;
    this.promodel.name = name;
    this.userList2.forEach(element => {
      if (element.productid == productid && element.serialnoFlag) {
        this.serialnoFlag = true;
      }
    });

  }

  getinteredproductmodel($event, ind) {
    if (this.model.lstproductintersted[ind].productCategoryID == null) {
      this.notifyService.showRroor("Please Select Product Category", "Opps");
      return;
    }


    let userId = (<HTMLInputElement>document.getElementById('product')).value;
    this.productList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    this.user.productcategoryId = this.model.lstproductintersted[ind].productCategoryID;
    // alert(this.user.productcategoryId);
    var obj = this;
    if (userId.length >= 3) {
      this.dealersService.getProductLike(this.user)
        .subscribe(data => {
          // alert('hi');
          data.forEach((item) => this.productList2.push({ "name": item.productName, "id": item.productid, "description": item.description }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.productList2.length; i++) {
            map.set(this.productList2[i].name, this.productList2[i].id);
            this.productmap.set(this.productList2[i].name, this.productList2[i].description)
            searchDataObj[this.productList2[i].name] = null;
            if (i == 0) {
              names = this.productList2[i].name;
            } else {
              names = names + "," + this.productList2[i].name;
            }
          }

          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Product");
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Product");
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
  }

  getintrestedproductcatego(ind) {
    //let userId = (<HTMLInputElement>document.getElementById('productcategos')).value;
    this.productList2 = [];
    let searchDataObj = {};
   // this.user.message = userId;
    var obj = this;
     
      this.transactionService.getproductCategorieslike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id, "description": item.description }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.productList2.length; i++) {
            map.set(this.productList2[i].name, this.productList2[i].id);
            this.productmap.set(this.productList2[i].name, this.productList2[i].description)
            searchDataObj[this.productList2[i].name] = null;
            if (i == 0) {
              names = this.productList2[i].name;
            } else {
              names = names + "," + this.productList2[i].name;
            }
          }

          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Categories");
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Categories");
              },
            });
          });
        },
          () => {
            alert("fail");
          });
     
  }
  getproductcategories($event, ind) {
    let userId = (<HTMLInputElement>document.getElementById('product')).value;
    this.productList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getproductCategorieslike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id, "description": item.description }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.productList2.length; i++) {
            map.set(this.productList2[i].name, this.productList2[i].id);
            this.productmap.set(this.productList2[i].name, this.productList2[i].description)
            searchDataObj[this.productList2[i].name] = null;
            if (i == 0) {
              names = this.productList2[i].name;
            } else {
              names = names + "," + this.productList2[i].name;
            }
          }

          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectProduct(map.get(txt), txt, ind, "Categories");
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectProduct(map.get(txt), txt, ind, "Categories");
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
  }

  typeaheadOnSelectInterstedProduct(id, name, ind, value) {
    if (value == "Product") {
      this.model.lstproductintersted[ind].productid = id;
      this.model.lstproductintersted[ind].name = name;
      this.model.lstproductintersted[ind].description = this.productmap.get(name);
    }
    else {
      this.model.lstproductintersted[ind].productCategoryID = id;
      this.model.lstproductintersted[ind].categoryname = name;
      this.model.lstproductintersted[ind].categoriesdescription = this.productmap.get(name);
    }

    if (this.model.areaID1 != null) {
      this.getAssignedto(this.model.areaID1);
    } else {
      this.getAssignedto(this.model.areaID);
    }


  }

  typeaheadOnSelectProduct(id, name, index, value) {
    if (value == "Product") {
      this.model.lstproduct[index].productid = id;
      this.model.lstproduct[index].description = this.productmap.get(name);
    }
    else {
      this.model.lstproduct[index].productCategoryID = id;
      this.model.lstproduct[index].categoriesdescription = this.productmap.get(name);
    }
    if (this.model.lstproduct[index].location == null) {
      this.model.lstproduct[index].location = this.model.city
    }
    if (this.model.lstproduct[index].location == null) {
      this.model.lstproduct[index].location = this.model.locality
    } else {
      this.model.lstproduct[index].location = this.model.lstproduct[index].location + ", " + this.model.locality

    }
    if (this.model.lstproduct[index].location != null) {
      this.model.lstproduct[index].location = this.model.lstproduct[index].location + ", " + this.model.pincode
    } else {
      this.model.lstproduct[index].location = this.model.lstproduct[index].location + ", " + this.model.pincode

    }

  }
  typeaheadOnSelect(id, name) {
    // this.model.id = id;
    this.model.pincode = name;
    this.lstlocality = [];
    this.lstlocality.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.model)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocality.push({ id: item.id, name: item.name });
        });
      });


  }

  typeaheadpincodeforproduct(id, name) {
    this.sendmodel.pincode = name;
    this.lstlocalityforproduct = [];
    this.lstlocalityforproduct.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.sendmodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalityforproduct.push({ id: item.id, name: item.name });
        });
      });

  }
  typeaheadOnSelectPincode(id, name) {
    // this.model.id = id;

    this.model.pincode1 = name;
    // alert(this.model.pincode1);
    this.lstlocalitys = [];
    this.lstlocalitys.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocalityforchangeaddress(this.model)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalitys.push({ id: item.id, name: item.name });
        });
      });


  }


  getIndustrytype() {
    this.lstindustry = [];
    let user = new User();
    this.lstindustry.push({ id: 0, name: "Select Industry" });
    this.Masterservice.getindustorylst(user)
      .subscribe(data => {
        for (let i = 0; i < data.industorylst.length; i++) {
          this.lstindustry.push({
            id: data.industorylst[i].id,
            name: data.industorylst[i].name,
          })
        }
      });
  }

  getConstitution() {
    this.lstconstutution = [];
    let user = new User();
    this.lstconstutution.push({ id: 0, name: "Select Constitution" });
    this.Masterservice.getindustorylst(user)
      .subscribe(data => {
        for (let i = 0; i < data.consistutelst.length; i++) {
          this.lstconstutution.push({
            id: data.consistutelst[i].id,
            name: data.consistutelst[i].name,
          })
        }
      });
  }

  onselectCall($event) {
    if (this.newcallchecked) {
      this.createleadchecked = false;
    }
  }
  onselectlead($event) {
    if (this.createleadchecked) {
      this.newcallchecked = false;
    }
  }
  getDesignation() {
    let user = new User();
    this.designationlst = [];
    this.Masterservice.getDept(user)
      .subscribe((value) => {
        this.designationlst = value.desiglst;
      });
  }
  getlocatity() {
    if (this.model.pincode.length == 6) {
      this.lstlocality = [];
      this.lstlocality.push({ id: 0, name: "Select Locality" });
      this.transactionService.getlocality(this.model)
        .subscribe(data => {
          data.forEach(item => {
            this.lstlocality.push({ id: item.id, name: item.name });
            this.localitymap.set(item.id, item.name)
          });
        });
    }
  }

  onSelectproductlocality(id) {
    this.sendmodel.localityID = id;
    this.localityID = id;
    this.locality = this.localitymap.get(id);
    this.sendmodel.locality = this.localitymap.get(id);
    this.transactionService.getgeographydetails(this.sendmodel)
      .subscribe(data => {
        this.city = data.city;
        this.state = data.state;
        this.country = data.country;
        this.locale = data.locale;
        this.areaname = data.areaname;
        this.areaID = data.areaID1;
        this.regionname = data.regionname;

      });
  }
  onSelectlocality1(id) {
    // alert(id);
    this.model.localityID1 = id;
    this.model.locality1 = this.localitymap.get(id);
    this.transactionService.getgeographydetailsforchangeaddress(this.model)
      .subscribe(data => {
        this.model.city1 = data.city1;
        this.model.state1 = data.state1;
        this.model.country1 = data.country1;
        this.model.locale1 = data.locale1;
        this.model.areaname1 = data.areaname1;
        this.model.localeID1 = data.localeID1;
        this.model.areaID1 = data.areaID1;
        this.model.regionname1 = data.regionname1;
        this.getAssignedto(this.model.areaID1);
      });


  }
  getSuperAuthorities($event) {
    let userId = (<HTMLInputElement>document.getElementById('supervisiorAuth')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    this.user.trnid = this.model.customertypeID
    var obj = this;
    if (userId.length >= 3) {
      this.Masterservice.getSuperAuthoritiesLike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.onselectSuperAuth(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.onselectSuperAuth(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
  }

  onselectSuperAuth(id, name) {
    this.model.supervisiorAuthid = id;
    this.model.supervisiorAuth = name;
  }
  getProductcat() {
    this.Masterservice.getProductcat(this.user)
      .subscribe(data => {
        this.productcatelst = data.procategorylst;
      });

  }
// onselectsealdeler(){
//   this.model.chkse
// }
  getAssignedto(id) {

    // if(this.model.chkseledel==true){
      
      // this.model.localeID=this.model.localeID;
      // this.lstassigneddealer.push({ id: 0, name: "Select Assigned To" })
      // this.transactionService.getAssignedtoListdeler(this.model1)
      //   .subscribe(data => {
      //     data.forEach(item => {
      //       this.lstassigneddealer.push({ id: item.id, name: item.name });
      //     });
      //   });
  
    
    // }else{
    // this.teammodel.chkseledel
    this.teammodel.areaid = id;
    this.teammodel.customertypeid = this.model.customertypeID;
    this.teammodel.localeid = this.model.localeID;
    //this.model.locality1 = this.model.locality1;
    // this.teammodel.pcInt = [];
    // this.model.lstproductintersted.forEach(element => {
    //   this.teammodel.pcInt.push(element.productCategoryID);
    // });

    this.teammodel.typeInt = [];
    this.teammodel.typeInt.push(this.model.customertypeID);
    this.teammodel.forflag = this.forflag;
    this.lstassigned = [];
    this.lstassigned.push({ id: 0, name: "Select Assigned To" })
    this.transactionService.getAssignedtoList(this.teammodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstassigned.push({ id: item.id, name: item.name });
        });
      });

    this.lstleadassigned = [];
    this.lstleadassigned.push({ id: 0, name: "Select Assigned To" })
    this.transactionService.getleadAssignedtoList(this.teammodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstleadassigned.push({ id: item.id, name: item.name });
        });
      });
    // }
      // if(this.model.chkseledel=="Y"){
        this.teammodel.chkseledel ==true;
      this.lstleadassigneddealer = [];
      this.lstleadassigneddealer.push({ id: 0, name: "Select Assigned To" })
      this.transactionService.getAssignedbydeler(this.teammodel)
        .subscribe(data => {
          data.forEach(item => {
            this.lstleadassigneddealer.push({ id: item.id, name: item.name });
          });
        });
          // this.model.localeID=this.model.localeID;
      this.lstassigneddealer.push({ id: 0, name: "Select Assigned To" })
      this.transactionService.getleadAssignedtoListbydeler(this.teammodel)
        .subscribe(data => {
          data.forEach(item => {
            this.lstassigneddealer.push({ id: item.id, name: item.name });
          });
        });


  }
  onSelectlocality(id) {

    this.model.localityID = id;
    this.model.locality = this.localitymap.get(id);
    this.transactionService.getgeographydetails(this.model)
      .subscribe(data => {
        this.model.city = data.city;
        this.model.state = data.state;
        this.model.country = data.country;
        this.model.locale = data.locale;
        this.model.areaname = data.areaname;
        this.model.localeID = data.localeID;
        this.model.locality = data.locality
        this.model.areaID = data.areaID;
        this.model.regionname = data.regionname;
        this.getAssignedto(this.model.areaID);
      });


  }
  onSelectcustmertype(id) {
    this.model.customertypeID = id;
    this.user.trnid = id;
    this.Masterservice.getAlldataBytypeid(this.user)
      .subscribe(value => {
        this.model.numberofempflag = value.chknoofemployee;
        this.model.numberofempMandatory = value.mandchkemp;
        this.model.annualturnoverflag = value.chkanualtrnovr;
        this.model.annualturnoverMandatory = value.mandchktrnovr;
        this.model.industrytypebyCtypeflag = value.chkindstype;
        this.model.industrytypeMandatory = value.mandindtype;
        this.model.industrytypename = value.industrytypename;
        this.model.industrytypeID = value.industryid;
        this.model.designationMandatory = value.mandDesignation;
        this.model.designationid = value.designationid;
        this.model.designationname = value.designationname;
        this.model.chksuperVisiorAuth = value.chksupervisingAuth;
        this.model.mandSupervisiorAuth = value.manSupervising;
        this.model.chkdesignation = value.chkdesignation;

        if (this.model.designationname != null) {
          this.model.lstcontact[0].contactdesignation = this.model.designationname;
        } else {
          this.model.lstcontact[0].contactdesignation = null;
        }

      });

  }
  onSelectindustry(id) {
    this.model.industrytypeID = id;
  }
  onSelectconstutution(id) {
    this.model.constitutionID = id;
  }

  btnsave() {
    if (this.model.customername == null) {
      this.notifyService.showRroor("Please Enter Account Name", "Opps");
      return;
    }
    if (this.model.customertypeID == null) {
      this.notifyService.showRroor("Please Select Customer Type", "Opps");
      return;
    }
    if (this.model.address == null) {
      this.notifyService.showRroor("Please Enter Address", "Opps");
      return;
    }
    if (this.model.pincode == null) {
      this.notifyService.showRroor("Please Enter Pincode", "Opps");
      return;
    }
    if (this.model.localityID == null) {
      this.notifyService.showRroor("Please Select Locality", "Opps");
      return;
    }
    if (this.model.mobile1 == "" || this.model.mobile1 == null) {
      this.notifyService.showRroor("Please Enter Mobile Number", "Opps");
      return;
    }
    if (this.model.email == null) {
      this.notifyService.showRroor("Please Enter E-Mail", "Opps");
      return;
    }

    if (this.model.industrytypebyCtypeflag && this.model.industrytypeMandatory) {
      if (this.model.industrytypeID == null) {
        this.notifyService.showRroor("Please Select Industry Type", "Opps");
        return;
      }
      if (this.model.constitutionID == null) {
        this.notifyService.showRroor("Please Select Constitution", "Opps");
        return;
      }
    }
    if (this.model.annualturnoverflag && this.model.annualturnoverMandatory) {
      if (this.model.annualturnover == null) {
        this.notifyService.showRroor("Please Select Industry Type", "Opps");
        return;
      }
    }
    if (this.model.numberofempflag && this.model.numberofempMandatory) {
      if (this.model.numberofemp == null) {
        this.notifyService.showRroor("Please Select Industry Type", "Opps");
        return;
      }
    }
    if (this.lstcustomerexits.length > 0 && this.addedflag == false) {
      this.notifyService.showRroor("Customer present Please Select Existing Customer", "Opps");
      return;
    }
    if (this.model.mandSupervisiorAuth) {
      if (this.model.supervisiorAuth == null) {
        this.notifyService.showRroor("Please Select Supervision Authority", "Opps");
        return;
      }
    }
    if (this.model.mobile1 == this.model.mobile2) {
      this.notifyService.showRroor("Mobile number should be unique", "Opps");
      return;
    }

    let flag: boolean = false;
    if (this.model.lstproduct.length != 0) {
      flag = true;
    }
    if (!flag && this.newcallchecked) {
      this.notifyService.showRroor("Please Select At least One Product", "Opps");
      return;
    }

    this.model.userid = Number(localStorage.getItem('userid'));
    this.transactionService.saveaccountProspect(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("Account details saved", "Success");
          this.model.accountid = Number(data.message)
          this.getdetais()

        }
      });
  }
  btnback() {
    this.router.navigate(['/Call Center Dashboard']);
  }
  opencontact(contact: TemplateRef<any>, index) {
    if (index == null) {
      this.lstindex = null;
      this.contactmo.id = null;
      this.contactmo.contactname = this.model.customername;
      this.contactmo.contactdesignationID = null;
      this.contactmo.contactdesignation = null;
      this.contactmo.contactmobile = this.model.mobile1;
      this.contactmo.contactemail = this.model.email;

    } else {
      this.lstindex = index;

      this.contactmo.contactdesignation = this.model.lstcontact[index].contactdesignation;
      this.contactmo.contactname = this.model.lstcontact[index].contactname;
      this.contactmo.contactmobile = this.model.lstcontact[index].contactmobile;
      this.contactmo.contactemail = this.model.lstcontact[index].contactemail;
      this.contactmo.contactdesignationID = this.model.lstcontact[index].contactdesignationID;
      this.contactmo.id = this.model.lstcontact[index].id;
      this.contactmo.added = true;
      this.contactmo.checkselect = this.model.lstcontact[index].checkselect;

    }
    this.modalRef2 = this.modalService.show(contact, {
      class: 'modal-lg'
    });

  }
  btnaddcontact() {
    //this.modalRef2.hide();
    // this.getcustomerexits1()
    // if (this.contactmo.contactmobile == this.model.mobile1) {
    //   this.notifyService.showRroor("Contact already exist", "Opps");
    //   return;
    // }
    if (this.contactmo.contactname == null) {
      this.notifyService.showRroor("Please Enter Contact Name", "Opps");
      return;
    }
    // if (this.contactmo.contactdesignation == null) {
    //   this.notifyService.showRroor("Please Select Designation", "Opps");
    //   return;
    // }
    if (this.contactmo.contactmobile == null) {
      this.notifyService.showRroor("Please Enter Mobile Number", "Opps");
      return;
    } else {
      for (let i = 0; i < this.model.lstcontact.length; i++) {
        if (this.model.lstcontact[i].contactmobile == this.contactmo.contactmobile) {
          this.notifyService.showRroor("Account present", "Opps");
          return;
        }
      }

    }
    if (this.contactmo.contactemail == null) {
      this.notifyService.showRroor("Please Enter Email", "Opps");
      return;
    }
    if (this.lstindex == null) {
      this.model.lstcontact.push({
        id: this.contactmo.id,
        contactname: this.contactmo.contactname,
        contactdesignationID: this.contactmo.contactdesignationID,
        contactdesignation: this.contactmo.contactdesignation,
        contactmobile: this.contactmo.contactmobile,
        contactemail: this.contactmo.contactemail,
        added: true,
        checkselect: false,
        checkselectcon: false,
      })
      // alert(this.model.lstcontact.length);
      if (this.model.lstcontact.length == 1) {
        this.model.lstcontact.forEach(item => {
          item.checkselect = true;
        })
      }
    } else {
      this.model.lstcontact[this.lstindex].id = this.contactmo.id;
      this.model.lstcontact[this.lstindex].contactname = this.contactmo.contactname;
      this.model.lstcontact[this.lstindex].contactdesignationID = this.contactmo.contactdesignationID
      this.model.lstcontact[this.lstindex].contactdesignation = this.contactmo.contactdesignation;
      this.model.lstcontact[this.lstindex].contactmobile = this.contactmo.contactmobile;
      this.model.lstcontact[this.lstindex].contactemail = this.contactmo.contactemail;
      this.model.lstcontact[this.lstindex].added = true;
      this.model.lstcontact[this.lstindex].checkselect = false;

      if (this.model.lstcontact[this.lstindex].id != null) {

        for (let i = 0; i < this.model.lstcontact.length; i++) {
          if (this.model.lstcontact[this.lstindex].contactmobile = this.contactmo.contactmobile) {
            this.notifyService.showRroor("Contact already added!", "Oops!");
            return;
          }
        }
      }

    }
    this.lstindex = null;
    this.contactmo.id = null;
    this.contactmo.contactname = null;
    this.contactmo.contactdesignationID = null;
    this.contactmo.contactdesignation = null;
    this.contactmo.contactmobile = null;
    this.contactmo.contactemail = null;

  }
  btnaddproductintersted(index, flag) {
    if (flag == true) {
      //  alert(this.model.lstproductintersted[index].productid);
      if (this.model.lstproductintersted[index].productid == null || this.model.lstproductintersted[index].productid == 0) {
        this.notifyService.showRroor("Select Product", "Opps");
        return;
      }
    } else {
      if (this.model.lstproductintersted[index].productCategoryID == null || this.model.lstproductintersted[index].productCategoryID == 0) {
        this.notifyService.showRroor("Select Product Categories ", "Opps");
        return;
      }
    }
    // if (this.model.lstproductintersted[index].productid == null || this.model.lstproductintersted[index].productid == 0) {
    //   this.notifyService.showRroor("Select Product", "Opps");
    //   return;
    // }
    this.model.lstproductintersted[index].added = true;
    this.model.lstproductintersted.push({
      accountid: null,
      id: null,
      productid: null,
      name: null,
      productCategoryID: null,
      categoryname: null,
      description: null,
      purdate: new Date(),
      location: null,
      added: false,
      categoriesdescription: null,
      checkselect: false,
      address: null,
      pincode: null,
      localityID: null,
      areaID: null,
      locality: null,
      city: null,
      state: null,
      country: null,
      locale: null,
      areaname: null,
      regionname: null,
      amcWarrantyEndDate: null,
      amcEndDate: null,
      warrentyEndDate: null,
      serviceCallStatusName: null,
      installationDate: new Date(),
      googlemap: null,
      srlno: null,
      serialnoFlag: false,
      status: null,
      serviceCallStatusID: null,
    })

  }

  Onchangeaddress() {
    this.model.changeaddress = true;

    if (!this.model.changeaddress) {
      this.model.city1 = null;
      this.model.state1 = null;
      this.model.country1 = null;
      this.model.locale1 = null;
      this.model.areaname1 = null;
      this.model.localeID1 = null;
      this.model.regionname1 = null;

    }
  }
  Onreinstalltion() {
    this.model.reistallation = true;

  }
  closereinstalltion() {
    this.model.reistallation = false;
  }
  Onsameaddress($event) {
    if ($event.target.checked) {
      this.model.addresss = this.model.address;
      this.model.unitNumbers = this.model.unitNumber;
      this.model.buildingNames = this.model.buildingName;

      this.model.pincode1 = this.model.pincode;
      this.model.locality1 = this.model.locality;
      this.model.localityID1 = this.model.localityID;
      this.model.city1 = this.model.city;
      this.model.state1 = this.model.state;
      this.model.country1 = this.model.country;
      this.model.locale1 = this.model.locale;
      this.model.areaname1 = this.model.areaname;
      this.model.localeID1 = this.model.localeID;
      this.model.regionname1 = this.model.regionname;
      this.sendmodel.pincode = this.model.pincode;
      this.model.googlemap1 = this.model.googlemap;
      this.transactionService.getlocality(this.sendmodel)
        .subscribe(data => {
          data.forEach(item => {
            this.lstlocalitys.push({ id: item.id, name: item.name });
          });
        });
    } else {
      this.model.unitNumbers = null
      this.model.buildingNames = null
      this.model.addresss = null
      this.model.city1 = null;
      this.model.state1 = null;
      this.model.country1 = null;
      this.model.locale1 = null;
      this.model.areaname1 = null;
      this.model.localeID1 = null;
      this.model.regionname1 = null;
      this.model.address1 = null;
      this.model.pincode1 = null;
      this.model.locality1 = null;
      this.model.googlemap1 = null;
      this.model.localityID1 = null;
    }

  }
  btndeltsproduct(id, index) {
    let i = confirm("Do you want to delete this record ?")
    if (i == true) {
      if (id != null) {
        this.SearchAccomodel.id = id
        this.SearchAccomodel.userid = Number(localStorage.getItem('userid'));
        this.Masterservice.deleteaccwisemc(this.SearchAccomodel)
          .subscribe(value => {
            this.notifyService.showSuccess(value.message, " Success");
          });
        this.model.lstproduct.splice(index, 1);
      } else {
        this.model.lstproduct.splice(index, 1);
      }
    }
  }
  btndelts(id, index) {
    let i = confirm("Do you want to delete Conatct ?")
    if (i == true) {
      if (id != null) {
        this.user.trnid = id;
        this.user.userId = Number(localStorage.getItem('userid'));
        this.transactionService.deletecontact(this.user)
          .subscribe(data => {
            if (data.status == "OK") {
              this.notifyService.showSuccess("Contact deleted", "Success");
            }
          });
        this.model.lstcontact.splice(index, 1);
      } else {
        this.model.lstcontact.splice(index, 1);
      }
    }
  }

  onSelectdesiganation(value) {
    this.contactmo.contactdesignation = value;
  }
  onSelectlstleadassigned(val) {
    this.model.assignedtoID = val;
    this.user.trnid = val;
    this.transactionService.getleadofresourse(this.user)
      .subscribe(data => {
        this.model.numberofleads = data.trnid;
        if (this.model.numberofleads <= 1) {
          this.Leads = "Lead"
        }
        if (this.model.numberofleads > 1) {
          this.Leads = "Leads"
        }

      });
  }

  btnsavelead(leadModel: TemplateRef<any>) {
    let flag2: boolean = false;
    this.model.lstcontact.forEach(element => {
      if (element.checkselect) {
        flag2 = true;
      }
    });

    if (!flag2) {
      this.notifyService.showRroor("Please Select At least One Contact", "Opps");
      return;
    }
    // if (this.model.lstproductintersted.length <= 1) {
    //   this.notifyService.showRroor("Please Add At least One Product", "Opps");
    //   return;
    // }
    if (this.model.assignedtoID == null || this.model.assignedtoID == 0) {
      this.notifyService.showRroor("Please Select Assigned To", "Opps");
      return;
    }

    if (this.model.sourceID == null || this.model.sourceID == 0) {
      this.notifyService.showRroor("Please Select Source", "Opps");
      return;
    }
    if (this.model.remark == null || this.model.remark == "") {
      this.notifyService.showRroor("Please Select Remark", "Opps");
      return;
    }

    if (this.lstsourcedetails.length > 0) {
      this.lstsource1.forEach(item => {
        if (this.model.sourceID == item.id) {
          this.model.checkselectsource = item.chkselect;
        }
      })
    }
    if (this.model.checkselectsource == true && this.model.sourcedetailID == null || this.model.sourcedetailID == 0 && this.model.checkselectsource == true) {
      this.notifyService.showRroor("Please Select Source Details", "Opps");
      return;
    }

    // if (this.model.sourcedetailID == null || this.model.sourcedetailID == 0) {
    //   this.notifyService.showRroor("Please Select Source Details", "Opps");
    //   return;
    // }



    // if (this.model.sourcedetailID == null || this.model.sourcedetailID == 0) {
    //   this.notifyService.showRroor("Please Select Source Details", "Opps");
    //   return;
    // }



    // this.sendmodel.accountid = accountid;
    // this.sendmodel.productID = productid;
    // this.transactionService.checkServicecall(this.sendmodel)
    //   .subscribe(data => {
    //     if (data.serviceCallnumber!=null) {
    //         this.serviceNO=data.serviceCallnumber;
    //         this.serviceID=data.serviceCallID;
    //         this.modalRef2 = this.modalService.show(servicecallModel, {
    //           class: 'modal-lg'
    //         });

    //     } else {
    if (this.leadID == null) {
      this.transactionService.checkleadStatus(this.model)
        .subscribe(data => {
          if (data.status == "OK") {
            this.notifyService.showRroor("Lead already present", "Opps");
            this.modalRef2 = this.modalService.show(leadModel, {
              class: 'modal-lg'
            });
            this.serviceNO = data.message;
          } else {
            this.transactionService.btnsavelead(this.model)
              .subscribe(data => {
                if (data.status == "OK") {
                  this.notifyService.showSuccess("Lead Created", "Success");
                  this.router.navigate(['/Call Center Dashboard']);
                }
              });
          }
        });
    } else {
      this.model.leadID = Number(this.leadID);
      this.transactionService.btnsavelead(this.model)
        .subscribe(data => {
          if (data.status == "OK") {
            this.notifyService.showSuccess("Lead Created", "Success");
            this.router.navigate(['/Opportunities']);
          }
        });
    }


  }
  btnbacklead() {
    if (this.leadID == null) {
      this.router.navigate(['/Call Center Dashboard']);
    } else {
      this.router.navigate(['/Opportunities']);
    }
  }

  onSelectsourcedetails(id) {
    this.model.sourcedetailID = id;
  }

  openlocationmodel(locationmodel: TemplateRef<any>, index) {
    this.lstlocalityforproduct = [];
    this.lstindex = null;
    this.lstlocalityforproduct.push({ "id": 0, "name": "Select Locality" })
    this.lstindex = index
    this.productname = null;
    this.productid = null;
    this.productcategoryid = null;
    if (index != null) {
      if (this.model.lstproduct[index].productCategoryID != null) {
        this.productcategoryid = this.model.lstproduct[index].productCategoryID;
        this.promodel.productCategoryID = this.productcategoryid;
      }
      if (this.model.lstproduct[index].productid != null) {
        this.productid = this.model.lstproduct[index].productid;
        this.promodel.productid = this.model.lstproduct[index].productid;
      }
      if (this.model.lstproduct[index].name != null) {
        this.productname = this.model.lstproduct[index].name;
        this.promodel.name = this.model.lstproduct[index].name;
      }
      if (this.model.lstproduct[index].address != null) {
        this.addr = this.model.lstproduct[index].address;
      }
      if (this.model.lstproduct[index].pincode != null) {
        this.pincode = this.model.lstproduct[index].pincode;
        this.sendmodel.pincode = this.model.lstproduct[index].pincode;
      }
      if (this.model.lstproduct[index].localityID != null) {
        this.localityID = this.model.lstproduct[index].localityID;
        this.sendmodel.localityID = this.model.lstproduct[index].localityID;

      }
      if (this.model.lstproduct[index].areaID != null) {
        this.areaID = this.model.lstproduct[index].areaID;
      }
      if (this.model.lstproduct[index].locality != null) {
        this.locality = this.model.lstproduct[index].locality;
      }

      if (this.model.lstproduct[index].city != null) {
        this.city = this.model.lstproduct[index].city;
      }

      if (this.model.lstproduct[index].state != null) {
        this.state = this.model.lstproduct[index].state;
      }

      if (this.model.lstproduct[index].country != null) {
        this.country = this.model.lstproduct[index].country;
      }

      if (this.model.lstproduct[index].locale != null) {
        this.locale = this.model.lstproduct[index].locale;
      }

      if (this.model.lstproduct[index].areaname != null) {
        this.areaname = this.model.lstproduct[index].areaname;
      }
      if (this.model.lstproduct[index].regionname != null) {
        this.regionname = this.model.lstproduct[index].regionname;
      }
      if (this.model.lstproduct[index].googlemap != null) {
        this.googlemap = this.model.lstproduct[index].googlemap;
      }
      if (this.model.lstproduct[index].serialnoFlag != null) {
        this.serialnoFlag = this.model.lstproduct[index].serialnoFlag;
      }
      if (this.model.lstproduct[index].srlno != null) {
        this.srlno = this.model.lstproduct[index].srlno;
      }
    } else {
      // this.lstindex = index;
      this.addr = this.model.address;
      this.pincode = this.model.pincode;
      this.sendmodel.pincode = this.model.pincode;
      this.localityID = this.model.localityID;
      this.sendmodel.localityID = this.model.localityID;
      this.areaID = this.model.areaID;
      this.locality = this.model.locality;
      this.city = this.model.city;
      this.state = this.model.state;
      this.country = this.model.country;
      this.locale = this.model.locale;
      this.areaname = this.model.areaname;
      this.regionname = this.model.regionname;
      this.googlemap = this.model.googlemap;
      this.amcddateflag = false;
      this.warrentydateflag = false;

    }



    this.transactionService.getlocality(this.sendmodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalityforproduct.push({ id: item.id, name: item.name });
        });
      });

    this.modalRef = this.modalService.show(locationmodel, {
      class: 'modal-lg'
    });
  }

  btnaddproductaddress() {

    if (this.promodel.productCategoryID == null) {
      this.notifyService.showRroor("Please Select Product Category", "Opps");
      return
    }
    if (this.promodel.name == null) {
      this.notifyService.showRroor("Please Select Product Name", "Opps");
      return
    }
    if (this.pincode == null) {
      this.notifyService.showRroor("Please Select Pincode", "Opps");
      return
    }
    if (this.localityID == null) {
      this.notifyService.showRroor("Please Select Locality", "Opps");
      return
    }
    if (this.promodel.serviceCallStatusName == null) {
      this.notifyService.showRroor("Please Select Machine Status", "Opps");
      return
    }

    // if (this.serialnoFlag && this.srlno == null) {
    //   this.notifyService.showRroor("Please Enter Serial No.", "Opps");
    //   return
    // }

    if (this.lstindex == null) {
      this.model.lstproduct.push({
        accountid: this.model.accountid,
        checkselect: false,
        id: null,
        productid: this.promodel.productid,
        name: this.promodel.name,
        productCategoryID: this.promodel.productCategoryID,
        categoryname: null,
        categoriesdescription: null,
        // description: this.description.get(this.promodel.name),
        description: this.promodel.name,
        purdate: null,
        installationDate: null,
        added: true,
        location: this.city + ", " + this.locality + ", " + this.pincode,
        address: this.addr,
        pincode: this.pincode,
        localityID: this.sendmodel.localityID,
        areaID: this.areaID,
        locality: this.locality,
        city: this.city,
        state: this.state,
        country: this.country,
        locale: this.locale,
        areaname: this.areaname,
        regionname: this.regionname,
        serviceCallStatusName: this.promodel.serviceCallStatusName,
        googlemap: this.googlemap,
        amcWarrantyEndDate: this.amcdEndDate,
        amcEndDate: this.amcdEndDate,
        warrentyEndDate: this.warrentyEndDate,
        srlno: this.srlno,
        serialnoFlag: this.serialnoFlag,
        status: null,
        serviceCallStatusID: null,
      })
    } else {
      this.model.lstproduct[this.lstindex].name = this.productname;
      this.model.lstproduct[this.lstindex].productCategoryID = this.productcategoryid;
      this.model.lstproduct[this.lstindex].productid = this.productid;
      this.model.lstproduct[this.lstindex].address = this.addr;
      this.model.lstproduct[this.lstindex].pincode = this.pincode;
      this.model.lstproduct[this.lstindex].localityID = this.localityID;
      this.model.lstproduct[this.lstindex].areaID = this.areaID;
      this.model.lstproduct[this.lstindex].locality = this.sendmodel.locality;
      this.model.lstproduct[this.lstindex].city = this.city;
      this.model.lstproduct[this.lstindex].state = this.state;
      this.model.lstproduct[this.lstindex].country = this.country;
      this.model.lstproduct[this.lstindex].locale = this.locale;
      this.model.lstproduct[this.lstindex].areaname = this.areaname;
      this.model.lstproduct[this.lstindex].regionname = this.regionname;
      this.model.lstproduct[this.lstindex].amcEndDate = this.amcdEndDate;
      this.model.lstproduct[this.lstindex].warrentyEndDate = this.warrentyEndDate;
      this.model.lstproduct[this.lstindex].serviceCallStatusName = this.serviceCallStatusName;
      this.model.lstproduct[this.lstindex].googlemap = this.googlemap;
      this.model.lstproduct[this.lstindex].serialnoFlag = this.serialnoFlag;
      this.model.lstproduct[this.lstindex].srlno = this.srlno;
      this.model.lstproduct[this.lstindex].location = this.model.lstproduct[this.lstindex].city + ", " + this.model.lstproduct[this.lstindex].locality + ", " + this.model.lstproduct[this.lstindex].pincode;

    }
    this.productname = null;
    this.productid = null;
    this.productcategoryid = null;
    this.modalRef.hide();
  }

  btncancle() {
    this.modalRef.hide();
  }

  Oncheckselect(index) {
    for (let i = 0; i < this.model.lstproduct.length; i++) {
      this.model.lstproduct[i].checkselect = false;
    }
    this.model.lstproduct[index].checkselect = true;
    this.model.productID = this.model.lstproduct[index].productid
    this.model.acwiseid = this.model.lstproduct[index].id
  }
  onSelectmachinestatus(id) {
    this.warrentydateflag = false;
    this.amcddateflag = false;
    // this.model.serviceCallStatusID = id;
    this.mcstatuscodelst.forEach(element => {
      if (element.id == id) {
        this.promodel.serviceCallStatusName = element.name
        if (element.name == "W") {
          this.warrentydateflag = true;
        }
        if (element.name == "A") {
          this.amcddateflag = true;
        }
      }
    });
  }


  Oncheckselectcontact($event, index) {
    if ($event.target.checked) {
      this.model.lstcontact.forEach(item => {
        item.checkselect = false;
      });
      this.model.lstcontact[index].checkselect = true;
    } else if (!$event.target.checked) {
      for (let i = 0; i < this.model.lstcontact.length; i++) {
        this.model.lstcontact[i].checkselect = false;
      }
    }


    // if (!$event.target.checked) {
    //   for (let i = 0; i < this.model.lstcontact.length; i++) {
    //     this.model.lstcontact[i].checkselect = false;
    //   }
    // }
    // this.model.lstcontact[index].checkselect = true;
    this.model.contactID = this.model.lstcontact[index].id;
  }

  onSelectlstassignedforcalls(id) {
    this.model.assignedtoID = id;
    this.user.trnid = id;
    this.transactionService.getcallofresourse(this.user)
      .subscribe(data => {
        this.model.calls = data.trnid;
        if (this.model.calls <= 1) {
          this.call = "Call"
        } else if (this.model.calls > 1) {
          this.call = "Calls"
        }
      });
    if (this.lstassigned.length == 0) {
      this.notifyService.showRroor("No resource for selected customer available", "Opps");
      return;
    }
  }

  
  onSelectpriority(value) {
    this.model.priority = value;
  }

  btnsaveBookCall(servicecallModel: TemplateRef<any>) {
    let flag: boolean = false;
    this.model.lstproduct.forEach(element => {
      if (element.checkselect) {
        flag = true;
      }
    });
    let flag2: boolean = false;
    this.model.lstcontact.forEach(element => {
      if (element.checkselect) {
        flag2 = true;
      }
    });
    if (!flag) {
      this.notifyService.showRroor("Please Select At least One Product", "Opps");
      return;
    }
    if (this.model.mcstatus == null) {
      this.notifyService.showRroor("Please Select Working Status", "Opps");
      return;
    }
    if (this.model.priority == null) {
      this.notifyService.showRroor("Please Select Priority", "Opps");
      return;
    }
    if (this.model.assignedtoID == null || this.model.assignedtoID == 0) {
      this.notifyService.showRroor("Please Select Assigned To", "Opps");
      return;
    }
    if (this.model.callcommitdate == null) {
      this.notifyService.showRroor("Please Select Call Commit Date", "Opps");
      return;
    }
    if (this.model.problemreported == null) {
      this.notifyService.showRroor("Please Enter Problem Reported ", "Opps");
      return;
    }
    if (!flag2) {
      this.notifyService.showRroor("Please Select At least One Contact", "Opps");
      return;
    }

    if (this.model.reistallation) {
      if (this.model.addresss == null) {
        this.notifyService.showRroor("Please Enter Address", "Opps");
        return;
      }
      if (this.model.pincode1 == null) {
        this.notifyService.showRroor("Please Enter Pincode", "Opps");
        return;
      }
      // if (this.model.locality1 == null) {
      //   this.notifyService.showRroor("Please Select locality", "Opps");
      //   return;
      // }
      if (this.model.localityID1 == null) {
        this.notifyService.showRroor("Please Select locality", "Opps");
        return;
      }

    }


    // this.sendmodel.accountid = this.model.accountid;
    // this.sendmodel.productID = this.model.productID;
    // this.transactionService.checkServicecall(this.sendmodel)
    //   .subscribe(data => {
    //     if (data.serviceCallnumber != null) {
    //       this.serviceNO = data.serviceCallnumber;
    //       this.serviceID = data.serviceCallID;
    //       this.modalRef2 = this.modalService.show(servicecallModel, {
    //         class: 'modal-lg'
    //       });

    //     } else {

    //     }
    //   });
    this.transactionService.BookServiceCall(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("Service Call Booked", "Success");
          this.router.navigate(['/Call Center Dashboard']);
        }
      });
  }
  btndeleteinterstedproduct(index) {
    this.model.lstproductintersted.splice(index, 1);
    this.notifyService.showSuccess("Product Deleted ", "Success");
  }
  getcustomerexits(accountmodel: TemplateRef<any>, contactmodel: TemplateRef<any>) {

    this.lstcustomerexits = [];
    if (this.model.mobile1.length >= 9 || this.contactmo.contactmobile.length >= 9) {
      //this.model.mobile1 = this.contactmo.contactmobile;
      this.transactionService.getcustomerexiste(this.model)
        .subscribe(data => {
          if (data.length > 0) {
            this.lstcustomerexits = data
            this.notifyService.showRroor("Account present", "Opps");

            this.modalRef2 = this.modalService.show(accountmodel, {
              class: 'modal-lg'
            });

          }
          // else
          // {
          //   this.modalRef2 = this.modalService.show(contactmodel, {
          //     class: 'modal-lg'
          //   });
          // }
        });
    }
  }

  getcustomerexits1() {
    this.lstcustomerexits = [];
    if (this.contactmo.contactmobile.length >= 9) {
      this.model.mobile1 = this.contactmo.contactmobile;
      this.transactionService.getcustomerexiste(this.model)
        .subscribe(data => {
          if (data.length > 0) {
            this.lstcustomerexits = data
            this.notifyService.showRroor("Account present", "Opps");


          } else {
            // alert(this.model.lstcontact.length);
            if (this.model.lstcontact.length > 0) {
              this.model.lstcontact.forEach(item => {
                // alert(item.contactmobile);
                if (item.contactmobile == this.contactmo.contactmobile) {
                  this.notifyService.showRroor("Account present", "Opps");
                }
              })
            }
          }

        });
    }
  }
  btnaddcustomerexites(id) {
    this.model.accountid = id;
    this.addedflag = true;
    this.modalRef2.hide();
    this.getdetais();
  }



  btnclosemodel() {
    this.modalRef2.hide();
  }

  //For Prospect and Add Account form for Autocomplete
  selectEvent(item) {
    this.model.pincode = item.name;
    this.lstlocality = [];
    this.lstlocality.push({ id: 0, name: "Select Locality" });
    // this.model.supervisiorAuth = null;
    this.transactionService.getlocality(this.model)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocality.push({ id: item.id, name: item.name });
        });
        this.model.pincode = item.id;
        this.model.pincode = item.name;
      });



  }
  onChangeSearch(search: string) {
    this.user.message = search;
    this.transactionService.getpincodelike(this.user)
      .subscribe(data => {
        this.pincodelist = [];
        data.forEach((item) => this.pincodelist.push({ "id": item.id, "name": item.name }));

      });


  }

  onFocused(e) {
    // do something
  }
  //For Product model pincode Autocomplete
  selectEvent1(item) {
    this.model.pincode = item.name;
    this.lstlocalityforproduct = [];
    this.lstlocalityforproduct.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.model)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalityforproduct.push({ id: item.id, name: item.name });
        });

      });
  }
  onChangeSearch1(search: string) {
    this.user.message = search;
    this.transactionService.getpincodelike(this.user)
      .subscribe(data => {
        this.pincodelist1 = [];
        data.forEach((item) => this.pincodelist1.push({ "id": item.id, "name": item.name }));

      });

  }

  onFocused1(e) {
    // do something
  }

  //For Create Lead Autocomplete 
  selectEvent2(item) {
    this.model.pincode1 = item.name;
    this.lstlocalitys = [];
    this.lstlocalitys.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocalityforchangeaddress(this.model)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalitys.push({ id: item.id, name: item.name });
        });
        this.model.pincode1 = item.id;
        this.model.pincode1 = item.name;
      });
  }

  onChangeSearch2(search: string) {

    this.user.message = search;
    this.transactionService.getpincodelike(this.user)
      .subscribe(data => {
        this.pincodelist2 = [];
        data.forEach((item) => this.pincodelist2.push({ "id": item.id, "name": item.name }));

      });

  }

  onFocused2(e) {
    // do something
  }

  //For Create Lead Autocomplete 
  selectEvent5(item, value, id,i) {
    // alert(value)
    if (value == "Product") {
      this.model.lstproductintersted[id].productid = item.id;
      // alert(this.model.lstproductintersted[ind].productid)
      this.model.lstproductintersted[id].name = item.name;
      this.model.lstproductintersted[id].description = item.description;

    }
    else {
      this.model.lstproductintersted[i].productCategoryID = item.id;
      this.model.lstproductintersted[i].categoryname = item.name;
      this.model.lstproductintersted[i].categoriesdescription = item.description;
    }

    if (this.model.areaID1 != null) {
      this.getAssignedto(this.model.areaID1);
    } else {
      this.getAssignedto(this.model.areaID);
    }
    //this.ngOnInit();
  }

  onChangeSearch5(search: string) {

    this.user.message = search;
    this.transactionService.getproductCategorieslike(this.user)
      .subscribe(data => {
        this.prodcat = [];
        data.forEach((item) => this.prodcat.push({ "name": item.name, "id": item.id, "description": item.description }));

      });

  }
  onFocused5(e) {
    // do something
  }
  onChangeSearch6(search: string, ind) {
    if (this.model.lstproductintersted[ind].productCategoryID == null) {
      this.notifyService.showRroor("Please Select Product Category", "Opps");
      return;
    }
    this.user.message = search;
    this.user.productcategoryId = this.model.lstproductintersted[ind].productCategoryID;
    this.dealersService.getProductLike(this.user)
      .subscribe(data => {
        this.prodcat = [];
        data.forEach((item) => this.prodcat.push({ "name": item.name, "id": item.id, "description": item.description }));

      });

  }

  onFocused6(e) {
    // do something
  }

  //For service call Autocomplete
  selectEvent3(item) {
    this.model.pincode1 = item.name;
    this.lstlocalitys = [];
    this.lstlocalitys.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocalityforchangeaddress(this.model)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalitys.push({ id: item.id, name: item.name });
        });
        this.model.pincode1 = item.id;
        this.model.pincode1 = item.name;
      });
  }

  onChangeSearch3(search: string) {

    this.user.message = search;
    this.transactionService.getpincodelike(this.user)
      .subscribe(data => {
        this.pincodelist3 = [];
        data.forEach((item) => this.pincodelist3.push({ "id": item.id, "name": item.name }));

      });

  }

  onFocused3(e) {
    // do something
  }

  //For Supervising Authority Autocompete
  selectEvent4(item) {
    this.model.supervisiorAuthid = item.id;
    this.model.supervisiorAuth = item.name
  }

  onChangeSearch4(search: string) {
    this.user.message = search;
    this.user.trnid = this.model.customertypeID

    this.Masterservice.getSuperAuthoritiesLike(this.user)
      .subscribe(data => {
        this.supervisiorAuth1 = [];
        data.forEach((item) => this.supervisiorAuth1.push({ id: item.id, name: item.name })
        );
      });

  }

  onFocused4(e) {
    // do something
  }

  //This autocomplete for add product model.
  onChangeSearchproduct(search: string) {
    this.user.message = search;
    this.dealersService.getProductLike(this.user)
      .subscribe(data => {
        this.userList2 = [];
        data.forEach((item) => this.userList2.push({ "productcatname": item.productcatname, "productName": item.productName, "productid": item.productid, "currencyName": item.currencyName, "mrp": item.mrp, "description": item.description, "serialnoFlag": item.serialnoFlag }));

      });
  }
  selectEventforproduct(item) {
    this.promodel.productid = item.productid;

    this.promodel.name = item.productName;

    this.userList2.forEach(element => {
      if (element.productid == item.productid && element.serialnoFlag) {
        this.serialnoFlag = true;
      }
    });
  }

  onFocusedforproduct(e) {
    // do something
  }
  selectCallCommitDate(date) {
    let pastdate = moment(new Date(), "DD/MM/YYYY").add(this.model.customervalue, 'days').toDate()
    if (date < pastdate && date < new Date()) {
      this.notifyService.showRroor("Previous date cannot be selected", "oops");
      this.model.callcommitdate = new Date()
      return;
    }
  }

  getItemLike1($event) {
    let userId = (<HTMLInputElement>document.getElementById('product')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.Masterservice.getItemLikeCpy(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect1(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect1(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
  }
}