import { Component, OnInit } from '@angular/core';
import { User } from '../_models/index';
import { Mainmenumodel } from '../_models/Mainmenumodel';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, MasterServices } from '../_services/index';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-header-mobilecomponent', 
  // moduleId: module.id.toString(),
  templateUrl: './header.mobilecomponent.html',
  styleUrls: ['./header.component.css']
})
export class Mobilecomponent implements OnInit {

  isLoggedIn$: boolean;
  model: any = {};
  i: any;
  username: string;
  imgname: string;
  currentUser: User;
  today: Date;
  mainmenus: Mainmenumodel[] = [];
  loading = false;
  fname: string;
  public showloader: boolean = false;
  constructor(private router: Router,
    private datePipe: DatePipe,
    public client: HttpClient,
    private authenticationService: AuthenticationService,
    private masterServices: MasterServices,
    private route: ActivatedRoute) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.isLoggedIn$ = false;
    this.loading = false;
    this.today = new Date();
    console.log('hello `Home` component');
    this.model.userid = localStorage.getItem('userid');
    if (localStorage.getItem('menu')) {
      this.isLoggedIn$ = true;
      this.mainmenus = JSON.parse(localStorage.getItem('menu'));
      this.loading = false;
    } else if (localStorage.getItem('profile')) {
      this.isLoggedIn$ = true;
      this.getmenubyuser();

    } else {
      this.router.navigate(['/login']);
      this.loading = false;
    }
    this.username = localStorage.getItem('fullname');
    this.fname = localStorage.getItem('firstName');
    // this.imgname = "https://s3.ap-south-1.amazonaws.com/clinicimages/" + localStorage.getItem('imgname');
    // this.setTimer()
  }
  btnlogout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  redirectUser(mainmenulabel: string) {
    this.router.navigate(['/' + mainmenulabel]);
  }
  btnhome() {
    this.router.navigate(['/home']);
  }

  private getmenubyuser() {
    var user = new User();
    this.model.username = localStorage.getItem('username');
    this.model.accessToken = localStorage.getItem('Token');
    this.model.userid = localStorage.getItem('userid');
    this.model.usergroupid = localStorage.getItem('usergroupid');
    this.masterServices.getmenubyusermobile(this.model)
      .subscribe(mainumodel => {
        localStorage.setItem('menu', JSON.stringify(mainumodel));
        this.mainmenus = mainumodel;
      });
  }

  


  // public setTimer(){

  //   // set showloader to true to show loading div on view
  //   this.loading   = true;

  //   this.timer        = Observable.timer(10000); // 5000 millisecond means 5 seconds
  //   this.subscription = this.timer.subscribe(() => {
  //       // set showloader to false to hide loading div from view after 5 seconds
  //       this.loading = false;
  //   });
  // }
}