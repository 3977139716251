import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import { SearchMarketingModel } from "src/app/Master/MarketingCostSubMaster/SearchMarketingModel";
import { DropDownModel } from "src/app/_models/DropDownModel";

export class MarketingProposalModel {
    id: number;
    resource:string;
    userid:number

    chkevent: boolean;
    chkstandalon: boolean;
    chkcollateral: boolean;
    proposaltype: string;
    
    eventtypeid: number;
    eventtype:string;
    shortname: string;
   
    description: string;
    expectedoutcome: string;
    
    pincode: string;
    localityid: number;
    region:string;
    area:string;
    locality:string;
    city:string;
    locale:string

    chkinternal: boolean;
    chkdealer: boolean;
    eventproposedby: string;
    dealerid: number;
    dealername:string;
    
    fromdate: Date;
    todate: Date;
    daysDuration: string;
    days:number;

    approverid:number;
    status:string;

    sponser: string;

    dealercontri:number;
    companycotri:number;
    totalcontri:number;

    approvedBy:string;
    approvalNo:string
    approvedDate:Date;

    paymentdetails:string;
    collateralsIissuesnotes:string;

    chkRecurring:boolean;
    chkdays:boolean;
    chkmonths:boolean;
    chknonschedule:boolean
    durationType:string;

    closeEvent:boolean;

    costheadid:number;
    costhead:string;
    subheadid:number;

    des1:string;
    amnt:number;
    
    chkApproved:boolean;
    chkReject:boolean;
    decision:string;

    expenselst:ExpensesModel[] = [];
    mrktmodellst:SearchMarketingModel[] = [];
    subheadlst:SubheadModel[] = [];
    costheadlst:DropDownModel[] = [];
    costSubhead:string;
    cityid:number;
    subheadvalue:number;
    uomname:string;
    costsubname:string;
    rate:number;
    qty:number;
    balance:number;
    chkselectvalue:boolean;
    budgeted:number;

}
export class ExpensesModel {
    id: number;
    description: string;
    amount: number;
    sponser: string;
    unit:string;
    balance:number;

    costhead:string;
    costheadid:number;

    subhead:string;
    subheadid:number;

    chkselect:boolean;
    expenseDate:Date;
    expenseMonth:string

    approvedamount:number
    disbursedamount:number
    priviousdisburseamt:number;
    subheadvalue:number;
    rate:number;
    totalsubheadvalue:number;
    qty:number;
    finalamount:number;
    alert:boolean = false;
    chkselectvalue:boolean;
    budgeted:number;
}

export class SubheadModel{
    id:number;
    subhead:string;
    unit:string;
    unitid:number;
    amount:number;

}