import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { MasterServices } from "../_services";
import { NotificationService } from "../_services/notification.service";
import { Location } from '@angular/common';
import { SearchDealerModel } from "./SearchDealerModel";
import { User } from "../_models";
import { DropDownModel } from "../_models/DropDownModel";
import { DealersService } from "../_services/DealersService";
@Component({
    selector: 'app-login',

    templateUrl: 'SearchDealer.html'
})
export class SearchDealer implements OnInit {
    model = new SearchDealerModel();
    sub: any;
    user = new User();
    id: number;
    p: any;
    public i;
    dealerlst: SearchDealerModel[] = [];
    dealerlst1: SearchDealerModel[] = [];
    constructor(private Masterservice: MasterServices, private dealersService: DealersService, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Code" },
        { id: 3, "name": "Name" },
    ];

    ngOnInit(): void {
        this.getAllDealersDetail();
    }

    getAllDealersDetail() {
        this.user.message = 'Y';

        this.dealersService.getAllDealersDetail(this.user)
            .subscribe(value => {
                this.dealerlst = value;
                this.dealerlst1 = [];
                value.forEach((item) => this.dealerlst1.push({ "id": item.id, "name": item.name, "code": item.code, "status": item.status, "txtserch": item.txtserch, "txtselect": item.txtselect }));
                this.dealerlst = this.dealerlst1;
            })
    }

    btnnew() {
        this.router.navigate(['/newDealersDetail']);
    }
    onSelect(value) {
        this.model.txtselect = value;
    }

    btnedit(id) {
       
        this.router.navigate(['/editDealersDetail', id]);
    }

    btndelts(id) {
        this.model.id = id
        this.i = confirm("Do you want to delete this record ?");
        if (this.i == true) {
            this.dealersService.deletedealersdetail(this.model)

                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, "Delete Successfully !");
                        this.ngOnInit()
                    }
                });

        }
    }
    btnsearch() {
        if (this.model.txtselect == "All") {
            this.getAllDealersDetail()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Code") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.dealerlst1 = [];
                this.dealerlst.forEach((item) => {
                    if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.dealerlst1.push({ "id": item.id, "name": item.name, "code": item.code, "status": item.status, "txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.dealerlst = this.dealerlst1;
            }
        }
        else if (this.model.txtselect == "Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.dealerlst1 = [];
                this.dealerlst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.dealerlst1.push({ "id": item.id, "name": item.name, "code": item.code, "status": item.status, "txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.dealerlst = this.dealerlst1;
            }
        }
    }
}
