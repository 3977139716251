import { Field } from './Field';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../_services/notification.service';
import { User } from '../../_models/user';
import { AlertService, MasterServices } from '../../_services';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DefineMasterModel } from './DefineMasterModel';
@Component({
    selector: 'app-prv',
    templateUrl: 'DefineMaster.html'
})
export class DefineMaster implements OnInit {
    model = new DefineMasterModel();
    user = new User();
    formGroup: FormGroup = new FormGroup({});
    formGroups: FormArray;
    fields: Field[] = [];
    fiel: Field[] = [];
    id: number;
    page: string;
    rowId: number;
    stringsplit: string[];
    pagename: string;
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private notifyService: NotificationService, private _formBuilder: FormBuilder) {
    }
   
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));

        
        this.route.queryParams.subscribe(params => {
            this.rowId = + params["Rowid"];
            this.page = params["master"];
        });

        if (this.rowId != null && this.rowId > 0) {
            this.user.trnid = this.rowId;
            this.user.message = this.page;
            this.formGroups = new FormArray([]);
            this.Masterservice.getMasterById(this.user)
                .subscribe(data => {
                    this.fields = data.extended;
                    for (let field of this.fields) {
                        const formGroup1 = this._formBuilder.group(
                            {
                                'dspname': this._formBuilder.control(field.dspname),
                                'columnName': this._formBuilder.control(field.columnName),
                                'dataType': this._formBuilder.control(field.dataType),
                                'masterId': this._formBuilder.control(field.masterId),
                                'value': this._formBuilder.control(field.value),
                                'id': this._formBuilder.control(''),
                                'labelName': this._formBuilder.control(field.labelName),
                                'maxlenth': this._formBuilder.control(field.maxlenth),
                                'compulsary': this._formBuilder.control(field.compulsary)
                            }
                        );
                        this.formGroups.push(formGroup1);
                    }
                },
                    error => {
                        console.log(error);
                    });
            this.formGroup = new FormGroup({
                'extended': this.formGroups
            });

        } else {
            this.route.queryParams.subscribe(params => {
                this.page = params["master"];
            });
            if (this.page != null) {
                this.user.message = this.page
                this.formGroups = new FormArray([]);
                this.Masterservice.getFields(this.user)
                    .subscribe(data => {
                        this.fields = data.extended;
                        for (let field of this.fields) {

                            const formGroup = this._formBuilder.group(
                                {
                                    'dspname': this._formBuilder.control(field.dspname),
                                    'columnName': this._formBuilder.control(field.columnName),
                                    'dataType': this._formBuilder.control(field.dataType),
                                    'masterId': this._formBuilder.control(field.masterId),
                                    'value': this._formBuilder.control(''),
                                    'id': this._formBuilder.control(''),
                                    'labelName': this._formBuilder.control(field.labelName),
                                    'maxlenth': this._formBuilder.control(field.maxlenth),
                                    'compulsary': this._formBuilder.control(field.compulsary)
                                }
                            );
                            this.formGroups.push(formGroup);
                        }
                        this.formGroup = new FormGroup({
                            'extended': this.formGroups
                        });

                        console.log(this.formGroups);
                    }, error => {
                        console.log(error);
                    });
            }
        }
    }

    get extendsForms() {
        return this.formGroup.get('extended') as FormArray;
    }

    onSelectDrop(value) {
    }

    btnsave() {
        this.fiel = this.formGroup.get('extended').value;
        for (let field of this.fiel) {
            if (field.compulsary == true) {
                if (field.value == "") {
                    this.notifyService.showRroor(field.dspname + " is compulsary", "oops");
                    return;
                }
            }
        }
        this.model.extended = this.formGroup.get('extended').value;
        this.model.masterId = this.user.trnid
        this.model.pagename = this.page
        this.model.rowid = this.rowId
        this.Masterservice.saveMaster(this.model).subscribe(data => {
            this.notifyService.showSuccess("Saved successfully", " Done");
            this.router.navigate(['/' + this.page]);
        }, error => console.log(error));
    }

    cancle() {
        this.router.navigate(['/' + this.page]);
    }
}