import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { DefineAllowanceModel } from './DefineAllowanceModel';

@Component({
    selector: 'app-AMCd',
    //  moduleId: module.id,
    templateUrl: 'DefineAllowance.html'
})
export class DefineAllowance implements OnInit,OnDestroy {

    user = new User();
    model = new DefineAllowanceModel();
    id: number;
    sub: any;
    unitttlst: DropDownModel[] = [];
    allowmodellst: DefineAllowanceModel[] = [];
    pagename: string;
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private alertService: AlertService, private notifyService: NotificationService, private router: Router, private location: Location, ) {
    }

    public frequencylst: DropDownModel[] = [
        { id: 2, "name": "Daily" },
        { id: 3, "name": "Weekly" },
        { id: 4, "name": "Fortnightly" },
        { id: 5, "name": "Monthly" },
        { id: 6, "name": "Bi-Monthly" },
        { id: 7, "name": "Quarterly" },
        { id: 8, "name": "Six-Monthly" },
        { id: 9, "name": "Yearly" },
        { id: 10, "name": "Actual" },

    ];

    public typelst: DropDownModel[] = [
        { id: 2, "name": "Fixed" },
        { id: 3, "name": "At Actuals" },
        { id: 4, "name": "Per Unit" },
    ];
    public calculatelst: DropDownModel[] = [
        { id: 2, "name": "Auto" },
        { id: 3, "name": "At claim" },
    ];
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }

    ngOnInit(): void {
        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.Masterservice.getUnit(this.user)
            .subscribe(value => {
                this.unitttlst = value
            });
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getallowanceByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;

                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('label2'));
            l2.className = "active";


        }
    }

    onSelectUnit(value) {
        if(value==0){
            this.notifyService.showRroor("Select Unit!!", "Oops!");
            return; 
        }
        this.model.unitid = value;

    }
    onSelectFrequency(value) {
        if(value==0){
            this.notifyService.showRroor("Select Frequency!!", "Oops!");
            return; 
        }
        this.model.frequency = value;

    }
    onSelectType(value) {
        if(value==0){
            this.notifyService.showRroor("Select Type!!", "Oops!");
            return; 
        }
   
        this.model.type = value;

    }
    onSelectCalculate(value) {
        if(value==0){
            this.notifyService.showRroor("Select Calculate!!", "Oops!");
            return; 
        }
        this.model.calculate = value;
    }
    btnsave($event) {

        if (this.model.code == null || this.model.code == "") {
            this.notifyService.showRroor("Enter Allowance  Code !!", "Oops!");
            return;
        }
        if (this.model.name == null || this.model.name == "") {
            this.notifyService.showRroor("Enter  Allowance Name !!", "Oops!");
            return;
        }
        if (this.model.frequency == null || this.model.frequency == "") {
            this.notifyService.showRroor("Select  Frequency !!", "Oops!");
            return;
        }
        if (this.model.type == null || this.model.type == "") {
            this.notifyService.showRroor("Select  Type !!", "Oops!");
            return;
        }
        if (this.model.calculate == null || this.model.calculate == "") {
            this.notifyService.showRroor("Select  Calculate !!", "Oops!");
            return;
        }

        this.Masterservice.saveallowance(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success ")
                        this.router.navigate(['Allowance']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    cancle() {
        this.router.navigate(["Allowance"]);
    }
}
