import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AlertService, DealersService, MasterServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { Location, } from '@angular/common';

@Component({
    selector: 'app-order',
    templateUrl: 'Payment.html'
})
export class Payment implements OnInit {

    constructor(private Masterservice: MasterServices, private datePipe: DatePipe, private dealersService: DealersService, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }

    ngOnInit(): void {

    }

}