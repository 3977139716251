export class OpportunityReportModel{
    id: number;
    frmdate: Date;
    todate: Date;

    regionid: number;
    areaid: number;
   // branchid: number;
    resourceID: number;

    name: String;//region
    areaname: string;
    branchname: string;
    resourcename: string;
    
    areaintlst: number[];
    resourceintlst: number[];
    branchintlst: number[];
    cityintlst: number[];
    teamintlst: number[];
    txtselect: string;
    searchtype: string;

    companyname:string;
    companyaddress:string;
    
    opportunityName	:string;
    customerName:string;
    owner:string;
    currentStage:string;
    status:string;
    date:Date;
    cityid:number;
    teamid:number;
    userid:number;
    
}