<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Executive Wise Quotation Detail Report</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>

        <div class="col l12 m12 s12 registerBgShadow" style="padding-left: 18px;padding-right: 18px;">
            <div class="row" id="LibDocs">
                <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                    <div class="modal-content " style="text-align:center;padding: 0px;margin-bottom: 10px;">
                        <!-- <span style="font-size: 20px;">Engineer Wise Quotation Report</span> -->
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>
                            </div>
                        </div>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 16px">{{companyaddress}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <span style="font-size: 20px;text-decoration: underline">Executive Wise Quotation Detail Report</span>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                        <div class="col l12 m12 s12" style="padding-right: 0px;">
                            <div class="input-field col l12 m12 s12"
                                style="text-align: end;padding-right: 0px;margin: 0px;">
                                <span><img src="../../../assets/images/printer.png" (click)="openPrint()"
                                        data-toggle="tooltip" data-placement="top" title="Print"
                                        width="30px" /></span>&nbsp;&nbsp;
                                <span><img src="../../../assets/images/excel2.png" onclick="tableToExcel('excel')"
                                        data-toggle="tooltip" data-placement="top" title="Excel" width="30px" /></span>
                            </div>
                        </div>
                        <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7">
                                <tbody>
                                    <tr>

                                        <td width="30%" style="text-align: left;border: 1px solid black;">
                                            <span>Region:-</span>
                                            &nbsp;<span><b>{{regionName}}</b></span>
                                        </td>
                                        <td width="30%" style="text-align: left;border: 1px solid black;">
                                            <span>Area:-</span>
                                            &nbsp;<span><b>{{areaName}}</b></span>
                                        </td>
                                        <td width="30%" style="text-align: left;border: 1px solid black;">
                                            <span>Branch:-</span>
                                            &nbsp;<span><b>{{branchName}}</b></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%" style="text-align: left;border: 1px solid black;">
                                            <span>Status:-</span>
                                            &nbsp;<span><b>{{status}}</b></span>
                                        </td>
                                        <td width="30%" style="text-align: left;border: 1px solid black;"> <span>From
                                                Date:-</span>
                                            &nbsp;<span><b>{{model.frmdate |
                                                    date:"dd-MM-yyyy"}}</b></span></td>
                                        <td width="30%" style="text-align: left;border: 1px solid black;"><span>To
                                                Date:-</span>
                                            &nbsp;<span><b>{{model.todate |
                                                    date:"dd-MM-yyyy"}}</b></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col l12 m12 s12" style="padding: 0px;">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                                id="excel">
                                <thead style="background: gainsboro;">
                                    <!-- <th width="5%" style="text-align: left;padding-left: 10px;">Sr. #</th>
                                    <th width="8%" style="text-align: left">Date</th>
                                    <th width="18%" style="text-align: left">Enquiry #</th>
                                    <th width="20%" style="text-align: left">Customer Name</th>
                                    <th width="15%" style="text-align: left">Executive Name</th>
                                    <th width="24%" style="text-align: left">Remark</th>
                                    <th width="10%" style="text-align: right">Value(&#8377;)</th> -->
                                </thead>
                                <tbody *ngFor="let lst of eqrlst">
                                    <th width="5%" style="text-align: left;padding-left: 10px;background: gainsboro;">Sr. #</th>
                                    <th width="8%" style="text-align: left;background: gainsboro;">Date</th>
                                    <!-- <th width="18%" style="text-align: left;background: gainsboro;">Enquiry #</th> -->
                                    <th width="10%" style="text-align: left;background: gainsboro;">Customer Name</th>
                                    <th width="10%" style="text-align: left;background: gainsboro;">Address</th>
                                    <th width="15%" style="text-align: left;background: gainsboro;">Executive Name</th>
                                    <th width="10%" style="text-align: left;background: gainsboro;">Remark</th>
                                    <th width="10%" style="text-align: left;background: gainsboro;">Mobile</th>
                                    <th width="10%" style="text-align: left;background: gainsboro;">Status</th>
                                    <th width="10%" style="text-align: left;background: gainsboro;">Discount</th>
                                    <th width="10%" style="text-align: right;background: gainsboro;">Amount</th>
                                    <tr>
                                        <td style="text-align: left">{{lst.srno}}</td>
                                        <td style="text-align: left;padding-left: 10px;">{{lst.startDate |
                                            date:"dd-MM-yyyy"}}</td>
                                        <!-- <td style="text-align: left">{{lst.enquiry}}</td> -->
                                        <td style="text-align: left">{{lst.customername}}</td>
                                        <td style="text-align: left">{{lst.address}}</td>
                                        <td style="text-align: left">{{lst.resourcename}}</td>
                                        <td style="text-align: left">{{lst.remark}}</td>
                                        <td style="text-align: left">{{lst.mobile}}</td>
                                        <td style="text-align: left">{{lst.status}}</td>
                                        <td style="text-align: left">{{lst.discount}}</td>
                                        <td style="text-align: right;padding-right: 10px;">&#8377; {{lst.quotationValue |
                                            number:'1.2-2'}}</td>

                                    </tr>
                                    <tr>
                                        <td style="width: 100%;padding-left: 10%;padding-top: 0px;" colspan="7">
                                            <table border="1px solid black;" style="width: 100%;">
                                                <!-- <thead style="background: gainsboro;"> -->
                                                    <!-- <th width="14%" style="text-align: right"></th>
                                                    <th width="6%" style="text-align: left;padding-left: 10px;">Sr.#
                                                    </th>
                                                    <th width="25%" style="text-align: left;">Product</th>
                                                    <th width="10%" style="text-align: right">Qty.</th>
                                                    <th width="15%" style="text-align: right">Rate</th>
                                                    <th width="20%" style="text-align: right">Amount(&#8377;)</th> -->
                                                <!-- </thead> -->
                                                <tbody>
                                                    <tr *ngFor="let lst2 of lst.eqrldtlst"> 
                                                        <!-- <td width="14%" style="text-align: left"></td> -->
                                                        <td width="6%" style="text-align: left;">{{lst2.srno}}</td>
                                                        <td width="38%" style="text-align: left;">{{lst2.productname}}</td>
                                                        <td width="10%" style="text-align: right;">{{lst2.quantity+"&nbsp;&nbsp;"+lst2.uom}}</td>
                                                        <td width="15%" style="text-align: right;">&#8377; {{lst2.rate | number:'1.2-2'}}</td>
                                                        <td width="15%" style="text-align: right;">&#8377; {{lst2.amount | number:'1.2-2'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col l12 m12 s12" style="text-align:left;padding-left:10%;padding-top:3%;">
                            <span style="font-size: 16px;">Product Summary</span>
                        </div>
                        <div class="col l12 m12 s12" style="padding-left:10%;padding-top:9px;">
                            <table border="1px solid black;"
                                style="width: 90%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                                id="excel">
                                <thead style="background: gainsboro;">
                                    <th width="60%" style="text-align: left;padding-left: 10px;">Product Name</th>
                                    <th width="15%" style="text-align: center;">Quantity</th>
                                    <th width="20%" style="text-align: center;">Amount</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of productSummarylst ">
                                        <td style="text-align: left">{{lst.productname}}</td>
                                        <td style="text-align: right">{{lst.count}}</td>
                                        <td style="text-align: right">&#8377; {{lst.finalamount | number:'1.2-2'}}</td>
                                    </tr>
                                    <tr style="background: ghostwhite;">
                                        <td style="text-align: left;font-weight: bold;">Total</td>
                                        <td style="text-align: right;font-weight: bold;">{{totalcount}}</td>
                                        <td style="text-align: right;font-weight: bold;">&#8377; {{totalfinalamount | number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div class="col s12 m12 l12" style="text-align: center;margin-top: 20px;">
                            <span style="font-size: 11px;">Report Genrated By: {{username}} on {{timing
                                |date:'medium'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="padding-right: 0px;">
                    <div class="input-field col l12 m12 s12"
                        style="text-align: end;padding-right: 0px;margin-top: 0px;margin-bottom: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="btnback()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>