import { OnInit, Component } from '@angular/core';
import { AuthenticationService, MasterServices, TransactionServices } from 'src/app/_services';
import { BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { PaymentScreenModel, SpareModel } from './PaymentScreenModel';
import { QuotationProductModel } from '../Quotation/QuotationModel';
import { PublicScreenModel } from 'src/app/_models/PublicScreenModel';
import { PublicServices } from 'src/app/_services/PublicServices';
import { OnDestroy } from '@angular/core';
var converter = require('number-to-words');
import convertor from 'rupees-to-words';
@Component({
    selector: 'app-PaymentScreen',
    templateUrl: 'PaymentScreen.html',
})
export class PaymentScreen implements OnInit, OnDestroy {
    loginsucess: boolean;
    constructor(private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private publicServices: PublicServices,
        private transactionservice: TransactionServices) {
    }
    user = new User();
    model = new PaymentScreenModel();
    productEnquirylst: QuotationProductModel[];
    serviceQuotationlst: QuotationProductModel[];
    sparelst: SpareModel[] = []
    hashkey: string;
    formtype: string;
    formname: string;
    publicModel = new PublicScreenModel();
    id: number;
    amountInWords: string;
    offeredPrice: number;
    totalamount: number;
    taxamount: number;
    finalamount: number;
    rateWithGst: number;
    discountAmt: number;
    gstamount: number;
    singleGst: number;
    ngOnInit() {

        this.route.queryParams.subscribe(params => {
            this.hashkey = params["hashkey"];
        });
        this.loginsucess = true;
        this.authenticationService.logisLoginValueset();
        this.user.userid = Number(localStorage.getItem("userid"));
        this.model.userid = Number(localStorage.getItem("userid"));

        //HARD CODED
        // this.id = 1;
        // this.formtype = "Quotation";
        // this.formtype = "Service";
        // alert( this.hashkey)
        this.publicModel.hashkey = this.hashkey;
        this.publicServices.checkpublicsite(this.publicModel)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.formtype = data.transactiontype;
                        this.formname = data.transactiontype;
                        if (data.transactiontype == "INSInvoice") {
                            this.formname = "Invoice";
                        }
                        this.id = data.refrenceID;
                        this.user.message = data.dbname;
                        this.user.sendstring = data.companycode;
                        this.model.companyname = data.companyname;
                        this.model.gstno = data.gstno;
                        this.model.companyaddress = data.companyaddress;
                        localStorage.setItem("dbName", data.dbname);
                        this.GetOrderDetaillogouts();
                    }
                });
        //  this.GetOrderDetails();
    }
    ngOnDestroy() {
        this.authenticationService.logout()
    }
    GetOrderDetaillogouts() {
        if (this.formtype == "Sales Quotation") {
            //Retailer Quotation Id
            this.user.trnid = this.id;

            this.model.chkterms = false;
            this.publicServices.getQuotationInfoForRetailerApprove(this.user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.companyname = data.companyname;
                        this.model.companyaddress = data.companyaddress;
                        this.model.datetime = new Date();
                        this.model.chkterms = false
                        this.productEnquirylst = data.productEnquirylst;
                        this.totalamount = 0;
                        this.discountAmt = 0;
                        for (let i = 0; i < this.productEnquirylst.length; i++) {
                            if (this.model.gstseperate == false) {
                                this.productEnquirylst[i].offeredPrice = this.productEnquirylst[i].totalproamount / this.productEnquirylst[i].quantity;
                            } else if (this.model.gstseperate == true) {
                                this.productEnquirylst[i].offeredPrice = this.productEnquirylst[i].offeredPrice;
                            }
                            this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                            this.discountAmt = this.discountAmt + this.productEnquirylst[i].discountAmount;

                            // this.amountInWords = converter.toWords(this.totalamount);
                            this.amountInWords = convertor(Math.round(this.totalamount).toFixed(2));

                        }
                    });

        } else if (this.formtype == "Service Quotation") {
            //Retailer Quotation Id
            this.user.trnid = this.id
            this.model.chkterms = false
            this.publicServices.getServiceQuotationInfo(this.user)
                .subscribe(
                    data => {
                        this.model = data
                        this.model.companyname = data.companyname;
                        this.model.datetime = new Date();
                        this.model.chkterms = false
                        this.serviceQuotationlst = data.serviceQuotationlst;
                        this.totalamount = 0;
                        this.discountAmt = 0;
                        this.taxamount = 0;
                        this.finalamount = 0;
                        this.rateWithGst = 0;
                        this.singleGst = 0;
                        for (let i = 0; i < this.serviceQuotationlst.length; i++) {
                            this.singleGst = this.serviceQuotationlst[i].listprice * this.serviceQuotationlst[i].taxpercent / 100; //newadd
                            this.serviceQuotationlst[i].rateWithGst = this.serviceQuotationlst[i].listprice + this.singleGst; //newadd
                            this.totalamount = this.totalamount + (this.serviceQuotationlst[i].listprice * this.serviceQuotationlst[i].quantity);
                            this.taxamount = Math.round(this.taxamount + this.serviceQuotationlst[i].taxamount);
                            this.finalamount = this.finalamount + this.serviceQuotationlst[i].amount;
                            this.discountAmt = this.discountAmt + this.serviceQuotationlst[i].discountAmount;
                            this.amountInWords = convertor(Math.round(this.finalamount).toFixed(2));
                        }
                    });
        } else if (this.formtype == "Invoice") {
            //Spare Invoice Id
            this.user.trnid = this.id
            this.model.chkterms = false
            this.transactionservice.getServiceInvoiceDetails(this.user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.companyname = data.companyname;
                        this.model.companyaddress = data.companyaddress;
                        this.model.telephone = data.telephone;
                        this.model.datetime = new Date();
                        this.sparelst = data.sparelst;
                        this.totalamount = 0;
                        this.gstamount = 0;
                        this.taxamount = 0;
                        this.finalamount = 0;
                        this.rateWithGst = 0;
                        this.singleGst = 0;
                        for (let i = 0; i < this.sparelst.length; i++) {

                            this.singleGst = this.sparelst[i].rate * this.sparelst[i].gstpersentage / 100; //newadd
                            this.sparelst[i].rateWithGst = this.sparelst[i].rate + this.singleGst; //newadd
                            // alert(this.sparelst[i].rateWithGst)
                            // this.sparelst[i].amount = this.sparelst[i].rateWithGst * this.sparelst[i].quantity //newadd
                            // alert(this.sparelst[i].amount)
                            this.sparelst[i].amount = Math.round(this.sparelst[i].amount);
                            this.totalamount = this.totalamount + this.sparelst[i].amount;
                            this.totalamount = Math.round(this.totalamount);
                            // this.taxamount = this.taxamount + this.sparelst[i].gstamount;
                            this.finalamount = this.totalamount + this.taxamount;

                            this.amountInWords = convertor(Math.round(this.finalamount).toFixed(2));
                        }
                    });
        } else if (this.formtype == "INSInvoice") {
            this.user.trnid = this.id
            this.model.chkterms = false
            this.transactionservice.getINSInvoiceDetails(this.user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.datetime = new Date();
                        this.sparelst = data.sparelst;

                        this.totalamount = 0;
                        this.gstamount = 0;
                        this.taxamount = 0;
                        this.finalamount = 0;
                        this.rateWithGst = 0;
                        this.singleGst = 0;
                        for (let i = 0; i < this.sparelst.length; i++) {

                            if (this.sparelst[i].ratewithDiscount != null) {
                                this.singleGst = this.sparelst[i].ratewithDiscount * this.sparelst[i].gstpersentage / 100; //newadd
                                this.sparelst[i].rateWithGst = this.sparelst[i].ratewithDiscount + this.singleGst; //newadd
                            } else {
                                this.singleGst = this.sparelst[i].rate * this.sparelst[i].gstpersentage / 100; //newadd
                                this.sparelst[i].rateWithGst = this.sparelst[i].rate + this.singleGst; //newadd
                            }

                            this.sparelst[i].amount = this.sparelst[i].rateWithGst * this.sparelst[i].quantity //newadd
                            this.sparelst[i].amount = Math.round(this.sparelst[i].amount)
                            //alert(convertor(Math.round(this.sparelst[i].amount).toFixed(2)));
                            // alert(Math.round(this.sparelst[i].amount))
                            this.totalamount = this.totalamount + this.sparelst[i].amount;
                            // this.taxamount = this.taxamount + this.sparelst[i].gstamount;
                            this.finalamount = this.totalamount;
                            this.amountInWords = convertor(Math.round(this.finalamount).toFixed(2));
                        }

                    });
        }
    }
    // SubmitcashCode(){
    //     this.publicServices.submitcashCode(this.model)
    //     .subscribe(
    //         data => {
    //             this.notifyService.showSuccess("Cash Code Submitted", "Oops!");
    //     });
    // }
    makePayment() {

    }
    pay() {

    }
    approveQuot() {
        if (this.formtype == "Sales Quotation") {
            this.publicServices.approveRetailerQuotation(this.model)
                .subscribe(
                    data => {
                        if (data.approvalCode != null) {
                            this.model.approvalCode = data.approvalCode
                            this.notifyService.showSuccess("Order accepted", "Success");
                            // this.router.navigate(['/Opportunities'])
                        }
                    });
        }
        else if (this.formtype == "Service Quotation") {
            this.publicServices.approveServiceQuotation(this.model)
                .subscribe(
                    data => {
                        if (data.approvalCode != null) {
                            this.model.approvalCode = data.approvalCode
                            this.notifyService.showSuccess("Order accepted", "Success");
                        }
                    });
        }
    }

    update() {

    }
    cancel() {
        this.router.navigate(['/Platform']);
    }

}