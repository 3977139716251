import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router/';
import { BehaviorSubject } from 'rxjs';
import { AlertService, AuthenticationService, MasterServices } from '../../_services';
import { User } from '../../_models/user';
import { NotificationService } from 'src/app/_services/notification.service';
import { Gstheadermastermodel } from './Gstheadermastermodel';
@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'Gstheadermaster.html'
})
export class Gstheadermaster implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private Masterservice: MasterServices,
        private alertService: AlertService,
        private notifyService: NotificationService) { }
    str: string;
    p: any;
    id: number;
    public i: any = 0;
    public userid: any = 0;
    // model: any = {};
    model = new Gstheadermastermodel();
    user = new User();
    private sub: any;


    ngOnInit() {
        this.model.userid = Number(localStorage.getItem("userid"));
        // $(document).ready(function() {
        //     $(".only-numeric").bind("keypress", function (e) {
        //         var keyCode = e.which ? e.which : e.keyCode

        //         if (!(keyCode >= 48 && keyCode <= 57)) {
        //           $(".error").css("display", "inline");
        //           return false;
        //         }else{
        //           $(".error").css("display", "none");
        //         }
        //     });
        //   });
        this.getbyid();
    }
    getbyid() {
        this.id = + this.route['id'];
        this.sub = this.route.params.subscribe(params => {
            this.id = + params['id'];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getgsthdrbyid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
        }
    }
    cancle() {
        this.router.navigate(['Tax Group']);
    }
    btnsave() {
        // this.model.userid = localStorage.getItem('userid');
        if (this.model.name == null || this.model.name == "") {
            this.notifyService.showRroor("Please Enter Name !!", " Oops!");
            return;
        }
        if (this.model.persentage == null) {
            this.notifyService.showRroor("Please Enter Value !!", " Oops!");
            return;
        }
        this.Masterservice.savegsthdr(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success ")
                        this.router.navigate(['Tax Group']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
}