import { OnInit, Component } from '@angular/core';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { InventoryServices } from 'src/app/_services/InventoryServices';
import { User } from 'src/app/_models';
import { DefinePurchaseReturnModel, ReturnModel } from './DefinePurchaseReturnModel';
declare var $: any;
@Component({
    selector: 'app-DefinePurchaseReturn',
    templateUrl: 'DefinePurchaseReturn.html',
})
export class DefinePurchaseReturn implements OnInit {
    user = new User();
    model = new DefinePurchaseReturnModel();

    products: ReturnModel[] = []
    userList2: any[];
    lastkeydown2: number = 0;
    ngOnInit(): void {

    }

    constructor(private Masterservice: MasterServices,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private inventoryServ: InventoryServices) {
    }

    getUserIdsSecondtWay($event) {
        let userId = (<HTMLInputElement>document.getElementById('dynamicUserIdsSecondWay')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        console.log('VALU ' + userId);
        var obj = this;
        if (userId.length >= 3) {
            if ($event.timeStamp - this.lastkeydown2 > 200) {
                this.inventoryServ.getSupplierNameForReturn(this.user)
                    .subscribe(data => {
                        data.forEach((item) => this.userList2.push({ "text": item.name, "val": item.id }));
                        let map = new Map();
                        var names = "";
                        for (let i = 0; i < this.userList2.length; i++) {
                            map.set(this.userList2[i].text, this.userList2[i].val)
                            searchDataObj[this.userList2[i].text] = null;
                            if (i == 0) {
                                names = this.userList2[i].text;
                            } else {
                                names = names + "," + this.userList2[i].text;
                            }
                        }
                        let customername;
                        $(document).ready(function () {
                            $('input.typeahead').autocomplete({
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect1(map.get(txt), txt);
                                },
                            });
                            $('input.autocomplete').autocomplete({
                                data: searchDataObj,
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect1(map.get(txt), txt);
                                },
                            });
                        });
                    },
                        () => {
                            alert("fail");
                        });
            }
        }
    }


    calculate() {
        for (let i = 0; i < this.products.length; i++) {
            for (let j = 0; j < this.products[i].inventorylst.length; j++) {
                if(this.products[i].inventorylst[j].rejectqnty > this.products[i].recvqnty)
                {
                    this.notifyService.showRroor("Return quantity is not greater than received quantity", "Oops!");
                    this.products[i].inventorylst[j].rejectqnty = 0
                    return;
                }

            }
        }   
    }

    typeaheadOnSelect1(val, val2) {
        this.user.trnid = val;
        this.model.suplierid = val;
        this.model.suppliername = val2
        this.inventoryServ.getAllItemsOfSupplier(this.user)
            .subscribe(data => {
                this.products = data.returnproductlst
            });
    }

    savePurchaseReturn($event) {

    }

    btnback() {
        this.router.navigate(['Purchse Return Note'])
    }
}