import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { SearchSourceModel } from './SearchSourceModel';

@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'SearchSource.html'
})

export class SearchSource implements OnInit {
    user = new User();
    model = new SearchSourceModel();
    p: any;
    public i;
    pagename: string;
    camplst: SearchSourceModel[] = [];
    camplst1: SearchSourceModel[] = [];
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
        this.pagename=this.router.url
    }
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Code" },
        { id: 3, "name": "Name" }
    ];

    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        localStorage.setItem("pagename",this.pagename);  
        //this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.getAllSource();

    }
    getAllSource() {
        this.camplst = []
        this.Masterservice.getAllSource(this.user)
            .subscribe(value => {
                this.camplst = value
                this.camplst1 = [];
                value.forEach((item) => this.camplst1.push({ "id": item.id, "campaignName": item.campaignName, "campaignCode": item.campaignCode, "txtserch": item.txtserch, "txtselect": item.txtselect, "chkselect": item.chkselect ,"name":item.name,"userid":item.userid}));
                this.camplst = this.camplst1;
            });
    }

    btnedit(id) {
        this.router.navigate(['/editSource', id]);
    }
    btnnew() {
        this.router.navigate(['/DefineSource']);
    }
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.Masterservice.deleteSource(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }
      
    }
    onSelectSource(value) {
        this.model.txtselect = value;
    }

    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllSource()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Code") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.camplst1 = [];
                this.camplst.forEach((item) => {
                    if ((item.campaignCode.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.camplst1.push({ "id": item.id, "campaignName": item.campaignName, "campaignCode": item.campaignCode, "txtserch": item.txtserch, "txtselect": item.txtselect, "chkselect": item.chkselect ,"name":item.name,"userid":item.userid});
                    }
                });
                this.camplst = this.camplst1;
            }
        }
        else if (this.model.txtselect == "Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.camplst1 = [];
                this.camplst.forEach((item) => {
                    if ((item.campaignName.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.camplst1.push({ "id": item.id, "campaignName": item.campaignName, "campaignCode": item.campaignCode, "txtserch": item.txtserch, "txtselect": item.txtselect, "chkselect": item.chkselect ,"name":item.name,"userid":item.userid});
                    }
                });
                this.camplst = this.camplst1;
            }
        }

    }
    openmodule(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
            }
        };
        this.router.navigate(['SourceDetail'], navigationExtras);
    }

}