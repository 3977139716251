

export class MasterHomePageModel {
    public txtserch: string;
    txtselect:string;
    tableName: string;
    hdrlst: Header[];
    masterId: number;
    pageName:string;
    homepagedatalast:MasterList[];
    searchlst:SearchModel[];
}
export interface Header {
    headerTbname: string;
    headerDspname: string;
    primaryId: string;
}
export interface MasterList {
    srno:number;
    id: number;
    column1: string;
    column2: string;
    column3: string;
    column4: string;
    column5: string;
}
export interface SearchModel{
    id:number;
    name:string;
    colname:string;
}