export class LocalityModel {
    id: number;
    txtserch: string;
    txtselect: string;
    code: string;
    name: string;
    locality: string;
    userid: number;
    locale: string;
    countryid: number;
    stateid: number;
    city:string;
    state:string;
    country:string;
    cityid: number;
    localeid: number;
    pincode: string;
    srno: number;
    totalItems:number;
    locmodlst:LocalityModel[]=[];
    localitylst:LocalityModel[]=[];
    localitylst1:LocalityModel[]=[];

}