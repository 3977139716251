
    <app-header></app-header>
    <div class="container" style="width: 80%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Quotation Dashboard</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="row" style="margin-top: 2%;padding-left: 5px;padding-right: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <!-- <div class="col l6 m5 s6">
                        <span style="font-size: 15px;font-weight: bold">Total
                            Opportunities:&nbsp;<span>{{opportunitieslst.length}}</span></span>
                        </div> -->
                        <div class="row" style="padding-left: 10px;">
                            <!-- <div class="col l1"></div> -->
                            <div class="input-field col l3 m3 s3" style="margin-top: 14px;margin-bottom:0px;padding-left: 5px;">
                                <span>Search Criteria</span>
                                <select (change)="onSelectSrch($event.target.value)">
                                    <option *ngFor="let type of searchtype" [value]="type.name">
                                        {{type.name}}</option>
                                </select>
                            </div>
                            <div class="input-field col l3 m3 s3" style="margin-top: 30px;margin-bottom:0px;">
                                <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                                    data-toggle="tooltip" data-placement="top" title="Search Text">
                                <label for="search">Search Text</label>
                            </div>
            
                            <div class="input-field col l1 m1 s1" style="text-align: end;margin-top: 30px;margin-bottom:0px;">
                                <button type="button" (click)="btnsearch($event)"
                                    class="waves-effect waves-light btn">Search</button>
            
                            </div>
                            <div class="col l2 m2 s2">
            
                            </div>
                            <div class="col l1"></div>
                        </div>
                </div>
               
                <div class="row" style="padding: 1%">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="3%" style="text-align: left;">Sr. No.</th>
                            <th width="10%" style="text-align: left;">Quotation Number</th>
                            <!-- <th width="9%" style="text-align: left">Date</th> -->
                            <th width="10%" style="text-align: left">Customer Name</th>
                            <th width="8%" style="text-align: left">Enquiry Name</th>
                            <th width="8%" style="text-align: left">Owner</th>
                            <th width="5%" style="text-align: left">Stage</th>
                            <th width="3%" style="text-align: left">Decision</th>
                            <th width="6%" style="text-align: center">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of quotationdash | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: left;">{{lst.srno}}</td>
                                <td style="text-align: left;">{{lst.quoCode}}</td>
                                <!-- <td style="text-align: left">{{lst.dCRDate | date:"dd-MM-yyyy"}}</td> -->
                                <td style="text-align: left">{{lst.customerName}}</td>
                                <td style="text-align: left">{{lst.enquiryName}}</td>
                                <td style="text-align: left">{{lst.ownernameofOppor}}</td>
                                <td style="text-align: left">{{lst.quostage}}</td>
                                <td style="text-align: left">{{lst.status}}</td>
                               
                                <td style="text-align: center">
                                    <!-- <span *ngIf="lst.status=='Open'"><img
                                            src="../../../assets/images/DCR-Entry.png" width="25px"
                                            (click)="btnDCR(lst.id)" title="DCR" /></span> -->
                                    <span *ngIf="lst.status=='Open'"><img src="../../../assets/images/edit.png"
                                            width="25px" title="Edit Create Lead" (click)="btnedit(lst.quoid)" /></span>
                                    <!-- <span *ngIf="lst.status=='Won'"><img src="../../../assets/images/view.png"
                                            (click)="btnView(lst.id)" data-toggle="tooltip" data-placement="top"
                                            title="View DCR" width="25px" /></span> -->
                                    <span *ngIf="lst.status=='Won'"><img src="../../../assets/images/file-preview.png"
                                            (click)="btnprint(QuotationReport,lst.quoid)" data-toggle="tooltip"
                                            data-placement="top" title="View Quotation" width="25px" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
        </div>
        <ng-template #QuotationReport>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right ">
                        <button (click)="modalRef.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                    </div>
                </div>
            </div>
            <div class="modal-content registerBgShadow" id="inv">
                <h5 style="text-align: center;">Quotation</h5>
                <div class="col l12 m12 s12" style="border: 1px solid black;">
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{quoReport.companyname}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{quoReport.companyaddress}}</span>
                        </div>
                    </div>
    
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12">
    
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                                <tbody>
                                    <tr>
                                        <td width="50%">Quotation No:&nbsp;{{quoReport.quotationCode}}</td>
                                        <td width="50%">Quotation Date:&nbsp;{{quoReport.quoDate | date:"dd-MM-yyyy"}}</td>
                                    </tr>
                                    <tr>
                                        <td width="50%">Contact Person:&nbsp;{{quoReport.contactPerson}}</td>
                                        <td width="50%">Payment Terms:&nbsp;{{quoReport.paymentTerm}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" style="margin:0px;">
                        <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px;"
                            cellpadding="7">
                            <thead>
                                <th style="text-align: left;padding-left: 10px;padding-right: 0px;">Sr.No.</th>
                                <th style="text-align: left">Description of Goods</th>
                                <th style="text-align: left">HSN</th>
                                <th style="text-align: right">Quantity</th>
                                <th style="text-align: right">Rate</th>
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th>
                                <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th> -->
                                <th style="text-align: right">UOM</th>
                                <!-- <th style="text-align: right">Discount</th> -->
                                <th style="text-align: right;padding-right: 10px;padding-left: 0px">Total Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of qutReportlst">
                                    <td style="text-align: center;">{{lst.srno}}</td>
                                    <td style="text-align: left">{{lst.name}}</td>
                                    <td style="text-align: left">{{lst.hsn}}</td>
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: right" *ngIf="lst.ratewithdiscount == null">{{lst.amount |
                                        number:'1.2-2'}}</td>
                                    <td style="text-align: right" *ngIf="lst.ratewithdiscount != null">
                                        {{lst.ratewithdiscount | number:'1.2-2'}}</td>
                                    <!-- <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.netamount |
                                        number:'1.2-2'}}</td>
                                    <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.taxamount |
                                        number:'1.2-2'}}</td> -->
                                    <td style="text-align: right">{{lst.uom}}</td>
                                    <!-- <td style="text-align: right">{{lst.discount | number:'1.2-2'}}</td> -->
                                    <td style="text-align: right;padding-right: 10px;padding-left: 0px">
                                        {{lst.totalproamount | number:'1.2-2'}}
    
                                    </td>
                                </tr>
                                <!-- <tr *ngIf="model.gstseperate==true">
                                    <td></td>
                                    <td style="text-align: right;">Net Amount</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px;">{{quoReport.totalnetamount |
                                        number:'1.2-2'}}</td>
                                </tr>
                                <tr *ngIf="model.gstseperate==true">
                                    <td></td>
                                    <td style="text-align: right;">Tax Amount</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px;">{{quoReport.totaltaxamount |
                                        number:'1.2-2'}}</td>
                                </tr> -->
                                <tr>
                                    <td></td>
                                    <td style="text-align: right">Total Amount</td>
                                    <td></td>
                                    <td></td>
                                    <!-- <td></td> -->
                                    <td *ngIf="model.gstseperate==true"></td>
                                    <td *ngIf="model.gstseperate==true"></td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px;">{{quoReport.totalamount |
                                        number:'1.2-2'}}</td>
    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="margin:0px;border:1px solid black">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{quoReport.totalinwords |
                                    uppercase}}&nbsp;ONLY</span></span>
    
                        </div>
                    </div>
                    <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                            <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                        </div>
                    </div>
    
                    <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                        style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reporttandclst">
                                <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                                <td style="text-align: left">{{lst.descrption}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    
            <div class="col l12 m12 s12">
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right ">
                        <button (click)="modalRef.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
   
