import { Component, OnInit } from '@angular/core';
import { MasterServices, AlertService, TransactionServices } from 'src/app/_services';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { SearchCompanyMasterModel } from './SearchCompanyMasterModel';
import { Location, DatePipe } from '@angular/common'
// import { usertwofieldModel } from './usertwofieldModel';

@Component({
    selector: 'app-login',
    templateUrl: 'SearchCompanyMaster.html'
})
export class SearchCompanyMaster implements OnInit {
    p:any;
    complst: SearchCompanyMasterModel[] = [];
    companylst1: SearchCompanyMasterModel[] = [];
    model: any = {};
    pagename: string;
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
        this.pagename=this.router.url
    }
    ngOnInit() {
        this.getAllCompany();
        this.model.userid = Number(localStorage.getItem("userid"));
        localStorage.setItem("pagename",this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }
    btnedit(id) {
        this.router.navigate(['/Definecompany', id]);
    }

    getAllCompany() {
        this.Masterservice.getAllCompany(this.model)
            .subscribe(value => {
                this.complst = [];
                this.companylst1 = [];
                value.forEach((item) => this.companylst1.push({ "id": item.id, "companyname": item.companyname, "companycode": item.companycode, "address": item.address, "tcname": item.tcname, "bcname": item.bcname }));
                this.complst = this.companylst1;
            })
    }
}