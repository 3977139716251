<app-header></app-header>
<div class="container" style="width:80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class=" input-field col s4" style=" margin-top: 20px;">
                            <input [(ngModel)]="model.stageCode" #stageCode="ngModel" type="text"
                                oninput="validity.valid||(value='');" id="first1" data-toggle="tooltip"
                                data-placement="top" title="Stage Code" ngModel maxlength="35"
                                style="margin-bottom: 0px;" required>
                            <label id="lable1" for="first1">Stage Code </label>
                            <div *ngIf="stageCode.invalid && (stageCode.dirty || stageCode.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="stageCode.errors.required">
                                    Stage Code Required
                                </div>
                                <div [hidden]="!stageCode.errors?.pattern">
                                    Invalid Stage Code
                                </div>
                            </div>
                        </div>
                        <div class=" input-field col s4" style=" margin-top: 20px;">
                            <input [(ngModel)]="model.description" #description="ngModel"
                                onkeypress="return /[A-Za-z_ ]/i.test(event.key)" type="text" id="first1"
                                data-toggle="tooltip" data-placement="top" title="Description" ngModel maxlength="35"
                                style="margin-bottom: 0px;" required>
                            <label id="lable2" for="first1">Description </label>
                            <div *ngIf="description.invalid && (description.dirty || description.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="description.errors.required">
                                    Description Required
                                </div>
                                <div [hidden]="!description.errors?.pattern">
                                    Invalid Description
                                </div>
                            </div>
                        </div>
                        <div class="col s2">
                        </div>
                    </div>



                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class=" input-field col s4" style=" margin-top: 9px;">
                            <input [(ngModel)]="model.percentProbability" #percentProbability="ngModel" type="number"
                                id="first1" data-toggle="tooltip"
                                data-placement="top" title="Probability" ngModel maxlength="35"
                                style="margin-bottom: 0px;text-align: right;" required>
                            <label id="lable3" for="first1">Probability </label>
                            <div *ngIf="percentProbability.invalid && (percentProbability.dirty || percentProbability.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="percentProbability.errors.required">
                                    Probability Required
                                </div>
                                <div [hidden]="!percentProbability.errors?.pattern">
                                    Invalid Probability
                                </div>
                            </div>
                        </div>
                        <div class=" input-field col s4" style=" margin-top: 9px;">
                            <input [(ngModel)]="model.hirarchy" #hirarchy="ngModel" type="number" min="0"
                                oninput="validity.valid||(value='');" id="first1" data-toggle="tooltip"
                                data-placement="top" title="Sequence" ngModel maxlength="35"
                                style="margin-bottom: 0px;text-align: right;" required>
                            <label id="lable4" for="first1">Sequence </label>
                            <div *ngIf="hirarchy.invalid && (hirarchy.dirty || hirarchy.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="hirarchy.errors.required">
                                    Sequence Required
                                </div>
                                <div [hidden]="!hirarchy.errors?.pattern">
                                    Invalid Sequence
                                </div>
                            </div>
                        </div>
                        <div class="col s2">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class="input-field col l2" style="margin-top: 9px;">
                            <input [(ngModel)]="model.chkselectF" #chkselectF="ngModel" type="checkbox"
                                style="text-align: 5px">&nbsp;&nbsp;&nbsp;&nbsp;
                            Funnel
                        </div>

                    </div>

                    <div class="row">
                        <div class="input-field col l11 m11 s11" style="text-align: right;margin-top: 0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                                style="margin-left: 5px;">Save</button>
                            <div class="col s1"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col l1"></div>
                        <div class="col l10 m10 s10" style="height: 500px; overflow: auto;">

                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="4%" style="text-align: left"></th>
                                    <th width="8%" style="text-align: left">Sequence</th>
                                    <th width="20%" style="text-align: left">Customer Type</th>
                                    <th width="30%" style="text-align: left">Activity</th>
                                    <th width="25%" style="text-align: left">Actions</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of modellst ">
                                        <td style="text-align: left;"><input type="checkbox"
                                                [(ngModel)]="lst.checkselect" #checkselect="ngModel"></td>
                                        <td style="text-align: right;"><input [(ngModel)]="lst.sequence"
                                                #sequence="ngModel" min="0" oninput="validity.valid||(value='');"
                                                type="number" id="sequence" data-toggle="tooltip" data-placement="top"
                                                placeholder="Sequence" style="text-align: right"></td>
                                        <span style="text-align:center;padding: 10px;"><td style="padding-top: 20px;" *ngFor="let prodlst of lst.customerTypelst">{{prodlst.customertypename}}</td></span>
                                        <td style="text-align: left">{{lst.taskName}}</td>
                                        <td class="input-field" style="text-align: left;">
                                            <select (change)=" onSelectAction($event.target.value, lst.tbtask)">
                                                <option>Select One</option>
                                                <option *ngFor="let type of searchtype" [value]="type.name"
                                                    [selected]="type.name ==lst.action">
                                                    {{type.name}}</option>
                                            </select>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="col l1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>