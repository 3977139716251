import { OnInit, Component, Input, ViewChild, HostBinding, ElementRef, Optional, Self } from '@angular/core';
import { ResourceRightsModel, AreaModel, LocaleModel } from './ResourceRightsModel';
import { MasterServices, CompanyService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ProductCategory, ProductResourceRightsModel, ResourceCType } from './ProductResourceRightsModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { ResourceApprovalsModel } from './ResourceRightsModel'
declare var $: any;
export class ItemList {
    constructor(public item: string, public selected?: boolean) {
        if (selected === undefined) selected = false;
    }
}
@Component({
    selector: 'app-rr',
    templateUrl: 'ResourceRights.html',
    providers: [{ provide: MatFormFieldControl, useExisting: ResourceRights }]
})
export class ResourceRights implements OnInit {
    newarr: string;
    constructor(@Optional() @Self() public ngControl: NgControl,
        private elRef: ElementRef<HTMLElement>,
        private fm: FocusMonitor,
        private Masterservice: MasterServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private companyService: CompanyService,
        private router: Router) {
        this.pagename = this.router.url
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }
    p: any;
    @ViewChild('inputTrigger', { read: MatAutocompleteTrigger }) inputTrigger: MatAutocompleteTrigger;
    private changeCallback: Function;
    private touchedCallback: Function;
    private changeCallbacks: Function;
    private touchedCallbacks: Function;
    stateChanges = new Subject<void>();
    selectedItems: ItemList[] = new Array<ItemList>();
    itemControl = new FormControl();
    @Input() autocomplete: any[];
    @Input() autocompletes: any[];
    @Input() set value(value: any) {
        if (value) {
            this.selectedItems = value;
        }
        this.stateChanges.next();
    }
    get value() {
        return this.selectedItems;
    }
    focused = false;
    autocompleteChoices: any;
    innerValue = '';
    lastFilter = '';
    filteredItems: ItemList[];
    filteredItemsofArea: ItemList[];
    allitemsItems: ItemList[];
    items = [];
    isAllSelected = false;

    isDisabled = false;
    user = new User();

    model = new ResourceRightsModel();
    model2 = new ProductResourceRightsModel();
    flagmodule = new CoustomerflagModel();

    approval = new ResourceApprovalsModel();

    pagename: string;
    resid: number;
    resname: string;
    regionlst: DropDownModel[] = [];
    citylst: AreaModel[] = [];
    arealst: AreaModel[] = [];
    newlocallst: LocaleModel[] = [];
    regionInt: number[] = [];
    savedLoc: number[] = [];
    unsvedloc: number[] = [];
    checkedloc: number[] = [];
    uncheckedloc: number[] = [];
    regionmap = new Map<string, number>();
    prodcatlst: ProductCategory[] = [];
    resourcectype: ResourceCType[] = [];

    marketingapprovals: number;
    quotationapprovals: number;

    approvallst: ResourceApprovalsModel[] = [];
    resourcelst: DropDownModel[] = [];


    ngOnInit(): void {

        // localStorage.setItem("pagename",this.pagename);  
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        var id = document.getElementById("defaulttab");
        id.click();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model2.userid = Number(localStorage.getItem("userid"));
        this.itemControl.valueChanges.pipe(
            startWith<string | ItemList[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
        ).subscribe(data => this.filteredItems = data);
        this.route.queryParams.subscribe(params => {
            this.resid = + params["id"];
            this.resname = params["name"];
        });
        
        // this.getRegionlist()
        this.approval.resourceID = this.resid
        this.getResourceApprovals()
       
         if (this.resid != null && this.resid > 0) {
            this.filteredItems = [];
            let user = new User();
            user.trnid = this.resid;
            this.getProductCategories();
            this.getResourceCustomerType()
            // this.user.cityid=this.model.cityid;
            // alert( this.user.cityid)
            this.model.resourceID = this.resid;
            this.model2.resourceID = this.resid
            this.Masterservice.getResourceLocations(user)
                .subscribe(
                    data => {
                        this.arealst = data.arealst;
                        this.model.allLocaleInRegion = data.allLocaleInRegion;
                        this.regionInt.sort(function (a, b) { return a - b });
                        this.arealst = data.arealst
                        let newarr = this.arealst.sort();
                        // this.model.areaString = data.areaString
                        this.model.regionString = data.regionString
                        data.regionlst.forEach((item) =>
                            this.regionlst.push({ id: item.id, name: item.name }));
                        for (let i = 0; i < this.regionlst.length; i++) {
                            this.filteredItems.push(new ItemList(this.regionlst[i].name));
                            this.regionmap.set(this.regionlst[i].name, this.regionlst[i].id)
                        }
                        this.itemControl.valueChanges.pipe(
                            startWith<string | ItemList[]>(''),
                            map(value => typeof value === 'string' ? value : this.lastFilter),
                            map(filter => this.filter(filter))
                        ).subscribe(data => this.items = this.filteredItems);
                        this.allitemsItems = this.filteredItems;
                        if (this.model.regionString != null) {
                            var splitted = this.model.regionString.split(",");
                            for (let i = 0; i < splitted.length; i++) {
                                if (this.filteredItems.find(value => value.item === splitted[i])) {
                                    this.selectedItems.push(new ItemList(splitted[i]));
                                    const j = this.filteredItems.findIndex(value => value.item === splitted[i]);
                                    this.regionmap.set(splitted[i], this.regionlst[i].id)

                                    this.filteredItems[j].selected = true;
                                } else {

                                }
                            }
                        }
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";


        }
    }

    getProductCategories() {
        this.user.trnid = this.resid
        this.prodcatlst = [];
        this.Masterservice.getProductCategoriesByResource(this.user)
            .subscribe(data => {
                data.pcategorylst.forEach((item) =>
                    this.prodcatlst.push({ id: item.id, name: item.name, chkselectcat: item.chkselectcat }));
            });
    }
    // onselectall($event,i) {
    //     if ($event.target.checked) {
    //         for ( i = 0; i < this.arealst.length; i++) {
    //             for (let j = 0; j < this.arealst[i].localelst.length; j++)
    //                 this.arealst[i].localelst[j].chkselectlocale = true
    //         }
    //     } else {
    //         for (let i = 0; i < this.arealst.length; i++) {
    //             for (let j = 0; j < this.arealst[i].localelst.length; j++)
    //                 this.arealst[i].localelst[j].chkselectlocale = false
    //         }
    //     }
    // }

    onselectall($event) {
        if ($event.target.checked) {

            for (let i = 0; i < this.newlocallst.length; i++) {
                this.newlocallst[i].chkselectlocale = true;
                this.checkedloc.push(this.newlocallst[i].localeid);
                const index = this.newlocallst.indexOf(this.newlocallst[i]);
                if (index > -1) {
                    this.uncheckedloc.splice(index, 1);
                }
            }
        } else {

            for (let i = 0; i < this.newlocallst.length; i++) {
                this.newlocallst[i].chkselectlocale = false;
                const index = this.newlocallst.indexOf(this.newlocallst[i]);
                if (index > -1) {
                    this.uncheckedloc.splice(index, 1);
                }
                // this.modelItemlst.splice(this.modelItemlst.indexOf(this.modelItemlst[i]), 1);

            }
        }
    }

    getResourceCustomerType() {
        this.user.trnid = this.resid;
        this.resourcectype = [];
        this.Masterservice.getResourceCustomerType(this.user)
            .subscribe(data => {
                data.resourcectype.forEach((item) =>
                    this.resourcectype.push({ id: item.id, type: item.type, chkselecttype: item.chkselecttype }));
            });
    }

    displayText(value: any): string {
        return value.text;
    }

    writeValue(value: any) {
        if (!this.autocomplete) {
            this.value = value;
        }
    }
    registerOnChange(fn: Function) {
        this.changeCallback = fn;
    }
    registerOnTouched(fn: Function) {
        this.touchedCallback = fn;
    }
    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }


    displayTexts(value: any): string {
        return value.text;
    }

    // writeValues(value: any) {
    //     if (!this.autocomplete) {
    //         this.values = value;
    //     }
    // }
    registerOnChanges(fn: Function) {
        this.changeCallback = fn;
    }
    registerOnToucheds(fn: Function) {
        this.touchedCallbacks = fn;
    }
    setDisabledStates(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    inputHandler(event: Event) {
        this.value = (<HTMLInputElement>event.target).value;
        if (this.changeCallback) {
            this.changeCallback(this.value);
        }
    }


    autocompleteHandler(event: Event) {
        const text = (<HTMLInputElement>event.target).value;
        if (this.autocomplete) {
            if (text) {
                this.autocompleteChoices = text;
            } else if (this.changeCallback) {
                this.innerValue = '';
                this.changeCallback(null);
            }
        }
        if (this.autocompletes) {
            if (text) {
                this.autocompleteChoices = text;
            } else if (this.changeCallbacks) {
                this.innerValue = '';
                this.changeCallbacks(null);
            }
        }
    }

    clicker() {
        this.inputTrigger.openPanel();
    }
    filter(filter: string): ItemList[] {
        this.lastFilter = filter;
        if (filter) {
            return this.items.filter(option => {
                return option.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            })
        } else {
            this.items = this.allitemsItems;
            return this.items;
        }

    }

    optionClicked(event: Event, item: ItemList) {
        event.stopPropagation();
        this.toggleSelection(item);
    }
    optionClickedofArea(event: Event, item: ItemList) {

    }

    toggleSelection(item: ItemList) {
        item.selected = !item.selected;
        if (item.selected) {
            var i = this.selectedItems.find(value => value.item === item.item);
            if (!i) {
                this.selectedItems.push(item);
            }
            this.updateLst()
            this.changeCallback(this.selectedItems);

        } else {
            if (!this.isAllSelected) {
                const i = this.selectedItems.findIndex(value => value.item === item.item);
                this.selectedItems.splice(i, 1);
                this.updateLst()
                this.changeCallback(this.selectedItems);
            }
        }

    }

    updateLst() {
        this.regionInt = [];
        if (this.selectedItems.length < 0) {
            this.notifyService.showRroor("Please Select Regions!!", " Oops!");
            return;
        } else {
            for (let i = 0; i < this.selectedItems.length; i++) {
                this.regionInt.push(this.regionmap.get(this.selectedItems[i].item))
            }
            this.getAreaLst()
        }
    }

    ngOnDestroy() {
        this.fm.stopMonitoring(this.elRef.nativeElement);
        this.stateChanges.complete();
    }

    autocompleteBlur(event: Event) {
        (<HTMLInputElement>event.target).value = this.innerValue;
        this.setTouched();
    }

    updateOption(event: MatAutocompleteSelectedEvent) {
        if (this.changeCallback) {
            const { value, text } = event.option.value;
            this.value = text;
            this.changeCallback(value);
        }
    }

    setTouched() {
        if (this.touchedCallback) {
            this.touchedCallback();
            this.touchedCallbacks();
        }
    }

    getAreaLst() {
        this.arealst = [];
        this.model.regionNumlst = this.regionInt
        this.Masterservice.getAreaListByRegionlst(this.model)
            .subscribe(data => {
                data.arealst.forEach((item) =>
                    this.arealst.push({ areaid: item.areaid, areaname: item.areaname, regionname: item.regionname, chkselectarea: item.chkselectarea, localelst: item.localelst, allcheckselect: item.allcheckselect, citylst: item.citylst }));
            });
    }
    btnsaveAndNext() {
        // this.model.arealst = this.arealst;
        this.unsvedloc = [];
        this.savedLoc = [];

        // for (let i = 0; i < this.arealst.length; i++) {
        //     if (this.arealst[i].chkselectarea == true) {
        //         for (let j = 0; j < this.arealst[i].localelst.length; j++) {
        //             if (this.arealst[i].localelst[j].chkselectlocale == true) {
        //                 this.savedLoc.push(this.arealst[i].localelst[j].localeid);
        //             } else {
        //                 this.unsvedloc.push(this.arealst[i].localelst[j].localeid)
        //             }
        //         }
        //     }
        // }
        this.model.savedlocale = this.checkedloc;
        this.model.unsavedlocale = this.uncheckedloc;
        this.Masterservice.saveResourceGeography(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !");
                        var id = document.getElementById("procat");
                        id.click();
                        this.getProductCategories();
                    } else {
                        this.notifyService.showRroor("Something Wrong !", "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    cancle() {
        this.router.navigate(['/Manage Resource']);
    }

    btnsaveProductCat() {
        this.model2.pcategorylst = this.prodcatlst
        this.Masterservice.saveResourceProCate(this.model2)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !");
                        var id = document.getElementById("cty");
                        id.click();
                        this.getResourceCustomerType();
                    } else {
                        this.notifyService.showRroor("Something Wrong !", "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    btnsaveCustomertype() {
        this.model2.resourcectype = this.resourcectype;
        this.Masterservice.saveResourceCustomertype(this.model2)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !");
                        var id = document.getElementById("appro");
                        id.click();
                    } else {
                        this.notifyService.showRroor("Something Wrong !", "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    getflag() {
        if (this.approvallst.length <= 0) {
            this.flagmodule.flagname = "Marketing Approval Flag";
            this.companyService.getFlagsOfCustomer(this.flagmodule)
                .subscribe((value) => {
                    this.marketingapprovals = Number(value.flagresult);
                    for (let i = 0; i < this.marketingapprovals; i++) {
                        this.approvallst.push({
                            resourceID: this.resid,
                            approverid: null,
                            type: "Marketing",
                            sequance: null
                        });
                    }
                });

            this.flagmodule.flagname = "Quotation Approval Flag";
            this.companyService.getFlagsOfCustomer(this.flagmodule)
                .subscribe((value) => {
                    this.quotationapprovals = Number(value.flagresult);
                    for (let i = 0; i < this.quotationapprovals; i++) {
                        this.approvallst.push({
                            resourceID: this.resid,
                            approverid: null,
                            type: "Quotation",
                            sequance: null
                        });
                    }
                });
        }

        this.getAllResource();

    }


    onSelectApprover(val, i) {
        this.approvallst[i].approverid = val;
    }


    getResourceApprovals() {
        let user = new User();
        user.userid = this.resid;
        this.approvallst = []
        this.Masterservice.getResourceApprovals(user)
            .subscribe(
                data => {
                    this.approvallst = data.approvallst
                    this.getflag()
                });

    }

    getAllResource() {
        this.Masterservice.getAllResources(this.user)
            .subscribe(
                data => {
                    this.resourcelst = data
                });
    }

    btnsaveApprovals() {
        this.model.approvallst = this.approvallst
        this.Masterservice.btnsaveApprovals(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !");
                        this.router.navigate(['/Manage Resource']);
                    } else {
                        this.notifyService.showRroor("Something Wrong !", "Oops !")
                    }
                });
    }
    onSelectCity(val, i) {
        this.model.cityid = val;

        this.getLocalesByCity(i);
    }
    getLocalesByCity(i) {
        // this.newlocallst = [];
        this.user.trnid = this.resid;
        this.user.cityid = this.model.cityid
        this.newlocallst = [];
        this.Masterservice.getLocalesByCity(this.user)
            .subscribe(
                data => {
                    // this.arealst[i].localelst
                    // this.newlocallst 
                    this.newlocallst = data.localelst;
                    this.newlocallst.forEach(element => {
                        this.checkedloc.forEach(checkedelement => {
                            if (checkedelement == element.localeid) {
                                element.chkselectlocale = true;
                            }
                        });
                    });
                });
    }
    onselectlocal($event, localeid, i) {
        if ($event.target.checked) {
            this.model.localeid = localeid;
            this.checkedloc.push(localeid)
            const index = this.uncheckedloc.indexOf(localeid, 0);
            if (index > -1) {
                this.uncheckedloc.splice(index, 1);
            }
        }
        else {
            const index = this.checkedloc.indexOf(localeid, 0);
            if (index > -1) {
                this.checkedloc.splice(index, 1);
            }
            this.model.localeid = localeid;
            this.uncheckedloc.push(localeid)
        }
    }
    onselectallLocales($event) {
        if ($event.target.checked) {
            this.model.allLocaleInRegion = true;
            // this.updateLst();

            this.regionInt = [];
            for (let i = 0; i < this.selectedItems.length; i++) {
                this.regionInt.push(this.regionmap.get(this.selectedItems[i].item))
            }
            this.model.regionNumlst = this.regionInt;

            for (let i = 0; i < this.arealst.length; i++) {
                this.arealst[i].chkselectarea = true;
            }
        }
    }
    onclickarea(areaname) {
        this.newlocallst = [];
        // alert("hi " +areaname)
    }
}