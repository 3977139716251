import { OnInit, Component } from '@angular/core';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { GoodsNoteDefineModel, PoModel, ProductList } from './GoodsNoteDefineModel';
import { InventoryServices } from 'src/app/_services/InventoryServices';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { DefineItemModel } from 'src/app/Master/ItemMaster/DefineItemModel';
import { searchgstheadermodel } from 'src/app/Master/GSTForm/searchgstheadermodel';
declare var $: any;
import * as moment from 'moment';
import { setConstantValue } from 'typescript';

@Component({
    selector: 'app-GoodsNoteDefine',
    templateUrl: 'GoodsNoteDefine.html',
})
export class GoodsNoteDefine implements OnInit {

    constructor(private Masterservice: MasterServices,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private inventoryServ: InventoryServices) {
        this.pagename = this.router.url;
    }
    user = new User();
    model = new GoodsNoteDefineModel();
    pmodel = new DefineItemModel();
    p: any;

    itemcatlst: DropDownModel[] = [];
    itemSubcatlst: DropDownModel[] = [];
    itemlst: DropDownModel[] = [];
    productslst: ProductList[] = [];
    productslst1: ProductList[] = [];
    inventoryLocations: DropDownModel[] = []
    rejectResonslst: DropDownModel[] = [];
    taxmodellst: searchgstheadermodel[] = [];


    userList2: any;
    pagename: string;
    lastkeydown2: number = 0;
    polst: PoModel[] = [];
    potype: string;
    grnnumber: string;
    poid: number;
    action: string;
    ngOnInit(): void {
        this.potype = "NVPO";
        this.model.chkverbal = false;
        this.getitemCategory();
        this.getGstgrp()
        this.route.queryParams.subscribe(params => {

            this.user.message = params["grnnumber"]
            this.grnnumber = this.user.message
            this.pagename = params["pagename"]
            this.poid = params["poid"]
            this.user.poid = this.poid
            this.user.id = params["id"]
            this.action = params["action"]
            this.user.accesstype = this.action
        });
        this.model.userid = Number(localStorage.getItem("userid"));
        this.user.pagename = null;
        if (this.pagename != null) {
            this.user.pagename = this.pagename.replace('/', '');
            this.user.pagename = this.user.pagename.replace('/', '');
            this.user.pagename = this.user.pagename.replace('%20', ' ');
            this.user.pagename = this.user.pagename.replace('%20', ' ');
        }

        if (this.user.message != null) {
            this.inventoryServ.getGRNbyGrnid(this.user)
                .subscribe((value) => {
                    this.model = value;
                    this.model.invodate = new Date(value.invodate)
                    this.model.userid = Number(localStorage.getItem("userid"));
                    this.typeaheadOnSelect1(this.model.supplierid, this.model.supplierName);
                    this.onselectPO(this.model.poid);
                    this.productslst1 = value.products;
                    this.rejectResonslst = value.rejectResonslst;
                });
        }
    }

    getGstgrp() {
        this.Masterservice.getallgsthdr(this.user)
            .subscribe(value => {
                this.taxmodellst = [];
                this.taxmodellst = value
            });
    }

    onselectTax(taxid, itemid, inde) {
        for (let i = 0; this.productslst.length; i++) {
            if (this.productslst[i].itemid == itemid) {
                for (let j = 0; j < this.taxmodellst.length; j++) {
                    if (this.taxmodellst[j].id == taxid) {
                        this.productslst[i].gstgrpid = taxid;
                        this.productslst[i].gstperc = this.taxmodellst[j].percent
                        this.calculate()
                    }
                }
            }
        }
    }

    onchkVerbalPO($event) {
        if ($event.target.checked) {
            this.model.chkverbal = true;
            this.potype = "VPO"
            this.getAllInventoryLocations();
        } else {
            this.model.chkverbal = false;
            this.potype = "NVPO"
        }
    }


    getUserIdsSecondtWay($event) {
        let userId = (<HTMLInputElement>document.getElementById('dynamicUserIdsSecondWay')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.sendstring = this.potype
        console.log('VALU ' + userId);
        var obj = this;
        if (userId.length >= 3) {
            if ($event.timeStamp - this.lastkeydown2 > 200) {
                this.inventoryServ.getVendorNameLike(this.user)
                    .subscribe(data => {
                        data.forEach((item) => this.userList2.push({ "text": item.name, "val": item.id }));
                        let map = new Map();
                        var names = "";
                        for (let i = 0; i < this.userList2.length; i++) {
                            map.set(this.userList2[i].text, this.userList2[i].val)
                            searchDataObj[this.userList2[i].text] = null;
                            if (i == 0) {
                                names = this.userList2[i].text;
                            } else {
                                names = names + "," + this.userList2[i].text;
                            }
                        }
                        let customername;
                        $(document).ready(function () {
                            $('input.typeahead').autocomplete({
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect1(map.get(txt), txt);
                                },
                            });
                            $('input.autocomplete').autocomplete({
                                data: searchDataObj,
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect1(map.get(txt), txt);
                                },
                            });
                        });
                    },
                        () => {
                            alert("fail");
                        });
            }
        }
    }

    typeaheadOnSelect1(val, val2) {
        this.user.trnid = val;
        this.model.supplierid = val;
        this.model.supplierName = val2
        // this.polst.push({ "id": 0, "podate": null, "pono": "Select One" })
        this.inventoryServ.getAllOpenPOBySupplierid(this.user)
            .subscribe(
                data => {
                    data.forEach((item) => this.polst.push({ "id": item.id, "podate": item.podate, "pono": item.pono }));
                }
            );
    }
    typeaheadOnSelect2(val, val2) {

    }

    dynamicUserIdsSecondWay1($event) {
        let userId = (<HTMLInputElement>document.getElementById('itmname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.userid = Number(localStorage.getItem("userid"));
        this.user.sendstring = this.potype
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "hsnCode": item.hsnCode, "uom": item.uom, "gstGrouppercentage": item.gstGrouppercentage }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].itemname, this.userList2[i].itemid)
                        // this.itemcodemap.set(this.userList2[i].itemname, this.userList2[i].itemcode)
                        // this.hsnCodemap.set(this.userList2[i].itemname, this.userList2[i].hsnCode)
                        // this.uommap.set(this.userList2[i].itemname, this.userList2[i].uom)
                        // this.gstmap.set(this.userList2[i].itemname, this.userList2[i].gstGrouppercentage)

                        searchDataObj[this.userList2[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList2[i].itemname;
                        } else {
                            names = names + "," + this.userList2[i].itemname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect2(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect2(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    onselectReject(val) {

    }

    onselectPO(poid) {
        this.inventoryLocations = [];
        this.productslst = [];
        this.model.poid = poid;
        let user = new User();
        user.trnid = poid;

        for (let i = 0; i < this.polst.length; i++) {
            if (this.polst[i].id == poid) {
                this.model.podate = this.polst[i].podate;
                this.model.pono = this.polst[i].pono
            }
        }

        this.inventoryLocations.push({ id: 0, name: "Select One" });
        this.inventoryServ.getAllDataOfPO(user)
            .subscribe(
                data => {
                    this.productslst = data.products;
                    data.inventoryLocations.forEach((item) => this.inventoryLocations.push({ id: item.id, name: item.name }));
                });
    }


    getAllInventoryLocations() {
        this.inventoryLocations = []
        this.inventoryLocations.push({ id: 0, name: "Select One" });
        this.Masterservice.getAllInventoryLocations(this.user)
            .subscribe(
                data => {
                    data.forEach((item) => this.inventoryLocations.push({ id: item.id, name: item.name }));
                })
    }

    getitemCategory() {
        let user = new User();
        this.itemcatlst = [];
        this.itemcatlst.push({ id: 0, name: "Select One" });
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                value.forEach((item) => this.itemcatlst.push({ id: item.id, name: item.name }));
            });
    }

    onselectItemCate(value) {
        if (value == 0) {
            this.notifyService.showRroor("Please select Item category!", "Oops!");
            return;
        }
        let user = new User();
        this.itemSubcatlst = [];
        this.model.itemcatid = value;
        for (let i = 0; i < this.itemcatlst.length; i++) {
            if (this.itemcatlst[i].id == value) {
                this.model.itemcatName = this.itemcatlst[i].name
            }
        }
        user.trnid = value
        this.itemSubcatlst.push({ id: 0, name: "Select One" });
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                value.forEach((item) => this.itemSubcatlst.push({ id: item.id, name: item.name }));
            });
    }
    onselectItemSubCate(value) {
        if (value == 0) {
            this.notifyService.showRroor("Please select Item sub category!", "Oops!");
            return;
        }
        for (let i = 0; i < this.itemSubcatlst.length; i++) {
            if (this.itemSubcatlst[i].id == value) {
                this.model.itemsubcatname = this.itemSubcatlst[i].name
            }
        }
        this.model.itemsubcatid = value
        this.itemlst.push({ id: 0, name: "Select One" });
        this.user.trnid = value
        this.inventoryServ.getAllItemsBySubcatid(this.user)
            .subscribe((value) => {
                value.forEach((item) => this.itemlst.push({ id: item.id, name: item.name }));
            });

    }

    onselectItem(itemid) {
        if (itemid == 0) {
            this.notifyService.showRroor("Please select Item!", "Oops!");
            return;
        }
        this.model.itemid = itemid
        for (let i = 0; i < this.itemlst.length; i++) {
            if (this.itemlst[i].id == itemid) {
                this.model.itemcatName = this.itemlst[i].name
            }
        }

        let user = new User();
        user.trnid = itemid
        this.Masterservice.getItemByid(user)
            .subscribe(
                data => {
                    this.pmodel = data
                });
    }

    calculate() {
        let totalnetamnt = 0;
        let totalgst = 0;
        let grandtotal = 0;
       
        if (this.grnnumber == null) {
            for (let i = 0; i < this.productslst.length; i++) {
                if(this.productslst[i].recvqnty >0){

                if(this.productslst[i].recvqnty < this.productslst[i].balanceqnty ){
                    this.notifyService.showRroor("Received quanity is greter than balance quantity!", "Oops!");
                    this.productslst[i].recvqnty= 0;
                }
                
            }

                this.productslst[i].netamnt = this.productslst[i].rate * this.productslst[i].recvqnty;

                this.productslst[i].gstamnt = (this.productslst[i].netamnt * this.productslst[i].gstperc) / 100;
                this.productslst[i].totalamnt = this.productslst[i].netamnt + this.productslst[i].gstamnt;
                totalnetamnt = totalnetamnt + this.productslst[i].netamnt;
                totalgst = totalgst + this.productslst[i].gstamnt;
                grandtotal = grandtotal + this.productslst[i].totalamnt;
            }
        } else {
            for (let i = 0; i < this.productslst1.length; i++) {
                this.productslst1[i].rejectqnty = this.productslst1[i].recvqnty - this.productslst1[i].approvqnty
                this.productslst1[i].netamnt = this.productslst1[i].rate * this.productslst1[i].approvqnty;
                this.productslst1[i].gstamnt = (this.productslst1[i].netamnt * this.productslst1[i].gstperc) / 100;
                this.productslst1[i].totalamnt = this.productslst1[i].netamnt + this.productslst1[i].gstamnt;
                totalnetamnt = totalnetamnt + this.productslst1[i].netamnt;
                totalgst = totalgst + this.productslst1[i].gstamnt;
                grandtotal = grandtotal + this.productslst1[i].totalamnt;
            }
        }

        this.model.grandtotal = grandtotal
    }


    btnAddItem() {
        if (this.model.itemcatid == null) {
            this.notifyService.showRroor("Please select Item category!", "Oops!");
            return;
        }
        if (this.model.itemsubcatid == null) {
            this.notifyService.showRroor("Please select Item sub category!", "Oops!");
            return;
        }
        if (this.model.itemid == null) {
            this.notifyService.showRroor("Please select Item!", "Oops!");
            return;
        }
        this.productslst.push({
            srno: null,
            id: null,
            itemid: this.pmodel.id,
            itemcode: this.pmodel.itemcode,
            itemname: this.pmodel.itemname,
            unitid: this.pmodel.inventoryunitid,
            unit: this.pmodel.inventoryunit,
            podtlid: null,
            poqnty: 0,
            invoiceqnty: 0,
            recvqnty: 0,
            gstgrpid: this.pmodel.gstGroupid,
            gstperc: this.pmodel.gstGrouppercentage,
            rate: 0,
            gstamnt: 0,
            netamnt: 0,
            totalamnt: 0,
            approvqnty: 0,
            rejectqnty: 0,
            rejectreason: null,
            balanceqnty:0
        })
        this.calculate()
    }

    onselectInventoryLocation(locid) {
        this.model.localtionid = locid
    }

    saveInvent($event) {

        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.grnnumber == null) {
            if (this.model.supplierid == null) {
                this.notifyService.showRroor("Please select Supplier!", "Oops!");
                return;
            }
            if (this.potype == "NVPO") {
                if (this.model.poid == null) {
                    this.notifyService.showRroor("Please select PO!", "Oops!");
                    return;
                }
            }
            if (this.model.invoiceno == null  ) {
                this.notifyService.showRroor("Please enter Invoice no!", "Oops!");
             
                return;
            }
            if (this.model.invodate == null) {
                this.notifyService.showRroor("Please select Invoice date!", "Oops!");
                this.model.invodate = new Date()
                return;
            }
            if (this.model.localtionid == null) {
                this.notifyService.showRroor("Please select Inventory location!", "Oops!");
                return;
            }

            this.model.products = this.productslst
            this.inventoryServ.saveGRN(this.model)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess(data.message, "Success !")
                        } else {
                            this.notifyService.showRroor(data.message, "Oops !")
                            return
                            }
                            if (data.status == "OK"){
                        this.router.navigate(['/Goods Receipt Note'])
                            }
                    },
                    error => {
                        alert("Something Wrong ")
                    });

        } else {
            this.model.products = this.productslst1
            this.model.rejectResonslst = this.rejectResonslst
            this.inventoryServ.saveGIN(this.model)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess(data.message, "Success !")
                        } else {
                            this.notifyService.showRroor(data.message, "Oops !")
                        }
                        this.router.navigate(['/Goods Receipt Note'])
                    },
                    error => {
                        alert("Something Wrong ")
                    });

        }

    }
    btnback() {
        this.router.navigate(['/Goods Receipt Note'])
    }
    selectInvoiceDate(date) {
        let invoicedate = moment(date).format('DD-MM-YYYY');
        let startdate = moment(this.model.podate).format('DD-MM-YYYY');
        
         if (invoicedate <= startdate ) {
            this.notifyService.showRroor("Previous date cannot be selected", "oops");
            this.model.invodate = this.model.podate
            this.model.invodate = new Date()
            this.model.invodate.setDate(this.model.invodate.getDate());
            return;
        }
    }


}