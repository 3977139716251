<div *ngIf="isMobile == true ">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol" id="Div1" style="padding-top: 7px;">
        <h1 class="header center" style="margin-bottom: 9px;">Opportunities</h1>
        <div class="container" style="width:94%;">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <h6 style="margin-bottom: 0px;margin-top: 0px;" class="header left">Total
                        Opportunities:&nbsp;{{user.totalItems}}</h6>
                    <div class="srchBy p-0">
                        <div class="input-field col s12" style="margin-bottom:0px;">
                            <select (change)="onSelectSrch($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col s12" *ngIf="model.txtselect!='Stage'"
                            style="margin-bottom:0px;margin-top: 5px;padding-right: 0px;">
                            <div class="w-80 left smallInputNew">
                                <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="mat-input "
                                    id="first-name" data-toggle="tooltip" data-placement="top" title="Search Field"
                                    placeholder="Search Text">
                            </div>
                            <div class="w-20 right">
                                <img src="../../../assets/images/search.png" style="margin-right: 8px;" width="25px"
                                    (click)="btnsearch($event)">
                                <img src="../../../assets/images/search-filter.jpg" width="25px" id="openChkBlist"
                                    (click)="onchkopen()">
                            </div>
                        </div>

                        <div class="w-80 left smallInputNew" *ngIf="model.txtselect=='Stage';">
                            <label for="" class="m-0">Stage</label>
                            <div class="input-field col s12" *ngIf="model.txtselect=='Stage';">
                                <select (change)="onSelectStages($event.target.value)">
                                    <option *ngFor="let type of stageslst" [value]="type.name">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="w-20 right" *ngIf="model.txtselect=='Stage';">
                            <img src="../../../assets/images/search.png" style="margin-right: 8px;" width="25px"
                                (click)="btnsearch($event)">
                            <img src="../../../assets/images/search-filter.jpg" width="25px" id="openChkBlist"
                                (click)="onchkopen()">
                        </div>

                        <div class="intro-text" id="showChekLine" *ngIf="chkopen" style="margin-bottom: 0px;">
                            <p class="w-50">
                                <input [(ngModel)]="model.openchk" #openchk="ngModel" type="checkbox">
                                <label for="test">Open</label>
                            </p>
                            <p class="w-50">
                                <input [(ngModel)]="model.closechk" #closechk="ngModel" type="checkbox">
                                <label for="test">Close</label>
                            </p>
                            <p class="w-50">
                                <input [(ngModel)]="model.wonchk" #wonchk="ngModel" type="checkbox">
                                <label for="test">Won</label>
                            </p>
                            <p class="w-50">
                                <input [(ngModel)]="model.lostchk" #lostchk="ngModel" type="checkbox">
                                <label for="test">Lost</label>
                            </p>
                            <p class="w-50">
                                <input [(ngModel)]="model.abandonedchk" #abandonedchk="ngModel" type="checkbox">
                                <label style="margin-bottom: 0px;" for="test">Abandoned</label>
                            </p>
                            <p class="w-50">&nbsp;</p>
                        </div>
                    </div>
                    <div class="col s12">
                        <div class="tabInnerBox" *ngFor="let lst of opportunitieslst">

                            <div class="introTab">
                                <label class="w-31">{{lst.dCRDate | date:"dd-MM-yyyy"}}</label>
                                <label class="widthR-70">{{"| "+"&nbsp;"+lst.stage+"&nbsp;"+" |
                                    "+"&nbsp;"+lst.status}}</label>
                                <label class="w-100 dis-block">{{lst.enquiryName}}</label>
                                <label class="w-100 dis-block"><b>{{lst.customerName}}</b></label>
                                <label class="w-100 dis-block">
                                    <a href="tel:{{lst.mobileno}}">{{lst.mobileno}}</a>
                                </label>

                                <label class="w-100 dis-block">{{lst.unitno+", "+lst.buildingName+", "+lst.address+",
                                    "+lst.locallity+", "+lst.city+", "+lst.pincode}}</label>
                                <div style="border-style: ridge;background-color: ghostwhite">
                                    <label class="widthR-60">{{lst.assignedTo}}</label>
                                    <label class="w-35 dcrDiv" style="text-align: end;">
                                        <span *ngIf="lst.status=='Open'"><img src="../../../assets/images/DCR-Entry.png"
                                                width="25px" (click)="btnDCR(lst.id)" title="DCR"
                                                style="margin-top: 5px;" /></span>
                                        <span *ngIf="lst.status=='Won'"><img src="../../../assets/images/view.png"
                                                (click)="btnView(lst.id)" data-toggle="tooltip" data-placement="top"
                                                title="View DCR" width="25px" /></span>&nbsp;&nbsp;&nbsp;
                                        <span *ngIf="lst.status=='Won'"><img
                                                src="../../../assets/images/file-preview.png"
                                                (click)="btnprintMobile(lst.quoid)" data-toggle="tooltip"
                                                data-placement="top" title="View Quotation" width="25px"
                                                class="modal-trigger" href="#printQut" /></span>&nbsp;&nbsp;
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="s12 right" style="margin-top: 10px;">
                            <lable (click)="first()" class="w-30"
                                style="font-size: 20px;margin-right: 17px;color: #645959;" *ngIf="this.sum > 1">
                                <b>First</b>
                            </lable>
                            &nbsp;&nbsp;
                            <lable class="w-30">
                                <span *ngIf="this.sum > 1" style="margin-right: 17px;"><img
                                        src="../../../assets/images/previous_icon.png" width="35px"
                                        data-toggle="tooltip" data-placement="top" (click)="btnprevious()"
                                        data-placement="top" title="Previous Page"></span>&nbsp;&nbsp;

                                <span><img src="../../../assets/images/next_icon.png" width="35px" data-toggle="tooltip"
                                        data-placement="top" (click)="btnnext()" data-placement="top"
                                        title="Next Page"></span>&nbsp;&nbsp;
                            </lable>
                            <lable (click)="last()" class="w-30"
                                style="font-size: 20px;color: #645959; margin-left: 17px;" *ngIf="this.sum > 1">
                                <b>Last</b>
                            </lable>
                        </div>
                    </div>

                    <div id="printQut" class="modal">
                        <div class="modal-content">
                            <h4 class="orgTxt center-align">Quotation</h4>
                            <div class="row">
                                <div class="tabInnerBox m-btm">
                                    <div class="introTab">
                                        <label class="w-100 font-weight-bold dis-block">Quotation No:</label>
                                        <!-- <label class="w-100 dis-block">{{quoReport.quotationCode}}</label> -->
                                        <label class="w-100 font-weight-bold dis-block">Quotation Date:</label>
                                        <label class="w-100 valignTxt dis-block">{{quoReport.quoDate |
                                            date:"dd-MM-yyyy"}}</label>

                                        <label class="w-100 font-weight-bold dis-block">Contact Person :</label>
                                        <label class="w-100 valignTxt dis-block">{{quoReport.contactPerson}}</label>
                                        <label class="w-100 font-weight-bold dis-block">Payment terms:</label>
                                        <label class="w-100 valignTxt dis-block">{{quoReport.paymentTerm}}</label>
                                    </div>
                                </div>
                                <div class="tabInnerBox m-btm" *ngFor="let lst of qutReportlst">
                                    <div class="introTab">
                                        <label class="w-50 font-weight-bold blueTxt ">Sr. No.</label>
                                        <label class="w-50 ">{{lst.srno}}</label>

                                        <label class="w-100  font-weight-bold blueTxt dis-block">Description of
                                            Goods</label>
                                        <label class="w-100 dis-block">{{lst.name}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">HSN</label>
                                        <label class="w-50 ">{{lst.hsn}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">Quantity</label>
                                        <label class="w-50 ">{{lst.quantity | number:'1.2-2'}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">Rate</label>
                                        <label class="w-50 ">{{lst.amount | number:'1.2-2'}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">UMO</label>
                                        <label class="w-50 ">{{lst.uom}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">Total Amount</label>
                                        <label class="w-50 ">{{lst.totalproamount | number:'1.2-2'}}</label>
                                    </div>
                                </div>

                                <div class="grayBg" style="box-shadow: rgb(99 99 99 / 45%) 2px 2px 2px 2px;">
                                    <label class="widthR-100 font-weight-bold blueTxt dis-block"
                                        style="text-align: left;">Total In Words :</label>
                                    <label class="widthR-100 dis-block"
                                        style="text-align: left;">{{quoReport.totalinwords |
                                        uppercase}}&nbsp;ONLY</label>
                                    <!-- class="w-35 font-weight-bold blueTxt valignTxt" -->
                                    <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Total
                                        :</label>
                                    <label class="widthR-60" style="text-align: left;">&#x20b9; {{quoReport.totalamount
                                        |
                                        number:'1.2-2'}}</label>
                                </div>

                               
                                <!-- <div class="tabInnerBox grayBg m-btm" *ngFor="let lst of reporttandclst">
                                    <div class="col12">
                                        <label class="w-50 font-weight-bold">Heading</label>
                                        <label class="w-50">&nbsp;{{lst.heading}}</label>
                                        <label class="w-50 font-weight-bold">Description</label>
                                        <label class="w-50">&nbsp;{{lst.descrption}}</label>
                                    </div>
                                </div> -->
                            </div>
                            <hr>
                            <div class="modal-footer">
                                <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                                    style="margin-left: 5px;">Cancel</a>
                                <!-- <a href="#!" (click)="openPrint()"
                                    class="btn btn-blue waves-effect waves-green btn-flat">Print</a> -->
                            </div>
                        </div>
                    </div>

                    <div id="address" class="modal">
                        <div class="modal-content" style="background-color: #363434;">
                            <h4 style="color: #fafafa; text-align: center;">Address</h4>
                            <div class="row">
                                <label class="w-50 font-weight-bold" style="color: #c2d9f9;">Unit #:</label>
                                <label class="w-50" style="color: #fafafa;">{{dmodel.unitno}}</label>
                                <label class="w-100 font-weight-bold dis-block"
                                    style="color: #c2d9f9;text-align: left">Building Name:</label>
                                <label class="w-100 dis-block"
                                    style="color: #fafafa;text-align: left">{{dmodel.buildingName}}</label>
                                <label class="w-100 font-weight-bold dis-block"
                                    style="color: #c2d9f9; text-align: left;">Address:</label>
                                <label class="w-100 dis-block"
                                    style="color: #fafafa;text-align: left;">{{dmodel.address}}</label>
                                <label class="w-50 font-weight-bold" style="color: #c2d9f9;">Locality:</label>
                                <label class="w-50" style="color: #fafafa;">{{dmodel.locallity}}</label>
                                <label class="w-50 font-weight-bold" style="color: #c2d9f9;">City:</label>
                                <label class="w-50" style="color: #fafafa;">{{dmodel.city}}</label>
                                <label class="w-50 font-weight-bold" style="color: #c2d9f9;">Pincode:</label>
                                <label class="w-50" style="color: #fafafa;">{{dmodel.pincode}}</label>
                            </div>
                            <hr>
                            <div class="modal-footer" style="background-color: #363434;">
                                <a href="#!" (click)="modalRef.hide()"
                                    class="modal-close waves-effect btn waves-green btn-flat"
                                    style="margin-left: 5px;">Cancel</a>
                                <!-- <a href="#!" (click)="openPrint()"
                                    class="btn btn-blue waves-effect waves-green btn-flat">Print</a> -->
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isMobile == false">
    <app-header></app-header>
    <div class="container" style="width: 90%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Opportunities</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="row" style="margin-top: 2%;padding-left: 5px;padding-right: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <div class="col l6 m5 s6">
                        <span style="font-size: 15px;font-weight: bold">Total
                            Opportunities:&nbsp;<span>{{user.totalItems}}</span></span>
                    </div>
                    <div class="col l6 m5 s6" style="text-align: end;padding-right: 0px;">
                        <button type="button" class="waves-ripple waves-light btn" (click)="btnnewprospect()">New
                            Prospect</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="input-field col l2 m2 s2"
                            style="margin-top: 0px;margin-bottom:0px;padding-left: 5px;">
                            <span>Search Criteria</span>
                            <select (change)="onSelectSrch($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>

                        <!-- this search for oppertunity screen -->
                        <div class="input-field col l5 m5 s5" style="margin-top: 17px;margin-bottom:0px;"
                            *ngIf="model.txtselect!='Stage' && model.txtselect!='Customer Name'">
                            <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                                data-toggle="tooltip" data-placement="top" title="Search Field">
                            <label id="lable1" for="search">Search Text</label>
                        </div>

                        <!-- this search for callcenter screen -->
                        <div class="input-field col s5 l5 m5"
                            style="margin-top: 17px;text-align: center;margin-bottom: 5px;"
                            *ngIf="model.txtselect=='Customer Name';">
                            <input [(ngModel)]="cmodel.txtserach2" #txtserach2="ngModel" type="text" id="searcMOb"
                                data-toggle="tooltip" data-placement="top" title="Search Field">
                            <label for="search" id="search">Search Here</label>
                        </div>

                        <div class="input-field col l2 m2 s2" *ngIf="model.txtselect=='Stage';"
                            style="margin-top: 0px;margin-bottom:0px;">
                            <span>Stage</span>
                            <select (change)="onSelectStages($event.target.value)">
                                <option selected value="0">Select Stage</option>
                                <option *ngFor="let type of stageslst" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col l1 m1 s1" style="text-align: end;margin-top: 17px">
                            <!-- this search for oppertunity screen -->
                            <button type="button" (click)="btnsearch($event)" class="waves-effect waves-light btn"
                                *ngIf="model.txtselect!='Customer Name'">Search</button>


                            <!-- this search for callcenter screen -->
                            <button type="button" (click)="btnsearch2()" class="waves-effect waves-light btn"
                                *ngIf="model.txtselect=='Customer Name';">Search</button>

                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="col l3 m3 s3" *ngIf="model.txtselect=='Stage';"></div>
                        <!-- <div class="col l1 m1 s1"></div> -->
                        <div class="col l4 m4 s4">
                            <div class="col l1 m1 s1"></div>
                            <div class="input-field col l2 m2 s2"
                                style="text-align: left;margin-top: 0px;margin-bottom: 0px;">
                                <input [(ngModel)]="model.openchk" #openchk="ngModel" type="checkbox"
                                    style="text-align: 5px">&nbsp;Open
                            </div>
                            <div class="input-field col l2 m2 s2"
                                style="text-align: left;margin-top: 0px;margin-bottom: 0px;">
                                <input [(ngModel)]="model.closechk" #closechk="ngModel" type="checkbox"
                                    style="text-align: 5px">&nbsp;Close
                            </div>
                            <div class="input-field col l2 m2 s2"
                                style="text-align: left;margin-top: 0px;margin-bottom: 0px;">
                                <input [(ngModel)]="model.wonchk" #wonchk="ngModel" type="checkbox"
                                    style="text-align: 5px">&nbsp;Won
                            </div>
                            <div class="input-field col l2 m2 s2"
                                style="text-align: left;margin-top: 0px;margin-bottom: 0px;">
                                <input [(ngModel)]="model.lostchk" #lostchk="ngModel" type="checkbox"
                                    style="text-align: 5px">&nbsp;Lost
                            </div>
                            <div class="input-field col l3 m3 s3"
                                style="text-align: left;margin-top: 0px;margin-bottom: 0px;">
                                <input [(ngModel)]="model.abandonedchk" #abandonedchk="ngModel" type="checkbox"
                                    style="text-align: 5px">Abandoned
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding: 1%" *ngIf="model.txtselect!='Customer Name';">
                    <!-- <div class="row" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                        (scrolled)="onScroll()" [scrollWindow]="false" style="height: 400px; overflow-y: scroll;"> -->
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="18%" style="text-align: left;padding-left: 10px;">Enquiry Number</th>
                            <th width="9%" style="text-align: left">Date</th>
                            <th width="14%" style="text-align: left">Enquiry Name</th>
                            <th width="8%" style="text-align: left">Mobile</th>
                            <th width="5%" style="text-align: left">Stage</th>
                            <th width="13%" style="text-align: left">Assigned To</th>
                            <th width="8%" style="text-align: left">City</th>
                            <th width="12%" style="text-align: left">Locality</th>
                            <th width="5%" style="text-align: left">Status</th>
                            <th width="6%" style="text-align: center">Actions</th>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let lst of opportunitieslst | paginate: { itemsPerPage: 10, currentPage: p,totalItems:user.totalItems }">
                                <td style="text-align: left;padding-left: 10px;">{{lst.enquiryName}}</td>
                                <td style="text-align: left">{{lst.dCRDate | date:"dd-MM-yyyy"}}</td>
                                <td style="text-align: left">{{lst.customerName}}</td>
                                <td style="text-align: left">{{lst.mobileno}}</td>
                                <td style="text-align: left">{{lst.stage}}</td>
                                <td style="text-align: left">{{lst.assignedTo}}</td>
                                <td style="text-align: left">{{lst.city}}</td>
                                <td style="text-align: left">{{lst.locallity}}</td>
                                <td style="text-align: left">{{lst.status}}</td>
                                <td style="text-align: center"><span *ngIf="lst.status=='Open'&& lst.dealerflag=='N'"><img
                                            src="../../../assets/images/DCR-Entry.png" width="25px"
                                            (click)="btnDCR(lst.id)" title="DCR" /></span>
                                    <span *ngIf="lst.status=='Open'&& lst.dealerflag=='N'"><img src="../../../assets/images/EditEnquiry.png"
                                            width="25px" (click)="btnAssignEnquiry(lst.customerId,lst.id)"
                                            title="Edit Create Lead" /></span>
                                    <span *ngIf="lst.status=='Won'"><img src="../../../assets/images/view.png"
                                            (click)="btnView(lst.id)" data-toggle="tooltip" data-placement="top"
                                            title="View DCR" width="25px" /></span>
                                    <span *ngIf="lst.status=='Won'&& lst.dealerflag=='N'"><img src="../../../assets/images/file-preview.png"
                                            (click)="btnprint(QuotationReport,lst.quoid)" data-toggle="tooltip"
                                            data-placement="top" title="View Quotation" width="25px" /></span>
                                    <span *ngIf="lst.status=='Close'&& lst.dealerflag=='N'" ><img src="../../../assets/images/view.png"
                                        (click)="btnview(lst.id)" data-toggle="tooltip" data-placement="top"
                                        title="ViewDCR"    [disabled]="this.action=='edit' " width="20px" /></span>
                                        <span *ngIf="lst.status=='Open'&& lst.dealerflag=='Y'" ><img
                                            src="../../../assets/images/edit.png" width="25px"
                                            (click)="btnDCR1(lst.id)" title="DCR" /></span>
                                            <span *ngIf="lst.status=='Won'&& lst.dealerflag=='Y'"><img src="../../../assets/images/file-preview.png"
                                                (click)="btnprint(QuotationReport,lst.quoid)" data-toggle="tooltip"
                                                data-placement="top" title="View Quotation" width="25px" /></span>
                                                <span *ngIf="lst.status=='Lost'&& lst.dealerflag=='Y'"><img src="../../../assets/images/file-preview.png"
                                                    (click)="btnprint(QuotationReport,lst.quoid)" data-toggle="tooltip"
                                                    data-placement="top" title="View Quotation" width="25px" /></span>
                                            <!-- && lst.delerflag=='y' -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- </div> -->
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)"></pagination-controls>
                    </span>
                </div>


                <div class="row" *ngIf="model.txtselect=='Customer Name';">
                    <div class="col s12 m12 l12 registerBgShadow"
                        style="padding-left: 9px;padding-right: 9px;padding-top: 5px;padding-bottom: 9px;">
                        <div class="col l12 m12 s12"
                            style="background: gainsboro; padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;">
                            <div class="col l3 m3 s3" style="text-align:left;padding-left: 7px;">
                                <span>Customer Name</span>
                            </div>
                            <div class="col l4 m4 s4" style="text-align:left;padding-left: 5px;">
                                <span>Address</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:left;padding-left: 5px;">
                                <span>Mobile</span>
                            </div>
                            <div class="col l3 m3 s3" style="text-align:left;padding-left: 3px;">
                                <span>Email</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:left;padding-left: 20px;">
                                <span>Action</span>
                            </div>
                        </div>
                        <mat-accordion style="padding: 0px;">
                            <!-- hideToggle -->
                            <mat-expansion-panel
                                *ngFor="let lst of modelst | paginate: { itemsPerPage: 10, currentPage: q,totalItems1:user.totalItems1 };let i = index "
                                style="padding: 1px;" (click)="btngetdetails(lst.accountid)">
                                <!-- <span  *ngIf="lst.customer=='P'"></span> -->
                                <!-- (click)="btngetdetails(lst.accountid,i)" -->
                                <mat-expansion-panel-header style="padding-right: 9px;padding-left: 9px;">
                                    <div class="col l12 m12 s12" style="margin-top:20px;color: black;">
                                        <div class="row">
                                            <div class=" col l3 m3 s3" style="text-align:left;">
                                                <span>{{lst.name}}</span>
                                            </div>
                                            <div class=" col l4 m4 s4" style="text-align:left;">
                                                <span>{{lst.address}}</span>
                                            </div>
                                            <div class=" col l1 m1 s1" style="text-align:left;">
                                                <span>{{lst.telephone1}}</span>
                                            </div>
                                            <div class=" col l3 m3 s3" style="text-align:left;">
                                                <span>{{lst.email}}</span>
                                            </div>
                                            <div class="col s1 m1 l1"
                                                style="text-align:left;padding-right: 0px;padding-left: 0px;">

                                                <span style="padding-right: 5px;"><img
                                                        src="../../../assets/images/edit.png"
                                                        (click)="btnedit(lst.accountid,i)" data-toggle="tooltip"
                                                        data-placement="top" title="Edit" width="20px"
                                                        style="height: 25px;width: 25px;" /></span>

                                                <span style="padding-right: 5px;"><img
                                                        src="../../../assets/images/CreateLead.png"
                                                        (click)="btncreatelead(lst.accountid,i)" title="Create Lead"
                                                        width="20px" style="height: 25px;width: 25px;" /></span>

                                                <span style="padding-right: 5px;"><img
                                                        src="../../../assets/images/Customer-360.png"
                                                        (click)="btnopenaccount360(lst.accountid)" data-toggle="tooltip"
                                                        data-placement="top" title="Account 360" width="20px"
                                                        style="height: 25px;width: 25px;" /></span>

                                                <!-- <span *ngIf="lst.customer=='P'"><img src="../../../assets/images/Down.png"
                                                        (click)="btngetdetails(lst.accountid,i)" data-toggle="tooltip"
                                                        data-placement="top" title="More" width="20px"  style="height: 25px;width: 25px;" /></span> -->

                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                                <div *ngIf="lst.lstproduct.length>0" class="registerBgShadow col l12 m12 s12"
                                    style="text-align: center; margin-top: 10px;">
                                    <div class="row" *ngFor="let sublst of lst.lstproduct" style="padding-top: 9px;">
                                        <div class="col l4 m4 s4" style="text-align:left">
                                            <span>{{sublst.name}}</span>
                                        </div>
                                        <div class="col l3 s3 m3" style="text-align:left">
                                            <span>{{sublst.locality}}</span>
                                        </div>
                                        <div class="col s4" style="text-align:left">
                                            <span>Installation On : </span>
                                            <span>{{sublst.installationDate| date:'d MMMM y'}}</span>
                                        </div>
                                        <div class="col s1"
                                            style="text-align:left;padding-right: 0px;padding-left: 3px;">
                                            <span style="margin-left: 5px;"><img
                                                    src="../../../assets/images/servicecall.png"
                                                    (click)="btnservicecall(lst.accountid,i,sublst.productid,sublst.id,servicecallModel)"
                                                    title="Book Service Call" width="15%"
                                                    style="height: 25px;width: 25px;" /></span>
                                            <span style="margin-left: 5px;"><img
                                                    src="../../../assets/images/Location.png"
                                                    (click)="btnlocation(locationmodel,sublst.productid,lst.id,i)"
                                                    title="Location" width="15%"
                                                    style="height: 25px;width: 25px;" /></span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="lst.lstproduct.length==0" class="col l11 m11 s11 registerBgShadow"
                                    style="margin-left:50px;text-align: center;">
                                    <div class="col l12 m12 s12" style="color: red">
                                        <samp> List Not Found </samp>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <!-- <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span> -->
                        </mat-accordion>
                    </div>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="q = $event" (click)="showPageIndex(q)"></pagination-controls>
                    </span>
                </div>

            </div>
        </div>
    </div>

    <ng-template #QuotationReport>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align:right ">
                    <!-- <button (click)="modalRef.hide()" type="button"
                    class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp; -->
                    <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                </div>
            </div>
        </div>
        <div class="modal-content registerBgShadow" id="inv">
            <h5 style="text-align: center;">Quotation</h5>
            <div class="col l12 m12 s12" style="border: 1px solid black;">
                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                        <span style="font-size: 16px">{{quoReport.companyname}}</span>
                    </div>
                </div>
                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                        <span style="font-size: 16px">{{quoReport.companyaddress}}</span>
                    </div>
                </div>

                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12">

                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <tbody>
                                <tr>
                                    <td width="50%">Quotation No:&nbsp;{{quoReport.quotationCode}}</td>
                                    <td width="50%">Quotation Date:&nbsp;{{quoReport.quoDate | date:"dd-MM-yyyy"}}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Contact Person:&nbsp;{{quoReport.contactPerson}}</td>
                                    <td width="50%">Payment Terms:&nbsp;{{quoReport.paymentTerm}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row" style="margin:0px;">
                    <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px;"
                        cellpadding="7">
                        <thead>
                            <th style="text-align: left;padding-left: 10px;padding-right: 0px;">Sr.No.</th>
                            <th style="text-align: left">Description of Goods</th>
                            <th style="text-align: left">HSN</th>
                            <th style="text-align: right">Quantity</th>
                            <th style="text-align: right">Rate</th>
                            <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th> -->
                            <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th> -->
                            <th style="text-align: right">UOM</th>
                            <!-- <th style="text-align: right">Discount</th> -->
                            <th style="text-align: right;padding-right: 10px;padding-left: 0px">Total Amount</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of qutReportlst">
                                <td style="text-align: center;">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.name}}</td>
                                <td style="text-align: left">{{lst.hsn}}</td>
                                <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                <td style="text-align: right" *ngIf="lst.ratewithdiscount == null">{{lst.amount |
                                    number:'1.2-2'}}</td>
                                <td style="text-align: right" *ngIf="lst.ratewithdiscount != null">
                                    {{lst.ratewithdiscount | number:'1.2-2'}}</td>
                                <!-- <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.netamount |
                                    number:'1.2-2'}}</td>
                                <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.taxamount |
                                    number:'1.2-2'}}</td> -->
                                <td style="text-align: right">{{lst.uom}}</td>
                                <!-- <td style="text-align: right">{{lst.discount | number:'1.2-2'}}</td> -->
                                <td style="text-align: right;padding-right: 10px;padding-left: 0px">
                                    {{lst.totalproamount | number:'1.2-2'}}

                                </td>
                            </tr>
                            <!-- <tr *ngIf="model.gstseperate==true">
                                <td></td>
                                <td style="text-align: right;">Net Amount</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{quoReport.totalnetamount |
                                    number:'1.2-2'}}</td>
                            </tr>
                            <tr *ngIf="model.gstseperate==true">
                                <td></td>
                                <td style="text-align: right;">Tax Amount</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{quoReport.totaltaxamount |
                                    number:'1.2-2'}}</td>
                            </tr> -->
                            <tr>
                                <td></td>
                                <td style="text-align: right">Total Amount</td>
                                <td></td>
                                <td></td>
                                <!-- <td></td> -->
                                <td *ngIf="model.gstseperate==true"></td>
                                <td *ngIf="model.gstseperate==true"></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{quoReport.totalamount |
                                    number:'1.2-2'}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" style="margin:0px;border:1px solid black">
                    <div class="col l12 m12 s12">
                        <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{quoReport.totalinwords |
                                uppercase}}&nbsp;ONLY</span></span>

                    </div>
                </div>
                <div class="row" style="margin:0px;">
                    <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                        <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                    </div>
                </div>

                <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                    style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                    <thead style="background-color: gainsboro;">
                        <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                        <th width="70%" style="text-align: left">Description</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of reporttandclst">
                            <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                            <td style="text-align: left">{{lst.descrption}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col l12 m12 s12">
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align:right ">
                    <button (click)="modalRef.hide()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                </div>
            </div>
        </div>
    </ng-template>


    <ng-template #locationmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 16px;"><b>Modify Location</b></span>
            </div>
            <div class="row">
                <div class="">
                    <div class="col l12 m12 s12">
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div class=" input-field col s12" style="text-align: center;">
                                <span style="font-size: 15px;">Name:-<span><b>{{name}}</b></span></span>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div class=" input-field col s7" style="margin-bottom: 0px;">
                                <input [(ngModel)]="addr" #address="ngModel" type="text" id="address10" title="Address"
                                    maxlength="100">
                                <label id="lable10" for="address" class="active"> Address</label>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div class=" input-field col s5" style="margin-bottom: 0px;margin-top: 17px">
                                <input [(ngModel)]="googlemap" #address="ngModel" type="text" id="address10"
                                    title="Address" maxlength="100">
                                <label id="lable10" for="address" class="active"> Google Map Location</label>
                            </div>
                            <div class=" input-field col s3" style="margin-bottom: 0px;margin-top: 17px">
                                <input type="text" class="autocomplete" [(ngModel)]="pincode" id="pincode2"
                                    placeholder="Pincode" (keyup)="getPincodeLikeforproduct($event)" />
                                <input type="hidden" id="acc-id" />
                            </div>
                            <div class=" input-field col s3" style="margin-top: 0px;margin-bottom: 0px">
                                <span>Locality</span>
                                <select (change)=" onSelectproductlocality($event.target.value)">
                                    <option *ngFor="let type of lstlocalityforproduct" [value]="type.id"
                                        [selected]="type.name==locality">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">

                            <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                                <span>Locale:-</span><br><span><b>{{locale}}</b></span>
                            </div>
                            <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                                <span>City:-</span><br><span><b>{{city}}</b></span>
                            </div>
                            <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                                <span>State:-</span><br><span><b>{{state}}</b></span>
                            </div>
                            <div class=" input-field col s2" style=" padding: 6px;border: 1px solid black;">
                                <span>Country:-</span><br><span><b>{{country}}</b></span>
                            </div>
                            <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                                <span>Area:-</span><br><span><b>{{areaname}}</b></span>
                            </div>
                            <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                                <span>Region:-</span><br><span><b>{{regionname}}</b></span>
                            </div>
                        </div>
                        <!-- <div class="input-field col s5">
                        <span>Machine Status</span>
                        <select (change)="onSelectmachinestatus($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Machine Status">
                            <option>Select Machine Status</option>
                            <option *ngFor="let lst of machiestatuslst" [value]="lst.id"
                                [selected]="lst.name==serviceCallStatusName">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s5">
                        <input *ngIf="amcddateflag" type="text" placeholder="AMC End Date" [(ngModel)]="amcdEndDate"
                            class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">

                        <input *ngIf="warrentydateflag" type="text" placeholder="Warranty End Date"
                            [(ngModel)]="warrentyEndDate" class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">

                    </div> -->

                        <div class="row">
                            <div class="input-field col l12 m12 s12" style="text-align: right">

                                <button (click)="btnclose()" type="button"
                                    class="waves-effect cancelbutton btn">Cancel</button> &nbsp;&nbsp;
                                <button (click)="btnaddproductaddress()" type="button"
                                    class="waves-effect waves-light btn">Save</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #servicecallModel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 16px;"><b>Service Call Details</b></span>
            </div>
            <div class="row">
                <div class="row col l12 m12 s12" style="text-align: center;">
                    <div class="col l6 m6 s6" style="text-align: center">
                        <span style="font-size: 15px;"> There is an Visit call pending</span> <br>
                        <span style="font-size: 15px;"> Call No :-<b>{{serviceNO}}</b> </span> <br>
                    </div>
                    <div class="input-field col l6 m6 s6" style="text-align: right">
                        <button (click)="btnclose()" type="button" class="waves-effect cancelbutton btn">Cancel</button>
                        &nbsp;&nbsp;
                        <button (click)="btnservicecalledit()" type="button"
                            class="waves-effect waves-light btn">Continue
                            with Call</button>
                    </div>
                </div>

                <div class="row">

                </div>
            </div>
        </div>
    </ng-template>
</div>