
export class DefinePurchaseReturnModel {
    suplierid: number;
    suppliername: string;

    returnproductlst: ReturnModel[] = []
}
export class ReturnModel {
    itemid: number;
    itemcode: string;
    itemname: string;
    rate: number;
    recvqnty: number;
    gstgrpid: number;
    gstamnt: number;
    netamnt: number;
    totalamnt: number;
    inventorylst: InventoryItem[] = []
}
export class InventoryItem {
    id: number;
    srno:number;
    locationid: number;
    location: string;
    rejectqnty: number;
    stock: number;
}

