import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchitemSubCategoryModel } from "./SearchitemSubCategoryModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'SearchitemSubCategory.html'
})
export class SearchitemSubCategory implements OnInit {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {
    }
    public i;
    p: any;
    model = new SearchitemSubCategoryModel();
    itemCatlst: SearchitemSubCategoryModel[] = [];
    itemCatlst1: SearchitemSubCategoryModel[] = [];
    user = new User();
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Item Category" },
        { id: 3, "name": "Sub Category Code" },
        { id: 4, "name": "Sub Category Name" },

    ];
    ngOnInit(): void {
        this.getAllitemCat();
    }
    getAllitemCat() {

        this.Masterservice.getAllitemCat(this.user)
            .subscribe(value => {
                this.itemCatlst = value
                this.itemCatlst1 = [];
                value.forEach((item) => this.itemCatlst1.push({ "id": item.id, "subcategorycode": item.subcategorycode, "subcategoryname": item.subcategoryname, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect ,"itemcategoryname":item.itemcategoryname}));
                this.itemCatlst = this.itemCatlst1;

            })
    }
    onSelectcategory(value) {
        this.model.txtselect = value;
    }
    btnsearch(id) {
        if (this.model.txtselect == "All") {
            this.getAllitemCat()
            this.model.txtsearch = null;
        }
        else if (this.model.txtselect == "Item Category") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Item Category to Search")
                return;
            }
            else {
                this.itemCatlst1 = [];
                this.itemCatlst.forEach((item) => {
                    if ((item.itemcategoryname.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemCatlst1.push({ "id": item.id, "subcategorycode": item.subcategorycode, "subcategoryname": item.subcategoryname, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect ,"itemcategoryname":item.itemcategoryname });
                    }
                });
                this.itemCatlst = this.itemCatlst1;
            }
        }
        else if (this.model.txtselect == "Sub Category Code") {
            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Sub Category Code To Search")
                return;
            }
            else {
              
                this.itemCatlst1 = [];
                this.itemCatlst.forEach((item) => {
                    if ((item.subcategorycode.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemCatlst1.push({ "id": item.id, "subcategorycode": item.subcategorycode, "subcategoryname": item.subcategoryname, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect ,"itemcategoryname":item.itemcategoryname });
                    }
                });
                this.itemCatlst = this.itemCatlst1;
            }
        }
        else if (this.model.txtselect == "Sub Category Name") {
            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Sub Category Name To Search")
                return;
            }
            else {
              
                this.itemCatlst1 = [];
                this.itemCatlst.forEach((item) => {
                    if ((item.subcategoryname.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemCatlst1.push({ "id": item.id, "subcategorycode": item.subcategorycode, "subcategoryname": item.subcategoryname, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect ,"itemcategoryname":item.itemcategoryname });
                    }
                });
                this.itemCatlst = this.itemCatlst1;
            }
        }


    }
    btnadd(id) {
        this.router.navigate(['/newDefineitemSubCategory']);
    }
    btnedit(id) {
        this.router.navigate(['/editDefineitemSubCategory', id]);

    }
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
                this.Masterservice.dltItemsubCategory(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }

    }
}