export class CityMasterModel {
    id: number;
    code: string;
    name: string;
    country: string;
    state: string;
    txtserch:string;
    txtselect:string;
    countryid: number;
    stateid: number;
    userid:number;
    citylst:number[] =[];
    region:string;
    totalItems:number;
}