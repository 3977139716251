import { Component, OnInit, TemplateRef } from "@angular/core";
import { AlertService, MasterServices, TransactionServices } from "src/app/_services";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { NotificationService } from "src/app/_services/notification.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { DatePipe } from "@angular/common";
import { Location } from '@angular/common';
import { SalesdashboardModel } from "./SalesdashboardModel";

@Component({
  selector: 'app-login',
  templateUrl: 'Salesdashboard.html'
})

export class Salesdashboard implements OnInit {
  model: any = {};
  id: number;
  saleslst: SalesdashboardModel[];
  nextdayslst: SalesdashboardModel[];
  approvedlst: SalesdashboardModel[];
  futureDate = new Date();
  public p: any;
  modalRef: BsModalRef;
  value: number;
  valueall: number;
  Total: number;

  constructor(private Masterservice: MasterServices, private transactionService: TransactionServices, private modalService: BsModalService, private datePipe: DatePipe, private alertService: AlertService,
    private transactionservice: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
  }


  ngOnInit(): void {
    this.getactivityoverdue();
    this.getNextdaysactivity();
    this.getApprovedactivity();
    this.model.userid = Number(localStorage.getItem("userid"));

  }
  getactivityoverdue() {

    this.model.dueDate = new Date();
    this.model.openCloseStatus = "Y";
    this.model.tbAssignedBy = Number(localStorage.getItem("userid"));;
    this.transactionService.getactivityoverdue(this.model)
      .subscribe(
        data => {
          this.model = data;
          this.saleslst = data.saleslst



          for (let i = 0; i < this.saleslst.length; i++) {
            // this.value = this.saleslst.length 
            this.futureDate.setDate(this.futureDate.getDate() + this.saleslst[i].creditdays);
          }
          for (let i = 0; i < this.saleslst.length; i++) {
            if (this.futureDate < this.saleslst[i].dueDate) {
              this.saleslst[i].openCloseStatus = "N"
            } else {
              this.saleslst[i].openCloseStatus = "Y"
            }
            // alert(this.saleslst[i].openCloseStatus)
          }
        });
  }

  onclickenquiryId(id) {
    this.router.navigate(['/Opportunities', id]);
  }
  onclickenquiryid(id) {
    this.router.navigate(['/Opportunities', id]);
  }

  // openOverduemodel(overduemodel: TemplateRef<any>) {
  //   this.model.dueDate = new Date();
  //   this.model.openCloseStatus = "Y";
  //   this.model.tbAssignedBy = 25;
  //   // this.model.genactivity = "Technical discussion";
  //   this.transactionService.getactivityoverdue(this.model)
  //     .subscribe(
  //       data => {
  //         this.model = data;
  //         this.saleslst = data.saleslst
  //       });
  //   this.modalRef = this.modalService.show(overduemodel, {
  //     class: 'modal-lg'
  //   });
  // }


  getNextdaysactivity() {
    this.model.openCloseStatus = "Y";
    this.model.tbAssignedBy = Number(localStorage.getItem("userid"));;
    this.transactionService.getNextdaysactivity(this.model)
      .subscribe(
        data => {
          this.nextdayslst = data
          for (let i = 0; i < this.nextdayslst.length; i++) {

          }

        });
  }

  getApprovedactivity() {
    this.model.userid = Number(localStorage.getItem("userid"));
    this.model.approveflag = "N";
    this.transactionService.getApprovedactivity(this.model)
      .subscribe(
        data => {
          this.approvedlst = data


        });
  }
  btnedit(enqid) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "enqid": enqid,
        "flag": "Approve",
      }
    };
    this.router.navigate(['/PrepareQuotation'], navigationExtras);
  }
  getAccountsReceivables() {
    this.transactionService.getAccountsReceivables(this.model)
      .subscribe(
        data => {
          // this.approvedlst = data


        });
  }
  seeall()
  {
    this.router.navigate(['/Quotationdashboard']);
  }

}