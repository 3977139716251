export class SearchAccountWiseMachineDetailModel{
    id: number;
    descrption: string;
    name:string;
    productCode:string;
    serviceCallStatusName:string;
    amcWarrantyStartDate: Date;
    amcWarrantyEndDate: Date;
    accountid:number;
    userid:number;
    
}