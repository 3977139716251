<app-header></app-header>

<div class="container">
  <div class="row">
    <div class="col l12 m12 s12">
      <div class="col l2 m2 s2"></div>
      <div class="heading col l8 m8 s8">
        <span style="font-size: 25px;">Menu By User</span>
      </div>
      <div class="col s12 m12 l12 registerBgShadow">
        <div class="col s12 l12 m12" style="text-align: center;font-size: 14px;">
          <b> {{menuname}}</b>
        </div>
        <div class=" row" style="margin-top: 30px;">
          <div class="input-field col s2 l2 m2">
          </div>
          <div class="input-field col l4 m4 s4" style="padding-left: 17px;">
            <input [(ngModel)]="marked" #code1="ngModel" type="checkbox" class="" data-toggle="tooltip"
              appForbiddenName="bob" data-placement="center" title="submenu" id="submenu" name="submenu"
              (change)="toggleIssubmenu($event)"> &nbsp;Is sub menu
          </div>

          <div class="col s1 l1 m1">
          </div>
          <div class="col s12 m12 l12">
            <div class="input-field col s2 l2 m2">
            </div>
            <div class="input-field col s4 l4 m4">
              <select (change)="onChangeModule($event.target.value)" *ngIf="!this.subpage">
                <option selected>Select Module</option>
                <option *ngFor="let menulb of modulelst" [value]="menulb.id" [selected]="menulb.name==model.modulename">
                  {{menulb.name}}</option>
              </select>
              <label id="lable2" for="code1">Module Name</label>
              <input *ngIf="this.subpage" [(ngModel)]="model.pagename" #code="ngModel" type="text" id="Pagename"
                title="pagename" maxlength="25">
              <label id="lable4" *ngIf="this.subpage" for="first2">Module Name </label>

            </div>
            <div class="input-field col l4 m4 s4" style="text-align: center;" *ngIf="!this.subpage">
              <select id="menuname" name="menuname" (change)="onChangeMenu($event.target.value)">
                <option selected>Select Menu Item</option>
                <option *ngFor="let menulb of menuName" [selected]="menulb.dspname==model.pagename" [value]="menulb.id">
                  {{menulb.dspname}}</option>
              </select>
              <label id="lable2" for="code1">Menu Name</label>
            </div>
          </div>
          <div class="col s12 m12 l12">
            <div class="input-field col s2 l2 m2">
            </div>
            <div class="input-field col s4 l4 m4">
              <input [(ngModel)]="model.sequence" #code="ngModel" type="number" id="sequence" title="Sequence"
                maxlength="35" (ngModelChange)="changeseq()">
              <label id="lable4" for="first2">Sequence </label>
            </div>
            <div class="input-field col l4 m4 s4" style="text-align: center;" *ngIf="!this.subpage">

              <input [(ngModel)]="landpage" #code1="ngModel" type="checkbox" class="" data-toggle="tooltip"
                appForbiddenName="bob" data-placement="center" title="landpage" id="landpage" name="landpage"
                (change)="togglelandpage($event)">&nbsp;Landing page

            </div>
          </div>
          <div class="col s3 l3 m3">
          </div>
          <div class="col s12 m12 l12" style="text-align: center;">
            <div class="row" style="padding-bottom: 5px;">
              <div class="input-field col l6 m6 s6" style="text-align: right">
                <button type="button" (click)="btnsave()" class="waves-effect waves-light btn"
                  style="margin-right: 5px;">Save</button>
              </div>
              <div class="input-field col l6 m6 s6" style="text-align: left">
                <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                  (click)="cancle()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>