import { Component, OnInit } from '@angular/core';
import { MasterServices, AlertService, TransactionServices, AuthenticationService } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
// import { NotificationService } from 'src/app/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { EncrDecrService } from 'src/app/_services/EncrDecrService';
import { User } from 'src/app/_models';
import { NotificationService } from 'src/app/_services/notification.service';
import { DeviceDetectorService } from 'ngx-device-detector';

declare var $: any;
@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'ChangePassword.html'
})
export class ChangePassword implements OnInit {
    user: User;
    model: any = {};
    return: any;
    str: any;
    pagename: string;
    isMobile: boolean;
    deviceInfo = null;

    constructor(private Masterservice: MasterServices, private authenticationService: AuthenticationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private EncrDecr: EncrDecrService, private transactionService: TransactionServices, private location: Location, private notifyService: NotificationService, private deviceService: DeviceDetectorService) {
        this.pagename = this.router.url
    }

    ngOnInit() {
        localStorage.setItem("pagename", this.pagename);
        this.model.id = Number(localStorage.getItem('userid'));
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        console.log(this.deviceInfo);
        console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        console.log(isDesktopDevice);
        // this.user.token = localStorage.getItem('Token');
        // this.Masterservice.checklogin(this.user).subscribe(
        //   data => {
        //     if (data.message == 'login again') {
        //         this.notifyService.showRroor("Session Expired !", "Error !");
        //       this.authenticationService.logout();
        //       this.router.navigate(['/Redirect']);
        //     }
        //   });
    }


    btnsave() {
        if (this.model.pass == null || this.model.pass == "") {
            this.notifyService.showRroor("Password Reqired !!", " Oops!");
            return;
        } else if (this.model.password == null || this.model.password == "") {
            this.notifyService.showRroor("Confirm Password Reqired !!", " Oops!");
            return;
        }
        else if (this.model.pass != this.model.password) {
            this.notifyService.showRroor("Password is Incorrect !!", " Oops!");
            return;
        } else {
            var encrypted = this.EncrDecr.set('UMJ3wfzn@kMpARd', this.model.pass);
            this.model.password = encrypted;

            this.Masterservice.savepassword(this.model).subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                        this.router.navigate(["CRMHome"]);
                    } else {
                        this.notifyService.showRroor(data.message, " Oops!");
                    }
                });
        }

    }
    cancle() {
        this.router.navigate(["CRMHome"]);


    }

}

