export class SchemeConfigurateModel {

    id: number;
    code: string;
    name: string;
    userid: number;
    customertype:string;
    customertypeid: number;
    description: string;
    geographicflag: boolean;
    countryid: number;
    regionflag: boolean;
    regionid: number;
    areaflag: boolean;
    areaid: number;
    cityflag: boolean;
    cityid: number;


    percentageflag: boolean;
    percentage: number;
    percentagevalueflag: boolean;
    perTrnValue: number;
    percentagevaluediscflag: boolean;
    perMaxValue: number;


    valueflag: boolean
    value: number;
    customflag:boolean;
    custom:number;
    trnvalueflag: boolean
    trnvalue: number;

    single: boolean;
    serial: boolean;
    couponType: string

    quanType: string;
    quaUnlimited: boolean;
    qualimited: boolean;
    qualimitedvalue: number;

    schemeUnlimited: boolean = false;
    schemelimited: boolean = false;
    schemeValidity: string;
    schemedays: number;

    fromdate: Date;
    todate: Date;

    forcloseflag: boolean;
    foreclosedate: Date;
    foreclosereason: string;

    status: string;
    message: string;

    schemeDiscount: number;
    offereddiscount:number;
    maxdiscount: number;
    discountType: string;
    schemetype:string;
    areaname:string;
    cityname:string;
    prefix:string;
    postfix:string;
}