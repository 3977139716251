import { OnInit, Component, OnDestroy } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { DefineTypeMasterModel } from './DefineTypeMasterModel';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';

@Component({
    selector: 'app-DTypeMaster',
    //  moduleId: module.id,
    templateUrl: 'DefineTypeMaster.html'
})
export class DefineTypeMaster implements OnInit,OnDestroy {
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
    }
    id: number
    model = new DefineTypeMasterModel();
    pagename: string;
    reasonlst: DropDownModel[] = [
        { id: 0, "name": "Choose one" },
        { id: 1, "name": "Industry Type" },
        { id: 2, "name": "Annual Turnover" },
        { id: 3, "name": "No. of Employee" },
        { id: 4, "name": "Constitution" },
        { id: 5, "name": "Loss Reason" }
    ];
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit(): void {
        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.Masterservice.getTypeMasterById(user)
                .subscribe(
                    data => {
                        this.model = data;
                        if (data.reason == "Industry Type") {
                            this.model.reasonFull = "Industry Type"
                        } else if (data.reason == "Annual Turnover") {
                            this.model.reasonFull = "Annual Turnover"
                        } else if (data.reason == "No. of Employee") {
                            this.model.reasonFull = "No. of Employee"
                        } else if (data.reason == "Constitution") {
                            this.model.reasonFull = "Constitution"
                        } else if (data.reason == "Loss Reason") {
                            this.model.reasonFull = "Loss Reason"
                        }
                    });
        }

    }


    onSelectReason(value) {
        if (value == "Choose one") {
            this.notifyService.showRroor("Please select valid Reason Type !!", "Oops!");
            return;
        }
        if (value == "Industry Type") {
            this.model.reason = "AI"
        } else if (value == "Annual Turnover") {
            this.model.reason = "AT"
        } else if (value == "No. of Employee") {
            this.model.reason = "NE"
        } else if (value == "Constitution") {
            this.model.reason = "CO"
        } else if (value == "Loss Reason") {
            this.model.reason = "LR"
        }
    }

    btnback() {
        this.router.navigate(['/Types']);
    }

    btnsaveType() {
        if (this.model.reason == null) {
            this.notifyService.showRroor("Please select type", "Oops!");
            return;
        }
        if (this.model.name == null) {
            this.notifyService.showRroor("Please enter name", "Oops!");
            return;
        }
        if (this.model.description == null) {
            this.notifyService.showRroor("Please enter description", "Oops!");
            return;
        }
        this.Masterservice.btnsaveType(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['/Types']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
}