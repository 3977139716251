import { DropDownModel } from "src/app/_models/DropDownModel";

export class DefinedlrcontModel {
    id: number
    id1: number
    contactName: String;
    designation: String;
    designationID: number;
    mobileNo1: number;
    mobileNo2: number;
    email: String;
    chkselectvalue: boolean;
    desiglst: DropDownModel[];
    countrylst: DropDownModel[];
    country1ID: number;
    country2ID: number;
    dealerid: number;
    chkselectlogin:boolean;
    username:string;
    password:string;
    userid:number;
    pass:string;
    contactlst: DefinedlrcontModel[] = [];
    usergroupid:number;
    usergroup:string;
}