<app-header></app-header>
<script>
    $(document).ready(function () {
        Materialize.updateTextFields();
    });
</script>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">City</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col l3 m3 s3">
                    <span>Country</span>
                    <select (change)="onSelectCountry($event.target.value)">
                        <option selected value="0">Select Country</option>
                        <option *ngFor="let type of countrylst" [value]="type.id"
                            [selected]="type.id == model.countryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3">
                    <span>State</span>
                    <select (change)="onSelectState($event.target.value)">
                        <option selected value="0">Select State</option>
                        <option *ngFor="let type of statelst" [value]="type.id" [selected]="type.id == model.stateid">
                            {{type.name}}</option>
                    </select>
                </div> 
                <div class="col l1"></div>
            </div>
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col l5 m5 s5">
                    <input [(ngModel)]="model.code" #code="ngModel" type="text" class="" id="Citycode"
                        data-toggle="tooltip" data-placement="top" maxlength="5" title="City Code">
                    <label for="Citycode" class="active">City Code</label>
                </div>
                <div class="input-field col l5 m5 s5">
                    <input [(ngModel)]="model.name" #name="ngModel" type="text" class="" id="Cityname"
                        data-toggle="tooltip" data-placement="top" title="City Name" maxlength="30">
                    <label for="Cityname" class="active">City Name</label>
                </div>
                <div class="col l1"></div>
            </div>
            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button (click)="btnback()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnsaveCity()" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Save</button>
                </div>
                <!-- <div class="input-field col l6 m6 s6" style="text-align: left">
                </div> -->

            </div>
        </div>
    </div>
</div>