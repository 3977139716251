export class DesignationAllowanceModel{
    id: number;
    allowancename:string;
    designationid:number;
    type:string;
    value:number;
    frequunit:string;
    unitid:number;
    frequency:string;
    calculate:string;
    frequday:string;
    linkedallowanceID:number;
    userid:number;
    modellst:DesignationAllowanceModel[]=[];
    mapcheckselect:boolean;
    checkselect:boolean;
}