<app-header></app-header>
<div class="container" style="width:80%">
    <div class="row">
        <div class="col l12 m12 s12">

            <div class="col l2 m2 s2"></div>
            <div class="heading col 8 m8 s8">
                <span style="font-size: 30px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>


        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col s1"></div>
                        <div class=" input-field col s4" style=" margin-top: 30px; text-align: left;" >
                            <span>Designation:</span>
                            <!-- <label id="lable1" for="first1">{{lst.designation}}</label> -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l12 m12 s12">

                            <div class="col l12 m12 s12" style="height: 500px; overflow: auto;">

                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="5%" style="text-align: left">Select</th>
                                        <th width="10%" style="text-align: left">Allowance Type</th>
                                        <th width="10%" style="text-align: left">Frequency</th>
                                        <th width="10%" style="text-align: left">Frequency Unit</th>
                                        <th width="5%" style="text-align: center">Value</th>
                                        <th width="10%" style="text-align: left">Type</th>
                                        <th width="5%" style="text-align: left">Unit</th>
                                        <th width="10%" style="text-align: left">Calculate</th>
                                        <th width="10%" style="text-align: left">Map Use</th>
                                        <th width="15%" style="text-align: center">Rate</th>
                                        <th width="10%" style="text-align: center">Linked Allowance</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of modellst">
                                            <td style="text-align: center;"><input type="checkbox"
                                                    [(ngModel)]="lst.checkselect" #checkselect="ngModel"></td>
                                            <td style="text-align: left">{{lst.allowancename}}</td>
                                            <td style="text-align: center">{{lst.frequency}}</td>
                                            <td style="text-align: center">{{lst.frequunit}}</td>
                                            <td style="text-align: center">{{lst.frequday}}</td>
                                            <td style="text-align: center">{{lst.type}}</td>
                                            <td style="text-align: center">{{lst.unitid}}</td>
                                           
                                            <td style="text-align: center">{{lst.calculate}}</td>
                                            <td style="text-align: center;"><input type="checkbox"
                                                [(ngModel)]="lst.mapcheckselect" #mapcheckselect="ngModel"></td>
                                            <td style="text-align: center;"><input [(ngModel)]="lst.value"
                                                #rate="ngModel" type="number" id="value"
                                                data-toggle="tooltip" data-placement="top" placeholder="Rate">
                                            </td>
                                            <td style="text-align: center">{{lst.linkedallowanceID}}</td>
                                           
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col l11 m11 s11" style="text-align: right">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                            (click)=" cancle()">Cancel</button>
                            <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                                style="margin-right: 5px;">Save</button>
                           
                            <div class="col s1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


