import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'app-home',
    //  moduleId: module.id,
    templateUrl: 'CRMHome.html'
})
export class CRMHome implements OnInit {

    pagename:string;
    hashkey:string;
    constructor(
        private route: ActivatedRoute,
        private router: Router) {
         }
    ngOnInit(): void {
        // this.route.queryParams.subscribe(params => {
        //     this.pagename = params["url"];
        //     // this.hashkey = params["hashkey"];
        // });

      
        // if( this.pagename==null){
        //     this.router.navigate(["login"]);
        // }else{
        //     alert( this.pagename)
        //     // let navigationExtras: NavigationExtras = {
        //     //     queryParams: {
        //     //       "hashkey":this.hashkey ,
        //     //     }
        //     //   };
        //       this.router.navigate([this.pagename]);
        // }

        // this.pagename=router.url;

    }


}