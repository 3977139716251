<app-header></app-header>
<script>
    function myFunction(x) {
        unhighlight()
        x.style.backgroundColor = '#cccccc';
    }
</script>

<div class="container" style="width: 90%;">
   

    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Dealers</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow" style="padding-bottom:9px;">
            <div class="col s12 l12 m12">
                <div class="tabtab">
                    <button class="tablinks" id="details" onclick="openCity(event, 'Details')">Details
                    </button>
                    <button class="tablinks" id="products" onclick="openCity(event, 'Products')">Products</button>
                    <button class="tablinks" id="geography" onclick="openCity(event, 'Geography')">Geography</button>
                    <button class="tablinks" id="contacts" onclick="openCity(event, 'Contacts')">Contacts</button>
                    <button class="tablinks" id="logistics" onclick="openCity(event, 'Logistics')">Logistics</button>
                </div>
            </div>


            <div class="loader" style="position: absolute;top: 70%;left: 50%;" *ngIf="loading"></div>
 
            <div id="Details" class="tabcontent col l12 m12 s12 registerBgShadow">
                
                <div class="row">

                    <div class=" input-field col s3" style=" margin-top: 30px;">
                        <input [(ngModel)]="model.code" #code="ngModel" type="text" id="code" title="Code"
                            maxlength="35">
                        <label id="lable1" for="first1">Code </label>
                    </div>
                    <div class=" input-field col s5" style=" margin-top: 30px;">
                        <input [(ngModel)]="model.name" #name="ngModel" type="text" id="Name" data-toggle="tooltip"
                            data-placement="top" title="Name" onkeypress="return /[A-Za-z_ ]/i.test(event.key)" ngModel
                            maxlength="35" required>
                        <label id="lable2" for="first2"> Name </label>
                    </div>
                    <div class="input-field col s4">
                        <span>Status</span>
                        <select (change)="onSelectStatus($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Status">
                            <!-- <option selected value="0">Select One</option> -->
                            <option *ngFor="let lst of statuslst" [value]="lst.name"
                                [selected]="lst.name==model.status">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class=" input-field col s8" style="margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.address" #address="ngModel" id="address" type="text"
                            data-toggle="tooltip" data-placement="top" title="Address" required>
                        <label id="lable3" for="first3">Address </label>
                    </div>
                    <div class=" input-field col s2" style="margin-top: 0px;margin-bottom: 0px;">
                        <input type="text" class="autocomplete" [(ngModel)]="model.pincode" id="pincode1"
                            placeholder="Pincode" (keyup)="getPincodeLike($event)" />
                        <input type="hidden" id="acc-id" />
                        <label id="lable4"></label>
                    </div>
                    
                    <!-- <div class="ng-autocomplete pinRowgrp input-field col s2" style="margin-top: 0px;margin-bottom: 0px;">
                        <ng-autocomplete [data]="pincodelist" type="text" [(ngModel)]="model.pincode" placeholder="Enter the pincode"
                          [searchKeyword]="keyword" [minQueryLength]="3"  id="pincode1"
                          (inputChanged)='onChangeSearch($event)'  (selected)='selectEvent($event)'  (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate1" [notFoundTemplate]="notFoundTemplate1">
                        </ng-autocomplete>
      
                        <ng-template #itemTemplate1 let-item>
                          <a [innerHTML]="item.name"></a>
                        </ng-template>
      
                        <ng-template #notFoundTemplate1 let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div> -->
                </div> 
                <div class="row">
                   
                    <div class=" input-field col s3" style="margin-top: 0px;">
                        <span>Locality</span>
                        <select (change)=" onSelectlocality($event.target.value)">
                            <!-- <option selected value="0">Select Locality</option> -->
                            <option *ngFor="let type of lstlocality" [value]="type.id"
                                [selected]="type.name==model.locality">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="col s1"></div>
                    <div class="col s3" style="margin-top: 23px;">
                        <div><span style="font-size: 13px; font-weight: bold">City:-</span> <label
                                for="first4">{{model.city}} </label></div>

                    </div>
                    <div class="col s3" style="margin-top: 23px;">
                        <div><span style="font-size: 13px; font-weight: bold">State:-</span> <label
                                for="first5">{{model.state}} </label></div>

                    </div>
                    <div class="col s2" style="margin-top: 23px;">
                        <div><span style="font-size: 13px; font-weight: bold">Country:-</span><label
                                for="first6">{{model.country}} </label></div>

                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s4" style="margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.googlemaplocation" #googlemaplocation="ngModel" id="googlemaplocation"
                            type="text" data-toggle="tooltip" data-placement="top" title="Google Maps Location"
                            required>
                        <label id="lable5" for="first7">Google Maps Location </label>
                    </div>
                    <div class=" input-field col s4" style="margin-top: 0px;margin-bottom:0px;">
                        <input type="text" pattern="[6789][0-9]{9}" [(ngModel)]="model.landline" #landline="ngModel"
                            min="0" id="first8" data-toggle="tooltip" data-placement="top" maxlength="10"
                            title="Mobile Number" required>

                        <label id="lable6" for="first8" class="active">Mobile Number</label>
                        <div *ngIf="landline.invalid && (landline.dirty || landline.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="landline.errors.required">
                                Mobile Number Required
                            </div>
                            <div [hidden]="!landline.errors?.pattern">
                                Invalid Mobile Number
                            </div>
                        </div>
                    </div>
                    <div class=" input-field col s4 m4 l4" style="margin-top: 0px;margin-bottom:0px;">
                        <input type="text" [(ngModel)]="model.email" #email="ngModel"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="first9" data-toggle="tooltip"
                            data-placement="top" title="Email" required>
                        <label id="lable7" for="first9">Email</label>
                        <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="email.errors.required">
                                Email Required
                            </div>
                            <div [hidden]="!email.errors?.pattern">
                                Invalid Email-Id
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s4" style="margin-top: 18px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.website" #website="ngModel" id="website" type="text"
                            data-toggle="tooltip" data-placement="top" title="Website" required>
                        <label id="lable8" for="first10">Website</label>
                    </div>

                    <div class=" input-field col s4" style="margin-top: 0px;margin-bottom: 0px;">
                        <span>Constitution</span>
                        <select (change)=" onSelectconstutution($event.target.value)">
                            <option *ngFor="let type of lstconstutution" [value]="type.id"
                                [selected]="type.name==model.constitutionName">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s4" style="text-align: left;margin-top: 0px;margin-bottom: 0px;">
                        <span>Default Currency</span>
                        <select (change)=" onSelectDefaultcurrency($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Default Currency">
                            <option selected value="0">Select Default Currency</option>
                            <option *ngFor="let type of defaultcurrencylst" [value]="type.id"
                                [selected]="type.id == model.defaultCurrency">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col l1 m2 s2" style="margin-top: 31px;">
                        <input type="checkbox" [(ngModel)]="model.checkselectegstn"
                            #checkselectegstn="ngModel">&nbsp;&nbsp;Gst
                    </div>
                    <div class="input-field col s3" *ngIf="model.checkselectegstn">
                        <input [(ngModel)]="model.gstNo" #gstNo="ngModel" id="gstNo" type="text" min="0"
                            data-toggle="tooltip" data-placement="top" title="GST No." ngModel maxlength="15" required>
                        <label id="lable9" for="first12">GST No. </label>
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 10px;" *ngIf="model.checkselectegstn">
                        <span><b>GST Type</b></span>&nbsp;&nbsp;
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkwihitins"
                                (click)="onchkwihitins($event)" />
                            <span style="font-size: 120%">Within State</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 28px;" *ngIf="model.checkselectegstn">
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkoutsides"
                                (click)="onchkoutsides($event)" />
                            <span style="font-size: 120%">Outside State</span>
                        </label>
                    </div>
                    <div class=" input-field col s3">
                        <input [(ngModel)]="model.panNo" #panNo="ngModel" id="panNo" type="text" min="0"
                            data-toggle="tooltip" data-placement="top" title="PAN No." ngModel maxlength="35" required>
                        <label id="lable10" for="first13">PAN No.</label>
                    </div>
                </div>

                <div class="row">
                    <div class=" input-field col s12" style="margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.about" #about="ngModel" id="about" type="text" data-toggle="tooltip"
                            data-placement="top" title="About" required>
                        <label id="lable11" for="first11">About</label>

                    </div>
                </div>
                <div class="row">
                    <!-- <div class="input-field col s3 m3 l3" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>User Group</span>
                        <select (change)="onSelectusergroup($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="User Group">
                            <option selected value="0">Select User Group</option>
                            <option *ngFor="let lst of usergrouplst" [value]="lst.id"
                                [selected]="lst.id==model.usergroupid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div> -->
                    <div class="input-field col s3" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>Payment</span>
                        <select (change)="onSelectPayment($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Payment Terms">
                            <option selected value="0">Select One</option>
                            <option *ngFor="let lst of paymenttermslst" [value]="lst.name"
                                [selected]="lst.name==model.paymentType">
                                {{lst.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s2" style="margin-top: 20px;"
                        *ngIf="model.paymentType=='Against Order'">
                        <input [(ngModel)]="model.creditAmount" #creditAmount="ngModel" id="creditAmount" type="number"
                            min="0" oninput="validity.valid||(value='');" data-toggle="tooltip" data-placement="top"
                            title="Credit Limit" ngModel maxlength="35" style="text-align: right;"
                            (change)="onChangeOfCreditlimit(model.creditAmount)" required>
                        <label id="lable12" for="first14">Credit Limit </label>
                    </div>
                    <div class=" input-field col s2" style="margin-top: 20px;"
                        *ngIf="model.paymentType=='Against Order'">
                        <input [(ngModel)]="model.creditDays" #creditDays="ngModel" id="creditdays" type="number"
                            min="0" oninput="validity.valid||(value='');" data-toggle="tooltip" data-placement="top"
                            title="Credit Days" ngModel maxlength="35" style="text-align: right;" required>
                        <label id="lable13" for="first15">Credit Days </label>
                    </div>
                    <div class="input-field col s3" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>Delivery Location</span>
                        <select (change)="onSelectDelivery($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Delivery Location">
                        <option selected value="0">Select One</option>
                        <option *ngFor="let lst of locationlst" [value]="lst.id" [selected]="lst.id==model.deliverylocid">
                        {{lst.name}}</option>
                    </select>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn"
                            (click)="btncancledetail()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="savedealers()" class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>





            <div id="Products" class="tabcontent col l12 m12 s12 registerBgShadow">
                <div class="row">
                    <div class="input-field col l3 m3 s3">
                        <span>Category</span>
                        <select (change)="onSelectcategory($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Product Category">
                            <option selected value="0">Select Category</option>
                            <option *ngFor="let lst of productcatelst" [value]="lst.id"
                                [selected]="lst.id==model.productcategoryid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col l1 m1 s1" style="margin-top: 40px;margin-left: 0px;">
                        <input type="checkbox" [(ngModel)]="model.selectall" #selectall="ngModel"
                            (click)="selectallproduct($event)">&nbsp;&nbsp;Select All
                    </div>
                    <!-- <div class=" input-field col s3" style="margin-top: 30px;">
                        <input type="text" class="autocomplete" [(ngModel)]="model.productName" id="productname1"
                            placeholder="Product Name" (keyup)="getProductLike()" />
                        <input type="hidden" id="acc-id" />
                    </div> -->
                    <div class="ng-autocomplete pinRowgrp input-field col s3" style="margin-top: 30px;">
                        <ng-autocomplete [data]="userList2" type="text" placeholder="Product Name"
                            [searchKeyword]="keyword2" [minQueryLength]="3" [(ngModel)]="model.productName"
                            id="productname1" (inputChanged)='onChangeSearchproduct($event)'
                            (selected)='selectEventfordealer($event)' (inputFocused)='onFocusedforSearchproduct($event)'
                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.productName"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>

                    </div>



                    <div class="col s2">
                        <div class="row">
                            <div class=" input-field col s10" style="margin-top: 30px;text-align: right; padding: 0px;">
                                <input [(ngModel)]="model.commission" #commission="ngModel" id="commission" type="text"
                                    min="0" oninput="validity.valid||(value>='100');" data-toggle="tooltip"
                                    data-placement="top" style="text-align: right;" title="Commission" ngModel
                                    maxlength="35" required>
                                <label id="lable16" for="first16">Commission % </label>
                            </div>
                            <div class=" input-field col s1" style="margin-top: 40px;padding: 0px;">&#37;</div>
                        </div>
                    </div>
                    <div class="right-align col l3 m3 s3" style=" margin-top: 30px;text-align: right;">
                        <button class="waves-ripple waves-light btn" type="button" (click)="addproduct()">
                            Add</button>
                    </div>
                </div>

                <div class="row" style=" margin-top: 0px;">
                    <div class="col l12 m12 s12">
                        <div class="col l12 m12 s12" style="padding: 0%;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="5%" style="text-align: left"></th>
                                    <th width="20%" style="text-align: left">Product Category</th>
                                    <th width="27%" style="text-align: left">Product</th>
                                    <th width="8%" style="text-align: right;padding-right: 10px;">MRP</th>
                                    <th width="10%" style="text-align: right;padding-right: 10px;">Commission %</th>
                                    <th width="12%" style="text-align: right;padding-right: 10px;">Transfer Price</th>
                                    <th width="10%" style="text-align: left">Currency</th>
                                    <th width="8%" style="text-align: left">Action</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: q ,id: 'q'};let i = index">
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;"><input
                                                type="checkbox" [(ngModel)]="lst.checkselectprocat"
                                                #checkselectprocat="ngModel">
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.productcatname}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.productName}}</td>
                                        <td
                                            style="text-align: right;padding-right: 10px;padding-top: 0px;padding-bottom: 0px;">
                                            &#8377;{{lst.mrp}}</td>
                                        <td
                                            style="text-align: right;padding-right: 10px;padding-top: 0px;padding-bottom: 0px;">
                                            <div class="row">
                                                <div class=" input-field col s10"
                                                    style="text-align: right;padding-left: 8px;margin-bottom: 0px;margin-top: 0px">
                                                    <input [(ngModel)]="lst.commission" #commission="ngModel"
                                                        id="commission" type="text" min="0" data-toggle="tooltip"
                                                        (ngModelChange)="onChangeOfcommission()"
                                                        style="text-align: right;" data-placement="top"
                                                        placeholder="Commission %" ngModel maxlength="35"
                                                        style="margin-bottom: 0px;text-align: right;" required>
                                                    <label id="lable16" for="first16"></label>
                                                </div>
                                                <div class=" input-field col s1" style="margin-top: 9px;padding: 0px;">
                                                    &#37;</div>
                                            </div>
                                        </td>
                                        <td
                                            style="text-align: right;padding-right: 10px;padding-top: 0px;padding-bottom: 0px;">
                                            <input [(ngModel)]="lst.transferprice" #transferprice="ngModel" min="0"
                                                oninput="validity.valid||(value='');" type="text" id="transferprice"
                                                data-toggle="tooltip" data-placement="top"
                                                (ngModelChange)="onChangeOftransferprice()" placeholder="Transfer price"
                                                style="margin-bottom: 0px;text-align: right;">
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;"><span
                                                style="text-align:left;padding-top: 0px;padding-bottom: 0px;"> &#8377;
                                            </span>{{lst.currencyName}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;"> <span><img
                                                    src="../../../assets/images/delete.png"
                                                    (click)="btndeletepro(lst.dealerproid,i)" data-toggle="tooltip"
                                                    data-placement="top" title="Delete" width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="q = $event" id="q"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn"
                            (click)="btncancledetail()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsaveproduct()"
                            class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>

            <div id="Geography" class="tabcontent col l12 m12 s12 registerBgShadow">
                <div class="row">
                    <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;">
                        <span>Country</span>
                        <select (change)="onSelectCountry($event.target.value)">
                            <option selected value="0">Select Country</option>
                            <option *ngFor="let type of countrylst" [value]="type.id"
                                [selected]="type.id == modelg.countryid">
                                {{type.name}}</option>
                        </select>
                    </div>

                    <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;">
                        <span>Region</span>
                        <select (change)="onSelectRegions($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Regions" data-toggle="tooltip" data-placement="top"
                            title="Regions">
                            <option selected value="0">Select Region</option>
                            <option *ngFor="let lst of regionlst" [value]="lst.id" [selected]="lst.id==modelg.regionid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;">
                        <span>State</span>
                        <select (change)="onSelectState($event.target.value)">
                            <option selected>Select State</option>
                            <option *ngFor="let type of statelst" [value]="type.id"
                                [selected]="type.id == modelg.stateid">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s4" style="margin-bottom: 0px;margin-top: 0px;">
                        <span>Area</span>
                        <select (change)="onSelectArea($event.target.value)" data-toggle="tooltip" data-placement="top"
                            title="Area">
                            <option selected>Select Area</option>
                            <option *ngFor="let type of arealst" [value]="type.id" [selected]="type.id==modelg.areaid">
                                {{type.name}}
                            </option>
                        </select>
                    </div>

                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 0px;">
                        <span>City</span>
                        <select (change)="onSelectCity($event.target.value)">
                            <option selected>Select City</option>
                            <option *ngFor="let type of citylst" [value]="type.id"
                                [selected]="type.id == modelg.cityid">
                                {{type.name}}</option>
                        </select>
                    </div>

                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 0px;">
                        <span>Locale</span>
                        <select (change)="onSelectlocale($event.target.value)">
                            <option *ngFor="let type of localelst" [value]="type.id"
                                [selected]="type.id == modelg.localeid">
                                {{type.name}}</option>
                        </select>
                        <div >
                            <input type="checkbox" [(ngModel)]="model.localall" #localall="ngModel"
                                (check)="localall1($event)">Select All
                        </div>
                    </div>
                    <div class="input-field col l2 m2 s2" style="text-align:right; margin-top: 18px;">
                        <button type="button" (click)="btngo()" class="waves-ripple waves-light btn">Add</button>
                    </div>
                </div>

                <div class="row" style=" margin-top: 10px;">
                    <div class="col l12 m12 s12">
                        <div class="col l12 m12 s12" style="padding: 0%;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="5%" style="text-align: center"> </th>
                                    <th width="15%" style="text-align: left">Country</th>
                                    <th width="16%" style="text-align: left">Region</th>
                                    <th width="16%" style="text-align: left">Area</th>
                                    <th width="16%" style="text-align: left">City</th>
                                    <th width="16%" style="text-align: left">Locale</th>
                                    <th width="16%" style="text-align: left">Locality</th>

                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of locmodlst | paginate: { itemsPerPage: 10, currentPage: p,id: 'p' }">
                                        <td style="text-align: center;"><input type="checkbox"
                                                [(ngModel)]="lst.checkselectgeo" #checkselectgeo="ngModel"></td>
                                        <td style="text-align: left">{{lst.country}}</td>
                                        <td style="text-align: left">{{lst.region}}</td>
                                        <td style="text-align: left">{{lst.area}}</td>
                                        <td style="text-align: left">{{lst.city}}</td>
                                        <td style="text-align: left">{{lst.locale}}</td>
                                        <td style="text-align: left">{{lst.locality}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event" id="p"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn"
                            (click)="btncancledetail()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsavegeography()"
                            class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>

            <div id="Contacts" class="tabcontent col l12 m12 s12 registerBgShadow">
                <div class="row">
                    <!-- <div class="col l1"></div> -->
                    <div class="input-field  col s6 l6 m6" style=" margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="modelc.contactName" #contactName="ngModel" type="text" id="contactname"
                            data-toggle="tooltip" data-placement="top" title="Contact Name">
                        <label for="contactname" class="active">Contact Name</label>
                    </div>

                    <div class="input-field col s5 m5 l5" style="margin-bottom: 0px;">
                        <span>Designation</span>
                        <select (change)="onSelectdesignation($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Designation">
                            <option selected value="0">Select Designation</option>
                            <option *ngFor="let lst of designationlst" [value]="lst.id"
                                [selected]="lst.id==modelc.designationID">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col l1"></div> -->
                    <div class="input-field col l3 m3 s3" style="margin-top: 0px;">
                        <span>Country</span>
                        <select (change)="onSelectcountry($event.target.value)">
                            <option selected value="0">Select Country</option>
                            <option *ngFor="let type of cntylst1" [value]="type.id"
                                [selected]="type.id == modelc.country1ID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s3" style="margin-top: 17px;margin-bottom: 0px;">
                        <input type="text" pattern="[6789][0-9]{9}" [(ngModel)]="modelc.mobileNo1" #mobileNo1="ngModel"
                            min="0" id="mobile1" maxlength="10" data-toggle="tooltip" data-placement="top"
                            title="Mobile Number" required>
                        <label id="mobile1" for="first8">Mobile Number</label>
                        <div *ngIf="mobileNo1.invalid && (mobileNo1.dirty || mobileNo1.touched)"
                        class="alert alert-danger" style="color: red">
                        <!-- && modelc.mobileNo1 == null -->
                            <div *ngIf="mobileNo1.errors.required">
                                Mobile Number Required
                            </div>
                            <div [hidden]="!mobileNo1.errors?.pattern">
                                Invalid Mobile Number
                            </div>
                        </div>
                    </div>

                    <div class="input-field col l3 m3 s3" style="margin-top: 0px;">
                        <span>Country</span>
                        <select (change)="onSelectcountryy($event.target.value)">
                            <option selected value="0">Select Country</option>
                            <option *ngFor="let type of cntylst2" [value]="type.id"
                                [selected]="type.id== modelc.country2ID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s3" style="margin-top: 17px;margin-bottom: 0px;">
                        <input type="text" pattern="[6789][0-9]{9}" [(ngModel)]="modelc.mobileNo2" #mobileNo2="ngModel"
                            min="0" id="mobile2" maxlength="10" data-toggle="tooltip" data-placement="top"
                            title="Mobile Number" required>
                        <label id="mobile2" for="first8">Mobile Number</label>
                        <div *ngIf="mobileNo2.invalid && (mobileNo2.dirty || mobileNo2.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="mobileNo2.errors.required">
                                Mobile Number Required
                            </div>
                            <div [hidden]="!mobileNo2.errors?.pattern">
                                Invalid Mobile Number
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <!-- <div class="col l1"></div> -->
                    <div class=" input-field col s4" style="margin-top: 2px;margin-bottom: 0px;">
                        <input type="text" [(ngModel)]="modelc.email" #email="ngModel" id="Email" data-toggle="tooltip"
                            data-placement="top" title="email" ngModel maxlength="35"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                        <label id="lable12" for="first1">Email </label>
                        <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="email.errors.required">
                                Email Required
                            </div>
                            <div [hidden]="!email.errors?.pattern">
                                Invalid Email-Id
                            </div>
                        </div>
                    </div>
                    <div class="col l1"></div>
                    <div class="input-field col l7 m7 s7" style="margin-top: 20px;margin-bottom: 8px;">
                        <input [(ngModel)]="modelc.chkselectvalue" #chkselectvalue="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;&nbsp;Principal Contact
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col l1 m1 s1" style="margin-top: 20px;margin-bottom: 0px;">
                        <input [(ngModel)]="modelc.chkselectlogin" #chkselectlogin="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;&nbsp;Login
                    </div>
                    <div class=" input-field col l3 m3 s3" style=" margin-top: 12px;margin-bottom: 0px;"
                        *ngIf="modelc.chkselectlogin">
                        <input [(ngModel)]="modelc.username" #username="ngModel" type="text" id="username"
                            data-toggle="tooltip" data-placement="top" title="User Name"  ngModel maxlength="35"
                            style="margin-bottom: 0px;" required>
                            <!-- onkeypress="return /[A-Za-z_ ]/i.test(event.key)" -->
                        <label id="lable5" for="first5">User Name</label>
                        <div *ngIf="username.invalid && (username.dirty || username.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="username.errors.required">
                                User Name Required
                            </div>
                            <div [hidden]="!username.errors?.pattern">
                                Invalid User Name
                            </div>
                        </div>
                    </div>

                    <div class=" input-field col l3 m3 s3" style="margin-top: 12px;margin-bottom: 0px"
                        *ngIf="modelc.chkselectlogin">
                        <input [(ngModel)]="modelc.pass" #pass="ngModel" type="text"
                            oninput="validity.valid||(value='');" id="first3" data-toggle="tooltip" data-placement="top"
                            title="Password" style="margin-bottom: 0px;" required>
                        <label id="lable6" for="first6">Password</label>
                        <div *ngIf="pass.invalid && (pass.dirty || pass.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="pass.errors.required">
                                Password Required
                            </div>
                            <div [hidden]="!pass.errors?.pattern">
                                Invalid Password
                            </div>
                        </div>
                    </div>
                    <div class="input-field col s3 m3 l3" style="margin-top: 5px;margin-bottom: 0px;" *ngIf="modelc.chkselectlogin">
                        <span>User Group</span>
                        <select (change)="onSelectusergroup($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="User Group">
                            <option selected value="0">Select User Group</option>
                            <option *ngFor="let lst of usergrouplst" [value]="lst.id"
                                [selected]="lst.id==modelc.usergroupid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col l1 m1 s1"
                        style="text-align:end;padding-right: 5px;margin-top: 2px;margin-bottom: 0px;">
                        <button type="button" (click)="btnadd($event)" class="waves-ripple waves-light btn"
                            style="margin-right: 5px;">Add</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="margin-top: 9px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="25%" style="text-align: left">Contact Name</th>
                                <th width="20%" style="text-align: left">Designation</th>
                                <th width="15%" style="text-align: left">Mobile #</th>
                                <th width="20%" style="text-align: left">Email</th>
                                <th width="20%" style="text-align: left">Actions</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of contactlst | paginate: { itemsPerPage: 10, currentPage: r, id:'r'};let i = index ">
                                    <td style="text-align: left">{{lst.contactName}}</td>
                                    <td style="text-align: left">{{lst.designation}}</td>
                                    <td style="text-align: left">{{lst.mobileNo1}}</td>
                                    <td style="text-align: left">{{lst.email}}</td>
                                    <td style="text-align: left">
                                        <span><img src="../../../assets/images/edit.png" (click)="btneditcon(lst,i)"
                                                data-toggle="tooltip" data-placement="top" title="Edit"
                                                width="20px" /></span>&nbsp;&nbsp;
                                        <span><img src="../../../assets/images/delete.png"
                                                (click)="btndeltscon(lst.id,i)" data-toggle="tooltip"
                                                data-placement="top" title="Delete" width="20px" />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="r = $event" id="r"></pagination-controls>
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;">
                        <button type="button" class="waves-ripple cancelbutton btn"
                            (click)="btncancledetail()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsavecon($event)"
                            class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>

            <div id="Logistics" class="tabcontent col l12 m12 s12 registerBgShadow">
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="row">
                            <div class="col l1 m1"></div>
                            <div class="input-field col l8 s8 m8">
                                <span style="font-size: 25px;">Shipping Address</span>
                            </div>
                            <div class="input-field col l3 s3 m3" style="text-align: right;">
                                <button type="button" class="waves-ripple cancelbutton btn"
                                (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                                <button type="button" class="waves-effect waves-light btn"
                                    (click)="openlocationmodel(locationmodel)">Add
                                    Address</button>
                            </div>
                        </div>

                        <div class="input-field col l12 m12 s12 registerBgShadow "
                            *ngFor="let lst of lstaddress;let i = index" style="padding-left: 44px;">
                            <div class="col l1 m1"></div>
                            <div class="registerBgShadow">
                                <div class=" row col l12 m12 s12">
                                    <!-- <div class="col l9 m9 s9" style="padding-left: 30px;">
                                        <div class=" col l3">
                                            <span><b>Short Name:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class="col l6 m6 s6">
                                            {{lst.shortname}}
                                        </div>
                                    </div> -->
                                    <div class=" col l12 m12 s12" style="padding-left: 40px;padding-top: 10px;">
                                        <div class=" col l2 m2 s2">
                                            <span><b>Short Name:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.shortname}}
                                        </div>
                                        <div class="  col l1 ">
                                            <span><b>Address:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.address}}
                                        </div>


                                        <div class=" col l1 m1 s1">

                                            <img src="../../../assets/images/edit.png" data-target="modal1"
                                                class=" modal-trigger" alt="active" title="Edit"
                                                (click)="btnedit(lst,locationmodel)" data-toggle="tooltip"
                                                data-placement="top" width="20px" />&nbsp;&nbsp;
                                            <img src="../../../assets/images/delete.png" alt="active" title="Delete"
                                                (click)="btndelts(lst.id,i)" data-toggle="tooltip" data-placement="top"
                                                width="20px" *ngIf="lstaddress.length > 1 || lst.chkselectvalue == false">
                                        </div>
                                        <div class=" col l2 m2 s2" style="text-align: end;">
                                            <span *ngIf="lst.chkselectvalue==true">Default &nbsp;&nbsp;</span>
                                            <!-- <img src="../../../assets/images/images.png" alt="active" title=""
                                                data-toggle="tooltip" data-placement="top"
                                                width="25px" *ngIf="lst.chkselectvalue==true"/> -->
                                            <i *ngIf="lst.chkselectvalue==true" class="fa fa-check-circle-o fa-3x"
                                                style="color: green;"></i>
                                        </div>
                                    </div>
                                    <!-- <div class=" col l12 m12 s12" style="padding-left: 40px;padding-top: 1px;">
                                    <div class=" col l2 m2 s2">
                                        <span><b>Address:-</b></span>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class=" col l9 m9 s9">
                                        {{lst.address}}
                                    </div>
                                </div> -->
                                    <div class=" col l12 m12 s12" style="padding-left: 40px;padding-top: 10px;">
                                        <div class=" col l2 m2 s2">
                                            <span><b>Landmark:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.landmark}}
                                        </div>
                                        <div class="  col l1 ">
                                            <span><b>City:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.city}}
                                        </div>
                                    </div>
                                    <div class="col l2"></div>
                                    <div class=" col l12 m12 s12" style="padding-left: 40px;padding-top: 10px;">
                                        <div class=" col l2 m2 s2">
                                            <span><b>Pincode:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.pincode}}
                                        </div>
                                        <div class=" col l1">
                                            <span><b>Mobile:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.mobile}}
                                        </div>
                                    </div>
                                    <div class="col l2 m2"></div>
                                    <div class=" col l12 m12 s12" style="padding-left: 40px;padding-top: 10px;">
                                        <div class=" col l2 m2 s2">
                                            <span><b>Map Location:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.maplocation}}
                                        </div>
                                        <div class=" col l1">
                                            <span><b>Email:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.email}}
                                        </div>
                                    </div>
                                    <div class="col l2 m2"></div>
                                    <div class=" col l12 m12 s12" style="padding-left: 40px;padding-top: 10px;">
                                        <div class=" col l2 m2 s2">
                                            <span><b>Landline:-</b></span>&nbsp;&nbsp;&nbsp;
                                        </div>
                                        <div class=" col l3 m3 s3">
                                            {{lst.phone}}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <ng-template #locationmodel style="padding-bottom: 0%;">
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">Shipping Address</span>
            </div>

            <div class="row">
                <div class="">
                    <div class="col l12 m12 s12">
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div class="input-field  col s6 l6 m6" style=" margin-top: 15px;">
                                <input [(ngModel)]="modell.shortname" #shortname="ngModel" type="text" id="shortname"
                                    data-toggle="tooltip" data-placement="top" title="Short Name">
                                <label for="shortname" class="active">Short Name</label>
                            </div>
                            <div class="col l3"></div>
                            <div class="input-field col l3 m3 s3" style="margin-top: 20px;">
                                <input [(ngModel)]="modell.chkselectvalue" #chkselectvalue="ngModel" type="checkbox"
                                    style="text-align: 5px">&nbsp;&nbsp;&nbsp;&nbsp; Default
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 s12 m12">
                        <div class=" input-field col s12" style=" margin-top:0px;">
                            <input [(ngModel)]="modell.address" #address="ngModel" type="text" id="address10"
                                title="Address" maxlength="100">
                            <label id="address10" for="address" class="active"> Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 s12 m12">
                        <div class="input-field  col s6 l6 m6" style=" margin-top: 0px;margin-bottom:0px;">
                            <input [(ngModel)]="modell.landmark" #landmark="ngModel" type="text" id="landmark"
                                data-toggle="tooltip" data-placement="top" title="Landmark">
                            <label for="landmark" class="active">Landmark </label>
                        </div>
                        <!-- <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom:0px;">
                            <input type="number" class="autocomplete" [(ngModel)]="modell.pincode" id="pincode"
                                placeholder="Pincode" (keyup)="getPincodeLikes($event)" />
                            <input type="hidden" id="acc-id" />
                        </div> -->
                        <div class="ng-autocomplete pinRowgrp input-field col s3"
                            style=" margin-top: 0px;margin-bottom:0px;">
                            <ng-autocomplete [data]="pincodelist1" type="text" [(ngModel)]="modell.pincode"
                                placeholder="Pincode" [searchKeyword]="keyword" [minQueryLength]="3" id="pincode"
                                (inputChanged)='onChangeSearch1($event)' (selected)='selectEvent1($event)'
                                (inputFocused)='onFocused1($event)' [itemTemplate]="itemTemplate"
                                [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s3" style="margin-top: 0px;">
                        <span>Locality</span>
                        <select (change)=" onSelectlocality1($event.target.value)">
                            <!-- <option selected value="0">Select Locality</option> -->
                            <option *ngFor="let type of lstlocality" [value]="type.id"
                                [selected]="type.name==modell.locality">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;">

                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>Locale:-</span>&nbsp;<span><b>{{modell.locale}}</b></span>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>City:-</span>&nbsp;<span><b>{{modell.city}}</b></span>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>State:-</span>&nbsp;<span><b>{{modell.state}}</b></span>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>Country:-</span>&nbsp;<span><b>{{modell.country}}</b></span>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <div class=" input-field col s6" style=" margin-top: 4px;">
                            <input [(ngModel)]="modell.maplocation" #maplocation="ngModel" type="text" id="maplocation"
                                title="Google Map Location" data-toggle="tooltip" data-placement="top">
                            <label id="lable8" for="maplocation">Google Map Location</label>
                        </div>
                        <div class=" input-field col s6" style=" margin-top: 4px;">
                            <input [(ngModel)]="modell.mobile" #mobile="ngModel" pattern="[6789][0-9]{9}" ngModel
                                maxlength="10" min="0" id="mobile1" type="text" data-toggle="tooltip"
                                data-placement="top" title="Mobile Number" required>
                            <label id="mobile1" for="first8">Mobile Number</label>
                            <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched) " class="alert alert-danger"
                                style="color: red">
                                <div *ngIf="mobile.errors.required">
                                    Mobile Number Required
                                </div>
                                <div [hidden]="!mobile.errors?.pattern">
                                    Invalid Mobile Number
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 s12 m12">
                        <div class=" input-field col s6" style=" margin-top: 0px;">
                            <input [(ngModel)]="modell.phone" #phone="ngModel" min="0"
                                oninput="validity.valid||(value='');" pattern="[6789][0-9]{9}" type="number" id="phone"
                                title="phone" maxlength="15">
                            <label id="phone" for="phone">Landline</label>
                        </div>
                        <div class=" input-field col s6" style=" margin-top: 0px;">
                            <input [(ngModel)]="modell.email" #email="ngModel" id="Email " type="text"
                                data-toggle="tooltip" data-placement="top" title="email" ngModel maxlength="35"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                            <label id="lable12" for="first1">Email </label>
                            <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                                style="color: red">
                                <div *ngIf="email.errors.required">
                                    Email Required
                                </div>
                                <div [hidden]="!tcemail.errors?.pattern">
                                    Invalid Email-Id
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;">
                        <button type="button" class="waves-ripple cancelbutton btn"
                            (click)="modalRef.hide()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsave($event)" *ngIf="hiddenflag==false"
                            class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>
</div>