import { TemplateRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { CityMasterModel } from "src/app/Master/CityMaster/CityMasterModel";
import { QuotationModel, QuotReportModel } from "src/app/Transactions/Quotation/QuotationModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService, DealersService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel";
import { QuotationReportModel } from "./QuotationReportModel";
declare var $: any;
var converter = require('number-to-words');

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'QuotationReport.html'
})
export class QuotationReport implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private dealersService: DealersService) { }

    model = new QuotationReportModel();
    dmodel = new DCRreportModel();
    lstt: QuotationReportModel[] = [];
    productList2: any
    p: any;
    user = new User();
    // quoReport = new quotationReportModel();
    // qutReportlst: quotationReportModel[];
    // reporttandclst: quotationReportModel[];
    quoReport = new QuotReportModel();
    qutReportlst: QuotReportModel[];
    reporttandclst: QuotReportModel[];
    citylst: CityMasterModel[] = [];

    model1 = new QuotationModel();
    customerList2: any;
    keyword = 'name'
    cityName: string;
    ngOnInit(): void {

        this.getCity();
    }
    getcustomerName($event) {
        let userId = (<HTMLInputElement>document.getElementById('custname')).value;
        this.productList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.reportServices.getCustomerName(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.productList2.length; i++) {
                        map.set(this.productList2[i].name.trim(), this.productList2[i].id);
                        searchDataObj[this.productList2[i].name] = null;
                        if (i == 0) {
                            names = this.productList2[i].name;
                        } else {
                            names = names + "," + this.productList2[i].name;
                        }
                    }

                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt.trim()), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                });
        }
    }

    typeaheadOnSelect(id, name) {

        this.model.accoutid = id;
        this.model.accountName=name;
    }
    selectFromDate(From) {
        if (From > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.frmdate = new Date()
            return;
        }
    }
    selectToDate(date) {
        if (date < this.model.frmdate) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.todate = new Date()
            return;
        }
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.todate = new Date()
            return;
        }
    }
    btnprint(QuotationReport: TemplateRef<any>, quotationId) {
        this.quoReport = null;
        let user = new User();
        user.trnid = quotationId;

        this.reportServices.getQuotationReport(user)
            .subscribe(
                data => {
                    this.quoReport = data
                    this.qutReportlst = data.qutReportlst;
                    this.reporttandclst = data.reporttandclst;

                    this.quoReport.totalamount = 0;
                    this.quoReport.totalnetamount = 0;
                    this.quoReport.totaltaxamount = 0;
                    for (let i = 0; i < this.quoReport.qutReportlst.length; i++) {
                        this.quoReport.totaltaxamount = this.quoReport.totaltaxamount + this.quoReport.qutReportlst[i].taxamount;
                        this.quoReport.totalnetamount = this.quoReport.totalnetamount + this.quoReport.qutReportlst[i].netamount;
                        this.quoReport.totalamount = this.quoReport.totalamount + this.quoReport.qutReportlst[i].totalproamount;
                        this.quoReport.totalinwords = converter.toWords(this.quoReport.totalamount);
                    }
                });

        this.modalRef = this.modalService.show(QuotationReport, {
            class: 'modal-lg'
        });
    }
    openPrint() {
        var divToPrint = document.getElementById('inv');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    cancle() {
        this.router.navigate(["/CRMHome"]);
    }
    btnshow() {
        if (this.model.cityid == null && this.user.message == null) {
            this.notifyService.showRroor("Please select City  !!", "Oops!");
           
            return;
        }
        if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
            
            return;
        }
        this.reportServices.showQuotationReports(this.model)
            .subscribe(
                data => {
                    this.lstt = data
                });
    }
    //For Autocomplete
    onChangeSearch(search: string) {
        this.user.message = search;
        //  this.promodel.productid = this.productid;
        this.reportServices.getCustomerName(this.user)
            .subscribe(data => {
                this.productList2 = [];
                data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id }));

            });

    }
    selectEvent(item) {
        this.model.accoutid = item.id;
    }
    onFocused(e) {
        // do something
    }
    getCity() {
        this.citylst = []
        this.Masterservice.getAllCities(this.user)
            .subscribe(value => {
                this.citylst = value
            });
    }
    onSelectCity(val) {
        this.model.cityintlst = [];
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.cityName = this.user.message
            }
            for (let i = 0; i < this.citylst.length; i++) {
                this.model.cityintlst.push(this.citylst[i].id)
            }
        } else {
            this.model.cityid = val;
            for (let i = 0; i < this.citylst.length; i++) {
                if (this.citylst[i].id == val) {
                    this.cityName = this.citylst[i].name
                }
            }
            this.model.cityintlst.push(this.model.cityid)
        }
    }
}