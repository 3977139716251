import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterServices, AlertService, TransactionServices } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { SearchUserModel } from './SearchUserModel';
import { Location, DatePipe } from '@angular/common'
import { usertwofieldModel } from './usertwofieldModel';
import { EncrDecrService } from 'src/app/_services/EncrDecrService';

@Component({
    selector: 'app-login',
    templateUrl: 'DefineUser.html'
})
export class DefineUser implements OnInit, OnDestroy {
    sub: any;
    str: string;
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService, private EncrDecr: EncrDecrService) {
    }
    id: number;
    user = new User();
    checkselect: boolean;
    chkselect: boolean;
    chkselec: boolean;
    departmentlst: usertwofieldModel[] = [];
    designationlst: usertwofieldModel[] = [];
    reportinglst: usertwofieldModel[] = [];
    branchlst: usertwofieldModel[] = [];
    usergrouplst: usertwofieldModel[] = [];
    locationlst: DropDownModel[];
    Date = new Date();
    pagename: string;
    model: any = {};
    public activeinaclst: DropDownModel[] = [
        { id: 0, "name": "Select Active Inactive" },
        { id: 2, "name": "Active" },
        { id: 3, "name": "Inactive" },
    ];

    public vehicletypelst: DropDownModel[] = [
        { id: 0, "name": "Select Vehicle Type" },
        { id: 2, "name": "Two-Wheeler" },
        { id: 3, "name": "Four-Wheeler" },
    ];

    public searchtype: DropDownModel[] = [
        { id: 0, "name": "Select Salutation" },
        { id: 2, "name": "Mr." },
        { id: 3, "name": "Ms." },
    ];

    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }


    ngOnInit() {
        this.model.activeInactiveflag = "Active"
        this.getInventoryLocation();
        this.pagename = localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getUserByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        if (data.birthdate != null) {
                            this.model.birthdate = new Date(data.birthdate);
                        }
                        if (data.expencestartdate != null) {
                            this.model.expencestartdate = new Date(data.expencestartdate);
                        }
                        if (data.expencestartseconddate != null) {
                            this.model.expencestartseconddate = new Date(data.expencestartseconddate);
                        }
                        if (this.model.activeInactive == 'Y') {
                            this.model.activeInactiveflag = "Active"
                        } else if (this.model.activeInactive == 'N') {
                            this.model.activeInactiveflag = "Inactive"
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";
            let l5 = (<HTMLInputElement>document.getElementById('lable5'));
            l5.className = "active";
            let l6 = (<HTMLInputElement>document.getElementById('lable6'));
            l6.className = "active";
            let l7 = (<HTMLInputElement>document.getElementById('lable7'));
            l7.className = "active";
        }


        this.getDept();


    }
    getDept() {
        let user = new User();
        this.departmentlst = [];
        this.designationlst = [];
        this.reportinglst = [];
        this.branchlst = [];
        this.usergrouplst = [];

        this.Masterservice.getDept(user)
            .subscribe((value) => {
                this.departmentlst = value.deptlst
                this.designationlst = value.desiglst
                this.reportinglst = value.reportingtolst
                this.branchlst = value.branlst
                this.usergrouplst = value.usergrolst

            });
    }
    onSelect(value) {
        this.model.vehicletype = value;

    }
    onSelectSalutation(value) {
        this.model.salutation = value;
    }
    getInventoryLocation() {
        this.locationlst = [];

        this.transactionService.getInventoryLocation(this.user)
            .subscribe(data => {
                this.model = data;
                this.locationlst = data.locationLst
            });
    }
    onSelectSalesInventoryLocation(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id == value) {
                this.model.salelocationid = this.locationlst[i].id;
                this.model.salelocationname = this.locationlst[i].name;
            }
        }
    }
    onSelectInventoryLocation(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id == value) {
                this.model.spalocationid = this.locationlst[i].id;
                this.model.sparelocationname = this.locationlst[i].name;
            }
        }
    }
    onSelectdesignation(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Designation !!", "Oops!");
            return;
        }

        for (let i = 0; i < this.designationlst.length; i++) {
            if (this.designationlst[i].id == value) {
                this.model.designationID = this.designationlst[i].id;
                this.model.designation = this.designationlst[i].name
            }
        }

    }
    onSelectdept(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Department !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.departmentlst.length; i++) {
            if (this.departmentlst[i].id == value) {
                this.model.deptID = this.departmentlst[i].id;
                this.model.deptName = this.departmentlst[i].name
            }
        }

    }
    onSelectreportingto(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select ReportsTo !!", "Oops!");
            return;
        }
        this.model.reportsToID = value;
    }
    onSelectbranch(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Branch Name!!", "Oops!");
            return;
        }
        this.model.userprefix = value
        for (let i = 0; i < this.branchlst.length; i++) {
            if (this.branchlst[i].id == value) {
                this.model.branchname = this.branchlst[i].name
            }
        }
    }

    onSelectActiveinactive(value) {
        if (value == "Active") {
            this.model.activeInactive = 'Y';
        } else if (value == "Inactive") {
            this.model.activeInactive = 'N';
        }
    }

    onSelectusergroup(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select User group !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.usergrouplst.length; i++) {
            if (this.usergrouplst[i].id == value) {
                this.model.usergroup = this.usergrouplst[i].name;
                this.model.usergroupid = this.usergrouplst[i].id
            }
        }
    }

    onSelectModule(id) {

    }

    btnsave() {
        let mobileval = /^[6789]\d{9}$/;
        if (this.model.salutation == null || this.model.salutation == "Select Salutation") {
            this.notifyService.showRroor("Select Salutation !!", "Oops!");
            return;
        }
        if (this.model.name == null || this.model.name == "") {
            this.notifyService.showRroor("Enter Name !!", "Oops!");
            return;
        }
        if (this.model.birthdate == null) {
            this.notifyService.showRroor("Enter Birthdate !!", "Oops!");
            return;
        }

        if (this.model.designationID == null) {
            this.notifyService.showRroor("Select Designation !!", "Oops!");
            return;
        }
        if (this.model.loginName == null || this.model.loginName == "") {
            this.notifyService.showRroor("Select  Login Name !!", "Oops!");
            return;
        }
        if (this.model.id == null) {
            if (this.model.pass == null || this.model.pass == "") {
                this.notifyService.showRroor("Enter password  !!", "Oops!");
                return;
            } else {
                var encrypted = this.EncrDecr.set('UMJ3wfzn@kMpARd', this.model.pass);
                this.model.password = encrypted;
            }
        }

        if (this.model.telephone == null) {
            this.notifyService.showRroor("Enter valid telephone No!!", "Oops!");
            return;

        }
        if (this.model.mobile == null) {
            this.notifyService.showRroor("Enter valid Mobile No   !!", "Oops!");
            return;
        }
        if (this.model.email != null || this.model.email != "") {
            let emailval = /^[a-z0-9._ %+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
            if (!emailval.test(this.model.email)) {

                this.notifyService.showRroor("Email-id is not in valid format !!", " Oops!");
                return;
            }
        }
        if (this.model.reportsToID == null) {
            this.notifyService.showRroor("Select Reports !!", "Oops!");
            return;
        }
        if (this.model.costRate == null) {
            this.notifyService.showRroor("Enter Cost Rate  !!", "Oops!");
            return;
        }
        if (this.model.activeInactive == null || this.model.activeInactive == "Select Active Inactive") {
            this.notifyService.showRroor("Select Active Inactive  !!", "Oops!");
            return;
        }
        if (this.model.branchname == null) {
            this.notifyService.showRroor("Select Branch Name  !!", "Oops!");
            return;
        }
        if (this.model.usergroup == null) {
            this.notifyService.showRroor("Select User group  !!", "Oops!");
            return;
        }
        if (this.model.deptID == null) {
            this.notifyService.showRroor("Select Department !!", "Oops!");
            return;
        }
        if (this.model.vehicletype == null || this.model.vehicletype == "Select Vehicle Type") {
            this.notifyService.showRroor("Select Vehicle type  !!", "Oops!");
            return;
        }
        if (this.model.activeInactive == "Active") {
            this.model.activeInactive = 'Y'
        } else if (this.model.activeInactive == "Inactive") {
            this.model.activeInactive = 'N'
        }
        this.Masterservice.saveuserdetails(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Manage Resource']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
    cancle() {
        this.router.navigate(["Manage Resource"]);
    }
    selectBirthDate(date)
    {
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.birthdate = new Date()
            return;
        }
    }

}
