import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationService } from '../../_services/notification.service';
import { AlertService, MasterServices } from '../../_services';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { User } from '../../_models/user';
import { DropDownModel } from '../../_models/DropDownModel';
import { Header, MasterHomePageModel, MasterList, SearchModel } from './MasterHomePageModel';
@Component({
    selector: 'app-HPprv',
    templateUrl: 'MasterHomePage.html'
})

export class MasterHomePage implements OnInit {
    model = new MasterHomePageModel();
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private notifyService: NotificationService, private _formBuilder: FormBuilder) {
        this.page = this.router.url;
    }
    id: number;
    page: string;
    searchtype: DropDownModel[] = [];
    user = new User();
    headerlst: Header[] = [];
    masterDatalst: MasterList[] = [];
    masterDatalst1: MasterList[] = [];
    searchLst: SearchModel[] = [];
    p: any
    public i;
    ngOnInit() {
        if (this.page != null) {
            this.model.pageName = this.page.replace('/#/', '');
            this.model.pageName = this.model.pageName.replace('/', '');
            this.model.pageName = this.model.pageName.replace('%20', ' ');
            this.model.pageName = this.model.pageName.replace('%20', ' ');
            this.page = this.model.pageName
            this.getHeaderlst();
        }
    }
    btnnew() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "master": this.page
            }
        };
        this.router.navigate(['/DefineMaster'], navigationExtras);
    }

    getHeaderlst() {
        this.user.message = this.page
        this.user.trnid = this.id
        this.Masterservice.getHeaderlst(this.user)
            .subscribe(
                data => {
                    this.headerlst = data
                    this.model.hdrlst = data
                    if (this.model.hdrlst.length > 0) {
                        this.getMasterData();
                    }
                });
    }

    getMasterData() {
        this.Masterservice.getMasterData(this.model)
            .subscribe(
                data => {
                    this.masterDatalst = data.homepagedatalast;
                    this.masterDatalst1 = data.homepagedatalast
                    this.searchLst = data.searchlst;
                });
    }

    onSelect(val) {
        this.model.txtselect = val;
    }

    btnsearch() {
        if (this.model.txtselect == "All") {
            this.getMasterData()
            this.model.txtserch = null;
        } else {
            for (let i = 0; i < this.headerlst.length; i++) {
                if (this.model.txtselect == "column 1") {
                    if (this.model.txtserch == null || this.model.txtserch == "") {
                        this.notifyService.showRroor("Enter Criteria To Search", "Oops!");
                        return;
                    }
                    else {
                        this.masterDatalst1 = [];
                        this.masterDatalst.forEach((item) => {
                            if ((item.column1.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                                this.masterDatalst1.push({ "id": item.id, "column1": item.column1, "column2": item.column2, "column3": item.column3, "column4": item.column4, "column5": item.column5, "srno": item.srno });
                            }
                        });
                        this.masterDatalst = this.masterDatalst1;
                    }
                } else if (this.model.txtselect == "column 2") {
                    if (this.model.txtserch == null || this.model.txtserch == "") {
                        this.notifyService.showRroor("Enter Criteria To Search", "Oops!");
                        return;
                    }
                    else {
                        this.masterDatalst1 = [];
                        this.masterDatalst.forEach((item) => {
                            if ((item.column2.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                                this.masterDatalst1.push({ "id": item.id, "column1": item.column1, "column2": item.column2, "column3": item.column3, "column4": item.column4, "column5": item.column5, "srno": item.srno });
                            }
                        });
                        this.masterDatalst = this.masterDatalst1;
                    }
                } else if (this.model.txtselect == "column 3") {
                    if (this.model.txtserch == null || this.model.txtserch == "") {
                        this.notifyService.showRroor("Enter Criteria To Search", "Oops!");
                        return;
                    }
                    else {
                        this.masterDatalst1 = [];
                        this.masterDatalst.forEach((item) => {
                            if ((item.column3.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                                this.masterDatalst1.push({ "id": item.id, "column1": item.column1, "column2": item.column2, "column3": item.column3, "column4": item.column4, "column5": item.column5, "srno": item.srno });
                            }
                        });
                        this.masterDatalst = this.masterDatalst1;
                    }
                } else if (this.model.txtselect == "column 4") {
                    if (this.model.txtserch == null || this.model.txtserch == "") {
                        this.notifyService.showRroor("Enter Criteria To Search", "Oops!");
                        return;
                    }
                    else {
                        this.masterDatalst1 = [];
                        this.masterDatalst.forEach((item) => {
                            if ((item.column4.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                                this.masterDatalst1.push({ "id": item.id, "column1": item.column1, "column2": item.column2, "column3": item.column3, "column4": item.column4, "column5": item.column5, "srno": item.srno });
                            }
                        });
                        this.masterDatalst = this.masterDatalst1;
                    }
                } else if (this.model.txtselect == "column 5") {
                    if (this.model.txtserch == null || this.model.txtserch == "") {
                        this.notifyService.showRroor("Enter Criteria To Search", "Oops!");
                        return;
                    }
                    else {
                        this.masterDatalst1 = [];
                        this.masterDatalst.forEach((item) => {
                            if ((item.column5.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                                this.masterDatalst1.push({ "id": item.id, "column1": item.column1, "column2": item.column2, "column3": item.column3, "column4": item.column4, "column5": item.column5, "srno": item.srno });
                            }
                        });
                        this.masterDatalst = this.masterDatalst1;
                    }
                }
            }
        }
    }

    btnedit(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "Rowid": id,
                "master": this.page,
            }
        };
        this.router.navigate(['/DefineMaster'], navigationExtras);
    }

    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.user.trnid = id
            this.user.message = this.page
            this.Masterservice.deleteMasterData(this.user)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess(data.message, " Success");
                            this.ngOnInit()
                        }
                    });
        }
    }
}