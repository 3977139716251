<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col l1"></div>
                        <div class=" input-field col s3 m3 l3" style=" margin-top: 28px;">
                            <input [(ngModel)]="model.code" #code="ngModel" type="text" id="code" title="Scheme Code"
                                maxlength="10">
                            <label id="lable1" for="code">Scheme Code</label>
                        </div>
                        <div class="input-field  col s5 m5 l5" style="margin-top: 28px;">
                            <input [(ngModel)]="model.name" #code="ngModel" type="text" id="code" title="Scheme Name"
                                maxlength="35">
                            <label id="lable2" for="code">Scheme Name</label>
                        </div>
                        <div class="input-field col s2 m2 l2">
                            <span>Customer Type</span>
                            <select (change)=" onSelectcustmertype($event.target.value)">
                                <option *ngFor="let type of lstcustomertype" [selected]="type.id==model.customertypeid"
                                    [value]="type.id">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col l1"></div>
                        <div class=" input-field col s10 m10 l10" style="    margin: 0px;">
                            <input [(ngModel)]="model.description" #code="ngModel" type="text" id="code"
                                title="Description" maxlength="35">
                            <label id="lable3" for="code">Description</label>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;margin-bottom: 12px;">
                        <div class="col l1"></div>
                        <div class="input-field col s5 m5 l5" style="margin: 0px;">
                            <input [(ngModel)]="model.geographicflag" #checkselect="ngModel" type="checkbox"
                                name="Geographic Reference " style="text-align: 20px;">&nbsp;&nbsp;Geographic Reference
                        </div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.geographicflag">
                        <div class="col l1"></div>
                        <div class="input-field col s4 m4 l4" style="margin: 0px;">
                            <span>Country</span>
                            <select (change)=" onSelectcountry($event.target.value)">
                                <option *ngFor="let type of lstcountry" [selected]="type.id==model.countryid"
                                    [value]="type.id">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="col l1"></div>
                        <div class="col l4 m4 s4">
                            <div class=" col s1 m1 l1" style=" margin-top:30px;">
                                <input [(ngModel)]="model.regionflag" #checkselect="ngModel" type="checkbox"
                                    name="Region" style="text-align: 20px;">&nbsp;&nbsp;
                            </div>
                            <div class=" input-field col s10 m10 l10" style=" margin: 0px;">
                                <span>Region</span>
                                <select (change)=" onSelectRegions($event.target.value)" [disabled]="!model.regionflag">
                                    <option *ngFor="let type of lstregion" [selected]="type.id==model.regionid"
                                        [value]="type.id">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.geographicflag">
                        <div class="col l1"></div>
                        <div class="col l5">
                            <div class="  col s1 m1 l1" style="margin-top: 30px;padding-left: 0px;">
                                <input [(ngModel)]="model.areaflag" #checkselect="ngModel" type="checkbox"
                                    style="text-align: 20px;">&nbsp;&nbsp;
                            </div>
                            <div class=" input-field col s10 m10 l10" style=" margin: 0px;">
                                <span>Area</span>
                                <select (change)=" onSelectArea($event.target.value)" [disabled]="!model.areaflag">
                                    <option *ngFor="let type of lstarea" [selected]="type.areaid==model.areaid"
                                        [value]="type.areaid">
                                        {{type.areaname}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col l1"></div>
                        <div class="col l5">
                            <div class="  col s1 m1 l1" style=" margin-top: 30px;">
                                <input [(ngModel)]="model.cityflag" #checkselect="ngModel" type="checkbox" name="City"
                                    style="text-align: 20px;">&nbsp;&nbsp;
                            </div>
                            <div class=" input-field col s10 m10 l10" style=" margin: 0px;">
                                <span>City</span>
                                <select (change)=" onSelectcity($event.target.value)" [disabled]="!model.cityflag">
                                    <option *ngFor="let type of lstcity" [selected]="type.id==model.cityid"
                                        [value]="type.id">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col l1"></div>
                        <div class="col l4 m4 s4">
                            <div class="row">
                                <div class="input-field col l4 m4 s4" style=" margin: 0px;">
                                    <label><input name="group1" type="radio" (click)="onchkpercentage()"
                                            [checked]="model.percentageflag">
                                        <span>Percentage</span></label>
                                </div>
                                <div class="input-field col l7 m7 s7" style=" margin: 0px;">
                                    <input [(ngModel)]="model.percentage" #percentage="ngModel" id="group1" type="text"
                                        id="percentage" title="percentage" maxlength="35">
                                    <label id="lable4" for="percentage">Percentage</label>
                                </div>
                            </div>
                        </div>
                        <div class="  col s3 m3 l3">
                            <div class="row">
                                <div class="input-field col l6 m6 s6" style=" margin-top: 19px;">
                                    <input [(ngModel)]="model.percentagevalueflag" #checkselect="ngModel"
                                        type="checkbox" name="Trn Value" style="text-align: 20px;">&nbsp;&nbsp;> Trn
                                    Value
                                </div>
                                <div class="input-field col l6 m6 s6" style="margin: 0px;">
                                    <input [(ngModel)]="model.perTrnValue" #perTrnValue="ngModel" type="text"
                                        id="perTrnValue" title="perTrnValue" maxlength="35"
                                        [disabled]="!model.percentagevalueflag">
                                    <label id="lable5" for="percentagevalueflag">Value</label>
                                </div>
                            </div>
                        </div>
                        <div class="input-field  col s3 m3 l3" style="margin:0px">
                            <div class="row">
                                <div class="input-field col l6 m6 s6" style=" margin-top: 19px;">
                                    <input [(ngModel)]="model.percentagevaluediscflag" #checkselect="ngModel"
                                        type="checkbox" name="percentagevaluediscflag"
                                        style="text-align: 20px;">&nbsp;Max
                                    Disc. Value
                                </div>
                                <div class="input-field col l6 m6 s6" style=" margin: 0px;">
                                    <input [(ngModel)]="model.perMaxValue" #perMaxValue="ngModel" type="text"
                                        id="perMaxValue" title="Value" maxlength="35"
                                        [disabled]="!model.percentagevaluediscflag">
                                    <label id="lable6" for="perMaxValue">Value</label>
                                </div>
                            </div>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px; ">
                        <div class="col l1"></div>
                        <div class="col l5 m5 s5">
                            <div class="row">
                                <div class="input-field col s3 m3 l3" style=" margin-top: 0px;">
                                    <label><input name="group1" type="radio" (click)="onchkvalue()"
                                            [checked]="model.valueflag">
                                        <span>Value</span>&nbsp;
                                    </label>
                                </div>
                                <div class="input-field  col s3 m3 l3" style="margin-top: 15px;">
                                    <input [(ngModel)]="model.customflag" #checkselect="ngModel" type="checkbox"
                                        name="Custom Flag" style="text-align: 20px;">&nbsp;&nbsp; Custom
                                </div>
                                <div class=" input-field  col s3 m3 l3" style=" margin: 0px; margin-left: 20px;">
                                    <input [(ngModel)]="model.value" #code="ngModel" type="text" id="code" title="value"
                                        maxlength="35" [disabled]="model.customflag">
                                    <label id="lable7" for="value">Value</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col l3 m3 s3">
                            <div class="row">
                                <div class="input-field  col s6 m6 l6" style=" margin-top: 19px;">
                                    <input [(ngModel)]="model.customflag" #checkselect="ngModel" type="checkbox"
                                        name="Custom Flag" style="text-align: 20px;">&nbsp;&nbsp; Custom
                                </div>
                                <div class="input-field  col s6 m6 l6" style="margin: 0px;">
                                    <input [(ngModel)]="model.custom" #custom="ngModel" type="text" id="custom"
                                    title="custom" maxlength="35" [disabled]="!model.customflag">
                                    [disabled]="!model.trnvalueflag"
                                <label for="code">Custom</label>
                                </div>
                            </div>
                        </div> -->
                        <div class="col l3 m3 s3">
                            <div class="row">
                                <div class="input-field  col s6 m6 l6" style=" margin-top: 19px;">
                                    <input [(ngModel)]="model.trnvalueflag" #checkselect="ngModel" type="checkbox"
                                        name="trnvalueflag " style="text-align: 20px;">&nbsp;&nbsp; > Trn Value
                                </div>
                                <div class="input-field  col s6 m6 l6" style=" margin: 0px;">
                                    <input [(ngModel)]="model.trnvalue" #code="ngModel" type="text" id="code"
                                        title="trnvalue" maxlength="35" [disabled]="!model.trnvalueflag">
                                    <label id="lable8" for="trnvalue">Value</label>
                                </div>
                            </div>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col l1"></div>
                        <div class="col l4 m4 s4">
                            <div class="row">
                                <div class=" input-field col s3 m3 l4" style="padding-right: 0px;margin-top: 15px;">
                                    <span><b>Coupon Type</b></span>
                                </div>
                                <div class="input-field  col s4 m4 l4" style=" margin-top: 2px;"><label><input
                                            name="coupongroup" type="radio" (click)="onchkSingle()"
                                            [checked]="model.single">
                                        <span>Single</span></label>
                                </div>
                                <div class="input-field  col s4 m4 l4" style=" margin-top: 2px;">
                                    <label><input name="coupongroup" type="radio" (click)="onchkSerial()"
                                            [checked]="model.serial">
                                        <span>Serial</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="col l6 m6 s6">
                            <div class="row">
                                <div class=" input-field col s2 m2 l2" style="margin-top: 15px;">
                                    <span><b>Quantity</b></span>
                                </div>
                                <div class="input-field   col s3 m3 l3" style="margin-top: 2px;">
                                    <label><input name="quantitygroup" type="radio" (click)="onchkQuanUnlimited()"
                                            [disabled]="model.serial" [checked]="model.quaUnlimited">
                                        <span>Unlimited</span></label>
                                </div>

                                <div class="input-field  col s2 m2 l2 " style="margin-top: 2px;">
                                    <label><input name="quantitygroup" type="radio" (click)="onchkqualimited()"
                                            [checked]="model.qualimited">
                                        <span>Limited</span> </label>
                                </div>
                                <div class="col l1"></div>
                                <div class="input-field  col s4 m4 l4" style=" margin: 0px;">
                                    <input [(ngModel)]="model.qualimitedvalue" #code="ngModel"
                                        [disabled]="model.quaUnlimited" type="text" id="code" title="Value"
                                        maxlength="35">
                                    <label id="lable9" for="code">Value</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col l1"></div> -->
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col l1"></div>
                        <div class="col s2 m2 l2" style="margin-top: 12px;">
                            <span><b>Scheme Validity</b></span>
                        </div>
                        <div class="input-field col s1 m1 l1" style="margin-top: 5px;">
                            <label><input name="schemegroup" type="radio" (click)="onchkschemeUnlimited()"
                                    [checked]="model.schemeUnlimited">
                                <span>Unlimited</span></label>
                        </div>
                        <div class="input-field col s1 m1 l1" style="margin-top: 5px;">
                            <label style="padding-left:20px;"><input name="schemegroup" type="radio"
                                    (click)="onchkschemelimited()" [checked]="model.schemelimited">
                                <span>Limited</span></label>
                        </div>
                        <!-- <div class="col l4 m4 s4" style="padding-left: 30px;">
                            <div class="col l5 m5 s5" style="margin-top: 22px;">
                                <span>Coupons Valid For</span>
                            </div>
                            <div class="input-field  col s2 m2 l2" style="margin-top: 0px;">
                                <input [(ngModel)]="model.schemedays" #code="ngModel" [disabled]="model.schemelimited"
                                    type="text" id="code" title="Value" maxlength="35" style="text-align: right;">
                            </div>
                            <div class="col l5 m5 s5" style="margin-top: 22px;">
                                <span>Days From Issue</span>
                            </div>
                        </div> -->
                        <div class="input-field  col s2 m2 l2" style=" margin: 0px;padding-left: 40px;"
                            *ngIf="this.action!='view'">
                            <input type="text" id="From" [disabled]="model.schemeUnlimited" [(ngModel)]="model.fromdate"
                                class="form-control" (ngModelChange)="selectFromDate(model.fromdate)" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue', adaptivePosition: true  }">
                            <label style=" margin: 0px;padding-left: 30px;" id="lable10" for="From">From Date</label>
                        </div>

                        <div class="input-field  col s2 m2 l2" style=" margin: 0px;" *ngIf="this.action!='view'">
                            <input type="text" id="todate" [disabled]="model.schemeUnlimited" class="form-control"
                                bsDatepicker [(ngModel)]="model.todate" (ngModelChange)="selectToDate(model.todate)"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue', adaptivePosition: true  }">
                            <label id="lable11" for="todate">To Date</label>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col l1"></div>
                        <div class="col l5 m5 s5">
                            <div class="row">
                                <div class=" input-field col s2 m2 l2" style="padding-right: 0px;margin-top: 15px;">
                                    <span><b>Prefix</b></span>
                                </div>
                                <div class=" input-field col s6 m6 l6" style="padding-right: 0px">
                                    <input [(ngModel)]="model.prefix" #prefix="ngModel" type="text" id="prefix"
                                        title="Prefix" maxlength="3">
                                </div>
                                <div class=" input-field col s4 m4 l4" style="padding-right: 0px;margin-top: 15px;">
                                    <button (click)="verifyprefix(model.prefix)" type="button"
                                        class="waves-effect waves-light btn"
                                        style="margin-left: 5px;padding-right: 18px;">Verify</button>
                                </div>

                            </div>
                        </div>
                        <div class="col l5 m5 s5" *ngIf="model.status == 'OK' && model.single == true && model.prefix !=''">
                            <div class=" input-field col s6 m6 l6" style="padding-right: 0px">
                                <input [(ngModel)]="model.postfix" #postfix="ngModel" type="text" id="postfix"
                                    title="Postfix" maxlength="4" placeholder="Postfix">
                            </div>
                        </div>
                    </div>

                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col l1"></div>
                        <div class="col s2 m2 l2">
                        </div>
                        <!-- <div class="input-field col s2 m2 l2" style=" margin: 0px;">
                            <label><input name="schemegroup" type="radio" (click)="onchkschemelimited()"
                                    [checked]="model.schemelimited">
                                <span>Limited</span></label>
                        </div> -->
                        <!-- <div class="input-field  col s2 m2 l2" style=" margin: 0px;"  *ngIf="this.action!='view'">
                            <input type="text" id="From" [disabled]="model.schemeUnlimited" [(ngModel)]="model.fromdate"
                                class="form-control" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue', adaptivePosition: true  }">
                            <label for="From">From date</label>
                        </div>
                       
                        <div class="input-field  col s2 m2 l2" style=" margin: 0px;"  *ngIf="this.action!='view'">
                            <input type="text" id="todate" [disabled]="model.schemeUnlimited" class="form-control"
                                bsDatepicker [(ngModel)]="model.todate"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue', adaptivePosition: true  }">
                            <label for="todate">To date</label>
                        </div> -->

                        <div class="col l2 m2 s2"></div>
                        <div class="col l1"></div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.id!=null">
                        <div class="col l1"></div>
                        <div class=" input-field col s2 m2 l2" style=" margin-top: 17px;">
                            <input [(ngModel)]="model.forcloseflag" #checkselect="ngModel" type="checkbox"
                                style="text-align: 20px;" (click)="chkForclose($event)">&nbsp;&nbsp; Foreclose
                        </div>
                        <div class="input-field  col s2 m2 l2" style=" margin: 0px;">
                            <input type="text" class="form-control" [disabled]="model.forcloseflag" bsDatepicker
                                [(ngModel)]="model.foreclosedate" id="foreclosedate"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue', placement:'top' }">
                            <label id="lable12" for="foreclosedate">Foreclosure Date</label>
                        </div>
                        <div class=" input-field col s6 m6 l6" style=" margin: 0px;">
                            <input [(ngModel)]="model.foreclosereason" [disabled]="model.forcloseflag" #code="ngModel"
                                type="text" id="foreclosereason" title="Description" maxlength="35">
                            <label id="lable13" for="foreclosereason">Foreclosure Reason</label>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row">
                        <div class="input-field col l11 m11 s11" style="text-align:right;padding-right: 18px;">
                            <button (click)="btnback()" type="button"
                                class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                            <button (click)="btnsaveScheme()" *ngIf="model.forcloseflag != false" type="button"
                                class="waves-effect waves-light btn"
                                style="margin-left: 5px;padding-right: 18px;">Save</button>
                            <button (click)="btnstopScheme()" *ngIf="model.forcloseflag == false" type="button"
                                class="waves-effect waves-light btn" style="margin-left: 5px;padding-right: 18px;">STOP
                                SCHEME</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>