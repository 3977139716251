<div class="row grayBg">
    <a href="#!" class="brand-logo">
        <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
        <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
    </a>

    <nav class="grayTheme">
        <div class="nav-wrapper">
            <div data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i>
            </div>
        </div>
    </nav>
    <ul class="sidenav" id="mobile-demo">
        <li *ngFor="let menu of mainmenus"><a  (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a></li>
    </ul>

    <div class="top-header-social-info">
        <!-- <span class="userName">Welcome Vaibhav Baraskar |</span> -->
        <a href="login.html">
            <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                aria-hidden="true" />
        </a>
    </div>
</div>
