<app-header></app-header>
<div class="container" style="width: 100%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Sample Invoice</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class=" input-field col s5" style="margin-top: 20px;margin-bottom: 0px;">
                    <span style="font-weight:bold">Customer Name :</span>
                    <input type="text" class="autocomplete" [(ngModel)]="model.customername" id="customerName1"
                        placeholder="Customer Name" (keyup)="getCustomerLike($event)">
                    <input type="hidden" id="acc-id" />
                </div>
           
                <div class="input-field col l3 m3 s3" style="margin-top: 20px;">
                    <span>Marketing Cost Head</span>
                    <select (change)="onSelectmrktcost($event.target.value)">
                        <option selected value='0'> Select Marketing Cost Head</option>
                        <option *ngFor="let type of mrktcostlst" [value]="type.id"
                            [selected]="type.id == model.costheadid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3"style="margin-top: 20px;">
                    <span>Marketing Cost SubHead</span>
                    <select (change)="onSelectmrktcostsubhead($event.target.value)">
                        <option selected value='0'> Select Marketing Cost SubHead</option>
                        <option *ngFor="let type of subcostheadlst" [value]="type.id"
                            [selected]="type.id == model.costsubheadid">
                            {{type.name}}</option>
                    </select>
                </div>
            </div>
            <span style="font-weight:bold">Address:&nbsp;</span>
            <div class="row">
                <div class="col s11 m11 l11">
                    <label id="lable1" for="first1"style="background:aliceblue">{{model.address}}</label>
                </div>
            </div>
           
            <div class="row">
                <div class="input-field col s4" style="margin-left: 12px;" *ngIf="model.id == null">
                    <span>Category</span>
                    <select (change)="onSelectprocategory($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Product Category">
                        <option selected value="0">Select Category</option>
                        <option *ngFor="let lst of productcatelst" [value]="lst.id"
                            [selected]="lst.id== model.productcategoryid">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" *ngIf="model.id == null">
                    <span>Product</span>
                    <select (change)="onSelectproduct($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Products">
                        <option selected>Select One</option>
                        <option *ngFor="let plst of productlist" [value]="plst.productid"
                            [selected]="plst.productid==productid">
                            {{plst.prouctname}}</option>
                    </select>
                </div>
                <div class="col l1 m1 s1" style="margin-top: 30px;text-align: right;padding-right: 0px;">
                    <button (click)="btnAddProduct()" type="button" class="waves-effect waves-light btn">Add</button>
                </div>

            </div>

            <div class="row" style="margin:0px; padding-left: 9px;padding-right: 9px;">
                <div class="col l12 m12 s12" style="margin-top: 0px;padding-left: 0px;padding-right: 0px;">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="5%" style="text-align: center;"><input type="checkbox" class="filled-in" [(ngModel)]="model.allcheckselect"
                                (change)="onselectall($event)" #allcheckselect="ngModel" ></th>
                            <th width="25%" style="text-align: left">Product</th>
                            <th width="13%" style="text-align: left">UOM</th>
                            <th width="10%" style="text-align: right">Quantity</th>
                            <th width="10%" style="text-align: right">Price</th>
                            <th width="7%" style="text-align: right">Discount</th>
                            <th width="7%" style="text-align: right">GST %</th>
                            <th width="10%" style="text-align: right">GST Amount</th>
                            <th width="10%" style="text-align: right;padding-right: 10px;">Value</th>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: p } ; let i = index ">
                                <td style="text-align: center;"><input type="checkbox" [(ngModel)]="lst.procheckselect"
                                        #procheckselect="ngModel"></td>
                                <td style="text-align: left">{{lst.prouctname}}</td>
                                <td style="text-align: left">{{lst.uom}}</td>
                                <td style="text-align: right" *ngIf="lst.id != null">{{lst.quantity}}</td>
                                <td style="text-align: right; margin: 10px;" *ngIf="lst.id == null"><input
                                        [(ngModel)]="lst.quantity" #orderquantity="ngModel" type="number" min="0"
                                        oninput="validity.valid||(value='');" id="orderquantity" data-toggle="tooltip"
                                        data-placement="top" placeholder="Order quantity"
                                        (ngModelChange)="onChangeOfqantity(lst,i)" style="text-align: right;">
                                </td>
                                <td style="text-align: right">{{lst.price | number:'1.2-2' }}</td>
                                <td style="text-align: right">{{lst.discount }}</td>
                                <td style="text-align: right">{{lst.gstrate | number:'1.2-2' }}</td>
                                <td style="text-align: right">{{lst.gstamount | number:'1.2-2' }}</td>
                                <td style="text-align: right;padding-right: 10px;">{{lst.value | number:'1.2-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="text-align: right;padding-right: 9px;">
                    <span style="font-size: 15px; font-weight: bold;padding-right: 35px;">Net
                        Order:&nbsp;<span>{{netorder |
                            number:'1.2-2'}}</span>
                    </span>
                    <span style="font-size: 15px; font-weight: bold;padding-right: 35px;">Gst:&nbsp;<span>{{ gst |
                            number:'1.2-2'}}</span>
                    </span>
                    <span style="font-size: 15px; font-weight: bold;padding-right: 10px;">Total:&nbsp;<span>{{ total |
                            number:'1.2-2'}}&nbsp;</span>
                    </span>
                </div>
            </div>
            <div class="row col s12">
                <div class="input-field col s12" style="text-align: right;padding-left: 0px;padding-right: 0px;">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                    <button  type="button" (click)="btnsave()"
                        class="waves-ripple waves-light btn" style="margin-right: 0px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>