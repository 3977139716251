import { OnInit, Component } from '@angular/core';
import { MasterConfigurationModel } from './MasterConfigurationModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { User } from 'src/app/_models';
@Component({
    selector: 'app-masConfig',
    templateUrl: 'MasterConfiguration.html'
})
export class MasterConfiguration implements OnInit {

    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private transactionService: TransactionServices, private notifyService: NotificationService) {
    }
    id: number;
    user = new User();
    model = new MasterConfigurationModel();

    ngOnInit(): void {

    }

    btnsave() {
        this.Masterservice.saveMasterQueries(this.model)
            .subscribe(value => {
                if (value.status == "OK") {
                    this.notifyService.showSuccess(value.message, " Done");
                    this.router.navigate(['/landpage']);
                } else {
                    this.notifyService.showRroor(value.message, " Oops");
                }
            });
    }

    cancle() {
        this.router.navigate(['/landpage']);
    }
}