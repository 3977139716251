export class GoodsNoteHomeModel {
    id: number;
    srno: number;
    supplierName: string;
    grnstatus: string;
    pono: string;
    grnno: string;
    grndate:Date;
    txtserch: string;
    txtselect: string;
    reference: string;
    suppliercheckselect:boolean;
    grncheckselect:boolean;
    datecheckselect:boolean;
    ponocheckselect:boolean;
    startdate:Date;
    enddate:Date;
    txtserchpono:string;
    txtserchgrn:string;
}