export class ReassignModel {
    id: number;
    name: string;
    userid:number;
    resourcename:string;
    enquiryno:string;
    date:string;
    customername:string;
    stage:string;
    assignlst: ReassignModel[] = [];
    teamcode:string;
    chksale:boolean;
    chkservice:boolean
    chkinstallation:boolean;
    servicecallno:string;
    callDate:string;
    callclose:boolean;
    lstopportunitylst:ReassignModel[] = [];
    servicecalllst:ReassignModel[] = [];
    installationlst:ReassignModel[] = [];

    lst:ReassignModel[] = [];
    lst1:ReassignModel[] = [];
    lst2:ReassignModel[] = [];
    oanumber:string;
    status:string;
    teamid:number;
    assignedtoID:number;
    resourcelst:ReassignModel[] = [];
}