import { DropDownModel } from './DropDownModel';

export class User {

  public message: string;
  public id: number;
  public trnid: number;
  public username: string;
  public password: string;
  public usernameOrEmail: string;
  public firstName: string;
  public lastName: string;
  public mainmenulabel: string;
  public userid: number;
  public token: string;
  public doctorid: number;
  public dbName: string;
  public companycode: number;
  public telephone1: string;
  public usergroupid: string;
  public landpage: string;
  public fullname: string;
  public companyName: string;
  public companyLogo: string;
  public pagename: string;
  public zoneid: number;
  public regionid: number;
  public areaid: number;
  public terrid: number;
  public patchid: number;
  public localid: number
  public name: string;
  public code: string;
  public userId: number;
  public noofdays: number;
  public isapproval: string;
  public sendstring: string;
  public companyaddress: string;
  public accesstype: string;
  public companyaddres: string;
  public productcompulsory: boolean;
  public productflag: boolean;
  public productcategoryId: number;
  public locationID: number;
  public calltype: string;
  public spareCategoryid: number;
  public start: number;
  public end: number;
  public pageno: number;
  public pageSize: number;
  public status: boolean;
  public statusCode: string;
  public cityid: number;
  public productID:number;
  public totalcount:number;
  public totalItems:number = 0;
  public txtserch :string;
  public uomname:string;
  public poid:number;
  public teamid:number;
  public suppilerid:number;

}
