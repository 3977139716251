import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { AlertService, DealersService, MasterServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { Location, } from '@angular/common';
import { MarketBudgetGeoperTemModel } from "../MarketingBudgGeoPercentageallocTem/MarketBudgetGeoperTemModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { User } from "src/app/_models";
import { TemplateRef } from "@angular/core";
import { CostheadModel1, mrkbudgetdealerallocationModel } from "./mrkbudgetdealerallocationModel";
import { MarketingServices } from "src/app/_services/MarketingServices";
import { FinancialyearModel } from "src/app/Master/FinancialYear/FinancialyearModel";
@Component({
    selector: 'app-order',
    templateUrl: 'mrkbudgetdealerallocation.html'
})
export class mrkbudgetdealerallocation implements OnInit {

    constructor(private Masterservice: MasterServices, private Marketingservice: MarketingServices, private dealersService: DealersService, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }
    model: any = {};
    user = new User();
    modalRef: BsModalRef;
    // finanyealst: DropDownModel[] = [];
    regionlst: DropDownModel[] = [];
    modellst: MarketBudgetGeoperTemModel[] = [];
    dealerlst:MarketBudgetGeoperTemModel[] = [];
    costheadlst: CostheadModel1[];
    regremainpercent: number;
    remainpercentage: number;
    userList2: any;
    sub: any;
    id: number;
    percentages: number;
    subtotal: number;
    remainvalue: number;
    remainpercent: number;
    remainpercentage1: number;
    remainvalue1: number;
    areavalue: number;
    total1: number;
    length: number;
    totalvalue: number;
    totalvalue1: number;
    subvalueTotal: number;
    costvalueTotal: number;
    areaname: string;
    cityname: string;
    citylst: MarketBudgetGeoperTemModel[] = [];
    total: number;
    totals: number;
    name: string;
    finanyealst: MarketBudgetGeoperTemModel[] = [];
    costpercentTotal:number;
    public i: any;
    p: any;
    newQuantity: number;
    subvalueRemain: number;
    areaindex:number;
    dealerindex:number;

    ngOnInit(): void {
        this.getfinancialyears();
        this.getregionlst();

        this.model.userid = Number(localStorage.getItem("userid"));


    }

    getfinancialyears() {
        let user = new User();
        this.finanyealst = [];
        this.transactionService.getfinancialyears(user)
            .subscribe(value => {
                this.finanyealst = value.finanyealst
            })
    }
    onSelectFinalyear(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Financial Year", "Oops!");
            return;
        }
        this.model.financialyearid = value
    }
    getregionlst() {
        let user = new User();
        this.regionlst = [];
        this.transactionService.getregionlst(user)
            .subscribe((value) => {
                this.regionlst = value.reglst;

            });
    }

    onSelectRegion(regionId) {
        if (regionId == 0) {
            this.notifyService.showRroor("Select Region", "Oops!");
            return;
        }
        this.model.regionId = regionId;
        this.modellst = [];
        this.citylst = [];
        // this.transactionService.getareaByregion(this.model)
        //     .subscribe(value => {
        //         this.model = value
        //         this.modellst = value.modelst
        //     });


        this.Marketingservice.getmrkbuddealerallocation(this.model)
            .subscribe(
                data => {
                    this.model = data;
                    this.modellst = data.modelst
                },
                error => {
                    this.alertService.error(error);
                });
        // let l1 = (<HTMLInputElement>document.getElementById('lable1'));
        // l1.className = "active";
        // let l2 = (<HTMLInputElement>document.getElementById('lable2'));
        // l2.className = "active";
        let l3 = (<HTMLInputElement>document.getElementById('lable3'));
        l3.className = "active";
        let l4 = (<HTMLInputElement>document.getElementById('lable4'));
        l4.className = "active";
    }

    onSelectArecheselect(index, $event) {
        // this.totalvalue = 0;
        // if (!$event.target.checkselect) {
        //     this.modellst[index].areavalue = null;
        //     this.modellst[index].arecheselect = false;
        //     for (let i = 0; i < this.modellst.length; i++) {
        //         this.totalvalue = Number(this.totalvalue) + Number(this.modellst[i].areavalue);
        //         this.model.regionvalue = this.totalvalue
        //         this.model.allreginvalue = this.model.regionvalue / this.model.percentage * 100
        //     }
        // }


    }

    onSelectcity(value, index) {
        if (value == 0) {
            this.notifyService.showRroor("Select City !!", "Oops!");
            // this.modellst[index].cityvalue=null;
            return;
        }
        for (let i = 0; i < this.modellst[index].citylst.length; i++) {
            if (this.modellst[index].citylst[i].city == value) {
                this.modellst[index].city = this.modellst[index].citylst[i].city
                this.modellst[index].cityname = this.modellst[index].citylst[i].cityname
                this.modellst[index].cityvalue = this.modellst[index].citylst[i].cityvalue
            }
        }
    }

    btnopenmodel(index, ind, templateModel: TemplateRef<any>) {
        this.model.marketingBudgethdr = this.modellst[index].citylst[ind].marketingBudgethdr
        this.model.marketingBudgetdtlid = this.modellst[index].citylst[ind].marketingBudgetdtlid
        this.model.city = this.modellst[index].citylst[ind].city
        this.model.dealerid = this.modellst[index].dealerlst[ind].dealerid

        this.Marketingservice.getallcostheads(this.model)
            .subscribe(
                value => {
                    // this.model = value
                    this.costheadlst = value.costheadlst
                    this.areaname = this.modellst[index].areaName
                    this.cityname = this.modellst[index].citylst[ind].cityname
                    this.name = this.modellst[index].dealerlst[ind].name

                    this.areaindex = index;
                    this.dealerindex = ind;

                    for (let i = 0; i < this.costheadlst.length; i++) {
                        this.costheadlst[i].costvalue = this.modellst[index].dealerlst[ind].dealervalue * this.costheadlst[i].costpercentage / 100
                        // alert(this.costheadlst[i].costpercentage)
                    }


                    for (let i = 0; i < this.costheadlst.length; i++) {
                        for (let j = 0; j < this.costheadlst[i].costSubheadlst.length; j++) {
                            if (this.costheadlst[i].costSubheadlst[j].subpercentage != null) {
                                this.costheadlst[i].costSubheadlst[j].subcheckselect = true
                            }
                        }
                    }
                    for (let i = 0; i < this.costheadlst.length; i++) {
                        for (let j = 0; j < this.costheadlst[i].costSubheadlst.length; j++) {
                            this.costheadlst[i].costSubheadlst[j].subheadvalue = this.costheadlst[i].costvalue * this.costheadlst[i].costSubheadlst[j].subpercentage / 100
                        }

                    }

                },

                error => {
                    this.alertService.error(error);
                });

        this.modalRef = this.modalService.show(templateModel, {
            class: 'modal-lg'
        });
    }

    onChangeOfcostpercentage(index) {
        this.costpercentTotal = 0;
        if (this.model.costpercentage > 100) {
            this.model.percentage = null;
            this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
            return;
        }

        for (let i = 0; i < this.costheadlst.length; i++) {
            for (let j = 0; j < this.modellst[index].citylst.length; j++) {
                this.costheadlst[i].costvalue = this.modellst[index].citylst[j].cityvalue * this.costheadlst[i].costpercentage / 100
            }
        }
        for (let i = 0; i < this.costheadlst.length; i++) {
                this.costpercentTotal = +this.subtotal + +this.costheadlst[index].costpercentage;
                if (this.costvalueTotal > 100) {
                    this.costheadlst[index].costpercentage = null;
                    this.costheadlst[index].costvalue = null;
                    this.notifyService.showRroor("Percent Allocation is not greater than 100%", "Oops!");
                    return;
                }
        }
    }

    onChangeOfcostvalue(index) {
        this.costvalueTotal = 0
        for (let i = 0; i < this.costheadlst.length; i++) {
            for (let j = 0; j < this.modellst[index].citylst.length; j++) {
                this.costheadlst[index].costpercentage = this.costheadlst[index].costvalue * 100 / this.modellst[index].citylst[j].cityvalue

                for (let i = 0; i < this.costheadlst.length; i++) {
                    for (let j = 0; j < this.modellst[index].citylst.length; j++) {
                        this.costvalueTotal = + this.costvalueTotal + + this.costheadlst[i].costvalue
                        alert(this.costvalueTotal)
                        if (this.costvalueTotal > this.modellst[index].citylst[j].cityvalue) {
                            this.costheadlst[index].costpercentage = null;
                            this.costheadlst[index].costvalue = null;
                            this.notifyService.showRroor("Percent Allocation is not greater than 100%", "Oops!");
                            return;
                        }
                    }
                }
            }
        }
    }
    onChangeOfsubpercentage(index, ind) {
        this.subtotal = 0;
        this.totalvalue1 = 0;
        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            if (this.costheadlst[index].costSubheadlst[ind].subpercentage != null) {
                this.costheadlst[index].costSubheadlst[ind].subcheckselect = true
            }
            this.subtotal = +this.subtotal + +this.costheadlst[index].costSubheadlst[j].subpercentage;
            // alert(this.subtotal)
            if (this.subtotal > 100) {
                this.costheadlst[index].costSubheadlst[j].subpercentage = 0;
                this.costheadlst[index].costSubheadlst[j].subheadvalue = null;
                this.notifyService.showRroor("Percent Allocation is not greater than 100%", "Oops!");
                return;
            }
            this.costheadlst[index].costSubheadlst[ind].subheadvalue = this.costheadlst[index].costvalue * this.costheadlst[index].costSubheadlst[ind].subpercentage / 100
        }
    }
    onChangeOfsubheadvalue(index, ind) {
        this.subvalueTotal = 0;
        this.costvalueTotal = 0
        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            if (this.costheadlst[index].costSubheadlst[ind].subheadvalue != null) {
                this.costheadlst[index].costSubheadlst[ind].subpercentage = null;
                this.costheadlst[index].costSubheadlst[ind].subcheckselect = true
            }
            this.costheadlst[index].costSubheadlst[ind].subpercentage = this.costheadlst[index].costSubheadlst[ind].subheadvalue * 100 / this.costheadlst[index].costvalue
        }
        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[j].subheadvalue
        }
        if (this.subvalueTotal > this.costheadlst[index].costvalue) {
            this.costheadlst[index].costSubheadlst[ind].subpercentage = 0;
            this.costheadlst[index].costSubheadlst[ind].subheadvalue = null;
            this.notifyService.showRroor("Value is not greater than Cost Value", "Oops!");
            return;

        }
    }

    onSelectsubcheckselect(index, $event, ind) {
        if (!$event.target.checkselect) {
            this.costheadlst[index].costSubheadlst[ind].subcheckselect = false;
            this.costheadlst[index].costSubheadlst[ind].subpercentage = null;
            this.costheadlst[index].costSubheadlst[ind].subheadvalue = null;
        }
    }
    closemodel() {
        this.modalRef.hide();
    }
    cancle() {
        this.router.navigate(["/CRMHome"])
    }
    btnsave() {
        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.modellst == null || this.modellst.length <= 0) {
            this.notifyService.showRroor("Please Add List ", "Oops!");
            return;
        }
        this.model.modellst = this.modellst
        this.model.costheadlst = this.costheadlst
        this.Marketingservice.savemrkbudgetDealer(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit()
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
    onChangeOfpercentage(index, ind) {
        this.total = 0;
        this.totals = 0;
        for (let i = 0; i < this.modellst[index].dealerlst.length; i++) {
            if (this.modellst[index].dealerlst[ind].percentage > 100) {
                this.modellst[index].dealerlst[ind].percentage = null;
                this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
                return;
            }
        }
        for (let i = 0; i < this.modellst[index].dealerlst.length; i++) {
            this.modellst[index].dealerlst[i].dealervalue = this.modellst[index].cityvalue * this.modellst[index].dealerlst[i].percentage / 100
        }
        for (let i = 0; i < this.modellst[index].dealerlst.length; i++) {
            if (this.modellst[index].dealerlst[ind].percentage != null) {
                this.modellst[index].dealerlst[ind].checkselect = true;
            }
            this.total = Number(this.total) + Number(this.modellst[index].dealerlst[i].percentage);
            if (this.total > 100) {
                this.modellst[index].dealerlst[ind].percentage = null;
                this.modellst[index].dealerlst[ind].dealervalue = null;
                this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
                return;
            }
        }

    }
    onChangeOfdealervalue(index, ind) {
        for (let j = 0; j < this.modellst[index].citylst.length; j++) {
            for (let i = 0; i < this.modellst[index].dealerlst.length; i++) {
                this.modellst[index].dealerlst[i].percentage = this.modellst[index].dealerlst[i].dealervalue * 100 / this.modellst[index].citylst[j].cityvalue

            }
        }
        for (let j = 0; j < this.modellst[index].citylst.length; j++) {
            for (let i = 0; i < this.modellst[index].dealerlst.length; i++) {
                if (this.modellst[index].dealerlst[ind].dealervalue != null) {
                    this.modellst[index].dealerlst[ind].checkselect = true;
                }
                this.totals = Number(this.total) + Number(this.modellst[index].dealerlst[i].dealervalue);
                if (this.totals > this.modellst[index].citylst[j].cityvalue) {
                    this.modellst[index].dealerlst[ind].dealervalue = null;
                    this.modellst[index].dealerlst[ind].percentage = null;
                    this.notifyService.showRroor("Value is Not greater than City Value", "Oops!");
                    return;
                }
            }
        }

    }
    onSelectCheckselect(index, $event, ind) {
        if (!$event.target.checkselect) {
            this.modellst[index].dealerlst[ind].dealervalue = null;
            this.modellst[index].dealerlst[ind].percentage = null;
            this.modellst[index].dealerlst[ind].checkselect = false;

        }
    }
    onChangeOfQuantity(index, ind) {

        this.subvalueTotal = 0;
        this.newQuantity = 0;
        this.subvalueRemain = 0;
        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            if (this.costheadlst[index].costSubheadlst[ind].subheadvalue != null) {
                this.newQuantity = this.costheadlst[index].costSubheadlst[ind].quantity * this.costheadlst[index].costSubheadlst[ind].rate;
                this.costheadlst[index].costSubheadlst[ind].subheadvalue = this.newQuantity;
            }
            this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[j].subheadvalue
            if (this.subvalueTotal > this.costheadlst[index].costvalue) {
                this.costheadlst[index].costSubheadlst[ind].subpercentage = 0;
                this.costheadlst[index].costSubheadlst[ind].subheadvalue = 0;
                this.costheadlst[index].costSubheadlst[ind].quantity = 0;
                this.notifyService.showRroor("Sub-Head Value is not greater than Cost Value", "Oops!");

                this.subvalueTotal = 0;
                for (let a = 0; a < this.costheadlst[index].costSubheadlst.length; a++) {

                    this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[a].subheadvalue
                }
                this.subvalueRemain = Number(this.costheadlst[index].costvalue) - Number(this.subvalueTotal);
                this.costheadlst[index].costSubheadlst[ind].subheadvalue = this.subvalueRemain;
                this.costheadlst[index].costSubheadlst[ind].subpercentage = this.costheadlst[index].costSubheadlst[ind].subheadvalue * 100 / this.costheadlst[index].costvalue
                this.costheadlst[index].costSubheadlst[ind].quantity = Math.floor(this.costheadlst[index].costSubheadlst[ind].subheadvalue / this.costheadlst[index].costSubheadlst[ind].rate);
                return;
            }
        }
        this.onChangeOfsubheadvalue(index, ind);


        // this.subvalueTotal = 0;
        // for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
        //     if (this.costheadlst[index].costSubheadlst[ind].subheadvalue != null) {
        //         this.costheadlst[index].costSubheadlst[ind].subpercentage = null;
        //         this.costheadlst[index].costSubheadlst[ind].subcheckselect = true
        //     }
        //     this.costheadlst[index].costSubheadlst[ind].subpercentage = this.costheadlst[index].costSubheadlst[ind].subheadvalue * 100 / this.costheadlst[index].costvalue
        // }
        // for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
        //     this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[j].subheadvalue
        // }
        // if (this.subvalueTotal > this.costheadlst[index].costvalue) {
        //     this.costheadlst[index].costSubheadlst[ind].subpercentage = 0;
        //     this.costheadlst[index].costSubheadlst[ind].subheadvalue = null;
        //     this.notifyService.showRroor("Value is not greater than Cost Value", "Oops!");
        //     return;
        // }
    }

}