<app-header></app-header>
<div class="container" style="width: 90%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Master Configuration</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row" style="margin-top:10px">
                <div class="col l1">
                </div>
                <div class="input-field col l10 m10 s10">
                    <textarea [(ngModel)]="model.queries" class="materialize-textarea" #queries="ngModel" type="text"
                        id="Master" data-toggle="tooltip" rows="6" data-placement="top"
                        title="Customer Name"></textarea>
                    <label for="Master" id="mas">Master queries</label>
                </div>
                <div class="col l1">
                </div>
            </div>
            <div class="row">
                <div class="input-field col l6 m6 s6" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)="cancle()">Cancel</button>
                </div>
                <div class="input-field col l6 m6 s6" style="text-align: left">
                    <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                        style="margin-right: 5px;">Save</button>
                </div>
                
            </div>
        </div>
    </div>
</div>