<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Payment Screen</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
    </div>
</div>