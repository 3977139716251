<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l1 m1 s1"></div>
            <div class="heading col l10 m10 s10">
                <span style="font-size: 25px;">Marketing Budget Geography % Allocation Template</span>
            </div>
            <div class="col l1 m1 s1"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row" style="margin-left:0px ;margin-right: 0px;">
                <div class="row">
                    <div class="input-field col s3" style="margin-left: 0px;margin-top: 9px;">
                        <span>Financial Year</span>
                        <select (change)="onSelectFinalyear($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Financial Year">
                            <option selected value="0">Select Financial Year</option>
                            <option *ngFor="let lst of finanyealst" [value]="lst.id"
                                [selected]="lst.id==model.financialyearid">
                                {{lst.name}}
                            </option>
                        </select> 
                    </div>
                    <div class="input-field col s3" style="padding-left: 9px;margin-top: 9px;">
                        <span>Region</span>
                        <select (change)="onSelectRegion($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Region">
                            <option selected>Select Region</option>
                            <option *ngFor="let type of regionlst" [value]="type.id"
                                [selected]="type.id==model.regionId">
                                {{type.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col s2" style="text-align: right;">
                        <div class="row">
                            <div class=" input-field col s10 "
                                style="margin-top: 26px;text-align: right; padding: 0px;margin-left: 9px;">
                                <input [(ngModel)]="model.percentage" #percentage1="ngModel" type="number" min="0"
                                    oninput="validity.valid||(value='');" maxlength="100" id="first1"
                                    data-toggle="tooltip" data-placement="top" title="Percentage"
                                    style="text-align: right;" (ngModelChange)="onChangeOfregionpercent();" ngModel
                                    maxlength="100" required>
                                <label id="lable1" for="first1" class style="left: 0px;">Percentage</label>
                            </div>
                            <div class=" input-field col s1" style="margin-top: 40px;padding: 0px;">&#37;</div>
                        </div>
                    </div>

                    <div class="col s2" style="text-align: left;margin-top: 45px">
                        <span style="font-size: 14px;font-style: normal;">Balance:&nbsp;</span>
                        <span style="font-size: 13px;">{{remainpercent | number:'1.2-2'}}&#37;&nbsp;&nbsp;</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12 m12 l12 registerBgShadow">

                    <div class="col l12 m12 s12"
                        style="background: gainsboro; padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 5px;">
                        <div class="col s1" style="text-align:left;">
                            <span> </span>
                        </div>
                        <div class="col l4 m4 s4" style="text-align:left;padding-left: 22px;">
                            <span>Area</span>
                        </div>
                        <div class="col s2" style="text-align:left;">
                            <span>% Allocation</span>
                        </div>
                        <div class="col s4" style="text-align:left;padding-left: 15px;">
                            <span>Balance : {{remainpercent | number:'1.2-2'}}&#37;</span>
                        </div>

                    </div>

                    <mat-accordion style="padding: 0px;">
                        <mat-expansion-panel
                            *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: p } ;let i = index "
                            style="padding-left: 1px;">
                            <mat-expansion-panel-header>
                                <div class="col l12 m12 s12">
                                    <div class="row">
                                        <div class="input-field col l1 m1 s1" style="text-align: left;margin-top: 30px"><input
                                                type="checkbox" [(ngModel)]="lst.arecheselect" #arecheselect="ngModel"
                                                (click)="onSelectArecheselect(i,$event)">
                                        </div>
                                        <div class=" col l4 m4 s4" style="text-align:left;margin-top: 30px">
                                            <span>{{lst.areaName}}</span>
                                        </div>
                                        <div class="col col l2 m2 s2" style="text-align: right;">
                                            <div class="row">
                                                <div class=" input-field col s10 "
                                                    style="text-align: right;padding: 0px;" *ngIf="model.percentage>0 ">
                                                    <input [(ngModel)]="lst.percentage1" #percentage1="ngModel"
                                                        type="text" data-toggle="tooltip" data-placement="top"
                                                        id="first2" pattern="[0123456789][0-9]{9}" maxlength="100"
                                                        (ngModelChange)="onChangeOfareapercentage(i);"
                                                        style="text-align: right;" title="Percentage Allocated"
                                                        required>
                                                    <label id="lable2" for="first2">% Allocation</label>
                                                </div>
                                                <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                                    &#37;</div>
                                            </div>
                                        </div>
                                        <!-- <div class="col s4" style="text-align: left;margin-top: 30px;padding-left: 24px;">
                                            <span style="font-size: 14px;font-style: normal;">Balance:&nbsp;</span><span
                                                style="font-size: 13px;">{{lst.remainpercentage1 |
                                                number:'1.2-2'}}&#37;&nbsp;&nbsp;</span>
                                        </div> -->
                                    </div>
                                </div>
                            </mat-expansion-panel-header>


                            <div *ngIf="lst.citylst.length>0" class="registerBgShadow col l12 m12 s12"
                                style="text-align: center; margin-top: 10px;">
                                <div class="col l12 m12 s12" style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 12px;height: 34.1875px;margin-top: 9px;">
                                    <div class=" col s1" style="text-align:left;width: 52px;">
                                        <span></span>
                                    </div>
                                    <div class=" col s4" style="text-align:left;">
                                        <span>Cities</span>
                                    </div>
                                    <div class="col s2" style="text-align:left;padding-left: 12px;">
                                        <span>% Allocation</span>
                                    </div>
                                    <div class="col s4" style="text-align:left;padding-left: 15px;">
                                        <span>Balance : {{lst.remainpercentage1 | number:'1.2-2'}}&#37;</span>
                                    </div>
                                </div>

                                <div class="row" *ngFor="let sublst of lst.citylst; let j = index">
                                    <div class=" input-field col l1" style="text-align: left;margin-top: 9px;padding-left: 18px;"><input
                                            type="checkbox" [(ngModel)]="sublst.checkselect" #checkselect="ngModel"
                                            (click)="onSelectCheckselect(i,$event,j)">
                                    </div>
                                    <div class="col l4 m4 s4" style="text-align:left;margin-top: 9px">
                                        <span>{{sublst.cityname}}</span>
                                    </div>
                                    <div class="col s2" style="text-align: right;margin-top: 0px">
                                        <div class="row">
                                            <div class=" input-field col s10 " style="text-align: right;padding: 0px;margin-top: 0px">
                                                <input [(ngModel)]="sublst.percentage2" #percentage2="ngModel"
                                                    type="text" id="first3" pattern="[0123456789][0-9]{9}"
                                                    data-toggle="tooltip" maxlength="100" data-placement="top"
                                                    style="text-align: right;"
                                                    (ngModelChange)="onChangeOfcitypercentage(i,j);"
                                                    title="Percentage Allocated" required style="margin-bottom: 0px;text-align: right;">
                                                <label id="lable3" for="first3">% Allocation</label>
                                            </div>
                                            <div class=" input-field col s1" style="margin-top:21px;padding: 0px;margin-bottom: 0px;">
                                                &#37;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="lst.citylst.length==0" class="col l11 m11 s11 registerBgShadow"
                                style="margin-left:50px;text-align: center;">
                                <div class="col l12 m12 s12" style="color: red">
                                    <samp> List Not Found </samp>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>