export class TeamWisePerformanceReportModel {

    id: number;
    frmdate: Date;
    todate: Date;
    companyname: string;
    companyaddress: String;

    regionid: number;
    areaid: number;
    branchid: number;
    resourceID: number;

    name: String;//region
    areaname: string;
    branchname: string;
    resourcename: string;

    txtselect: string;
    searchtype: string;

    areaintlst: number[];
    resourceintlst: number[];
    cityintlst: number[];
    localeintlst: number[];
    localeint: number[];
    status: string;
    dateTime: Date;
    type: string;
    desciption: string;
    firstName: string;
    customerName: string;
    name1: string;
    name2: string;
    name3: string;
    stageCode: string;
    followuptaskdate: Date;
    enggName: string;
    userid: number;
    teamtype: string;

    ///////DcrSummary Model

    openCalls: number;
    closeCalls: number;
    allCalls: number;

    ////////EnggWiseQuotaion Report
    startDate: Date;
    customername: string;
    quotationValue: number;
    enquiry: string;
    srno: number;
    cityid: number;

    allTotal: number;
    openTotal: number;
    lostTotal: number;
    undecidedTotal: number;
    wonTotal: number;
    completeTotal: number;
    postpondTotal: number;
    undrInstTotal: number;

    openPerc: number;
    lostPerc: number;
    undecidedPerc: number;
    wonPerc: number;
    completePerc: number;
    postpondPerc: number;
    undrInstPerc: number;
    localName: string;
    cityName:string;
    teamintlst: number[];
    teamid:number;
    zeroValue:boolean;
}