export class SearchPaymentReceivableReceiptModel {
    id: number;
    srno: number;
    partyname: string;
    paymentNo: string;
    paymenttype: string;
    paymentDate: Date;
    txtserch:string;
    userid: number;
    txtselect:string;
}