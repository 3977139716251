import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LoginComponent } from './login/login.component';
import { AuthenticationService, AlertService, MasterServices, TransactionServices, MessageService, CompanyService, MenuServices, DealersService, ProductionService } from './_services';
import { InstallationService } from '../app/_services/Installation.Service';
import { HttpErrorHandler } from './_services/http-error-handler.service';
import { DatePipe, LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { EncrDecrService } from './_services/EncrDecrService';
import { ReportServices } from './_services/ReportServices';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MasterConfiguration } from './ConfigurationPages/MasterConfiguration/MasterConfiguration';
import { HeaderComponent } from './header/header.component';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchUser } from './Master/ResourceMaster/SearchUser';
import { DefineUser } from './Master/ResourceMaster/DefineUser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal'
import { SearchAccountMaster } from './Master/AccountMaster/SearchAccountMaster';
import { NgxPaginationModule } from 'ngx-pagination';
import { DefineAccountMaster } from './Master/AccountMaster/DefineAccountMaster';
import { SearchPipeline } from './Master/PipelineMaster/SearchPipeline';
import { DefinePipeline } from './Master/PipelineMaster/DefinePipeline';
import { SearchAccountWiseMachineDetail } from './Master/AccountWiseMachineDetailMaster/SearchAccountWiseMachineDetail';
import { DefineAccountwisemachinedetail } from './Master/AccountWiseMachineDetailMaster/DefineAccountwisemachinedetail';
import { SearchCompanyMaster } from './Master/CompanyMaster/SearchCompanyMaster';
import { DefineCompanyMaster } from './Master/CompanyMaster/DefineCompanyMaster';
import { CRMHome } from './CRMHome/CRMHome';
import { DefineMaster } from './Master/ConfiguratedMasters/DefineMaster';
import { MasterHomePage } from './Master/ConfiguratedMasters/MasterHomePage';
import { SearchEnquiryname } from './Master/EnquiryNameMaster/SearchEnquiryname';
import { SearchCurrencyExchange } from './Master/CurrencyExchange/SearchCurrencyExchange';
import { SearchProduct } from './Master/ProductMaster/SearchProduct';
import { DefineProduct } from './Master/ProductMaster/DefineProduct';
import { DefineResourceAllowance } from './Master/ResourceAllowance/DefineResourceAllowance';
import { SearchResourceAllowance } from './Master/ResourceAllowance/SearchResourceAllowance';
import { SearchTypeMaster } from './Master/TypeMaster/SearchTypeMaster';
import { DefineTypeMaster } from './Master/TypeMaster/DefineTypeMaster';
import { DefineAMCType } from './Master/AMCTypeMaster/DefineAMCType';
import { SearchAMCType } from './Master/AMCTypeMaster/SearchAMCType';
import { SearchLocality } from './Master/Locality/SearchLocality';
import { DefineLocality } from './Master/Locality/DefineLocality';
import { DesignationAllowance } from './Master/DesignationMaster/DesignationAllowance';
import { TreeModule } from 'angular-tree-component';
import { SelectUser } from './menu/SelectUser/SelectUser';
import { AddMenuComponent } from './menu/add-menu/add-menu.component';
import { ContextmenuComponent } from './menu/contextmenu.component';
import { DefineMenuItem } from './menu/Menu_Items/DefineMenuItem';
import { SearchMenuItem } from './menu/Menu_Items/SearchMenuItem';
import { SearchUserGroup } from './menu/UserGroup/SearchUserGroup';
import { SearchAllowanceMaster } from './Master/AllowanceMaster/SearchAllowanceMaster';
import { DefineAllowance } from './Master/AllowanceMaster/DefineAllowance';
import { SearchSource } from './Master/SourceMaster/SearchSource';
import { DefineSource } from './Master/SourceMaster/DefineSource';
import { SourceDetail } from './Master/SourceMaster/SourceDetail';
import { SearchCategoryTermsCon } from './Master/CategoryTermConditionMaster/SearchCategoryTermsCon';
import { DefineCategoryTermsCon } from './Master/CategoryTermConditionMaster/DefineCategoryTermsCon';

import { SearchCityMaster } from './Master/CityMaster/SearchCityMaster';
import { DefineCityMaster } from './Master/CityMaster/DefineCityMaster';
import { CustomerTypeWorkflow } from './Master/CustomerTypeWorkflow/CustomerTypeWorkflow';
import { ResourceRights } from './Master/ResourceRights/ResourceRights';
import { SearchTeam } from './Transactions/Teams/SearchTeam';
import { DefineTeam } from './Transactions/Teams/DefineTeam';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Prospect } from './Transactions/Sales-Transaction/Prospect';
import { DailyCall } from './Transactions/DailyCall/DailyCall';
import { CallCenterDashboard } from './Transactions/CallCenterDashboard/CallCenterDashboard';
import { Opportunities } from './Transactions/Opportunities/Opportunities';
import { Quotation } from './Transactions/Quotation/Quotation';
import { searchgstheader } from './Master/GSTForm/searchgstheader';
import { Gstheadermaster } from './Master/GSTForm/Gstheadermaster';
import { GSTgroupmaster } from './Master/GSTForm/GSTgroupmaster';
import { ChangePassword } from './Master/ChangePassword/ChangePassword';
import { ResetPassword } from './Master/ResetPassword/ResetPassword';
import { SearchTarget } from './Transactions/TargetManagement/SearchTarget';
import { SearchActivity } from './Master/ActivityMaster/SearchActivity';
import { DefineActivity } from './Master/ActivityMaster/DefineActivity';
import { Financialyear } from './Master/FinancialYear/Financialyear';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SearchUOM } from './Master/UnitofMeasure/SearchUOM';
import { DefineMarketing } from './Master/MarketingCostSubMaster/DefineMarketing';
import { SearchMarketing } from './Master/MarketingCostSubMaster/SearchMarketing';

import { TargetHome } from './Transactions/TargetManagement/ TargetHome';
import { ServiceCall } from './Transactions/ServiceCallTransaction/Servicecall';
import { OrderHomepage } from './Transactions/OrderAcceptance/OrderHomepage';
import { ServiceCallDashboard } from './Transactions/ServiceCallDashboard/ServiceCallDashboard';
import { DefineOrderAcceptance } from './Transactions/OrderAcceptance/DefineOrderAcceptance';
import { SearchDealer } from './DealersDetail/SearchDealer';
import { DealersDetail } from './DealersDetail/DealersDetail';


import { SearchProductcategory } from './Master/ProductCategoryMaster/SearchProductcategory';
import { DefineProductcategory } from './Master/ProductCategoryMaster/DefineProductcategory';
// import { DefineitemSubCategory } from './Master/ItemSubCategoryMaster /DefineitemSubCategory';
// import { SearchitemSubCategory } from './Master/ItemSubCategoryMaster /SearchitemSubCategory';
import { SearchInventoryLoc } from './Master/InventoryLocationMaster/SearchInventoryLoc';
import { DefineInventoryLoc } from './Master/InventoryLocationMaster/DefineInventoryLoc';



import { SearchPurchaseorder } from './Transactions/DealerPurchaseOrder/SearchPurchaseorder';
import { DefinePuchaseOrder } from './Transactions/DealerPurchaseOrder/DefinePuchaseOrder';

import { PaymentScreen } from './Transactions/PaymentScreen/PaymentScreen';
import { DefineItem } from './Master/ItemMaster/DefineItem';
import { SearchItem } from './Master/ItemMaster/SearchItem';
import { DefineServiceCharges } from './Master/ServiceChergesMaster/DefineServiceCharges';

import { InstallationDashbord } from './Transactions-Installation/InstallationsDashboard/InstallationsDashbord';
import { SchemeConfigurate } from './Transactions/Scheme_Configurator/SchemeConfigurate';



import { DispatchOrder } from './Transactions/DispatchOrder/DispatchOrder';
import { DispatchOrderHome } from './Transactions/DispatchOrder/DispatchOrderHome';

import { SearchServiceCharges } from './Master/ServiceChergesMaster/SearchServiceCharges';
import { DefineVendor } from './Master/Vendor Master/DefineVendor';
import { VendorService } from './_services/VendorService';
import { SearchVendor } from './Master/Vendor Master/SearchVendor';

import { CreateInstallation } from './Transactions-Installation/CreateInstallation/CreateInstallation';
import { MarketBudgetGeoperTem } from './Transaction-Marketing/MarketingBudgGeoPercentageallocTem/MarketBudgetGeoperTem';
import { MarketingProposal } from './Transaction-Marketing/Marketing/MarketingProposal';
import { MarketingProposalHome } from './Transaction-Marketing/Marketing/MarketingProposalHome';
import { SearchInstallationDCR } from './Transactions-Installation/DCR/SearchInstallationDCR';
import { InstallationDCR } from './Transactions-Installation/DCR/InstallationDCR';

import { MarketingServices } from './_services/MarketingServices';
import { AccountThreeSixty } from './Transactions/AccountThreeSixty/AccountThreeSixty';
import { SearchMCHallocaTemplate } from './Transaction-Marketing/MarketingCostHeadPercentageAllocaTemplate/SearchMCHallocaTemplate';
import { DefineMarketingBudget } from './Transaction-Marketing/MarketingBudget/DefineMarketingBudget';
import { mrkbudgetdealerallocation } from './Transaction-Marketing/MarketingBudgetDealerAllocation/mrkbudgetdealerallocation';
import { Salesdashboard } from './Transactions/SalesDashboard/Salesdashboard';
import { Payment } from './Transactions/DealerPurchaseOrder/Payment';

import { DCRreport } from './Reports/DCR Report/DCRreport';

import { ConversionTemplate } from './Transactions-Production/Conversion Template/ConversionTemplate';
import { DefinePurchaseOrder } from './InventoryManagement/PurchaseOrder/DefinePurchaseOrder';
import { SearchPurchaseOrder } from './InventoryManagement/PurchaseOrder/SearchPurchaseOrder';
import { PublicServices } from './_services/PublicServices';
import { DefinePaymentReceivableReceipt } from './Transactions/PaymentReceivableReceipt/DefinePaymentReceivableReceipt';
import { SearchPaymentReceivableReceipt } from './Transactions/PaymentReceivableReceipt/SearchPaymentReceivableReceipt';

import { Stockadjustment } from './Transactions/Stock Adjustment/Stockadjustment';

import { SearchconversionTemplate } from './Transactions-Production/Conversion Template/SearchconversionTemplate';
import { GoodsNoteHome } from './InventoryManagement/GoodsNote/GoodsNoteHome';
import { GoodsNoteDefine } from './InventoryManagement/GoodsNote/GoodsNoteDefine';
import { InventoryServices } from './_services/InventoryServices';
import { DailyProductionPlan } from './Transactions-Production/Daily Production Plan/DailyProductionPlan';
import { SearchDailyProductionPlan } from './Transactions-Production/Daily Production Plan/SearchDailyProductionPlan';
import { PlanItemConversion } from './Transactions-Production/Daily Production Plan/PlanItemConversion';
import { SchemeHome } from './Transactions/Scheme_Configurator/SchemeHome';
import { QuotationReport } from './Reports/Quotation Report/QuotationReport';
import { SearchMaterialRequisition } from './Transactions-Production/Material Requisition/SearchMaterialRequisition';
import { DefineMaterialRequisition } from './Transactions-Production/Material Requisition/DefineMaterialRequisition';
import { ReassignEnquiry } from './Transactions/DailyCall/ReassignEnquiry';
import { CreatePlanRequisition } from './Transactions-Production/CreatePlanRequisition/CreatePlanRequisition';
import { SearchProductionBooking } from './Transactions-Production/Production Booking/SearchProductionBooking';
import { DefineProductionBooking } from './Transactions-Production/Production Booking/DefineProductionBooking';
import { StockLedgerReport } from './Reports/Inventory Stock Ledger Report/StockLedgerReport';
import { OpportunityReport } from './Reports/Opportunity Report/OpportunityReport';
import { OrdInHandReport } from './Reports/Order In Hand Report/OrdInHandReport';
import { CorporateCustProcurementSmry } from './Reports/Corporate Customer Procurement Summary/CorporateCustProcurementSmry';

import { SampleInvoice } from './Transactions/OrderAcceptance/SampleInvoice';

import { SearchPurchaseReturn } from './InventoryManagement/PurchaseReturn/SearchPurchaseReturn';
import { DefinePurchaseReturn } from './InventoryManagement/PurchaseReturn/DefinePurchaseReturn';
import { MaterialInventoryHome } from './InventoryManagement/MaterialInventoryHome/MaterialInventoryHome';
import { VisitDetailSmry } from './Reports/Visit Detail Summary/VisitDetailSmry';
import { SpareIssue } from './Transactions/Spare Issue/SpareIssue';
import { InstallationSummary } from './Reports/InstallationSummary/InstallationSummary';
import { ReassEnquiry } from './Transactions/ServiceCallDashboard/ReassEnquiry';
import { ThreeSixtyMaster } from './Master/ThreeSixtyMaster/ThreeSixtyMaster';
import { ServiceDashboard } from './Transactions/ServiceDashboard/ServiceDashboard';
import { DCRSummaryreport } from './Reports/DCR Report/DCRSummaryreport';
// import { EngineerWiseQuotationReport } from './Reports/DCR Report/EngineerWiseQuotationReport';
import { CRMMObileHome } from './CRMHome/CRMMObileHome';
import { Mobilecomponent } from './header/header.mobilecomponent';
import { Quotationdashboard } from './Transactions/Quotation/Quotationdashboard';
import { Firingform } from './Reports/Firing Form/Firingform';
import { ExecWiseQuotationDetailReport } from './Reports/DCR Report/ExecWiseQuotationDetailReport';
import { ReferencersReport } from './Reports/Referencer Report/ReferencersReport';
import { AreaWisePerformanceReport } from './Reports/Area Wise Performance Report/AreaWisePerformanceReport';
import { DefineMCHallocaTEMP } from './Transaction-Marketing/MarketingCostHeadPercentageAllocaTemplate/DefineMCHallocaTEMP';
import { TeamWisePerformanceReport } from './Reports/Team Wise Performance Report/TeamWisePerformanceReport';
import { SuperwisingAuthorityWiseOrderReport } from './Reports/Superwising Authority Wise Order Report/SuperwisingAuthorityWiseOrderReport';
import { ServiceExeWiseQuotationDtlReport } from './Reports/Service Executive Wise Quotation Detail Report/ServiceExeWiseQuotationDtlReport';
import { OpenCloseServiceEnquiriesReport } from './Reports/OpenCloseServiceEnquiriesReport/OpenCloseServiceEnquiriesReport';
import { PerformanceReprotofTechnicians } from './Reports/Performance Reprot of Technicians/PerformanceReprotofTechnicians';
import { CustomerMerge } from './Master/Customer Merge/CustomerMerge';
import { Reassign } from './Transactions/Reassign/Reassign';
import { SalesReturn } from './Transactions/Sales Return/SalesReturn';
// MatIconModule, MatButtonModule
// import {MatTreeFlatDataSource, MatTreeFlattener,MatTreeModule} from '@angular/material/tree';  
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { DelearDailyCall } from './Transactions/DailyCall/DelarDailyCall';
import { SearchitemSubCategory } from './Master/ItemSubCategoryMaster/SearchitemSubCategory';
import { DefineitemSubCategory } from './Master/ItemSubCategoryMaster/DefineitemSubCategory';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MasterConfiguration,
    HeaderComponent,
    SearchUser,
    DefineUser,
    SearchAccountMaster,
    DefineAccountMaster,
    SearchPipeline,
    DefinePipeline,
    SearchAccountWiseMachineDetail,
    DefineAccountwisemachinedetail,
    SearchCompanyMaster,
    DefineCompanyMaster,
    MasterHomePage,
    DefineMaster,
    SearchEnquiryname,
    SearchCurrencyExchange,
    SearchProduct,
    DefineProduct,
    CRMHome,
    DefineResourceAllowance,
    SearchResourceAllowance,
    SearchTypeMaster,
    DefineTypeMaster,
    DefineAMCType,
    SearchAMCType,
    SearchLocality,
    DefineLocality,
    DesignationAllowance,
    SelectUser,
    AddMenuComponent,
    ContextmenuComponent,
    SearchAccountMaster,
    DefineMenuItem,
    SearchMenuItem,
    SearchUserGroup,
    SearchAllowanceMaster,
    DefineAllowance,
    SearchSource,
    DefineSource,
    SourceDetail,
    SearchCategoryTermsCon,
    DefineCategoryTermsCon,
    SearchCityMaster,
    DefineCityMaster,
    CustomerTypeWorkflow,
    ResourceRights,
    SearchTeam,
    DefineTeam,
    Prospect,
    DailyCall,
    ReassignEnquiry,
    CallCenterDashboard,
    Opportunities,
    Quotation,
    searchgstheader,
    Gstheadermaster,
    GSTgroupmaster,
    ChangePassword,
    ResetPassword,
    SearchTarget,
    DefineActivity,
    SearchActivity,
    Financialyear,

    SearchUOM,
    DefineMarketing,
    SearchMarketing,
    SearchProductcategory,
    DefineProductcategory,
    DefineitemSubCategory,
    SearchitemSubCategory,
    SearchInventoryLoc,
    DefineInventoryLoc,
    DefineItem,
    SearchItem,
    DefineServiceCharges,
    SearchServiceCharges,
    DefineVendor,
    SearchVendor,

    TargetHome,
    ServiceCall,
    OrderHomepage,
    ServiceCallDashboard,
    DefineOrderAcceptance,
    SearchDealer,
    DealersDetail,

    SearchPurchaseorder,
    DefinePuchaseOrder,

    PaymentScreen,
    SchemeConfigurate,
    InstallationDashbord,

    DispatchOrder,
    DispatchOrderHome,
    SearchMCHallocaTemplate,
    CreateInstallation,
    MarketBudgetGeoperTem,
    MarketingProposal,
    MarketingProposalHome,
    InstallationDCR,
    SearchInstallationDCR,
    DefineMarketingBudget,
    mrkbudgetdealerallocation,
    AccountThreeSixty,
    Salesdashboard,
    Payment,
    ServiceDashboard,
    GoodsNoteHome,
    GoodsNoteDefine,
    DCRreport,
    DCRSummaryreport,
    // EngineerWiseQuotationReport,
    ExecWiseQuotationDetailReport,
    SuperwisingAuthorityWiseOrderReport,
    QuotationReport,
    StockLedgerReport,
    OpportunityReport,
    OrdInHandReport,
    CorporateCustProcurementSmry,
    VisitDetailSmry,
    InstallationSummary,
    ReferencersReport,
    AreaWisePerformanceReport,
    TeamWisePerformanceReport,
    ServiceExeWiseQuotationDtlReport,
    OpenCloseServiceEnquiriesReport,
    PerformanceReprotofTechnicians,
    ConversionTemplate,
    DefinePurchaseOrder,
    SearchPurchaseOrder,
    DefinePaymentReceivableReceipt,
    SearchPaymentReceivableReceipt,
    Stockadjustment,
    SearchconversionTemplate,
    DailyProductionPlan,
    SearchDailyProductionPlan,
    PlanItemConversion,
    SchemeHome,
    SearchMaterialRequisition,
    DefineMaterialRequisition,
    CreatePlanRequisition,
    SearchProductionBooking,
    DefineProductionBooking,
    SampleInvoice,
    SearchPurchaseReturn,
    DefinePurchaseReturn,
    MaterialInventoryHome,
    SpareIssue,
    ReassEnquiry,
    ThreeSixtyMaster,
    CRMMObileHome,
    Mobilecomponent,
    Quotationdashboard,
    Firingform,
    DefineMCHallocaTEMP,
    CustomerMerge,
    Reassign,
    SalesReturn,
    DelearDailyCall,

  ],
  imports: [
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    BrowserModule,
    AppRoutingModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    TreeModule,
    ModalModule.forRoot(),
    TreeModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2500,
    }),
    RouterModule.forRoot([]),
    BsDatepickerModule.forRoot(),
    NgxPaginationModule,
    ModalModule.forRoot(),
    MatExpansionModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    InfiniteScrollModule,
    MatRadioModule
  ],
  providers: [
    AuthenticationService,
    AlertService,
    MasterServices,
    TransactionServices,
    PublicServices,
    VendorService,
    ProductionService,
    InstallationService,

    HttpErrorHandler,
    MenuServices,
    DatePipe,
    MessageService,
    DealersService,
    CompanyService,
    MarketingServices,
    InventoryServices,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ReportServices,
    EncrDecrService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
