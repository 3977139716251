<app-header></app-header>
<div class="container" style="width:100%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Sales Dashboard</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="row">
                    <div class="col s9"><span
                            style="font-size: 17px;font-weight: bold;padding-left: 9px;">Overdue</span>
                        <span style="font-size: 15px;margin-left: 80%;"><a>See All{{value}} </a> </span>
                        <!-- (click)="openOverduemodel(overduemodel)" -->
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="25" style="text-align: left;padding-left: 10px;">Enquiry ID</th>
                                    <th width="25" style="text-align: left">Date</th>
                                    <th width="10" style="text-align: left">Customer name</th>
                                    <th width="15" style="text-align: left">Product Category/Model</th>
                                    <th width="25" style="text-align: left">Activity</th>
                                    <th width="25" style="text-align: left">Stage</th>
                                    <th width="25" style="text-align: left">Owner</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of saleslst; let i = index">
                                        <td
                                            style="text-align: left;padding-left: 10px;padding-top: 0px;padding-bottom: 0px;">
                                            <a (click)="onclickenquiryId(lst.id)">{{lst.enquiryId}}</a>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.dueDate | date: 'dd-MM-yyyy'}}</p>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customername}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.productModel}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.genactivity}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.stageCode}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">{{lst.own}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span> -->
                        </div>
                    </div>
                    <div class="col s3">
                    </div>
                </div>
                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Next 30 Days</span>
                        <span style="font-size: 15px;margin-left: 78%;"><a>See All{{valueall}}</a></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9">

                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="25" style="text-align: left;padding-left: 10px;">Enquiry ID</th>
                                    <th width="25" style="text-align: left">Date</th>
                                    <th width="10" style="text-align: left">Customer name</th>
                                    <th width="15" style="text-align: left">Product Category/Model</th>
                                    <th width="25" style="text-align: left">Activity</th>
                                    <th width="25" style="text-align: left">Stage</th>
                                    <th width="25" style="text-align: left">Owner</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of nextdayslst">


                                        <td
                                            style="text-align: left;padding-left: 10px;padding-top: 0px;padding-bottom: 0px;">
                                            <a (click)="onclickenquiryid(lst.id)">{{lst.enquiryId}}</a>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.dueDate | date: 'dd-MM-yyyy'}}</p>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customername}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.productModel}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.genactivity}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.stageCode}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">{{lst.own}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span> -->
                        </div>

                    </div>
                    <div class="col s3">

                    </div>

                </div>
                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Approvals</span><span
                            style="font-size: 15px;margin-left: 80%;"><a (click)="seeall()">See All </a> </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9">

                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">


                                    <th width="20" style="text-align: left;padding-left: 10px;">Date</th>
                                    <th width="25" style="text-align: left">Name</th>
                                    <th width="30" style="text-align: left">Document Type</th>

                                    <th width="25" style="text-align: left">Action</th>

                                </thead>
                                <tbody>

                                    <tr *ngFor="let lst of approvedlst">

                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.startdate | date: 'dd-MM-yyyy'}}</p>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.contactname}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.documentType}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;"><span><img
                                                    src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst.prospectOpporId)" data-toggle="tooltip"
                                                    data-placement="top" title="Edit" width="20px" /></span>&nbsp;&nbsp;
                                        </td>


                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="col s3">
                    </div>
                </div>

                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Accounts
                            Receivable</span><span style="font-size: 15px;">&nbsp;&nbsp; Total Receivable
                            &#8377;:{{Total}}</span><span style="font-size: 15px;margin-left: 55%;"><a>See All </a>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9">

                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20" style="text-align: left;padding-left: 10px;">Date</th>
                                    <th width="25" style="text-align: left">Name</th>
                                    <th width="30" style="text-align: left"></th>
                                    <th width="25" style="text-align: center">Action</th>

                                </thead>
                                <!-- <tbody>
                                        <tr *ngFor="let lst of approvedlst">
                                         

                                            <td style="text-align: center;padding-top: 0px;padding-bottom: 0px;">{{lst.startdate}}</td>
                                            <td style="text-align: center;padding-top: 0px;padding-bottom: 0px;">{{lst.contactname}}</td>
                                            <td style="text-align: center;padding-top: 0px;padding-bottom: 0px;"><span><img src="../../../assets/images/edit.png"

                                            <td style="text-align: center"><span><img src="../../../assets/images/edit.png"

                                                (click)="btnedit(lst.tbqotation)" data-toggle="tooltip" data-placement="top"
                                                title="Edit" width="20px" /></span>&nbsp;&nbsp;</td>
                                            
                                            
                                        </tr>
                                    </tbody> -->
                            </table>

                        </div>
                    </div>
                    <div class="col s3">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<!-- <ng-template #overduemodel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-content registerBgShadow " style="text-align: center;">
        <h4>Overdue Activitys</h4>
        <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
            <div class="row">
                <div class="col l12 m12 s12">
                 
                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="25" style="text-align: center">Enquiry ID</th>
                                <th width="25" style="text-align: center">Date</th>
                                <th width="10" style="text-align: center">Party</th>
                                <th width="15" style="text-align: center">Product Model</th>
                                <th width="25" style="text-align: center">Activity</th>
                                <th width="25" style="text-align: center">Stage</th>
                                <th width="25" style="text-align: center">Own</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of saleslst | paginate: { itemsPerPage: 10, currentPage: p }">
                                 
                                    <td style="text-align: center"><a href="enquiryId" (click)="enquiryId()">{{lst.enquiryId}}</a></td>
                                    <td style="text-align: center">{{lst.dueDate}}</td>
                                    <td style="text-align: center">{{lst.customername}}</td>
                                    <td style="text-align: center">{{lst.productModel}}</td>
                                     <td style="text-align: center">{{lst.genactivity}}</td>
                                      <td style="text-align: center">{{lst.stageCode}}</td>
                                       <td style="text-align: center">{{lst.own}}</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</ng-template> -->