import { OnInit, Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common';
import { User } from 'src/app/_models';
import { SearchMaterialRequisitionModel } from './SearchMaterialRequisitionModel';
import { BsModalService } from 'ngx-bootstrap';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { DefineMaterialRequisitionModel } from './DefineMaterialRequisitionModel';
import { timeStamp } from 'console';
declare var $: any;
@Component({
    selector: 'app-SearchMaterialRequisition',
    templateUrl: 'SearchMaterialRequisition.html',
})

export class SearchMaterialRequisition implements OnInit {
    modalRef: any;
    requisitionDate: Date;
    issueDate: Date;
    locationlst: DropDownModel[];
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    requisitionlst: DefineMaterialRequisitionModel[] = [];

    tolocationlst: DropDownModel[];
    mrpmap = new Map();
    userList2: any;
    id: number;
    public i;
    itemname: string;
    itemcodemap = new Map();
    hsnCodemap = new Map();
    uommap = new Map();
    stockforsalemap = new Map();
    quantitymap = new Map();
    p: any;
    user = new User();
    model: any = {};
    updatequantity: number;
    tolocstock: number;
    fromlocstock: number;
    requisionlst: SearchMaterialRequisitionModel[] = [];
    requisionlst1: SearchMaterialRequisitionModel[] = [];
    miid: string;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {

    }
    ngOnInit(): void {
        this.getallrequisition();
        // this.getInventoryLocation();
        this.getitemCategory();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.requisitionDate = new Date();
        this.issueDate = new Date();

    }
    getallrequisition() {
        this.transactionService.getallrequisition(this.model)
            .subscribe(data => {
                this.requisionlst = data
                this.requisionlst1 = [];
                data.forEach((item) => this.requisionlst1.push({
                    "id": item.id,
                    "name": item.name,
                    "enddate": item.enddate,
                    "requestor": item.requestor,
                    "txtserch": item.txtserch,
                    "requestorcheckselect": item.requestorcheckselect,
                    "reqnocheckselect": item.reqnocheckselect,
                    "statuscheckselect": item.statuscheckselect,
                    "userid": item.userid,
                    "startdate": item.startdate,
                    "tolocationname": item.tolocationname,
                    "locationname": item.locationname,
                    "requisitionQuantity": item.requisitionQuantity,
                    "issuedquantity": item.issuedquantity,
                    "txtserchstatus": item.txtserchstatus,
                    "updatequantity": item.updatequantity,
                    "quantity": item.quantity,
                    "requisitionDate": item.requisitionDate,
                    "stockforsaletoloc": item.stockforsaletoloc,
                    "tolocstock": item.tolocstock,
                    "stockforsalefloc": item.stockforsalefloc,
                    "fromlocstock": item.fromlocstock,
                    "fromlocationname": item.fromlocationname,
                    "requisitiondate": item.requisitiondate,
                    "status": item.status,
                    "requisitionNo": item.requisitionNo,
                    "txtserchreqno": item.txtserchreqno,
                    "requestors": item.requestors,
                    "locationlst": null,
                    "miid": item.miid,
                    "complete": item.complete,
                }));
                this.requisionlst = this.requisionlst1;

            });
    }

    btnsearch() {
        if (this.model.suppliercheckselect) {
            this.requisionlst1 = [];
            this.requisionlst.forEach((item) => {
                if ((item.requestor.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                    this.requisionlst1.push({
                        "id": item.id,
                        "name": item.name,
                        "enddate": item.enddate,
                        "requestor": item.requestor,
                        "txtserch": item.txtserch,
                        "requestorcheckselect": item.requestorcheckselect,
                        "reqnocheckselect": item.reqnocheckselect,
                        "statuscheckselect": item.statuscheckselect,
                        "userid": item.userid,
                        "startdate": item.startdate,
                        "tolocationname": item.tolocationname,
                        "locationname": item.locationname,
                        "requisitionQuantity": item.requisitionQuantity,
                        "issuedquantity": item.issuedquantity,
                        "txtserchstatus": item.txtserchstatus,
                        "updatequantity": item.updatequantity,
                        "quantity": item.quantity,
                        "requisitionDate": item.requisitionDate,
                        "stockforsaletoloc": item.stockforsaletoloc,
                        "tolocstock": item.tolocstock,
                        "stockforsalefloc": item.stockforsalefloc,
                        "fromlocstock": item.fromlocstock,
                        "fromlocationname": item.fromlocationname,
                        "requisitiondate": item.requisitiondate,
                        "status": item.status,
                        "requisitionNo": item.requisitionNo,
                        "txtserchreqno": item.txtserchreqno,
                        "requestors": item.requestors,
                        "locationlst": null,
                        "miid": item.miid,
                        "complete": item.complete,
                    });
                }
            });
            this.requisionlst = this.requisionlst1;

        } else

            if (this.model.statuscheckselect) {
                this.requisionlst1 = [];
                this.requisionlst.forEach((item) => {
                    if ((item.status.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.requisionlst1.push({
                            "id": item.id,
                            "name": item.name,
                            "enddate": item.enddate,
                            "requestor": item.requestor,
                            "txtserch": item.txtserch,
                            "requestorcheckselect": item.requestorcheckselect,
                            "reqnocheckselect": item.reqnocheckselect,
                            "statuscheckselect": item.statuscheckselect,
                            "userid": item.userid,
                            "startdate": item.startdate,
                            "tolocationname": item.tolocationname,
                            "locationname": item.locationname,
                            "requisitionQuantity": item.requisitionQuantity,
                            "issuedquantity": item.issuedquantity,
                            "txtserchstatus": item.txtserchstatus,
                            "updatequantity": item.updatequantity,
                            "quantity": item.quantity,
                            "requisitionDate": item.requisitionDate,
                            "stockforsaletoloc": item.stockforsaletoloc,
                            "tolocstock": item.tolocstock,
                            "stockforsalefloc": item.stockforsalefloc,
                            "fromlocstock": item.fromlocstock,
                            "fromlocationname": item.fromlocationname,
                            "requisitiondate": item.requisitiondate,
                            "status": item.status,
                            "requisitionNo": item.requisitionNo,
                            "txtserchreqno": item.txtserchreqno,
                            "requestors": item.requestors,
                            "locationlst": null,
                            "miid": item.miid,
                            "complete": item.complete,

                        });
                    }
                });
                this.requisionlst = this.requisionlst1;

            }
    }
    btnnew() {
        this.router.navigate(["/DefineMaterialRequisition"])
    }

    // getInventoryLocation() {
    //     this.locationlst = [];
    //     this.transactionService.getInventoryLocation(this.model)
    //         .subscribe(data => {
    //             this.model = data;
    //             this.locationlst = data.locationLst
    //         });
    // }
    // onSelectInventoryLocationFrom(value) {
    //     if (value == 0) {
    //         this.notifyService.showRroor("Select Location !!", "Oops!");
    //         return;
    //     }

    //     for (let i = 0; i < this.locationlst.length; i++) {
    //         if (this.locationlst[i].id == value) {
    //             this.model.locationid = this.locationlst[i].id;
    //             this.model.locationname = this.locationlst[i].name;

    //         }
    //     }
    // }

    btnopenissuemodel(id, status, i, issuemodel: TemplateRef<any>) {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.id = id
        this.model.status = status
        this.transactionService.getmaterialrequisionnote(this.model)
            .subscribe(data => {
                this.model = data;
                this.requisitionlst = data.locationlst
            });
        this.modalRef = this.modalService.show(issuemodel, {
            class: 'modal-lg'
        });
    }
    btnopenmodel(id, status, issuemodel: TemplateRef<any>) {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.id = id
        this.model.status = status
        this.transactionService.getmaterialrequisionnote(this.model)
            .subscribe(data => {
                this.model = data;
                this.requisitionlst = data.locationlst
            });

        this.modalRef = this.modalService.show(issuemodel, {
            class: 'modal-lg'
        });
    }

    closemodel() {
        this.modalRef.hide();
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }

    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.itemsubcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.itemsubcategory = this.subCatlst[i].name
            }
        }
    }
    getItemLike($event) {

        let userId = (<HTMLInputElement>document.getElementById('itmname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.userid =  this.model.itemcategoryid;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLikePO(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "hsnCode": item.hsnCode, "uom": item.uom, "gstGrouppercentage": item.gstGrouppercentage, "stockforsale": item.stockforsale, "requisitionQuantity": item.requisitionQuantity }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].itemname, this.userList2[i].itemid)
                        this.itemcodemap.set(this.userList2[i].itemname, this.userList2[i].itemcode)
                        this.uommap.set(this.userList2[i].itemname, this.userList2[i].uom)
                        this.stockforsalemap.set(this.userList2[i].itemname, this.userList2[i].stockforsale)
                        this.quantitymap.set(this.userList2[i].itemname, this.userList2[i].requisitionQuantity)
                        searchDataObj[this.userList2[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList2[i].itemname;
                        } else {
                            names = names + "," + this.userList2[i].itemname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect1(itemid, itemname) {
        this.model.itemid = itemid;
        this.model.itemname = itemname;
        this.model.itemcode = this.itemcodemap.get(itemname);
        this.model.uom = this.uommap.get(itemname);
        this.model.stockforsale = this.stockforsalemap.get(itemname);
        this.model.requisitionQuantity = this.quantitymap.get(itemname);
    }
    btnadd() {
        for (let i = 0; i < this.requisitionlst.length; i++) {
            if (this.requisitionlst[i].itemid == this.model.itemid) {
                this.notifyService.showRroor("Item already present", " Oops");
                return;
            }
        }
        if (this.model.itemcategoryid == null) {
            this.notifyService.showRroor("Please Select Itemcategory", " Oops");
            return;
        }
        if (this.model.itemsubcategoryid == null) {
            this.notifyService.showRroor("Please Select ItemSubcategory", " Oops");
            return;
        }
        if (this.model.itemname == null) {
            this.notifyService.showRroor("Please Select Itemname", " Oops");
            return;
        }

        this.requisitionlst.push({
            id: this.model.id,
            locationid: this.model.locationid,
            locationname: this.model.locationname,
            userid: this.model.userid,
            quantity: this.model.quantity,
            itemcategoryid: this.model.itemcategoryid,
            itemsubcategoryid: this.model.itemsubcategoryid,
            itemname: this.model.itemname,
            itemcategory: this.model.itemcategory,
            itemsubcategory: this.model.itemsubcategory,
            itemid: this.model.itemid,
            uom: this.model.uom,
            itemcode: this.model.itemcode,
            stockforsale: this.model.stockforsale,
            fromlocationname: "",
            tolocationname: "",
            locid: this.model.locid,
            requisitionDate: this.model.requisitionDate,
            requisitionId: "",
            requestor: this.model.requestor,
            requisitionQuantity: this.model.requisitionQuantity,
            issuedquantity: this.model.issuedquantity,
            tolocationid: this.model.tolocationid,
            stockforsalefloc: this.model.stockforsalefloc,
            miid: this.model.miid,
            stocklocationlst: this.model.stocklocationlst,
            checkselect: false,
            balquantity: this.model.balquantity,
            tolocstock:this.model.tolocstock,
            fromlocstock:this.model.fromlocstock,

        })

    }
    btndelete(index, id) {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.transactionService.deletematerialRequisition(this.model)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()

                    }
                });
            this.requisionlst.splice(index, 1);
        }
    }
    btncancle() {
        this.modalRef.hide();
    }
    btnsave($event) {
        for (let i = 0; i < this.requisitionlst.length; i++) {
            if (this.requisitionlst[i].issuedquantity == null && this.requisitionlst[i].issuedquantity == 0) {
                this.notifyService.showRroor("Please Enter Issued Quantity", " Oops");
                return;
            }
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.locationlst = this.requisitionlst
        this.transactionService.saverequisiotionIssueNote(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.modalRef.hide();
                        this.ngOnInit();
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    onChangeOfqantity(index) {
        for (let i = 0; i < this.requisitionlst.length; i++) {
            this.requisitionlst[i].issuedquantity <= this.requisitionlst[i].quantity;

            if (this.requisitionlst[i].issuedquantity > this.requisitionlst[i].balquantity) {
                this.requisitionlst[i].issuedquantity = null;
                this.notifyService.showRroor("Quantity Is not Greater than Balance Quantity !", " Oops");
                return;
            }
            if (this.requisitionlst[i].issuedquantity > this.requisitionlst[i].stockforsalefloc) {
                this.notifyService.showRroor("Quantity Is not Greater than Available Stock !", " Oops");
                this.requisitionlst[i].issuedquantity = 0;
                return;
            }
            this.tolocstock = 0;
            this.fromlocstock = 0;
            for (let i = 0; i < this.requisitionlst.length; i++) {
                this.requisitionlst[i].fromlocstock = Number(this.requisitionlst[i].stockforsalefloc) - Number(this.requisitionlst[i].issuedquantity);
                // this.model.fromlocstock = this.fromlocstock

                for (let i = 0; i < this.requisitionlst.length; i++) {
                    this.requisitionlst[i].tolocstock = Number(this.requisitionlst[i].stockforsale) + Number(this.requisitionlst[i].issuedquantity);
                    // this.model.tolocstock = this.tolocstock
                }
            }
        }
    }
    btndeleteissue(id, index) {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.requisitionlst.splice(index, 1);

            this.model.id = id
            this.transactionService.deleterequisition(this.model)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
    }
}