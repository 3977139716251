import { User } from 'src/app/_models';
import { MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { SearchUserModel, SearchUModel } from './SearchUserModel';
import { isTaggedTemplateExpression } from 'typescript';
@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'SearchUser.html'
})
export class SearchUser implements OnInit {
    str: string;
    itemCount = 0;
    sum = 0;
    pagename: string;
    user = new User();
    posts = ' '.repeat(100).split('').map((s, i) => i + 1);
    model: any = {};
    p: any;
    public i;
    reslst: SearchUModel[] = [];
    reslst1: SearchUModel[] = [];
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Name" },
        { id: 3, "name": "Designation" },

    ];

    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, ) {
        this.pagename = this.router.url
    }

    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        localStorage.setItem("pagename", this.pagename);
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.user.pageno = 0;
        this.user.pageSize = 20;
        this.getAllUsers();
    }

    getAllUsers() {

        this.Masterservice.getAllUsers(this.model)
            .subscribe(value => {
                this.reslst = [];
                this.reslst1 = [];
                value.forEach((item) => this.reslst1.push({ "id": item.id, "name": item.name, "designationName": item.designationName, "activeInactive": item.activeInactive, "txtserch": item.txtserch, "txtselect": item.txtselect, "deptName": item.deptName, "branchname": item.branchname, srno: null }));
                this.reslst = this.reslst1;
            })
    }

    onScroll() {
        this.sum = this.sum + 1;
        this.user.pageno = this.sum + 1;
        this.user.pageSize = 5;
        this.getAllUsers();
    }

    btnnew() {
        this.router.navigate(['/DefineUser']);
    }

    btnedit(id) {
        this.router.navigate(['/DefineUser', id]);
    }

    btnAddGeography(id, name) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "name": name
            }
        };
        this.router.navigate(['/ResourceRights'], navigationExtras);
    }

    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.Masterservice.deleteUser(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }
    }

    onSelectuser(value) {
        this.model.txtselect = value;
    }


    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllUsers()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.reslst1 = [];
                this.reslst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.reslst1.push({ "id": item.id, "name": item.name, "designationName": item.designationName, "activeInactive": item.activeInactive, "txtserch": item.txtserch, "txtselect": item.txtselect, "deptName": item.deptName, "branchname": item.branchname, srno: null });
                    }
                });
                this.reslst = this.reslst1;
            }
        }
        else if (this.model.txtselect == "Designation") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.reslst1 = [];
                this.reslst.forEach((item) => {
                    if ((item.designationName.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.reslst1.push({ "id": item.id, "name": item.name, "designationName": item.designationName, "activeInactive": item.activeInactive, "txtserch": item.txtserch, "txtselect": item.txtselect, "deptName": item.deptName, "branchname": item.branchname, srno: null });
                    }
                });
                this.reslst = this.reslst1;
            }
        }
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
}