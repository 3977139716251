export class VisitDetailSmryModel {
    frmdate: Date;
    todate: Date;
    companyname: string;
    companyaddress: string;
    customertypeID: number;
    customertypename: String;
    industrytypeID: number;
    industrytypename: String;
    visitintlst: number[];
    accid: number;
    authName:string;
    duedate: Date;
    description: string;
    followupdate: Date;
    activity: string;
    serialNo: number;
   taskName: string;
   customername:string;
   itemname:string;
}