import { Template } from "@angular/compiler/src/render3/r3_ast";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AreaModel1 } from "src/app/Transactions/Teams/TeamModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { User } from "src/app/_models/user";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel";
import { OrdInHandReportModel } from "../Order In Hand Report/OrdInHandReportModel";
import { OpportunityReportModel } from "./OpportunityReportModel";

@Component({
    selector: 'app-login',
    templateUrl: 'OpportunityReport.html'
})
export class OpportunityReport implements OnInit {
    [x: string]: any;
    modalRef: any;
    constructor( private reportServices: ReportServices,private router: Router) {}

    model = new OpportunityReportModel();
    omodel = new OrdInHandReportModel();
    dmodel = new DCRreportModel();
    opplst: OpportunityReportModel[] = [];
    user = new User();
    regionlst: DropDownModel[] = [];
    arealst: AreaModel1[] = [];
    resourcelst: DropDownModel[] = [];
    teamlst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    citylst: DropDownModel[] = [];
    companyname: string;
    companyaddress: String;
    cityName: string;
    regionName: string;
    areaName: string;
    branchName: string;
    teamName: string;
    resourceName: string;
    statusflag: string;
    timing = new Date();
    username: string;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Open" },
        { id: 3, "name": "Close" },
        { id: 4, "name": "Accepted" },
        { id: 5, "name": "Rejected" },
        { id: 6, "name": "Abandoned" },

    ];
    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.user.userid = Number(localStorage.getItem("userid"));
        this.areaName = localStorage.getItem('areaName');
        this.regionName = localStorage.getItem('regionName');
        this.branchName = localStorage.getItem('branchName');
        var getObj = JSON.parse(localStorage.getItem('testObject'));
        this.model = getObj;
        this.btnshow();
        
        // this.model.frmdate = new Date()
        // this.model.todate = new Date()
        // this.getRegions();
    }
    // selectFromDate(From) {
    //     if (From > new Date()) {
    //         this.notifyService.showRroor("Future date cannot be selected", "oops");
    //         this.model.frmdate = new Date()
    //         return;
    //     }
    // }
    // selectToDate(date) {
    //     if (date < this.model.frmdate) {
    //         this.notifyService.showRroor("To Date should be grether than From Date", "oops");
    //         this.model.todate = new Date()
    //         return;
    //     }
    //     if (date > new Date()) {
    //         this.notifyService.showRroor("Future date cannot be selected", "oops");
    //         this.model.todate = new Date()
    //         return;
    //     }
    // }
    // getRegions() {
    //     this.regionlst = []
    //     this.Masterservice.getAllRegions(this.user)
    //         .subscribe(value => {
    //             this.regionlst = value
    //         });
    // }
    // onSelectRegions(val) {
    //     this.arealst = [];
    //     if (val == 0) {
    //         this.notifyService.showRroor("Select Region!!", "Oops!");
    //         return;
    //     }
    //     if (val == "All") {
    //         this.user.message = val;

    //         if (this.user.message == val) {
    //             this.model.name = this.user.message;
    //             this.regionName = this.user.message;
    //         }

    //     } else {
    //         this.model.regionid = val
    //         for (let i = 0; i < this.regionlst.length; i++) {
    //             if (this.regionlst[i].id == val) {
    //                 this.regionName = this.regionlst[i].name
    //             }
    //         }
    //         this.user.trnid = val;
    //     }

    //     this.Masterservice.getAreaByRegion(this.user)
    //         .subscribe(value => {
    //             this.arealst = value
    //         });
    // }
    // onSelectArea(val) {
    //     this.dmodel.areaintlst = [];
    //     if (val == "All") {
    //         this.user.message = val;

    //         if (this.user.message == val) {
    //             this.areaName = this.user.message
    //         }

    //         for (let i = 0; i < this.arealst.length; i++) {
    //             this.dmodel.areaintlst.push(this.arealst[i].areaid)
    //         }
    //     } else {
    //         this.dmodel.areaid = val;
    //         for (let i = 0; i < this.arealst.length; i++) {
    //             if (this.arealst[i].areaid == val) {
    //                 this.areaName = this.arealst[i].areaname
    //             }
    //         }
    //         this.dmodel.areaintlst.push(this.dmodel.areaid)
    //     }
    //     // this.branchlst = [];
    //     // this.reportServices.getBranchByarea(this.dmodel)
    //     //     .subscribe(value => {
    //     //         this.branchlst = value
    //     //     });
    //     this.citylst = [];
    //     this.reportServices.getCityByarea(this.dmodel)
    //         .subscribe(value => {
    //             this.citylst = value
    //         });
    // }
    // onSelectCity(val) {
    //     this.omodel.cityintlst = [];
    //     if (val == "All") {
    //         this.user.message = val;

    //         if (this.user.message == val) {
    //             this.cityName = this.user.message
    //         }
    //         for (let i = 0; i < this.citylst.length; i++) {
    //             this.omodel.cityintlst.push(this.citylst[i].id)
    //         }
    //     } else {
    //         this.omodel.cityid = val;
    //         for (let i = 0; i < this.citylst.length; i++) {
    //             if (this.citylst[i].id == val) {
    //                 this.cityName = this.citylst[i].name
    //             }
    //         }
    //         this.omodel.cityintlst.push(this.omodel.cityid)
    //     }
    //     this.branchlst = [];
    //     this.reportServices.getBranchBycity(this.omodel)
    //         .subscribe(value => {
    //             this.branchlst = value
    //         });

    // }
    // onSelectBranches(val) {
    //     this.dmodel.branchintlst = []
    //     if (val == "All") {
    //         this.user.message = val;

    //         if (this.user.message == val) {
    //             this.branchName = this.user.message
    //         }

    //         for (let i = 0; i < this.branchlst.length; i++) {
    //             this.dmodel.branchintlst.push(this.branchlst[i].id)
    //         }
    //     } else {
    //         this.dmodel.branchid = val
    //         for (let i = 0; i < this.branchlst.length; i++) {
    //             if (this.branchlst[i].id == val) {
    //                 this.branchName = this.branchlst[i].name
    //             }
    //         }
    //         this.dmodel.branchintlst.push(this.dmodel.branchid)
    //     }
    //     // this.reportServices.getResourceByBranch(this.dmodel)
    //     //     .subscribe(value => {
    //     //      this.resourcelst = value
    //     //     });
    //     this.dmodel.teamtype = "sales";
    //     this.dmodel.userid = this.user.userid
    //     this.reportServices.getTeamByBranch(this.dmodel)
    //         .subscribe(value => {
    //             this.teamlst = value
    //         });
    // }

    // onSelectTeam(val) {
    //     this.model.teamintlst = []
    //     if (val == "All") {
    //         this.user.message = val;

    //         if (this.user.message == val) {
    //             this.teamName = this.user.message
    //         }

    //         for (let i = 0; i < this.teamlst.length; i++) {
    //             this.model.teamintlst.push(this.teamlst[i].id)
    //         }
    //     }
    //     else {
    //         this.model.teamid = val;
    //         for (let i = 0; i < this.teamlst.length; i++) {
    //             if (this.teamlst[i].id == val) {
    //                 this.teamName = this.teamlst[i].name
    //             }
    //         }
    //         this.model.teamintlst.push(this.model.teamid)
    //     }
    //     this.model.userid = this.user.userid
    //     this.reportServices.getResourceByTeam(this.model)
    //         .subscribe(value => {
    //             this.resourcelst = value
    //         });
    // }

    // onSelectResource(val) {
    //     this.model.resourceintlst = []
    //     if (val == "All") {
    //         this.user.message = val;

    //         if (this.user.message == val) {
    //             this.resourceName = this.user.message
    //         }

    //         for (let i = 0; i < this.resourcelst.length; i++) {
    //             this.model.resourceintlst.push(this.resourcelst[i].id)
    //         }
    //     } else {
    //         this.model.resourceID = val;
    //         this.model.resourceintlst.push(this.model.resourceID)
    //     }

    // }
    // onSelectStatus(value) {
    //     this.model.status = value;
    // }
    btnshow() {

        if (this.model.frmdate == null) {
         this.notifyService.showRroor(" Please select From Date !!", "Oops!");
         this.model.frmdate = new Date()
         this.model.frmdate.setMonth(this.model.frmdate.getMonth()-1)
         this.model.todate = new Date()
         return;
        }
         if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
             return;
        }

        this.reportServices.showOpportunityReport(this.model)
            .subscribe(
                data => {
                    this.opplst = data;
                    for (let i = 0; i < this.opplst.length; i++) {
                        this.companyname = this.opplst[0].companyname;
                        this.companyaddress = this.opplst[0].companyaddress;

                        if (this.opplst[i].status == 'Y') {
                            this.opplst[i].status = "Open"

                        } else if (this.opplst[i].status == 'N') {
                            this.opplst[i].status = "Close"
                        }
                        else if (this.opplst[i].status == 'W') {
                            this.opplst[i].status = "Accepted"
                        }
                        else if (this.opplst[i].status == 'L') {
                            this.opplst[i].status = "Rejected"
                        }
                        else if (this.opplst[i].status == 'A') {
                            this.opplst[i].status = "Abandoned"
                        }
                       if(this.opplst[i].currentStage=='OPPTY')
                        {
                            this.opplst[i].currentStage= "visit & Quataion Stage"
                        }
                        else if(this.opplst[i].currentStage=='LEAD')
                        {
                           this.opplst[i].currentStage="Assiged"
                        }
                        // else if(this.opplst[i].currentStage=='Order')
                        // {
                        //    this.opplst[i].currentStage=""
                        // }
                    }

                   
                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        // WindowObject.close();
    }
    btnback() {
        this.router.navigate(["/OpportunityReport"]);
    }
    
}