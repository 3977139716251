<div *ngIf="isMobile == true">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol">
        <h1 class="header center">Quotation</h1>
        <div class="container ">
            <div class="row innerCnt">
                <div class="col s12 m4 srchBy">

                    <label class="w-35 font-weight-bold">Customer Name :</label>
                    <label class="widthR-60">{{model.accname}}</label>

                    <label class="w-35 font-weight-bold">Address :</label>
                    <label class="widthR-60">{{model.address}}</label>

                    <label class="w-35 font-weight-bold">Enquiry No :</label>
                    <label class="widthR-60">{{model.enqId}}</label>

                    <label class="w-35 font-weight-bold" *ngIf="action!='New'">Quotation No :</label>
                    <label class="widthR-60" *ngIf="action!='New'">{{model.quoCode}}</label>

                    <label class="w-35 font-weight-bold" *ngIf="action!='New'"> Date</label>
                    <label class="widthR-60" *ngIf="action!='New'">{{model.quoDate | date:"dd-MM-yyyy"}}</label>

                    <hr>

                    <label class="w-35 font-weight-bold" *ngIf="action!='Approve'">Contact</label>
                    <label class="input-field widthR-60" *ngIf="action!='Approve'">
                        <div class="input-field">
                            <select (change)="onSelectContact($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Contacts">
                                <option selected value="0">Select Contact</option>
                                <option *ngFor="let lst of contactlst" [value]="lst.contactpersonid"
                                    [selected]="model.contactperson==lst.contactname">
                                    {{lst.contactname}}</option>
                            </select>
                        </div>
                    </label>

                    <label class="w-35 font-weight-bold">Payment</label>
                    <label class="input-field widthR-60">
                        <select (change)="onSelectPayment($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Payment Terms" [disabled]="action=='View'||action=='Decision'">
                            <option selected value="0">Select One</option>
                            <option *ngFor="let lst of paymenttermslst" [value]="lst.name"
                                [selected]="lst.name==model.paymentType">
                                {{lst.name}}</option>
                        </select>
                    </label>

                    <!-- <label class="w-35 font-weight-bold" *ngIf="model.orderaccp=='Company'">Credit Days</label>
                    <label class="input-field widthR-60" *ngIf="model.orderaccp=='Company'">
                        <input [(ngModel)]="model.creditdays" #creditdays="ngModel" type="number" class=""
                            id="creditdays" data-toggle="tooltip" data-placement="top" min="0" title="Credit Days"
                            [disabled]="action=='View'||action=='Decision'">
                    </label> -->

                    <div class="printBtnRow center-align m-btm" *ngIf="model.orderaccp=='Company'">
                        <button type="button" class="btn btn-blue waves-effect waves-light m-btm modal-trigger"
                            href="#qutHistory" *ngIf="quohistorylst.length>0">Quotation History</button>
                        <button type="button" class="btn btn-blue waves-effect waves-light modal-trigger"
                            href="#versionHistory" (click)="btnVersHist(VersionHistory)"
                            *ngIf="quoVersionHistory.length>0">Version History</button>

                        <!-- Quotation History Modal Structure -->
                        <!-- <div id="qutHistory" class="modal" style="display: none;">
                            <div class="modal-content">
                                <h4 class="orgTxt center-align">Quotation History</h4>
                                <div class="row">
                                    <div class="tabInnerBox m-btm" *ngFor="let lst of quohistorylst">
                                        <div class="introTab">
                                            <label class="w-35 font-weight-bold">Quotation Code:</label>
                                            <label class="widthR-60 valignTxt">{{lst.quotationno}}</label>
    
                                            <label class="w-35 font-weight-bold">Created By</label>
                                            <label class="widthR-60 valignTxt">{{lst.createdBy}}</label>
    
                                            <label class="w-35 font-weight-bold">Created Date</label>
                                            <label class="widthR-60 valignTxt">{{lst.createdDate | date:"MMM d, y, h:mm:ss
                                                a"}}</label>
    
                                            <label class="w-35 font-weight-bold">Customer Name</label>
                                            <label class="widthR-60 valignTxt">{{lst.customerName}}</label>
    
                                            <label class="w-35 font-weight-bold">Customer Address</label>
                                            <label class="widthR-60 valignTxt">{{lst.customerAddress}}</label>
    
                                            <label class="w-35 font-weight-bold">Enquiry Name</label>
                                            <label class="widthR-60 valignTxt">{{lst.enquiryName}}</label>
    
                                            <label class="w-35 font-weight-bold">Remark</label>
                                            <label class="widthR-60 valignTxt">{{lst.remarks}}</label>
                                        </div>
                                    </div>
                                    <div class="tabInnerBox m-btm" *ngFor="let pro of lst.historyproductlst">
                                        <div class="introTab">
                                            <label class="w-35 font-weight-bold blueTxt">Product Name</label>
                                            <label class="widthR-60 ">{{pro.name}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">List Price</label>
                                            <label class="widthR-60 ">{{pro.listprice | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Quantity</label>
                                            <label class="widthR-60 ">{{pro.quantity | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Discount</label>
                                            <label class="widthR-60 ">{{pro.discount | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Offered Price</label>
                                            <label class="widthR-60 ">{{pro.offeredPrice | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Total Amount</label>
                                            <label class="widthR-60 ">{{pro.totalproamount | number:'1.2-2'}}</label>
                                        </div>
                                    </div>
    
                                    <div class="tabInnerBox grayBg m-btm" *ngFor="let terms of lst.historyTermslst">
                                        <div class="col12">
                                            <label class="w-50 font-weight-bold">Heading</label>
                                            <label class="w-50">&nbsp;{{terms.heading}}</label>
                                            <label class="w-50 font-weight-bold">Description</label>
                                            <label class="w-50">&nbsp;{{terms.descrption}}</label>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="modal-footer">
                                    <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                                        style="margin-left: 5px;">Cancel</a>
    
                                </div>
                            </div>
                        </div> -->

                        <!--Version History Modal Structure -->

                        <!-- <div id="versionHistory" class="modal">
                            <div class="modal-content">
                                <h4 class="orgTxt center-align">Quotation Version History</h4>
                                <div class="row">
                                    <span class="printIcon right-align"><i class="fa fa-print"></i></span>
                                    <div class="tabInnerBox m-btm" *ngFor="let lst of quoVersionHistory">
                                        <div class="introTab">
                                            <label class="w-35 font-weight-bold">Quotation Code:</label>
                                            <label class="widthR-60 valignTxt">{{lst.quotationno}}</label>
    
                                            <label class="w-35 font-weight-bold">Created By</label>
                                            <label class="widthR-60 valignTxt">{{lst.createdBy}}</label>
    
                                            <label class="w-35 font-weight-bold">Created Date</label>
                                            <label class="widthR-60 valignTxt">{{lst.createdDate | date:"MMM d, y, h:mm:ss
                                                a"}}</label>
    
                                            <label class="w-35 font-weight-bold">Customer Name</label>
                                            <label class="widthR-60 valignTxt">{{lst.customerName}}</label>
    
                                            <label class="w-35 font-weight-bold">Customer Address</label>
                                            <label class="widthR-60 valignTxt">{{lst.customerAddress}}</label>
    
                                            <label class="w-35 font-weight-bold">Enquiry Name</label>
                                            <label class="widthR-60 valignTxt">{{lst.enquiryName}}</label>
    
                                            <label class="w-35 font-weight-bold">Remark</label>
                                            <label class="widthR-60 valignTxt">{{lst.remarks}}</label>
    
                                        </div>
                                    </div>
    
                                    <div class="tabInnerBox m-btm" *ngFor="let pro of lst.historyproductlst">
                                        <div class="introTab">
                                            <label class="w-35 font-weight-bold blueTxt">Product Name</label>
                                            <label class="widthR-60 ">{{pro.name}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">List Price</label>
                                            <label class="widthR-60 ">{{pro.listprice | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Quantity</label>
                                            <label class="widthR-60 ">{{pro.quantity | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Discount</label>
                                            <label class="widthR-60 ">{{pro.discount | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Offered Price</label>
                                            <label class="widthR-60 ">{{pro.offeredPrice | number:'1.2-2'}}</label>
    
                                            <label class="w-35  font-weight-bold blueTxt">Total Amount</label>
                                            <label class="widthR-60 ">{{pro.totalproamount | number:'1.2-2'}}</label>
    
                                        </div>
                                    </div>
    
                                    <div class="tabInnerBox grayBg m-btm" *ngFor="let terms of lst.historyTermslst">
                                        <div class="col12">
                                            <label class="w-50 font-weight-bold">Heading</label>
                                            <label class="w-50">&nbsp;{{terms.heading}}</label>
    
                                            <label class="w-50 font-weight-bold">Description</label>
                                            <label class="w-50">&nbsp;{{terms.descrption}}</label>
                                        </div>
                                    </div>
                                </div>
    
                                <hr>
                                <div class="modal-footer">
                                    <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                                        style="margin-left: 5px;">Cancel</a>
                                    <a href="#!" class="btn btn-blue waves-effect waves-green btn-flat">Print</a>
                                </div>
                            </div>
                        </div> -->

                    </div>

                    <h3 class="picRupee" *ngIf="action=='Decision'">Decision</h3>
                    <div class="tabInnerBox m-btm" *ngIf="action=='Decision'">
                        <div class="col12">
                            <div class="intro-text">
                                <!-- <form action="#"> -->
                                <!-- <lable class="w-50">
                                    <input name="group1" type="checkbox" [checked]="model.chkwon"
                                        (click)="onchkwon($event)" />
                                    <span>Won</span>
                                </lable>
                                <p class="w-50">
                                    <input name="group1" type="checkbox" [checked]="model.chklost"
                                        (click)="onchklost($event)" />
                                    <span>Lost</span>
                                </p>
                                <p class="w-50">
                                    <input name="group1" type="checkbox" [checked]="model.chkaband"
                                        (click)="onchkaband($event)" />
                                    <span>Abandoned</span>
                                </p>
                                <p class="w-50">
                                    &nbsp;
                                </p> -->
                                <div class="input-field">
                                    <select (change)="onSelectdecision($event.target.value)" data-toggle="tooltip"
                                        data-placement="top" title="">
                                        <option selected>Select One</option>
                                        <option *ngFor="let lst of decisionlst" [value]="lst.name"
                                            [selected]="lst.name==model.decision">
                                            {{lst.name}}</option>
                                    </select>
                                </div>

                                <!-- </form> -->
                            </div>

                            <label class="w-100">
                                <input type="text" placeholder="Decision Date" [(ngModel)]="model.decisionDate"
                                    id="decisionDate" class="form-control" bsDatepicker data-toggle="tooltip"
                                    data-placement="top" title="Decision Date"
                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true}">
                            </label>

                            <label class="w-100 font-weight-bold blueTxt" *ngIf="model.decision!='won'">lost/abandoned
                                Reason</label>
                            <label class="w-100 pl-2 m-0" *ngIf="model.decision!='won'">
                                <div class="input-field">
                                    <select (change)="onSelectLossReason($event.target.value)" data-toggle="tooltip"
                                        data-placement="top" title="Loss/Abandoned Reason">
                                        <option selected>Select One</option>
                                        <option *ngFor="let lst of reasonslst" [value]="lst.id"
                                            [selected]="lst.id==model.lossreason">
                                            {{lst.name}}</option>
                                    </select>
                                </div>
                            </label>

                        </div>
                    </div>

                    <div class="tabInnerBox m-btm">
                        <div class="col12">
                            <label class="font-weight-bold"
                                *ngIf="action=='Approve'&&model.orderaccp=='Company'">Quotation
                                Owner : </label>
                            <label class="widthR-60"
                                *ngIf="action=='Approve'&&model.orderaccp=='Company'">{{model.ownernameofOppor}}</label>

                            <label class="w-100 dateBox" *ngIf="action=='Decision'||action=='Approve'">
                                <textarea [(ngModel)]="model.remarks" #remarks="ngModel" class="materialize-textarea"
                                    type="text" id="remarks" data-toggle="tooltip" data-placement="top" title="Remarks"
                                    style="margin-bottom: 0px;"></textarea>
                            </label>
                            <label class="font-weight-bold" *ngIf="action=='Decision'||action=='Approve'">Previous
                                Remark :
                            </label>
                            <label class="widthR-60"
                                *ngIf="action=='Decision'||action=='Approve'">{{model.previousremarks}}</label>
                        </div>
                    </div>

                    <label class="w-35 font-weight-bold" *ngIf="action!='View'&&action!='Decision'">Product
                        Category</label>
                    <label class="input-field widthR-60" *ngIf="action!='View'&&action!='Decision'">

                        <select (change)="onSelectProCat($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Product Category">
                            <option selected>Select One</option>
                            <option *ngFor="let lst of procatlst" [value]="lst.pcid" [selected]="lst.pcid==procatid">
                                {{lst.pcname}}</option>
                        </select>

                    </label>

                    <label class="w-35 font-weight-bold" *ngIf="action!='View'&&action!='Decision'">Products</label>
                    <label class="input-field widthR-60" *ngIf="action!='View'&&action!='Decision'">

                        <select (change)="onSelectProdCt($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Products">
                            <option selected>Select One</option>
                            <option *ngFor="let lst of productlst" [value]="lst.pid" [selected]="lst.pid==proid">
                                {{lst.name}}</option>
                        </select>

                    </label>

                    <label class="w-100 currencyCntr"
                        *ngIf="action!='View' && action!='Decision' && currcymodel.id!=null">Currency :
                        {{currcymodel.name}}</label>

                    <hr>

                    <div class="s12" style="margin-bottom: 55px;" *ngIf="action!='View'&&action!='Decision'">
                        <button type="button" (click)="btnAddProduct()"
                            class="btn btn-blue waves-effect waves-light right">Add</button>
                    </div>

                    <!-- <h3 class="picRupee">8 Ft Pull it ( 4 Pipes )-S <span class="rSpan">Rupees</span></h3> -->
                    <!-- <div class="tabInnerBox grayBg" *ngFor="let lst of productEnquirylst ; let i=index">
                        <h3 class="picRupee">{{lst.name}}<span class="rSpan"></span></h3>
                        <h3 class="picRupee" *ngFor="let curlst of lst.currencylst" [value]="curlst.id" [disabled]="i!=0"
                            [disabled]="action=='View'||action=='Decision'"><span class="rSpan">{{curlst.name}}</span></h3>
                        <label class="picRupee">
                            <span *ngIf="action=='edit'||action=='New'"><img src="../../../assets/images/delete.png"
                                    width="22px" (click)="deleteProduct(lst.id, lst.pid)" /></span>
                        </label>
    
                        <div class="introTab">
                            <div class="col12">
                                <label class="w-35 font-weight-bold blueTxt">Quantity</label>
                                <label class="w-50 pl-2">
                                    <input [(ngModel)]="lst.quantity" #quantity="ngModel" type="number" class=""
                                        id="quantity" data-toggle="tooltip" oninput="validity.valid||(value='');"
                                        style="text-align: end" data-placement="top" title="Quantity" min="1"
                                        (ngModelChange)="onChangeOfPrice(lst.id, lst.discount)"
                                        [disabled]="action=='View'||action=='Decision'">
                                </label>
    
                                <label class="w-35 font-weight-bold blueTxt">UOM</label>
                                <label class="w-50 pl-2">{{lst.uom}}</label>
    
                                <label class="w-35 font-weight-bold blueTxt">List Price </label>
                                <label class="w-50 pl-2">{{lst.listprice | number:'1.2-2'}}</label>
    
                                <label class="w-35 font-weight-bold blueTxt">Discount Offered</label>
                                <label class="w-50 pl-2" *ngIf="model.chkschemeflag =='N'">
                                    <input [(ngModel)]="lst.discount" #discount="ngModel" type="number"
                                        style="text-align: end;width: 75%" class="" id="discount" data-toggle="tooltip"
                                        data-placement="top" title="Discount"
                                        (ngModelChange)="onChangeOfPrice(lst.id, lst.discount)" min="0"
                                        oninput="validity.valid||(value='');"
                                        [disabled]="action=='View'||action=='Decision'">
                                </label>
                                <label class="w-50 pl-2" *ngIf="model.chkschemeflag =='Y'">{{lst.discount |
                                    number:'1.2-2'}}</label>
    
                                <label class="w-35 font-weight-bold blueTxt" *ngIf="model.gstseperate==true">Tax
                                    Amount</label>
                                <label class="w-50 pl-2" *ngIf="model.gstseperate==true">{{lst.taxamount |
                                    number:'1.2-2'}}</label>
    
                                <label class="w-35 font-weight-bold blueTxt">Total Amount</label>
                                <label class="w-50 pl-2">{{lst.totalproamount | number:'1.2-2'}}</label>
                            </div>
                        </div>
    
                    </div> -->

                    <div class="tabInnerBox grayBg editMony" *ngFor="let lst of productEnquirylst ; let i=index">
                        <h3 class="picRupee widthR-100" style="margin-top: 0px;margin-bottom: 0px;">{{lst.name}}<span
                                class="rSpan"></span></h3>
                        <!-- <h3 class="picRupee w-35" *ngFor="let curlst of lst.currencylst" [value]="curlst.id" [disabled]="i!=0" [disabled]="action=='View'||action=='Decision'">
                            <span class="rSpan">{{curlst.name}}</span>
                        </h3> -->
                        <label class="picRupee">
                            <span *ngIf="action=='edit'||action=='New'"><img src="../../../assets/images/delete.png"
                                    width="22px" (click)="deleteProduct(lst.id, lst.pid)" /></span>
                        </label>

                        <div class="introTab">
                            <div class="col12">
                                <label class="w-35 font-weight-bold blueTxt">Quantity</label>
                                <label class="w-35 pl-2">
                                    <input [(ngModel)]="lst.quantity" #quantity="ngModel" type="number" class=""
                                        id="quantity" data-toggle="tooltip" oninput="validity.valid||(value='');"
                                        style="text-align: center" data-placement="top" title="Quantity" min="1"
                                        (ngModelChange)="onChangeOfPrice(lst.id, lst.discount)"
                                        [disabled]="action=='View'||action=='Decision'">
                                </label>

                                <!-- <label class="w-35 font-weight-bold blueTxt">UOM</label>
                                <label class="w-50 pl-2">{{lst.uom}}</label>

                                <label class="w-35 font-weight-bold blueTxt">List Price </label>
                                <label class="w-50 pl-2">{{lst.listprice | number:'1.2-2'}}</label> -->

                                <!-- <label class="w-35 font-weight-bold blueTxt">Discount Offered</label> -->
                                <!-- <label class="w-50 pl-2" *ngIf="model.chkschemeflag =='N'">
                                    <input [(ngModel)]="lst.discount" #discount="ngModel" type="number"
                                        style="text-align: end;width: 75%" class="" id="discount" data-toggle="tooltip"
                                        data-placement="top" title="Discount"
                                        (ngModelChange)="onChangeOfPrice(lst.id, lst.discount)" min="0"
                                        oninput="validity.valid||(value='');"
                                        [disabled]="action=='View'||action=='Decision'">
                                </label> -->
                                <!-- <label class="w-50 pl-2" *ngIf="model.chkschemeflag =='Y'">{{lst.discount |
                                    number:'1.2-2'}}</label> -->

                                <!-- <label class="w-35 font-weight-bold blueTxt" *ngIf="model.gstseperate==true">Tax
                                    Amount</label>
                                <label class="w-50 pl-2" *ngIf="model.gstseperate==true">{{lst.taxamount |
                                    number:'1.2-2'}}</label> -->

                                <!-- <label class="w-35 font-weight-bold blueTxt">Total Amount</label>
                                <label class="w-50 pl-2">{{lst.totalproamount | number:'1.2-2'}}</label> -->
                            </div>
                        </div>

                    </div>

                    <label class="w-35 dateBox" *ngIf="model.chkschemeflag =='Y'">
                        <input [(ngModel)]="model.schmemCode" #schmemCode="ngModel" type="text" id="schmemCode"
                            data-toggle="tooltip" style="text-align: end" data-placement="top"
                            [disabled]="action=='View'||action=='Decision' || codehide == true" title="Scheme Code"
                            style="position: relative;" placeholder="scheme Code"
                            (ngModelChange)="selectSchemeCode(model.schmemCode)">

                    </label>
                    <label class="w-10" *ngIf="model.chkschemeflag =='Y'">
                        <span *ngIf="model.schmemCode!=null"><img src="../../../assets/images/cancel.png" width="40%"
                                (click)="removeScheme()" /></span>
                    </label>
                    <label class="w-35 dateBox"
                        *ngIf="schemeModel.discountType == 'Custom' || model.schemetype == 'Custom'">
                        <input [(ngModel)]="model.customnumber" #customnumber="ngModel" type="text" id="customnumber"
                            data-toggle="tooltip" style="text-align: end" data-placement="top" title="Custom Number"
                            style="position: relative;" placeholder="Custom Number">
                    </label>
                    <button *ngIf="model.chkschemeflag =='Y'" (click)="btnApplyScheme()" type="button"
                        [disabled]="model.schmemCode==null" class="btn grayButtonRes">Calculate</button>

                    <div class="w-100">
                        <label class="blueTxt font-weight-bold widthR-40 valignTxt">Amount In Words: </label>
                        <label class="width-55">&nbsp;<span>{{currcymodel.code}}
                                {{amountInWords}}</span>&nbsp;ONLY</label>

                        <label class="blueTxt font-weight-bold widthR-40">Total Quotation Amount: </label>
                        <label class="width-55 valignTxt">&nbsp;<span>{{totalamount}}</span></label>
                    </div>

                    <div *ngIf="action=='Decision'&&model.orderaccp=='EndCustomer' || action=='Approve'&&model.orderaccp=='EndCustomer'"
                        style="text-align: end;">
                        <button type="button" *ngIf="showOtp == false" (click)="showotp()" class="btn btn-blue">Show
                            OTP</button>&nbsp;
                        <button type="button" *ngIf="showOtp == true"
                            class="btn btn-blue"><b>{{model.Otpshow}}</b></button>&nbsp;
                    </div>
                    <div class="tabInnerBox m-btm"
                        *ngIf="model.decision=='won'&&model.orderaccp=='EndCustomer'&&model.otpverified==false">
                        <div class="col12">
                            <label class="w-100 font-weight-bold">Ask
                                to Customer for OTP And Enter Here to Approve Quotation
                            </label>

                            <label class="w-100">
                                <input type="text" id="otp" [(ngModel)]="model.otp" #otp="ngModel"
                                    (ngModelChange)="btnVerfiyOtp()" [max]="6" placeholder="OTP">
                            </label>
                        </div>
                    </div>

                    <div class="tabInnerBox m-btm">
                        <div class="col12" *ngIf="model.orderaccp=='EndCustomer' && model.otpverified==true">
                            <label class="w-100 font-weight-bold">Installation Instructions</label>
                            <label class="w-100">
                                <textarea [(ngModel)]="model.installInstru" #installInstru="ngModel"
                                    class="materialize-textarea" type="text" id="installInstru" data-toggle="tooltip"
                                    data-placement="top" title="Installation Instructions"></textarea>
                            </label>

                            <label class="w-100 font-weight-bold">
                                <!-- <input type="checkbox"><span>Timing</span> -->
                                <input type="checkbox" [(ngModel)]="model.chkscheduleInstal">&nbsp;&nbsp;Schedule Timing
                            </label>

                            <label class="w-50 font-weight-bold blueTxt">Installation Date</label>
                            <label class="w-50">
                                <input type="text" [(ngModel)]="model.installionDate" id="installionDate"
                                    class="form-control" bsDatepicker
                                    (ngModelChange)="selectInstallationDate(model.installionDate)" data-toggle="tooltip"
                                    data-placement="top" title="Installation Date"
                                    [disabled]="model.chkscheduleInstal==false"
                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true }">
                            </label>

                            <label class="w-50 font-weight-bold blueTxt">Time</label>
                            <label class="w-50">
                                <input type="text" [(ngModel)]="model.installionTime" data-toggle="tooltip"
                                    data-placement="top" title="Installation Date" id="installionTime"
                                    [disabled]="model.chkscheduleInstal==false">
                            </label>

                            <label class="w-100 font-weight-bold"
                                *ngIf="model.decision=='won'&&model.orderaccp=='EndCustomer'&&model.otpverified==false">Ask
                                to Customer for OTP And Enter Here to Approve Quotation</label>
                            <label class="w-100">
                                <input type="text" id="otp" [(ngModel)]="model.otp" #otp="ngModel"
                                    (ngModelChange)="btnVerfiyOtp()" [max]="6" placeholder="OTP">
                            </label>
                        </div>
                    </div>

                    <hr>

                    <div class="s12 right">
                        <!-- <button type="button" class="btn btn-blue waves-effect waves-light modal-trigger" href="#printQut"
                            (click)="btnprint(QuotationReport)" *ngIf="approvedQuo==true&&model.quotationid!=null">Print
                            Quotation</button>&nbsp; -->
                        <button type="button" onclick="manageViews(true)" id="close" class="btn grayButton"
                            (click)="btnback()">Cancel</button>&nbsp;
                        <button type="button" (click)="btnsaveQuotation()" *ngIf="action!='Approve'"
                            [disabled]="action=='View' || showBtn == true" class="btn btn-blue">Save</button>&nbsp;
                        <button (click)="btnApproveQuotation()" type="button" *ngIf="action=='Approve'"
                            class="btn btn-blue">Approve</button>


                        <!-- <div id="printQut" class="modal">
                            <div class="modal-content">
                                <h4 class="orgTxt center-align">Quotation</h4>
                                <h4 class="center-align">{{quoReport.companyname}}</h4>
                                <h4 class="center-align">{{quoReport.companyaddress}}</h4>
                                <div class="row">
                                    <div class="tabInnerBox m-btm">
                                        <div class="introTab">
                                            <label class="w-35 font-weight-bold">Quotation No:</label>
                                            <label class="widthR-60">{{quoReport.quotationCode}}</label>
                                            <label class="w-35 font-weight-bold">Quotation Date:</label>
                                            <label class="widthR-60 valignTxt">{{quoReport.quoDate |
                                                date:"dd-MM-yyyy"}}</label>
    
                                            <label class="w-35 font-weight-bold">Contact Person :</label>
                                            <label class="widthR-60 valignTxt">{{quoReport.contactPerson}}</label>
                                            <label class="w-35 font-weight-bold">Payment terms:</label>
                                            <label class="widthR-60 valignTxt">{{quoReport.paymentTerm}}</label>
                                        </div>
                                    </div>
                                    <div class="tabInnerBox m-btm" *ngFor="let lst of qutReportlst">
                                        <div class="introTab">
                                            <label class="w-35 font-weight-bold blueTxt">Sr No</label>
                                            <label class="widthR-60 ">{{lst.srno}}</label>
                                            <label class="w-35  font-weight-bold blueTxt">Description of Goods</label>
                                            <label class="widthR-60 ">{{lst.name}}</label>
                                            <label class="w-35  font-weight-bold blueTxt">HSN</label>
                                            <label class="widthR-60 ">{{lst.hsn}}</label>
                                            <label class="w-35  font-weight-bold blueTxt">Quantity</label>
                                            <label class="widthR-60 ">{{lst.quantity | number:'1.2-2'}}</label>
                                            <label class="w-35  font-weight-bold blueTxt">Rate</label>
                                            <label class="widthR-60 ">{{lst.amount | number:'1.2-2'}}</label>
                                            <label class="w-35  font-weight-bold blueTxt">UMO</label>
                                            <label class="widthR-60 ">{{lst.uom}}</label>
                                            <label class="w-35  font-weight-bold blueTxt">Total Amount</label>
                                            <label class="widthR-60 ">{{lst.totalproamount | number:'1.2-2'}}</label>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <span class="font-weight-bold center-align">TOTAL AMOUNT:
                                            <span>{{quoReport.totalinwords |
                                                uppercase}}&nbsp;ONLY</span></span>
                                        <label class="font-weight-bold">Terms And Conditions</label>
                                    </div>
                                    <div class="tabInnerBox grayBg m-btm" *ngFor="let lst of reporttandclst">
                                        <div class="col12">
                                            <label class="w-50 font-weight-bold">Heading</label>
                                            <label class="w-50">&nbsp;{{lst.heading}}</label>
                                            <label class="w-50 font-weight-bold">Description</label>
                                            <label class="w-50">&nbsp;{{lst.descrption}}</label>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="modal-footer">
                                    <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                                        style="margin-left: 5px;">Cancel</a>
                                    <a href="#!" (click)="openPrint()"
                                        class="btn btn-blue waves-effect waves-green btn-flat">Print</a>
                                </div>
                            </div>
                        </div> -->

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isMobile == false">
    <app-header></app-header>
    <div class="container" style="width: 80%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Quotation</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="col l12 m12 s12">
                    <div class="row" style="margin-top: 2%">
                        <div class="col l5 m5 s5">
                            <div class="row">
                                <div class="col l12 m12 s12">
                                    <span style="font-size: 15px;font-weight: bold">Customer
                                        Name:&nbsp;<span>{{model.accname}}</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l12 m12 s12">
                                    <span
                                        style="font-size: 15px;font-weight: bold">Address:&nbsp;<span>{{model.address}}</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="col l7 m7 s7">
                            <div class="row">
                                <div class="col l12 m12 s12">
                                    <span style="font-size: 15px;font-weight: bold">Enquiry
                                        No:&nbsp;<span>{{model.enqId}}</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l8 m8 s8" *ngIf="action!='New'">
                                    <span style="font-size: 15px;font-weight: bold">Quotation
                                        No:&nbsp;<span>{{model.quoCode}}</span></span>
                                </div>
                                <div class="col l4 m4 s4" *ngIf="action!='New'">
                                    <span style="font-size: 15px;font-weight: bold">Date:&nbsp;<span>{{model.quoDate |
                                            date:"dd-MM-yyyy"}}</span></span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;" *ngIf="action!='Approve'">
                            <span>Contact</span>
                            <select (change)="onSelectContact($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Contacts">
                                <option selected value="0">Select Contact</option>
                                <option *ngFor="let lst of contactlst" [value]="lst.contactpersonid"
                                    [selected]="model.contactperson==lst.contactname">
                                    {{lst.contactname}}</option>
                            </select>
                        </div>
                        <div class="col l1 m1 s1"></div>
                        <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;">
                            <span>Payment</span>
                            <select (change)="onSelectPayment($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Payment Terms"
                                [disabled]="action=='View'||action=='Decision'">
                                <!--  -->
                                <option selected value="0">Select One</option>
                                <option *ngFor="let lst of paymenttermslst" [value]="lst.name"
                                    [selected]="lst.name==model.paymentType">
                                    {{lst.name}}</option>
                            </select>
                        </div>
                        <div class="col l1 m1 s1"></div>
                        <!-- <div class="input-field col l2 m2 s2" style="margin-top: 30px;margin-bottom: 0px;"
                            *ngIf="model.orderaccp=='Company'">
                            <input [(ngModel)]="model.creditdays" #creditdays="ngModel" type="number" class=""
                                id="creditdays" data-toggle="tooltip" data-placement="top" min="0" title="Credit Days"
                                [disabled]="action=='View'||action=='Decision'">
                            <label for="creditdays" class="active">Credit Days</label>
                        </div> -->
                    </div>

                    <div class="row" style="margin-top: 0px;margin-bottom: 9px;margin-top: 9px;"
                        *ngIf="model.orderaccp=='Company'">
                        <div class="col l6 m6 s6" *ngIf="model.dearflag==false">
                            <div class="col l8 m8 s8" *ngIf="this.changeflag == false" style="padding-right: 0px;">
                                <span style="font-size: 15px;"><b>Installation By: {{instaName}}</b></span>
                            </div>
                            <div class="input-field col l8 m8 s8" *ngIf="this.changeflag == true"
                                style="margin-bottom: 0px;margin-top: 0px;">
                                <select (change)="onSelectInstby($event.target.value)" style="margin-bottom: 0px;"
                                    data-toggle="tooltip" data-placement="top" title="Installation By">
                                    <option *ngFor="let lst of instNamelst" [value]="lst.id"
                                        [selected]="instaName==lst.name">
                                        {{lst.name}}</option>
                                </select>
                            </div>
                            <div class="col l2 m2 s2" style="padding-right: 0px;">
                                <button (click)="onchange()" *ngIf="this.changeflag == false" type="button"
                                    class="waves-effect waves-light btn"
                                    style="background-color: #a9a7a7;">Change</button>
                                <button (click)="onupdate()" *ngIf="this.changeflag == true" type="button"
                                    class="waves-effect waves-light btn">Update</button>
                            </div>
                        </div>
                        <div class="col l6 m6 s6" style="text-align: end;padding-right: 0px;">
                            <button (click)="btnQuotHist(QuotationHistory)" type="button"
                                class="waves-effect waves-light btn" *ngIf="quohistorylst.length>0">Quotation
                                History</button>&nbsp;&nbsp;&nbsp;

                            <button (click)="btnVersHist(VersionHistory)" type="button"
                                class="waves-effect waves-light btn" *ngIf="quoVersionHistory.length>0">Version
                                History</button>
                        </div>
                    </div>


                    <div class="row" *ngIf="action=='Decision'">
                        <div class="col l6 m6 s6">
                            <span style="font-size: 15px;font-weight: bold">Decision</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="action=='Decision'">
                        <div class="col l2 m2 s2" style="margin-top: 20px">
                            <label>
                                <input name="group1" type="radio" [checked]="model.chkwon" (click)="onchkwon($event)" />
                                <span style="font-size: 104%">Won</span>
                            </label>
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 20px">
                            <label>
                                <input name="group1" type="radio" [checked]="model.chklost"
                                    (click)="onchklost($event)" />
                                <span style="font-size: 104%">Lost</span>
                            </label>
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 20px">
                            <label>
                                <input name="group1" type="radio" [checked]="model.chkaband"
                                    (click)="onchkaband($event)" />
                                <span style="font-size: 104%">Abandoned</span>
                            </label>
                        </div>
                        <div class="col l3 m3 s3" style="margin-top: 20px">
                            <input type="text" placeholder="Decision Date" [(ngModel)]="model.decisionDate"
                                id="decisionDate" class="form-control" bsDatepicker data-toggle="tooltip"
                                data-placement="top" title="Decision Date"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true}">
                        </div>
                        <div class="input-field col l3 m3 s3" style="margin: 0px" *ngIf="model.decision!='won'">
                            <span>Loss/Abandoned Reason</span>
                            <select (change)="onSelectLossReason($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Loss/Abandoned Reason">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of reasonslst" [value]="lst.id"
                                    [selected]="lst.id==model.lossreason">
                                    {{lst.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row" *ngIf="action=='Approve'&&model.orderaccp=='Company'">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 15px;font-weight: bold">Quotation
                                Owner:&nbsp;{{model.ownernameofOppor}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col l6 m6 s6" *ngIf="action=='Decision'||action=='Approve'"
                            style="margin-bottom: 0px;">
                            <textarea [(ngModel)]="model.remarks" #remarks="ngModel" class="materialize-textarea"
                                type="text" id="remarks" data-toggle="tooltip" data-placement="top" title="Remarks"
                                style="margin-bottom: 0px;"></textarea>
                            <label for="remarks" class="active">Remarks</label>
                        </div>
                        <div class="input-field col l6 m4 s4"
                            *ngIf="action=='Decision'&&model.orderaccp=='EndCustomer' || action=='Approve'&&model.orderaccp=='EndCustomer'"
                            style="margin-bottom: 0px;text-align: end;">
                            <button *ngIf="showOtp == false" (click)="showotp()" type="button"
                                class="waves-effect waves-light btn">Show OTP</button>
                            <button *ngIf="showOtp == true" type="button"
                                class="waves-effect waves-light btn"><b>{{model.Otpshow}}</b></button>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom:9px;">
                        <div class="col l9 m9 s9" *ngIf="action=='Decision'||action=='Approve'">
                            <span style="font-size: 15px">Previous
                                Remark:&nbsp;<span>{{model.previousremarks}}</span></span>
                        </div>
                    </div>
                    <div class="row" *ngIf="action!='View'&&action!='Decision'">
                        <div class="input-field col l3 m3 s3">
                            <span>Product Category</span>
                            <select (change)="onSelectProCat($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Product Category">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of procatlst" [value]="lst.pcid"
                                    [selected]="lst.pcid==procatid">
                                    {{lst.pcname}}</option>
                            </select>
                        </div>
                        <div class="input-field col l4 m4 s4">
                            <span>Product</span>
                            <select (change)="onSelectProdCt($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Products">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of productlst" [value]="lst.pid" [selected]="lst.pid==proid">
                                    {{lst.name}}</option>
                            </select>
                        </div>

                        <!-- <div class="input-field col l2 m2 s2" *ngIf="currencylst.length>0">
                        <span>Currency</span>
                        <select (change)="onSelectCurrency($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Currency">
                            <option selected>Select One</option>
                            <option *ngFor="let lst of currencylst" [value]="lst.id">
                                {{lst.name}}</option>
                        </select>
                    </div> -->
                        <div class="input-field col l3 m3 s3" *ngIf="currcymodel.id!=null" style="margin-top: 30px">
                            <span style="font-size: 17px">Currency:&nbsp;<span>{{currcymodel.name}}</span></span>
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 30px;text-align: right;padding-right: 0px;">
                            <button (click)="btnAddProduct()" type="button"
                                class="waves-effect waves-light btn">Add</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l12 m12 s12" style="background-color: gainsboro;padding: 5px;">
                            <div *ngFor="let lst of productEnquirylst ; let i=index">
                                <div class="col l12 m12 s12" style="background-color: aliceblue">
                                    <div class="row">
                                        <div class="col l6 m6 l6" style="text-align: center; margin-top: 1%;">
                                            <span style="font-size: 15px;">{{lst.name}}</span>
                                        </div>
                                        <div class="input-field col l2 m2 s2" style="margin-bottom: 0px;font-size: 17px"
                                            *ngFor="let curlst of lst.currencylst" [value]="curlst.id" [disabled]="i!=0"
                                            [disabled]="action=='View'||action=='Decision'">
                                            {{curlst.name}}
                                            <!-- <select (change)="onSelectProCurrency($event.target.value, lst.pid, i)"
                                            data-toggle="tooltip" data-placement="top" title="Currency"
                                            [disabled]="i!=0" [disabled]="action=='View'||action=='Decision'">
                                            <option [value]="0">Select</option>
                                            <option *ngFor="let curlst of lst.currencylst" [value]="curlst.id"
                                                [selected]="curlst.id==lst.currencyid">
                                                {{curlst.name}}</option>
                                        </select> -->
                                        </div>
                                        <!-- <div class="col l3 m3 s3" style="text-align: right">
                                        <span style="font-size: 15px;">Total Product Amount</span><br />
                                        <span style="font-size: 15px;">{{lst.totalproamount | number:'1.2-2'}}</span>
                                    </div> -->
                                        <div class="col l1 m1 s1" style="margin-top: 2%; text-align: end">
                                            <span *ngIf="action=='edit'||action=='New'"><img
                                                    src="../../../assets/images/delete.png" width="22px"
                                                    (click)="deleteProduct(lst.id, lst.pid)" /></span>
                                        </div>
                                    </div>
                                </div>
                                <table>
                                    <thead>
                                        <th width="10%" style="text-align: right">Quantity</th>
                                        <th width="10%" style="text-align: right">UOM</th>
                                        <th width="15%" style="text-align: right">List Price</th>
                                        <th width="15%" style="text-align: right">Discount Offered</th>
                                        <!-- <th width="15%" style="text-align: right">Offered Price</th> -->
                                        <th width="15%" style="text-align: right" *ngIf="model.gstseperate==true">Tax
                                            Amount
                                        </th>
                                        <th width="20%" style="text-align: right;padding-right: 10px;">Total Amount</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="padding: 0px;">
                                                <input [(ngModel)]="lst.quantity" #quantity="ngModel" type="number"
                                                    class="" id="quantity" data-toggle="tooltip"
                                                    oninput="validity.valid||(value='');" style="text-align: end"
                                                    data-placement="top" title="Quantity" min="0"
                                                    (ngModelChange)="onChangeOfPrice(lst.id,lst.discount)"
                                                    [disabled]="action=='View'||action=='Decision'">
                                            </td>
                                            <td style="text-align: right;font-size: 15px">{{lst.uom}}</td>
                                            <td style="text-align: end;font-size: 15px;" *ngIf="lst.ratewithdiscount == null">
                                                {{lst.listprice | number:'1.2-2'}}</td>
                                            <!-- <td style="text-align: right" *ngIf="lst.ratewithdiscount == null">{{lst.amount |
                                                    number:'1.2-2'}}</td> -->
                                            <td style="text-align: right;font-size: 15px;" *ngIf="lst.ratewithdiscount != null">
                                                {{lst.ratewithdiscount | number:'1.2-2'}}</td>
                                            <td style="padding: 0px;text-align: end" *ngIf="model.chkschemeflag =='N'">
                                                <input [(ngModel)]="lst.discount" #discount="ngModel" type="number"
                                                    style="text-align: end;width: 75%" class="" id="discount"
                                                    data-toggle="tooltip" data-placement="top" title="Discount"
                                                    (ngModelChange)="onChangeOfPrice(lst.id,lst.discount)" min="0"
                                                    oninput="validity.valid||(value='');"
                                                    [disabled]="action=='View'||action=='Decision'">
                                            </td>
                                            <td style="text-align: right;font-size: 15px;"
                                                *ngIf="model.chkschemeflag =='Y'">
                                                <!-- model.orderaccp=='EndCustomer' -->
                                                {{lst.discount | number:'1.2-2'}}
                                            </td>
                                            <!-- <td style="text-align: end; font-size: 15px;">
                                            {{lst.offeredPrice | number:'1.2-2'}}</td> -->

                                            <td style="text-align: right;font-size: 15px;"
                                                *ngIf="model.gstseperate==true">
                                                {{lst.taxamount | number:'1.2-2'}}</td>
                                            <td style="text-align: right;font-size: 15px;padding-right: 10px;">
                                                {{lst.totalproamount | number:'1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="row" *ngIf="model.chkschemeflag =='Y'">
                        <div class="input-field col l3 m3 s3">
                            <input [(ngModel)]="model.schmemCode" #schmemCode="ngModel" type="text" id="schmemCode"
                                data-toggle="tooltip" style="text-align: end" data-placement="top"
                                [disabled]="action=='View'||action=='Decision' || codehide == true" title="Scheme Code"
                                (ngModelChange)="selectSchemeCode(model.schmemCode)">
                            <label for="schmemCode">Scheme Code</label>
                        </div>
                        <div class="col l1 m1 s1" style="margin-top: 12px;" *ngIf="model.schmemCode!=null">
                            <span><img src="../../../assets/images/cancel.png" width="50%"
                                    (click)="removeScheme()" /></span>
                        </div>
                        <div class="input-field col l2 m2 s2"
                            *ngIf="schemeModel.discountType == 'Custom' || model.schemetype == 'Custom'">
                            <input [(ngModel)]="model.customnumber" #customnumber="ngModel" type="text"
                                id="customnumber" data-toggle="tooltip" style="text-align: end" data-placement="top"
                                placeholder="CustomNumber">
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 10px;">
                            <button (click)="btnApplyScheme()" type="button" [disabled]="model.schmemCode==null"
                                class="waves-effect waves-light btn">Calculate</button>
                        </div>
                    </div>
                    <div class="row" style="background-color: gainsboro">
                        <div class="col l8 m8 s8">
                            <span style="font-size: 15px; font-weight: bold">Amount In
                                Words:&nbsp;<span>{{currcymodel.code}} {{amountInWords |
                                    uppercase}}</span>&nbsp;ONLY</span>
                        </div>
                        <div class="col l4 m4 s4" style="text-align: right;">
                            <span style="font-size: 15px; font-weight: bold">Total Quotation
                                Amount:&nbsp;<span>{{totalamount | number:'1.2-2'}}</span></span>
                        </div>
                    </div>
                    <div class="row" *ngIf="model.orderaccp=='EndCustomer' && model.otpverified==true">
                        <div class="input-field col l5 m5 s5" style="margin-bottom: 0px;">
                            <textarea [(ngModel)]="model.installInstru" #installInstru="ngModel"
                                class="materialize-textarea" type="text" id="installInstru" data-toggle="tooltip"
                                data-placement="top" title="Installation Instructions"></textarea>
                            <label for="installInstru" class="active">Installation Instructions</label>
                        </div>
                        <div class="input-field col l2 m2 s2" style="margin-top: 30px;">
                            <input type="checkbox" [(ngModel)]="model.chkscheduleInstal">&nbsp;&nbsp;Schedule Timing
                        </div>
                        <div class="input-field col l2 m2 s2" style="margin-bottom: 0px;">
                            <input type="text" [(ngModel)]="model.installionDate" id="installionDate"
                                class="form-control" bsDatepicker
                                (ngModelChange)="selectInstallationDate(model.installionDate)" data-toggle="tooltip"
                                data-placement="top" title="Installation Date"
                                [disabled]="model.chkscheduleInstal==false"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true }">
                            <label for="installionDate" class="active">Installation Date</label>
                        </div>
                        <div class="input-field col l1 m1 s1" style="margin-bottom: 0px;">
                            <input type="text" [(ngModel)]="model.installionTime" data-toggle="tooltip"
                                data-placement="top" title="Installation Date" id="installionTime"
                                [disabled]="model.chkscheduleInstal==false">
                            <label for="installionTime" class="active">Time</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="model.salesTC==true" style="margin-top: 1%">
                        <div class="col l12 m12 s12" style="padding: 0px;">
                            <span style="font-weight: bold; font-size: 15px">Terms and Conditions</span>
                            <table>
                                <thead style="background-color: gainsboro;">
                                    <th width="5%" style="text-align: center"></th>
                                    <th width="20%" style="text-align: left">Heading</th>
                                    <th width="75%" style="text-align: left">Description</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of tandclst">
                                        <td style="text-align: center; padding: 0px"><input
                                                [(ngModel)]="lst.chkselecttc" #chkselecttc="ngModel" type="checkbox"
                                                class="" id="chkselecttc"
                                                [disabled]="action=='View'||action=='Decision'">
                                        </td>
                                        <td style="text-align: left; padding: 0px;font-size: 14px">
                                            {{lst.heading}}
                                        </td>
                                        <td style="text-align: left; padding: 0px"><input [(ngModel)]="lst.descrption"
                                                #descrption="ngModel" type="text" class="" id="descrption"
                                                data-toggle="tooltip" (ngModelChange)="onchangeTerms()"
                                                data-placement="top" title="Description"
                                                [disabled]="action=='View'||action=='Decision'">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col l12 m12 s12" *ngIf="action=='Approve'&&changetandclt.length>0"
                            style="padding: 0px;">
                            <span style="font-weight: bold; font-size: 15px">Changed Terms and Conditions</span>
                            <table>
                                <thead style="background-color: gainsboro;">
                                    <th width="40%" style="text-align: left">Heading</th>
                                    <th width="60%" style="text-align: left">Description</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of changetandclt">
                                        <td style="text-align: left; padding: 0px;font-size: 15px">
                                            {{lst.heading}}
                                        </td>
                                        <td style="text-align: left; padding: 0px;font-size: 15px"><span
                                                [innerHTML]="lst.descrption"></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="model.decision=='won'&&model.orderaccp=='EndCustomer'&&model.otpverified==false">
                        <div class="col l10 m10 s10" style="text-align: right;margin-top: 19px"><span
                                style="font-size: 15px; font-weight: bold">Ask to Customer for OTP
                                And Enter Here to Approve Quotation</span></div>
                        <div class="input-field col l2 m2 s2" style="margin: 0px">
                            <input type="text" id="otp" [(ngModel)]="model.otp" #otp="ngModel"
                                (ngModelChange)="btnVerfiyOtp()" [max]="6">
                            <label for="otp">OTP</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col l6 m6 s6" style="text-align: left;">
                            <button (click)="btnprint(QuotationReport)"
                                *ngIf="model.approvedQuo==true&&model.quotationid!=null" type="button"
                                class="waves-effect waves-light btn" [disabled]="action=='View'">Print
                                Quotation</button>&nbsp;&nbsp;
                            <b *ngIf="action=='View' && action!='Approve' && model.decision!='won'"
                                style="color: red;">Quotation is sent for approval</b>
                        </div>
                        <div class="input-field col l6 m6 s6" style="text-align: right;">
                            <button (click)="btnback()" type="button"
                                class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                            <!-- <span *ngIf="model.sendquotation==true&&model.quotationid!=null"><button (click)="btnSendQuo()"
                                type="button" class="waves-effect waves-light btn">Send
                                Quotation</button>&nbsp;&nbsp;</span> -->
                            <button (click)="btnsaveQuotation()" type="button" *ngIf="action!='Approve'"
                                [disabled]="action=='View' || showBtn == true"
                                class="waves-effect waves-light btn">Save</button>
                            <button (click)="btnApproveQuotation()" type="button" *ngIf="action=='Approve'"
                                class="waves-effect waves-light btn">Approve</button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>


    <ng-template #QuotationHistory>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Quotation History</h4>
            <div class=" col l12 m12 s12" id="LibDocs">
                <div class="row" *ngFor="let lst of quohistorylst" style="border-bottom: 2px solid black;">
                    <div class="col l12 m12 s12" style="    padding: 10px;">
                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l8 m8 s8" style="padding: 5px;">
                                <span style="font-size: 13px; font-weight: bold">Quotation
                                    Code:&nbsp;<span>{{lst.quotationno}}</span></span>
                            </div>
                            <div class="right-align col l4 m4 s4" style="margin-top: 2px">
                                <span><img src="../../../assets/images/printer.png" (click)="openPrint1()"
                                        width="25px" /></span>
                            </div>
                        </div>
                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Created
                                    By:&nbsp;<span>{{lst.createdBy}}</span></span>
                            </div>
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Created
                                    Date:&nbsp;<span>{{lst.createdDate | date:"MMM d, y, h:mm:ss a"}}</span></span>
                            </div>
                        </div>

                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Customer
                                    Name:&nbsp;<span>{{lst.customerName}}</span></span>
                            </div>
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Customer
                                    Address:&nbsp;<span>{{lst.customerAddress}}</span></span>
                            </div>
                        </div>

                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Enquiry
                                    Name:&nbsp;<span>{{lst.enquiryName}}</span></span>
                            </div>
                            <div class="left-align col l6 m6 s6">
                                <span
                                    style="font-size: 13px; font-weight: bold">Remarks:&nbsp;<span>{{lst.remarks}}</span></span>
                            </div>
                        </div>
                    </div>
                    <table>
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Product Name</th>
                            <th width="14%" style="text-align: end">List Price</th>
                            <th width="14%" style="text-align: end">Quntity</th>
                            <th width="14%" style="text-align: end">Discount</th>
                            <th width="14%" style="text-align: end">Offered Price</th>
                            <th width="14%" style="text-align: end;padding-right: 10px;">Total Amount</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pro of lst.historyproductlst">
                                <td style="text-align: left;padding-left: 10px;">{{pro.name}}</td>
                                <td style="text-align: end">{{pro.listprice | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.quantity | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.discount | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.offeredPrice | number:'1.2-2'}}</td>
                                <td style="text-align: end;padding-right: 10px;">{{pro.totalproamount | number:'1.2-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <table style="margin-bottom: 2%;">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let terms of lst.historyTermslst">
                                <td style="text-align: left;padding-left: 10px;">{{terms.heading}}</td>
                                <td style="text-align: left">{{terms.descrption}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="row" style="margin-top: 9px;">
                    <button (click)="modalRef.hide()" type="button" class="waves-effect waves-light btn">Close</button>
                </div>
            </div>
        </div>
    </ng-template>


    <ng-template #VersionHistory>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Quotation Version History</h4>
            <div class=" col l12 m12 s12">
                <div class="row" *ngFor="let lst of quoVersionHistory">
                    <div class="col l12 m12 s12" style="    padding: 10px;">
                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l8 m8 s8" style="padding: 5px;">
                                <span style="font-size: 13px; font-weight: bold">Quotation
                                    Code:&nbsp;<span>{{lst.quotationno}}</span></span>
                            </div>
                            <!-- <div class="right-align col l4 m4 s4" style="margin-top: 2px">
                                <span><img src="../../../assets/images/printer.png" width="25px"/></span>
                            </div> -->
                        </div>
                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Created
                                    By:&nbsp;<span>{{lst.createdBy}}</span></span>
                            </div>
                            <div class="left-align col l4 m4 s4">
                                <span style="font-size: 13px; font-weight: bold">Created
                                    Date:&nbsp;<span>{{lst.createdDate | date:"MMM d, y, h:mm:ss a"}}</span></span>
                            </div>
                            <div class="right-align col l2 m2 s2">
                            </div>
                        </div>

                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Customer
                                    Name:&nbsp;<span>{{lst.customerName}}</span></span>
                            </div>
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Customer
                                    Address:&nbsp;<span>{{lst.customerAddress}}</span></span>
                            </div>
                        </div>

                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Enquiry
                                    Name:&nbsp;<span>{{lst.enquiryName}}</span></span>
                            </div>
                            <div class="left-align col l6 m6 s6">
                                <span
                                    style="font-size: 13px; font-weight: bold">Remarks:&nbsp;<span>{{lst.remarks}}</span></span>
                            </div>
                        </div>
                    </div>
                    <table>
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Product Name</th>
                            <th width="14%" style="text-align: end">List Price</th>
                            <th width="14%" style="text-align: end">Quntity</th>
                            <th width="14%" style="text-align: end">Discount</th>
                            <th width="14%" style="text-align: end">Offered Price</th>
                            <th width="14%" style="text-align: end;padding-right: 10px;">Total Amount</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pro of lst.historyproductlst">
                                <td style="text-align: left;padding-left: 10px;">{{pro.name}}</td>
                                <td style="text-align: end">{{pro.listprice | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.quantity | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.discount | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.offeredPrice | number:'1.2-2'}}</td>
                                <td style="text-align: end;padding-right: 10px;">{{pro.totalproamount | number:'1.2-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style="margin-bottom: 2%;">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let terms of lst.historyTermslst">
                                <td style="text-align: left;padding-left: 10px;">{{terms.heading}}</td>
                                <td style="text-align: left">{{terms.descrption}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="row">
                    <button (click)="modalRef.hide()" type="button" class="waves-effect waves-light btn">Close</button>
                </div>
            </div>
        </div>

    </ng-template>

    <ng-template #QuotationReport>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align:right ">
                    <button (click)="modalRef.hide()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                </div>
            </div>
        </div>
        <div class="modal-content registerBgShadow" id="inv">
            <h5 style="text-align: center;">Quotation</h5>
            <div class="col l12 m12 s12" style="border: 1px solid black;">
                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                        <span style="font-size: 16px">{{quoReport.companyname}}</span>
                    </div>
                </div>
                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                        <span style="font-size: 16px">{{quoReport.companyaddress}}</span>
                    </div>
                </div>

                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12">

                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <tbody>
                                <tr>
                                    <td width="50%">Quotation No:&nbsp;{{quoReport.quotationCode}}</td>
                                    <td width="50%">Quotation Date:&nbsp;{{quoReport.quoDate | date:"dd-MM-yyyy"}}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Contact Person:&nbsp;{{quoReport.contactPerson}}</td>
                                    <td width="50%">Payment Terms:&nbsp;{{quoReport.paymentTerm}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row" style="margin:0px;">
                    <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px;"
                        cellpadding="7">
                        <thead>
                            <th style="text-align: left;padding-left: 10px;padding-right: 0px;">Sr.No.</th>
                            <th style="text-align: left">Description of Goods</th>
                            <th style="text-align: left">HSN</th>
                            <th style="text-align: right">Quantity</th>
                            <th style="text-align: right">Rate</th>
                            <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th>
                            <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th> -->
                            <th style="text-align: right">UOM</th>
                            <!-- <th style="text-align: right">Discount</th> -->
                            <th style="text-align: right;padding-right: 10px;padding-left: 0px">Total Amount</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of qutReportlst">
                                <td style="text-align: center;">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.name}}</td>
                                <td style="text-align: left">{{lst.hsn}}</td>
                                <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                <td style="text-align: right" *ngIf="lst.ratewithdiscount == null">{{lst.amount |
                                    number:'1.2-2'}}</td>
                                <td style="text-align: right" *ngIf="lst.ratewithdiscount != null">
                                    {{lst.ratewithdiscount | number:'1.2-2'}}</td>
                                <!-- <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.netamount |
                                    number:'1.2-2'}}</td>
                                <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.taxamount |
                                    number:'1.2-2'}}</td> -->
                                <td style="text-align: right">{{lst.uom}}</td>
                                <!-- <td style="text-align: right">{{lst.discount | number:'1.2-2'}}</td> -->
                                <td style="text-align: right;padding-right: 10px;padding-left: 0px">
                                    {{lst.totalproamount | number:'1.2-2'}}

                                </td>
                            </tr>
                            <!-- <tr *ngIf="model.gstseperate==true">
                                <td></td>
                                <td style="text-align: right;">Net Amount</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{quoReport.totalnetamount |
                                    number:'1.2-2'}}</td>
                            </tr>
                            <tr *ngIf="model.gstseperate==true">
                                <td></td>
                                <td style="text-align: right;">Tax Amount</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{quoReport.totaltaxamount |
                                    number:'1.2-2'}}</td>
                            </tr> -->
                            <tr>
                                <td></td>
                                <td style="text-align: right">Total Amount</td>
                                <td></td>
                                <td></td>
                                <!-- <td></td> -->
                                <td *ngIf="model.gstseperate==true"></td>
                                <td *ngIf="model.gstseperate==true"></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{quoReport.totalamount |
                                    number:'1.2-2'}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" style="margin:0px;border:1px solid black">
                    <div class="col l12 m12 s12">
                        <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{quoReport.totalinwords |
                                uppercase}}&nbsp;ONLY</span></span>

                    </div>
                </div>
                <div class="row" style="margin:0px;">
                    <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                        <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                    </div>
                </div>

                <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                    style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                    <thead style="background-color: gainsboro;">
                        <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                        <th width="70%" style="text-align: left">Description</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of reporttandclst">
                            <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                            <td style="text-align: left">{{lst.descrption}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col l12 m12 s12">
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align:right ">
                    <button (click)="modalRef.hide()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                </div>
            </div>
        </div>
    </ng-template>
</div>