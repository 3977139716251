import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { SearchTargetModel } from './SearchTargetModel';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FinancialyearModel } from 'src/app/Master/FinancialYear/FinancialyearModel';
@Component({
    selector: 'app-nTeam',
    templateUrl: 'SearchTarget.html'
})
export class SearchTarget implements OnInit {
    id: number;
    user = new User();
    model: any = {};

    p: any;

    q1 = "April,May, June";
    q2 = "July, August, September";
    q3 = "October,November,December";
    q4 = "January, February, March";
    currentdate = new Date();
    months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    d = new Date();
    monthName = this.months[this.d.getMonth()];

    public localename:string;
    modalRef: BsModalRef;
    regionlst: DropDownModel[] = [];
    finanyealst: DropDownModel[] = [];
    arealst: DropDownModel[] = [];
    teamlst: DropDownModel[] = [];
    lstuser: DropDownModel[] = [];
    targetlst: SearchTargetModel[] = [];
    localelst: SearchTargetModel[] = [];
    promodlst: SearchTargetModel[] = [];
    defaultcurrencylst: DropDownModel[] = [];
    // finanyealst: FinancialyearModel[] = [];

    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private alertService: AlertService, private location: Location, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private transactionservice: TransactionServices) {
    }

    ngOnInit(): void {
       
        this.getdefaultcurr();
        this.getregionlst();
        this.getfinancialyelst();
        this.modalRef.hide();
        this.model.userid = Number(localStorage.getItem("userid"));

    }
    getregionlst() {

        let user = new User();
        this.regionlst = [];
        this.transactionservice.getregionlst(user)
            .subscribe((value) => {
                this.regionlst = value.reglst;

            });
    }

    // getfinancialyear() {
    //     this.Masterservice.getfinancialyear(this.model)
    //         .subscribe(value => {
    //             this.finanyealst = value;


    //         })
    // }
    getfinancialyelst() {
        let user = new User();
        this.finanyealst = [];
        this.transactionservice.getfinancialyelst(user)
            .subscribe(value => {
                this.finanyealst = value.finyearlst
            })
    }
    getdefaultcurr() {
        let user = new User();
        this.defaultcurrencylst = [];
        this.Masterservice.getdefaultcurr(user)
            .subscribe((value) => {
                this.defaultcurrencylst = value.deafcurlst
            });
    }
    onSelectFinalyear(value) {
        if (value == 0) {
            this.model.typeid = null
            this.notifyService.showRroor("Select Target Calender", "Oops!");
            return;
        }
        for (let i = 0; i < this.finanyealst.length; i++) {
            if (this.finanyealst[i].id == value) {
                this.model.financialyearid = this.finanyealst[i].id;
                this.model.financialYear = this.finanyealst[i].name
            }
        }
          
        if (this.model.currentyear == 'Y') {
            
            if (this.q1.includes(this.monthName)) {

                this.model.q1flag = true
                this.model.q2flag = true
                this.model.q3flag = true
                this.model.q4flag = true
            }

            if (this.q2.includes(this.monthName)) {
                this.model.q1flag = false
                this.model.q2flag = true
                this.model.q3flag = true
                this.model.q4flag = true

            }
            if (this.q3.includes(this.monthName)) {

                this.model.q1flag = false
                this.model.q2flag = false
                this.model.q3flag = true
                this.model.q4flag = true

            }
            if (this.q4.includes(this.monthName)) {

                this.model.q1flag = false
                this.model.q2flag = false
                this.model.q3flag = false
                this.model.q4flag = true



            }

        }

    }
    onSelectRegion(regionId) {


        this.arealst = [];
        this.user.trnid = regionId;
        this.model.regionId = regionId;
        this.transactionservice.getallAreaByregion(this.user)
            .subscribe(value => {
                this.arealst = value.arelst
            });
    }
    onSelectArea(areaid) {

        this.teamlst = [];
        this.user.trnid = areaid;
        this.model.areaid = areaid;
        this.transactionservice.getallTeamByarea(this.user)
            .subscribe(value => {
                this.teamlst = value.teamlst
            });

    }
    onSelectCurrency(value) {

        this.model.defaultcurrency = value

    }
    onSelectTeam(teamMasterID) {
        if (teamMasterID == 0) {
            this.notifyService.showRroor("Select Team!!", "Oops!");
            return;
        }
        this.lstuser = [];
        this.user.trnid = teamMasterID;
        this.model.teamMasterID = teamMasterID;
        this.transactionservice.getallUserByTeam(this.user)
            .subscribe(value => {
                this.lstuser = value.lstuser

            });
    }
    onSelectlstuser(tbAssignBy) {

        this.model.resourceid = tbAssignBy;
        this.model.tbAssignBy = tbAssignBy;
        this.transactionservice.getlocaleByUser(this.model)
            .subscribe(value => {
                // this.model = value
                this.model.localename = value.localename
                this.model.targetlst = value.targetlst

                if (this.q1.includes(this.monthName)) {

                    this.model.q1flag = true
                    this.model.q2flag = true
                    this.model.q3flag = true
                    this.model.q4flag = true
                }

                if (this.q2.includes(this.monthName)) {
                    this.model.q1flag = false
                    this.model.q2flag = true
                    this.model.q3flag = true
                    this.model.q4flag = true
                }
                if (this.q3.includes(this.monthName)) {

                    this.model.q1flag = false
                    this.model.q2flag = false
                    this.model.q3flag = true
                    this.model.q4flag = true
                }
                if (this.q4.includes(this.monthName)) {

                    this.model.q1flag = false
                    this.model.q2flag = false
                    this.model.q3flag = false
                    this.model.q4flag = true
                }
               
            });
           
    }
    btnopenmodel(tbAssignBy, localemodel: TemplateRef<any>) {
       
        this.model.tbAssignBy = tbAssignBy
        this.transactionservice.getalllocale(this.model)
            .subscribe(
                value => {
                  
                //   this.localename = value.localename
                    this.model.localenames = value.localename

                },

                error => {
                    this.alertService.error(error);
                });
        this.modalRef = this.modalService.show(localemodel, {
            class: 'modal-lg'
        });
    }

    closemodel() {
        this.modalRef.hide();
    }



    btnsave() {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.transactionservice.savetarget(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success ")
                        this.router.navigate(['target']);

                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    cancle() {
        this.router.navigate(["/TargetHome"]);
    }
    onChangestartdate() {

    }
    onSelectsales(value) {
        this.model.activity = false

        this.model.sales = true
        this.model.salesflag = "S";

        this.model.activityflag = null;

    }
    onSelectactivity(value) {
        this.model.sales = false
        this.model.activity = true
        this.model.activityflag = "A";
        this.model.salesflag = null;
    }

}