<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Marketing Cost Head % Allocation Template</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="row" style="margin: 0px;">
                    <div class="input-field col l3 m3 s3" style="padding-left: 14px;margin-bottom: 0px;">
                        <span>Marketing Cost Head</span>
                        <select (change)="onSelectmrktcost($event.target.value)">
                            <option selected> Select Marketing Cost Head</option>
                            <option *ngFor="let type of mrktcostlst" [value]="type.id"
                                [selected]="type.id == model.marketingcostid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="col s3">
                        <div class="row">
                            <div class=" input-field col s10"
                                style="margin-top: 26px;text-align: right; padding: 0px;margin-bottom: 0px;margin-left: 9px;">
                                <input [(ngModel)]="model.percentage" #percentage="ngModel" type="number" min="0"
                                    oninput="validity.valid||(value='');" id="first1" data-toggle="tooltip"
                                    style="text-align: right;" data-placement="top" title="Percentage"
                                    (ngModelChange)="onChangeOfpercent(model.percentage);" required>
                                <label id="lable1" for="first1" class style="left: 0px;">Percentage</label>
                            </div>
                         
                        </div>
                    </div>
                    <div class="col l4 m4 s4" style="text-align: left;margin-top: 45px">
                        <span style="font-size: 17px;font-style: normal;">Balance to Allocate: &nbsp;</span><span
                            style="font-size: 13px;">{{remainpercent | number:'1.2-2'}} &#37;&nbsp;&nbsp;</span>
                    </div>
                    <div class="col l2 m2 s2" style="text-align: right;margin-top: 30px;">
                        <button (click)="btnAdd()" type="button" class="waves-effect waves-light btn">Add</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="margin-top: 12px;padding-left: 18px;padding-right: 18px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="5%" style="text-align: left;"><input type="checkbox"></th>
                                <th width="75%" style="text-align: left">Marketing Cost Sub-Head</th>
                                <th width="20%" style="text-align: left">% Allocation</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: p } ;let i = index; ">
                                    <td style="text-align: left;padding: 5px;"><input type="checkbox"
                                            [(ngModel)]="lst.checkselect" #checkselect="ngModel"
                                            (click)="onSelectCheckselect(i,$event)"></td>
                                    <td style="text-align: left;padding: 5px;">{{lst.costsubname}}</td>
                                    <td style="padding: 5px;">
                                        <div class="input-field col l10 m10 s10"
                                            style="text-align: right;padding-left: 8px;margin-top: 0px;margin-bottom: 0px;">
                                            <input [(ngModel)]="lst.percentallocate" #percentallocate="ngModel"
                                                type="number" min="0" oninput="validity.valid||(value='');"
                                                id="percentage" data-toggle="tooltip" data-placement="top"
                                                style="text-align: right;margin-bottom: 0px;"
                                                (ngModelChange)="onChangeOfpercentage(i);" title="Percentage" required>
                                        </div>
                                        <div class=" input-field col s1"
                                            style="margin-top:12px;margin-bottom: 0px;padding: 0px;">
                                            &#37;</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
                <div class="row" style="margin: 0px;">
                    <div class="input-field col l12 m12 s12" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                            style="margin-left: 5px;">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>