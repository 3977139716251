import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AreaModel1 } from "src/app/Transactions/Teams/TeamModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel"

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'OpenCloseServiceEnquiries.html'
})
export class OpenCloseServiceEnquiriesReport implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {

    }
    model = new DCRreportModel();
    user = new User();
    regionlst: DropDownModel[] = [];
    arealst: AreaModel1[] = [];
    resourcelst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    companyname: string;
    companyaddress: String;
    srno: number;

    id: number;
    srlst: DCRreportModel[] = [];
    dsrlst: DCRreportModel[] = [];
    eqrlst: DCRreportModel[] = [];

    regionName: string;
    areaName: string;
    branchName: string;
    resourceName: string;
    timing = new Date();
    username: string;
    status: string;
   

    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.areaName = localStorage.getItem('areaName');
        this.regionName = localStorage.getItem('regionName');
        this.branchName = localStorage.getItem('branchName');
        var getObj = JSON.parse(localStorage.getItem('testObject'));
        this.status = localStorage.getItem('status');
        this.model = getObj;
        this.btnshow();
    }

    btnshow() {

        this.reportServices.showOpenCloseServiceEnquiry(this.model)
            .subscribe(
                data => {
                    this.eqrlst = data;
                    for (let i = 0; i < this.eqrlst.length; i++) {
                        this.eqrlst[i].srno = i + 1 ;
                        this.companyname = this.eqrlst[0].companyname
                        this.companyaddress = this.eqrlst[0].companyaddress;
                    }

                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        // WindowObject.close();
    }
    btnback() {
        this.router.navigate(["/OpenCloseServiceEnquiriesReport"]);
    }

}