<app-header></app-header>

<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m4 l12 registerBgShadow">
            <div class="row" style="margin-bottom: 0px;">
                <!-- <div class="col s3">
                </div> -->
                <div class="input-field col s3" style="margin-top: 9px;padding-left: 14px;">
                    <span>Search Criteria</span>
                    <select (change)="onSelect($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Search Type">
                        <option *ngFor="let type of searchtype" [value]="type.name">{{type.name}} </option>
                    </select>
                </div>
                <div class="input-field col s3" style="margin-top: 26px;">
                    <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Text">
                    <label for="search">Search Text</label>
                </div>
                <div class="col s3" style="margin-top: 26px;">
                    <button type="button" (click)="btnsearch($event)"
                        class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnnew()">New</button>
                </div>
            </div>

            <div class="row" style="padding-left: 9px;padding-right: 9px;">
                <table>
                    <thead style="background: gainsboro;">
                        <th width="15%" style="text-align: left">Sr.no</th>
                        <th width="20%" style="text-align: left">Name</th>
                        <th width="35%" style="text-align: left">Value(%)</th>
                        <th width="20%" style="text-align: left">Actions</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of taxmodellst | paginate: { itemsPerPage: 10, currentPage: p }">
                            <td style="text-align: left">{{lst.srno}}</td>
                            <td style="text-align: left">{{lst.name}}</td>
                            <td style="text-align: left">{{lst.persentage}}</td>
                            <td style="text-align: left"><span><img src="../../../assets/images/edit.png"
                                        (click)="btnedit(lst.id)" style="width: 20px;"
                                        title="Edit" /></span>&nbsp;&nbsp;&nbsp;<span><img
                                        src="../../../assets/images/file-preview.png"
                                        (click)="creategroup(lst.id, lst.name, lst.persentage)" style="width: 20px;"
                                        title="GST Group" /></span>&nbsp;&nbsp;&nbsp;<span><img
                                        src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                        style="width: 20px;" title="Delete" /></span></td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align: right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </span>
            </div>

        </div>
    </div>
</div>