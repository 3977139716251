import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, AuthenticationService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationDashbordModel } from './InstallationsDashbordModel';
import { InstallationService } from 'src/app/_services/Installation.Service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';
var converter = require('number-to-words');
@Component({
    selector: 'app-InstallationDashbord',
    templateUrl: 'InstallationsDashbord.html',
})
export class InstallationDashbord implements OnInit {
    constructor(private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private transactionservice: TransactionServices, private installationService: InstallationService, private deviceService: DeviceDetectorService, private authenticationService: AuthenticationService) {
    }
    model: any = {};
    loginuserid: any;
    user = new User();
    p: any;

    isMobile: boolean;
    deviceInfo = null;
    mainmenus: Mainmenumodel[] = [];
    username: string;
    sum: number = 1;
    totalItems: any;
    instcount: number;

    installationlst: InstallationDashbordModel[] = [];
    installationlst1: InstallationDashbordModel[] = [];
    searchtype: DropDownModel[] = [
        { id: 0, name: "Select Search Criteria" },
        { id: 1, name: "All" },
        { id: 2, name: "Customer Name" },
        { id: 3, name: "Mobile Number" },
        { id: 4, name: "Invoice Number" },
        { id: 5, name: "Status" },
    ]
    ngOnInit() {
        this.getinstallationlst();
        this.username = localStorage.getItem('fullname');
        this.mainmenus = JSON.parse(localStorage.getItem('menu'));
        // console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        // console.log(this.deviceInfo);
        // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        // console.log(isDesktopDevice);

    }
    getinstallationlst() {
        this.loginuserid = Number(localStorage.getItem("userid"));
        this.user.userId = Number(localStorage.getItem("userid"));
        this.model.userid = this.user.userId;
        this.model.pageno = this.sum;
        this.installationService.getinstallationlst(this.model)
            .subscribe(
                data => {
                    this.installationlst = data;
                    this.installationlst1 = data;
                    for (let i = 0; i < this.installationlst.length; i++) {
                        this.model.instcount = this.installationlst[0].instcount;
                        this.instcount = this.model.instcount * 2;
                    }
                });
    }
    btncreateinst(id,approvalnumber) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "approvalnumber": approvalnumber,
                "forflag": "new"
            }
        };
        this.router.navigate(['/Create Installation'], navigationExtras);
    }
    btnedit(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "forflag": "edit"
            }
        };
        this.router.navigate(['/Create Installation'], navigationExtras);
    }

    btnclose(id) {
        let navigationExtras: NavigationExtras =
        {
            queryParams: {
                "id": id,
                "forflag": "close"
            }
        };
        this.router.navigate(['/Create Installation'], navigationExtras);
    }
    btneditDCR(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id
            }
        };
        this.router.navigate(['/InstallationDCRcreate'], navigationExtras);
    }
    onSelectSrch(val) {
        this.model.txtselect = val;

    }
    btnsearch($event) {

        if (this.model.txtselect == "All") {
            this.model.sendstring = this.model.txtserch;
            this.model.message = null;
            this.getinstallationlst()
            this.model.txtserch = null;
        }
        else {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            this.model.message = this.model.txtselect;
            this.model.sendstring = this.model.txtserch;
            this.model.pageno = this.sum;
            this.installationService.getinstallationlst(this.model)
                .subscribe(
                    data => {
                        this.installationlst = data;
                        this.installationlst1 = data;
                        for (let i = 0; i < this.installationlst.length; i++) {
                            this.model.instcount = this.installationlst[0].instcount;
                            this.instcount = this.model.instcount * 2;
                        }
                    });
        }
        // else if (this.model.txtselect == "Customer Name") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.installationlst1 = [];
        //         this.installationlst.forEach((item) => {
        //             if ((item.coustomername.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {

        //                 this.installationlst1.push({ "id": item.id, "quotationId":item.quotationId,"orderaccID":item.orderaccID,"coustomername":item.coustomername,"mobile":item.mobile,"invoicenumner":item.invoicenumner,"approvalnumber":item.approvalnumber,"invoicedate":item.invoicedate,"status":item.status,"srnumbers":item.srnumbers,"showedit":item.showedit,"closeinstalltion":item.closeinstalltion,"installationstatus":item.installationstatus,"installstatus":item.installstatus,"installationclose":item.installationclose,"installationApproved":item.installationApproved,"userid":item.userid,"txtserch":item.txtserch,"assignedtoID":item.assignedtoID,"installtionID":item.installtionID,"totalItems":item.totalItems,"pageno":item.pageno});

        //             }
        //         });
        //         this.installationlst = this.installationlst1;
        //     }
        // }else if (this.model.txtselect == "Mobile Number") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.installationlst1 = [];
        //         this.installationlst.forEach((item) => {
        //             if ((item.mobile.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {

        //                 this.installationlst1.push({ "id": item.id, "quotationId":item.quotationId,"orderaccID":item.orderaccID,"coustomername":item.coustomername,"mobile":item.mobile,"invoicenumner":item.invoicenumner,"approvalnumber":item.approvalnumber,"invoicedate":item.invoicedate,"status":item.status,"srnumbers":item.srnumbers,"showedit":item.showedit,"closeinstalltion":item.closeinstalltion,"installationstatus":item.installationstatus,"installstatus":item.installstatus,"installationclose":item.installationclose,"installationApproved":item.installationApproved,"userid":item.userid,"txtserch":item.txtserch,"assignedtoID":item.assignedtoID,"installtionID":item.installtionID,"totalItems":item.totalItems,"pageno":item.pageno});

        //             }
        //         });
        //         this.installationlst = this.installationlst1;
        //     }
        // }else if (this.model.txtselect == "Invoice Number") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.installationlst1 = [];
        //         this.installationlst.forEach((item) => {
        //             if ((item.invoicenumner.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {

        //                 this.installationlst1.push({ "id": item.id, "quotationId":item.quotationId,"orderaccID":item.orderaccID,"coustomername":item.coustomername,"mobile":item.mobile,"invoicenumner":item.invoicenumner,"approvalnumber":item.approvalnumber,"invoicedate":item.invoicedate,"status":item.status,"srnumbers":item.srnumbers,"showedit":item.showedit,"closeinstalltion":item.closeinstalltion,"installationstatus":item.installationstatus,"installstatus":item.installstatus,"installationclose":item.installationclose,"installationApproved":item.installationApproved,"userid":item.userid,"txtserch":item.txtserch,"assignedtoID":item.assignedtoID,"installtionID":item.installtionID ,"totalItems":item.totalItems,"pageno":item.pageno});

        //             }
        //         });
        //         this.installationlst = this.installationlst1;
        //     }
        // }
        // if (this.model.txtselect == "Invoice Date") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.installationlst1 = [];
        //         this.installationlst.forEach((item) => {
        //             if ((item.invoicedate.toString()).match(this.model.txtserch.toLowerCase().trim())){

        //                 this.installationlst1.push({ "id": item.id, "quotationId":item.quotationId,"orderaccID":item.orderaccID,"coustomername":item.coustomername,"mobile":item.mobile,"invoicenumner":item.invoicenumner,"approvalnumber":item.approvalnumber,"invoicedate":item.invoicedate,"status":item.status,"srnumbers":item.srnumbers,"showedit":item.showedit,"closeinstalltion":item.closeinstalltion,"installationstatus":item.installationstatus,"installstatus":item.installstatus,"installationclose":item.installationclose,"installationApproved":item.installationApproved,"userid":item.userid,"txtserch":item.txtserch,"assignedtoID":item.assignedtoID,"installtionID":item.installtionID,"totalItems":item.totalItems,"pageno":item.pageno});

        //             }
        //         });
        //         this.installationlst = this.installationlst1;
        //     }
        // } else if (this.model.txtselect == "Status") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.installationlst1 = [];
        //         this.installationlst.forEach((item) => {
        //             if ((item.installstatus.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {

        //                 this.installationlst1.push({ "id": item.id, "quotationId":item.quotationId,"orderaccID":item.orderaccID,"coustomername":item.coustomername,"mobile":item.mobile,"invoicenumner":item.invoicenumner,"approvalnumber":item.approvalnumber,"invoicedate":item.invoicedate,"status":item.status,"srnumbers":item.srnumbers,"showedit":item.showedit,"closeinstalltion":item.closeinstalltion,"installationstatus":item.installationstatus,"installstatus":item.installstatus,"installationclose":item.installationclose,"installationApproved":item.installationApproved,"userid":item.userid,"txtserch":item.txtserch,"assignedtoID":item.assignedtoID,"installtionID":item.installtionID ,"totalItems":item.totalItems,"pageno":item.pageno});

        //             }
        //         });
        //         this.installationlst = this.installationlst1;
        //     }
        // }


    }
    redirectUser(mainmenulabel: string) {////Resp
        this.router.navigate(['/' + mainmenulabel]);
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    showPageIndex(pageIndex) {
        this.sum = pageIndex;
        this.getinstallationlst();
    }
    btnprevious() {
        this.sum = this.sum - 1;
        this.getinstallationlst();
    }
    btnnext() {
        this.sum = this.sum + 1;
        this.getinstallationlst();
    }
    last() {
        this.sum = Math.ceil(this.instcount/ 20);
        this.getinstallationlst();
    }
    first() {
        this.sum = 1;
        this.getinstallationlst();
    }
}