export class SearchPurchaseorderModel {
    id: number;
    dateTime: Date;
    approvedorderflg: string;
    txtselect: string;
    txtserch: string;
    userid: number;
    dealerPurchesNO: string;
    srno: number;
    value: number;
    iseditflag:boolean;
    approvedflag:boolean;
}