import { StockadjustmentModel } from 'src/app/Transactions/Stock Adjustment/StockadjustmentModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { usertwofieldModel } from './usertwofieldModel';

export class SearchUModel {
    id: number;
    name: string;
    srno:number;
    activeInactive: string;
    designationName: string;
    branchname: string;
    deptName: string;
    txtserch: string;
    txtselect: string;
}



export class SearchUserModel {
    id: number;
    name: string;
    status: string;
    branch: string;
    branchid:number;
    department: string;
    designation: string;
    designationID: number;
    deptID: number;
    telephone: number;
    mobile: number;
    email: string;
    password: string;
    pass:string;
    loginName: string;
    salutation: string;
    resourceID: string;
    activeInactive: string;
    designationName: string;
    branchname: string;
    userprefix:number;
    deptName: string;
    userid:number;
    usergroup: string;
    usergroupid:number
    
    salelocationid:number;
    spalocationid:number;
    sparelocationname:string;
    salelocationname:string;
    customervalue:string;
  

    reportstoname:string;
    vehicletype: string;
    // accesstype:string;
    costRate: number = 0;
    reportsToID: number;
    // searchtype: DropDownModel[]=[];
    // accesstypelst: DropDownModel[]=[];
    // activeinactivelst: DropDownModel[]=[];
    // vehicletypelst: DropDownModel[]=[];

    desiglst:usertwofieldModel[]=[];
    deptlst:usertwofieldModel[]=[];
    reportingtolst:usertwofieldModel[]=[];
    activeinaclst:usertwofieldModel[]=[];
    branlst:usertwofieldModel[]=[];
    usergrolst:usertwofieldModel[]=[];
    accesslst:usertwofieldModel[]=[];
    vehiclelst:usertwofieldModel[]=[];

    isadmincheck: boolean;
    showinchkselect: boolean;
    seflag: boolean;

    txtserch: string;
    txtselect: string;

    activeInactiveflag:string;
    birthdate: Date = new Date();
    Expensefromdate: Date = new Date();
    ExpenseSecofromdate: Date = new Date();
    expencestartdate: Date = new Date();
    expencestartseconddate: Date = new Date();

}