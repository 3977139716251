import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, ProductionService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { Pipe } from '@angular/core';
declare var $: any;

@Component({
    selector: 'app-CreatePlanRequisition',
    templateUrl: 'CreatePlanRequisition.html',
})
export class CreatePlanRequisition implements OnInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private masterservice: MasterServices,
        private transactionService: TransactionServices,
        private ProductionService: ProductionService) {
    }
    model: any = {};
    user = new User();
    locationlst: DropDownModel[] = [];
    conversionid: number = 0;
    p: any;
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.model.planhdrId = params["id"];
        });

        if (this.model.planhdrId != null) {
 
            this.ProductionService.getAllPlanreuisition(this.model)
                .subscribe(
                    data => {
                        this.model = data;

                          if(data.requisitiondate!=null){
                              this.model.requisitiondate=new Date(data.requisitiondate)
                          }else{
                            this.model.requisitiondate=new Date();

                          }
                        this.transactionService.getInventoryLocation(this.model)
                            .subscribe(data => {

                                data.locationLst.forEach(element => {
                                    if (element.id != this.model.fromlocationid) {
                                        this.locationlst.push({
                                            id: element.id,
                                            name: element.name
                                        })
                                    }
                                });
                            });
                    });
        }
    }

    btnBack() {
        this.router.navigate(['/Production Plan']);
    }
    btncancle() {

    }
    btnfinish() {

        if(this.model.tolocationid==null||this.model.tolocationid==0){
            this.notifyService.showRroor("Please Select To Location ","Opps");
            return;
        }



        this.model.userid=localStorage.getItem("userid");
        this.ProductionService.SavePlanpreRequisition(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Requisition Saved Successfully", "Success");
                    }
                });

    }
    btnFinalize() {

        if(this.model.tolocationid==null||this.model.tolocationid==0){
            this.notifyService.showRroor("Please Select To Location ","Opps");
            return;
        }



        this.model.userid=localStorage.getItem("userid");
        this.ProductionService.SaveFinalizepreRequisition(this.model)
        .subscribe(
            data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess("Requisition Finalized Successfully", "Success");
                    this.router.navigate(['/Production Plan']);
                }
            });
    }

    onSelectInventoryLocationTo(id){
        this.model.tolocationid=id;
    }
}