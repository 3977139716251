import { OnInit, Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { SearchPurchaseOrderModel } from './SearchPurchaseOrderModel';
import { DefinePurchaseOrderModel } from './DefinePurchaseOrderModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import * as moment from 'moment';
@Component({
    selector: 'app-SearchPurchaseOrder',
    templateUrl: 'SearchPurchaseOrder.html',
})
export class SearchPurchaseOrder implements OnInit {

    constructor(private transactionService: TransactionServices,
        private notifyService: NotificationService,
        private router: Router,
    ) {

    }
    user = new User();
    model: any = {};
    p: any;

    public i;
    edit: boolean = false;
    view: boolean = false;
    vendorlist: DefinePurchaseOrderModel[] = [];
    orderlstall: SearchPurchaseOrderModel[] = [];
    orderlstall1: SearchPurchaseOrderModel[] = [];

    searchtype: DropDownModel[] = [
        { id: 0, name: "All" },
        { id: 1, name: "Open" },
        { id: 2, name: "Close" }
    ]

    ngOnInit(): void {
        this.getAllpurchaseorder();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.startdate = new Date()
        this.model.enddate = new Date()
    }
    getAllpurchaseorder() {

        this.transactionService.getAllpurchaseorder(this.model)
            .subscribe(value => {
                this.orderlstall = value;
                this.orderlstall1 = [];
                value.forEach((item) => this.orderlstall1.push({ "id": item.id, "name": item.name, "enddate": item.enddate, "podate": item.podate, "txtserch": item.txtserch, "vendorname": item.vendorname, "amount": item.amount, "postatus": item.postatus, "userid": item.userid, "startdate": item.startdate, "suppliercheckselect": item.suppliercheckselect, "ponocheckselect": item.ponocheckselect, "statuscheckselect": item.statuscheckselect, "pono": item.pono, "txtserchstatus": item.txtserchstatus, "txtserchpono": item.txtserchpono }));
                this.orderlstall = this.orderlstall1;
            })
    }
    btnnew() {
        this.router.navigate(['/DefinePurchaseOrder']);
    }
    btnedit(id) {

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "flag": "edit"
            }
        };
        this.router.navigate(['/viewPurchaseOrder'], navigationExtras);

    }
    btnview(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "flag": "view"
            }
        };
        this.router.navigate(['/viewPurchaseOrder'], navigationExtras);

    }
    btnclose(index, id) {

        this.orderlstall[index].postatus = "Close"
        this.model.id = id
        this.model.vendormodellst = this.vendorlist
        this.transactionService.savePurchase(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        // this.router.navigate(["/Product"]);

                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }

    btnsearch() {
        if (this.model.suppliercheckselect) {
            this.orderlstall1 = [];
            this.orderlstall.forEach((item) => {
                if ((item.vendorname.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                    this.orderlstall1.push({ "id": item.id, "name": item.name, "enddate": item.enddate, "podate": item.podate, "txtserch": item.txtserch, "vendorname": item.vendorname, "amount": item.amount, "postatus": item.postatus, "userid": item.userid, "startdate": item.startdate, "suppliercheckselect": item.suppliercheckselect, "ponocheckselect": item.ponocheckselect, "statuscheckselect": item.statuscheckselect, "pono": item.pono, "txtserchstatus": item.txtserchstatus, "txtserchpono": item.txtserchpono });
                }
            });
            this.orderlstall = this.orderlstall1;

        }
        // else

        if (this.model.statuscheckselect) {
            if (this.model.txtselect == "All") {
                this.getAllpurchaseorder();
            }
            this.orderlstall1 = [];
            this.orderlstall.forEach((item) => {
                if ((item.postatus.toLowerCase()).match(this.model.txtselect.toLowerCase().trim())) {
                    this.orderlstall1.push({ "id": item.id, "name": item.name, "enddate": item.enddate, "podate": item.podate, "txtserch": item.txtserch, "vendorname": item.vendorname, "amount": item.amount, "postatus": item.postatus, "userid": item.userid, "startdate": item.startdate, "suppliercheckselect": item.suppliercheckselect, "ponocheckselect": item.ponocheckselect, "statuscheckselect": item.statuscheckselect, "pono": item.pono, "txtserchstatus": item.txtserchstatus, "txtserchpono": item.txtserchpono });
                }
            });
            this.orderlstall = this.orderlstall1;

        }
        //  else
        if (this.model.ponocheckselect) {
            this.orderlstall1 = [];
            this.orderlstall.forEach((item) => {
                if ((item.pono.toLowerCase()).match(this.model.txtserchpono.toLowerCase().trim())) {
                    this.orderlstall1.push({ "id": item.id, "name": item.name, "enddate": item.enddate, "podate": item.podate, "txtserch": item.txtserch, "vendorname": item.vendorname, "amount": item.amount, "postatus": item.postatus, "userid": item.userid, "startdate": item.startdate, "suppliercheckselect": item.suppliercheckselect, "ponocheckselect": item.ponocheckselect, "statuscheckselect": item.statuscheckselect, "pono": item.pono, "txtserchstatus": item.txtserchstatus, "txtserchpono": item.txtserchpono });
                }
            });
            this.orderlstall = this.orderlstall1;
        }
        if (this.model.datecheckselect) {
            let startdate = moment(this.model.startdate).format('YYYY-MM-DD');
            let todate = moment(this.model.enddate).format('YYYY-MM-DD');
            if (this.model.startdate && this.model.enddate) {
                this.orderlstall1 = [];
                this.orderlstall.forEach((item) => {
                    if (item.podate.toString() <= todate && item.podate.toString() >= startdate) {
                        this.orderlstall1.push({ "id": item.id, "name": item.name, "enddate": item.enddate, "podate": item.podate, "txtserch": item.txtserch, "vendorname": item.vendorname, "amount": item.amount, "postatus": item.postatus, "userid": item.userid, "startdate": item.startdate, "suppliercheckselect": item.suppliercheckselect, "ponocheckselect": item.ponocheckselect, "statuscheckselect": item.statuscheckselect, "pono": item.pono, "txtserchstatus": item.txtserchstatus, "txtserchpono": item.txtserchpono });
                    }
                });
                this.orderlstall = this.orderlstall1;
            }
        }


    }
    onSelectSrch(val) {
        this.model.txtselect = val
    }
    selectStartDate(startdate) {
        if (startdate < new Date()) {
            this.notifyService.showRroor("Previous date cannot be selected", "oops");
            this.model.startdate = new Date()
            return;
        }
    }
    selectEndDate(enddate) {
        if (enddate < this.model.startdate) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.enddate = this.model.startdate
            return;
        }
    }
}
