import { TemplateRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { CityMasterModel } from "src/app/Master/CityMaster/CityMasterModel";
import { AreaModel1, CityModel1 } from "src/app/Transactions/Teams/TeamModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel";
import { OrdInHandReportModel } from "./OrdInHandReportModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'OrdInHandReport.html'
})
export class OrdInHandReport implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {

    }
    model = new OrdInHandReportModel();
    dmodel = new DCRreportModel();
    user = new User();
    regionlst: DropDownModel[] = [];
    citylst: CityMasterModel[] = [];
    arealst: AreaModel1[] = [];
    citylst1 :CityModel1[] = [];
    prodCatlst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    companyname: String;
    companyaddress: String;

    id: number;
    ordlst: OrdInHandReportModel[] = [];
    regionName: string;
    areaName: string;
    cityName:string;
    branchName: string;
    prodCatName: string;
    timing = new Date();
    username:string;
    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.getRegions();
        this.getCity();
    }
    selectFromDate(From) {
        if (From > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.frmdate = new Date()
            return;
        }
    }
    selectToDate(date) {
        if (date < this.model.frmdate) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.todate = new Date()
            return;
        }
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.todate = new Date()
            return;
        }
    }
    getRegions() {
        this.regionlst = []
        this.Masterservice.getAllRegions(this.user)
            .subscribe(value => {
                this.regionlst = value
            });
    }
    getCity()
    {
        this.citylst = [];
        this.Masterservice.getAllCitiesReport(this.user)
        .subscribe(value => {
            this.citylst = value
        });

    }
    onSelectRegions(val) {
        this.arealst = [];
        if (val == 0) {
            this.notifyService.showRroor("Select Region!!", "Oops!");
            return;
        }
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.model.name = this.user.message;
                this.regionName = this.user.message;
            }

        } else {
            this.model.regionid = val
            for (let i = 0; i < this.regionlst.length; i++) {
                if (this.regionlst[i].id == val) {
                    this.regionName = this.regionlst[i].name
                }
            }
            this.user.trnid = val;
        }

        this.Masterservice.getAreaByRegion(this.user)
            .subscribe(value => {
                this.arealst = value
            });
    }
    onSelectArea(val) {
        this.dmodel.areaintlst = [];
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.areaName = this.user.message
            }

            for (let i = 0; i < this.arealst.length; i++) {
                this.dmodel.areaintlst.push(this.arealst[i].areaid)
            }
        } else {
            // alert(val)
            this.dmodel.areaid = val;
            for (let i = 0; i < this.arealst.length; i++) {
                if (this.arealst[i].areaid == val) {
                    this.areaName = this.arealst[i].areaname
                }
            }
            this.dmodel.areaintlst.push(this.dmodel.areaid)
        }
        this.branchlst = [];
        this.reportServices.getBranchByarea(this.dmodel)
            .subscribe(value => {

                this.branchlst = value
               
            });
    }
    onSelectBranches(val) {
        this.model.branchintlst = []
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.branchName = this.user.message
            }

            for (let i = 0; i < this.branchlst.length; i++) {
                this.model.branchintlst.push(this.branchlst[i].id)
               
            }
        } else {
            this.dmodel.branchid = val
            for (let i = 0; i < this.branchlst.length; i++) {
                if (this.branchlst[i].id == val) {
                    this.branchName = this.branchlst[i].name
                }
            }
            //alert(this.dmodel.branchid)
            this.model.branchintlst.push(this.dmodel.branchid)
           // alert(this.model.branchintlst.length)
        }
        this.reportServices.getProdCatByBranch(this.model)
            .subscribe(value => {
                this.prodCatlst = value
            });
    }
    onSelectPrdCategory(val) {
        this.model.productintlst = []
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.prodCatName = this.user.message
            }
            for (let i = 0; i < this.prodCatlst.length; i++) {
                this.model.productintlst.push(this.prodCatlst[i].id)
            }
        } else {
            this.model.prodCatID = val;
            this.model.productintlst.push(this.model.prodCatID)
        }
    }
    btnshow(OrdInHandReport: TemplateRef<any>) {
        if (this.model.frmdate == null) {
            this.notifyService.showRroor(" Please select From Date !!", "Oops!");
            this.model.frmdate = new Date()
            this.model.frmdate.setMonth(this.model.frmdate.getMonth()-1)
            this.model.todate = new Date()
            return;
        }
        if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
            this.model.todate=new Date()
            return;
        }
        // if (this.model.regionid == null) {
        //     this.notifyService.showRroor("Please select Region  !!", "Oops!");
        //     return;
        // }
        // if (this.dmodel.areaid == null) {
        //     this.notifyService.showRroor("Please select Area  !!", "Oops!");
        //     return;
        // }
        if (this.dmodel.branchid == null) {
            this.notifyService.showRroor("Please select Branch  !!", "Oops!");
            return;
        }
        // if (this.model.resourceID == null) {
        //     this.notifyService.showRroor("Please select Product Category  !!", "Oops!");
        //     return;
        // }
        this.reportServices.showOrdInHandReport(this.model)
            .subscribe(
                data => {
                    this.ordlst = data;
                    for (let i = 0; i < this.ordlst.length; i++) {
                    this.companyname = this.ordlst[0].companyname;
                    this.companyaddress = this.ordlst[0].companyaddress;
                    }
                    this.modalRef = this.modalService.show(OrdInHandReport, {
                        class: 'modal-lg'
                    });
                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    onSelectCity(val)
    {
        this.model.cityintlst = [];
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.cityName = this.user.message
            }

            for (let i = 0; i < this.citylst.length; i++) {
                this.model.cityintlst.push(this.citylst1[i].cityid)
            }
        } else {
            this.model.cityid = val;
            for (let i = 0; i < this.citylst.length; i++) {
                if (this.citylst[i].id == val) {
                    this.cityName = this.citylst[i].name
                }
            }
            this.model.cityintlst.push(this.model.cityid)
        }
        this.branchlst = [];
        this.reportServices.getBranchBycity(this.model)
            .subscribe(value => {

                this.branchlst = value
            });
    }
}