import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AlertService, DealersService, MasterServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { Location, } from '@angular/common';
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { productIssue, SpareIssueModel } from "./SpareIssueModel";
var converter = require('number-to-words');
declare var $: any;
@Component({
    selector: 'app-SpareIssue',
    templateUrl: 'SpareIssue.html'
})
export class SpareIssue implements OnInit {
    user = new User();
    model: any = {};
    userList2: any;
    locationlst: DropDownModel[];
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    mrpmap = new Map();
    id: number;
    public i;
    itemname: string;
    itemcodemap = new Map();
    hsnCodemap = new Map();
    uommap = new Map();
    stockforsalemap = new Map();
    p: any;
    sparelst: productIssue[] = [];
    returnspare: SpareIssueModel[] = [];


    constructor(private Masterservice: MasterServices, private dealersService: DealersService, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }

    ngOnInit(): void {
        this.getSpareInventoryLocation();
        this.getitemCategory();
        // this.model.assinedTo = "Assigned To"
        for (let i = 0; i < this.sparelst.length; i++) {
            this.sparelst[i].balance = this.sparelst[i].stockforsale - this.sparelst[i].issuedquantity
        }


    }
    getresourceNameLike($event) {
        let userId = (<HTMLInputElement>document.getElementById('resourceName1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getResourceLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "resourceName": item.resourceName, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].resourceName, this.userList2[i].id)
                        searchDataObj[this.userList2[i].resourceName] = null;
                        if (i == 0) {
                            names = this.userList2[i].resourceName;
                        } else {
                            names = names + "," + this.userList2[i].resourceName;
                        }
                    }
                    let resourceName;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect(id, resourceName) {
        this.model.id = id;
        this.model.resourceName = resourceName;

        if (this.model.spareReturnchecked) {
            this.transactionService.getreturnspare(this.model)
                .subscribe(data => {
                    this.model = data;
                    this.sparelst = data.sparelst
                    this.model.spareReturnchecked = true;
                });

        }
        this.transactionService.getresourceloc(this.model)
            .subscribe(data => {
                this.model.locationid = data.locationid
                this.model.locationname = data.locationname
            });
    }


    onselectReturn($event) {

        if (this.model.spareReturnchecked) {
            if (this.model.resourceName == null || this.model.resourceName == "") {
                this.notifyService.showRroor("Please select Resource !", " Oops");
                return;
            }
        }


    }
    getSpareInventoryLocation() {
        this.locationlst = [];
        this.transactionService.getSpareInventoryLocation(this.model)
            .subscribe(data => {
                this.model = data;
                this.locationlst = data.locationlst

            });
    }

    onSelectInventoryLocation(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id == value) {
                this.model.locationid = this.locationlst[i].id;
                this.model.locationname = this.locationlst[i].name;
            }
        }
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }

    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.itemsubcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.itemsubcategory = this.subCatlst[i].name
            }
        }
    }
    getItemLike($event) {

        let userId = (<HTMLInputElement>document.getElementById('itmename')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        // this.user.id = this.model.itemcategoryid;
        // this.user.trnid = this.model.itemsubcategoryid;
        this.user.locationID = this.model.locationid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getAllItemsLikes(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "uom": item.uom, "stockforsale": item.stockforsale }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].itemname, this.userList2[i].itemid)
                        this.itemcodemap.set(this.userList2[i].itemname, this.userList2[i].itemcode)
                        this.uommap.set(this.userList2[i].itemname, this.userList2[i].uom)
                        this.stockforsalemap.set(this.userList2[i].itemname, this.userList2[i].stockforsale)

                        searchDataObj[this.userList2[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList2[i].itemname;
                        } else {
                            names = names + "," + this.userList2[i].itemname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect1(itemid, itemname) {
        this.model.itemid = itemid;
        this.model.itemname = itemname;
        this.model.itemcode = this.itemcodemap.get(itemname);
        this.model.uom = this.uommap.get(itemname);
        this.model.stockforsale = this.stockforsalemap.get(itemname);


    }
    btnadd() {

        for (let j = 0; j < this.sparelst.length; j++) {
            if (this.sparelst.length <= 0) {
                this.notifyService.showRroor("List is Null", " Oops");
                return;
            }
        }
        for (let i = 0; i < this.sparelst.length; i++) {
            if (this.sparelst[i].itemid == this.model.itemid) {
                this.notifyService.showRroor("Item already present", " Oops");
                return;
            }
        }
        if (this.model.itemcategoryid == null) {
            this.notifyService.showRroor("Please Select Itemcategory", " Oops");
            return;
        }
        if (this.model.itemsubcategoryid == null) {
            this.notifyService.showRroor("Please Select ItemSubcategory", " Oops");
            return;
        }
        if (this.model.itemname == null) {
            this.notifyService.showRroor("Please Select Itemname", " Oops");
            return;
        }

        this.sparelst.push({
            itemname: this.model.itemname,
            itemid: this.model.itemid,
            uom: this.model.uom,
            itemcode: this.model.itemcode,
            stockforsale: this.model.stockforsale,
            issuedquantity: this.model.issuedquantity,
            balance: this.model.balance,
        })
        this.model.balance = 0;
        for (let i = 0; i < this.sparelst.length; i++) {
            this.sparelst[i].balance = this.sparelst[i].stockforsale - this.sparelst[i].issuedquantity
        }
    }

    onChangeOfqantity(index) {
        for (let i = 0; i < this.sparelst.length; i++) {
            this.sparelst[i].balance = this.sparelst[i].stockforsale - this.sparelst[i].issuedquantity
        }

        if (this.model.spareReturnchecked) {
            for (let i = 0; i < this.sparelst.length; i++) {
                this.sparelst[index].balance = this.sparelst[index].stockforsale + this.sparelst[index].issuedquantity
            }
        }

    }
    // onChangeOfQantity() {
    //     for (let i = 0; i < this.sparelst.length; i++) {
    //         this.sparelst[i].balance = this.sparelst[i].stockforsale - this.sparelst[i].issuedquantity
    //     }
    // }
    btncancle() {

    }
    btnsave($event) {
        if (this.model.resourceName == null || this.model.resourceName == "") {
            this.notifyService.showRroor("Please select Resource !", " Oops");
            return;
        }
        if (this.model.locationid == null) {
            this.notifyService.showRroor("Please select Location !", " Oops");
            return;
        }
        if (this.sparelst.length <= 0) {
            this.notifyService.showRroor("List is Null", " Oops");
            return;
        }

        for (let j = 0; j < this.sparelst.length; j++) {
            if (this.sparelst[j].issuedquantity == null) {
                this.notifyService.showRroor("Enter Quantity  !!", "Oops!");
                return;
            }
        }

        this.model.userid = Number(localStorage.getItem('userid'));
        this.model.sparelst = this.sparelst
        this.transactionService.savespareissue(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit();
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
}



