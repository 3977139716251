import { DropDownModel } from 'src/app/_models/DropDownModel';


export class DefineAccountwisemachinedetailModel {
    id: number;
    name: string;
    accountid: number;
    customername: string;
    orderdate: Date;
    amcname: string;
    localeName: string;
    address: string;
    serviceCallStatusName: string;
    tbservicecallstatus:number;
    productCode: string;
    accountname: string;
    dateTime: Date;
    installationHourCount: number;
    amcEndDate: Date;
    orderDate: Date;
    orderNo: string;
    warrentyEndDate: Date;
    mcRefNo: string;
    siteAddr: string;
    Mandays: number;
    remarks: string;
    installationDate: Date;
    amcMcAmount: string;
    amcWarrantyEndDate: Date;
    amcWarrantyStartDate: Date;
    warrantyMonths: number;
    productCategoryID: number;
    serialNo: string;
    installationdate: Date;
    warrentystartdate: Date;
    warrentyenddate: Date;
    productid: number;
    categoryname: string;
    mcstatuslst: DefineAccountwisemachinedetailModel[] = [];
    servicecallmcstatuslst: DropDownModel[] = [];

    localelst: DefineAccountwisemachinedetailModel[] = [];
    productlst: DefineAccountwisemachinedetailModel[] = [];
    amctypelst: DefineAccountwisemachinedetailModel[] = [];
    mcstatuscodelst: DropDownModel[] = [];
}