<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Item Sub Category</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12">
                <div class=row>
                    <div class="col l1"></div>
                    <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;">
                        <span>Item Category</span>
                        <select (change)="onSelectitemCat($event.target.value)">
                            <option selected value="0">Select Item Category</option>
                            <option *ngFor="let type of itmCatlst" [value]="type.id"
                                [selected]="type.id == model.itemcategoryid">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class=row>
                    <div class="col l1"></div>
                    <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.subcategorycode" #subcategorycode="ngModel" type="text" class=""
                            id="subcategorycode" data-toggle="tooltip" data-placement="top" title="Sub Category Code"
                            style="margin-top:1px;" maxlength="30">
                        <label for="subcategorycode" class="active">Sub Category Code</label>
                    </div>
                   
                    <div class="input-field col l5 m5 s5" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.subcategoryname" #subcategoryname="ngModel" type="text" class=""
                            id="subcategoryname" data-toggle="tooltip" data-placement="top" title="Sub Category Name"
                            style="margin-top:1px;">
                        <label for="subcategoryname" class="active">Sub Category Name</label>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class=row>
                    <div class="col l1"></div>
                    <div class="input-field col l4 m4 s4" style="margin-top: 15px;">
                        <input [(ngModel)]="model.chkSaleproduct" #chkSaleproduct="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;
                        Sales Product Category
                    </div>
                    <div class="input-field col l4 m4 s4" style="margin-top: 15px;">
                        <input [(ngModel)]="model.chkProductspare" #chkProductspare="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;
                        Product Spare
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="input-field col l10 m10 s10" style="text-align: right;padding-right: 0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)"
                                class="waves-ripple waves-light btn" style="margin-left: 5px;">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>