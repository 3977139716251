export class PublicScreenModel {
    hashkey: string;
    customerid: number;
    refrenceID: number;
    transactiontype: string;
    dbname: string;
    status: string;
    massage: string;
    companycode:string;
    companyname:string;
    gstno:string;
    companyaddress:string;

}