import { DropDownModel } from "src/app/_models/DropDownModel";
import { ShorthandPropertyAssignment } from "typescript";

export class DefineInventoryLocModel{
    locationcode:string;
    locationname:string;
    itemcategoryid: number;
    itemcate:string;
    subcategoryid: number;
    subcategory:string
    subcategoryname: string;
    id: number;
    itemcateName:string;
    subcatName:string;
    userid: number;
    srno:number;
    chkrowMaterial:boolean;
    chkProductionLoc:boolean;
    chkSpareloc:boolean;
    chkDespatchLoc:boolean;
    itemslst:CategoryModel[] = [];
    resourcelst:DropDownModel[] = [];
    storekeeperid:number;
    storekeepername:string;
}

export class CategoryModel{
    id:number;
    srno:number
    itemcateName:string;
    itemcateId:number;
    subcatName:string;
    subcatId:number;
    userid:number;
}