<app-header></app-header>
<div class="container">
  <div class="row">
    <div class="col l12 m12 s12">
      <div class="col l2 m2 s2"></div>
      <div class="heading col l8 m8 s8" style="text-align: center;">
          <span style="font-size: 25px;">Menu</span>
      </div>
      <div class="col l2 m2 s2"></div>
  </div>
    <div class="col s12 m12 l12 registerBgShadow">
      <div class="row" style="margin-top:20px">
        <tree-root [focused]="true" [options]="options" [nodes]="nodes" style="font-size: 17px;">
          <ng-template #treeNodeTemplate let-node="node" style="font-size: 17px;" >
            <span *ngIf="node === editNode">
              <input autofocus [(ngModel)]="node.data.name" (blur)="stopEdit()" (keyup.enter)="stopEdit()" />
            </span>
            <span *ngIf="node !== editNode">{{ node.data.name }}</span>
          </ng-template>
        </tree-root>
        <div class="menu" *ngIf="contextMenu" [style.left.px]="contextMenu.x" [style.top.px]="contextMenu.y">
          <div>Menu for {{ contextMenu.node.data.name }}</div>
          <hr>
          <ul>
             <li (click)="edit()"><a>edit</a></li>
            <li (click)="add()" *ngIf="contextMenu.node.data.issub"><a>Add</a></li>
            <li (click)="cut()"><a>Delete</a></li>
          </ul>
        </div>
        <br>

      </div>
      <!-- <div class="row">
        <div class="input-field col l12 m12 s12" style="text-align: right" *ngIf="edit">
            <button (click)="btnback()" type="button"
                class="waves-effect cancelbutton waves-light btn">Cancel</button>
        </div>
    </div> -->
    </div>

   
  </div>
</div>