import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchInventoryLocModel } from "./SearchInventoryLocModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'SearchInventoryLoc.html'
})
export class SearchInventoryLoc implements OnInit {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {
            this.pagename=this.router.url
    }
    public i;
    user = new User();
    p: any;
    pagename:string;
    model = new SearchInventoryLocModel();
    inventryLoclst: SearchInventoryLocModel[] = [];
    inventryLoclst1: SearchInventoryLocModel[] = [];
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Location Code" },
        { id: 3, "name": "Location" },
    ];
    ngOnInit(): void {
        this.getAllinventoryLoc();
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }
    getAllinventoryLoc() {

        this.Masterservice.getAllinventoryLoc(this.user)
            .subscribe(value => {
                this.inventryLoclst = value
                this.inventryLoclst1 = [];
                value.forEach((item) => this.inventryLoclst1.push({ "id": item.id, "locationcode": item.locationcode, "locationname": item.locationname, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect}));
                this.inventryLoclst = this.inventryLoclst1;

            })
    }
    onSelectLocation(value) {
        this.model.txtselect = value;
    }
    btnsearch(id) {
        if (this.model.txtselect == "All") {
            this.getAllinventoryLoc()
            this.model.txtsearch = null;
        }
        else if (this.model.txtselect == "Location Code") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Location Code to Search")
                return;
            }
            else {
                this.inventryLoclst1 = [];
                this.inventryLoclst.forEach((item) => {
                    if ((item.locationcode.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.inventryLoclst1.push({ "id": item.id, "locationcode": item.locationcode, "locationname": item.locationname, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect });
                    }
                });
                this.inventryLoclst = this.inventryLoclst1;
            }
        }
        else if (this.model.txtselect == "Location") {
            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Location To Search")
                return;
            }
            else {
              
                this.inventryLoclst1 = [];
                this.inventryLoclst.forEach((item) => {
                    if ((item.locationname.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.inventryLoclst1.push({"id": item.id, "locationcode": item.locationcode, "locationname": item.locationname, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect});
                    }
                });
                this.inventryLoclst = this.inventryLoclst1;
            }
        }
    }
    btnadd(id) {
        this.router.navigate(['/newDefineInventoryLoc']);
    }
    btnedit(id) {
        this.router.navigate(['/editDefineInventoryLoc', id]);
    }
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
                this.Masterservice.dltInventoryLoc(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }

    }

}
