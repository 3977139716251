import { OnInit, Component } from '@angular/core';
import { DefineAMCTypeModel } from './DefineAMCTypeModel';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
@Component({
    selector: 'app-AMCs',
    //  moduleId: module.id,
    templateUrl: 'SearchAMCType.html'
})
export class SearchAMCType implements OnInit {
    p: any;
    user = new User();
    model = new DefineAMCTypeModel();
    amctypelst: DefineAMCTypeModel[] = [];
    amctypelst1: DefineAMCTypeModel[] = [];
    pagename: string;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Type" },
        { id: 3, "name": "Name" }
    ];
    public i;
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
        this.pagename=this.router.url
    }
    ngOnInit() {
        this.getAllAMCType();
        this.user.userid = Number(localStorage.getItem("userid"))
        localStorage.setItem("pagename",this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }


    getAllAMCType() {
        this.amctypelst = []
        this.Masterservice.getAllAMCType(this.user)
            .subscribe(value => {
                this.amctypelst = [];
                this.amctypelst1 = [];
                value.forEach((item) => this.amctypelst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "lab": null, "com": null, "userid": null, "amcFlag": null }));
                this.amctypelst = this.amctypelst1;
            });
    }

    btnnew() {
        this.router.navigate(['/newAMCType']);
    }

    btnedit(value) {
        this.router.navigate(['/editAMCType', value]);
    }

    btndelts(value) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.user.trnid = value
            this.Masterservice.deleteAMCType(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
    }

    onSelectSrch(val) {
        this.model.txtselect = val
    }
    btnsearch() {
        if (this.model.txtselect == "All") {
            this.getAllAMCType()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Type") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.amctypelst1 = [];
                this.amctypelst.forEach((item) => {
                    if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.amctypelst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "lab": null, "com": null, "userid": null, "amcFlag": null });
                    }
                });
                this.amctypelst = this.amctypelst1;
            }
        }
        else if (this.model.txtselect == "Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.amctypelst1 = [];
                this.amctypelst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.amctypelst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "lab": null, "com": null, "userid": null, "amcFlag": null });
                    }
                });
                this.amctypelst = this.amctypelst1;
            }
        }
    }
}