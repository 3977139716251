import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { common } from './Common';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SaveSuccessModel } from '../_models/SaveSucessModel';
import { addmenucomponentModel } from '../menu/add-menu/add-menu-component-Model';
import { SearchMenuItemModel } from '../menu/Menu_Items/SearchMenuItemModel';
import { User } from '../_models';
import { SearchUserGroupModel } from '../menu/UserGroup/SearchUserGroupModel';
import { DropDownModel } from '../_models/DropDownModel';

@Injectable()
export class MenuServices {

  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  getCollectionofLibrary: any;
  //private headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
  }


  getmenuName(mainumodel: User): Observable<any[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<any>(this.customersUrl + 'api/menu/menubymenname', mainumodel, httpOptions1);
  }

  getmenuTreebyUserid(mainumodel: addmenucomponentModel): Observable<any[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<any>(this.customersUrl + 'api/menu/menubyuser', mainumodel, httpOptions1);
  }


  savemenubyuser(mainumodel: addmenucomponentModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/menu/savemenubyuser', mainumodel, httpOptions1);
  }

  deletemenubyuser(mainumodel: addmenucomponentModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/menu/deletemenubyuser', mainumodel, httpOptions1);
  }

  

  getAllpages(mainumodel: addmenucomponentModel): Observable<SearchMenuItemModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchMenuItemModel[]>(this.customersUrl + 'api/menu/getAllitempage', mainumodel, httpOptions1);
  }

  SaveItemname(mainumodel: SearchMenuItemModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/menu/SaveItemname', mainumodel, httpOptions1);
  }


  
  getAllgroup(user: User): Observable<SearchUserGroupModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchUserGroupModel[]>(this.customersUrl + 'api/menu/getAllgroup', user, httpOptions1);
  } 


  
  SaveUserGroup(mainumodel: SearchUserGroupModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/menu/SaveUserGroup', mainumodel, httpOptions1);
  }

  
  deletegroup(mainumodel: SearchUserGroupModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/menu/deletegroup', mainumodel, httpOptions1);
  }
  getmodulelst(user:SearchUserGroupModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/menu/getmodulelst',user, httpOptions1);
  }
  getallmenudata(user:SearchUserGroupModel): Observable<addmenucomponentModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<addmenucomponentModel>(this.customersUrl + 'api/menu/getallmenudata',user, httpOptions1);
  }
   

  
}