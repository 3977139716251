<div *ngIf="isMobile == true ">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>
    <div class="section no-pad-bot OprntCol" style="padding-top: 7px;">
        <h1 class="header center" style="margin-bottom: 9px;">Installation Dashboard</h1>
        <div class="container" style="width:94%;">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <h6 class="header left" style="margin-bottom: 0px;margin-top: 0px;">Total
                        Installations:&nbsp;{{model.instcount}}</h6>
                    <div class="srchBy p-0">
                        <!-- <label for="">Search Criteria</label> -->
                        <div class="input-field col s12" style="margin-bottom:0px;">
                            <select (change)="onSelectSrch($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col s12" style="margin-bottom:0px;margin-top: 5px;">
                            <div class="w-80 left smallInputNew">
                                <!-- <label for="first-name" class="mat-label m-0">Search Text</label> -->
                                <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="mat-input "
                                    id="first-name" data-toggle="tooltip" data-placement="top" title="Search Field"
                                    placeholder="Search Text">
                            </div>
                            <div class="w-20 right" style="height: 30px;">
                                <img src="../../../assets/images/search.png" width="30px" (click)="btnsearch($event)">
                            </div>
                        </div>

                    </div>
                    <div class="col s12">
                        <div class="tabInnerBox" *ngFor="let lst of installationlst">
                            <!-- <div class="introTab">
                                <label class="w-50 font-weight-bold blueTxt valignTxt">Sr. No.</label>
                                <label class="w-50">{{lst.srnumbers}}</label>
                                <label class="w-50 font-weight-bold blueTxt">Customer Name</label>
                                <label class="w-50">{{lst.coustomername}}</label>
                                <label class="w-50 font-weight-bold blueTxt">Mobile Number</label>
                                <label class="w-50">
                                    <a href="tel:{{lst.mobile}}">{{lst.mobile}}</a></label>

                                <label class="w-50 font-weight-bold blueTxt">OA / Approval Number</label>
                                <label class="w-50 valignTxt">{{lst.approvalnumber}}</label>
                                <label class="w-50 font-weight-bold blueTxt">Invoice Number</label>
                                <label class="w-50">{{lst.invoicenumner}}</label>
                                <label class="w-50 font-weight-bold blueTxt">Invoice Date</label>
                                <label class="w-50">{{lst.invoicedate | date :'d MMMM y'}}</label>
                                <label class="w-50 font-weight-bold blueTxt">Status</label>
                                <label class="w-50">{{lst.installstatus}}</label>
                                <label class="w-50 font-weight-bold blueTxt">Actions</label>
                                <label class="w-50 dcrDiv"> -->
                            <!-- <i class="fa fa-pencil editPencil" aria-hidden="true" id="Button1" value="Click" onclick="switchVisible();"></i>
                                    <i class="fa fa-mobile" aria-hidden="true"></i> -->
                            <!-- <span style="margin-left: 5px;"><img src="../../../assets/images/CreateDocument.png"
                                            (click)="btncreateinst(lst.orderaccID)" data-toggle="tooltip"
                                            data-placement="top" title="Create" width="20px"
                                            *ngIf="lst.installstatus=='Pending'" /></span>
                                    <span style="margin-left: 5px;" *ngIf="lst.showedit"><img
                                            src="../../../assets/images/edit.png" (click)="btnedit(lst.orderaccID)"
                                            data-toggle="tooltip" data-placement="top" title="Edit"
                                            width="20px" /></span> -->
                            <!-- !lst.installationApproved -->
                            <!-- <span style="margin-left: 5px;"
                                        *ngIf="loginuserid === lst.userid && !lst.closeinstalltion"><img
                                            src="../../../assets/images/DCR-Entry.png"
                                            (click)="btneditDCR(lst.installtionID)" data-toggle="tooltip"
                                            data-placement="top" title="DCR-Entry" width="20px" /></span>
                                    <span style="margin-left: 5px;" *ngIf="lst.closeinstalltion"><img
                                            src="../../../assets/images/Close.png" (click)="btnclose(lst.orderaccID)"
                                            data-toggle="tooltip" data-placement="top" title="Close"
                                            width="20px" /></span>
                                </label>
                            </div> -->
                            <div class="introTab">
                                <label class="w-100 dis-block">{{lst.invoicedate | date:"dd-MM-yyyy"}}&nbsp;&nbsp;{{" |
                                    &nbsp;&nbsp;"+lst.invoicenumner}}</label>
                                <label class="w-100 dis-block"><b>{{lst.coustomername}}</b></label>
                                <label class="w-100 dis-block">
                                    <a href="tel:{{lst.mobile}}">{{lst.mobile}}</a>
                                </label>
                                <label class="w-100 dis-block">{{lst.address}}</label>
                                <label class="w-50">{{lst.installstatus}}</label>
                                <label class="w-50" style="height: 21px;">
                                    <!-- <i class="fa fa-pencil editPencil" aria-hidden="true" id="Button1" value="Click" onclick="switchVisible();"></i>
                                    <i class="fa fa-mobile" aria-hidden="true"></i> -->
                                    <span style="margin-left: 5px;"><img src="../../../assets/images/CreateDocument.png"
                                            (click)="btncreateinst(lst.orderaccID,lst.approvalnumber)" data-toggle="tooltip"
                                            data-placement="bottom" title="Create" width="20px"
                                            *ngIf="lst.installstatus=='Pending'" /></span>
                                    <span style="margin-left: 5px;" *ngIf="lst.showedit"><img
                                            src="../../../assets/images/edit.png" (click)="btnedit(lst.orderaccID)"
                                            data-toggle="tooltip" data-placement="bottom" title="Edit"
                                            width="20px" /></span>
                                    <span style="margin-left: 5px;"
                                        *ngIf="loginuserid === lst.userid && lst.installstatus!='Completed'"><img
                                            src="../../../assets/images/DCR-Entry.png"
                                            (click)="btneditDCR(lst.installtionID)" data-toggle="tooltip"
                                            data-placement="bottom" title="DCR-Entry" width="20px" /></span>
                                            <!-- !lst.closeinstalltion -->
                                    <span style="margin-left: 5px;" *ngIf="lst.closeinstalltion"><img
                                        src="../../../assets/images/view.png" (click)="btnclose(lst.orderaccID)"
                                            data-toggle="tooltip" data-placement="bottom" title="Close"
                                            width="20px" /></span>
                                </label>
                            </div>
                        </div>

                        <div class="s12 right" style="margin-top: 10px;">
                            <lable (click)="first()" class="w-30"
                                style="font-size: 20px;margin-right: 17px;color: #645959;" *ngIf="this.sum > 1">
                                <b>First</b>
                            </lable>
                            &nbsp;&nbsp;
                            <lable class="w-30">
                                <span *ngIf="this.sum > 1" style="margin-right: 17px;"><img
                                        src="../../../assets/images/previous_icon.png" width="35px"
                                        data-toggle="tooltip" data-placement="top" (click)="btnprevious()"
                                        data-placement="top" title="Previous Page"></span>&nbsp;&nbsp;

                                <span><img src="../../../assets/images/next_icon.png" width="35px" data-toggle="tooltip"
                                        data-placement="top" (click)="btnnext()" data-placement="top"
                                        title="Next Page"></span>&nbsp;&nbsp;
                            </lable>
                            <lable (click)="last()" class="w-30"
                                style="font-size: 20px;color: #645959; margin-left: 17px;" *ngIf="this.sum > 1">
                                <b>Last</b>
                            </lable>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isMobile == false ">
    <app-header></app-header>
    <div class="container" style="width: 90%;">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Installation Dashboard</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>

            <div class="col l12 m12 s12 registerBgShadow" style="margin-top: 1%">
                <div class="row" style="margin-top: 2%;padding-left: 5px;padding-right: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <div class="col l6 m5 s6">
                        <span style="font-size: 15px;font-weight: bold">Total
                            Installations:&nbsp;<span>{{model.instcount}}</span></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <!-- <div class="col l1"></div> -->
                        <div class="input-field col  s2" style="margin-top: 0px;margin-bottom:0px;padding-left: 5px;">
                            <span>Search Criteria</span>
                            <select (change)="onSelectSrch($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col s2" style="margin-top: 17px;margin-bottom:0px;">
                            <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                                data-toggle="tooltip" data-placement="top" title="Search Field">
                            <label for="search">Search Text</label>
                        </div>
                        <div class="input-field col l1 m1 s1" style="text-align: end;margin-top: 17px">
                            <button type="button" (click)="btnsearch($event)"
                                class="waves-effect waves-light btn">Search</button>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="margin-top:9px;margin-bottom: 9px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="5%" style="text-align: left">Sr. No.</th>
                                <th width="18%" style="text-align: left">Customer Name</th>
                                <th width="12%" style="text-align: left">Mobile Number</th>
                                <th width="15%" style="text-align: left">OA / Approval Number</th>
                                <th width="15%" style="text-align: left">Invoice Number</th>
                                <th width="12%" style="text-align: left">Invoice Date</th>
                                <th width="15%" style="text-align: left">Status</th>
                                <th width="12%" style="text-align: left">Actions</th>

                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of installationlst | paginate: { itemsPerPage: 20, currentPage: p,totalItems:instcount}">
                                    <td style="text-align: left">{{lst.srnumbers}}</td>
                                    <td style="text-align: left">{{lst.coustomername}}</td>
                                    <td style="text-align: left">{{lst.mobile}}</td>
                                    <td style="text-align: left">{{lst.approvalnumber}}</td>
                                    <td style="text-align: left">{{lst.invoicenumner}}</td>
                                    <td style="text-align: left">{{lst.invoicedate | date :'d MMMM y'}}</td>
                                    <td style="text-align: left">{{lst.installstatus}}</td>
                                    <td style="text-align: left">
                                        <span style="margin-left: 5px;"><img
                                                src="../../../assets/images/CreateDocument.png"
                                                (click)="btncreateinst(lst.orderaccID,lst.approvalnumber)" data-toggle="tooltip"
                                                data-placement="top" title="Create" width="20px"
                                                *ngIf="lst.installstatus=='Pending'" /></span>
                                        <!-- && !lst.closeinstalltion -->
                                        <span style="margin-left: 5px;"
                                            *ngIf="loginuserid === lst.userid  && lst.installstatus!='Completed'"><img
                                                src="../../../assets/images/DCR-Entry.png"
                                                (click)="btneditDCR(lst.installtionID)" data-toggle="tooltip"
                                                data-placement="top" title="DCR-Entry" width="20px" /></span>
                                        <span style="margin-left: 5px;" *ngIf="lst.showedit"><img
                                                src="../../../assets/images/edit.png" (click)="btnedit(lst.orderaccID)"
                                                data-toggle="tooltip" data-placement="top" title="Edit"
                                                width="20px" /></span>
                                        <!-- !lst.installationApproved -->
                                        <span style="margin-left: 5px;" *ngIf="lst.closeinstalltion"><img
                                                src="../../../assets/images/view.png"
                                                (click)="btnclose(lst.orderaccID)" data-toggle="tooltip"
                                                data-placement="top" title="View" width="20px" /></span>&nbsp;&nbsp;

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)">
                            </pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>