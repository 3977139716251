import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MasterServices } from "src/app/_services/MasterServices";
import { NotificationService } from "src/app/_services/notification.service";
import { TransactionServices } from "src/app/_services/TransactionServices";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { SearchMCHallocaTemplateModel } from "./SearchMCHallocaTemplateModel";
import { MarketingServices } from "src/app/_services/MarketingServices";
declare var $: any;
@Component({
    selector: 'app-order',
    templateUrl: 'SearchMCHallocaTemplate.html'
})
export class SearchMCHallocaTemplate implements OnInit {

    constructor(private Masterservice: MasterServices,
        private Marketingservice: MarketingServices,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,) {
    }
    mrktcostlst: DropDownModel[] = [];
    modellst: SearchMCHallocaTemplateModel[] = [];
    model: any = {};
    sub: any;
    public p: any;
    user = new User();
    userList2: any;
    percentages: number;
    total: number;
    remainvalue: number;
    id: number;
    costtotal: number;
    remainpercent: number;
    totalall: number;
    sum: number;
    percentage: number;
    totalpercentage: number;
    totalpercentage1: number;
    costhesdpercentage: number;
    cngPercentage: number;
    ngOnInit(): void {
        this.total = 0;
        this.remainvalue = 0;
        this.remainpercent = 0;
        this.model.userid = Number(localStorage.getItem("userid"));
        this.totalpercentage = Number(localStorage.getItem("totalpercentage"));
        this.remainpercent = 100 - this.totalpercentage;
        this.getmrktcosthead();
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            this.model.marketingcostid = this.id
            this.transactionService.getmcostSubhead(this.model)
                .subscribe((value) => {
                    this.model = value
                    this.costhesdpercentage = this.model.percentage
                    this.modellst = value.modellst
                    for (let i = 0; i < this.modellst.length; i++) {
                        if (this.modellst[i].percentallocate != null) {
                            this.modellst[i].checkselect = true;
                        }
                    }
                });
        }
    }
    getmrktcosthead() {
        let user = new User();
        this.Masterservice.getmrktcosthead(user)
            .subscribe((value) => {
                this.mrktcostlst = value.mrklst
            });
    }

    onSelectmrktcost(value) {
        this.costtotal = 0;
        if (value == 0) {
            this.notifyService.showRroor("Select Marketing Cost head !!", "Oops!");
            return;
        }
        this.model.percentage = this.percentage;
        this.modellst = null;
        // this.remainpercent = 0;
        this.model.checkselect = false
        this.model.marketingcostid = value;
    }
    btnAdd() {
        if (this.model.marketingcostid == null) {
            this.notifyService.showRroor("Please Add List !!", "Oops!");
            return;
        }
        this.modellst = [];

        this.model.percentage = this.model.percentage;
        this.transactionService.getmcostSubhead(this.model)
            .subscribe((value) => {
                this.model = value
                this.modellst = value.modellst
                if (this.totalpercentage >= 100) {
                    this.model.percentage = null;
                    this.notifyService.showRroor("Percentage Allocation is Not greater than 100% !!", "Oops!");
                    return;
                }
                for (let i = 0; i < this.modellst.length; i++) {
                    if (this.modellst[i].percentallocate != null) {
                        this.modellst[i].checkselect = true;
                        // this.total = Number(this.total) + Number(this.modellst[i].percentallocate);
                        // this.remainvalue = this.model.percentage - this.total;
                        // this.remainpercent = this.remainvalue;
                    }
                }
            });
    }
    onChangeOfpercent(val) {
        this.total = 0;
        this.remainvalue = 0;
        this.cngPercentage = 0
        this.totalpercentage1 = 0;
        this.cngPercentage = val
        if (this.id != null && this.id > 0) {
            if (this.cngPercentage == 0 || this.cngPercentage == null) {
                this.remainpercent = 0;
                this.totalpercentage1 = this.totalpercentage - this.costhesdpercentage;
                this.remainpercent = 100 - this.totalpercentage1;
            }
        }
        if (this.cngPercentage > this.remainpercent || this.cngPercentage > 100) {
            this.model.percentage = null;
            this.notifyService.showRroor("Percentage Allocation is Not greater than 100% !!", "Oops!");
            return;
        }

    }

    onChangeOfpercentage(index) {
        this.total = 0;
        this.remainvalue = 0;
        this.remainpercent = 0;
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].percentallocate != null) {
                this.modellst[i].checkselect = true;
                this.total = Number(this.total) + Number(this.modellst[i].percentallocate);
                if(this.total>100){
                    this.modellst[index].percentallocate = 0
                    this.notifyService.showRroor("Percentage Allocation is Not greater than 100% !!", "Oops!");
                    return;
                }
                
            }
        }
        if (!this.modellst[index].checkselect) {
            this.modellst[index].percentallocate = 0;
        }
    }

    onSelectCheckselect(index, $event) {

        if (!$event.target.checkselect) {
            this.modellst[index].checkselect = false;
            this.modellst[index].percentallocate = 0;
        }
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].checkselect) {

            }
        }
    }

    btnsave() {

        if (this.modellst == null || this.modellst.length <= 0) {
            this.notifyService.showRroor("Please select at least one Marketing cost head", "Oops!");
            return;
        }
        if (this.model.percentage == null || this.model.percentage == "") {
            this.notifyService.showRroor("Please Enter percentage", "Oops!");
            return;
        }

        if (this.model.percentage > 100) {
            this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
            return;
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.modellst = this.modellst
        this.Marketingservice.savecostheadtemplate(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit()
                        this.router.navigate(["/Marketing Cost Head % Allocation Template"]);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    cancle() {
        this.router.navigate(["/Marketing Cost Head % Allocation Template"]);
    }
}