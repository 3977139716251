import { Component, OnInit, Inject, forwardRef, OnDestroy } from '@angular/core';
import { MasterModel } from '../../_models/MasterModel';
import { EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router/';
import { BehaviorSubject } from 'rxjs';
import { AlertService, AuthenticationService, MasterServices } from '../../_services';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { User } from '../../_models/user';
import { DropDownModel } from '../../_models/DropDownModel';
import { gstelement, GSTgroupmastermodel } from './GSTgroupmastermodel';
import { NotificationService } from 'src/app/_services/notification.service';
declare var $: any;
@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'GSTgroupmaster.html'
})
export class GSTgroupmaster implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private Masterservice: MasterServices,
        private alertService: AlertService,
        private notifyService: NotificationService) { }
    str: string;
    id: number;
    p: any;
    public i: any = 0;
    public userid: any = 0;
    model = new GSTgroupmastermodel();
    user = new User();
    pagename: string;
    percentage: number;
    total: number;
    remainvalue: number;
    lsttax: gstelement[] = [];
    lsttax2: gstelement[] = [];
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    private sub: any;
    ngOnInit() {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.route.queryParams.subscribe(params => {
            this.user.id = params["id"],
            this.model.gsthrname = params["grp"]
            this.str = params["grp"];
            this.percentage = params["perc"];
        });
        this.model.id = this.user.id
        this.Masterservice.getgstdetails(this.model)
            .subscribe(
                data => {
                    this.model = data
                    this.model.lsttax = data.lsttax
                    this.lsttax = data.lsttax;
                    this.lsttax2 = data.lsttax2
                    this.model.gsthrname = this.str;
                    this.model.id = this.id
                },
                error => {
                    this.alertService.error(error);
                });
        let l1 = (<HTMLInputElement>document.getElementById('lable1'));
        l1.className = "active";

    }

    cancle() {
        this.router.navigate(['Tax Group']);
    }

    onchkinstate($event) {
    }

    onchkoutstate($event) {
    }

    onChangePercentage() {
        this.total = 0;
        this.remainvalue = 0;
        this.remainvalue = this.percentage
        for (let i = 0; i < this.lsttax.length; i++) {
            if (this.lsttax[i].cheselect == true) {
                this.total = this.total + this.lsttax[i].persentage;
                this.remainvalue = this.percentage - this.total;
                if (this.remainvalue < 0) {
                    this.lsttax[i].persentage = 0
                }
            }
        }
    }

    onChangePercentage2() {
        this.total = 0;
        this.remainvalue = 0;
        this.remainvalue = this.percentage
        for (let i = 0; i < this.lsttax2.length; i++) {
            if (this.lsttax2[i].cheselect == true) {
                this.total = this.total + this.lsttax2[i].persentage;
                this.remainvalue = this.percentage - this.total;
                if (this.remainvalue < 0) {
                    this.lsttax2[i].persentage = 0
                }
            }
        }
    }

    btnsave() {
        this.model.userid = Number(localStorage.getItem('userid'));
        if (this.model.displayname == null || this.model.displayname == "") {
            this.notifyService.showRroor("Please Enter Name !!", " Oops!");
            return;
        }

        for (let i = 0; i < this.model.lsttax.length; i++) {
            if (this.model.lsttax[i].cheselect == true) {
                if (this.model.lsttax[i].persentage == null) {
                    this.notifyService.showRroor("Please Enter Persentage of Selected Field !!", " Oops!");
                    return;
                }
            }
        }
        this.model.lsttax = this.lsttax;
        this.model.lsttax2 = this.lsttax2;
        this.model.id=this.user.id;
        this.Masterservice.savegstdetails(this.model)
            .subscribe(
                data => {
                    if (data.status == "No") {
                        alert(data.message);
                    }
                    else {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess("Data Saved successfully !!", " Success");
                            this.router.navigate(['Tax Group']);
                        }
                    }
                },
                error => {
                    this.alertService.error(error);
                });

    }
}

