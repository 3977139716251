import { DropDownModel } from 'src/app/_models/DropDownModel';

export class GoodsNoteDefineModel {
    id: number;
    userid:number
    chkverbal: boolean
    poid: number;
    pono: string;
    podate: Date;
    invoiceno: string;
    invodate: Date;
    supplierid: number;
    supplierName: string;
    localtionid: number;
    locationname: string;

    itemcatid: number;
    itemcatName: string;
    itemsubcatid: number;
    itemsubcatname: string;
    itemid: number;
    itemname: string;
    inventoryLocations: DropDownModel[] = [];
    rejectResonslst : DropDownModel[] = [];
    products: ProductList[] = [];

    grandtotal:number;
    grnno: string;
    grndate:Date;
    balanceqnty: number;
}
export class ProductList {
    srno: number
    id: number;
    itemid: number;
    itemcode: string;
    itemname: string;
    unitid: number;
    unit: string;
    podtlid: number;
    poqnty: number;
    invoiceqnty: number;
    recvqnty: number;
    approvqnty: number;
    rejectqnty:number;
    rejectreason:string;
    gstgrpid: number;
    gstperc: number;
    rate: number;
    gstamnt: number;
    netamnt: number;
    totalamnt: number;
    balanceqnty: number;
}
export class PoModel {
    id: number;
    pono: string;
    podate: Date;
}