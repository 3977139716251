export class SearchMarketingModel{
id: number;
code: string;
name: string;
costhead:string;
txtserch:string;
txtselect:string;
uomid:number;
uomname:string;
subheadvalue:number;
totalsubheadvalue:number;
balance:number;
qty:number;
chkselectvalue:boolean

}