<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Vendor</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow" style="padding-left: 5px;">
            <div class="row col l12 m12 s12">
                <div class=row style="margin-top: 2px;" style="margin-top:10px;">
                    <div class="input-field col l4 m4 s4">
                        <input [(ngModel)]="dmodel.code" #code="ngModel" type="text" class="" id="code"
                            data-toggle="tooltip" data-placement="top" title="Vendor Code" style="margin-top:1px;">
                        <label for="code" class="active">Vendor Code</label>
                    </div>
                    <div class="col l1"></div>
                    <div class="input-field col l6 m6 s6">
                        <input [(ngModel)]="dmodel.name" #name="ngModel" type="text" class="" id="name"
                            data-toggle="tooltip" data-placement="top" title="Vendor Name" style="margin-top:1px;">
                        <label for="name" class="active">Vendor Name</label>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class=row>
                    <div class="input-field col l11 m11 s11" style="margin-top:1px;margin-bottom: 0px;">
                        <input [(ngModel)]="dmodel.address" #address="ngModel" type="text" class="" id="address"
                            data-toggle="tooltip" data-placement="top" title="Address" style="margin-top:1px;">
                        <label for="address" class="active">Address</label>
                    </div>
                    <!-- <div class="col l2"></div> -->
                </div>
                <div class="row">
                    <div class="ng-autocomplete pinRowgrp" class=" input-field col s4" style="margin-bottom: 0px;margin-top: 17px;" >
                        <ng-autocomplete [data]="pincodelist" type="text" [(ngModel)]="dmodel.pincode" placeholder="Enter the pincode"
                          [searchKeyword]="keyword" [minQueryLength]="3"
                          (inputChanged)='onChangeSearch($event)'  (selected)='selectEvent($event)'  (inputFocused)='onFocused($event)'
                          [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>
      
                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.name"></a>
                        </ng-template>
      
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>

                    <div class=" input-field col s4" style="margin-top: 0%;margin-bottom: 0px;">
                        <span>Locality</span>
                        <select (change)=" onSelectlocality1($event.target.value)">
                            <option *ngFor="let type of lstlocalitys" [value]="type.id"
                                [selected]="type.id==dmodel.localityID">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;" style="margin-top: 0%;">

                    <div class=" input-field col s3" style=" margin-top: 15px;">
                        <span>City:-</span>&nbsp;<span><b>{{dmodel.city}}</b></span>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 15px;">
                        <span>State:-</span>&nbsp;<span><b>{{dmodel.state}}</b></span>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 15px;">
                        <span>Country:-</span>&nbsp;<span><b>{{dmodel.country}}</b></span>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col l12 s12 m12"> -->
                    <div class=" input-field col s4" style=" margin-top: 15px;">
                        <input [(ngModel)]="dmodel.landline" #landline="ngModel" min="0"
                            oninput="validity.valid||(value='');" pattern="[6789][0-9]{9}" type="number" id="phone"
                            title="Landline" maxlength="15">
                        <label id="landline" for="landline">Landline</label>
                    </div>
                    <div class=" input-field col s4" style=" margin-top: 15px;">
                        <input [(ngModel)]="dmodel.email" #email="ngModel" id="Email " type="text" data-toggle="tooltip"
                            data-placement="top" title="Email" ngModel maxlength="35"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                        <label id="email" for="first1">Email </label>
                        <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="email.errors.required">
                                Email Required
                            </div>
                            <div [hidden]="!tcemail.errors?.pattern">
                                Invalid Email-Id
                            </div>
                        </div>
                    </div>
                    <div class=" input-field col s4" style="margin-top: 15px;">
                        <input [(ngModel)]="dmodel.website" #website="ngModel" id="website" type="text"
                            data-toggle="tooltip" data-placement="top" title="Website" required>
                        <label id="website" for="first10" class="active">Website</label>
                    </div>
                    <!-- </div> -->
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="text-align: end;margin-bottom: 12px;padding-right: 0px;">
                        <button type="button" (click)="btnAddContact(contactmodel)"
                            class="waves-effect waves-light btn">Add
                            Contact</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="padding-left: 4px;padding-right: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="5%" style="text-align: left">Select</th>
                                <th width="30%" style="text-align: left">Name</th>
                                <th width="30%" style="text-align: left">Designation</th>
                                <th width="10%" style="text-align: left">Mobile No.</th>
                                <th width="25%" style="text-align: left">Email</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of contactlst1 ; let i = index">
                                    <td style="text-align: left"><input type="checkbox" [(ngModel)]="lst.chkcontact"
                                            #chkcontact="ngModel"></td>
                                    <td style="text-align: left">{{lst.contactName}}</td>
                                    <td style="text-align: left">{{lst.designation}}</td>
                                    <td style="text-align: left">{{lst.mobileNo1}}</td>
                                    <td style="text-align: left">{{lst.email}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class=row>
                    <div class="input-field col l12" style="margin-top: 5px;">
                        <input [(ngModel)]="dmodel.chkgst" #chkgst="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;GSTIN
                    </div>
                </div>
                <div class="row" *ngIf=" dmodel.chkgst">
                    <div class=" input-field col s2">
                        <input [(ngModel)]="dmodel.gstNo" #gstNo="ngModel" id="gstNo" type="text" min="0"
                            data-toggle="tooltip" data-placement="top" title="GST No" ngModel maxlength="35" required>
                        <label id="lable12" for="first12" class="active">GST No </label>
                    </div>
                    <!-- <div class=" col s1" *ngIf="this.dmodel.gstNo" style="margin-top: 30px;">
                        <label><input name="grup2" type="radio" (click)="onSelectIGST($event.target.value)"
                                [checked]="dmodel.igst">
                            <span style="font-size: 15px; font-weight: bold">IGST</span></label>
                    </div>
                    <div class=" col s1" *ngIf="this.dmodel.gstNo" style="margin-top: 30px;">
                        <label><input name="grup2" type="radio" (click)="onSelectSGST($event.target.value)"
                                [checked]="dmodel.sgst">
                            <span style=" font-size: 15px;font-weight: bold">SGST</span></label>
                    </div>
                    <div class=" col s2" *ngIf="this.dmodel.gstNo" style="margin-top: 30px;">
                        <label><input name="grup2" type="radio" (click)="onSelectUTGST($event.target.value)"
                                [checked]="dmodel.utgst">
                            <span style=" font-size: 15px;font-weight: bold">UTGST</span></label>
                    </div> -->
                    <div class="col l2 m2 s2" style="margin-top: 10px;">
                        <span><b>GST Type</b></span>&nbsp;&nbsp;
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkwihitins"
                                (click)="onchkwihitins()" />
                            <span style="font-size: 104%">Within State</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 28px;">
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkoutsides"
                                (click)="onchkoutsides()" />
                            <span style="font-size: 104%">Outside State</span>
                        </label>
                    </div>

                    <div class=" input-field col s2">
                        <input [(ngModel)]="dmodel.panNo" #panNo="ngModel" id="panNo" type="text" min="0"
                            data-toggle="tooltip" data-placement="top" title="PAN No" ngModel maxlength="35" required>
                        <label id="lable13" for="first13" class="active">PAN No </label>
                    </div>
                    <div class=" input-field col s2">
                        <!-- *ngIf="model.paymentTerm=='Against Order'" -->
                        <input [(ngModel)]="dmodel.creditDays" #creditDays="ngModel" id="creditdays" type="text" min="0"
                            data-toggle="tooltip" data-placement="top" title="Credit Days" ngModel maxlength="35"
                            required>
                        <label id="lable15" for="first15" class="active">Credit Days </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12" style="padding-right: 0px;">
                        <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                                style="margin-left: 5px;">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contactmodel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="registerBgShadow">
        <div class="modal-content " style="text-align: center;">
            <span style="font-size: 20px;">Add Contact</span><br>
        </div>
        <div class="row ">
            <div class=" input-field col s12 m12 l12" style=" margin-top: 0px;">
                <div class="col l1 m1 s1"></div>
                <div class=" input-field col s5 m5 l5" style=" margin-top: 0px;">
                    <input [(ngModel)]="contactmo.contactName" #contactName="ngModel" type="text" id="contactName"
                        title="Name">
                    <label for="contactName">Name</label>
                </div>
                <div class=" input-field col s5 m5 l5" style=" margin-top: 0px;">
                    <select (change)="onSelectdesiganation($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Designation">
                        <option selected disabled>Select Designation</option>
                        <option *ngFor="let type of designationlst" [value]="type.id"
                            [selected]="type.id==contactmo.designationID">
                            {{type.name}} </option>
                    </select>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>
            <div class=" input-field col s12" style=" margin-top: 0px;">
                <div class="col l1 m1 s1"></div>

                <div class=" input-field col s5 m5 l5" style=" margin-top: 0px;margin-bottom: 0px;">
                    <input [(ngModel)]="contactmo.mobileNo1" #mobileNo1="ngModel" type="text" id="contactmobile"
                        title="Mobile" pattern="[6789][0-9]{9}">
                    <label for="contactmobile">Mobile</label>
                    <div *ngIf="mobileNo1.errors &&(mobileNo1.touched || mobileNo1.dirty)" class="aler alert-danger"
                        style="padding: 10px; color: red">
                        <div [hidden]="!mobileNo1.errors?.pattern">
                            Invalid mobile Number
                        </div>
                    </div>
                </div>

                <!-- <div class=" input-field col s2" *ngIf="edit">
                    <input [(ngModel)]="model.mobile2" #mobile2="ngModel" pattern="[6789][0-9]{9}"   maxlength="10" type="text"
                        id="mobile2" title="Mobile2">
                    <label id="lable4" for="mobile2">Mobile2</label>
                    <div *ngIf="mobile2.errors &&(mobile2.touched || mobile2.dirty)" class="aler alert-danger"
                        style="padding: 10px; color: red">
                        <div [hidden]="!mobile2.errors?.pattern">
                            Invalid mobile Number
                        </div>
                    </div>
                </div> -->


                <div class=" input-field col s5 m5 l5" style=" margin-top: 0px;margin-bottom: 0px;">
                    <input [(ngModel)]="contactmo.email" #email="ngModel" id="contactemail" type="text"
                        data-toggle="tooltip" data-placement="top" title="Email" ngModel maxlength="35"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                    <label id="email" for="contactemail">Email</label>
                    <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                        style="color: red">
                        <div *ngIf="email.errors.required">
                            Email Required
                        </div>
                        <div [hidden]="!tcemail.errors?.pattern">
                            Invalid Email-Id
                        </div>
                    </div>
                </div>
                <div class="col l1 m1 s1"></div>

            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button (click)="modalRef.hide()" type="button"
                        class="waves-ripple cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnaddcontact()" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Add</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>