import { common } from './Common';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpModule } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { User } from '../_models/index';
//import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MasterModel } from '../_models/MasterModel';
import { Observable } from 'rxjs';
import { SaveSuccessModel } from '../_models/SaveSucessModel';
import { MarketingProposalModel } from '../Transaction-Marketing/Marketing/MarketingProposalModel';
import { MarketingProposalHomeModel } from '../Transaction-Marketing/Marketing/MarketingProposalHomeModel';
import { DropDownModel } from '../_models/DropDownModel';
import { CostheadModel, DefineMarketingBudgetModel } from '../Transaction-Marketing/MarketingBudget/DefineMarketingBudgetModel';
import { mrkbudgetdealerallocationModel } from '../Transaction-Marketing/MarketingBudgetDealerAllocation/mrkbudgetdealerallocationModel';
import { SearchMCHallocaTemplateModel } from '../Transaction-Marketing/MarketingCostHeadPercentageAllocaTemplate/SearchMCHallocaTemplateModel';
import { CityMasterModel } from '../Master/CityMaster/CityMasterModel';



@Injectable()
export class MarketingServices {


  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  getCollectionofLibrary: any;
  //private headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {

  }

  getMarketingProposalsByUser(user: User): Observable<MarketingProposalHomeModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<MarketingProposalHomeModel[]>(this.customersUrl + 'api/marketing/getMarketingProposalsByUser', user, httpOptions1);
  }

  getEventType(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/marketing/getEventType', user, httpOptions1);
  }

  saveMarketingProposal(model: MarketingProposalModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/saveMarketingProposal', model, httpOptions1);
  }

  getMarketingProposalbyId(user: User): Observable<MarketingProposalModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<MarketingProposalModel>(this.customersUrl + 'api/marketing/getMarketingProposalbyId', user, httpOptions1);
  }
  getallcostheads(user: CostheadModel): Observable<CostheadModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CostheadModel>(this.customersUrl + 'api/marketing/getallcostheads', user, httpOptions1);
  }
  savemrkbudget(model: DefineMarketingBudgetModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/savemrkbudget', model, httpOptions1);
  }
  getmrkbuddealerallocation(model: mrkbudgetdealerallocationModel): Observable<mrkbudgetdealerallocationModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<mrkbudgetdealerallocationModel>(this.customersUrl + 'api/marketing/getmrkbuddealerallocation', model, httpOptions1);
  }
  savemrkbudgetDealer(model: mrkbudgetdealerallocationModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),

        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/savemrkbudgetDealer', model, httpOptions1);
  }
  savemrktingBudgetTemplate(usr: SearchMCHallocaTemplateModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/savemrktingBudgetTemplate', usr, httpOptions1);
  }
  savecostheadtemplate(usr: SearchMCHallocaTemplateModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/savecostheadtemplate', usr, httpOptions1);
  }

  approveMarketingProposal(model: MarketingProposalModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/approveMarketingProposal', model, httpOptions1);
  }
  deleteexpense(user: MarketingProposalModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/deleteexpense', user, httpOptions1);
  }

  getAllCostHeadPercentage(model: User): Observable<SearchMCHallocaTemplateModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchMCHallocaTemplateModel[]>(this.customersUrl + 'api/marketing/getAllCostHeadPercentage', model, httpOptions1);
  }
  deletemarketingproposal(model: MarketingProposalHomeModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/deletemarketingproposal', model, httpOptions1);
  }
  btndeleteforapproveExpense(user: MarketingProposalModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/marketing/btndeleteforapproveExpense', user, httpOptions1);
  }
  getAllCitiesForProposal(user: User): Observable<CityMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CityMasterModel[]>(this.customersUrl + 'api/marketing/getAllCitiesForProposal', user, httpOptions1);
  }

}