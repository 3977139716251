<app-header></app-header>
<script>
    function myFunction(x) {
        unhighlight()
        x.style.backgroundColor = '#cccccc';
    }
</script>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="col s12 l12 m12">
                <div class="tabtab">
                    <button class="tablinks" id="defaulttab" onclick="openCity(event, 'Details')">Company
                        Details</button>
                    <button class="tablinks" onclick="openCity(event, 'Configaration')">Configuration</button>
                </div>
            </div>
            <div id="Details" class="tabcontent col l12 m12 s12 registerBgShadow" style="margin-bottom: 9px;">
                <div class="row">
                    <div class="col s1"></div>
                    <div class=" input-field col s3" style=" margin-top: 30px;margin-bottom: 5px;">
                        <input [(ngModel)]="model.companyname" #companyname="ngModel" type="text" id="Name"
                            data-toggle="tooltip" data-placement="top" title="Name"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" ngModel maxlength="35" required>
                        <label id="lable1" for="first1">Company Name </label>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 30px;margin-bottom: 5px;">
                        <input [(ngModel)]="model.companycode" #companycode="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" type="text" id="code" title="Code"
                            maxlength="35">
                        <label id="lable2" for="first1">Company Code </label>
                    </div>
                    <div class=" input-field col s4" style=" margin-top: 30px;margin-bottom: 5px;">
                        <textarea id="textarea1" title=" Address" class="materialize-textarea"
                            [(ngModel)]="model.address" #address="ngModel" id="Address"></textarea>
                        <label id="lable3" for="textarea1">Company Address</label>
                    </div>
                </div>


                <div class="row">
                    <div class="col s1"></div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.city" #city="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" id="city" type="text"
                            data-toggle="tooltip" data-placement="top" title="City" ngModel maxlength="35" required>
                        <label id="lable4" for="first1">City </label>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.pincode" #pincode="ngModel" id="PinCode" type="text" min="0"
                            data-toggle="tooltip" data-placement="top" title="Pincode" ngModel maxlength="35" required>
                        <label id="lable5" for="first1">PinCode </label>
                    </div>
                    <div class=" input-field col s4" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.state" #state="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" id="state" type="text"
                            data-toggle="tooltip" data-placement="top" title="State" ngModel maxlength="35" required>
                        <label id="lable6" for="first1">State </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col s1"></div>
                    <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.country" #country="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" id="country" type="text"
                            data-toggle="tooltip" data-placement="top" title="Country" ngModel maxlength="35" required>
                        <label id="lable7" for="first1">Country </label>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.telephone" #telephone="ngModel" id="telephone" type="text" min="0"
                            data-toggle="tooltip" data-placement="top" title="Telephone" ngModel maxlength="35"
                            required>
                        <label id="lable8" for="first1">Telephone </label>
                    </div>
                    <div class=" input-field col s4" style=" margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.companywebsite" #companywebsite="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" id="companywebsite" type="text"
                            data-toggle="tooltip" data-placement="top" title="Company Website" ngModel maxlength="35"
                            required>
                        <label id="lable9" for="first1">Company Website</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col s1"></div>
                    <div class=" input-field col s4" style=" margin-top: 17px;">
                        <input [(ngModel)]="model.gstnumber" #gstnumber="ngModel" id="gstnumber" type="text"
                            data-toggle="tooltip" data-placement="top" title="GST Number" min="0" ngModel maxlength="35" required>
                        <label id="lable10" for="first1" >GST Number</label>
                    </div>
                    <div class="input-field col s4" style=" margin-top: 0px;margin-bottom: 0px;">
                        <span>Default Currency</span>
                        <select (change)="onSelectdefaultcurrency($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Default Currency">
                            <option selected value="0"> Select Default Currency </option>
                            <option *ngFor="let lst of defautcurrencylst" [value]="lst.id"
                                [selected]="lst.name==model.defaultcurrencyid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <!-- <div class="input-field col s4" style=" margin-top: 20px;">
                        <select (change)="onselectinstalationapproval($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Instalation Approval">
                            <option disabled> Installation Approval </option>
                            <option *ngFor="let lst of instalationaprovlst" [value]="lst.name"
                                [selected]="lst.name==model.installationapproval">
                                {{lst.name}}
                            </option>
                        </select>
                    </div> -->
                </div>


                <div class="row" style="margin-top:0px;">
                    <div class="col s1"></div>
                    <div class=" input-field col s5" style="margin:0px;">
                        <div class="file-field input-field" style="margin:0px;">
                            <div class="btn" style="margin-top:18px;">
                                <span>Choose File</span>
                                <input type="file" title="Choose File">
                            </div>
                            <div class="file-path-wrapper">
                                <span> Company Logo</span>
                                <input class="file-path validate" type="text">
                            </div>
                        </div>
                    </div>

                    <!-- <div class=" input-field col s4">
                        <input [(ngModel)]="model.state" #state="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" id="state" type="text"
                            data-toggle="tooltip" data-placement="top" title="state" ngModel maxlength="35" required>
                        <label id="lable4" for="first1">Company Logo </label>
                    </div> -->

                    <div class=" input-field col s5" style="margin:0px;">
                        <div class="file-field input-field" style="margin:0px;">
                           
                            <div class="btn" style="margin-top:18px;">
                                <span>Choose File</span>
                                <input type="file" title="Choose File">
                            </div>
                            <div class="file-path-wrapper">
                                <span> Report First Page Logo</span>
                                <input class="file-path validate" type="text">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col s1"></div>
                    <div class=" input-field col s2">
                        <input [(ngModel)]="model.percentage" #percentage="ngModel" id="state" type="text"
                            data-toggle="tooltip" data-placement="top" title="Percentage" ngModel maxlength="35" required>
                        <label id="lable11" for="first1">Percentage </label>
                    </div>
                    <div class=" input-field col s3">
                        <input [(ngModel)]="model.nodallowedbeforeservicecall" #nodallowedbeforeservicecall="ngModel"
                            id="state" type="text" data-toggle="tooltip" data-placement="top" title="No.Of Days Before Service Call" ngModel
                            maxlength="35" required>
                        <label id="lable12" for="first1">Service DCR lock days</label>
                    </div>
                    <div class=" input-field col s3">
                        <input [(ngModel)]="model.nodallwedbeforedcr" #nodallwedbeforedcr="ngModel" id="state"
                            type="text" data-toggle="tooltip" data-placement="top" title="No.Of Days Allowed Before DCR" ngModel maxlength="35"
                            required>
                        <label id="lable13" for="first1">Sales DCR lock days</label>
                    </div>
                    <div class=" input-field col s2">
                        <input [(ngModel)]="model.noofdaysescalate" #noofdaysescalate="ngModel" id="state" type="text"
                            data-toggle="tooltip" data-placement="top" title="No Of Days Escalate" ngModel maxlength="35" required>
                        <label id="lable14" for="first1">No Of Days Escalate</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col s1"></div>
                    <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.bucketname" #bucketname="ngModel" id="state" type="text"
                            data-toggle="tooltip" data-placement="top" title="Bucket Name" ngModel maxlength="35" required>
                        <label id="lable15" for="first1">Bucket Name </label>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.amazons3key" #amazons3key="ngModel" id="state" type="text"
                            data-toggle="tooltip" data-placement="top" title="Amazon S3 Key" ngModel maxlength="35" required>
                        <label id="lable16" for="first1">Amazon S3 Key</label>
                    </div>
                    <div class=" input-field col s4" style=" margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.amazonserect3key" #amazonserect3key="ngModel" id="state" type="text"
                            data-toggle="tooltip" data-placement="top" title="Amazon Secret Key" ngModel maxlength="35" required>
                        <label id="lable17" for="first1">Amazon Secret Key </label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l11 m11 s11" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                            (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="savecomp()" class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>

            <div id="Configaration" class="tabcontent col l12 m12 s12 registerBgShadow" style="margin-bottom: 9px;">
                <!-- <div *ngFor="let lst of complst > -->
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col  s2">
                        </div>
                        <div class="col l8 m8 s8" style="padding-top: 9px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="10%" style="text-align: left;padding-left: 10px;">Flag Name</th>
                                    <th width="3%" style="text-align: center">Type</th>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of complst | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.moduleflagName}}</td>
                                        <td style="text-align: right;padding-right: 10px;">
                                            <input type="checkbox" *ngIf="lst.type=='Boolean'"
                                                [(ngModel)]="lst.chkselect" #chkselect="ngModel" style="text-align: right;" >
                                            <input [(ngModel)]="lst.customervalue" #customervalue="ngModel"
                                                *ngIf="lst.type=='Integer' || lst.type=='Character'" type="text"
                                                id="customervalue" min="0"  data-toggle="tooltip" data-placement="top"
                                                placeholder="Value" style="text-align: right;" >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                        <div class="col  s2">
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: center">
                     
                    <div class=" input-field col s3" style="margin-top:3%">
                        <input type="checkbox" [(ngModel)]="model.fortnightly" (change)="onChangefort()"
                            #checkselect="ngModel">&nbsp;&nbsp;
                        Fortnightly
                    </div>
                    <div class=" input-field col s3" style="margin-top:3%">
                        <input type="checkbox" [(ngModel)]="model.monthly" #checkselect="ngModel"
                            (change)="onChangemonth()">&nbsp;&nbsp;
                        Monthly
                    </div>
                    <div class=" input-field col s3" style="margin-top:3%">
                        <input type="checkbox" [(ngModel)]="model.edittripdistance" #checkselect="ngModel">&nbsp;&nbsp;
                        Edit Trip Distance
                    </div>
                </div> -->
                <div class="row">
                    <div class="input-field col l10 m10 s10" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                            (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="savecomp()" class="waves-ripple waves-light btn" style="margin-right: 5px;">Save</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>