<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Installation</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="row">
                    <!-- <div class=" input-field col s3 l3 m3" style="margin-top: 10px">
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnnew()">New</button>
                </div> -->
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="margin-top: 25px; padding-left: 18px;padding-right: 18px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="10%" style="text-align: left">Sr. No.</th>
                                <th width="30%" style="text-align: left">Installation No.</th>
                                <th width="50%" style="text-align: left">Customer Name</th>
                                <!-- <th width="30" style="text-align: center">Status</th> -->
                                <th width="10%" style="text-align: left">Action</th>

                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of lstinstallation | paginate: { itemsPerPage: 10, currentPage: p }">
                                    <td style="text-align: left">{{lst.srno}}</td>
                                    <td style="text-align: left">{{lst.installtionnumber}}</td>
                                    <td style="text-align: left">{{lst.coustomername}}</td>
                                    <td style="text-align: left"><span><img src="../../../assets/images/DCR-Entry.png"
                                                (click)="btnedit(lst.installtionID)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px" /></span>&nbsp;&nbsp;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>