import { OnInit, Component } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { InventoryServices } from 'src/app/_services/InventoryServices';
import { User } from 'src/app/_models';
import { SerachPurchaseReturnModel } from './SearchPurchaseReturnModel';

@Component({
    selector: 'app-SearchPurchaseReturn',
    templateUrl: 'SearchPurchaseReturn.html',
})
export class SearchPurchaseReturn implements OnInit {

    pagename: string;
    searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Supplier" },
        { id: 3, "name": "Invoice Number" },
    ];

    user = new User();
    model = new SerachPurchaseReturnModel();
    purchasereturnlst: SerachPurchaseReturnModel[] = []

    constructor(private Masterservice: MasterServices,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private inventoryServ: InventoryServices) {
        this.pagename = this.router.url;
    }
    ngOnInit(): void {
        this.user.pagename = null;
        if (this.pagename != null) {
            this.user.pagename = this.pagename.replace('/', '');
            this.user.pagename = this.user.pagename.replace('/', '');
            this.user.pagename = this.user.pagename.replace('%20', ' ');
            this.user.pagename = this.user.pagename.replace('%20', ' ');
        }
        this.getAllReturns()
    }


    getAllReturns() {
        this.user.message = "Purchase Return Note";
        this.inventoryServ.getAllReturns(this.user)
            .subscribe(value => {
                this.purchasereturnlst = [];
                this.purchasereturnlst = value
            });
    }

    onSelect(val) {
        this.model.txtselect = val;
    }

    btnnew($event) {
        this.router.navigate(['newPurchseReturn'])
    }

    btnsearch($event) {

    }
}