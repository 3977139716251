import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Mainmenumodel } from '../_models/Mainmenumodel';

@Component({
    selector: 'app-home',
    //  moduleId: module.id,
    templateUrl: 'CRMMObileHome.html'
})
export class CRMMObileHome implements OnInit {

    pagename: string;
    hashkey: string;
    chkopen: boolean = false;
    mainmenus: Mainmenumodel[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router) {
    }
    ngOnInit(): void {
        
       this.mainmenus= JSON.parse(localStorage.getItem('menu'));
      
        // this.route.queryParams.subscribe(params => {
        //     this.pagename = params["url"];
        //     // this.hashkey = params["hashkey"];
        // });


        // if( this.pagename==null){
        //     this.router.navigate(["login"]);
        // }else{
        //     alert( this.pagename)
        //     // let navigationExtras: NavigationExtras = {
        //     //     queryParams: {
        //     //       "hashkey":this.hashkey ,
        //     //     }
        //     //   };
        //       this.router.navigate([this.pagename]);
        // }

        // this.pagename=router.url;

    }
    redirectUser(mainmenulabel: string) {
        this.router.navigate(['/' + mainmenulabel]);
    }
    // onchkopen() {
    //     alert("Menu")
    //     this.chkopen = !this.chkopen;
    // }


}