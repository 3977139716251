import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { StockadjustmentModel } from "src/app/Transactions/Stock Adjustment/StockadjustmentModel";
import { User } from "src/app/_models";
import { Location, } from '@angular/common';
import { DropDownModel } from "src/app/_models/DropDownModel";
import { AlertService, MasterServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { DefinePurchaseOrderModel } from "src/app/InventoryManagement/PurchaseOrder/DefinePurchaseOrderModel";
import { DefineMaterialRequisitionModel } from "./DefineMaterialRequisitionModel";
import { contains } from "jquery";
import { element } from "protractor";
declare var $: any;
@Component({
    selector: 'app-DefineMaterialRequisition',
    templateUrl: 'DefineMaterialRequisition.html',
})
export class DefineMaterialRequisition implements OnInit {
    model: any = {};
    user = new User();
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    requisitionlst: DefineMaterialRequisitionModel[] = [];
    stocklocationlst: DefineMaterialRequisitionModel[] = [];

    locationlst: DropDownModel[];
    tolocationlst: DropDownModel[];
    mrpmap = new Map();
    userList2: any;
    id: number;
    public i;
    itemname: string;
    itemcodemap = new Map();
    hsnCodemap = new Map();
    uommap = new Map();
    stockforsalemap = new Map();
    stocklocationlstmap = new Map();
    p: any;
    requisitionDate: Date;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }

    ngOnInit(): void {
        this.requisitionlst = [];
        this.getInventoryLocation();
        this.getitemCategory();
        this.getauthorizedBy();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.requisitionDate = new Date();
        //    this.requisitionDate = this.model.requisitionDate
    }

    getauthorizedBy() {

        this.model.userid = Number(localStorage.getItem('userid'));
        this.transactionService.getauthorizedBy(this.model)
            .subscribe(data => {
                this.model.requestor = data.requestor;

            });

    }
    getInventoryLocation() {
        this.locationlst = [];
        this.tolocationlst = [];
        this.transactionService.getInventoryLocation(this.model)
            .subscribe(data => {
                this.model = data;
                this.locationlst = data.locationLst
                // this.tolocationlst = data.locationLst
            });
    }
    onSelectInventoryLocationFrom(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id == value) {
                this.model.locationid = this.locationlst[i].id;
                this.model.locationname = this.locationlst[i].name;
                // this.model.tolocationname = this.locationlst[i].name;
            }
        }
        this.tolocationlst = [];
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id != this.model.locationid) {

                this.tolocationlst.push({
                    id: this.locationlst[i].id,
                    name: this.locationlst[i].name,
                });
                this.model.tolocationid = this.locationlst[i].id
                this.model.tolocationname = this.locationlst[i].name
            }

        }
    }
    onSelectInventoryLocationTo(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }

        this.model.tolocationid = value;

    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }

    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.itemsubcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.itemsubcategory = this.subCatlst[i].name
            }
        }
    }
    getItemLike($event) {

        let userId = (<HTMLInputElement>document.getElementById('itmname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.userid =  this.model.itemcategoryid;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        this.user.localid = this.model.locationid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLikePO(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({
                        "itemname": item.itemname,
                        "itemid": item.itemid,
                        "itemcode": item.itemcode,
                        "hsnCode": item.hsnCode,
                        "uom": item.uom,
                        "gstGrouppercentage": item.gstGrouppercentage,
                        "stockforsale": item.stockforsale,
                        "stocklocationlst": item.stocklocationlst
                    }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].itemname, this.userList2[i].itemid)
                        this.itemcodemap.set(this.userList2[i].itemname, this.userList2[i].itemcode)
                        this.uommap.set(this.userList2[i].itemname, this.userList2[i].uom)
                        this.stockforsalemap.set(this.userList2[i].itemname, this.userList2[i].stockforsale)
                        this.stocklocationlstmap.set(this.userList2[i].itemname, this.userList2[i].stocklocationlst)

                        searchDataObj[this.userList2[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList2[i].itemname;
                        } else {
                            names = names + "," + this.userList2[i].itemname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect1(itemid, itemname) {
        this.model.itemid = itemid;
        this.model.itemname = itemname;
        this.model.itemcode = this.itemcodemap.get(itemname);
        this.model.uom = this.uommap.get(itemname);
        this.model.stockforsale = this.stockforsalemap.get(itemname);
        this.model.stocklocationlst = this.stocklocationlstmap.get(itemname);
    }
    btnadd() {
        for (let i = 0; i < this.requisitionlst.length; i++) {
            if (this.requisitionlst[i].itemid == this.model.itemid) {
                this.notifyService.showRroor("Item already present", " Oops");
                return;
            }
        }
        if (this.model.itemcategoryid == null) {
            this.notifyService.showRroor("Please Select Itemcategory", " Oops");
            return;
        }
        if (this.model.itemsubcategoryid == null) {
            this.notifyService.showRroor("Please Select ItemSubcategory", " Oops");
            return;
        }
        if (this.model.itemname == null) {
            this.notifyService.showRroor("Please Select Itemname", " Oops");
            return;
        }

        this.requisitionlst.push({
            id: this.model.id,
            locationid: this.model.locationid,
            locationname: this.model.locationname,
            userid: this.model.userid,
            quantity: this.model.quantity,
            itemcategoryid: this.model.itemcategoryid,
            itemsubcategoryid: this.model.itemsubcategoryid,
            itemname: this.model.itemname,
            itemcategory: this.model.itemcategory,
            itemsubcategory: this.model.itemsubcategory,
            itemid: this.model.itemid,
            uom: this.model.uom,
            itemcode: this.model.itemcode,
            stockforsale: this.model.stockforsale,
            fromlocationname: "",
            tolocationname: "",
            locid: this.model.locid,
            requisitionDate: this.model.requisitionDate,
            requisitionId: "",
            requestor: this.model.requestor,
            requisitionQuantity: null,
            issuedquantity: null,
            tolocationid: this.model.tolocationid,
            stockforsalefloc: null,
            miid: this.model.miid,
            stocklocationlst: this.model.stocklocationlst,
            checkselect: false,
            balquantity:this.model.balquantity,
            tolocstock:this.model.tolocstock,
            fromlocstock:this.model.fromlocstock,

        })

    }
    btncancle() {
        this.router.navigate(["SearchMaterialRequisition"])
    }
    btnsave($event) {

        if (this.model.locationid == null) {
            this.notifyService.showRroor("Please Select the From Inventory Location !", "Oops !")
            return;
        }
        if (this.model.tolocationid == null) {
            this.notifyService.showRroor("Please Select the To Inventory Location !", "Oops !")
            return;
        }
        if (this.requisitionlst.length < 0) {
            this.notifyService.showRroor("List Is Null!", "Oops !")
            return;
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.locationlst = this.requisitionlst
        this.transactionService.savematerialrequisiotion(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(["SearchMaterialRequisition"]);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
    onChangeOfqantity(ind1, ind2) {
        for (let i = 0; i < this.requisitionlst.length; i++) {
            for (let i = 0; i < this.requisitionlst[i].stocklocationlst.length; i++) {
                if (this.requisitionlst[ind1].stocklocationlst[ind2].stockforsale < this.requisitionlst[ind1].stocklocationlst[ind2].quantity) {
                    this.requisitionlst[ind1].stocklocationlst[ind2].quantity = null;
                    this.notifyService.showRroor("Requisitioned quantity is not greater than available quantity!", "Oops !")
                    return;
                }
            }
        }
    }
    btndelete(id, index) {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.transactionService.deleterequisition(this.model)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                        this.requisitionlst.splice(index, 1);
                    }
                });
        }
    }
    onSelectCheckselect(index, $event, ind){

    }
}