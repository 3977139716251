<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row" style="padding-bottom: 10px;">
                <div class="col l1"></div>
                <div class="input-field col s3 m3 l3" style=" margin-top: 0px;">
                    <label><input name="grup2" type="radio" [checked]="model.sales" (click)="salesclick($event)">
                        <span>Sales</span></label>
                </div>
                <div class="input-field col s3 m3 l3" style=" margin-top: 0px;">
                    <label><input name="grup2" type="radio" [checked]="model.service" (click)="serviceclick($event)">
                        <span>Service</span></label>
                </div>
                <div class="input-field col s3 m3 l3" style=" margin-top: 0px;">
                    <label><input name="grup2" type="radio" [checked]="model.purchase" (click)="purchaseclick($event)">
                        <span>Purchase</span></label>
                </div>
            </div>


            <div class="row" style="padding-bottom: 10px;padding-top: 10px;">
                <div class="col l1"></div>
                <div class="input-field col s4 " *ngIf="model.sales">
                    <span> Customer type</span>
                    <select (change)="onselectCustomerType($event.target.value)">
                        <option selected value="0">Select Customer type</option>
                        <option *ngFor="let lst of custtypelst" [value]="lst.id"
                            [selected]="lst.id == model.customerTypeId">
                            {{lst.name}}</option>
                    </select>
                </div>
                <div class="col l2 m2 s2" *ngIf="model.sales" style="margin-top: 35px;">
                    <p><input type="checkbox" [(ngModel)]="model.chkall" id="chkall" #chkall="ngModel">&nbsp;&nbsp;
                        <label for="chkall">All Categories</label>
                    </p>
                </div>
                <div class="input-field col l4 m4 s4" *ngIf="model.sales">
                    <span>Category</span>
                    <select (change)="onSelectCategory($event.target.value)">
                        <option selected value="0">Select Category</option>
                        <option *ngFor="let type of categorylst" [value]="type.id"
                            [selected]="type.id == model.categoryID">
                            <!-- [selected]="type.id == model.categoryID" -->
                            {{type.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field  col s4" style="margin-top: 0px;">
                    <input [(ngModel)]="model.heading" #heading="ngModel" type="text" id="heading" data-toggle="tooltip"
                        data-placement="top" title="Heading">
                    <label id="lable1" for="First1">Heading</label>
                </div>
                <div class="input-field  col s6" style="margin-top: 0px;">
                    <!-- <input [(ngModel)]="model.descrption" #descrption="ngModel" type="text" id="descrption"
                        data-toggle="tooltip" data-placement="top" title="Description"> -->
                    <textarea [(ngModel)]="model.descrption" #descrption="ngModel" class="materialize-textarea"
                        type="text" id="descrption" data-toggle="tooltip" data-placement="top"
                        title="descrption"></textarea>

                    <label id="lable2" for="First2">Description</label>
                </div>
            </div>

            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                        (click)="btncancle()">Cancel</button>
                    <button type="button" (click)="savecateg()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>