import { OnInit, Component } from '@angular/core';
import { User } from 'src/app/_models';
import { SearchActivityModel } from './SearchActivityModel';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
@Component({
    selector: 'app-Activity',
    //  moduleId: module.id,
    templateUrl: 'SearchActivity.html'
})
export class SearchActivity implements OnInit {
    user = new User();
    p: any;
    public i;
    model = new SearchActivityModel();
    activitylst: SearchActivityModel[] = [];
    activitylst1: SearchActivityModel[] = [];
    pagename: string;

    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
        this.pagename=this.router.url
    }

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Activity Code" },
        { id: 3, "name": "Activity Name" },
        { id: 4, "name": "Access Type" },
        { id: 5, "name": "Call Type" }

    ];

    ngOnInit(): void {
        this.getAllActivities();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.user.userid = Number(localStorage.getItem("userid"));
        localStorage.setItem("pagename",this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }

    }

    getAllActivities() {
        this.activitylst = [];
        this.activitylst1 = [];
        this.Masterservice.getAllActivities(this.user)
            .subscribe(value => {
                value.forEach((item) => this.activitylst1.push({ "id": item.id, "activityCode": item.activityCode, "activityName": item.activityName, "txtserch": item.txtserch, "txtselect": item.txtselect, "sequence": item.sequence, "accesstype": item.accesstype, "callType": item.callType, "srno": item.srno, "callsubtype": item.callsubtype, "description": item.description, "userid": null, "actiontype":item.actiontype,"actiontypesaved":item.actiontypesaved, "issmscheck":item.issmscheck,"isemailcheck":item.isemailcheck,"smsid":item.smsid,"msgcode":item.msgcode,"messagelst":null,"cuscheckselect":item.cuscheckselect,"allcheckselect":item.allcheckselect,"customertypename":item.customertypename,"customerTypelst":null }));
                this.activitylst = this.activitylst1;
            });
    }

    onSelectAllowance(val) {
        this.model.txtselect = val;
    }
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllActivities()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Activity Code") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.activitylst1 = [];
                this.activitylst.forEach((item) => {
                    if ((item.activityCode.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.activitylst1.push({ "id": item.id, "activityCode": item.activityCode, "activityName": item.activityName, "txtserch": item.txtserch, "txtselect": item.txtselect, "sequence": item.sequence, "accesstype": item.accesstype,"actiontype": item.actiontype, "callType": item.callType, "srno": item.srno, "callsubtype": item.callsubtype, "description": item.description, "userid": null,"actiontypesaved":item.actiontypesaved,"issmscheck":item.issmscheck,"isemailcheck":item.isemailcheck,"smsid":item.smsid,"msgcode":item.msgcode ,"messagelst":null,"cuscheckselect":item.cuscheckselect,"allcheckselect":item.allcheckselect,"customertypename":item.customertypename,"customerTypelst":null});
                    }
                });
                this.activitylst = this.activitylst1;
            }
        }
        else if (this.model.txtselect == "Activity Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.activitylst1 = [];
                this.activitylst.forEach((item) => {
                    if ((item.activityName.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.activitylst1.push({ "id": item.id, "activityCode": item.activityCode, "activityName": item.activityName, "txtserch": item.txtserch, "txtselect": item.txtselect, "sequence": item.sequence, "accesstype": item.accesstype, "callType": item.callType, "actiontype": item.actiontype, "srno": item.srno, "callsubtype": item.callsubtype, "description": item.description, "userid": null,"actiontypesaved":item.actiontypesaved,"issmscheck":item.issmscheck,"isemailcheck":item.isemailcheck,"smsid":item.smsid,"msgcode":item.msgcode ,"messagelst":null,"cuscheckselect":item.cuscheckselect,"allcheckselect":item.allcheckselect,"customertypename":item.customertypename,"customerTypelst":null});
                    }
                });
                this.activitylst = this.activitylst1;
            }
        } else if (this.model.txtselect == "Access Type") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.activitylst1 = [];
                this.activitylst.forEach((item) => {
                    if ((item.accesstype.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.activitylst1.push({ "id": item.id, "activityCode": item.activityCode, "activityName": item.activityName, "txtserch": item.txtserch, "txtselect": item.txtselect, "sequence": item.sequence, "accesstype": item.accesstype, "callType": item.callType,"actiontype": item.actiontype,  "srno": item.srno, "callsubtype": item.callsubtype, "description": item.description, "userid": null ,"actiontypesaved":item.actiontypesaved,"issmscheck":item.issmscheck,"isemailcheck":item.isemailcheck,"smsid":item.smsid,"msgcode":item.msgcode ,"messagelst":null,"cuscheckselect":item.cuscheckselect,"allcheckselect":item.allcheckselect,"customertypename":item.customertypename,"customerTypelst":null});
                    }
                });
                this.activitylst = this.activitylst1;
            }
        }
        else if (this.model.txtselect == "Call Type") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.activitylst1 = [];
                this.activitylst.forEach((item) => {
                    if ((item.callType.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.activitylst1.push({ "id": item.id, "activityCode": item.activityCode, "activityName": item.activityName, "txtserch": item.txtserch, "txtselect": item.txtselect, "sequence": item.sequence, "accesstype": item.accesstype, "callType": item.callType, "actiontype": item.actiontype, "srno": item.srno, "callsubtype": item.callsubtype, "description": item.description, "userid": null,"actiontypesaved":item.actiontypesaved ,"issmscheck":item.issmscheck,"isemailcheck":item.isemailcheck,"smsid":item.smsid,"msgcode":item.msgcode ,"messagelst":null,"cuscheckselect":item.cuscheckselect,"allcheckselect":item.allcheckselect,"customertypename":item.customertypename,"customerTypelst":null});
                    }
                });
                this.activitylst = this.activitylst1;
            }
        }
    }

    btnnew() {
        this.router.navigate(["newActivity"]);
    }

    btnedit(val) {
        this.router.navigate(["editActivity", val]);
    }

    btndelts(val) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.user.trnid = val
            this.Masterservice.deleteActivity(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
    }
}