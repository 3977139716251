import { Products1 } from 'src/app/Transactions/ServiceCallTransaction/ServiceProductModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';

export class DefineProductModel {
    id: number;
    name: string;
    warrantyMonths:number;
    productCode:string;
    discount:number;
    hourFlag:string;
    defaultflag:string;
    description:string;
    productcategoryid:number;
    defaultCurrency:number;
    instacheckselect:boolean;
    sercheckselect:boolean;
    sparcheckselect:boolean;
    currencyName:string;
    createdate: Date;
    effctiveDate: Date;
    userid:number;
    checkselect:boolean;
    defcheckselect:boolean;
    minimumPrice:number;
    promodellst:DefineProductModel[]=[];
    procategorylst:DropDownModel[]=[];
    procategorylst1:Products1[]=[];
    proDiscountlst:DefineProductModel[]=[];
    deffalg:boolean;
    itemsubcategory:string;
    installation:boolean;
    installationStatus:string;
}