<div *ngIf="isMobile == true ">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol">
        <h1 class="header center">Installation DCR</h1>
        <div class="container ">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="srchBy p-0">
                        <div class="col s12 sectionSrch m-btm" style="margin-bottom: 0px;">
                            <label class="w-35 font-weight-bold">Customer:</label>
                            <label class="widthR-60">{{model.coustomername}}</label>
                            <label class="w-35 font-weight-bold">Customer Type:</label>
                            <label class="widthR-60">{{model.cusType}}</label>
                            <label class="w-35 font-weight-bold">Address:</label>
                            <label class="widthR-60">{{model.address}}</label>
                            <label class="w-35 font-weight-bold">Locality:</label>
                            <label class="widthR-60">{{model.locality}}</label>
                            <label class="w-35 font-weight-bold">Pincode:</label>
                            <label class="widthR-60">{{model.pincode}}</label>
                        </div>
                        <div class="col s12 m-btm">
                            <label class="w-100 dateBox">
                                <input type="text" [(ngModel)]="model.googlemap" #googlemaplocation="ngModel" class=""
                                    placeholder="Google Map Location" style="position: relative;">
                                <!-- <button type="button" class="btn grayButtonRes"><i class="fa fa-map-marker"
                                        aria-hidden="true"></i> Location</button> -->
                            </label>
                        </div>
                    </div>
                    <div class="col s12 colGrayNew">
                        <div class="left-align">
                            <label> <input type="checkbox" [(ngModel)]="model.chkall" #chkall="ngModel"
                                    (click)="btnselectAllProduct($event)"><b>Select All</b></label>
                        </div>
                        <div class="tabInnerBox grayBg" *ngFor="let lst of model.lstproduct">

                            <div class="introTab">
                                <label class="w-100 font-weight-bold blueTxt">Installation #</label>
                                <label class="w-100">{{lst.installtionnumber}}</label>

                                <label class="w-40 font-weight-bold blueTxt">Date</label>
                                <label class="widthR-60">{{lst.installtiondate | date :'dd-MM-yyyy'}}</label>

                                <label class="w-100 font-weight-bold blueTxt">Product Model</label>
                                <label class="w-100">{{lst.productname}}</label>

                                <!-- <label class="w-35 font-weight-bold blueTxt">Unit #</label>
                                <label class="widthR-60">--</label> -->

                                <label class="w-40 font-weight-bold blueTxt">Assigned To</label>
                                <label class="widthR-60">{{lst.assignedtoname}}</label>

                                <label class="widthR-60 font-weight-bold blueTxt">Warranty(In Months)</label>
                                <label class="w-40">
                                    <div class="quantity">
                                        <input [(ngModel)]="lst.warrantyMonth" #warrantyMonth="ngModel"
                                            (ngModelChange)="enteredMonth(lst.warrantyMonth,j)" type="number" min="1"
                                            oninput="validity.valid||(value='');" id="first4" data-toggle="tooltip"
                                            data-placement="top" style="text-align: left;" title="Warranty"
                                            [disabled]="lst.completeInst == true || lst.assignedtoID != userid" ngModel
                                            required>
                                    </div>
                                </label>

                                <label class="widthR-60 font-weight-bold blueTxt">Warranty End Date</label>
                                <label class="w-40 valignTxt">{{lst.warrantyenddate | date:'dd-MM-yyyy'}}</label>

                                <label class="widthR-60 font-weight-bold blueTxt">Completed</label>
                                <!-- <label class="w-40">add chkbox here</label> -->
                                <!-- <input type="checkbox" [(ngModel)]="model.chkall" #chkall="ngModel"
                                    (click)="btnselectAllProduct($event)"> -->
                                <label class="w-40"><input type="checkbox" (click)="onproductcomplet($event)"
                                        [(ngModel)]="lst.completflag" name="Select" style="text-align: 20px;"
                                        [disabled]="lst.completeInst == true || lst.assignedtoID != userid"></label>
                                <!-- <label class="widthR-60">Western India</label> -->
                            </div>
                        </div>

                    </div>
                    <div class="col s12 srchBy sectionSrch m-btm mt-top">
                        <label class="w-35 font-weight-bold valignTxt">DCR Date</label>
                        <label class="widthR-60">
                            <div class="input-field">
                                <!-- <i class="fa fa-calendar inpClndr"></i>
                                <input type="text" placeholder=""> -->
                                <!-- <input type="checkbox"
                                                (click)="onproductcomplet($event)" [(ngModel)]="lst.completflag"
                                                name="Select" style="text-align: 20px;" [disabled] = "lst.completeInst == true || lst.assignedtoID != userid" > -->
                                <input type="text" placeholder="DCR Date" [(ngModel)]="model.dcrdate" id="date"
                                    title="DCR Date" class="form-control" (ngModelChange)="selectDCRDate(model.dcrdate)"
                                    bsDatepicker
                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true}">

                            </div>
                        </label>
                        <label class="w-35 font-weight-bold valignTxt">DCR Start Time</label>
                        <label class="widthR-60">
                            <div class="input-field">
                                <!-- <i class="fa fa-clock-o" aria-hidden="true"></i>
                                <input type="text" placeholder=""> -->
                                <input id="timepicker_ampm_dark" class="timepicker" type="time"
                                    [(ngModel)]="model.dcrstarttime" #dcrstarttime="ngModel" id="dcrstarttime"
                                    value={{model.dcrstarttime}}>
                            </div>
                        </label>
                        <label class="w-35 font-weight-bold valignTxt">Activity</label>
                        <label class="widthR-60" style="margin-bottom: 0px;">
                            <div class="input-field" style="margin-bottom: 0px;">
                                <select (change)="onSelectactivity($event.target.value)" style="margin-bottom: 0px;">
                                    <option *ngFor="let type of lstactivity" [value]="type.id"
                                        [selected]="type.id==model.activityid" [disabled]="model.closureOTPsend">
                                        {{type.name}}</option>

                                </select>
                            </div>
                        </label>
                    </div>
                    <div class="col s12 m-btm">
                        <label class="w-100 dateBox">
                            <input [(ngModel)]="model.remark" #remark="ngModel" id="remark" type="text"
                                data-toggle="tooltip" data-placement="top" title="Remark" style="margin-bottom: 0px;"
                                placeholder="Remark">


                        </label>
                    </div>
                    <div class="col s12 srchBy sectionSrch m-btm mt-top" style="margin-top:0px;margin-bottom: 0px;">
                        <label class="w-35 font-weight-bold valignTxt">Invoice Total</label>
                        <label class="widthR-60">
                            <div class="input-field font-weight-bold valignTxt">
                                &#8377;{{model.grandtotal | number: '1.2-2'}}
                            </div>
                        </label>
                    </div>
                    <div class="col s12 m-btm" style="text-align: left;">
                        <label class="w-100 font-weight-bold valignTxt dis-block">Preferred Installation:</label>
                        <label class="w-100 dis-block">{{model.preferredInst+", "+model.preferredInstDate+",
                            "+model.preferredInstTime}}</label>
                    </div>
                    <div class="col s12 m-btm oprnDiv">
                        <label class="w-40" *ngIf="!model.closureOTPsend">
                            <input [(ngModel)]="model.follocheckselect" #follocheckselect="ngModel" type="checkbox"
                                id="chkfollowup" onclick="ShowHideDiv(this)">
                            <label for="chkfollowup"> Follow up</label>
                        </label>
                        <label class="widthR-60" *ngIf="!model.closureOTPsend">
                            <input type="checkbox" [(ngModel)]="model.complcheckselect"
                                (click)="oncompleteSelect($event)" #complcheckselect="ngModel" id="chkInstallation"
                                onclick="ShowHideDiv(this)">
                            <label for="chkInstallation"> Confirmed Installation</label>
                        </label>
                        <!-- Follow up click div opens -->
                        <div id="openFollowUpDiv" style="display: block">

                            <div class="srchBy container">
                                <div class="row" *ngIf="model.follocheckselect">
                                    <div class="trangel1"></div>
                                    <div class="openDateDiv s12">
                                        <label class="w-50 font-weight-bold">Follow up Date</label>
                                        <label class="widthR-50">
                                            <i class="fa fa-calendar inpClndr2"></i>
                                            <input type="text" placeholder="Follow up Date"
                                                [(ngModel)]="model.followupdate" id="start" data-toggle="tooltip"
                                                data-placement="top" title="DCR Date" class="form-control"
                                                popup-placement="top-right" bsDatepicker
                                                (ngModelChange)="selectToDate(model.followupdate)"
                                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true }">
                                        </label>
                                        <label class="w-100 font-weight-bold">Activity</label>
                                        <!-- <label class="widthR-60">
                                    <select (change)="onSelectfollowUPactivity($event.target.value)">
                                        <option *ngFor="let type of activitylst" [value]="type.name">{{type.name}}</option>
                                    </select>
                                </label> -->

                                        <div class="input-field w-100">
                                            <select (change)="onSelectfollowUPactivity($event.target.value)">
                                                <option *ngFor="let type of activitylst" [value]="type.id"
                                                    [selected]="type.id==model.activityid"
                                                    [disabled]="model.closureOTPsend">{{type.name}}</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Confirmed Installation click div -->
                        <div id="openInstallationDiv" style="display: block">

                            <div class="srchBy container">
                                <div class="row" *ngIf="model.complcheckselect && model.chkcustomer">
                                    <div class="trangel2"></div>
                                    <div class="openDateDiv s12" *ngFor="let lst of model.lstcontact; let i = index">
                                        <label class="w-35">
                                            <input [(ngModel)]="lst.checkselectcon" #checkselectcon="ngModel"
                                                (click)="Oncheckselectcontact(i)" type="checkbox">
                                            <label class="checkmark"></label>
                                        </label>
                                        <label class="w-50 font-weight-bold blueTxt valignTxt"></label>
                                        <label class="w-35 font-weight-bold blueTxt">Name</label>
                                        <label class="w-50 pl-2">{{lst.contactname}}</label>
                                        <label class="w-35 font-weight-bold blueTxt">Designation</label>
                                        <label class="w-50 pl-2">{{lst.contactdesignation}}</label>
                                        <label class="w-35 font-weight-bold blueTxt">Mobile #</label>
                                        <label class="w-50 pl-2">{{lst.contactmobile}}</label>
                                        <label class="w-35 font-weight-bold blueTxt">Email</label>
                                        <label class="w-50 pl-2">{{lst.contactemail}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col s12 m-btm" *ngIf="model.complcheckselect && model.chkcustomer">
                        <label class="w-100 dateBox">
                            <button type="button" *ngIf="showOtp == false && model.closureOTPsend" (click)="showotp()"
                                class="btn btn-blue">Show
                                OTP</button>&nbsp;
                            <button type="button" *ngIf="showOtp == true && model.closureOTPsend"
                                class="btn btn-blue"><b>{{model.otpshow}}</b></button>&nbsp;

                            <button type="button" class="btn right" value="Show/Hide" (click)="btngenrateOTP()"
                                onClick="showHideDiv('openGnrtDiv')">Generate OTP</button>
                        </label>
                        <div id="openGnrtDiv" class="openGnrtOTP" style="display:block;" *ngIf="model.closureOTPsend">
                            <label class="w-100 font-weight-bold">Service Report Confirmed By: </label>
                            <label class="w-100">{{model.contactname}}</label>
                            <label class="w-50">
                                <input type="text" [(ngModel)]="model.closureOTP" #closureOTP="ngModel"
                                    (ngModelChange)="verifyClosureOTP()" id="otp" placeholder="OTP"
                                    style="text-align: center;">
                            </label>
                        </div>
                    </div>


                    <div class="col s12 dcrTabnew">
                        <h3 class="picRupee" style="text-align: left;margin-top: 0px;margin-bottom: 0px;">DCR History
                        </h3>
                        <div class="tabInnerBox grayBg mt-top" *ngFor="let history of model.dcrhis">
                            <label class="widthR-60 font-weight-bold blueTxt">DCR Date</label>
                            <label class="w-35">&nbsp;{{history.dcrdate |
                                date:"dd-MM-yyyy"}}</label>
                            <label class="widthR-60 font-weight-bold blueTxt">Activity</label>
                            <label class="w-35">&nbsp;{{history.activity}}</label>
                            <label class="widthR-60 font-weight-bold blueTxt">Owner</label>
                            <label class="w-35">&nbsp;{{history.assignedByName}}</label>
                            <label class="widthR-60 font-weight-bold blueTxt">Description</label>
                            <label class="w-35">&nbsp;{{history.remarks}}</label>
                        </div>
                    </div>

                    <div class="s12 right mt-top">
                        <button type="button" (click)="cancle()" id="close" class="btn grayButton"
                            style="margin-right:6px;">Cancel</button>
                        <button type="button" class="btn btn-blue" (click)="btnsave()"
                            [disabled]="showBtn == true">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isMobile == false ">

    <app-header></app-header>
    <div class="container" style="width: 80%;">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Installation DCR</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="col l12 m12 s12">
                    <div class="row" style="margin-top: 1%">
                        <div class="col s6 m6 l6" style=" font-size: 15px;margin-top: 20px;">
                            <span>Customer Name:</span> <br> <b>{{model.coustomername}}</b>
                        </div>
                        <div class="col s6 m6 l6" style=" font-size: 15px;margin-top: 20px;">
                            <span>Customer Type:</span> <br> <b>{{model.cusType}}</b>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 1%;padding-top: 10px;">
                        <div class="col s4 m4 l4">
                            <span style="font-size: 15px;">
                                Address:&nbsp;<br><span><b>{{model.address}}</b></span></span>
                        </div>
                        <div class="col s2 m2 l2">
                            <span style="font-size: 15px;">
                                Locality:&nbsp;<br><span><b>{{model.locality}}</b></span></span>
                        </div>
                        <div class="col s3 m3 l3">
                            <span style="font-size: 15px;">
                                Pincode:&nbsp;<br><span><b>{{model.pincode}}</b></span></span>
                        </div>
                        <div class="col s3 m3 l3">
                            <span style="font-size: 15px;">
                                Mobile Number:&nbsp;<br><span><b>{{model.mobile}}</b></span></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" input-field col s4" style="margin-bottom:8px;">
                            <input [(ngModel)]="model.googlemap" #googlemaplocation="ngModel" id="googlemaplocation"
                                type="text" data-toggle="tooltip" data-placement="top" title="Google Maps Location"
                                required>
                            <label id="lable3" for="googlemaplocation" class="active">Google Maps Location </label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l12 m12 s12" style="margin-top: 0px;padding-left: 0px;padding-right: 0px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="15%" style="text-align: left">Installation #</th>
                                    <th width="8%" style="text-align: left">Date</th>
                                    <th width="30%" style="text-align: left">Product Model</th>
                                    <!-- <th width="10%" style="text-align: left">Unit #</th> -->
                                    <th width="16%" style="text-align: left">Assigned To</th>
                                    <th width="13%" style="text-align: left">Warranty(In Months)</th>
                                    <th width="13%" style="text-align: left">Warranty End Date</th>
                                    <th width="5%" style="text-align: left">Completed
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox"
                                            [(ngModel)]="model.chkall" #chkall="ngModel"
                                            (click)="btnselectAllProduct($event)"></th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of model.lstproduct | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left">{{lst.installtionnumber}}</td>
                                        <td style="text-align: left">{{lst.installtiondate | date :'dd-MM-yyyy'}}</td>
                                        <td style="text-align: left">{{lst.productname}}</td>
                                        <!-- <td style="text-align: left">{{lst.unit}}</td> -->
                                        <td style="text-align: left">{{lst.assignedtoname}}</td>
                                        <td style="text-align: right;padding-left: 80px;">
                                            <input [(ngModel)]="lst.warrantyMonth" #warrantyMonth="ngModel"
                                                (ngModelChange)="enteredMonth(lst.warrantyMonth,j)" type="number"
                                                min="1" oninput="validity.valid||(value='');" id="first4"
                                                data-toggle="tooltip" data-placement="top" style="text-align: right;"
                                                title="Warranty"
                                                [disabled]="lst.completeInst == true || lst.assignedtoID != userid"
                                                ngModel required>
                                        </td>
                                        <td style="text-align: left">{{lst.warrantyenddate | date:'dd-MM-yyyy'}}</td>
                                        <td style="text-align: center"> <input type="checkbox"
                                                (click)="onproductcomplet($event)" [(ngModel)]="lst.completflag"
                                                name="Select" style="text-align: 20px;"
                                                [disabled]="lst.completeInst == true || lst.assignedtoID != userid">&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s3 m3 l3" style="margin-right :10px; margin-top:0px;">
                            <span>DCR Date</span>
                            <input type="text" placeholder="DCR Date" [(ngModel)]="model.dcrdate" id="date"
                                title="DCR Date" class="form-control" (ngModelChange)="selectDCRDate(model.dcrdate)"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true}">

                        </div>

                        <div class=" col l2 m2 s2">
                            <span>DCR Start Time</span>
                            <input id="timepicker_ampm_dark" class="timepicker" type="time"
                                [(ngModel)]="model.dcrstarttime" #dcrstarttime="ngModel" id="dcrstarttime"
                                value={{model.dcrstarttime}}>
                            <!-- <input type="text" value={{now}}/>   -->


                            <!-- Time:{{model.dcrstarttime }} -->
                            <!-- <input [(ngModel)]="model.dcrstarttime" #dcrstarttime="ngModel" id="remark" type="text"
                            data-toggle="tooltip" data-placement="top" title="DCR Start Time" required>
                        <label id="lable4" for="first4">DCR Start Time</label> -->
                        </div>
                        <div class="input-field col l3 m3 s3" style="margin-top:0px;">
                            <span>Activity</span>
                            <select (change)="onSelectactivity($event.target.value)">
                                <option *ngFor="let type of lstactivity" [value]="type.id"
                                    [selected]="type.id==model.activityid" [disabled]="model.closureOTPsend">
                                    {{type.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class=" input-field col s8" style="margin-top:0pxunit;margin-bottom: 0px;">
                            <input [(ngModel)]="model.remark" #remark="ngModel" id="remark" type="text"
                                data-toggle="tooltip" data-placement="top" title="Remark" style="margin-bottom: 0px;"
                                required>
                            <label id="lable4" for="first4">Remarks</label>
                        </div>
                        <div class=" input-field col l3 m3 s3" style="padding-right: 0px;margin-top: 18px;">
                            <span style="font-size: 15px; font-weight: bold">Invoice Total :
                                &nbsp;&#8377;{{model.grandtotal | number: '1.2-2'}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l12 m9 s9" style="padding-top: 5px;">
                            <span style="font-size: 15px;">Preferred Installation
                                :&nbsp;<span><b>{{model.preferredInst+", "+model.preferredInstDate+",
                                        "+model.preferredInstTime}}</b></span></span>
                        </div>
                    </div>

                    <div class="row" *ngIf="!model.closureOTPsend" style="margin-top:15px;">
                        <div class="input-field col l2" style="margin-top: 0px;font-size:15px">
                            <input [(ngModel)]="model.follocheckselect" #follocheckselect="ngModel" type="checkbox"
                                style="text-align: 20px; ">
                            Follow up
                        </div>
                        <div class="input-field col l4" style="margin-top: 0px;font-size:15px">
                            <input [(ngModel)]="model.complcheckselect" (click)="oncompleteSelect($event)"
                                #complcheckselect="ngModel" type="checkbox" style="text-align: 20px;">
                            Confirmed Installation
                        </div>
                    </div>
                    <div class="row" *ngIf="model.follocheckselect">
                        <div class="input-field col s3 m3 l3" style="margin-right :10px; margin-top:17px;">
                            <input type="text" placeholder="Follow up Date" [(ngModel)]="model.followupdate" id="start"
                                data-toggle="tooltip" data-placement="top" title="DCR Date" class="form-control"
                                popup-placement="top-right" bsDatepicker
                                (ngModelChange)="selectToDate(model.followupdate)"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true }">
                        </div>

                        <div class="input-field col l3 m3 s3" style="margin-top:0px;">
                            <span>Activity</span>
                            <select (change)="onSelectfollowUPactivity($event.target.value)">
                                <option *ngFor="let type of activitylst" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 0px;padding-right: 0px;"
                        *ngIf="model.complcheckselect && model.chkcustomer">
                        <!-- <b style="font-size: 15px;">Contacts</b> -->
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="5%" style="text-align: center"></th>
                                <th width="20%" style="text-align: center">Name</th>
                                <th width="10%" style="text-align: center">Designation</th>
                                <th width="10%" style="text-align: center">Mobile</th>
                                <th width="20%" style="text-align: center">E-Mail</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of model.lstcontact; let i = index">
                                    <td class=" input-field" style="text-align: center;">
                                        <input [(ngModel)]="lst.checkselectcon" #checkselectcon="ngModel"
                                            type="checkbox" (click)="Oncheckselectcontact(i)" name="Select"
                                            style="text-align: 20px; ">&nbsp;
                                    </td>
                                    <td style="text-align: center">
                                        <span>{{lst.contactname}}</span>
                                    </td>
                                    <td class=" input-field " style="text-align: center;">
                                        <span>{{lst.contactdesignation}}</span>
                                    </td>
                                    <td style="text-align: center">
                                        <span>{{lst.contactmobile}}</span>
                                    </td>
                                    <td style="text-align: center;">
                                        <span> {{lst.contactemail}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" *ngIf="model.complcheckselect && model.chkcustomer">
                        <!-- *ngIf="!model.closureOTPsend" -->
                        <div class=" input-field col l2 m2 s2" style="padding-right: 0px;">
                            <button (click)="btngenrateOTP()" type="button"
                                class="waves-effect  waves-light btn">Generate
                                OTP</button>&nbsp;&nbsp;
                        </div>

                        <div class="col l6 m6 s6" style="text-align: left;margin-top: 18px;padding-left: 0px;"
                            *ngIf="model.closureOTPsend">
                            <span style="font-size: 15px; font-weight: bold">Service Report Confirmed By:
                                {{model.contactname}}</span>
                        </div>
                        <div class="input-field col l1 m1 s1"
                            style="text-align: start; margin-bottom: 0px;padding-top: 10px;"
                            *ngIf="model.closureOTPsend">
                            <input type="text" [(ngModel)]="model.closureOTP" #closureOTP="ngModel"
                                (ngModelChange)="verifyClosureOTP()" id="otp">
                            <label for="otp">OTP</label>
                        </div>
                        <div *ngIf="model.closureOTPsend" class="input-field col l3 m3 s3"
                            style="margin-bottom: 0px;text-align: end;">
                            <button *ngIf="showOtp == false" (click)="showotp()" type="button"
                                class="waves-effect waves-light btn">Show OTP</button>
                            <button *ngIf="showOtp == true" type="button"
                                class="waves-effect waves-light btn"><b>{{model.otpshow}}</b></button>
                        </div>

                    </div>
                    <div class="row col l12 m12 s12 " style="padding: 0px;display: none;">
                        <!-- <div class="col l6 m6 s6" style="padding: 0px;"> -->
                        <div class="left-align col l3 m3 s3" *ngIf="model.closeotpverify" style="padding-left: 9px;">
                            <button (click)="btnsendinvoince()" [disabled]="!model.sendinvoice" type="button"
                                class="waves-effect waves-light btn">Send
                                Invoice</button>&nbsp;&nbsp;
                        </div>
                        <!-- <div class="col l3 m3 s3" style="text-align: left"*ngIf="model.closeotpverify">
                            <button (click)="btnprintInvo(Invoice)" type="button" class="waves-effect waves-light btn">Show
                                Invoice</button>
                        </div> -->
                        <div class=" col l2 m3 s3" *ngIf="model.invocesend" style="margin-top: 18px;">
                            <span style="font-size: 15px;">Payment Status</span>
                        </div>
                        <div class="row col l2 m3 s3" *ngIf="model.invocesend" style="margin-top: 18px;">
                            <input [(ngModel)]="model.cashcheckselect" #cashcheckselect="ngModel"
                                (click)="cashcodesave()" type="checkbox" name="Select">&nbsp;Cash Received
                        </div>
                        <div class="row col l2 m3 s3" *ngIf="model.invocesend">
                            <input [(ngModel)]="model.cashreceiverOTP" #cashreceiverOTP="ngModel"
                                (ngModelChange)="verifyOTP()" type="text" id="serviceOTP" placeholder="OTP"
                                maxlength="6">
                            <!-- <label for="cashreceiverOTP">Name</label> -->
                        </div>
                        <!-- </div> -->
                    </div>
                    <div class="row">
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div> <b style="font-size: 15px;">DCR History</b></div>
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20%" style="text-align: left;padding-left: 10px;">DCR Date</th>
                                    <th width="20%" style="text-align: left">Activity</th>
                                    <th width="20%" style="text-align: left">Owner</th>
                                    <th width="30%" style="text-align: left">Description</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let history of model.dcrhis">
                                        <td style="text-align: left;padding-left: 10px;">{{history.dcrdate |
                                            date:"dd-MM-yyyy"}}</td>

                                        <td style="text-align: left">{{history.activity}}</td>
                                        <td style="text-align: left">{{history.assignedByName}}</td>
                                        <td style="text-align: left">{{history.remarks}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col l12 m12 s12"
                            style="text-align: right;padding-right: 0px;margin-top:0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel
                            </button>&nbsp;&nbsp;<button type="button" (click)="btnsave()"
                                class="waves-ripple waves-light btn" [disabled]="showBtn == true">Save</button>
                            <!-- [disabled] = "model.installationclose" -->
                            <!-- [disabled] = "model.completeall" -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>