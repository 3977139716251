import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { AlertService, CompanyService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { SearchUserModel } from '../ResourceMaster/SearchUserModel';
import { DefineResourceAllowanceModel } from './DefineResourceAllowanceModel';

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'SearchResourceAllowance.html'
})
export class SearchResourceAllowance implements OnInit {
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private notifyService: NotificationService) {
            this.pagename=this.router.url
        }
    p: any
    pagename: string;
    model = new SearchUserModel();
    reslst: DefineResourceAllowanceModel[] = [];
    reslst1: DefineResourceAllowanceModel[] = [];
    searchLst: DropDownModel[] = [
        { id: 1, name: "All" },
        { id: 2, name: "Resource" },
    ]
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        localStorage.setItem("pagename",this.pagename); 
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        } 
        this.getResorceWithAllownc()
    }

    getResorceWithAllownc() {
        this.Masterservice.getResorceWithAllownc(this.model)
            .subscribe(
                data => {
                    this.reslst = []
                    this.reslst1 = []
                    // this.reslst = data;
                    // this.reslst1 = data
                });
    }

    btnnew() {
        this.router.navigate(['/newDefineResAllo']);
    }

    btnedit(id, name) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "name": name
            }
        };
        this.router.navigate(['/editDefineResAllo'], navigationExtras);
    }

    onSelectOpt(val) {
        this.model.txtselect = val
    }

    btnsearch() {
        if (this.model.txtselect == "All") {
            this.getResorceWithAllownc()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Resource") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.reslst1 = [];
                this.reslst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.reslst1.push({ "id": item.id, "name": item.name, "allowancelst": null, "designationName": null, "resource": null, "txtselect": null, "txtserch": null, "userid": null, "srno": item.srno });
                    }
                });
                this.reslst = this.reslst1;
            }
        }
    }


}