import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AreaModel1 } from "src/app/Transactions/Teams/TeamModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "./DCRreportModel";

@Component({
    selector: 'app-login',
    templateUrl: 'ExecWiseQuotationDetailReport.html'
})
export class ExecWiseQuotationDetailReport implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, 
        private modalService: BsModalService, 
        private reportServices: ReportServices, 
        private companyservice: CompanyService, 
        private notifyService: NotificationService, 
        private alertService: AlertService, 
        private userService: MasterServices, 
        private route: ActivatedRoute,
        private router: Router) {

    }
    model = new DCRreportModel();
    user = new User();
    regionlst: DropDownModel[] = [];
    arealst: AreaModel1[] = [];
    resourcelst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    companyname: string;
    companyaddress: String;

    id: number;
    srlst: DCRreportModel[] = [];
    dsrlst: DCRreportModel[] = [];
    eqrlst: DCRreportModel[] = [];
    eqrldtlst: DCRreportModel[] = [];
    productSummarylst: DCRreportModel[] = [];
    regionName: string;
    areaName: string;
    branchName: string;
    resourceName: string;
    timing = new Date();
    username: string;
    status: string;
    totalcount:number;
    totalfinalamount:number;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Open" },
        { id: 3, "name": "Won" },
        { id: 3, "name": "Lost" },
        { id: 3, "name": "Abandoned" },
    ];

    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.areaName = localStorage.getItem('areaName');
        this.regionName = localStorage.getItem('regionName');
        this.branchName = localStorage.getItem('branchName');
        this.status = localStorage.getItem('status');
        var getObj = JSON.parse(localStorage.getItem('testObject'));
        this.model = getObj;
        this.btnshow();
    }

    btnshow() {
        this.reportServices.showEnggQuotationdtlReport(this.model)
            .subscribe(
                data => {
                    this.eqrlst = data.eqrlst;
                    this.productSummarylst = data.productSummarylst
                    this.totalcount = 0;
                    this.totalfinalamount = 0;
                    for (let i = 0 ;i< this.productSummarylst.length; i++)
                    {
                        this.totalcount = this.totalcount + this.productSummarylst[i].count;
                        this.totalfinalamount = this.totalfinalamount + this.productSummarylst[i].finalamount;
                    }
                    for (let i = 0; i < this.eqrlst.length; i++) {
                        this.companyname = this.eqrlst[0].companyname
                        this.companyaddress = this.eqrlst[0].companyaddress;
                    }

                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        // WindowObject.close();
    }
    btnback() {

        this.router.navigate(["/ExecWiseQuotationDetailReport"]);
    }

}