export class DailyProductionPlanModel{
    id:number;
    srno:number;
    planID:string;
    checkselect:boolean=false;
    plandate:Date;
    location:string;
    locationid:number;
    itemcategoryid:number;
    itemsubcategoryid:number;
    itemname:string;
    uom:string;
    itemid:number;
    quantity:number;
    itemcode:string;
    productionquantity:number;
    availablestock:number;
    productionplanquantity:number;
    status:string;
    productionplandtlid:number;
    requisetionsend:boolean;
    plancomplete:boolean;
    actualproduction:number;
    lstitem:DailyProductionPlanModel[]=[];
    transferquantity:number;
}