import { OnInit, Component } from '@angular/core';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { MarketingProposalHomeModel } from './MarketingProposalHomeModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MarketingServices } from 'src/app/_services/MarketingServices';
import { User } from 'src/app/_models/user';
@Component({
    selector: 'app-MarketingProposalHome',
    templateUrl: 'MarketingProposalHome.html',
})
export class MarketingProposalHome implements OnInit {

    constructor(private masterservice: MasterServices, private transactionservice: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private marketingServices: MarketingServices) {
    }
    model = new MarketingProposalHomeModel();
    user = new User();
    p: any;
    public i;
    searchtype: DropDownModel[] = [
        { id: 0, name: "Select Search Criteria" },
        { id: 1, name: "All" },
        { id :2, name :"Proposal Type"},
        { id :3, name:"Event Propose By"},
        { id :4, name :"Description"},
        { id :5, name :"Status"},
    ];
    listtype: DropDownModel[] = [
        { "id": 1, "name": "My Proposals" },
        { "id": 2, "name": "Proposals For Approval" }
    ];
    marketingproposallst: MarketingProposalHomeModel[] = [];
    marketingproposallst1:MarketingProposalHomeModel[] = [];

    ngOnInit() {
        this.user.userid = Number(localStorage.getItem("userid"));
        this.user.message = "proposals"
        this.getMarketingProposalsByUser()
    }

    onSelectListType(val) {
        if (val == 1) {
            this.user.message = "proposals";
        } else if (val == 2) {
            this.user.message = "approvals";
        }
        this.getMarketingProposalsByUser()
    }

    getMarketingProposalsByUser() {
        this.marketingproposallst = [];
        this.marketingServices.getMarketingProposalsByUser(this.user)
            .subscribe(value => {
                this.marketingproposallst = value
                this.marketingproposallst1 = value;
            });
    }

    btnnew() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "operationType": "new",
            }
        };
        this.router.navigate(['/newMarketing'], navigationExtras)
    }

    onSelectSrch(val) {
        this.model.txtselect = val;
    }

    btnsearch() {
        if (this.model.txtselect == "All") {
            this.getMarketingProposalsByUser()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Proposal Type") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.marketingproposallst1 = [];
                this.marketingproposallst.forEach((item) => {
                    if ((item.proposaltype.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.marketingproposallst1.push({ "id": item.id, "txtserch": null, "txtselect": null, "proposaltype": item.proposaltype, "srno": item.srno, "description": item.description, "eventproposedby": item.eventproposedby, "status": item.status });
                    }
                });
                this.marketingproposallst = this.marketingproposallst1;
            }
        }
        else if (this.model.txtselect == "Event Propose By") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.marketingproposallst1 = [];
                this.marketingproposallst.forEach((item) => {
                    if ((item.eventproposedby.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.marketingproposallst1.push({ "id": item.id, "txtserch": null, "txtselect": null, "proposaltype": item.proposaltype, "srno": item.srno, "description": item.description, "eventproposedby": item.eventproposedby, "status": item.status});
                    }
                });
                this.marketingproposallst = this.marketingproposallst1;
            }
        } else if (this.model.txtselect == "Description") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.marketingproposallst1 = [];
                this.marketingproposallst.forEach((item) => {
                    if ((item.description.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.marketingproposallst1.push({ "id": item.id, "txtserch": null, "txtselect": null, "proposaltype": item.proposaltype, "srno": item.srno, "description": item.description, "eventproposedby": item.eventproposedby, "status": item.status});
                    }
                });
                this.marketingproposallst = this.marketingproposallst1;
            }
        }
        else if (this.model.txtselect == "Status") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.marketingproposallst1 = [];
                this.marketingproposallst.forEach((item) => {
                    if ((item.status.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.marketingproposallst1.push({ "id": item.id, "txtserch": null, "txtselect": null, "proposaltype": item.proposaltype, "srno": item.srno, "description": item.description, "eventproposedby": item.eventproposedby, "status": item.status });
                    }
                });
                this.marketingproposallst = this.marketingproposallst1;
            }
        }
    }



    btnedit(id, status) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "operationType": status,
                "id": id
            }
        };
        this.router.navigate(['/newMarketing'], navigationExtras)
    }

    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {

            this.model.id = id
            this.marketingServices.deletemarketingproposal(this.model)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
    }
}