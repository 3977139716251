import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { AlertService, CompanyService, MasterServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { searchgstheadermodel } from "../GSTForm/searchgstheadermodel";
import { BOMItemModel, DefineItemModel } from "./DefineItemModel";
declare var $: any;
@Component({
    selector: 'app-login',
    templateUrl: 'DefineItem.html'
})
export class DefineItem implements OnInit {
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {
    }
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    // invUnitlst: DropDownModel[] = [];
    // prodUnitlst: DropDownModel[] = [];
    // dimUnitlst: DropDownModel[] = [];
    unitttlst: DropDownModel[] = [];
    gstlst: DropDownModel[] = [];
    model = new DefineItemModel();
    model1 = new BOMItemModel();
    itemMasterlst: BOMItemModel[] = [];
    itemMasterlst1: BOMItemModel[] = [];
    modelItemlst: BOMItemModel[] = [];
    taxmodellst: searchgstheadermodel[] = [];
    modalRef: BsModalRef;
    user = new User();
    sub: any;
    p: any;
    id: number;
    public i;
    userList2: any
    keyword = 'name';
    keyword1 = 'name';
    temp: boolean;
    ngOnInit(): void {
        this.temp = true;
        this.getitemCategory();
        this.getGstgrp();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.chkbyitem = true;
        this.model.itemtype = "Item"

        this.Masterservice.getUnit(this.user)
            .subscribe(value => {
                this.unitttlst = value
            });

        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.Masterservice.getItemByid(user)
                .subscribe(
                    data => {
                        this.model = data;

                        if (this.model.itemtype == "Item") {
                            this.model.chkbyitem = true;
                        } else if (this.model.itemtype == "Service") {
                            this.model.chkbyservice = true;
                        }

                        if (this.model.lengt) {
                            this.model.chklengt = true
                        } else
                            if (this.model.breadth) {
                                this.model.chkbreadth = true
                            } else if (this.model.height) {
                                this.model.chkheight = true
                            }


                        this.itemMasterlst = data.itemMasterlst;
                        this.onSelectitemCat(this.model.itemcategoryid)
                    },
                    error => {
                        this.alertService.error(error);
                    });
        }
    }
    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        //alert(value);
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.itemsubcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.itemsubcategory = this.subCatlst[i].name
            }
        }
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }
    getGstgrp() {
        this.Masterservice.getallgsthdr(this.user)
            .subscribe(value => {
                this.taxmodellst = [];
                this.taxmodellst = value

            });
    }
    onchkbyitem($event) {
        this.model.itemtype = "";
        if ($event.target.checked) {
            this.model.itemtype = "Item"
        } else {
            this.model.itemtype = ""
        }
    }
    onchkbyservice($event) {
        this.model.itemtype = "";
        if ($event.target.checked) {
            this.model.itemtype = "Service"
        } else {
            this.model.itemtype = ""
        }
    }
    onSelectInvUnit(value) {
        this.model.inventoryunitid = value;
        for (let i = 0; i < this.unitttlst.length; i++) {
            if (this.unitttlst[i].id == value) {
                this.model.inventoryunit = this.unitttlst[i].name
            }
        }
    }
    onSelectProdUnit(value) {
        this.model.productionunitid = value;
        for (let i = 0; i < this.unitttlst.length; i++) {
            if (this.unitttlst[i].id == value) {
                this.model.productionunit = this.unitttlst[i].name
            }
        }
    }
    onSelectDimUnit(value) {
        this.model.diamensionUnitid = value;
        for (let i = 0; i < this.unitttlst.length; i++) {
            if (this.unitttlst[i].id == value) {
                this.model.diamensionUnit = this.unitttlst[i].name
            }
        }
    }
    onSelectGstGrp(value) {
        this.model.gstGroupid = value;
        for (let i = 0; i < this.taxmodellst.length; i++) {
            if (this.taxmodellst[i].id == value) {
                this.model.gstGrouppercentage = Number(this.taxmodellst[i].persentage.toString)
            }
        }
    }
    onSelectUCInvUnit(value) {
        this.model.ucInvenUnitid = value;
        for (let i = 0; i < this.unitttlst.length; i++) {
            if (this.unitttlst[i].id == value) {
                this.model.ucInvenUnit = this.unitttlst[i].name
            }
        }
    }
    onSelectConvInvUnit(value) {
        this.model.convInvenUnitid = value;
        for (let i = 0; i < this.unitttlst.length; i++) {
            if (this.unitttlst[i].id == value) {
                this.model.convInvenUnit = this.unitttlst[i].name
            }
        }
    }

    openlocationmodel(locationmodel: TemplateRef<any>) {

        this.modalRef = this.modalService.show(locationmodel, {
            class: 'modal-lg'
        });

    }
    onSlctitemCat(value) {
        this.model1.itemcatid = value;
        let user = new User();
        this.subCatlst = [];
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSlctSubCat(value) {
        this.model1.subitemcatid = value;
    }
    getItemLike($event) {
        let userId = (<HTMLInputElement>document.getElementById('itmname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.model1.itemcatid;
        this.user.trnid = this.model1.subitemcatid;
        var obj = this;
        if (userId.length >= 3) {
            this.Masterservice.getItemLikeForBOMitem(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect(id, name) {
        this.model1.itemid = id;
        this.model1.item = name;
        let user = new User();
        user.trnid = id
        this.Masterservice.getItemByid(user)
            .subscribe(
                data => {
                    if (this.model1.itemtype == "Item") {
                        this.model1.chkbyitem = true;
                    } else if (this.model1.itemtype == "Service") {
                        this.model1.chkbyservice = true;
                    }
                    this.model1.uom = data.inventoryunit;
                    this.model1.itemcode = data.itemcode
                },
                error => {
                    this.alertService.error(error);
                });
    }
    getItemLike1($event) {
        let userId = (<HTMLInputElement>document.getElementById('cpyitem')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.Masterservice.getItemLikeCpy(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect1(id, name) {
        let user = new User();
        user.trnid = id
        this.Masterservice.getItemByid(user)
            .subscribe(
                data => {
                    this.model.itemcode = data.itemcode;
                    this.model.itemname = data.itemname;
                    this.model.description = data.description;
                    this.model.chkbyitem = data.chkbyitem;
                    this.model.chkbyservice = data.chkbyservice;
                    this.model.itemcategoryid = data.itemcategoryid;
                    this.model.itemsubcategoryid = data.itemsubcategoryid;
                    this.model.inventoryunitid = data.inventoryunitid;
                    this.model.productionunitid = data.productionunitid;
                    this.model.gstGroupid = data.gstGroupid;
                    this.model.hsnCode = data.hsnCode;
                    this.model.sacCode = data.sacCode;
                    this.model.reorderLevel = data.reorderLevel;
                    this.model.minimumOrderQty = data.minimumOrderQty;
                    this.model.chklengt = data.chklengt;
                    this.model.lengt = data.lengt;
                    this.model.chkbreadth = data.chkbreadth;
                    this.model.breadth = data.breadth;
                    this.model.chkheight = data.chkheight;
                    this.model.height = data.height;
                    this.model.diamensionUnitid = data.diamensionUnitid;
                    this.model.chkUntConversion = data.chkUntConversion;
                    this.model.ucQuantity = data.ucQuantity;
                    this.model.ucInvenUnitid = data.ucInvenUnitid;
                    this.model.convQuantity = data.convQuantity;
                    this.model.convInvenUnitid = data.convInvenUnitid;
                    this.model.chkbom = data.chkbom;
                    //this.model.uom = data.inventoryunit;
                    //this.model.itemcode = data.itemcode;
                },
                error => {
                    this.alertService.error(error);
                });
    }
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.Masterservice.dltBoM(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }
    }
    delBomlst(itmid) {
        this.i = confirm("Do you want to delete this record ?")

        for (let i = 0; i < this.modelItemlst.length; i++) {
            if (this.modelItemlst[i].itemid == itmid) {
                this.modelItemlst.splice(this.modelItemlst.indexOf(this.modelItemlst[i]), 1);
                this.notifyService.showSuccess("Product Removed Successfully", " Success");
            }
        }
    }

    btnadd1() {
        for (let i = 0; i < this.modelItemlst.length; i++) {
            if (this.modelItemlst[i].subitemcatid == this.model1.subitemcatid) {
                this.notifyService.showRroor("Subcategory already in list!", "Oops!");
                return;
            }
        }
        this.modelItemlst.push({
            id: null,
            subitemcatid: this.model1.subitemcatid,
            itemcatid: null,
            item: this.model1.item,
            itemid: this.model1.itemid,
            quantity: 0,
            uom: this.model1.uom,
            itemcode: this.model1.itemcode,
            chkbyitem: this.model1.chkbyitem,
            chkbyservice: this.model1.chkbyservice,
            itemtype: this.model1.itemtype
        })
        this.model1.subitemcatid = null,
            this.model1.item = null,
            this.model1.itemid = null,
            this.model1.uom = null,
            this.model1.itemcode = null,
            this.model1.chkbyitem = null,
            this.model1.chkbyservice = null,
            this.model1.itemtype = null

    }
    cancle() {
        this.router.navigate(["/Item Master"]);
    }
    btnsave($event) {
        if (this.model.itemcode == null || this.model.itemcode == "") {
            this.notifyService.showRroor("Please Enter Item code", "Opps");
            return;
        }
        if (this.model.itemname == null || this.model.itemname == "") {
            this.notifyService.showRroor("Please Enter Item Name", "Opps");
            return;
        }
        if (this.model.description == null || this.model.description == "") {
            this.notifyService.showRroor("Please Enter Description", "Opps");
            return;
        }
        if (this.model.itemcategoryid == null || this.model.itemcategoryid == 0) {
            this.notifyService.showRroor("Please Select Item Category", "Opps");
            return;
        }
        if (this.model.itemsubcategoryid == null || this.model.itemsubcategoryid == 0) {
            this.notifyService.showRroor("Please  Select Item Sub Category", "Opps");
            return;
        }
        if (this.model.inventoryunitid == null || this.model.inventoryunitid == 0) {
            this.notifyService.showRroor("Please Select Inventory Unit ", "Opps");
            return;
        }

        if (this.model.productionunitid == null || this.model.productionunitid == 0) {
            this.notifyService.showRroor("Please Select Product Unit", "Opps");
            return;
        }
        // if (this.model.diamensionUnitid == null || this.model.diamensionUnitid == 0) {
        //     this.notifyService.showRroor("Please Select Diamension Unit", "Opps");
        //     return;
        // }
        if (this.model.gstGroupid == null || this.model.gstGroupid == 0) {
            this.notifyService.showRroor("Please Select GST ", "Opps");
            return;
        }
        if (this.model.itemtype == "Item") {
            if (this.model.hsnCode == null || this.model.hsnCode == "") {
                this.notifyService.showRroor("Please Enter HSN Code", "Opps");
                return;
            }
        }
        if (this.model.itemtype == "Service") {
            if (this.model.sacCode == null || this.model.sacCode == "") {
                this.notifyService.showRroor("Please Enter SAC Code", "Opps");
                return;
            }
        }

        this.model.itemMasterlst = this.itemMasterlst
        this.model.userid = Number(localStorage.getItem("userid"));
        this.Masterservice.saveitemMaster(this.model)
            .subscribe(
                data => {

                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['/Item Master']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
    mbtnsave($event) {
        this.modelItemlst.forEach((item) => this.itemMasterlst.push({ "id": item.id, "itemcode": item.itemcode, "item": item.item, "uom": item.uom, "quantity": item.quantity, "subitemcatid": item.subitemcatid, "itemcatid": item.itemcatid, "itemid": item.itemid, "chkbyitem": item.chkbyitem, "chkbyservice": item.chkbyservice, "itemtype": item.itemtype }));
        this.modalRef.hide()
    }
    onChangeSearch(search: string) {
        let searchDataObj = {};
        this.user.message = search;
        //  this.promodel.productid = this.productid;
        this.Masterservice.getItemLikeCpy(this.user)
            .subscribe(data => {
                this.userList2 = [];
                data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));

            });



    }
    selectEventforitem(item) {
        let user = new User();
        user.trnid = item.id
        this.Masterservice.getItemByid(user)
            .subscribe(
                data => {
                    this.model.itemcode = data.itemcode;
                    this.model.itemname = data.itemname;
                    this.model.description = data.description;
                    this.model.chkbyitem = data.chkbyitem;
                    this.model.chkbyservice = data.chkbyservice;
                    this.model.itemcategoryid = data.itemcategoryid;
                    this.model.itemsubcategoryid = data.itemsubcategoryid;
                    this.model.inventoryunitid = data.inventoryunitid;
                    this.model.productionunitid = data.productionunitid;
                    this.model.gstGroupid = data.gstGroupid;
                    this.model.hsnCode = data.hsnCode;
                    this.model.sacCode = data.sacCode;
                    this.model.reorderLevel = data.reorderLevel;
                    this.model.minimumOrderQty = data.minimumOrderQty;
                    this.model.chklengt = data.chklengt;
                    this.model.lengt = data.lengt;
                    this.model.chkbreadth = data.chkbreadth;
                    this.model.breadth = data.breadth;
                    this.model.chkheight = data.chkheight;
                    this.model.height = data.height;
                    this.model.diamensionUnitid = data.diamensionUnitid;
                    this.model.chkUntConversion = data.chkUntConversion;
                    this.model.ucQuantity = data.ucQuantity;
                    this.model.ucInvenUnitid = data.ucInvenUnitid;
                    this.model.convQuantity = data.convQuantity;
                    this.model.convInvenUnitid = data.convInvenUnitid;
                    this.model.chkbom = data.chkbom;
                    //this.model.uom = data.inventoryunit;
                    //this.model.itemcode = data.itemcode;
                },
                error => {
                    this.alertService.error(error);
                });
    }

    onFocused(e) {
        // do something
    }
    onChangeSearchitem(search: string) {
        let searchDataObj = {};
        this.user.message = search;
        this.user.id = this.model1.itemcatid;
        this.user.trnid = this.model1.subitemcatid;
        //  this.promodel.productid = this.productid;
        this.Masterservice.getItemLikeForBOMitem(this.user)
            .subscribe(data => {
                this.userList2 = [];
                data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));

            });


    }
    selectEvent(item) {
        this.model1.itemid = item.id;
        this.model1.item = item.name;

        let user = new User();
        user.trnid = item.id
        this.Masterservice.getItemByid(user)
            .subscribe(
                data => {
                    if (this.model1.itemtype == "Item") {
                        this.model1.chkbyitem = true;
                    } else if (this.model1.itemtype == "Service") {
                        this.model1.chkbyservice = true;
                    }
                    this.model1.uom = data.inventoryunit;
                    this.model1.itemcode = data.itemcode
                },
                error => {
                    this.alertService.error(error);
                });
    }
    onFocuseditem(e) {
        // do something
    }

}
