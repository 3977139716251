import { Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { User } from "src/app/_models";
import { InstallationService } from "src/app/_services";
import { SearchInstallationDCRModel } from "./SearchInstallationDCRModel";

@Component({
    selector: 'app-order',
    templateUrl: 'SearchInstallationDCR.html'
})
export class SearchInstallationDCR implements OnInit {
    user = new User();
    model: any = {};
    public p: any;
    lstinstallation: SearchInstallationDCRModel[] = [];

    constructor(
        private router: Router,
        private installationService: InstallationService,
    ) { }

    ngOnInit(): void {
        this.user.userid = Number(localStorage.getItem("userid"));
        this.installationService.Getinstallationbyresource(this.user)
            .subscribe(data => {
                this.lstinstallation = data;
            });

    }
    btnnew() {
        this.router.navigate(['/InstallationDCRcreate']);
    }
    btnedit(id) {
        // alert(id);
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id
            }
        };
        this.router.navigate(['/InstallationDCRcreate'], navigationExtras);
    }
}