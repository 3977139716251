export class DispatchOrderModel {
    id: number;
    supplierflag: boolean
    accid: number
    userid: number;
    oanumber: string;
    customername: string;
    disdetails: string;
    locality: string;
    localityid: number;
    customertype: string;
    status: string;
    oadate: Date;
    address: string;
    gstseprate: boolean;
    chkall: boolean;
    invoiceProducts: InvoiceProducts[] = [];
    serialProducts: serialProducts[] = [];
    caddress: string;
    pincode: string;
    inventorylocationid: number;
    netamount: number;
    totalproamount: number;
    totalgst: number;
    grandtotal: number;
    ctypeRetail: boolean;
    supplierName: string;
    supplierId: number;
    QuotationID: number;
    gstinno: string;
    gsttype: string;
    supplierAddrs: string;
    unitno: string;
    buildingName: string;
    city: string;
    name: string;
    proid: number;
    schemecode: string;
    serialno: string;
    pid: number;
    invIntegration: boolean;
    invFromlocationid: number;
    invTolocationid: number;
    invFromlocation: string;
    invTolocation: string;
    dealerflag:string;
    invTolocations: InvoiceProducts[] = [];
    invFromlocations: InvoiceProducts[] = [];

}
export class InvoiceProducts {
    id: number;
    pid: number;
    name: string;
    uom: string;
    uomid: number;
    srno: number;
    hsn: string;
    chkselectpc: boolean
    orderquantity: number;
    balancequantity: number;
    supplyquantity: number;

    netamount: number;
    taxamount: number;
    offeredPrice: number;
    totalproamount: number;
    noGstAmmount:number;
    taxpercent: number;
    listprice: number;

    discount: number;
    discountAmount: number;
    serialno: boolean;
    subtotalproamount: number;
    serialProducts: serialProducts[] = [];
    ratewithdiscount: number;
    availablestock:number;
    newissueLocStock: number;
    newSupplyLocStock: number;
}

export class serialProducts {
    srno: number;
    serialno: string;
    proid: number;
    pid: number;
}