import { common } from './Common';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpModule } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { User } from '../_models/index';
//import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Mainmenumodel } from '../_models/Mainmenumodel';
import { MasterModel } from '../_models/MasterModel';
import { SaveSuccessModel } from '../_models/SaveSucessModel';
import { Observable } from 'rxjs';
import { CoustomerflagModel } from '../_models/CoustomerflagModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('Token'),

    'Token': localStorage.getItem('Token'),
    'dbName': localStorage.getItem('dbName')
  })
};
@Injectable()
export class CompanyService {

  private headers = new Headers({
    'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Token': localStorage.getItem('Token'),
    'dbName': localStorage.getItem('dbName')
  });
  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;

  //private headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {

  }



  getmoduleflag(hero: CoustomerflagModel): Observable<CoustomerflagModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<CoustomerflagModel>(this.customersUrl + 'api/master/getmoduleflag', hero, httpOptions1);
  }

  getFlagsOfCustomer(flagmodule: CoustomerflagModel): Observable<CoustomerflagModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<CoustomerflagModel>(this.customersUrl + 'api/master/getFlagsOfCustomer', flagmodule, httpOptions1);
  }

}