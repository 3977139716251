<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Inventory Location</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12">
                <div class=row style="margin-top:15px;">
                    <div class="col l1"></div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;padding-left: 0px;">
                        <input [(ngModel)]="model.locationcode" #locationcode="ngModel" type="text" class=""
                            id="locationcode" data-toggle="tooltip" data-placement="top" title="Location Code"
                            style="margin-top:1px;" maxlength="30">
                        <label id="lable1" for="locationcode" class="active" style="left: 0px;">Location Code</label>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;padding-right: 0px;">
                        <input [(ngModel)]="model.locationname" #locationname="ngModel" type="text" class=""
                            id="locationname" data-toggle="tooltip" data-placement="top" title="Location Name"
                            style="margin-top:1px;">
                        <label id="lable2" for="locationname" class="active">Location Name</label>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;padding-right: 0px;">
                        <select (change)="onSelectresource($event.target.value)">
                            <option [value]="0" selected>Select one</option>
<<<<<<< HEAD
                            <option *ngFor="let type1 of resourcelst" [value]="type1.id"[selected]="type1.id == model.storekeeperid">
=======
                            <option *ngFor="let type1 of resourcelst" [value]="type1.id"
                                [selected]="type1.id == model.storekeeperid">
>>>>>>> main
                                {{type1.name}}
                            </option>
                        </select>
                        <label id="lable2" for="locationname" class="active">Store Keeper</label>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class=row>
                    <div class="col l1"></div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 10px;padding-left: 0px;">
                        <input [(ngModel)]="model.chkrowMaterial" #chkrowMaterial="ngModel" (change)=onRowMaterial()
                            type="checkbox" style="text-align: 5px" [disabled]="temp==true">&nbsp;&nbsp;
                        Raw Material
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 10px;padding-left: 0px;">
                        <input [(ngModel)]="model.chkProductionLoc" #chkProductionLoc="ngModel"
                            (change)=onProductionLoc() type="checkbox" style="text-align: 5px"
                            [disabled]="temp==true">&nbsp;&nbsp;
                        Production Location
                    </div>
                    <div class="input-field col s2" style="margin-top: 10px;">
                        <input [(ngModel)]="model.chkDespatchLoc" #chkDespatchLoc="ngModel" (change)=onDespatchLoc()
                            type="checkbox" style="text-align: 5px" [disabled]="temp==true">&nbsp;&nbsp;
                        Sales Location
                    </div>
                    <div class="input-field col s2" style="margin-top: 10px;">
                        <input [(ngModel)]="model.chkSpareloc" #chkSpareloc="ngModel" (change)=onSpareloc()
                            type="checkbox" style="text-align: 5px" [disabled]="temp==true">&nbsp;&nbsp;
                        Spare Location
                    </div>

                </div>
                <div class=row>
                    <div class="col l1"></div>
                    <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 0px;padding-left: 0px;">
                        <span>Item Category</span>
                        <select (change)="onSelectitemCat($event.target.value)">
                            <option selected>Select Item Category</option>
                            <option *ngFor="let type of itmCatlst" [value]="type.id"
                                [selected]="type.id == model.itemcategoryid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l4 m4 s4"
                        style="margin-top: 0px;margin-bottom: 0px;padding-right: 0px;">
                        <span>Sub Category</span>
                        <select (change)="onSelectSubCat($event.target.value)">
                            <option selected>Select Sub Category</option>
                            <option *ngFor="let type of subCatlst" [value]="type.id"
                                [selected]="type.id == model.subcategoryid">
                                {{type.name}}</option>
                        </select>
                    </div>

                    <div class="input-field col l2 m2 s2"
                        style="margin-top: 17px;text-align: right;padding-right: 0px;">
                        <button type="button" (click)="btnadd($event)" class="waves-effect waves-light btn"
                            style="margin-left: 0px;">Add</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="padding-left: 0px;padding-right: 0px;">
                        <div class="col l1 m1 s1"></div>
                        <div class="col l10 m10 s10" style="padding-left: 0px;padding-right: 0px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="8%" style="text-align: left;padding-left: 10px;">Sr.No.</th>
                                    <th width="44%" style="text-align: left">Item Category</th>
                                    <th width="44%" style="text-align: left">Sub Category</th>
                                    <th width="8%" style="text-align: center">Action</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of model.itemslst | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                        <td style="text-align: left">{{lst.itemcateName}}</td>
                                        <td style="text-align: left">{{lst.subcatName}}</td>
                                        <td style="text-align: center"><span><img
                                                    src="../../../assets/images/delete.png"
                                                    (click)="btndelts(lst.id, lst.subcatId)" data-toggle="tooltip"
                                                    data-placement="top" title="Delete" width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span> -->
                        </div>
                        <div class="col l1 m1 s1"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="padding-right: 0px;">
                        <div class="input-field col l11 m11 s11" style="text-align: right;padding-right: 0px;">
                            <button type="button" class="waves-effect cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)"
                                class="waves-ripple waves-light btn">Save</button>
                        </div>
                        <!-- <div class="input-field col l6 m6 s6" style="text-align: left">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>