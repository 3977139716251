import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models';
import { MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { SearchCategoryTermsConModel } from './SearchCategoryTermsConModel';
import { Location } from '@angular/common'
import { DropDownModel } from 'src/app/_models/DropDownModel';

@Component({
    selector: 'app-AMCd',
    //  moduleId: module.id,
    templateUrl: 'SearchCategoryTermsCon.html'
})
export class SearchCategoryTermsCon implements OnInit {
   p:any;
    user = new User();
    model = new SearchCategoryTermsConModel();
    categtermlst:SearchCategoryTermsConModel[]=[];
    categtermlst1:SearchCategoryTermsConModel[]=[];
    public i;
    pagename:string;
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, ) {
    this.pagename=this.router.url
    }
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Heading" },
        { id: 2, "name": "Industry Type" },
    ];


    ngOnInit(): void {
      this.getAllCategoryTermCo();
      localStorage.setItem("pagename",this.pagename);
      if (this.pagename != null) {
          this.pagename = this.pagename.replace('/', '');
          this.pagename = this.pagename.replace('/', '');
          this.pagename = this.pagename.replace('%20', ' ');
          this.pagename = this.pagename.replace('%20', ' ');
          this.pagename = this.pagename.replace('%20', ' ');
      }
    }

    getAllCategoryTermCo() {
        this.categtermlst = []
        this.Masterservice.getAllCategoryTermCo(this.model)
            .subscribe(value => {
                this.categtermlst = [];
                this.categtermlst1 = [];
                value.forEach((item) => this.categtermlst1.push({ "id": item.id ,"name": item.name, "heading": item.heading,  "txtserch": item.txtserch, "txtselect": item.txtselect }));
                this.categtermlst = this.categtermlst1;
            });
    }
    
    btnnew() {
        this.router.navigate(['/DefineCategoryTermsCon']);
    }
    
    btnedit(value) {
        this.router.navigate(['/editcattermcon', value]);
    }
    
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {   
            this.model.id = id
            this.Masterservice.deleteCategoryterm(this.model)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
       
    }
    
    onSelectcategory(value) {
        this.model.txtselect = value;
    }
    
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllCategoryTermCo()
            this.model.txtserch = null;
        }
       
        else if (this.model.txtselect == "Heading") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.categtermlst1 = [];
                this.categtermlst.forEach((item) => {
                    if ((item.heading.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.categtermlst1.push({ "id": item.id ,"name": item.name, "heading": item.heading,  "txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.categtermlst = this.categtermlst1;
            }
     
        }else if(this.model.txtselect=="Industry Type"){
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.categtermlst1 = [];
                this.categtermlst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.categtermlst1.push({ "id": item.id ,"name": item.name, "heading": item.heading,  "txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.categtermlst = this.categtermlst1;
            }
        }
        
    }
    
    
    
}
