import { Component, OnInit } from '@angular/core';
import { MasterServices, AlertService, TransactionServices, MenuServices } from 'src/app/_services';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { Location, DatePipe } from '@angular/common'
import { SearchMenuItemModel } from './SearchMenuItemModel';
import { viLocale } from 'ngx-bootstrap';

// import { usertwofieldModel } from './usertwofieldModel';



@Component({
    selector: 'app-login',
    templateUrl: 'DefineMenuItem.html'
})
export class DefineMenuItem implements OnInit {
    model: any = {};
    public i: any = 0;
    user = new User();
    userList2: any;
    lastkeydown2: number = 0;
    id: number;
    constructor( private menuServices: MenuServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
    }
ngOnInit(){
    this.route.queryParams.subscribe(params => {
        this.model.id = + params["id"];
        this.model.modelname= params["modelname"];
        this.model.pagename= params["pagename"];
        this.model.itemname= params["itemname"];

    });
  
}
btnsave(){
    this.menuServices.SaveItemname(this.model)
    .subscribe(value => {
       if(value.status=="OK"){
           this.notifyService.showSuccess(value.message,"Done");
           this.router.navigate(['/Menu'])
       }
       if(value.status=="NO"){
        this.notifyService.showRroor(value.message,"Oops");
       }
    })
}
btncancle(){
    this.router.navigate(['/Menu'])
}
}