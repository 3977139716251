<app-header></app-header>
<div class="container"  style="width: 90%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Call Center Dashboard</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">

            <div class="row">
                <div class="col l12 m12 s12" style="padding-left: 14px;">
                    <div style="margin-top: 20px">
                        <span style="margin-top: 40px;font-size: 15px;">Search [MobileNo,Name,Locality,E-Mail]</span>
                    </div>
                </div>
            </div>
            <div class="row" style="padding-left: 5px;">
                <div class="input-field col s5 l5 m5" style="text-align: center;margin-bottom: 5px;">
                    <input [(ngModel)]="model.txtserach2" #txtserach2="ngModel" type="text" id="searcMOb"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search" id="search">Search Here</label>
                </div>
                <div class=" input-field col s2 l2 m2">
                    <button type="button" (click)="btnsearch()"
                        class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;&nbsp;
                </div>
                <div class=" input-field col s5 l5 m5" style="text-align: end;">
                    <button type="button" (click)="btnaddprospect()" class="waves-ripple waves-light btn"
                        style="margin-right: 5px;">Add Customer</button>&nbsp;&nbsp;
                    <button type="button" class="waves-ripple waves-light btn" (click)="btnnewprospect()">New
                        Prospect</button>
                </div>
            </div>

            <div class="row">
                <div class="col s12 m12 l12 registerBgShadow" style="padding-left: 9px;padding-right: 9px;padding-top: 5px;padding-bottom: 9px;">
                    <div class="col l12 m12 s12"
                        style="background: gainsboro; padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;">
                        <div class="col l3 m3 s3" style="text-align:left;padding-left: 7px;">
                            <span>Customer Name</span>
                        </div>
                        <div class="col l4 m4 s4" style="text-align:left;padding-left: 5px;">
                            <span>Address</span>
                        </div>
                        <div class="col l1 m1 s1" style="text-align:left;padding-left: 5px;">
                            <span>Mobile</span>
                        </div>
                        <div class="col l2 m2 s2" style="text-align:left;padding-left: 3px;">
                            <span>Email</span>
                        </div>
                        <div class="col l2 m2 s2" style="text-align:left;padding-left: 3px;">
                            <span>Date</span>
                        </div>
                        <div class="col l1 m1 s1" style="text-align:left;padding-left: 20px;">
                            <span>Action</span>
                        </div>
                    </div>
                    <mat-accordion style="padding: 0px;">
                        <!-- hideToggle -->
                        <mat-expansion-panel  *ngFor="let lst of modelst | paginate: { itemsPerPage: 10, currentPage: p,totalItems:user.totalItems };let i = index "
                            style="padding: 1px;" (click)="btngetdetails(lst.accountid)">
                            <!-- <span  *ngIf="lst.customer=='P'"></span> -->
                            <!-- (click)="btngetdetails(lst.accountid,i)" -->
                            <mat-expansion-panel-header  style="padding-right: 9px;padding-left: 9px;">
                                <div class="col l12 m12 s12" style="margin-top:20px;color: black;">
                                    <div class="row">
                                        <div class=" col l3 m3 s3" style="text-align:left;">
                                            <span>{{lst.name}}</span>
                                        </div>
                                        <div class=" col l4 m4 s4" style="text-align:left;">
                                            <span>{{lst.address}}</span>
                                        </div>
                                        <div class=" col l1 m1 s1" style="text-align:left;">
                                            <span>{{lst.telephone1}}</span>
                                        </div>
                                        <div class=" col l2 m2 s2" style="text-align:left;">
                                            <span>{{lst.email}}</span>
                                        </div>
                                        <div class=" col l1 m1 s1" style="text-align:left;">
                                            <span>{{lst.callDate | date:"dd-MM-yyyy"}}</span>
                                        </div>
                                        <div class="col s1 m1 l1" style="text-align:left;padding-right: 0px;padding-left: 0px;">

                                            <span style="padding-right: 5px;"><img src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst.accountid,i)" data-toggle="tooltip"
                                                    data-placement="top" title="Edit" width="20px" style="height: 25px;width: 25px;"/></span>

                                            <span style="padding-right: 5px;"><img
                                                    src="../../../assets/images/CreateLead.png"
                                                    (click)="btncreatelead(lst.accountid,i)" title="Create Lead"
                                                    width="20px" style="height: 25px;width: 25px;"/></span>

                                            <span style="padding-right: 5px;"><img
                                                    src="../../../assets/images/Customer-360.png"
                                                    (click)="btnopenaccount360(lst.accountid)" data-toggle="tooltip"
                                                    data-placement="top" title="Account 360" width="20px" style="height: 25px;width: 25px;" /></span>

                                            <!-- <span *ngIf="lst.customer=='P'"><img src="../../../assets/images/Down.png"
                                                    (click)="btngetdetails(lst.accountid,i)" data-toggle="tooltip"
                                                    data-placement="top" title="More" width="20px"  style="height: 25px;width: 25px;" /></span> -->

                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div *ngIf="lst.lstproduct.length>0" class="registerBgShadow col l12 m12 s12"
                                style="text-align: center; margin-top: 10px;">
                                <div class="row" *ngFor="let sublst of lst.lstproduct" style="padding-top: 9px;">
                                    <div class="col l4 m4 s4" style="text-align:left">
                                        <span>{{sublst.name}}</span>
                                    </div>
                                    <div class="col l3 s3 m3" style="text-align:left">
                                        <span>{{sublst.locality}}</span>
                                    </div>
                                    <div class="col s4" style="text-align:left">
                                        <span>Installation On : </span>
                                        <span>{{sublst.installationDate| date:'d MMMM y'}}</span>
                                    </div>
                                    <div class="col s1" style="text-align:left;padding-right: 0px;padding-left: 3px;">
                                        <span style="margin-left: 5px;"><img src="../../../assets/images/servicecall.png"
                                                (click)="btnservicecall(lst.accountid,i,sublst.productid,sublst.id,servicecallModel)"
                                                title="Book Service Call" width="15%" style="height: 25px;width: 25px;" /></span>
                                        <span style="margin-left: 5px;"><img src="../../../assets/images/Location.png"
                                                (click)="btnlocation(locationmodel,sublst.productid,lst.id,i)"
                                                title="Location" width="15%" style="height: 25px;width: 25px;" /></span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="lst.lstproduct.length==0" class="col l11 m11 s11 registerBgShadow"
                                style="margin-left:50px;text-align: center;">
                                <div class="col l12 m12 s12" style="color: red">
                                    <samp> List Not Found </samp>
                                </div>
                            </div>
                        </mat-expansion-panel >
                        <!-- <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span> -->
                    </mat-accordion>
                </div>
                <span style="text-align:right">
                    <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)"></pagination-controls>
                </span>
            </div>

        </div>
    </div>
</div>





<ng-template #locationmodel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="registerBgShadow">
        <div class="modal-content " style="text-align: center;">
            <span style="font-size: 16px;"><b>Modify Location</b></span>
        </div>
        <div class="row">
            <div class="">
                <div class="col l12 m12 s12">
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class=" input-field col s12" style="text-align: center;">
                            <span style="font-size: 15px;">Name:-<span><b>{{name}}</b></span></span>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class=" input-field col s7" style="margin-bottom: 0px;">
                            <input [(ngModel)]="addr" #address="ngModel" type="text" id="address10" title="Address"
                                maxlength="100">
                            <label id="lable10" for="address" class="active"> Address</label>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class=" input-field col s5" style="margin-bottom: 0px;margin-top: 17px"> 
                            <input [(ngModel)]="googlemap" #address="ngModel" type="text" id="address10" title="Address"
                                maxlength="100">
                            <label id="lable10" for="address" class="active"> Google Map Location</label>
                        </div>
                        <div class=" input-field col s3"  style="margin-bottom: 0px;margin-top: 17px">
                            <input type="text" class="autocomplete" [(ngModel)]="pincode" id="pincode2"
                                placeholder="Pincode" (keyup)="getPincodeLikeforproduct($event)" />
                            <input type="hidden" id="acc-id" />
                        </div>
                        <div class=" input-field col s3" style="margin-top: 0px;margin-bottom: 0px">
                            <span>Locality</span>
                            <select (change)=" onSelectproductlocality($event.target.value)">
                                <option *ngFor="let type of lstlocalityforproduct" [value]="type.id"
                                    [selected]="type.name==locality">
                                    {{type.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">

                        <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                            <span>Locale:-</span><br><span><b>{{locale}}</b></span>
                        </div>
                        <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                            <span>City:-</span><br><span><b>{{city}}</b></span>
                        </div>
                        <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                            <span>State:-</span><br><span><b>{{state}}</b></span>
                        </div>
                        <div class=" input-field col s2" style=" padding: 6px;border: 1px solid black;">
                            <span>Country:-</span><br><span><b>{{country}}</b></span>
                        </div>
                        <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                            <span>Area:-</span><br><span><b>{{areaname}}</b></span>
                        </div>
                        <div class=" input-field col s2" style="  padding: 6px;border: 1px solid black;">
                            <span>Region:-</span><br><span><b>{{regionname}}</b></span>
                        </div>
                    </div>
                    <!-- <div class="input-field col s5">
                        <span>Machine Status</span>
                        <select (change)="onSelectmachinestatus($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Machine Status">
                            <option>Select Machine Status</option>
                            <option *ngFor="let lst of machiestatuslst" [value]="lst.id"
                                [selected]="lst.name==serviceCallStatusName">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s5">
                        <input *ngIf="amcddateflag" type="text" placeholder="AMC End Date" [(ngModel)]="amcdEndDate"
                            class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">

                        <input *ngIf="warrentydateflag" type="text" placeholder="Warranty End Date"
                            [(ngModel)]="warrentyEndDate" class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">

                    </div> -->

                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align: right">

                            <button (click)="btnclose()" type="button"
                                class="waves-effect cancelbutton btn">Cancel</button> &nbsp;&nbsp;
                            <button (click)="btnaddproductaddress()" type="button"
                                class="waves-effect waves-light btn">Save</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #servicecallModel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="registerBgShadow">
        <div class="modal-content " style="text-align: center;">
            <span style="font-size: 16px;"><b>Service Call Details</b></span>
        </div>
        <div class="row">
            <div class="row col l12 m12 s12" style="text-align: center;">
                <div class="col l6 m6 s6" style="text-align: center">
                    <span style="font-size: 15px;"> There is an Visit call pending</span> <br>
                    <span style="font-size: 15px;"> Call No :-<b>{{serviceNO}}</b> </span> <br>
                </div>
                <div class="input-field col l6 m6 s6" style="text-align: right">
                    <button (click)="btnclose()" type="button" class="waves-effect cancelbutton btn">Cancel</button>
                    &nbsp;&nbsp;
                    <button (click)="btnservicecalledit()" type="button" class="waves-effect waves-light btn">Continue
                        with Call</button>
                </div>
            </div>

            <div class="row">

            </div>
        </div>
    </div>
</ng-template>