export class DefineSourceModel{
    id: number;
    campaignCode: string;
    campaignName:string;
    campaignStatus:string;
    campaignStatusflag:string;
    description:string;
    startDate:Date;
    endDate:Date;
    userid:number;
    chkselect:boolean;

   
}