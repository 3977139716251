export class SearchUOMModel {
    id: number;
    txtserch: string;
    txtselect:string;
    code:string;
    name:string;
    


}
