<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="input-field col l3 m3 s3" style="margin-top: 14px;margin-bottom:0px;padding-left: 5px;">
                    <span>Search Criteria</span>
                    <select (change)="onSelectSrch($event.target.value)">
                        <option *ngFor="let type of searchtype" [value]="type.name">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-top: 30px;margin-bottom:0px;">
                    <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Text">
                    <label for="search">Search Text</label>
                </div>

                <div class="input-field col l4 m4 s4" style="text-align: end;margin-top: 30px;margin-bottom:0px;">
                    <button type="button" (click)="btnsearch($event)"
                        class="waves-effect waves-light btn">Search</button>&nbsp;
                    <button type="button" (click)="btnNewSch($event)" class="waves-effect waves-light btn">New
                        Scheme</button>
                </div>

            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left">Sr.No.</th>
                            <th width="30%" style="text-align: left">Scheme Code</th>
                            <th width="30%" style="text-align: left">Scheme Name</th>
                            <th width="20%" style="text-align: left">Customer Type</th>
                            <th width="30%" style="text-align: left">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of schemelst |  paginate: { itemsPerPage: 10, currentPage: p };">
                                <td style="text-align: left">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.code}}</td>
                                <td style="text-align: left">{{lst.name}}</td>
                                <td style="text-align: left">{{lst.customerType}}</td>
                                <td style="text-align:center">
                                    <span><img src="../../../assets/images/edit.png" (click)="btnedit(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Edit"
                                            width="20px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Delete"
                                            width="20px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/listicon.png"
                                            (click)="openproduct(coupenmodel,lst.id)" data-toggle="tooltip"
                                            data-placement="top" title="Coupen list" width="20px"
                                            style="height: 20px;width: 20px;" /></span>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>

            <div>
                <ng-template #coupenmodel>
                    <div class="col l12 m12 s12  modal-header" style="text-align: end">
                        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="registerBgShadow">
                        <div class="modal-content " style="text-align: center;">
                            <span style="font-size: 20px;">{{pagename1}}</span><br>
                        </div>

                        <div class="row">
                            <span style="margin-left:950px;"><img src="../../../assets/images/excel2.png"
                                    onclick="tableToExcel('excel')" data-toggle="tooltip" data-placement="top"
                                    title="Excel" width="30px" /></span>

                            <div class="col l12 m12 s12">
                                <div class="col l6 m6 s6">
                                    <label>Scheme Code:&nbsp;&nbsp;<b>{{model.code}}</b></label>
                                </div>
                                <div class="col l6 m6 s6">
                                    <label>Scheme Name:&nbsp;&nbsp;<b>{{model.name}}</b></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="">
                                <div class="col l12 m12 s12">
                                    <table border="1px solid black;"
                                        style="width: 100%;border-collapse: collapse; padding-top: 10px; "
                                        cellpadding="7" id="excel">
                                        <thead style="background: gainsboro;">
                                            <th width="10%" style="text-align: center">Sr.No.</th>
                                            <th width="30%" style="text-align: center">Coupon Code</th>
                                            <th width="30%" style="text-align: center">Quantity</th>
                                            <th width="20%" style="text-align: center"
                                                *ngIf="model2.schemeUnlimited == true">Coupen Days</th>
                                            <th width="30%" style="text-align: center"
                                                *ngIf="model2.schemeUnlimited == false">Validity</th>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let lstcoupen of coupenlst | paginate: { itemsPerPage: 10, currentPage: q }">
                                                <td style="text-align: center">{{lstcoupen.srno}}</td>
                                                <td style="text-align: center">{{lstcoupen.code}}</td>
                                                <td style="text-align: center">{{lstcoupen.coupenDays}}</td>
                                                <td style="text-align: center" *ngIf="model2.schemeUnlimited == true">
                                                    {{lstcoupen.days}}</td>
                                                <td style="text-align: center" *ngIf="model2.schemeUnlimited == false">
                                                    {{lstcoupen.todate | date:"dd-MM-yyyy"}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span style="text-align:right">
                                        <pagination-controls (pageChange)="q = $event"></pagination-controls>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>


            </div>
        </div>
    </div>