<app-header></app-header>

<div class="container" style="width:50%">
  <div class="row">
    <div class="col l12 m12 s12">
      <div class="col l2 m2 s2"></div>
      <div class="heading col l8 m8 s8">
        <span style="font-size: 25px;">Tax Group</span>
      </div>
      <div class="col l2 m2 s2"></div>
    </div>
    <div class="col s12 m12 l12 registerBgShadow">
      <div class="row">
        <div class="col l12 m12 s12">
          <div class="col s12 m12 l12">
            <div class="row" style="padding-top: 30px;">
              <div class="input-field col l1 m1 s12">
              </div>
              <div class="input-field col l5 m5 s12" style="text-align: center;margin: 0px;">
                <input [(ngModel)]="model.name" #name="ngModel" type="text" class="" data-toggle="tooltip"
                  data-placement="top" title="Name" id="name" name="name" required>
                <label id="lable1" for="name">Name</label>
                <div *ngIf="name.invalid && (name.dirty || name.touched) " class="alert alert-danger"
                  style="color: red">
                  <div *ngIf="name.errors.required">
                    Name required
                  </div>
                </div>
              </div>
              <div class="input-field col l5 m5 s12" style="text-align: center;margin: 0px;">
                <input [(ngModel)]="model.persentage" #persentage="ngModel" type="number" OnlyNumber="true"
                  data-toggle="tooltip" data-placement="top" title="Value" id="persentage" name="Percentage" required>
                <!-- appForbiddenName="bob" -->
                <label id="lable2" for="persentage">Value</label>
                <!-- <span class="error" style="color: red; display: none">* Input digits (0 - 9)</span> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-field col l11 m11 s11" style="text-align: right">
              <button type="button" class="waves-effect cancelbutton btn" style="margin-left: 5px;"
                (click)="cancle()">Cancel</button>&nbsp;&nbsp;
              <button type="button" (click)="btnsave()" class="waves-effect waves-light btn"
                style="margin-right: 5px;">Save</button>
            </div>
            <!-- <div class="input-field col l6 m6 s6" style="text-align: left">
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>