<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">

                <div class="row">
                    <div class="col l1"></div>
                    <div class="input-field col l3 m3 s3">
                        <span>Marketing Cost Head</span>
                        <select (change)="onSelectmrktcost($event.target.value)">
                            <option selected> Select Marketing Cost Head</option>
                            <option *ngFor="let type of mrktcostlst" [value]="type.id"
                                [selected]="type.id == model.marketingcostid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="col l6"></div>
                </div>

                <div class="row">
                    <div class="col l1"></div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.code" #code="ngModel" type="text" class="" id="code"
                            data-toggle="tooltip" data-placement="top" title="Code">
                        <label for="code" class="active">Code</label>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.name" #name="ngModel" type="text" class="" id="name"
                            data-toggle="tooltip" data-placement="top" title="Name">
                        <label for="name" class="active">Name</label>
                    </div>
                    <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.description" #description="ngModel" type="text" class=""
                            id="description" data-toggle="tooltip" data-placement="top" title="Description">
                        <label for="description" class="active">Description</label>
                    </div>
                    <div class="col l1"></div>
                </div>

                <div class="row">
                    <div class="col l1"></div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 28px;">
                        <input [(ngModel)]="model.chkselectvalue" #chkselectvalue="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;
                        Value
                    </div>
                    <div class="input-field col l4 m4 s4" style="margin-top: 0px;">
                        <!-- *ngIf=" model.chkselectvalue==false" -->
                        <span>Unit of Measure</span>
                        <select (change)="onSelectuom($event.target.value)" [disabled]="model.chkselectvalue==true">
                            <option selected> Select Unit of Measure</option>
                            <option *ngFor="let type of uomlst" [value]="type.id" [selected]="type.id == model.uomid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 17px;">
                        <!-- *ngIf=" model.chkselectvalue==false" -->
                        <input [(ngModel)]="model.rate" #rate="ngModel" type="text" class="" id="rate"
                            data-toggle="tooltip" data-placement="top" title="Rate" style="text-align: right;"
                            [disabled]="model.chkselectvalue==true">
                        <label for="rate" class="active">Rate</label>
                    </div>
                    <div class="col l1"></div>
                </div>

                <div class="row">
                    <div class="input-field col l11 m11 s11" style="text-align: right">
                        <button (click)="btnback()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnsavemarketing()" type="button" class="waves-effect waves-light btn"
                            style="margin-left: 5px;">Save</button>
                    </div>
                    <div class="col l1"></div>
                </div>
            </div>
        </div>
    </div>
</div>