import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, ProductionService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { Pipe } from '@angular/core';
import { DefineServiceChargesModel } from 'src/app/Master/ServiceChergesMaster/DefineServiceChargesModel';
declare var $: any;

@Component({
    selector: 'app-DailyProductionPlan',
    templateUrl: 'DailyProductionPlan.html',
})
export class DailyProductionPlan implements OnInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private masterservice: MasterServices,
        private transactionService: TransactionServices,
        private ProductionService: ProductionService) {
    }
    model: any = {};
    inventorylocations: DropDownModel[] = [];
    user = new User();
    p: any;
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    subCatlst1: DropDownModel[] = [];
    userList: any;
    userList1: any;
    ngOnInit() {

        this.route.queryParams.subscribe(params => {
            this.model.id = params["id"];
        });
        this.getInventoryLocations();
        this.model.lstitem = [];
        this.model.plandate = new Date();
        if (this.model.id != null) {
            this.ProductionService.getProductiondetails(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.plandate = new Date(data.plandate);
                        this.model.locationid = data.locationid;
                        this.masterservice.getinventorylocatinCategories(this.model)
                            .subscribe(
                                data => {
                                    this.itmCatlst = data;
                                });
                    });


        }

    }
    getInventoryLocations() {
        this.user.sendstring = "P";
        this.masterservice.getAllInventoryLocations(this.user)
            .subscribe(
                data => {
                    this.inventorylocations = data;
                });
    }
    onSelectLocation(value) {
        this.itmCatlst = [];
        this.model.lstitem = [];
        this.model.itemcategoryid = null;
        if (value == 0) {
            this.notifyService.showRroor("Select Location", "Opps");
            return;
        } else {
            this.model.locationid = value;
            this.masterservice.getinventorylocatinCategories(this.model)
                .subscribe(
                    data => {
                        this.itmCatlst = data;
                    });
        }


    }
    onSelectitemCat(value) {
        this.subCatlst = [];
        this.model.itemsubcategoryid = null;
        if (value == 0) {
            this.notifyService.showRroor("Select Item Category", "Opps");
            return;
        } else {
            this.model.itemcategoryid = value;
            this.masterservice.getinventorylocatinSubCategories(this.model)
                .subscribe(
                    data => {
                        this.subCatlst = data;
                    });
        }


    }
    onChangeOfqantity(index){
        this.model.lstitem[index].productionplanquantity=this.model.lstitem[index].productionquantity-this.model.lstitem[index].availablestock
      if(this.model.lstitem[index].productionplanquantity<0){
        this.model.lstitem[index].productionplanquantity=0;
      }
    }


    onSelectSubCat(value) {
        this.model.itemid = null;
        this.model.itemname = null;
        if (value == 0) {
            this.notifyService.showRroor("Please Select Item Sub Category", "Opps");

            return;
        } else {
            this.model.itemsubcategoryid = value;
        }

    }

    btnAdd() {
        if (this.model.itemcategoryid == null || this.model.itemcategoryid == 0) {
            this.notifyService.showRroor("Select Item Category", "Opps");
            return;
        }
        if (this.model.itemsubcategoryid == null || this.model.itemsubcategoryid == 0) {
            this.notifyService.showRroor("Please Select Item Sub Category", "Opps");
            return;
        }
        if (this.model.itemid == null || this.model.itemid == 0) {
            this.notifyService.showRroor("Please Select Item", "Opps");
            return;
        }
        if (this.model.quantity == null || this.model.quantity == 0 || this.model.quantity == "") {
            this.notifyService.showRroor("Please Enter Quantity", "Opps");
            return;
        }
        let itemflag = false;
        this.model.lstitem.forEach(element => {
            if (this.model.itemid == element.itemid) {
                itemflag = true;
                return;
            }
        });

        if (itemflag) {
            this.notifyService.showRroor("Item already Present", "Opps");
            return;
        }
        let prod=  this.model.quantity-this.model.availablestock ;
        if(prod<=0){
            prod=0;
        }
        this.model.lstitem.push({
            id: null,
            srno: null,
            planID: null,
            plandate: null,
            locationid: this.model.locationid,
            itemcategoryid: this.model.itemcategoryid,
            itemsubcategoryid: this.model.itemsubcategoryid,
            itemname: this.model.itemname,
            itemid: this.model.itemid,
            quantity: this.model.quantity,
            itemcode: this.model.itemcode,
            productionquantity: this.model.quantity,
            productionplanquantity:prod,
            availablestock: this.model.availablestock,
        })

        this.model.availablestock = null;
        this.model.itemname = null;
        this.model.itemid = null;
        this.model.quantity = null;
        this.model.itemcode = null;
    }
    getItemLike() {
        if (this.model.itemcategoryid == null || this.model.itemcategoryid == 0) {
            this.notifyService.showRroor("select item categories", "Opps");
            return;
        }
        if (this.model.itemsubcategoryid == null || this.model.itemsubcategoryid == 0) {
            this.notifyService.showRroor("select item Sub categories", "Opps");
            return;
        }
        let userId = (<HTMLInputElement>document.getElementById('productname')).value;
        this.userList = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.model.itemcategoryid;
        this.user.locationID=this.model.locationid;
        this.user.trnid = this.model.itemsubcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList.push({
                        "itemname": item.itemname,
                        "itemcode": item.itemcode,
                        "itemid": item.itemid,
                        "uom": item.uom,
                        "dimensions": item.dimensions,
                        "diamensionUnit": item.diamensionUnit,
                        "availablestock": item.availablestock
                    }));
                    var names = "";
                    for (let i = 0; i < this.userList.length; i++) {
                        searchDataObj[this.userList[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList[i].itemname;
                        } else {
                            names = names + "," + this.userList[i].itemname;
                        }
                    }
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem(txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem(txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadONselectitem(name) {
        this.userList.forEach(element => {
            if (element.itemname.trim() == name.trim()) {
                this.model.itemid = element.itemid;
                this.model.itemname = element.itemname;
                this.model.itemcode = element.itemcode;
                this.model.uom = element.uom;
                this.model.availablestock = element.availablestock;
            }
        });
    }
    deleleitem(id, i) {
        if (id == null) {
            this.model.lstitem.splice(i, 1);
        } else {
            this.user.trnid = id;
            this.user.userid = Number(localStorage.getItem("userid"));
            this.ProductionService.deleteitemformplan(this.user)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess("Item Deleted Successfully", "Success");
                            this.model.lstitem.splice(i, 1);
                        }
                    });
        }
    }
    btnsave() {
        if (this.model.lstitem.length <= 0) {
            this.notifyService.showRroor("Add At least One Item", "Opps");
            return;
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.ProductionService.SaveDailyProductionplan(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Daily Plan Saved", "Success");
                       
                        this.model.id=data.message
                        this.router.navigate(['/Plan Item Conversion',this.model.id]);
                        
                    }
                });
    }

    btnback() {
        this.router.navigate(['/Production Plan']);

    }
    btncancle(){
        
    }

    changedate(){
        if(this.model.plandate<new Date()){
            this.notifyService.showRroor("Date Not be Greater than Current Date","Opps");
            this.model.plandate=new Date();
            return;
        }
    }
}