<div id="top-strip">
    <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '25px',backdropBackgroundColour: 'rgba(18, 18, 128, 0.3)' }" style="background-color: #d03221;/* color: red; */"></ngx-loading> -->
    <div class="row" style="background-color:gainsboro">
        <div class="col l2 m2 s4" style="margin-top: 1%; margin-bottom: 1%">
            <img src="../../assets/images/RavishSysLogo.png" width="50%" />
            <!-- <img src={{imgname}} width="108" height=62 alt="Home" (click)="btnhome()" /> -->
        </div>
        <div class="col l8 m8 s4" style="text-align: center">
            <span style="font-size: 20px; color: black"><b><span>Welcome&nbsp;</span>{{username}}</b>
            </span>&nbsp;&nbsp;<br />
            <span style="font-size: 15px;color: black"><b>{{today | date:'MMMM d, y'}}</b></span>
        </div>
        <div class="right-align col l2 m2 s4" style="margin-top: 1%; margin-bottom: 1%">
            <span><img src="../../assets/images/easy-dry-logo.png" width="50%"/></span>
            <span style="font-size: 15px;color: black;text-align: center;"><b>{{companyCode}}</b></span>
        </div>
    </div>
</div>
<div class="rmm style" *ngIf="isLoggedIn$">
    <form class="form-signin" (ngSubmit)="logout()" #f="ngForm">
        <div class="col sm12">
            <div class="row menulist" style="cursor: pointer;    height: 38px;">
                <div class="col sm10" style="height: 38px;">
                    <ul>
                        <li *ngFor="let menu of mainmenus"><a *ngIf="menu.mainmenulabel=='Dashboard'"
                                (click)="redirectUser(menu.mainmenulabel, menu.mainmenulink)"
                                style="background-color: #db422e;color: white">{{menu.mainmenulabel}}</a>
                            <a *ngIf="menu.mainmenulabel!='Dashboard'"
                                (click)="redirectUser(menu.mainmenulabel,menu.mainmenulink)">{{menu.mainmenulabel}}</a>
                            <ul>
                                <li *ngFor="let submenu of menu.lstsubmenu"><a
                                        (click)="redirectUser(submenu.submenulabel,submenu.submenulink)">{{submenu.submenulabel}}</a>
                                    <ul>
                                        <li *ngFor="let childmenu of submenu.lstchildmenu"><a
                                                (click)="redirectUser(childmenu.submenulabel,childmenu.submenulink)">{{childmenu.submenulabel}}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <div class="col sm2" style="float: right">
                    <ul>
                        <li style="background-color: #57356f">
                            <a (click)="btnlogout()" style="color: white">Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </form>
</div>