export class SuperwisingAuthorityWiseOrderReportModel {
    frmdate: Date;
    todate: Date;
    companyname: String;
    companyaddress: string;
  
    regionid: number;
    // areaid: number;
    //  branchid: number;
    prodCatID: number;
    date: Date;
    orderAcceptanceNo:string;
    name: String;//region
    areaname: string;
    branchname: string;
    resourcename: string;
    status:string;
    txtselect: string;
    searchtype: string;
  
    // areaintlst: number[];
    productintlst:number[];
    branchintlst:number[];
    citylst:number[];
    cityintlst:number[];
    customerName:string;
    localityName:string;
    productName:string;
    branchName:string;
    quantity:string;
    orderValue:string;
    uom:string;
      finalamount:string;
    cityid:number;
  }