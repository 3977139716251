export class ServiceProductModel{
    id:number;
    productid:number;
    name:string;
    callserviceno:string;
    serialno:string;
    status:string;
    sparedescription:string;
    uom:string;
    cashcheckselect:boolean;
    gstpersentage:string;
    rate:number;
    amount:number;
    quantity:number;
    customertypeId :number;
    model:number;
    customervalue:number;
    Otpshow:string;
    closureOTP:string;
    txtselect:string;
    txtserch:string;


   
}
export class Products1 {
    id: number;
    chkselectpro: boolean
    pcid: number;
    pcname: string;
    pid: number;
    name: number
    description: string;
    procategorylst:Products1[];
    procategorylst1:Products1[]=[];
    prosprocatid: number;
    uom: string;
    uomid: number;
    gstamount: number;
    gstrate: number;
    discount: number;
    price: number;
    productid: number;
    prouctname: string;
    currency: string;
    currencyName:string;
    currencyid: number;
    minimumPrice: number;
    discountAllowed: number;
    taxpercent: number;
    added: boolean
    //currencylst: CurrencyModel[] = [];
    categoriesdescription: string;
}