<div *ngIf="isMobile==true">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>
        <nav class="grayTheme">
            <div class="nav-wrapper">
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a
                    (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>&nbsp;
            </li>
        </ul>
        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="selection no-pad-bot OprntCol" id="div1" style="padding: top 7px;">
        <h1 class="header center" style="margin-bottom:9px;">Order Acceptance</h1>
        <div class="container" style="width:94%;">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="srchBy p-0">
                        <div class="input-field col s12" style="margin-bottom:0px;">
                            <select (change)="onSelectSrch($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                            <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                                data-toggle="tooltip" data-placement="top" title="Search Text">


                            <div>
                                <div class="input-field col l12 m12 s12:w-20 right"
                                    style="text-align: end;margin-top: 30px;margin-bottom:0px;text-align:left;">
                                    <img src="../../../assets/images/search.png" style="margin-right: 8px;" width="25px"
                                        (click)="btnsearch($event.target.value)">
                                </div>
                            </div>
                            <div>
                                <div class="input-field col 12px"
                                    style="text-align: left;margin-top: 25px;margin-bottom: 0px;">
                                    <div>Status</div>
                                    <input [(ngModel)]="model.allchk" #pendingchk="ngModel" type="checkbox"
                                        style="text-align: 5px">&nbsp;&nbsp;All



                                    <input [(ngModel)]="model.pendingchk" #pendingchk="ngModel" type="checkbox"
                                        style="text-align: 5px">&nbsp;&nbsp;Pending



                                    <input [(ngModel)]="model.approvechk" #approvechk="ngModel" type="checkbox"
                                        style="text-align: 5px">&nbsp;&nbsp;Approved
                                </div>
                            </div>

                            <div class="col s12">
                                <div class="tabInnerBox" *ngFor="let lst of orderlst">
                                    <div class="introTab">

                                        <label class="w-80 dis-block">Sr
                                            No &nbsp;{{lst.srno}} &nbsp; </label>
                                        <label class="w-20 dis-block">{{lst.typeCust}}</label>
                                        <label class="w-100 dis-block font-weight-bold ">{{lst.customername}}</label>
                                        <label class="w-100 dis-block">Quotation
                                            Code &nbsp;{{lst.quotationCode}}</label>
                                        <label class="w-100 dis-block">Date &nbsp;{{lst.dateTime |
                                            date:"dd-MM-yyyy"}}</label>

                                        <label class="w-100 dis-block">Status &nbsp;{{lst.status}}
                                            <span *ngIf="lst.approveorderflag=='A'||lst.approveorderflag=='Y' "><img
                                                    src="../../../assets/images/view.png"
                                                    (click)="btnView(lst.quotationID,lst.dealerflag)"
                                                    data-toggle="tooltip" data-placement="top" title="View Order"
                                                    width="20px" /></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </label>
                                        <label class="w-100 dis-block">Amount &nbsp;&#8377;{{lst.totalAmount |
                                            number:'1.2-2'}}</label>

                                        <span *ngIf="lst.approveorderflag=='P'||lst.approveorderflag=='N' ">
                                            <img src="../../../assets/images/edit.png"
                                                (click)="btnedit(lst.quotationID,lst.dealerflag)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px" /></span>




                                    </div>
                                </div>
                            </div>
                            <div class="col s12" style="margin-top: 10px;">
                                <lable (click)="first()" class="w-30"
                                    style="font-size: 20px;margin-right: 17px;color: #645959;" *ngIf="this.sum > 1">
                                    <b>First</b>
                                </lable>
                                &nbsp;&nbsp;
                                <lable class="w-30">
                                    <span *ngIf="this.sum > 1" style="margin-right: 17px;"><img
                                            src="../../../assets/images/previous_icon.png" width="35px"
                                            data-toggle="tooltip" data-placement="top" (click)="btnprevious()"
                                            data-placement="top" title="Previous Page"></span>&nbsp;&nbsp;

                                    <span><img src="../../../assets/images/next_icon.png" width="35px"
                                            data-toggle="tooltip" data-placement="top" (click)="btnnext()"
                                            data-placement="top" title="Next Page"></span>&nbsp;&nbsp;
                                </lable>
                                <lable (click)="last()" class="w-30"
                                    style="font-size: 20px;color: #645959; margin-left: 17px;" *ngIf="this.sum > 1">
                                    <b>Last</b>
                                </lable>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>


<div *ngIf="isMobile == false">
    <app-header></app-header>
    <div class="container" style="width: 80%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Order Acceptance</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>

            <div class="col l12 m12 s12 registerBgShadow">
                <div class="row" style="padding-left: 10px;">
                    <div class="input-field col l2 m2 s2" style="margin-top: 14px;margin-bottom:0px;padding-left: 5px;">
                        <span>Search Criteria</span>
                        <select (change)="onSelectSrch($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l2 m2 s2" style="margin-top: 30px;margin-bottom:0px;">
                        <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Text">
                        <label for="search">Search Text</label>
                    </div>

                    <div class="input-field col l1 m2 s2" style="text-align: left;margin-top: 25px;margin-bottom: 0px;">
                        <div>Status </div>
                        <input [(ngModel)]="model.allchk" #pendingchk="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;All
                    </div>
                    <div class="input-field col l1 m1 s1" style="text-align: left;margin-top: 45px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.pendingchk" #pendingchk="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;Pending
                    </div>
                    <div class="input-field col l2 m1 s1" style="text-align: left;margin-top: 45px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.approvechk" #approvechk="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;Approved
                    </div>
                    <div class="input-field col l4 m4 s4"
                        style="text-align: end;margin-top: 30px;margin-bottom:0px;text-align: left;">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Search</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsampleIn()" class="waves-effect waves-light btn"
                            style="margin-left: 5px;">Sample Invoice</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12" style="margin-top: 9px;padding-left: 9px;padding-right: 9px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="10" style="text-align: left;padding-left: 10px;">Sr. No.</th>
                                <th width="10" style="text-align: left">Customer Type</th>
                                <th width="25" style="text-align: left">Customer Name</th>
                                <th width="20" style="text-align: left">Quotation No.</th>
                                <th width="20" style="text-align: left">Quotation Date</th>
                                <th width="10" style="text-align: left">Status</th>
                                <th width="15" style="text-align: right">Value</th>
                                <th width="10" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of orderlst | paginate: { itemsPerPage: 10, currentPage: p,totalItems:model.totalcount } ;let i = index">
                                    <td style="text-align: left;padding-left: 10px;">{{i+1}}</td>
                                    <td style="text-align: left">{{lst.typeCust}}</td>
                                    <td style="text-align: left">{{lst.customername}}</td>
                                    <td style="text-align: left">{{lst.quotationCode}}</td>
                                    <td style="text-align: left">{{lst.dateTime | date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align: left">{{lst.status}}</td>
                                    <td style="text-align: right">{{lst.totalAmount | number:'1.2-2'}}</td>
                                    <td style="text-align: center"> <span
                                            *ngIf="lst.approveorderflag=='P'||lst.approveorderflag=='N' "><img
                                                src="../../../assets/images/edit.png"
                                                (click)="btnedit(lst.quotationID,lst.dealerflag)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px" /></span>
                                        <span
                                            *ngIf="lst.approveorderflag=='A'||lst.approveorderflag=='Y'||lst.approveorderflag=='C'"><img
                                                src="../../../assets/images/view.png"
                                                (click)="btnView(lst.quotationID,lst.dealerflag)" data-toggle="tooltip"
                                                data-placement="top" title="View Order" width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)">
                            </pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>