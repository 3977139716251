<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Assign Enquiry</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="col l12 m12 s12">
                <div class="row" style="margin-top: 2%">
                    <div class="col l1"></div>
                    <div class="col l5 m5 s5">
                        <span style="font-weight: bold; font-size: 15px">Customer
                            Name:&nbsp;<span>{{model.accountname}}</span></span><br />
                        <span
                            style="font-weight: bold; font-size: 15px">Address:&nbsp;<span>{{model.address}}</span></span>
                    </div>
                    <div class="col l5 m5 s5">
                        <span style="font-weight: bold; font-size: 15px">Enquiry Name:&nbsp;<span>{{model.enquiryName}}</span></span>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class="row">
                    <div class="col l1"></div>
                    <div class="col l5 m5 s5">
                        <span style="font-weight: bold; font-size: 15px">Owner
                            Name:&nbsp;<span>{{model.assignedByName}}</span></span>
                    </div>
                    <div class="input-field col l5 m5 s5" style="margin: 0px">
                        <span>Assign To</span>
                        <select (change)="onSelectAssignTo($event.target.value)">
                            <option selected value="0">Select Enquiry</option>
                            <option *ngFor="let lst of assignlst" [value]="lst.id">
                                {{lst.name}}</option>
                        </select>
                    </div>
                    <div class="col l1"></div>
                </div>


                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right ;padding-right: 0px;">
                        <button (click)="btnback()" type="button" class="waves-effect cancelbutton btn">Cancel</button>
                        &nbsp;&nbsp; <button (click)="btnsaveReAssignTo()" type="button"
                            class="waves-effect waves-light btn">Save</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>