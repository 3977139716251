<app-header></app-header>
<div class="container" style="width: 90%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12" style="padding-top: 12px;">
                    <div class="row" style="margin-left:0px;margin-right: 0px;">
                        <div class=" input-field col s6 m6 l6"
                            style=" margin-top: 12px;margin-bottom: 0px;padding-left: 5px;" *ngIf="edit">
                            <input [(ngModel)]="model.customername" #customername="ngModel" type="text"
                                id="customername" title="Name" maxlength="100">
                            <label id="lable1" for="customername" style="margin-bottom: 5px;left: 5px;">Name</label>
                        </div>
                

                        <div class="col s6 m6 l6" style=" font-size: 15px;margin-top: 20px;" *ngIf="!edit">
                            <b>{{model.customername}}</b>
                        </div>
                        <div class="col s3 m3 l3" style=" margin-top: 20px;text-align: start;">
                            <span *ngIf="forflag=='createlead'"><span style="font-size: 15px;">Lead Date:&nbsp;<b>
                                        {{currentdate | date:'d MMMM y'}}</b></span></span>
                        </div>

                    </div>
                    <div class="row" style="padding-left: 9px;">
                        <div class="col s6 m6 l6" *ngIf="!edit" style="text-align: start">
                            <span style="font-size: 15px;">Customer Type:&nbsp;<b>{{model.customertypename}}</b></span>
                        </div>
                        <div class=" input-field col s4" *ngIf="edit"
                            style="margin-bottom:0px;margin-top:0px;padding-left: 5px;">
                            <span>Customer Type</span>
                            <select (change)=" onSelectcustmertype($event.target.value)">
                                <option *ngFor="let type of lstcustomertype"
                                    [selected]="type.name==model.customertypename" [value]="type.id">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field  col s2" style="padding: 0px;margin: 0px;"
                            *ngIf="model.industrytypebyCtypeflag&&edit">
                            <div class=" input-field col s12" style="padding: 0px;margin-bottom:0px;margin-top:0px;">
                                <span>Industry Type</span>
                                <select (change)=" onSelectindustry($event.target.value)">
                                    <option *ngFor="let type of lstindustry" [value]="type.id"
                                        [selected]="type.name==model.industrytypename">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col s2 m2 l2" *ngIf="model.industrytypebyCtypeflag&&!edit"
                            style="padding-left: 4px;">
                            <span style="font-size: 15px;">Industry
                                Type:&nbsp;<b>{{model.industrytypename}}</b></span>&nbsp;&nbsp;&nbsp;
                        </div>

                        <div class=" input-field  col s3" style=" margin: 0px;"
                            *ngIf="model.industrytypebyCtypeflag&&edit">
                            <div class=" input-field col s12" style="padding: 0px;" *ngIf="edit"
                                style="margin-bottom:0px;margin-top:0px;">
                                <span>Constitution</span>
                                <select (change)=" onSelectconstutution($event.target.value)">
                                    <option *ngFor="let type of lstconstutution" [value]="type.id"
                                        [selected]="type.name==model.constitutionname">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col s2 m2 l2" *ngIf="model.industrytypebyCtypeflag&&!edit"
                            style="margin: 0px;padding-left: 4px;">
                            <span *ngIf="!edit"
                                style="font-size: 15px;">Constitution:&nbsp;<b>{{model.constitutionname}}</b></span>
                        </div>
                        <div class="col l3 m3 s3"   >
                            <p><input type="checkbox" [(ngModel)]="model.chkseledel" id="chkseledel"
                                    #chkseledel="ngModel">&nbsp;&nbsp;
                                <label for="chkseledel"> Dealer</label>
                            </p>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 9px;" *ngIf="model.chksuperVisiorAuth">
                        <!-- <div class="input-field col l4 m4 s4" *ngIf="edit">
                            <input type="text" class="autocomplete" [(ngModel)]="model.supervisiorAuth"
                                id="supervisiorAuth" (keyup)="getSuperAuthorities($event)" data-toggle="tooltip"
                                data-placement="top" title="Supervising Authority" />
                            <input type="hidden" id="acc-id" />
                            <label class="active" for="supervisiorAuth">Supervising Authority</label>
                        </div> -->
                        <div class="ng-autocomplete pinRowgrp input-field col l4 m4 s4" *ngIf="edit">
                            <ng-autocomplete [data]="supervisiorAuth1" type="text" [(ngModel)]="model.supervisiorAuth1"
                                placeholder="Supervising Authority" [searchKeyword]="keyword4" [minQueryLength]="3"
                                id="supervisiorAuth" (inputChanged)='onChangeSearch4($event)'
                                (selected)='selectEvent4($event)' (inputFocused)='onFocused4($event)'
                                [itemTemplate]="itemTemplate4" [notFoundTemplate]="notFoundTemplate4">
                            </ng-autocomplete>

                            <ng-template #itemTemplate4 let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate4 let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>

                        <div class="input-field col l4 m4 s4" *ngIf="!edit">
                            <span>Supervising Authority</span>
                            <br>
                            <span>{{model.supervisiorAuth}}</span>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="padding-left: 0px;padding-right: 0px;margin-right: 0px;margin-left: 0px;">
                        <div class=" input-field col m1 s1 l1" style="margin-top: 17px;" *ngIf="edit">
                            <input [(ngModel)]="model.unitNumber" #unitNumber="ngModel" id="unitNumber" type="text"
                                data-toggle="tooltip" data-placement="top" title="Unit Number" required>
                            <label id="unit" for="unitNumber" class="active">Unit Number</label>
                        </div>
                        <div class=" input-field col s3 m3 l3" *ngIf="edit"
                            style="margin-top: 17px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.buildingName" #buildingName="ngModel" id="buildingName"
                                type="text" data-toggle="tooltip" data-placement="top" title="Building Name" required>
                            <label id="buildin" for="buildingName" class="active">Building Name</label>
                        </div>
                        <div class=" input-field col s4 m4 l4" *ngIf="edit"
                            style="margin-top: 17px;margin-bottom: 5px;padding-left: 5px;">
                            <input [(ngModel)]="model.address" #address="ngModel" type="text" id="address"
                                title="Principal Address">
                            <label id="address" for="add" style="left: 5px;" class="active">Principal Address</label>
                        </div>

                        <div class="col s3 m3 l3" *ngIf="!edit">
                            <span style="font-size: 15px;">
                                Unit Number:&nbsp;<span><b>{{model.unitNumber}}</b></span></span>
                        </div>
                        <div class="col s3 m3 l3" *ngIf="!edit">
                            <span style="font-size: 15px;">
                                Building Name:&nbsp;<span><b>{{model.buildingName}}</b></span></span>
                        </div>
                        <div class="col s6 m6 l6" *ngIf="!edit">
                            <span style="font-size: 15px;">Principal
                                Address:&nbsp;<span><b>{{model.address}}</b></span></span>
                        </div>

                        <!-- <div class=" input-field col s1" *ngIf="edit" style="margin-top: 17px;margin-bottom: 5px;">
                            <input type="text" class="autocomplete" [(ngModel)]="model.pincode" id="pincode1"
                                placeholder="Pincode" (keyup)="getPincodeLike($event)" title="Pincode" />
                            <input type="hidden" id="acc-id" />
                        </div> -->

                        <div class="ng-autocomplete pinRowgrp input-field col s2" *ngIf="edit"
                            style="margin-bottom: 5px;margin-top: 17px;">
                            <ng-autocomplete [data]="pincodelist" type="text" [(ngModel)]="model.pincode"
                                placeholder="pincode" id="pincode" [searchKeyword]="keyword" [minQueryLength]="3"
                                (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                        <div class="col s6 m6 l6" *ngIf="!edit" style="margin: 0px;">
                            <span style="font-size: 15px;">Locality:&nbsp;<span><b>{{model.locality}}</b></span></span>
                        </div>
                        <div class="col s2 m2 l2" *ngIf="!edit">
                            <span style="font-size: 15px;">Pincode:&nbsp;<span><b>{{model.pincode}}</b></span></span>
                        </div>
                        <div class=" input-field col s2" *ngIf="edit" style="margin-top: 0%;margin-bottom: 5px;">
                            <span>Locality</span>
                            <select (change)=" onSelectlocality($event.target.value)">
                                <option *ngFor="let type of lstlocality" [value]="type.id"
                                    [selected]="type.name==model.locality">
                                    {{type.name}}</option>
                            </select>
                        </div>

                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;margin-bottom: 5px;">
                        <table>
                            <tbody>
                                <tr style="background: gainsboro;">
                                    <td width="17%"> <span
                                            style="font-size: 15px;">Locale:<br><span><b>{{model.locale}}</b></span></span>
                                    </td>
                                    <td width="17%"><span
                                            style="font-size: 15px;">City:<br><span><b>{{model.city}}</b></span></span>
                                    </td>
                                    <td width="17%"><span
                                            style="font-size: 15px;">State:<br><span><b>{{model.state}}</b></span></span>
                                    </td>
                                    <td width="15%"><span
                                            style="font-size: 15px;">Country:<br><span><b>{{model.country}}</b></span></span>
                                    </td>
                                    <td width="17%"> <span
                                            style="font-size: 15px;">Area:<br><span><b>{{model.areaname}}</b></span></span>
                                    </td>
                                    <td width="17%"> <span
                                            style="font-size: 15px;">Region:<br><span><b>{{model.regionname}}</b></span></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class=" input-field col s2" *ngIf="edit">
                            <input [(ngModel)]="model.mobile1" pattern="[6789][0-9]{9}" #mobile1="ngModel" type="text"
                                id="mobile1" title="Mobile1" maxlength="10"
                                (ngModelChange)="getcustomerexits(accountmodel,contactmodel)">
                            <label id="lable2" for="mobile1">Mobile 1</label>

                            <div *ngIf="mobile1.errors &&(mobile1.touched || mobile1.dirty)" class="aler alert-danger"
                                style="padding: 10px; color: red">
                                <div [hidden]="!mobile1.errors?.pattern">
                                    Invalid mobile Number
                                </div>
                            </div>
                        </div>
                        <div class="col s4 l4 m4" *ngIf="!edit">
                            <span style="font-size: 15px;">Mobile 1:&nbsp;<span><b>{{model.mobile1}}</b></span></span>

                        </div>

                        <div class=" input-field col s2" *ngIf="edit">
                            <input [(ngModel)]="model.mobile2" #mobile2="ngModel" pattern="[6789][0-9]{9}"
                                maxlength="10" type="text" id="mobile2" title="Mobile2">
                            <label id="lable3" for="mobile2">Mobile 2</label>

                            <div *ngIf="mobile2.errors &&(mobile2.touched || mobile2.dirty)" class="aler alert-danger"
                                style="padding: 10px; color: red">
                                <div [hidden]="!mobile2.errors?.pattern">
                                    Invalid mobile Number
                                </div>
                            </div>
                        </div>
                        <div class="col s4 l4 m4" *ngIf="!edit">
                            <span style="font-size: 15px;">Mobile 2:&nbsp;<span><b>{{model.mobile2}}</b></span></span>

                        </div>
                        <div class=" input-field col s2" *ngIf="edit">
                            <input [(ngModel)]="model.landline" #landline="ngModel" type="text" id="landline"
                                title="Landline" maxlength="15">
                            <label id="lable4" for="landline">Landline</label>

                        </div>
                        <div class="col s4 l4 m4" *ngIf="!edit">
                            <span style="font-size: 15px;">Landline:&nbsp;<span><b>{{model.landline}}</b></span></span>

                        </div>
                        <div class=" input-field col s3" *ngIf="edit">
                            <input [(ngModel)]="model.email" #email="ngModel"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" type="text" id="email" title="E-mail">
                            <label id="lable5" for="email">E-Mail</label>

                            <div *ngIf="email.errors &&(email.touched || email.dirty)" class="aler alert-danger"
                                style="padding: 5px; color: red">
                                <div [hidden]="!email.errors?.pattern">
                                    Invalid Email-Id
                                </div>
                            </div>
                        </div>

                        <div class=" input-field col s3" *ngIf="edit">
                            <input [(ngModel)]="model.website" #website="ngModel" type="text" id="website"
                                title="Website">
                            <label id="lable6" for="website">Website</label>
                        </div>

                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="input-field col s4" *ngIf="edit" style="margin-top:0px;margin-bottom:0px;">
                            <input [(ngModel)]="model.googlemap" #googlemap="ngModel" type="text" id="googlemap"
                                title="Google Map Location">
                            <label id="lable7" for="googlemap">Google Map Location</label>
                        </div>
                        <div class="input-field col s6" *ngIf="edit" style="margin-top:0px;margin-bottom:0px;">
                            <input [(ngModel)]="model.customerhistory" #customerhistory="ngModel" type="text"
                                id="customerhistory" title="Customer History">
                            <label id="lable8" for="customerhistory">Customer History </label>
                        </div>

                        <div class="col s4 l4 m4" *ngIf="!edit" style="margin: 0px">
                            <span style="font-size: 15px;">Google Map
                                Location:&nbsp;<span><b>{{model.googlemap}}</b></span></span>
                        </div>

                        <div class="col s4 l4 m4" *ngIf="!edit">
                            <span style="font-size: 15px;">Email-id:&nbsp;<span><b>{{model.email}}</b></span></span>
                        </div>

                        <div class="col s4 l4 m4" *ngIf="!edit">
                            <span style="font-size: 15px;">Website:&nbsp;<span><b>{{model.website}}</b></span></span>
                        </div>

                    </div>
                    <div class="input-field col s12 " style="margin: 0px;padding: 0px;"
                        *ngIf="!model.createlead&&!model.newcall">
                        <div class="input-field col s6 " style="padding-top: 10px;padding: 0px;margin-top: 5px;">
                            <b style="font-size: 15px;">Contacts</b>
                        </div>
                        <div class="input-field col s6" style="text-align: end;padding: 0px;margin-top: 5px;">
                            <button type="button" class="waves-ripple waves-light btn" style="margin-right: 0px;"
                                (click)="opencontact(contactmodel,null)">Add
                                Contact</button>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;"
                        *ngIf="!model.createlead&&!model.newcall">
                        <!-- <b style="font-size: 15px;">Contacts</b> -->
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="4%" style="text-align: left"></th>
                                <th width="25%" style="text-align: left">Name</th>
                                <th width="20%" style="text-align: left" *ngIf="model.chkdesignation">Designation</th>
                                <th width="20%" style="text-align: left">Mobile</th>
                                <th width="25%" style="text-align: left">E-Mail</th>
                                <th width="6%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of model.lstcontact; let i = index">
                                    <td class=" input-field" style="text-align: left;">
                                        <input [(ngModel)]="lst.checkselect" *ngIf="lst.added" #checkselect="ngModel"
                                            type="checkbox" (click)="Oncheckselectcontact($event,i)" name="Select"
                                            style="text-align: 20px; ">&nbsp;
                                    </td>
                                    <td style="text-align: left">

                                        <span>{{lst.contactname}}</span>
                                    </td>
                                    <td class=" input-field " style="text-align: left;" *ngIf="model.chkdesignation">

                                        <span>{{lst.contactdesignation}}</span>

                                    </td>
                                    <td style="text-align: left">


                                        <span>{{lst.contactmobile}}</span>

                                    </td>
                                    <td style="text-align: left;">

                                        <span> {{lst.contactemail}}</span>

                                    </td>
                                    <td style="text-align: center">
                                        <span><img src="../../../assets/images/edit.png"
                                                (click)="opencontact(contactmodel ,i)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px" /></span>
                                        <span *ngIf="lst.added"><img src="../../../assets/images/delete.png"
                                                (click)="btndelts(lst.id,i)" data-toggle="tooltip" data-placement="top"
                                                title="Delete" width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="input-field col s12 " style="margin: 0px;padding: 0px;"
                        *ngIf="this.forflag!='newprospect'">
                        <div class="input-field col s6 " style="padding-top: 10px;padding: 0px;">
                            <b style="font-size: 15px;">Product Portfolio</b>
                        </div>
                        <div class="input-field col s6" style="text-align: end;padding: 0px;" *ngIf="edit">
                            <button type="button" class="waves-ripple waves-light btn" style="margin-right: 0px;"
                                (click)="openlocationmodel(locationmodel,null)">Add
                                Product</button>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px; "
                        *ngIf="this.forflag!='newprospect'">

                        <table>
                            <thead style="background: gainsboro;">
                                <th width="4%" style="text-align: left"> </th>
                                <th width="19%" style="text-align: left">Product Model</th>
                                <th width="18%" style="text-align: left" *ngIf="serialnoFlag">Serial #</th>
                                <th width="24%" style="text-align: left">Description</th>
                                <th width="9%" style="text-align: left">Status</th>
                                <th width="19%" style="text-align: left">Location</th>
                                <!-- <th width="10%" style="text-align: center">Installed On</th> -->
                                <th width="7%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of model.lstproduct; let i = index">
                                    <td class=" input-field" style="text-align: left">
                                        <input [(ngModel)]="lst.checkselect"
                                            *ngIf="lst.id!=null&&lst.added&&forflag!='createlead'"
                                            #checkselect="ngModel" type="checkbox" (click)="Oncheckselect(i)"
                                            name="Select" style="text-align: 20px; ">&nbsp;
                                    </td>
                                    <td class=" input-field" style="text-align: left">
                                        <span *ngIf="lst.added"> {{lst.name}} </span>
                                    </td>

                                    <td *ngIf="serialnoFlag" class=" input-field" style="text-align: left">
                                        <span *ngIf="lst.added&&lst.serialnoFlag"> {{lst.srlno}} </span>
                                    </td>

                                    <td style="text-align: left">
                                        <span> {{lst.description}} </span>
                                    </td>
                                    <td style="text-align: left">
                                        <span> {{lst.serviceCallStatusName}} </span>
                                    </td>
                                    <td style="text-align: left">
                                        <span *ngIf="lst.added"><b> {{lst.location}} </b></span>
                                    </td>

                                    <td style="text-align: center">

                                        <span *ngIf="lst.added&&edit"><img src="../../../assets/images/edit.png"
                                                (click)="openlocationmodel(locationmodel,i)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px" /></span>

                                        <span *ngIf="this.forflag=='bookservicecall'&&!model.reistallation"><img
                                                src="../../../assets/images/Reinstallation.png"
                                                (click)="Onreinstalltion()" data-toggle="tooltip" data-placement="top"
                                                title="Re-Installation" width="20px"
                                                style="height: 25px;width: 25px;" /></span>
                                        <span *ngIf="this.forflag=='bookservicecall'&&model.reistallation"><img
                                                src="../../../assets/images/cancel.png" (click)="closereinstalltion()"
                                                data-toggle="tooltip" data-placement="top"
                                                title="Cancel Re-Installation" width="20px"
                                                style="height: 25px;width: 25px;" /></span>

                                        <span *ngIf="lst.added&&edit"><img src="../../../assets/images/delete.png"
                                                (click)="btndeltsproduct(lst.id,i)" data-toggle="tooltip"
                                                data-placement="top" title="Delete" width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="input-field col s4" *ngIf="model.annualturnoverflag&&edit"
                            style="margin-bottom: 0px;">
                            <span>Annual Revenue</span>
                            <select (change)="onSelectannualrevenue($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Annual Revenue">
                                <option *ngFor="let lst of annualrevenuelst" [value]="lst.id"
                                    [selected]="lst.id==model.annualturnover">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                        <div class="input-field col s4" *ngIf="model.annualturnoverflag&&!edit"
                            style="margin-bottom: 0px;">
                            <span>Annual Revenue:-</span><b style="font-size: 15px;">
                                {{model.annualturnover}}</b>

                        </div>
                        <div class="input-field col s4 " *ngIf="model.numberofempflag&&edit"
                            style="margin-bottom: 0px;">
                            <span>No Of Employees</span>
                            <select (change)="onSelectnoofemple($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="No Of Employees">
                                <option *ngFor="let lst of noofemplst" [value]="lst.id"
                                    [selected]="lst.id==model.numberofemp">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                        <div class="input-field col s4 " *ngIf="model.numberofempflag&&!edit"
                            style="margin-bottom: 0px;">
                            <span>No Of Employees:-</span><b style="font-size: 15px;">
                                {{model.numberofemp}}</b>

                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;"
                        *ngIf="this.forflag=='addprospect'||this.forflag=='newprospect'">
                        <div class=" input-field col s3" style=" margin-top: 15px;">
                            <div class="switch">
                                <label>
                                    <b>Create Lead</b>
                                    <input type="checkbox" [(ngModel)]="createleadchecked"
                                        (change)="onselectlead($event)" #createlead="ngModel">
                                    <span class="lever"></span>
                                </label>
                            </div>
                        </div>
                        <div class=" input-field col s3" style=" margin-top: 15px;" *ngIf="this.forflag!='newprospect'">
                            <div class="switch">
                                <label>
                                    <b>Book Service Call</b>
                                    <input type="checkbox" [(ngModel)]="newcallchecked" (change)="onselectCall($event)"
                                        value="model.newcall" #newcall="ngModel">
                                    <span class="lever"></span>

                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right" *ngIf="edit">
                    <button (click)="btnback()" type="button"
                        class="waves-effect cancelbutton waves-light btn">Cancel</button>
                    &nbsp;&nbsp;<button (click)="btnsave()" type="button"
                        class="waves-effect waves-light   btn">Save</button>
                </div>
            </div>

            <div class="registerBgShadow" *ngIf="model.createlead">
                <div class="row" style="padding-left: 10px;text-align: center;">
                    <span style="font-size: 16px;"><b>Lead Details</b></span>
                </div>

                <div class="row" style="padding-left: 10px;padding-right: 10px;">
                    <div class=" input-field col s4" style="margin-bottom: 0px;padding-left: 14px;">
                        <span>Source</span>
                        <select (change)="onSelectsource($event.target.value)">
                            <option selected value="0">Select Source</option>
                            <option *ngFor="let type of lstsource1" [value]="type.id"
                                [selected]="type.id==model.sourceID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s5" style="margin-bottom: 0px;">
                        <span>Source Details</span>
                        <select (change)="onSelectsourcedetails($event.target.value)">
                            <option *ngFor="let type of lstsourcedetails" [value]="type.id"
                                [selected]="type.id==model.sourcedetailID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" row input-field col s2" style=" margin-top: 40px;">
                        <input [(ngModel)]="model.changeaddress" #checkselect="ngModel" type="checkbox"
                            (click)="Onchangeaddress()" name="Change Address"
                            style="text-align: 20px; ">&nbsp;&nbsp;Change Lead
                        Address
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;"
                    *ngIf="model.changeaddress&&model.createlead">
                    <!-- <div class="input-field col s12 m12 l12" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.address1" #address="ngModel" type="text" id="address9" title="Address"
                            maxlength="100">
                        <label id="lable12" for="address"> Address</label>
                    </div> -->

                    <div class=" input-field col m1 s1 l1" style="margin-top: 17px;">
                        <input [(ngModel)]="model.unitNumber2" #unitNumber2="ngModel" id="unitNumber2" type="text"
                            data-toggle="tooltip" data-placement="top" title="Unit Number" required>
                        <label id="unit" for="unitNumber2" class="active">Unit Number</label>
                    </div>
                       <div class=" input-field col m1 s1 l1" style="margin-top: 17px;">
                        <input [(ngModel)]="model.unitNumber2" #unitNumber2="ngModel" id="unitNumber2" type="text"
                            data-toggle="tooltip" data-placement="top" title="Unit Number" required>
                        <label id="unit" for="unitNumber2" class="active">Unit Number</label>
                    </div>

                    <div class=" input-field col s4 m4 l4" style="margin-top: 17px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.buildingName2" #buildingName2="ngModel" id="buildingName2" type="text"
                            data-toggle="tooltip" data-placement="top" title="Building Name" required>
                        <label id="buildin" for="buildingName2" class="active">Building Name</label>
                    </div>
                    <div class=" input-field col s5 m5 l5"
                        style="margin-top: 17px;margin-bottom: 5px;padding-left: 5px;">
                        <input [(ngModel)]="model.address2" #address2="ngModel" type="text" id="address2"
                            title="Principal Address">
                        <label for="add" style="left: 5px;" class="active">Principal Address</label>
                    </div>

                </div>

                <div class="row" style="padding-left: 10px;padding-right: 0px;"
                    *ngIf="model.changeaddress&&model.createlead">
                    <div class=" input-field col s4" style=" margin-top: 2%;">
                        <input [(ngModel)]="model.googlemap1" #googlemap="ngModel" type="text" id="googlemap144"
                            title="Google Map Location">
                        <label id="lable14" for="googlemap144">Google Map Location</label>
                    </div>
                    <!-- <div class=" input-field col s3" style=" margin-top: 2%;">
                        <input type="text" class="autocomplete" [(ngModel)]="model.pincode1" id="resAllos"
                            placeholder="Pincode" (keyup)="getPincodeLikes($event)" />
                        <input type="hidden" id="acc-id" />
                    </div> -->
                    <div class="ng-autocomplete pinRowgrp input-field col s2" style="margin-top: 19px;">
                        <ng-autocomplete [data]="pincodelist2" type="text" [(ngModel)]="model.pincode1"
                            placeholder="pincode" [searchKeyword]="keyword3" [minQueryLength]="3" id="resAllos"
                            (inputChanged)='onChangeSearch2($event)' (selected)='selectEvent2($event)'
                            (inputFocused)='onFocused2($event)' [itemTemplate]="itemTemplate2"
                            [notFoundTemplate]="notFoundTemplate2">
                        </ng-autocomplete>

                        <ng-template #itemTemplate2 let-item>
                            <a [innerHTML]="item.name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate2 let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>

                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>Locality</span>
                        <select (change)=" onSelectlocality1($event.target.value)">
                            <option *ngFor="let type of lstlocalitys" [value]="type.id"
                                [selected]="type.name==model.locality1">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;margin-left: 0px;margin-right: 0px;"
                    *ngIf="model.changeaddress&&model.createlead">
                    <table>
                        <tbody>
                            <tr style="background: gainsboro;">
                                <td width="17%"> <span
                                        style="font-size: 15px;">Locale:<br><span><b>{{model.locale1}}</b></span></span>
                                </td>
                                <td width="17%"><span
                                        style="font-size: 15px;">City:<br><span><b>{{model.city1}}</b></span></span>
                                </td>
                                <td width="17%"><span
                                        style="font-size: 15px;">State:<br><span><b>{{model.state1}}</b></span></span>
                                </td>
                                <td width="15%"><span
                                        style="font-size: 15px;">Country:<br><span><b>{{model.country1}}</b></span></span>
                                </td>
                                <td width="17%"> <span
                                        style="font-size: 15px;">Area:<br><span><b>{{model.areaname1}}</b></span></span>
                                </td>
                                <td width="17%"> <span
                                        style="font-size: 15px;">Region:<br><span><b>{{model.regionname1}}</b></span></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row" style="padding-left: 9px;padding-right: 9px;margin: 0px;"
                    *ngIf="model.createlead&&model.createlead">

                    <table>
                        <thead style="background: gainsboro;">
                            <th width="20%" style="text-align: left">Product Categories Interested In
                            </th>
                            <th width="20%" style="text-align: left" *ngIf="productmodel">Product Model</th>

                            <th width="30%" style="text-align: left">Description</th>

                            <th width="10%" style="text-align: center">Action</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of model.lstproductintersted; let i = index">
                                <!-- *ngIf="!productmodel&&!lst.added" -->
                                <td class=" input-field" style="text-align: left" *ngIf="!lst.added">
                                    <!--  <input type="text" class="autocomplete" id="productcategos"
                                        placeholder="Product Categories"
                                        (keyup)="getintrestedproductcatego($event,i)" /> 
                                    <input type="hidden" id="pro-id" />-->

                                    <ng-autocomplete [data]="prodcat" type="text" placeholder="Product Categories"
                                        [searchKeyword]="keyword5" [minQueryLength]="3" id="productcategos"
                                        (inputChanged)='onChangeSearch5($event)'
                                        (selected)='selectEvent5($event,lst.productcategos,"Categories",i)'
                                        (inputFocused)='onFocused5($event)' [itemTemplate]="itemTemplate5"
                                        [notFoundTemplate]="notFoundTemplate5">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplate5 let-item>
                                        <a [innerHTML]="item.name"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate5 let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                    <input type="hidden" id="pro-id" />

                                </td>
                                <td style="text-align: left" *ngIf="lst.added">
                                    <!-- *ngIf="!productmodel&&lst.added" -->
                                    {{ lst.categoryname}}
                                </td>
                                <!-- *ngIf="productmodel&&!lst.added" -->
                                <td class=" input-field" style="text-align: left" *ngIf="productmodel&&!lst.added">
                                    <input type="text" class="autocomplete" id="product" placeholder="Product Model"
                                        (keyup)="getinteredproductmodel($event,i)" />
                                    <input type="hidden" id="pro-id" />


                                    <!-- <div class="input-field col l11 m11 s11" style="margin-top: 0px;margin-bottom: 0px;padding-left: 0px;">
                                        <input type="text" class="autocomplete" id="product" data-toggle="tooltip" data-placement="top"
                                            title="Item Name" style="margin-top:1px;"  placeholder="Product Model" (keyup)="getItemLike1($event)">
                                        <input type="hidden" id="acc-id" />
                                        <label for="itmname" class="active" style="left: 0px;">Item Name</label>
                                    </div> -->


                                    <!-- <ng-autocomplete [data]="prodcat" type="text" placeholder="Product Categories"
                                    [searchKeyword]="keyword6" [minQueryLength]="3" id="resAllos"
                                    (inputChanged)='onChangeSearch6($event,i)'  (selected)='selectEvent5($event,"Product")'  (inputFocused)='onFocused6($event)'
                                    [itemTemplate]="itemTemplate6" [notFoundTemplate]="notFoundTemplate6">
                                  </ng-autocomplete>
                
                                  <ng-template #itemTemplate6 let-item>
                                    <a  [innerHTML]="item.name"></a>
                                  </ng-template>
                
                                  <ng-template #notFoundTemplate6 let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                  </ng-template> 
                                   <input type="hidden" id="pro-id" /> -->
                                </td>
                                <td *ngIf="productmodel&&lst.added" style="text-align: left">
                                    {{ lst.name}}
                                </td>
                                <td style="text-align: left">
                                    <!-- <span *ngIf="productmodel"> {{lst.description}} </span> -->
                                    <span> {{lst.categoriesdescription}} </span>
                                    <!-- *ngIf="!productmodel" -->
                                </td>

                                <td style="text-align: center">
                                    <span *ngIf="!lst.added"><img src="../../../assets/images/CreateDocument.png"
                                            (click)="btnaddproductintersted(i,productmodel)" data-toggle="tooltip"
                                            data-placement="top" title="Add " width="20px" /></span>
                                    <span *ngIf="lst.added"><img src="../../../assets/images/delete.png"
                                            (click)="btndeleteinterstedproduct(i)" data-toggle="tooltip"
                                            data-placement="top" title="Delete" width="20px" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" style="padding-left: 9px;padding-right: 9px;margin-left: 0px;margin-right: 0px ;"
                    *ngIf="model.createlead">
                    <div class=" input-field col s12" style="margin-bottom: 0px;padding-left: 5px;">
                        <textarea [(ngModel)]="model.remark" class="materialize-textarea" #remark="ngModel" type="text"
                            id="remark" title="Remark" rows="5" maxlength="1000" style="margin-bottom: 0px;">
                    </textarea>
                        <label id="lable7" for="remark">Remark</label>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.createlead  ">
                    <div class=" input-field col s4" style="margin-bottom: 0px;margin-top: 0px;padding-left: 14px;" *ngIf="model.chkseledel===false && model.createlead">
                        <span>Assigned To</span>
                        <select (change)=" onSelectlstleadassigned($event.target.value)" *ngIf="forflag!=reassinglead">
                            <option *ngFor="let type of lstassigned" [value]="type.id" [selected]="type.id==leadID">
                                {{type.name}}</option>
                        </select>
                        <select (change)=" onSelectlstleadassigned($event.target.value)" *ngIf="forflag==reassinglead">
                            <option *ngFor="let type of lstleadassigned" [value]="type.id" [selected]="type.id==leadID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s4" style="margin-bottom: 0px;margin-top: 0px;padding-left: 14px;" *ngIf=" model.chkseledel && model.createlead ">
                        <span>Assigned To Dealer</span>
                        <select (change)=" onSelectlstleadassigned($event.target.value)">
                            <option *ngFor="let type of lstleadassigneddealer" [value]="type.id" [selected]="type.id==leadID">
                                {{type.name}}</option>
                        </select>
                        <select (change)=" onSelectlstleadassigned($event.target.value)" *ngIf="forflag==reassinglead">
                            <option *ngFor="let type of lstleadassigneddealer" [value]="type.id" [selected]="type.id==leadID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s5" style=" margin-top: 24px;">
                        <span><b style="font-size: 15px;">{{model.numberofleads}} </b> {{Leads}}</span>
                    </div>
                </div>
                <div class="input-field col s12 " style="margin: 0px;padding-top: 0px;padding-left: 0px;"
                    *ngIf="model.createlead">
                    <div class="input-field col s6 " style="padding-top: 0px;">
                        <b style="font-size: 15px;">Contacts</b>
                    </div>
                    <div class="input-field col s6" style="text-align: end;padding: 0px;">
                        <button type="button" class="waves-ripple waves-light btn" style="margin-right: 0px;"
                            (click)="opencontact(contactmodel,null)">Add
                            contact</button>
                    </div>
                </div>
                <div class="row" style="padding-left: 9px;padding-right: 9px;margin: 0px;" *ngIf="model.createlead">
                    <!-- <b style="font-size: 15px;">Contacts</b> -->
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="5%" style="text-align: left"></th>
                            <th width="20%" style="text-align: left">Name</th>
                            <th width="10%" style="text-align: left"
                                *ngIf="model.customertypename == 'Institutional' || model.customertypename == 'Defence'">
                                Designation</th>
                            <th width="10%" style="text-align: left">Mobile</th>
                            <th width="20%" style="text-align: left">E-Mail</th>
                            <th width="5%" style="text-align: center">Action</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of model.lstcontact; let i = index">
                                <td class=" input-field" style="text-align: left;">
                                    <input [(ngModel)]="lst.checkselect" *ngIf="lst.added" #checkselect="ngModel"
                                        type="checkbox" (click)="Oncheckselectcontact($event,i)" name="Select"
                                        style="text-align: 20px; ">&nbsp;
                                </td>
                                <td style="text-align: left">
                                    <span>{{lst.contactname}}</span>
                                </td>
                                <td class=" input-field " style="text-align: left;"
                                    *ngIf="model.customertypename == 'Institutional' || model.customertypename == 'Defence'">
                                    <span>{{lst.contactdesignation}}</span>
                                </td>
                                <td style="text-align: left">
                                    <span>{{lst.contactmobile}}</span>
                                </td>
                                <td style="text-align: left;">
                                    <span> {{lst.contactemail}}</span>
                                </td>
                                <td style="text-align: center">
                                    <span><img src="../../../assets/images/edit.png"
                                            (click)="opencontact(contactmodel ,i)" data-toggle="tooltip"
                                            data-placement="top" title="Edit" width="20px" /></span>
                                    <span *ngIf="lst.added"><img src="../../../assets/images/delete.png"
                                            (click)="btndelts(lst.id,i)" data-toggle="tooltip" data-placement="top"
                                            title="Delete" width="20px" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right" *ngIf="model.createlead">
                        <button (click)="btnbacklead()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnsavelead(CreateleadModel)" type="button"
                            class="waves-effect waves-light btn">Create
                            Lead</button>&nbsp;&nbsp;


                    </div>
                </div>

            </div>
            <div class="registerBgShadow" *ngIf="model.newcall" style="padding-top: 9px;margin-bottom: 9px;">
                <div class="row" style="padding-left: 30px;text-align: center;">
                    <span style="font-size: 16px;"><b>Service Call Details</b></span>
                </div>

                <div class="row" style="padding-left: 9px;padding-bottom: 5px;margin-left: 0px;margin-right: 0px;"
                    *ngIf="model.reistallation">
                    <input [(ngModel)]="model.sameaddress" #sameaddress="ngModel" type="checkbox"
                        (click)="Onsameaddress($event)" name="Same as Exisiting Address"
                        style="text-align: 20px; ">&nbsp;&nbsp;Same as Exisiting Address
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.reistallation">

                    <div class=" input-field col m1 s1 l1" style="margin-top: 17px;">
                        <input [(ngModel)]="model.unitNumbers" #unitNumbers="ngModel" id="unitNumbers" type="text"
                            data-toggle="tooltip" data-placement="top" title="Unit Number" required>
                        <label id="unit" for="unitNumbers" class="active">Unit Number</label>
                    </div>
                    <div class=" input-field col s3 m3 l3" style="margin-top: 17px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.buildingNames" #buildingNames="ngModel" id="buildingNames" type="text"
                            data-toggle="tooltip" data-placement="top" title="Building Name" required>
                        <label id="buildin" for="buildingNames" class="active">Building Name</label>
                    </div>
                    <div class="input-field col s4 m4 l4" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.addresss" #address="ngModel" type="text" id="address9" title="Address"
                            maxlength="100">
                        <label id="lable12" class="active" for="address"> Re-Installation Address</label>
                    </div>

                </div>

                <div class="row" style="padding-left: 10px;padding-right: 10px;margin-top: 0%;"
                    *ngIf="model.reistallation">
                    <div class=" input-field col s4" style="margin-top: 2%;">
                        <input [(ngModel)]="model.googlemap1" #googlemap="ngModel" type="text" id="googlemap144"
                            title="Google Map Location">
                        <label id="lable14" class="active" for="googlemap144">Google Map Location</label>
                    </div>
                    <!-- <div class=" input-field col s3" style="margin-top: 2%;">
                        <input type="text" class="autocomplete" [(ngModel)]="model.pincode1" id="resAllos"
                            placeholder="Pincode" (keyup)="getPincodeLikes($event)" />
                        <input type="hidden" id="acc-id" />
                    </div> -->
                    <div class="ng-autocomplete pinRowgrp input-field col s3" style="margin-top: 2%;">
                        <ng-autocomplete [data]="pincodelist3" type="text" [(ngModel)]="model.pincode1"
                            placeholder="pincode" [searchKeyword]="keyword3" [minQueryLength]="3" id="resAllos"
                            (inputChanged)='onChangeSearch3($event)' (selected)='selectEvent3($event)'
                            (inputFocused)='onFocused2($event)' [itemTemplate]="itemTemplate3"
                            [notFoundTemplate]="notFoundTemplate3">
                        </ng-autocomplete>

                        <ng-template #itemTemplate3 let-item>
                            <a [innerHTML]="item.name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate3 let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>

                    <div class=" input-field col s3" style="margin-top: 0%;">
                        <span>Locality</span>
                        <select (change)=" onSelectlocality1($event.target.value)">
                            <option *ngFor="let type of lstlocalitys" [value]="type.id"
                                [selected]="type.name==model.locality1">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.reistallation">
                    <table>
                        <tbody>
                            <tr style="background: gainsboro;">
                                <td width="17%"> <span
                                        style="font-size: 15px;">Locale:<br><span><b>{{model.locale1}}</b></span></span>
                                </td>
                                <td width="17%"><span
                                        style="font-size: 15px;">City:<br><span><b>{{model.city1}}</b></span></span>
                                </td>
                                <td width="17%"><span
                                        style="font-size: 15px;">State:<br><span><b>{{model.state1}}</b></span></span>
                                </td>
                                <td width="15%"><span
                                        style="font-size: 15px;">Country:<br><span><b>{{model.country1}}</b></span></span>
                                </td>
                                <td width="17%"> <span
                                        style="font-size: 15px;">Area:<br><span><b>{{model.areaname1}}</b></span></span>
                                </td>
                                <td width="17%"> <span
                                        style="font-size: 15px;">Region:<br><span><b>{{model.regionname1}}</b></span></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="input-field col s12 m12 l12" style=" margin-top:1px;margin-bottom: 0px;">
                    <div class="input-field col s3" *ngIf="!model.reistallation">
                        <span>Working Status</span>
                        <select (change)="onSelectmachinestatusforcall($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Product Working Status">
                            <option *ngFor="let lst of machiestatuslstforcall" [value]="lst.name"
                                [selected]="lst.name==model.mcstatus">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s3">
                        <span>Priority</span>
                        <select (change)="onSelectpriority($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Priority">
                            <option *ngFor="let lst of lstpriority" [value]="lst.name"
                                [selected]="lst.name==model.priority">
                                {{lst.name}}
                            </option> ￼
                        </select>
                    </div>
                    <div class=" input-field col s3">
                        <span>Assigned To</span>
                        <select (change)="onSelectlstassignedforcalls($event.target.value)">
                            <option *ngFor="let type of lstassigned" [value]="type.id"
                                [selected]="type.id==model.assignedtoID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s1" style=" margin-top:40px;">
                        <span><b style="font-size: 15px;">{{model.calls}}</b> {{call}}</span>
                    </div>
                </div>
                <div class="input-field col s12 m12 l12" style=" margin-top: 0px;margin-bottom: 0px;">
                    <div class="input-field col s3" style=" margin-top: 0px;">
                        <input type="text" [(ngModel)]="model.callcommitdate" id="date" class="form-control"
                            (ngModelChange)="selectCallCommitDate(model.callcommitdate)" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }"
                            style="margin-bottom: 0px;">
                        <label id="lable23" for="problemre" class="active">Call Commit Date</label>
                    </div>
                    <div class="input-field col s9" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.problemreported" #problemre="ngModel" type="text" id="problemre"
                            title="Problem Reported" maxlength="35" style="margin-bottom: 0px;">
                        <label id="lable1" for="problemre">Problem Reported</label>
                    </div>
                </div>
                <div class="input-field col s12 " style="margin: 0px;padding: 0px;" *ngIf="model.newcall">
                    <div class="input-field col s6 " style="padding-top: 0px;">
                        <b style="font-size: 15px;">Contacts</b>
                    </div>
                    <div class="input-field col s6" style="text-align: end;padding: 0px;padding-right: 9px;">
                        <button type="button" class="waves-ripple waves-light btn" style="margin-right: 0px;"
                            (click)="opencontact(contactmodel,null)">Add
                            contact</button>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;margin-left: 0px;margin-right: 0px;"
                    *ngIf="model.newcall">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="5%" style="text-align: left"></th>
                            <th width="20%" style="text-align: left">Name</th>
                            <th width="10%" style="text-align: left">Designation</th>
                            <th width="10%" style="text-align: left">Mobile</th>
                            <th width="20%" style="text-align: left">E-Mail</th>
                            <th width="5%" style="text-align: center">Action</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of model.lstcontact; let i = index">
                                <td class=" input-field" style="text-align: left;">
                                    <input [(ngModel)]="lst.checkselect" *ngIf="lst.added" #checkselect="ngModel"
                                        type="checkbox" (click)="Oncheckselectcontact($event,i)" name="Select"
                                        style="text-align: 20px; ">&nbsp;
                                </td>
                                <td style="text-align: left">
                                    <span>{{lst.contactname}}</span>
                                </td>
                                <td class=" input-field " style="text-align: left;">
                                    <span>{{lst.contactdesignation}}</span>
                                </td>
                                <td style="text-align: left">
                                    <span>{{lst.contactmobile}}</span>
                                </td>
                                <td style="text-align: left;">
                                    <span> {{lst.contactemail}}</span>
                                </td>
                                <td style="text-align: center">
                                    <span><img src="../../../assets/images/edit.png"
                                            (click)="opencontact(contactmodel ,i)" data-toggle="tooltip"
                                            data-placement="top" title="Edit" width="20px" /></span>
                                    <span *ngIf="lst.added"><img src="../../../assets/images/delete.png"
                                            (click)="btndelts(lst.id,i)" data-toggle="tooltip" data-placement="top"
                                            title="Delete" width="20px" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;margin-top: 12px;">
                        <button (click)="btnbacklead()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnsaveBookCall()" type="button" class="waves-effect waves-light btn">Book
                            Call</button>&nbsp;&nbsp;
                    </div>
                </div>

            </div>

        </div>
    </div>

    <ng-template #locationmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">Add Product</span>
            </div>

            <div class="row">
                <div class="">
                    <div class="col l12 m12 s12">
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div class="input-field col s4" style="margin-bottom:0px;">
                                <span>Product Category</span>
                                <select (change)="onSelectcategory($event.target.value)" data-toggle="tooltip"
                                    data-placement="top" title="Product Category">
                                    <option selected value="0">Select Category</option>
                                    <option *ngFor="let lst of productcatelst" [value]="lst.id"
                                        [selected]="lst.id==productcategoryid" [disabled]="productcategoryid!=null">
                                        {{lst.name}}
                                    </option>
                                </select>
                            </div>

                            <!-- <div class=" input-field col s7" style=" margin-top: 30px;margin-bottom:0px;">
                                <input type="text" class="autocomplete" id="productname" placeholder="Product Name"
                                    (keyup)="getProductLike()" *ngIf="productname==null" />
                                <input type="hidden" id="acc-id" />
                                <span *ngIf="productname!=null"
                                    style="font-size: 15px;">Product:-<span><b>{{productname}}</b></span> </span>
                            </div> -->

                            <div class="ng-autocomplete pinRowgrp input-field col s5"
                                style=" margin-top: 25px;margin-bottom:0px;">
                                <ng-autocomplete [data]="userList2" type="text" placeholder="Product Name"
                                    [searchKeyword]="keyword1" [minQueryLength]="3" *ngIf="productname==null"
                                    (inputChanged)='onChangeSearchproduct($event)'
                                    (selected)='selectEventforproduct($event)'
                                    (inputFocused)='onFocusedforproduct($event)' [itemTemplate]="itemTemplate"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.productName"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                                <span *ngIf="productname!=null" style="font-size: 15px;">Product
                                    Name:&nbsp;<span><b>{{productname}}</b></span></span>
                            </div>

                            <div class=" input-field col s3" style=" margin-top: 28px;margin-bottom: 0px;"
                                *ngIf="serialnoFlag">
                                <input [(ngModel)]="srlno" #name="ngModel" type="text" id="srlnoID" title="Serial No."
                                    style="margin-bottom: 0px;">
                                <label id="lable15" for="srlnoID">Serial #</label>
                            </div>

                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div class=" input-field col s7" style=" margin-top: 19px;margin-bottom: 0px;">
                                <input [(ngModel)]="addr" #address="ngModel" type="text" id="address10" title="Address"
                                    maxlength="150">
                                <label id="lable10" for="address" class="active"> Address</label>
                            </div>

                            <!-- <div class=" input-field col s2" style=" margin-top: 19px;">
                                <input type="text" class="autocomplete" [(ngModel)]="pincode" id="pincode2"
                                    placeholder="Pincode" (keyup)="getPincodeLikeforproduct($event)" />
                                <input type="hidden" id="acc-id" />
                            </div> -->
                            <div class="ng-autocomplete pinRowgrp input-field col s2"
                                style="margin-top: 15px;margin-bottom: 0px;">
                                <ng-autocomplete [data]="pincodelist1" type="text" [(ngModel)]="pincode"
                                    placeholder="pincode" [searchKeyword]="keyword" [minQueryLength]="3" id="pincode2"
                                    (inputChanged)='onChangeSearch1($event)' (selected)='selectEvent1($event)'
                                    (inputFocused)='onFocused1($event)' [itemTemplate]="itemTemplate1"
                                    [notFoundTemplate]="notFoundTemplate1">
                                </ng-autocomplete>

                                <ng-template #itemTemplate1 let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate1 let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>

                            <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom: 0px;">
                                <span>Locality</span>
                                <select (change)=" onSelectproductlocality($event.target.value)">
                                    <option *ngFor="let type of lstlocalityforproduct" [value]="type.id"
                                        [selected]="type.name==locality">
                                        {{type.name}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <table>
                                <tbody>
                                    <tr style="background: gainsboro;">
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Locale:<br><span><b>{{locale}}</b></span></span>
                                        </td>
                                        <td width="17%"><span
                                                style="font-size: 15px;">City:<br><span><b>{{city}}</b></span></span>
                                        </td>
                                        <td width="17%"><span
                                                style="font-size: 15px;">State:<br><span><b>{{state}}</b></span></span>
                                        </td>
                                        <td width="15%"><span
                                                style="font-size: 15px;">Country:<br><span><b>{{country}}</b></span></span>
                                        </td>
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Area:<br><span><b>{{areaname}}</b></span></span>
                                        </td>
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Region:<br><span><b>{{regionname}}</b></span></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="input-field col s5" style="margin-bottom:0px;">
                            <span>Machine Status</span>
                            <select (change)="onSelectmachinestatus($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Machine Status">
                                <!-- <option>Select Status</option> -->
                                <option *ngFor="let lst of machiestatuslst" [value]="lst.id"
                                    [selected]="lst.name==serviceCallStatusName">
                                    {{lst.name}}
                                </option>
                            </select>

                        </div>
                        <div class="input-field col s5" style=" margin-bottom:0px;">
                            <span *ngIf="amcddateflag">AMC End Date</span>
                            <input *ngIf="amcddateflag" type="text" placeholder="AMC End Date" [(ngModel)]="amcdEndDate"
                                class="form-control" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }">
                            <span *ngIf="warrentydateflag">Warranty End Date</span>
                            <input *ngIf="warrentydateflag" type="text" placeholder="Warranty End Date"
                                [(ngModel)]="warrentyEndDate" class="form-control" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }">
                        </div>

                        <div class="row">
                            <div class="input-field col l12 m12 s12" style="text-align: right">
                                <button (click)="btncancle()" type="button"
                                    class="waves-effect cancelbutton waves-light btn">Cancel</button>
                                &nbsp;&nbsp;<button (click)="btnaddproductaddress()" type="button"
                                    class="waves-effect waves-light btn">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #accountmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">Customer Details</span><br>
                <span style="font-size: 12px;padding-top: 10px;">Customer Present in Database</span>

            </div>

            <div class="row">
                <div class="">
                    <div class="col l12 m12 s12">
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20%" style="text-align: left;padding-left: 10px;">Name</th>
                                    <th width="20%" style="text-align: left">Mobile</th>
                                    <th width="30%" style="text-align: left">Address</th>
                                    <th width="10%" style="text-align: center">Action</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of lstcustomerexits; let i = index">
                                        <td class=" input-field" style="text-align: left;padding-left: 10px;">
                                            <span> {{lst.customername}} </span>
                                        </td>
                                        <td class=" input-field" style="text-align: left">
                                            <span> {{lst.mobile1}} </span>
                                        </td>
                                        <td style="text-align: left">
                                            <span> {{lst.address}} </span>
                                        </td>

                                        <td style="text-align: center">
                                            <span><img src="../../../assets/images/edit.png"
                                                    (click)="btnaddcustomerexites(lst.accountid)" data-toggle="tooltip"
                                                    data-placement="top" title="Edit" width="20px" /></span>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


    <ng-template #contactmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="registerBgShadow">

            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">Add Contact</span><br>

            </div>

            <div class="row ">

                <div class=" input-field col s12" style="margin-top: 0%;margin-bottom: 0%;">
                    <div class=" input-field col s6" style="margin-top: 2%;">
                        <input [(ngModel)]="contactmo.contactname" #contactname="ngModel" type="text" id="contactname"
                            title="Name">
                        <label id="Nameid" for="contactname" class="active"> Name</label>

                    </div>
                    <div class=" input-field col s6" style="margin-top: 0%;" *ngIf="model.chkdesignation">
                        <span>Designation</span>
                        <select (change)="onSelectdesiganation($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Designation">
                            <option selected disabled>Select Designation</option>
                            <option *ngFor="let type of designationlst" [value]="type.name"
                                [selected]="type.name==contactmo.contactdesignation">
                                {{type.name}} </option>
                        </select>
                    </div>
                </div>
                <div class=" input-field col s12" style="margin-top: 0%;margin-bottom: 0%;">
                    <div class=" input-field col s6">
                        <input [(ngModel)]="contactmo.contactmobile" #contactmobile="ngModel" type="text"
                            id="contactmobile" title="Mobile" pattern="[6789][0-9]{9}" maxlength="10"
                            (ngModelChange)="getcustomerexits1()">

                        <label id="Mobileid" for="contactmobile" class="active"> Mobile</label>
                        <div *ngIf="contactmobile.errors &&(contactmobile.touched || contactmobile.dirty)"
                            class="aler alert-danger" style="padding: 10px; color: red">
                            <div [hidden]="!contactmobile.errors?.pattern">
                                Invalid mobile Number
                            </div>
                        </div>
                    </div>
                    <div class=" input-field col s6">
                        <input [(ngModel)]="contactmo.contactemail" #contactemail="ngModel"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" type="text" id="contactemail"
                            title="E-Mail" required>
                        <label id="contactemails" for="contactemail" class="active"> E-Mail</label>
                        <div *ngIf="contactemail.invalid && (contactemail.dirty || contactemail.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="contactemail.errors.required">
                                Email Required
                            </div>
                            <div [hidden]="!contactemail.errors?.pattern">
                                Invalid Email-Id
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;margin-top: 0%;">
                        <button (click)="btnclosemodel()" type="button"
                            class="waves-effect cancelbutton waves-light btn"
                            (click)="modalRef2.hide()">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnaddcontact()" type="button" class="waves-effect waves-light btn"
                            (click)="modalRef2.hide()">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>


    <ng-template #CreateleadModel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 16px;"><b>Lead Details</b></span>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="text-align: center;">
                    <div class="col l6 m6 s6">
                        <span style="font-size: 15px;"> There is Lead Open</span> <br>
                        <span style="font-size: 15px;"> Lead No :-<b>{{serviceNO}}</b> </span> <br>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right">
    
                        <button (click)="btnclose()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button> &nbsp;&nbsp;
                        <button (click)="btnservicecalledit()" type="button"
                            class="waves-effect waves-light btn">Add</button>
    
                    </div>
                </div> -->
            </div>
        </div>
    </ng-template>
</div>