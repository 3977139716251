<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>

        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12">
                <div class="col l6 m6 s6">
                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class="input-field col s10">
                            <span>Sales Product Category</span>
                            <select (change)="onSelectcategory($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Product Category">
                                <option selected value="0">Select Sales Product Category</option>
                                <option *ngFor="let lst of productcatelst" [value]="lst.id"
                                    [selected]="lst.id==model.productcategoryid">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s1">
                        </div>
                        <span style="font-size: 15px;padding-left: 10px;"> Item Product Category: {{model.itemsubcategory}}</span>

                    </div>

                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class=" input-field col s10" style=" margin-top: 15px;">
                            <input [(ngModel)]="model.productCode" #productCode="ngModel"
                                oninput="validity.valid||(value='');" type="text" id="first1" data-toggle="tooltip"
                                data-placement="top" title="Product Model" required>
                            <label id="lable1" for="first1" style="font-size:15px">Product Model</label>
                            <div *ngIf="productCode.invalid && (productCode.dirty || productCode.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="productCode.errors.required">
                                    Model Required
                                </div>
                                <div [hidden]="!productCode.errors?.pattern">
                                    Invalid Model
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class=" input-field col s10" style=" margin-top: 15px;">
                            <input [(ngModel)]="model.description" #description="ngModel"
                                oninput="validity.valid||(value='');" type="text" id="first2" data-toggle="tooltip"
                                data-placement="top" title="Description" required>
                            <label id="lable2" for="first2" style="font-size:15px">Description </label>
                            <div *ngIf="description.invalid && (description.dirty || description.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="description.errors.required">
                                    Description Required
                                </div>
                                <div [hidden]="!description.errors?.pattern">
                                    Invalid Description
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class=" input-field col s5" style=" margin-top: 15px;">
                            <input [(ngModel)]="model.discount" #discount="ngModel" type="number" min="0" max="100"
                                oninput="validity.valid||(value='');" id="first3" maxlength="3" data-toggle="tooltip"
                                data-placement="top" title="Discount Allowed"  style="text-align: right;"  required>
                            <label id="lable3" for="first3" style="font-size:15px">Discount Allowed</label>
                            <div *ngIf="discount.invalid && (discount.dirty || discount.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="discount.errors.required">
                                    Discount Allowed Required
                                </div>
                                <div [hidden]="!discount.errors?.pattern">
                                    Invalid Discount Allowed
                                </div>
                            </div>
                        </div>

                        <div class=" input-field col s5" style=" margin-top: 15px;">
                            <input [(ngModel)]="model.warrantyMonths" #warrantyMonths="ngModel" type="number" min="0"
                                oninput="validity.valid||(value='');" id="first4" data-toggle="tooltip"
                                data-placement="top" style="text-align: right;" title="Warranty" ngModel required>
                            <label id="lable4" for="first4" style="font-size:15px">Warranty(In Months)</label>
                            <div *ngIf="warrantyMonths.invalid && (warrantyMonths.dirty || warrantyMonths.touched) "
                                class="alert alert-danger" style="color: red">
                                <div *ngIf="warrantyMonths.errors.required">
                                    Warranty Required
                                </div>
                                <div [hidden]="!warrantyMonths.errors?.pattern">
                                    Invalid Warranty
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class="input-field col l4" style="margin-right: 30px;font-size:15px">
                            <input [(ngModel)]="model.sparcheckselect" #sparcheckselect="ngModel" type="checkbox"
                                name="Sparelist" style="text-align: 20px; ">&nbsp;&nbsp;
                            Spare list
                        </div>
                        <div class="input-field col l4" style="margin-right: 30px;font-size:15px">
                            <input [(ngModel)]="model.sercheckselect" #sercheckselect="ngModel" type="checkbox"
                                name="SerialNo" style="text-align: 20px; ">&nbsp;&nbsp;
                            Serial No.
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s1">
                        </div>
                        <div class="input-field col l4" style="margin-right: 30px;font-size:15px">
                            <input [(ngModel)]="model.installation" #installation="ngModel" type="checkbox"
                                name="Installation" style="text-align: 20px; ">&nbsp;&nbsp;
                            Installation
                        </div>
                    </div>
                    <!-- <div style="border-right:1px solid;"> -->
                </div>

                <div class="col l6 m6 s6" style="border-left:1px solid;">
                    <div class="row">

                        <div class="input-field col s3 m3 l3" style="margin-left :10px;margin-top: 30px;">
                            <input type="text" placeholder="Effective Date" [(ngModel)]=currentdate id="start"
                                data-toggle="tooltip" data-placement="top" title="Effective Date" class="form-control"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l12 m12 s12">
                            <div class="col l12 m12 s12 " style="height: 400px; overflow: auto;">
                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="10%" style="text-align: left"></th>
                                        <th width="30%" style="text-align: left">Currency</th>
                                        <th width="40%" style="text-align: center">Value</th>
                                        <th width="20%" style="text-align: left">Default</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of promodellst;let i = index">
                                            <td style="text-align: left;"><input type="checkbox"
                                                    [(ngModel)]="lst.checkselect" #checkselect="ngModel"
                                                    (change)="onselectcheckselect(i)"></td>

                                            <td style="text-align: left">{{lst.currencyName}}</td>
                                            <td style="text-align: right;"><input [(ngModel)]="lst.minimumPrice"
                                                    #sequence="ngModel" type="number" min="0" id="minimumPrice"
                                                    data-toggle="tooltip" data-placement="top"
                                                    placeholder="Minimum Price" style="text-align: right;"></td>
                                            <td style="text-align: left;"><input type="checkbox"
                                                    [(ngModel)]="lst.defcheckselect" (change)="onselectdef(i)"
                                                    #defcheckselect="ngModel"></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <span style="text-align:right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </span> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="input-field col l12 m12 s12" style="text-align: right">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                                style="margin-right: 5px;">Save</button>
                        </div>
                        <!-- <div class="input-field col l6 m6 s6" style="text-align: left">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>