import { common } from './Common';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpModule } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { User } from '../_models/index';
//import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Mainmenumodel } from '../_models/Mainmenumodel';
import { MasterModel } from '../_models/MasterModel';
import { Observable } from 'rxjs';
import { MasterConfigurationModel } from '../ConfigurationPages/MasterConfiguration/MasterConfigurationModel';
import { SaveSuccessModel } from '../_models/SaveSucessModel';
import { SearchUserModel } from '../Master/ResourceMaster/SearchUserModel';
import { DropDownModel } from '../_models/DropDownModel';
import { SearchPipelineModel } from '../Master/PipelineMaster/SearchPipelineModel';
import { DefinePipelineModel } from '../Master/PipelineMaster/DefinePipelineModel';
import { SearchAccountMasterModel } from '../Master/AccountMaster/SearchAccountMasterModel';
import { DefineAccountMasterModel } from '../Master/AccountMaster/DefineAccountMasterModel';
import { SearchAccountWiseMachineDetailModel } from '../Master/AccountWiseMachineDetailMaster/SearchAccountWiseMachineDetailModel';
import { DefineAccountwisemachinedetailModel } from '../Master/AccountWiseMachineDetailMaster/DefineAccountwisemachinedetailModel';
import { SearchCompanyMasterModel } from '../Master/CompanyMaster/SearchCompanyMasterModel';
import { DefineCompanyMasterModel } from '../Master/CompanyMaster/DefineCompanyMasterModel';
import { SearchEnquirynameModel } from '../Master/EnquiryNameMaster/SearchEnquirynameModel';
import { SearchCurrencyExchangeModel } from '../Master/CurrencyExchange/SearchCurrencyExchangeModel';
import { SearchProductModel } from '../Master/ProductMaster/SearchProductModel';
import { DefineProductModel } from '../Master/ProductMaster/DefineProductModel';
import { DesignationAllowanceModel } from '../Master/DesignationMaster/DesignationAllowanceModel';
import { DefineMasterModel } from '../Master/ConfiguratedMasters/DefineMasterModel';
import { MasterHomePageModel, Header } from '../Master/ConfiguratedMasters/MasterHomePageModel';
import { DefineResourceAllowanceModel } from '../Master/ResourceAllowance/DefineResourceAllowanceModel';
import { SearchTypeMasterModel } from '../Master/TypeMaster/SearchTypeMasterModel';
import { DefineTypeMasterModel } from '../Master/TypeMaster/DefineTypeMasterModel';
import { DefineAMCTypeModel } from '../Master/AMCTypeMaster/DefineAMCTypeModel';
import { LocalityModel } from '../Master/Locality/LocalityModel';
import { CityMasterModel } from '../Master/CityMaster/CityMasterModel';
import { CustomerTypeWorkflowModel } from '../Master/CustomerTypeWorkflow/CustomerTypeWorkflowModel';
import { AreaModel, ResourceRightsModel, ResourceApprovalsModel } from '../Master/ResourceRights/ResourceRightsModel';
import { ProductCategory, ProductResourceRightsModel, ResourceCType } from '../Master/ResourceRights/ProductResourceRightsModel';
import { AreaModel1, ProductCategory1, TeamModel } from '../Transactions/Teams/TeamModel';
import { SearchAllowanceMasterModel } from '../Master/AllowanceMaster/SearchAllowanceMasterModel';
import { DefineAllowanceModel } from '../Master/AllowanceMaster/DefineAllowanceModel';
import { SearchSourceModel } from '../Master/SourceMaster/SearchSourceModel';
import { DefineSourceModel } from '../Master/SourceMaster/DefineSourceModel';
import { SourceDetailModel } from '../Master/SourceMaster/SourceDetailModel';
import { SearchCategoryTermsConModel } from '../Master/CategoryTermConditionMaster/SearchCategoryTermsConModel';
import { DefineCategoryTermsConModel } from '../Master/CategoryTermConditionMaster/DefineCategoryTermsConModel';
import { CallCenterDashboardModel } from '../Transactions/CallCenterDashboard/CallCenterDashboardModel';
import { searchgstheadermodel } from '../Master/GSTForm/searchgstheadermodel';
import { Gstheadermastermodel } from '../Master/GSTForm/Gstheadermastermodel';
import { GSTgroupmastermodel } from '../Master/GSTForm/GSTgroupmastermodel';
import { ChangePasswordModel } from '../Master/ChangePassword/ChangePasswordModel';
import { ResetPasswordModel } from '../Master/ResetPassword/ResetPasswordModel';
import { SearchActivityModel } from '../Master/ActivityMaster/SearchActivityModel';
import { FinancialyearModel } from '../Master/FinancialYear/FinancialyearModel';
import { SearchUOMModel } from '../Master/UnitofMeasure/SearchUOMModel';
import { DefineMarketingModel } from '../Master/MarketingCostSubMaster/DefineMarketingModel';
import { SearchMarketingModel } from '../Master/MarketingCostSubMaster/SearchMarketingModel';

import { SearchProductcategoryModel } from '../Master/ProductCategoryMaster/SearchProductcategoryModel';
import { DefineProductcategory } from '../Master/ProductCategoryMaster/DefineProductcategory';
import { DefineProductcategoryModel } from '../Master/ProductCategoryMaster/DefineProductcategoryModel';
// import { SearchitemSubCategoryModel } from '../Master/ItemSubCategoryMaster /SearchitemSubCategoryModel';
// import { DefineitemSubCategory } from '../Master/ItemSubCategoryMaster /DefineitemSubCategory';
// import { DefineitemSubCategoryModel } from '../Master/ItemSubCategoryMaster /DefineitemSubCategoryModel';
import { DefineInventoryLocModel } from '../Master/InventoryLocationMaster/DefineInventoryLocModel';
import { SearchInventoryLocModel } from '../Master/InventoryLocationMaster/SearchInventoryLocModel';

import { DealersDetailModel, GeographyModel } from '../DealersDetail/DealersDetailModel';
import { DefineItemModel } from '../Master/ItemMaster/DefineItemModel';
import { SearchItemModel } from '../Master/ItemMaster/SearchItemModel';
import { DefineServiceChargesModel } from '../Master/ServiceChergesMaster/DefineServiceChargesModel';
import { InvoiceModel } from '../Transactions/DispatchOrder/DispatchOrderHomeModel';
import { SearchServiceChargesModel } from '../Master/ServiceChergesMaster/SearchServiceChargesModel';
import { DailyProductionPlanModel } from '../Transactions-Production/Daily Production Plan/DailyProductionPlanModel';
import { CoupenModel, SchemeHomeModel } from '../Transactions/Scheme_Configurator/SchemeHomeModel';
import { SchemeConfigurateModel } from '../Transactions/Scheme_Configurator/SchemeConfigurateModel';
import { PoModel } from '../InventoryManagement/GoodsNote/GoodsNoteDefineModel';

import { SampleInvoiceModel } from '../Transactions/OrderAcceptance/SampleInvoiceModel';
import { Definethreefieldmodel } from '../_models/Definethreefieldmodel';
import { SearchMCHallocaTemplateModel } from '../Transaction-Marketing/MarketingCostHeadPercentageAllocaTemplate/SearchMCHallocaTemplateModel';
import { AccountThreeSixtyModel } from '../Transactions/AccountThreeSixty/AccountThreeSixtyModel';
import { CustomerMergeModel } from '../Master/Customer Merge/CustomerMergeModel';
import { QuotationModel } from '../Transactions/Quotation/QuotationModel';
import { SearchTargetModel } from '../Transactions/TargetManagement/SearchTargetModel';
import { DefineitemSubCategoryModel } from '../Master/ItemSubCategoryMaster/DefineitemSubCategoryModel';
import { SearchitemSubCategoryModel } from '../Master/ItemSubCategoryMaster/SearchitemSubCategoryModel';




@Injectable()
export class MasterServices {


  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  getCollectionofLibrary: any;
  //private headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
  }

  getmenubyuser(hero: User): Observable<Mainmenumodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<Mainmenumodel[]>(this.customersUrl + 'api/menu/menuofUser', hero, httpOptions1);
  }
  getAllUsers(user: SearchUserModel): Observable<SearchUserModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchUserModel[]>(this.customersUrl + 'api/master/getAllUsers', user, httpOptions1);
  }

  deleteUser(user: SearchUserModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteUser', user, httpOptions1);
  }
  getUserByid(usr: SearchUserModel): Observable<SearchUserModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchUserModel>(this.customersUrl + 'api/master/getUserByid', usr, httpOptions1);
  }
  getDept(usr: User): Observable<SearchUserModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchUserModel>(this.customersUrl + 'api/master/getDept', usr, httpOptions1);
  }

  saveuserdetails(usr: SearchUserModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveuserdetails', usr, httpOptions1);
  }

  getAll(model: SearchPipelineModel): Observable<SearchPipelineModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchPipelineModel[]>(this.customersUrl + 'api/master/getAll', model, httpOptions1);
  }
  getCodeByid(usr: DefinePipelineModel): Observable<DefinePipelineModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefinePipelineModel>(this.customersUrl + 'api/master/getCodeByid', usr, httpOptions1);
  }

  deleteCode(user: SearchPipelineModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteCode', user, httpOptions1);
  }

  savepipeline(usr: DefinePipelineModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savepipeline', usr, httpOptions1);
  }

  getAllAccounts(user: SearchUserModel): Observable<SearchAccountMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchAccountMasterModel[]>(this.customersUrl + 'api/master/getAllAccounts', user, httpOptions1);
  }

  getAccountwismcDetail(user: SearchAccountWiseMachineDetailModel): Observable<SearchAccountWiseMachineDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchAccountWiseMachineDetailModel[]>(this.customersUrl + 'api/master/getAccountwismcDetail', user, httpOptions1);
  }

  getAccountDetailsByid(usr: DefineAccountMasterModel): Observable<DefineAccountMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAccountMasterModel>(this.customersUrl + 'api/master/getAccountDetailsByid', usr, httpOptions1);
  }

  getaccwisedetailByid(usr: DefineAccountwisemachinedetailModel): Observable<DefineAccountwisemachinedetailModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineAccountwisemachinedetailModel>(this.customersUrl + 'api/master/getaccwisedetailByid', usr, httpOptions1);
  }

  getindustorylst(usr: User): Observable<DefineAccountMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineAccountMasterModel>(this.customersUrl + 'api/master/getindustorylst', usr, httpOptions1);
  }

  getmcstatus(usr: User): Observable<DefineAccountwisemachinedetailModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineAccountwisemachinedetailModel>(this.customersUrl + 'api/master/getmcstatus', usr, httpOptions1);
  }

  deleteaccount(user: SearchAccountMasterModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteaccount', user, httpOptions1);
  }

  getAccountLikeForMchDtl(user: User): Observable<SearchAccountWiseMachineDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchAccountWiseMachineDetailModel[]>(this.customersUrl + 'api/master/getAccountLikeForMchDtl', user, httpOptions1);
  }

  saveaccount(usr: DefineAccountMasterModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };

    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveaccount', usr, httpOptions1);
  }

  savemcdtl(usr: DefineAccountwisemachinedetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savemcdtl', usr, httpOptions1);
  }

  getcitylike(user: User): Observable<DefineAccountMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAccountMasterModel[]>(this.customersUrl + 'api/master/getcitylike', user, httpOptions1);
  }

  getAllCompany(user: SearchCompanyMasterModel): Observable<SearchCompanyMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchCompanyMasterModel[]>(this.customersUrl + 'api/master/getAllCompany', user, httpOptions1);
  }

  getCustomerDetailsByid(usr: DefineCompanyMasterModel): Observable<DefineCompanyMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineCompanyMasterModel>(this.customersUrl + 'api/master/getCustomerDetailsByid', usr, httpOptions1);
  }

  getDefaultcurrency(usr: User): Observable<DefineCompanyMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineCompanyMasterModel>(this.customersUrl + 'api/master/getDefaultcurrency', usr, httpOptions1);
  }


  savecompany(usr: DefineCompanyMasterModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };

    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savecompany', usr, httpOptions1);
  }
  getAccountwiseproduct(user: DefineAccountwisemachinedetailModel): Observable<DefineAccountwisemachinedetailModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAccountwisemachinedetailModel>(this.customersUrl + 'api/master/getAccountwiseproduct', user, httpOptions1);
  }
  getAccountwismc(user: DefineAccountwisemachinedetailModel): Observable<DefineAccountwisemachinedetailModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAccountwisemachinedetailModel>(this.customersUrl + 'api/master/getAccountwismc', user, httpOptions1);
  }
  deleteaccwisemc(user: SearchAccountWiseMachineDetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteaccwisemc', user, httpOptions1);
  }
  getAllDocumentNum(model: SearchEnquirynameModel): Observable<SearchEnquirynameModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchEnquirynameModel[]>(this.customersUrl + 'api/master/getAllDocumentNum', model, httpOptions1);
  }
  getdocumentbyid(usr: SearchEnquirynameModel): Observable<SearchEnquirynameModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchEnquirynameModel>(this.customersUrl + 'api/master/getdocumentbyid', usr, httpOptions1);
  }
  savedocument(usr: SearchEnquirynameModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savedocument', usr, httpOptions1);
  }
  deleteDocumentnum(user: SearchEnquirynameModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteDocumentnum', user, httpOptions1);
  }

  getAllCurrencyEx(model: SearchCurrencyExchangeModel): Observable<SearchCurrencyExchangeModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchCurrencyExchangeModel[]>(this.customersUrl + 'api/master/getAllCurrencyEx', model, httpOptions1);
  }

  deleteCurrencyex(model: SearchCurrencyExchangeModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteCurrencyex', model, httpOptions1);
  }
  getdefaultcurr(model: User): Observable<SearchCurrencyExchangeModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchCurrencyExchangeModel>(this.customersUrl + 'api/master/getdefaultcurr', model, httpOptions1);
  }
  savecurrencyex(usr: SearchCurrencyExchangeModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savecurrencyex', usr, httpOptions1);
  }
  getCode(model: DefinePipelineModel): Observable<DefinePipelineModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefinePipelineModel[]>(this.customersUrl + 'api/master/getCode', model, httpOptions1);
  }

  getAllProduct(model: SearchProductModel): Observable<SearchProductModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchProductModel[]>(this.customersUrl + 'api/master/getAllProduct', model, httpOptions1);
  }
  deleteProduct(user: SearchProductModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteProduct', user, httpOptions1);
  }
  getProductByid(usr: DefineProductModel): Observable<DefineProductModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineProductModel>(this.customersUrl + 'api/master/getProductByid', usr, httpOptions1);
  }

  getProductcat(usr: User): Observable<DefineProductModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineProductModel>(this.customersUrl + 'api/master/getProductcat', usr, httpOptions1);
  }

  saveproduct(usr: DefineProductModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };

    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveproduct', usr, httpOptions1);
  }
  getproduct(model: DefineProductModel): Observable<DefineProductModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineProductModel[]>(this.customersUrl + 'api/master/getproduct', model, httpOptions1);
  }
  getallActiveuser(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getallActiveuser', user, httpOptions1);
  }
  getdesignationallowbyid(user: User): Observable<DesignationAllowanceModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DesignationAllowanceModel>(this.customersUrl + 'api/master/getdesignationallowbyid', user, httpOptions1);
  }

  saveMasterQueries(model: MasterConfigurationModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/config/saveMasterQueries', model, httpOptions1);
  }
  getHeaderlst(user: User): Observable<Header[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<Header[]>(this.customersUrl + 'api/config/getHeaderlst', user, httpOptions1);
  }

  getMasterData(user: MasterHomePageModel): Observable<MasterHomePageModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<MasterHomePageModel>(this.customersUrl + 'api/config/getMasterData', user, httpOptions1);
  }

  getMasterById(user: User): Observable<DefineMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineMasterModel>(this.customersUrl + 'api/config/getMasterById', user, httpOptions1);
  }

  deleteMasterData(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/config/deleteMasterData', user, httpOptions1);
  }

  getFields(user: User): Observable<DefineMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineMasterModel>(this.customersUrl + 'api/config/getFields', user, httpOptions1);
  }

  saveMaster(value: any): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/config/saveMaster', value, httpOptions1);
  }

  getResorceWithAllownc(model: SearchUserModel): Observable<DefineResourceAllowanceModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineResourceAllowanceModel[]>(this.customersUrl + 'api/master/getResorceWithAllownc', model, httpOptions1);
  }

  getResourceLikeForAllownce(user: User): Observable<SearchUserModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchUserModel[]>(this.customersUrl + 'api/master/getResourceLikeForAllownce', user, httpOptions1);
  }

  getAllowanceByResource(model: DefineResourceAllowanceModel): Observable<DefineResourceAllowanceModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineResourceAllowanceModel>(this.customersUrl + 'api/master/getAllowanceByResource', model, httpOptions1);
  }

  saveResourceAllowance(model: DefineResourceAllowanceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveResourceAllowance', model, httpOptions1);
  }

  getAlltypesMaster(user: User): Observable<SearchTypeMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTypeMasterModel[]>(this.customersUrl + 'api/master/getAlltypesMaster', user, httpOptions1);
  }

  btnsaveType(user: DefineTypeMasterModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/btnsaveType', user, httpOptions1);
  }

  getTypeMasterById(user: User): Observable<DefineTypeMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineTypeMasterModel>(this.customersUrl + 'api/master/getTypeMasterById', user, httpOptions1);
  }

  deleteType(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteType', user, httpOptions1);
  }

  getAllAMCType(user: User): Observable<DefineAMCTypeModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAMCTypeModel[]>(this.customersUrl + 'api/master/getAllAMCType', user, httpOptions1);
  }

  getAMCTypeById(user: User): Observable<DefineAMCTypeModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAMCTypeModel>(this.customersUrl + 'api/master/getAMCTypeById', user, httpOptions1);
  }

  btnsaveAmcType(model: DefineAMCTypeModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/btnsaveAmcType', model, httpOptions1);
  }

  deleteAMCType(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteAMCType', user, httpOptions1);
  }

  getLocalities(user: User): Observable<LocalityModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<LocalityModel>(this.customersUrl + 'api/master/getLocalities', user, httpOptions1);
  }

  btnsaveLocality(model: LocalityModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/btnsaveLocality', model, httpOptions1);
  }

  getLocalityById(user: User): Observable<LocalityModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<LocalityModel>(this.customersUrl + 'api/master/getLocalityById', user, httpOptions1);
  }

  deleteLocality(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteLocality', user, httpOptions1);
  }

  getAllCities(user: User): Observable<CityMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CityMasterModel[]>(this.customersUrl + 'api/master/getAllCities', user, httpOptions1);
  }

  getAllCountries(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllCountries', user, httpOptions1);
  }
  getStatesByCountry(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getStatesByCountry', user, httpOptions1);
  }

  btnsaveCity(model: CityMasterModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveCity', model, httpOptions1);
  }
  getCityById(user: User): Observable<CityMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CityMasterModel>(this.customersUrl + 'api/master/getCityById', user, httpOptions1);
  }

  getAllCitiesByState(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllCitiesByState', user, httpOptions1);
  }
  getAllLocalesByCity(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllLocalesByCity', user, httpOptions1);
  }

  getAllCustomertypes(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllCustomertypes', user, httpOptions1);
  }

  saveCustomerTypeWorkflow(model: CustomerTypeWorkflowModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveCustomerTypeWorkflow', model, httpOptions1);
  }

  getAlldataBytypeid(user: User): Observable<CustomerTypeWorkflowModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CustomerTypeWorkflowModel>(this.customersUrl + 'api/master/getAlldataBytypeid', user, httpOptions1);
  }

  getAreaListByRegionlst(user: ResourceRightsModel): Observable<ResourceRightsModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ResourceRightsModel>(this.customersUrl + 'api/master/getAreaListByRegionlst', user, httpOptions1);
  }

  getAllRegions(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllRegions', user, httpOptions1);
  }

  getResourceLocations(user: User): Observable<ResourceRightsModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ResourceRightsModel>(this.customersUrl + 'api/master/getResourceLocations', user, httpOptions1);
  }

  saveResourceGeography(model: ResourceRightsModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveResourceGeography', model, httpOptions1);
  }

  getProductCategoriesByResource(user: User): Observable<ProductResourceRightsModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ProductResourceRightsModel>(this.customersUrl + 'api/master/getProductCategoriesByResource', user, httpOptions1);
  }

  saveResourceProCate(model: ProductResourceRightsModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveResourceProCate', model, httpOptions1);
  }

  getResourceCustomerType(user: User): Observable<ProductResourceRightsModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ProductResourceRightsModel>(this.customersUrl + 'api/master/getResourceCustomerType', user, httpOptions1);
  }

  saveResourceCustomertype(model2: ProductResourceRightsModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveResourceCustomertype', model2, httpOptions1);
  }

  getAreaByRegion(user: User): Observable<AreaModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<AreaModel[]>(this.customersUrl + 'api/master/getAreaByRegion', user, httpOptions1);
  }

  getAllallowance(user: User): Observable<SearchAllowanceMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchAllowanceMasterModel[]>(this.customersUrl + 'api/master/getAllallowance', user, httpOptions1);
  }


  getAllBranches(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllBranches', user, httpOptions1);
  }


  getallowanceByid(model: DefineAllowanceModel): Observable<DefineAllowanceModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAllowanceModel>(this.customersUrl + 'api/master/getallowanceByid', model, httpOptions1);
  }

  getAllProductCategorylst(user: User): Observable<ProductCategory1[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ProductCategory1[]>(this.customersUrl + 'api/master/getAllProductCategorylst', user, httpOptions1);
  }
  getUnit(model: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getUnit', model, httpOptions1);
  }

  saveallowance(model: DefineAllowanceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveallowance', model, httpOptions1);
  }

  deleteAllowance(user: SearchAllowanceMasterModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteAllowance', user, httpOptions1);
  }

  getAllSource(user: User): Observable<SearchSourceModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchSourceModel[]>(this.customersUrl + 'api/master/getAllSource', user, httpOptions1);
  }

  getsourceByid(model: DefineSourceModel): Observable<DefineSourceModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineSourceModel>(this.customersUrl + 'api/master/getsourceByid', model, httpOptions1);
  }
  savesourcemaster(model: DefineSourceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savesourcemaster', model, httpOptions1);
  }


  getAllcustomertyps(user: User): Observable<ResourceCType[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ResourceCType[]>(this.customersUrl + 'api/master/getAllcustomertyps', user, httpOptions1);
  }


  deleteSource(user: SearchSourceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteSource', user, httpOptions1);
  }

  savesourcedetail(model: SourceDetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savesourcedetail', model, httpOptions1);
  }
  getsourceDetail(model: SourceDetailModel): Observable<SourceDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SourceDetailModel[]>(this.customersUrl + 'api/master/getsourceDetail', model, httpOptions1);
  }

  deleteDetailsource(user: SourceDetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteDetailsource', user, httpOptions1);
  }
  getSourceDetailById(model: SourceDetailModel): Observable<SourceDetailModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SourceDetailModel>(this.customersUrl + 'api/master/getSourceDetailById', model, httpOptions1);
  }
  getAllCategoryTermCo(user: SearchCategoryTermsConModel): Observable<SearchCategoryTermsConModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchCategoryTermsConModel[]>(this.customersUrl + 'api/master/getAllCategoryTermCo', user, httpOptions1);
  }
  getcategorytermconByid(model: DefineCategoryTermsConModel): Observable<DefineCategoryTermsConModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineCategoryTermsConModel>(this.customersUrl + 'api/master/getcategorytermconByid', model, httpOptions1);
  }
  getcategcustype(usr: User): Observable<DefineCategoryTermsConModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineCategoryTermsConModel>(this.customersUrl + 'api/master/getcategcustype', usr, httpOptions1);
  }
  savecategtermcon(model: DefineCategoryTermsConModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savecategtermcon', model, httpOptions1);
  }
  deleteCategoryterm(user: SearchCategoryTermsConModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteCategoryterm', user, httpOptions1);
  }
  getallgsthdr(model: User): Observable<searchgstheadermodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<searchgstheadermodel[]>(this.customersUrl + 'api/master/getallgsthdr', model, httpOptions1);
  }

  deletegsthdr(user: searchgstheadermodel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deletegsthdr', user, httpOptions1);
  }
  getgsthdrbyid(model: Gstheadermastermodel): Observable<Gstheadermastermodel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<Gstheadermastermodel>(this.customersUrl + 'api/master/getgsthdrbyid', model, httpOptions1);
  }
  savegsthdr(model: Gstheadermastermodel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savegsthdr', model, httpOptions1);
  }
  getgstdetails(model: GSTgroupmastermodel): Observable<GSTgroupmastermodel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<GSTgroupmastermodel>(this.customersUrl + 'api/master/getgstdetails', model, httpOptions1);
  }
  savegstdetails(model: GSTgroupmastermodel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savegstdetails', model, httpOptions1);
  }
  savepassword(model: ChangePasswordModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savepassword', model, httpOptions1);
  }
  checklogin(user: ResetPasswordModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/checklogin', user, httpOptions1);
  }
  getActiveuser(model: ResetPasswordModel): Observable<ResetPasswordModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ResetPasswordModel[]>(this.customersUrl + 'api/master/getActiveuser', model, httpOptions1);
  }
  saveaccesstoken(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveaccesstoken', user, httpOptions1);
  }
  deleteCity(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteCity', user, httpOptions1);
  }
  getsourceDetailBysourceid(user: User): Observable<DropDownModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getsourceDetailBysourceid', user, httpOptions1);
  }



  getAllActivities(user: User): Observable<SearchActivityModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchActivityModel[]>(this.customersUrl + 'api/master/getAllActivities', user, httpOptions1);
  }

  getfinancialyear(model: FinancialyearModel): Observable<FinancialyearModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<FinancialyearModel[]>(this.customersUrl + 'api/master/getfinancialyear', model, httpOptions1);
  }


  saveActivity(model: SearchActivityModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveActivity', model, httpOptions1);
  }


  savefinancialyear(user: FinancialyearModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savefinancialyear', user, httpOptions1);
  }
  getAcitvityById(user: User): Observable<SearchActivityModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchActivityModel>(this.customersUrl + 'api/master/getAcitvityById', user, httpOptions1);
  }

  deletefinanalyer(user: FinancialyearModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deletefinanalyer', user, httpOptions1);
  }


  deleteActivity(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteActivity', user, httpOptions1);
  }


  getAllIndustryTypes(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllIndustryTypes', user, httpOptions1);
  }

  getAllUOM(user: User): Observable<SearchUOMModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchUOMModel[]>(this.customersUrl + 'api/master/getAllUOM', user, httpOptions1);
  }
  getmrktcosthead(model: User): Observable<DefineMarketingModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineMarketingModel>(this.customersUrl + 'api/master/getmrktcosthead', model, httpOptions1);
  }

  getrateByid(model: DefineMarketingModel): Observable<DefineMarketingModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineMarketingModel>(this.customersUrl + 'api/master/getrateByid', model, httpOptions1);
  }

  getAllMarketing(user: User): Observable<SearchMarketingModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchMarketingModel[]>(this.customersUrl + 'api/master/getAllMarketing', user, httpOptions1);
  }
  deletemarketingcost(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deletemarketingcost', user, httpOptions1);
  }
  savemarketing(user: DefineMarketingModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/savemarketing', user, httpOptions1);
  }
  getAllProductCat(model: SearchProductcategoryModel): Observable<SearchProductcategoryModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };

    return this.http.post<SearchProductcategoryModel[]>(this.customersUrl + 'api/master/getAllProductCat', model, httpOptions1);
  }
  getservicecallstatus(model: User): Observable<DropDownModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getservicecallstatus', model, httpOptions1);


  }

  dltProductCategory(user: SearchProductcategoryModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/dltProductCategory', user, httpOptions1);
  }
  saveproductCat(usr: DefineProductcategoryModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveproductCat', usr, httpOptions1);
  }
  getAllStages(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllStages', user, httpOptions1);
  }
  getProductCateByid(usr: DefineProductcategoryModel): Observable<DefineProductcategoryModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineProductcategoryModel>(this.customersUrl + 'api/master/getProductCateByid', usr, httpOptions1);
  }
  getAllitemCat(model: User): Observable<SearchitemSubCategoryModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchitemSubCategoryModel[]>(this.customersUrl + 'api/master/getAllitemCat', model, httpOptions1);
  }

  dltItemsubCategory(user: SearchitemSubCategoryModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/dltItemsubCategory', user, httpOptions1);
  }
  getitemCategory(user: User): Observable<Definethreefieldmodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<Definethreefieldmodel[]>(this.customersUrl + 'api/master/getitemCategory', user, httpOptions1);
  }
  saveitemsubcatgry(usr: DefineitemSubCategoryModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveitemsubcatgry', usr, httpOptions1);
  }

  getItemCateByid(model: DefineitemSubCategoryModel): Observable<DefineitemSubCategoryModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineitemSubCategoryModel>(this.customersUrl + 'api/master/getItemCateByid', model, httpOptions1);
  }
  saveinventorylocation(usr: DefineInventoryLocModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveinventorylocation', usr, httpOptions1);
  }
  getSubCategory(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getSubCategory', user, httpOptions1);
  }
  getAllinventoryLoc(model: User): Observable<SearchInventoryLocModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchInventoryLocModel[]>(this.customersUrl + 'api/master/getAllinventoryLoc', model, httpOptions1);
  }
  getInventoryLocByid(model: DefineInventoryLocModel): Observable<DefineInventoryLocModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineInventoryLocModel>(this.customersUrl + 'api/master/getInventoryLocByid', model, httpOptions1);
  }

  getstorekeeper(model: TeamModel): Observable<TeamModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<TeamModel>(this.customersUrl + 'api/master/getstorekeeper', model, httpOptions1);
  }
  

  dltInventoryLoc(user: SearchInventoryLocModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/dltInventoryLoc', user, httpOptions1);
  }

  dltInventorylst(user: DefineInventoryLocModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/dltInventorylst', user, httpOptions1);
  }
  saveitemMaster(usr: DefineItemModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveitemMaster', usr, httpOptions1);
  }
  getAllitem(model: User): Observable<SearchItemModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchItemModel[]>(this.customersUrl + 'api/master/getAllitem', model, httpOptions1);
  }
  dltItemsmaster(user: SearchItemModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/dltItemsmaster', user, httpOptions1);
  }

  getItemByid(model: User): Observable<DefineItemModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineItemModel>(this.customersUrl + 'api/master/getItemByid', model, httpOptions1);
  }

  getItemLikeForBOMitem(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getItemLikeForBOMitem', user, httpOptions1);
  }
  dltBoM(user: DefineItemModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/dltBoM', user, httpOptions1);
  }
  getItemLikeCpy(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getItemLikeCpy', user, httpOptions1);
  }
  getsalesCategory(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getsalesCategory', user, httpOptions1);
  }
  getSubCategorychked(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getSubCategorychked', user, httpOptions1);
  }

  getSubCategorybycategory(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getSubCategorybycategory', user, httpOptions1);
  }

  getProductItemBysubCatid(model: DefineServiceChargesModel): Observable<DefineServiceChargesModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineServiceChargesModel[]>(this.customersUrl + 'api/master/getProductItemBysubCatid', model, httpOptions1);
  }
  saveServiceCharges(usr: DefineServiceChargesModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveServiceCharges', usr, httpOptions1);
  };
  getAllInventoryLocations(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllInventoryLocations', user, httpOptions1);
  }

  getAllServiceCharges(user: User): Observable<SearchServiceChargesModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchServiceChargesModel[]>(this.customersUrl + 'api/master/getAllServiceCharges', user, httpOptions1);
  }
  getServiceChargesByid(model: DefineServiceChargesModel): Observable<DefineServiceChargesModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineServiceChargesModel>(this.customersUrl + 'api/master/getServiceChargesByid', model, httpOptions1);
  }

  dltServiceChargLoc(user: SearchServiceChargesModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/dltServiceChargLoc', user, httpOptions1);
  }

  getCurrentFinancialYear(user: User): Observable<FinancialyearModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<FinancialyearModel>(this.customersUrl + 'api/master/getCurrentFinancialYear', user, httpOptions1);
  }

  getLossReasons(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getLossReasons', user, httpOptions1);
  }
  getAllResources(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllResources', user, httpOptions1);
  }

  btnsaveApprovals(approval: ResourceRightsModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/btnsaveApprovals', approval, httpOptions1);
  }

  getResourceApprovals(user: User): Observable<ResourceRightsModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<ResourceRightsModel>(this.customersUrl + 'api/master/getResourceApprovals', user, httpOptions1);
  }

  getSuperAuthoritiesLike(model: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getSuperAuthoritiesLike', model, httpOptions1);

  }

  getsms(model: User): Observable<SearchActivityModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<SearchActivityModel>(this.customersUrl + 'api/master/getsms', model, httpOptions1);

  }
  getinventorylocatinCategories(model: DailyProductionPlanModel): Observable<DropDownModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getinventorylocatinCategories', model, httpOptions1);

  }
  getinventorylocatinSubCategories(model: DailyProductionPlanModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getinventorylocatinSubCategories', model, httpOptions1);
  }


  getAllSchemeConfiguration(user: User): Observable<SchemeHomeModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeHomeModel[]>(this.customersUrl + 'api/master/getAllSchemeConfiguration', user, httpOptions1);
  }

  saveNewScheme(model: SchemeConfigurateModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/saveSchemeConfiguration', model, httpOptions1);
  }

  getAllCitiesByCountries(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getAllCitiesByCountries', user, httpOptions1);
  }
  getmrktcostSubhead(model: User): Observable<SampleInvoiceModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SampleInvoiceModel>(this.customersUrl + 'api/master/getmrktcostSubhead', model, httpOptions1);
  }


  getspaInventoryLocation(model: SearchUserModel): Observable<SearchUserModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchUserModel>(this.customersUrl + 'api/master/getspaInventoryLocation', model, httpOptions1);
  }
  getLocalitiesSearchLike(user: User): Observable<LocalityModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<LocalityModel[]>(this.customersUrl + 'api/master/getLocalitiesSearchLike', user, httpOptions1);
  }

  getcustomerType(model: SearchActivityModel): Observable<SearchActivityModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SearchActivityModel[]>(this.customersUrl + 'api/master/getcustomerType', model, httpOptions1);
  }
  getproductDiscount(model: DefineProductModel): Observable<DefineProductModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineProductModel[]>(this.customersUrl + 'api/master/getproductDiscount', model, httpOptions1);
  }

  getAllitemSubCategory(model: DefineProductcategoryModel): Observable<DefineProductcategoryModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineProductcategoryModel[]>(this.customersUrl + 'api/master/getAllitemSubCategory', model, httpOptions1);
  }
  getSpareListCat(usr: User): Observable<DefineProductModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineProductModel>(this.customersUrl + 'api/master/getSpareListCat', usr, httpOptions1);
  }

  getProductLikeSpareItem(user: User): Observable<DefineServiceChargesModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineServiceChargesModel[]>(this.customersUrl + 'api/master/getProductLikeSpareItem', user, httpOptions1);
  }

  // getCityByArea(user: User): Observable<ResourceRightsModel> {
  //   const httpOptions1 = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + localStorage.getItem('Token'),
  //     })
  //   };
  //   return this.http.post<ResourceRightsModel>(this.customersUrl + 'api/master/getCityByArea', user, httpOptions1);
  // }

  getLocalesByCity(user: User): Observable<ResourceRightsModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ResourceRightsModel>(this.customersUrl + 'api/master/getLocalesByCity', user, httpOptions1);
  }

  getSchemeConfigureByid(model: SchemeConfigurateModel): Observable<SchemeConfigurateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeConfigurateModel>(this.customersUrl + 'api/master/getSchemeConfigureByid', model, httpOptions1);
  }
  getAllArea(user: User): Observable<AreaModel1[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<AreaModel1[]>(this.customersUrl + 'api/master/getAllArea', user, httpOptions1);
  }
  getAllCoupenConfiguration(user: User): Observable<CoupenModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CoupenModel[]>(this.customersUrl + 'api/master/getAllCoupenConfiguration', user, httpOptions1);
  }
  deleteScheme(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/master/deleteScheme', user, httpOptions1);
  }
  getstopSchemeConfigureByid(model: SchemeConfigurateModel): Observable<SchemeConfigurateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeConfigurateModel>(this.customersUrl + 'api/master/getstopSchemeConfigureByid', model, httpOptions1);
  }
  getmenubyusermobile(hero: User): Observable<Mainmenumodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<Mainmenumodel[]>(this.customersUrl + 'api/menu/menuofUserformobile', hero, httpOptions1);
  }
  getmrktcosthead1(model: User): Observable<DefineMarketingModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefineMarketingModel>(this.customersUrl + 'api/master/getmrktcosthead1', model, httpOptions1);
  }
 
  getfromcustomerLike(model: User): Observable<DropDownModel[]> {
  
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getfromcustomerLike', model, httpOptions1);

  }

  getTocustomerLike(model: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getTocustomerLike', model, httpOptions1);

  }
 
  getcustomerinfocustomermerarge(model:User): Observable<CustomerMergeModel>{
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      }) 
    };
    return this.http.post<CustomerMergeModel>(this.customersUrl+ 'api/master/getcustomerinfocustomermerarge',model,httpOptions1);
  }
  getSchemeType(model1: QuotationModel): Observable<SchemeConfigurateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeConfigurateModel>(this.customersUrl + 'api/master/getSchemeType', model1, httpOptions1);
  }
  verifyprefix(model: SchemeConfigurateModel): Observable<SchemeConfigurateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeConfigurateModel>(this.customersUrl + 'api/master/verifyprefix', model, httpOptions1);
  }
  getAllAreaByState(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/master/getAllAreaByState', user, httpOptions1);

  }
  getcityByTypeLike(user: User): Observable<CityMasterModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<CityMasterModel[]>(this.customersUrl + 'api/master/getcityByTypeLike', user, httpOptions1);
  }
  getresourcelstIL(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/master/getresourcelstIL', user, httpOptions1);
  }
  getAllCitiesReport(user: User): Observable<CityMasterModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CityMasterModel[]>(this.customersUrl + 'api/master/getAllCitiesReport', user, httpOptions1);
  }
  

}