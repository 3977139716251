import { OnInit, Component, OnDestroy } from '@angular/core';
import { DefineAMCTypeModel } from './DefineAMCTypeModel';
import { User } from 'src/app/_models';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
@Component({
    selector: 'app-AMCd',
    //  moduleId: module.id,
    templateUrl: 'DefineAMCType.html'
})
export class DefineAMCType implements OnInit,OnDestroy {
    user = new User();
    model = new DefineAMCTypeModel();
    id: number;
    pagename: string;

    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
    }
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit(): void {

        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.lab = false;
        this.model.com = false
        this.user.userid = Number(localStorage.getItem("userid"));
        this.model.userid = Number(localStorage.getItem("userid"));
        this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.Masterservice.getAMCTypeById(user)
                .subscribe(
                    data => {
                        this.model = data;
                    });
        }
    }

    oncheckLab($event) {
        if ($event.target.checked) {
            this.model.amcFlag = "L"
            this.model.lab = true;
            this.model.com = false;
        }
    }

    onCheckCom($event) {
        if ($event.target.checked) {
            this.model.amcFlag = "C"
            this.model.lab = false;
            this.model.com = true;
        }
    }

    btnsaveAmcType() {
        if (this.model.code == null|| this.model.code=="") {
            this.notifyService.showRroor("Please enter AMC code", "Oops!");
            return;
        }
        if (this.model.name == null||this.model.name=="") {
            this.notifyService.showRroor("Please enter AMC name", "Oops!")
            return;
        }
        if (this.model.com == false && this.model.lab == false) {
            this.notifyService.showRroor("Please select atleast one type", "Oops!")
            return;
        }

        this.Masterservice.btnsaveAmcType(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['/AMC Type']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    btnback() {
        this.router.navigate(['/AMC Type']);
    }

}