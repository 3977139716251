import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models';
import { AlertService, CompanyService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { DefineCategoryTermsConModel } from './DefineCategoryTermsConModel';
import { Location } from '@angular/common'
import { DropDownModel } from 'src/app/_models/DropDownModel';

@Component({
    selector: 'app-AMCd',
    templateUrl: 'DefineCategoryTermsCon.html'
})
export class DefineCategoryTermsCon implements OnInit, OnDestroy {
    sub: any;
    id: number;

    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location) { }

    user = new User();
    model = new DefineCategoryTermsConModel();
    custtypelst: DropDownModel[] = [];
    categorylst: DropDownModel[] = [];
    pagename: string;
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;

            this.Masterservice.getcategorytermconByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                    },
                    error => {
                        this.alertService.error(error);
                    });


            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
        }
        this.getcusttype();

        this.pagename = localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');

        }
    }

    getcusttype() {
        let user = new User();
        this.custtypelst = [];
        this.categorylst = [];
        this.model.categorylstint = [];
        this.Masterservice.getcategcustype(user)
            .subscribe((value) => {
                this.custtypelst = value.customertypelst;
                this.categorylst = value.industrylst;
            });
    }
    salesclick($event) {
        if ($event.target.checked) {
            this.model.sales = true;
            this.model.service = false;
            this.model.purchase = false;

        }
    }
    serviceclick($event) {
        if ($event.target.checked) {
            this.model.service = true;
            this.model.sales = false;
            this.model.purchase = false;
        }
    }
    purchaseclick($event) {
        if ($event.target.checked) {
            this.model.purchase = true;
            this.model.service = false;
            this.model.sales = false;
        }
    }

    savecateg() {
        if (!this.model.sales && !this.model.service && !this.model.purchase) {
            this.notifyService.showRroor("Please Select Conditions Type ", "Opps");
            return;
        }
        if (this.model.sales) {
            if (this.model.customerTypeId == null || this.model.customerTypeId == 0) {
                this.notifyService.showRroor("Please Select Customer type", "Opps");
                return;
            }
            if (this.model.categoryID == null || this.model.categoryID == 0) {
                this.notifyService.showRroor("Please Select Category", "Opps");
                return;
            }
        }
        if (this.model.heading == null) {
            this.notifyService.showRroor("Please Enter Heading", "Opps");
            return;
        }
        if (this.model.descrption == null) {
            this.notifyService.showRroor("Please Enter Descrption", "Opps");
            return;
        }


        // this.model.complst = this.complst
        this.Masterservice.savecategtermcon(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Terms and Conditions']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
    btncancle() {
        this.router.navigate(['Terms and Conditions']);
    }
    onSelectCategory(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Category!!", "Oops!");
            return;
        }
        if (this.model.chkall == true) {
            for (let i = 0; i < this.categorylst.length; i++) {
                this.model.categorylstint.push(this.categorylst[i].id);
            }
        }
        else {
            this.model.categoryID = value;
        }

    }
    onselectCustomerType(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Customer type!!", "Oops!");
            return;
        }
        this.model.customerTypeId = value;

    }
}