import { BooleanLiteral } from 'typescript';
import { QuotationHistory } from '../Quotation/QuotationModel';

export class DefineOrderAcceptanceModel {
  id: number;
  srno: number;
  unitName: string;
  accountid: number;
  quotationCode: string;
  customername: string;
  quotationName: string;
  dateTime: Date;
  startDate: Date;
  remark: string;
  address: string
  address1: string;
  userid: number;
  paymentType: string;
  creditdays: number;
  avialablecredit: number;
  dealerflag: boolean;
  afterordercredit: number;
  ponumber: string;
  podate: Date;
  allcheckselect: boolean;
  chkgst: Boolean;
  chkpan: boolean;
  chkconsignee: boolean;
  afterbal: boolean;
  addresslst: producModel[] = [];
  termmodlst: producModel[] = [];
  modellst: producModel[] = [];
  alltotal: number;
  totalgstvalue: number;
  pricevalue: number;
  orderApprove: string;
  orderchecksele: boolean;
  sercheckselect: boolean;
  instacheckselect: boolean;
  checkselect: boolean = false;
  termscondtion: string;
  termscondtions: boolean = false;
  termsid: number;
  quotaTermsconid: number;
  alltotalword: string;
  qversionlst: QuotationHistory[] = [];

  chkwihitins: boolean;
  chkoutsides: boolean;
  caddress: string;
  pincode: string;
  localityid: number;
  locality: string;
  gstno: string;
  panno: string;
  gsttype: string;
  Userid: number;
  limit: boolean = false;
  creditdue: Date;
  chkNewAdderss: boolean;
  chkselectvalue: boolean;
  shortname: string;
  newaddress: string;
  email: string;
  landmark: string;
  newPincode: string;
  locality1: string;
  locale: string;
  city: string;
  state: string;
  country: string;
  maplocation: string;
  mobile: string;
  phone: string;
  localityid1: number;
  consigneename: string;
  consigneeaddress: string;
  consigneepincode: string;
  consigneegsttype: string;
  consigneechkwihitins: boolean;
  consigneechkoutsides: boolean;
  consigneegstno: string;
  shipingaddid: number;
  consigneeID: number;
  newgstno: string;
  newchkoutsides: boolean;
  newchkwihitins: boolean;
  newgsttype: string;
  ratewithdiscount :number;
  bcreditlimit: number;
  orderAcceptanceByCompany :boolean;

}
export class producModel {

  id: number;
  accountid: number;
  creditdays: number;
  accWiseMcDetlsID: number;
  localityName: string;
  pincode: string;
  address: string;
  cheselect: boolean;
  price: number;
  gstamount: number;
  gstrate: number;
  prouctname: string;
  pricevalue: number;
  quantity: number;
  quotationQuantity: number;
  totalAmount: number;
  checkselect: boolean;
  termscondtion: string;
  quotationProID: number
  termsid: number;
  procheckselect: boolean;
  orderchecksele: boolean;
  termsheding: string;
  quotaTermsconid: number;
  shipingaddid: number;
  discount: number;
  discountAmount: number;
  shortname: string;
  newaddress: string;
  email: string;
  landmark: string;
  newPincode: string;
  locality1: string;
  locale: string;
  city: string;
  state: string;
  country: string;
  maplocation: string;
  mobile: string;
  phone: string;
  localityid1: number;
  displayaddress: string;
  newgstno: string;
  newchkoutsides: boolean;
  newchkwihitins: boolean;
  newgsttype: string;
  ratewithdiscount :number;
  testqty:number;
  balance:number;

}