import { Component, HostListener, OnInit, Inject, forwardRef } from '@angular/core';

import { Router, ActivatedRoute, NavigationExtras } from '@angular/router/';
import { ITreeOptions, TREE_ACTIONS, TreeNode, TreeModel } from 'angular-tree-component';
import { MasterServices } from '../_services/MasterServices';
import { MenuServices } from '../_services';
import { NotificationService } from '../_services/notification.service';

@Component({
  selector: 'app-contextmenu',
  templateUrl: 'contextmenu.component.html',
  styles: [
    `.menu {
      position: absolute;
      background: rgba(255, 255, 255, 0.9);
      padding: 7px;
      border-radius: 5px;
      box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
    }`,
    `ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }`,
    `li {
      padding: 7px;
      border-radius: 3px;
      cursor: pointer;
    }`,
    `li:hover {
      background-color: aliceblue;
    }`,
  ]
})
export class ContextmenuComponent implements OnInit {
  contextMenu: { node: TreeNode, x: number, y: number } = null;
  sourceNode: TreeNode = null;
  editNode: TreeNode = null;
  model: any = {};
  doCut = false;
  nodes = [];
  private sub: any;
  id: number;
  str:string;
  constructor(
    private router: Router,  private notifyService: NotificationService,private Masterservice: MasterServices, private menuServices: MenuServices, private route: ActivatedRoute,) {

  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = + params['id'];
      this.str= params['from'];

  });
    if (this.id != null && this.id > 0) {
      this.model.userId = this.id
    }
    if(this.str!=null&&this.str!=""){
      this.model.from=this.str;
    }
    this.menuServices.getmenuTreebyUserid(this.model)
      .subscribe(value => {
        this.nodes = value;
      });
  }

  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        contextMenu: (treeModel: TreeModel, treeNode: TreeNode, e: MouseEvent) => {
          e.preventDefault();
          if (this.contextMenu && treeNode === this.contextMenu.node) {
            return this.closeMenu();
          }
          this.contextMenu = {
            node: treeNode,
            x: e.pageX,
            y: e.pageY
          };
        },
        click: (treeModel: TreeModel, treeNode: TreeNode, e: MouseEvent) => {
          this.closeMenu();
          TREE_ACTIONS.TOGGLE_ACTIVE(treeModel, treeNode, e);
        }
      }
    }
  };

  closeMenu = () => {
    this.contextMenu = null;
  }
  btnback(){
    this.router.navigate(['CRMHome']);
  }
  edit = () => {
   localStorage.setItem('menuid', this.contextMenu.node.data.id);
   localStorage.setItem('Menuname',this.contextMenu.node.data.name);
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': this.model.userId,
      'from':this.model.from,
      'forstr':"edit"
    }
  };
   this.router.navigate(['addmenu'],navigationExtras);
  //   console.log("data " + this.contextMenu.node.data);
  //   console.log("ID " + this.model.userId);
  //   // this.router.navigate(["definedocumenttype"]);

  // //  this.closeMenu();
  // let navigationExtras: NavigationExtras = {
  //   queryParams: {
  //     'from':this.model.from,
  //     'forstr':"edit"
  //   }
  // };
  // localStorage.setItem('menuid', this.contextMenu.node.data.id);




  }
  add = () => {

    localStorage.setItem('parentid', this.contextMenu.node.data.id);
    localStorage.setItem('Menuname',this.contextMenu.node.data.name);
    localStorage.setItem('sequence',this.contextMenu.node.data.sequence)
     let navigationExtras: NavigationExtras = {
      queryParams: {
        'id': this.model.userId,
        'from':this.model.from,
        'forstr':"new"
      }
    };
    this.router.navigate(['addmenu'],navigationExtras);

    // console.log('data ' + this.contextMenu.node.data);
    // console.log('ID ' + this.model.userId);
    // localStorage.setItem('menuid', this.contextMenu.node.data.id);
   
    // localStorage.setItem('Menuname',this.contextMenu.node.data.name)
   








  }
  stopEdit = () => {
    this.editNode = null;
  }

  copy = () => {
    this.sourceNode = this.contextMenu.node;
    this.doCut = false;
    this.closeMenu();
  }

  cut = () => {
    this.model.menuId = this.contextMenu.node.data.id;
    this.model.userId = Number(localStorage.getItem('userid'));
    this.model.parentId = this.contextMenu.node.data.id;
    this.menuServices.deletemenubyuser(this.model)
      .subscribe(value => {
        this.notifyService.showSuccess(value.message,"  Success");
       // this.router.navigate(['Home']);
        this.ngOnInit();
      });

  }

  paste = () => {
    if (!this.canPaste()) {
      return;
    }
    this.doCut
      ? this.sourceNode.treeModel.moveNode(this.sourceNode, { parent: this.contextMenu.node, index: 0 })
      : this.sourceNode.treeModel.copyNode(this.sourceNode, { parent: this.contextMenu.node, index: 0 });

    this.sourceNode = null;
    this.closeMenu();
  }

  canPaste = () => {
    if (!this.sourceNode) {
      return false;
    }
    return this.sourceNode.treeModel.canMoveNode(this.sourceNode, { parent: this.contextMenu.node, index: 0 });
  }
}

function uuid() {
  return Math.floor(Math.random() * 10000000000000);
}
