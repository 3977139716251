<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{user.pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">

            <div class="row" style="margin-top: 20px">
                <div class="input-field col l3 s12 m3" style="margin: 0px;">
                    <select (change)="onSelect($event.target.value)">
                        <option selected disabled>Choose One</option>
                        <option *ngFor="let type of searchtype" [value]="type.name">{{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 s12 m3" style="text-align: center;margin: 0px;">
                    <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search Field</label>
                </div>
                <div class="col s4 m4 l4" style="margin-top: 7px;">
                    <button type="button" (click)="btnsearch($event)"
                        class="waves-effect waves-light btn">Go</button>&nbsp;
                    <button type="button" (click)="btnnew($event)" class="waves-effect waves-light btn">New</button>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <tr>
                                <th width="10%" style="text-align: center">Sr.No</th>
                                <th width="50%" style="text-align: center">Supplier</th>
                                <th width="20%" style="text-align: center">Invoice Number</th>
                                <th width="10%" style="text-align: center">Invoice Date</th>
                                <th width="10%" style="text-align: center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of purchasereturnlst">
                                <td style="text-align: center">{{lst.srno}}</td>
                                <td style="text-align: center">{{lst.supplier}}</td>
                                <td style="text-align: center">{{lst.invoiceno}}</td>
                                <td style="text-align: center">{{lst.invdate}}</td>
                                <td style="text-align: center"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>