<app-header></app-header>
<div class="container" style="width: 80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Purchase Return Note</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row" style="margin-top: 20px">
                <div class="input-field col l5 m5 s5">
                    <input type="text" id="dynamicUserIdsSecondWay" [(ngModel)]="model.suppliername" type="text"
                        class="autocomplete" name="Name" (keyup)="getUserIdsSecondtWay($event)" />
                    <label for="dynamicUserIdsSecondWay">Supplier Name</label>
                </div>


            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <div *ngFor="let prod of products" style="background-color: aliceblue">
                        <div class="col l12 m12 s12">
                            <div class="col l5 m5 s5" style="font-size: 14px; font-weight: bold">
                                <span>Item Name</span><br>
                                <span>{{prod.itemname }}</span>
                            </div>
                            <div class="col l1 m1 s1" style="font-size: 14px; font-weight: bold">
                                <span>Rate</span><br>
                                <span>{{prod.rate | number:'1.2-2'}}</span>
                            </div>
                            <div class="col l2 m2 s2" style="font-size: 14px; font-weight: bold">
                                <span>Received Quantity</span><br>
                                <span>{{prod.recvqnty | number:'1.2-2'}}</span>
                            </div>
                            <div class="col l1 m1 s1" style="font-size: 14px; font-weight: bold">
                                <span>Net Amount</span><br>
                                <span>{{prod.netamount | number:'1.2-2'}}</span>
                            </div>
                            <div class="col l1 m1 s1" style="font-size: 14px; font-weight: bold">
                                <span>GST Amount</span><br>
                                <span>{{prod.gstamount | number:'1.2-2'}}</span>
                            </div>
                            <div class="col l2 m2 s2" style="font-size: 14px; font-weight: bold">
                                <span>Total Amount</span><br>
                                <span>{{prod.totalamount | number:'1.2-2'}}</span>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr style="background: gainsboro;">
                                    <th width="10%" style="text-align: left">Sr.No</th>
                                    <th width="50%" style="text-align: left">Inventory Location</th>
                                    <th width="20%" style="text-align: right">Actual Stock</th>
                                    <th width="20%" style="text-align: right">Return Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of prod.inventorylst">
                                    <td style="text-align: left">{{lst.srno}}</td>
                                    <td style="text-align: left">{{lst.location}}</td>
                                    <td style="text-align: right">{{lst.stock | number:'1.2-2'}}</td>
                                    <td style="text-align: right"><input type="number" [(ngModel)]="lst.rejectqnty"
                                            #rejectqnty="ngModel" data-toggle="tooltip" data-placement="top"
                                            title="Return Quantity" min="0" (ngModelChange)="calculate()"
                                            style="margin: 0px;text-align: end">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>
                </div>
            </div>


            <div class="row">
                <div class="col l12 m12 s12" style="text-align: right">
                    <button (click)="btnback()" type="button" class="waves-effect cancelbutton btn">Cancel</button>
                    &nbsp;
                    <button (click)="savePurchaseReturn($event)" type="button" id="btnsave1"
                        class="waves-effect waves-light btn">Save</button>
                </div>
            </div>


        </div>
    </div>
</div>