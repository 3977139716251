import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { AlertService, MasterServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchMarketingModel } from "./SearchMarketingModel";

@Component({
    selector: 'app-localD',
    //  moduleId: module.id,
    templateUrl: 'SearchMarketing.html'
})
export class SearchMarketing implements OnInit {
    p: any;
    model = new SearchMarketingModel();
    user = new User();
    pagename: string;
    mrktmodellst: SearchMarketingModel[] = [];
    mrktmodellst1: SearchMarketingModel[] = [];
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Code" },
        { id: 3, "name": "Name" },
        { id: 4, "name": "Cost Head" }
    ];
    public i;


    constructor(private Masterservice: MasterServices, private alertService: AlertService, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
        this.pagename=this.router.url
    }
    ngOnInit(): void {
        this.getAllMarketing();
        localStorage.setItem("pagename",this.pagename); 
        //this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }

    getAllMarketing() {
        this.Masterservice.getAllMarketing(this.user)
            .subscribe(value => {
                this.mrktmodellst = value;
                this.mrktmodellst1 = [];
                value.forEach((item) => this.mrktmodellst1.push({"id": item.id ,"name": item.name, "code": item.code, "costhead": item.costhead,"txtserch": item.txtserch, "txtselect": item.txtselect,"uomid":item.uomid,"uomname":item.uomname,"subheadvalue":item.subheadvalue,"totalsubheadvalue":item.totalsubheadvalue,"balance":item.balance,"qty":item.qty,"chkselectvalue":item.chkselectvalue }));           
                this.mrktmodellst = this.mrktmodellst1;
            });
    }
   
    onSelectSrch(value) {
        this.model.txtselect = value;

    }
    btnsearch($event) {

        if (this.model.txtselect == "All") {
            this.getAllMarketing()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Code") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.mrktmodellst1 = [];
                this.mrktmodellst.forEach((item) => {
                    if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.mrktmodellst1.push({"id": item.id ,"name": item.name, "code": item.code, "costhead": item.costhead,"txtserch": item.txtserch, "txtselect": item.txtselect,"uomid":item.uomid,"uomname":item.uomname,"subheadvalue":item.subheadvalue,"totalsubheadvalue":item.totalsubheadvalue,"balance":item.balance,"qty":item.qty,"chkselectvalue":item.chkselectvalue});
                    }
                });
                this.mrktmodellst = this.mrktmodellst1;
            }
        }
         else if (this.model.txtselect == "Name") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.mrktmodellst1 = [];
                this.mrktmodellst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.mrktmodellst1.push({"id": item.id ,"name": item.name, "code": item.code, "costhead": item.costhead,"txtserch": item.txtserch, "txtselect": item.txtselect,"uomid":item.uomid,"uomname":item.uomname,"subheadvalue":item.subheadvalue,"totalsubheadvalue":item.totalsubheadvalue,"balance":item.balance,"qty":item.qty,"chkselectvalue":item.chkselectvalue});
                    }
                });
                this.mrktmodellst = this.mrktmodellst1;
            }
        }
        else if (this.model.txtselect == "Cost Head") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.mrktmodellst1 = [];
                this.mrktmodellst.forEach((item) => {
                    if ((item.costhead.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.mrktmodellst1.push({"id": item.id ,"name": item.name, "code": item.code, "costhead": item.costhead,"txtserch": item.txtserch, "txtselect": item.txtselect,"uomid":item.uomid,"uomname":item.uomname,"subheadvalue":item.subheadvalue,"totalsubheadvalue":item.totalsubheadvalue,"balance":item.balance,"qty":item.qty,"chkselectvalue":item.chkselectvalue});
                    }
                });
                this.mrktmodellst = this.mrktmodellst1;
            }
        }
    }

    btnnew() {
        this.router.navigate(['/DefineMarketing']);
    }
    btnedit(val) {

        this.router.navigate(['/DefineMarketing', val]);

    }
    btndelts(val) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {

            this.user.trnid = val
            this.Masterservice.deletemarketingcost(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }

    }

}