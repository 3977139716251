<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{user.pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <!-- <div class="center-align row" style="margin-top: 20px">
                <div class="input-field col l3 s12 m3" style="margin: 0px;">
                    <select (change)="onSelect($event.target.value)">
                        <option selected disabled>Choose One</option>
                        <option *ngFor="let type of searchtype" [value]="type.name">{{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 s12 m3" style="text-align: center;margin: 0px;">
                    <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search Field</label>
                </div>
                <div class="col s4 m4 l4" style="margin-top: 7px;">
                    <button type="button" (click)="btnsearch($event)"
                        class="waves-effect waves-light btn">Go</button>&nbsp;
                    <button type="button" (click)="btnGRN($event)" class="waves-effect waves-light btn">New GRN</button>
                </div>
            </div> -->
            <div class="row">

                <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.suppliercheckselect" #suppliercheckselect="ngModel" type="checkbox"
                        style="text-align: 5px">&nbsp;&nbsp;Supplier
                </div>
                <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.grncheckselect" #statuscheckselect="ngModel" type="checkbox"
                        style="text-align: 5px">&nbsp;&nbsp;GRN Number
                </div>

                <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.datecheckselect" #datecheckselect="ngModel" type="checkbox"
                        style="text-align: 5px">&nbsp;&nbsp;Date Range
                </div>
                <div class="col s2"></div>
                <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.ponocheckselect" #ponocheckselect="ngModel" type="checkbox"
                        style="text-align: 5px">&nbsp;&nbsp;PO Number
                </div>


            </div>


            <div class="row">
                <div class="input-field col s2 l2 m2" style="margin-bottom: 0px;">
                    <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search Text</label>
                </div>
                <!-- <div class="input-field col s2 l2 m2" style="margin-bottom: 0px;">
                    <input [(ngModel)]="model.txtserchstatus" #txtserchsta="ngModel" type="text" class=""
                        id="searchstatus" data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search Text</label>
                </div> -->
                <div class="input-field col s2" style="margin-bottom: 0px;">
                    <input [(ngModel)]="model.txtserchgrn" #txtserch="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search GRN No.</label>
                </div>
                <div class="input-field col s2 m2 l2" style=" margin-top: 12px;margin-bottom: 0px;">
                    <input type="text" placeholder=" Start Date" [(ngModel)]="model.startdate" id="start"
                        autocomplete="off" data-toggle="tooltip" data-placement="top" title="Start Date"
                        class="form-control" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                        (ngModelChange)="selectStartDate(model.startdate)">

                </div>
                <div class="input-field col s2 m2 l2" style=" margin-top: 12px;margin-bottom: 0px;">

                    <input type="text" placeholder="End Date" [(ngModel)]="model.enddate" id="start"
                        data-toggle="tooltip" data-placement="top" title="End Date" class="form-control"
                        autocomplete="off" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                        (ngModelChange)="selectEndDate(model.enddate)">
                </div>
                <div class="input-field col s2 l2 m2" style=" text-align: left; margin-bottom: 0px;">
                    <input [(ngModel)]="model.txtserchpono" #txtserchpo="ngModel" type="text" class="" id="searchpo"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search For PO Number</label>
                </div>
                <div class="col  s2 l2 m2" style=" margin-top: 30px;"> <span><img
                            src="../../../assets/images/search.png" (click)="btnsearch()" data-toggle="tooltip"
                            data-placement="top" title="Edit" width="20px" /></span>&nbsp;
                    <button type="button" (click)="btnGRN($event)" class="waves-effect waves-light btn">New GRN</button>
                </div>
            </div>
            <div class="row" style="
            padding-top: 1%;
            padding-right: 1%;
            padding-bottom: 1%;
            padding-left: 1%;
        ">
                <table>
                    <thead style="background: gainsboro;">
                        <th width="8%" style="text-align: center">Sr.No.</th>
                        <th width="30%" style="text-align: center">Supplier Name</th>
                        <th width="15%" style="text-align: center">PO Number</th>
                        <th width="10%" style="text-align: center">Status</th>
                        <th width="15%" style="text-align: center">GRN Number</th>
                        <th width="10%" style="text-align: center">GRN Date</th>
                        <th width="10%" style="text-align: center">Actions</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of grnlst | paginate: { itemsPerPage: 10, currentPage: p }">
                            <td style="text-align: center">{{lst.srno}}</td>
                            <td style="text-align: center">{{lst.supplierName}}</td>
                            <td style="text-align: center">{{lst.pono}}</td>
                            <td style="text-align: center">{{lst.grnstatus}}</td>
                            <td style="text-align: center">{{lst.grnno}}</td>
                            <td style="text-align: center">{{lst.grndate | date:"dd-MM-yyyy"}}</td>
                            <td style="text-align: center"><span><span class="fa fa-check-circle-o fa-2x"
                                        style="color: black;" (click)="onEdit(lst.grnno,lst.poid)" title="GIN"
                                        *ngIf="lst.grnstatus == 'Open'">
                                        <!-- <img src="../../../assets/images/edit.png"
                                            (click)="onEdit(lst.grnno)" title="Edit" *ngIf="lst.grnstatus == 'Open'"
                                            width="20px" /> -->
                                    </span>&nbsp;&nbsp;<span><img src="../../../assets/images/delete.png"
                                            (click)="btndelts(lst.grnno)" title="Delete" *ngIf="lst.grnstatus == 'Open'"
                                            width="20px" /></span></span>
                                <span><img src="../../../assets/images/view.png" (click)="btnView(lst.grnno,lst.poid)"
                                        title="View" *ngIf="lst.grnstatus == 'Close'" width="20px" /></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align: right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </span>
            </div>
        </div>
    </div>
</div>