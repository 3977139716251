<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="col l1"></div>
                    <div class="input-field col l5 m5 s5" style="margin-bottom: 0px;">
                        <span>Reason</span>
                        <select (change)="onSelectReason($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Type">
                            <option *ngFor="let type of reasonlst" [value]="type.name"
                                [selected]="type.name == model.reasonFull">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="col l1"></div>
                    <div class="input-field col l5 m5 s5" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.name" #name="ngModel" type="text" class="" id="name"
                            data-toggle="tooltip" data-placement="top" title="Type name" maxlength="15">
                        <label for="name" class="active">Type name</label>
                    </div>
                    <div class="input-field col l5 m5 s5" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.description" #description="ngModel" type="text" class="" id="desc"
                            data-toggle="tooltip" data-placement="top" title="Description">
                        <label for="desc" class="active">Description</label>
                    </div>
                    <div class="col l1"></div>
                </div>

            </div>

            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button (click)="btnback()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnsaveType()" type="button" class="waves-effect waves-light btn">Add</button>
                </div>
            </div>
        </div>
    </div>
</div>