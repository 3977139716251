import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { AlertService, MasterServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { DefineMarketingModel } from "./DefineMarketingModel";

@Component({
    selector: 'app-localD',
    //  moduleId: module.id,
    templateUrl: 'DefineMarketing.html'
})
export class DefineMarketing implements OnInit,OnDestroy {
    sub: any;
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
    }

    model = new DefineMarketingModel();
    mrktcostlst: DropDownModel[] = [];

    uomlst: DropDownModel[] = [];
    user = new User();
    id: number;
    pagename: string;

    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit(): void {
        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');

        }
        this.getmrktcosthead();

        this.model.userid = Number(localStorage.getItem("userid"));
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getrateByid(this.model)
                .subscribe(data => {
                    this.model = data;
                    if(this.model.chkselectvalue == true)
                    {
                        this.model.uomid = null;
                        this.model.rate = null;
                    }
                },
                    error => {
                        this.alertService.error(error);
                    });

        }

    }

    getmrktcosthead() {
        let user = new User();
        this.mrktcostlst = [];

        this.Masterservice.getmrktcosthead(user)
            .subscribe((value) => {
                this.mrktcostlst = value.mrklst
                this.uomlst = value.ulst

            });
    }

    onSelectmrktcost(value) {
        this.model.marketingcostid = value;
    }
    onSelectuom(value) {
        this.model.uomid = value;

    }
    btnback() {
        this.router.navigate(["/Marketing Cost Sub-Head"]);
    }

    btnsavemarketing() {
        if (this.model.marketingcostid == null) {
            this.notifyService.showRroor("Please select Marketing Cost Head", "Oops!");
            return;
        }
        if (this.model.code == null) {
            this.notifyService.showRroor("Please enter Code ", "Oops!");
            return;
        }
        if (this.model.description == null) {
            this.notifyService.showRroor("Please enter Description", "Oops!");
            return;
        }
        // if (this.model.chkselectvalue == "!null") {
        // if (this.model.uomid == null) {
        //     this.notifyService.showRroor("Please Select Unit of Measure", "Oops!");
        //     return;
        // }
        // }
        // if (this.model.chkselectvalue == "!null") {
        // if (this.model.rate == null) {
        //     this.notifyService.showRroor("Please enter Rate", "Oops!");
        //     return;
        // }
        // }
        if(this.model.chkselectvalue != null)
        {
            this.model.uomid == null
            this.model.rate == null
        }


        this.Masterservice.savemarketing(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Marketing Cost Sub-Head']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

}

