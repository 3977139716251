import { OnInit, Component } from '@angular/core';
import { User } from 'src/app/_models';
import { CityMasterModel } from './CityMasterModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
@Component({
    selector: 'app-citys',
    //  moduleId: module.id,
    templateUrl: 'SearchCityMaster.html'
})
export class SearchCityMaster implements OnInit {
    p: any;
    user = new User();
    model = new CityMasterModel();
    citylst: CityMasterModel[] = []
    citylst1: CityMasterModel[] = [];
    public i;
    pagename: string;
    sum: number = 1;
    totalItems: any;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "City Code" },
        { id: 3, "name": "City Name" },
        { id: 4, "name": "State" },
        { id: 5, "name": "Country" }
    ];
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
        this.pagename = this.router.url
    }
    ngOnInit(): void {
        this.user.userid = Number(localStorage.getItem("userid"));
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getAllCities()
        localStorage.setItem("pagename", this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }

    getAllCities() {
        this.citylst = []
        this.user.pageno = this.sum;
        this.user.totalItems;
        this.Masterservice.getAllCities(this.user)
            .subscribe(value => {
                this.citylst = value;
                this.citylst1 = value
                for (let i = 0; i < this.citylst.length; i++) {
                    this.user.totalItems = this.citylst[i].totalItems; ''
                }
            });
    }

    btnnew() {
        this.router.navigate(['/newCity']);
    }

    btnedit(value) {
        this.router.navigate(['/editCity', value]);
    }

    btndelts(value) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.user.trnid = value
            this.Masterservice.deleteCity(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }

    }

    onSelectSrch(val) {
        this.model.txtselect = val
    }
    showPageIndex(pageIndex) {
            this.sum = pageIndex;
            if (this.model.txtselect == null && this.model.txtserch == null) {
                this.getAllCities();
            } else {
                if (this.model.txtselect == "All") {
                    // this.getLocalities(0, 10);
                    this.getAllCities();
                    this.model.txtserch = null;
                } else {
                    if (this.model.txtserch == null || this.model.txtserch == "") {
                        this.notifyService.showRroor("Enter Criteria To Search", "Oops");
                        return;
                    }
                    this.user.message = this.model.txtselect
                    this.user.sendstring = this.model.txtserch
                    this.user.pageno = this.sum;
                    this.Masterservice.getcityByTypeLike(this.user)
                        .subscribe(value => {
                            this.citylst = value;
                            this.citylst1 = value
                            for (let i = 0; i < this.citylst.length; i++) {
                                this.user.totalItems = this.citylst[10].totalItems;
                            }
                        });
                }
            }
    
        }
    
    // btnsearch() {
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            // this.getLocalities(0, 10);
            this.getAllCities();
            this.model.txtserch = null;
        } else {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                this.notifyService.showRroor("Enter Criteria To Search", "Oops");
                return;
            }

            this.user.message = this.model.txtselect
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            this.Masterservice.getcityByTypeLike(this.user)
                .subscribe(value => {
                    this.citylst = value;
                    this.citylst1 = value
                    for (let i = 0; i < this.citylst.length; i++) {
                        this.user.totalItems = this.citylst[10].totalItems;
                    }
                });
            this.citylst = this.citylst1;

        }
        // this.user.sendstring = this.model.txtserch
        //     this.user.pageno = this.sum;
        //     this.user.message = this.model.txtselect
        // if (this.model.txtselect == "All") {
        //     this.getAllCities()
        //     this.model.txtserch = null;

        // }
        // else if (this.model.txtselect == "City Code") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.citylst1 = [];
        //         this.citylst.forEach((item) => {
        //             if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.citylst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "country": item.country, "state": item.state, "countryid": null, "stateid": null, "userid": null,"citylst":null,"region": item.region,"totalItems":null });
        //             }
        //         });
        //         this.citylst = this.citylst1;
        //     }
        // }
        // else if (this.model.txtselect == "City Name") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.citylst1 = [];
        //         this.citylst.forEach((item) => {
        //             if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.citylst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "country": item.country, "state": item.state, "countryid": null, "stateid": null, "userid": null ,"citylst":null,"region": item.region,"totalItems":null});
        //             }
        //         });
        //         this.citylst = this.citylst1;
        //     }
        // } else if (this.model.txtselect == "State") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.citylst1 = [];
        //         this.citylst.forEach((item) => {
        //             if ((item.state.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.citylst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "country": item.country, "state": item.state, "countryid": null, "stateid": null, "userid": null ,"citylst":null,"region":null,"totalItems":null});
        //             }
        //         });
        //         this.citylst = this.citylst1;
        //     }
        // }
        // else if (this.model.txtselect == "Country") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.citylst1 = [];
        //         this.citylst.forEach((item) => {
        //             if ((item.country.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.citylst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "country": item.country, "state": item.state, "countryid": null, "stateid": null, "userid": null,"citylst":null,"region":null,"totalItems":null });
        //             }
        //         });
        //         this.citylst = this.citylst1;
        //     }
        // }
    }

    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
}