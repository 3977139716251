import { common } from './Common';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { HttpModule } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { User } from '../_models/index';
//import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Mainmenumodel } from '../_models/Mainmenumodel';
import { MasterModel } from '../_models/MasterModel';
import { SaveSuccessModel } from '../_models/SaveSucessModel';
// import { Observable } from 'rxjs';
import { DropDownModel } from '../_models/DropDownModel';
import { Observable } from 'rxjs';
import { TeamModel, ResourceModel1, LocalePincodeModel } from '../Transactions/Teams/TeamModel';
import { CallCenterDashboardModel } from '../Transactions/CallCenterDashboard/CallCenterDashboardModel';
import { contactmodel, productmodel, ProspectModel } from '../Transactions/Sales-Transaction/ProspectModel';
import { DefineResourceAllowanceModel } from '../Master/ResourceAllowance/DefineResourceAllowanceModel';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { DailyCallModel, Products } from '../Transactions/DailyCall/DailyCallModel';
import { OpportunitiesModel } from '../Transactions/Opportunities/OpportunitiesModel';
import { QuotationModel, CurrencyModel, QuotationHistory } from '../Transactions/Quotation/QuotationModel';
import { SearchTargetModel } from '../Transactions/TargetManagement/SearchTargetModel';
import { TargetHomeModel } from '../Transactions/TargetManagement/ TargetHomeModel';
import { OrderHomepageModel } from '../Transactions/OrderAcceptance/OrderHomepageModel';
import { DefineOrderAcceptanceModel } from '../Transactions/OrderAcceptance/DefineOrderAcceptanceModel';
import { Definethreefieldmodel } from '../_models/Definethreefieldmodel';
import { PaymentScreenModel } from '../Transactions/PaymentScreen/PaymentScreenModel';
import { SearchPurchaseorderModel } from '../Transactions/DealerPurchaseOrder/SearchPurchaseorderModel';
import { DefinePuchaseOrderModel, productModels } from '../Transactions/DealerPurchaseOrder/DefinePuchaseOrderModel';
import { DispatchOrderModel } from '../Transactions/DispatchOrder/DispatchOrderModel';
import { DispatchOrderHomeModel } from '../Transactions/DispatchOrder/DispatchOrderHomeModel';
import { MarketBudgetGeoperTemModel } from '../Transaction-Marketing/MarketingBudgGeoPercentageallocTem/MarketBudgetGeoperTemModel';
// import { SearchMCHallocaTemplateModel } from '../Transactions/MarketingCostHead%AllocaTemplate/SearchMCHallocaTemplateModel';
import { FinancialyearModel } from '../Master/FinancialYear/FinancialyearModel';
import { CostheadModel, DefineMarketingBudgetModel } from '../Transaction-Marketing/MarketingBudget/DefineMarketingBudgetModel'
import { mrkbudgetdealerallocationModel } from '../Transaction-Marketing/MarketingBudgetDealerAllocation/mrkbudgetdealerallocationModel';
import { AccountThreeSixtyModel } from '../Transactions/AccountThreeSixty/AccountThreeSixtyModel';
import { SearchMCHallocaTemplateModel } from '../Transaction-Marketing/MarketingCostHeadPercentageAllocaTemplate/SearchMCHallocaTemplateModel';
import { SalesdashboardModel } from '../Transactions/SalesDashboard/SalesdashboardModel';
import { SearchPaymentReceivableReceiptModel } from '../Transactions/PaymentReceivableReceipt/SearchPaymentReceivableReceiptModel';
import { SearchPurchaseOrderModel } from '../InventoryManagement/PurchaseOrder/SearchPurchaseOrderModel';
import { DefinePurchaseOrderModel } from '../InventoryManagement/PurchaseOrder/DefinePurchaseOrderModel';

import { StockadjustmentModel } from '../Transactions/Stock Adjustment/StockadjustmentModel';

import { DefinePaymentReceivableReceiptModel } from '../Transactions/PaymentReceivableReceipt/DefinePaymentReceivableReceiptModel';
import { SchemeHomeModel } from '../Transactions/Scheme_Configurator/SchemeHomeModel';
import { DefineMaterialRequisitionModel } from '../Transactions-Production/Material Requisition/DefineMaterialRequisitionModel';
import { DefineAccountMaster } from '../Master/AccountMaster/DefineAccountMaster';
import { DefineAccountMasterModel } from '../Master/AccountMaster/DefineAccountMasterModel';
import { SchemeConfigurateModel } from '../Transactions/Scheme_Configurator/SchemeConfigurateModel';
import { SearchMaterialRequisitionModel } from '../Transactions-Production/Material Requisition/SearchMaterialRequisitionModel';
import { productInvoice, SampleInvoiceModel } from '../Transactions/OrderAcceptance/SampleInvoiceModel';
import { MaterialInventoryHomeModel } from '../InventoryManagement/MaterialInventoryHome/MaterialInventoryHomeModel';
import { productIssue, SpareIssueModel } from '../Transactions/Spare Issue/SpareIssueModel';
import { SearchUserModel } from '../Master/ResourceMaster/SearchUserModel';
import { ThreeSixtyModel } from '../Master/ThreeSixtyMaster/ThreeSixtyModel';
import { InstallationDCRModel } from '../Transactions-Installation/DCR/InstallationDCRModel';
import { CityMasterModel } from '../Master/CityMaster/CityMasterModel';
import { CustomerMergeModel } from '../Master/Customer Merge/CustomerMergeModel';
import { ReassignModel } from '../Transactions/Reassign/Reassignmodel';
import { SalesReturnModel } from '../Transactions/Sales Return/SalesReturnModel';

// import {  DefineMarketingBudgetModel } from '../Transactions/MarketingBudget/DefineMarketingBudgetModel';
@Injectable()
export class TransactionServices {


  private headers = new Headers({
    'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Token': localStorage.getItem('Token'),
    'dbName': localStorage.getItem('dbName')
  });
  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  //private headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
  }


  getAllTeams(user: User): Observable<TeamModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<TeamModel[]>(this.customersUrl + 'api/transaction/getAllTeams', user, httpOptions1);
  }
  getAllAccountcall(user: User): Observable<CallCenterDashboardModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CallCenterDashboardModel[]>(this.customersUrl + 'api/transaction/getAllAccountcall', user, httpOptions1);
  }



  getgeographydetails(model: ProspectModel): Observable<ProspectModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    }; return this.http.post<ProspectModel>(this.customersUrl + 'api/transaction/getgeographydetails', model, httpOptions1);
  }


  saveTeamData(model: TeamModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveTeamData', model, httpOptions1);
  }

  getCustomerDetails(model: ProspectModel): Observable<ProspectModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ProspectModel>(this.customersUrl + 'api/transaction/getCustomerDetails', model, httpOptions1);
  }
  getlocality(model: ProspectModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getlocality', model, httpOptions1);
  }

  getAssignedtoList(model: TeamModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAssignedtoList', model, httpOptions1);
  }
  getleadAssignedtoList(model: TeamModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getleadAssignedtoList', model, httpOptions1);
  }


  getcustomerBySearch(model: CallCenterDashboardModel): Observable<CallCenterDashboardModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CallCenterDashboardModel[]>(this.customersUrl + 'api/transaction/getcustomerBySearch', model, httpOptions1);
  }
  getsubcallcen(model: CallCenterDashboardModel): Observable<productmodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<productmodel[]>(this.customersUrl + 'api/transaction/getsubcallcen', model, httpOptions1);
  }

  getDataByOpportunityId(user: User): Observable<DailyCallModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DailyCallModel>(this.customersUrl + 'api/transaction/getDataByOpportunityId', user, httpOptions1);
  }

  getAllOpportunitiesOfResource(user: User): Observable<OpportunitiesModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<OpportunitiesModel[]>(this.customersUrl + 'api/transaction/getAllOpportunitiesOfResource', user, httpOptions1);
  }
  getTeamById(user: User): Observable<TeamModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<TeamModel>(this.customersUrl + 'api/transaction/getTeamById', user, httpOptions1);
  }

  getpincodelike(model: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getpincodelike', model, httpOptions1);

  }


  getResourceForTeam(model: TeamModel): Observable<ResourceModel1[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ResourceModel1[]>(this.customersUrl + 'api/transaction/getResourceForTeam', model, httpOptions1);

  }
  getregionlst(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getregionlst', user, httpOptions1);
  }

  btnsaveDailyCall(model: DailyCallModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnsaveDailyCall', model, httpOptions1);

  }

  getallAreaByregion(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getallAreaByregion', user, httpOptions1);

  }


  getproductmodellike(model: User): Observable<productmodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<productmodel[]>(this.customersUrl + 'api/transaction/getproductmodellike', model, httpOptions1);

  }
  getallTeamByarea(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getallTeamByarea', user, httpOptions1);

  }
  saveaccountProspect(model: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveaccountProspect', model, httpOptions1);

  }
  getallUserByTeam(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getallUserByTeam', user, httpOptions1);
  }
  getlocaleByUser(user: SearchTargetModel): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getlocaleByUser', user, httpOptions1);
  }

  savetarget(model: SearchTargetModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savetarget', model, httpOptions1);
  }
  getOpportunityInfo(user: User): Observable<QuotationModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<QuotationModel>(this.customersUrl + 'api/transaction/getOpportunityInfo', user, httpOptions1);
  }

  getCurrencyOfProduct(user: User): Observable<CurrencyModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CurrencyModel>(this.customersUrl + 'api/transaction/getCurrencyOfProduct', user, httpOptions1);
  }
  getfinancialyelst(user: User): Observable<SearchTargetModel> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getfinancialyelst', user, httpOptions1);
  }

  deletecontact(model: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deletecontact', model, httpOptions1);
  }
  getalllocale(user: SearchTargetModel): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getalllocale', user, httpOptions1);

  }

  btnsavelead(model: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnsavelead', model, httpOptions1);
  }
  gettargetById(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/gettargetById', user, httpOptions1);
  }
  getAllTargetyear(user: TargetHomeModel): Observable<TargetHomeModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<TargetHomeModel[]>(this.customersUrl + 'api/transaction/getAllTargetyear', user, httpOptions1);
  }
  deleteTeam(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deleteTeam', user, httpOptions1);
  }

  getproductCategorieslike(model: User): Observable<productmodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<productmodel[]>(this.customersUrl + 'api/transaction/getproductCategorieslike', model, httpOptions1);
  }

  getProductsByPC(user: User): Observable<Products[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<Products[]>(this.customersUrl + 'api/transaction/getProductsByPC', user, httpOptions1);
  }

  getAllOrder(model: OrderHomepageModel): Observable<OrderHomepageModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<OrderHomepageModel[]>(this.customersUrl + 'api/transaction/getAllOrder', model, httpOptions1);
  }
  getaddressbycustomer(user: DefineOrderAcceptanceModel): Observable<DefineOrderAcceptanceModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineOrderAcceptanceModel>(this.customersUrl + 'api/transaction/getaddressbycustomer', user, httpOptions1);
  }
  getleadofresourse(model: User): Observable<User> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<User>(this.customersUrl + 'api/transaction/getleadofresourse', model, httpOptions1);

  }
  getcallofresourse(model: User): Observable<User> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<User>(this.customersUrl + 'api/transaction/getcallofresourse', model, httpOptions1);

  }



  BookServiceCall(model: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/BookServiceCall', model, httpOptions1);

  }

  getservicecalldetails(model: ProspectModel): Observable<ProspectModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ProspectModel>(this.customersUrl + 'api/transaction/getservicecalldetails', model, httpOptions1);

  }


  deleteQuotationProduct(user: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deleteQuotationProduct', user, httpOptions1);
  }
  btnsaveQuotation(model: QuotationModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnsaveQuotation', model, httpOptions1);
  }


  getOrderByid(model: DefineOrderAcceptanceModel): Observable<DefineOrderAcceptanceModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineOrderAcceptanceModel>(this.customersUrl + 'api/transaction/getOrderByid', model, httpOptions1);
  }
  getservicecalldata(model: User): Observable<CallCenterDashboardModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CallCenterDashboardModel[]>(this.customersUrl + 'api/transaction/getservicecalldata', model, httpOptions1);

  }

  saveOrderAcceptance(model: DefineOrderAcceptanceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveOrderAcceptance', model, httpOptions1);

  }

  getallactivity(model: User): Observable<Definethreefieldmodel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<Definethreefieldmodel[]>(this.customersUrl + 'api/transaction/getallactivity', model, httpOptions1);
  }


  getcustomerexiste(model: ProspectModel): Observable<ProspectModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ProspectModel[]>(this.customersUrl + 'api/transaction/getcustomerexiste', model, httpOptions1);

  }

  saveterms(model: DefineOrderAcceptanceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveterms', model, httpOptions1);
  }
  SaveServiceCall(model: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/SaveServiceCall', model, httpOptions1);
  }

  saveproductaddress(user: productmodel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveproductaddress', user, httpOptions1);
  }
  getproblemcat(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getproblemcat', user, httpOptions1);
  }

  SendAndsaveOTP(user: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/SendAndsaveOTP', user, httpOptions1);
  }

  Saveserviceinvoice(user: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/Saveserviceinvoice', user, httpOptions1);
  }

  VerifyOTP(user: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/VerifyOTP', user, httpOptions1);
  }

  getAllAcceptedOrders(user: User): Observable<DispatchOrderHomeModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DispatchOrderHomeModel[]>(this.customersUrl + 'api/transaction/getAllAcceptedOrders', user, httpOptions1);
  }

  getOrderByidForInvoice(user: User): Observable<DispatchOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DispatchOrderModel>(this.customersUrl + 'api/transaction/getOrderByidForInvoice', user, httpOptions1);
  }

  saveDispatchOrder(model: DispatchOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveDispatchOrder', model, httpOptions1);
  }


  getareaByregion(user: MarketBudgetGeoperTemModel): Observable<MarketBudgetGeoperTemModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<MarketBudgetGeoperTemModel>(this.customersUrl + 'api/transaction/getareaByregion', user, httpOptions1);
  }
  getServiceInvoiceDetails(user: User): Observable<PaymentScreenModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PaymentScreenModel>(this.customersUrl + 'api/transaction/getServiceInvoiceDetails', user, httpOptions1);
  }

  getAreaLike(model: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAreaLike', model, httpOptions1);
  }




  getmcostSubhead(model: SearchMCHallocaTemplateModel): Observable<SearchMCHallocaTemplateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchMCHallocaTemplateModel>(this.customersUrl + 'api/transaction/getmcostSubhead', model, httpOptions1);
  }
  getfinancialyears(user: User): Observable<MarketBudgetGeoperTemModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<MarketBudgetGeoperTemModel>(this.customersUrl + 'api/transaction/getfinancialyears', user, httpOptions1);
  }

  checkServicecall(user: ProspectModel): Observable<ProspectModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),

        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<ProspectModel>(this.customersUrl + 'api/transaction/checkServicecall', user, httpOptions1);
  }


  getDCRHestory(user: ProspectModel): Observable<DailyCallModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DailyCallModel[]>(this.customersUrl + 'api/transaction/getDCRHestory', user, httpOptions1);
  }
  getmrketingBudget(model: DefineMarketingBudgetModel): Observable<DefineMarketingBudgetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<DefineMarketingBudgetModel>(this.customersUrl + 'api/transaction/getmrketingBudget', model, httpOptions1);
  }
  checkleadStatus(prospectmodel: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/checkleadStatus', prospectmodel, httpOptions1);
  }
  getaccountAllDetails(user: User): Observable<AccountThreeSixtyModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<AccountThreeSixtyModel>(this.customersUrl + 'api/transaction/getaccountAllDetails', user, httpOptions1);
  }

  getactivityoverdue(model: SalesdashboardModel): Observable<SalesdashboardModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SalesdashboardModel>(this.customersUrl + 'api/transaction/getactivityoverdue', model, httpOptions1);
  }

  getNextdaysactivity(model: SalesdashboardModel): Observable<SalesdashboardModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SalesdashboardModel[]>(this.customersUrl + 'api/transaction/getNextdaysactivity', model, httpOptions1);
  }

  btnSendQuotation(model: DailyCallModel): Observable<SaveSuccessModel> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnSendQuotation', model, httpOptions1);
  }

  getApprovedactivity(model: SalesdashboardModel): Observable<SalesdashboardModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SalesdashboardModel[]>(this.customersUrl + 'api/transaction/getApprovedactivity', model, httpOptions1);
  }
  btnVerifiedOTPforSalesQuotation(model: QuotationModel): Observable<SaveSuccessModel> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnVerifiedOTPforSalesQuotation', model, httpOptions1);
  }


  getAccountsReceivables(model: SalesdashboardModel): Observable<SalesdashboardModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SalesdashboardModel[]>(this.customersUrl + 'api/transaction/getAccountsReceivables', model, httpOptions1);
  }
  getAllpurchaseorder(model: SearchPurchaseOrderModel): Observable<SearchPurchaseOrderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchPurchaseOrderModel[]>(this.customersUrl + 'api/transaction/getAllpurchaseorder', model, httpOptions1);
  }
  getVendorLike(model: User): Observable<DefinePurchaseOrderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePurchaseOrderModel[]>(this.customersUrl + 'api/transaction/getVendorLike', model, httpOptions1);
  }

  getAllPaymentReceipts(user: User): Observable<SearchPaymentReceivableReceiptModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchPaymentReceivableReceiptModel[]>(this.customersUrl + 'api/transaction/getAllPaymentReceipts', user, httpOptions1);
  }
  getpartyLikeForPR(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getpartyLikeForPR', user, httpOptions1);
  }

  getvenaddress(user: DefinePurchaseOrderModel): Observable<DefinePurchaseOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePurchaseOrderModel>(this.customersUrl + 'api/transaction/getvenaddress', user, httpOptions1);
  }
  getItemLike(model: User): Observable<DefinePurchaseOrderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePurchaseOrderModel[]>(this.customersUrl + 'api/transaction/getItemLike', model, httpOptions1);
  }


  savePurchaseOrder(model: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savePurchaseOrder', model, httpOptions1);
  }
  getpurchaseOrderByid(user: DefinePurchaseOrderModel): Observable<DefinePurchaseOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePurchaseOrderModel>(this.customersUrl + 'api/transaction/getpurchaseOrderByid', user, httpOptions1);
  }
  savePurchase(model: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savePurchase', model, httpOptions1);
  }

  deleteOrder(user: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deleteOrder', user, httpOptions1);
  }


  savecategoryterms(user: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savecategoryterms', user, httpOptions1);
  }

  getAllInvoicesOfParty(user: User): Observable<DefinePaymentReceivableReceiptModel> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePaymentReceivableReceiptModel>(this.customersUrl + 'api/transaction/getAllInvoicesOfParty', user, httpOptions1);
  }

  savetermcondition(user: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savetermcondition', user, httpOptions1);
  }

  savePaymentReceipt(model: DefinePaymentReceivableReceiptModel): Observable<SaveSuccessModel> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savePaymentReceipt', model, httpOptions1);
  }

  btnApproveQuotation(model: QuotationModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnApproveQuotation', model, httpOptions1);
  }

  getcatrgoryterms(user: DefinePurchaseOrderModel): Observable<DefinePurchaseOrderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<DefinePurchaseOrderModel[]>(this.customersUrl + 'api/transaction/getcatrgoryterms', user, httpOptions1);
  }
  saveAllpotermscondition(user: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveAllpotermscondition', user, httpOptions1);
  }
  getInventoryLocation(model: User): Observable<StockadjustmentModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<StockadjustmentModel>(this.customersUrl + 'api/transaction/getInventoryLocation', model, httpOptions1);
  }
  getauthorizedBy(model: StockadjustmentModel): Observable<StockadjustmentModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<StockadjustmentModel>(this.customersUrl + 'api/transaction/getauthorizedBy', model, httpOptions1);
  }
  savestockadjustment(user: StockadjustmentModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savestockadjustment', user, httpOptions1);
  }
  deletestock(user: StockadjustmentModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deletestock', user, httpOptions1);
  }
  savematerialrequisiotion(user: DefineMaterialRequisitionModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savematerialrequisiotion', user, httpOptions1);
  }

  getSuppliersLike(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getSuppliersLike', user, httpOptions1);
  }

  getallrequisition(model: SearchMaterialRequisitionModel): Observable<SearchMaterialRequisitionModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchMaterialRequisitionModel[]>(this.customersUrl + 'api/transaction/getallrequisition', model, httpOptions1);
  }


  getAccountDetails(user: User): Observable<DefineAccountMasterModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineAccountMasterModel>(this.customersUrl + 'api/transaction/getAccountDetails', user, httpOptions1);
  }

  saverequisiotionIssueNote(user: SearchMaterialRequisitionModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saverequisiotionIssueNote', user, httpOptions1);
  }

  btnApplySchemeCode(model: QuotationModel): Observable<SchemeConfigurateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeConfigurateModel>(this.customersUrl + 'api/transaction/btnApplySchemeCode', model, httpOptions1);
  }


  deleterequisition(user: DefineMaterialRequisitionModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deleterequisition', user, httpOptions1);
  }

  btnsaveReAssignTo(model: DailyCallModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnsaveReAssignTo', model, httpOptions1);
  }
  getlocations(model: StockadjustmentModel): Observable<StockadjustmentModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<StockadjustmentModel>(this.customersUrl + 'api/transaction/getlocations', model, httpOptions1);
  }
  getmaterialrequisionnote(model: SearchMaterialRequisitionModel): Observable<SearchMaterialRequisitionModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchMaterialRequisitionModel>(this.customersUrl + 'api/transaction/getmaterialrequisionnote', model, httpOptions1);
  }

  deletematerialRequisition(user: SearchMaterialRequisitionModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deletematerialRequisition', user, httpOptions1);
  }
  getCustomerLike(model: User): Observable<SampleInvoiceModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SampleInvoiceModel[]>(this.customersUrl + 'api/transaction/getCustomerLike', model, httpOptions1);
  }
  saveSampleInvoice(model: SampleInvoiceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveSampleInvoice', model, httpOptions1);
  }

  getproductcategorys(usr: User): Observable<productInvoice> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<productInvoice>(this.customersUrl + 'api/transaction/getproductcategorys', usr, httpOptions1);
  }
  getProductsByprodcategory(usr: User): Observable<productInvoice[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<productInvoice[]>(this.customersUrl + 'api/transaction/getProductsByprodcategory', usr, httpOptions1);
  }
  getitemnames(usr: MaterialInventoryHomeModel): Observable<MaterialInventoryHomeModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<MaterialInventoryHomeModel[]>(this.customersUrl + 'api/transaction/getitemnames', usr, httpOptions1);
  }
  getitemcategoryandSub(usr: MaterialInventoryHomeModel): Observable<any[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<any>(this.customersUrl + 'api/transaction/getitemcategoryandSub', usr, httpOptions1);
  }

  getResourceLike(model: User): Observable<SpareIssueModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SpareIssueModel[]>(this.customersUrl + 'api/transaction/getResourceLike', model, httpOptions1);
  }
  getAllItemsLikes(model: User): Observable<productIssue[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<productIssue[]>(this.customersUrl + 'api/transaction/getAllItemsLikes', model, httpOptions1);
  }

  savespareissue(model: productIssue): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savespareissue', model, httpOptions1);
  }

  btnApplyschemecodedealer(model: productModels): Observable<SchemeConfigurateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeConfigurateModel>(this.customersUrl + 'api/transaction/btnApplyschemecodedealer', model, httpOptions1);
  }

  getSpareInventoryLocation(model: SpareIssueModel): Observable<SpareIssueModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SpareIssueModel>(this.customersUrl + 'api/transaction/getSpareInventoryLocation', model, httpOptions1);
  }
  getreturnspare(model: SpareIssueModel): Observable<SpareIssueModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SpareIssueModel>(this.customersUrl + 'api/transaction/getreturnspare', model, httpOptions1);
  }
  getresourceloc(model: SpareIssueModel): Observable<SpareIssueModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SpareIssueModel>(this.customersUrl + 'api/transaction/getresourceloc', model, httpOptions1);
  }
  getcustomernameLike(model: User): Observable<AccountThreeSixtyModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<AccountThreeSixtyModel[]>(this.customersUrl + 'api/transaction/getcustomernameLike', model, httpOptions1);
  }
  getaccountDetails(user: User): Observable<ThreeSixtyModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ThreeSixtyModel>(this.customersUrl + 'api/transaction/getaccountDetails', user, httpOptions1);
  }

  getservicecallReassign(user: DailyCallModel): Observable<DailyCallModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DailyCallModel>(this.customersUrl + 'api/transaction/getservicecallReassign', user, httpOptions1);
  }
  SendAndsaveCashOTP(user: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/SendAndsaveCashOTP', user, httpOptions1);
  }

  SaveInstallationinvoice(user: InstallationDCRModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/SaveInstallationinvoice', user, httpOptions1);
  }
  SendAndsaveInstallaCashOTP(user: InstallationDCRModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/SendAndsaveInstallaCashOTP', user, httpOptions1);
  }

  getINSInvoiceDetails(user: User): Observable<PaymentScreenModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PaymentScreenModel>(this.customersUrl + 'api/transaction/getINSInvoiceDetails', user, httpOptions1);
  }
  getlocalityforchangeaddress(model: ProspectModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getlocalityforchangeaddress', model, httpOptions1);
  }
  getgeographydetailsforchangeaddress(model: ProspectModel): Observable<ProspectModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    }; return this.http.post<ProspectModel>(this.customersUrl + 'api/transaction/getgeographydetailsforchangeaddress', model, httpOptions1);
  }

  savechangeAddress(model: DefineOrderAcceptanceModel): Observable<SaveSuccessModel> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };

    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savechangeAddress', model, httpOptions1);

  }

  dltaddress(user: DefineOrderAcceptanceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/dltaddress', user, httpOptions1);
  }


  updategsttype(model: DefineOrderAcceptanceModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/updategsttype', model, httpOptions1);

  }
  getLocaleAndPincode(user: User): Observable<TeamModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<TeamModel>(this.customersUrl + 'api/transaction/getLocaleAndPincode', user, httpOptions1);
  }
  getAssignedtoList1(model: DailyCallModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAssignedtoList1', model, httpOptions1);
  }
  getAssignedtoListInst(model: TeamModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAssignedtoListInst', model, httpOptions1);
  }
  getAssignedtoListSales(model: TeamModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAssignedtoListSales', model, httpOptions1);
  }
  getCustomerDetailsAccsixty(model1: AccountThreeSixtyModel): Observable<AccountThreeSixtyModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<AccountThreeSixtyModel>(this.customersUrl + 'api/transaction/getCustomerDetailsAccsixty', model1, httpOptions1);
  }
  getAssignedtoListService(model: TeamModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAssignedtoListService', model, httpOptions1);
  }
  getPaymentReceiptByid(model: DefinePaymentReceivableReceiptModel): Observable<DefinePaymentReceivableReceiptModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePaymentReceivableReceiptModel>(this.customersUrl + 'api/transaction/getPaymentReceiptByid', model, httpOptions1);
  }

  getserialexist(model: DispatchOrderModel): Observable<DispatchOrderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DispatchOrderModel[]>(this.customersUrl + 'api/transaction/getserialexist', model, httpOptions1);

  }
  getAllQuotationReport(user: User): Observable<QuotationModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<QuotationModel[]>(this.customersUrl + 'api/transaction/getAllQuotationReport', user, httpOptions1);
  }
  getProductsByCategory(user: User): Observable<Products[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<Products[]>(this.customersUrl + 'api/transaction/getProductsByCategory', user, httpOptions1);
  }
  getOrdersSearchLike(user: User): Observable<DispatchOrderHomeModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DispatchOrderHomeModel[]>(this.customersUrl + 'api/transaction/getOrdersSearchLike', user, httpOptions1);
  }
  getOrdersAcceptanceLike(model: OrderHomepageModel): Observable<OrderHomepageModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<OrderHomepageModel[]>(this.customersUrl + 'api/transaction/getOrdersAcceptanceLike', model, httpOptions1);
  }
  getServiceCallLike(user: User): Observable<CallCenterDashboardModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<CallCenterDashboardModel[]>(this.customersUrl + 'api/transaction/getServiceCallLike', user, httpOptions1);
  }
  getopportunitiesSearchLike(user: User): Observable<OpportunitiesModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<OpportunitiesModel[]>(this.customersUrl + 'api/transaction/getopportunitiesSearchLike', user, httpOptions1);
  }
  getProductsByISC(user: User): Observable<Products[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<Products[]>(this.customersUrl + 'api/transaction/getProductsByISC', user, httpOptions1);
  }

  btnApplySchemeCodeforservice(model: QuotationModel): Observable<SchemeConfigurateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SchemeConfigurateModel>(this.customersUrl + 'api/transaction/btnApplySchemeCodeforservice', model, httpOptions1);
  }
  getServiceQuotationReport(user: User): Observable<CallCenterDashboardModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<CallCenterDashboardModel[]>(this.customersUrl + 'api/transaction/getServiceQuotationReport', user, httpOptions1);
  }
  getareaByregionForBudget(user: MarketBudgetGeoperTemModel): Observable<MarketBudgetGeoperTemModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<MarketBudgetGeoperTemModel>(this.customersUrl + 'api/transaction/getareaByregionForBudget', user, httpOptions1);
  }

  deletetermsandcond(user: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/deletetermsandcond', user, httpOptions1);
  }

  savefinalize(model: DefinePurchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),

      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savefinalize', model, httpOptions1);
  }

  getInventoryFromLocations(user: User): Observable<DispatchOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DispatchOrderModel>(this.customersUrl + 'api/transaction/getInventoryFromLocations', user, httpOptions1);
  }
  getInventoryToLocations(user: User): Observable<DispatchOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DispatchOrderModel>(this.customersUrl + 'api/transaction/getInventoryToLocations', user, httpOptions1);
  }
  getCustomerInvLike(model: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getCustomerInvLike', model, httpOptions1);
  }

  getInvoicelst(user: User): Observable<SalesReturnModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SalesReturnModel[]>(this.customersUrl + 'api/transaction/getInvoicelst', user, httpOptions1);
  }
  getInvoicedtl(user: User): Observable<SalesReturnModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SalesReturnModel[]>(this.customersUrl + 'api/transaction/getInvoicedtl', user, httpOptions1);
  }

  saveSalesReturn(user: SalesReturnModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/saveSalesReturn', user, httpOptions1);
  }
  getresourcenamelike(model: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getresourcenamelike', model, httpOptions1);

  }
  getOpportunityInfolst(model: ReassignModel): Observable<ReassignModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ReassignModel>(this.customersUrl + 'api/transaction/getOpportunityInfolst', model, httpOptions1);
  }
  getAssignedtoListForReassign(model: TeamModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAssignedtoListForReassign', model, httpOptions1);
  }

  btnsaveReassign(model: ReassignModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnsaveReassign', model, httpOptions1);

  }
  getTeamResource(model: ReassignModel): Observable<ReassignModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ReassignModel>(this.customersUrl + 'api/transaction/getTeamResource', model, httpOptions1);
  }

  // getCustomerOpportunity
  getCustomerOpportunity(user: User): Observable<CustomerMergeModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CustomerMergeModel>(this.customersUrl + 'api/transaction/getCustomerOpportunity', user, httpOptions1);
  }
 
  getCustomerInstallation(user: User): Observable<CustomerMergeModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CustomerMergeModel>(this.customersUrl + 'api/transaction/getCustomerInstallation', user, httpOptions1);
  }
  getfindservicecustomermearge(user: User): Observable<CustomerMergeModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CustomerMergeModel>(this.customersUrl + 'api/transaction/getfindservicecustomermearge' , user, httpOptions1);
  }
  savecustomermerge(user:CustomerMergeModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/savecustomermerge' , user, httpOptions1);
  }
  getallStateByregion(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getallStateByregion', user, httpOptions1);

  }
  getallCityByarea(user: User): Observable<SearchTargetModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchTargetModel>(this.customersUrl + 'api/transaction/getallCityByarea', user, httpOptions1);

  }
  getItemLikePO(model: User): Observable<DefinePurchaseOrderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePurchaseOrderModel[]>(this.customersUrl + 'api/transaction/getItemLikePO', model, httpOptions1);
  }

  ForeCloseDailyCall(model: DailyCallModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/ForeCloseDailyCall', model, httpOptions1);

  }

  getAssignedbydeler(model: TeamModel): Observable<DropDownModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getAssignedbydeler',model,httpOptions1);
  }

  getleadAssignedtoListbydeler(model: TeamModel): Observable<DropDownModel[]> {

    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/transaction/getleadAssignedtoListbydeler', model, httpOptions1);
    }
  btnsaveDealerDailyCall(model: DailyCallModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnsaveQuotationdealer', model, httpOptions1);

  }
  btnsaveQuotationdealer(model: DailyCallModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/transaction/btnsaveQuotationdealer', model, httpOptions1);

  }
  
}
