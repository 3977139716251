export class SalesReturnModel {
    customerid: number;
    customerName: string;
    invoceDate: Date;
    invoiceNo: string;
    invlst: SalesReturnModel[];
    invdtllst: SalesReturnModel[];
    id: number;
    amount: number;
    quantity: number;
    productid: number;
    productName: string;
    totalAmount: number;
    chkall: boolean;
    chkselectpc: boolean;
    returnedquantity: number = 0;
    uom: string;
    rate: number;
    creditNoteDate: Date = new Date();
    locationid: number;
    crdtAmount: number;
    crdtTotalAmount: number;
    userid: number;
    netAmount: number;
    custName: string;
    taxTotal: number;
    gstPer: number;
    tax: number;
    currentgstPer: number;
    gstretun: number;
    gstid:number;
    currentgstid:number;

   
}
