import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { SourceDetailModel } from './SourceDetailModel';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { User } from 'src/app/_models';

@Component({
    selector: 'app-AMCd',
    //  moduleId: module.id,
    templateUrl: 'SourceDetail.html'
})
export class SourceDetail implements OnInit {

    model = new SourceDetailModel();
    p: any;
    user = new User();
    campaigndetaillst: SourceDetailModel[] = [];
    id: any;
    pagename: string;

    constructor(private Masterservice: MasterServices, private datePipe: DatePipe, private route: ActivatedRoute, private alertService: AlertService, private notifyService: NotificationService, private router: Router, private location: Location, ) {
        this.pagename=this.router.url
    }

    ngOnInit(): void {
        localStorage.setItem("pagename",this.pagename); 
        this.route.queryParams.subscribe(params => {
            this.id = params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.campaignID = this.id;
            this.Masterservice.getsourceDetail(this.model)
                .subscribe(value => {
                    this.campaigndetaillst = value;
                })
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";


        }
    }

    btnsave() {
        if (this.model.name == null || this.model.name == "") {
            this.notifyService.showRroor("Enter Name !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.campaigndetaillst.length; i++) {
            if (this.campaigndetaillst[i].name == this.model.name) {
                this.notifyService.showRroor("Source already present", " Oops");
                return;
            }
        }
        this.Masterservice.savesourcedetail(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Campaign']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }

    cancle() {
        this.router.navigate(["/Campaign"]);
    }

    btnedit(id, name) {
        this.model.id = id;
        this.model.name = name;
    }

    btndelts(id) {
        this.model.id = id
        this.id = confirm("Do you wants to Delete !")
        this.Masterservice.deleteDetailsource(this.model)
            .subscribe(value => {
                this.notifyService.showSuccess(value.message, " Success");
                this.ngOnInit()
            });
    }
}
