import { NumberFormatStyle } from "@angular/common";

export class DefineDealerLogisticsModel{
    pincode:string;
    lstaddress:DefineDealerLogisticsModel[] = [];
    locality:string;
    city:string;
    state:string;
    country:string;
    locale:string;
    localityID:number;
    id: number;
    shortname: String;
    chkselectvalue: boolean;
    address: string;
    landmark: string;
    pincode1: string;
    maplocation: string;
    mobile: string;
    phone: string;
    email: string;
    dealerid: number;
  

  
}
export interface addressmodel {
    pincode1: string;
    detailid: number;
    userid: number;
    customerid: number;
    landmark: string;
    landline: string;
    mobile: string;
   
    address: string;
    pincode: string;
    locality: string;
    maplocation: string;
    dealerid: number;
    phone: string;
}