<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12" style="padding-top: 9px;">
                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="18%" style="text-align: left;padding-left: 10px;">Company Name</th>
                                <th width="10%" style="text-align: left;">Company Code</th>
                                <th width="40%" style="text-align: left;">Address</th>
                                <th width="13%" style="text-align: left;">TC Name</th>
                                <th width="13%" style="text-align: left;">BC Name</th>
                                <th width="6%" style="text-align: center;">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of complst  | paginate: { itemsPerPage: 10, currentPage: p }">
                                    <td style="text-align: left;padding-left: 10px;">{{lst.companyname}}</td>
                                    <td style="text-align: left">{{lst.companycode}}</td>
                                    <td style="text-align: left">{{lst.address}}</td>
                                    <td style="text-align: left">{{lst.tcname}}</td>
                                    <td style="text-align: left">{{lst.bcname}}</td>
                                    <td style="text-align: center"> <span><img src="../../../assets/images/edit.png"
                                                (click)="btnedit(lst.id)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px"
                                                style="margin-bottom: 3px;" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>