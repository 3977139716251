<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Manage Sales Product Category</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="l12 m12 s12">
                    <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 9px;padding-left: 14px;">
                        <span>Search Criteria</span>
                        <select (change)=" onSelectProduct($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center;margin-top: 26px;">
                        <input [(ngModel)]="model.txtsearch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class=" input-field col s3 l3 m3" style="margin-top: 26px">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;&nbsp;
                            <button type="button" (click)="btnnew()"
                            class="waves-effect waves-light btn">New</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12">

                        <div class="col l12 m12 s12">

                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="8%" style="text-align: left">Sr.No.</th>
                                    <th width="20%" style="text-align: left">Product Category Code</th>
                                    <th width="32%" style="text-align: left">Product Category Name</th>
                                    <th width="30%" style="text-align: left">Description</th>
                                    <th width="10%" style="text-align: left">Actions</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of proCatlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left">{{lst.srno}}</td>
                                        <td style="text-align: left">{{lst.productCategoryCode}}</td>
                                        <td style="text-align: left">{{lst.productCategoryName}}</td>
                                        <td style="text-align: left">{{lst.description}}</td>
                                        <td style="text-align: left"> <span><img src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst.id)" data-toggle="tooltip" data-placement="top"
                                                    title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                                    src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                    width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>