import { Contacts } from "../Master/Vendor Master/DefineVendorModel";
import { DropDownModel } from "../_models/DropDownModel";

export class DealersDetailModel {
    id: number;
    code: string;
    name: string;
    status: string;
    state: string;
    city: string;
    locale: string;
    country: string;
    proid:number;
    pincode: string;
    locality: string;
    email: string;
    gsttype:string;
    chkwihitins:boolean;
    chkoutsides:boolean;
    website: string;
    panNo: string;
    gstNo: string;
    egst: boolean;
;
    landline: number;
    constitutionid: number;
    constitutionname: string;
    currencyid: number;
    creditAmount: number;
    creditDays: number;
    googlemaplocation: string;
    about: string;
    paymentTerm: string;
    gstType: string;
    dealerproid:number;
    usergroup:string;
    usergroupid:number;
    paymentType:string;
    //gsttype:string;
   // chkwihitins:boolean;
    //chkoutsides:boolean;


    address: string;
   
    productcatname: string;
   
    chkgst:boolean
    productName: string;
    defaultCurrency: number;
    localityID: number;
    mrp: number;
    productid: number;
    localeid: number;
    transferprice: number;
    currencyName: string;
    productcategoryid: number;
    commission: number;
    checkselectprocat: boolean;
    geolst:DealersDetailModel[]=[];
    locmodlst:DealersDetailModel [] = [];
    modellst: ProductModel1 [] = [];
    contactlst:DefinedlrcontModel[]=[];
    localityid:number;
    countryid: number;
    regionid: number;
    region: string;
    areaid: number;
    stateid: number;
    area: string;
    cityid: number;
    checkselectgeo: boolean;
    localmodlst:GeographyModel[]=[];
    description:string;
    vendor:string;
    userId:number;
    userid:number;
    dealersid:number;
    wigstflag:string;
    outgstflag:string;
    serialnoFlag:boolean;
    chkselectlogin:boolean;
    contactlst1: Contacts[];
    selectall:boolean;
    customertype:String;
    checkselectegstn:boolean;
    locationlst: DropDownModel[] = [];
    deliverylocid:number;
localall:boolean;
locallist:[]=[];
}
export class ProductModel1 {
    id: number;
    productcatname: string;
    productName: string;
    currencyName: string;
    commission: number;

    productcategoryid: number;
    transferprice: number;
    mrp: number;
    defaultCurrency: number;
    checkselectprocat: boolean;

}
export class GeographyModel {
    id: number;
    countryid: number;
    name: string;
    localityid:number;
    locale: string;
    regionid: number;
    region: string;
    areaid: number;
    stateid: number;
    country: string;
    city: string;
    area: string;
    state: string;
   
    cityid: number;
    locality: string;
    localeid: number;
    locallist:number[] = [];
    checkselectgeo: boolean;
}
export class DefinedlrcontModel {
    id: number
    id1: number
    contactName: String;
    designation: String;
    designationID: number;
    mobileNo1: number;
    mobileNo2: number;
    email: String;
    chkselectvalue: boolean;
    desiglst: DropDownModel[];
    countrylst: DropDownModel[];
    country1ID: number;
    country2ID: number;
    chkselectlogin:boolean;
    username:string;
    password:string;
    dealerid: number;
    contactlst: DefinedlrcontModel[] = [];
    checkselectegst:boolean;
}

export class DefineDealerLogisticsModel {

    lstaddress: DefineDealerLogisticsModel[] = [];
    locality: string;
    city: string;
    state: string;
    country: string;
    locale: string;
    localityID: number;
    id: number;
    shortname: String;
    chkselectvalue: boolean;
    address: string;
    landmark: string;
    pincode: string;
    maplocation: string;
    mobile: string;
    phone: string;
    email: number;
    dealerid: number;



}