<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l2 m2 s2"></div>
        <div class="heading col l8 m8 s8">
            <span style="font-size: 25px;">Payment Receipt</span>
        </div>
        <div class="col l2 m2 s2"></div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col l4 m4 s4">
                    <input type="text" id="dynamicUserIdsSecondWay" [(ngModel)]="model.partyname" type="text"
                        class="autocomplete" name="Name" placeholder="Party Name" (keyup)="getUserIdsSecondtWay($event)"
                        data-toggle="tooltip" data-placement="top" title="Party Name" />
                    <input type="hidden" id="cust-id" />
                </div>
                <div class="input-field col l3 m3 s3">
                    <span>Customer Type:&nbsp;<span style="font-size: 15px;font-weight: bold ; margin-bottom:0px ">
                            {{model.customertypeName}}</span></span>
                </div>
                <div class="input-field col l3 m3 s3">
                    <span>GST NO:&nbsp;<span style="font-size: 15px;font-weight: bold; margin-bottom:0px "
                            *ngIf="model.gstno; else content">{{model.gstno}}</span>
                        <ng-template #content><span *ngIf="model.partyid!=null">GST Not Present</span></ng-template>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col l1">
                </div>
                <div class="input-field col l3 m2 s4" style="margin-bottom:0px">
                    <select (change)="onSelectPaymentType($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Payment Type">
                        <option selected disabled>Select payment type</option>
                        <option *ngFor="let type of paymentType" [value]="type.name"
                            [selected]="type.name == model.paymenttype"> {{type.name}} </option>
                    </select>
                </div>
                <div class="input-field col l5 m5 s8" style="margin-bottom:0px">
                    <input type="text" [(ngModel)]="model.bankname" #bankname="ngModel" id="bankname"
                        data-toggle="tooltip" data-placement="top" title="Bank Name"
                        [disabled]="model.paymenttype=='Cash'">
                    <label for="bankname">Bank Name</label>
                </div>
                <div class=" input-field col l3 m3 s6" style="margin-bottom:0px">
                    <input type="text" [(ngModel)]="model.chequeNo" #chequeNo="ngModel" id="checq" data-toggle="tooltip"
                        data-placement="top" title="Cheque/DD Number" [disabled]="model.paymenttype=='Cash'">
                    <label for="checq">Cheque/DD Number</label>
                </div>
                <div class="col l1">
                </div>
            </div>

            <div class="row">
                <div class="col l1">
                </div>
                <div class="input-field col l2 m2 s5" style="margin-bottom:0px">
                    <input type="text" placeholder="Payment Date" id="end" [(ngModel)]="model.paymentDate"
                        class="form-control" bsDatepicker (ngModelChange)="onChangePaymentDate(model.paymentDate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                        [disabled]="model.partyid==null" data-toggle="tooltip" data-placement="top"
                        title="Payment Date">
                </div>
                <div class="input-field col l2 m2 s5" style="margin-bottom:0px">
                    <input type="number" [(ngModel)]="model.netAmount" #netAmount="ngModel" id="netAmount"
                        data-toggle="tooltip" data-placement="top" title="Net Amount"
                        (ngModelChange)="changeNetAmou(model.netAmount)" [disabled]="model.partyid==null">
                    <label for="netAmount">Net Amount</label>
                </div>
                <div class="input-field col l2 m2 s5" style="margin-bottom:0px">
                    <input type="number" [(ngModel)]="model.bankcharges" #bankcharges="ngModel" id="bankcharges"
                        data-toggle="tooltip" data-placement="top" title="Bank Charges"
                        (ngModelChange)="changeBankCharg(model.bankcharges)"
                        [disabled]="model.partyid == null || model.paymenttype=='Cash' || model.paymenttype =='Cheque/DD' || model.paymenttype == 'Digital' || model.paymenttype == 'Credit Note'">
                    <label for="bankcharges" class="active">Bank Charges</label>
                </div>


                <div class="col l3 m6 s3">
                    <span style="font-size: 110%; font-weight: bold" style="margin-bottom:0px">Gross Amount
                        :&#x20b9;<span>{{model.grossAmount}}</span></span>
                </div>
                <div class="col l1">
                </div>
            </div>
            <div class="row">
                <div class="col l1">
                </div>

                <div class="input-field col l9 m4 s6" style="margin-bottom:0px">
                    <textarea type="text" class="materialize-textarea" [(ngModel)]="model.remarks" #remarks="ngModel"
                        id="remark" data-toggle="tooltip" data-placement="top" title="Remarks"></textarea>
                    <label for="remark">Remarks</label>
                </div>
                <!-- <div class="col l3 m6 s3">
                    <span style="font-size: 110%; font-weight: bold">Remaining Net Amount
                        :&#x20b9;<span>{{netam |number}}</span></span>
                </div> -->
                <div class="col l1">
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left">Sr.No.</th>
                            <th width="20%" style="text-align: left">Invoice No.</th>
                            <th width="20%" style="text-align: left">Invoice Date</th>
                            <th width="10%" style="text-align: center">Total Amount</th>
                            <th width="20%" style="text-align: center">Paid Amount</th>
                            <th width="20%" style="text-align: center">Balance</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of model.invoicelst">
                                <td style="text-align: left">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.invNo}}</td>
                                <td style="text-align: left">{{lst.invDate | date : "dd-MM-yyyy"}}</td>
                                <td style="text-align: right">&#8377;&nbsp; {{lst.grandtotal | number}}</td>
                                <td style="text-align: right">&#8377;&nbsp;{{lst.paidamount | number}}</td>
                                <td style="text-align: right">&#8377;&nbsp;{{lst.balanceAmount | number}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 0px;">
                        <button (click)="btnback($event)" type="button"
                            class="waves-ripple cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="saveData(paymentreceipt)" onclick="buttonof(id)" id="btnsave1" type="button"
                            class="waves-effect waves-light btn" [disabled]="this.action=='view'">Save</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #paymentreceipt>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div id="paymentreceiptdetails">
            <div class="modal-content registerBgShadow">

                <div class="col l12 m12 s12">
                    <div class="col-sm-12" style="text-align: center; ">
                        <span style="font-size: 20px; font-weight: bolder"> {{paymodel.companyname}}</span>
                    </div>
                    <div class="col-sm-12" style="text-align: center; ">
                        <span style="font-size: 15px; font-weight: bolder"> {{paymodel.companyaddress}}</span>
                    </div>
                    <div class="col-sm-12" style="text-align: center; ">
                        <span style="font-size: 15px; font-weight: bolder"> Payment Receipt</span>
                    </div>
                    <div class="row">
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <thead>
                                <tr>
                                    <th width="50%">
                                        Party Details
                                    </th>
                                    <th width="50%">
                                        Payment Details
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span style="font-size:17px;"><b>{{paymodel.customername}}</b></span><br>
                                    </td>
                                    <td>
                                        <span>Date:
                                            {{paymodel.paymentDate | date:"dd-MM-yyyy"}}
                                        </span><br>
                                        <span><b>Payment Voucher Number
                                                :{{paymodel.paymentno}}</b></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <tbody>
                                <tr>
                                    <td style="text-align:left;">
                                        <span style="font-size: 15px; font-weight: bolder">Payment Type:
                                            {{paymodel.paymenttype}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:left;">
                                        <span style="font-size: 15px; font-weight: bolder">Bank Name:
                                            {{paymodel.bankname}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:left;">
                                        <span style="font-size: 15px; font-weight: bolder">Cheque Number :
                                            {{paymodel.chequeNo}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:left;">
                                        <span style="font-size: 15px; font-weight: bolder">Amount:
                                            {{paymodel.chequeamount | currency:'INR'}}</span>
                                    </td>
                                </tr><br>
                                <tr>
                                    <th style="text-align:right;">
                                        <span>(Rs.{{grandtotalworld | uppercase}} ONLY)</span>
                                    </th>
                                </tr><br>
                                <tr>
                                    <td style="text-align:left;">
                                        <span
                                            style="font-size: 15px; font-weight: bolder">Remark:&nbsp;{{paymodel.remarks}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">
                                        <span style="font-size: 15px; font-weight: bolder">Authorised Signatory</span>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="input-field col l6 m6 s6" style="text-align: right">
                <button (click)="modalRef.hide()" type="button" class="waves-effect waves-light btn">Cancel</button>
            </div>
            <div class="input-field col l6 m6 s6" style="text-align: left;">
                <button (click)="openPrint()" type="button" class="waves-effect waves-light btn" id="btn"
                    name="btn">Print</button>
            </div>
        </div>
    </div>
</ng-template>