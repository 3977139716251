import { OnInit, Component, TemplateRef } from '@angular/core';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DefinePaymentReceivableReceiptModel } from './DefinePaymentReceivableReceiptModel';
import { User } from 'src/app/_models';
import { BsModalRef } from 'ngx-bootstrap';
import { DropDownModel } from 'src/app/_models/DropDownModel';
var converter = require('number-to-words');
declare var $: any;
@Component({
    selector: 'app-DefinePaymentReceivableReceipt',
    templateUrl: 'DefinePaymentReceivableReceipt.html',
})
export class DefinePaymentReceivableReceipt implements OnInit {

    constructor(private masterservice: MasterServices, private transactionservice: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router,private alertService: AlertService) {
    }

    model = new DefinePaymentReceivableReceiptModel();
    user = new User();
    netam: number;
    lastkeydown2: number = 0;
    userList2: any;
    id: number;
    modalRef: BsModalRef;
    grandtotalworld: String;
    sub: any;
     action: string;
     paymentreceipt:boolean;
    paymentType: DropDownModel[] = [
        { id: 1, "name": "Credit/Debit Card" },
        { id: 2, "name": "Cheque/DD" },
        { id: 3, "name": "Cash" },
        { id: 4, "name": "Digital" },
        { id: 5, "name": "Credit Note" },
    ];
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.bankcharges = 0;
        this.model.grossAmount = 0;

        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });

        this.route.queryParams.subscribe(params => {
            this.id = + params["id"];
            this.paymentreceipt = params["paymentreceipt"]
            this.action = params["flag"]
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            
            //this.model.productid = this.id;
            this.transactionservice.getPaymentReceiptByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.id = data.id;
                        if (data.paymentDate != null) {
                            this.model.paymentDate = new Date(data.paymentDate);
                          }
                        // this.itemlst = data.itemlst
                     },
                    error => {
                        this.alertService.error(error);
                    });
        }
        
    }

    getUserIdsSecondtWay($event) {
        let userId = (<HTMLInputElement>document.getElementById('dynamicUserIdsSecondWay')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        
        console.log('VALU ' + userId);
        var obj = this;
        if (userId.length > 3) {
            if ($event.timeStamp - this.lastkeydown2 > 200) {
                this.transactionservice.getpartyLikeForPR(this.user)
                    .subscribe(data => {
                        data.forEach((item) => this.userList2.push({ "text": item.name, "val": item.id }));
                        let map = new Map();
                        var names = "";
                        for (let i = 0; i < this.userList2.length; i++) {
                            map.set(this.userList2[i].text, this.userList2[i].val)
                            searchDataObj[this.userList2[i].text] = null;
                            if (i == 0) {
                                names = this.userList2[i].text;
                            } else {
                                names = names + "," + this.userList2[i].text;
                            }
                        }
                        let customername;
                        $(document).ready(function () {
                            $('input.typeahead').autocomplete({
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect(map.get(txt), txt);
                                },
                            });
                            $('input.autocomplete').autocomplete({
                                data: searchDataObj,
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect(map.get(txt), txt);
                                },
                            });
                        });
                    },
                        () => {
                            alert("fail");
                        });
            }
        }
    }

    onSelectPaymentType(type) {
        this.model.paymenttype = type
    }

    typeaheadOnSelect(val, val2) {
        this.user.trnid = val;
        this.model.partyid = val
        this.model.partyname = val2
      
        this.transactionservice.getAllInvoicesOfParty(this.user)
            .subscribe(
                data => {
                   
                    this.model.invoicelst = data.invoicelst
                    this.model.customertypeName = data.customertypeName;
                    this.model.gstno = data.gstno;
                   
                }
            );
    }

    changeNetAmou(netamount) {
        this.model.netAmount = netamount;
        this.changeAmount();
        this.netam = netamount;
        for (let i = 0; i < this.model.invoicelst.length; i++) {
            if (this.netam > 0) {
                if (this.model.invoicelst[i].grandtotal < this.netam) {
                    this.netam = Number(this.netam) - Number(this.model.invoicelst[i].grandtotal)
                    this.model.invoicelst[i].paidamount = this.model.invoicelst[i].grandtotal
                } else {
                    this.model.invoicelst[i].paidamount = Number(this.netam)
                    this.netam = 0;
                }
            } else if (this.netam == 0) {
                this.model.invoicelst[i].paidamount = 0;
            }
            this.model.invoicelst[i].balanceAmount = this.model.invoicelst[i].grandtotal -   this.model.invoicelst[i].paidamount
        }
        this.netam = Number(this.netam)
        this.model.balanceAmount = this.netam
    }

    changeBankCharg(bankcharge) {
        this.model.bankcharges = bankcharge;
        this.changeAmount();
    }

    onChangePaymentDate(date) {
        if (date > new Date()) {
            this.model.paymentDate = new Date();
            this.notifyService.showRroor("You cannot select future date", "Oops !");
            return;
        }
    }

    changeAmount() {
        this.model.grossAmount = 0;
        this.model.grossAmount = this.model.netAmount + this.model.bankcharges;
    }

    saveData(paymentreceipt: TemplateRef<any>) {
        if (this.model.partyid == null) {
            this.notifyService.showRroor("Please select Party Name", "Oops !");
            return;
        }
        if (this.model.paymenttype == null) {
            this.notifyService.showRroor("Please select Payment Type", "Oops !");
            return;
        }
        if (this.model.paymenttype != "Cash") {
            if (this.model.bankname == null) {
                this.notifyService.showRroor("Please select Bank Name", "Oops !");
                return;
            }
        }

        if (this.model.paymenttype == "Cheque/DD") {
            if (this.model.chequeNo == null) {
                this.notifyService.showRroor("Please enter Cheque/DD Number", "Oops !");
                return;
            }
        }
        if (this.model.paymentDate == null) {
            this.notifyService.showRroor("Please select Payment Date", "Oops !");
            return;
        }
        if (this.model.netAmount == null) {
            this.notifyService.showRroor("Please enter Net Amount", "Oops !");
            return;
        }
        this.transactionservice.savePaymentReceipt(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.id = data.id;
                        // this.getpaymentreceiptdetail();
                        // this.modalRef = this.modalService.show(paymentreceipt, {
                        //     class: 'modal-'
                        // });
                        this.router.navigate(['/Payment Receipt']);
                    }
                },
                error => {
                    alert("Something Wrong")
                });
    }

    btnback($event) {
        this.router.navigate(['/Payment Receipt']);
    }

    getpaymentreceiptdetail() {
        //     this.paymodel.paymentreceipthdrid = this.id
        //     this.paymodel.ddate = null
        //     this.paymodel.customername = null
        //     this.paymodel.grandtotal = null
        //     this.paymodel.paymentDate = null
        //     this.paymodel.paymentno = null
        //     this.paymodel.paymenttype = null
        //     this.paymodel.bankname = null
        //     this.paymodel.chequeNo = null
        //     this.paymodel.chequeamount = null
        //     this.paymodel.remarks = null
        //     this.paymodel.companyname = null
        //     this.paymodel.companyaddress = null
        //     this.paymodel.companyphone = null


        //     this.Masterservice.getpaymentreceiptdetails(this.paymodel)
        //         .subscribe(
        //             data => {
        //                 this.paymentreceiptdetaillst = data.paymentreceiptdetaillst;
        //                 this.paymodel.totalitem = data.paymentreceiptdetaillst.length.toString();
        //                 for (let i = 0; i < data.paymentreceiptdetaillst.length; i++) {
        //                     this.paymodel.ddate = data.paymentreceiptdetaillst[i].ddate;
        //                     this.paymodel.customername = data.paymentreceiptdetaillst[i].customername;
        //                     this.paymodel.grandtotal = data.paymentreceiptdetaillst[i].grandtotal;
        //                     this.paymodel.paymentDate = data.paymentreceiptdetaillst[i].paymentDate;
        //                     this.paymodel.paymentno = data.paymentreceiptdetaillst[i].paymentno;
        //                     this.paymodel.paymenttype = data.paymentreceiptdetaillst[i].paymenttype;
        //                     this.paymodel.bankname = data.paymentreceiptdetaillst[i].bankname;
        //                     this.paymodel.chequeNo = data.paymentreceiptdetaillst[i].chequeNo;
        //                     this.paymodel.chequeamount = data.paymentreceiptdetaillst[i].chequeamount;
        //                     this.paymodel.remarks = data.paymentreceiptdetaillst[i].remarks;
        //                 }
        //                 this.paymodel.companyname = data.companyname;
        //                 this.paymodel.companyaddress = data.companyaddress;
        //                 this.paymodel.companyphone = data.companyphone;

        //                 this.grandtotalworld = converter.toWords(this.paymodel.chequeamount)
        //             },
        //             error => {
        //                 this.alertService.error(error);
        //             });
    }

    openPrint() {
        //     var divToPrint = document.getElementById('paymentreceiptdetails');
        //     var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        //     WindowObject.document.writeln(divToPrint.innerHTML);
        //     WindowObject.document.close();
        //     WindowObject.focus();
        //     WindowObject.print();
        //     WindowObject.close();
    }

}