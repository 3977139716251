<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="col l1">
                    </div>
                    <div class="input-field col l7 m7 s7" style="padding-left: 4px;"> 
                        <input type="text" [(ngModel)]="model.name" class="autocomplete" id="dynamicUserIdsSecondWay1"
                            placeholder="Account Name" (keyup)="getUserIdsSecondtWay1($event)" />
                        <input type="hidden" id="acc-id" />
                    </div>
                    <!-- <div class="col l1">
                    </div> -->
                    <div class=" input-field col s3 l3 m3" style="margin-top: 10px;text-align: right;padding-right: 0px;" >
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                            (click)="btnnew()">New</button>
                    </div>
                </div>

                <div class="row">

                    <div class="col l12 m12 s12">
                        <div class="col l1">
                        </div>
                        <div class="col l10 m10 s10" style="height: 500px; overflow: auto;">

                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20" style="text-align: center">Product Category</th>
                                    <th width="20" style="text-align: center">Product Model</th>
                                    <th width="10" style="text-align: center">Status</th>
                                    <th width="10" style="text-align: center">Start date</th>
                                    <th width="10" style="text-align: center">End date</th>
                                    <th width="30" style="text-align: center">Actions</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of accwiselst">
                                        <td style="text-align: center">{{lst.name}}</td>
                                        <td style="text-align: center">{{lst.productcode}}</td>
                                        <td style="text-align: center">{{lst.serviceCallStatusName}}</td>
                                        <td style="text-align: center">
                                            {{lst.amcWarrantyStartDate | date :  "dd-MM-yyyy"}}</td>
                                        <td style="text-align: center">{{lst.amcWarrantyEndDate | date :  "dd-MM-yyyy"}}
                                        </td>

                                        <td style="text-align: center"> <span><img src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst.id)" data-toggle="tooltip" data-placement="top"
                                                    title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                                    src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                    width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="col l1">
                    </div>
                </div>

                
                <!-- <div class="row">
                    <div class="col s12 m12 l12" *ngIf="lstflag" style="color: red;text-align: center;">
                        <span>No Record Available</span>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</div>