<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field  col s5 l5 m5" style="margin-top: 24px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.code" #code="ngModel" type="text" id="allowancecode" data-toggle="tooltip"
                        data-placement="top" title="Allowance Code">
                    <label id="lable1" for="First1">Allowance Code</label>
                </div>
                <div class="input-field  col s5 l5 m5" style="margin-top: 24px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.name" #name="ngModel" type="text" id="allwaname" data-toggle="tooltip"
                        data-placement="top" title="Allowance Name">
                    <label id="lable2" for="First2">Allowance Name</label>
                </div>
                <div class="col l1"></div>
            </div>

            <div class="row">

                <div class="col l1"></div>
                <div class="input-field col s5 l5 m5" style="margin-bottom: 0px;margin-top: 0px;">
                    <span>Frequency</span>
                    <select (change)="onSelectFrequency($event.target.value)">
                        <option selected value="0">Select Frequency</option>
                        <option *ngFor="let type of frequencylst" [value]="type.name"
                            [selected]="type.name == model.frequency">
                            {{type.name}}</option>
                    </select>
                </div>

                <div class="input-field col  s5 l5 m5" style="margin-bottom: 0px;margin-top: 0px;">
                    <span>Type</span>
                    <select (change)="onSelectType($event.target.value)">
                        <option selected value="0">Select Type</option>
                        <option *ngFor="let type of typelst" [value]="type.name" [selected]="type.name == model.type">
                            {{type.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col  s5 l5 m5" style="margin-bottom: 0px;margin-top: 0px;">
                    <span>Calculate</span>
                    <select (change)="onSelectCalculate($event.target.value)">
                        <option selected value="0">Select Calculate</option>
                        <option *ngFor="let type of calculatelst" [value]="type.name"
                            [selected]="type.name===model.calculate">
                            {{type.name}}</option>
                    </select>
                </div>

                <div class="input-field col  s5 l5 m5" *ngIf="model.type=='Per Unit'" style="margin-bottom: 0px;margin-top: 0px;">
                    <span>Unit</span>
                    <select (change)="onSelectUnit($event.target.value)">
                        <option selected value="0">Select Unit</option>
                        <option *ngFor="let type of unitttlst" [value]="type.id" [selected]="type.name===model.name">
                            {{type.name}}</option>
                    </select>
                </div>

            </div>

            <div class="input-field col l11 m11 s11" style="text-align: right;padding-right: 0px;margin-top: 0px;">
                <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                    (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn">Save</button>

            </div>

        </div>
    </div>
</div>