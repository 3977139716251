import { InvoiceProducts } from './DispatchOrderModel';
import { gstelement } from 'src/app/Master/GSTForm/GSTgroupmastermodel';

export class DispatchOrderHomeModel {
    
    srno: number;
    id: number;
    oanumber: string;
    customername: string;
    customertype: string;
    status: string;
    oadate: Date;
    ordercomplete: boolean;
    invNo: string;
    invDate: Date;
    invId: number;
    grandtotal: number;
    invoicelst: DispatchOrderHomeModel[] = [];
    retail:boolean;
    quotationID:number;
    txtserch: string;
    txtselect: string;
    telephone:string;
    totalcount:number;
    chkopen:boolean;
    userid:number;
}

export class InvoiceModel {
    id: number;
    retail:boolean;
    customername: string;
    customeraddress: string;
    customergstno: string;
    customerPhone:string;
    customerID:number;
    customerInvName:string;
    customerInvPhNo:string;
    customerInvgstNo:string;
    customerInvaddress:string;
  

    companyname: string;
    companyaddress: string;
    companygstno: string;

    invNo: string;
    invDate: Date;

    pono:string;
    podate:Date;

    place:string;
    pterms:string;

    invoiceProducts: InvoiceProducts[] = [];
    taxlst:gstelement[]
    netamount: number;
    totalgst: number;
    totalcount:number;

    cgstamount: number;
    cgstpercent: number;

    sgstamount: number;
    sgstpercent: number;

    igstamount: number;
    igstpercent: number;

    discountamount:number;
    grandtotal: number;
    // nogstammount:number;
    noGstAmmount:number;
    
}