<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Teams</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="col l12 m12 s12">
                <div class="row">
                    <div class="input-field col l2 m3 s3" style="margin-top: 30px;">
                        <input [(ngModel)]="model.teamcode" #teamcode="ngModel" type="text" id="teamcode"
                            data-toggle="tooltip" data-placement="top" title="Team Code" maxlength="25" required
                            class="validate">
                        <label for="teamcode" class="active">Team Code</label>
                    </div>

                    <div class="input-field col l2 m2 s2">
                        <span>Region</span>
                        <select (change)="onSelectRegions($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Regions" data-toggle="tooltip" data-placement="top"
                            title="Regions">
                            <option selected value="0">Select Region</option>
                            <option *ngFor="let lst of regionlst" [value]="lst.id" [selected]="lst.id==model.regionid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col l2 m2 s2">
                        <span>Branch</span>
                        <select (change)="onSelectBranches($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Branches" data-toggle="tooltip" data-placement="top"
                            title="Branches">
                            <option Selected value="0">Select Branch</option>
                            <option *ngFor="let lst of branchlst" [value]="lst.id" [selected]="lst.id==model.branchid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>

                    <div class="col l5 m4 s4" style="margin-top: 25px;">
                        <div class="input-field col l2 m1 s1" style="margin-top: 15px;">
                            <input [(ngModel)]="model.chkSale" #chkSaleproduct="ngModel"(change)=onsale() type="checkbox"
                                style="text-align: 5px ">&nbsp;
                                <br>Sales
                        </div>
                        <div class="input-field col l2 m1 s1" style="margin-top: 15px;">
                            <input [(ngModel)]="model.chkService" #chkServiceproduct="ngModel" (change)=onchkService() type="checkbox"
                                style="text-align: 5px">
                                <br>Service
                        </div>
                        <div class="input-field col l2 m1 s1" style="margin-top: 15px;margin: right 2px;" >
                            <input [(ngModel)]="model.chkInstallation" #chkInstallproduct="ngModel" (change)=oninstall() type="checkbox"
                                style="text-align: 5px" >&nbsp;&nbsp;
                                <br>Installation
                        </div>
                        <div class="input-field col l6 m2 s2" style="margin-top: 15px; ">
                           <input [(ngModel)]="model.chkOrderAccept" #chkOrderAccept="ngModel" (change)="onOrderAccept()" type="checkbox"
                                style="text-align: 20px">
                               <br>Order <br>Acceptance
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="model.chkInstallation && model.invIntegration">
                    <div class="input-field col s4" style="margin-top: 0px;margin-bottom: 0px;">
                        <span>Issue Location</span>
                        <select (change)="onSelectInventoryLocationFrom($event.target.value )" data-toggle="tooltip"
                            data-placement="top" title="Inventory Location">
                            <option selected value="0">Select From Inventory Location</option>
                            <option *ngFor="let lst of locationlst" [value]="lst.id"
                                [selected]="lst.id== model.locationid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s4" style="margin-top: 0px;margin-bottom: 0px;">
                        <span>Supply Location</span>
                        <select (change)="onSelectInventoryLocationTo($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Inventory Location">
                            <option selected value="0">Select To Inventory Location</option>
                            <option *ngFor="let lst of tolocationlst" [value]="lst.id"
                                [selected]="lst.id== model.tolocationid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s4" style="margin-top: 0px;margin-bottom: 0px;">
                        <span>Store Keeper : <b>{{model1.name}}</b></span>
                    </div>
                   
                </div>
               
                <!-- <div class="col s1" style="margin-top: 0px;margin-bottom: 0px;">
                    <span>Store Keeper : {{model.name}}</span>
                </div> -->
                <div class="row">
                    <div class="col l6 m6 s6" style="padding-left:0px;">
                        <div class="col l12 m12 s12" style="background-color: gainsboro">
                            <div class="col l1 m1 s1" style="text-align: center;padding: 2%">
                                <input type="checkbox" [(ngModel)]="model.allCitycheckselect"
                                #allcheckselect="ngModel" (change)="onSelectCity1()"></div>
                            <div class="col l6 m6 s6" style="text-align: center;padding: 2%">
                                <span style="font-size: 15px;color: #3389c8; font-weight: bold">Areas</span>
                            </div>
                            <!-- <div class="input-field col l6 m6 s6" style="text-align: center;padding: 0; display: none">
                                <input type="text" id="autoc1" type="text" class="autocomplete" placeholder="Area Names"
                                    (keyup)="getAutoc1($event)" data-toggle="tooltip" data-placement="top"
                                    title="Area Names" />
                                <input type="hidden" id="" />
                            </div> -->
                        </div>
                        <div class="col l12 m12 s12" style="overflow-y: auto; height: 300px;padding: 3%;">
                            
                            <div class="row" *ngFor="let lst of arealst">
                                <div class="col l1 m1 s1">
                                    <input type="checkbox" [(ngModel)]="lst.chkselectarea" #chkselectarea="ngModel">
                                </div>
                                <div class="col l5 m5 s5" (click)="onclickarea(lst.areaid)">
                                    <span>{{lst.areaname}}</span>
                                </div>
                                <div class="input-field col l6 m6 s6" style="margin: 0px;">
                                    <select (change)="onSelectCity($event.target.value,lst.citylst.cityid,lst.areaid)">
                                        <option selected value="0">Select City</option>
                                        <option *ngFor="let type of lst.citylst" [value]="type.cityid"
                                            [selected]="type.cityid == model.cityid">
                                            {{type.cityname}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col l6 m6 s6" style="padding-right: 0px;">

                        <div class="col l12 m12 s12" style="background-color: gainsboro;padding-left: 3px;">
                            <div class="col l1 m1 s1" style="text-align: center;padding: 2%"
                                *ngIf="model.cityid != null"><input type="checkbox" [(ngModel)]="model.allcheckselect"
                                    #allcheckselect="ngModel" (change)="onselectall($event)"></div>

                            <div class="col l6 m6 s6" style="text-align: center;padding: 2%">
                                <span style="font-size: 15px;color: #3389c8; font-weight: bold">Locale</span>
                            </div>

                            <div class="input-field col l5 m5 s5" style="text-align: center;padding: 2%;margin: 0px;">
                                <span style="font-size: 15px;color: #3389c8; font-weight: bold">Pincode</span>
                            </div>
                        </div>
                        <div class="col l12 m12 s12" style="overflow-y: auto; height: 300px;padding: 3%;">
                            <div class="row" *ngFor="let lst of localpincodelst;let i = index">
                                <div class="col l1 m1 s1">
                                    <input type="checkbox" [(ngModel)]="lst.chkselectlocale" #chkselectlocale="ngModel"
                                        (change)="onselectlocalandpincode($event,lst.localeid,lst.pincode,i)">
                                </div>
                                <div class="col l6 m6 s6">
                                    <span>{{lst.localename}}</span>
                                </div>
                                <div class="col l5 m5 s5">
                                    <span>{{lst.pincode}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col l6 m6 s6" style="padding-left: 0px;">
                        <div class="col l12 m12 s12" style="padding: 2%; background-color: gainsboro">
                            <div class="col l12 m12 s12" style="text-align: center;">
                                <span style="font-size: 15px;color: rgb(0, 136, 199); font-weight: bold">Customer
                                    Types</span>
                            </div>
                        </div>
                        <div class="col l12 m12 s12" style="overflow-y: auto; height: 100px;padding: 3%;">
                            <div *ngFor="let lst of customertyps">
                                <div class="col l6 m6 s6">
                                    <div class="col l3 m3 s3">
                                        <input type="checkbox" [(ngModel)]="lst.chkselecttype" #chkselecttype="ngModel">
                                    </div>
                                    <div class="col l9 m9 s9">
                                        <span>{{lst.type}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col l6 m6 s6" style="padding-right: 0px;">
                        <div class="col l12 m12 s12" style="background-color: gainsboro;padding: 2%;">
                            <div class="col l12 m12 s12" style="text-align: center;">
                                <span style="font-size: 15px;color: #3389c8 ;font-weight: bold;">Product
                                    Categories</span>
                            </div>
                            <!-- <div class="input-field col l8 m8 s8" style="text-align: center;padding: 0; display: none">
                                <input type="text" id="autoc2" type="text" class="autocomplete"
                                    placeholder="Product categories" (keyup)="getAutoc2($event)" data-toggle="tooltip"
                                    data-placement="top" title="Product categories" />
                                <input type="hidden" id="" />
                            </div> -->
                        </div>
                        <div class="col l12 m12 s12"
                            style="overflow-y: auto; height: 150px;padding-left: 3%;padding-right: 3%;padding-bottom: 3%;margin-top: 3%;">
                            <div class="row" *ngFor="let lst of procatlst">
                                <div class="col l6 m6 s6">
                                    <div class="col l3 m3 s3">
                                        <input type="checkbox" [(ngModel)]="lst.chkselectpc" #chkselectpc="ngModel">
                                    </div>
                                    <div class="col l9 m9 s9">
                                        <span>{{lst.pcname}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9"></div>
                    <div class="col l3 m3 s3">
                        <button type="button" (click)="btnShowResources()" class="waves-ripple waves-light btn">Show
                            Resources</button>
                    </div>
                </div>
                <div class="col l12 m12 s12" style="overflow-y: scroll;height: 350px;padding-left: 0px;">
                    <div class="row" style="margin-top: 2%;">
                        <table>
                            <thead style=" background-color: gainsboro;">
                                <th width="10%" style="text-align: center">Select</th>
                                <th width="30%" style="text-align: left">Resource</th>
                                <th width="10%" style="text-align: center">Assign To</th>
                                <th width="20%" style="text-align: center">Hierarchy</th>
                                <th width="20%" style="text-align: center">Call Escalation(Days)</th>
                                <!-- <th width="10%" style="text-align: center">Approval</th> -->
                                <th width="10%" style="text-align: center">Discount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of resoureslst">
                                    <td style="text-align: center"><input type="checkbox" [(ngModel)]="lst.chkselectres"
                                            #chkselectres="ngModel">
                                    </td>
                                    <td style="text-align: left">{{lst.name}}</td>
                                    <td style="text-align: center"><input type="checkbox" [(ngModel)]="lst.chkassignto"
                                            #chkassignto="ngModel" *ngIf="lst.chkselectres">
                                    </td>
                                    <td style="text-align: center"><input type="number" style="text-align: end"
                                            [(ngModel)]="lst.hierarchy" #hierarchy="ngModel" placeholder="Hierarchy"
                                            data-toggle="tooltip" data-placement="top" title="Hierarchy" min="0"
                                            id="hierarchy">
                                    </td>
                                    <td style="text-align: center"><input type="number" style="text-align: end"
                                            [(ngModel)]="lst.callEscalationDays" #callEscalationDays="ngModel"
                                            data-toggle="tooltip" data-placement="top"
                                            placeholder="Call Escalation(Days)" title="Call Escalation(Days)" min="0">
                                    </td>
                                    <!-- <td style="text-align: center"><input type="checkbox" [(ngModel)]="lst.approval"
                                            #approval="ngModel"> 
                                    </td>-->
                                    <td style="text-align: center"><input type="number" style="text-align: end"
                                            [(ngModel)]="lst.discount" #discount="ngModel" data-toggle="tooltip"
                                            data-placement="top" title="Discount" placeholder="Discount" min="0"
                                            max="99" oninput="validity.valid||(value='');"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsaveTeam()" class="waves-ripple waves-light btn"
                            [disabled]="showBtn == true">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
