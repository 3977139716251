<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Production Booking</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%;padding-top: 5px;">
            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span style="font-size: 16px;">Production Plan ID <br>
                        <b>{{model.planID}}</b></span>
                </div>
                <div class="input-field col l2 m2 s2" style="margin: 0px">
                    <span style="font-size: 16px;">Plan Date<br>
                        <b> {{model.plandate |date : "dd-MM-yyyy"}}</b>
                    </span>

                </div>
                <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span style="font-size: 16px;">Inventory Locations<br>
                        <b> {{model.fromlocation}}</b></span>
                </div>
            </div>

            <div class="col s12 l12 m12">
                <div class="tabtab">
                    <button class="tablinks" id="defaulttab" onclick="openCity(event, 'SemiFinished')">Semi
                        Finished</button>
                    <button class="tablinks"  id="fpd" onclick="openCity(event, 'FinishedProducts')" [disabled]="!model.bookingdone">Finished Products</button>
                    <button class="tablinks" id="st" onclick="openCity(event, 'StockTransfer')" [disabled]="!model.plancomplete">
                        Stock Transfer</button>
                </div>
            </div>
            <div id="SemiFinished" class="tabcontent col l12 m12 s12 " style="margin-bottom: 9px;">
                <div class="row">
                    <div class="col s12 m12 l12 registerBgShadow"
                        style="padding-left: 9px;padding-right: 9px;padding-top: 5px;">
                        <div class="col l12 m12 s12"
                            style="background: gainsboro; padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;">
                            <div class="col l4 m4 s4" style="text-align:center;padding-left: 22px;">
                                <span>Conversion Template</span>
                            </div>
                            <div class="col l3 m3 s3" style="text-align:center;">
                                <span>Input Item</span>
                            </div>

                            <div class="col l2 m2 s2" style="text-align:center;">
                                <span>UOM</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:center;">
                                <span>Requisitioned</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:center;">
                                <span style="color: red;">Used</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:center;">
                                <span>Balance</span>
                            </div>
                        </div>
                        <mat-accordion style="padding: 0px;">
                            <mat-expansion-panel hideToggle
                                *ngFor="let lst of model.lstitemconversion | paginate: { itemsPerPage: 10, currentPage: q };let i = index "
                                style="padding: 1px;">
                                <mat-expansion-panel-header style="padding-right: 9px;">
                                    <div class="col l12 m12 s12"
                                        style="margin-top:20px;color: black;font-weight: bold;font-weight: bold; margin-bottom: 5px;">
                                        <div class="row">
                                            <div class=" col l4 m4 s4" style="text-align:center;">
                                                <span>{{lst.formulaname}}</span>
                                            </div>
                                            <div class=" col l3 m3 s3" style="text-align:center;">
                                                <span>{{lst.itemname}}</span>
                                            </div>

                                            <div class=" col l2 m2 s2" style="text-align:center;">
                                                <span>{{lst.uom}}</span>
                                            </div>
                                            <div class=" col l1 m1 s1" style="text-align:center;">
                                                <span>{{lst.quantity | number:'1.2-2'}}</span>
                                            </div>
                                            <div class=" col l1 m1 s1" style="text-align:center;margin-top: -15px;" *ngIf="!model.bookingdone">
                                                <input type="text" [(ngModel)]="lst.usedquantity"  style="text-align: end;" 
                                                    #usedquantity="ngModel" class="" id="usedquantity"
                                                    data-toggle="tooltip" data-placement="top" title="Used Quantity"
                                                    (ngModelChange)="countbalence(i)">
                                                  
                                            </div>
                                            <div class=" col l1 m1 s1" style="text-align:center;"  *ngIf="model.bookingdone"> 
                                                <span >{{lst.usedquantity | number:'1.2-2'}}</span></div>
                                            <div class="col s1 m1 l1" style="text-align:center;">
                                                <span>{{lst.balance | number:'1.2-2'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                                <div *ngIf="lst.lstoutputmeteriallst.length>0" class="registerBgShadow col l12 m12 s12"
                                    style="text-align: center; margin-top: 10px;">

                                    <div class="row">
                                        <div class="col l1 m1 s1"></div>
                                        <div class="col l4 m4 s4" style="text-align:center;color: rgb(0, 136, 199);">
                                            <span>Output Item</span>
                                        </div>
                                        <div class="col l2 m2 s2" style="text-align:center;color: rgb(0, 136, 199);">
                                            <span>Quantity / UOM</span>
                                        </div>
                                        <div class="col l4 m4 s4" style="text-align:center;color: rgb(0, 136, 199);">
                                            <span>Balance / UOM</span>
                                        </div>
                                        <div class="col l1 m1 s1"></div>
                                    </div>
                                    <div class="row" *ngFor="let sublst of lst.lstoutputmeteriallst">
                                        <div class="col l1 m1 s1"></div>
                                        <div class="col l4 m4 s4" style="text-align:center">
                                            <span>{{sublst.omitemname}}</span>
                                        </div>
                                        <div class="col s2" style="text-align:center">
                                            <span>{{sublst.omusedquantity | number:'1.2-2'}}/{{sublst.omuom}}</span>
                                        </div>
                                        <div class="col s4" style="text-align:center">
                                            <span>{{sublst.ombalance | number:'1.2-2'}}/{{sublst.omuom}}</span>
                                        </div>
                                        <div class="col l1 m1 s1"></div>
                                    </div>
                                </div>
                                <div *ngIf="lst.lstoutputmeteriallst.length==0" class="col l11 m11 s11 registerBgShadow"
                                    style="margin-left:50px;text-align: center;">
                                    <div class="col l12 m12 s12" style="color: red">
                                        <samp> List Not Found </samp>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="q= $event"></pagination-controls>
                            </span>
                        </mat-accordion>
                    </div>
                </div>
                <div class="row">
                <div class=" col s12 m12 l12 registerBgShadow">
                    <div class="col l12 m12 s12"   style="text-align: start;padding: 0px;">
                        <span style="font-size: 16px;">Miscellaneous
                            Production & Scrappage</span>
                    </div>
                    <div class="row"  *ngIf="!model.bookingdone">
                        <div class="input-field col l4 m4 s4">
                            <span style="font-size: 13px;">Input Item</span>
                            <select (change)="onSelectitem($event.target.value)">
                                <option selected value="0">Select Input Item</option>
                                <option *ngFor="let type of inputitemlst" [value]="type.id">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field  col l1 m1 s1" style="padding-top: 5px;">
                            <span style="font-size: 13px;">Balance<br>
                                <b>{{balance | number : '1.2-2'}}</b> </span>
                        </div>
                        <div class="input-field col l1 m1 s1" style="padding-top: 9px;">
                            <input type="text" [(ngModel)]="quantity" #name="ngModel" class="" id="inquan"
                                data-toggle="tooltip" data-placement="top" title="Quantity" style="text-align: end;"  (ngModelChange)="quantitychange()">
                            <label for="inquan" class="active" style="font-size: 13px;">Quantity </label>
                        </div>
                    </div>
                    <div class="row"  *ngIf="!model.bookingdone">
                        <div class="col l12 m12 s12" style="text-align: start;">
                            <span style="font-size: 16px;">Output Item</span>
                        </div>
                        <div class="input-field col l3 m3 s3">
                            <span>Item Category</span>
                            <select (change)="onSelectitemCat($event.target.value)">
                                <option selected value="0">Select Item Category</option>
                                <option *ngFor="let type of itmCatlst" [value]="type.id"
                                    [selected]="type.id == conversionmodel.itemcategoryid">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col l3 m3 s3"  *ngIf="!model.bookingdone">
                            <span>Sub Category</span>
                            <select (change)="onSelectSubCat($event.target.value)">
                                <option selected value="0">Select Sub Category</option>
                                <option *ngFor="let type of subCatlst" [value]="type.id"
                                    [selected]="type.id == conversionmodel.itemsubcategoryid">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col l3 m3 s3" style="padding-top: 14px;">
                            <input type="text" class="autocomplete" [(ngModel)]="conversionmodel.itemname" id="productname"
                                data-toggle="tooltip" data-placement="top" title="Item Name"
                                (keyup)="getItemLike()" />
                            <input type="hidden" id="acc-id" />
                            <label for="item" class="active">Search Item</label>
                        </div>
                        <div class="input-field col l2 m2 s2" style="padding-top: 14px;">
                            <input [(ngModel)]="conversionmodel.quantity" onkeypress="return /[0-9]/i.test(event.key)" id="qua"
                                type="text" title="Quantity" maxlength="10" style="text-align: end;" >
                            <label for="qua" class="active">Quantity</label>
                        </div>
                        <div class="input-field col l1 m1 s1">
                            <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                                (click)="btnadd()">Add</button>&nbsp;&nbsp;
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l12 m12 s12" style="margin-top: 25px; padding-left: 18px;padding-right: 18px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="10" style="text-align: center">Item Code</th>
                                    <th width="10" style="text-align: center">Item name</th>
                                    <th width="10" style="text-align: center">Quantity</th>
                                    <th width="10" style="text-align: center">UOM</th>
                                    <th width="10" style="text-align: center" *ngIf="!model.bookingdone">Action</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of lstoutputmeteriallst | paginate: { itemsPerPage: 10, currentPage: r };let i=index;">
                                        <td style="text-align: center">{{lst.omitemcode}}</td>
                                        <td style="text-align: center">{{lst.omitemname}}</td>
                                        <td style="text-align: center">{{lst.omusedquantity | number:'1.2-2'}}</td>
                                        <td style="text-align: center">{{lst.omuom}}</td>
                                        <td style="text-align: center" *ngIf="!model.bookingdone"> <span><img src="../../../assets/images/delete.png"
                                              data-toggle="tooltip" (click)="deleleitem(i)"
                                                    data-placement="top" title="delete" width="20px" /></span>&nbsp;&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="r = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>



                </div>
            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                    (click)="cancle()">Cancle</button>
                     <button type="button" (click)="btnsaveProductionBooking()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;" > Next >> Finished Product</button>
                       
                </div>
            </div>
            </div>
            <div id="FinishedProducts" class="tabcontent col l12 m12 s12 registerBgShadow" style="margin-bottom: 9px;">
                <div class=row>
                    <div class="col l12 m12 s12" style="margin-top: 25px; padding-left: 18px;padding-right: 18px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="10%" style="text-align: center">Item Code</th>
                                <th width="20%" style="text-align: center">Item Name</th>
                                <th width="5%" style="text-align: center">UOM</th>
                                <th width="10%" style="text-align: center">Production Plan</th>

                                <th width="5%" style="text-align: center">Production Quantity</th>
    
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of model.lstitem | paginate: { itemsPerPage: 10, currentPage: s };let i=index;">
                                    <td style="text-align: center">{{lst.itemcode}}</td>
                                    <td style="text-align: center">{{lst.itemname}}</td>
                                    <td style="text-align: center">{{lst.uom}}</td>
                                    <td style="text-align: center">{{lst.productionquantity | number:'1.2-2'}}
                                    </td>
                                    <td style="text-align: center">
                                        <input [(ngModel)]="lst.actualproduction"   *ngIf="!model.plancomplete" style="text-align: end;" onkeypress="return /[0-9]/i.test(event.key)" id="quaty"
                                        type="text" title="Actual Production" maxlength="10" (ngModelChange)="actucalproductionchange(i)" >
                                        <span  *ngIf="model.plancomplete"   >{{lst.actualproduction | number:'1.2-2'}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="s = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l11 m11 s11" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                        (click)="cancle()">Cancle</button>
                         <button type="button" (click)="btnsaveFinishproduct()" class="waves-ripple waves-light btn"
                            style="margin-left: 5px;"  > Next >> Stock Transfer</button>
                    </div>
                </div>
                
            </div>

            <div id="StockTransfer" class="tabcontent col l12 m12 s12 registerBgShadow" style="margin-bottom: 9px;">
            <div class="row">
                <div class="input-field col l4 m4 s4">
                    <span style="font-size: 16px;">Transfer To Inventory Location</span>
                    <select (change)="onSelectInventoryLocationTo($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Inventory Location">
                        <option selected value="0">Select To Inventory Location</option>
                        <option *ngFor="let lst of locationlst" [value]="lst.id"
                            [selected]="lst.id== model.tolocationid">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class=row>
                <div class="col l12 m12 s12" style="margin-top: 25px; padding-left: 18px;padding-right: 18px;">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="5%" style="text-align: center"></th>
                            <th width="10%" style="text-align: center">Item Code</th>
                            <th width="20%" style="text-align: center">Item Name</th>
                            <th width="5%" style="text-align: center">UOM</th>
                            <th width="10%" style="text-align: center">Actual Production</th>
                            <th width="10%" style="text-align: center">Transfer Quantity</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of model.lstitem | paginate: { itemsPerPage: 10, currentPage: s };let i=index;">
                                <td style="text-align: center">
                                    <input [(ngModel)]="lst.checkselect"  #checkselect="ngModel"
                                    type="checkbox"  name="Select"
                                    style="text-align: 20px; ">&nbsp;
                                </td>
                                <td style="text-align: center">{{lst.itemcode}}</td>
                                <td style="text-align: center">{{lst.itemname}}</td>
                                <td style="text-align: center">{{lst.uom}}</td>
                                <td style="text-align: center">{{lst.actualproduction}}
                                </td>
                                <td style="text-align: center" style="padding: 0px;">
                                    <input [(ngModel)]="lst.transferquantity"  style="text-align: end;" onkeypress="return /[0-9]/i.test(event.key)" id="quaty"
                                    type="text" title="Transfer" maxlength="10"  (ngModelChange)="transferquantitychange(i)"  >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="s = $event"></pagination-controls>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                    (click)="cancle()">Cancle</button>
                     <button type="button" (click)="btnsaveStocktransfer()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;"  >Finish</button>
                </div>
            </div>

            </div>

        </div>
    </div>
</div>