<div *ngIf="isMobile == true">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>
    <div class="section no-pad-bot OprntCol" id="Div1" style="padding-top: 7px;">
        <h1 class="header center" style="margin-bottom: 9px;">Order Acceptance</h1>
        <div class="container" style="width:94%;">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab">
                        <b><label class="w-100 left smallInputNew">Customer
                                Name:&nbsp;{{model.customername}}</label></b>
                        <div class="w-80 left smallInputNew">
                            Shipping Address:&nbsp;{{model.address}}
                        </div>
                        <div class="w-20 right">
                            <img src="../../../assets/images/search-filter.jpg" width="25px" data-toggle="tooltip"
                                data-placement="top">
                        </div>

                    </div>
                </div>
            </div>
            <div class="row center" *ngIf="!model.dealerflag">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab">
                        <label class="w-100 fllwUp">
                            <!-- <input type="checkbox" id="test5" /> -->
                            <!-- (click)="onchkFollowUp($event)"  -->
                            <input [(ngModel)]="model.chkconsignee" #chkconsignee="ngModel" type="checkbox"
                                id="chkconsignee">&nbsp;
                            <button type="button" class="waves-effect waves-light btn"
                                [disabled]="this.action=='view'">Consignee
                            </button>
                        </label>
                        <label class="w-100 dis-block">Consignee Name:&nbsp;{{model.consigneename}}</label>
                        <label class="w-100 dis-block">Address:&nbsp;{{model.consigneeaddress}}</label>
                        <label class="w-100 dis-block">GST Type:&nbsp;{{model.consigneegsttype}}</label>
                        <label class="w-100 dis-block">GSTIN Number:&nbsp;{{model.consigneegstno}}</label>
                    </div>
                </div>
            </div>
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab">
                        <b><label class="w-100 left smallInputNew">Payment</label></b>
                        <div class="srchBy p-0">
                            <div class="input-field col s12" style="margin-bottom:0px;">
                                <select (change)="onSelectSrch($event.target.value)">
                                    <option *ngFor="let type of paymenttermslst" [disabled]="this.action=='view'"
                                        [value]="type.name" [selected]="type.name==model.paymentType">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="w-50 left smallInputNew">
                            <input [(ngModel)]="model.instacheckselect" #instacheckselect="ngModel" type="checkbox"
                                id="instacheckselect">&nbsp;Installation
                        </div>
                        <div class="w-50 right">
                            <input [(ngModel)]="model.sercheckselect" #sercheckselect="ngModel" type="checkbox"
                                id="sercheckselect">&nbsp;Service
                        </div>


                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab" *ngIf="!model.dealerflag">
                        <label class="w-100 dis-block"><b>Quotation Number:</b>&nbsp;{{model.quotationCode}}</label>
                        <label class="w-100 dis-block"><b>Quotation Date:</b>&nbsp;{{model.startDate |
                            date:"dd-MM-yyyy"}}</label>
                    </div>
                    <div class="introTab" *ngIf="model.dealerflag">
                        <label class="w-100 dis-block"><b>PO Number:</b>&nbsp;{{model.ponumber}}</label>
                        <label class="w-100 dis-block"><b>PO Date:</b>&nbsp;{{model.podate | date:"dd-MM-yyyy"}}</label>
                    </div>
                    <div class="introTab">
                        <button type="button" class="btn btn blue" style="margin-left: 5px;"
                            [disabled]="this.action=='view'">History</button>
                        <!-- (click)="btnhistory(VersionHistory)" -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab">
                        <label class="w-100 fllwUp">
                            <!-- <input type="checkbox" id="test5" /> -->
                            <!-- (click)="onchkFollowUp($event)"  -->
                            <input [(ngModel)]="model.gsttype" #gsttype="ngModel" type="checkbox"
                                id="gsttype">&nbsp;&nbsp;GST Type
                        </label>

                        <div class="w-50 left smallInputNew">
                            <input name="group1" type="radio" [(ngModel)]="model.chkwihitins" id="chkwihitins"
                                #chkwihitins="ngModel" [checked]="model.chkwihitins" (click)="onchkwihitins($event)" />
                            <span style="font-size: 104%">Within State</span>
                        </div>
                        <div class="w-50 right">
                            <input name="group1" type="radio" [checked]="model.chkoutsides"
                                (click)="onchkoutsides($event)" />
                            <span style="font-size: 104%">Outside State</span>
                        </div>
                        <!-- <mat-radio-button value="1" color="primary">
                            Primary Theme radio button
                          </mat-radio-button><br><br>
                           
                          <mat-radio-button value="2" color="warn">
                            Warn Theme Radio button
                          </mat-radio-button><br><br>
                           
                          <mat-radio-button value="3" color="accent">
                            Accent Theme Radio button
                          </mat-radio-button> -->
                        <div class="row">
                            <div class="input-field col s12">
                                <!-- <input id="first_name" type="text" class="validate"> -->
                                <input [(ngModel)]="model.gstno" #gstno="ngModel" type="text" id="gstno"
                                    title="GSTIN Number">
                                <label for="first_name">GSTIN Number</label>
                            </div>
                        </div>
                        <label class="w-100 fllwUp">
                            <!-- <input type="checkbox" id="test5" /> -->
                            <!-- (click)="onchkFollowUp($event)"  -->
                            <input [(ngModel)]="model.chkpan" #chkpan="ngModel" type="checkbox"
                                id="chkpan">&nbsp;&nbsp;PAN
                        </label>
                        <div class="row">
                            <div class="input-field col s12">
                                <!-- <input id="first_name" type="text" class="validate"> -->
                                <input [(ngModel)]="model.panno" #panno="ngModel" type="text" id="panno"
                                    title="GSTIN Number">
                                <label for="first_name">Pan Number</label>
                            </div>
                        </div>
                        <div class="introTab">
                            <button type="button" class="btn btn blue" [disabled]="this.action=='view'"
                                (click)="btnupdategsttype()">Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12 m4 innerCnt" *ngFor="let lst of model.modellst">
                    <div class="introTab">
                        <label class="w-50"> <input type="checkbox" [(ngModel)]="model.allcheckselect"
                                #allcheckselect="ngModel" (change)="onselectall($event)"></label>

                        <label class="w-50 font-weight-bold left smallInputNew">Product</label>
                        <label class="w-50 right">{{lst.prouctname}}</label>

                        <label class="w-50  font-weight-bold left smallInputNew">UOM</label>
                        <label class="w-50 right">{{lst.unitName}}</label>

                        <label class="w-50  font-weight-bold left smallInputNew">Quotation Qty</label>
                        <label class="w-50 right">{{lst.quotationQuantity | number:'1.2-2'}}</label>

                        <label class="w-50  font-weight-bold left smallInputNew">Order Qty</label>
                        <label class="w-50 "><input [(ngModel)]="lst.quantity" #orderquantity="ngModel" type="number"
                                min="0" [disabled]="this.action=='view'" oninput="validity.valid||(value='');"
                                id="orderquantity" data-toggle="tooltip" data-placement="top"
                                placeholder="Order quantity" (ngModelChange)="onChangeOfqantity()"
                                style="text-align: right;" [max]="lst.testqty"></label>


                        <label class="w-50  font-weight-bold left smallInputNew">Price</label>
                        <label class="w-50 right" *ngIf="lst.ratewithdiscount==null">{{lst.price |
                            number:'1.2-2'}}</label>
                        <label class="w-50 right" *ngIf="lst.ratewithdiscount!=null">{{lst.ratewithdiscount |
                            number:'1.2-2'}}</label>

                        <label class="w-50 font-weight-bold left smallInputNew">GST %</label>
                        <label class="w-50 right">{{lst.gstrate}}</label>

                        <label class="w-50 font-weight-bold left smallInputNew">GST Amount</label>
                        <label class="w-50 right">{{lst.gstamount | number:'1.2-2' }}</label>

                        <label class="w-50 font-weight-bold left smallInputNew">Value</label>
                        <label class="w-50 right">{{lst.totalAmount | number:'1.2-2'}}</label>

                        <label class="w-50 font-weight-bold left smallInputNew">Net Value:&nbsp;&nbsp;{{pricevalue
                            |number:'1.2-2'}}</label>
                        <label class="w-50 font-weight-bold right">Total GST:&nbsp;&nbsp;{{totalgstvalue |
                            number:'1.2-2'}}</label>
                        <label class="w-100 font-weight-bold left smallInputNew">Grand Total:&nbsp;&nbsp;{{alltotal |
                            number:'1.2-2'}}</label>
                        <div class="w-100">
                            <label class="font-weight-bold widthR-40 valignTxt">Amount In Words: </label>
                            <label class="width-55">&nbsp;<span>
                                    {{alltotalword | uppercase}}</span>&nbsp;ONLY</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab">
                        <label class="w-50 font-weight-bold left smallInputNew">Credit
                            Period:&nbsp;&nbsp;{{model.creditdays}}</label>
                        <label class="w-50 font-weight-bold right" style="color: red;" *ngIf="model.limit">Available
                            Credit Balance:&#8377;&nbsp;{{model.bcreditlimit |
                            number:'1.2-2'}}</label>
                        <label class="w-50 font-weight-bold right" *ngIf="!model.limit">Available
                            Credit Balance:<span style="color: green;">&#8377;&nbsp;{{model.bcreditlimit |
                                number:'1.2-2'}}</span></label>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="model.dealerflag">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab">
                        <label class="w-50 font-weight-bold left smallInputNew">Last Invoice
                            Due:&nbsp;&nbsp;{{model.creditdue |
                            date:"dd-MM-yyyy"}}</label>
                        <label class="w-50 font-weight-bold right" style="color: red;" *ngIf="model.afterbal">Available
                            Credit Balance After This Order:&#8377;&nbsp;{{model.afterordercredit |
                            number:'1.2-2'}}</label>
                        <label class="w-50 font-weight-bold right" style="color: green;"
                            *ngIf="!model.afterbal">Available Credit Balance After This
                            Order:&#8377;&nbsp;{{model.afterordercredit |
                            number:'1.2-2'}}</label>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="this.termmodlst.length !=0">
                <div class="col s12 m4 innerCnt" *ngFor="let lst of termmodlst;let i = index">
                    <div class="introTab">
                        <b><label class="w-100 left smallInputNew">Terms & Conditions</label></b>
                        <label class="w-50 font-weight-bold left smallInputNew">Heading</label>
                        <label class="w-50 right">{{lst.termsheding}}</label>
                        <label class="w-50 font-weight-bold left smallInputNew">Description</label>
                        <label class="w-50 right">{{lst.termscondtion}}</label>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="this.action !='view'">
                <div class="col s12 m4 innerCnt">
                    <div class="introTab">
                        <label class="w-100 fllwUp">
                            <!-- <input type="checkbox" id="test5" /> -->
                            <!-- (click)="onchkFollowUp($event)"  -->
                            <input [(ngModel)]="model.orderchecksele" #orderchecksele="ngModel" type="checkbox"
                                id="chkpan">&nbsp;&nbsp; Order Approved For Despatch
                        </label>
                        <label class="w-50 font-weight-bold left smallInputNew">OA Date:&nbsp;&nbsp;{{oadate |
                            date:"dd-MM-yyyy"}}</label>
                        <div class="row">
                            <div class="input-field col s12">
                                <!-- <input id="first_name" type="text" class="validate"> -->
                                <input [(ngModel)]="model.remark" #remark="ngModel" type="text" id="remark"
                                    title="Remark">
                                <label for="lable6">Remark</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="s12 right">
                <button type="button" class="btn grayButton" (click)=" cancle()">Cancel</button>&nbsp;
                <button type="button" (click)="btnsave()" class="btn btn blue"
                    [disabled]="this.action=='view'">Save</button>
            </div>
        </div>
    </div>

</div>

<div *ngIf="isMobile == false">
    <app-header></app-header>
    <div class="container" style="width: 80%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Order Acceptance</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="row">
                    <div class="col l4 m6 s6">
                        <div class="row" style="padding-left: 5px;">
                            <div class="row">
                                <!-- <div class="col s1"></div> -->
                                <div class="col s11" style="margin-top: 15px;">
                                    <span style="font-weight:bold">Customer Name:&nbsp;</span>
                                    <label id="lable1" for="first1"><b>{{model.customername}}</b></label>
                                </div>
                            </div>
                            <div class="row">
                                <!-- <div class="col s1"></div> -->
                                <div class="col s11" *ngIf="model.modellst.length >0">
                                    <span style="font-size: 14px;">Shipping Address
                                    </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button type="button" (click)="openshippingmodel(model.accountid,changemodel)"
                                        class="waves-effect waves-light btn" style="margin-right: 5px;left:10%;"
                                        [disabled]="this.action=='view'">Change
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <!-- <div class="col s1 m1 l1"></div> -->
                                <div class="col s11 m11 l11">
                                    <label id="lable2" for="first2">{{model.address}}</label>
                                </div>
                            </div>
                            <div class="col l1"></div>
                            <div class="row">
                                <div class="row" style="margin-top: 15px;">
                                    <div class="input-field col l12 m2 l2">
                                        <div class="col l1"></div>
                                        <input [(ngModel)]="model.gsttype" id="gsttype" #gsttype="ngModel"
                                            type="checkbox" style="text-align: 20px;">&nbsp;&nbsp;GST Type<br>

                                    </div>

                                    <div class="col l12 m2 s2">
                                        <div class="col l1"></div>
                                        <div class="col l5 m1 s1">
                                            <label>
                                                <input name="group1" type="radio" [(ngModel)]="model.chkwihitins"
                                                    id="chkwihitins" #chkwihitins="ngModel"
                                                    [checked]="model.chkwihitins" (click)="onchkwihitins($event)" />
                                                <span style="font-size: 104%">Within State</span>
                                            </label>&nbsp;&nbsp;
                                        </div>
                                        <div class="col l5 m2 s2">
                                            <label>
                                                <input name="group1" type="radio" [checked]="model.chkoutsides"
                                                    (click)="onchkoutsides($event)" />
                                                <span style="font-size: 104%">Outside State</span>
                                            </label>
                                        </div>
                                        <!-- <label>
                                        <input name="group1" type="radio" [checked]="model.chkwihitins"
                                            [disabled]="model.chkgst==false" (click)="onchkwihitins($event)"
                                            data-toggle="tooltip" />
                                        <span style="font-size: 104%">Within State</span>
                                    </label>&nbsp;&nbsp;
                                    <label>
                                        <input name="group1" type="radio" [checked]="model.chkoutsides"
                                            [disabled]="model.chkgst==false" (click)="onchkoutsides($event)"
                                            data-toggle="tooltip" />
                                        <span style="font-size: 104%">Outside State</span>
                                    </label> -->
                                    </div>
                                    <div class="col l1"></div>
                                    <div class="input-field col l10 m3 s3">
                                        <div class="col l1"></div>
                                        <input id="gstinno" type="text" data-toggle="tooltip" data-placement="top"
                                            title="GSTIN Number" maxlength="50" [(ngModel)]="model.gstno"
                                            #gstno="ngModel" id="gstno" [disabled]="model.chkgst==false" required>
                                        <label class="active" for="gstinno">GSTIN Number</label>
                                    </div>

                                    <div class="col l12 m2 l2" style="margin:0px;">
                                        <div class="col l1"></div>
                                        <input type="checkbox" style="text-align: 20px;" [(ngModel)]="model.chkpan"
                                            #chkpan="ngModel">&nbsp;&nbsp;PAN<br>
                                    </div>
                                    <div class="col l1"></div>
                                    <div class="input-field col l10 m3 l3">
                                        <div class="col l1"></div>
                                        <input id="panno" type="text" data-toggle="tooltip" data-placement="top"
                                            title="PAN NUMBER" maxlength="50" [(ngModel)]="model.panno" #panno="ngModel"
                                            id="panno" [disabled]="model.chkpan==false" required>
                                        <label class="active" for="panno">Pan Number</label>
                                    </div>
                                    <button type="button" class="waves-effect waves-light btn"
                                        style="margin-right: 5px;left:10%;" [disabled]="this.action=='view'"
                                        (click)="btnupdategsttype()">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col l3 m6 s6" *ngIf="!model.dealerflag">
                        <div class="col l1"></div>
                        <div class="input-field col l12 m2 l2">
                            <input type="checkbox" style="text-align: 20px;" [(ngModel)]="model.chkconsignee"
                                id="chkconsignee" #chkconsignee="ngModel">&nbsp;&nbsp;
                            <button type="button" (click)="btnConsigneeModal(ConsigneeModal)"
                                class="waves-effect waves-light btn" [disabled]="this.action=='view'">Consignee
                            </button><br>
                        </div>
                        <div class="input-field col l9 m2 l2">
                            <div class="row">

                                <div class=" col l12 m5 s5">
                                    <span style="font-weight:bold">Consignee
                                        Name:</span>&nbsp;&nbsp;{{model.consigneename}}<br>
                                    <label id="lable3" for="first3"></label>
                                </div>
                                <div class="input-field col l12 m5 s5">
                                    <span
                                        style="font-weight:bold">Address:</span>&nbsp;&nbsp;{{model.consigneeaddress}}<br>
                                    <label id="lable3" for="first3"></label>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col l12 m2 s2">
                                    <span style="font-weight:bold">GST
                                        Type:</span>&nbsp;&nbsp;{{model.consigneegsttype}}<br>
                                    <label id="lable3" for="first3"></label>
                                </div>

                                <div class="input-field col l12 m5 s5">
                                    <span style="font-weight:bold">GSTIN
                                        Number:</span>&nbsp;&nbsp;{{model.consigneegstno}}<br>
                                    <label id="lable3" for="first3"></label>
                                </div>
                                <div class="col l1"></div>
                            </div>

                        </div>
                    </div>
                    <div class="col l2 m6 s6">
                        <div class="input-field col l12 m3 s3" style="margin: 0px; margin-top: 15px;">
                            <span style="font-weight:bold">Payment</span>
                            <select (change)="onSelectPayment($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Payment Terms">
                                <option selected value="0">Select One</option>
                                <option *ngFor="let lst of paymenttermslst" [disabled]="this.action=='view'"
                                    [value]="lst.name" [selected]="lst.name==model.paymentType">

                                    {{lst.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col l12 m2 s2" *ngIf="this.action!='view'">
                            <input [(ngModel)]="model.creditdays" #creditdays="ngModel" type="number" class=""
                                id="creditdays" data-toggle="tooltip" data-placement="top" title="Credit Days"
                                style="text-align: right;">
                                <!-- [disabled] = "!model.dealerflag" -->
                            <label for="creditdays" class="active">Credit Days</label>
                        </div>
                        <div class="input-field col l12 m2 l2">
                            <input [(ngModel)]="model.instacheckselect" #instacheckselect="ngModel" type="checkbox"
                                style="text-align: 20px; ">
                            Installation<br>
                            <input [(ngModel)]="model.sercheckselect" #sercheckselect="ngModel" type="checkbox"
                                style="text-align: 20px; ">
                            Service
                        </div>
                    </div>
                    <div class="col l3 m6 s6">
                        <div class="row">
                            <div class="row" style="margin-top: 21px;" *ngIf="!model.dealerflag">
                                <div class="col s7 m7 l7">
                                    <span style="font-weight:bold">Quotation Number</span><br>
                                    <label id="lable3" for="first3">{{model.quotationCode}}</label>
                                </div>
                                <div class="col s5 m5 l5">
                                    <span style="font-weight:bold">Quotation Date</span><br>
                                    <label id="lable4" for="first4">{{model.startDate | date:"dd-MM-yyyy"}}</label>
                                </div>
                            </div>

                            <div class="row" *ngIf="model.orderAcceptanceByCompany" style="margin-top: 15px;">
                                <div class="input-field col s7 m7 l7">
                                    <input [(ngModel)]="model.ponumber" #ponumber="ngModel" type="text" class=""
                                        id="ponumber" data-toggle="tooltip" data-placement="top" title="PO Number"
                                        style="text-align: left;">
                                    <label class="active" for="ponumber">PO Number</label>
                                </div>
                                <div class="input-field col s5 m5 l5" style="padding-right:15px;">
                                    <input type="text" [(ngModel)]="model.podate" id="podate" class="form-control"
                                        bsDatepicker data-toggle="tooltip" data-placement="top" title="PO Date"
                                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',  containerClass: 'theme-dark-blue' ,adaptivePosition: true}">
                                    <label class="active" for="podate">PO Date</label>
                                </div>


                                <div class="col s7 m7 l7" *ngIf="model.dealerflag">
                                    <span style="font-weight:bold">PO Number </span><br>
                                    <label for="first3">{{model.ponumber}}</label>
                                </div>
                                <div class="col s5 m5 l5" *ngIf="model.dealerflag">
                                    <span style="font-weight:bold">PO Date</span><br>
                                    <label for="first4">{{model.podate | date:"dd-MM-yyyy"}}</label>
                                </div>
                            </div>
                        </div>
                        <div class=" input-field col l12 m2 s2" style="text-align: end;padding-right:0px">
                            <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                                (click)="btnhistory(VersionHistory)" [disabled]="this.action=='view'">History</button>
                        </div>
                    </div>
                </div>

                <hr>
                <div class="row col s12" style="padding-left:0px;padding-right:0px">

                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="margin-top:2px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="5%" style="text-align: center;"><input type="checkbox" class="filled-in"
                                        [(ngModel)]="model.allcheckselect" (change)="onselectall($event)"
                                        #allcheckselect="ngModel"></th>
                                <th width="25%" style="text-align: left">Product</th>
                                <th width="10%" style="text-align: right">UOM</th>
                                <th width="10%" style="text-align: right">Quotation Qty</th>
                                <th width="8%" style="text-align: right">Order Qty</th>
                                <th width="10%" style="text-align: right">Price</th>
                                <th width="10%" style="text-align: right">GST %</th>
                                <th width="10%" style="text-align: right">GST Amount</th>
                                <th width="15%" style="text-align: right;padding-right: 10px;">Value</th>

                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of model.modellst">
                                    <td style="text-align: center;"><input type="checkbox"
                                            [(ngModel)]="lst.procheckselect" #procheckselect="ngModel"
                                            (click)="onSelectProduct($event, lst.quotationProID)">
                                    </td>
                                    <td style="text-align: left">{{lst.prouctname}}</td>
                                    <td style="text-align: right">{{lst.unitName}}</td>
                                    <td style="text-align: right">{{lst.quotationQuantity | number:'1.2-2' }}</td>
                                    <td style="text-align: right; margin: 10px;"><input [(ngModel)]="lst.quantity"
                                            #orderquantity="ngModel" type="number" min="0"
                                            [disabled]="this.action=='view'" oninput="validity.valid||(value='');"
                                            id="orderquantity" data-toggle="tooltip" data-placement="top"
                                            placeholder="Order quantity" (ngModelChange)="onChangeOfqantity()"
                                            style="text-align: right;" [max]="lst.testqty">
                                    </td>
                                    <td style="text-align: right">{{lst.price |
                                        number:'1.2-2' }}</td>
                                    <td style="text-align: right">{{lst.gstrate}}</td>
                                    <td style="text-align: right">{{lst.gstamount | number:'1.2-2' }}</td>
                                    <td style="text-align: right;padding-right: 10px;">{{lst.totalAmount |
                                        number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="row">
                    <div class="col l1"> </div>
                    <div class="col l11 m11 s11" style="text-align: right;">
                        <span style="font-size: 15px; font-weight: bold">Net Order Value:&nbsp;<span>{{pricevalue |
                                number:'1.2-2'}}&nbsp;&nbsp;&nbsp;</span>Total GST:&nbsp;<span>{{totalgstvalue |
                                number:'1.2-2'}}&nbsp;&nbsp;&nbsp;</span>Grand Total:&nbsp;<span>{{alltotal |
                                number:'1.2-2'}}&nbsp;&nbsp;</span></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l1"> </div>
                    <div class="col l11 m11 s11" style="text-align: right;">
                        <span style="font-size: 15px;">Amount in Words:&nbsp;<span>{{alltotalword | uppercase}}
                                ONLY</span></span>
                    </div>
                </div>
                <hr>
                <div class="row">

                    <div class="col l6 m6 s6" style="text-align: left;">
                        <span style="font-size: 17px;font-style: normal;">Credit Period :&nbsp;<span
                                style="text-align: left;">{{model.creditdays
                                }}&nbsp;&nbsp;&nbsp;</span></span>
                    </div>
                    <!-- <div class="col l4 m4 s4" style="text-align: left;">
                    <span style="font-size: 17px;font-style: normal;">Balance Credit Limit :&nbsp;
                        <span style="text-align: left;">{{model.bcreditlimit |
                            number:'1.2-2'}}&nbsp;&nbsp;&nbsp;</span></span>
                </div> -->
                    <div class="col l6 m6 s6" style="text-align: right;" *ngIf="model.limit">
                        <span style="font-size: 17px;font-style: normal;">Available Credit Balance :&nbsp;<span
                                style="text-align: right;color: red;">&#8377;&nbsp;{{model.bcreditlimit |
                                number:'1.2-2'}}&nbsp;&nbsp;&nbsp;</span></span>
                    </div>

                    <div class="col l6 m6 s6" style="text-align: right;" *ngIf="!model.limit">
                        <span style="font-size: 17px;font-style: normal;">Available Credit Balance :&nbsp;<span
                                style="text-align: right;color: green;">&#8377;&nbsp;{{model.bcreditlimit |
                                number:'1.2-2'}}&nbsp;&nbsp;&nbsp;</span></span>
                        <!-- avialablecredit -->
                    </div>

                </div>
                <div class="row" *ngIf="model.dealerflag">
                    <div class="col l6 m6 s6" style="text-align: left;">
                        <span style="font-size: 17px;font-style: normal;">Last Invoice Due :&nbsp;<span
                                style="text-align: left;">{{model.creditdue |
                                date:"dd-MM-yyyy"}}&nbsp;&nbsp;&nbsp;</span></span>
                    </div>
                    <div class="col l6 m6 s6" style="text-align: right;" *ngIf="!model.afterbal">
                        <span style="font-size: 17px;font-style: normal;">Available Credit Balance After This Order
                            :&nbsp;<span style="text-align: right;color: green;">&#8377;&nbsp;{{model.afterordercredit |
                                number:'1.2-2'}}&nbsp;&nbsp;&nbsp;</span></span>
                    </div>

                    <div class="col l6 m6 s6" style="text-align: right;" *ngIf="model.afterbal">
                        <span style="font-size: 17px;font-style: normal;">Available Credit Balance After This Order
                            :&nbsp;<span style="text-align: right;color: red;">&#8377;&nbsp;{{model.afterordercredit |
                                number:'1.2-2'}}&nbsp;&nbsp;&nbsp;</span></span>
                    </div>
                </div>


                <div class="row">
                    <div class="col l12 m12 s12" style="height: 400px; overflow: auto;padding: 0px"
                        style="margin-top: 25px;" *ngIf="this.termmodlst.length !=0">
                        <span style="font-weight: bold; font-size: 15px">Terms & Conditions</span>
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="40%" style="text-align: left">Heading</th>
                                <th width="60%" style="text-align: left">Description</th>
                                <!-- <th width="10%" style="text-align: center">Action</th> -->
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of termmodlst;let i = index">
                                    <td style="text-align: left">{{lst.termsheding}}</td>
                                    <td style="text-align: left">{{lst.termscondtion}}</td>
                                    <!-- <td style="text-align: center"> <span><img src="../../../assets/images/edit.png"
                                                (click)="btnopenmodel(i,lst.quotaTermsconid,termmodel)"
                                                data-toggle="tooltip" data-placement="top" title="Edit"
                                                width="20px" /></span>&nbsp;&nbsp; -->
                                    <!-- </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row col s12" style="padding-left:0px;padding-right:0px;" *ngIf="this.action !='view'">
                    <div class="input-field col l4" style="margin-top: 30px;font-size:15px;padding-left:5px;">
                        <input [(ngModel)]="model.orderchecksele" #orderchecksele="ngModel" type="checkbox"
                            style="text-align: 20px; ">
                        Order Approved For Despatch
                    </div>

                    <!-- <div class=" input-field col s4 l4 m4">
                        <span>Order Acceptance #</span>
                        <input [(ngModel)]="model.quotationName" #quotationName="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" type="text" id="first5"
                            data-toggle="tooltip" data-placement="top" title="Order Acceptance"  required>
                        <label id="lable5" for="first5"> </label>

                    </div> -->
                    <!-- <div class="col s1"></div> -->
                    <div class="col s2" style="margin-top: 30px;">
                        <div> <span>OA Date</span></div>
                        <label id="lable7" for="first7">{{oadate | date:"dd-MM-yyyy"}}</label>
                    </div>
                    <div class=" input-field col s6 l6 m6" style="margin-top: 30px;padding-right:5px;">
                        <input [(ngModel)]="model.remark" #remark="ngModel" type="text" id="first6"
                            data-toggle="tooltip" data-placement="top" title="Remark" required>
                        <label id="lable6" for="first6">Remark </label>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12" style="padding-right:5px;">
                        <div class="input-field  col s2" style="text-align: left;padding-left:0px;">
                            <button type="button" class="waves-ripple waves-light btn" [disabled]="this.action=='view'"
                                style="margin-right: 5px;" (click)=" print()">Print</button>
                        </div>
                        <div class="input-field col s10" style="text-align: end;padding-right:0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;
                            <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                                style="margin-right: 5px;" [disabled]="this.action=='view'">Save</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-template #changemodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Shipping Address</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
                <div class="row">
                    <div class="row col l12 m12 s12" *ngFor="let lst of model.addresslst;let i= index;">
                        <div class="row">
                            <div class=" col l9 m9 s9" style="text-align: left;">
                                <label><input name="grup1" type="checkbox" [(ngModel)]="model.checkselect" #checkselect="ngModel"
                                        (click)="onselectaddress(model.accountid,$event,i)">
                                <b>Address:-</b>&nbsp;&nbsp;&nbsp;<span> {{lst.address}}</span></label>
                            </div>
                            <div class=" col l3 m3 s3" style="text-align: right;">
                                <span><img src="../../../assets/images/CreateDocument.png" (click)="btnsaveaddress(i,$event)"
                                        data-toggle="tooltip" data-placement="top" title="Add"
                                        width="20px" /></span>&nbsp;&nbsp;
                                <span><img src="../../../assets/images/edit.png" (click)="btnedit(i)"
                                        data-toggle="tooltip" data-placement="top" title="Edit"
                                        width="20px" /></span>&nbsp;&nbsp;<span><img
                                        src="../../../assets/images/delete.png" (click)="btndelts(i)"
                                        data-toggle="tooltip" data-placement="top" title="Delete" width="20px" /></span>
                                <!-- <input [(ngModel)]="model.chkselectvalue" #chkselectvalue="ngModel" type="checkbox" (click)="btndefault(i)"
                                style="text-align: 5px">&nbsp;&nbsp;&nbsp;Default -->
                            </div>
                        </div>
                        <!-- <div class="row">
                        <div class="col s1">
                        </div>
                        <div class=" col l11 m11 s11" style="text-align: left;">
                            <div class=" col l3 m3 s3">
                                <span><b>Locality:-</b></span>&nbsp;&nbsp;&nbsp;<span> {{lst.localityName}}</span>
                            </div>

                            <div class=" col l3 m3 s3" style="text-align: left;">
                                <span><b>Pincode:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.pincode}}</span>
                            </div>
                            <div class=" col l2 m2 s2" style="text-align: left;">
                                <span><b>City:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.city}}</span>
                            </div>
                            <div class=" col l3 m3 s3" style="text-align: left;">
                                <span><b>State:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.state}}</span>
                            </div>
                        </div>
                    </div> -->
                        <hr>
                    </div>


                </div>
            </div>
            <!-- <div class="row col s12" style="text-align: center;padding-top: 16px;">
            <button type="button" (click)="btnsaveaddress()"
                class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;&nbsp;

        </div> -->

            <div class="row col s12">
                <div class="input-field col l3">
                    <input [(ngModel)]="model.chkNewAdderss" #chkNewAdderss="ngModel" type="checkbox"
                        style="text-align:left;">&nbsp;&nbsp;&nbsp;&nbsp;<b>New Address</b>
                </div>
                <div class="input-field col l9"></div>
            </div>
            <div class="row" *ngIf="model.chkNewAdderss">
                <div class="">
                    <div class="col l12 m12 s12">
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <div class="input-field  col s6 l6 m6" style=" margin-top: 15px;">
                                <input [(ngModel)]="model.shortname" #shortname="ngModel" type="text" id="shortname"
                                    data-toggle="tooltip" data-placement="top" title="Short Name">
                                <label for="shortname" class="active">Short Name</label>
                            </div>
                            <div class="col l3"></div>
                            <!-- <div class="input-field col l3 m3 s3" style="margin-top: 20px;">
                            <input [(ngModel)]="model.chkselectvalue" #chkselectvalue="ngModel" type="checkbox"
                                style="text-align: 5px">&nbsp;&nbsp;&nbsp;&nbsp; Default
                        </div> -->
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 s12 m12">
                        <div class=" input-field col s12" style=" margin-top:0px;">
                            <input [(ngModel)]="model.newaddress" #newaddress="ngModel" type="text" id="address10"
                                title="Address" maxlength="100">
                            <label id="address10" for="address" class="active"> Address</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 s12 m12">
                        <div class="input-field  col s6 l6 m6" style=" margin-top: 0px;margin-bottom:0px;">
                            <input [(ngModel)]="model.landmark" #landmark="ngModel" type="text" id="landmark"
                                data-toggle="tooltip" data-placement="top" title="Landmark">
                            <label for="landmark" class="active">Landmark </label>
                        </div>
                        <!-- <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom:0px;">
                        <input type="number" class="autocomplete" [(ngModel)]="modell.pincode" id="pincode"
                            placeholder="Pincode" (keyup)="getPincodeLikes($event)" />
                        <input type="hidden" id="acc-id" />
                    </div> -->
                        <div class="ng-autocomplete pinRowgrp input-field col s3">
                            <ng-autocomplete [data]="pincodelist1" type="text" [(ngModel)]="model.newPincode"
                                placeholder="Pincode" [searchKeyword]="keyword" [minQueryLength]="3" id="pincode"
                                (inputChanged)='onChangeSearch1($event)' (selected)='selectEvent1($event)' [itemTemplate]="itemTemplate"
                                [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s3" style="margin-top: 0px;">
                        <span>Locality</span>
                        <select (change)=" onSelectlocality1($event.target.value)">
                            <!-- <option selected value="0">Select Locality</option> -->
                            <option *ngFor="let type of lstlocality" [value]="type.id"
                                [selected]="type.id == model.localityid">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;">

                    <!-- <div class=" input-field col s3" style=" margin-top: 0px;">
                    <span>Locale:-</span>&nbsp;<span><b>{{model.locale}}</b></span>
                </div> -->
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>City:-</span>&nbsp;<span><b>{{model.city}}</b></span>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>State:-</span>&nbsp;<span><b>{{model.state}}</b></span>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <span>Country:-</span>&nbsp;<span><b>{{model.country}}</b></span>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <div class=" input-field col s6" style=" margin-top: 4px;">
                            <input [(ngModel)]="model.maplocation" #maplocation="ngModel" type="text" id="maplocation"
                                title="Google Map Location" data-toggle="tooltip" data-placement="top">
                            <label id="lable8" for="maplocation">Google Map Location</label>
                        </div>
                        <div class=" input-field col s6" style=" margin-top: 4px;">
                            <input [(ngModel)]="model.mobile" #mobile="ngModel" pattern="[6789][0-9]{9}" ngModel
                                maxlength="10" min="0" oninput="validity.valid||(value='');" id="mobile1" type="number"
                                data-toggle="tooltip" data-placement="top" title="Mobile" required>
                            <label id="mobile1" for="first8">Mobile No.</label>
                            <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched) " class="alert alert-danger"
                                style="color: red">
                                <div *ngIf="mobile.errors.required">
                                    Mobile Number Required
                                </div>
                                <div [hidden]="!mobile.errors?.pattern">
                                    Invalid Mobile Number
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 s12 m12">
                        <div class=" input-field col s6" style=" margin-top: 0px;">
                            <input [(ngModel)]="model.phone" #phone="ngModel" min="0"
                                oninput="validity.valid||(value='');" pattern="[6789][0-9]{9}" type="number" id="phone"
                                title="phone" maxlength="15">
                            <label id="phone" for="phone">Landline</label>
                        </div>
                        <div class=" input-field col s6" style=" margin-top: 0px;">
                            <input [(ngModel)]="model.email" #email="ngModel" id="Email " type="text"
                                data-toggle="tooltip" data-placement="top" title="email" ngModel maxlength="35"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                            <label id="lable12" for="first1">Email </label>
                            <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                                style="color: red">
                                <div *ngIf="email.errors.required">
                                    Email Required
                                </div>
                                <div [hidden]="!tcemail.errors?.pattern">
                                    Invalid Email-Id
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l2 m2 s2" style="margin-top: 9px;">
                        <span><b>GST Type</b></span>
                        <label>
                            <input name="group1" type="radio" [checked]="model.newchkwihitins"
                                (click)="onNewchkwihitins($event)" />
                            <span style="font-size: 104%">Within State</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 30px;">
                        <label>
                            <input name="group1" type="radio" [checked]="model.newchkoutsides"
                                (click)="onNewchkoutsides($event)" />
                            <span style="font-size: 104%">Outside State</span>
                        </label>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.newgstno" #newgstno="ngModel" id="newgstno" type="text"
                            data-toggle="tooltip" data-placement="top" title="GSTIN Number" ngModel maxlength="50"
                            required>
                        <label class="active" for="gstinno">GSTIN Number</label>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;">
                        <button type="button" class="waves-ripple cancelbutton btn"
                            (click)="modalRef.hide()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsaveNewAddress()"
                            class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>

        </div>

    </ng-template>



    <ng-template #termmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Terms And Conditions</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
                <div class="row">
                    <input [(ngModel)]="model.termscondtion" #termscondtion="ngModel" type="text" id="first10"
                        data-toggle="tooltip" data-placement="top" title="Termscondtion">
                    <label id="lable10" for="first10">Termscondtion </label>
                </div>
            </div>
            <div class="row col s12" style="text-align: center;padding-top: 16px;">
                <button type="button" (click)="btnsaveterm()"
                    class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;&nbsp;
            </div>
        </div>

    </ng-template>


    <ng-template #VersionHistory>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Quotation Version History</h4>
            <div class=" col l12 m12 s12">
                <div class="row" *ngFor="let lst of qversionlst">
                    <div class="col l12 m12 s12" style="    padding: 10px;">
                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l8 m8 s8" style="padding: 5px;">
                                <span style="font-size: 13px; font-weight: bold">Quotation
                                    Code:&nbsp;<span>{{lst.quotationno}}</span></span>
                            </div>
                            <div class="right-align col l4 m4 s4" style="margin-top: 2px">
                                <span><img src="../../../assets/images/printer.png" width="25px" /></span>
                            </div>
                        </div>
                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Created
                                    By:&nbsp;<span>{{lst.createdBy}}</span></span>
                            </div>
                            <div class="left-align col l4 m4 s4">
                                <span style="font-size: 13px; font-weight: bold">Created
                                    Date:&nbsp;<span>{{lst.createdDate | date:"MMM d, y, h:mm:ss a"}}</span></span>
                            </div>
                            <div class="right-align col l2 m2 s2">
                            </div>
                        </div>

                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Customer
                                    Name:&nbsp;<span>{{lst.customerName}}</span></span>
                            </div>
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Customer
                                    Address:&nbsp;<span>{{lst.customerAddress}}</span></span>
                            </div>
                        </div>

                        <div class="row" style="border: 1px solid black;">
                            <div class="left-align col l6 m6 s6">
                                <span style="font-size: 13px; font-weight: bold">Enquiry
                                    Name:&nbsp;<span>{{lst.enquiryName}}</span></span>
                            </div>
                            <div class="left-align col l6 m6 s6">
                                <span
                                    style="font-size: 13px; font-weight: bold">Remarks:&nbsp;<span>{{lst.remarks}}</span></span>
                            </div>
                        </div>
                    </div>
                    <table>
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Product Name</th>
                            <th width="14%" style="text-align: end">List Price</th>
                            <th width="14%" style="text-align: end">Quntity</th>
                            <th width="14%" style="text-align: end">Discount</th>
                            <th width="14%" style="text-align: end">Offered Price</th>
                            <th width="14%" style="text-align: end;padding-right: 10px;">Total Amount</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pro of lst.historyproductlst">
                                <td style="text-align: left;padding-left: 10px;">{{pro.name}}</td>
                                <td style="text-align: end">{{pro.listprice | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.quantity | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.discount | number:'1.2-2'}}</td>
                                <td style="text-align: end">{{pro.offeredPrice | number:'1.2-2'}}</td>
                                <td style="text-align: end;padding-right: 10px;">{{pro.totalproamount | number:'1.2-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table style="margin-bottom: 2%;">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let terms of lst.historyTermslst">
                                <td style="text-align: left;padding-left: 10px;">{{terms.heading}}</td>
                                <td style="text-align: left">{{terms.descrption}}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="row">
                    <button (click)="modalRef2.hide()" type="button" class="waves-effect waves-light btn">Close</button>
                </div>
            </div>
        </div>

    </ng-template>


    <ng-template #ConsigneeModal>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef5.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Consignee Address</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
                <div class="row">
                    <div class="col l1"></div>
                    <div class="input-field col l5 m5 s5">
                        <div class="ng-autocomplete pinRowgrp input-field col l12 m5 s5">
                            <ng-autocomplete [data]="resourcelist" type="text" placeholder="Consignee Name"
                                [searchKeyword]="keyword" [minQueryLength]="3" id="resAllo"
                                (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l1"></div>
                    <div class="input-field col l5 m5 s5">
                        <input type="text" [(ngModel)]="model.consigneeaddress" #consigneeaddress="ngModel"
                            id="consigneeaddress" data-toggle="tooltip" data-placement="top" title="Address">
                        <label class="active" for="caddress">Address</label>
                    </div>
                    <div class=" input-field col m2 s2 l2">
                        <input type="text" class="autocomplete" [(ngModel)]="model.pincode" id="pincode1"
                            (keyup)="getPincodeLike($event)" data-toggle="tooltip" data-placement="top"
                            title="PIN Code" />
                        <input type="hidden" id="acc-id" />
                        <label class="active" for="first1">PIN Code</label>
                    </div>
                    <div class=" input-field col s3 m3 l3" style="margin: 0px;">
                        <span>Locality</span>
                        <select (change)="onSelectLocality($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Locality" data-toggle="tooltip" data-placement="top"
                            title="Locality">
                            <option selected value="0">Select Locality</option>
                            <option *ngFor="let lst of lstlocality" [value]="lst.id"
                                [selected]="lst.id==model.localityID">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col l1"></div>
                </div>

                <div class="row">
                    <div class="col l1"></div>
                    <div class="col l2 m2 s2" style="margin-top: 9px;">

                        <span>
                            <input [(ngModel)]="model.consigneegsttype" id="consigneegsttype"
                                #consigneegsttype="ngModel" type="checkbox" style="text-align: 20px;"><b>GST
                                Type</b><br>

                        </span>
                        <label>
                            <input name="group1" type="radio" [checked]="model.consigneechkwihitins"
                                (click)="onconsigneechkwihitins($event)" />
                            <span style="font-size: 104%">Within State</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 30px;">
                        <label>
                            <input name="group1" type="radio" [checked]="model.consigneechkoutsides"
                                (click)="onconsigneechkoutsides($event)" />
                            <span style="font-size: 104%">Outside State</span>
                        </label>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.consigneegstno" #consigneegstno="ngModel" id="consigneegstno"
                            type="text" data-toggle="tooltip" data-placement="top" title="GSTIN Number" ngModel
                            maxlength="50" required>
                        <label class="active" for="gstinno">GSTIN Number</label>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class="row">
                    <div class="row col s12" style="text-align: right;padding-top: 16px;">
                        <button type="button" (click)="modalRef5.hide()"
                            class="waves-effect cancelbutton btn">Close</button>
                        &nbsp;&nbsp; <button type="button" (click)="btnsaveConsigneenew()"
                            class="waves-effect waves-light btn">OK</button>

                    </div>
                </div>

            </div>
        </div>
    </ng-template>
</div>