import { DailyCallModel } from "../DailyCall/DailyCallModel";

export class OpportunitiesModel {
    id: number;
    enquiryName: string;
    customerId:number;
    customerName: string;
    stage: string;
    owner: string;
    dCRDate:string;
    assignedTo: string;
    assignedByName:string;
    locallity: string;
    city: string;
    mobileno:string;
    value: number;
    status:string;
    txtserch: string;
    txtselect: string;
    openchk:boolean;
    wonchk:boolean;
    lostchk:boolean;
    abandonedchk:boolean;
    closechk:boolean;
    dcrHistorylst: DailyCallModel[] = [];
    pageno:number;
    pageSize:number;
    totalItems:number = 0;
    txtserach2:string;
    dealerflag:string;
    
}