<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row" *ngIf="model.id ==null">
                <!-- <div class="input-field col l6 m4 s12">
                    <input type="text" [(ngModel)]="model.name" class="autocomplete" id="resAllo"
                        placeholder="Resource Name" (keyup)="getResourceLike($event)" />
                    <input type="hidden" id="acc-id" />
                </div> -->
                <div class="ng-autocomplete pinRowgrp input-field l6 m4 s12">
                    <ng-autocomplete [data]="resourcelist" type="text" [(ngModel)]="model.name" placeholder="Resource Name"
                      [searchKeyword]="keyword" [minQueryLength]="3"  id="resAllo"
                      (inputChanged)='onChangeSearch($event)'  (selected)='selectEvent($event)'  (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
  
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <div class="col l1">
                </div>
            </div>
            <div class="row" style="margin-top: 2%; margin-bottom: 2%" *ngIf="model.resource!=null">
                <div class="col l6 m6 s6">
                    <span style="font-size: 150%; font-weight: 800">Resource : <span>{{model.resource}}</span></span>
                </div>
                <div class="right-align col l6 m6 s6">
                    <span style="font-size: 150%; font-weight: 800">Designation :
                        <span>{{model.designationName}}</span></span>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="overflow-y: scroll; height: 500px;">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="5%" style="text-align: center">Select</th>
                            <th width="15%" style="text-align: left">Allowance type</th>
                            <th width="5%" style="text-align: left">Frequency</th>
                            <th width="10%" style="text-align: left">Frequency Unit</th>
                            <th width="10%" style="text-align: center">Value</th>
                            <th width="10%" style="text-align: left">Type</th>
                            <th width="10%" style="text-align: left">Unit</th>
                            <th width="10%" style="text-align: left">Calculate</th>
                            <th width="5%" style="text-align: left">Map Use</th>
                            <th width="10%" style="text-align: left">Rate</th>
                            <th width="10%" style="text-align: left">Linked Allowance</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of allowancelst">
                                <td style="text-align: center"><input type="checkbox" [(ngModel)]="lst.chkselect"
                                        #chkselect="ngModel"></td>
                                <td style="text-align: left">{{lst.allowncetype}}</td>
                                <td style="text-align: left">{{lst.frequncy}}</td>
                                <td style="text-align: left">{{lst.freqUnit}}</td>
                                <td style="text-align: left">{{lst.value}}</td>
                                <td style="text-align: left">{{lst.type}}</td>
                                <td style="text-align: left">{{lst.unit}}</td>
                                <td style="text-align: left">{{lst.calculation}}</td>
                                <td style="text-align: center"><input type="checkbox" [(ngModel)]="lst.mapUse"
                                        #mapUse="ngModel"></td>
                                <td style="text-align: center"><input type="number" [(ngModel)]="lst.rate"
                                        #rate="ngModel" oninput="validity.valid||(value='')" style="text-align: right;"></td>
                                <td style="text-align: center"><span style="cursor: pointer">
                                        <img src="../../../assets/images/link-variant.png" width="30%"
                                            title="Linked Allowances"
                                            (click)="addLinkedAllows(lst.allowanceid ,lst.type, lst.linkedAllowance, LinkedAllowance)" />
                                    </span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)=" cancle()">Cancel</button> &nbsp;&nbsp;<button type="button" (click)="btnsave()"
                        class="waves-ripple waves-light btn" style="margin-right: 5px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #LinkedAllowance>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-content registerBgShadow">
        <div class="col l12 m12 s12 modal-header">
            <h4>Linked Allowance</h4>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12" style="overflow-y: auto; height: 300px;padding-left: 18px;padding-right: 18px;padding-top: 9px;">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left"></th>
                            <th width="90%" style="text-align: left">Allowance Name</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of linkedlst">
                                <td style="text-align: left"><input type="checkbox" [(ngModel)]="lst.chkselect"
                                        #chkselect="ngModel" (click)="oncheckLinked($event, lst.alloid)"></td>
                                <td style="text-align: left">{{lst.allowaType}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" style="padding-bottom: 15px;">
                <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 18px;">
                    <button (click)="saveLinkedAllo(linkedid)" type="button"
                        class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;
                    <button (click)="modalRef.hide()" type="button" class="waves-effect waves-light btn">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>