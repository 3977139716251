import { DropDownModel } from "src/app/_models/DropDownModel";

export class DefineItemModel {
    id: number;
    chkcpyitem: boolean;
    cpyitem:string;
    item: string;
    itemcode: string;
    itemname: string;
    description: string;
    chkbyitem: boolean;
    chkbyservice: boolean;
    itemtype: string;
    itemcategoryid: number;
    itemsubcategoryid: number;
    inventoryunitid: number;
    productionunitid: number;
    diamensionUnitid: number;
    lengt: number;
    breadth: number;
    height: number;
    chklengt: boolean;
    chkbreadth: boolean;
    chkheight: boolean;
    reorderLevel: number;
    minimumOrderQty: number;
    gstGroupid: number;
    chkUntConversion: boolean;
    ucQuantity: number;
    convQuantity: number;
    ucInvenUnitid: number;
    convInvenUnitid: number;

    chkbom: boolean;
    uom: string;
    qty: string;
    hsnCode: string;
    sacCode: string;
    userid: number;


    itemcategory: string;
    itemsubcategory: string;
    inventoryunit: string;
    productionunit: string;
    diamensionUnit: string;
    gstGrouppercentage: number;
    ucInvenUnit: string;
    convInvenUnit: string;
    itemMasterlst: BOMItemModel[] = [];
    unitlst: DropDownModel[] = [];

}
export class BOMItemModel {
    id: number;
    item: string;
    itemcode:string
    itemid:number;
    itemcatid: number;
    subitemcatid: number;
    uom: string;
    quantity: number;
    chkbyitem: boolean;
    chkbyservice: boolean;
    itemtype: string;
}