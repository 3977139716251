import { OnInit, Component, OnDestroy } from '@angular/core';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { SearchActivityModel } from './SearchActivityModel';

@Component({
    selector: 'app-DActivity',
    //  moduleId: module.id,
    templateUrl: 'DefineActivity.html'
})
export class DefineActivity implements OnInit, OnDestroy {
    user = new User();
    p: any;
    public i;
    id: number;
    
    customertypelst: SearchActivityModel[] = [];
    model = new SearchActivityModel();
    smslst: DropDownModel[] = [];
    pagename: string;
    accesslst: DropDownModel[] = [
        { "id": 1, "name": "SALES" },
        { "id": 2, "name": "SERVICE" }
    ]

    callTypelst: string[] = [];


    // callSubTypelst: DropDownModel[] = [
    //     { "id": 1, "name": "Installtion" },
    //     { "id": 2, "name": "Warranty" },
    //     { "id": 3, "name": "AMC" },
    //     { "id": 4, "name": "Preventive Maintenance" },
    //     { "id": 5, "name": "Break Down" }
    // ]
    actionlst: DropDownModel[] = [
        { "id": 1, "name": "Create Quotation" },
        { "id": 3, "name": "Repair" },
        { "id": 4, "name": "Re-installation" },
        { "id": 5, "name": "Create Invoice" },
        { "id": 6, "name": "Installation" },
        { "id": 7, "name": "Assign To" },


    ]
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {

    }
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit(): void {
        this.getsms();
        this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.Masterservice.getAcitvityById(user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.customertypelst = data.customerTypelst;
                        this.onSelectaccessType(this.model.accesstype);




                    }
                );
        }


        this.pagename = localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }

    // getActivity() {
       
    //         this.Masterservice.getcustomerType(this.model)
    //             .subscribe(
    //                 data => {
    //                     this.customertypelst = data;
    //                 }
    //             );
        
    // }
    onSelectaccessType(val) {
        if (val == 0) {
            this.notifyService.showRroor("Select Access Type !!", "Oops!");
            return;
        }
        if (val == "SALES") {
            this.model.accesstype = "SALES";
            this.model.callsubtype = null;
            this.callTypelst = [];
            this.callTypelst.push("DCR Sales");
            this.callTypelst.push("General");
        } else {
            this.model.accesstype = "SERVICE"
            this.callTypelst = [];
            this.callTypelst.push("General");
            this.callTypelst.push("Machine Under Installation");
            this.callTypelst.push("Machine Under Warranty");
            this.callTypelst.push("Machine Under AMC");
            this.callTypelst.push("Machine Out of AMC/Warranty");
            this.callTypelst.push("Machine Re-Installation");
        }
    }
    onselectall($event) {
        
        if ($event.target.checked) {
            for (let i = 0; i < this.customertypelst.length; i++) {
                this.customertypelst[i].cuscheckselect = true
            }
        } else {
            for (let i = 0; i < this.customertypelst.length; i++) {
                this.customertypelst[i].cuscheckselect = false
            }
        }
    }
    
    
    
    onSelectCallType(val) {
        if (val == 0) {
            this.notifyService.showRroor("Select Call Type!!", "Oops!");
            return;
        }

        this.model.callType = val
        // this.customertypelst=null;
        // if (this.model.callType == "DCR Sales") {
            this.Masterservice.getcustomerType(this.model)
                .subscribe(
                    data => {
                        this.customertypelst = data;
                    }
                );
        // } 
        // else {
        //     this.customertypelst = null
        // }
    } 

    onSelectCallSubType(val) {
        if (val == 0) {
            this.notifyService.showRroor("Select Call Subtype!!", "Oops!");
            return;
        }
        this.model.callsubtype = val
    }

    onSelectactionType(val) {
        //  this.model.actiontype = val
        if (val == "Create Quotation") {
            this.model.actiontype = "Q"
        }
        if (val == "Create Invoice") {
            this.model.actiontype = "I"
        }
        if (val == "Repaire") {
            this.model.actiontype = "R"
        }
        if (val == "Re-installation") {
            this.model.actiontype = "Re"
        }
        if (val == "Installation") {
            this.model.actiontype = "In"
        }
        if (val == "Assign To") {
            this.model.actiontype = "A"
        }
        


    }

    btnsave($event) {
        if (this.model.accesstype == null) {
            this.notifyService.showRroor("Please select Access type", "Oops");
            return;
        }
        if (this.model.callType == null) {
            this.notifyService.showRroor("Please select Call type", "Oops");
            return;
        }
        // if (this.model.accesstype == "Service") {
        //     if (this.model.callsubtype == null) {
        //         this.notifyService.showRroor("Please select Call Subtype", "Oops");
        //         return;
        //     }
        // }
        if (this.model.activityCode == null) {
            this.notifyService.showRroor("Please enter Activity Code", "Oops");
            return;
        }
        if (this.model.activityName == null) {
            this.notifyService.showRroor("Please enter Activity Name", "Oops");
            return;
        }
        if (this.model.sequence == null) {
            this.notifyService.showRroor("Please enter Hierarchy", "Oops");
            return;
        }
        if (this.model.accesstype == "Service") {
            if (this.model.actiontype == null) {
                this.notifyService.showRroor("Please select Action", "Oops");
                return;
            }
        }
        this.model.customerTypelst = this.customertypelst
        this.Masterservice.saveActivity(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success ")
                        this.router.navigate(['Activity']);
                    } else {
                        if (data.message == null) {
                            this.notifyService.showRroor("Something wrong ", "Oops !")
                        } else {
                            this.notifyService.showRroor(data.message, "Oops !")
                        }
                    }
                });

    }

    cancle() {
        this.router.navigate(["Activity"]);
    }



    getsms() {
        let user = new User();
        this.smslst = [];
        this.Masterservice.getsms(user)
            .subscribe((value) => {
                this.smslst = value.messagelst

            });
    }
    onSelectSms(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Message !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.smslst.length; i++) {
            if (this.smslst[i].id == value) {
                this.model.smsid = this.smslst[i].id;
                this.model.msgcode = this.smslst[i].name
            }
        }


    }
}