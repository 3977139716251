<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">

            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s4" style="text-align: left; margin-top: 12px;margin-bottom: 0px;">
                        <span>Default Currency</span>
                        <select (change)=" onSelectDefaultcurrency($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Default Currency">
                            <option selected value="0">Select Default Currency</option>
                            <option *ngFor="let type of defaultcurrencylst" [value]="type.id"
                                [selected]="type.name == model.defltcurrency">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="col s1">
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 29px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.exchangeRate" #exchangeRate="ngModel" type="number" id="first1"
                            data-toggle="tooltip" data-placement="top" title="Exchange Rate"
                            oninput="validity.valid||(value='');" required min="0">
                        <label id="lable1" for="first1">Exchange Rate</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s4" style="text-align: left;margin-bottom: 0px;margin-top: 0px;">
                        <span>Target Currency</span>
                        <select (change)=" onSelectTargetcurrency($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Target Currency">
                            <option selected value="0">Select Target Currency</option>
                            <option *ngFor="let type of targetcurrencylst" [value]="type.id"
                                [selected]="type.name == model.targetCurrencyName">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="col s1">
                    </div>
                    <div class="input-field col s3 " style="margin-right :2%; margin-bottom: 0px;margin-top: 0px;">
                        <span>From Date</span>
                        <input type="text" placeholder="From Date" [(ngModel)]="model.asonDate"
                            (ngModelChange)="onChangeCreateddate(model.asonDate)" id="start" class="form-control"
                            bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col l11 m11 s11" style="text-align: right">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                            (click)="cancle()">Cancel</button>
                        <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                            style="margin-left: 5px;">Save</button>
                    </div>
                    <div class="col s1">
                    </div>
                </div>
                <div class="row">

                    <div class="col l12 m12 s12">
                        <div class="col s1">
                        </div>
                        <div class="col l10 m10 s10" style="height: 500px; overflow: auto;padding-left: 0px;padding-right: 0px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="30%" style="text-align: left;padding-left: 10px;">Currency</th>
                                    <th width="30%" style="text-align: right;padding-right: 15px;">Exchange Rate</th>
                                    <th width="30%" style="text-align: left;">Target Currency</th>
                                    <th width="10%" style="text-align: left">Actions</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of cuexchlst">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.currencyName}}</td>
                                        <td style="text-align: right;padding-right: 15px;">{{lst.exchangeRate}}</td>
                                        <td style="text-align: left">{{lst.targetCurrencyName}}</td>

                                        <td style="text-align: left"> <span><img src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst)" data-toggle="tooltip" data-placement="top"
                                                    title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                                    src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                    width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col s1"></div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>