<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Orders In Hand Report</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2" style="padding-top: 9px;">
                    <span>From Date</span>
                    <input type="text" placeholder="From Date" [(ngModel)]="model.frmdate" class="form-control"
                        bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true,showWeekNumbers: false }" (ngModelChange)="selectFromDate(model.frmdate)">
                </div>
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2" style="padding-top: 9px;">
                    <span>To Date</span>
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" class="form-control"
                        bsDatepicker (ngModelChange)="selectToDate(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true,showWeekNumbers: false}">
                </div>
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 0px;padding-top: 9px;">
                    <span>City</span>
                    <select (change)="onSelectCity($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="City">
                        <option selected value="0">Select City</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of citylst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 0px;">
                    <span>Region</span>
                    <select (change)="onSelectRegions($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Regions">
                        <option selected value="0">Select Region</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of regionlst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 0px;">
                    <span>Area</span>
                    <select (change)="onSelectArea($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Area">
                        <option selected value="0">Select Area</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of arealst" [value]="lst.areaid">
                            {{lst.areaname}}
                        </option>
                    </select>
                </div>

            </div> -->
            <div class="row">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l3 m3 s3" style="margin-top: 0px;">
                    <span>Branch</span>
                    <select (change)="onSelectBranches($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Branches" data-toggle="tooltip" data-placement="top" title="Branches">
                        <option Selected value="0">Select Branch</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of branchlst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-top: 0px;">
                    <span>Product Category</span>
                    <select (change)="onSelectPrdCategory($event.target.value)" data-toggle="tooltip"
                        data-placement="top" data-toggle="tooltip" data-placement="top" title="Product Category">
                        <option selected value="0">Select Product Category</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of prodCatlst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l2 m2 s2" style="text-align: right;margin-top: 17px;">
                    <button (click)="btnshow(OrdInHandReport)" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Show</button>
                </div>
            </div>
        </div>

        <ng-template #OrdInHandReport>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row" id="LibDocs">
                <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                    <div class="modal-content " style="text-align:center;padding: 0px;margin: 24px;">
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>

                            </div>
                        </div>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 16px">{{companyaddress}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <span style="font-size: 20px;text-decoration: underline">Order In Hand Report</span>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                        <div class="col l12 m12 s12" style="padding-right: 0px;">
                            <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;margin: 0px;">
                                <span><img src="../../../assets/images/printer.png"
                                    (click)="openPrint()" data-toggle="tooltip"
                                    data-placement="top" title="Print" width="30px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/excel2.png"
                                        onclick="tableToExcel('excel')" data-toggle="tooltip"
                                        data-placement="top" title="Excel" width="30px" /></span>
                            </div>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7">
                            <tbody>
                                <tr>
                                    <!-- <td width="20%" style="text-align: left;border: 1px solid black;">
                                        <span>Region:-</span>
                                        &nbsp;<span><b>{{regionName}}</b></span>
                                    </td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;">
                                        <span>Area:-</span>
                                        &nbsp;<span><b>{{areaName}}</b></span>
                                    </td> -->
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>From
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.frmdate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>To
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.todate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                            id="excel">
                            <thead style="background: gainsboro;">
                                <!-- <th width="24%" style="text-align: left;">Customer Name</th>
                                <th width="12%" style="text-align: left">Branch Name</th>
                                <th width="18%" style="text-align: left">Product Name</th>
                                <th width="12%" style="text-align: left">Locality Name</th>
                                <th width="7%" style="text-align: center">Quantity</th>
                                <th width="10%" style="text-align: left">UOM</th>
                                <th width="8%" style="text-align: center">Order Value</th>
                                <th width="9%" style="text-align: center">Final Amount</th> -->
                                <th width="8%" style="text-align: left">Date</th>
                                <th width="9%" style="text-align: left">city</th>
                                <th width="9%" style="text-align: left">Branch</th>
                                <th width="12%" style="text-align: left">OA#</th>
                                <th width="15%" style="text-align: left">Customer Name</th>
                                <th width="22%" style="text-align: left">Product Name</th>
                                <th width="5%" style="text-align: left">Qty</th>
                                <th width="5%" style="text-align: left">UOM</th>
                                <th width="7%" style="text-align: left">Order Value</th>
                                <!-- <th width="8%" style="text-align: left">Status</th>                             -->
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of ordlst">
                                    <td style="text-align: left;">{{lst.date |
                                        date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align: left">{{cityName}}</td>
                                    <td style="text-align: left">{{branchName}}</td>
                                    <td style="text-align: left">{{lst.orderAcceptanceNo}}</td>
                                    <td style="text-align: left">{{lst.customerName}}</td>
                                    <td style="text-align: left">{{lst.productName}}</td>
                                    <td style="text-align: left">{{lst.quantity}}</td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <td style="text-align: left">{{lst.orderValue |
                                        number:'1.2-2'}}</td>
                                    <!-- <td style="text-align: left">{{lst.status}}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col s12 m12 l12" style="text-align: center;margin-top: 12px;">
                        <span style="font-size: 11px;">Report Genrated By: {{username}} on {{timing
                            |date:'medium'}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="padding-right: 0px;">
                    <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="modalRef.hide()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>
</div>