import { OnInit, Component } from '@angular/core';
import { MarketingProposalHomeModel } from './MarketingProposalHomeModel';
import { MasterServices, TransactionServices, DealersService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models/user';
import { MarketingProposalModel, ExpensesModel, SubheadModel } from './MarketingProposalModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
import { MarketingServices } from 'src/app/_services/MarketingServices';

declare var $: any;
import * as moment from 'moment';
import { SearchMarketingModel } from 'src/app/Master/MarketingCostSubMaster/SearchMarketingModel';
import { CityMasterModel } from 'src/app/Master/CityMaster/CityMasterModel';
import { ThrowStmt } from '@angular/compiler';
import { FormBuilder } from '@angular/forms';
import { updateFor } from 'typescript';
var converter = require('number-to-words');
@Component({
    selector: 'app-MarketingProposal',
    templateUrl: 'MarketingProposal.html',
})
export class MarketingProposal implements OnInit {
    constructor(private masterservice: MasterServices, private transactionService: TransactionServices, private dealersService: DealersService, private transactionservice: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private marketingServices: MarketingServices) {
    }
    model = new MarketingProposalModel();
    user = new User();
    promodel = new ProspectModel()
    eventtypelst: DropDownModel[] = [];
    lstlocality: DropDownModel[] = [];
    lstlocalitys: DropDownModel[] = [];
    dealerlst: DropDownModel[] = [];
    sponserlst: DropDownModel[] = [];
    expenselst: ExpensesModel[] = [];
    userList2: any;
    keyword = 'name';
    pincodelist = [];
    costheadlst: DropDownModel[] = []
    subheadlst: SubheadModel[] = [];
    subcostheadlst: MarketingProposalModel[] = [];
    citylst: CityMasterModel[] = [];

    operationType: string;
    comapnyname: string;
    loginUser: string;
    id: number;
    subheadvalue: number
    monthmap = new Map();

    currentFromyear: Date;
    currentToyear: Date;
    mrktcostlst: DropDownModel[] = [];

    mrktmodellst: SearchMarketingModel[] = [];
    mrktmodellst1: SearchMarketingModel[] = [];
    public i;
    uomname: string;
    costheadid: number;
    name: string;
    costsubheadname: string;
    balance: number;
    alert: boolean;
    ngOnInit() {
        this.getCurrentFinancialYear();
        this.monthmap.set(0, "Jan")
        this.monthmap.set(1, "Feb")
        this.monthmap.set(2, "Mar")
        this.monthmap.set(3, "Apr")
        this.monthmap.set(4, "May")
        this.monthmap.set(5, "June")
        this.monthmap.set(6, "Jul")
        this.monthmap.set(7, "Aug")
        this.monthmap.set(8, "Sep")
        this.monthmap.set(9, "Oct")
        this.monthmap.set(10, "Nov")
        this.monthmap.set(11, "Dec")

        this.model.closeEvent = false
        this.comapnyname = localStorage.getItem('companyName');
        this.loginUser = localStorage.getItem('fullname');
        this.user.userId = Number(localStorage.getItem("userid"));
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.chkinternal = true;
        this.model.chkdealer = false;
        this.model.eventproposedby = "I";
        this.onchkinternal();
        this.model.chkevent = true;
        this.model.proposaltype = "Event";
        this.model.sponser = this.comapnyname
        this.route.queryParams.subscribe(params => {
            this.operationType = params["operationType"];
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User()
            user.trnid = this.id
            this.marketingServices.getMarketingProposalbyId(user)
                .subscribe(data => {
                    this.model = data
                    this.expenselst = data.expenselst;
                    this.mrktmodellst = data.mrktmodellst;
                    // this.model.companycotri = data.companycotri;
                    this.operationType = data.status;
                    this.model.fromdate = new Date(data.fromdate)
                    this.model.todate = new Date(data.todate);
                    this.model.cityid = data.cityid;
                    this.subheadlst = data.subheadlst
                    this.model.eventproposedby = data.eventproposedby;

                    for (let i = 0; i < this.expenselst.length; i++) {
                        this.model.dealername = this.expenselst[i].sponser
                        this.expenselst[i].alert = false;
                        // for (let j = 0; j < this.expenselst.indexOf(this.expenselst[i]); j++) {
                        if (this.expenselst[i].balance < 0) {
                            this.expenselst[i].alert = true;
                        }
                        // }
                    }

                    if (data.eventproposedby == "I") {
                        this.onchkinternal();
                    } else if (data.eventproposedby == "D") {
                        this.onchkdealer()
                        this.onSelectDealer(data.dealerid)
                    }
                    this.calculateAmount()

                });
        } else {
            this.getEventType();
        }
        this.getmrktcosthead();
        this.getAllMarketing();
        this.getAllCity();
        this.model.fromdate = new Date();
        this.model.todate = new Date();
    }


    getmrktcosthead() {
        let user = new User();
        this.mrktcostlst = [];

        this.masterservice.getmrktcosthead(user)
            .subscribe((value) => {
                this.mrktcostlst = value.mrklst



            });
    }


    getAllMarketing() {
        this.masterservice.getAllMarketing(this.user)
            .subscribe(value => {
                this.mrktmodellst = value;
                this.mrktmodellst1 = [];
                value.forEach((item) => this.mrktmodellst1.push({ "id": item.id, "name": item.name, "code": item.code, "costhead": item.costhead, "txtserch": item.txtserch, "txtselect": item.txtselect, "uomid": item.uomid, "uomname": item.uomname, "subheadvalue": item.subheadvalue, "totalsubheadvalue": item.totalsubheadvalue, "balance": item.balance, "qty": item.qty, "chkselectvalue": item.chkselectvalue }));
                this.mrktmodellst = this.mrktmodellst1;
                // for(let i = 0;i < this.mrktmodellst.length; i++)
                // {
                //     this.model.subheadid = this.mrktmodellst[i].id;
                // }

            });
    }

    getCurrentFinancialYear() {

        this.masterservice.getCurrentFinancialYear(this.user)
            .subscribe(data => {
                this.currentFromyear = new Date(data.yearFrom)
                this.currentToyear = new Date(data.yearTo)
            });
    }

    getEventType() {
        this.marketingServices.getEventType(this.user)
            .subscribe(data => {
                this.eventtypelst = data
            });
    }


    onchkevent($event) {
        this.model.chkevent = true;
        this.model.chkcollateral = false;
        this.model.chkstandalon = false;
        this.model.proposaltype = "Event";
        this.expenselst = []
        this.model.fromdate = new Date()
        this.model.todate = new Date()
    }

    onchkstandalon($event) {
        this.model.chkstandalon = true;
        this.model.chkevent = false
        this.model.chkcollateral = false
        this.model.proposaltype = "Standalone";
        this.onchkRecurring()
        this.expenselst = []
        this.model.fromdate = new Date()
        this.model.todate = new Date()
    }

    onchkcollateral($event) {
        this.model.chkcollateral = true;
        this.model.chkstandalon = false;
        this.model.chkevent = false
        this.model.proposaltype = "Collateral";
        this.expenselst = []
    }

    onSelectEventtype(id) {
        this.model.eventtypeid = id
    }

    getPincodeLikes($event) {
        let userId = (<HTMLInputElement>document.getElementById('resAllos')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionservice.getpincodelike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelectPincode(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelectPincode(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
        if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
            this.lstlocality = [];
        }
    }


    typeaheadOnSelectPincode(id, name) {
        this.promodel.pincode = name;
        this.model.pincode = name
        this.lstlocality = [];
        this.lstlocality.push({ id: 0, name: "Select Locality" });
        this.transactionservice.getlocality(this.promodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocality.push({ id: item.id, name: item.name });
                });
            });
    }

    onSelectLocality(id) {
        if (id == 0) {
            this.notifyService.showRroor("Please select Locality !", "Oops!")
            return;
        }
        this.model.localityid = id
        this.user.trnid = id;

        this.promodel.localityID1 = id;
        this.transactionservice.getgeographydetails(this.promodel)
            .subscribe(data => {
                this.model.city = data.city;
                // this.model.sta = data.state;
                // this.model.country1 = data.country;
                this.model.locale = data.locale;
                this.model.area = data.areaname;
                // this.model.localeID1 = data.localeID;
                // this.model.areaID1 = data.areaID;
                this.model.region = data.regionname;
            });


        this.dealersService.getDealerByLocality(this.user)
            .subscribe(data => {
                this.dealerlst = data
            });
    }


    onchkinternal() {
        this.model.chkinternal = true;
        this.model.chkdealer = false;
        this.model.eventproposedby = "I";
        this.model.dealercontri = null;
        this.model.sponser = this.comapnyname
        this.sponserlst = [];
        this.sponserlst.push({
            name: "Select one",
            id: 0
        })
        this.sponserlst.push({
            name: this.comapnyname,
            id: 1
        })
    }

    onchkdealer() {
        this.model.chkdealer = true;
        this.model.chkinternal = false
        this.model.eventproposedby = "D"
        this.model.companycotri = null;
    }

    onSelectDealer(id) {
        this.sponserlst = []
        this.model.dealerid = id;
        for (let i = 0; i < this.dealerlst.length; i++) {
            if (this.dealerlst[i].id == id) {
                this.model.dealername = this.dealerlst[i].name;
            }
        }

        this.sponserlst.push({
            name: "Select one",
            id: 0
        })
        this.sponserlst.push({
            name: this.comapnyname,
            id: 1,

        })
        this.sponserlst.push({
            name: this.model.dealername,
            id: this.model.dealerid
        })
    }

    fromDateOnchange(fromdate) {
        if (fromdate < this.currentFromyear || fromdate > this.currentToyear) {
            this.notifyService.showRroor("From Date is not in current financial year !", "Oops!");
            this.model.fromdate = new Date();
            return;
        }
        let pastdate = moment(new Date(), "DD/MM/YYYY").subtract(1, 'days').toDate()
        
        if (this.model.fromdate < pastdate) {
            this.notifyService.showRroor("Past date is not allowed", "oops");
            // this.model.followupdate = this.currentDate
            this.model.fromdate = new Date()
            return;
        }
        if(this.model.fromdate > this.model.todate)
        {
            this.notifyService.showRroor("From date should be less than To-date", "oops");
            // this.model.followupdate = this.currentDate
            this.model.fromdate = new Date()
            return;
        }

        this.model.fromdate = fromdate;
    }
    toDateOnchange(todate) {
        if (todate < this.currentFromyear || todate > this.currentToyear) {
            this.notifyService.showRroor("From Date is not in current financial year !", "Oops!");
            this.model.todate = new Date();
            return;
        }
        if (this.model.fromdate >= this.model.todate) {
            this.notifyService.showRroor("To-date should be greater than From-date !", "Oops!")
            this.model.todate = new Date();
            return;
        }
        this.model.todate = todate;
        let from = new Date(this.model.fromdate);
        let to = new Date(todate);

        let days = Math.floor((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24));
        this.model.days = days + 1
        this.model.daysDuration = converter.toWords(days + 1);
    }


    onSelectSponser(sponser) {
        this.model.sponser = sponser;
    }

    btnaddExpense() {
        if (this.model.proposaltype == "Event") {
            if (this.model.des1 == null) {
                this.notifyService.showRroor("Please enter Description", "Oops!")
                return;
            }
            if (this.model.amnt == null) {
                this.notifyService.showRroor("Please enter Amount", "Oops!")
                return;
            }
            if (this.model.des1 == null) {
                this.notifyService.showRroor("Please select Description", "Oops!")
                return;
            }
            this.expenselst.push({
                id: null,
                description: this.model.des1,
                amount: this.model.amnt,
                sponser: this.model.sponser,
                unit: null,
                balance: null,
                subheadid: null,
                chkselect: true,
                approvedamount: null,
                disbursedamount: null,
                subhead: null,
                expenseDate: null,
                expenseMonth: null,
                costhead: null,
                costheadid: null,
                subheadvalue: this.model.subheadvalue,
                rate: null,
                totalsubheadvalue: null,
                qty: null,
                finalamount: null,
                priviousdisburseamt: null,
                alert: null,
                chkselectvalue: null,
                budgeted: null
            });
        } else if (this.model.proposaltype == "Standalone") {
            if (this.model.des1 == null) {
                this.notifyService.showRroor("Please enter Description", "Oops!")
                return;
            }
            if (this.model.amnt == null) {
                this.notifyService.showRroor("Please enter Amount", "Oops!")
                return;
            }
            if (this.model.fromdate == null || this.model.todate == null) {
                this.notifyService.showRroor("Please enter Date", "Oops!")
                return;
            }
            if (this.model.durationType == null) {
                if (this.model.chkRecurring) {
                    if (this.model.chkdays) {
                        for (let i = 0; i < this.model.days; i++) {
                            this.expenselst.push({
                                id: null,
                                description: this.model.des1,
                                amount: this.model.amnt,
                                sponser: null,
                                unit: null,
                                balance: null,
                                subheadid: null,
                                chkselect: true,
                                approvedamount: null,
                                disbursedamount: null,
                                subhead: null,
                                expenseDate: moment(this.model.fromdate, "DD/MM/YYYY").add(i, 'days').toDate(),
                                expenseMonth: null,
                                costhead: null,
                                costheadid: null,
                                subheadvalue: this.model.subheadvalue,
                                rate: null,
                                totalsubheadvalue: null,
                                qty: null,
                                finalamount: null,
                                priviousdisburseamt: null,
                                alert: null,
                                chkselectvalue: null,
                                budgeted: null

                            })
                        }
                        // moment().add(this.expdu, 'days') 
                    } else if (this.model.chkmonths) {


                        for (let i = 0; i < this.model.days; i++) {
                            this.expenselst.push({
                                id: null,
                                description: this.model.des1,
                                amount: this.model.amnt,
                                sponser: null,
                                unit: null,
                                balance: null,
                                subheadid: null,
                                chkselect: true,
                                approvedamount: null,
                                disbursedamount: null,
                                subhead: null,
                                expenseDate: null,
                                expenseMonth: this.monthmap.get(moment(this.model.fromdate, "MMM-YYYY").add(i, 'months').toDate().getMonth()) + "-" + moment(this.model.fromdate, "MMM-YYYY").add(i, 'months').toDate().getFullYear(),
                                costhead: null,
                                costheadid: null,
                                subheadvalue: this.model.subheadvalue,
                                rate: null,
                                totalsubheadvalue: null,
                                qty: null,
                                finalamount: null,
                                priviousdisburseamt: null,
                                alert: null,
                                chkselectvalue: null,
                                budgeted: null
                            })
                        }

                    } else {
                        this.notifyService.showRroor("Please select Recurring Type", "Oops!")
                        return;
                    }


                } else if (this.model.chknonschedule) {
                    this.expenselst.push({
                        id: null,
                        description: this.model.des1,
                        amount: this.model.amnt,
                        sponser: null,
                        unit: null,
                        balance: null,
                        subheadid: null,
                        chkselect: true,
                        approvedamount: null,
                        disbursedamount: null,
                        subhead: null,
                        expenseDate: this.model.fromdate,
                        expenseMonth: null,
                        costhead: null,
                        costheadid: null,
                        subheadvalue: this.model.subheadvalue,
                        rate: null,
                        totalsubheadvalue: null,
                        qty: null,
                        finalamount: null,
                        priviousdisburseamt: null,
                        alert: null,
                        chkselectvalue: null,
                        budgeted: null
                    })
                } else {
                    this.notifyService.showRroor("Please select Duration Type", "Oops!")
                    return;
                }
            }
        } else if (this.model.proposaltype == "Collateral") {
            if (this.model.costheadid == null) {
                this.notifyService.showRroor("Please select Cost-head", "Oops!")
                return;
            }
            // if (this.model.costheadid == null) {
            //     this.notifyService.showRroor("Please select Cost-head", "Oops!")
            //     return;
            // }
            if (this.model.subheadid == null) {
                this.notifyService.showRroor("Please select Sub-head", "Oops!")
                return;
            }
            if (this.model.qty == null) {
                this.notifyService.showRroor("Please Enter Quantity", "Oops!")
                return;
            }

            this.expenselst.push({
                id: null,
                description: this.model.description,
                amount: this.model.amnt,
                sponser: null,
                unit: this.model.uomname,
                subheadid: this.model.subheadid,
                chkselect: true,
                approvedamount: null,
                disbursedamount: null,
                subhead: this.model.costSubhead,
                expenseDate: this.model.fromdate,
                expenseMonth: null,
                costhead: this.model.costhead,
                costheadid: this.costheadid,
                subheadvalue: this.model.subheadvalue,
                rate: this.model.rate,
                totalsubheadvalue: null,
                qty: this.model.qty,
                finalamount: this.model.rate * this.model.qty,
                balance: this.model.balance,
                priviousdisburseamt: null,
                alert: false,
                chkselectvalue: null,
                budgeted: this.model.budgeted
            })
        }
        // this.model.des1 = null
        // this.model.amnt = null
        let dealer = 0;
        let company = 0;
        for (let i = 0; i < this.expenselst.length; i++) {
            if (this.expenselst[i].chkselect) {
                if (this.expenselst[i].sponser == this.comapnyname) {
                    if (this.model.chkselectvalue == false) {
                        company = company + this.expenselst[i].finalamount
                    }
                    else {
                        company = company + this.expenselst[i].amount
                    }
                }
                if (this.expenselst[i].sponser == this.model.dealername) {
                    if (this.model.chkselectvalue == false) {
                        dealer = dealer + this.expenselst[i].finalamount
                    }
                    else {
                        dealer = dealer + this.expenselst[i].amount
                    }

                }
            }
        }
        if (this.model.eventproposedby == "D") {
            this.model.dealercontri = dealer
        }
        if (this.model.eventproposedby == "I") {
            this.model.companycotri = company
        }
        this.model.totalcontri = company + dealer
    }

    onchkselect($event) {
        for (let i = 0; i < this.expenselst.length; i++) {
            if ($event.target.checked) {
                this.expenselst[i].chkselect = true;
            } else {
                this.expenselst[i].chkselect = false;
            }
        }
        this.calculateAmount()
    }

    btndeleteExpense(id) {
        // this.calculateAmount()
        this.model.userid = Number(localStorage.getItem("userid"));
        this.i = confirm("Do you want to delete this record ?")
        for (let i = 0; i < this.expenselst.length; i++) {
            if (this.expenselst[i].id == id) {
                this.expenselst.splice(this.expenselst.indexOf(this.expenselst[i]), 1);
                this.notifyService.showSuccess("Expense Removed Successfully", " Success");
            }
            this.calculateAmount();
        }
    }


    calculateAmount() {
        let dealer = 0;
        let comapny = 0;

        for (let i = 0; i < this.expenselst.length; i++) {
            if (this.expenselst[i].chkselect) {
                if (this.expenselst[i].sponser == this.comapnyname) {
                    comapny = comapny + this.expenselst[i].amount
                }
                if (this.expenselst[i].sponser == this.model.dealername) {
                    dealer = dealer + this.expenselst[i].amount
                }
            }
        }
        if (this.model.eventproposedby == "D") {
            this.model.dealercontri = dealer
        }
        this.model.companycotri = comapny
        this.model.totalcontri = comapny + dealer

        // for (let i = 0; i < this.expenselst.length; i++) {
        //     this.balance = 0;
        //     this.alert = false;
        //     // this.model.dealername = this.expenselst[i].sponser
        //     this.balance = this.balance + this.expenselst[i].balance;
        //     if (this.balance < 0) {
        //         this.alert = true;
        //         return;
        //     }
        //     else
        //     {
        //         this.alert = false;

        //     }

        // }
    }

    onchkRecurring() {
        this.model.chkRecurring = true;
        this.model.chknonschedule = false;

        this.onchkdays();
        this.expenselst = []
    }
    onchknonschedule($event) {
        this.expenselst = []
        this.model.chkRecurring = false;
        this.model.chknonschedule = true;

        this.model.chkdays = false;
        this.model.chkmonths = false;

        this.model.fromdate = null;
        this.model.todate = null;
    }
    onchkdays() {
        this.expenselst = []
        this.model.chkdays = true;
        this.model.chkmonths = false;

        this.model.fromdate = null;
        this.model.todate = null;
    }
    onchkmonths($event) {
        this.expenselst = []
        this.model.chkdays = false;
        this.model.chkmonths = true;

        this.model.fromdate = null;
        this.model.todate = null
    }

    selectFrommonth(val) {
        this.model.fromdate = val
    }

    selecttomonth(val) {
        this.model.todate = val
        let from = new Date(this.model.fromdate);
        let to = new Date(val);
        let days = to.getMonth() - from.getMonth() + (12 * (to.getFullYear() - from.getFullYear()))
        this.model.days = days + 1
        this.model.daysDuration = converter.toWords(days + 1);
    }


    onchkApproved($event) {
        this.model.chkApproved = true;
        this.model.chkReject = false;
        this.model.decision = "approve"
    }

    onchkReject($event) {
        this.model.chkApproved = false;
        this.model.chkReject = true;
        this.model.decision = "reject"
    }

    onSelectmrktcost(costheadid) {
        if (costheadid == 0) {
            this.notifyService.showRroor("Select Cost Head !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.mrktcostlst.length; i++) {
            if (this.mrktcostlst[i].id == costheadid) {
                this.model.costhead = this.mrktcostlst[i].name;
            }
        }
        this.user.trnid = costheadid;
        this.model.costheadid = costheadid;
        this.masterservice.getmrktcostSubhead(this.user)
            .subscribe((value) => {
                this.subcostheadlst = value.mrkcostsublst
                // for(let i = 0 ;i < this.subcostheadlst.length ;i++)
                // {
                //     this.subcostheadlst[i].uomname = value.uomname;
                // }
                // this.uomname = value.uomname;
            });
    }

    onSelectmrktcostsubhead(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Cost Sub Head !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.subcostheadlst.length; i++) {
            if (this.subcostheadlst[i].id == value) {

                this.model.subheadid = this.subcostheadlst[i].id;
                this.model.costSubhead = this.subcostheadlst[i].costsubname;
                this.model.uomname = this.subcostheadlst[i].uomname;
                this.model.rate = this.subcostheadlst[i].rate
                this.model.qty = this.subcostheadlst[i].qty;
                this.model.chkselectvalue = this.subcostheadlst[i].chkselectvalue
                this.model.budgeted = this.subcostheadlst[i].budgeted

                if (this.subcostheadlst[i].balance != null) {
                    this.model.balance = this.subcostheadlst[i].balance
                }
                else {
                    this.model.balance = this.model.budgeted;
                }



            }
        }

    }

    btnsaveMarketingproposal() {
        if (this.operationType == "new") {
            if (this.model.proposaltype == null) {
                this.notifyService.showRroor("Please select proposal type!", "Oops!");
                return;
            }
            if (this.model.chkevent) {
                if (this.model.eventtypeid == null) {
                    this.notifyService.showRroor("Please select event type!", "Oops!");
                    return;
                }
                if (this.model.shortname == null) {
                    this.notifyService.showRroor("Please enter short name!", "Oops!");
                    return;
                }
                if (this.model.expectedoutcome == null) {
                    this.notifyService.showRroor("Please enter expected outcome!", "Oops!");
                    return;
                }
            }
            if (this.model.description == null) {
                this.notifyService.showRroor("Please enter description!", "Oops!");
                return;
            }
            // if (this.model.pincode == null) {
            //     this.notifyService.showRroor("Please select pincode!", "Oops!");
            //     return;
            // }
            // if (this.model.localityid == null) {
            //     this.notifyService.showRroor("Please select locality!", "Oops!");
            //     return;
            // }


            if (this.model.fromdate == null && this.model.proposaltype != 'Collateral') {
                this.notifyService.showRroor("Please select From Date!", "Oops!");
                return;
            }
            if (this.model.todate == null && this.model.proposaltype != 'Collateral') {
                this.notifyService.showRroor("Please select To Date!", "Oops!");
                return;
            }

            if (this.model.cityid == null) {
                this.notifyService.showRroor("Please select City!", "Oops!");
                return;
            }
            if (this.expenselst.length == 0) {
                this.notifyService.showRroor("Please select at least one Expense Item", "Oops!");
                return;
            }


        }
        this.model.expenselst = this.expenselst;
        this.model.status = this.operationType
        this.marketingServices.saveMarketingProposal(this.model)
            .subscribe(data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess(data.message, "Done!");
                    this.router.navigate(['/Marketing Proposal']);
                }
            });
    }

    btnApprove() {
        this.model.expenselst = this.expenselst;
        this.model.status = this.operationType
        this.model.mrktmodellst = this.mrktmodellst;
        if(this.model.subheadid == null)
        {
            this.notifyService.showRroor("Please select Sub-Head", "Oops!");
            return;
        }
        if(this.model.chkApproved == null || this.model.chkReject == null)
        {
            this.notifyService.showRroor("Please select atleast one decision", "Oops!");
            return;
        }
        this.marketingServices.approveMarketingProposal(this.model)
            .subscribe(data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess(data.message, "Done!");
                    this.router.navigate(['/Marketing Proposal']);
                }
            });

    }

    btnback() {
        this.router.navigate(['/Marketing Proposal']);
    }
    selectEvent(item) {
        this.promodel.pincode = item.name;
        this.lstlocality = [];
        this.lstlocality.push({ id: 0, name: "Select Locality" });
        this.transactionService.getlocality(this.promodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocality.push({ id: item.id, name: item.name });
                });
            });
    }

    onChangeSearch(search: string) {

        this.user.message = search;
        this.transactionService.getpincodelike(this.user)
            .subscribe(data => {
                this.pincodelist = [];
                data.forEach((item) => this.pincodelist.push({ "id": item.id, "name": item.name }));

            });

    }

    onFocused(e) {
        // do something
    }
    onSelectSubhead(value, value1) {
        this.model.subheadid = value
        if (value == 0) {
            this.notifyService.showRroor("Select Cost Sub Head !!", "Oops!");
            return;
        }
        this.expenselst[value1].subheadid = value
        for (let i = 0; i < this.model.mrktmodellst.length; i++) {
            if (this.model.mrktmodellst[i].id == value) {

                this.expenselst[value1].subhead = this.model.mrktmodellst[i].name;
                this.expenselst[value1].unit = this.model.mrktmodellst[i].uomname;
                this.expenselst[value1].subheadvalue = this.model.mrktmodellst[i].subheadvalue;
                this.expenselst[value1].totalsubheadvalue = this.model.mrktmodellst[i].totalsubheadvalue;
                if(this.model.mrktmodellst[i].balance != null)
                {
                    this.expenselst[value1].balance = this.model.mrktmodellst[i].balance
                }
                else
                {
                    this.expenselst[value1].balance = this.model.mrktmodellst[i].subheadvalue;
                }
                
                this.expenselst[value1].qty = this.model.mrktmodellst[i].qty
                this.expenselst[value1].chkselectvalue = this.model.mrktmodellst[i].chkselectvalue
                if (this.expenselst[value1].chkselectvalue == false) {
                    this.expenselst[value1].unit = "Value"
                }

            }


        }
    }
    getAllCity() {
        this.marketingServices.getAllCitiesForProposal(this.user)
            .subscribe(value => {
                this.citylst = value;

            });
    }
    onSelectCity(value) {
        if (value == 0) {
            this.notifyService.showRroor("Please select City !", "Oops!")
            return;
        }

        this.model.cityid = value;
        this.user.trnid = value;
        this.dealersService.getDealerByCity(this.user)
            .subscribe(data => {
                this.dealerlst = data
            });
    }
    onSelectSubhead1(value, value1) {
        if (value == 0) {
            this.notifyService.showRroor("Select Cost Sub Head !!", "Oops!");
            return;
        }
        this.expenselst[value1].subheadid = value
        for (let i = 0; i < this.model.mrktmodellst.length; i++) {
            if (this.model.mrktmodellst[i].id == value) {

                this.expenselst[value1].subhead = this.model.mrktmodellst[i].name;
                this.expenselst[value1].unit = this.model.mrktmodellst[i].uomname;
                this.expenselst[value1].subheadvalue = this.model.mrktmodellst[i].subheadvalue;
                if(this.model.mrktmodellst[i].balance != null)
                {
                    this.expenselst[value1].balance = this.model.mrktmodellst[i].balance
                }
                else
                {
                    this.expenselst[value1].balance = this.model.mrktmodellst[i].subheadvalue;
                }
                this.expenselst[value1].totalsubheadvalue = this.model.mrktmodellst[i].totalsubheadvalue;
                this.expenselst[value1].qty = this.model.mrktmodellst[i].qty
                this.expenselst[value1].chkselectvalue = this.model.mrktmodellst[i].chkselectvalue
            }
        }
    }
    btndeleteforapproveExpense(id, index) {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            for (let i = 0; i < this.expenselst.length; i++) {
                this.model.id = this.expenselst[i].id;
            }

            this.marketingServices.btndeleteforapproveExpense(this.model)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                        // this.expenselst.splice(index, 1);
                    }
                });
        }
    }
    onChangeQuantity(id) {
        // this.model.amnt = 0;
        this.model.qty = 0;
        if (this.expenselst[id].qty != null && this.expenselst[id].qty > 0) {
            // if (this.expenselst[id].balance != null) {
            //     this.expenselst[id].balance = this.expenselst[id].balance - this.expenselst[id].amount
            //     this.expenselst[id].balance = Math.round(this.expenselst[id].balance);
            // }
            // else {
            //     this.expenselst[id].balance = this.expenselst[id].qty - this.expenselst[id].amount
            //     this.expenselst[id].balance = Math.round(this.expenselst[id].balance);
            // }

            this.expenselst[id].finalamount = this.expenselst[id].qty * this.expenselst[id].rate
            this.expenselst[id].finalamount = Math.round(this.expenselst[id].finalamount)
        }
    }
    onChangedisbursedamt(i) {
        if (this.expenselst[i].disbursedamount > this.expenselst[i].approvedamount - this.expenselst[i].priviousdisburseamt) {
            this.expenselst[i].disbursedamount = 0;
            this.notifyService.showRroor("Disbursedamount is Not greater than Approvedamount", "Oops!");
            return;

        }
    }
    Onucheckselect(i) {
        this.expenselst[i].amount = 0;
        this.expenselst[i].finalamount = 0;
        this.onChangeQuantity(i);
    }
}