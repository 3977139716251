import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location, getNumberOfCurrencyDigits, DatePipe } from '@angular/common';
import { AlertService, AuthenticationService, MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { CallCenterDashboardModel } from './../CallCenterDashboard/CallCenterDashboardModel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { QuotReportModel } from '../Quotation/QuotationModel';
import { ReportServices } from 'src/app/_services/ReportServices';
import convertor from 'rupees-to-words';
import { BsModalRef, BsModalService } from "ngx-bootstrap";


@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'ServiceCallDashboard.html'
})

export class ServiceCallDashboard implements OnInit {
    p: any;
    model: any = {};
    user = new User();
    singleGst: number;
    rateWithGst: number;
    // calllst: CallCenterDashboardModel[] = [];
    // subacclst:CallCenterDashboardModel[] = [];
    // callcenlst:CallCenterDashboardModel[] = [];
    modelst: CallCenterDashboardModel[] = [];
    lstspare: CallCenterDashboardModel[] = [];
    lstspare1: CallCenterDashboardModel[] = [];
    lstspareivoice: CallCenterDashboardModel[] = [];

    modelst1: CallCenterDashboardModel[] = [];

    lstproduct: CallCenterDashboardModel[] = [];

    deviceInfo = null;
    isMobile = null;
    mainmenus: Mainmenumodel[] = [];
    username: string;
    sum: number = 1;
    quoReport = new CallCenterDashboardModel();
    qutReportlst: CallCenterDashboardModel[];
    reporttandclst: CallCenterDashboardModel[];
    modalRef: BsModalRef;
    constructor(private Masterservice: MasterServices,
        private datePipe: DatePipe,
        private alertService: AlertService,
        private transactionservice: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private location: Location,
        private deviceService: DeviceDetectorService,
        private authenticationService: AuthenticationService,
        private reportServices: ReportServices,
        private modalService: BsModalService,) {
    }
    searchtype: DropDownModel[] = [
        { id: 0, name: "All" },
        { id: 1, name: "Service Call Number" },
        { id: 2, name: "Customer Name" },
        { id: 3, name: "Mobile Number" },
        { id: 4, name: "Resource Name" },
    ]
    ngOnInit(): void {

        this.mainmenus = JSON.parse(localStorage.getItem('menu'));
        this.username = localStorage.getItem('fullname');
        console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        // console.log(this.deviceInfo);
        // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        // console.log(isDesktopDevice);

        this.lstproduct = []
        this.getservicecalldata();

    }
    getservicecalldata() {
        this.user.userid = Number(localStorage.getItem("userid"));
        this.user.pageno = this.sum;
        this.transactionservice.getservicecalldata(this.user)
            .subscribe(value => {
                this.modelst = value
                for (let i = 0; i < this.modelst.length; i++) {
                    this.user.totalItems = this.modelst[0].totalItems;
                }
            });
    }
    btngetdetails(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id
            }
        };
        this.router.navigate(['/ServiceCall'], navigationExtras);
    }
    btnAssignEnquiry(id, accountid) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,

            }
        };
        this.router.navigate(['/ReassEnquiry'], navigationExtras);
    }
    btnview(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "forflag": "view"
            }
        };
        this.router.navigate(['/ServiceCall'], navigationExtras);
    }
    redirectUser(mainmenulabel: string) {
        this.router.navigate(['/' + mainmenulabel]);
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    onSelectSrch(val) {
        this.model.txtselect = val;
    }
    btnsearch() {
        if (this.model.txtselect == "All") {
            // this.getLocalities(0, 10);
            this.getservicecalldata();
            this.model.txtserch = null;
        } else {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                this.notifyService.showRroor("Enter Criteria To Search", "Oops");
                return;
            }
            this.user.message = this.model.txtselect
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;

            this.transactionservice.getServiceCallLike(this.user)
                .subscribe(value => {
                    this.modelst = value;
                    this.modelst1 = value
                    for (let i = 0; i < this.modelst.length; i++) {
                        this.user.totalItems = this.modelst[0].totalItems;
                    }
                });
        }
    }
    showPageIndex(pageIndex) {
        this.sum = pageIndex;
        this.user.pageno = this.sum;
        // this.user.pageSize = this.opportunitieslst[this.opportunitieslst.length - 1].id + 9;
        // this.getservicecalldata();
        if(this.model.txtserch == null)
        {
            this.getservicecalldata();
        }
        else{
            this.user.message = this.model.txtselect
            this.user.sendstring = this.model.txtserch
            this.transactionservice.getServiceCallLike(this.user)
            .subscribe(value => {
                this.modelst = value;
                this.modelst1 = value
                for (let i = 0; i < this.modelst.length; i++) {
                    this.user.totalItems = this.modelst[0].totalItems;
                }
            });
    
        }
    }
    btnprevious() {
        this.sum = this.sum - 1;
        this.getservicecalldata();
    }
    btnnext() {
        this.sum = this.sum + 1;
        this.getservicecalldata();
    }
    last() {
        this.sum = Math.round(this.user.totalItems / 10);
        this.getservicecalldata();
    }
    first() {
        this.sum = 1;
        this.getservicecalldata();
    }
    btnprint(QuotationReport: TemplateRef<any>, id) {
        this.quoReport = null;
        let user = new User();
        user.trnid = id;
        this.transactionservice.getServiceQuotationReport(user)
            .subscribe(
                data => {

                    // this.quoReport = data
                    this.lstspare1 = data;
                    // this.singleGst = 0;
                    // this.rateWithGst = 0;
                    for (let i = 0; i < this.lstspare1.length; i++) {
                        this.lstspare = this.lstspare1[i].lstspare;
                    }
                    for (let i = 0; i < this.lstspare1.length; i++) {

                        this.model.companyname = this.lstspare1[i].companyname;
                        this.model.companyaddress = this.lstspare1[i].companyaddress;
                        this.model.name = this.lstspare1[i].name;
                        this.model.servicecallno = this.lstspare1[i].servicecallno;
                        this.model.contactname = this.lstspare1[i].cname;
                        this.model.callBookDate = this.lstspare1[i].callBookDate

                    }
                    for (let i = 0; i < this.lstspare1.length; i++) {
                        this.model.iname = this.lstspare1[i].iname;
                        this.lstspareivoice = this.lstspare1[i].lstspareivoice;
                        for (let j = 0; j < this.lstspareivoice.length; j++) {
                            this.model.invoicenumber = this.lstspareivoice[j].invoicenumber;
                            this.model.datetime = this.lstspareivoice[j].datetime
                            this.model.address = this.lstspareivoice[j].address
                            this.lstspareivoice[j].tempVar = this.lstspareivoice[j].rate * this.lstspareivoice[j].gstpersentage / 100;
                            this.lstspareivoice[j].rate =  Math.round(this.lstspareivoice[j].rate + this.lstspareivoice[j].tempVar);
                        }

                    }


                    // this.reporttandclst = data.reporttandclst;

                    this.model.totalamount = 0;
                    this.model.totalnetamount = 0;
                    this.model.totaltaxamount = 0;
                    this.model.discountAmount = 0;
                    for (let i = 0; i < this.lstspare1.length; i++) {

                        this.lstspare = this.lstspare1[i].lstspare;
                        for (let j = 0; j < this.lstspare.length; j++) {
                            this.model.totaltaxamount = this.model.totaltaxamount + this.lstspare[j].gstamount
                            // this.model.totalnetamount = this.model.totalnetamount + this.lstspare[].netamount;
                            this.lstspare[j].tempVar = this.lstspare[j].rate * this.lstspare[j].gstpersentage / 100;
                            this.lstspare[j].rate =  Math.round(this.lstspare[j].rate + this.lstspare[j].tempVar);
                          
                            this.model.totalamount = this.model.totalamount + this.lstspare[j].amount;
                            this.model.discountAmount = this.model.discountAmount + this.lstspare[j].discountAmount
                            this.model.totalinwords = convertor(this.model.totalamount.toFixed(2));
                        }

                    }

                    // for(let i = 0 ;i<this.lstspare1.length ;i++)
                    // {
                    //     this.lstspareivoice = this.lstspare1[i].lstspareivoice;
                    //     for(let j = 0; j < this.lstspareivoice.length; j++)
                    //     {
                    //         this.singleGst = this.lstspareivoice[j].rate * this.lstspareivoice[j].gstpersentage / 100; //newadd
                    //         alert(this.singleGst);
                    //         this.lstspareivoice[j].rate = this.lstspareivoice[j].rate + this.singleGst; //newadd
                    //     }
                    // }
                });

        this.modalRef = this.modalService.show(QuotationReport, {
            class: 'modal-lg'
        });
    }
    btnprintMobile(id) {
        this.quoReport = null;
        let user = new User();
        user.trnid = id;
        this.transactionservice.getServiceQuotationReport(user)
            .subscribe(
                data => {

                    // this.quoReport = data
                    this.lstspare1 = data;
                    for (let i = 0; i < this.lstspare1.length; i++) {
                        this.lstspare = this.lstspare1[i].lstspare;
                    }
                    for (let i = 0; i < this.lstspare1.length; i++) {

                        this.model.companyname = this.lstspare1[i].companyname;
                        this.model.companyaddress = this.lstspare1[i].companyaddress;
                        this.model.name = this.lstspare1[i].name;
                    }
                    for (let i = 0; i < this.lstspare1.length; i++) {
                        this.model.iname = this.lstspare1[i].iname;
                        this.lstspareivoice = this.lstspare1[i].lstspareivoice;
                        for (let j = 0; j < this.lstspareivoice.length; j++) {
                            this.model.invoicenumber = this.lstspareivoice[j].invoicenumber;
                            this.model.datetime = this.lstspareivoice[j].datetime
                            this.model.address = this.lstspareivoice[j].address
                        }

                    }
                    // this.reporttandclst = data.reporttandclst;

                    this.model.totalamount = 0;
                    this.model.totalnetamount = 0;
                    this.model.totaltaxamount = 0;
                    this.model.discountAmount = 0;
                    for (let i = 0; i < this.lstspare1.length; i++) {

                        this.lstspare = this.lstspare1[i].lstspare;
                        for (let j = 0; j < this.lstspare.length; j++) {
                            this.model.totaltaxamount = this.model.totaltaxamount + this.lstspare[j].gstamount
                            // this.model.totalnetamount = this.model.totalnetamount + this.lstspare[].netamount;
                            this.model.totalamount = this.model.totalamount + this.lstspare[j].amount;
                            // this.quoReport.totalinwords = converter.toWords(this.quoReport.totalamount);
                            this.model.discountAmount = this.model.discountAmount + this.lstspare[j].discountAmount
                            this.model.totalinwords = convertor(this.model.totalamount.toFixed(2));
                        }

                    }
                });

    }
    openPrint() {
        var divToPrint = document.getElementById('inv');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    openPrint1() {
        var divToPrint = document.getElementById('invoice');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
}