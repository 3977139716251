import { OnInit, Component, TemplateRef } from '@angular/core';
import { DispatchOrderModel, InvoiceProducts, serialProducts } from './DispatchOrderModel';
import { User } from 'src/app/_models/user';
import { MasterServices, TransactionServices, AuthenticationService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { DefineOrderAcceptanceModel } from '../OrderAcceptance/DefineOrderAcceptanceModel';
import { ProspectModel } from '../Sales-Transaction/ProspectModel';
import { DispatchOrderHomeModel } from './DispatchOrderHomeModel';
var converter = require('number-to-words');
import convertor from 'rupees-to-words';
import { data } from 'jquery';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { element } from 'protractor';
import { DeviceDetectorService } from 'ngx-device-detector';
declare var $: any;
@Component({
    selector: 'app-DispatchOrder',
    templateUrl: 'DispatchOrder.html',
})
export class DispatchOrder implements OnInit {
    constructor(private masterservice: MasterServices,
        private transactionService: TransactionServices,
        private modalService: BsModalService,
        private transactionservice: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private authenticationService: AuthenticationService) {
    }
    id: number;
    model = new DispatchOrderModel();
    model1 = new DispatchOrderModel();
    orderacc = new DefineOrderAcceptanceModel();
    pomodel = new ProspectModel();
    user = new User();
    modalRef: BsModalRef;
    modalRef2: BsModalRef;
    modalRef3: BsModalRef;
    invoiceProducts: InvoiceProducts[] = []
    serialProducts: serialProducts[] = [];
    serialProductsave: serialProducts[] = [];
    inventorylocations: DropDownModel[] = [];
    invFromlocations: InvoiceProducts[] = [];
    invTolocations: InvoiceProducts[] = [];
    lstlocality: DropDownModel[] = [];
    localitymap = new Map();
    userList2: any;
    pincodelist = [];
    keyword = 'name';
    totalnetamount: number;
    totalgst: number;
    grandtotal: number;
    quantity: number;
    serialNumber: number;
    grandtotalinwords: string;
    lastkeydown2: number = 0;
    orderlist: DispatchOrderHomeModel[] = [];
    retail: boolean;
    QuotationID: number;
    proid: number;
    pid: number;
    showBtn: boolean = false;
    isMobile: boolean;
    deviceInfo = null;
    username: string;
    keyword4 = 'name';
    supplierName = [];
    tempName: string;

    ngOnInit(): void {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();

        this.model.userid = Number(localStorage.getItem("userid"));
        this.username = localStorage.getItem('fullname');
        this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.transactionservice.getOrderByidForInvoice(user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.userid = Number(localStorage.getItem("userid"));
                        this.invoiceProducts = data.invoiceProducts;
                        // this.serialProducts = data.serialProducts;
                        // this.model.pincode = data.pincode;
                        // this.model.unitno = data.unitno;
                        // this.model.buildingName = data.buildingName;
                        // this.model.city = data.city;

                        if (this.invoiceProducts != null) {
                            this.model.chkall = true;
                        }
                        this.onChangeQuantity();
                    });
        }
        this.getInventoryLocations(); //old
        this.getInventoryFromLocations();
    }

    getInventoryLocations() {   //old
        let user = new User();
        user.sendstring = "D";
        this.masterservice.getAllInventoryLocations(user)
            .subscribe(
                data => {
                    this.inventorylocations = data
                })
    }

    btnselectProduct($event, id) {
        for (let i = 0; i < this.invoiceProducts.length; i++) {
            if (this.invoiceProducts[i].id == id) {
                if ($event.target.checked == true) {
                    this.invoiceProducts[i].chkselectpc = true;
                } else {
                    this.invoiceProducts[i].chkselectpc = false;
                }
            }
        }
        this.onChangeQuantity();
    }

    btnselectAllProduct($event) {
        for (let i = 0; i < this.invoiceProducts.length; i++) {
            if ($event.target.checked) {
                this.invoiceProducts[i].chkselectpc = true;
            } else {
                this.invoiceProducts[i].chkselectpc = false;
            }
        }
        this.onChangeQuantity();
    }

    onChangeQuantity() {
        this.totalnetamount = 0;
        this.totalgst = 0;
        this.grandtotal = 0;
        this.model.totalgst = 0;
        this.model.netamount = 0;
        this.model.grandtotal = 0;
        for (let i = 0; i < this.invoiceProducts.length; i++) {
            if (this.invoiceProducts[i].chkselectpc == true && this.invoiceProducts[i].ratewithdiscount == null) {
                let tax1 = (this.invoiceProducts[i].offeredPrice * this.invoiceProducts[i].taxpercent) / 100;
                if (this.invoiceProducts[i].discount != null && this.invoiceProducts[i].discount > 0) {
                    this.invoiceProducts[i].discountAmount = this.invoiceProducts[i].listprice * this.invoiceProducts[i].discount * this.invoiceProducts[i].supplyquantity / 100;
                }
                this.invoiceProducts[i].taxamount = (tax1 * this.invoiceProducts[i].supplyquantity)
                this.invoiceProducts[i].netamount = (this.invoiceProducts[i].offeredPrice * this.invoiceProducts[i].supplyquantity)
                this.invoiceProducts[i].totalproamount = (this.invoiceProducts[i].offeredPrice * this.invoiceProducts[i].supplyquantity) + this.invoiceProducts[i].taxamount;
                this.invoiceProducts[i].totalproamount = Math.round(this.invoiceProducts[i].totalproamount)
                this.totalgst = this.totalgst + this.invoiceProducts[i].taxamount
                this.totalnetamount = this.totalnetamount + this.invoiceProducts[i].netamount
                this.grandtotal = this.grandtotal + this.invoiceProducts[i].totalproamount;
                this.grandtotal = Math.round(this.grandtotal)
                // this.grandtotalinwords = converter.toWords(this.grandtotal)

                // this.grandtotalinwords = convertor(this.grandtotal.toFixed(2));
                this.grandtotalinwords = convertor(Math.round(this.grandtotal).toFixed(2));
                this.model.totalgst = this.totalgst
                this.model.netamount = this.totalnetamount;
                this.model.grandtotal = this.grandtotal;
            }
            else if (this.invoiceProducts[i].chkselectpc == true && this.invoiceProducts[i].ratewithdiscount != null) {
                let tax1 = (this.invoiceProducts[i].ratewithdiscount * this.invoiceProducts[i].taxpercent) / 100;
                if (this.invoiceProducts[i].discount != null && this.invoiceProducts[i].discount > 0) {
                    // this.invoiceProducts[i].discountAmount = this.invoiceProducts[i].ratewithdiscount * this.invoiceProducts[i].discount * this.invoiceProducts[i].supplyquantity / 100;
                    this.invoiceProducts[i].totalproamount = (this.invoiceProducts[i].listprice * this.invoiceProducts[i].orderquantity) + this.invoiceProducts[i].taxamount;
                    this.invoiceProducts[i].totalproamount = Math.round(this.invoiceProducts[i].totalproamount)
                    this.invoiceProducts[i].discountAmount = this.invoiceProducts[i].totalproamount * this.invoiceProducts[i].discount / 100;
                    //this.invoiceProducts[i].discountAmount = Math.round(this.invoiceProducts[i].discountAmount)

                }
                this.invoiceProducts[i].taxamount = (tax1 * this.invoiceProducts[i].supplyquantity)
                this.invoiceProducts[i].netamount = (this.invoiceProducts[i].ratewithdiscount * this.invoiceProducts[i].supplyquantity)
                this.invoiceProducts[i].totalproamount = (this.invoiceProducts[i].ratewithdiscount * this.invoiceProducts[i].supplyquantity) + this.invoiceProducts[i].taxamount;
                this.invoiceProducts[i].totalproamount = Math.round(this.invoiceProducts[i].totalproamount)
                this.totalgst = this.totalgst + this.invoiceProducts[i].taxamount
                this.totalnetamount = this.totalnetamount + this.invoiceProducts[i].netamount
                this.grandtotal = this.grandtotal + this.invoiceProducts[i].totalproamount;
                this.grandtotal = Math.round(this.grandtotal)
                // this.grandtotalinwords = converter.toWords(this.grandtotal)
                this.grandtotalinwords = convertor(Math.round(this.grandtotal).toFixed(2));

                this.model.totalgst = this.totalgst
                this.model.netamount = this.totalnetamount;
                this.model.grandtotal = this.grandtotal;
            }

            if (this.invoiceProducts[i].chkselectpc == true && this.model.invIntegration) {
                this.invoiceProducts[i].newissueLocStock = this.invoiceProducts[i].availablestock - this.invoiceProducts[i].supplyquantity;
                // this.invoiceProducts[i].newSupplyLocStock = this.invoiceProducts[i].availablestock - this.invoiceProducts[i].supplyquantity;

            }
        }
    }

    // -----------------------For Scheme code------------------
    // onChangeQuantity() {
    //     this.totalnetamount = 0;
    //     this.totalgst = 0;
    //     this.grandtotal = 0;
    //     this.model.totalgst = 0;
    //     this.model.netamount = 0;
    //     this.model.grandtotal = 0;
    //     for (let i = 0; i < this.invoiceProducts.length; i++) {
    //         if (this.invoiceProducts[i].chkselectpc == true) {
    // let tax1 = (this.invoiceProducts[i].offeredPrice * this.invoiceProducts[i].taxpercent) / 100;
    // if (this.invoiceProducts[i].discount != null && this.invoiceProducts[i].discount > 0) {
    //     this.invoiceProducts[i].discountAmount = this.invoiceProducts[i].listprice * this.invoiceProducts[i].discount * this.invoiceProducts[i].supplyquantity / 100;
    // }
    //this.invoiceProducts[i].taxamount = (tax1 * this.invoiceProducts[i].supplyquantity)
    //this.invoiceProducts[i].netamount = (this.invoiceProducts[i].offeredPrice * this.invoiceProducts[i].supplyquantity)
    //this.invoiceProducts[i].totalproamount = (this.invoiceProducts[i].offeredPrice * this.invoiceProducts[i].supplyquantity) + this.invoiceProducts[i].taxamount;
    //             this.invoiceProducts[i].netamount = (this.invoiceProducts[i].offeredPrice * this.invoiceProducts[i].supplyquantity)//
    //             this.invoiceProducts[i].subtotalproamount = (this.invoiceProducts[i].totalproamount / this.invoiceProducts[i].supplyquantity);
    //             this.invoiceProducts[i].totalproamount = (this.invoiceProducts[i].subtotalproamount * this.invoiceProducts[i].supplyquantity);
    //             alert(this.invoiceProducts[i].totalproamount);
    //             // this.totalgst = this.totalgst + this.invoiceProducts[i].taxamount
    //             this.totalnetamount = this.totalnetamount + this.invoiceProducts[i].netamount
    //             this.grandtotal = this.grandtotal + this.invoiceProducts[i].totalproamount;
    //             this.grandtotalinwords = convertor(this.grandtotal.toFixed(2));

    //             this.model.totalgst = this.totalgst
    //             this.model.netamount = this.totalnetamount;
    //             this.model.grandtotal = this.grandtotal;
    //         }
    //     }
    // }

    onSelectLocation(val) {
        this.model.inventorylocationid = val
    }

    getUserIdsSecondtWay($event) {
        let userId = (<HTMLInputElement>document.getElementById('dynamicUserIdsSecondWay')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.trnid = this.model.accid;
        console.log('VALU ' + userId);
        var obj = this;
        if (userId.length > 3) {
            if ($event.timeStamp - this.lastkeydown2 > 200) {
                this.transactionservice.getSuppliersLike(this.user)
                    .subscribe(data => {
                        data.forEach((item) => this.userList2.push({ "text": item.name, "val": item.id }));
                        let map = new Map();
                        var names = "";
                        for (let i = 0; i < this.userList2.length; i++) {
                            map.set(this.userList2[i].text, this.userList2[i].val)
                            searchDataObj[this.userList2[i].text] = null;
                            if (i == 0) {
                                names = this.userList2[i].text;
                            } else {
                                names = names + "," + this.userList2[i].text;
                            }
                        }
                        let customername;
                        $(document).ready(function () {
                            $('input.typeahead').autocomplete({
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect1(map.get(txt), txt);
                                },
                            });
                            $('input.autocomplete').autocomplete({
                                data: searchDataObj,
                                onAutocomplete: function (txt) {

                                    obj.typeaheadOnSelect1(map.get(txt), txt);
                                },
                            });
                        });
                    },
                        () => {
                            alert("fail");
                        });
            }
        }
    }

    typeaheadOnSelect1(val, val2) {
        this.model.supplierId = val;
        this.model.supplierName = val2;
        let user = new User();
        user.trnid = this.model.supplierId
        this.transactionservice.getAccountDetails(user)
            .subscribe(
                data => {
                    this.model.gstinno = data.gstinno
                    this.model.gsttype = data.gsttype;
                    this.model.supplierAddrs = data.address
                });
    }

    btnChnageSupplier(SupplierModal: TemplateRef<any>) {
        this.modalRef3 = this.modalService.show(SupplierModal, {
            class: 'modal-lg'
        });
    }

    btnRemoveSupplier() {
        this.model.supplierId = null;
        this.model.supplierName = null;
        this.model.supplierAddrs = null;
        this.model.gstinno = null;
        this.model.gsttype = null;
    }

    btnchangeaddress(shippingmodel: TemplateRef<any>) {
        this.orderacc.accountid = this.model.accid
        this.transactionservice.getaddressbycustomer(this.orderacc)
            .subscribe(
                data => {
                    this.orderacc.addresslst = data.addresslst
                    if (this.orderacc.accountid != null) {
                        for (let i = 0; i < this.orderacc.addresslst.length; i++) {
                            if (this.orderacc.addresslst[i].accountid == this.orderacc.accountid) {
                                this.orderacc.addresslst[i].cheselect = true;
                            }
                        }
                    }
                });
        this.modalRef = this.modalService.show(shippingmodel, {
            class: 'modal-lg'
        });

    }
    getPincodeLike($event) {
        let userId = (<HTMLInputElement>document.getElementById('pincode1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getpincodelike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
        if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
            this.lstlocality = [];
        }
    }
    typeaheadOnSelect(id, name) {
        this.pomodel.pincode = name;
        this.model.pincode = name;
        this.lstlocality = [];
        this.transactionService.getlocality(this.pomodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocality.push({ id: item.id, name: item.name });
                    this.localitymap.set(item.id, item.name)
                });
            });
    }

    onSelectLocality(id) {
        this.model.localityid = id;
        this.pomodel.localityID = id;
        this.model.locality = this.localitymap.get(id);

        this.transactionService.getgeographydetails(this.pomodel)
            .subscribe(data => {
                this.model.locality = data.locality;
            });
    }

    onchkwihitins($event) {
        this.model.gsttype = "InS";

    }
    onchkoutsides($event) {
        this.model.gsttype = "OutS";
    }

    btnConsigneeModal(ConsigneeModal: TemplateRef<any>) {

        this.modalRef = this.modalService.show(ConsigneeModal, {
            class: 'modal-lg'
        });
    }

    btnsaveConsignee() {
        this.model.address = this.model.caddress + ", " + this.model.pincode
        this.modalRef.hide()
    }

    onselectaddress(value, $event) {
        for (let i = 0; i < this.orderacc.modellst.length; i++) {
            if (this.orderacc.addresslst[i].accountid == value) {
                this.orderacc.addresslst[i].cheselect = true;
                this.orderacc.accountid = value;
            } else {
                this.orderacc.addresslst[i].cheselect = false;
            }
        }
    }

    btnsaveaddress() {
        for (let i = 0; i < this.orderacc.modellst.length; i++) {
            if (this.orderacc.addresslst[i].cheselect == true) {
                this.model.address = this.orderacc.addresslst[i].address;
                if (this.model.address != "" && this.model.address != null) {
                    this.notifyService.showSuccess("Address Changed Successfully", "Done");
                }
            }
        }
        this.modalRef.hide();
    }

    btnback() {
        this.router.navigate(['/Despatch Order'])
    }

    confirm(Invoice: TemplateRef<any>) {
        // if (this.model.inventorylocationid == null) {
        //     this.notifyService.showRroor("Please select inventory location", " Oops");
        //     return;
        // }
        if (this.model.invIntegration == true && this.model.invFromlocationid == null) {
            this.notifyService.showRroor("Please select Iussue Location", " Oops");
            return;
        }
        if (this.model.invIntegration == true && this.model.invTolocationid == null) {
            this.notifyService.showRroor("Please select Supply Location", " Oops");
            return;
        }
        if (this.model.invIntegration == true) {
            this.invoiceProducts.forEach(element => {
                if (element.chkselectpc == true && element.availablestock < element.supplyquantity || element.chkselectpc == true && element.availablestock == null) {

                    this.notifyService.showRroor("Available Stock is Insufficient !!", " Oops");
                    return;
                }
                // else {
                //     this.modalRef2 = this.modalService.show(Invoice, {
                //         class: 'modal-lg'
                //     });
                // }
            });

        }


        this.modalRef2 = this.modalService.show(Invoice, {
            class: 'modal-lg'
        });

    }

    btnsaveDespatch() {
       
        this.model.invoiceProducts = this.invoiceProducts;
        // for(let i = 0;i<this.invoiceProducts.length;i++)
        // {
        //     this.model.invoiceProducts.serialProducts = this.serialProducts;
        // }

        this.model.serialProducts = this.serialProductsave;
        this.showBtn = true;
        this.transactionservice.saveDispatchOrder(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                        this.modalRef2.hide()
                        this.router.navigate(['/Despatch Order'])
                    }
                });
    }

    btnAddSupplier() {
        this.model
        this.modalRef3.hide()
    }
    selectEvent(item) {
        this.pomodel.pincode = item.name;
        this.model.pincode = item.name;
        this.lstlocality = [];

        this.transactionService.getlocality(this.pomodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocality.push({ id: item.id, name: item.name });
                    this.localitymap.set(item.id, item.name)
                });
            });
    }
    onChangeSearch(search: string) {
        this.user.message = search;
        this.transactionService.getpincodelike(this.user)
            .subscribe(data => {
                this.pincodelist = [];
                data.forEach((item) => this.pincodelist.push({ "id": item.id, "name": item.name }));

            });
    }

    onFocused(e) {
        // do something
    }

    openmodel(coupenmodel: TemplateRef<any>, id) {
        // alert(id);
        this.modalRef = this.modalService.show(coupenmodel, {
            class: 'modal-lg'
        });
        for (let i = 0; i < this.invoiceProducts.length; i++) {
            if (this.invoiceProducts[i].id == id) {
                this.model.name = this.invoiceProducts[i].name;
                this.serialProducts = [];
                this.quantity = this.invoiceProducts[i].supplyquantity
                this.proid = this.invoiceProducts[i].id
                this.pid = this.invoiceProducts[i].pid;
                let j = 1;
                for (let i = 0; i < this.quantity; i++) {
                    this.serialProducts.push({
                        srno: j,
                        serialno: null,
                        proid: this.proid,
                        pid: this.pid,
                    });
                    j++;
                }
                this.model.serialProducts = this.serialProducts;
            }
        }

    }
    btnsaveserial() {
        // this.serialProducts = [];
        this.serialNumber = this.model.serialProducts.length;
        for (let i = 0; i < this.serialNumber; i++) {
            this.serialProductsave.push(
                {
                    srno: this.model.serialProducts[i].srno,
                    serialno: this.model.serialProducts[i].serialno,
                    proid: this.model.serialProducts[i].proid,
                    pid: this.model.serialProducts[i].pid,

                });
        }

        this.modalRef.hide()
        // this.router.navigate(['/editDispatch/:id'])

    }
    getserialnumberexist(sno, pid) {
        // this.serialProducts = [];
        // alert(sno);
        this.model.serialno = sno;
        this.model.pid = pid;
        this.model.proid = this.model.pid;
        if (this.model.serialno.length >= 6) {
            this.transactionService.getserialexist(this.model)
                .subscribe(data => {
                    if (data.length > 0) {
                        data.forEach(item => {
                            this.model.serialno = item.serialno;
                            this.notifyService.showRroor("Serial Number is present", "Opps");
                        });
                    }
                })
            //this.transactionService.getcustomerexiste(this.model)
        }
    }
    getInventoryFromLocations() {
        let user = new User();
        this.user.userid = this.model.userid;
        this.transactionService.getInventoryFromLocations(this.user)
            .subscribe(
                data => {
                    this.invFromlocations = data.invFromlocations
                });
    }
    onSelectInventoryLocationFrom(val) {
        this.model.invFromlocationid = val;
        this.user.userid = this.model.userid;
        this.user.locationID = this.model.invFromlocationid;
        this.transactionService.getInventoryToLocations(this.user)
            .subscribe(
                data => {
                    this.invTolocations = data.invTolocations
                });

        if (this.id != null && this.id > 0) {
            let user = new User();
            this.user.trnid = this.id;
            this.user.areaid = this.model.invFromlocationid;
            this.transactionservice.getOrderByidForInvoice(this.user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.userid = Number(localStorage.getItem("userid"));
                        this.invoiceProducts = data.invoiceProducts
                        if (this.invoiceProducts != null) {
                            this.model.chkall = true;
                        }
                        this.onChangeQuantity();
                    });
        }
    }
    onSelectInventoryToLocation(val) {
        this.model.invTolocationid = val;
    }

    btnprintMobile(id) {
        // if (this.model.inventorylocationid == null) {
        //     this.notifyService.showRroor("Please select inventory location", " Oops");
        //     return;
        // }
        if (this.model.invIntegration == true && this.model.invFromlocationid == null) {
            this.notifyService.showRroor("Please select Iussue Location", " Oops");
            return;
        }
        if (this.model.invIntegration == true && this.model.invTolocationid == null) {
            this.notifyService.showRroor("Please select Supply Location", " Oops");
            return;
        }
        if (this.model.invIntegration == true) {
            this.invoiceProducts.forEach(element => {
                if (element.chkselectpc == true && element.availablestock < element.supplyquantity || element.chkselectpc == true && element.availablestock == null) {

                    this.notifyService.showRroor("Available Stock is Insufficient !!", " Oops");
                    return;
                }
                // else {
                //     this.modalRef2 = this.modalService.show(Invoice, {
                //         class: 'modal-lg'
                //     });
                // }
            });

        }
    }

    redirectUser(mainmenulabel: string) {
        this.router.navigate(['/' + mainmenulabel]);
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    onChangeSearch4(search: string) {
        this.user.message = search;
        this.user.trnid = this.model.accid;

        this.transactionservice.getSuppliersLike(this.user)
            .subscribe(data => {
                this.supplierName = [];
                data.forEach((item) => this.supplierName.push({ id: item.id, name: item.name })
                );
            });

    }
    selectEvent4(item) {
        this.model.supplierId = item.id;
        // this.model.supplierName = item.name;
        this.model.supplierName = item.name;
        let user = new User();
        user.trnid = this.model.supplierId
        this.transactionservice.getAccountDetails(user)
            .subscribe(
                data => {
                    this.model.supplierName = item.name;
                    this.model.gstinno = data.gstinno
                    this.model.gsttype = data.gsttype;
                    this.model.supplierAddrs = data.address
                });
    }
    onFocused4(e) {
        // do something
    }
    openserialNumber(id) {
        for (let i = 0; i < this.invoiceProducts.length; i++) {
            if (this.invoiceProducts[i].id == id) {
                this.model.name = this.invoiceProducts[i].name;
                this.serialProducts = [];
                this.quantity = this.invoiceProducts[i].supplyquantity
                this.proid = this.invoiceProducts[i].id
                this.pid = this.invoiceProducts[i].pid;
                let j = 1;
                for (let i = 0; i < this.quantity; i++) {
                    this.serialProducts.push({
                        srno: j,
                        serialno: null,
                        proid: this.proid,
                        pid: this.pid,
                    });
                    j++;
                }
                this.model.serialProducts = this.serialProducts;
            }
        }
    }

}