import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { AlertService, CompanyService, MasterServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchItemModel } from "./SearchItemModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'SearchItem.html'
})
export class SearchItem implements OnInit {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {
    }
    public i;
    user = new User();
    p: any;
    sub: any;
    id: number;
    pageno: string;
    model = new SearchItemModel();
    itemmasterlst: SearchItemModel[] = [];
    itemmasterlst1: SearchItemModel[] = [];
    public searchtype: DropDownModel[] = [
        // { id: 1, "name": "All" },
        { id: 1, "name": "Item Code" },
        { id: 2, "name": "Item Name" },
        { id: 3, "name": "Item Category" },
        { id: 4, "name": "Item Sub Category" },
        { id: 5, "name": "UOM" },
    ];

    ngOnInit(): void {
        this.getAllitem();
        this.pageno = localStorage.getItem("pageno");

    }
    getAllitem() {

        this.Masterservice.getAllitem(this.user)
            .subscribe(value => {
                this.itemmasterlst = value
                this.itemmasterlst1 = [];
                value.forEach((item) => this.itemmasterlst1.push({ "id": item.id, "itemcode": item.itemcode, "itemname": item.itemname, "itemcategory": item.itemcategory, "itemsubcategory": item.itemsubcategory, "inventoryunit": item.inventoryunit, "txtsearch": item.txtsearch, "txtselect": item.txtselect, "srno": item.srno }));
                this.itemmasterlst = this.itemmasterlst1;

            })
    }
    onSelectItem(value) {
        this.model.txtselect = value;
    }
    btnsearch($event) {
        // if (this.model.txtselect == "All") {
        //     this.getAllitem()
        //     this.model.txtsearch = null;
        // }
        // else 
        if (this.model.txtselect == "Item Code") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                // alert("Enter Item Code to Search")
                // return;
                this.getAllitem();
            }
            else {
                this.itemmasterlst1 = [];
                this.itemmasterlst.forEach((item) => {
                    if ((item.itemcode.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemmasterlst1.push({ "id": item.id, "itemcode": item.itemcode, "itemname": item.itemname, "itemcategory": item.itemcategory, "itemsubcategory": item.itemsubcategory, "inventoryunit": item.inventoryunit, "txtsearch": item.txtsearch, "txtselect": item.txtselect, "srno": item.srno });
                    }
                });
                this.itemmasterlst = this.itemmasterlst1;
            }
        }
        else if (this.model.txtselect == "Item Name") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                // alert("Enter Item Name to Search")
                // return;
                this.getAllitem();
            }
            else {
                this.itemmasterlst1 = [];
                this.itemmasterlst.forEach((item) => {
                    if ((item.itemname.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemmasterlst1.push({ "id": item.id, "itemcode": item.itemcode, "itemname": item.itemname, "itemcategory": item.itemcategory, "itemsubcategory": item.itemsubcategory, "inventoryunit": item.inventoryunit, "txtsearch": item.txtsearch, "txtselect": item.txtselect, "srno": item.srno });
                    }
                });
                this.itemmasterlst = this.itemmasterlst1;
            }
        }
        else if (this.model.txtselect == "Item Category") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                // alert("Enter Item Category to Search")
                // return;
                this.getAllitem();
            }
            else {
                this.itemmasterlst1 = [];
                this.itemmasterlst.forEach((item) => {
                    if ((item.itemcategory.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemmasterlst1.push({ "id": item.id, "itemcode": item.itemcode, "itemname": item.itemname, "itemcategory": item.itemcategory, "itemsubcategory": item.itemsubcategory, "inventoryunit": item.inventoryunit, "txtsearch": item.txtsearch, "txtselect": item.txtselect, "srno": item.srno });
                    }
                });
                this.itemmasterlst = this.itemmasterlst1;
            }
        }
        else if (this.model.txtselect == "Item Sub Category") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                // alert("Enter Item Sub Category to Search")
                // return;
                this.getAllitem();
            }
            else {
                this.itemmasterlst1 = [];
                this.itemmasterlst.forEach((item) => {
                    if ((item.itemsubcategory.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemmasterlst1.push({ "id": item.id, "itemcode": item.itemcode, "itemname": item.itemname, "itemcategory": item.itemcategory, "itemsubcategory": item.itemsubcategory, "inventoryunit": item.inventoryunit, "txtsearch": item.txtsearch, "txtselect": item.txtselect, "srno": item.srno });
                    }
                });
                this.itemmasterlst = this.itemmasterlst1;
            }
        }
        else if (this.model.txtselect == "UOM") {
            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                // alert("Enter UOM To Search")
                // return;
                this.getAllitem();
            }
            else {

                this.itemmasterlst1 = [];
                this.itemmasterlst.forEach((item) => {
                    if ((item.inventoryunit.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.itemmasterlst1.push({ "id": item.id, "itemcode": item.itemcode, "itemname": item.itemname, "itemcategory": item.itemcategory, "itemsubcategory": item.itemsubcategory, "inventoryunit": item.inventoryunit, "txtsearch": item.txtsearch, "txtselect": item.txtselect, "srno": item.srno });
                    }
                });
                this.itemmasterlst = this.itemmasterlst1;
            }
        }
    }
    btnadd($event) {
        this.router.navigate(['/newDefineItem']);
    }
    btnedit(id) {
        this.router.navigate(['/editDefineItem', id]);
    }
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.Masterservice.dltItemsmaster(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }
    }
    showPageIndex(pageIndex) {
        this.pageno = pageIndex;
    }
}