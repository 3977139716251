import { OnInit, Component, TemplateRef } from '@angular/core';
import { AuthenticationService, MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
//import { OpportunitiesModel } from './OpportunitiesModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { QuotationModel, QuotReportModel } from '../Quotation/QuotationModel';
import { ReportServices } from 'src/app/_services/ReportServices';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import convertor from 'rupees-to-words';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-Quotationdashboard',
    templateUrl: 'Quotationdashboard.html',
})
export class Quotationdashboard implements OnInit {

    constructor(private masterservice: MasterServices, private transactionservice: TransactionServices, private route: ActivatedRoute,
        private notifyService: NotificationService, private router: Router, private reportServices: ReportServices, private modalService: BsModalService,
        private deviceService: DeviceDetectorService, private authenticationService: AuthenticationService) {
    }


    searchtype: DropDownModel[] = [
        { id: 0, name: "Choose One" },
        { id: 1, name: "All" },
        { id: 2, name: "Quotation Number" },
        { id: 3, name: "Customer Name" },
        { id: 4, name: "Enquiry Name" },
        { id: 5, name: "Owner" },
        { id: 6, name: "Stage" },
        { id: 7, name: "Decision" },
    ]
    model = new QuotationModel();
    user = new User();
    quotationdash: QuotationModel[] = [];
    quotationdash1: QuotationModel[] = [];
    stageslst: DropDownModel[] = []
    quoReport = new QuotReportModel();
    qutReportlst: QuotReportModel[];
    reporttandclst: QuotReportModel[];
    modalRef: BsModalRef;
    p: any;
    ngOnInit(): void {
        this.user.userid = Number(localStorage.getItem("userid"));
        if (this.user.userid != null) {
            this.getAllQuotationReport()
        }

        this.getAllStages();
    }


    getAllQuotationReport() {
        this.quotationdash = []
        this.transactionservice.getAllQuotationReport(this.user)
            .subscribe(
                data => {
                    this.quotationdash = data
                    this.quotationdash1 = data
                    // this.model.openchk = true;
                });
    }
    getAllStages() {
        this.masterservice.getAllStages(this.user)
            .subscribe(
                data => {
                    this.stageslst = data
                });
    }
    btnedit(enqid) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "enqid": enqid,
                "flag": "Approve",
            }
        };
        this.router.navigate(['/PrepareQuotation'], navigationExtras);
    }
    onSelectSrch(val) {
        this.model.txtselect = val;

    }
    btnprint(QuotationReport: TemplateRef<any>, enqid) {
        this.quoReport = null;
        let user = new User();
        user.trnid = enqid;
        this.reportServices.getQuotationReport(user)
            .subscribe(
                data => {

                    this.quoReport = data
                    this.qutReportlst = data.qutReportlst;
                    this.reporttandclst = data.reporttandclst;

                    this.quoReport.totalamount = 0;
                    this.quoReport.totalnetamount = 0;
                    this.quoReport.totaltaxamount = 0;
                    for (let i = 0; i < this.qutReportlst.length; i++) {
                        this.quoReport.totaltaxamount = this.quoReport.totaltaxamount + this.qutReportlst[i].taxamount;
                        this.quoReport.totalnetamount = this.quoReport.totalnetamount + this.qutReportlst[i].netamount;
                        this.quoReport.totalamount = this.quoReport.totalamount + this.qutReportlst[i].totalproamount;
                        // this.quoReport.totalinwords = converter.toWords(this.quoReport.totalamount);
                        this.quoReport.totalinwords = convertor(this.quoReport.totalamount.toFixed(2));
                    }
                });

        this.modalRef = this.modalService.show(QuotationReport, {
            class: 'modal-lg'
        });
    }
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllQuotationReport();
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Customer Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.quotationdash1 = [];
                this.quotationdash.forEach((item) => {
                    if (item.customerName != null) {
                        if ((item.customerName.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                            this.quotationdash1.push({
                                "id": item.id, "userid": item.userid, "connection": item.connection, "activityid": item.activityid, "actionTaken": item.actionTaken, "quotationid": item.quotationid, "quoCode": item.quoCode, "accid": item.accid, "accname": item.accname, "contactpersonid": item.contactpersonid, "ownerIdofOppor": item.ownerIdofOppor,
                                "txtserch": item.txtserch, "ownernameofOppor": item.ownernameofOppor, "address": item.address, "currencyName": item.currencyName, "enqId": item.enqId, "quoDate": item.quoDate, "status": item.status, "ctypeid": item.ctypeid, "ctype": item.ctype, "creditdays": item.creditdays, "quoStatus": item.quoStatus, "contactperson": item.contactperson, "quotationAmount": item.quotationAmount,
                                "installInstru": item.installInstru, "installionDate": item.installionDate, "txtselect": item.txtselect, "installionTime": item.installionTime, "nextstageid": item.nextstageid, "chkscheduleInstal": item.chkscheduleInstal, "contactlst": item.contactlst, "productEnquirylst": item.productEnquirylst, "productlst": item.productlst, "procatlst": item.procatlst, "tandclst": item.tandclst, "changetandclt": item.changetandclt, "salesTC": item.salesTC,
                                "paymentType": item.paymentType, "gstseperate": item.gstseperate, "orderaccp": item.orderaccp, "chkschemeflag": item.chkschemeflag, "sendquotation": item.sendquotation, "chkwon": item.chkwon, "chklost": item.chklost, "chkaband": item.chkaband, "decision": item.decision, "decisionDate": item.decisionDate, "lossreason": item.lossreason, "remarks": item.remarks, "approvedQuo": item.approvedQuo, "schmemCode": item.schmemCode,
                                "otp": item.otp, "otpverified": item.otpverified, "qhistorylst": item.qhistorylst, "qversionlst": item.qversionlst, "discountAmount": item.discountAmount, "tax": item.tax, "previousremarks": item.previousremarks, "lstproductintersted": item.lstproductintersted, "unitno": item.unitno, "buildingName": item.buildingName, "city": item.city, "pincode": item.pincode, "discount": item.discount,
                                "showOtp": item.showOtp, "Otpshow": item.Otpshow, "fulldiscount": item.fulldiscount, "schemetype": item.schemetype, "quoid": item.quoid, "customerName": item.customerName, "enquiryName": item.enquiryName, "quostage": item.quostage, "srno": item.srno, "installation": null,"dearflag":null,  lstspare:null, totalamount:null,total:null,totalgstamount:null,totalamountwords:null,customnumber:null
                            });
                        }
                    }
                });
                this.quotationdash = this.quotationdash1;

            }
        }
        else if (this.model.txtselect == "Enquiry Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.quotationdash1 = [];
                this.quotationdash.forEach((item) => {
                    if (item.enquiryName != null) {
                        if ((item.enquiryName.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                            this.quotationdash1.push({
                                "id": item.id, "userid": item.userid, "connection": item.connection, "activityid": item.activityid, "actionTaken": item.actionTaken, "quotationid": item.quotationid, "quoCode": item.quoCode, "accid": item.accid, "accname": item.accname, "contactpersonid": item.contactpersonid, "ownerIdofOppor": item.ownerIdofOppor,
                                "txtserch": item.txtserch, "ownernameofOppor": item.ownernameofOppor, "address": item.address, "currencyName": item.currencyName, "enqId": item.enqId, "quoDate": item.quoDate, "status": item.status, "ctypeid": item.ctypeid, "ctype": item.ctype, "creditdays": item.creditdays, "quoStatus": item.quoStatus, "contactperson": item.contactperson, "quotationAmount": item.quotationAmount,
                                "installInstru": item.installInstru, "installionDate": item.installionDate, "txtselect": item.txtselect, "installionTime": item.installionTime, "nextstageid": item.nextstageid, "chkscheduleInstal": item.chkscheduleInstal, "contactlst": item.contactlst, "productEnquirylst": item.productEnquirylst, "productlst": item.productlst, "procatlst": item.procatlst, "tandclst": item.tandclst, "changetandclt": item.changetandclt, "salesTC": item.salesTC,
                                "paymentType": item.paymentType, "gstseperate": item.gstseperate, "orderaccp": item.orderaccp, "chkschemeflag": item.chkschemeflag, "sendquotation": item.sendquotation, "chkwon": item.chkwon, "chklost": item.chklost, "chkaband": item.chkaband, "decision": item.decision, "decisionDate": item.decisionDate, "lossreason": item.lossreason, "remarks": item.remarks, "approvedQuo": item.approvedQuo, "schmemCode": item.schmemCode,
                                "otp": item.otp, "otpverified": item.otpverified, "qhistorylst": item.qhistorylst, "qversionlst": item.qversionlst, "discountAmount": item.discountAmount, "tax": item.tax, "previousremarks": item.previousremarks, "lstproductintersted": item.lstproductintersted, "unitno": item.unitno, "buildingName": item.buildingName, "city": item.city, "pincode": item.pincode, "discount": item.discount,
                                "showOtp": item.showOtp, "Otpshow": item.Otpshow, "fulldiscount": item.fulldiscount, "schemetype": item.schemetype, "quoid": item.quoid, "customerName": item.customerName, "enquiryName": item.enquiryName, "quostage": item.quostage, "srno": item.srno, "installation": null,"dearflag":null,  lstspare:null, totalamount:null,total:null,totalgstamount:null,totalamountwords:null,customnumber:null
                            });
                        }
                    }

                });
                this.quotationdash = this.quotationdash1;
            }
        }
        else if (this.model.txtselect == "Quotation Number") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.quotationdash1 = [];
                this.quotationdash.forEach((item) => {
                    if (item.quoCode != null) {
                        if ((item.quoCode.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                            this.quotationdash1.push({
                                "id": item.id, "userid": item.userid, "connection": item.connection, "activityid": item.activityid, "actionTaken": item.actionTaken, "quotationid": item.quotationid, "quoCode": item.quoCode, "accid": item.accid, "accname": item.accname, "contactpersonid": item.contactpersonid, "ownerIdofOppor": item.ownerIdofOppor,
                                "txtserch": item.txtserch, "ownernameofOppor": item.ownernameofOppor, "address": item.address, "currencyName": item.currencyName, "enqId": item.enqId, "quoDate": item.quoDate, "status": item.status, "ctypeid": item.ctypeid, "ctype": item.ctype, "creditdays": item.creditdays, "quoStatus": item.quoStatus, "contactperson": item.contactperson, "quotationAmount": item.quotationAmount,
                                "installInstru": item.installInstru, "installionDate": item.installionDate, "txtselect": item.txtselect, "installionTime": item.installionTime, "nextstageid": item.nextstageid, "chkscheduleInstal": item.chkscheduleInstal, "contactlst": item.contactlst, "productEnquirylst": item.productEnquirylst, "productlst": item.productlst, "procatlst": item.procatlst, "tandclst": item.tandclst, "changetandclt": item.changetandclt, "salesTC": item.salesTC,
                                "paymentType": item.paymentType, "gstseperate": item.gstseperate, "orderaccp": item.orderaccp, "chkschemeflag": item.chkschemeflag, "sendquotation": item.sendquotation, "chkwon": item.chkwon, "chklost": item.chklost, "chkaband": item.chkaband, "decision": item.decision, "decisionDate": item.decisionDate, "lossreason": item.lossreason, "remarks": item.remarks, "approvedQuo": item.approvedQuo, "schmemCode": item.schmemCode,
                                "otp": item.otp, "otpverified": item.otpverified, "qhistorylst": item.qhistorylst, "qversionlst": item.qversionlst, "discountAmount": item.discountAmount, "tax": item.tax, "previousremarks": item.previousremarks, "lstproductintersted": item.lstproductintersted, "unitno": item.unitno, "buildingName": item.buildingName, "city": item.city, "pincode": item.pincode, "discount": item.discount,
                                "showOtp": item.showOtp, "Otpshow": item.Otpshow, "fulldiscount": item.fulldiscount, "schemetype": item.schemetype, "quoid": item.quoid, "customerName": item.customerName, "enquiryName": item.enquiryName, "quostage": item.quostage, "srno": item.srno, "installation": null,"dearflag":null,  lstspare:null, totalamount:null,total:null,totalgstamount:null,totalamountwords:null,customnumber:null
                            });
                        }
                    }

                });
                this.quotationdash = this.quotationdash1;
            }
        }
        else if (this.model.txtselect == "Owner") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.quotationdash1 = [];
                this.quotationdash.forEach((item) => {
                    if (item.ownernameofOppor != null) {
                        if ((item.ownernameofOppor.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                            this.quotationdash1.push({
                                "id": item.id, "userid": item.userid, "connection": item.connection, "activityid": item.activityid, "actionTaken": item.actionTaken, "quotationid": item.quotationid, "quoCode": item.quoCode, "accid": item.accid, "accname": item.accname, "contactpersonid": item.contactpersonid, "ownerIdofOppor": item.ownerIdofOppor,
                                "txtserch": item.txtserch, "ownernameofOppor": item.ownernameofOppor, "address": item.address, "currencyName": item.currencyName, "enqId": item.enqId, "quoDate": item.quoDate, "status": item.status, "ctypeid": item.ctypeid, "ctype": item.ctype, "creditdays": item.creditdays, "quoStatus": item.quoStatus, "contactperson": item.contactperson, "quotationAmount": item.quotationAmount,
                                "installInstru": item.installInstru, "installionDate": item.installionDate, "txtselect": item.txtselect, "installionTime": item.installionTime, "nextstageid": item.nextstageid, "chkscheduleInstal": item.chkscheduleInstal, "contactlst": item.contactlst, "productEnquirylst": item.productEnquirylst, "productlst": item.productlst, "procatlst": item.procatlst, "tandclst": item.tandclst, "changetandclt": item.changetandclt, "salesTC": item.salesTC,
                                "paymentType": item.paymentType, "gstseperate": item.gstseperate, "orderaccp": item.orderaccp, "chkschemeflag": item.chkschemeflag, "sendquotation": item.sendquotation, "chkwon": item.chkwon, "chklost": item.chklost, "chkaband": item.chkaband, "decision": item.decision, "decisionDate": item.decisionDate, "lossreason": item.lossreason, "remarks": item.remarks, "approvedQuo": item.approvedQuo, "schmemCode": item.schmemCode,
                                "otp": item.otp, "otpverified": item.otpverified, "qhistorylst": item.qhistorylst, "qversionlst": item.qversionlst, "discountAmount": item.discountAmount, "tax": item.tax, "previousremarks": item.previousremarks, "lstproductintersted": item.lstproductintersted, "unitno": item.unitno, "buildingName": item.buildingName, "city": item.city, "pincode": item.pincode, "discount": item.discount,
                                "showOtp": item.showOtp, "Otpshow": item.Otpshow, "fulldiscount": item.fulldiscount, "schemetype": item.schemetype, "quoid": item.quoid, "customerName": item.customerName, "enquiryName": item.enquiryName, "quostage": item.quostage, "srno": item.srno, "installation": null,"dearflag":null,  lstspare:null, totalamount:null,total:null,totalgstamount:null,totalamountwords:null,customnumber:null
                            });
                        }
                    }

                });
                this.quotationdash = this.quotationdash1;
            }
        }
        else if (this.model.txtselect == "Stage") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.quotationdash1 = [];
                this.quotationdash.forEach((item) => {
                    if (item.quostage != null) {
                        if ((item.quostage.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                            this.quotationdash1.push({
                                "id": item.id, "userid": item.userid, "connection": item.connection, "activityid": item.activityid, "actionTaken": item.actionTaken, "quotationid": item.quotationid, "quoCode": item.quoCode, "accid": item.accid, "accname": item.accname, "contactpersonid": item.contactpersonid, "ownerIdofOppor": item.ownerIdofOppor,
                                "txtserch": item.txtserch, "ownernameofOppor": item.ownernameofOppor, "address": item.address, "currencyName": item.currencyName, "enqId": item.enqId, "quoDate": item.quoDate, "status": item.status, "ctypeid": item.ctypeid, "ctype": item.ctype, "creditdays": item.creditdays, "quoStatus": item.quoStatus, "contactperson": item.contactperson, "quotationAmount": item.quotationAmount,
                                "installInstru": item.installInstru, "installionDate": item.installionDate, "txtselect": item.txtselect, "installionTime": item.installionTime, "nextstageid": item.nextstageid, "chkscheduleInstal": item.chkscheduleInstal, "contactlst": item.contactlst, "productEnquirylst": item.productEnquirylst, "productlst": item.productlst, "procatlst": item.procatlst, "tandclst": item.tandclst, "changetandclt": item.changetandclt, "salesTC": item.salesTC,
                                "paymentType": item.paymentType, "gstseperate": item.gstseperate, "orderaccp": item.orderaccp, "chkschemeflag": item.chkschemeflag, "sendquotation": item.sendquotation, "chkwon": item.chkwon, "chklost": item.chklost, "chkaband": item.chkaband, "decision": item.decision, "decisionDate": item.decisionDate, "lossreason": item.lossreason, "remarks": item.remarks, "approvedQuo": item.approvedQuo, "schmemCode": item.schmemCode,
                                "otp": item.otp, "otpverified": item.otpverified, "qhistorylst": item.qhistorylst, "qversionlst": item.qversionlst, "discountAmount": item.discountAmount, "tax": item.tax, "previousremarks": item.previousremarks, "lstproductintersted": item.lstproductintersted, "unitno": item.unitno, "buildingName": item.buildingName, "city": item.city, "pincode": item.pincode, "discount": item.discount,
                                "showOtp": item.showOtp, "Otpshow": item.Otpshow, "fulldiscount": item.fulldiscount, "schemetype": item.schemetype, "quoid": item.quoid, "customerName": item.customerName, "enquiryName": item.enquiryName, "quostage": item.quostage, "srno": item.srno, "installation": null,"dearflag":null,  lstspare:null, totalamount:null,total:null,totalgstamount:null,totalamountwords:null,customnumber:null
                            });
                        }
                    }

                });
                this.quotationdash = this.quotationdash1;
            }
        }
        else if (this.model.txtselect == "Decision") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {

                this.quotationdash1 = [];
                this.quotationdash.forEach((item) => {
                    if (item.status != null) {
                        if ((item.status.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                            this.quotationdash1.push({
                                "id": item.id, "userid": item.userid, "connection": item.connection, "activityid": item.activityid, "actionTaken": item.actionTaken, "quotationid": item.quotationid, "quoCode": item.quoCode, "accid": item.accid, "accname": item.accname, "contactpersonid": item.contactpersonid, "ownerIdofOppor": item.ownerIdofOppor,
                                "txtserch": item.txtserch, "ownernameofOppor": item.ownernameofOppor, "address": item.address, "currencyName": item.currencyName, "enqId": item.enqId, "quoDate": item.quoDate, "status": item.status, "ctypeid": item.ctypeid, "ctype": item.ctype, "creditdays": item.creditdays, "quoStatus": item.quoStatus, "contactperson": item.contactperson, "quotationAmount": item.quotationAmount,
                                "installInstru": item.installInstru, "installionDate": item.installionDate, "txtselect": item.txtselect, "installionTime": item.installionTime, "nextstageid": item.nextstageid, "chkscheduleInstal": item.chkscheduleInstal, "contactlst": item.contactlst, "productEnquirylst": item.productEnquirylst, "productlst": item.productlst, "procatlst": item.procatlst, "tandclst": item.tandclst, "changetandclt": item.changetandclt, "salesTC": item.salesTC,
                                "paymentType": item.paymentType, "gstseperate": item.gstseperate, "orderaccp": item.orderaccp, "chkschemeflag": item.chkschemeflag, "sendquotation": item.sendquotation, "chkwon": item.chkwon, "chklost": item.chklost, "chkaband": item.chkaband, "decision": item.decision, "decisionDate": item.decisionDate, "lossreason": item.lossreason, "remarks": item.remarks, "approvedQuo": item.approvedQuo, "schmemCode": item.schmemCode,
                                "otp": item.otp, "otpverified": item.otpverified, "qhistorylst": item.qhistorylst, "qversionlst": item.qversionlst, "discountAmount": item.discountAmount, "tax": item.tax, "previousremarks": item.previousremarks, "lstproductintersted": item.lstproductintersted, "unitno": item.unitno, "buildingName": item.buildingName, "city": item.city, "pincode": item.pincode, "discount": item.discount,
                                "showOtp": item.showOtp, "Otpshow": item.Otpshow, "fulldiscount": item.fulldiscount, "schemetype": item.schemetype, "quoid": item.quoid, "customerName": item.customerName, "enquiryName": item.enquiryName, "quostage": item.quostage, "srno": item.srno, "installation": null,"dearflag":null,  lstspare:null, totalamount:null,total:null,totalgstamount:null,totalamountwords:null,customnumber:null
                            });
                        }
                    }

                });
                this.quotationdash = this.quotationdash1;
            }
        }
    }


}