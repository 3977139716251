import { Mainmenumodel } from '../_models/Mainmenumodel';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import { User } from '../_models/index';
import { SaveSuccessModel } from '../_models/SaveSucessModel';
// import { Observable } from 'rxjs';
import { TodoComment } from 'typescript';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { promise } from 'protractor';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { common } from './Common';
import { InvoiceModel } from '../Transactions/DispatchOrder/DispatchOrderHomeModel';
import { Observable } from 'rxjs';
import { DropDownModel } from '../_models/DropDownModel';
import { DCRreportModel } from '../Reports/DCR Report/DCRreportModel';
import { QuotReportModel } from '../Transactions/Quotation/QuotationModel';
import { QuotationReportModel } from '../Reports/Quotation Report/QuotationReportModel';
import { StockLedgerReportModel } from '../Reports/Inventory Stock Ledger Report/StockLedgerReportModel';
import { OpportunityReportModel } from '../Reports/Opportunity Report/OpportunityReportModel';
import { OrdInHandReportModel } from '../Reports/Order In Hand Report/OrdInHandReportModel';
import { CorporateCustProcurementSmryModel } from '../Reports/Corporate Customer Procurement Summary/CorporateCustProcurementSmryModel';
import { VisitDetailSmryModel } from '../Reports/Visit Detail Summary/VisitDetailSmryModel';
import { InstallationSummaryModel } from '../Reports/InstallationSummary/InstallationSummaryModel';
import { AreaWisePerformanceReportModel } from '../Reports/Area Wise Performance Report/AreaWisePerformanceReportModel';
import { ReferencersReportModel } from '../Reports/Referencer Report/ReferencersReportModel';
import { TeamWisePerformanceReportModel } from '../Reports/Team Wise Performance Report/TeamWisePerformanceReportModel';
import { PerformanceReprotofTechniciansModel } from '../Reports/Performance Reprot of Technicians/PerformanceReprotofTechniciansModel';
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),

        'Token': localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
    })
};

@Injectable()
export class ReportServices {

    private headers = new Headers({
        'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Token': localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
    });
    private handleError: HandleError;
    private url: String = common.s1;
    private customersUrl = this.url;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {

    }

    getInvoiceInfo(user: User): Observable<InvoiceModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                'dbName': localStorage.getItem('dbName')
            })
        };
        return this.http.post<InvoiceModel>(this.customersUrl + 'api/report/getInvoiceInfo', user, httpOptions1);
    }
    getINSInvoiceInfo(model: InvoiceModel): Observable<InvoiceModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                'dbName': localStorage.getItem('dbName')
            })
        };
        return this.http.post<InvoiceModel>(this.customersUrl + 'api/report/getINSInvoiceInfo', model, httpOptions1);
    }

    getBranchByarea(dcrmodel: DCRreportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getBranchByarea', dcrmodel, httpOptions1);
    }
    getBranchBycity(dcrmodel: OrdInHandReportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getBranchBycity', dcrmodel, httpOptions1);
    }
    getResourceByBranch(dcrmodel: DCRreportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getResourceByBranch', dcrmodel, httpOptions1);
    }
    showDcrReport(model: DCRreportModel): Observable<DCRreportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DCRreportModel[]>(this.customersUrl + 'api/report/showDcrReport', model, httpOptions1);
    }
    showDcrSummaryReport(model: DCRreportModel): Observable<DCRreportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DCRreportModel[]>(this.customersUrl + 'api/report/showDcrSummaryReport', model, httpOptions1);
    }
    showEnggQuotationReport(model: DCRreportModel): Observable<DCRreportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DCRreportModel[]>(this.customersUrl + 'api/report/showEnggQuotationReport', model, httpOptions1);
    }
    getQuotationReport(user: User): Observable<QuotReportModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                'dbName': localStorage.getItem('dbName')
            })
        };
        return this.http.post<QuotReportModel>(this.customersUrl + 'api/report/getQuotationReport', user, httpOptions1);
    }
    getCustomerName(qtrmodel: User): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getCustomerName', qtrmodel, httpOptions1);
    }

    showQuotationReports(model: QuotationReportModel): Observable<QuotationReportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                'dbName': localStorage.getItem('dbName')
            })
        };
        return this.http.post<QuotationReportModel[]>(this.customersUrl + 'api/report/showQuotationReports', model, httpOptions1);
    }
    showStockLedgerReport(model: StockLedgerReportModel): Observable<StockLedgerReportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<StockLedgerReportModel[]>(this.customersUrl + 'api/report/showStockLedgerReport', model, httpOptions1);
    }
    showOpportunityReport(model: OpportunityReportModel): Observable<OpportunityReportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<OpportunityReportModel[]>(this.customersUrl + 'api/report/showOpportunityReport', model, httpOptions1);
    }
    getItemName(qtrmodel: User): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getItemName', qtrmodel, httpOptions1);
    }
    showOrdInHandReport(model: OrdInHandReportModel): Observable<OrdInHandReportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<OrdInHandReportModel[]>(this.customersUrl + 'api/report/showOrdInHandReport', model, httpOptions1);
    }

    getProdCatByBranch(model: OrdInHandReportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getProdCatByBranch', model, httpOptions1);
    }
    showCorporateSmry(model: CorporateCustProcurementSmryModel): Observable<CorporateCustProcurementSmryModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<CorporateCustProcurementSmryModel[]>(this.customersUrl + 'api/report/showCorporateSmry', model, httpOptions1);
    }
    getParantName(visitmodel: User): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getParantName', visitmodel, httpOptions1);
    }
    showVisitDetailSmry(model: VisitDetailSmryModel): Observable<VisitDetailSmryModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<VisitDetailSmryModel[]>(this.customersUrl + 'api/report/showVisitDetailSmry', model, httpOptions1);
    }
    showInstaSummaryReport(model: InstallationSummaryModel): Observable<InstallationSummaryModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<InstallationSummaryModel[]>(this.customersUrl + 'api/report/showInstaSummaryReport', model, httpOptions1);
    }
    getCityByarea(dcrmodel: DCRreportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getCityByarea', dcrmodel, httpOptions1);
    }
    getTeamByBranch(dcrmodel: DCRreportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getTeamByBranch', dcrmodel, httpOptions1);
    }
    getResourceByTeam(omodel: OpportunityReportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getResourceByTeam', omodel, httpOptions1);
    }
    showEnggQuotationdtlReport(model: DCRreportModel): Observable<DCRreportModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DCRreportModel>(this.customersUrl + 'api/report/showEnggQuotationdtlReport', model, httpOptions1);
    }
    getLocaleByCity(amodel: AreaWisePerformanceReportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getLocaleByCity', amodel, httpOptions1);
    }
    showAreaPerformanceReport(model: AreaWisePerformanceReportModel): Observable<AreaWisePerformanceReportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<AreaWisePerformanceReportModel[]>(this.customersUrl + 'api/report/showAreaPerformanceReport', model, httpOptions1);
    }
    showRefrencersReport(model: ReferencersReportModel): Observable<ReferencersReportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<ReferencersReportModel[]>(this.customersUrl + 'api/report/showRefrencersReport', model, httpOptions1);
    }
    getTeamByCustomerType(dcrmodel: DCRreportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getTeamByCustomerType', dcrmodel, httpOptions1);
    }
    showTeamPerformanceReport(model: TeamWisePerformanceReportModel): Observable<TeamWisePerformanceReportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<TeamWisePerformanceReportModel[]>(this.customersUrl + 'api/report/showTeamPerformanceReport', model, httpOptions1);
    }

    getTeamByUserId(dcrmodel: DCRreportModel): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/report/getTeamByUserId', dcrmodel, httpOptions1);
    }
    
    showEnggServiceQuotationdtlReport(model: DCRreportModel): Observable<DCRreportModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DCRreportModel>(this.customersUrl + 'api/report/showEnggServiceQuotationdtlReport', model, httpOptions1);
    }

    showOpenCloseServiceEnquiry(model: DCRreportModel): Observable<DCRreportModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DCRreportModel[]>(this.customersUrl + 'api/report/showOpenCloseServiceEnquiry', model, httpOptions1);
    }
    showPerformanceReportOfServiceTeam(model: PerformanceReprotofTechniciansModel): Observable<PerformanceReprotofTechniciansModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<PerformanceReprotofTechniciansModel[]>(this.customersUrl + 'api/report/showPerformanceReportOfServiceTeam', model, httpOptions1);
    }
}