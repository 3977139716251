<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Customer Merging </span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 s12 m12 registerBgShadow">
            <div class="row">

                <div class="row">
                    <div class="col l1"></div>

                </div>
                <div class="ng-autocomplete pinRowgrp input-field col l4 m4 s4">
                    <ng-autocomplete [data]="supervisiorAuth" type="text" [(ngModel)]="model.fromcustomerName"
                        placeholder="From Customer Name" [searchKeyword]="keyword" [minQueryLength]="3"
                        id="Fromcustomername" (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                        (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate4"
                        [notFoundTemplate]="notFoundTemplate4">
                    </ng-autocomplete>

                    <ng-template #itemTemplate4 let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate4 let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>

                <div class="col l1"></div>


                <div class="row">
                    <div class="col l1"></div>
                    <div class="ng-autocomplete pinRowgrp input-field col l4 m4 s4">
                        <ng-autocomplete [data]="supervisiorAuth1" type="text" [(ngModel)]="model.tocustomerName"
                            placeholder="To Customer Name" [searchKeyword]="keyword1" [minQueryLength]="3"
                            id="tocustomername" (inputChanged)='onChangeSearch1($event)'
                            (selected)='selectEvent1($event)' (inputFocused)='onFocused1($event)'
                            [itemTemplate]="itemTemplate4" [notFoundTemplate]="notFoundTemplate4">
                        </ng-autocomplete>

                        <ng-template #itemTemplate4 let-item>
                            <a [innerHTML]="item.name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate4 let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>


                </div>
                <div>
                    <div class="row" style="margin-top: 2px;padding:10px;">
                        <div class="col l12 m12 s12" style="margin:left-right 2px;">

                            <span style="font:caption;"><b>Customer Details</b></span>
                            <table>

                                <thead style="background-color: gainsboro;">

                                    <th width="25%" class="supervisiorAuth1=" style="text-align: left">Customer Name</th>
                                    <th width="25%" style="text-align: left;padding-left: 10px;">locality</th>
                                   
                                    <th width="25%" style="text-align: left">Customer Mobile</th>
                                   
                                    <!--<th width="17%" style="text-align: left">Item Sub Category</th>
                                    <th width="7%" style="text-align: left">UOM</th>
                                    <th width="8%" style="text-align: center">Actions</th> -->
                                </thead>
                                <tbody>
                                    <tr
                                            *ngFor="let lst1 of model.accountlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td style="text-align: left">{{lst1.customername}}</td>
                                            <!-- <td style="text-align: left">{{lst1.tocmlocality}}</td>  -->
                                            <td style="text-align: left">{{lst1.locality}}</td>
                                            <td style="text-align: left">{{lst1.mobile1}}</td>
                                    </tr>
                                           </tbody>
                            </table>




                            <div class="row" style="margin-top: 2px;padding:10px;">
                                <span style="font:caption;"><b>Opportunity</b></span>
                                <table>
                                    <!-- opportunitylst -->
                                    <thead style="background-color:gainsboro;">
                                        <th width="17%" style="text-align: left">EnquiryName</th>
                                        <!-- <th width="17%" style="text-align: left">Id</th> -->
                                        <th width="17%" style="text-align: left">Stage</th>
                                        <th width="17%" style="text-align: left">Assigned</th>
                                        <th width="17%" style="text-align: left">Status</th>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let lst1 of model.opportunitylst | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td style="text-align: left">{{lst1.enquiryName}}</td>
                                            <!-- <td style="text-align: left">{{lst1.accountid}}</td> -->
                                            <td style="text-align: left">{{lst1.stage}}</td>
                                            <td style="text-align: left">{{lst1.assignedByName}}</td>
                                            <td style="text-align:left">{{lst1.status}}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                         <div class=" col l12 m12 s12">
                                 <div class="row" style="margin-top: 1px;padding:10px;margin-bottom: 0px;">

                                <span style="font:caption;"><b>Installation</b></span>
                                <table>
                                    <thead style="background-color: gainsboro;">
                                        <th width="17%" style="text-align: left; margin-right:01px;">
                                            Installation No.
                                        </th>
                                        <th width="17%" style="text-align: left; margin-right:01px;">
                                            OA No.</th>
                                        <th width="17%" style="text-align:left; margin-right:01px;">
                                            Status</th>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let lst2 of model.installationlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td style="text-align: left">{{lst2.installtionnumber}}</td>
                                            <td style="text-align: left">{{lst2.oanumber}}</td>

                                            <td style="text-align: left">{{lst2.status}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="row" style="margin-top: 2px;padding:10px;">
                                <span style="font:caption;"><b>Service</b></span>
                                <table>
                                    <thead style="background-color: gainsboro;">
                                        <th width="17% "style="text-align:left; margin-right:01px;">Date.</th>
                                        <th width="17% "style="text-align:left; margin-right:01px;">Service No.</th>
                                        <th width="17% "style="text-align:left; margin-right:01px;">Service Status.</th>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let lst3 of model.servicecalllst| paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td style="text-align: left">{{lst3.calldate| date:"dd-MM-yyyy"}}</td>
                                            <td style="text-align: left">{{lst3.serviceCallnumber}}</td>

                                            <td style="text-align: left">{{lst3.status}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            
                            <div class="row">
                                <div class="input-field col l11 m11 s11" style="text-align: right">
                                    <button type="button" class="waves-ripple cancelbutton btn"
                                        style="margin-right: 5px;" (click)="cancle()">Cancel</button>
                                    <button type="button" (click)="savecustomermerge()" class="waves-ripple waves-light btn"
                                        style="margin-left: 5px;">Save</button>
                                </div>

                            </div> 

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>


