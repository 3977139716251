import { QuotationProductModel, TermsAndConditionsModel } from "../Quotation/QuotationModel";

export class ProspectModel{
    accountid :number;
    id :number;
    acwiseid :number;
    customername:string;
    address:string;
    address2:string;
    buildingName:string;
    buildingName2:string;
    unitNumber:string;
    unitNumber2:string;
    pincode:string;
    localityID:number;
    areaID:number
    locality:string;
    city:string;
    cityid:number;
    state:string;
    country:string;
    locale:string;
    areaname:string;
    regionname:string;
    mobile1:string;
    mobile2:string;
    landline:string;
    email:string;
    website:string;
    googlemap:string;
    mcstatus:string;
    lstcontact:contactmodel[]=[];
    
    customertypeID:number;
    customertypename:string;
    industrytypeID:number;
    industrytypename:string;
    constitutionID:number;
    constitutionname:string;

    sourcename:string
    sourceID:number;
    sourcedetail:string;
    sourcedetailID:number;
    remark:string;

    lstproduct:productmodel[]=[];
    lstproductintersted:productmodel[]=[];

    assignedtoname:string;
    assignedtoID:number;

    changeaddress:boolean=false;
    createlead:boolean;
    newcall:boolean;
    localeID:number;


    address1:string;
    pincode1:string;
    pincode2:string;
    localityID1:number;
    areaID1:number
    locality1:string;
    city1:string;
    state1:string;
    country1:string;
    locale1:string;
    areaname1:string;
    regionname1:string;
    localeID1:number;
    googlemap1:string;

    numberofleads:number;

    numberofemp:number;
    numberofempflag:boolean=false
    numberofempMandatory:boolean=false;
    annualturnover:number;
    annualturnoverflag:boolean=false;
    annualturnoverMandatory:boolean=false;
    industrytypebyCtype:number;
    industrytypebyCtypeflag:boolean=false;
    industrytypeMandatory:boolean=false;
    designationMandatory:boolean;
    designationid:number;
    designationname:string;
    chkdesignation:boolean=false;
    userid:number;

    contactID:number
    productID:number

    //calls 
    serviceCallStatusName:string;
    serviceCallStatuscode:string;

    unitNumbers:string;
    buildingNames:string;
    addresss:string;
    serviceCallStatusID:number
    priority:string;
    calls:number;
    callcommitdate:Date;
    problemreported:string;
    calltime:string
    calldate:Date;
    callbookeddate:Date;
    status:string;

    activityID:number;
    showspare:boolean;
    productspare:boolean;

    follow:boolean;
    close:boolean;
    closureOTPsend:boolean;

    closeotpverify:boolean=false;
    invocesend:boolean=false;
    closureOTP:string;
    sendclosureOTP:string;
    cashreceived:boolean;
    observationandanalysis:string;
    problemcategoryid:number;
    followupdate:Date;
    followupassingto:number;

    lstspare:sparemodel[]=[];
    activity:string;
    activityflag:string;
    amount:number;
    serviceOTP:string;
    sendserviceOTP:string;

    cashreceiverOTP:string;
    sendcashreceiverOTP:string;
    index:number;

    flag:string;
    createleadchecked:boolean;
    newcallchecked:boolean;
    sameaddress:boolean=true;
    reistallation:boolean=false;

    serviceOTPverifed:boolean=false;
    closureOTPverifed:boolean=false;
    serviceCallnumber:string;
    serviceCallID:number;
    sparequotationID:number;


    chksuperVisiorAuth: boolean;
    mandSupervisiorAuth: boolean;

    supervisiorAuthid:number;
    supervisiorAuth:string;
    supervisiorAuth1:string;

    
    totalgstamount:number=0;
    totalamount:number=0;
    total:number=0;
    totalamountwords:string;
    contactname:string;
    followupactivityID:number;

    tandclst: TermsAndConditionsModel[] = [];
    leadID:number;
    customerhistory:string;
    serviceCallStatusCode:string;
    localeid:number;
    newassignedtoid:number;
    customervalue:number;
    nodallowedbeforeservicecall:number;
    checkselectsource:boolean;
    showOtp:boolean;
    genOtp:boolean;
    machineid:number;
    productCategoryID:number;
    schmemCode:string;
    productEnquirylst: QuotationProductModel[];
    chkschemeflag:string;
    discount:number;
    discountAmount:number;
    ratewithdiscount:number;
    customnumber:number;
    schemetype:string;
    resourcename:string;
    chkseledel:boolean;

   
}
export class contactmodel{
    id:number;
    contactname:string;
    contactdesignationID:number;
    contactdesignation:string;
    contactmobile:string;
    contactemail:string;
    added:boolean;
    checkselectcon:boolean;
    checkselect:boolean;
    
}
export class productmodel{
    accountid:number;
    checkselect:boolean;
    id:number;
    productid:number;
    name:string
    productCategoryID:number;
    categoryname:string;
    categoriesdescription:string;
    description:string;
    purdate:Date;
    installationDate:Date;
    added:boolean;
    location:string;

    address:string;
    pincode:string;
    localityID:number;
    areaID:number;
    locality:string;
    city:string;
    state:string;
    country:string;
    locale:string;
    areaname:string;
    regionname:string;
    serviceCallStatusName:string;
    googlemap:string

    amcWarrantyEndDate:Date
    amcEndDate:Date
    warrentyEndDate:Date;
    srlno:string;
    serialnoFlag:boolean;
    status:string;
    serviceCallStatusID:number;
}

export interface sparemodel{
    sparedescription:string;
    gstid:number;
    gstpersentage:number;
    gstamount:number;
    quantity:number;
    rate:number;
    uom:string;
    cashcheckselect:boolean;
    type:string;
    uomid:number;
    amount:number;
    checkselect:boolean;
    spareProductid:number;
    finalamount:number;
    id:number;
    name:string;
    spareid:number;
    pid:number;
    listprice:number;  
    discount:number;
    discountAmount:number;
    netamount:number;
    totalproamountnew:number;
    offereddiscount:number;
    newdiscountamt:number;
    ratewithdiscount:number;
    totalproamount:number;
    totalamount:number;
    rate1:number;
    customnumber:number;
    

}