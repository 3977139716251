export class ResourceRightsModel {
    id: number;
    name: string;
    userid: number;
    resourceID: number;
    regionid: number;
    regionname: string;
    regionString: String;
    regionNumlst: number[] = []
    arealst: AreaModel[] = [];
    regionlst: ResourceRightsModel[] = [];
    savedlocale: number[] = [];
    unsavedlocale: number[] = [];
    areaString:string;
    approvallst:ResourceApprovalsModel[] = [];
    cityid: number;
    cityname: string;
    localelst: LocaleModel[] = [];
    newlocallst: LocaleModel[] = [];
    allLocaleInRegion: boolean;
    localeid: number;
}
export class AreaModel {
    areaid: number;
    areaname: string;
    regionname: string;
    allcheckselect:boolean;
    chkselectarea: boolean;
    localelst: LocaleModel[] = [];
    citylst: CityModel[] = [];
}
export class LocaleModel {
    localeid: number;
    chkselectlocale: boolean;
    localename: string;
}

export class ResourceApprovalsModel {
    resourceID: number;
    approverid: number;
    type: string;
    sequance: number;
} 
export class CityModel {
    cityid: number;
    cityname: string;
}
