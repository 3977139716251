<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Conversion Template</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 0px;padding-top: 0px;">
            <div class="row" style="margin-top: 0px;padding-left: 0px;">
                <div class=" input-field col s3 m3 l3" style="margin-top: 24px;margin-bottom: 0px;padding-left: 14px;">
                    <input [(ngModel)]="model.shortname" #code="ngModel" type="text" id="code" title="Short Name"
                        maxlength="35" id="id1">
                    <label id="lable1" for="id1" style="color: red;left: 14px;" class="active">Short Name</label>
                </div>
                <div class=" input-field col s6 m6 l6" style="margin-top: 24px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.formulaname" #name="ngModel" type="text" id="Name" data-toggle="tooltip"
                        data-placement="top" title="Formula Name" onkeypress="return /[A-Za-z_ ]/i.test(event.id)"
                        ngModel maxlength="100" required id="id2">
                    <label id="lable2" for="id2" style="color: red;" class="active">Formula Name </label>
                </div>
            </div>
            <div class=row>
                <div class="input-field col l3 m3 s3" style="padding-left: 14px;margin-top: 0px;margin-bottom: 0px;">
                    <span>Item Category</span>
                    <select (change)="onSelectitemCat($event.target.value)">
                        <option selected value="0">Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id"
                            [selected]="type.id == model.itemcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 0px;">
                    <span>Sub Category</span>
                    <select (change)="onSelectSubCat($event.target.value)">
                        <option selected value="0">Select Sub Category</option>
                        <option *ngFor="let type of subCatlst" [value]="type.id"
                            [selected]="type.id == model.itemsubcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class=" input-field col l4 m4 s4" style="padding-top: 0px;margin-top: 17px;margin-bottom: 0px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.itemname" id="productname"
                        data-toggle="tooltip" data-placement="top" title="Product Name" (keyup)="getItemLike()" />
                    <input type="hidden" id="acc-id" />
                    <label for="item" class="active">Search Item</label>
                </div>
                <div class="input-field  col l1 m1 s1" style="margin-top: 17px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.quantity" onkeypress="return /[0-9]/i.test(event.key)" id="qua"
                        type="text" title="Quantity" maxlength="10" style="text-align: right;">
                    <label for="qua" class="active">Quantity</label>
                </div>
            </div>
            <div class=row>
                <div class="input-field col l3 m3 s3" style="padding-top: 0px;padding-left: 14px;margin-top: 0px;">
                    <span>UOM:</span><b style="font-size: 16px;">{{model.uom}}</b>
                </div>
                <div class="input-field col l3 m3 s3" style="padding-top: 0px;margin-top: 0px;">
                    <span>Dimensions:</span><b style="font-size: 16px;">{{model.dimensions}}</b>
                </div>
                <!-- <div class="input-field col l2 m2 s2" style="padding-top: 10px;">
                    <span>Dimensions Unit</span><br> <b>{{model.diamensionUnit}}</b>
                </div> -->
            </div>
            <div class="col l12 m12 s12" style="text-align: start;padding-left: 0px;">
                <span style="font-size: 16px;"><b>Output Material</b> </span>
            </div>
            <div class=row>
                <div class="input-field col l3 m3 s3" style="padding-left: 14px;margin-top: 5px;margin-bottom: 0px;">
                    <span>Item Category</span>
                    <select (change)="onSelectitemCat1($event.target.value)">
                        <option selected value="0">Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id"
                            [selected]="type.id==model.omitemcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-top: 5px;margin-bottom: 0px;">
                    <span>Sub Category</span>
                    <select (change)="onSelectSubCat1($event.target.value)">
                        <option selected value="0">Select Sub Category</option>
                        <option *ngFor="let type of subCatlst1" [value]="type.id"
                            [selected]="type.id==model.omitemsubcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class=" input-field col l4 m4 s4" style="padding-top: 0px;margin-top: 21px;margin-bottom: 0px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.omitemname" id="productname1"
                        data-toggle="tooltip" data-placement="top" title="Product Name" (keyup)="getItemLike1()" />
                    <input type="hidden" id="acc-id" />
                    <label for="item" class="active">Search Item</label>
                </div>
                <div class="input-field  col l1 m1 s1" style="padding-top: 0px;margin-top: 21px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.omquantity" id="qua" type="text" title="Quantity" maxlength="35"
                        style="text-align: right;">
                    <label for="qua" class="active">Quantity</label>
                </div>
                <div class="input-field  col l1 m1 s1" style="margin-top:22px;margin-bottom: 0px;">
                    <button type="button" (click)="btnAdd()" class="waves-ripple waves-light btn">Add</button>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="margin-top: 0px; padding-left: 9px;padding-right: 9px;">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="40%" style="text-align: left">Item name</th>
                            <th width="30%" style="text-align: left">Dimensions</th>
                            <th width="10%" style="text-align: right;padding-right: 10px;">Quantity</th>
                            <!-- <th width="30" style="text-align: center">Status</th> -->
                            <th width="10%" style="text-align: left">UOM</th>
                            <th width="10%" style="text-align: left">Action</th>

                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let lst of model.lstoutputmeteriallst | paginate: { itemsPerPage: 10, currentPage: p };let i=index;">
                                <td style="text-align: left">{{lst.omitemname}}</td>
                                <td style="text-align: left">{{lst.omdimensions}}</td>
                                <td style="text-align: right;padding-right: 10px;">{{lst.omquantity}}</td>
                                <td style="text-align: left">{{lst.omuom}}</td>
                                <td style="text-align: left"> <span><img src="../../../assets/images/delete.png"
                                            data-toggle="tooltip" (click)="deleleitem(lst.id,i)" data-placement="top"
                                            title="delete" width="20px" /></span>&nbsp;&nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l8 m8 s8" style="margin-top: 0px;"></div>
                <div class="input-field col l4 m4 s4" style="text-align: right;margin-top: 0px;">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;
                    <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>