import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { AlertService } from "src/app/_services";
import { DealersService } from "src/app/_services/DealersService";
import { MasterServices } from "src/app/_services/MasterServices";
import { NotificationService } from "src/app/_services/notification.service";
import { TransactionServices } from "src/app/_services/TransactionServices";
import { Location, } from '@angular/common';
import { User } from "src/app/_models";
import { SearchMCHallocaTemplateModel } from "./SearchMCHallocaTemplateModel";
import { MarketingServices } from "src/app/_services/MarketingServices";
import { DropDownModel } from "src/app/_models/DropDownModel";
declare var $: any;
@Component({
    selector: 'app-order',
    templateUrl: 'DefineMCHallocaTEMP.html'
})
export class DefineMCHallocaTEMP implements OnInit {

    constructor(
        private Marketingservice: MarketingServices,
        private router: Router,) {
    }
    user = new User();
    p: any;
    model = new SearchMCHallocaTemplateModel();
    mrklst: SearchMCHallocaTemplateModel[] = [];
    mrklst1: SearchMCHallocaTemplateModel[] = [];
    totalpercentage: number = 0;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Marketing Cost Head" },
        { id: 3, "name": "Percentage" },

    ];
    ngOnInit(): void {
        this.getAllitem();
    }
    getAllitem() {

        this.Marketingservice.getAllCostHeadPercentage(this.user)
            .subscribe(value => {
                this.mrklst = value
                for(let i= 0;i<this.mrklst.length; i++){
                    this.totalpercentage = Number(this.totalpercentage) + Number(this.mrklst[i].percentallocate);
                }
                localStorage.setItem('totalpercentage', this.totalpercentage.toString());
                this.mrklst1 = [];
                value.forEach((item) => this.mrklst1.push({
                    "id": item.id, "txtserch": item.txtserch, "txtselect": item.txtselect, "srno": item.srno,
                    "costhead": item.costhead,
                    "costsubname": item.costsubname,
                    "marketingcostid": item.marketingcostid,
                    "percentallocate": item.percentallocate,
                    "percentage": item.percentage,
                    "remainpercent": item.remainpercent,
                    "userid": item.userid,
                    "checkselect": item.checkselect,
                    "modellst": null,
                    "mrklst": item.mrklst,
                    "sum": item.sum
                }));
                this.mrklst = this.mrklst1;

            });
    }
    btnedit(id) {
        this.router.navigate(['/editDefineMCHallocaTEMP', id]);
    }
    btnsearch($event) {

    }
    btnNew($event) {
        this.router.navigate(['/newDefineMCHallocaTEMP']);
    }
    onSelectItem(value) {
        this.model.txtselect = value;
    }
    btndelts(id){

    }
}