import { OnInit, Component } from '@angular/core';
import { TeamModel } from './TeamModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { NullTemplateVisitor } from '@angular/compiler';
@Component({
    selector: 'app-sTeam',
    templateUrl: 'SearchTeam.html'
})
export class SearchTeam implements OnInit {
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private transactionservice: TransactionServices) {
    }
    model = new TeamModel();
    teamlst: TeamModel[] = [];
    teamlst1: TeamModel[] = [];
    user = new User();
    p: any;
    accesstype: string;
    public i;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Team Code" },
        { id: 3, "name": "Region" },
        { id: 4, "name": "Branch" },
    ];

    ngOnInit(): void {
        this.user.accesstype = localStorage.getItem('accesstype');
        this.getAllTeams()
    }

    getAllTeams() {
        this.teamlst = [];
        this.transactionservice.getAllTeams(this.user)
            .subscribe(value => {
                this.teamlst = value
                this.teamlst1 = value
            })
    }

    btnnew() {
        this.router.navigate(["/newTeam"])
    }

    onSelectSearch(val) {
        this.model.txtselect = val
    }

    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllTeams()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Team Code") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.teamlst1 = [];
                this.teamlst.forEach((item) => {
                    if ((item.teamcode.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.teamlst1.push({
                            "id": item.id, "areaid": item.areaid, "region": item.region, "branch": item.branch, "teamcode": item.teamcode, "txtserch": null, "txtselect": null, "userid": null, "branchid": null, "acesstype": null, "typeInt": null, "regionid": null, "pcInt": null,
                            "resoureslst": null, "procatlst": null, "customertyps": null, "areaInt": null, "arealst": null, accesstype: null, "srno": item.srno, "chkSale": item.chkSale, "chkService": item.chkService, "chkInstallation": item.chkInstallation, "cityid": null,
                            "localpincodelst": null, "localeid": null, "savedlocale": null, "unsavedlocale": null, "pincode": null, "customertypeid": null, "forflag": null,
                            "savedlocale1": null, "unsavedlocale1": null, "type": null, "allcheckselect": null, "tolocationid": item.tolocationid, "tolocationname": item.tolocationname, "locationname": item.locationname, "locationid": item.locationid, "invIntegration": item.invIntegration, "customertype": item.customertype, "itemlst": null, "name": null,
                            "chkOrderAccept": item.chkOrderAccept,
                            chkseledel: false
                        });
                    }
                });
                this.teamlst = this.teamlst1;
            }
        }
        else if (this.model.txtselect == "Region") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.teamlst1 = [];
                this.teamlst.forEach((item) => {
                    if ((item.region.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.teamlst1.push({
                            "id": item.id, "areaid": item.areaid, "region": item.region, "branch": item.branch, "teamcode": item.teamcode, "txtserch": null, "txtselect": null,
                            "userid": null, "branchid": null, "acesstype": null, "typeInt": null, "regionid": null, "pcInt": null, "resoureslst": null, "procatlst": null, "customertyps": null,
                            "areaInt": null, "arealst": null, accesstype: null, "srno": item.srno, "chkSale": item.chkSale, "chkService": item.chkService, "chkInstallation": item.chkInstallation,
                            "cityid": null, "localpincodelst": null, "localeid": null, "savedlocale": null, "unsavedlocale": null, "pincode": null, "customertypeid": null, "forflag": null, "savedlocale1": null,
                            "unsavedlocale1": null, "type": null, "allcheckselect": null, "tolocationid": item.tolocationid, "tolocationname": item.tolocationname, "locationname": item.locationname, "locationid": item.locationid, "invIntegration": item.invIntegration, "customertype": item.customertype, "itemlst": null, "name": null,
                            "chkOrderAccept": item.chkOrderAccept,
                            chkseledel: false
                        });
                    }
                });
                this.teamlst = this.teamlst1;
            }
        } else if (this.model.txtselect == "Branch") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.teamlst1 = [];
                this.teamlst.forEach((item) => {
                    if ((item.branch.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.teamlst1.push({
                            "id": item.id, "areaid": item.areaid, "region": item.region, "branch": item.branch, "teamcode": item.teamcode, "txtserch": null, "txtselect": null, "userid": null, "branchid": null, "acesstype": null, "typeInt": null, "regionid": null,
                            "pcInt": null, "resoureslst": null, "procatlst": null, "customertyps": null, "areaInt": null, "arealst": null, accesstype: null, "srno": item.srno, "chkSale": item.chkSale, "chkService": item.chkService, "chkInstallation": item.chkInstallation,
                            "cityid": null, "localpincodelst": null, "localeid": null, "savedlocale": null, "unsavedlocale": null, "pincode": null, "customertypeid": null, "forflag": null, "savedlocale1": null, "unsavedlocale1": null, "type": null, "allcheckselect": null,
                            "tolocationid": item.tolocationid, "tolocationname": item.tolocationname, "locationname": item.locationname, "locationid": item.locationid, "invIntegration": item.invIntegration, "customertype": item.customertype, "itemlst": null, "name": null, "chkOrderAccept": item.chkOrderAccept,
                            chkseledel: false
                        });
                    }
                });
                this.teamlst = this.teamlst1;
            }
        }
    }

    btnedit(id) {
        this.router.navigate(["/editTeam", id])
    }

    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.user.trnid = id
            this.transactionservice.deleteTeam(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
    }
}