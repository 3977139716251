<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{routingname}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2" style="padding-top: 9px;"
                    *ngIf="routingname != 'Open / Lost Enquiries Report'">
                    <span>From Date</span>
                    <input type="text" placeholder="From Date" [(ngModel)]="model.frmdate" class="form-control"
                        bsDatepicker (ngModelChange)="selectFromDate(model.frmdate)"
                        [bsConfig]="{  isAnimated: true ,dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true,showWeekNumbers: false}">
                    </div>

                <div class="col l2 m2 s2" style="padding-top: 9px;"
                    *ngIf="routingname != 'Open / Lost Enquiries Report'">
                    <span>To Date</span>
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" class="form-control"
                        bsDatepicker (ngModelChange)="selectToDate(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true ,showWeekNumbers: false}">
                </div>
                <div class="input-field col l2 m2 s2" style="margin-top: 10px;margin-bottom: 5px;">
                    <span>Region</span>
                    <select (change)="onSelectRegions($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Regions">
                        <option>Select Region</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of regionlst" [value]="lst.id" [selected]="lst.id==model.regionid">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l4 m4 s4" style="margin-top: 10px;margin-bottom: 5px;">
                    <span>Area</span>
                    <select (change)="onSelectArea($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Area">
                        <option>Select Area</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of arealst" [value]="lst.areaid" [selected]="lst.areaid==model.areaid">
                            {{lst.areaname}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class="col l10 m10 s10" style="padding-left: 0px;">

                    <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 5px;">
                        <span>City</span>
                        <select (change)="onSelectCity($event.target.value)" data-toggle="tooltip" data-placement="top"
                            data-toggle="tooltip" data-placement="top" title="City">
                            <option>Select City</option>
                            <!-- <option value="All">All</option> -->
                            <option *ngFor="let lst of citylst" [value]="lst.id" [selected]="lst.id==omodel.cityid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 5px;">
                        <span>Branch</span>
                        <select (change)="onSelectBranches($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Branches" data-toggle="tooltip" data-placement="top"
                            title="Branch">
                            <option>Select Branch</option>
                            <!-- <option value="All">All</option> -->
                            <option *ngFor="let lst of branchlst" [value]="lst.id " [selected]="lst.id ==model.branchid"[selected]="lst.name ==model.branchname" >
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 5px;">
                        <span>Team</span>
                        <select (change)="onSelectTeam($event.target.value)" data-toggle="tooltip" data-placement="top"
                            title="Branches" data-toggle="tooltip" data-placement="top" title="Team">
                            
                            <option>Select </option>
                            <!-- <option value="All">All</option> -->
                            <option *ngFor="let lst of teamlst" [value]="lst.id"[selected]="lst.id==opmodel.teamid">
                                {{lst.name}}
                            </option>
                            
                        </select>
                     
                    </div>
                </div>
                <div class="col l1 m1 s1"></div>

            </div>
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>

                <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Resource Name</span>
                    <select (change)="onSelectResource($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Resource">
                        <option>Select Resource</option>
                        <!-- <option value="All">All</option> -->
                        
                        <option *ngFor="let lst of resourcelst" [value]="lst.id" [selected]="lst.id==model.resourceID">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col s2 l2 m2"
                    *ngIf="routingname == 'Daily Call Report' || routingname == 'DCR Summary Report'"
                    style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Status</span>
                    <select (change)=" onSelectStatus($event.target.value)">
                        <option >Select Status</option>
                        <option *ngFor="let type of searchtype" [value]="type.name" title="Status">
                      {{type.name}}</option>
                        </select>
                   </div>
                <div class="input-field col s2 l2 m2" *ngIf="routingname == 'Open / Lost Enquiries Report'"
                    style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Status</span>
                    <select (change)=" onSelectStatus($event.target.value)">
                        <option>Select Status</option>
                        <option *ngFor="let type of exewiseQuotation" [value]="type.name" title="Status">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s2 l2 m2" *ngIf="routingname == 'Executive Wise Quotation Detail Report'"
                    style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Status</span>
                    <select (change)=" onSelectStatus($event.target.value)">
                        <option>Select Status</option>
                        <option *ngFor="let type of exewiseQuotnDtl" [value]="type.name" title="Status">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s2 l2 m2" *ngIf="routingname == 'Opportunity Report'"
                    style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Status</span>
                    <select (change)=" onSelectStatus($event.target.value)">
                        <option>Select Status</option>
                        <option *ngFor="let type of opportunity" [value]="type.name"
                            [selected]="type.name==model.status" title="Status">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s2 l2 m2" *ngIf="routingname == 'Installation Summary Report'"
                    style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Status</span>
                    <select (change)=" onSelectStatus($event.target.value)">
                        <option>Select Status</option>
                        <option *ngFor="let type of instaSummary" [value]="type.name" title="Status">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s2 l2 m2" *ngIf="routingname == 'Pending / In Progress / Rejected Enquiries'"
                    style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Status</span>
                    <select (change)=" onSelectStatus($event.target.value)">
                        <option>Select Status</option>
                        <option *ngFor="let type of opencloseenquiry" [value]="type.name" title="Status">
                            {{type.name}}</option>
                    </select>
                </div>

                <div class="col l3 m3 s3"></div>
                <div class="input-field col l2 m2 s2" style="text-align: right;margin-top: 17px;margin-bottom: 5px;">
                    <button (click)="btnshow()" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Show</button>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>
        </div>
    </div>
</div>