<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Installation Summary Report</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>

        <div class="col l12 m12 s12 registerBgShadow" style="padding-left: 18px;padding-right: 18px;">
            <div class="row" id="LibDocs">
                <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                    <div class="modal-content " style="text-align:center;padding: 0px;margin: 24px;">
                        <!-- <span style="font-size: 20px;">Installation Summary Report </span> -->
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>
                            </div>
                        </div>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 16px">{{companyaddress}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <span style="font-size: 20px;text-decoration: underline">Installation Summary Report</span>
                    </div>
                    <div class="col l12 m12 s12" style="padding-right: 0px;" id="LibDocs">
                        <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                            <span><img src="../../../assets/images/printer.png" (click)="openPrint()"
                                    data-toggle="tooltip" data-placement="top" title="Print"
                                    width="30px" /></span>&nbsp;&nbsp;&nbsp;
                            <span><img src="../../../assets/images/excel2.png" onclick="tableToExcel('excel')"
                                    data-toggle="tooltip" data-placement="top" title="Excel" width="30px" /></span>
                            <!-- <button onclick="tableToExcel('excel')" type="button" class="btn btn-default btn_main" id="btn1"
                                  xlname="sample.xls">Excel</button> -->
                        </div>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">

                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7">
                            <tbody>
                                <tr>
                                    <td width="20%" style="text-align: left;border: 1px solid black;">
                                        <span>Region:-</span>
                                        &nbsp;<span><b>{{regionName}}</b></span>
                                    </td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;">
                                        <span>Area:-</span>
                                        &nbsp;<span><b>{{areaName}}</b></span>
                                    </td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>From
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.frmdate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>To
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.todate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                            id="excel">
                            <thead style="background: gainsboro;">
                                <th width="7%" style="text-align: left">Sr.#</th>
                                <th width="10%" style="text-align: left;">Date</th>
                                <th width="10%" style="text-align: left;">Order Date</th>
                                <th width="10%" style="text-align: left">Customer</th>
                                <th width="10%" style="text-align: left">Type</th>
                                <th width="10%" style="text-align: left">Product</th>
                                <th width="10%" style="text-align: left">Inst Assign</th>
                                <th width="10%" style="text-align: left">Amount</th>
                                <th width="10%" style="text-align: left">Status</th>
                                <th width="10%" style="text-align: left">Remark</th>
                                <th width="10%" style="text-align: left">Mobile</th>
                                <th width="10%" style="text-align: left">Locality</th>
                                <th width="15%" style="text-align: left">Address</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of inslst ">
                                    <td style="text-align: left">{{lst.srno}}</td>
                                    <td style="text-align: left;">{{lst.date |
                                        date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: left;">{{lst.orderDate                                            |
                                            date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align: left">{{lst.customerName}}</td>
                                    <td style="text-align: left">{{lst.custType}}</td>
                                    <td style="text-align: left">{{lst.product}}</td>
                                    <td style="text-align: left">{{lst.assignTo}}</td>
                                    <td style="text-align: left">{{lst.amount}}</td>
                                    <td style="text-align: left">{{lst.instaStatus}}</td>
                                   <td style="text-align: left">{{lst.remark}}</td>
                                    <td style="text-align: left">{{lst.mobile}}</td>
                                    <td style="text-align: left">{{lst.locality}}</td>
                                    <td style="text-align: left">{{lst.address}}</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col s12 m12 l12" style="text-align: center;margin-top: 12px;">
                        <span style="font-size: 11px;">Report Genrated By: {{username}} on {{timing
                            |date:'medium'}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="padding-right: 0px;">
                    <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="btnback()">Back</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>