import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location, getNumberOfCurrencyDigits, DatePipe } from '@angular/common';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';

import { BsModalService } from 'ngx-bootstrap';
declare var $: any;
@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'ThreeSixtyMaster.html'
})

export class ThreeSixtyMaster implements OnInit {
    p:any;
    model: any = {};
    user = new User();
    userList2: any;
    mrpmap = new Map();
    id: number;
    public i;
    modalRef2: any;
    searchstring:string;
    constructor(private Masterservice: MasterServices,
         private transactionservice: TransactionServices, 
         private route: ActivatedRoute, 
         private notifyService: NotificationService,
          private router: Router, 
          private modalService: BsModalService,) {
    }

    ngOnInit(){

        this.route.queryParams.subscribe(params => {
            this.user.userid = + atob(params["id"]);
            this.searchstring = params["searchstring"];

        })
        if (this.searchstring != null) {
            localStorage.setItem("searchstring", this.searchstring);
          }
          this.user.trnid = this.model.accountid
      
    }
    getcustomernameLike($event) {
      let userId = (<HTMLInputElement>document.getElementById('customerName1')).value;
      this.userList2 = [];
      let searchDataObj = {};
      this.user.message = userId;
      var obj = this;
      if (userId.length >= 3) {
          this.transactionservice.getcustomernameLike(this.user)
              .subscribe(data => {
                  data.forEach((item) => this.userList2.push({ "customerName": item.customerName, "accountid": item.accountid }));
                  let map = new Map();
                  var names = "";
                  for (let i = 0; i < this.userList2.length; i++) {
                      map.set(this.userList2[i].customerName, this.userList2[i].accountid)
                      searchDataObj[this.userList2[i].customerName] = null;
                      if (i == 0) {
                          names = this.userList2[i].customerName;
                      } else {
                          names = names + "," + this.userList2[i].customerName;
                      }
                  }
                  let customerName;
                  $(document).ready(function () {
                      $(".only-numeric").bind("keypress", function (e) {
                          var keyCode = e.which ? e.which : e.keyCode

                          if (!(keyCode >= 48 && keyCode <= 57)) {
                              $(".error").css("display", "inline");
                              return false;
                          } else {
                              $(".error").css("display", "none");
                          }
                      });
                  });
                  $(document).ready(function () {
                      $('input.typeahead').autocomplete({
                          onAutocomplete: function (txt) {
                              obj.typeaheadOnSelect(map.get(txt), txt);
                          },
                      });
                      $('input.autocomplete').autocomplete({
                          data: searchDataObj,
                          onAutocomplete: function (txt) {
                              obj.typeaheadOnSelect(map.get(txt), txt);
                          },
                      });
                  });
              },
                  () => {
                      alert("fail");
                  });
      }
  }
  typeaheadOnSelect(accountid, customerName) {
      this.model.accountid = accountid;
      this.model.customerName = customerName;
      this.user.trnid=this.model.accountid
      this.transactionservice.getaccountDetails(this.user)
      .subscribe(value => {
          this.model = value
        //   this.model.customerName = value.customerName
      });
  }
    btncreatelead(){
        let navigationExtras: NavigationExtras = {
            queryParams: {
              "flag": btoa("createlead"),
              "id":  btoa(this.model.accountid)
            }
          };
          this.router.navigate(['/prospect'], navigationExtras);
    }
    btnbookservice(){
        let navigationExtras: NavigationExtras = {
            queryParams: {
              "flag":  btoa("bookservicecall"),
              "id":   btoa(this.model.accountid),
              "productid": null
            }
          };
          this.router.navigate(['/prospect'], navigationExtras);
    }
    btncancle(){
            this.router.navigate(['/Call Center Dashboard']);
    }
}