<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l1"></div>

                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                    <span>Access Type</span>
                    <select (change)="onSelectaccessType($event.target.value)">
                        <option selected value="0">Select Access Type </option>
                        <option *ngFor="let type of accesslst" [value]="type.name"
                            [selected]="type.name==model.accesstype">
                            {{type.name}}</option>
                    </select>
                </div>

                <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;">
                    <span>Call Type</span>
                    <select (change)=" onSelectCallType($event.target.value)">
                        <option selected value="0">Select Call Type </option>
                        <option *ngFor="let type of callTypelst" [value]="type" [selected]="type==model.callType">
                            {{type}}</option>
                    </select>
                </div>

                <!-- <div class="input-field col l3 m3 s3" *ngIf="model.accesstype=='SERVICE'" style="margin-bottom: 0px;">
                    <span>Call Subtype</span>
                    <select (change)=" onSelectCallSubType($event.target.value)">
                        <option selected value="0">Select Call Subtype </option>
                        <option *ngFor="let type of callSubTypelst" [value]="type.name"
                            [selected]="type.name==model.callsubtype">
                            {{type.name}}</option>
                    </select>
                </div> -->

                <div class="col l1"></div>
            </div>

            <div class="row">
                <div class="col l1" style="margin-bottom: 0px;"></div>
                <div class="input-field  col s4 l4 m4" style="margin-bottom: 0px;">
                    <input [(ngModel)]="model.activityCode" #activityCode="ngModel" type="text" id="allowancecode"
                        data-toggle="tooltip" data-placement="top" maxlength="15" title="Activity Code">
                    <label for="allowancecode" class="active">Activity Code [MAX. Length 15 Characters]</label>
                </div>
                <div class="input-field  col s5 l5 m5" style="margin-bottom: 0px;">
                    <input [(ngModel)]="model.activityName" #activityName="ngModel" type="text" id="allwaname"
                        data-toggle="tooltip" data-placement="top" title="Activity Name">
                    <label for="allwaname" class="active">Activity Name</label>
                </div>
                <div class="input-field  col s1 l1 m1" style="margin-bottom: 0px;">
                    <input [(ngModel)]="model.sequence" #sequence="ngModel" type="number" id="sequence"
                        data-toggle="tooltip" data-placement="top" title="Hierarchy" style="text-align: right;">
                    <label for="sequence" class="active">Hierarchy</label>
                </div>
                <div class="col l1"></div>
            </div>

            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col l5 m5 s5" style="margin-bottom: 0px;">
                    <textarea [(ngModel)]="model.description" class="materialize-textarea" #description="ngModel"
                        type="text" id="description" data-toggle="tooltip" rows="6" data-placement="top"
                        title="Description" style="height: 40px;margin-bottom: 0px;"></textarea>
                    <label for="description" class="active">Description</label>
                </div>
                <!-- *ngIf="model.callType !='General'" -->
                <div class="input-field col l4 m4 s4"  *ngIf="model.accesstype =='SERVICE' && model.callType !='General'" style="margin-top: 0px;margin-bottom: 0px;">
                    <span>Action</span>
                    <select (change)="onSelectactionType($event.target.value)">
                        <option selected value='0'>Action</option>
                        <option *ngFor="let type of actionlst" [value]="type.name"
                            [selected]="type.name==model.actiontypesaved">
                            {{type.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf = "model.callType !='General'">
                <div class="col l1"></div>
                <div class="input-field col l2 m2 l2" style="margin-top: 27px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.issmscheck" #issmscheck="ngModel" type="checkbox"
                        style="text-align: 5px">&nbsp;&nbsp;Sms
                </div>
                <div class="input-field col l2 m2 s2" *ngIf="model.issmscheck" style="margin-top: 0px;margin-bottom: 0px;">
                    <span>Message</span>
                    <select (change)="onSelectSms($event.target.value)">
                        <option selected value='0' disabled>Sms</option>
                        <option *ngFor="let type of smslst" [value]="type.id"
                            [selected]="type.id==model.smsid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l2 m2 l2" style="margin-top: 27px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.isemailcheck" #isemailcheck="ngModel" type="checkbox"
                        style="text-align: 5px">&nbsp;&nbsp;Email
                </div>
                <!-- <div class="input-field col l2 m2 s2" *ngIf="model.issmscheck" style="margin-top: 0px;">
                    <span>Sms</span>
                    <select (change)="onSelectactionType($event.target.value)">
                        <option selected disabled>Action</option>
                        <option *ngFor="let type of actionlst" [value]="type.name"
                            [selected]="type.name==model.actiontypesaved">
                            {{type.name}}</option>
                    </select>
                </div> -->
            </div>

            <div class="row" style="margin:0px; padding-left: 0px;padding-right: 0px;" *ngIf="model.accesstype=='SERVICE' || model.accesstype=='SALES'">
                <div class="col m1 l1 s1"></div> 
                <div class="col l10 m10 s10" style="margin-top: 0px;padding-left: 0px;padding-right: 0px;">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="5%" style="text-align: center;"><input type="checkbox" class="filled-in" [(ngModel)]="model.allcheckselect"
                                (change)="onselectall($event)" #allcheckselect="ngModel" ></th>
                            <th width="95%" style="text-align: left">Customer Type</th>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let lst of customertypelst; let i = index ">
                                <td style="text-align: center;"><input type="checkbox" [(ngModel)]="lst.cuscheckselect"
                                        #cuscheckselect="ngModel"></td>
                                <td style="text-align: left">{{lst.customertypename}}</td>
                              
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                <div class="col m1 l1 s1"></div> 
            </div>

            <div class="input-field col l11 m11 s11" style="text-align: right;padding-right: 0px;">
                <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                    (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn">Save</button>
            </div>
        </div>
    </div>
</div>