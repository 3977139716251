export class SearchMCHallocaTemplateModel {
    id:number;
    costhead:string;
    costsubname:string;
    marketingcostid:number;
    percentallocate:number;
    percentage:number;
    remainpercent:number;
    userid:number;
    checkselect:boolean;
    txtselect:string;
    txtserch:string;
    srno:number;
    modellst:SearchMCHallocaTemplateModel[];
    mrklst: SearchMCHallocaTemplateModel[];
    sum:number;

}