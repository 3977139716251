<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Target Management</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col s10 l10 m10">
                </div>
                <div class=" input-field col s2 l2 m2" style="margin-top: 10px;text-align: right;">
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnnew()">New</button>
                </div>
                <!-- <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 30px;">
                    <select (change)=" onSelectuser($event.target.value)">
                        <option *ngFor="let type of searchtype" [value]="type.name">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s3 l3 m3" style=" margin-top: 30px; text-align: center ">
                    <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Type your Search</label>
                </div> -->
                <!-- <div class="col s1 l1 m1">
                </div>
                <div class=" input-field col s3 l3 m3" style="margin-top: 10px">
                    <button type="button" (click)="btnsearch($event)" class="waves-effect waves-light btn">Go</button>
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnnew()">New</button>
                </div> -->
            </div>
            <div class="row">
                <div class="col l12 m12 s12"style="margin-top: 10px">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="20" style="text-align: center">Sr. No</th>
                            <th width="40" style="text-align: center">Finanacial Year</th>
                          
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: center">{{lst.srno}}</td>
                                <td style="text-align: center">{{lst.financialYear}}</td>

                               
                                  
                               
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
