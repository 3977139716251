import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService, DealersService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { VisitDetailSmryModel } from "./VisitDetailSmryModel";
declare var $: any;
@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'VisitDetailSmry.html'
})
export class VisitDetailSmry implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private dealersService: DealersService) {

    }
    model = new VisitDetailSmryModel();
    lstindustry: DropDownModel[] = [];
    lstcustomertype: DropDownModel[] = [];
    visitDetaillst: VisitDetailSmryModel[] = [];
    user = new User();
    productList2: any;
    companyname: string;
    companyaddress: string;
    keyword = 'name';
    username: string;
    timing = new Date();
    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.getcustomertype();
        this.getIndustrytype();
    }
    selectFromDate(From) {
        if (From > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.frmdate = new Date()
            return;
        }
    }
    selectToDate(date) {
        if (date < this.model.frmdate) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.todate = new Date()
            return;
        }
        if (date > new Date()) {
            this.notifyService.showRroor("Future date Cannot be Select", "oops");
            this.model.todate = new Date()
            return;
        }
    }
    getSupervisiedAuthName($event) {
        let userId = (<HTMLInputElement>document.getElementById('itemname')).value;
        this.productList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.reportServices.getParantName(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.productList2.length; i++) {
                        map.set(this.productList2[i].name.trim(), this.productList2[i].id);
                        searchDataObj[this.productList2[i].name] = null;
                        if (i == 0) {
                            names = this.productList2[i].name;
                        } else {
                            names = names + "," + this.productList2[i].name;
                        }
                    }

                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt.trim()), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                });
        }
    }

    typeaheadOnSelect(id, name) {
        this.model.accid = id;
        // alert( this.model.accid);
    }

    getcustomertype() {
        this.lstcustomertype = [];
        let user = new User();
        this.lstcustomertype.push({ id: 0, name: "Select Customer Type" });
        //user.sendstring = "prospect";
        this.Masterservice.getAllCustomertypes(user)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstcustomertype.push({ id: item.id, name: item.name });
                });
            });
    }
    onSelectcustmertype(id) {
        this.model.customertypeID = id;
        this.user.trnid = id;
        this.Masterservice.getAlldataBytypeid(this.user)
            .subscribe(value => {
                this.model.industrytypename = value.industrytypename;
                this.model.industrytypeID = value.industryid;

            });

    }
    getIndustrytype() {
        this.lstindustry = [];
        let user = new User();
        this.lstindustry.push({ id: 0, name: "Select Industry" });
        this.Masterservice.getindustorylst(user)
            .subscribe(data => {
                for (let i = 0; i < data.industorylst.length; i++) {
                    this.lstindustry.push({
                        id: data.industorylst[i].id,
                        name: data.industorylst[i].name,
                    })
                }
            });
    }
    onSelectindustry(id) {

        this.model.visitintlst = []
        this.model.industrytypeID = id;
        //alert(this.model.industrytypeID)
        this.model.visitintlst.push(this.model.industrytypeID)
    }

    btnshow(VisitDetailSmryReport: TemplateRef<any>) {
        if (this.model.frmdate == null) {
            this.notifyService.showRroor(" Please select From Date !!", "Oops!");
            return;
        }
        if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
            return;
        }
        this.reportServices.showVisitDetailSmry(this.model)
            .subscribe(
                data => {
                    this.visitDetaillst = data;
                    for (let i = 0; i < this.visitDetaillst.length; i++) {
                        this.companyname = this.visitDetaillst[0].companyname;
                        this.companyaddress = this.visitDetaillst[0].companyaddress;
                    }
                    this.modalRef = this.modalService.show(VisitDetailSmryReport, {
                        class: 'modal-lg'
                    });
                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    //For Autocomplete code
    onChangeSearch(search: string) {
        let searchDataObj = {};
        this.user.message = search;

        this.reportServices.getParantName(this.user)
            .subscribe(data => {
                this.productList2 = [];
                data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id }));

            });
    }
    selectEvent(item) {
        this.model.accid = item.id;
    }
    onFocused(e) {
        // do something
    }
}