<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">

            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="row">
                    <!-- style="margin-top: 3%;" -->
                    <div class="col s1">
                    </div>
                    <div class="input-field col s2 l2 m2" style="text-align: left;margin-bottom: 0px;margin-top: 9px;">
                        <span>Salutation</span>
                        <select (change)=" onSelectSalutation($event.target.value)" style="margin-bottom: 0px;">
                            <option *ngFor="let type of searchtype" [value]="type.name"
                                [selected]="type.name == model.salutation">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s6 l6 m6" style="margin-bottom: 0px;margin-top:26px;">
                        <input [(ngModel)]="model.name" #name="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" type="text" id="first1"
                            data-toggle="tooltip" data-placement="top" title="Name" ngModel maxlength="35"
                            style="margin-bottom: 0px;" required>
                        <label id="lable1" for="first1">Name </label>
                        <div *ngIf="name.invalid && (name.dirty || name.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="name.errors.required">
                                Name Required
                            </div>
                            <div [hidden]="!name.errors?.pattern">
                                Invalid Name
                            </div>
                        </div>
                    </div>
                    <div class="input-field col s2 m2 l2" style="margin-right :9px;margin-bottom: 0px;margin-top: 9px;">
                        <span>Birth Date</span>
                        <input type="text" placeholder="Birth Date" id="birthdate" name="birthdate"
                            [(ngModel)]="model.birthdate" class="form-control" bsDatepicker data-toggle="tooltip"
                            data-placement="top" title="Birth Date"
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                            style="margin-bottom: 0px" (ngModelChange)="selectBirthDate(model.birthdate)">
                    </div>

                    <div class="col s1">
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s2 m2 l2" style="margin-top: 5px;margin-bottom: 0px">
                        <span>Designation</span>
                        <select (change)="onSelectdesignation($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Designation">
                            <option selected value="0">Select Designation</option>
                            <option *ngFor="let lst of designationlst" [value]="lst.id"
                                [selected]="lst.id==model.designationID">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class=" input-field col s4 m4 l4" style="margin-top: 22px;margin-bottom: 0px">
                        <input [(ngModel)]="model.loginName" #loginName="ngModel" type="text"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" id="first2" data-toggle="tooltip"
                            data-placement="top" style="margin-bottom: 0px;" title="Login Name" required>
                        <label id="lable2" for="first2">Login Name</label>
                        <div *ngIf="loginName.invalid && (loginName.dirty || loginName.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="loginName.errors.required">
                                Login name Required
                            </div>
                            <div [hidden]="!loginName.errors?.pattern">
                                Invalid Name Number
                            </div>
                        </div>
                    </div>
                    <div class=" input-field col s4 m4 l4" style="margin-top: 22px;margin-bottom: 0px">
                        <input [(ngModel)]="model.pass" #pass="ngModel" type="text" [disabled]="model.id != null"
                            oninput="validity.valid||(value='');" id="first3" data-toggle="tooltip" data-placement="top"
                            title="Password" style="margin-bottom: 0px;" required>
                        <label id="lable3" for="first3">Password</label>
                        <div *ngIf="pass.invalid && (pass.dirty || pass.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="pass.errors.required">
                                Password Required
                            </div>
                            <div [hidden]="!pass.errors?.pattern">
                                Invalid Password
                            </div>
                        </div>
                    </div>
                    <div class="col s1">
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class=" input-field col s3 m3 l3" style="margin-top: 12px; margin-bottom: 0px;">
                        <input [(ngModel)]="model.telephone" #telephone="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first4" data-toggle="tooltip" data-placement="top"
                            title="Telephone" ngModel maxlength="10" style="margin-bottom: 0px;" required required>
                        <label id="lable4" for="first4">Telephone</label>
                        <div *ngIf="telephone.invalid && (telephone.dirty || telephone.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="telephone.errors.required">
                                Telephone Required
                            </div>
                            <div [hidden]="!telephone.errors?.pattern">
                                Invalid Telephone Number
                            </div>
                        </div>
                    </div>

                    <div class=" input-field col s3 m3 l3" style="margin-top: 12px;margin-bottom: 0px;">
                        <input type="number" pattern="[6789][0-9]{9}" [(ngModel)]="model.mobile" #mobile="ngModel"
                            min="0" oninput="validity.valid||(value='');" id="first5" data-toggle="tooltip"
                            data-placement="top" title="Mobile No" style="margin-bottom: 0px;" required>
                        <label id="lable5" for="first5">Mobile No</label>
                        <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="mobile.errors.required">
                                Mobile No Required
                            </div>
                            <div [hidden]="!mobile.errors?.pattern">
                                Invalid Mobile Number
                            </div>
                        </div>
                    </div>

                    <div class=" input-field col s4 m4 l4" style="margin-top:12px;margin-bottom: 0px;">
                        <input type="text" [(ngModel)]="model.email" #email="ngModel"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="first6" data-toggle="tooltip"
                            data-placement="top" title="Email" style="margin-bottom: 0px;" required>
                        <label id="lable6" for="first6">Email</label>
                        <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="email.errors.required">
                                Email Required
                            </div>
                            <div [hidden]="!email.errors?.pattern">
                                Invalid Email-Id
                            </div>
                        </div>
                        <div class="col s1">
                        </div>
                    </div>
                    <div class="col s1">
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s4 m4 l4" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>Reporting To</span>
                        <select (change)="onSelectreportingto($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Reporting To">
                            <option selected value="0">Select Reporting To</option>
                            <option *ngFor="let lst of reportinglst" [value]="lst.id"
                                [selected]="lst.id==model.reportsToID">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>

                    <div class=" input-field col s3 m3 l3" style="margin-top: 22px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.costRate" #costRate="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first7" title="Cost Rate" data-toggle="tooltip"
                            data-placement="top" ngModel maxlength="10" required>
                        <label id="lable7" for="first7">Cost Rate</label>
                    </div>

                    <div class="input-field col s3 m3 l3 " style="margin-top: 5px;margin-bottom: 0px;">
                        <span>Status</span>
                        <select (change)="onSelectActiveinactive($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Active/Inactive">
                            <option *ngFor="let lst of activeinaclst" [value]="lst.name"
                                [selected]="lst.name==model.activeInactive">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col s1">
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s3 m3 l3" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>Branch</span>
                        <select (change)="onSelectbranch($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Branch">
                            <option selected value="0">Select Branch</option>
                            <option *ngFor="let lst of branchlst" [value]="lst.id"
                                [selected]="lst.id==model.userprefix">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.isadmincheck" #isadmincheck="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Is Admin
                    </div>
                    <div class="input-field col l3 m3 l3" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.showinchkselect" #showinchkselect="ngModel" type="checkbox"
                            name="Allsku" style="text-align: 5px">&nbsp;&nbsp;Do Not Show In Report
                    </div>
                    <!-- <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.seflag" #seflag="ngModel" type="checkbox" name="Allsku"
                            style="text-align: 5px">&nbsp;&nbsp;Is Sales Coordinator
                    </div> -->
                    <div class="col s1">
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s4 m4 l4" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>User Group</span>
                        <select (change)="onSelectusergroup($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="User Group">
                            <option selected value="0">Select User Group</option>
                            <option *ngFor="let lst of usergrouplst" [value]="lst.id"
                                [selected]="lst.id==model.usergroupid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s3 m3 l3" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>Department</span>
                        <select (change)="onSelectdept($event.target.value)" data-toggle="tooltip" data-placement="top"
                            title="Department">
                            <option selected value="0">Select Department</option>
                            <option *ngFor="let lst of departmentlst" [value]="lst.id"
                                [selected]="lst.id==model.deptID">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s3 m3 l3" style="margin-top: 5px;margin-bottom: 0px;">
                        <span>Vehicle type</span>
                        <select (change)="onSelect($event.target.value)" data-toggle="tooltip" data-placement="top"
                            title="Vehicle Type">
                            <option *ngFor="let lst of vehicletypelst" [value]="lst.name"
                                [selected]="lst.name==model.vehicletype">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col s1">
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s3 m3 l3"
                        style="margin-right :10px; margin-top: 5px;margin-bottom: 0px;">
                        <span>Expense Start Date</span>
                        <input type="text" placeholder="Expense Start Date" [(ngModel)]="model.expencestartdate"
                            id="start" data-toggle="tooltip" data-placement="top" title="Expense Start Date"
                            class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">
                    </div>
                    <div class="input-field col s3 m3 l3" style="margin-left :10px;margin-top: 5px;margin-bottom: 0px;">
                        <span>Second Expense Start Date</span>
                        <input type="text" placeholder="Second Expense Start Date"
                            [(ngModel)]="model.expencestartseconddate" id="start" data-toggle="tooltip"
                            data-placement="top" title="Second Expense Start Date" class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">
                    </div>
                    <div class="col s1">
                    </div>
                </div>
                <div class="row">
                    <div class="col s1"></div>
                    <div class="input-field col s4">
                        <span>Spare Inventory Location</span>
                        <select (change)="onSelectInventoryLocation($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Inventory Location">
                            <option selected value="0">Select Spare Inventory Location</option>
                            <option *ngFor="let lst of locationlst" [value]="lst.id"
                                [selected]="lst.id== model.spalocationid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s4">
                        <span>Sales Inventory Location</span>
                        <select (change)="onSelectSalesInventoryLocation($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Inventory Location">
                            <option selected value="0">Select Sales Inventory Location</option>
                            <option *ngFor="let lst of locationlst" [value]="lst.id"
                                [selected]="lst.id== model.salelocationid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>

                </div>

                <div class="row">
                    <div class="input-field col l11 m11 s11" style="text-align: right;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>