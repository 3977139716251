import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, ProductionService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { Pipe } from '@angular/core';
import { DefineServiceChargesModel } from 'src/app/Master/ServiceChergesMaster/DefineServiceChargesModel';
import { ConversionTemplateModel } from './ConversionTemplateModel';
declare var $: any;

@Component({
    selector: 'app-ConversionTemplate',
    templateUrl: 'ConversionTemplate.html',
})
export class ConversionTemplate implements OnInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private masterservice: MasterServices,
        private transactionService: TransactionServices,
        private ProductionService: ProductionService) {
    }
    model: any = {};
    servicemodel = new DefineServiceChargesModel();
    p: any;
    userList: any;
    userList1: any;

    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    subCatlst1: DropDownModel[] = [];
    user = new User();
    id:number;
 
    servicelst1: DefineServiceChargesModel[] = [];
    servicelst: DefineServiceChargesModel[] = [];

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.id = params["id"];
          });
         if(this.id!=null){
            this.user.trnid=this.id;
            this.ProductionService.getconversiondetails(this.user)
                .subscribe((value) => {
                    this.model=value;
                    this.user.trnid = value.itemcategoryid
                    //alert(value);
                    this.masterservice.getSubCategorybycategory(this.user)
                        .subscribe((value) => {
                            this.subCatlst = value
                        });
                });


         } 

        this.model.lstoutputmeteriallst = [];
        this.getitemCategory()
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }
    onSelectitemCat(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Item Category", "Opps");
            return;
        }
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        //alert(value);
        this.masterservice.getSubCategorybycategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectitemCat1(value) {
        this.subCatlst1 = [];
        if (value == 0) {
            this.notifyService.showRroor("Select Item Category", "Opps");
            return;
        }
        let user = new User();
        this.model.omitemcategoryid = value;
        user.trnid = value
        this.masterservice.getSubCategorybycategory(user)
            .subscribe((value) => {
                this.subCatlst1 = value
            });
    }
    btnAdd() {
       let flag:Boolean =false;
        this.model.lstoutputmeteriallst.forEach(element => {
            if(element.itemid==this.model.omitemid){
                flag=true;
            }
        });
        if(flag){
            this.notifyService.showRroor("this Item already present","Opps");
            return;
        }
        if (this.model.omitemcategoryid == null || this.model.omitemcategoryid == 0) {
            this.notifyService.showRroor("select item categories", "Opps");
            return;
        }
        if (this.model.omitemsubcategoryid == null || this.model.omitemsubcategoryid == 0) {
            this.notifyService.showRroor("select item Sub categories", "Opps");
            return;
        }
        if (this.model.omitemid == null || this.model.omitemid == 0) {
            this.notifyService.showRroor("select item ", "Opps");
            return;
        }
        this.model.lstoutputmeteriallst.push({
            shortname: null,
            formulaname: null,
            itemcategoryid: null,
            itemsubcategoryid: null,
            itemname: null,
            itemid: null,
            quantity: null,
            uom: null,
            uomid: null,

            omitemcategoryid: this.model.omitemcategoryid,
            omitemsubcategoryid: this.model.omitemsubcategoryid,
            omitemname: this.model.omitemname,
            omitemid: this.model.omitemid,
            omquantity: this.model.omquantity,
            omuom: this.model.omuom,
            omuomid: this.model.omuomid,
            omdimensions: this.model.omdimensions
        })
        this.model.omitemcategoryid = null;
        this.model.omitemsubcategoryid = null;
        this.model.omitemname = null;
        this.model.omitemid = null;
        this.model.omquantity = null;
        this.model.omuom = null;
        this.model.omuomid = null;
    }
    onSelectSubCat(value) {
        this.userList=[];
        if (value == 0) {
            this.notifyService.showRroor("Select Item Sub Category", "Opps");
            return;
        }
        this.model.itemsubcategoryid = value;
    }
    onSelectSubCat1(value) {
        this.userList1=[];
        if (value == 0) {
            this.notifyService.showRroor("Select Item Sub Category", "Opps");
            return;
        }
        this.model.omitemsubcategoryid = value;
    }

    deleleitem(id,i){
        if (this.model.lstoutputmeteriallst.length <= 1) {
            this.notifyService.showRroor("Enter At Least One Output Material Required", "Opps");
            return;
        }
        if(id!=null){
            this.user.trnid=id;
            this.ProductionService.deleteconversiontemplateitem(this.user)
            .subscribe((value) => {
                if(value.status=="OK"){
                    this.notifyService.showSuccess("Item Deleted","Success");
                    this.model.lstoutputmeteriallst.splice(i,1)
                }
            });
        }else{
            this.model.lstoutputmeteriallst.splice(i,1)
        }
      
    }
    getItemLike() {
        if (this.model.itemcategoryid == null || this.model.itemcategoryid == 0) {
            this.notifyService.showRroor("select item categories", "Opps");
            return;
        }
        if (this.model.itemsubcategoryid == null || this.model.itemsubcategoryid == 0) {
            this.notifyService.showRroor("select item Sub categories", "Opps");
            return;
        }
       
        let userId = (<HTMLInputElement>document.getElementById('productname')).value;
        this.userList = [];
        console.log(userId)
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList.push({
                        "itemname": item.itemname,
                        "itemid": item.itemid,
                        "uom": item.uom,
                        "dimensions": item.dimensions,
                        "diamensionUnit": item.diamensionUnit,
                    }));

                    var names = "";
                    for (let i = 0; i < this.userList.length; i++) {

                        searchDataObj[this.userList[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList[i].itemname;
                        } else {
                            names = names + "," + this.userList[i].itemname;
                        }
                    }
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem(txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem(txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadONselectitem(itemname) {
        this.userList.forEach(element => {
            if (element.itemname.trim() == itemname.trim()) {
                this.model.itemid = element.itemid;
                this.model.itemname = itemname;
                this.model.uom = element.uom;
                this.model.quantity = 1;
                this.model.dimensions = element.dimensions;
                this.model.diamensionUnit = element.diamensionUnit;
            }
        });
    }
    getItemLike1() {
        let userId = (<HTMLInputElement>document.getElementById('productname1')).value;
        this.userList1 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.model.omitemcategoryid;
        this.user.trnid = this.model.omitemsubcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList1.push({
                        "itemname": item.itemname,
                        "itemid": item.itemid,
                        "uom": item.uom,
                        "dimensions": item.dimensions,
                        "diamensionUnit": item.diamensionUnit,
                    }));
                    var names = "";
                    for (let i = 0; i < this.userList1.length; i++) {
                        searchDataObj[this.userList1[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList1[i].itemname;
                        } else {
                            names = names + "," + this.userList1[i].itemname;
                        }
                    }
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem1(txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem1(txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadONselectitem1(itemname) {
        this.userList1.forEach(element => {
            if (element.itemname.trim() == itemname.trim() ) {
                this.model.omitemid = element.itemid;
                this.model.omitemname = itemname;
                this.model.omuom = element.uom;
                this.model.omdimensions = element.dimensions;
            }
        });
    }
    btnsave() {
        if (this.model.shortname == null || this.model.shortname == "") {
            this.notifyService.showRroor("Please Enter Short name", "Opps");
            return;
        }
        if (this.model.formulaname == null || this.model.formulaname == "") {
            this.notifyService.showRroor("Please  Enter Formula Name", "Opps");
            return;
        }
        if (this.model.itemcategoryid == null || this.model.itemcategoryid == 0) {
            this.notifyService.showRroor("select item categories", "Opps");
            return;
        }
        if (this.model.itemsubcategoryid == null || this.model.itemsubcategoryid == 0) {
            this.notifyService.showRroor("select item Sub categories", "Opps");
            return;
        }
        if (this.model.quantity == null || this.model.quantity == 0) {
            this.notifyService.showRroor("Enter Valid Quantity", "Opps");
            return;
        }

        if (this.model.lstoutputmeteriallst.length <= 0) {
            this.notifyService.showRroor("Enter At Least One Output Material Required", "Opps");
            return;
        }
        this.ProductionService.saveconversiontemplate(this.model)
            .subscribe(data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess("Formula Created", "Opps");
                    this.router.navigate(['/Search Conversion']);
                } else if (data.status == "Repete") {
                    this.notifyService.showSuccess("Formula Already Present", "Opps");
                    return;
                }
            });
    }
    cancle() {
        this.router.navigate(['/Search Conversion']);
    }
}