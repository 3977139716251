import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DealersDetailModel, GeographyModel, ProductModel1 } from "../DealersDetail/DealersDetailModel";
import { DefineDealerLogisticsModel } from "../DealersDetail/DefineDealerLogisticsModel";
import { DefinedlrcontModel } from "../DealersDetail/DefinedlrcontModel";
import { SearchDealerModel } from "../DealersDetail/SearchDealerModel";
import { Products } from "../Transactions/DailyCall/DailyCallModel";
import { DefinePuchaseOrderModel } from "../Transactions/DealerPurchaseOrder/DefinePuchaseOrderModel";
import { SearchPurchaseorderModel } from "../Transactions/DealerPurchaseOrder/SearchPurchaseorderModel";
// import { SearchMCHallocaTemplateModel } from "../Transactions/MarketingCostHead%AllocaTemplate/SearchMCHallocaTemplateModel";
import { User } from "../_models";
import { DropDownModel } from "../_models/DropDownModel";
import { SaveSuccessModel } from "../_models/SaveSucessModel";
import { common } from "./Common";
import { HandleError, HttpErrorHandler } from "./http-error-handler.service";

@Injectable()
export class DealersService {

  private headers = new Headers({
    'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Token': localStorage.getItem('Token'),
    'dbName': localStorage.getItem('dbName')
  });
  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
  }


  getAllDealersDetail(user: User): Observable<SearchDealerModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchDealerModel[]>(this.customersUrl + 'api/dealer/getAllDealersDetail', user, httpOptions1);
  }

  getdealersdetailsById(usr: DealersDetailModel): Observable<DealersDetailModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DealersDetailModel>(this.customersUrl + 'api/dealer/getdealersdetailsById', usr, httpOptions1);
  }

  savedealersdetail(usr: DealersDetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/savedealersdetail', usr, httpOptions1);
  }
  deletedealersdetail(user: SearchDealerModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/deletedealersdetail', user, httpOptions1);
  }
  getProductsByPCategory(usr: DealersDetailModel): Observable<DealersDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DealersDetailModel[]>(this.customersUrl + 'api/dealer/getProductsByPCategory', usr, httpOptions1);
  }
  getProductLike(user: User): Observable<DealersDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DealersDetailModel[]>(this.customersUrl + 'api/dealer/getProductLike', user, httpOptions1);
  }
  deletedealerpro(user: DealersDetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/deletedealerpro', user, httpOptions1);
  }

  savedealerproduct(model: DealersDetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/savedealerproduct', model, httpOptions1);
  }
  getalllstbyLocaleid(usr: GeographyModel): Observable<DealersDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DealersDetailModel[]>(this.customersUrl + 'api/dealer/getalllstbyLocaleid', usr, httpOptions1);
  }
  getcountry(model: User): Observable<DefinedlrcontModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefinedlrcontModel>(this.customersUrl + 'api/dealer/getcountry', model, httpOptions1);
  }
  deletedealercont(model: DefinedlrcontModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/deletedealercont', model, httpOptions1);
  }
  savedealercont(usr: DefinedlrcontModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/savedealercont', usr, httpOptions1);
  }
  getdealerid(model: DealersDetailModel): Observable<DefinedlrcontModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinedlrcontModel>(this.customersUrl + 'api/dealer/getdealerid', model, httpOptions1);
  }

  savedealerlogistic(usr: DefineDealerLogisticsModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/savedealerlogistic', usr, httpOptions1);
  }

  getshippingadd(model: DealersDetailModel): Observable<DefineDealerLogisticsModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineDealerLogisticsModel>(this.customersUrl + 'api/dealer/getshippingadd', model, httpOptions1);
  }
  deleteshippingadd(model: DefineDealerLogisticsModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/deleteshippingadd', model, httpOptions1);
  }
  savedealergeography(usr: GeographyModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/savedealergeography', usr, httpOptions1);
  }

  getgeographyByid(model: DealersDetailModel): Observable<DealersDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DealersDetailModel[]>(this.customersUrl + 'api/dealer/getgeographyByid', model, httpOptions1);
  }
  getproductcat(usr: User): Observable<DefinePuchaseOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<DefinePuchaseOrderModel>(this.customersUrl + 'api/dealer/getproductcat', usr, httpOptions1);
  }
  getpurchaseordByid(model: DefinePuchaseOrderModel): Observable<DefinePuchaseOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePuchaseOrderModel>(this.customersUrl + 'api/dealer/getpurchaseordByid', model, httpOptions1);
  }
  getProductsByPCat(user: User): Observable<Products[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<Products[]>(this.customersUrl + 'api/dealer/getProductsByPCat', user, httpOptions1);
  }
  getcustomerdetail(model: DefinePuchaseOrderModel): Observable<DefinePuchaseOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePuchaseOrderModel>(this.customersUrl + 'api/dealer/getcustomerdetail', model, httpOptions1);
  }
  getAllpurchesorder(model: SearchPurchaseorderModel): Observable<SearchPurchaseorderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchPurchaseorderModel[]>(this.customersUrl + 'api/dealer/getAllpurchesorder', model, httpOptions1);
  }
  savepurchaseOrder(usr: DefinePuchaseOrderModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/savepurchaseOrder', usr, httpOptions1);
  }
  getaddressofcustomers(model: DefinePuchaseOrderModel): Observable<DefinePuchaseOrderModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefinePuchaseOrderModel>(this.customersUrl + 'api/dealer/getaddressofcustomers', model, httpOptions1);
  }

  getDealerByLocality(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/dealer/getDealerByLocality', user, httpOptions1);
  }

 

  savedealersdtl(usr: DealersDetailModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
        'dbName': localStorage.getItem('dbName')
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/dealer/savedealersdtl', usr, httpOptions1);
  }
  getAllVendorDetails(user: User): Observable<SearchDealerModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchDealerModel[]>(this.customersUrl + 'api/dealer/getAllVendorDetails', user, httpOptions1);
  }

  getdealerproduct(model: DealersDetailModel): Observable<DealersDetailModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DealersDetailModel[]>(this.customersUrl + 'api/dealer/getdealerproduct', model, httpOptions1);
  }
  vieworders(model: SearchPurchaseorderModel): Observable<SearchPurchaseorderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchPurchaseorderModel[]>(this.customersUrl + 'api/dealer/vieworders', model, httpOptions1);
  }
  closeorders(model: SearchPurchaseorderModel): Observable<SearchPurchaseorderModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchPurchaseorderModel[]>(this.customersUrl + 'api/dealer/closeorders', model, httpOptions1);
  }
  
  getDealerByCity(user: User): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/dealer/getDealerByCity', user, httpOptions1);
  }
  getdeliveryloc(model: DealersDetailModel): Observable<DropDownModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DropDownModel[]>(this.customersUrl + 'api/dealer/getdeliveryloc', model, httpOptions1);
  }
  
}
