export class DefineVendorModel{
    userID: number;
    code: string;
    name:string;
    address:string;
    landline: string;
    email: number;
    pincode: string;
    website: string;
    chkwihitins:boolean;
    chkoutsides:boolean;
    wigstflag:string;
    outgstflag:string;
    city: string;
    state: string;
    country: string;
    locale: string;
    localeID: number;
    localityID:number;
   // locality:string;
    Locality:string;
    gstNo:number;
    panNo:number;
    creditDays:number;
    gsttype:string;

    sgst: boolean;
    igst: boolean;
    utgst: boolean;
    sgstflag: string;
    igstflag: string;
    utgstflag: string;
    chkgst:boolean;
    contactlst1: Contacts[];
    //contactlst: Contacts[]=[];
}
export class Contacts {
    chkcontact: boolean;
    id: number;
    contactName: string;
    designation: string;
    designationID: number;
    mobileNo1: string;
    email: string;

}