import { OnInit, Component, TemplateRef } from '@angular/core';
import { AuthenticationService, MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { ProspectModel, sparemodel } from '../Sales-Transaction/ProspectModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { Definethreefieldmodel } from 'src/app/_models/Definethreefieldmodel';
import { TeamModel } from '../Teams/TeamModel';
import { DailyCallModel, Products } from '../DailyCall/DailyCallModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { QuotationModel, QuotationProductModel, TermsAndConditionsModel } from '../Quotation/QuotationModel';
import { SearchActivityModel } from 'src/app/Master/ActivityMaster/SearchActivityModel';
import { ServiceProductModel } from './ServiceProductModel';
// var converter = require('number-to-words');
import convertor from 'rupees-to-words';
import * as moment from 'moment';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { threadId } from 'worker_threads';
import { ThrowStmt } from '@angular/compiler';
import { SchemeConfigurateModel } from '../Scheme_Configurator/SchemeConfigurateModel';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-Service',
  templateUrl: 'Servicecall.html',
})
export class ServiceCall implements OnInit {
  constructor(private Masterservice: MasterServices,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    private router: Router,
    private modalService: BsModalService,
    private transactionservice: TransactionServices,
    private deviceService: DeviceDetectorService,
    private authenticationService: AuthenticationService) {
  }
  user = new User();
  id: number;
  enquiryId: number;
  modalRef2: BsModalRef;
  // totalamount: number = 0;
  // total: number = 0
  // totalgstamount: number = 0;
  teammodel = new TeamModel();
  lstassigned: DropDownModel[] = [];
  lstproblem: DropDownModel[] = [];
  totalamountwords: string;
  lstactivity: Definethreefieldmodel[] = [];
  tandclst: TermsAndConditionsModel[] = [];

  callTypelst: SearchActivityModel[] = [];
  model = new ProspectModel();
  qOTP: boolean = false;
  resendOTP: boolean = false;
  showgst: boolean = false;
  changeActivity: boolean = true;
  forflag: string;
  dcrHistorylst: DailyCallModel[] = []
  sermodel = new ServiceProductModel();
  customervalue: number;
  spareProductcatelst: DropDownModel[] = [];
  deviceInfo = null;
  isMobile = null;
  mainmenus: Mainmenumodel[] = [];
  procategorylst: DropDownModel[] = [];
  productlst: Products[] = [];
  pid: number;
  pname: number;
  username: string;
  sparedescription: string;
  gstid: number;
  gstpersentage: number;
  gstamount: number;
  quantity: number = 1;
  rate: number;
  uom: string;
  cashcheckselect: boolean;
  type: string;
  uomid: number;
  amount: number;
  checkselect: boolean;
  spareProductid: number;
  finalamount: number;
  name: number;
  spareid: number;
  showOtp: boolean = false;
  genOtp: boolean = false;
  codehide: boolean = false;
  schemeModel = new SchemeConfigurateModel();
  totalamount: number
  productEnquirylst: QuotationProductModel[];
  listprice: number;
  model1 = new QuotationModel();
  lstspare: sparemodel[] = [];
  discount: number;
  discountAmount: number;
  netamount: number;
  totalnewamt: number;
  totalproamountnew: number;
  offereddiscount: number;
  newdiscountamt: number;
  ratewithdiscount: number;
  totalproamount: number;
  amountInWords: string;
  rate1: number;


  ngOnInit() {

    this.mainmenus = JSON.parse(localStorage.getItem('menu'));
    this.username = localStorage.getItem('fullname');
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    // console.log(this.deviceInfo);
    // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice);

    this.route.queryParams.subscribe(params => {
      this.model.id = + params["id"];
      this.forflag = params["forflag"];
    });

    this.model.userid = Number(localStorage.getItem("userid"));
    this.teammodel.userid = Number(localStorage.getItem("userid"));

    if (this.model.id) {
      this.getdetails();
    } else {
      this.getallactivity();
    }
    this.problemcategories();
    // this.getAssignedtocall();
    this.getProductcat();
  }
  onchagnedesrption(indx) {

  }


  getdetails() {
    this.transactionservice.getservicecalldetails(this.model)
      .subscribe(data => {
        this.model = data;
        this.model1.id = data.id;
        this.model1.schmemCode = data.schmemCode;
        this.model1.schemetype = data.schemetype;
        this.model1.customnumber = data.customnumber;
        this.model1.quotationAmount = this.model.totalamount
        this.model1.discountAmount = data.discountAmount;
        this.model1.discount = data.discount;
        this.model1.chkschemeflag = data.chkschemeflag;
        this.teammodel.pincode = data.pincode;
        this.model1.lstspare = data.lstspare;
        this.model.nodallowedbeforeservicecall = data.nodallowedbeforeservicecall;
        this.getAssignedtocall();

        // if (data.calldate != null) {
        //   this.model.calldate = new Date(data.calldate);
        // //this.model.calldate = moment(new Date(), "DD/MM/YYYY").add(this.model.customervalue, 'days').toDate()
        // } 
        //  else {
        // // this.model.calldate = new Date();
        // this.model.calldate = moment(new Date(), "DD/MM/YYYY").add(this.model.customervalue, 'days').toDate()
        //  }
        for(let i = 0 ; i < this.model1.lstspare.length ; i++)
        {
            this.model1.lstspare[i].customnumber = this.model1.customnumber
        }
        this.model.calldate = new Date();
        this.model.calltime = new Date().toString().split(' ')[4];
        if (data.followupdate != null) {
          this.model.followupdate = new Date(data.followupdate);
        } else {
          this.model.followupdate = new Date();
        }
        this.model.total = 0;
        this.model.totalgstamount = 0;
        this.model.totalamount = 0;
        // this.model.remark = null;
        // this.model.observationandanalysis = null;
        this.model.totalamountwords = null;
        this.sermodel.Otpshow = data.sendserviceOTP;
        this.sermodel.closureOTP = data.sendclosureOTP;
        if (data.activityflag == "Q") {
          this.qOTP = true;
          this.changeActivity = false;
          if (this.model.productspare) {
            this.model.showspare = true;
          }
          this.resendOTP = true;
        }
        if (data.activityflag == "I") {
          this.model.close = true;
        }
        if (data.sendclosureOTP != null) {
          this.model.closureOTPsend = true;
        }

        if (this.model.activityID == null) {
          this.lstactivity.forEach(element => {
            if (element.code == "Q") {
              this.model.activityID = element.id;
              this.model.activity = element.name
              this.model.showspare = true;
              this.model.flag = element.code;
              this.changeActivity = false;
            }
          });
        }

        this.user.calltype = data.serviceCallStatusName;
        // this.user.calltype ="Under Warranty";
        // this.user.calltype ="Under AMC";
        // this.user.calltype ="Out of AMC/Warranty";
        // alert(this.user.calltype);
        this.getallactivity();


        this.model.lstcontact.forEach(element => {
          this.model.contactname = element.contactname;
        });

        for (let i = 0; i < this.model.lstproduct.length; i++) {
          this.model.productID = this.model.lstproduct[i].productid
          this.model.productCategoryID = this.model.lstproduct[i].productCategoryID

          if (this.model.lstproduct[i].status == 'O') {
            this.model.lstproduct[i].status = "Out of Warrenty"
          }
          if (this.model.lstproduct[i].status == 'W') {
            this.model.lstproduct[i].status = "In Warrenty"
          }
        }
        // if (data.serviceOTPverifed) {
        //  this.changeActivity = true;
        // this.lstactivity.forEach(element => {
        //   if (element.code == "R") {
        //     this.model.activityID = element.id;
        //   }
        //   // if (element.code == "I") {
        //   //   this.model.activityID = element.id;
        //   // }
        //   if(this.model.reistallation&&element.code == "Re"){
        //     this.model.activityID = element.id;
        //   }
        // });
        // this.resendOTP=false;
        //}

        if (data.closureOTPverifed) {
          this.model.closeotpverify = true;
          this.changeActivity = false;
        }
        if (data.invocesend) {
          this.model.closureOTPsend = true;
          this.model.closeotpverify = true;
          this.changeActivity = false;
        }
        this.user.trnid = this.model.customertypeID;
        this.Masterservice.getAlldataBytypeid(this.user)
          .subscribe(value => {
            this.showgst = value.chkSERshwgst;
            this.model.chkdesignation = value.chkdesignation;
          });
        this.getAssignedto(this.model.areaID);
        if (this.model.lstspare.length == 1) {
          this.model.lstspare.forEach(item => {
            item.checkselect = true;
          });
        }
        for (let i = 0; i < this.model1.lstspare.length; i++) {
          this.schemeModel.schemeDiscount = this.model1.lstspare[i].discountAmount;
          this.totalamount = 0;
          if (this.model1.schmemCode != null) {
            // this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
            // this.productEnquirylst[i].totalamount = this.totalamount;
            this.model.total = this.model.total + (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity);
            this.model.totalgstamount = this.model.totalgstamount + this.model1.lstspare[i].gstamount;
            this.model.totalamount = this.model.totalamount + this.model1.lstspare[i].amount
            this.model.totalamountwords = convertor(this.model.totalamount.toFixed(2));
          }
        }
        if (this.model1.schmemCode == null) {
          for (let i = 0; i < this.model1.lstspare.length; i++) {
            let tax1 = (this.model1.lstspare[i].rate * this.model1.lstspare[i].gstpersentage) / 100;
            this.model1.tax = tax1;
            this.model1.lstspare[i].gstamount = (tax1 * this.model1.lstspare[i].quantity)
            this.model1.lstspare[i].netamount = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity)
            this.model1.lstspare[i].amount = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity) + this.model1.lstspare[i].gstamount;
            this.model1.lstspare[i].amount = Math.round(this.model1.lstspare[i].amount)
            this.model.total = this.model.total + (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity);
            this.totalamount = this.totalamount + this.model1.lstspare[i].amount;
            this.model1.lstspare[i].totalamount = this.totalamount;
            this.model1.totalamount = this.totalamount;
            this.model.totalamount = this.model1.totalamount
            this.amountInWords = convertor(Math.round(this.model.totalamount).toFixed(2));
            this.model.totalamountwords = this.amountInWords
            this.model1.lstspare[i].discount = this.discount;
          }
        }

        // this.model.lstspare.forEach(element => {
        //   // alert(this.model.lstspare.length);
        //   for (let i = 0; i < this.model.lstspare.length; i++) {
        //     if (this.model.lstspare[i].quantity == null && this.model.lstspare[i].checkselect == true) {
        //       this.model.lstspare[i].quantity = 1;
        //     }
        //     if (this.model.lstspare[i].quantity != null && this.model.lstspare[i].quantity > 0) {
        //       // this.model.lstspare[i].cashcheckselect = true;
        //       this.model.lstspare[i].amount = this.model.lstspare[i].quantity * this.model.lstspare[i].rate;
        //       this.model.lstspare[i].amount = Math.round(this.model.lstspare[i].amount);
        //       if (this.model.lstspare[i].gstpersentage != null) {
        //         this.model.lstspare[i].gstamount = (this.model.lstspare[i].amount / 100) * this.model.lstspare[i].gstpersentage;
        //         this.model.lstspare[i].gstamount = Math.round(this.model.lstspare[i].gstamount);
        //       }
        //       // if (this.model.lstspare[i].gstamount != null) {
        //       //   this.model.lstspare[i].amount = this.model.lstspare[i].amount + this.model.lstspare[i].gstamount
        //       // }
        //     }

        //   }

        //   // this.model.totalamount = element.amount;
        //   // this.model.total = this.model.total + element.amount;
        //   // if (element.cashcheckselect) {
        //   this.model.total = this.model.total + element.amount;
        //   this.model.totalgstamount = this.model.totalgstamount + element.gstamount;
        //   this.model.totalamount = this.model.totalamount + element.amount + element.gstamount
        //   // }
        // });
        // this.model.totalamountwords = converter.toWords(this.model.totalamount);
        // this.model.totalamountwords = convertor(this.model.totalamount.toFixed(2));

        // this.totalamount=data.amount;
        // if(this.totalamount!=null){
        //   this.totalamountwords=converter.toWords(this.totalamount);
        // }
        this.model.reistallation = data.reistallation;

        // this.model.customertypeID=data.customertypeID;
        // if(this.model.customertypeID==1){
        //   this.model.chkdesignation= true;
        // }
      });
    this.onChangeOfPrice();

  }
  problemcategories() {
    this.lstproblem = [];
    this.lstproblem.push({ id: 0, name: "Select Problem Category" });
    this.transactionservice.getproblemcat(this.user)
      .subscribe(data => {
        data.forEach((item) =>
          this.lstproblem.push({ id: item.id, name: item.name }));
      });
  }
  cashcodesave() {
    this.transactionservice.SendAndsaveCashOTP(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("OTP Send", "Success");

        }
      });



  }

  btngetDCRDetails(DCRModel: TemplateRef<any>) {

    this.transactionservice.getDCRHestory(this.model)
      .subscribe(data => {
        this.dcrHistorylst = data
      });

    this.modalRef2 = this.modalService.show(DCRModel, {
      class: 'modal-lg'
    });
  }
  getallactivity() {
    for (let i = 0; i < this.model.lstproduct.length; i++) {
      // if (this.model.lstproduct[i].id == value) {
      if (this.model.reistallation == true) {
        this.user.statusCode = "O";
      } else {
        this.user.statusCode = this.model.lstproduct[i].status
      }
      // }
    }
    this.lstactivity = [];
    this.lstactivity.push({ id: 0, name: "Select Activity", code: "" });
    this.transactionservice.getallactivity(this.user)
      .subscribe(data => {
        data.forEach((item) =>
          //this.callTypelst = 
          this.lstactivity.push({ id: item.id, name: item.name, code: item.code }));
      });
  }
  getAssignedto(id) {
    this.teammodel.areaid = id;
    this.teammodel.pcInt = [];
    this.model.lstproduct.forEach(element => {
      // this.model.lstproduct[i].status
      this.teammodel.pcInt.push(element.productCategoryID);
    });
    this.lstassigned = [];
    this.lstassigned.push({ id: 0, name: "Select Assigned To" })
    this.transactionservice.getAssignedtoList(this.teammodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstassigned.push({ id: item.id, name: item.name });
        });
      });

  }
  btnresendOTP() {
    // if(this.model.)
  }

  OnfollowChecked($event) {
    if ($event.target.checked) {
      this.model.close = false;
    }
  }
  Onclosechecked($event) {
    if ($event.target.checked) {
      this.model.follow = false;
      this.lstactivity.forEach(element => {
        if (element.code == "I") {
          this.model.activityID = element.id;
        }
      });
    }
  }


  verifyOTP() {
    if (this.model.serviceOTP.length == 6) {
      this.transactionservice.VerifyOTP(this.model).subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("OTP verifed", "Success");
          this.changeActivity = true;
          this.resendOTP = false;
          this.lstactivity.forEach(element => {
            if (element.code == "R") {
              this.model.activityID = element.id;
              this.model.showspare = false;
            }
            if (this.model.reistallation && element.code == "Re") {
              this.model.activityID = element.id;
            }
          });
        } else {
          this.notifyService.showRroor("Enter Valid OTP", "Success");
        }
      });


    }
  }


  onSelectactivity(value) {
    this.model.showspare = false;
    if (value != 0) {
      this.model.activityID = value;
      // for (let i = 0; i < this.model.lstproduct.length; i++) {
      //   // if (this.model.lstproduct[i].id == value) {
      //   this.user.statusCode = this.model.lstproduct[i].status
      //   // }
      // }
      this.lstactivity.forEach(element => {
        this.model.activity = element.name;
        if (element.id == value && element.code == "Q") {
          this.model.showspare = true;
          this.model.flag = element.code;
        }
        if (element.id == value && element.code == "I") {
          if (this.model.serviceOTPverifed) {
            this.model.close = true;
            this.model.follow = false;
            this.model.flag = element.code;
          } else {
            this.notifyService.showRroor("Send Quotation first", "Opps");
          }

        }
        if (element.id == value) {
          this.model.flag = element.code;
        }
      });

    } else {
      this.notifyService.showRroor("Select Valid Activity!", "Opps");
      this.model.showspare = false;
      return;
    }
  }

  onSelectAssignto(value) {
    this.model.followupassingto = value;
  }
  onSelectfollowupactivity(id) {
    this.model.followupactivityID = id;
  }

  btnsendquotation() {
    let flag = false;
    if (this.model.calldate == null) {
      this.notifyService.showRroor("Please Enter call Date", "Opps");
      return;
    } else if (this.model.calldate > new Date()) {
      this.notifyService.showRroor("Please Enter Valid  call Date", "Opps");
      return;
    }
    if (this.model.calltime == null) {
      this.notifyService.showRroor("Please Enter call Time", "Opps");
      return;
    }
    if (this.model.status == "O") {
      this.model.lstspare.forEach(element => {
        if (element.cashcheckselect) {
          flag = true;
        }
      });
      // if (!flag) {
      //   this.notifyService.showRroor("Please Select At least on Service", "Opps");
      //   return;
      // }
    }

    this.model.userid = Number(localStorage.getItem("userid"));
    this.model.flag = "Q";
    this.transactionservice.SaveServiceCall(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          if (data.flag == "Q") {
            this.model.sparequotationID = Number(data.message);
            // this.model.sendserviceOTP = "123456";
            // this.transactionservice.SendAndsaveOTP(this.model).subscribe(otpdata => {
            // if (otpdata.status == "OK") {
            this.notifyService.showSuccess("Quatation OTP Send", "Success");
            this.qOTP = true;
            this.resendOTP = true;
            // this.changeActivity = false;

            this.getdetails();
            // }
            // });
          }
          this.notifyService.showSuccess("Service call saved", "Success");
          // this.router.navigate(("/Service Call"));
          this.router.navigate(['/Service Call']);
        }
      });

  }
  btnsendinvoince() {
    this.transactionservice.Saveserviceinvoice(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("Invoice Send By SMS", "Success");
          this.model.invocesend = true;
        }
      });
  }

  btnsave() {
    let flag = false;
    if (this.model.calldate == null) {
      this.notifyService.showRroor("Please Enter call Date", "Opps");
      return;
    }
    // else if (this.model.calldate > new Date()) {
    //   this.notifyService.showRroor("Please Enter Valid  call Date", "Opps");
    //   return;
    // }
    if (this.model.calltime == null) {
      this.notifyService.showRroor("Please Enter call Time", "Opps");
      return;
    }
    if (!this.model.close) {
      if (this.model.remark == null) {
        this.notifyService.showRroor("Please Enter Remarks", "Opps");
        return;
      }
    } else {
      if (this.model.observationandanalysis == null) {
        this.notifyService.showRroor("Please Enter Observation And Analysis", "Opps");
        return;
      }
    }

    if (this.model.status == "O") {
      this.model.lstspare.forEach(element => {
        if (element.cashcheckselect) {
          flag = true;
        }
      });
      // if (!flag) {
      //   this.notifyService.showRroor("Please Select At least on Service", "Opps");
      //   return;
      // }
    }


    this.model.userid = Number(localStorage.getItem("userid"));
    this.model.showOtp = this.showOtp;
    this.model.genOtp = this.genOtp;
    this.model.schmemCode = this.model1.schmemCode
    this.model.discount = this.model1.discount;
    this.model.discountAmount = this.model1.discountAmount;
    this.model.customnumber = this.model1.customnumber
    for (let i = 0; i < this.model1.lstspare.length; i++) {
      this.model.ratewithdiscount = this.model1.lstspare[i].ratewithdiscount;
    }
    this.transactionservice.SaveServiceCall(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          if (data.flag == "Q") {
            //   this.model.sendserviceOTP = "123456";
            //   this.model.sparequotationID = Number(data.message);
            //   this.transactionservice.SendAndsaveOTP(this.model).subscribe(data => {
            //     if (data.status == "OK") {
            // this.notifyService.showSuccess("Quatation OTP Send", "Success");
            this.qOTP = true;
            //     }
            //   });
            //   //this.notifyService.showSuccess("Service call saved", "Success");
            //   // this.router.navigate[("ServiceCall")];

          }
          if (this.model.closeotpverify && this.model.close) {
            this.btnsendinvoince();

          }
          this.notifyService.showSuccess("Service call saved", "Success");
          //  this.router.navigate[("/Service Call")];
          // this.router.navigate(['/ServiceCall']);
          this.ngOnInit();
        }
      });
  }
  onSelectlstproblem(value) {
    this.model.problemcategoryid = value;
  }

  btngenrateOTP() {
    // this.model.closureOTPsend = true;

    this.transactionservice.SaveServiceCall(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          // this.model.sendclosureOTP = "123456";
          // this.transactionservice.SendAndsaveOTP(this.model).subscribe(data => {
          // if (data.status == "OK") {
          this.model.serviceOTP = data.closecallOTP;
          this.sermodel.closureOTP = this.model.serviceOTP;
          this.notifyService.showSuccess("OTP Send", "Success");
          // this.ngOnInit();
          // }
          // });
        }
      });

  }
  verifyClosureOTP() {

    if (this.model.closureOTP.length == 6) {
      this.transactionservice.VerifyOTP(this.model).subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("OTP verifed", "Success");
          this.model.closeotpverify = true;
          this.changeActivity = false;
        } else {
          this.notifyService.showRroor("Enter Valid OTP", "Success");
        }
      })
    }


  }

  btnback() {
    this.router.navigate(['/Service Call']);
  }

  quantitychange(i) {
    this.model.totalamount = 0;
    this.model.totalgstamount = 0;
    this.model.total = 0;
    if (this.model1.lstspare[i].quantity != null && this.model1.lstspare[i].quantity >= 0) {
      // this.model.lstspare[i].cashcheckselect = true;
      this.model1.lstspare[i].amount = this.model1.lstspare[i].quantity * this.model1.lstspare[i].rate;
      this.model1.lstspare[i].amount = Math.round(this.model1.lstspare[i].amount);
      if (this.model1.lstspare[i].gstpersentage != null) {
        this.model1.lstspare[i].gstamount = (this.model1.lstspare[i].amount / 100) * this.model1.lstspare[i].gstpersentage;
        this.model1.lstspare[i].gstamount = Math.round(this.model1.lstspare[i].gstamount);
      }

      // if (this.model.lstspare[i].gstamount != null) {
      //   // this.model.lstspare[i].finalamount = this.model.lstspare[i].amount + this.model.lstspare[i].gstamount
      //   this.model.lstspare[i].amount = this.model.lstspare[i].amount + this.model.lstspare[i].gstamount
      // }


    } else {
      this.model1.lstspare[i].cashcheckselect = false;
    }
    // this.model.lstspare.forEach(element => {
    //   // if (element.cashcheckselect) {
    //   this.model.total = this.model.total + element.amount;
    //   // this.model.totalgstamount = this.model.totalgstamount + element.gstamount;
    //   this.model.totalgstamount = (this.model.total / 100) * this.gstpersentage
    //   this.model.totalgstamount = Math.round(this.model.totalgstamount);
    //   // }
    // });
    this.model1.lstspare.forEach(element => {
      // if (element.cashcheckselect) {
      this.model.total = this.model.total + element.amount;
      this.model.totalgstamount = this.model.totalgstamount + Math.round((element.amount / 100) * element.gstpersentage)
      // }
    });
    this.model.totalamount = this.model.total + this.model.totalgstamount;
    this.model.totalamountwords = convertor(this.model.totalamount.toFixed(2));
  }

  onSelectassigned(id) {
    this.model.newassignedtoid = id;
  }
  getAssignedtocall() {
    this.teammodel.localeid = this.model.localeid;
    this.teammodel.userid;
    this.lstassigned = [];
    if (this.lstassigned.length == 1) {
      this.notifyService.showRroor("No resource available for this pincode", " Oops");
    }
    // this.lstassigned.push({ id: 0, name: "Select Assigned To" })
    // this.transactionservice.getAssignedtoList1(this.model)
    this.transactionservice.getAssignedtoListService(this.teammodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstassigned.push({ id: item.id, name: item.name });

        });


      });
  }
  selectFollowupDate(date) {
    let pastdate = moment(new Date(), "DD/MM/YYYY").subtract(1, 'days').toDate();
    if (date <= pastdate) {
      this.notifyService.showRroor("Previous date cannot be selected", "oops");
      this.model.followupdate = new Date()
      return;
    }
  }
  selectCallDate(date) {
    let pastdate = moment(new Date(), "DD/MM/YYYY").subtract(this.model.nodallowedbeforeservicecall + 1, 'days').toDate()
    if (date <= pastdate) {
      this.notifyService.showRroor("Previous date cannot be selected", "oops");
      this.model.calldate = new Date()
      return;
    }
    else if (date >= new Date()) {
      this.notifyService.showRroor("Future date cannot be selected", "oops");
      this.model.calldate = new Date()
      return;
    }

  }

  btngetDCRDetailsMobile(DCRModel: TemplateRef<any>) {

    this.transactionservice.getDCRHestory(this.model)
      .subscribe(data => {
        this.dcrHistorylst = data
      });
  }
  redirectUser(mainmenulabel: string) {
    this.router.navigate(['/' + mainmenulabel]);
  }
  btnlogout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  getProductcat() {

    this.Masterservice.getSpareListCat(this.user)
      .subscribe(data => {
        this.spareProductcatelst = data.procategorylst;
        //this.sparedescription = data.description;
      });

  }
  getSpareByProd(pid) {
    this.productlst = [];
    this.user.trnid = pid;
    this.user.userid = Number(localStorage.getItem("userid"));
    this.model.lstproduct.forEach(item => {
      this.user.productID = item.productid;
    });
    this.transactionservice.getProductsByCategory(this.user)
      .subscribe(
        data => {
          this.productlst = data
        });


  }
  onSelectProCat(id) {
    this.getSpareByProd(id);
    for (let i = 0; i < this.productlst.length; i++) {
      if (this.productlst[i].pcid == id) {
        this.pid = this.productlst[i].pcid;
        this.pname = this.productlst[i].name
      }
    }
  }
  onSelectPro(value) {
    for (let j = 0; j < this.productlst.length; j++) {
      if (this.productlst[j].pid == value) {
        this.sparedescription = this.productlst[j].description;
        this.gstpersentage = this.productlst[j].taxpercent
        this.gstid = this.productlst[j].gstid;
        if (this.productlst[j].taxpercent != null) {
          this.productlst[j].gstamount = (this.productlst[j].price / 100) * this.productlst[j].taxpercent;
          this.productlst[j].gstamount = Math.round(this.productlst[j].gstamount);

        }
        this.gstamount = this.productlst[j].gstamount;
        this.rate = this.productlst[j].minimumPrice
        this.uom = this.productlst[j].uom;
        this.type = this.productlst[j].type;
        this.amount = this.productlst[j].price;
        this.checkselect = this.productlst[j].chkselectpro;
        this.finalamount = this.productlst[j].price;
        this.name = this.productlst[j].name;
        this.pid = this.productlst[j].pid;
        this.spareid = this.productlst[j].spareid;
      }
    }

  }
  btnAddProduct() {
    for (let i = 0; i < this.model1.lstspare.length; i++) {
      if (this.model1.lstspare[i].pid == this.pid || this.model1.lstspare[i].spareProductid == this.pid) {
        this.notifyService.showRroor("Product already added!", "Oops!");
        return;
      }
    }
    this.model.totalamount = 0;
    this.model.totalgstamount = 0;
    this.model.total = 0;
    this.model1.lstspare.push({
      spareid: this.spareid,
      sparedescription: this.sparedescription,
      gstid: this.gstid,
      gstpersentage: this.gstpersentage,
      gstamount: this.gstamount,
      quantity: this.quantity,
      rate: this.rate,
      uom: this.uom,
      cashcheckselect: null,
      type: this.type,
      uomid: null,
      amount: Math.round(this.rate * this.quantity),
      checkselect: true,
      spareProductid: this.spareProductid,
      finalamount: this.finalamount,
      id: null,
      pid: this.pid,
      name: null,
      listprice: this.listprice,
      discount: this.discount,
      discountAmount: this.discountAmount,
      netamount: this.netamount,
      totalproamountnew: this.totalproamountnew,
      offereddiscount: this.offereddiscount,
      newdiscountamt: this.newdiscountamt,
      ratewithdiscount: this.ratewithdiscount,
      totalproamount: this.totalproamount,
      totalamount: this.totalamount,
      rate1: this.rate1,
      customnumber:null
    });
    //   if (this.model1.schmemCode != null) {
    //     this.btnApplyScheme();
    // }
    this.onChangeOfPrice();
    // this.model.lstspare.forEach(element => {
    //   // if (element.cashcheckselect) {
    //   this.model.total = this.model.total + element.amount;
    //   this.model.totalgstamount = this.model.totalgstamount + (element.amount / 100) * element.gstpersentage

    //   // this.model.totalgstamount = (this.model.total / 100) * this.gstpersentage

    //   // this.model.totalgstamount = this.model.totalgstamount + element.gstamount;
    //   // for (let i = 0; i < this.model.lstspare.length; i++) {
    //   //   if (this.model.lstspare[i].gstpersentage != null) {
    //   //     this.model.lstspare[i].gstamount = (this.model.lstspare[i].amount / 100) * this.model.lstspare[i].gstpersentage;
    //   //     this.model.lstspare[i].gstamount = Math.round(this.model.lstspare[i].gstamount);
    //   //     this.gstamount = this.model.lstspare[i].gstamount;
    //   //     this.model.totalgstamount = this.model.totalgstamount + this.gstamount;
    //   //     this.model.totalgstamount = Math.round(this.model.totalgstamount);
    //   //   }
    //   // }

    // });
    // this.model.totalamount = this.model.total + this.model.totalgstamount;
    // this.model.totalamount = Math.round(this.model.totalamount);
    // // this.model.totalamountwords = converter.toWords(this.model.totalamount);
    // this.model.totalamountwords = convertor(this.model.totalamount.toFixed(2));
    // // }

  }
  Onucheckselect(i) {
    this.model1.lstspare[i].quantity = 0;
    // this.quantitychange(i);
    this.onChangeOfPrice();
  }
  showotp() {
    this.showOtp = true;
  }
  closureOTP() {
    this.genOtp = true;
  }
  removeScheme() {
    this.codehide = false;
    this.model1.schmemCode = null;
    this.schemeModel.schemeDiscount = 0;
    this.schemeModel.maxdiscount = 0;
    this.schemeModel.discountType = null;
    this.model1.customnumber = null;
    this.model1.schemetype = null;
    this.onChangeOfPrice();
  }
  onChangeOfPrice() {
    this.totalamount = 0;
    for (let i = 0; i < this.model1.lstspare.length; i++) {
      if (this.model1.chkschemeflag != 'Y') {

        this.model1.lstspare[i].discount = 0;
        this.model1.lstspare[i].discountAmount = 0;
        this.model1.lstspare[i].customnumber = 0;
      }
      if (this.schemeModel.schemeDiscount != null && this.schemeModel.schemeDiscount > 0) {
        if (this.schemeModel.discountType == "percentage") {
          //Scheme Discount Type Percentage
          this.model1.lstspare[i].discount = this.schemeModel.schemeDiscount;
        } else {
          this.model1.lstspare[i].discountAmount = this.schemeModel.schemeDiscount
          // this.model.lstspare[i].discount = 0;
        }

      }
      if (this.model1.customnumber != null && this.model1.customnumber > 0) {
        if (this.schemeModel.discountType == "Custom") {
            this.model1.lstspare[i].customnumber = this.model1.customnumber
        }
    }
      if (this.model1.schmemCode != null) {
        this.model.total = this.model.total + this.model1.lstspare[i].amount;
        this.model.totalgstamount = this.model.totalgstamount + this.model1.lstspare[i].gstamount;
        this.model.totalamount = this.model.totalamount + this.model1.lstspare[i].amount + this.model1.lstspare[i].gstamount
      }
    }


    //       }
    //       if (this.model.lstspare[i].discount != null && this.model.lstspare[i].discount > 0 && this.model.schemetype == "percentage" || this.schemeModel.discountType == "percentage" || this.model.chkschemeflag == 'N') {

    //           //Scheme Discount Type Percentage
    //           this.totalamount = 0;
    //           // for (let i = 0; i < this.model.lstspare.length; i++) {
    //           //     let discount = this.model.lstspare[i].listprice * this.model.lstspare[i].discount / 100;
    //           //     this.model.lstspare[i].offeredPrice = (this.model.lstspare[i].listprice) - discount;
    //           //     // this.model.lstspare[i].ratewithdiscount = this.model.lstspare[i].offeredPrice / this.model.lstspare[i].quantity;
    //           //     this.model.lstspare[i].ratewithdiscount = this.model.lstspare[i].offeredPrice;

    //           //     this.model.lstspare[i].discountAmount = discount * this.model.lstspare[i].quantity;

    //           //     if (this.model.orderaccp == "EndCustomer") {
    //           //         if (this.schemeModel.maxdiscount != null) {
    //           //             if (this.schemeModel.maxdiscount < this.model.lstspare[i].discountAmount) {
    //           //                 this.model.lstspare[i].discountAmount = this.schemeModel.maxdiscount
    //           //                 let disAmount = this.model.lstspare[i].discountAmount / this.model.lstspare.length
    //           //                 this.model.lstspare[i].offeredPrice = (this.model.lstspare[i].listprice) - disAmount;
    //           //                 this.model.lstspare[i].discount = disAmount * 100 / this.model.lstspare[i].listprice;
    //           //             }
    //           //         }
    //           //     }
    //           //     let tax1 = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].taxpercent) / 100;
    //           //     this.model.tax = tax1;
    //           //     this.model.lstspare[i].taxamount = (tax1 * this.model.lstspare[i].quantity)
    //           //     this.model.lstspare[i].netamount = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity)
    //           //     this.model.lstspare[i].totalproamount = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity) + this.model.lstspare[i].taxamount;
    //           //     this.model.lstspare[i].totalproamount = Math.round(this.model.lstspare[i].totalproamount);
    //           //     this.totalamount = this.totalamount + this.model.lstspare[i].totalproamount;
    //           //     this.model.lstspare[i].totalamount = this.totalamount;
    //           //     // this.model.lstspare[i].offeredPrice = this.model.lstspare[i].offeredPrice + tax1;
    //           //     this.amountInWords = convertor(Math.round(this.model.lstspare[i].totalamount).toFixed(2));
    //           //     this.model.quotationAmount = this.totalamount

    //           // }

    //           let listtotal = 0;
    //           let fulldiscount = 0;
    //           let fulltotal = 0;
    //           let fulltotaldiscount = 0;
    //           let lsdiscount = 0;
    //           for (let i = 0; i < this.model.lstspare.length; i++) {

    //               listtotal = this.model.lstspare[i].listprice * this.model.lstspare[i].quantity;
    //               fulltotal = fulltotal + listtotal;
    //               // fulldiscount = listtotal * this.model.lstspare[i].discount / 100;
    //               // alert(fulldiscount + 'fulldiscount')
    //               lsdiscount = this.model.lstspare[i].discount;

    //           }
    //           fulltotaldiscount = fulltotal * lsdiscount / 100;
    //           for (let i = 0; i < this.model.lstspare.length; i++) {

    //               if (this.model.orderaccp == "EndCustomer") {
    //                   if (this.schemeModel.maxdiscount != null) {
    //                       if (this.schemeModel.maxdiscount < fulltotaldiscount) {
    //                           // fulltotaldiscount = this.schemeModel.maxdiscount
    //                           this.model.lstspare[i].discount = this.schemeModel.maxdiscount * 100 / fulltotal;
    //                       }
    //                   }
    //               }
    //               // }
    //               // for (let i = 0; i < this.model.lstspare.length; i++) {
    //               this.model.lstspare[i].discountAmount = (this.model.lstspare[i].listprice * this.model.lstspare[i].quantity) * this.model.lstspare[i].discount / 100;
    //               this.model.lstspare[i].offeredPrice = ((this.model.lstspare[i].listprice * this.model.lstspare[i].quantity) - this.model.lstspare[i].discountAmount) / this.model.lstspare[i].quantity;
    //               this.model.lstspare[i].ratewithdiscount = this.model.lstspare[i].offeredPrice;
    //               let tax1 = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].taxpercent) / 100;
    //               // this.model.tax = tax1;
    //               this.model.lstspare[i].taxamount = (tax1 * this.model.lstspare[i].quantity)
    //               this.model.lstspare[i].netamount = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity)
    //               this.model.lstspare[i].totalproamount = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity) + this.model.lstspare[i].taxamount;
    //               this.model.lstspare[i].totalproamount = Math.round(this.model.lstspare[i].totalproamount);
    //               this.totalamount = this.totalamount + this.model.lstspare[i].totalproamount;
    //               this.model.lstspare[i].totalamount = this.totalamount;
    //               // this.model.lstspare[i].offeredPrice = this.model.lstspare[i].offeredPrice + tax1;
    //               this.amountInWords = convertor(Math.round(this.model.lstspare[i].totalamount).toFixed(2));
    //               this.model.quotationAmount = this.totalamount
    //           }


    //           // let tax1 = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].taxpercent) / 100;
    //           // this.model.tax = tax1;
    //           // this.model.lstspare[i].taxamount = (tax1 * this.model.lstspare[i].quantity)
    //           // this.model.lstspare[i].netamount = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity)
    //           // this.model.lstspare[i].totalproamount = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity) + this.model.lstspare[i].taxamount;
    //           // // alert(this.model.lstspare[i].totalproamount.toFixed(2));
    //           // this.totalamount = this.totalamount + this.model.lstspare[i].totalproamount;
    //           // this.model.lstspare[i].totalamount = this.totalamount;
    //           // // alert(this.model.lstspare[i].totalamount);
    //           // // this.model.lstspare[i].offeredPrice = this.model.lstspare[i].offeredPrice + tax1;
    //           // this.amountInWords = convertor(this.model.lstspare[i].totalamount.toFixed(2));
    //           // this.model.quotationAmount = this.totalamount
    //           // // alert(this.model.quotationAmount)

    //       } else if (this.model.orderaccp == "EndCustomer" && this.model.lstspare[i].discountAmount != null && this.model.lstspare[i].discountAmount > 0) {
    if(this.schemeModel.discountType != "Custom")
    {
    this.totalnewamt = 0;
    this.totalamount = 0;
    let disAmount = 0;
    this.model.total = 0;
    //           //Scheme Discount Type Value
    for (let i = 0; i < this.model1.lstspare.length; i++) {
      let tax1 = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity * this.model1.lstspare[i].gstpersentage) / 100;
      this.model1.lstspare[i].gstamount = tax1
      this.model1.lstspare[i].netamount = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity)
      this.model1.lstspare[i].totalproamountnew = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity) + this.model1.lstspare[i].gstamount;
      this.totalnewamt = this.totalnewamt + this.model.lstspare[i].totalproamountnew;
      disAmount = this.model1.lstspare[i].discountAmount;
    }
    for (let i = 0; i < this.model.lstspare.length; i++) {
      this.offereddiscount = disAmount * 100 / this.totalnewamt;
      this.model1.lstspare[i].offereddiscount = this.offereddiscount;
      this.model1.lstspare[i].discount = this.model.lstspare[i].offereddiscount;
      this.model1.lstspare[i].newdiscountamt = (this.model1.lstspare[i].totalproamountnew * this.model1.lstspare[i].discount) / 100;
      this.model1.lstspare[i].netamount = (this.model1.lstspare[i].rate * this.model.lstspare[i].quantity)
      this.model1.lstspare[i].ratewithdiscount = this.model1.lstspare[i].netamount - ((this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity * this.model1.lstspare[i].discount) / 100)
      let tax1 = (this.model1.lstspare[i].ratewithdiscount * this.model1.lstspare[i].gstpersentage) / 100;
      this.model1.lstspare[i].gstamount = tax1;
      this.model1.lstspare[i].amount = this.model1.lstspare[i].ratewithdiscount + tax1;
      this.model1.lstspare[i].amount = Math.round(this.model1.lstspare[i].amount)
      this.model.total = this.model.total + (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity);
      this.model1.lstspare[i].ratewithdiscount = this.model1.lstspare[i].ratewithdiscount / this.model1.lstspare[i].quantity;
      this.totalamount = this.totalamount + this.model1.lstspare[i].amount;
      this.model1.totalamount = this.totalamount
      this.model.totalamount = this.model1.totalamount;
      this.amountInWords = convertor(Math.round(this.model.totalamount).toFixed(2));
      this.model.totalamountwords = this.amountInWords
      this.model1.lstspare[i].listprice = this.model1.lstspare[i].rate 
      // this.model1.lstspare[i].rate1 = this.model1.lstspare[i].ratewithdiscount

    }
  }
  else if(this.schemeModel.discountType == "Custom"){
    this.totalnewamt = 0;
    this.totalamount = 0;
    let disAmount = 0;
    this.model.total = 0;
    //           //Scheme Discount Type Custom
    for (let i = 0; i < this.model1.lstspare.length; i++) {
      let tax1 = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity * this.model1.lstspare[i].gstpersentage) / 100;
      this.model1.lstspare[i].gstamount = tax1
      this.model1.lstspare[i].netamount = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity)
      this.model1.lstspare[i].totalproamountnew = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity) + this.model1.lstspare[i].gstamount;
      this.totalnewamt = this.totalnewamt + this.model.lstspare[i].totalproamountnew;
      disAmount = this.model1.lstspare[i].customnumber;
    }
    for (let i = 0; i < this.model.lstspare.length; i++) {
      this.offereddiscount = disAmount * 100 / this.totalnewamt;
      this.model1.lstspare[i].offereddiscount = this.offereddiscount;
      this.model1.lstspare[i].discount = this.model.lstspare[i].offereddiscount;
      this.model1.lstspare[i].newdiscountamt = (this.model1.lstspare[i].totalproamountnew * this.model1.lstspare[i].discount) / 100;
      this.model1.lstspare[i].netamount = (this.model1.lstspare[i].rate * this.model.lstspare[i].quantity)
      this.model1.lstspare[i].ratewithdiscount = this.model1.lstspare[i].netamount - ((this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity * this.model1.lstspare[i].discount) / 100)
      let tax1 = (this.model1.lstspare[i].ratewithdiscount * this.model1.lstspare[i].gstpersentage) / 100;
      this.model1.lstspare[i].gstamount = tax1;
      this.model1.lstspare[i].amount = this.model1.lstspare[i].ratewithdiscount + tax1;
      this.model1.lstspare[i].amount = Math.round(this.model1.lstspare[i].amount)
      this.model.total = this.model.total + (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity);
      this.model1.lstspare[i].ratewithdiscount = this.model1.lstspare[i].ratewithdiscount / this.model1.lstspare[i].quantity;
      this.totalamount = this.totalamount + this.model1.lstspare[i].amount;
      this.model1.totalamount = this.totalamount
      this.model.totalamount = this.model1.totalamount;
      this.amountInWords = convertor(Math.round(this.model.totalamount).toFixed(2));
      this.model.totalamountwords = this.amountInWords
      this.model1.lstspare[i].listprice = this.model1.lstspare[i].rate 
  }
}

    //           // this.totalamount = 0;
    //           // this.totalproamount = 0;
    //           // let discountAmount = 0 ;
    //           // //Scheme Discount Type Value
    //           // for (let i = 0; i < this.model.lstspare.length; i++) {

    //           //     let tax1 = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].taxpercent) / 100;
    //           //     this.model.lstspare[i].taxamount = (tax11 * this.model.lstspare[i].quantity)
    //           //     this.model.lstspare[i].netamount = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity)
    //           //     this.totalproamount = this.totalproamount +  ((this.model.lstspare[i].offeredPrice * this.model.lstspare[i].quantity) + this.model.lstspare[i].taxamount);
    //           // }

    //           // for (let i = 0; i < this.model.lstspare.length; i++) {
    //           //     let discount = this.model.lstspare[i].discountAmount * 100 / this.totalproamount;
    //           //     this.model.lstspare[i].discount = discount;

    //           //      discountAmount = (this.model.lstspare[i].listprice * this.model.lstspare[i].quantity) * this.model.lstspare[i].discount / 100;
    //           //     this.model.lstspare[i].offeredPrice = (this.model.lstspare[i].listprice * this.model.lstspare[i].quantity) - discountAmount;
    //           //     this.model.lstspare[i].ratewithdiscount = this.model.lstspare[i].offeredPrice;

    //           //     let tax1 = (this.model.lstspare[i].offeredPrice * this.model.lstspare[i].taxpercent) / 100;
    //           //     this.model.tax = tax1;
    //           //     this.model.lstspare[i].taxamount = tax1;
    //           //     this.model.lstspare[i].netamount = (this.model.lstspare[i].offeredPrice)
    //           //     this.model.lstspare[i].totalproamount = (this.model.lstspare[i].offeredPrice) + tax1;
    //           //     this.model.lstspare[i].totalproamount = Math.round(this.model.lstspare[i].totalproamount);
    //           //     this.totalamount = this.totalamount + this.model.lstspare[i].totalproamount;
    //           //     this.model.lstspare[i].totalamount = this.totalamount;
    //           //     this.amountInWords = convertor(Math.round(this.model.lstspare[i].totalamount).toFixed(2));
    //           //     this.model.quotationAmount = this.totalamount
    //           // }
    //       }
    //       else {
    //           this.model.lstspare[i].offeredPrice = (this.model.lstspare[i].listprice);
    //       }
    if (this.model1.schmemCode == null) {
      // && this.model1.chkschemeflag == 'Y'
      this.totalamount = 0;
      this.discount = 0;
      this.model.total = 0;
      for (let i = 0; i < this.model1.lstspare.length; i++) {
        let tax1 = (this.model1.lstspare[i].rate * this.model1.lstspare[i].gstpersentage) / 100;
        this.model1.tax = tax1;
        this.model1.lstspare[i].gstamount = (tax1 * this.model1.lstspare[i].quantity)
        this.model1.lstspare[i].netamount = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity)
        this.model1.lstspare[i].amount = (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity) + this.model1.lstspare[i].gstamount;
        this.model1.lstspare[i].amount = Math.round(this.model1.lstspare[i].amount)
        this.model.total = this.model.total + (this.model1.lstspare[i].rate * this.model1.lstspare[i].quantity);
        this.totalamount = this.totalamount + this.model1.lstspare[i].amount;
        this.model1.lstspare[i].totalamount = this.totalamount;
        this.model1.totalamount = this.totalamount;
        this.model.totalamount = this.model1.totalamount
        this.amountInWords = convertor(Math.round(this.model.totalamount).toFixed(2));
        this.model.totalamountwords = this.amountInWords
        this.model1.lstspare[i].discount = this.discount;
        this.model1.lstspare[i].listprice = this.model1.lstspare[i].rate;
        // this.model1.lstspare[i].rate1 = this.model1.lstspare[i].ratewithdiscount
      }
    }
    //   }
    //   var found = this.model.lstspare.find(function (x) { return x.approvedQuo === false; });
    //   if (found) {
    //       this.approvedQuo = false;
    //       this.model.approvedQuo = false;
    //   } else {
    //       this.approvedQuo = true;
    //       this.model.approvedQuo = true;
    //   }
  }
  btnApplyScheme() {
    this.codehide = true;
    if (this.model1.lstspare.length < 0) {
      this.notifyService.showRroor("Please select at least one Product", "Oops!");
      return;
    }
    if (this.model1.schmemCode == null || this.model1.schmemCode == "") {
      this.notifyService.showRroor("Please enter scheme code", "Oops!");
      return;
    }
    if (this.schemeModel.discountType == "Custom") {
      if (this.model1.customnumber == null || this.model1.customnumber.toString() == "") {
          this.notifyService.showRroor("Please Enter Custom Number", "Oops!");
          return;
      }
  }
  if(this.schemeModel.discountType == "Custom")
  {
      
      if(this.model1.customnumber > this.model.totalamount)
      {
          this.notifyService.showRroor("Please Enter Valid Custom Number", "Oops!");
          return;
      }
  }

    this.schemeModel.schemeDiscount = 0;
    this.schemeModel.maxdiscount = 0;
    // this.model1.lstspare = this.lstspare;
    this.model1.lstspare = this.model.lstspare;
    this.model1.quotationAmount = this.model.totalamount
    this.model1.schemetype = this.schemeModel.discountType;

    this.transactionservice.btnApplySchemeCodeforservice(this.model1)
      .subscribe(
        data => {
          if (data.status == "OK") {
            this.schemeModel = data;
            this.onChangeOfPrice();
            this.notifyService.showSuccess(data.message, "Done!");
          } else if (data.status == "NO") {
            this.notifyService.showRroor(data.message, "Done!");
            return;
          }
        });
  }
  selectSchemeCode(data)
  {
    this.model1.schmemCode = data;
    // this.model1.productEnquirylst = null;
    if (this.model1.schmemCode.length > 4) {
        this.Masterservice.getSchemeType(this.model1)
            .subscribe(
                data => {
                    this.schemeModel = data;
                })
    }
  }

}