export class CorporateCustProcurementSmryModel {

    frmdate: Date;
    todate: Date;
    companyname: string;
    companyaddress: string;
    customertypeID: number;
    customertypename: String;
    industrytypeID: number;
    industrytypename: String;
    corporateintlst: number[];
    
    customerName:String;
    supervisingAuth:String;
    suppiler:String;
    item:String;
    rate:number;
    qty:number;
    invoiceNo:String;
    invoiceDate:Date;
    amount:number;
    serialNo:number;
    partyName: string;
}
