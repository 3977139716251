import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, ProductionService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { Pipe } from '@angular/core';
import { DefineServiceChargesModel } from 'src/app/Master/ServiceChergesMaster/DefineServiceChargesModel';
import { DailyProductionPlanModel } from './DailyProductionPlanModel';
declare var $: any;

@Component({
    selector: 'app-SearchDailyProductionPlan',
    templateUrl: 'SearchDailyProductionPlan.html',
})
export class SearchDailyProductionPlan implements OnInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private transactionService: TransactionServices,
        private ProductionService: ProductionService) {
    }
    model: any = {};
    lstplan:DailyProductionPlanModel[]=[];
    searchtype:DropDownModel[]=[
        { id: 1, "name": "All" },
        { id: 2, "name": "Plan ID" },
        { id: 2, "name": "Location" },
    ];
    user = new User();
    txtserch:string;
    txtselect:string;
    ngOnInit(){
        this.ProductionService.getAllDailyplan(this.user)
            .subscribe(
                data => {
                   this.lstplan=data;
                });
    }

    btnnew(){
        this.router.navigate(['/DailyProductionPlan']);
    }

    btnsearch(){

    }
    onSelect(value){

    }

    btnedit(id){
        let navigationExtras: NavigationExtras = {
            queryParams: {
              "id":id,
            }
          };
          this.router.navigate(['/DailyProductionPlan'],navigationExtras);
    }
    btnview(id){
        let navigationExtras: NavigationExtras = {
            queryParams: {
              "id":id,
            }
          };
          this.router.navigate(['/PlanRequisition'],navigationExtras);
    }
    btndelts(id){
        
    }
}