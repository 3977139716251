import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { searchgstheadermodel } from './searchgstheadermodel';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router/';
import { AlertService, AuthenticationService, MasterServices } from '../../_services';
import { Location } from '@angular/common';
import { User } from '../../_models/user';
import { DropDownModel } from '../../_models/DropDownModel';
import { NotificationService } from 'src/app/_services/notification.service';


@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'searchgstheader.html'
})

export class searchgstheader implements OnInit {
    pagename: string;
    p: any;
    model = new searchgstheadermodel();
    user = new User();
    public i: any = 0;
    id: number;
    items = [];
    public userid: string;
    taxmodellst: searchgstheadermodel[] = [];
    taxmodellst1: searchgstheadermodel[] = [];
    str: string;


    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Name" },
    ];
    constructor(private Masterservice: MasterServices, private notifyService: NotificationService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location) {
        this.pagename = this.router.url
    }
    ngOnInit() {
        this.getall();
        this.model.userid = Number(localStorage.getItem("userid"));
        // localStorage.setItem("pagename",this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }
    // reloadItems(params) {
    //     this.itemResource.query(params).then(items => this.items = items);
    // }

    getall(): void {
        this.Masterservice.getallgsthdr(this.user)
            .subscribe(value => {
                this.taxmodellst = [];
                this.taxmodellst1 = [];
                value.forEach((item) =>
                    this.taxmodellst.push({ srno: item.srno, id: item.id, name: item.name, persentage: item.persentage, txtserch: item.txtserch, txtselect: item.txtserch, userid: item.userid,percent:null }));
                this.taxmodellst1 = this.taxmodellst;
                // this.itemResource.count().then(count => this.itemCount = count);
                // this.reloadItems({ "offset": 0, "limit": 10 });
            });
    }


    onSelect(value) {
        for (let i = 0; i < this.searchtype.length; i++) {
            if (this.searchtype[i].name == value) {
                this.model.txtselect = this.searchtype[i].name;
            }
        }
    }

    btnnew() {
        this.router.navigate(['/Gstheadermaster']);
    }

    btnedit(value) {
        this.model.id = value;
        this.router.navigate(['/gsthdr', value]);
    }

    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getall()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Name") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.taxmodellst1 = [];
                this.taxmodellst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.taxmodellst1.push({ srno: item.srno, id: item.id, name: item.name, persentage: item.persentage, txtserch: item.txtserch, txtselect: item.txtserch, userid: item.userid,percent:null });
                    }
                });
                this.taxmodellst = this.taxmodellst1;
            }
        }
        // if (this.model.txtselect == "All") {

        //     // this.itemResource.refresh(this.alllist1);
        //     // this.itemResource.refresh(this.alllist);
        //     // this.itemResource.count().then(count => this.itemCount = count);
        //     // this.reloadItems({ "offset": 0, "limit": 10 });

        //         this.getall();

        //     this.model.txtserch = null;
        // }
        // else if (this.model.txtselect == "Name") {

        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
        //         return;
        //     }
        //     else {
        //         this.taxmodellst = [];
        //         this.taxmodellst1.forEach((item) => {
        //             if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.taxmodellst.push({ srno: item.srno, id: item.id, name: item.name, persentage: item.persentage, txtserch: item.txtserch, txtselect: item.txtserch });
        //             }
        //         });
        //         // this.itemResource.refresh(this.alllist);
        //         // this.itemResource.count().then(count => this.itemCount = count);
        //         if (this.taxmodellst.length <= 10) {
        //             // this.dataTable.SETLIM(this.alllist.length);
        //             // this.reloadItems({ "offset": 0, "limit": this.alllist.length });
        //         }
        //         else {
        //             // this.reloadItems({ "offset": 0, "limit": 10 });
        //         }
        //     }
        // } 

    }


    btndelts(id) {
        this.model.id = this.id;
        this.userid = localStorage.getItem('userid');
        this.user.userid = Number(this.userid);
        this.i = confirm("Do you want to delete this record ?");
        if (this.i == true) {
            this.Masterservice.deletegsthdr(this.model)
                .subscribe(
                    data => {
                        if (data.status == "No") {
                            this.notifyService.showRroor("Something Wrong !", "Error !");
                        }
                        else {
                            if (data.status == "OK") {
                                this.notifyService.showSuccess("Data Deleted successfully !!", " Success");
                                this.str = "Group";
                                let navigationExtras: NavigationExtras = {
                                    queryParams: {
                                        "pagename": this.str
                                    }
                                };
                                this.router.navigate(['/Group'], navigationExtras);
                            }
                        }
                    },
                    error => {
                    });
        } else {
            alert("You have selected cancel...");
        }
    }
    creategroup(id, grp, perc) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "grp": grp,
                "id": id,
                "perc": perc
            }
        };
        this.router.navigate(['/gstgroup'], navigationExtras);
    }
}