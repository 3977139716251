import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { MasterConfiguration } from './ConfigurationPages/MasterConfiguration/MasterConfiguration';
import { CRMHome } from './CRMHome/CRMHome';
import { SearchUser } from './Master/ResourceMaster/SearchUser';
import { DefineUser } from './Master/ResourceMaster/DefineUser';
import { SearchAccountMaster } from './Master/AccountMaster/SearchAccountMaster';
import { DefineAccountMaster } from './Master/AccountMaster/DefineAccountMaster';
import { SearchPipeline } from './Master/PipelineMaster/SearchPipeline';
import { DefinePipeline } from './Master/PipelineMaster/DefinePipeline';
import { SearchAccountWiseMachineDetail } from './Master/AccountWiseMachineDetailMaster/SearchAccountWiseMachineDetail';
import { DefineAccountwisemachinedetail } from './Master/AccountWiseMachineDetailMaster/DefineAccountwisemachinedetail';
import { SearchCompanyMaster } from './Master/CompanyMaster/SearchCompanyMaster';
import { DefineCompanyMaster } from './Master/CompanyMaster/DefineCompanyMaster';
import { MasterHomePage } from './Master/ConfiguratedMasters/MasterHomePage';
import { DefineMaster } from './Master/ConfiguratedMasters/DefineMaster';
import { SearchEnquiryname } from './Master/EnquiryNameMaster/SearchEnquiryname';
import { SearchCurrencyExchange } from './Master/CurrencyExchange/SearchCurrencyExchange';
import { SearchProduct } from './Master/ProductMaster/SearchProduct';
import { DefineProduct } from './Master/ProductMaster/DefineProduct';
import { SearchResourceAllowance } from './Master/ResourceAllowance/SearchResourceAllowance';
import { DefineResourceAllowance } from './Master/ResourceAllowance/DefineResourceAllowance';
import { SearchTypeMaster } from './Master/TypeMaster/SearchTypeMaster';
import { DefineTypeMaster } from './Master/TypeMaster/DefineTypeMaster';
import { DefineAMCType } from './Master/AMCTypeMaster/DefineAMCType';
import { SearchAMCType } from './Master/AMCTypeMaster/SearchAMCType';
import { SearchLocality } from './Master/Locality/SearchLocality';
import { DefineLocality } from './Master/Locality/DefineLocality';
import { DesignationAllowance } from './Master/DesignationMaster/DesignationAllowance';

import { SelectUser } from './menu/SelectUser/SelectUser';
import { AddMenuComponent } from './menu/add-menu/add-menu.component';
import { ContextmenuComponent } from './menu/contextmenu.component';
import { SearchMenuItem } from './menu/Menu_Items/SearchMenuItem';
import { DefineMenuItem } from './menu/Menu_Items/DefineMenuItem';
import { SearchUserGroup } from './menu/UserGroup/SearchUserGroup';
import { DefineAllowance } from './Master/AllowanceMaster/DefineAllowance';
import { SearchAllowanceMaster } from './Master/AllowanceMaster/SearchAllowanceMaster';
import { SearchSource } from './Master/SourceMaster/SearchSource';
import { DefineSource } from './Master/SourceMaster/DefineSource';
import { SourceDetail } from './Master/SourceMaster/SourceDetail';
import { SearchCategoryTermsCon } from './Master/CategoryTermConditionMaster/SearchCategoryTermsCon';
import { DefineCategoryTermsCon } from './Master/CategoryTermConditionMaster/DefineCategoryTermsCon';

import { SearchCityMaster } from './Master/CityMaster/SearchCityMaster';
import { DefineCityMaster } from './Master/CityMaster/DefineCityMaster';
import { CustomerTypeWorkflow } from './Master/CustomerTypeWorkflow/CustomerTypeWorkflow';
import { ResourceRights } from './Master/ResourceRights/ResourceRights';
import { Prospect } from './Transactions/Sales-Transaction/Prospect';
import { SearchTeam } from './Transactions/Teams/SearchTeam';
import { DefineTeam } from './Transactions/Teams/DefineTeam';
import { DailyCall } from './Transactions/DailyCall/DailyCall';
import { CallCenterDashboard } from './Transactions/CallCenterDashboard/CallCenterDashboard';
import { Opportunities } from './Transactions/Opportunities/Opportunities';
import { Quotation } from './Transactions/Quotation/Quotation';
import { searchgstheader } from './Master/GSTForm/searchgstheader';
import { Gstheadermaster } from './Master/GSTForm/Gstheadermaster';
import { GSTgroupmaster } from './Master/GSTForm/GSTgroupmaster';
import { ChangePassword } from './Master/ChangePassword/ChangePassword';
import { ResetPassword } from './Master/ResetPassword/ResetPassword';
import { SearchTarget } from './Transactions/TargetManagement/SearchTarget';
import { SearchActivity } from './Master/ActivityMaster/SearchActivity';
import { DefineActivity } from './Master/ActivityMaster/DefineActivity';
import { Financialyear } from './Master/FinancialYear/Financialyear';
import { SearchUOM } from './Master/UnitofMeasure/SearchUOM';
import { DefineMarketing } from './Master/MarketingCostSubMaster/DefineMarketing';
import { SearchMarketing } from './Master/MarketingCostSubMaster/SearchMarketing';

import { TargetHome } from './Transactions/TargetManagement/ TargetHome';
import { ServiceCall } from './Transactions/ServiceCallTransaction/Servicecall';
import { OrderHomepage } from './Transactions/OrderAcceptance/OrderHomepage';
import { ServiceCallDashboard } from './Transactions/ServiceCallDashboard/ServiceCallDashboard';
import { DefineOrderAcceptance } from './Transactions/OrderAcceptance/DefineOrderAcceptance';
import { SearchDealer } from './DealersDetail/SearchDealer';
import { DealersDetail } from './DealersDetail/DealersDetail';
import { PaymentScreen } from './Transactions/PaymentScreen/PaymentScreen';
import { SearchProductcategory } from './Master/ProductCategoryMaster/SearchProductcategory';
import { DefineProductcategory } from './Master/ProductCategoryMaster/DefineProductcategory';
// import { DefineitemSubCategory } from './Master/ItemSubCategoryMaster /DefineitemSubCategory';
// import { SearchitemSubCategory } from './Master/ItemSubCategoryMaster /SearchitemSubCategory';
import { SearchInventoryLoc } from './Master/InventoryLocationMaster/SearchInventoryLoc';
import { DefineInventoryLoc } from './Master/InventoryLocationMaster/DefineInventoryLoc';

import { SearchPurchaseorder } from './Transactions/DealerPurchaseOrder/SearchPurchaseorder';
import { DefinePuchaseOrder } from './Transactions/DealerPurchaseOrder/DefinePuchaseOrder';
import { DefineItem } from './Master/ItemMaster/DefineItem';

import { SearchItem } from './Master/ItemMaster/SearchItem';
import { DefineServiceCharges } from './Master/ServiceChergesMaster/DefineServiceCharges';

import { SchemeConfigurate } from './Transactions/Scheme_Configurator/SchemeConfigurate';
// import { SearchMCHallocaTemplate } from './Transactions/MarketingCostHead%AllocaTemplate/SearchMCHallocaTemplate';
import { DispatchOrderHome } from './Transactions/DispatchOrder/DispatchOrderHome';
import { DispatchOrder } from './Transactions/DispatchOrder/DispatchOrder';

import { MarketBudgetGeoperTem } from './Transaction-Marketing/MarketingBudgGeoPercentageallocTem/MarketBudgetGeoperTem';

import { SearchServiceCharges } from './Master/ServiceChergesMaster/SearchServiceCharges';
import { DefineVendor } from './Master/Vendor Master/DefineVendor';
import { SearchVendor } from './Master/Vendor Master/SearchVendor';
import { InstallationDashbord } from './Transactions-Installation/InstallationsDashboard/InstallationsDashbord'
import { CreateInstallation } from './Transactions-Installation/CreateInstallation/CreateInstallation';
import { InstallationDCR } from './Transactions-Installation/DCR/InstallationDCR';
import { SearchInstallationDCR } from './Transactions-Installation/DCR/SearchInstallationDCR';
// import { DefineMarketingBudget } from './Transactions/MarketingBudget/DefineMarketingBudget';
// import { mrkbudgetdealerallocation } from './Transactions/MarketingBudgetDealerAllocation/mrkbudgetdealerallocation';
import { MarketingProposal } from './Transaction-Marketing/Marketing/MarketingProposal';
import { MarketingProposalHome } from './Transaction-Marketing/Marketing/MarketingProposalHome';
import { AccountThreeSixty } from './Transactions/AccountThreeSixty/AccountThreeSixty';
import { DefineMarketingBudget } from './Transaction-Marketing/MarketingBudget/DefineMarketingBudget';
import { SearchMCHallocaTemplate } from './Transaction-Marketing/MarketingCostHeadPercentageAllocaTemplate/SearchMCHallocaTemplate';
import { mrkbudgetdealerallocation } from './Transaction-Marketing/MarketingBudgetDealerAllocation/mrkbudgetdealerallocation';
import { Salesdashboard } from './Transactions/SalesDashboard/Salesdashboard';
import { Payment } from './Transactions/DealerPurchaseOrder/Payment';
import { DCRreport } from './Reports/DCR Report/DCRreport';

import { SearchPurchaseOrder } from './InventoryManagement/PurchaseOrder/SearchPurchaseOrder';
import { DefinePurchaseOrder } from './InventoryManagement/PurchaseOrder/DefinePurchaseOrder';


import { ConversionTemplate } from './Transactions-Production/Conversion Template/ConversionTemplate';
import { SearchPaymentReceivableReceipt } from './Transactions/PaymentReceivableReceipt/SearchPaymentReceivableReceipt';

import { Stockadjustment } from './Transactions/Stock Adjustment/Stockadjustment';
import { SchemeHome } from './Transactions/Scheme_Configurator/SchemeHome';
import { GoodsNoteHome } from './InventoryManagement/GoodsNote/GoodsNoteHome';
import { GoodsNoteDefine } from './InventoryManagement/GoodsNote/GoodsNoteDefine';


import { SearchconversionTemplate } from './Transactions-Production/Conversion Template/SearchconversionTemplate';
import { ConversionTemplateModel } from './Transactions-Production/Conversion Template/ConversionTemplateModel';
import { DefinePaymentReceivableReceipt } from './Transactions/PaymentReceivableReceipt/DefinePaymentReceivableReceipt';

import { DailyProductionPlan } from './Transactions-Production/Daily Production Plan/DailyProductionPlan';
import { SearchDailyProductionPlan } from './Transactions-Production/Daily Production Plan/SearchDailyProductionPlan';
import { QuotationReport } from './Reports/Quotation Report/QuotationReport';
import { PlanItemConversion } from './Transactions-Production/Daily Production Plan/PlanItemConversion';
import { SearchMaterialRequisition } from './Transactions-Production/Material Requisition/SearchMaterialRequisition';
import { DefineMaterialRequisition } from './Transactions-Production/Material Requisition/DefineMaterialRequisition';
import { ReassignEnquiry } from './Transactions/DailyCall/ReassignEnquiry';
import { CreatePlanRequisition } from './Transactions-Production/CreatePlanRequisition/CreatePlanRequisition';
import { SearchProductionBooking } from './Transactions-Production/Production Booking/SearchProductionBooking';
import { DefineProductionBooking } from './Transactions-Production/Production Booking/DefineProductionBooking';
import { StockLedgerReport } from './Reports/Inventory Stock Ledger Report/StockLedgerReport';
import { OpportunityReport } from './Reports/Opportunity Report/OpportunityReport';
import { OrdInHandReport } from './Reports/Order In Hand Report/OrdInHandReport';
import { CorporateCustProcurementSmry } from './Reports/Corporate Customer Procurement Summary/CorporateCustProcurementSmry';

import { SampleInvoice } from './Transactions/OrderAcceptance/SampleInvoice';
import { SearchPurchaseReturn } from './InventoryManagement/PurchaseReturn/SearchPurchaseReturn';
import { DefinePurchaseReturn } from './InventoryManagement/PurchaseReturn/DefinePurchaseReturn';
import { MaterialInventoryHome } from './InventoryManagement/MaterialInventoryHome/MaterialInventoryHome';
import { VisitDetailSmry } from './Reports/Visit Detail Summary/VisitDetailSmry';
import { SpareIssue } from './Transactions/Spare Issue/SpareIssue';
import { InstallationSummary } from './Reports/InstallationSummary/InstallationSummary';
import { ReassEnquiry } from './Transactions/ServiceCallDashboard/ReassEnquiry';
import { ThreeSixtyMaster } from './Master/ThreeSixtyMaster/ThreeSixtyMaster';
import { ServiceDashboard } from './Transactions/ServiceDashboard/ServiceDashboard';
import { DCRSummaryreport } from './Reports/DCR Report/DCRSummaryreport';

// import { EngineerWiseQuotationReport } from './Reports/DCR Report/EngineerWiseQuotationReport';
import { CRMMObileHome } from './CRMHome/CRMMObileHome';
import { Quotationdashboard } from './Transactions/Quotation/Quotationdashboard';
import { Firingform } from './Reports/Firing Form/Firingform';
import { ExecWiseQuotationDetailReport } from './Reports/DCR Report/ExecWiseQuotationDetailReport';
import { ReferencersReport } from './Reports/Referencer Report/ReferencersReport';
import { AreaWisePerformanceReport } from './Reports/Area Wise Performance Report/AreaWisePerformanceReport';
import { DefineMCHallocaTEMP } from './Transaction-Marketing/MarketingCostHeadPercentageAllocaTemplate/DefineMCHallocaTEMP';
import { TeamWisePerformanceReport } from './Reports/Team Wise Performance Report/TeamWisePerformanceReport';
import { ServiceExeWiseQuotationDtlReport } from './Reports/Service Executive Wise Quotation Detail Report/ServiceExeWiseQuotationDtlReport';
import { OpenCloseServiceEnquiriesReport } from './Reports/OpenCloseServiceEnquiriesReport/OpenCloseServiceEnquiriesReport';
import { PerformanceReprotofTechnicians } from './Reports/Performance Reprot of Technicians/PerformanceReprotofTechnicians';
import { CustomerMerge } from './Master/Customer Merge/CustomerMerge';
import { Reassign } from './Transactions/Reassign/Reassign';
import { SalesReturn } from './Transactions/Sales Return/SalesReturn';
import { SuperwisingAuthorityWiseOrderReport } from './Reports/Superwising Authority Wise Order Report/SuperwisingAuthorityWiseOrderReport';
import { DelearDailyCall } from './Transactions/DailyCall/DelarDailyCall';
import { DefineitemSubCategory } from './Master/ItemSubCategoryMaster/DefineitemSubCategory';
import { SearchitemSubCategory } from './Master/ItemSubCategoryMaster/SearchitemSubCategory';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'landpage', component: CRMHome },
  { path: 'mobilelandpage', component: CRMMObileHome },
  { path: 'newMaster', component: MasterConfiguration },
  // { path: 'DCR', component: MasterConfigurationHome },
  { path: 'editMaster/:id', component: MasterConfiguration },
  { path: 'CRMHome', component: CRMHome },

  //MASTERS
  //{ path: 'Resource', component: SearchInstallationDCR },
  // { path: 'Resource', component: SearchInstallationDCR },
  // { path: 'Resource', component: SearchAccountWiseMachineDetail },
  { path: 'mangeprocat/:id', component: DefineProductcategory },
  { path: 'DefineProductcategory', component: DefineProductcategory },
  //{ path: 'Resource/:id', component: SearchProductcategory },
  { path: 'newDefineitemSubCategory', component: DefineitemSubCategory },
  { path: 'editDefineitemSubCategory/:id', component: DefineitemSubCategory },

 // { path: 'Resource', component: CRMHome },
 
 { path: 'Item Subcategory', component: SearchitemSubCategory },
  { path: 'DefineInventoryLoc', component: SearchInventoryLoc },
  { path: 'newDefineInventoryLoc', component: DefineInventoryLoc },
  { path: 'editDefineInventoryLoc/:id', component: DefineInventoryLoc },
  // { path: 'Service', component: ServiceDashboard },
  { path: 'newDefineInventoryLoc', component: DefineInventoryLoc },
  { path: 'editDefineInventoryLoc/:id', component: DefineInventoryLoc },
  // { path: 'Geography', component: SearchInventoryLoc },
  // { path: 'newDefineInventoryLoc', component: DefineInventoryLoc },
  // { path: 'editDefineInventoryLoc/:id', component: DefineInventoryLoc },
  // { path: 'Resource', component: SearchItem },

  { path: 'ThreeSixtyMaster', component: ThreeSixtyMaster },

  // { path: 'Product', component: SearchPaymentReceivableReceipt },

  // {path:"Geography",component:SearchItem},
  // { path: 'newDefineItem', component: DefineItem },
  // { path: 'editDefineItem/:id', component: DefineItem },

  //{ path: 'Manage Resource', component: SearchUser },
  //{ path: 'newDefineItem', component: DefineItem },
  //{ path: 'editDefineItem/:id', component: DefineItem },

  // { path: 'Sales', component: Quotation },
  { path: 'newDefineVendor', component: DefineVendor },
  { path: 'editDefineVendor/:id', component: DefineVendor },

  { path: 'Service Charges', component: SearchServiceCharges },
  { path: 'newDefineServiceCharges', component: DefineServiceCharges },
  { path: 'editDefineServiceCharges/:id', component: DefineServiceCharges },

  // { path: 'Resource', component: DefineItem },

  // { path: 'Resource', component: SearchUser },
  // { path: 'Manage Product', component: SearchProduct },

  { path: 'Product', component: SearchProduct },
  //{ path: 'Manage Resource', component: SearchUser },

  // { path: 'Resource', component: OrderHomepage },

  { path: 'Manage Product', component: SearchProduct },

  //{ path: 'Marketing', component: SearchMarketing },

  //{ path: 'Marketing', component: SearchMarketing },
  { path: 'DefineMarketing', component: DefineMarketing },
  { path: 'DefineMarketing/:id', component: DefineMarketing },

  // { path: 'Resource', component: SearchCategoryTermsCon },

  { path: 'AccountThreeSixty', component: AccountThreeSixty },

  { path: 'Transaction', component: SearchDailyProductionPlan },
  { path: 'Search Conversion', component: SearchconversionTemplate },

  { path: 'Conversion Template', component: ConversionTemplate },

  { path: 'Manage Resource', component: SearchUser },
  { path: 'DefineUser', component: DefineUser },
  { path: 'DefineUser/:id', component: DefineUser },

  { path: 'Account', component: SearchAccountMaster },
  { path: 'defineaccount', component: DefineAccountMaster },
  { path: 'defaccount', component: DefineAccountMaster },

  { path: 'Pipeline', component: SearchPipeline },
  { path: 'DefinePipeline', component: DefinePipeline },
  { path: 'DefinePipeline/:id', component: DefinePipeline },
  { path: 'Company', component: SearchCompanyMaster },

  { path: 'Definecompany/:id', component: DefineCompanyMaster },
  { path: 'Account Wise Machine Detail', component: SearchAccountWiseMachineDetail },
  { path: 'DefineAccountwisemachinedetail', component: DefineAccountwisemachinedetail },
  { path: 'DefineAccountwisemachinedetail/:id', component: DefineAccountwisemachinedetail },
  { path: 'Document Numbering', component: SearchEnquiryname },
  { path: 'SearchEnquiryname/:id', component: SearchEnquiryname },
  { path: 'Exchange Rate', component: SearchCurrencyExchange },
  { path: 'SearchCurrencyExchange/:id', component: SearchCurrencyExchange },
  { path: 'Manage Product', component: SearchProduct },
  { path: 'DefineProduct', component: DefineProduct },
  { path: 'DefineProduct/:id', component: DefineProduct },
  { path: 'Manage Allowance', component: SearchResourceAllowance },
  { path: 'newDefineResAllo', component: DefineResourceAllowance },
  { path: 'editDefineResAllo', component: DefineResourceAllowance },
  { path: 'Types', component: SearchTypeMaster },
  { path: 'newDefineTypeMaster', component: DefineTypeMaster },
  { path: 'editDefineTypeMaster/:id', component: DefineTypeMaster },
  { path: 'AMC Type', component: SearchAMCType },
  { path: 'newAMCType', component: DefineAMCType },
  { path: 'editAMCType/:id', component: DefineAMCType },
  { path: 'Locality', component: SearchLocality },
  { path: 'newLocality', component: DefineLocality },
  { path: 'editLocality/:id', component: DefineLocality },
  { path: 'City', component: SearchCityMaster },
  { path: 'newCity', component: DefineCityMaster },
  { path: 'editCity/:id', component: DefineCityMaster },
  { path: 'Customer Type Workflow', component: CustomerTypeWorkflow },
  { path: 'ResourceRights', component: ResourceRights },
  { path: 'Team', component: SearchTeam },
  { path: 'newTeam', component: DefineTeam },
  { path: 'editTeam/:id', component: DefineTeam },
  { path: 'Change Password', component: ChangePassword },

  // { path: 'TargetHome', component: TargetHome },
  { path: 'editTargetHome/:id', component: SearchTarget },
  { path: 'newTargetHome', component: SearchTarget },

  { path: 'Reset Password', component: ResetPassword },
  { path: 'Activity', component: SearchActivity },
  { path: 'newActivity', component: DefineActivity },
  { path: 'editActivity/:id', component: DefineActivity },
  { path: 'Financial Year', component: Financialyear },
  { path: 'DefineOrder', component: DefineOrderAcceptance },
  { path: 'editDefineOrder/:id', component: DefineOrderAcceptance },
  { path: 'Manage Category', component: SearchProductcategory },


  //Configured Master
  { path: 'DefineMaster', component: DefineMaster },
  { path: 'Region', component: MasterHomePage },
  { path: 'Area', component: MasterHomePage },
  { path: 'Currency', component: MasterHomePage },
  { path: 'Locale', component: MasterHomePage },
  { path: 'Branch', component: MasterHomePage },
  { path: 'Department', component: MasterHomePage },
  { path: 'Designation', component: MasterHomePage },

  { path: 'Template', component: MasterHomePage },
  { path: 'Heading', component: MasterHomePage },
  { path: 'Mode of Transport', component: MasterHomePage },
  { path: 'Customer Type', component: MasterHomePage },

  { path: 'Model Specification', component: MasterHomePage },
  { path: 'Item Category', component: MasterHomePage },
  { path: 'Unit of Measure', component: MasterHomePage },

  { path: 'Tax Element', component: MasterHomePage },
  { path: 'Designation', component: MasterHomePage },
  { path: 'Country', component: MasterHomePage },
  { path: 'States', component: MasterHomePage },
  { path: 'Marketing Cost Head', component: MasterHomePage },
  { path: 'Problem Category', component: MasterHomePage },
  { path: 'Event Type', component: MasterHomePage },
  { path: 'Material Reject Resons', component: MasterHomePage },


  //Menu routing
  { path: 'addmenu', component: AddMenuComponent },
  { path: 'contextmenue', component: ContextmenuComponent },
  { path: 'prospect', component: Prospect },
  { path: 'Defineitme', component: DefineMenuItem },
  { path: 'User Group', component: SearchUserGroup },
  { path: 'Allowance', component: SearchAllowanceMaster },
  { path: 'DefineAllowance', component: DefineAllowance },
  { path: 'editallowance/:id', component: DefineAllowance },
  { path: 'Campaign', component: SearchSource },
  { path: 'DefineSource', component: DefineSource },
  { path: 'editSource/:id', component: DefineSource },
  { path: 'SourceDetail', component: SourceDetail },
  { path: 'Terms and Conditions', component: SearchCategoryTermsCon },
  // { path: 'Expense', component: SearchCategoryTermsCon },
  { path: 'DefineCategoryTermsCon', component: DefineCategoryTermsCon },
  { path: 'editcattermcon/:id', component: DefineCategoryTermsCon },
  { path: 'DefineCategoryTermsCon', component: DefineCategoryTermsCon },

  { path: 'Call Center Dashboard', component: CallCenterDashboard },
  { path: 'Call Center Dashboard/:pageno', component: CallCenterDashboard },
  { path: 'Service Call', component: ServiceCallDashboard },
  { path: 'Tax Group', component: searchgstheader },
  { path: 'Gstheadermaster', component: Gstheadermaster },
  { path: 'gsthdr/:id', component: Gstheadermaster },
  { path: 'gstgroup', component: GSTgroupmaster },
  { path: 'Dealers Detail', component: SearchDealer },
  // Transactions
  { path: 'DealersDetail', component: DealersDetail },
  { path: 'DealersDetail/:id', component: DealersDetail },
  { path: 'Opportunities', component: Opportunities },
  { path: 'DCR', component: DailyCall },
  { path: 'updateDCR', component: DailyCall },
  { path: 'updateDCR/:id', component: DailyCall },
  { path: 'PrepareQuotation/:id', component: Quotation },
  { path: 'ServiceCall', component: ServiceCall },
  { path: 'Inventory Location', component: SearchInventoryLoc },
  { path: 'Item Sub category', component: SearchitemSubCategory },
  { path: 'PrepareQuotation', component: Quotation },
  { path: 'Order Acceptance', component: OrderHomepage },
  { path: 'editDefineOrder/:id', component: DefineOrderAcceptance },
  { path: 'SampleInvoice', component: SampleInvoice },
  { path: 'Despatch Order', component: DispatchOrderHome },
  { path: 'editDispatch/:id', component: DispatchOrder },
  { path: 'Purchase order', component: SearchPurchaseOrder },
  { path: 'DefinePuchaseOrder', component: DefinePuchaseOrder },
  { path: 'DefinePuchaseOrder/:id', component: DefinePuchaseOrder },

  { path: 'Menu By User', component: SelectUser },
  { path: 'Menu By Group', component: SearchUserGroup },

  { path: 'Installation Dashbord', component: InstallationDashbord },
  { path: 'Installation Dashbord', component: InstallationDashbord },
  // { path: 'Installation', component: SearchInstallationDCR },

  { path: 'InstallationDCRcreate', component: InstallationDCR },
  { path: 'Create Installation', component: CreateInstallation },
  { path: 'Create Installation', component: CreateInstallation },
  { path: 'newTeam', component: DefineTeam },
  { path: 'editTeam/:id', component: DefineTeam },



  // { path: 'Marketing', component: SearchMCHallocaTemplate },
  { path: 'MarketBudgetGeoperTemplate', component: MarketBudgetGeoperTem },
  { path: 'MarketingBudget', component: DefineMarketingBudget },

  // { path: 'MarketingCostHead%alloTemplate', component: SearchMCHallocaTemplate },
  //  { path: 'User', component: mrkbudgetdealerallocation },
  //  { path: 'User', component: MarketBudgetGeoperTem },




  { path: 'SearchPurchaseOrder', component: SearchPurchaseOrder },
  { path: 'DefinePurchaseOrder', component: DefinePurchaseOrder },
  { path: 'viewPurchaseOrder', component: DefinePurchaseOrder },

  { path: 'Scheme Configuration', component: SchemeHome },
  { path: 'newScheme', component: SchemeConfigurate },
  { path: 'reassignEnquiry', component: ReassignEnquiry },


  { path: 'InstallationDCRcreate', component: InstallationDCR },
  { path: 'InstallationDCR', component: SearchInstallationDCR },

  { path: 'Payment Receipt', component: SearchPaymentReceivableReceipt },
  { path: 'newPaymentRec', component: DefinePaymentReceivableReceipt },
  { path: 'editpaymentreceipt', component: DefinePaymentReceivableReceipt },
  { path: 'editpaymentreceipt/:id', component: DefinePaymentReceivableReceipt },


  //MARKETING TRANSACTIONS
  { path: 'Marketing Proposal', component: MarketingProposalHome },
  { path: 'newMarketing', component: MarketingProposal },
  { path: 'Quotation', component: PaymentScreen },
  { path: 'Invoice', component: PaymentScreen },

  //Sales Dashboard

  { path: 'Salesdashboard', component: Salesdashboard },

  { path: 'Quotation/:tbqotation', component: Quotation },
  { path: 'Opportunities/:id', component: Opportunities },

  //Inventory
  { path: "Goods Receipt Note", component: GoodsNoteHome },
  { path: "ViewGoodsReceiptNote", component: GoodsNoteDefine },
  { path: "GIN", component: GoodsNoteDefine },
  { path: 'Stockadjustment', component: Stockadjustment },

  //////Production 
  { path: 'Production Plan', component: SearchDailyProductionPlan },

  { path: "DailyProductionPlan", component: DailyProductionPlan },
  { path: "Daily Production Plan", component: SearchDailyProductionPlan },
  { path: "Plan Item Conversion/:id", component: PlanItemConversion },
  { path: "Create Plan Requisition", component: CreatePlanRequisition },
  { path: "PlanRequisition", component: CreatePlanRequisition },

  { path: "Production Booking", component: SearchProductionBooking },
  { path: "Define Production Booking", component: DefineProductionBooking },
  { path: "Define Production Booking/:id", component: DefineProductionBooking },

  { path: "SearchMaterialRequisition", component: SearchMaterialRequisition },
  { path: "DefineMaterialRequisition", component: DefineMaterialRequisition },

  { path: "MaterialInventoryHome", component: MaterialInventoryHome },

  { path: "SpareIssue", component: SpareIssue },
  //{ path: "Sales", component: SearchPurchaseorder },



  //Reports 

  // { path: 'Product', component: DCRreport },
  { path: 'DailyCallReport', component: Firingform },
  { path: 'Daily Call Report', component: DCRreport },

  // { path: 'Geography', component: DCRSummaryreport },
  { path: 'DCRSummaryReport', component: Firingform },
  { path: 'DCR Summary Report', component: DCRSummaryreport },

  // { path: 'Service', component: EngineerWiseQuotationReport },
  // { path: 'ExecutiveWiseQuotationSummaryReport', component: Firingform },
  { path: 'Open / Lost Enquiries Report', component: DelearDailyCall },

  { path: 'ExecWiseQuotationDetailReport', component: Firingform },
  { path: 'Executive Wise Quotation Detail Report', component: ExecWiseQuotationDetailReport },

  // { path: 'Expense', component: OpportunityReport },
  { path: 'OpportunityReport', component: Firingform },
  { path: 'Opportunity Report', component: OpportunityReport },

  // { path: 'Organization', component: OrdInHandReport },
  { path: 'Order In Hand Report', component: OrdInHandReport },

  { path: 'SuperwisingAuthorityWiseOrderReport', component: SuperwisingAuthorityWiseOrderReport },

  // { path: 'Configuration', component: QuotationReport },
  { path: 'Quotation Report', component: QuotationReport },

  // { path: 'Sales', component: InstallationSummary },
  { path: 'InstallationSummaryReport', component: Firingform },
  { path: 'Installation Summary Report', component: InstallationSummary },

  // { path: 'Geography', component: StockLedgerReport },
  // { path: 'Organization', component: CorporateCustProcurementSmry },
  //  { path: 'Commercial', component: VisitDetailSmry },
  { path: 'Visit Detail Summary', component: VisitDetailSmry },
  { path: 'Referencers Report', component: ReferencersReport },
  { path: 'Area Performance Report', component: AreaWisePerformanceReport },
  { path: 'Team Performance Report', component: TeamWisePerformanceReport },
  { path: 'Performance Reprot of Technicians', component: PerformanceReprotofTechnicians },

  { path: 'ServiceExeWiseQuotationDtlReport', component: Firingform },
  { path: 'Completed Enquiries', component: ServiceExeWiseQuotationDtlReport },

  { path: 'OpenCloseServiceEnquiriesReport', component: Firingform },
  { path: 'Pending / In Progress / Rejected Enquiries', component: OpenCloseServiceEnquiriesReport },



  ////////\\\\\\\///////New Routing////////\\\\\\\////////

  { path: 'Inventory Location', component: SearchInventoryLoc },
  { path: 'newDefineInventoryLoc', component: DefineInventoryLoc },
  { path: 'editDefineInventoryLoc/:id', component: DefineInventoryLoc },

  { path: 'Item Subcategory', component: SearchitemSubCategory },
  { path: 'newDefineitemSubCategory', component: DefineitemSubCategory },
  { path: 'editDefineitemSubCategory', component: DefineitemSubCategory },

  { path: 'Item Master', component: SearchItem },
  { path: 'newDefineItem', component: DefineItem },
  { path: 'editDefineItem/:id', component: DefineItem },

  { path: 'Vendor', component: SearchVendor },
  { path: 'newDefineVendor', component: DefineVendor },
  { path: 'editDefineVendor/:id', component: DefineVendor },

  { path: 'Service Charges Template', component: SearchServiceCharges },
  { path: 'newDefineServiceCharges', component: DefineServiceCharges },
  { path: 'editDefineServiceCharges/:id', component: DefineServiceCharges },

  { path: 'Marketing Cost Sub-Head', component: SearchMarketing },
  { path: 'DefineMarketing', component: DefineMarketing },
  { path: 'DefineMarketing/:id', component: DefineMarketing },

  { path: 'Opportunities', component: Opportunities },

  { path: 'Order Acceptace', component: OrderHomepage },
  { path: 'editDefineOrder', component: DefineOrderAcceptance },

  { path: 'Despatch Order', component: DispatchOrderHome },
  { path: 'editDispatch', component: DispatchOrder },

  { path: 'Call Center Dashboard', component: CallCenterDashboard },

  { path: 'ReassEnquiry', component: ReassEnquiry },

  { path: 'Dealer Details', component: SearchDealer },
  { path: 'newDealersDetail', component: DealersDetail },
  { path: 'editDealersDetail/:id', component: DealersDetail },

  { path: 'Dealer Purchase Order', component: SearchPurchaseorder },
  { path: 'newDefinePuchaseOrder', component: DefinePuchaseOrder },
  { path: 'editDefinePuchaseOrder/:id', component: DefinePuchaseOrder },

  { path: 'Marketing Cost Head % Allocation Template', component: DefineMCHallocaTEMP },
  { path: 'editDefineMCHallocaTEMP/:id', component: SearchMCHallocaTemplate },
  { path: 'newDefineMCHallocaTEMP', component: SearchMCHallocaTemplate },

  { path: 'Marketing Budget Geography % Allocation Template', component: MarketBudgetGeoperTem },
  { path: 'Marketing Budget', component: DefineMarketingBudget },
  { path: 'Marketing Budget Dealer Allocation', component: mrkbudgetdealerallocation },
  { path: 'Marketing Proposal', component: MarketingProposal },
  { path: 'Sales Dashboard', component: Salesdashboard },
  { path: 'Installation Dashboard', component: InstallationDashbord },

  // { path: 'Installation', component: SearchInstallationDCR },

  { path: 'Conversion Template', component: ConversionTemplate },
  { path: 'Search Conversion', component: SearchconversionTemplate },

  { path: 'Scheme Configuration', component: SchemeConfigurate },
  { path: 'Purchase Return Note', component: SearchPurchaseReturn },
  { path: 'newPurchseReturn', component: DefinePurchaseReturn },

  { path: 'Material Inventory', component: MaterialInventoryHome },
  { path: 'editScheme/:id', component: SchemeConfigurate },
  { path: 'Quotationdashboard', component: Quotationdashboard },

  // { path: 'Expense', component:Reassign },
  { path: 'Reassign Enquiry', component:Reassign },

  // { path: 'Purchase Order', component:  },
  // { path: 'Good Received Note', component:  },
  // { path: 'Purchase Note', component:  },
  { path: 'Sales Return Note', component: SalesReturn },
  // { path: 'Mateial Requisition Home', component:  },
  // { path: 'Stock Adjustment', component:  },
   { path: 'DelearDailyCall', component: DelearDailyCall },
   { path: 'Open / Lost Enquiries Report/id', component: DailyCall },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  //imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routing = RouterModule.forRoot(routes);
