<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Pending / In Progress / Rejected Enquiries</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>

        <div class="col l12 m12 s12 registerBgShadow" style="padding-left: 18px;padding-right: 18px;">
            <div class="col l12 m12 s12  modal-header" style="text-align: end;padding-top: 9px;" >
                <button type="button" class="close pull-right" aria-label="Close" (click)="btnback()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row" id="LibDocs">
                <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                    <div class="modal-content " style="text-align:center;padding: 0px;margin-bottom: 10px;">
                        <!-- <span style="font-size: 20px;">Engineer Wise Quotation Report</span> -->
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>

                            </div>
                        </div>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 16px">{{companyaddress}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;" *ngIf ="status=='Open'">
                        <span style="font-size: 20px;text-decoration: underline">Pending Enquiries</span>
                    </div>

                    <div style="text-align: center;" *ngIf ="status=='In Progress'">
                        <span style="font-size: 20px;text-decoration: underline">In Progress Enquiries</span>
                    </div>
                    
                    <div style="text-align: center;" *ngIf ="status=='Rejected'">
                        <span style="font-size: 20px;text-decoration: underline">Rejected Enquiries</span>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                        <div class="col l12 m12 s12" style="padding-right: 0px;">
                            <div class="input-field col l12 m12 s12"
                                style="text-align: end;padding-right: 0px;margin: 0px;">
                                <span><img src="../../../assets/images/printer.png" (click)="openPrint()"
                                        data-toggle="tooltip" data-placement="top" title="Print"
                                        width="30px" /></span>&nbsp;&nbsp;
                                <span><img src="../../../assets/images/excel2.png" onclick="tableToExcel('excel')"
                                        data-toggle="tooltip" data-placement="top" title="Excel" width="30px" /></span>
                            </div>
                        </div>
                        <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7">
                                <tbody>
                                    <tr>
                                        <td width="20%" style="text-align: left;border: 1px solid black;"><span>From
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.frmdate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>To
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.todate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    </tr>
                                    <tr>

                                        <td width="21%" style="text-align: left;border: 1px solid black;">
                                            <span>Region:-</span>
                                            &nbsp;<span><b>{{regionName}}</b></span>
                                        </td>
                                        <td width="21%" style="text-align: left;border: 1px solid black;">
                                            <span>Area:-</span>
                                            &nbsp;<span><b>{{areaName}}</b></span>
                                        </td>
                                        <td width="21%" style="text-align: left;border: 1px solid black;">
                                            <span>Branch:-</span>
                                            &nbsp;<span><b>{{branchName}}</b></span>
                                        </td>

                                        <!-- <td width="19%" style="text-align: left;border: 1px solid black;"> <span>From
                                                Date:-</span>
                                            &nbsp;<span><b>{{model.frmdate |
                                                    date:"dd-MM-yyyy"}}</b></span></td>
                                        <td width="18%" style="text-align: left;border: 1px solid black;"><span>To
                                                Date:-</span>
                                            &nbsp;<span><b>{{model.todate |
                                                    date:"dd-MM-yyyy"}}</b></span></td> -->
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <div class="col l12 m12 s12" style="padding: 0px;">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                                id="excel">
                                <thead style="background: gainsboro;">
                                    <th width="5%" style="text-align: left;padding-left: 10px;">Sr. #</th>
                                    <th width="15%" style="text-align: left;">Date</th>
                                    <th width="15%" style="text-align: left">Service Enquiry #</th>
                                    <th width="10%" style="text-align: left">Mobile</th>
                                    <th width="15%" style="text-align: left">Customer Name</th>
                                    <th width="15%" style="text-align: left">Executive Name</th>
                                    <th width="15%" style="text-align: left">Locality</th>
                                    <th width="10%" style="text-align: left">Status</th>
                                   
                                    <!-- <th width="12%" style="text-align: right">Quotation Value(&#8377;)</th> -->
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of eqrlst ">
                                        <td style="text-align: left">{{lst.srno}}</td>
                                        <td style="text-align: left;padding-left: 10px;">{{lst.startDate |
                                            date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: left">{{lst.enquiry}}</td>
                                        <td style="text-align: left">{{lst.mobile}}</td>
                                        <td style="text-align: left">{{lst.customername}}</td>
                                        <td style="text-align: left">{{lst.resourcename}}</td>
                                        <td style="text-align: left">{{lst.localityname}}</td>
                                        <td style="text-align: left">{{lst.status}}</td>
                                        
                                        <!-- <td style="text-align: right;padding-right: 10px;">{{lst.quotationValue |
                                            number:'1.2-2'}}</td> -->
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="col s12 m12 l12" style="text-align: center;margin-top: 12px;">
                            <span style="font-size: 11px;">Report Genrated By: {{username}} on {{timing
                                |date:'medium'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="padding-right: 0px;margin-bottom: 12px;">
                    <div class="input-field col l12 m12 s12"
                        style="text-align: end;padding-right: 0px;margin-top: 0px;margin-bottom: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="btnback()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>