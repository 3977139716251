import { Component, OnInit } from '@angular/core';
import { MasterServices, AlertService, TransactionServices } from 'src/app/_services';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { SearchAccountMasterModel } from './SearchAccountMasterModel';
import { Location, DatePipe } from '@angular/common'
// import { usertwofieldModel } from './usertwofieldModel';



@Component({
    selector: 'app-login',
    templateUrl: 'SearchAccountMaster.html'
})
export class SearchAccountMaster implements OnInit {
    p: any;
    model: any = {};
    public i: any = 0;
    accontlst: SearchAccountMasterModel[] = [];
    accountslst1: SearchAccountMasterModel[] = [];
    pagename: string;
    user = new User();
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Name" },
        { id: 3, "name": "Code" },
    ];
    constructor(private Masterservice: MasterServices, 
        private route: ActivatedRoute, private notifyService: NotificationService,
         private router: Router, private location: Location,) {
     this.pagename=this.router.url
    }
    ngOnInit() {
        this.getAllAccounts();
        localStorage.setItem("pagename",this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));

    }
    getAllAccounts() {
        this.Masterservice.getAllAccounts(this.model)
            .subscribe(value => {
                this.accontlst = [];
                this.accountslst1 = [];
                value.forEach((item) => this.accountslst1.push({ "id": item.id, "name": item.name, "code": item.code, "isAccount": item.isAccount, "txtserch": item.txtserch, "locality":item.locality,"telephone1":item.telephone1,"customerType":item.customerType,"txtselect": item.txtselect }));
                this.accontlst = this.accountslst1;
            })
    }

    onSelect(value) {
        this.model.txtselect = value;
    }

    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllAccounts()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.accountslst1 = [];
                this.accontlst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.accountslst1.push({ "id": item.id, "name": item.name, "code": item.code, "isAccount": item.isAccount, "locality":item.locality,"telephone1":item.telephone1,"customerType":item.customerType,"txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.accontlst = this.accountslst1;
            }
        }
        else if (this.model.txtselect == "Code") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.accountslst1 = [];
                this.accontlst.forEach((item) => {
                    if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.accountslst1.push({ "id": item.id, "name": item.name, "code": item.code, "isAccount": item.isAccount, "locality":item.locality,"telephone1":item.telephone1,"customerType":item.customerType,"txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.accontlst = this.accountslst1;
            }
        }
    }

    btnnew() {
        this.router.navigate(['/defineaccount']);
    }

    btnedit(id, name) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "name": name,
            }
        };
        this.router.navigate(['/defaccount'], navigationExtras);

    }

    btndelts(id) {
        this.model.id = id
        this.i = confirm("Do you want to delete this record ?");
        if (this.i == true) {
            this.Masterservice.deleteaccount(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess("Data Deleted Successfully !", "Success !");
                    this.ngOnInit()
                });
        }
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
}