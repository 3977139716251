<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l4 m4 s4">
                    <span>Customer Type</span>
                    <select (change)="onselectcustype($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Customer type">
                        <option selected value="0">Select Customer Type</option>
                        <option *ngFor="let type of custTypelst" [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="col l3 m3 s3"></div>
                <!-- <div class="col l3 m3 s3" *ngIf="model.typeid!=null" style="margin-top: 30px">
                    <p><input type="checkbox" [(ngModel)]="model.chkdealer" id="chkdealer"
                            #chkdealer="ngModel">&nbsp;&nbsp;
                        <label for="chkdealer">Dealer</label>
                    </p>
                </div> -->
            </div>
            <div *ngIf="model.typeid!=null">
                <div class="row">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkSalesEnq" id="chkSalesEnq"
                                #chkSalesEnq="ngModel">&nbsp;&nbsp;
                            <label for="chkSalesEnq">Show in Sales Enquiry</label>
                        </p>
                    </div>
                    <div class="col l3 m3 s3"  >
                        <p><input type="checkbox" [(ngModel)]="model.chkSalesEnqdel" id="chkSalesEnqdel"
                                #chkSalesEnqDel="ngModel">&nbsp;&nbsp;
                            <label for="chkSalesEnqdel">Show in Sales Enquiry by Dealer</label>
                        </p>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <span style="font-size: 160%; color: gray">Sales Quotation</span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row" style="margin-left: 3%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkTC" id="chkTC" #chkTC="ngModel">&nbsp;&nbsp;
                            <label for="chkTC">Show Terms and
                                Conditions</label>
                        </p>
                    </div>
                    <div class="col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chksendQuo" id="chksendQuo" #chksendQuo="ngModel"
                                (click)="onchksendq($event)">&nbsp;&nbsp;
                            <label for="chksendQuo">Send Quotation</label>
                        </p>
                    </div>
                    <div class="col l2 m2 s2" *ngIf="model.chksendQuo==true">
                        <p><input type="checkbox" [(ngModel)]="model.chkemail" id="chkemail"
                                #chkemail="ngModel">&nbsp;&nbsp;
                            <label for="chkemail">Email</label>
                        </p>
                    </div>
                    <div class="col l2 m2 s2" *ngIf="model.chksendQuo==true">
                        <p><input type="checkbox" [(ngModel)]="model.chksms" id="chksms" #chksms="ngModel">&nbsp;&nbsp;
                            <label for="chksms">SMS</label>
                        </p>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row" style="margin-left: 3%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkshwgst" id="chkshwgst"
                                #chkshwgst="ngModel">&nbsp;&nbsp;
                            <label for="chkshwgst">Show GST Separately</label>
                        </p>
                    </div>
                    <div class="col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkschemeflag" id="chksch"
                                #chkschemeflag="ngModel">&nbsp;&nbsp;
                            <label for="chksch">Scheme</label>
                        </p>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row" style="margin-top: 1%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <span style="font-size: 160%;color: gray">Order Acceptance</span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row" style="margin-top: 1%; margin-left: 3%">
                    <div class="col l1"></div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkOAbycmpy"
                                (click)="onchkOAbycmpy($event)" />
                            <span style="font-size: 104%">{{companyName}}</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkOAbyndcust"
                                (click)="onchkOAbyndcust($event)" />
                            <span style="font-size: 104%">By End Customer</span>
                        </label>
                    </div>

                    <div class="input-field col l3 m3 s3" *ngIf="model.chkOAbycmpy" style="margin: 0px;">
                        <p style="margin: 0px;"><input type="checkbox" [(ngModel)]="model.chkSupplier" id="chkSupplier"
                                #chkSupplier="ngModel">&nbsp;&nbsp;
                            <label for="chkSERTC">Supplier</label>
                        </p>
                    </div>
                </div>

                <div class="row" style="margin-top: 1%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <span style="font-size: 160%;color: gray">Workflow Scope</span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row" style="margin-top: 1%; margin-left: 3%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <span style="font-size: 140%;color: gray">Installation</span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row" style="margin-top: 2%;margin-left: 3%">
                    <div class="col l1"></div>
                    <div class="col l3 m3 s3"style="margin-top: 1%;">
                        <label>
                            <input name="group2" type="radio" [checked]="model.chkINScmpny"
                                (click)="onchkINScmpny($event)" />
                            <span style="font-size: 104%">{{companyName}}</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l3 m3 s3"style="margin-top: 1%;">
                        <label>
                            <input name="group2" type="radio" [checked]="model.chkINScust"
                                (click)="onchkINScust($event)" />
                            <span style="font-size: 104%">Customer</span>
                        </label>
                    </div>
                    <div class="col l2 m2 s2"style="margin-top: 1%;">
                        <label>
                            <input name="group2" type="radio" [checked]="model.chkINSdealer"
                                (click)="onchkINSdealer($event)" />
                            <span style="font-size: 104%">Dealer</span>
                        </label>
                    </div>
                    <!-- <div class="col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkUnitinstallation" id="chkUnitinstall"
                                #chkUnitinstallation="ngModel">&nbsp;&nbsp;
                            <label for="chkSalesEnq">Unit Installation</label>
                        </p>
                    </div> -->
                </div>
                <div class="row"  style="margin-top: 0%;margin-left: 3%"*ngIf="model.chkINScmpny==true" >
                    <div class="col l1 m1 s1"> </div>
                    <div class="col l3 m3 s3"style="margin-top: 7px;">
                        <span style="font-size: 140%;color: gray">Installation Approval</span>&nbsp;&nbsp;
                        <label>
                            <input name="group6" type="radio" [checked]="model.chkINSApprovcmpny"
                                (click)="onchkINSApprovecmpny($event)" />
                            <span style="font-size: 104%">{{companyName}}</span>
                        </label>&nbsp;&nbsp;
                    </div>
                        <div class="col l3 m3 s3" style="margin-top: 28px;"*ngIf="model.chkINSApprovcmpny==true && model.chkINScmpny==true">
                           
                            <label>
                                <input name="group5" type="radio" [checked]="model.chkfield"
                                    (click)="onchkfield($event)" />
                                <span style="font-size: 104%">Field</span>
                            </label>&nbsp;&nbsp;
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 28px;"*ngIf="model.chkINSApprovcmpny==true && model.chkINScmpny==true">
                            <label>
                                <input name="group5" type="radio" [checked]="model.chksupervisor"
                                    (click)="onchksupervisor($event)" />
                                <span style="font-size: 104%">Supervisor</span>
                            </label>
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 28px;">
                            <label>
                                <input name="group6" type="radio" [checked]="model.chkCustomer"
                                    (click)="onchkCustomer ($event)" />
                                <span style="font-size: 104%">Customer</span>
                            </label>
                        </div>
                    <div class="col l1 m1 s1"></div>
                </div>


                <div class="row" style="margin-top: 1%; margin-left: 3%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <span style="font-size: 140%;color: gray">Service</span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row" style="margin-top: 2%;margin-left: 3%">
                    <div class="col l1"></div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group3" type="radio" [checked]="model.chkSERcmpny"
                                (click)="onchkSERcmpny($event)" />
                            <span style="font-size: 104%">{{companyName}}</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group3" type="radio" [checked]="model.chkSERcust"
                                (click)="onchkSERcust($event)" />
                            <span style="font-size: 104%">Customer</span>
                        </label>
                    </div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group3" type="radio" [checked]="model.chkSERdealer"
                                (click)="onchkSERdealer($event)" />
                            <span style="font-size: 104%">Dealer</span>
                        </label>
                    </div>
                </div>
                <div class="row" style="margin-top: 1%; margin-left: 3%" *ngIf="model.chkSERcmpny==true">
                    <div class="col l1 m1 s1"></div>
                    <div class=" col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkSERTC" id="chkSERTC"
                                #chkSERTC="ngModel">&nbsp;&nbsp;
                            <label for="chkSERTC">Show Terms and
                                Conditions</label>
                        </p>
                    </div>
                    <div class=" col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkSERsendQuo" id="chkSERsendQuo"
                                #chkSERsendQuo="ngModel" (click)="onchkSERsendq($event)">&nbsp;&nbsp;
                            <label for="chkSERsendQuo">Send Quotation</label>
                        </p>
                    </div>
                    <div class=" col l2 m2 s2" *ngIf="model.chkSERsendQuo==true">
                        <p><input type="checkbox" [(ngModel)]="model.chkSERemail" id="chkSERemail"
                                #chkSERemail="ngModel">&nbsp;&nbsp;
                            <label for="chkSERemail">Email</label>
                        </p>
                    </div>
                    <div class=" col l2 m2 s2" *ngIf="model.chkSERsendQuo==true">
                        <p><input type="checkbox" [(ngModel)]="model.chkSERsms" id="chkSERsms"
                                #chkSERsms="ngModel">&nbsp;&nbsp;
                            <label for="chkSERsms">SMS</label>
                        </p>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row" style="margin-top: 1%; margin-left: 3%" *ngIf="model.chkSERcmpny==true">
                    <div class="col l1 m1 s1"></div>
                    <div class=" col l3 m3 s3">
                        <p><input type="checkbox" [(ngModel)]="model.chkSERshwgst" id="chkSERshwgst"
                                #chkSERshwgst="ngModel">&nbsp;&nbsp;
                            <label for="chkSERshwgst">Show GST Separately</label>
                        </p>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row" style="margin-top: 1%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <span style="font-size: 160%;color: gray">Payment Terms</span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row" style="margin-top: 2%;margin-left: 3%">
                    <div class="col l1"></div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group4" type="radio" [checked]="model.chkagnstOr"
                                (click)="onchkagnstOr($event)" />
                            <span style="font-size: 104%">Against Order</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group4" type="radio" [checked]="model.chkagnstInv"
                                (click)="onchkagnstInv($event)" />
                            <span style="font-size: 104%">Against Invoice</span>
                        </label>
                    </div>
                    <div class="col l3 m3 s3">
                        <label>
                            <input name="group4" type="radio" [checked]="model.chkagnstInst"
                                (click)="onchkagnstInst($event)" [disabled]="model.chkINScmpny!=true" />
                            <span style="font-size: 104%">Against Installation</span>
                        </label>
                    </div>
                </div>


                <div class="row" style="margin-top: 1%">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <span style="font-size: 160%;color: gray">Demographics</span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row" style="margin-top: 2%;">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="10%" style="text-align: left;padding-left: 10px;">Select</th>
                                <th width="45%" style="text-align: left;">Demographics</th>
                                <th width="10%" style="text-align: left;">Mandatory</th>
                                <th width="35%" style="text-align: left;">Default Value</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;"><input type="checkbox"
                                            [(ngModel)]="model.chknoofemployee" id="chknoofemployee"
                                            #chknoofemployee="ngModel"></td>
                                    <td style="text-align: left">No. Of Employees</td>
                                    <td style="text-align: left"><input type="checkbox" [(ngModel)]="model.mandchkemp"
                                            id="mandchkemp" #mandchkemp="ngModel"></td>
                                    <td class="input-field"></td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;"><input type="checkbox"
                                            [(ngModel)]="model.chkanualtrnovr" id="chkanualtrnovr"
                                            #chkanualtrnovr="ngModel"></td>
                                    <td style="text-align: left">Annual Turnover</td>
                                    <td style="text-align: left"><input type="checkbox"
                                            [(ngModel)]="model.mandchktrnovr" id="mandchktrnovr"
                                            #mandchktrnovr="ngModel"></td>
                                    <td class="input-field"></td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;"><input type="checkbox"
                                            [(ngModel)]="model.chkindstype" id="chkindstype" #chkindstype="ngModel">
                                    </td>
                                    <td style="text-align: left">Industry Type</td>
                                    <td style="text-align: left"><input type="checkbox"
                                            [(ngModel)]="model.mandindtype" id="mandindtype" #mandindtype="ngModel">
                                    </td>
                                    <td class="input-field" style="padding-top: 5px;padding-bottom: 5px;">

                                        <span>Industry Type</span>
                                        <select (change)="onselectIndustry($event.target.value)" data-toggle="tooltip"
                                            data-placement="top" title="Industry Type"
                                            [disabled]="model.chkindstype==false" style="margin-bottom: 0px;">
                                            <option selected value="0">Select Industry Type</option>
                                            <option *ngFor="let type of industryTypeLst" [value]="type.id"
                                                [selected]="type.id == model.industryid">
                                                {{type.name}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align: left;padding-left: 10px;"><input type="checkbox"
                                            [(ngModel)]="model.chkdesignation" id="chkindstype" #chkindstype="ngModel">
                                    </td>
                                    <td style="text-align: left">Designation</td>
                                    <td style="text-align: left"><input type="checkbox"
                                            [(ngModel)]="model.mandDesignation" id="mandindtype" #mandindtype="ngModel">
                                    </td>
                                    <td class="input-field" style="padding-top: 5px;padding-bottom: 5px;">

                                        <span>Designation</span>
                                        <select (change)="onselectDesignation($event.target.value)"
                                            data-toggle="tooltip" data-placement="top" title="Designation"
                                            [disabled]="model.chkdesignation==false"  style="margin-bottom: 0px;">
                                            <option selected value="0">Select Designation</option>
                                            <option *ngFor="let type of designationlst" [value]="type.id"
                                                [selected]="type.id == model.designationid">
                                                {{type.name}}</option>
                                        </select>
                                    </td>

                                </tr>

                                <tr>
                                    <td style="text-align: left;padding-left: 10px;"><input type="checkbox"
                                            [(ngModel)]="model.chksupervisingAuth" id="chksupervisingAuth"
                                            #chksupervisingAuth="ngModel">
                                    <td style="text-align: left">Supervising Authority</td>
                                    <td style="text-align: left"><input type="checkbox"
                                            [(ngModel)]="model.manSupervising" id="manSupervising"
                                            #manSupervising="ngModel">
                                    </td>
                                    <td class="input-field"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row">
                    <div class="input-field col l11 m11 s11" style="text-align: right">
                        <button (click)="btnback()" type="button" class="waves-effect cancelbutton btn"
                            style="margin-right: 5px;">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnsaveWorkflow()" type="button"
                            class="waves-effect waves-light btn">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>