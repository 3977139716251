import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, ProductionService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { Pipe } from '@angular/core';
import { DailyProductionPlanModel } from '../Daily Production Plan/DailyProductionPlanModel'
import { DefineProductionBookingModel } from './DefineProductionBookingModel';
import { ConversionTemplateModel } from '../Conversion Template/ConversionTemplateModel';
import { from } from 'rxjs';
declare var $: any;

@Component({
    selector: 'app-DefineProductionBooking',
    templateUrl: 'DefineProductionBooking.html',
})
export class DefineProductionBooking implements OnInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private masterservice: MasterServices,
        private transactionService: TransactionServices,
        private ProductionService: ProductionService) {
    }
    model: any = {};
    productionmodel = new DailyProductionPlanModel();
    lstoutputmeteriallst: ConversionTemplateModel[] = []
    conversionmodel = new ConversionTemplateModel();
    q: any;
    r: any;
    s: any;
    userList: any;
    userList1: any;
    user = new User();
    itmCatlst: DropDownModel[] = [];
    locationlst: DropDownModel[] = [];

    subCatlst: DropDownModel[] = [];
    quantity: number;
    balance: number = 0;

    inputitemlst: DropDownModel[] = [];
    addedlst: any[] = [];
    ngOnInit() {
        this.model.lstoutputmeteriallst = [];
        this.route.params.subscribe(params => {
            this.model.planhdrId = params["id"];
        });
        var id = document.getElementById("defaulttab");
        id.click();
        if (this.model.planhdrId != null) {

            this.ProductionService.getAllConversionForProductionBooking(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.lstoutputmeteriallst = data.lstoutputmeteriallst;
                        this.transactionService.getInventoryLocation(this.model)
                            .subscribe(data => {

                                data.locationLst.forEach(element => {
                                    if (element.id != this.model.fromlocationid) {
                                        this.locationlst.push({
                                            id: element.id,
                                            name: element.name
                                        })
                                    }
                                });
                            });

                        data.lstitemconversion.forEach(element => {
                            if (element.balance > 0) {
                                this.inputitemlst.push({
                                    id: element.itemid,
                                    name: element.itemname
                                })
                            }
                        });

                        this.productionmodel.id = this.model.planhdrId
                        this.ProductionService.getProductiondetails(this.productionmodel)
                            .subscribe(
                                data => {
                                    this.model.lstitem = data.lstitem;
                                    this.model.plancomplete = data.plancomplete
                                });
                    });




        }
        this.getitemCategory()
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }
    onSelectitemCat(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Item Category", "Opps");
            return;
        }
        let user = new User();
        this.subCatlst = [];
        this.conversionmodel.omitemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            // if (this.itmCatlst[i].id == value) {
            //     this.conversionmodel.itemcategory = this.itmCatlst[i].name
            // }
        }
        user.trnid = value
        //alert(value);
        this.masterservice.getSubCategorybycategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    countbalence(index) {
        this.inputitemlst = [];
        this.addedlst = [];
        if (this.model.lstitemconversion[index].usedquantity > this.model.lstitemconversion[index].quantity) {
            this.notifyService.showRroor("Used Quantity not more ", "Opps");
            this.model.lstitemconversion[index].usedquantity = this.model.lstitemconversion[index].quantity;
        }
        this.model.lstitemconversion.forEach(element => {
            if (element.usedquantity != null && element.usedquantity > 0) {
                element.balance = element.quantity - element.usedquantity;
                element.lstoutputmeteriallst.forEach(ouptputlst => {
                    ouptputlst.omusedquantity = ouptputlst.omquantity * element.usedquantity;
                    ouptputlst.ombalance = 0;
                    ouptputlst.ombalance = ouptputlst.quantityconversion - ouptputlst.omusedquantity;
                });
            } else {
                element.lstoutputmeteriallst.forEach(ouptputlst => {
                    ouptputlst.omusedquantity = 0;
                    ouptputlst.ombalance = ouptputlst.quantityconversion;
                });
            }
            if (element.balance > 0) {
                this.inputitemlst.push({
                    id: element.itemid,
                    name: element.itemname
                })
            }
        });
    }

    btnadd() {
        if (this.conversionmodel.itemid == null || this.conversionmodel.itemid == 0) {
            this.notifyService.showRroor("Please Select Input Item", "Opps");
            return;
        }
        if (this.quantity == null || this.quantity == 0) {
            this.notifyService.showRroor("Please Enter Quantity", "Opps");
            return;
        }
        if (this.conversionmodel.omitemcategoryid == null || this.conversionmodel.omitemcategoryid == 0) {
            this.notifyService.showRroor("Please Select Output Item Category", "Opps");
            return;
        }
        if (this.conversionmodel.omitemsubcategoryid == null || this.conversionmodel.omitemsubcategoryid == 0) {
            this.notifyService.showRroor("Please Select Output Item Sub Category", "Opps");
            return;
        }
        if (this.conversionmodel.omitemid == null || this.conversionmodel.omitemid == 0) {
            this.notifyService.showRroor("Please Select Output Item", "Opps");
            return;
        }
        if (this.conversionmodel.quantity == null || this.conversionmodel.quantity == 0) {
            this.notifyService.showRroor("Please Enter Output Item Quantity", "Opps");
            return;
        }


        this.lstoutputmeteriallst.push({
            id: null,
            itemid: this.conversionmodel.itemid,
            quantity: this.quantity,
            omitemcategoryid: this.conversionmodel.omitemcategoryid,
            omitemsubcategoryid: this.conversionmodel.omitemsubcategoryid,
            omitemid: this.conversionmodel.omitemid,
            omquantity: null,
            srno: null,
            shortname: null,
            formulaname: null,
            itemcategoryid: null,
            itemsubcategoryid: null,
            itemname: null,
            usedquantity: null,
            uom: null,
            stock: null,
            balance: null,
            uomid: null,
            dimensions: null,

            omitemname: this.conversionmodel.omitemname,
            omuom: this.conversionmodel.omuom,
            omstock: null,
            ombalance: null,
            omuomid: null,
            omdimensions: null,
            diamensionUnit: null,
            omusedquantity: this.conversionmodel.quantity,
            quantityconversion: this.conversionmodel.quantity,
            itemreiredquantity: null,
            lstoutputmeteriallst: null,
            omitemcode: this.conversionmodel.omitemcode


        })
        this.conversionmodel.omitemname = null;
        this.conversionmodel.quantity = null;
        this.conversionmodel.omitemid = null;
    }

    cancle() {
        this.router.navigate(['/Production Booking']);
    }
    btnsaveProductionBooking() {
        if (this.model.bookingdone == false) {
            let balanceflag = false;
            let i = null;
            this.model.lstitemconversion.forEach(element => {
                if (element.balance == null || element.balance == "" || element.balance < 0) {
                    balanceflag = true;
                }
            });
            if (balanceflag) {
                i = confirm("All Item no Set used Quantity");
            }
            if (i) {
                this.model.lstoutputmeteriallst = this.lstoutputmeteriallst;
                this.ProductionService.SaveproductionBooking(this.model)
                    .subscribe((value) => {
                        if (value.status == "OK") {
                            var id = document.getElementById("fpd");
                            id.click();
                            this.notifyService.showSuccess("Booking Saved Successfully", "Done");
                            this.model.bookingdone = true;
                          
                        }

                    });
            }
        } else {
            var id = document.getElementById("fpd");
            id.click();
        }

    }

    onSelectSubCat(value) {
        this.userList = [];
        if (value == 0) {
            this.notifyService.showRroor("Select Item Sub Category", "Opps");
            return;
        }
        this.conversionmodel.omitemsubcategoryid = value;
    }
    getItemLike() {
        if (this.conversionmodel.omitemcategoryid == null || this.conversionmodel.omitemcategoryid == 0) {
            this.notifyService.showRroor("select item categories", "Opps");
            return;
        }
        if (this.conversionmodel.omitemsubcategoryid == null || this.conversionmodel.omitemsubcategoryid == 0) {
            this.notifyService.showRroor("select item Sub categories", "Opps");
            return;
        }

        let userId = (<HTMLInputElement>document.getElementById('productname')).value;
        this.userList = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.conversionmodel.omitemcategoryid;
        this.user.trnid = this.conversionmodel.omitemsubcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList.push({
                        "itemname": item.itemname,
                        "itemcode": item.itemcode,
                        "itemid": item.itemid,
                        "uom": item.uom,
                        "dimensions": item.dimensions,
                        "diamensionUnit": item.diamensionUnit,
                    }));

                    var names = "";
                    for (let i = 0; i < this.userList.length; i++) {

                        searchDataObj[this.userList[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList[i].itemname;
                        } else {
                            names = names + "," + this.userList[i].itemname;
                        }
                    }
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem(txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadONselectitem(txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadONselectitem(itemname) {
        this.userList.forEach(element => {
            if (element.itemname.trim() == itemname.trim()) {
                this.conversionmodel.omitemid = element.itemid;
                this.conversionmodel.omitemname = itemname;
                this.conversionmodel.omuom = element.uom;
                this.conversionmodel.omitemcode = element.itemcode
            }
        });
    }

    deleleitem(index) {
        var i = confirm("Do You Want to delete");
        if (i) {
            this.lstoutputmeteriallst.splice(index, 1);
        } else {
            alert("You Select Cancle");
        }

    }

    btnnexttab() {

    }
    onSelectitem(id) {
        this.balance = 0;
        this.model.lstitemconversion.forEach(element => {
            if (element.itemid == id) {
                if (this.balance == null) {
                    this.balance = element.balance;
                } else {
                    this.balance = this.balance + element.balance;
                }
                this.conversionmodel.itemid = id;
            }
        });
    }
    quantitychange() {
        if (this.quantity < this.balance) {
            this.notifyService.showRroor("Input Quantity not more than Balence", "Opps");
            this.quantity = this.balance;
        }
    }
    /////// Finish Product

    btnsaveFinishproduct() {
        let flag: boolean = false;
        this.model.lstitem.forEach(element => {
            if (element.actualproduction == null || element.actualproduction < 0 || element.actualproduction > element.productionquantity) {
                flag = true;
            }
        });
        if (flag) {
            this.notifyService.showRroor("Enter Actual Production Quantity", "Opps");
            return;
        }

        if (this.model.plancomplete == false) {
            this.model.userid=Number(localStorage.getItem("userid"));
            this.ProductionService.btnsaveFinishproduct(this.model)
                .subscribe((value) => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess("Finish Product Saved Successfully", "Done");
                        this.productionmodel.id = this.model.planhdrId;
                        this.ProductionService.getProductiondetails(this.productionmodel)
                            .subscribe(
                                data => {
                                    this.model.lststocktransfer = data.lstitem;
                                    this.model.plancomplete = data.plancomplete
                                });
                        var id = document.getElementById("st");
                        id.click();
                    }
                });
        }
        else {
            var id = document.getElementById("st");
            id.click();
        }
    }

    onSelectInventoryLocationTo(id) {
        this.model.tolocationid = id;
    }

    transferquantitychange(index) {
        if (this.model.lstitem[index].transferquantity > this.model.lstitem[index].actualproduction) {
            this.notifyService.showRroor("Transfer Quantity Not more than Actual Production ", "Opps");
            this.model.lstitem[index].transferquantity = this.model.lstitem[index].actualproduction;
            return;
        }
    }
    btnsaveStocktransfer() {
        let flag: boolean = false;
        if (this.model.tolocationid == null || this.model.tolocationid == 0) {
            this.notifyService.showRroor("Please select To Inventory Location", "Opps");
            return;
        }
        // this.model.lstitem.forEach(element => {
        //     if (element.checkselect) {
        //         flag = true;
        //     }
        // });

        // if (flag) {
        //     this.notifyService.showRroor("Select At Least One Item", "Opps");
        //     return;
        // }

        var found = this.model.lstitem.find(function (x) { return x.checkselect === true; });
        if (!found) {
            this.notifyService.showRroor("Select At Least One Item", " Oops");
            return;
        }
        flag = false;
        this.model.lstitem.forEach(element => {
            if (element.checkselect) {
                if (element.transferquantity == null || element.transferquantity < 0 || element.transferquantity > element.actualproduction) {
                    flag = true;
                }
            }
        });
        if (flag) {
            this.notifyService.showRroor("Enter Valid Transfer Quantity", "Opps");
            return;
        }
        this.model.userid=Number(localStorage.getItem("userid"));
        this.ProductionService.btnsavestockTransfer(this.model)
            .subscribe((value) => {
                if (value.status == "OK") {
                    this.notifyService.showSuccess("Stock Transfered Successfully", "Done");
                    this.router.navigate(['Production Booking']);
                }
            });
    }



    actucalproductionchange(index) {
        if (this.model.lstitem[index].actualproduction > this.model.lstitem[index].productionquantity) {
            this.notifyService.showRroor("Production Quantity Not more than Plan Quantity", "Opps");
            this.model.lstitem[index].actualproduction = this.model.lstitem[index].productionquantity
            return;
        }
    }
}