<div *ngIf="isMobile == true ">
 <app-header></app-header>
 
    <div class="row" style="max-width:900px;">
        <div style="margin-top: 60px;">
            <div class="col s2 l3 m3"></div>
            <div class="input-field col l6 s8 m6 registerBgShadow "
                style="height: 100%; background: #f7f7f7;">
                <h1 class="header center" style="font-size: 100%;font-weight: bold;">Change Password</h1>
                <!-- <div class="center-align">
                    <img id="profile-img" src="../../assets/images/easy-dry-logo.png" width="50%" />
                </div> -->
                <form (ngSubmit)="f.form.valid && btnsave()" #f="ngForm" novalidate style="margin-top: 10px;">
                    <div class="col l12 s12 m12" style="margin-bottom: 3%">
                        <div class="row">
                            <div class="input-field col l12 s12 m12" style=" margin-left: 27px;width: 85%;;">
                                <input name="new password" [(ngModel)]="model.pass" #confirmpassword="ngModel" id="pass"
                                    type="password" class="validate" >
                                    <!-- required autofocus -->
                                <!-- <div *ngIf="f.submitted && !username.valid" class="help-block"
                                    style="padding-top: 3px;color: red">Username is
                                    required</div> -->
                                <label for="user">New Password</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col l12 s12 m12"
                                style="margin-left: 27px;width: 85%;; margin-bottom: 35px;">
                                <input type="password" class="validate" name="Confirm Password" [(ngModel)]="model.password" id="password"
                                    #password="ngModel" >
                                    <!-- required autofocus -->
                                <!-- <div *ngIf="f.submitted && !pass.valid" class="help-block"
                                    style="padding-top: 3px;color: red">Password is
                                    required</div> -->
                                <label for="pass">Confirm Password</label>
                            </div>
                        </div>
                        <div class="row" style=" padding: 1%;">
                            <div class="center-align">
                                <button class="btn grayButton" type="submit" (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                                <button class="btn btn-blue" type="submit">Save</button>
                            </div>
                        
                        </div>
                    </div>
                </form>
            </div>
            <div class="col s2 l3 m3"></div>
        </div>
    </div>
</div>
<div *ngIf="isMobile == false ">
    <app-header></app-header>
    <div class="container">
        <div class="row">
            <div class="col l2"></div>
            <div class="heading col l8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col 12"></div>
            <div class="col s12 m12 l12 registerBgShadow">
                <div class="row">
    
                    <div class="col s2"></div>
                    <div class="input-field col l4 m4 s4" style="text-align: center;margin-top: 30px;">
                        <input [(ngModel)]="model.pass" #confirmpassword="ngModel" type="password" class="" data-toggle="tooltip"
                            appForbiddenName="bob" data-placement="top" title="New Password" id="pass" name="new password"
                            maxlength="20" required>
                        <label id="lable2" for="password">New Password</label>
                        <div *ngIf="confirmpassword.invalid && (confirmpassword.dirty || confirmpassword.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="confirmpassword.errors.required">
                                Password Required
                            </div>
                        </div>
                    </div>
    
                    <div class="input-field col l4 m4 s4" style="text-align: center;margin-top: 30px;">
                        <input [(ngModel)]="model.password" #password="ngModel" type="password" class=""
                            data-toggle="tooltip" data-placement="top" title="Confirm Password" id="password"
                            name="Confirm Password" required>
                        <label id="lable1" for="password">Confirm Password</label>
                        <div *ngIf="password.invalid && (password.dirty || password.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="password.errors.required">
                                Confirm Password Required
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l1"></div>
                        <div class="row">
                            <div class="input-field col l10 m10 s10" style="text-align: right;padding-right: 18px;">
                                <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                                (click)="cancle()">Cancel</button>
                                <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                                    style="margin-left: 5px;">Save</button>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>