<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Daily Production Plan</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%;padding-top: 5px;">
            <div class="col l12 m12 s12"   style="text-align: center;padding: 0px;">
                <span style="font-size: 16px;">Create Plan Requisition</span>
            </div>
           
            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span style="font-size: 16px;">Production Plan ID <br>
                        <b>{{model.planID}}</b></span>
                </div>
                <div class="input-field col l2 m2 s2" style="margin: 0px">
                    <span style="font-size: 16px;">Plan Date<br>
                        <b> {{model.plandate |date : "dd-MM-yyyy"}}</b>
                    </span>

                </div>

            </div>
            <div class="row">
                <div class="input-field col l3 m3 s3">
                    <span style="font-size: 16px;">From Inventory Locations<br>
                        <b> {{model.fromlocation}}</b></span>
                </div>
                <div class="input-field col l4 m4 s4">
                    <span style="font-size: 16px;">To Inventory Location</span><br>
                    <select  (change)="onSelectInventoryLocationTo($event.target.value)"  data-toggle="tooltip"
                        data-placement="top" title="Inventory Location">
                        <option selected value="0">Select To Inventory Location</option>
                        <option *ngFor="let lst of locationlst" [value]="lst.id"
                            [selected]="lst.id==model.tolocationid" >
                            {{lst.name}}
                        </option >
                    </select >
                </div>
                <div class="input-field col l2 m2 s2" >
                    <span style="font-size: 16px;">Requisition Date<br>
                        <input type="text" *ngIf="!model.requisetionsend" placeholder="Requisition Date" [(ngModel)]="model.requisitiondate"
                            id="requisitiondate" class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue',placement:'top' }">

                            <span *ngIf="model.requisetionsend"><b>{{model.requisitiondate |date : "dd-MM-yyyy" }}</b></span>
                    </span>
                </div>
                <div class="input-field col l2 m2 s2" *ngIf="model.requisetionsend">
                    <span style="font-size: 16px;">Requisition number<br>
                        <b>{{model.requisitionnumber}}</b>
                    </span>
                </div>
            </div>

            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <table>
                    <thead style="background: gainsboro;">
                        <th width="10%" style="text-align: center">Item Code</th>
                        <th width="20%" style="text-align: center">Item Name</th>
                        <th width="5%" style="text-align: center">UOM</th>
                        <th width="10%" style="text-align: center">Quantity Requisitioned</th>
                        <th width="10%" style="text-align: center">Stock</th>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let lst of model.lstitemdetails | paginate: { itemsPerPage: 10, currentPage: p };let i=index;">
                            <td style="text-align: center">{{lst.itemcode}}</td>
                            <td style="text-align: center">{{lst.itemname}}</td>
                            <td style="text-align: center">{{lst.uom}}</td>
                            <td style="text-align: center" *ngIf="!model.requisetionsend">
                                <input type="text" [(ngModel)]="lst.quantityreqired" style="text-align: end;" #quantityreqired="ngModel"
                                    class="" />
                            </td>
                            <td style="text-align: center" *ngIf="model.requisetionsend">
                                {{lst.quantityreqired | number:'1.2-2'}}
                            </td>
                            <td style="text-align: center">{{lst.stock | number:'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align:right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </span>
            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button (click)="btnBack()" type="button"
                        class="waves-effect cancelbutton waves-light btn">Back</button>
                    &nbsp;&nbsp;
                    <button (click)="btncancle()" type="button"
                        class="waves-effect cancelbutton waves-light btn"  *ngIf="!model.requisetionsend">Cancel</button>
                    &nbsp;&nbsp;<button (click)="btnfinish()" type="button" class="waves-effect waves-light   btn"
                        *ngIf="!model.requisetionsend">Finish</button>
                    &nbsp;&nbsp;<button (click)="btnFinalize()" type="button" class="waves-effect waves-light   btn"
                        *ngIf="!model.requisetionsend">Finalize</button>
                </div>
            </div>
        </div>
    </div>
</div>