import { DailyCallModel, TeamMembers } from "../DailyCall/DailyCallModel";
import { productmodel } from "../Sales-Transaction/ProspectModel";

export class CallCenterDashboardModel{
    id:number;
    productid:number
    name:string;
    servicecallNo:string;
    address:string;
    assignbyname:string;
    telephone1:number;
    email:string;
    accountid:number;
    serialNo:string;
    installationDate:Date;
    orderDate:Date;
    descrption:string;
    minimumPrice:number;
    locality:string;
    modelLst:CallCenterDashboardModel[]=[];
    lstproduct: productmodel[] = [];
    customer:string;
    length:number;
    status:string;
    servicecallno:string;
    customername:string;
    callDate:string;
    callBookDate:string;
    memberlst: TeamMembers[];
    // calllst:CallCenterDashboardModel[]=[];
    // subacclst:CallCenterDashboardModel[]=[];
 
    dcrHistorylst: DailyCallModel[] = []
    checkselectMob:string
    checkseleName:string
    checkseleLocal:string
    checkseleEmail:string

    txtserach2:string;
    txtserch: string;
    txtselect: string;

    callclose:boolean;
    totalcount:number;
    quotationid:number;
    companyname:string;
    companyaddress:string;
    sparedescription:string;
    lstspare:CallCenterDashboardModel[]=[];
    srno:number;
    gstamount;number;
    amount:number;
    discountAmount:number;
    cname:string;
    lstspareivoice:CallCenterDashboardModel[]=[];
    invoicenumber:string;
    datetime:string;
    iname:string;
    rate:number;
    gstpersentage:number;
    rateWithGst:number;
    tempVar:number;
    invSend:string;
    totalItems:number = 0;
    callcommitDate:string;
}