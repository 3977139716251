import { DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../_models";
import { AlertService, MasterServices, TransactionServices } from "../_services";
import { DealersService } from "../_services/DealersService";
import { NotificationService } from "../_services/notification.service";
import { DealersDetailModel, GeographyModel, ProductModel1 } from "./DealersDetailModel";
import { Location } from '@angular/common';
import { DropDownModel } from "../_models/DropDownModel";
import { productmodel, ProspectModel } from "../Transactions/Sales-Transaction/ProspectModel";
import { Products } from "../Transactions/DailyCall/DailyCallModel";
import { CurrencyModel } from "../Transactions/Quotation/QuotationModel";
import { DefineProductModel } from "../Master/ProductMaster/DefineProductModel";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { DefinedlrcontModel } from "./DefinedlrcontModel";
import { DefineDealerLogisticsModel } from "./DefineDealerLogisticsModel";
import { EncrDecrService } from "../_services/EncrDecrService";
import { usertwofieldModel } from "../Master/ResourceMaster/usertwofieldModel";
declare var $: any;
import convertor from 'rupees-to-words';
@Component({
  selector: 'app-login',

  templateUrl: 'DealersDetail.html'
})
export class DealersDetail implements OnInit {

  model = new DealersDetailModel();
  modelp = new ProductModel1();
  model1 = new ProspectModel();
  models = new DefineProductModel();
  modelg = new GeographyModel();
  modelc = new DefinedlrcontModel();
  modell = new DefineDealerLogisticsModel();
  sub: any;
  user = new User();
  id: number;
  public i: any;
  p: any;
  q: any;
  r: any;
  loading:boolean=false;
  productList2: any;
  productid: number;
  productmap = new Map();
  currentmap = new Map();
  mrpmap = new Map();
  userList2: any;
  keyword = 'name';
  keyword2 = 'productName';
  pincodelist = [];
  pincodelist1 = [];
  promodel = new productmodel();
  defaultcurrencylst: DropDownModel[] = [];
  public statelst: DropDownModel[] = [];
  public countrylst: DropDownModel[] = [];
  public regionlst: DropDownModel[] = [];
  public citylst: DropDownModel[] = [];
  public localelst: DropDownModel[] = [];
  public arealst: DropDownModel[] = [];
  lstconstutution: DropDownModel[] = [];
  lstlocality: DropDownModel[] = [];
  lstlocalitys: DropDownModel[] = [];
  productcatelst: DropDownModel[] = [];
  modellst: DealersDetailModel[] = [];
  locmodlst: DealersDetailModel[] = [];
  locmodlst1: DealersDetailModel[] = [];
  geolst: DealersDetailModel[] = [];
  localitymap = new Map();
  proid: number;
  currencylst: CurrencyModel[] = []
  currcymodel = new CurrencyModel();
  productcategoryid: number;
  index: number;
  designationlst: DropDownModel[] = [];
  cntylst1: DropDownModel[] = [];
  cntylst2: DropDownModel[] = [];
  contactlst: DefinedlrcontModel[] = [];
  dealerid: number;
  address: number;
  modalRef: BsModalRef;
  // localitymap = new Map();
  // lstlocality: DropDownModel[] = [];
  pincode: string;
  localityID: number;
  city: string;
  state: string;
  country: string;
  locale: string;
  lstaddress: DefineDealerLogisticsModel[] = [];
  usergrouplst: usertwofieldModel[] = [];
  hiddenflag: boolean;
  productlst: Products[] = [];
  paymenttermslst: DropDownModel[] = [
    { id: 1, "name": "Against Order" },
    { id: 2, "name": "Against Invoice" },
    { id: 3, "name": "Against Installation" }
  ]
  locationlst: DropDownModel[] = [];

  constructor(private Masterservice: MasterServices,
    private EncrDecr: EncrDecrService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private dealersService: DealersService,
    private router: Router,
    private modalService: BsModalService,
    private transactionService: TransactionServices,
    private notifyService: NotificationService) {
  }
  // paymenttermslst: DropDownModel[] = [
  //   { id: 1, "name": "Against Order" },
  //   { id: 2, "name": "Against Invoice" },
  //   { id: 3, "name": "Against Installation" }
  // ]
  statuslst: DropDownModel[] = [
    { id: 1, "name": "Active" },
    { id: 2, "name": "Inactive" },
  ]
  ngOnInit(): void {

    this.hiddenflag = false
    this.getConstitution();
    this.getdefaultcurr();
    this.getAllCountries();
    this.getRegions();
    this.getdesignation();
    this.getcountry();
    this.getProductcat();
    this.getDept();
    this.getdeliveryloc();
    // this.getAllProduct();
    this.model.vendor = "D";

    this.user.userid = Number(localStorage.getItem('userid'));
    this.model.userid = Number(localStorage.getItem('userid'));
    this.lstlocalitys.push({ id: 0, name: "Select Locality" });

    var id = document.getElementById("details");
    id.click();

    this.model.status = "Active";

    this.sub = this.route.params.subscribe(params => {
      this.id = + params["id"];
    });


    if (this.id != null && this.id > 0) {

      this.model.id = this.id
      this.dealersService.getdealerproduct(this.model)
        .subscribe(
          data => {
            this.modellst = data;

          });

      // this.modelc.dealerid = this.modelc.id
      this.model.id = this.id
      // this.model.localall=false;
      this.dealersService.getgeographyByid(this.model)
        .subscribe(
          data => {
            this.locmodlst = data;
            if(this.model.localall==true){
              for(let i = 0; i < this.locmodlst.length; i++){
                this.locmodlst.push({
                  id: 0,
                  code: "",
                  name: "",
                  status: "",
                  state: "",
                  city: "",
                  locale: "",
                  country: "",
                  proid: 0,
                  pincode: "",
                  locality: "",
                  email: "",
                  gsttype: "",
                  chkwihitins: false,
                  chkoutsides: false,
                  website: "",
                  panNo: "",
                  gstNo: "",
                  egst: false,
                  landline: 0,
                  constitutionid: 0,
                  constitutionname: "",
                  currencyid: 0,
                  creditAmount: 0,
                  creditDays: 0,
                  googlemaplocation: "",
                  about: "",
                  paymentTerm: "",
                  gstType: "",
                  dealerproid: 0,
                  usergroup: "",
                  usergroupid: 0,
                  paymentType: "",
                  address: "",
                  productcatname: "",
                  chkgst: false,
                  productName: "",
                  defaultCurrency: 0,
                  localityID: 0,
                  mrp: 0,
                  productid: 0,
                  localeid: 0,
                  transferprice: 0,
                  currencyName: "",
                  productcategoryid: 0,
                  commission: 0,
                  checkselectprocat: false,
                  geolst: [],
                  locmodlst: [],
                  modellst: [],
                  contactlst: [],
                  localityid: 0,
                  countryid: 0,
                  regionid: 0,
                  region: "",
                  areaid: 0,
                  stateid: 0,
                  area: "",
                  cityid: 0,
                  checkselectgeo: false,
                  localmodlst: [],
                  description: "",
                  vendor: "",
                  userId: 0,
                  userid: 0,
                  dealersid: 0,
                  wigstflag: "",
                  outgstflag: "",
                  serialnoFlag: false,
                  chkselectlogin: false,
                  contactlst1: [],
                  selectall: false,
                  customertype: undefined,
                  checkselectegstn: false,
                  locationlst: [],
                  deliverylocid: 0,
                  localall: false,
                  locallist:[]
                })
              }
            }else if(this.model.localall==false){
              this.locmodlst.push({
                id: 0,
                code: "",
                name: "",
                status: "",
                state: "",
                city: "",
                locale: "",
                country: "",
                proid: 0,
                pincode: "",
                locality: "",
                email: "",
                gsttype: "",
                chkwihitins: false,
                chkoutsides: false,
                website: "",
                panNo: "",
                gstNo: "",
                egst: false,
                landline: 0,
                constitutionid: 0,
                constitutionname: "",
                currencyid: 0,
                creditAmount: 0,
                creditDays: 0,
                googlemaplocation: "",
                about: "",
                paymentTerm: "",
                gstType: "",
                dealerproid: 0,
                usergroup: "",
                usergroupid: 0,
                paymentType: "",
                address: "",
                productcatname: "",
                chkgst: false,
                productName: "",
                defaultCurrency: 0,
                localityID: 0,
                mrp: 0,
                productid: 0,
                localeid: 0,
                transferprice: 0,
                currencyName: "",
                productcategoryid: 0,
                commission: 0,
                checkselectprocat: false,
                geolst: [],
                locmodlst: [],
                modellst: [],
                contactlst: [],
                localityid: 0,
                countryid: 0,
                regionid: 0,
                region: "",
                areaid: 0,
                stateid: 0,
                area: "",
                cityid: 0,
                checkselectgeo: false,
                localmodlst: [],
                description: "",
                vendor: "",
                userId: 0,
                userid: 0,
                dealersid: 0,
                wigstflag: "",
                outgstflag: "",
                serialnoFlag: false,
                chkselectlogin: false,
                contactlst1: [],
                selectall: false,
                customertype: undefined,
                checkselectegstn: false,
                locationlst: [],
                deliverylocid: 0,
                localall: false,
                locallist:[]
              })


            }

          });

      // this.modelc.dealerid = this.model.id
      this.model.id = this.id
      this.dealersService.getdealerid(this.model)
        .subscribe(
          data => {
            this.contactlst = data.contactlst;
          },
          error => {
            this.alertService.error(error);
          });

      this.model.id = this.id
      this.lstlocality.push({ id: 0, name: "Select Locality" });
      this.dealersService.getshippingadd(this.model)
        .subscribe(
          data => {
            this.lstaddress = data.lstaddress;
          },
          error => {
            this.alertService.error(error);
          });

      let user = new User();
      user.trnid = this.id;
      this.model.id = this.id;
      this.dealersService.getdealersdetailsById(this.model)
        .subscribe(
          data => {
            this.model = data;
            this.model.selectall = false;
            this.model.pincode = data.pincode;
            this.model1.pincode = data.pincode;
            this.lstlocality = [];


            this.lstlocality.push({ id: 0, name: "Select Locality" });
            this.transactionService.getlocality(this.model1)
              .subscribe(lst => {
                lst.forEach(item => {
                  this.lstlocality.push({ id: item.id, name: item.name });
                });
                this.model.locality = data.locality
              });
            if (this.model.gstNo != null) {
              this.model.checkselectegstn = true;
            }
            if (data.gsttype == "InS") {
              this.model.chkwihitins = true;
              this.model.gsttype = "InS"
            } else if (data.gsttype == "OutS") {
              this.model.chkoutsides = true;
              this.model.gsttype = "OutS"
            }

          },
          error => {
            this.alertService.error(error);
          });
      let l1 = (<HTMLInputElement>document.getElementById('lable1'));
      l1.className = "active";
      let l2 = (<HTMLInputElement>document.getElementById('lable2'));
      l2.className = "active";
      let l3 = (<HTMLInputElement>document.getElementById('lable3'));
      l3.className = "active";
      let l4 = (<HTMLInputElement>document.getElementById('lable4'));
      l4.className = "active";
      let l5 = (<HTMLInputElement>document.getElementById('lable5'));
      l5.className = "active";
      let l6 = (<HTMLInputElement>document.getElementById('lable6'));
      l6.className = "active";
      let l7 = (<HTMLInputElement>document.getElementById('lable7'));
      l7.className = "active";
      let l8 = (<HTMLInputElement>document.getElementById('lable8'));
      l8.className = "active";
      // let l9 = (<HTMLInputElement>document.getElementById('lable9'));
      // l9.className = "active";
      let l10 = (<HTMLInputElement>document.getElementById('lable10'));
      l10.className = "active";
      let l11 = (<HTMLInputElement>document.getElementById('lable11'));
      l11.className = "active";
      let l12 = (<HTMLInputElement>document.getElementById('lable12'));
      l12.className = "active";
      // let l13 = (<HTMLInputElement>document.getElementById('lable13'));
      // l13.className = "active";


    }

  }
  onSelectPayment(val) {
    if (val == "0") {
      this.notifyService.showRroor("Please select payment type!", "Oops!");
      this.model.paymentType = null;
      return;
    }
    this.model.paymentType = val;
  }
  getConstitution() {
    this.lstconstutution = [];
    let user = new User();
    this.lstconstutution.push({ id: 0, name: "Select Constitution" });
    this.Masterservice.getindustorylst(user)
      .subscribe(data => {
        for (let i = 0; i < data.consistutelst.length; i++) {
          this.lstconstutution.push({
            id: data.consistutelst[i].id,
            name: data.consistutelst[i].name,
          })
        }
      });
  }

  getdefaultcurr() {
    let user = new User();
    this.defaultcurrencylst = [];
    this.Masterservice.getdefaultcurr(user)
      .subscribe((value) => {
        this.defaultcurrencylst = value.deafcurlst
      });
  }
  getDept() {
    let user = new User();
    this.usergrouplst = [];
    this.Masterservice.getDept(user)
      .subscribe((value) => {
        this.usergrouplst = value.usergrolst

      });
  }
  getPincodeLike($event) {
    let userId = (<HTMLInputElement>document.getElementById('pincode1')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;

    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getpincodelike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
    // if (this.model1.pincode.length < 6 || this.model1.pincode.length > 6) {
    //   this.lstlocality = [];
    // }
  }
  typeaheadOnSelect(id, name) {
    // this.model.id = id;

    this.model1.pincode = name;
    this.model.pincode = name;
    this.model.localityID = null;
    this.lstlocality = [];
    this.lstlocality.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.model1)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocality.push({ id: item.id, name: item.name });
        });
      });
  }

  getlocatity() {
    if (this.model1.pincode.length == 6) {
      this.lstlocality = [];
      this.lstlocality.push({ id: 0, name: "Select Locality" });
      this.transactionService.getlocality(this.model1)
        .subscribe(data => {
          data.forEach(item => {
            this.lstlocality.push({ id: item.id, name: item.name });
            this.localitymap.set(item.id, item.name)
          });
        });
    }
  }
  onSelectlocality(id) {
    this.model1.localityID = id;
    this.model1.locality = this.localitymap.get(id);
    this.model.localityID = id;
    this.model.locality = this.localitymap.get(id);

    this.transactionService.getgeographydetails(this.model1)
      .subscribe(data => {
        this.model.city = data.city;
        this.model.state = data.state;
        this.model.country = data.country;

      });


  }
  onSelectStatus(val) {
    if (val == 0) {
      this.notifyService.showRroor("Select Status!!", "Oops!");
      return;
    }
    this.model.status = val;
  }
  onSelectconstutution(value) {
    if (value == 0) {
      this.notifyService.showRroor("Select Constitution!!", "Oops!");
      return;
    }
    this.model.constitutionid = value
  }
  onSelectDefaultcurrency(value) {
    if (value == 0) {
      this.notifyService.showRroor("Select Default Currency!!", "Oops!");
      return;
    }
    this.model.defaultCurrency = value;
  }
  onchkwihitins($event) {
    this.model.chkoutsides = false

    this.model.chkwihitins = true
    this.model.wigstflag = "InS";

    this.model.outgstflag = null;
  }
  onchkoutsides($event) {
    this.model.chkwihitins = false

    this.model.chkoutsides = true
    this.model.outgstflag = "OutS";
    this.model.wigstflag = null;

  }




  onSelectusergroup(value) {
    if (value == 0) {
      this.notifyService.showRroor("Select User group !!", "Oops!");
      return;
    }
    for (let i = 0; i < this.usergrouplst.length; i++) {
      if (this.usergrouplst[i].id == value) {
        this.modelc.usergroup = this.usergrouplst[i].name;
        this.modelc.usergroupid = this.usergrouplst[i].id
      }
    }
  }



  savedealers() {
    if (this.model.code == null || this.model.code == "") {
      this.notifyService.showRroor("Enter Code !!", "Oops!");
      return;
    }
    if (this.model.name == null || this.model.name == "") {
      this.notifyService.showRroor("Enter Name !!", "Oops!");
      return;
    }
    if (this.model.address == null || this.model.address == "") {
      this.notifyService.showRroor("Enter Address !!", "Oops!");
      return;
    }
    if (this.model.pincode == null || this.model.pincode == "") {
      this.notifyService.showRroor("Enter Pincode !!", "Oops!");
      return;
    }
    if (this.model.localityID == null || this.model.localityID == 0) {
      this.notifyService.showRroor("Please Select Locality !!", "Oops!");
      return;
    }
    // if (this.model.googlemaplocation == null || this.model.googlemaplocation == "") {
    //   this.notifyService.showRroor("Enter Google Map Location !!", "Oops!");
    //   return;
    // }
    if (this.model.landline == null) {
      this.notifyService.showRroor("Please Enter Mobile Number!!", "Oops!");
      return;
    }
    if (this.model.email != null || this.model.email != "") {
      let emailval = /^[a-z0-9._ %+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (!emailval.test(this.model.email)) {
        this.notifyService.showRroor("Email-id is not in valid format !!", " Oops!");
        return;
      }
    }

    if (this.model.checkselectegstn == true) {
      if (this.model.gstNo != null || this.model.gstNo != "") {
        // var gstval = ^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]1}[1-9A-Z]{1}Z[0-9A-Z]{1}$ ;
        // if (!gstval.test(this.model.gstNo)) {
        let regex = /^[A-Za-z0-9]{15}/
        if (!regex.test(this.model.gstNo)) {
          this.notifyService.showRroor("Please Enter valid GST Number", " Oops!");
          return;
        }
        if (this.model.chkwihitins == null || this.model.chkoutsides == null) {
          this.notifyService.showRroor("Please Select atleast one GST Type !!", "Oops!");
          return;
        }
      }
    }
    if (this.model.constitutionid == null) {
      this.notifyService.showRroor("Please Select Constitution !!", "Oops!");
      return;
    }
    if (this.model.defaultCurrency == null) {
      this.notifyService.showRroor("Please Select Currency !!", "Oops!");
      return;
    }
    if (this.model.panNo == null || this.model.panNo == "") {
      this.notifyService.showRroor("Enter PAN No. !!", "Oops!");
      return;
    }

    if (this.model.paymentType == "Against Order" && this.model.creditAmount == null) {
      this.notifyService.showRroor("Please Enter Credit Amount !!", "Oops!");
      return;
    }
    if (this.model.paymentType == "Against Order" && this.model.creditDays == null) {
      this.notifyService.showRroor("Enter Credit Days !!", "Oops!");
      return;
    }
    this.model.userid = Number(localStorage.getItem('userid'));
    this.model.customertype = "Dealer";
    this.dealersService.savedealersdtl(this.model)
      .subscribe(
        data => {
          if (data.status == "OK") {
            this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
            this.model.id = Number(data.message)
            var id = document.getElementById("products");
            id.click();
            // this.router.navigate(["/Product"]);

          } else {
            this.notifyService.showRroor(data.message, "Oops !")
          }
        },
        error => {
          alert("Something Wrong ")
        });


  }

  btncancledetail() {
    this.router.navigate(["Dealer Details"]);
  }
  getProductcat() {

    this.Masterservice.getProductcat(this.user)
      .subscribe(data => {

        this.productcatelst = data.procategorylst;

      });

  }
  onSelectcategory(value) {
    if (value == 0) {
      this.notifyService.showRroor("Select Product Category!!", "Oops!");
      return;
    }
    this.model.productcategoryid = value;

    for (let i = 0; i < this.productcatelst.length; i++) {
      if (this.productcatelst[i].id == this.model.productcategoryid)
        this.model.productcatname = this.productcatelst[i].name
    }

    this.productlst = [];
    this.user.trnid = value;
    this.transactionService.getProductsByPC(this.user)
      .subscribe(
        data => {
          this.productlst = data
        });
  }



  btncanclepro() {
    this.router.navigate(["Commercial"]);
  }

  getProductLike() {

    let userId = (<HTMLInputElement>document.getElementById('productname1')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    this.user.productcategoryId = this.model.productcategoryid;
    var obj = this;
    if (userId.length >= 3) {
      this.dealersService.getProductLike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "productcatname": item.productcatname, "productName": item.productName, "productid": item.productid, "currencyName": item.currencyName, "mrp": item.mrp }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].productName, this.userList2[i].productid)
            this.currentmap.set(this.userList2[i].productName, this.userList2[i].currencyName)
            this.mrpmap.set(this.userList2[i].productName, this.userList2[i].mrp)
            searchDataObj[this.userList2[i].productName] = null;
            if (i == 0) {
              names = this.userList2[i].productName;
            } else {
              names = names + "," + this.userList2[i].productName;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect1(map.get(txt), txt);

              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelect1(map.get(txt), txt);

              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }

  }

  typeaheadOnSelect1(val1, val2) {
    this.model.productid = val1;
    this.model.productName = val2;
    this.model.mrp = this.mrpmap.get(val2);
    this.model.currencyName = this.currentmap.get(val2);

  }
  onChangeOfcommition($event) {
    if (this.model.commission > 100) {
      this.notifyService.showRroor("Commission is not greater than 100%", "Opps");
      return;
    }
  }

  addproduct() {
   
    if (this.model.id == null) {
      this.notifyService.showRroor("Please Enter Dealer Details !!", "Oops!");
      return;
    }

    if (this.model.productcategoryid == null) {
      this.notifyService.showRroor("Please Select Productcategory", "Opps");
      return;
    }
    if (this.model.productid == null) {
      this.notifyService.showRroor("Please Select Product", "Opps");
      return;
    }
    if (this.model.commission == null || this.model.commission > 100) {
      this.notifyService.showRroor("Enter Commission", "Opps");
      return;
    }
    for (let i = 0; i < this.modellst.length; i++) {
      if (this.modellst[i].productid == this.model.productid) {
        this.notifyService.showRroor("Product already present", " Oops");
        return;
      }
    }
    if (this.model.selectall == false) {

      this.modellst.push({
        id: this.model.id,
        productid: this.model.productid,
        code: "",
        name: "",
        status: "",
        state: "",
        city: "",
        country: "",
        pincode: "",
        locality: "",
        email: "",
        website: "",
        panNo: null,
        gstNo: null,
        landline: null,
        constitutionid: null,
        constitutionname: "",
        currencyid: null,
        creditAmount: null,
        creditDays: null,
        googlemaplocation: "",
        about: "",
        locale: "",
        paymentTerm: "",
        gstType: "",
        gsttype: " ",
        chkwihitins: false,
        chkoutsides: false,
        address: "",
        productcatname: this.model.productcatname,
        productName: this.model.productName,
        defaultCurrency: this.model.defaultCurrency,
        localityID: null,
        mrp: this.model.mrp,
        transferprice: null,
        currencyName: this.model.currencyName,
        productcategoryid: this.model.productcategoryid,
        commission: this.model.commission,
        modellst: null,
        locmodlst: null,
        countryid: null,
        regionid: null,
        region: "",
        areaid: null,
        stateid: null,
        localityid: null,
        area: "",
        geolst: null,
        cityid: null,
        checkselectgeo: false,
        localeid: null,
        localmodlst: null,
        description: "",
        checkselectprocat: false,
        chkgst: false,
        vendor: null,
        userId: null,
        userid: null,
        contactlst1: null,
        contactlst: null,
        usergroup: "",
        usergroupid: null,
        serialnoFlag: false,
        chkselectlogin: false,
        dealersid: null,
        wigstflag: "",
        outgstflag: "",
        dealerproid: null,
        paymentType: null,
        proid: null,
        selectall: null,
        customertype: null,
        egst: null,
        checkselectegstn: false,
        locationlst: null,
        deliverylocid: null,
        localall: false,
        locallist:[]
      })
    }
    else if (this.model.selectall == true) {
      for (let i = 0; i < this.productlst.length; i++) {

        this.modellst.push({
          id: this.model.id,
          productid: this.productlst[i].pid,
          code: "",
          name: "",
          status: "",
          state: "",
          city: "",
          country: "",
          pincode: "",
          locality: "",
          email: "",
          website: "",
          panNo: null,
          gstNo: null,
          landline: null,
          constitutionid: null,
          constitutionname: "",
          currencyid: null,
          creditAmount: null,
          creditDays: null,
          googlemaplocation: "",
          about: "",
          locale: "",
          paymentTerm: "",
          gstType: "",
          gsttype: " ",
          chkwihitins: false,
          chkoutsides: false,
          address: "",
          productcatname: this.model.productcatname,
          productName: this.productlst[i].description,
          defaultCurrency: this.model.defaultCurrency,
          localityID: null,
          mrp: this.productlst[i].minimumPrice,
          transferprice: null,
          currencyName: this.model.currencyName,
          productcategoryid: this.model.productcategoryid,
          commission: this.model.commission,
          modellst: null,
          locmodlst: null,
          countryid: null,
          regionid: null,
          region: "",
          areaid: null,
          stateid: null,
          localityid: null,
          area: "",
          geolst: null,
          cityid: null,
          checkselectgeo: false,
          localeid: null,
          localmodlst: null,
          description: "",
          checkselectprocat: true,
          chkgst: false,
          vendor: null,
          userId: null,
          userid: null,
          contactlst1: null,
          contactlst: null,
          usergroup: "",
          usergroupid: null,
          serialnoFlag: false,
          chkselectlogin: false,
          dealersid: null,
          wigstflag: "",
          outgstflag: "",
          dealerproid: null,
          paymentType: null,
          proid: null,
          selectall: null,
          customertype: null,
          egst: null,
          checkselectegstn: false,
          locationlst: null,
          deliverylocid: null,
          localall: false,
          locallist:[]
        })
      }
    }

    this.model.transferprice = 0;
    for (let i = 0; i < this.modellst.length; i++) {
      let price = this.modellst[i].mrp * this.modellst[i].commission / 100;
      this.modellst[i].transferprice = (this.modellst[i].mrp) - price;
      this.modellst[i].transferprice = Number(this.modellst[i].transferprice.toFixed(2));
      // this.modellst[i].transferprice = Math.round(this.modellst[i].transferprice);
    }
  }

  onChangeOfcommission() {
    this.model.transferprice = 0;
    for (let i = 0; i < this.modellst.length; i++) {
      let price = this.modellst[i].mrp * this.modellst[i].commission / 100;
      this.modellst[i].transferprice = Number(((this.modellst[i].mrp) - price).toFixed(2));

    }
  }
  onChangeOftransferprice() {
    for (let i = 0; i < this.modellst.length; i++) {
      let price = this.modellst[i].mrp - this.modellst[i].transferprice
      this.modellst[i].commission = price * 100 / this.modellst[i].mrp;
      this.modellst[i].commission = Number(this.modellst[i].commission.toFixed(2));
    }
  }
  btndeletepro(dealerproid, index) {
    if (dealerproid != null) {
      this.i = confirm("Do you want to delete this record ?")
      if (this.i == true) {
        this.model.dealerproid = dealerproid
        this.dealersService.deletedealerpro(this.model)
          .subscribe(value => {
            this.notifyService.showSuccess(value.message, "Product Deleted Successfully !!");

            var id = document.getElementById("Products");
            id.click();
            this.model.id = this.id
            this.dealersService.getdealerproduct(this.model)
              .subscribe(
                data => {
                  this.modellst = data;
                });
          });

      }
    } else {
      this.modellst.splice(index, 1);
    }

  }

  btnsaveproduct() {

    var found = this.modellst.find(function (x) { return x.checkselectprocat === true; });
    if (!found) {
      this.notifyService.showRroor("Please select atleast one Checkbox", " Oops");
      return;
    }
    if (this.modellst == null || this.modellst.length <= 0) {
      this.notifyService.showRroor("Please Add List", "Oops!");
      return;
    }
    this.model.modellst = this.modellst

    this.dealersService.savedealerproduct(this.model)
      .subscribe(
        data => {
          if (data.status == "OK") {
            this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
            var id = document.getElementById("geography");
            id.click();
          } else {
            this.notifyService.showRroor(data.message, "Oops !")
          }
        },
        error => {
          alert("Something Wrong ")
        });
  }

  getAllCountries() {
    this.countrylst = []
    this.Masterservice.getAllCountries(this.user)
      .subscribe(value => {
        // this.countrylst.push({ id: 0, name: "Choose One" })
        value.forEach((item) => this.countrylst.push({ id: item.id, name: item.name }));
      });
  }
  onSelectCountry(value) {
    if (value == 0) {
      this.notifyService.showRroor("Please Select Country!", "Oops!");
      return;
    }
    this.modelg.countryid = value
    // this.getStatesByCountry(value)
  }

  getRegions() {
    this.regionlst = []
    this.Masterservice.getAllRegions(this.user)
      .subscribe(value => {
        this.regionlst = value

      });
  }
  onSelectRegions(regionid) {
    if (regionid == 0) {
      this.notifyService.showRroor("Select Region!!", "Oops!");
      return;
    }
    this.modelg.regionid = regionid

    this.user.trnid = regionid;
    this.modelg.regionid = regionid;
    this.transactionService.getallStateByregion(this.user)
      .subscribe(value => {
        this.statelst = value.stlst
      })
    // this.transactionService.getallAreaByregion(this.user)
    //   .subscribe(value => {
    //     this.arealst = value.arelst
    //   });
  }


  onSelectArea(value) {
    if (value == 0) {
      this.notifyService.showRroor("Select Area!!", "Oops!");
      return;
    }
    this.modelg.areaid = value
    this.user.trnid = value;

    this.transactionService.getallCityByarea(this.user)
      .subscribe(value => {
        this.citylst = value.ctlst
      })

  }
  getStatesByCountry(countryid) {
    this.statelst = []
    this.user.trnid = countryid
    this.Masterservice.getStatesByCountry(this.user)
      .subscribe(value => {
        // this.statelst.push({ id: 0, name: "Choose One" })
        value.forEach((item) => this.statelst.push({ id: item.id, name: item.name }));
      });
  }

  onSelectState(val) {
    this.modelg.stateid = val;
    this.getAllAreaByState(val);
  }
  getAllAreaByState(stateid) {
    this.user.trnid = stateid;
    this.Masterservice.getAllAreaByState(this.user)
      .subscribe(value => {
        this.arealst = value.arelst
      });
  }



  // getAllCitiesByState(stateid) {
  //   this.citylst = [];
  //   // this.citylst.push({ id: 0, name: "Choose One" });
  //   this.user.trnid = stateid;
  //   this.Masterservice.getAllCitiesByState(this.user)
  //     .subscribe(value => {
  //       value.forEach((item) => this.citylst.push({ id: item.id, name: item.name }));
  //     });
  // }

  onSelectCity(cityid) {
    this.modelg.cityid = cityid
    this.getAllLocalesByCity(cityid)
    
  }


  getAllLocalesByCity(cityid) {
    this.localelst = [];
   
    this.localelst.push({ id: null, name: "Select  Locale" });
    this.localelst.push({ id: 0, name: "Select All Locale" });
    this.user.trnid = cityid;
    this.Masterservice.getAllLocalesByCity(this.user)
      .subscribe(value => {
        value.forEach((item) => this.localelst.push({ id: item.id, name: item.name }));
      });
  }

  onSelectlocale(id) {
    this.modelg.localeid = id;
    

  }


  btngo() {
    if (this.model.id == null) {
      this.notifyService.showRroor("Please Enter Dealer Details !!", "Oops!");
      return;
    }

    if (this.modelg.countryid == null) {
      this.notifyService.showRroor("Please Select Country !!", "Oops!");
      return;
    }
    if (this.modelg.regionid == null) {
      this.notifyService.showRroor("Please Select Region !!", "Oops!");
      return;
    }
    if (this.modelg.stateid == null) {
      this.notifyService.showRroor("Please Select State !!", "Oops!");
      return;
    }
    if (this.modelg.areaid == null) {
      this.notifyService.showRroor("Please Select Area !!", "Oops!");
      return;
    }
    if (this.modelg.cityid == null) {
      this.notifyService.showRroor("Please Select City !!", "Oops!");
      return;
    }
    if (this.modelg.localeid == null) {
      this.notifyService.showRroor("Please Select Locale !!", "Oops!");
      return;
    }
    this.locmodlst1 = [];
    this.locmodlst = [];
    this.modelg.id = this.model.id;
    this.loading=true;
    this.dealersService.getalllstbyLocaleid(this.modelg)
      .subscribe(
        data => {
          this.locmodlst1 = data
          for (let i = 0; i < this.locmodlst1.length; i++) {
            this.locmodlst.push({
              id: this.locmodlst1[i].id,
              code: null,
              name: null,
              status: null,
              state: this.locmodlst1[i].state,
              city: this.locmodlst1[i].city,
              locale: this.locmodlst1[i].locale,
              country: this.locmodlst1[i].country,
              proid: null,
              pincode: null,
              locality: this.locmodlst1[i].locality,
              email: null,
              gsttype: null,
              chkwihitins: null,
              chkoutsides: null,
              website: null,
              panNo: null,
              gstNo: null,
              landline: null,
              constitutionid: null,
              constitutionname: null,
              currencyid: null,
              creditAmount: null,
              creditDays: null,
              googlemaplocation: null,
              about: null,
              paymentTerm: null,
              gstType: null,
              dealerproid: null,
              usergroup: null,
              usergroupid: null,
              paymentType: null,
              address: null,
              productcatname: null,
              chkgst: null,
              productName: null,
              defaultCurrency: null,
              localityID: null,
              mrp: null,
              productid: null,
              localeid: this.locmodlst1[i].localeid,
              transferprice: null,
              currencyName: null,
              productcategoryid: null,
              commission: null,
              checkselectprocat: null,
              geolst: null,
              locmodlst: null,
              modellst: null,
              contactlst: null,
              localityid: this.locmodlst1[i].localityid,
              countryid: this.locmodlst1[i].countryid,
              regionid: this.locmodlst1[i].regionid,
              region: this.locmodlst1[i].region,
              areaid: this.locmodlst1[i].areaid,
              stateid: this.locmodlst1[i].stateid,
              area: this.locmodlst1[i].area,
              cityid: this.locmodlst1[i].cityid,
              checkselectgeo: true,
              localmodlst: null,
              description: null,
              vendor: null,
              userId: null,
              userid: null,
              dealersid: this.locmodlst1[i].dealersid,
              wigstflag: null,
              outgstflag: null,
              serialnoFlag: null,
              chkselectlogin: null,
              contactlst1: null,
              selectall: null,
              customertype: null,
              egst: null,
              checkselectegstn: null,
              locationlst: null,
              deliverylocid: null,
              localall: false,
              locallist:[]
            });
          }
          this.loading=false;
        });
  }
  btnsavegeography() {
    if (this.locmodlst.length <= 0) {
      this.notifyService.showRroor("Please Add List!!", "Oops!");
      return;
    }


    var found = this.locmodlst.find(function (x) { return x.checkselectgeo === true; });
    if (!found) {
      this.notifyService.showRroor("Please select atleast one Checkbox", " Oops");
      return;
    }

    this.model.locmodlst = this.locmodlst;
    this.loading=true;
    this.dealersService.savedealergeography(this.model)
      .subscribe(
        data => {
          if (data.status == "OK") {
            this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
            var id = document.getElementById("contacts");
            id.click();
          } else {
            this.notifyService.showRroor(data.message, "Oops !")
          }
          this.loading=false;
        },
        error => {
          alert("Something Wrong ")
        });
  }
  btncanclegeo() {
    this.router.navigate(["Commercial"]);
  }

  getdesignation() {
    let user = new User();
    this.designationlst = [];
    this.Masterservice.getDept(user)
      .subscribe((value) => {
        this.designationlst = value.desiglst
      });
  }
  onSelectdesignation(value) {
    if (value == 0) {
      this.notifyService.showRroor("Select Designation !!", "Oops!");
      return;
    }
    for (let i = 0; i < this.designationlst.length; i++) {
      if (this.designationlst[i].id == value) {
        this.modelc.designationID = this.designationlst[i].id;
        this.modelc.designation = this.designationlst[i].name
      }
    }

  }
  getcountry() {
    let user = new User();
    this.cntylst1 = [];
    this.cntylst2 = [];
    this.dealersService.getcountry(user)
      .subscribe((value) => {
        this.cntylst1 = value.countrylst
        this.cntylst2 = value.countrylst

      });
  }
  onSelectcountry(value) {

    this.modelc.country1ID = value;
  }
  onSelectcountryy(value) {

    this.modelc.country2ID = value;
  }

  btnadd($event) {
    if (this.model.id == null) {
      this.notifyService.showRroor("Enter Dealer Details !!", "Oops!");
      return;
    }
    // for (let i = 0; i < this.contactlst.length; i++) {
    //   if (this.contactlst[i].contactName == this.modelc.contactName) {
    //     this.notifyService.showRroor("Contact already present", " Oops");
    //     return;
    //   }
    // }
    if (this.modelc.contactName == null) {
      this.notifyService.showRroor("Enter Contact Name  !!", "Oops!");
      return;
    }
    if (this.modelc.designation == null) {
      this.notifyService.showRroor("Select designation  !!", "Oops!");
      return;
    }
    if (this.modelc.country1ID == null) {
      this.notifyService.showRroor("Select Country  !!", "Oops!");
      return;
    }
    if (this.modelc.mobileNo1 == null) {
      this.notifyService.showRroor("Enter Mobile No.  !!", "Oops!");
      return;
    }
    if (this.modelc.email == null) {
      this.notifyService.showRroor("Enter Email-Id !!", "Oops!");
      return;
    }

    if (this.modelc.chkselectvalue) {
      for (let i = 0; i < this.contactlst.length; i++) {
        this.contactlst[i].chkselectvalue = false
      }
    }
    if (this.modelc.chkselectlogin) {
      for (let i = 0; i < this.contactlst.length; i++) {
        this.contactlst[i].chkselectlogin = false
      }
      if (this.modelc.username == null) {
        this.notifyService.showRroor("Enter Username Name  !!", "Oops!");
        return;
      }
      if (this.modelc.password == null) {
        if (this.modelc.pass == null || this.modelc.pass == "") {
          this.notifyService.showRroor("Enter Password Name  !!", "Oops!");
          return;
        } else {
          var encrypted = this.EncrDecr.set('UMJ3wfzn@kMpARd', this.modelc.pass);
          this.modelc.password = encrypted;
        }
      } else {
        var encrypted = this.EncrDecr.set('UMJ3wfzn@kMpARd', this.modelc.pass);
        this.modelc.password = encrypted;
      }
      if (this.modelc.usergroupid == null) {
        this.notifyService.showRroor("Please select User Group !!", "Oops!");
        return;
      }

    }


    if (this.index != null) {
      this.contactlst[this.index].contactName = this.modelc.contactName;
      this.contactlst[this.index].designation = this.modelc.designation;
      this.contactlst[this.index].designationID = this.modelc.designationID;
      this.contactlst[this.index].mobileNo1 = this.modelc.mobileNo1;
      this.contactlst[this.index].mobileNo2 = this.modelc.mobileNo2;
      this.contactlst[this.index].email = this.modelc.email;
      this.contactlst[this.index].chkselectvalue = this.modelc.chkselectvalue;
      this.contactlst[this.index].desiglst = this.modelc.desiglst;
      this.contactlst[this.index].country1ID = this.modelc.country1ID;
      this.contactlst[this.index].country2ID = this.modelc.country2ID;
      this.contactlst[this.index].chkselectlogin = this.modelc.chkselectlogin;
      this.contactlst[this.index].username = this.modelc.username;
      this.contactlst[this.index].password = this.modelc.password;
      this.contactlst[this.index].dealerid = this.modelc.dealerid;
      this.contactlst[this.index].usergroupid = this.modelc.usergroupid
      this.contactlst[this.index].usergroup = this.modelc.usergroup
      this.contactlst[this.index].contactlst = null;
    } else {
      this.contactlst.push({
        id: null,
        id1: null,
        contactName: this.modelc.contactName,
        designation: this.modelc.designation,
        designationID: this.modelc.designationID,
        mobileNo1: this.modelc.mobileNo1,
        mobileNo2: this.modelc.mobileNo2,
        email: this.modelc.email,
        chkselectvalue: this.modelc.chkselectvalue,
        desiglst: this.modelc.desiglst,
        countrylst: this.modelc.countrylst,
        country1ID: this.modelc.country1ID,
        country2ID: this.modelc.country2ID,
        dealerid: this.modelc.dealerid,
        contactlst: null,
        chkselectlogin: this.modelc.chkselectlogin,
        username: this.modelc.username,
        // password: this.EncrDecr.set('UMJ3wfzn@kMpARd', this.modelc.password),
        password: this.modelc.password,
        pass: this.modelc.pass,
        userid: this.modelc.userid,
        usergroupid: this.modelc.usergroupid,
        usergroup: this.modelc.usergroup
      })

    }
    this.index = null;
    this.modelc.contactName = null;
    this.modelc.designation = null;
    this.modelc.designationID = null;
    this.modelc.mobileNo1 = null;
    this.modelc.mobileNo2 = null;
    this.modelc.email = null;
    this.modelc.chkselectvalue = null;
    this.modelc.desiglst = null;
    this.modelc.countrylst = null;
    this.modelc.country1ID = null;
    this.modelc.country2ID = null;
    this.modelc.chkselectlogin = null;
    this.modelc.username = null;
    this.modelc.password = null;
    this.modelc.dealerid = null;
    this.modelc.id = null;
    this.modelc.usergroupid = null;
    this.modelc.usergroup = null;

  }
  btneditcon(lst, index) {
    this.index = index;
    this.modelc.contactName = lst.contactName;
    this.modelc.designation = lst.designation;
    this.modelc.designationID = lst.designationID;
    this.modelc.mobileNo1 = lst.mobileNo1;
    this.modelc.mobileNo2 = lst.mobileNo2;
    this.modelc.email = lst.email;
    this.modelc.chkselectvalue = lst.chkselectvalue;
    this.modelc.desiglst = lst.desiglst;
    this.modelc.countrylst = lst.countrylst;
    this.modelc.country1ID = lst.country1ID;
    this.modelc.country2ID = lst.country2ID;
    this.modelc.chkselectlogin = lst.chkselectlogin;
    this.modelc.username = lst.username;
    this.modelc.password = lst.password;
    this.modelc.dealerid = lst.dealerid;
    this.modelc.usergroupid = lst.usergroupid;
    this.modelc.usergroup = lst.usergroup;
    this.modelc.id = lst.id;

    let l1 = (<HTMLInputElement>document.getElementById('contactname'));
    l1.className = "active";
    let l2 = (<HTMLInputElement>document.getElementById('mobileNo1'));
    l2.className = "active";
    let l3 = (<HTMLInputElement>document.getElementById('mobileNo2'));
    l3.className = "active";
    let l4 = (<HTMLInputElement>document.getElementById('Email'));
    l4.className = "active";

  }
  btndeltscon(id, index) {
    if (id != null) {
      this.modelc.id = id
      // this.contactlst.splice(index, 1);
      this.modelc.dealerid = this.model.id;
      this.dealersService.deletedealercont(this.modelc)
        .subscribe(value => {
          if (value.status == "OK") {
            this.notifyService.showSuccess("Contact Deleted", " Contatct Deleted Successfully !!");
            var id = document.getElementById("Contacts");
            id.click();

            this.dealersService.getdealerid(this.model)
              .subscribe(
                data => {
                  this.contactlst = data.contactlst;
                },
                error => {
                  this.alertService.error(error);
                });

          } else {
            this.notifyService.showRroor("Not Deleted", "Opps")
          }
        });
    } else {
      this.contactlst.splice(index, 1);
    }
  }
  btnsavecon(event) {
    if (this.model.id == null) {
      this.notifyService.showRroor("Enter Dealer Details !!", "Oops!");
      return;
    }
    if (this.contactlst.length <= 0) {
      this.notifyService.showRroor("Please Add Contact Details!!", "Oops!");
      return;
    }

    // if (this.model.id == null) {
    // if (this.modelc.pass == null || this.modelc.pass == "") {
    //   this.notifyService.showRroor("Enter password  !!", "Oops!");
    //   return;
    // } else {
    //   var encrypted = this.EncrDecr.set('UMJ3wfzn@kMpARd', this.modelc.pass);
    //   this.modelc.password = encrypted;
    // }
    // }


    this.modelc.userid = Number(localStorage.getItem('userid'));
    this.modelc.dealerid = this.model.id;
    this.modelc.contactlst = this.contactlst;
    this.dealersService.savedealercont(this.modelc)
      .subscribe(
        data => {
          if (data.status == "OK") {
            this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
            this.modelc.id = Number(data.message);

            var id = document.getElementById("logistics");
            id.click();
          } else {
            this.notifyService.showRroor(data.message, "Oops !")
          }
        },
        error => {
          alert("Something Wrong ")
        });
  }
  btncanclecon() {
    this.router.navigate(["Commercial"]);
  }


  openlocationmodel(locationmodel: TemplateRef<any>) {
    if (this.model.id == null) {
      this.notifyService.showRroor("Please Enter Dealer Details !!", "Oops!");
      return;
    }
    this.index = null;
    this.modell.shortname = null;
    this.modell.chkselectvalue = null;
    this.modell.address = this.model.address;
    this.modell.landmark = null;
    this.modell.pincode = this.model.pincode;
    this.modell.locality = this.model.locality;
    this.modell.locale = this.model.locale;
    this.modell.city = this.model.city;
    this.modell.state = this.model.state;
    this.modell.country = this.model.country;
    this.modell.maplocation = this.model.googlemaplocation;
    this.modell.mobile = null;
    this.modell.phone = null;
    this.modell.email = this.model.email;
    this.modell.localityID = this.model.localityID;
    this.modell.lstaddress = null;
    // this.lstlocality = null;
    this.modalRef = this.modalService.show(locationmodel, {
      class: 'modal-lg'
    });
  }
  getPincodeLikes($event) {
    let userId = (<HTMLInputElement>document.getElementById('pincode')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getpincodelike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectg(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadOnSelectg(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
    if (this.modell.pincode != null) {
      if (this.modell.pincode.length < 6 || this.modell.pincode.length > 6) {
        this.lstlocality = [];
      }
    }

  }

  typeaheadOnSelectg(id, name) {
    this.model1.pincode = name;
    this.modell.pincode = name;
    if (this.model1.pincode.length == 6) {
      this.lstlocality = [];
      this.lstlocality.push({ id: 0, name: "Select Locality" });
      this.transactionService.getlocality(this.model1)
        .subscribe(data => {
          data.forEach(item => {
            this.lstlocality.push({ id: item.id, name: item.name });
            this.localitymap.set(item.id, item.name)
          });
        });
    }

  }

  onSelectlocality1(id, name) {
    // alert(this.modell.pincode)
    this.model1.localityID = id;
    this.model1.locality = this.localitymap.get(id);
    this.modell.localityID = id;
    this.modell.locality = this.localitymap.get(id);


    this.transactionService.getgeographydetails(this.model1)
      .subscribe(data => {
        this.modell.city = data.city;
        this.modell.state = data.state;
        this.modell.country = data.country;
        this.modell.locale = data.locale;
      });

  }
  btnsave($event) {
    this.hiddenflag = true;

    if (this.modell.shortname == null) {
      this.notifyService.showRroor("Enter Short Name !!", "Oops!");
      this.hiddenflag = false;
      return;
    }
    if (this.modell.address == null) {
      this.notifyService.showRroor("Enter Address !!", "Oops!");
      this.hiddenflag = false;
      return;
    }
    if (this.modell.landmark == null) {
      this.notifyService.showRroor("Enter Landmark !!", "Oops!");
      this.hiddenflag = false;
      return;
    }
    if (this.modell.pincode == null) {
      this.notifyService.showRroor("Enter Pincode !!", "Oops!");
      this.hiddenflag = false;
      return;
    }
    if (this.modell.localityID == null || this.modell.localityID == 0) {
      this.notifyService.showRroor("Select Locality!!", "Oops!");
      this.hiddenflag = false;
      return;
    }
    // if (this.modell.maplocation == null) {
    //   this.notifyService.showRroor("Enter Google map location !!", "Oops!");
    //   this.hiddenflag = false;
    //   return;
    // }
    if (this.modell.mobile == null) {
      this.notifyService.showRroor("Enter Mobile No !!", "Oops!");
      this.hiddenflag = false;
      return;
    }
    // if (this.modell.phone == null) {
    //   this.notifyService.showRroor("Enter Landline No !!", "Oops!");
    //   this.hiddenflag = false;
    //   return;
    // }
    if (this.modell.email == null) {
      this.notifyService.showRroor("Enter Email-Id !!", "Oops!");
      this.hiddenflag = false;
      return;
    }
    this.hiddenflag = true;
    this.modell.dealerid = this.model.id;

    // if (this.modell.chkselectvalue == true) {
    //   this.lstaddress.forEach(item => {
    //     for (let i = 0; i < this.lstaddress.length; i++) {
    //       if (this.lstaddress[i].chkselectvalue == true) {
    //         this.lstaddress[i].chkselectvalue = false;
    //       }
    //     }
    //   });
    // }
    this.dealersService.savedealerlogistic(this.modell)
      .subscribe(
        data => {
          if (data.status == "OK") {
            // this.ngOnInit()
            this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
            var id = document.getElementById("Logistics");
            id.click();
            let flag = true;
            let index = 0;
            for (let i = 0; i < this.lstaddress.length; i++) {
              if (Number(data.message) == this.lstaddress[i].id) {
                flag = false;
                index = i
              }
            }
            // this.lstaddress.forEach(element => {
            //  
            // });
            if (flag) {
              this.lstaddress.push({
                shortname: this.modell.shortname,
                address: this.modell.address,
                chkselectvalue: this.modell.chkselectvalue,
                landmark: this.modell.landmark,
                pincode: this.modell.pincode,
                locality: this.modell.locality,
                locale: this.modell.locale,
                city: this.modell.city,
                state: this.modell.state,
                country: this.modell.country,
                maplocation: this.modell.maplocation,
                mobile: this.modell.mobile,
                phone: this.modell.phone,
                email: this.modell.email,
                localityID: this.modell.localityID,
                dealerid: this.modell.dealerid,
                id: Number(data.message),
                lstaddress: this.modell.lstaddress,
                pincode1: this.modell.pincode1,
              });
            } else {
              this.lstaddress[index].shortname = this.modell.shortname,
                this.lstaddress[index].address = this.modell.address,
                this.lstaddress[index].chkselectvalue = this.modell.chkselectvalue,
                this.lstaddress[index].landmark = this.modell.landmark,
                this.lstaddress[index].pincode = this.modell.pincode,
                this.lstaddress[index].locality = this.modell.locality,
                this.lstaddress[index].locale = this.modell.locale,
                this.lstaddress[index].city = this.modell.city,
                this.lstaddress[index].state = this.modell.state,
                this.lstaddress[index].country = this.modell.country,
                this.lstaddress[index].maplocation = this.modell.maplocation,
                this.lstaddress[index].mobile = this.modell.mobile,
                this.lstaddress[index].phone = this.modell.phone,
                this.lstaddress[index].email = this.modell.email,
                this.lstaddress[index].localityID = this.modell.localityID,
                this.lstaddress[index].dealerid = this.modell.dealerid,

                this.lstaddress[index].lstaddress = this.modell.lstaddress,
                this.lstaddress[index].pincode1 = this.modell.pincode1;

            }
            this.modalRef.hide()

            this.hiddenflag = false;

          } else {
            this.notifyService.showRroor(data.message, "Oops !")
          }
        },
        error => {
          alert("Something Wrong ")
        });
  }
  btnedit(lst, locationmodel: TemplateRef<any>) {

    this.modell.shortname = lst.shortname;
    this.modell.chkselectvalue = lst.chkselectvalue;
    this.modell.address = lst.address;
    this.modell.landmark = lst.landmark;
    this.modell.pincode = lst.pincode;
    this.modell.locality = lst.locality;
    this.modell.locale = lst.locale;
    this.modell.city = lst.city;
    this.modell.state = lst.state;
    this.modell.country = lst.country;
    this.modell.maplocation = lst.maplocation;
    this.modell.mobile = lst.mobile;
    this.modell.phone = lst.phone;
    this.modell.email = lst.email;
    this.modell.localityID = lst.localityID;
    this.modell.dealerid = lst.dealerid;
    this.modell.id = lst.id;
    this.modalRef = this.modalService.show(locationmodel, {
      class: 'modal-lg'
    });

  }

  btndelts(id, index) {
    this.i = confirm("Do you want to delete this record ?")
    if (this.i == true) {
      this.modell.id = id

      this.dealersService.deleteshippingadd(this.modell)
        .subscribe(value => {
          // for(let i = 0; i<this.lstaddress.length; i++)
          // {
          // if(this.lstaddress[i].id && this.lstaddress[i].chkselectvalue==true)
          // {
          //   this.notifyService.showRroor("Default address can't be deleted !!", "Oops!");
          //   return;
          // }
          // }
          if (value.status == "OK") {
            this.notifyService.showSuccess("Shipping Address deleted", " Success");
            var id = document.getElementById("Logistics");
            id.click();
            this.lstaddress.splice(index, 1);
          }

        });

    }
  }

  //For Autocomplete
  selectEvent(item) {
    this.model1.pincode = item.name;
    this.lstlocality = [];
    this.lstlocality.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.model1)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocality.push({ id: item.id, name: item.name });
        });
        this.model1.pincode = item.id;
        this.model1.pincode = item.name;
      });

  }

  onChangeSearch(search: string) {

    this.user.message = search;
    this.transactionService.getpincodelike(this.user)
      .subscribe(data => {
        this.pincodelist = [];
        data.forEach((item) => this.pincodelist.push({ "id": item.id, "name": item.name }));

      });

  }

  onFocused(e) {
    // do something
  }
  selectEvent1(item) {
    this.model1.pincode = item.name;
    this.lstlocality = [];
    this.lstlocality.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.model1)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocality.push({ id: item.id, name: item.name });
        });
        this.modell.pincode = item.id;
        this.modell.pincode = item.name;
      });
  }

  onChangeSearch1(search: string) {

    this.user.message = search;
    this.transactionService.getpincodelike(this.user)
      .subscribe(data => {
        this.pincodelist1 = [];
        data.forEach((item) => this.pincodelist1.push({ "id": item.id, "name": item.name }));

      });

  }

  onFocused1(e) {
    // do something
  }






  onChangeSearchproduct(searchproduct: string) {
    this.user.message = searchproduct;
    this.user.productcategoryId = this.model.productcategoryid;

    this.dealersService.getProductLike(this.user)
      .subscribe(data => {
        this.userList2 = [];
        data.forEach((item) => this.userList2.push({ "productcatname": item.productcatname, "productName": item.productName, "productid": item.productid, "currencyName": item.currencyName, "mrp": item.mrp }));

      });

  }
  selectEventfordealer(item) {
    this.model.productid = item.productid;
    this.dealersService.getProductLike(this.user)
      .subscribe(data => {
        this.userList2 = [];
        data.forEach((item) => this.userList2.push({ "productcatname": item.productcatname, "productName": item.productName, "productid": item.productid, "currencyName": item.currencyName, "mrp": item.mrp }));
        this.model.productName = item.productName;
      });

    this.model.mrp = item.mrp;
    this.model.currencyName = item.currencyName;
  }
  onFocusedforSearchproduct(e) {
    // do something
  }
  selectallproduct($event) {
    if ($event.target.checked) {
      this.model.selectall = true;
    }
  }
  getProductsByPC(pcid) {
    this.productlst = [];
    this.user.trnid = pcid;
    this.transactionService.getProductsByPC(this.user)
      .subscribe(
        data => {
          this.productlst = data
        });
  }
  onChangeOfCreditlimit(val) {
    this.model.creditAmount = val;
    if (this.model.creditAmount == 0) {
      this.model.creditDays = 0;
    }
  }
  getAllProduct() {
    this.modellst = [];
    this.dealersService.getProductsByPCategory(this.model)
      .subscribe(
        data => {
          this.modellst = data
          // for(let i = 0; i < this.modellst.length ; i++)
          // {
          //   this.modellst[i].commission = Math.round(this.modellst[i].commission)
          // }
        });
  }
  btncancle() {
    this.router.navigate(["Dealer Details"]);
  }
  getdeliveryloc() {
    this.locationlst = [];
    this.dealersService.getdeliveryloc(this.model)
      .subscribe(
        data => {
          this.locationlst = data
        });
  }
  onSelectDelivery(value) {
    this.model.deliverylocid = value
  }
  localall1($event){
  if ($event.target.checked){
    this.model.localall==true
    this.model.cityid=this.modelg.cityid ;
  }else{
    this.model.localall==false
  }
  }
}