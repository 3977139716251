export class QuotationReportModel{
    
    todate:Date;
    frmdate:Date;
    lstt: QuotationReportModel[] = [];

    quotationId:number;
	accountName:string;
	quotationCode:string;
    accoutid: number;
    cityintlst: number[];
    cityid :number;
    

}