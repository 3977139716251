<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="l12 m12 s12">
                    <!-- <div class="col s3 l3 m3">
                    </div> -->
                    <div class="input-field col s3 l3 m3" style="text-align: left; margin-top: 5px;padding-left: 14px;">
                        <span>Search Criteria</span>
                        <select (change)=" onSelectAllowance($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center" style="margin-top: 22px;">
                        <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Text">
                        <label for="search">Search Text</label>
                    </div>
                    <!-- <div class="col s2 l2 m2">
                    </div> -->
                    <div class=" input-field col s3 l3 m3" style="margin-top: 22px">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                            (click)="btnnew()">New</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="40%" style="text-align: left;padding-left: 10px;">Code</th>
                            <th width="40%" style="text-align: left">Name</th>
                            <th width="20%" style="text-align: left">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of allowlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align:left;padding-left: 10px;">{{lst.code}}</td>
                                <td style="text-align: left;">{{lst.name}}</td>
                                <td style="text-align: left">
                                    <span><img src="../../../assets/images/edit.png" (click)="btnedit(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Edit"
                                            width="20px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Delete"
                                            width="20px" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>