import { ResourceCType } from 'src/app/Master/ResourceRights/ProductResourceRightsModel';
import { BooleanLiteral } from 'typescript';

export class TeamModel {
    id: number;
    srno: number;
    txtserch: string;
    txtselect: string;
    accesstype: string
    teamcode: string;
    region: string;
    branch: string;
    userid: number;
    acesstype: string;
    regionid: number;
    branchid: number;
    areaid: number;
    arealst: AreaModel1[] = [];
    procatlst: ProductCategory1[] = [];
    resoureslst: ResourceModel1[] = [];
    customertyps: ResourceCType[] = [];
    customertype:number;
    areaInt: number[] = [];
    pcInt: number[] = [];
    typeInt: number[] = []

    chkSale: boolean;
    chkService: boolean;
    chkInstallation: boolean;
    chkOrderAccept:boolean;
    cityid: number;
    localpincodelst: LocalePincodeModel[] = [];
    localeid:number;
    savedlocale: number[] = [];
    unsavedlocale: number[] = [];
    pincode:string;
    customertypeid:number;
    forflag:string;
    savedlocale1: object[] = [];
    unsavedlocale1: object[] = [];
    type:string;
    allcheckselect:boolean;
    tolocationid:number;
    tolocationname:string;
    locationname:string;
    locationid:number;
    invIntegration:boolean;
    itemlst:TeamModel[] = [];
    name:string;
  chkseledel: boolean;
    // chkseledel:boolean;


}
export class AreaModel1 {
    chkselectarea: boolean;
    areaid: number;
    areaname: string;
    citylst: CityModel[] = [];
}
export class CityModel1 {
    chkselectarea: boolean;
    cityid: number;
    cityname: string;
    // citylst: CityModel[] = [];
}
export class ProductCategory1 {
    chkselectpc: boolean;
    pcid: number;
    pcname: string;
}
export class ResourceModel1 {
    resourceID: number;
    name: string;
    chkselectres: boolean;
    hierarchy: number;
    callEscalationDays: number;
    approval: boolean;
    discount: number;
    chkassignto: boolean;
}
export class CityModel {
    cityid: number;
    cityname: string;
}
export class LocalePincodeModel {
    pincode: number;
    localeid: number;
    localename: string;
    chkselectlocale: boolean;
    localpincodelst: LocalePincodeModel[] = [];
}