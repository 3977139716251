import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, ProductionService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { Pipe } from '@angular/core';
import { DefineServiceChargesModel } from 'src/app/Master/ServiceChergesMaster/DefineServiceChargesModel';
import { ConversionTemplateModel } from './ConversionTemplateModel';
declare var $: any;

@Component({
    selector: 'app-SearchconversionTemplate',
    templateUrl: 'SearchconversionTemplate.html',
})
export class SearchconversionTemplate implements OnInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private ProductionService: ProductionService) {
    }
    model: any = {};
    p :any;
    user = new User();
    lstconversion:ConversionTemplateModel[]=[];
    lstconversion1:ConversionTemplateModel[]=[];
    txtserch:string;
    txtselect:string;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Short Name" },
        { id: 2, "name": "Formula Name" },
    ];
    ngOnInit(){
        this.ProductionService.getallconversiontemplate(this.user)
            .subscribe((value) => {
                this.lstconversion=value;
                this.lstconversion1=value;
            });
    }

    btnedit(id){
        let navigationExtras: NavigationExtras = {
            queryParams: {
              "id": id,
            }
          };
          this.router.navigate(['/Conversion Template'], navigationExtras);
    }
    btndelts(id){
        this.user.trnid=id;
        this.ProductionService.deleteallconversiontempleatebyid(this.user)
        .subscribe((value) => {
          if(value.status=="OK"){
              this.notifyService.showSuccess("Deleted Successfuly","success");
              this.ngOnInit();
          }

        });
    }
    btnsearch(){
        alert(this.txtselect)
        if (this.txtselect == "All") {
            this.ngOnInit()
            this.txtserch = null;
        }
        else if (this.txtselect == "Formula Name") {
            if (this.txtserch == null || this.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.lstconversion1 = [];
                this.lstconversion
                .forEach((item) => {
                    if ((item.formulaname.toLowerCase()).match(this.txtserch.toLowerCase().trim())) {
                        this.lstconversion1.push({
                            id:item.id,
                            srno:item.srno,
                            shortname:item.shortname,
                            formulaname:item.formulaname,
                            itemcategoryid:item.itemcategoryid,
                            itemsubcategoryid:item.itemsubcategoryid,
                            itemname:item.itemname,
                            itemid:item.itemid,
                            quantity:item.quantity,
                            uom:item.uom,
                            uomid:item.uomid,
                            dimensions:item.dimensions,
                            omitemcategoryid:item.omitemcategoryid,
                            omitemsubcategoryid:item.omitemsubcategoryid,
                            omitemname:item.omitemname,
                            omitemid:item.omitemid,
                            omquantity:item.omquantity,
                            omuom:item.omuom,
                            omuomid:item.omuomid,
                            omdimensions:item.omdimensions,
                            diamensionUnit:item.diamensionUnit,
                            lstoutputmeteriallst:null,
                            stock:null,
                            balance:null,
                            ombalance:null,
                            omstock:null,
                            quantityconversion:null,
                            itemreiredquantity:null,
                            usedquantity:null,
                            omusedquantity:null,
                            omitemcode:null


                        });
                    }
                });
                this.lstconversion=this.lstconversion1;
            }
        }
        else if (this.txtselect == "Short Name") {
            if (this.txtserch == null || this.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.lstconversion1 = [];
                this.lstconversion.forEach((item) => {
                    if ((item.shortname.toLowerCase()).match(this.txtserch.toLowerCase().trim())) {
                        this.lstconversion1.push({
                            id:item.id,
                            srno:item.srno,
                            shortname:item.shortname,
                            formulaname:item.formulaname,
                            itemcategoryid:item.itemcategoryid,
                            itemsubcategoryid:item.itemsubcategoryid,
                            itemname:item.itemname,
                            itemid:item.itemid,
                            quantity:item.quantity,
                            uom:item.uom,
                            uomid:item.uomid,
                            dimensions:item.dimensions,
                        
                            omitemcategoryid:item.omitemcategoryid,
                            omitemsubcategoryid:item.omitemsubcategoryid,
                            omitemname:item.omitemname,
                            omitemid:item.omitemid,
                            omquantity:item.omquantity,
                            omuom:item.omuom,
                            omuomid:item.omuomid,
                            omdimensions:item.omdimensions,
                            diamensionUnit:item.diamensionUnit,
                            lstoutputmeteriallst:null,
                            stock:null,
                            balance:null,
                            ombalance:null,
                            omstock:null,
                            quantityconversion:null,
                            itemreiredquantity:null,
                            usedquantity:null,
                            omusedquantity:null,
                            omitemcode:null
                        });
                    }
                });
                this.lstconversion=this.lstconversion1;
            }
        }
    }

    btnadd(){
        this.router.navigate(['/Conversion Template']);
    }
    onSelectItem(value){
      this.txtselect=value;
      if (this.txtselect == "All") {
        this.ngOnInit()
        this.txtserch = null;
    }
    }
}