<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Referencer's Report</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class="col l3 m3 s3" style="padding: 0px;">
                    <div class="col l6 m2 s2" style="padding-top: 9px;">
                        <span>From Date</span>
                        <input type="text" placeholder="From Date" [(ngModel)]="model.frmdate" class="form-control"
                            bsDatepicker (ngModelChange)="selectFromDate(model.frmdate)"
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true,showWeekNumbers: false }">
                    </div>

                    <div class="col l6 m2 s2" style="padding-top: 9px;">
                        <span>To Date</span>
                        <input type="text" placeholder="To Date" [(ngModel)]="model.todate" class="form-control"
                            bsDatepicker (ngModelChange)="selectToDate(model.todate)"
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true,showWeekNumbers: false}">
                    </div>
                </div>
                <!-- <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 5px;padding-top: 9px;">
                    <span>Referencer's Name</span>
                    <select (change)="onSelectReference($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Resource">
                        <option selected value="0">Select Resource</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of referencerslst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div> -->
                <div class="input-field col s2 m2 l2" style="margin-bottom: 0px;padding-left: 9px;margin-top: 9px">
                    <span>Source</span>
                    <select (change)=" onSelectsource($event.target.value)">
                        <option selected value="0">Select Source</option>
                        <option *ngFor="let type of lstsource1" [value]="type.id" [selected]="type.id==model.sourceID">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s2 m2 l2" style="margin-bottom: 0px;margin-top: 9px">
                    <span>Source Details</span>
                    <select (change)=" onSelectsourcedetails($event.target.value)">
                        <option *ngFor="let type of lstsourcedetails" [value]="type.id"
                            [selected]="type.id==model.sourcedetailID">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s2 l2 m2" style="margin-top: 0px;margin-bottom: 5px;padding-top: 9px;">
                    <span>Status</span>
                    <select (change)=" onSelectStatus($event.target.value)">
                        <option selected value="0">Select Status</option>
                        <option *ngFor="let type of searchtype" [value]="type.name" title="Status">
                            {{type.name}}</option>
                    </select>
                </div>

            </div>

            <div class="row col l12 m12 s12" style="padding:0px;">

                <div class="input-field col l12 m12 s12"
                    style="text-align: center;margin-top: 17px;margin-bottom: 5px;">
                    <button (click)="btnshow(ReferenceReportModel)" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Show</button>
                </div>
            </div>
        </div>


        <ng-template #ReferenceReportModel>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="col l12 m12 s12 registerBgShadow" style="padding-left: 18px;padding-right: 18px;">
                <div class="row" id="LibDocs">
                    <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                        <div class="modal-content " style="text-align:center;padding: 0px;margin-bottom: 10px;">

                            <div class="row" style="margin: 0px;">
                                <div class="col l12 m12 s12">
                                    <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>

                                </div>
                            </div>
                            <div class="row" style="margin: 0px;">
                                <div class="col l12 m12 s12">
                                    <span style="font-size: 16px">{{companyaddress}}</span>
                                </div>
                            </div>
                        </div>
                        <div style="text-align: center;">
                            <span style="font-size: 20px;text-decoration: underline">Referencer's Report</span>
                        </div>

                        <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                            <div class="col l12 m12 s12" style="padding-right: 0px;">
                                <div class="input-field col l12 m12 s12"
                                    style="text-align: end;padding-right: 0px;margin-top: 0px;margin-bottom: 0px;">
                                    <span><img src="../../../assets/images/printer.png" (click)="openPrint()"
                                            data-toggle="tooltip" data-placement="top" title="Print"
                                            width="30px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/excel2.png" onclick="tableToExcel('excel')"
                                            data-toggle="tooltip" data-placement="top" title="Excel"
                                            width="30px" /></span>
                                </div>
                            </div>
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7">
                                <tbody>
                                    <tr>
                                        <td width="30%" style="text-align: left;border: 1px solid black;">
                                            <span>Status:-</span>
                                            &nbsp;<span><b>{{model.status}}</b></span></td>
                                        <td width="20%" style="text-align: left;border: 1px solid black;"><span>From
                                                Date:-</span>
                                            &nbsp;<span><b>{{model.frmdate |
                                                    date:"dd-MM-yyyy"}}</b></span></td>
                                        <td width="20%" style="text-align: left;border: 1px solid black;"><span>To
                                                Date:-</span>&nbsp;<span><b>{{model.todate | date:"dd-MM-yyyy"}}</b></span></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div class="col l12 m12 s12" style="padding: 0px;">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                                id="excel">
                                <thead style="background: gainsboro;">
                                    <th width="7%" style="text-align: left;padding-left: 10px;">Sr. #</th>
                                    <th width="10%" style="text-align: left">Date</th>
                                    <th width="20%" style="text-align: left">Enquiry #</th>
                                    <th width="20%" style="text-align: left">Customer Name</th>
                                    <th width="20%" style="text-align: left">Referencer Name</th>
                                    <th width="10%" style="text-align: left">Status</th>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of reflst ">
                                        <td style="text-align: left">{{lst.srno}}</td>
                                        <td style="text-align: left">{{lst.date | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: left;">{{lst.opportunityName}}</td>
                                        <td style="text-align: left">{{lst.customerName}}</td>
                                        <td style="text-align: left">{{lst.owner}}</td>
                                        <td style="text-align: left">{{lst.status}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col s12 m12 l12" style="text-align: center;margin-top: 12px;">
                            <span style="font-size: 11px;">Report Genrated By: {{username}} on {{timing
                                |date:'medium'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="padding-right: 0px;">
                        <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)="modalRef.hide()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" class="waves-effect waves-light btn"
                                (click)="openPrint()">Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>