import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DefinePurchaseOrderModel } from "../InventoryManagement/PurchaseOrder/DefinePurchaseOrderModel";
import { ConversionTemplateModel } from "../Transactions-Production/Conversion Template/ConversionTemplateModel";
import { CreatePlanRequisitionModel } from "../Transactions-Production/CreatePlanRequisition/CreatePlanRequisitionModel";
import { DailyProductionPlan } from "../Transactions-Production/Daily Production Plan/DailyProductionPlan";
import { DailyProductionPlanModel } from "../Transactions-Production/Daily Production Plan/DailyProductionPlanModel";
import { PlanItemConversionModel } from "../Transactions-Production/Daily Production Plan/PlanItemConversionModel";
import { DefineProductionBookingModel } from "../Transactions-Production/Production Booking/DefineProductionBookingModel";
import { User } from "../_models";
import { SaveSuccessModel } from "../_models/SaveSucessModel";
import { common } from "./Common";
import { HandleError, HttpErrorHandler } from "./http-error-handler.service";

@Injectable()
export class ProductionService {


  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  //private headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
  }
  saveconversiontemplate(model: ConversionTemplateModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/saveconversiontemplate', model, httpOptions1);
  }
  getallconversiontemplate(model: User): Observable<ConversionTemplateModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ConversionTemplateModel[]>(this.customersUrl + 'api/production/getallconversiontemplate', model, httpOptions1);
  }

  
  getconversiondetails(model: User): Observable<ConversionTemplateModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<ConversionTemplateModel>(this.customersUrl + 'api/production/getconversiondetails', model, httpOptions1);
  }
  deleteconversiontemplateitem(model: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/deleteconversiontemplateitem', model, httpOptions1);
  }
  
  deleteallconversiontempleatebyid(model: User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/deleteallconversiontempleatebyid', model, httpOptions1);
  }
  SaveDailyProductionplan(model: DailyProductionPlanModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/SaveDailyProductionplan', model, httpOptions1);
  }
  getAllDailyplan(user:User): Observable<DailyProductionPlanModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DailyProductionPlanModel[]>(this.customersUrl + 'api/production/GetAllDailyProductionplan',user, httpOptions1);
  }
  getProductiondetails(model:DailyProductionPlanModel): Observable<DailyProductionPlanModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DailyProductionPlanModel>(this.customersUrl + 'api/production/getProductiondetails',model, httpOptions1);
  }
  
  deleteitemformplan(model:User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/deleteitemformplan',model, httpOptions1);
  }
  getConversionplandetails(model:PlanItemConversionModel): Observable<PlanItemConversionModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PlanItemConversionModel>(this.customersUrl + 'api/production/GetPlanconversiondetails',model, httpOptions1);
  }
  saveplanconversion(model:PlanItemConversionModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/saveplanconversion',model, httpOptions1);
  }
  getAllPlanreuisition(model:CreatePlanRequisitionModel): Observable<CreatePlanRequisitionModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CreatePlanRequisitionModel>(this.customersUrl + 'api/production/getAllPlanreuisition',model, httpOptions1);
  }
  SavePlanpreRequisition(model:CreatePlanRequisitionModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/SavePlanpreRequisition',model, httpOptions1);
  }
  SaveFinalizepreRequisition(model:CreatePlanRequisitionModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/SaveFinalizepreRequisition',model, httpOptions1);
  }
  

  getAllConversionForProductionBooking(model:DefineProductionBookingModel): Observable<DefineProductionBookingModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<DefineProductionBookingModel>(this.customersUrl + 'api/production/getAllConversionForProductionBooking',model, httpOptions1);
  }
  SaveproductionBooking(model:DefineProductionBookingModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/SaveproductionBooking',model, httpOptions1);
  }
  btnsaveFinishproduct(model:DefineProductionBookingModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/btnsaveFinishproduct',model, httpOptions1);
  }

  
  btnsavestockTransfer(model:DefineProductionBookingModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/btnsavestockTransfer',model, httpOptions1);
  }

  deleteconversionFormula(model:User): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/production/deleteconversionFormula',model, httpOptions1);
  }
  
} 


