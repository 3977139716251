import { QuotationModel, CurrencyModel, QuotationProductModel, TermsAndConditionsModel, QuotationHistory, QuotReportModel } from './QuotationModel';
import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, AuthenticationService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ReportServices } from 'src/app/_services/ReportServices';
import { SchemeConfigurateModel } from '../Scheme_Configurator/SchemeConfigurateModel';
import { DomSanitizer } from '@angular/platform-browser';
var converter = require('number-to-words');
import * as moment from 'moment';
import convertor from 'rupees-to-words';
import { productmodel } from '../Sales-Transaction/ProspectModel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';
@Component({
    selector: 'app-Quotation',
    templateUrl: 'Quotation.html',
})
export class Quotation implements OnInit {
    constructor(private Masterservice: MasterServices,
        private sanitizer: DomSanitizer,
        private reportServices: ReportServices,
        private modalService: BsModalService,
        private CompanyService: CompanyService,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private transactionservice: TransactionServices,
        private deviceService: DeviceDetectorService,
        private authenticationService: AuthenticationService) {
    }
    model = new QuotationModel();
    flagmodule = new CoustomerflagModel();
    quoReport = new QuotReportModel();
    qutReportlst: QuotReportModel[];
    reporttandclst: QuotReportModel[];
    user = new User();
    id: number;
    enquiryId: number;
    modalRef: BsModalRef;
    saveButton: boolean;
    productlst: Products[] = []
    procatlst: Products[] = [];
    productEnquirylst: QuotationProductModel[];
    contactlst: Contacts[] = [];
    currencylst: CurrencyModel[] = []
    procurrencylst: CurrencyModel[] = []
    tandclst: TermsAndConditionsModel[] = [];
    changetandclt: TermsAndConditionsModel[] = [];
    lstproductintersted: productmodel[] = [];
    currcymodel = new CurrencyModel();
    procatid: number
    procatname: string;
    proid: number;
    proname: number;
    currencyid: number;
    currencyName: string;
    discountAllowed: number;
    taxamount: number;
    taxpercent: number;
    offereddiscount: number;
    discount: number;
    discountAllowedNew: number;
    totalnewamt: number;
    schemeModel = new SchemeConfigurateModel();
    currentDate = new Date();
    taxdetails: boolean = false;
    paymenttermslst: DropDownModel[] = [
        { id: 1, "name": "Against Order" },
        { id: 2, "name": "Against Invoice" },
        { id: 3, "name": "Against Installation" }
    ]
    action: string;
    reasonslst: DropDownModel[] = [];
    totalamount: number;
    totalproamount: number;
    totalproamountnew: number;
    uom: string;
    minprice: number;
    public i;
    approvedQuo: boolean = true;
    quohistorylst: QuotationHistory[] = [];
    quoVersionHistory: QuotationHistory[] = [];
    amountInWords: string;
    nextstageid: number;
    activityid: number;
    connection: string;
    codehide: boolean = false;
    instaName: string;
    changeflag: boolean = false;

    decisionlst: DropDownModel[] = [
        { id: 1, "name": "Won" },
        { id: 2, "name": "Lost" },
        { id: 3, "name": "Abandoned" }
    ]
    fixedDecision: string;
    deviceInfo = null;
    isMobile = null;
    mainmenus: Mainmenumodel[] = [];
    username: string;
    showOtp: boolean = false;
    showBtn: boolean = false;

    instNamelst: DropDownModel[] = [
        { id: 1, "name": "Easy Dry Systems" },
    ]

    ngOnInit(): void {
        this.mainmenus = JSON.parse(localStorage.getItem('menu'));
        this.username = localStorage.getItem('fullname');
        console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        // const isTablet = this.deviceService.isTablet();
        // const isDesktopDevice = this.deviceService.isDesktop();
        // console.log(this.deviceInfo);
        // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        // console.log(isDesktopDevice);

        this.quoVersionHistory = []
        this.quohistorylst = []
        this.saveButton = true;
        this.approvedQuo = true
        this.model.chkscheduleInstal = false
        this.user.userid = Number(localStorage.getItem("userid"));
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getflag();
        this.getLossReasons()
        this.route.queryParams.subscribe(params => {
            this.enquiryId = + params["enqid"];
            this.nextstageid = + params["nextStage"];
            this.action = params["flag"];
            this.model.activityid = +params["Activityid"];
            this.connection = params["connection"];
            this.model.connection = this.connection;
            this.model.actionTaken = this.action,
                this.model.nextstageid = this.nextstageid;
        });


        this.totalamount = 0;
        this.model.creditdays = 0;
        this.totalproamount = 0;
        this.totalproamountnew = 0;
        if (this.enquiryId != null && this.enquiryId > 0) {
            this.user.trnid = this.enquiryId
            this.transactionservice.getOpportunityInfo(this.user)
                .subscribe(
                    data => {
                        this.model.id = data.id
                        this.model.quotationid = data.quotationid
                        this.model.ownerIdofOppor = data.ownerIdofOppor;
                        this.model.ownernameofOppor = data.ownernameofOppor;
                        this.model.quoStatus = data.quoStatus
                        this.model.accname = data.accname
                        this.model.accid = data.accid;
                        this.model.approvedQuo = data.approvedQuo;
                        this.model.enqId = data.enqId;
                        this.model.quoCode = data.quoCode
                        this.model.quoDate = data.quoDate
                        this.model.ctype = data.ctype;
                        this.model.ctypeid = data.ctypeid;
                        this.model.salesTC = data.salesTC
                        this.model.lossreason = data.lossreason
                        this.model.paymentType = data.paymentType;
                        this.model.sendquotation = data.sendquotation
                        this.model.gstseperate = data.gstseperate
                        this.model.orderaccp = data.orderaccp;
                        this.model.dearflag = data.dearflag
                        this.model.installation = data.installation
                        if (this.model.installation == 'EndCustomer') {
                            this.instaName = this.model.accname;
                        } else if (this.model.installation == 'Company') {
                            this.instaName = "Easy Dry Systems";
                        }
                        this.instNamelst.push({
                            id: 2,
                            name: this.model.accname,
                        })
                        this.model.chkschemeflag = data.chkschemeflag;
                        this.model.creditdays = data.creditdays;
                        this.model.contactperson = data.contactperson
                        this.model.otpverified = data.otpverified
                        this.model.Otpshow = data.otp
                        this.contactlst = data.contactlst;
                        this.procatlst = data.procatlst;
                        this.productEnquirylst = data.productEnquirylst;
                        this.tandclst = data.tandclst;

                        this.quohistorylst = data.qhistorylst;
                        this.quoVersionHistory = data.qversionlst;

                        this.lstproductintersted = data.lstproductintersted;

                        this.changetandclt = data.changetandclt
                        this.model.previousremarks = data.previousremarks;
                        this.model.schmemCode = data.schmemCode
                        this.model.schemetype = data.schemetype
                        this.model.installionDate = new Date();
                        // this.model.unitno = data.unitno;
                        // this.model.buildingName = data.buildingName;
                        // this.model.city = data.city;
                        // this.model.pincode = data.pincode;
                        this.model.address = data.unitno + ", " + data.buildingName + ", " + data.address + ", " + data.city + ", " + data.pincode;
                        // for(let i=0; i< this.changetandclt.length; i++){
                        //     this.changetandclt[i].descrption = this.sanitizer.sanitize(SecurityContext.HTML, this.changetandclt[i].descrption);
                        // }
                        for (let i = 0; i < this.productEnquirylst.length; i++) {
                            this.model.customnumber = this.productEnquirylst[i].customnumber
                        }
                        for (let i = 0; i < this.lstproductintersted.length; i++) {

                            this.procatid = this.lstproductintersted[0].productCategoryID;
                            this.procatname = this.lstproductintersted[0].categoryname;
                            this.getProductsByPC(this.procatid)
                        }


                        for (let i = 0; i < this.contactlst.length; i++) {
                            this.model.contactpersonid = this.contactlst[i].id
                            this.model.contactperson = this.contactlst[i].contactname

                        }
                        if (this.model.quoStatus == 'W') {
                            this.model.chkwon = true;
                            this.model.decision = "won";
                            this.fixedDecision = "won";
                        } else if (this.model.quoStatus == 'A') {
                            this.model.chkaband = true;
                            this.model.decision = "abandoned"
                            this.fixedDecision = null;
                        } else if (this.model.quoStatus == 'L') {
                            this.model.chklost = true;
                            this.model.decision = "lost"
                            this.fixedDecision = null;
                        }
                        this.model.remarks = data.remarks
                        if (data.decisionDate != null) {
                            this.model.decisionDate = new Date(data.decisionDate);
                        }
                        this.model.installInstru = data.installInstru;
                        if (data.installionDate != null) {
                            this.model.installionDate = new Date(data.installionDate)
                        }
                        if (data.installionTime != null) {
                            this.model.installionTime = data.installionTime;
                        }

                        if (this.model.installionDate != null) {
                            this.model.chkscheduleInstal = true
                        } else {
                            this.model.chkscheduleInstal = false
                        }

                        this.onChangeOfPrice();
                        //this.btnApplyScheme();

                        if (this.model.schmemCode == null) {
                            this.codehide = false;
                        } else if (this.model.schmemCode != null) {
                            this.codehide = true;
                        }
                    });
        }
    }

    getflag() {
        this.flagmodule.flagname = "Tax";
        this.CompanyService.getmoduleflag(this.flagmodule).subscribe((value) => {
            if (value.flagresult == "Y") {
                this.taxdetails = true;
            }
        });

    }

    getLossReasons() {
        let user = new User();
        user.message = "LR"
        this.Masterservice.getLossReasons(user)
            .subscribe(
                data => {
                    this.reasonslst = data
                });
    }

    onSelectContact(val) {
        if (val == "0") {
            this.notifyService.showRroor("Please select contact person!", "Oops!");
            this.model.contactperson = null;
            return;
        }
        this.model.contactperson = val
        // this.model.contactperson = val
        // alert(this.model.contactperson)
    }

    onSelectPayment(val) {
        if (val == "0") {
            this.notifyService.showRroor("Please Select Payment Type!", "Oops!");
            this.model.paymentType = null;
            return;
        }
        this.model.paymentType = val;
    }

    btnQuotHist(QuotationHistory: TemplateRef<any>) {

        this.modalRef = this.modalService.show(QuotationHistory, {
            class: 'modal-lg'
        });
    }

    btnVersHist(VersionHistory: TemplateRef<any>) {
        this.modalRef = this.modalService.show(VersionHistory, {
            class: 'modal-lx'
        });
    }

    onSelectProCat(value) {
        this.getProductsByPC(value)
        for (let i = 0; i < this.procatlst.length; i++) {
            if (this.procatlst[i].pcid == value) {
                this.procatid = this.procatlst[i].pcid;
                this.procatname = this.procatlst[i].pcname;
            }
        }
    }

    getProductsByPC(pcid) {
        this.productlst = [];
        this.user.trnid = pcid;
        this.user.userid = Number(localStorage.getItem("userid"));
        this.transactionservice.getProductsByPC(this.user)
            .subscribe(
                data => {
                    this.productlst = data
                });
    }

    onSelectProdCt(val) {
        this.procurrencylst = []
        for (let j = 0; j < this.productlst.length; j++) {
            if (this.productlst[j].pid == val) {
                this.proid = this.productlst[j].pid
                this.proname = this.productlst[j].name
                this.uom = this.productlst[j].uom
                this.minprice = this.productlst[j].minimumPrice;
                this.procurrencylst = this.productlst[j].currencylst;
                this.currencyid = this.productlst[j].currencyid
                this.currencyName = this.productlst[j].currencyName
                this.discountAllowed = this.productlst[j].discountAllowed
                this.taxpercent = this.productlst[j].taxpercent
                this.discountAllowedNew = this.productlst[j].discountAllowedNew

            }
        }
        this.getCurrencyOfProduct()
    }

    btnAddProduct() {
        if (this.proid != null) {
            for (let i = 0; i < this.productEnquirylst.length; i++) {
                if (this.productEnquirylst[i].pid == this.proid) {
                    this.notifyService.showRroor("Product already added!", "Oops!");
                    return;
                }
            }
            this.productEnquirylst.push({
                id: null,
                pcid: this.procatid,
                pcname: this.procatname,
                pid: this.proid,
                name: this.proname,
                uom: this.uom,
                listprice: this.minprice,
                offeredPrice: 1 * this.minprice,
                discount: 0,
                quantity: 1,
                currencyid: this.currencyid,
                currencyName: this.currencyName,
                currencylst: this.procurrencylst,
                discountAllowed: this.discountAllowed,
                totalproamount: null,
                approvedQuo: true,
                taxamount: null,
                taxpercent: this.taxpercent,
                netamount: 0,
                discountAmount: null,
                targetcurrencyid: null,
                revisedDiscount: null,
                totalamount: null,
                amount: null,
                ratewithdiscount: null,
                offereddiscount: 0,
                newdiscountamt: null,
                totalproamountnew: null,
                tax1: null,
                totalnewamt: null,
                fulldiscount: null,
                listtotal: null,
                rateWithGst: null,
                discountAllowedNew: this.discountAllowedNew,
                customnumber: null,
            });

            if (this.model.schmemCode != null) {
                this.btnApplyScheme();
            }

            this.onChangeOfPrice();
            this.procatid = null;
            this.proid = null;
            this.proname = null;
            this.uom = null;
            this.minprice = null;
            // this.currencyid = null;
            this.procurrencylst = null;
            this.discountAllowed = null;
            this.taxpercent = null;
            this.productlst = [];

        } else {
            this.notifyService.showRroor("Please select product!", "Oops!");
            return;
        }
        for (let i = 0; i < this.lstproductintersted.length; i++) {

            this.procatid = this.lstproductintersted[0].productCategoryID;
            this.procatname = this.lstproductintersted[0].categoryname;
            this.getProductsByPC(this.procatid)
        }
    }

    onSelectCurrency(value) {
        this.currencyid = value
        // this.currencyName = value

    }

    deleteProduct(quoid, proid) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            if (proid != null) {
                for (let i = 0; i < this.productEnquirylst.length; i++) {
                    if (this.productEnquirylst[i].pid == proid) {
                        this.productEnquirylst.splice(this.productEnquirylst.indexOf(this.productEnquirylst[i]), 1);
                        this.notifyService.showSuccess("Product Removed Successfully", " Success");
                    }
                }
            } else {
                let user = new User();
                user.trnid = quoid;
                this.transactionservice.deleteQuotationProduct(user)
                    .subscribe(
                        data => {
                            if (data.status == "OK") {
                                this.notifyService.showSuccess(data.message, " Success");
                                for (let i = 0; i < this.productEnquirylst.length; i++) {
                                    if (this.productEnquirylst[i].id == quoid) {
                                        this.productEnquirylst.splice(this.productEnquirylst.indexOf(this.productEnquirylst[i]), 1);
                                    }
                                }
                            }
                        });
            }
            this.onChangeOfPrice();
        }
    }

    getCurrencyOfProduct() {
        let user = new User();
        user.trnid = this.proid;
        this.transactionservice.getCurrencyOfProduct(user)
            .subscribe(
                data => {
                    this.currencylst = data.currencylst
                    this.currcymodel = data;

                });
    }

    onChangeOfPrice() {
        this.totalamount = 0;
        for (let i = 0; i < this.productEnquirylst.length; i++) {
            if (this.model.orderaccp == "Company" && this.action != "Approve") {
                if (this.productEnquirylst[i].revisedDiscount > 0) {
                    if (this.productEnquirylst[i].discount > this.productEnquirylst[i].revisedDiscount) {
                        this.productEnquirylst[i].approvedQuo = false
                    } else {
                        this.productEnquirylst[i].approvedQuo = true
                    }
                }
                else {
                    if (this.productEnquirylst[i].discount > this.productEnquirylst[i].discountAllowedNew) {
                        this.notifyService.showRroor("Discount value is greater than discount allowed", "Oops!");
                        this.productEnquirylst[i].approvedQuo = true
                    }
                    else {
                        this.productEnquirylst[i].approvedQuo = false
                    }
                    if (this.productEnquirylst[i].discount > this.productEnquirylst[i].discountAllowed) {
                        this.productEnquirylst[i].approvedQuo = false
                    } else {
                        this.productEnquirylst[i].approvedQuo = true
                    }
                }
            }
            else {

                if (this.model.orderaccp == "EndCustomer") {
                    if (this.model.chkschemeflag != 'Y') {

                        this.productEnquirylst[i].discount = 0;
                        this.productEnquirylst[i].discountAmount = 0;
                        this.productEnquirylst[i].customnumber = 0;
                    }

                    if (this.schemeModel.schemeDiscount != null && this.schemeModel.schemeDiscount > 0) {
                        if (this.schemeModel.discountType == "percentage") {
                            //Scheme Discount Type Percentage
                            this.productEnquirylst[i].discount = this.schemeModel.schemeDiscount;
                        } else {
                            this.productEnquirylst[i].discountAmount = this.schemeModel.schemeDiscount
                            // this.productEnquirylst[i].discount = 0;
                        }

                    }
                    if (this.model.customnumber != null && this.model.customnumber > 0) {
                        if (this.schemeModel.discountType == "Custom") {
                            this.productEnquirylst[i].customnumber = this.model.customnumber
                        }
                    }
                }

                if (this.model.schmemCode != null) {
                    this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                    this.productEnquirylst[i].totalamount = this.totalamount;
                    this.productEnquirylst[i].totalamount = Math.round(this.productEnquirylst[i].totalamount);
                    this.amountInWords = convertor(Math.round(this.productEnquirylst[i].totalamount).toFixed(2));
                    this.model.quotationAmount = this.totalamount
                }
            }
            if (this.productEnquirylst[i].discount != null && this.productEnquirylst[i].discount > 0 && this.model.schemetype == "percentage" || this.schemeModel.discountType == "percentage" || this.model.chkschemeflag == 'N') {

                //Scheme Discount Type Percentage
                this.totalamount = 0;
                // for (let i = 0; i < this.productEnquirylst.length; i++) {
                //     let discount = this.productEnquirylst[i].listprice * this.productEnquirylst[i].discount / 100;
                //     this.productEnquirylst[i].offeredPrice = (this.productEnquirylst[i].listprice) - discount;
                //     // this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].offeredPrice / this.productEnquirylst[i].quantity;
                //     this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].offeredPrice;

                //     this.productEnquirylst[i].discountAmount = discount * this.productEnquirylst[i].quantity;

                //     if (this.model.orderaccp == "EndCustomer") {
                //         if (this.schemeModel.maxdiscount != null) {
                //             if (this.schemeModel.maxdiscount < this.productEnquirylst[i].discountAmount) {
                //                 this.productEnquirylst[i].discountAmount = this.schemeModel.maxdiscount
                //                 let disAmount = this.productEnquirylst[i].discountAmount / this.productEnquirylst.length
                //                 this.productEnquirylst[i].offeredPrice = (this.productEnquirylst[i].listprice) - disAmount;
                //                 this.productEnquirylst[i].discount = disAmount * 100 / this.productEnquirylst[i].listprice;
                //             }
                //         }
                //     }
                //     let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].taxpercent) / 100;
                //     this.model.tax = tax1;
                //     this.productEnquirylst[i].taxamount = (tax1 * this.productEnquirylst[i].quantity)
                //     this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                //     this.productEnquirylst[i].totalproamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity) + this.productEnquirylst[i].taxamount;
                //     this.productEnquirylst[i].totalproamount = Math.round(this.productEnquirylst[i].totalproamount);
                //     this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                //     this.productEnquirylst[i].totalamount = this.totalamount;
                //     // this.productEnquirylst[i].offeredPrice = this.productEnquirylst[i].offeredPrice + tax1;
                //     this.amountInWords = convertor(Math.round(this.productEnquirylst[i].totalamount).toFixed(2));
                //     this.model.quotationAmount = this.totalamount

                // }

                let listtotal = 0;
                let fulldiscount = 0;
                let fulltotal = 0;
                let fulltotaldiscount = 0;
                let lsdiscount = 0;
                for (let i = 0; i < this.productEnquirylst.length; i++) {

                    listtotal = this.productEnquirylst[i].listprice * this.productEnquirylst[i].quantity;
                    fulltotal = fulltotal + listtotal;
                    // fulldiscount = listtotal * this.productEnquirylst[i].discount / 100;
                    // alert(fulldiscount + 'fulldiscount')
                    lsdiscount = this.productEnquirylst[i].discount;

                }
                fulltotaldiscount = fulltotal * lsdiscount / 100;
                for (let i = 0; i < this.productEnquirylst.length; i++) {

                    if (this.model.orderaccp == "EndCustomer") {
                        if (this.schemeModel.maxdiscount != null) {
                            if (this.schemeModel.maxdiscount < fulltotaldiscount) {
                                // fulltotaldiscount = this.schemeModel.maxdiscount
                                this.productEnquirylst[i].discount = this.schemeModel.maxdiscount * 100 / fulltotal;
                            }
                        }
                    }
                    // }
                    // for (let i = 0; i < this.productEnquirylst.length; i++) {
                    this.productEnquirylst[i].discountAmount = (this.productEnquirylst[i].listprice * this.productEnquirylst[i].quantity) * this.productEnquirylst[i].discount / 100;
                    this.productEnquirylst[i].offeredPrice = ((this.productEnquirylst[i].listprice * this.productEnquirylst[i].quantity) - this.productEnquirylst[i].discountAmount) / this.productEnquirylst[i].quantity;
                    this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].offeredPrice;

                    if (this.productEnquirylst[i].taxpercent > 0) {
                        let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].taxpercent) / 100;
                        this.productEnquirylst[i].taxamount = (tax1 * this.productEnquirylst[i].quantity)
                    } else {
                        this.productEnquirylst[i].taxamount = 0;
                    }

                    this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                    this.productEnquirylst[i].totalproamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity) + this.productEnquirylst[i].taxamount;
                    this.productEnquirylst[i].totalproamount = Math.round(this.productEnquirylst[i].totalproamount);
                    this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                    this.productEnquirylst[i].totalamount = this.totalamount;
                    // this.productEnquirylst[i].offeredPrice = this.productEnquirylst[i].offeredPrice + tax1;
                    this.amountInWords = convertor(Math.round(this.productEnquirylst[i].totalamount).toFixed(2));
                    this.model.quotationAmount = this.totalamount
                }


                // let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].taxpercent) / 100;
                // this.model.tax = tax1;
                // this.productEnquirylst[i].taxamount = (tax1 * this.productEnquirylst[i].quantity)
                // this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                // this.productEnquirylst[i].totalproamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity) + this.productEnquirylst[i].taxamount;
                // // alert(this.productEnquirylst[i].totalproamount.toFixed(2));
                // this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                // this.productEnquirylst[i].totalamount = this.totalamount;
                // // alert(this.productEnquirylst[i].totalamount);
                // // this.productEnquirylst[i].offeredPrice = this.productEnquirylst[i].offeredPrice + tax1;
                // this.amountInWords = convertor(this.productEnquirylst[i].totalamount.toFixed(2));
                // this.model.quotationAmount = this.totalamount
                // // alert(this.model.quotationAmount)

            } else if (this.model.orderaccp == "EndCustomer" && this.productEnquirylst[i].discountAmount != null && this.productEnquirylst[i].discountAmount > 0 && this.model.schemetype == "value" || this.schemeModel.discountType == "value") {
                this.totalnewamt = 0;
                this.totalamount = 0;
                let disAmount = 0;
                //Scheme Discount Type Value
                for (let i = 0; i < this.productEnquirylst.length; i++) {
                    if (this.productEnquirylst[i].taxpercent > 0) {
                        let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity * this.productEnquirylst[i].taxpercent) / 100;
                        this.productEnquirylst[i].taxamount = tax1
                    }else{
                        this.productEnquirylst[i].taxamount = 0;
                    }
                    this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                    this.productEnquirylst[i].totalproamountnew = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity) + this.productEnquirylst[i].taxamount;
                    this.totalnewamt = this.totalnewamt + this.productEnquirylst[i].totalproamountnew;
                    disAmount = this.productEnquirylst[i].discountAmount;
                }
                for (let i = 0; i < this.productEnquirylst.length; i++) {
                    this.offereddiscount = disAmount * 100 / this.totalnewamt;
                    this.productEnquirylst[i].offereddiscount = this.offereddiscount;
                    this.productEnquirylst[i].discount = this.productEnquirylst[i].offereddiscount;
                    this.productEnquirylst[i].newdiscountamt = (this.productEnquirylst[i].totalproamountnew * this.productEnquirylst[i].discount) / 100;
                    this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                    this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].netamount - ((this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity * this.productEnquirylst[i].discount) / 100)
                   if(this.productEnquirylst[i].taxpercent>0){
                    let tax1 = (this.productEnquirylst[i].ratewithdiscount * this.productEnquirylst[i].taxpercent) / 100;
                    this.productEnquirylst[i].taxamount = tax1;
                   }else{
                    this.productEnquirylst[i].taxamount = 0;
                   }
                    this.productEnquirylst[i].totalproamount = this.productEnquirylst[i].ratewithdiscount + this.productEnquirylst[i].taxamount;
                    this.productEnquirylst[i].totalproamount = Math.round(this.productEnquirylst[i].totalproamount)
                    this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].ratewithdiscount / this.productEnquirylst[i].quantity;
                    this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                    this.amountInWords = convertor(Math.round(this.totalamount).toFixed(2));
                    this.model.quotationAmount = this.totalamount
                }

                // this.totalamount = 0;
                // this.totalproamount = 0;
                // let discountAmount = 0 ;
                // //Scheme Discount Type Value
                // for (let i = 0; i < this.productEnquirylst.length; i++) {

                //     let tax11 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].taxpercent) / 100;
                //     this.productEnquirylst[i].taxamount = (tax11 * this.productEnquirylst[i].quantity)
                //     this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                //     this.totalproamount = this.totalproamount +  ((this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity) + this.productEnquirylst[i].taxamount);
                // }

                // for (let i = 0; i < this.productEnquirylst.length; i++) {
                //     let discount = this.productEnquirylst[i].discountAmount * 100 / this.totalproamount;
                //     this.productEnquirylst[i].discount = discount;

                //      discountAmount = (this.productEnquirylst[i].listprice * this.productEnquirylst[i].quantity) * this.productEnquirylst[i].discount / 100;
                //     this.productEnquirylst[i].offeredPrice = (this.productEnquirylst[i].listprice * this.productEnquirylst[i].quantity) - discountAmount;
                //     this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].offeredPrice;

                //     let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].taxpercent) / 100;
                //     this.model.tax = tax1;
                //     this.productEnquirylst[i].taxamount = tax1;
                //     this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice)
                //     this.productEnquirylst[i].totalproamount = (this.productEnquirylst[i].offeredPrice) + tax1;
                //     this.productEnquirylst[i].totalproamount = Math.round(this.productEnquirylst[i].totalproamount);
                //     this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                //     this.productEnquirylst[i].totalamount = this.totalamount;
                //     this.amountInWords = convertor(Math.round(this.productEnquirylst[i].totalamount).toFixed(2));
                //     this.model.quotationAmount = this.totalamount
                // }
            }
            else if (this.model.orderaccp == "EndCustomer" && this.productEnquirylst[i].customnumber != null && this.productEnquirylst[i].customnumber > 0 && this.schemeModel.discountType == "Custom" || this.model.schemetype == "Custom") {
                this.totalnewamt = 0;
                this.totalamount = 0;
                let disAmount = 0;
                //Scheme Discount Type Custom
                for (let i = 0; i < this.productEnquirylst.length; i++) {
                    if (this.productEnquirylst[i].taxpercent > 0) {
                        let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity * this.productEnquirylst[i].taxpercent) / 100;
                        this.productEnquirylst[i].taxamount = tax1
                    } else {
                        this.productEnquirylst[i].taxamount = 0;
                    }
                    this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                    this.productEnquirylst[i].totalproamountnew = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity) + this.productEnquirylst[i].taxamount;
                    this.totalnewamt = this.totalnewamt + this.productEnquirylst[i].totalproamountnew;
                    disAmount = this.productEnquirylst[i].customnumber;
                }
                for (let i = 0; i < this.productEnquirylst.length; i++) {
                    this.offereddiscount = disAmount * 100 / this.totalnewamt;
                    this.productEnquirylst[i].offereddiscount = this.offereddiscount;
                    this.productEnquirylst[i].discount = this.productEnquirylst[i].offereddiscount;
                    this.productEnquirylst[i].newdiscountamt = (this.productEnquirylst[i].totalproamountnew * this.productEnquirylst[i].discount) / 100;
                    this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                    this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].netamount - ((this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity * this.productEnquirylst[i].discount) / 100)

                    if (this.productEnquirylst[i].taxpercent > 0) {
                        let tax1 = (this.productEnquirylst[i].ratewithdiscount * this.productEnquirylst[i].taxpercent) / 100;
                        this.productEnquirylst[i].taxamount = tax1;
                        this.productEnquirylst[i].totalproamount = this.productEnquirylst[i].ratewithdiscount + tax1;
                    } else {
                        let tax1 = 0;
                        this.productEnquirylst[i].taxamount = tax1;
                        this.productEnquirylst[i].totalproamount = this.productEnquirylst[i].ratewithdiscount + tax1;
                    }

                    this.productEnquirylst[i].totalproamount = Math.round(this.productEnquirylst[i].totalproamount)
                    this.productEnquirylst[i].ratewithdiscount = this.productEnquirylst[i].ratewithdiscount / this.productEnquirylst[i].quantity;
                    this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                    this.amountInWords = convertor(Math.round(this.totalamount).toFixed(2));
                    this.model.quotationAmount = this.totalamount
                }

            }
            else {
                this.productEnquirylst[i].offeredPrice = (this.productEnquirylst[i].listprice);
            }
            if (this.model.schmemCode == null && this.model.chkschemeflag == 'Y') {
                this.totalamount = 0;
                this.discount = 0;
                for (let i = 0; i < this.productEnquirylst.length; i++) {
                    this.productEnquirylst[i].ratewithdiscount = null;
                    // let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].taxpercent) / 100;
                    // this.model.tax = tax1;
                    // this.productEnquirylst[i].taxamount = (tax1 * this.productEnquirylst[i].quantity)
                    if (this.productEnquirylst[i].taxpercent > 0) {
                        let tax1 = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].taxpercent) / 100;
                        this.model.tax = tax1;
                        this.productEnquirylst[i].taxamount = (tax1 * this.productEnquirylst[i].quantity)
                    } else {
                        let tax1 = 0;
                        this.model.tax = tax1;
                        this.productEnquirylst[i].taxamount = this.productEnquirylst[i].quantity;
                    }
                    this.productEnquirylst[i].netamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity)
                    this.productEnquirylst[i].totalproamount = (this.productEnquirylst[i].offeredPrice * this.productEnquirylst[i].quantity) + this.productEnquirylst[i].taxamount;
                    this.productEnquirylst[i].totalproamount = Math.round(this.productEnquirylst[i].totalproamount)
                    this.totalamount = this.totalamount + this.productEnquirylst[i].totalproamount;
                    this.productEnquirylst[i].totalamount = this.totalamount;
                    this.amountInWords = convertor(Math.round(this.productEnquirylst[i].totalamount).toFixed(2));
                    this.model.quotationAmount = this.totalamount;
                    this.productEnquirylst[i].discount = this.discount;

                }
            }
            if (this.productEnquirylst[i].quantity == 0) {
                this.productEnquirylst[i].discount = 0;
            }

        }
        var found = this.productEnquirylst.find(function (x) { return x.approvedQuo === false; });
        if (found) {
            this.approvedQuo = false;
            this.model.approvedQuo = false;
        } else {
            this.approvedQuo = true;
            this.model.approvedQuo = true;
        }
    }

    onSelectProCurrency(val, pid, ind) {

        if (this.productEnquirylst[ind].pid == pid) {
            this.productEnquirylst[ind].currencyid = val;
            for (let j = 0; j < this.productEnquirylst[ind].currencylst.length; j++) {
                if (this.productEnquirylst[ind].currencylst[j].id == val) {
                    this.productEnquirylst[ind].listprice = this.productEnquirylst[ind].currencylst[j].amount
                }
                if (this.currencyid != val) {
                    this.productEnquirylst[ind].targetcurrencyid = val
                }
            }
        }
        if (ind == 0) {
            for (let i = 0; i < this.productEnquirylst.length; i++) {
                this.productEnquirylst[i].currencyid = val
            }
        }
        this.onChangeOfPrice();
    }

    onchkwon($event) {
        this.model.decision = "won";
        this.model.lossreason = null;
    }

    onchklost($event) {
        this.model.decision = "lost"
    }

    onchkaband($event) {
        this.model.decision = "abandoned"
    }

    onSelectLossReason(value) {
        this.model.lossreason = value;
    }

    btnApplyScheme() {
        this.codehide = true;
        if (this.productEnquirylst.length < 0) {
            this.notifyService.showRroor("Please select at least one Product", "Oops!");
            return;
        }
        if (this.model.schmemCode == null || this.model.schmemCode == "") {
            this.notifyService.showRroor("Please enter scheme code", "Oops!");
            return;
        }
        if (this.schemeModel.discountType == "Custom") {
            if (this.model.customnumber == null || this.model.customnumber.toString() == "") {
                this.notifyService.showRroor("Please Enter Custom Number", "Oops!");
                return;
            }
        }
        if (this.schemeModel.discountType == "Custom") {

            if (this.model.customnumber > this.totalamount) {
                this.notifyService.showRroor("Please Enter Valid Custom Number", "Oops!");
                return;
            }
        }
        this.schemeModel.schemeDiscount = 0;
        this.schemeModel.maxdiscount = 0;
        this.model.productEnquirylst = this.productEnquirylst;
        this.transactionservice.btnApplySchemeCode(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.schemeModel = data;
                        this.onChangeOfPrice();
                        this.notifyService.showSuccess(data.message, "Done!");
                    } else if (data.status == "NO") {
                        this.notifyService.showRroor(data.message, "Done!");
                        return;
                    }
                });
    }

    removeScheme() {
        this.codehide = false;
        this.model.schmemCode = null;
        this.schemeModel.schemeDiscount = 0;
        this.schemeModel.maxdiscount = 0;
        this.schemeModel.discountType = null;
        this.model.customnumber = null;
        for (let i = 0; i < this.productEnquirylst.length; i++) {
            this.productEnquirylst[i].customnumber = null;
            this.productEnquirylst[i].discountAmount = null;
            this.productEnquirylst[i].ratewithdiscount = null;
        }
        this.model.schemetype = null;
        this.onChangeOfPrice();
    }

    btnback() {
        if (this.action == "Approve") {
            this.router.navigate(['/Opportunities']);
        } else {
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    "id": this.enquiryId,
                }
            };
            this.router.navigate(['/updateDCR'], navigationExtras);
        }

    }

    // btnSendQuo() {

    //     this.transactionservice.btnSendQuotation(this.model)
    //         .subscribe(
    //             data => {
    //                 if (data.status == "OK") {
    //                     this.notifyService.showSuccess(data.message, "Done!");
    //                 }
    //             });

    // }

    btnsaveQuotation() {
        this.onChangeOfPrice();
        this.tandcapprove();
        this.saveButton = false;
        
        if (this.productEnquirylst.length == 0) {
            this.saveButton = true;
            this.notifyService.showRroor("Please select at least one Product", "Oops!");
            return;
        }
        if (this.model.contactperson == null) {
            this.saveButton = true;
            this.notifyService.showRroor("Please select Contact Person", "Oops!");
            return;
        }
        if (this.model.otpverified == false && this.model.decision == "won" && this.model.ctype == 'Retail') {
            this.saveButton = true;
            this.notifyService.showRroor("Please Enter OTP", "Oops!");
            return;
        }
        if (this.action == "Decision" && this.model.orderaccp == 'Company') {

            if (this.model.decision == null) {
                this.saveButton = true;
                this.notifyService.showRroor("Please select one Decision", "Oops!");
                return;
            }
            if (this.model.decisionDate == null) {
                this.saveButton = true;
                this.notifyService.showRroor("Please select Decision Date", "Oops!");
                return;
            }
            // if (this.model.decisionDate == null) {
            //     this.saveButton = true;
            //     this.notifyService.showRroor("Please select Decision Date", "Oops!");
            //     return;
            // }
            if (this.model.decision == "lost" || this.model.decision == "abandoned") {
                if (this.model.lossreason == null) {
                    this.saveButton = true;
                    this.notifyService.showRroor("Please select Loss/Abandoned Reason", "Oops!");
                    return;
                }
            }

            if (this.model.remarks == null) {
                this.saveButton = true;
                this.notifyService.showRroor("Please enter Remarks", "Oops!");
                return;
            }
        }
        if (this.action == "Decision" && this.model.orderaccp == 'EndCustomer') {
            if (this.model.decision == null) {
                this.saveButton = true;
                this.notifyService.showRroor("Please select one Decision", "Oops!");
                return;
            }
            if (this.model.decision == "lost" || this.model.decision == "abandoned") {
                if (this.model.lossreason == null) {
                    this.saveButton = true;
                    this.notifyService.showRroor("Please select Loss/Abandoned Reason", "Oops!");
                    return;
                }
            }
            if (this.model.remarks == null) {
                this.saveButton = true;
                this.notifyService.showRroor("Please enter Remarks", "Oops!");
                return;
            }

        }
        if (this.changeflag == true) {
            this.saveButton = true;
            this.notifyService.showRroor("Please Update Instalation ", "Oops!");
            return;
        }
        this.model.schemetype = this.schemeModel.discountType;
        this.model.productEnquirylst = this.productEnquirylst;
        for (let i = 0; i < this.model.productEnquirylst.length; i++) {
            if (this.model.schemetype == "value" || this.model.schemetype == "percentage") {
                this.model.productEnquirylst[i].customnumber = null;
            }
        }
        this.model.tandclst = this.tandclst;
        this.model.showOtp = this.showOtp
        if (this.instaName == "Easy Dry Systems") {
            this.model.installation = "Y";
        } else {
            this.model.installation = "N";
        }
        this.showBtn = true;
        if ( this.showBtn ==  true)
        {
           alert("Please Wait ! Button is already clicked");   
        }
         
        this.transactionservice.btnsaveQuotation(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        // this.tandclst.forEach(item=>{
                        //     if(item.descrption == item.prevDis)
                        //     {   
                        //         alert('OK');
                        //     }
                        // })
                        this.notifyService.showSuccess("Quotation version saved successfully", "Done!");
                        this.saveButton = true;
                        this.fixedDecision = this.model.decision
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                "id": this.enquiryId,
                                "activityid": this.activityid
                            }
                        };
                        if (this.model.quotationid == null || this.action == "Decision") {
                            this.router.navigate(['/Opportunities'], navigationExtras);
                        } else {
                            this.router.navigate(['/updateDCR'], navigationExtras);
                        }
                    }
                });


    }

    btnApproveQuotation() {
        this.model.productEnquirylst = this.productEnquirylst;
        this.model.tandclst = this.tandclst;
        this.transactionservice.btnApproveQuotation(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.router.navigate(['/Sales Dashboard']);
                    }
                });


    }

    btnVerfiyOtp() {
        this.model.productEnquirylst = this.productEnquirylst;
        if (this.model.schmemCode == null) {
            for (let i = 0; i < this.model.productEnquirylst.length; i++) {
                this.model.productEnquirylst[i].ratewithdiscount = null;
            }
        }
        this.model.tandclst = this.tandclst;
        if (this.model.otp.length == 6) {
            this.transactionservice.btnVerifiedOTPforSalesQuotation(this.model)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess(data.message, "Done!");
                            this.ngOnInit();
                        } else if (data.status == "NO") {
                            this.notifyService.showRroor(data.message, "Oops!");
                            return;
                        }
                    });
        }
    }
    btnprint(QuotationReport: TemplateRef<any>) {
        this.quoReport = null;
        let user = new User();
        user.trnid = this.model.quotationid;
        this.model.quotationid;
        this.reportServices.getQuotationReport(user)
            .subscribe(
                data => {

                    this.quoReport = data
                    this.qutReportlst = data.qutReportlst;
                    this.reporttandclst = data.reporttandclst;

                    this.quoReport.totalamount = 0;
                    this.quoReport.totalnetamount = 0;
                    this.quoReport.totaltaxamount = 0;
                    for (let i = 0; i < this.qutReportlst.length; i++) {
                        this.quoReport.totaltaxamount = this.quoReport.totaltaxamount + this.qutReportlst[i].taxamount;
                        this.quoReport.totalnetamount = this.quoReport.totalnetamount + this.qutReportlst[i].netamount;
                        this.quoReport.totalamount = this.quoReport.totalamount + this.qutReportlst[i].totalproamount;
                        // this.quoReport.totalinwords = converter.toWords(this.quoReport.totalamount);
                        this.quoReport.totalinwords = convertor(this.quoReport.totalamount.toFixed(2));
                    }
                });

        this.modalRef = this.modalService.show(QuotationReport, {
            class: 'modal-lg'
        });
    }
    openPrint() {
        var divToPrint = document.getElementById('inv');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    selectInstallationDate(installionDate) {
        let pastdate = moment(new Date(), "DD/MM/YYYY").toDate()

        if (installionDate < pastdate) {
            this.notifyService.showRroor("Previous installation date cannot be selected", "oops");
            this.model.installionDate = new Date();
            return;
        }
    }
    redirectUser(mainmenulabel: string) {
        this.router.navigate(['/' + mainmenulabel]);
    }
    onSelectdecision(val) {
        if (val == "Won") {
            this.model.decision = "won";
            this.model.lossreason = null;
        } else if (val == "Lost") {
            this.model.decision = "lost";
        } else if (val == "Abandoned") {
            this.model.decision = "abandoned";
        }
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    showotp() {
        this.showOtp = true;
    }
    tandcapprove() {
        for (let i = 0; i < this.tandclst.length; i++) {
            if (this.tandclst[i].prevDis == this.tandclst[i].descrption) {
                this.model.approvedQuo = true;
            }
            else {
                this.model.approvedQuo = false;
            }
        }
    }
    onSelectInstby(val) {
        for (let i = 0; i < this.instNamelst.length; i++) {
            if (val == this.instNamelst[i].id) {
                this.instaName = this.instNamelst[i].name;
            }
        }
    }
    onchange() {
        this.changeflag = true;
    }
    onupdate() {
        this.changeflag = false;
    }
    openPrint1() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        //WindowObject.close();
    }
    selectSchemeCode(data) {
        this.model.schmemCode = data;
        this.model.productEnquirylst = null;
        this.model.customnumber = null;
        if (this.model.schmemCode.length > 4) {
            this.Masterservice.getSchemeType(this.model)
                .subscribe(
                    data => {
                        this.schemeModel = data;
                    })
        }
    }

}