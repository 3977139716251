<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{page}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="col l3"></div>
            <div class="row">
                <div class="col l6 m6 s12" style="margin-top: 3%">
                    <form [formGroup]="formGroup">
                        <div formArrayName="extended">
                            <div *ngFor="let form of extendsForms.controls; let i=index">
                                <div [formGroupName]="i">
                                    <div class="input-field"
                                        *ngIf="fields[i].labelName == 'Textbox' && fields[i].dataType == 'STRING'"
                                        style="margin-bottom: 6%">
                                        <label class="active" [for]="fields[i].dspname">{{fields[i].dspname}}</label>
                                        <input type="text" formControlName="value" [maxlength]="fields[i].maxlenth"
                                            data-toggle="tooltip" data-placement="top" [title]="fields[i].dspname">
                                    </div>
                                    <div class="input-field"
                                        *ngIf="fields[i].labelName == 'Textbox' && fields[i].dataType == 'INT'"
                                        style="margin-bottom: 6%">
                                        <label class="active" [for]="fields[i].dspname">{{fields[i].dspname}}</label>
                                        <input type="number" formControlName="value" [maxlength]="fields[i].maxlenth"
                                            data-toggle="tooltip" data-placement="top" [title]="fields[i].dspname">
                                    </div>
                                    <div class="input-field" *ngIf="'Checkbox' == fields[i].labelName"
                                        style="margin-bottom: 6%">
                                        <label class="active" [for]="fields[i].dspname">{{fields[i].dspname}}</label>
                                        <input type="checkbox" formControlName="value" [formControl]="form">
                                    </div>
                                    <div class="input-field" *ngIf="'Textarea' == fields[i].labelName"
                                        style="margin-bottom: 6%">
                                        <label class="active" [for]="fields[i].dspname">{{fields[i].dspname}}</label>
                                        <textarea formControlName="value" class="materialize-textarea" required=true
                                            [maxlength]="fields[i].maxlenth" data-toggle="tooltip" data-placement="top"
                                            [title]="fields[i].dspname"></textarea>
                                    </div>
                                    <div class="input-field"
                                        *ngIf="'Dropdown' == fields[i].labelName && fields[i].dataType == 'INT'"
                                        style=" margin-bottom: 6%">
                                        <label class="active" [for]="fields[i].dspname">{{fields[i].dspname}}</label>
                                        <div class="input-field">
                                            <select formControlName="value" (change)="onSelectDrop($event.target.value)"
                                                data-toggle="tooltip" data-placement="top" [title]="fields[i].dspname">
                                                <option *ngFor="let order of fields[i].selectArray" value="{{order.id}}"
                                                    selected="value==order.id">
                                                    {{order.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>


                                    <div class="input-field"
                                        *ngIf="'Dropdown' == fields[i].labelName && fields[i].dataType == 'STRING'"
                                        style="margin-bottom: 6%">
                                        <label class="active" [for]="fields[i].dspname">{{fields[i].dspname}}</label>
                                        <div class="input-field">
                                            <select formControlName="value" (change)="onSelectDrop($event.target.value)"
                                                data-toggle="tooltip" data-placement="top" [title]="fields[i].dspname">
                                                <option *ngFor="let order of fields[i].selectArray"
                                                    value="{{order.name}}" [selected]="value==order.name">
                                                    {{order.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>


                                    <div class="input-field" *ngIf="'Date Picker' == fields[i].labelName"
                                        style="margin-bottom: 6%">
                                        <input class="active" type="text" [placeholder]="fields[i].dspname" id="mdate"
                                            name="completiondate" formControlName="value" class="form-control"
                                            bsDatepicker data-toggle="tooltip" data-placement="top"
                                            [title]="fields[i].dspname"
                                            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue' }">
                                    </div>

                                    <div class="input-field" *ngIf="'DATE_TIME' == fields[i].dataType"
                                        style="margin-bottom: 6%">
                                        <label class="active" [for]="fields[i].dspname">{{fields[i].dspname}}</label>
                                        <input type="text" formControlName="value">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l9 m9 s9" style="text-align: right;padding-right: 0px;">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>
                </div>
            </div>
            <div class="col l3"></div>
        </div>
    </div>
</div>