import { DropDownModel } from "src/app/_models/DropDownModel";

export class SearchActivityModel {
    id: number;
    srno: number;
    activityCode: string;
    activityName: string;
    sequence: number;
    accesstype: string;
    cuscheckselect:boolean;
     customertypename:string;
    customerTypelst:SearchActivityModel[];
    allcheckselect:boolean;
    callType: string;
    callsubtype: string;
    description: string;
    txtserch: string;
    txtselect: string;
    userid:number;
    actiontype: string;
    actiontypesaved: string;
    msgcode:string;
    smsid:number;
    issmscheck:boolean;
    isemailcheck:boolean;
    messagelst:DropDownModel[];
}