<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Stock Adjustment</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="input-field col s4">
                    <span>Inventory Location</span>
                    <select (change)="onSelectInventoryLocation($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Inventory Location">
                        <option selected value="0">Select Inventory Location</option>
                        <option *ngFor="let lst of locationlst" [value]="lst.id">
                            <!-- [selected]="lst.id== model.locationid" -->
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="col s4"></div>
                <div class="col s4 m4 l4" style="margin-top: 40px;text-align: center;">
                    <div><span><b>Auythorised By </b></span></div>
                    <label id="lable1" for="first1">{{model.authorizedby}}</label>
                </div>
            </div>


            <div class="row">
                <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;margin-top: 30px;">
                    <span>Item Category</span>
                    <select (change)="onSelectitemCat($event.target.value)">
                        <option selected>Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id"
                            [selected]="type.id == model.itemcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;margin-top: 30px;">
                    <span>Sub Category</span>
                    <select (change)="onSelectSubCat($event.target.value)">
                        <option selected>Select Sub Category</option>
                        <option *ngFor="let type of subCatlst" [value]="type.id"
                            [selected]="type.id == model.itemsubcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <!-- <div class=" input-field col s3" style="margin-top: 40px;margin-bottom: 0px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.itemname" id="itmname"
                        placeholder="Item Name" (keyup)="getItemLike($event)" />
                    <input type="hidden" id="acc-id" />
                </div> -->
                <div class="ng-autocomplete pinRowgrp input-field col s3" style="margin-top: 40px;margin-bottom: 0px;">
                    <ng-autocomplete [data]="userList2" type="text" placeholder="Item Name" [searchKeyword]="keyword1"
                        [minQueryLength]="3" [(ngModel)]="model.itemname" id="itmname"
                        (inputChanged)='onChangeSearchstock($event)' (selected)='selectEvent($event)'
                        (inputFocused)='onFocusedforstock($event)' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.itemname"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>

                </div>

                <!-- <div class=" input-field col s2 " style="margin-top: 40px;">
                    <input [(ngModel)]="model.quantity" #quantity="ngModel" type="number" min="0" id="first3"
                        data-toggle="tooltip" data-placement="top" title="Quantity" style="text-align: right;" ngModel
                        required>
                    <label id="lable2" for="first2">Quantity</label>
                </div> -->

                <div class="row col s1" style="text-align: right;padding-top: 40px;">
                    <button type="button" (click)="btnadd()" class="waves-effect waves-light btn">Add</button>

                </div>
            </div>

            <div class="row">
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col l12 m12 s12" style="padding-left: 0px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="25px" style="text-align: center">Item Code</th>
                                    <th width="25px" style="text-align: center;padding-right: 30px;">Item Name</th>
                                    <th width="10px" style="text-align: center">UOM</th>
                                    <th width="12px" style="text-align: center">Balance</th>
                                    <th width="5px" style="text-align: center">Adjustment Type</th>
                                    <th width="3px" style="text-align: center">Adjustment Quantity</th>
                                    <th width="3px" style="text-align: center">Balance</th>
                                    <th width="12px" style="text-align: center">Remark</th>
                                    <th width="5px" style="text-align: center"></th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of stocklist | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                                        <td style="text-align: center">{{lst.itemcode}}</td>
                                        <td style="text-align: center">{{lst.itemname}}</td>
                                        <td style="text-align: center">{{lst.uom}}</td>
                                        <td style="text-align: center">{{lst.actuStock}}</td>
                                        <td style="text-align: center">
                                            <div class="input-field s2" style="margin-bottom: 0px;margin-top: 0px;">
                                                <select (change)="onSelectadjustmentType($event.target.value)">
                                                    <option *ngFor="let type of adjusttypelst" [value]="type.name"
                                                        [selected]="type.id == model.id">
                                                        {{type.name}}</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td style="text-align: center"> <input [(ngModel)]="lst.adjustquantity"
                                                #adjustquantity="ngModel" type="number" class="" min="0" id="quantity"
                                                data-toggle="tooltip" data-placement="top" title="quantity"
                                                (ngModelChange)="onChangeOfqantity()" style="margin-top:1px;">
                                        </td>
                                        <td style="text-align: center">{{lst.newstock}}</td>

                                        <td style="text-align: center"> <input [(ngModel)]="lst.remark"
                                                #remark="ngModel" type="text" class="" id="rate" data-toggle="tooltip"
                                                data-placement="top" title="Rrmark" style="margin-top:1px;"></td>
                                        <!-- onkeypress="return /[A-Za-z_ ]/i.test(event.key)" -->

                                        <td style="text-align: center"><span><img
                                                    src="../../../assets/images/delete.png"
                                                    (click)="btndelete(lst.id,i)" data-toggle="tooltip"
                                                    data-placement="top" title="Delete" width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>&nbsp;&nbsp;


                </div>
            </div>

        </div>
    </div>
</div>