import { OnInit, Component } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common';
import { SearchTypeMasterModel } from './SearchTypeMasterModel';
import { User } from 'src/app/_models';

@Component({
    selector: 'app-TypeMaster',
    //  moduleId: module.id,
    templateUrl: 'SearchTypeMaster.html'
})
export class SearchTypeMaster implements OnInit {
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location) {
        this.pagename=this.router.url
    }
    p: any;
    user = new User();
    model = new SearchTypeMasterModel();
    typelst: SearchTypeMasterModel[] = [];
    typelst1: SearchTypeMasterModel[] = [];
    public i;
    pagename: string;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Type" },
        { id: 3, "name": "Type Name" }
    ];
    ngOnInit(): void {
        localStorage.setItem("pagename",this.pagename);  
        this.user.userid = Number(localStorage.getItem("userid"));
        this.getAlltypesMaster()
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }

    onSelectSrch(value) {
        this.model.txtselect = value
    }

    btnsearch() {
        if (this.model.txtselect == "All") {
            this.getAlltypesMaster()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Type") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.typelst1 = [];
                this.typelst.forEach((item) => {
                    if ((item.type.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.typelst1.push({ "id": item.id, "typename": item.typename, "type": item.type, "txtselect": null, "txtserch": null });
                    }
                });
                this.typelst = this.typelst1;
            }
        }
        else if (this.model.txtselect == "Type Name") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.typelst1 = [];
                this.typelst.forEach((item) => {
                    if ((item.typename.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.typelst1.push({ "id": item.id, "typename": item.typename, "type": item.type, "txtselect": null, "txtserch": null });
                    }
                });
                this.typelst = this.typelst1;
            }
        }
    }

    btnnew() {
        this.router.navigate(['/newDefineTypeMaster']);
    }

    btnedit(id) {
        this.router.navigate(['/editDefineTypeMaster', id]);
    }

    getAlltypesMaster() {
        this.Masterservice.getAlltypesMaster(this.user)
            .subscribe(value => {
                this.typelst = value;
                this.typelst1 = value;
            });
    }

    btndelts(value) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.user.trnid = value
            this.Masterservice.deleteType(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
    }

}