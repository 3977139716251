<app-header></app-header>
<div class="container" style="width: 80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Purchase Orders</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="col l12 m12 s12">
                <div class="row">

                    <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.suppliercheckselect" #suppliercheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Supplier
                    </div>
                    <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.statuscheckselect" #statuscheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Status
                    </div>

                    <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.datecheckselect" #datecheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Date Range
                    </div>
                    <div class="col s2"></div>
                    <div class="input-field col l2 m2 l2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.ponocheckselect" #ponocheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;PO Number
                    </div>


                </div>
             <div class="row">
                    <div class="input-field col s2 l2 m2" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <!-- <div class="input-field col s2 l2 m2" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.txtserchstatus" #txtserchsta="ngModel" type="text" class=""
                            id="searchstatus" data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div> -->
                    <div class="input-field col s2" style="margin-bottom: 0px;">
                        <select (change)="onSelectSrch($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s2 m2 l2" style=" margin-top: 12px;margin-bottom: 0px;">
                        <input type="text" placeholder=" Start Date" [(ngModel)]="model.startdate" id="start"
                            autocomplete="off" data-toggle="tooltip" data-placement="top" title="Start Date"
                            class="form-control" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                            (ngModelChange)="selectStartDate(model.startdate)">

                    </div>
                    <div class="input-field col s2 m2 l2" style=" margin-top: 12px;margin-bottom: 0px;">

                        <input type="text" placeholder="End Date" [(ngModel)]="model.enddate" id="start"
                            data-toggle="tooltip" data-placement="top" title="End Date" class="form-control"
                            autocomplete="off" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                            (ngModelChange)="selectEndDate(model.enddate)">
                    </div>
                    <div class="input-field col s2 l2 m2" style=" text-align: left; margin-bottom: 0px;">
                        <input [(ngModel)]="model.txtserchpono" #txtserchpo="ngModel" type="text" class="" id="searchpo"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search For PO Number</label>
                    </div>
                    <div class="col  s2 l2 m2" style=" margin-top: 30px;"> <span><img
                                src="../../../assets/images/search.png" (click)="btnsearch()" data-toggle="tooltip"
                                data-placement="top" title="Edit" width="35px" /></span>
                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s12 l12 m12"
                        style="margin-top: 0px;text-align: right;padding-right: 0px;">
                        <button type="button" class="waves-effect waves-light btn" style="margin-right: 0px;"
                            (click)="btnnew()">New Purchase Orders</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12" style="padding-right: 0px;padding-left: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="20%" style="text-align: left">PO Number</th>
                                <th width="20%" style="text-align: left">PO Date</th>
                                <th width="25%" style="text-align: left">Supplier Name</th>
                                <th width="10%" style="text-align: left">Amount</th>
                                <th width="10%" style="text-align: left">Status</th>
                                <th width="15%" style="text-align: left">Actions</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of orderlstall | paginate: { itemsPerPage: 10, currentPage: p }; let i= index">
                                    <td style="text-align: left">{{lst.pono}}</td>
                                    <td style="text-align: left">{{lst.podate | date:'yyyy-MM-dd'}}</td>
                                    <td style="text-align: left">{{lst.vendorname}}</td>
                                    <td style="text-align: left">{{lst.amount}}</td>
                                    <td style="text-align: left">{{lst.postatus}}</td>
                                    <td style="text-align: left">
                                        <span *ngIf="lst.postatus=='Open' || lst.postatus=='Draft'"><img
                                                src="../../../assets/images/edit.png" (click)="btnedit(lst.id)"
                                                data-toggle="tooltip" data-placement="top" title="Amend"
                                                width="20px" /></span>&nbsp;&nbsp;
                                        <span *ngIf="lst.postatus=='Close'"><img src="../../../assets/images/view.png"
                                                (click)="btnview(lst.id)" data-toggle="tooltip" data-placement="top"
                                                title="View" width="20px" /></span>&nbsp;&nbsp;
                                        <span *ngIf="lst.postatus=='Open' || lst.postatus=='Draft'"><img
                                                src="../../../assets/images/Close.png" (click)="btnclose(i,lst.id)"
                                                data-toggle="tooltip" data-placement="top" title="ForceClose"
                                                width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>