<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Marketing Cost Head % Allocation Template</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow"> 
            <div class="row">
                <div class="l12 m12 s12">
                    <div class="input-field col s3 l3 m3" style="text-align: left;padding-left: 14px;">
                        <span>Search Criteria</span>
                        <select (change)=" onSelectItem($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center;margin-top:30px;">
                        <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Text">
                        <label for="search">Search Text</label>
                    </div>
                    <div class=" input-field col s3 l3 m3" style="margin-top: 30px">
                        <button type="button" (click)="btnsearch($event)" class="waves-effect waves-light btn"
                            style="margin-left: 5px;">Go</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnNew($event)" class="waves-effect waves-light btn">New</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="7%" style="text-align: left">Sr.No</th>
                                    <th width="15%" style="text-align: left;padding-left: 10px;">Marketing Cost Head
                                    </th>
                                    <th width="29%" style="text-align: left">Percentage</th>
                                    <th width="8%" style="text-align: center">Action</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of mrklst | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                        <td style="text-align: left;padding-left: 10px;">{{lst.costhead}}</td>
                                        <td style="text-align: left">{{lst.percentallocate}}</td>
                                        <td style="text-align: center">
                                            <span><img src="../../../assets/images/edit.png" (click)="btnedit(lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Edit"
                                                    width="20px" /></span>&nbsp;&nbsp;
                                            <span><img src="../../../assets/images/delete.png"
                                                    (click)="btndelts(lst.id)" data-toggle="tooltip"
                                                    data-placement="top" title="Delete" width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>