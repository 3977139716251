export class ReferencersReportModel {
    id: number;
    frmdate: Date;
    todate: Date;
    resourceID: number;
    name: String;//region
    resourcename: string;
    referenceintlst: number[];
    txtselect: string;
    searchtype: string;
    companyname: string;
    companyaddress: string;
    opportunityName: string;
    customerName: string;
    owner: string;
    currentStage: string;
    status: string;
    date: Date;
    userid: number;
    sourcedetailID:number;
    sourceID:number;
    instStatus: string;

}