import { DropDownModel } from "src/app/_models/DropDownModel";

export class DefineMarketingModel{
    
    id: number;
    marketingcostid: number;
    code: string;
    description: string;
    mrklst: DropDownModel[];
    costhead:string;
    ulst:DropDownModel[];
    uomid: number;
    userid:number;
    rate:number;
    chkselectvalue:boolean = false;
    name:string;

}
