import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateInstallationModel } from "../Transactions-Installation/CreateInstallation/CreateInstallationModel";
import { InstallationDCRModel } from "../Transactions-Installation/DCR/InstallationDCRModel";
import { SearchInstallationDCRModel } from "../Transactions-Installation/DCR/SearchInstallationDCRModel";
import { InstallationDashbordModel } from "../Transactions-Installation/InstallationsDashboard/InstallationsDashbordModel";
import { ProspectModel } from "../Transactions/Sales-Transaction/ProspectModel";
import { User } from "../_models";
import { SaveSuccessModel } from "../_models/SaveSucessModel";
import { common } from "./Common";
import { HandleError, HttpErrorHandler } from "./http-error-handler.service";

@Injectable()
export class InstallationService {

  private headers = new Headers({
    'Token': localStorage.getItem('Token'),
    'dbName': localStorage.getItem('dbName')
  });
  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
  }

  
  getinstallationlst(user: User): Observable<InstallationDashbordModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<InstallationDashbordModel[]>(this.customersUrl + 'api/installation/getinstallationlst', user, httpOptions1);
  }
  getinstallationdetails(user: InstallationDashbordModel): Observable<CreateInstallationModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
         'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CreateInstallationModel>(this.customersUrl + 'api/installation/getinstallationdetails', user, httpOptions1);
  }

  
  
  CreateInstallation(user: InstallationDashbordModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/installation/CreateInstallation', user, httpOptions1);
  }

  btnclosecall(user: InstallationDashbordModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/installation/btnclosecall', user, httpOptions1);
  }
  

  Getinstallationbyresource(user: User): Observable<SearchInstallationDCRModel[]> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SearchInstallationDCRModel[]>(this.customersUrl + 'api/installation/Getinstallationbyresource', user, httpOptions1);
  }

  GetinstallationDCRdetails(user: User): Observable<InstallationDCRModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<InstallationDCRModel>(this.customersUrl + 'api/installation/GetinstallationDCRdetails', user, httpOptions1);
  }
  
  saveinstalltionDCR(user: InstallationDCRModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/installation/saveinstalltionDCR', user, httpOptions1);
  }

  geteditinstalltion(user: CreateInstallationModel): Observable<CreateInstallationModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CreateInstallationModel>(this.customersUrl + 'api/installation/geteditinstalltion', user, httpOptions1);
  }
  getdispatchprodcut(user: InstallationDashbordModel): Observable<CreateInstallationModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
         'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<CreateInstallationModel>(this.customersUrl + 'api/installation/getdispatchprodcut', user, httpOptions1);
  }
  closecall(user: InstallationDashbordModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/installation/closecall', user, httpOptions1);
  }
  verifyClosureOTP(user: ProspectModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/installation/verifyClosureOTP', user, httpOptions1);
  }
  genrateOTP(user: InstallationDCRModel): Observable<SaveSuccessModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*', 
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/installation/genrateOTP', user, httpOptions1);
  }
  
}
