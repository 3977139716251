import { TemplateRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { User } from "src/app/_models/user";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { CorporateCustProcurementSmryModel } from "./CorporateCustProcurementSmryModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'CorporateCustProcurementSmry.html'
})
export class CorporateCustProcurementSmry implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {

    }
    model = new CorporateCustProcurementSmryModel();
    user = new User();
    edit: boolean = false;
    lstindustry: DropDownModel[] = [];
    lstcustomertype: DropDownModel[] = [];
    corporatelst: CorporateCustProcurementSmryModel[] = [];
    companyname: string;
    companyaddress: string;

    ngOnInit(): void {
        this.getcustomertype();
        this.getIndustrytype();
    }
    selectToDate(date) {
        if (date > new Date()) {
            this.notifyService.showRroor("Future date Cannot be Select", "oops");
            this.model.todate = new Date()
            return;
        }
    }
    getcustomertype() {
        this.lstcustomertype = [];
        let user = new User();
        this.lstcustomertype.push({ id: 0, name: "Select Customer Type" });
        //user.sendstring = "prospect";
        this.Masterservice.getAllCustomertypes(user)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstcustomertype.push({ id: item.id, name: item.name });
                });
            });
    }
    onSelectcustmertype(id) {
        this.model.customertypeID = id;
        this.user.trnid = id;
        this.Masterservice.getAlldataBytypeid(this.user)
            .subscribe(value => {
                this.model.industrytypename = value.industrytypename;
                this.model.industrytypeID = value.industryid;

            });

    } 
    getIndustrytype() {
        this.lstindustry = [];
        let user = new User();
        this.lstindustry.push({ id: 0, name: "Select Industry" });
        this.Masterservice.getindustorylst(user)
            .subscribe(data => {
                for (let i = 0; i < data.industorylst.length; i++) {
                    this.lstindustry.push({
                        id: data.industorylst[i].id,
                        name: data.industorylst[i].name,
                    })
                }
            });
    }
    onSelectindustry(id) {
      
        this.model.corporateintlst = []
        this.model.industrytypeID = id;
        // alert(this.model.industrytypeID)
        this.model.corporateintlst.push(this.model.industrytypeID)
    }

    btnshow(CorporateSmryReport: TemplateRef<any>) {
        if (this.model.frmdate == null) {
            this.notifyService.showRroor(" Please select From Date !!", "Oops!");
            return;
        }
        if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
            return;
        }
        this.reportServices.showCorporateSmry(this.model)
            .subscribe(
                data => {
                    this.corporatelst = data;
                    this.companyname = this.corporatelst[0].companyname;
                    this.companyaddress = this.corporatelst[0].companyaddress;
                    this.modalRef = this.modalService.show(CorporateSmryReport, {
                        class: 'modal-lg'
                    });
                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
}
