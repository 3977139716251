import { OnInit, Component } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { SearchPaymentReceivableReceiptModel } from './SearchPaymentReceivableReceiptModel';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router,NavigationExtras} from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models/user';
@Component({
    selector: 'app-SearchPaymentReceivableReceipt',
    templateUrl: 'SearchPaymentReceivableReceipt.html',
})
export class SearchPaymentReceivableReceipt implements OnInit {

    constructor(private masterservice: MasterServices, private transactionservice: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
    }

    searchtype: DropDownModel[] = [
        { "id": 0, "name": "All" },
        { "id": 1, "name": "Party Name" },
        { "id": 2, "name": "Payment Number" },
        { "id": 3, "name": "Payment Type" },
    ];
    paymentreceipts: SearchPaymentReceivableReceiptModel[] = []
    paymentreceipts1: SearchPaymentReceivableReceiptModel[] = []
    p: any;
    model = new SearchPaymentReceivableReceiptModel();
    user = new User()
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getAllPaymentReceipts();
        
    }

    onSelect(value) {
        this.model.txtselect = value;
    }

    getAllPaymentReceipts() {
        this.paymentreceipts = []
        this.transactionservice.getAllPaymentReceipts(this.user)
            .subscribe(
                data => {
                    this.paymentreceipts = data
                    this.paymentreceipts1 = data
                });
    }

    btnnew($event) {
        this.router.navigate(['/newPaymentRec']);
    }


    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllPaymentReceipts()
            this.model.txtserch = null;
        }
        else if(this.model.txtselect == "Party Name")
        {
            if(this.model.txtserch == "" || this.model.txtserch == null)
            {
                alert("Enter Criteria To Search")
                return;
            }
            else
            {
                this.paymentreceipts1 = [];
                this.paymentreceipts.forEach((item) => {
                    if ((item.partyname.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.paymentreceipts1.push({ "id": item.id,"srno":item.srno,"partyname":item.partyname, "paymentNo":item.paymentNo, "paymenttype": item.paymenttype, "paymentDate": item.paymentDate, "txtserch": null,"userid":null,"txtselect":null });
                    }
                });
                this.paymentreceipts = this.paymentreceipts1;
            }
        }
        else if(this.model.txtselect == "Payment Number")
        {
            if(this.model.txtserch == "" || this.model.txtserch == null)
            {
                alert("Enter Criteria To Search")
                return;
            }
            else
            {
                this.paymentreceipts1 = [];
                this.paymentreceipts.forEach((item) => {
                    if ((item.paymentNo.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.paymentreceipts1.push({ "id": item.id,"srno":item.srno,"partyname":item.partyname, "paymentNo":item.paymentNo, "paymenttype": item.paymenttype, "paymentDate": item.paymentDate, "txtserch": null,"userid":null,"txtselect":null });
                    }
                });
                this.paymentreceipts = this.paymentreceipts1;
            } 
        }
        else if(this.model.txtselect == "Payment Type")
        {
            if(this.model.txtserch == "" || this.model.txtserch == null)
            {
                alert("Enter Criteria To Search")
                return;
            }
            else
            {
                this.paymentreceipts1 = [];
                this.paymentreceipts.forEach((item) => {
                    if ((item.paymenttype.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.paymentreceipts1.push({ "id": item.id,"srno":item.srno,"partyname":item.partyname, "paymentNo":item.paymentNo, "paymenttype": item.paymenttype, "paymentDate": item.paymentDate, "txtserch": null,"userid":null,"txtselect":null });
                    }
                });
                this.paymentreceipts = this.paymentreceipts1;
            } 
        }
    }
    // btnview(id, paymentreceipt) {
    //     this.router.navigate(['/editpaymentreceipt',id]);
    // }
    btnview(id, paymentreceipt) {   
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "paymentreceipt": paymentreceipt,
                "flag": "view",
            }
        };
        this.router.navigate(['/editpaymentreceipt'], navigationExtras);
    }




    btndelts(id)
    {

    }
}