<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8" style="text-align: center;">
                <span style="font-size: 25px;">Material Inventory Home</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">

            <div class="row">
                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 12px;padding-left: 14px;"
                    *ngIf="model.id==null">
                    <span>Item Category</span>
                    <select (change)="onSelectitemCat($event.target.value)">
                        <option selected>Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id"
                            [selected]="type.id == model.itemcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 12px;"
                    *ngIf="model.id==null">
                    <span>Sub Category</span>
                    <select (change)="onSelectSubCat($event.target.value)">
                        <option selected>Select Sub Category</option>
                        <option *ngFor="let type of subCatlst" [value]="type.id"
                            [selected]="type.id == model.itemsubcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class=" input-field col s4" style="margin-top: 29px;margin-bottom: 0px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.itemname" #itemname="ngModel" id="itmname"
                        placeholder="Item Name"  (keyup)="getItemLike($event)" />
                    <input type="hidden" id="acc-id" />
                </div>

                <!-- <div class="ng-autocomplete pinRowgrp input-field col s4" style="margin-top: 29px;margin-bottom: 0px;">
                    <ng-autocomplete [data]="userList2" type="text" placeholder="Item Name" [searchKeyword]="keyword1"
                        [minQueryLength]="3" [(ngModel)]="model.itemname" id="itmname"
                        (inputChanged)='onChangeSearchmaterialinventory($event)' (selected)='selectEvent($event)'
                        (inputFocused)='onFocusedforSearchmaterialinventory($event)' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.itemname"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>

                </div> -->
                <div class="row col s2" style="text-align: right;padding-top: 29px;">
                    <button type="button" (click)="btnSearch(itemnamelst)"
                        class="waves-effect waves-light btn">Search</button>
                </div>
            </div>
            
            <div class="row">
                <div class="col s4" style="margin-top:20px">
                    <div class="row" style="padding-left: 14px;">
                        <tree-root [focused]="true" [options]="options" [nodes]="nodes" style="font-size: 17px;">
                            <ng-template #treeNodeTemplate let-node="node" style="font-size: 17px;">
                                <span [(ngModel)]="node.data.id" ngDefaultControl (click)="onClick(node.data.id)">{{node.data.name}}</span>
                            </ng-template>
                        </tree-root>
                    </div>
                </div>

                <div class="col s8 m8 l8 registerBgShadow">
                    <div class="col l12 m12 s12" style="background: gainsboro;">
                    </div>
                    <mat-accordion>
                        <mat-expansion-panel
                            *ngFor="let lst of itemnamelst| paginate: { itemsPerPage: 10, currentPage: p } ;let i = index "
                            style="padding-left: 1px;">
                            <mat-expansion-panel-header>
                                <div class="col l12 m12 s12">
                                    <div class=" col l8 m8 s8" style="text-align:left;">
                                        <span>{{lst.itemname}}</span>
                                    </div>
                                    <div class=" col l4 m4 s4" style="text-align:left;">
                                        <span>In Stock : {{lst.stockforsale + "&nbsp;&nbsp; "+ lst.uom}}</span>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>

                            <div *ngIf="lst.locationlst" class="registerBgShadow col l12 m12 s12"
                                style="text-align: center; margin-top: 10px;">
                                <div class="col l12 m12 s12">
                                    <div class="col l8 m8 s8" style="text-align:left;">
                                        <span>Location Name</span>
                                    </div>
                                    <div class="col l4 m4 s4" style="text-align:left;">
                                        <span>Stock for sale</span>
                                    </div>
                                </div>
                                <div class="row" *ngFor="let sublst of lst.locationlst">
                                    <div class="col l8 m8 s8" style="text-align:left;margin-top:12px;">
                                        <span>{{sublst.locationname}}</span>
                                    </div>
                                    <div class="col l4 m4 s4" style="text-align:left;margin-top:12px">
                                        <span>{{sublst.stockforsale + "&nbsp;&nbsp; "+ sublst.uom}}</span>
                                    </div>

                                </div>

                            </div>
                            <div *ngIf="lst.locationlst == null" class="col l11 m11 s11 registerBgShadow"
                                style="margin-left:50px;text-align: center;">
                                <div class="col l12 m12 s12" style="color: red">
                                    <samp> List Not Found </samp>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>