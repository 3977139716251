<app-header></app-header>
<div class="container" style="width: 80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Purchase Orders</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">

            <div class="col s12 l12 m12">
                <div class="tabtab">
                    <button class="tablinks" id="defaulttab" onclick="openCity(event, 'Details')">Details
                    </button>
                    <button class="tablinks" id="Term" (click)="btntermcon()" onclick="openCity(event, 'Terms')">Terms And
                        Conditions</button>

                </div>
            </div>

            <div id="Details" class="tabcontent col l12 m12 s12 registerBgShadow">
                <div class="col l12 m12 s12">
                    <div class="row">

                        <!-- <div class=" input-field col s5" style="margin-top: 20px;margin-bottom: 0px;">
                            <span>Supplier Name :</span>
                            <input type="text" class="autocomplete" [(ngModel)]="model.vendorname" id="vendorname1"
                                placeholder="Suplier Name" (keyup)="getVendorLike($event)"
                                [disabled]="this.action=='view'" [disabled]="this.action=='edit'|| this.action=='view'">
                            <input type="hidden" id="acc-id" />
                        </div> -->

                        <div class="ng-autocomplete pinRowgrp input-field col s4"
                            style="margin-top: 22px;margin-bottom: 0px;" >
                            <b><label>Supplier Name<span  style="color:blue"> &nbsp;</span></label></b>
                            <ng-autocomplete [data]="userListVendor" type="text" placeholder="Supplier Name"
                                [searchKeyword]="keyword2" [minQueryLength]="3" [(ngModel)]="model.vendorname"
                                id="vendorname1" (inputChanged)='onChangeSearchVendor($event)'
                                (selected)='selectEventVendor($event)' (inputFocused)='onFocusedforvendor($event)'
                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.vendorname"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>

                        </div>


                        <div class=" input-field col s5 m5 l6" style="margin-top: 22px;margin-bottom: 0px;">
                            <b><span>Dispatch Address</span> <span style="color:blue">&nbsp;</span></b>
                            <!-- <textarea id="textarea1" title=" Address" class="materialize-textarea"
                                [(ngModel)]="model.dispatchaddress"
                                [disabled]="this.action=='edit' || this.action=='view'" #dispatchaddress="ngModel"
                                id="Address" style="margin-bottom: 0px;"></textarea> -->
                            <input type="text" title=" Address" [(ngModel)]="model.dispatchaddress"
                                [disabled]="this.action=='edit' || this.action=='view'" #dispatchaddress="ngModel"
                                id="Address" style="margin-bottom: 0px;grid-auto-rows: auto;">
                            <label id="lable3" for=""></label>
                        </div>

                        <div class="input-field col s2 m2 l2" style=" margin-top: 22px;margin-bottom: 0px;">
                            <b><span>PO Date</span><span style="color:blue">&nbsp;</span></b>
                            <input type="text" placeholder=" Po Date" [(ngModel)]="model.podate" id="start"
                                autocomplete="off" data-toggle="tooltip"
                                [disabled]="this.action=='edit'|| this.action=='view'" data-placement="top"
                                title="Po Date" class="form-control" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                                (ngModelChange)="selectPODate(model.podate)">

                        </div>

                    </div>
                    <div class="row">
                        <div class=" input-field col s4">
                            <b><span>Address :-</span></b>
                            <span>&nbsp;&nbsp;{{model.address}}</span>
                        </div>

                    </div>


                    <div class="row">
                        <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 30px;"
                            *ngIf="model.id==null">
                            <b><span>Item Category</span></b>
                            <select (change)="onSelectitemCat($event.target.value)">
                                <option selected>Select Item Category</option>
                                <option *ngFor="let type of itmCatlst" [value]="type.id"
                                    [selected]="type.id == model.itemcategoryid">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 30px;"
                            *ngIf="model.id==null">
                            <b><span>Sub Category</span></b>
                            <select (change)="onSelectSubCat($event.target.value)">
                                <option selected>Select Sub Category</option>
                                <option *ngFor="let type of subCatlst" [value]="type.id"
                                    [selected]="type.id == model.itemsubcategoryid">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <!-- <div class=" input-field col s4" style="margin-top: 40px;margin-bottom: 0px;"
                            *ngIf="model.id==null">
                            <input type="text" class="autocomplete" [(ngModel)]="model.itemname" id="itmname"
                                placeholder="Item Name" (keyup)="getItemLike($event)" />
                            <input type="hidden" id="acc-id" />
                        </div> -->
                        <div class="ng-autocomplete pinRowgrp input-field col s4"
                            style="margin-top: 40px;margin-bottom: 0px;" *ngIf="model.id==null">
                            <ng-autocomplete [data]="userList" type="text" placeholder="Item Name"
                                [searchKeyword]="keyword1" [minQueryLength]="3" [(ngModel)]="model.itemname"
                                id="itmname" (inputChanged)='onChangeSearchitem($event)'
                                (selected)='selectEvent($event)' (inputFocused)='onFocusedforitem($event)'
                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.itemname"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>

                        </div>

                        <div class="row col s2" style="text-align: right;padding-top: 40px;" *ngIf="model.id==null">
                            <button type="button" (click)="btnadd()" class="waves-effect waves-light btn">Add</button>

                        </div>
                    </div>

                    <div class="row">
                        <div class="row">
                            <div class="col l12 m12 s12">
                                <div class="col l12 m12 s12" style="padding-left: 0px;">
                                    <table>
                                        <thead style="background: gainsboro;">
                                            <th width="25px" style="text-align: center">Item Code</th>
                                            <th width="25px" style="text-align: center">Item Name</th>
                                            <th width="10px" style="text-align: center">HSN</th>
                                            <th width="12px" style="text-align: center">Unit</th>
                                            <th width="5px" style="text-align: center">GST(%)</th>
                                            <th width="3px" style="text-align: center">Qty</th>
                                            <th width="3px" style="text-align: center">Rate</th>
                                            <th width="12px" style="text-align: center">Amount</th>
                                            <th width="5px" style="text-align: center">Action</th>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let lst of vendorlist | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                                                <td style="text-align: center">{{lst.itemcode}}</td>
                                                <td style="text-align: center">{{lst.itemname}}</td>
                                                <td style="text-align: center">{{lst.hsnCode}}</td>
                                                <td style="text-align: center">{{lst.uom}}</td>
                                                <td style="text-align: center">{{lst.gstGrouppercentage}}</td>
                                                <td style="text-align: center"> <input [(ngModel)]="lst.quantity"
                                                        #quantity="ngModel" type= 'number' class=""
                                                        [disabled]="this.action=='view'"
                                                        oninput="validity.valid||(value='');" min="1" id="quantity"
                                                        data-toggle="tooltip" data-placement="top" title="quantity"
                                                        (ngModelChange)="onChangeOfqantity()" style="margin-top:1px;">
                                                </td>
                                                <td style="text-align: center"> <input [(ngModel)]="lst.rate"
                                                        #rate="ngModel" type="number" step="0.01"
                                                        oninput="validity.valid||(value='');" min="0.0" class="" id="rate"
                                                        data-toggle="tooltip" [disabled]="this.action=='view'"
                                                        (ngModelChange)="onChangeOfrate()" data-placement="top"
                                                        title="Rate" style="margin-top:1px;"></td>
                                                <td style="text-align: center">{{lst.amount | number:'1.2-2'}}</td>
                                                <td style="text-align: center"><span *ngIf="this.action!= 'view'"><img
                                                            src="../../../assets/images/delete.png"
                                                            (click)="btndelete(lst.id,i)" data-toggle="tooltip"
                                                            data-placement="top" title="Delete" width="20px" /></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span style="text-align:right">
                                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s10"> </div>
                        <div class="col l2 m2 s2" style="margin-top: 30px;">
                            <span style="font-size: 15px; font-weight: bold">Net Amount:&nbsp;<span>{{netamount |
                                    number:'1.2-2'
                                    }}</span>
                            </span>
                        </div>
                    </div>


                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align: right">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event) " class="waves-ripple waves-light btn"
                                style="margin-left: 5px;">Save</button>&nbsp;&nbsp;
                            <!-- <button class="waves-ripple waves-light btn" (click)="btntermcon()"  [disabled]="this.action=='view'"
                               >Next - Terms
                                And
                                Conditions</button>  onclick="openCity(event, 'Terms')"-->
                        </div>
                    </div>
                    <!-- (click)="btntermcon()" -->

                </div>
            </div>


            <div id="Terms" class="tabcontent col l12 m12 s12 registerBgShadow">
                <div class="col l12 m12 s12">
                    <div class="input-field  col s10 l10 m10" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.termcondition" #termcondition="ngModel" type="text" id="Terms"
                            data-toggle="tooltip" data-placement="top" title="Terms Condtion"
                            [disabled]="this.action=='view'">
                        <label id="lable2" for="First2">Terms Condtion</label>
                    </div>
                    <div class="row col s2" style="text-align: left;padding-top: 30px;">
                        <button type="button" (click)="btnaddterm()" [disabled]="this.action=='view'"
                            class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;
                    </div>
                    <div class="row">

                        <div class="col l12 m12 s12" style="height: 400px; overflow: auto;" style="margin-top: 25px;">
                            <div class="col l12 m12 s12">
                                <span>Terms & Conditions</span>
                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="5%" style="text-align: center"> </th>
                                        <th width="80%" style="text-align: left"> </th>

                                        <th width="15%" style="text-align: center"> </th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of orderlstall;let i = index">
                                            <td style="text-align: center;"><input type="checkbox"
                                                    [(ngModel)]="lst.checkselect" #checkselect="ngModel"></td>
                                            <td style="text-align: left">{{lst.termcondition}}</td>
                                            <td style="text-align: center"> <span
                                                    *ngIf="lst.categorytermconid != null"><img
                                                        src="../../../assets/images/edit.png"
                                                        [disabled]="this.action=='view'"
                                                        (click)="btnopenmodel(i,lst.categorytermconid,lst.potermsid,termmodel)"
                                                        data-toggle="tooltip" data-placement="top" title="Edit"
                                                        width="20px" /></span>&nbsp;&nbsp;
                                                <span
                                                    *ngIf="lst.categorytermconid == null || this.action != 'view'"><img
                                                        src="../../../assets/images/delete.png"
                                                        (click)="btndeleteterms(lst.id,i)" data-toggle="tooltip"
                                                        data-placement="top" [disabled]="this.action=='view'"
                                                        title="Amend" width="20px" /></span>&nbsp;&nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align: right">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)="btncanclepo()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsavepoterm($event)" class="waves-ripple waves-light btn"
                                style="margin-left: 5px;" [disabled]="this.action=='view'">Save</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnfinalize($event)" class="waves-ripple waves-light btn"
                                style="margin-left: 5px;" [disabled]="this.action=='view'">Finalize</button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #termmodel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-content registerBgShadow " style="text-align: center;">
        <h4>Terms And Conditions</h4>
        <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
            <div class="row">
                <input [(ngModel)]="model.termcondition" #termcondition="ngModel" type="text" id="first10"
                    data-toggle="tooltip" data-placement="top" title="Termscondtion" [disabled]="this.action=='view'">
                <label id="lable10" for="first10">Termscondtion </label>
            </div>
        </div>
        <div class="row col s12" style="text-align: center;padding-top: 16px;">
            <button type="button" (click)="btnsaveterm()" [disabled]="this.action=='view'"
                class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;&nbsp;
        </div>
    </div>

</ng-template>