<app-header></app-header>
<div class="container" style="width:80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Reassign Enquiry</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="input-field col l3 m3 s3">
                    <span>Select Team</span>
                    <select (change)="onSelectTeam($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Team" data-toggle="tooltip" data-placement="top" title="Branches">
                        <option Selected value="0">Select Team</option>
                        <option *ngFor="let lst of teamlst" [value]="lst.id">
                            {{lst.teamcode}}
                            <!-- *ngFor="let lst of branchlst" [value]="lst.id" [selected]="lst.id==model.branchid"
                            {{lst.name}} -->
                        </option>
                    </select>
                </div>
                <div class="input-field col l4 m4 s4" style="margin-top: 25px; margin-bottom: 0px; font-size: 15px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.resourcename" id="resourcename"
                        (keyup)="getresourcename($event)" data-toggle="tooltip" data-placement="top"
                        title="Resource Name" />
                    <input type="hidden" id="acc-id" />
                    <label class="active" for="resourcename">Resource Name</label>
                </div>
                <div class="col s2"></div>
                <div class="input-field col l4 m4 s4" style="margin-top: 17px; margin-bottom: 0px; font-size: 15px;">
                    <div class="input-field s4">
                        <select (change)="onSelectAssignTo($event.target.value)">
                            <option selected>Select Assign To</option>
                            <option *ngFor="let type of lstassigned" [value]="type.id"
                                [selected]="type.id==model.assignedtoID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <label class="active" for="resourcename">Assign To</label>
                </div>
            </div>
            <div class="row">
                <div class="row">
                    <div class="col s9"><span
                            style="font-size: 17px;font-weight: bold;padding-left: 9px;">Opportunities</span>

                        <!-- (click)="openOverduemodel(overduemodel)" -->
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20px" style="text-align: left;padding-left: 10px;">Enquiry Number</th>
                                    <th width="25px" style="text-align: left">Date</th>
                                    <th width="20px" style="text-align: left">Customer name</th>
                                    <th width="15px" style="text-align: left">Stage</th>
                                    <th width="20px" style="text-align: left">Assign To</th>
                                </thead>
                                <tbody>
                                    <!-- *ngFor="let lst of lstopportunitylst; let i = index" -->
                                    <tr
                                        *ngFor="let lst of model.lstopportunitylst | paginate: { itemsPerPage: 10, currentPage: p, id:'pagination1'}; let i = index">
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.enquiryName}}</p>
                                        </td>
                                        <td
                                            style="text-align: left;padding-top: 0px;padding-bottom: 0px;padding-right: 20px;">
                                            {{lst.dCRDate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customerName}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.stage}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <div class="input-field col s12">
                                                <select (change)="onSelectAssignToforSales($event.target.value,i)">
                                                    <option selected>Select Assign To</option>
                                                    <option *ngFor="let type of lstassigned" [value]="type.id"
                                                        [selected]="type.id==lst.assignedtoID">
                                                        {{type.name}}</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls id="pagination1" (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Service Call</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">

                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="25" style="text-align: left;padding-left: 10px;">Service Call #</th>
                                    <th width="25" style="text-align: left">Date</th>
                                    <th width="10" style="text-align: left">Customer name</th>
                                    <th width="25" style="text-align: left">Status</th>
                                    <th width="25" style="text-align: left">Assign To</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of model.servicecalllst | paginate: { itemsPerPage: 10, currentPage: q, id:'pagination2'}; let j = index">
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.servicecallno}}</p>
                                        </td>
                                        <td
                                            style="text-align: left;padding-top: 0px;padding-bottom: 0px;padding-right: 20px;">
                                            {{lst.callDate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customername}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.status}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <div class="input-field col s12">
                                                <select (change)="onSelectAssignToforService($event.target.value,j)">
                                                    <option selected>Select Assign To</option>
                                                    <option *ngFor="let type of lstassigned" [value]="type.id"
                                                        [selected]="type.id==lst.assignedtoID">
                                                        {{type.name}}</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls id="pagination2" (pageChange)="q = $event"></pagination-controls>
                            </span>
                        </div>

                    </div>
                    <div class="col s3">

                    </div>

                </div>
                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Installation</span><span
                            style="font-size: 15px;margin-left: 80%;"> </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">

                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">


                                    <th width="25" style="text-align: left;padding-left: 10px;">OA / Approval Number
                                    </th>
                                    <th width="25" style="text-align: left">Date</th>
                                    <th width="10" style="text-align: left">Customer name</th>
                                    <th width="25" style="text-align: left">Status</th>
                                    <th width="25" style="text-align: left">Assign To</th>

                                </thead>
                                <tbody>

                                    <tr *ngFor="let lst of model.installationlst | paginate: { itemsPerPage: 10, currentPage: r, id:'pagination3'}; let k = index">

                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.oanumber}}</p>
                                        </td>
                                        <td
                                            style="text-align: left;padding-top: 0px;padding-bottom: 0px;padding-right: 20px;">
                                            {{lst.callDate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customername}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.status}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <div class="input-field col s12">
                                                <select (change)="onSelectAssignToforInstallation($event.target.value,k)">
                                                    <option selected>Select Assign To</option>
                                                    <option *ngFor="let type of assignlst" [value]="type.name">
                                                        {{type.name}}</option>
                                                </select>
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls id="pagination3" (pageChange)="r = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                    <div class="col s3">
                    </div>
                </div>
                <div class="row" style="text-align: right;">
                    <div class="input-field col s10" style="text-align: end;padding-right:0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                            (click)=" cancle()">Cancel</button>&nbsp;
                        <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                            style="margin-right: 5px;">Save</button>
                    </div>
                </div>


            </div>
        </div>

    </div>
</div>