<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">My Order</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="input-field col s3 l3 m3" style="margin-top: 13px">
                    <span>Search Crietria</span>
                    <select (change)="onSelectuser($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Type">
                        <option *ngFor="let type of searchtype" [value]="type.name">
                            {{type.name}} </option>
                    </select>
                </div>
                <div class="input-field col s3 l3 m3" style="text-align: center" style="margin-top: 30px">
                    <input type="text" [(ngModel)]="model.txtserch" #name="ngModel" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search Text</label>
                </div>
                <!-- <div class="col s1 l1 m1">
                </div> -->
                <div class=" input-field col s3 l3 m3" style="margin-top: 30px;text-align: left">
                    <button type="button" (click)="btnsearch($event)"
                        class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnnew()">New</button>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left;padding-left: 10px;">Sr. #</th>
                            <th width="25%" style="text-align: left;padding-left: 10px;">Purchase Order #</th>
                            <th width="15%" style="text-align: left">Date</th>
                            <th width="20%" style="text-align: left">Status</th>
                            <th width="10%" style="text-align: left;">Order Value(&#8377;)</th>
                            <th width="10%" style="text-align: right">Action</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of dealermodelst | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                <td style="text-align: left;padding-left: 10px;">{{lst.dealerPurchesNO}}</td>
                                <td style="text-align: left">{{lst.dateTime | date : "dd-MM-yyyy"}}</td>
                                <td style="text-align: left">{{lst.approvedorderflg}}</td>
                                <td style="text-align:left;"><a data-placement="top" data-toggle="tooltip"
                                        style="color: black;">&#8377;{{lst.value}}</a></td>
                                <td style="text-align: right">
                                    <span
                                        *ngIf="lst.approvedorderflg == 'Closed' || lst.approvedorderflg == 'Approved'">
                                        <img src="../../../assets/images/view.png" (click)="view(History,lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="View" width="20px">
                                    </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span *ngIf="lst.approvedorderflg == 'Pending'"><img
                                            src="../../../assets/images/edit.png" (click)="btnedit(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Edit"
                                            width="20px" /></span>;&nbsp;&nbsp;;&nbsp;&nbsp;;&nbsp;&nbsp;
                                    <span *ngIf="lst.iseditflag == false && lst.approvedorderflg == 'Pending'">
                                        <img src="../../../assets/images/Close.png" (click)="btnclose(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Close" width="20px" />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #History>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-content" style="text-align: center;">
        <h4>Dealer Orders</h4>
        <div class=" col l12 m12 s12" id="LibDocs">


            <table>
                <thead style="background-color: gainsboro;">
                    <th width="25%" style="text-align: left">Product</th>
                    <th width="13%" style="text-align: left">UOM</th>
                    <th width="10%" style="text-align: right">Order Quantity</th>
                    <th width="10%" style="text-align: right">Price</th>
                    <th width="7%" style="text-align: right">Discount</th>
                    <th width="7%" style="text-align: right">GST %</th>
                    <th width="10%" style="text-align: right">GST Amount</th>
                    <th width="10%" style="text-align: right;padding-right: 10px;">Value</th>
                </thead>
                <tbody>
                    <tr *ngFor="let lst of modellst">
                        <td style="text-align: left;padding-left: 10px;">{{lst.prouctname}}</td>
                        <td style="text-align: end">{{lst.uom}}</td>
                        <td style="text-align: end">{{lst.quantity}}</td>
                        <td style="text-align: end">{{lst.minimumPrice | number:'1.2-2'}}</td>
                        <td style="text-align: end">{{lst.discount | number:'1.2-2' }}</td>
                        <td style="text-align: end">{{lst.gstrate | number:'1.2-2'}}</td>
                        <td style="text-align: end">{{lst.gstamount | number:'1.2-2'}}</td>
                        <td style="text-align: end;padding-right: 10px;">{{lst.value | number:'1.2-2'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>