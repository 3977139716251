export class MaterialInventoryHomeModel{
    id:number;
    itemcategoryid:number;
    itemsubcategoryid:number;
    itemname:string;
    itemid:number;
    stockforsale:number;
    itemnamelst:MaterialInventoryHomeModel[];
    locationlst:MaterialInventoryHomeModel[];
    txtserch:string;
    txtselect:string;
    locationname:string;
    uom:string;
}

