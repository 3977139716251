<app-header></app-header>
<div class="container" >
  
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Quotation Report</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2" style="padding-top: 9px;">
                    <span>From Date</span>
                    <input type="text" placeholder="From Date" [(ngModel)]="model.frmdate" class="form-control"
                        bsDatepicker (ngModelChange)="selectFromDate(model.frmdate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true,showWeekNumbers: false}">
                </div>
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2" style="padding-top: 9px;">
                    <span>To Date</span>
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" class="form-control"
                        bsDatepicker (ngModelChange)="selectToDate(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true,showWeekNumbers: false }">
                </div>
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l4 m4 s4" style="margin-top: 10px;margin-bottom: 0px;">
                    <span>City</span>
                    <select (change)="onSelectCity($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="City">
                        <option selected value="0">Select City</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of citylst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row col l12 m12 s12" style="padding:0px;margin-top: 5px;">
                <div class="col l1 m1 s1"></div>
                <!-- <div class="input-field col l6 m6 s6" style="margin-top: 5px;margin-bottom: 0px;padding-left: 9px;">
                    <input type="text" class="autocomplete" id="custname" data-toggle="tooltip" data-placement="top"
                        title="Customer Name" style="margin-top:1px;" (keyup)="getcustomerName($event)">
                    <input type="hidden" id="acc-id" />
                    <label for="custname" class="active" style="left: 9px;">Customer Name</label>
                </div> -->
                <div class="ng-autocomplete pinRowgrp input-field col l6 m6 s6"
                    style="margin-top: 5px;margin-bottom: 0px;padding-left: 9px;">
                    <ng-autocomplete [data]="productList2" type="text" placeholder="Customer Name" id="custname"
                        [searchKeyword]="keyword" [minQueryLength]="3" (inputChanged)='onChangeSearch($event)'
                        (selected)='selectEvent($event)' (inputFocused)='onFocused($event)'
                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>

                </div>


                <div class="input-field col l4 m4 s4" style="text-align: end; margin-top: 6px;">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnshow()" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Show</button>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">

                        <table>
                            <thead style="background: gainsboro;">
                                <th width="10%" style="text-align: left;padding-left: 10px;">Sr.#</th>
                                <th width="30%" style="text-align: left;padding-left: 10px;">Quotation Code</th>
                                <th width="53%" style="text-align: left">Account Name</th>
                                <th width="7%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of lstt | paginate: { itemsPerPage: 10, currentPage: p }">
                                    <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                    <td style="text-align: left;padding-left: 10px;">{{lst.quotationCode}}</td>
                                    <td style="text-align: left">{{lst.accountName}}</td>
                                    <td style="text-align: center"> <span> <img src="../../../assets/images/printer.png"
                                                (click)="btnprint(QuotationReport,lst.quotationId)"
                                                data-toggle="tooltip" data-placement="top" title="Print"
                                                width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #QuotationReport>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <!-- <div class="row">
            <div class="input-field col l12 m12 s12" style="text-align:right ">
                <button (click)="modalRef.hide()" type="button"
                    class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
            </div>
        </div> -->
    </div>
    <div class="modal-content registerBgShadow" id="inv">
        <h5 style="text-align: center;">Quotation Report</h5>
        <div class="col l12 m12 s12" style="border: 1px solid black;">
            <div class="row" style="margin: 0px;">
                <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                    <span style="font-size: 16px">{{quoReport.companyname}}</span>
                </div>
            </div>
            <div class="row" style="margin: 0px;">
                <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                    <span style="font-size: 16px">{{quoReport.companyaddress}}</span>
                </div>
            </div>

            <div class="row" style="margin: 0px;">
                <div class="col l12 m12 s12">

                    <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px;"
                        cellpadding="7">
                        <tbody>
                            <tr>
                                <td width="50%">Quotation No:&nbsp;{{quoReport.quotationCode}}</td>
                                <td width="50%">Quotation Date:&nbsp;{{quoReport.quoDate | date:"dd-MM-yyyy"}}</td>
                            </tr>
                            <tr>
                                <td width="50%">Contact Person:&nbsp;{{quoReport.contactPerson}}</td>
                                <td width="50%">Payment Terms:&nbsp;{{quoReport.paymentTerm}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row" style="margin:0px;">
                <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px;"
                    cellpadding="7">
                    <thead>
                        <th style="text-align: left;padding-left: 10px;">Sr.No.</th>
                        <th style="text-align: left">Description of Goods</th>
                        <th style="text-align: left">HSN</th>
                        <th style="text-align: right">Quantity</th>
                        <th style="text-align: right">Rate</th>
                        <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th>
                        <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th>
                        <th style="text-align: right">UOM</th>
                        <!-- <th style="text-align: right">Discount</th> -->
                        <th style="text-align: right;">Total Amount</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of qutReportlst">
                            <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                            <td style="text-align: left">{{lst.name}}</td>
                            <td style="text-align: left">{{lst.hsn}}</td>
                            <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                            <td style="text-align: right">{{lst.amount | number:'1.2-2'}}</td>
                            <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.netamount |
                                number:'1.2-2'}}</td>
                            <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.taxamount |
                                number:'1.2-2'}}</td>
                            <td style="text-align: right">{{lst.uom}}</td>
                            <!-- <td style="text-align: right">{{lst.discount | number:'1.2-2'}}</td> -->
                            <td style="text-align: right;">{{lst.totalproamount | number:'1.2-2'}}
                            </td>
                        </tr>
                        <tr *ngIf="model.gstseperate==true">
                            <td></td>
                            <td style="text-align: right;">Net Amount</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <!-- <td></td> -->
                            <td></td>
                            <td style="text-align: right;">{{quoReport.totalnetamount |
                                number:'1.2-2'}}</td>
                        </tr>
                        <tr *ngIf="model.gstseperate==true">
                            <td></td>
                            <td style="text-align: right;">Tax Amount</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <!-- <td></td> -->
                            <td></td>
                            <td></td>
                            <td style="text-align: right; ">{{quoReport.totaltaxamount |
                                number:'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align: right">Total Amount</td>
                            <td></td>
                            <td></td>
                            <!-- <td></td> -->
                            <td *ngIf="model.gstseperate==true"></td>
                            <td *ngIf="model.gstseperate==true"></td>
                            <td></td>
                            <td></td>
                            <td style="text-align: right;">{{quoReport.totalamount |
                                number:'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" style="margin:0px;border:1px solid black">
                <div class="col l12 m12 s12">
                    <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{quoReport.totalinwords |
                            uppercase}}&nbsp;ONLY</span></span>

                </div>
            </div>
            <div class="row" style="margin:0px;">
                <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                    <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                </div>
            </div>

            <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                <thead style="background-color: gainsboro;">
                    <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                    <th width="70%" style="text-align: left">Description</th>
                </thead>
                <tbody>
                    <tr *ngFor="let lst of reporttandclst">
                        <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                        <td style="text-align: left">{{lst.descrption}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="col l12 m12 s12">
        <div class="row">
            <div class="input-field col l12 m12 s12" style="text-align:right ">
                <button (click)="modalRef.hide()" type="button"
                    class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
            </div>
        </div>
    </div>
</ng-template>