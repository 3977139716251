<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Performance Reprot of Technicians</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l4 m4 s4" style="padding: 0px;">
                    <div class="col l6 m6 s6" style="padding-top: 9px;">
                        <span>From Date</span>
                        <input type="text" placeholder="From Date" [(ngModel)]="model.frmdate" class="form-control"
                            bsDatepicker (ngModelChange)="selectFromDate(model.frmdate)"
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true,showWeekNumbers: false }">
                    </div>
                    <div class="col l6 m6 s6" style="padding-top: 9px;">
                        <span>To Date</span>
                        <input type="text" placeholder="To Date" [(ngModel)]="model.todate" class="form-control"
                            bsDatepicker (ngModelChange)="selectToDate(model.todate)"
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true ,showWeekNumbers: false}">
                    </div>
                </div>
                <div class="input-field col l2 m2 s2" style="margin-top: 10px;margin-bottom: 5px;">
                    <span>Region</span>
                    <select (change)="onSelectRegions($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Regions">
                        <option selected value="0">Select Region</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of regionlst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-top: 10px;margin-bottom: 5px;">
                    <span>Area</span>
                    <select (change)="onSelectArea($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Area">
                        <option selected value="0">Select Area</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of arealst" [value]="lst.areaid">
                            {{lst.areaname}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-top: 10px;margin-bottom: 5px;">
                    <span>City</span>
                    <select (change)="onSelectCity($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="City">
                        <option selected value="0">Select City</option>
                        <!-- <option value="All">All</option> -->
                        <option *ngFor="let lst of citylst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row col l12 m12 s12" style="padding:0px;">
                <!-- <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Customer Type</span>
                    <select (change)="onSelectCustomerType($event.target.value)" data-toggle="tooltip"
                        data-placement="top" data-toggle="tooltip" data-placement="top" title="City">
                        <option selected value="0">Select Customer Type</option>
                        <option *ngFor="let lst of customertyps" [value]="lst.id">
                            {{lst.type}}
                        </option>
                    </select>
                </div> -->
                <div class="input-field col l3 m3 s3" style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Team</span>
                    <select (change)="onSelectTeam($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Branches" data-toggle="tooltip" data-placement="top" title="Team">
                        <option Selected value="0">Select Team</option>
                        <!-- <option value="All">All</option> -->
                        <option *ngFor="let lst of teamlst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <!-- <div class="input-field col l4 m4 s4" style="margin-top: 0px;margin-bottom: 5px;">
                    <span>Resource</span>
                    <select (change)="onSelectResource($event.target.value)" data-toggle="tooltip" data-placement="top"
                        data-toggle="tooltip" data-placement="top" title="Resource">
                        <option selected value="0">Select Resource</option>
                        <option value="All">All</option>
                        <option *ngFor="let lst of resourcelst" [value]="lst.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div> -->
                <div class="input-field col l3 m3 s3" style="text-align: left;margin-top: 30px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.zeroValue" #lostchk="ngModel" type="checkbox"
                        style="text-align: 5px">&nbsp;Suppresed Zero Value
                </div>
                <div class="input-field col l2 m2 s2"
                    style="text-align: right;margin-top: 17px;margin-bottom: 5px;padding: 0px;">
                    <button (click)="btnshow(TeamPerformanceReport)" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Show</button>
                </div>
            </div>
        </div>

        <ng-template #TeamPerformanceReport>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row" id="LibDocs">
                <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                    <div class="modal-content " style="text-align:center;padding: 0px;margin: 24px;">
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>

                            </div>
                        </div>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 16px">{{companyaddress}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <span style="font-size: 20px;text-decoration: underline">Performance Reprot of Technicians</span>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                        <div class="col l12 m12 s12" style="padding-right: 0px;">
                            <div class="input-field col l12 m12 s12"
                                style="text-align: end;padding-right: 0px;margin: 0px;">
                                <span><img src="../../../assets/images/printer.png" (click)="openPrint()"
                                        data-toggle="tooltip" data-placement="top" title="Print"
                                        width="30px" /></span>&nbsp;&nbsp;
                                <span><img src="../../../assets/images/excel2.png" onclick="tableToExcel('excel')"
                                        data-toggle="tooltip" data-placement="top" title="Excel" width="30px" /></span>
                            </div>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7">
                            <tbody>
                                <tr>

                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>From
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.frmdate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>To
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.todate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>
                                            City:-</span>
                                        &nbsp;<span><b>{{cityName}}</b></span></td>
                                    <td width="25%" style="text-align: left;border: 1px solid black;"><span>
                                            Team Name:-</span>
                                        &nbsp;<span><b>{{teamName}}</b></span></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                            id="excel">
                            <thead style="background: gainsboro;">
                                <th width="10%" style="text-align: left">Resource</th>
                                <th width="6%" style="text-align: left">Total Enquiries</th>
                                <th width="6%" style="text-align: right">Open</th>
                                <th width="6%" style="text-align: left"></th>
                                <th width="6%" style="text-align: right">In Progress</th>
                                <th width="6%" style="text-align: left"></th>
                                <th width="6%" style="text-align: right">Rejected</th>
                                <th width="6%" style="text-align: left"></th>
                                <th width="6%" style="text-align: right">Completed</th>
                                <th width="6%" style="text-align: left"></th>

                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of taemPerformancelst">
                                    <td style="text-align: left">{{lst.localName}}</td>
                                    <td style="text-align: right">{{lst.allTotal}}</td>

                                    <td style="text-align: right">{{lst.openTotal}}</td>
                                    <td style="text-align: right">{{lst.openPerc | number:'1.2-2'}} &#37;</td>

                                    <td style="text-align: right">{{lst.inprogress}}</td>
                                    <td style="text-align: right">{{lst.inprogressPerc | number:'1.2-2'}} &#37;</td>

                                    <td style="text-align: right">{{lst.lostTotal}}</td>
                                    <td style="text-align: right">{{lst.lostPerc | number:'1.2-2'}} &#37;</td>

                                    <td style="text-align: right">{{lst.completeTotal}}</td>
                                    <td style="text-align: right">{{lst.completePerc | number:'1.2-2'}} &#37;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col s12 m12 l12" style="text-align: center;margin-top: 12px;">
                        <span style="font-size: 11px;">Report Genrated By: {{username}} on {{timing
                            |date:'medium'}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="padding-right: 0px;">
                    <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="modalRef.hide()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>