import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location, getNumberOfCurrencyDigits, DatePipe } from '@angular/common';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { CallCenterDashboardModel } from './../CallCenterDashboard/CallCenterDashboardModel';
import { OpportunitiesModel } from '../Opportunities/OpportunitiesModel';
import { AccountThreeSixtyModel } from './AccountThreeSixtyModel';
import { DailyCallModel } from '../DailyCall/DailyCallModel';
import { BsModalService } from 'ngx-bootstrap';
declare var $: any;
@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'AccountThreeSixty.html'
})

export class AccountThreeSixty implements OnInit {
    productID: String = null;
    forflag: string;
    edit: boolean = false;
    p: any;
    model: any = {};
    user = new User();
    userList2: any;
    mrpmap = new Map();
    id: number;
    public i;
    modalRef2: any;
    searchstring: string;
    pageno: string;
    constructor(private Masterservice: MasterServices,
        private transactionservice: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private modalService: BsModalService,) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.user.userid = + atob(params["id"]);
            this.searchstring = params["searchstring"];
            this.pageno = params["pageno"];

        })
        if (this.searchstring != null) {
            localStorage.setItem("searchstring", this.searchstring);
        }
        if(this.pageno != null)
        {
            localStorage.setItem("pageno",this.pageno);
        }
        this.transactionservice.getaccountAllDetails(this.user)
            .subscribe(value => {
                this.model = value
            });
        // this.transactionservice.getCustomerDetailsAccsixty(this.model1)
        // .subscribe(data => {
        //     if (this.productID != null) {
        //         data.lstproduct.forEach(element => {
        //           if (element.productid == Number(this.productID)) {
        //             element.checkselect = true;
        //             this.model.productID = element.productid
        //             this.model.acwiseid = element.id
        //           }
        //         });
        //       }
        // });


        //         this.transactionservice.getCustomerDetails(this.model)
        //         .subscribe(data => {
        //           this.model = data;
        //       this.model.localeID = data.localeID
        //   // alert(this.model.localeID);
        //           if (this.productID != null) {
        //             data.lstproduct.forEach(element => {
        //               if (element.productid == Number(this.productID)) {
        //                 element.checkselect = true;
        //                 this.model.productID = element.productid
        //                 this.model.acwiseid = element.id
        //               }
        //             });
        //           }


        //         });
    }
    //     getcustomernameLike($event) {
    //       let userId = (<HTMLInputElement>document.getElementById('customerName1')).value;
    //       this.userList2 = [];
    //       let searchDataObj = {};
    //       this.user.message = userId;
    //       var obj = this;
    //       if (userId.length >= 3) {
    //           this.transactionservice.getcustomernameLike(this.user)
    //               .subscribe(data => {
    //                   data.forEach((item) => this.userList2.push({ "customerName": item.customerName, "id": item.id }));
    //                   let map = new Map();
    //                   var names = "";
    //                   for (let i = 0; i < this.userList2.length; i++) {
    //                       map.set(this.userList2[i].customerName, this.userList2[i].id)
    //                       searchDataObj[this.userList2[i].customerName] = null;
    //                       if (i == 0) {
    //                           names = this.userList2[i].customerName;
    //                       } else {
    //                           names = names + "," + this.userList2[i].customerName;
    //                       }
    //                   }
    //                   let customerName;
    //                   $(document).ready(function () {
    //                       $(".only-numeric").bind("keypress", function (e) {
    //                           var keyCode = e.which ? e.which : e.keyCode

    //                           if (!(keyCode >= 48 && keyCode <= 57)) {
    //                               $(".error").css("display", "inline");
    //                               return false;
    //                           } else {
    //                               $(".error").css("display", "none");
    //                           }
    //                       });
    //                   });
    //                   $(document).ready(function () {
    //                       $('input.typeahead').autocomplete({
    //                           onAutocomplete: function (txt) {
    //                               obj.typeaheadOnSelect(map.get(txt), txt);
    //                           },
    //                       });
    //                       $('input.autocomplete').autocomplete({
    //                           data: searchDataObj,
    //                           onAutocomplete: function (txt) {
    //                               obj.typeaheadOnSelect(map.get(txt), txt);
    //                           },
    //                       });
    //                   });
    //               },
    //                   () => {
    //                       alert("fail");
    //                   });
    //       }
    //   }
    //   typeaheadOnSelect(id, customerName) {
    //       this.model.id = id;
    //       this.model.customerName = customerName;
    //   }
    btncreatelead() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "flag": btoa("createlead"),
                "id": btoa(this.model.accountid)
            }
        };
        this.router.navigate(['/prospect'], navigationExtras);
    }
    btnbookservice() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "flag": btoa("bookservicecall"),
                "id": btoa(this.model.accountid),
                "productid": null
            }
        };
        this.router.navigate(['/prospect'], navigationExtras);
    }
    btncancle(pageno) {
      this.router.navigate(['/Call Center Dashboard',pageno]);
    }
    Oncheckselect(index) {
        for (let i = 0; i < this.model.lstproduct.length; i++) {
            this.model.lstproduct[i].checkselect = false;
        }
        this.model.lstproduct[index].checkselect = true;
        this.model.productID = this.model.lstproduct[index].productid
        this.model.acwiseid = this.model.lstproduct[index].id
    }
}