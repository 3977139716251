import { DropDownModel } from 'src/app/_models/DropDownModel';

export class DefineCategoryTermsConModel {
    id: number;
    heading: string;
    descrption:string;
    name:string;
    type:string;
    customerTypeId:number;
    categoryID:number
    userid:number;
    customertypelst:DefineCategoryTermsConModel[]=[];
    industrylst:DefineCategoryTermsConModel[]=[];
    purchase:boolean=false;
    service:boolean=false;
    sales:boolean=false;
    chkall:boolean=false;
    categorylstint: number[];
}