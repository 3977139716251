<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <!-- <div class="col s3 l3 m3">
                </div> -->
                <div class="input-field col s3 l3 m3" style="text-align: left; margin-top: 5px;padding-left: 14px;">
                    <span>Search Criteria</span>
                    <select (change)=" onSelectAllowance($event.target.value)">
                        <option *ngFor="let type of searchtype" [value]="type.name">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s3 l3 m3" style="text-align: center" style="margin-top: 22px;">
                    <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Text">
                    <label for="search">Search Text</label>
                </div>
                <!-- <div class="col s2 l2 m2">
                </div> -->
                <div class=" input-field col s3 l3 m3" style="margin-top: 22px">
                    <button type="button" (click)="btnsearch($event)"
                        class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnnew()">New</button>
                </div>
            </div>



            <div class="row" style="padding-right: 9px; padding-left: 9px;">
                <table>
                    <thead style="background: gainsboro;">
                        <th style="text-align: left;" width="6%">Sr. No.</th>
                        <th style="text-align: left;" width="20%">Activity Code</th>
                        <th style="text-align: left;" width="22%">Activity Name</th>
                        <th style="text-align: center;" width="10%">Hierarchy</th>
                        <th style="text-align: left;" width="13%">Access Type</th>
                        <th style="text-align: left;" width="22%">Call Type</th>
                        <th style="text-align: center;" width="7%">Actions</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of activitylst | paginate: { itemsPerPage: 10, currentPage: p }">
                            <td style="text-align: left;">{{lst.srno}}</td>
                            <td style="text-align: left;">{{lst.activityCode}}</td>
                            <td style="text-align: left;">{{lst.activityName}}</td>
                            <td style="text-align: right;padding-right: 10px;">{{lst.sequence}}</td>
                            <td style="text-align: left;">{{lst.accesstype}}</td>
                            <td style="text-align: left;">{{lst.callType}}</td>
                            <td style="text-align: center;"><span><img src="../../../assets/images/edit.png"
                                        (click)="btnedit(lst.id)" data-toggle="tooltip" data-placement="top"
                                        title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                        src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                        data-toggle="tooltip" data-placement="top" title="Delete" width="20px" /></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align:right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </span>
            </div>
        </div>
    </div>
</div>