import { DropDownModel } from 'src/app/_models/DropDownModel';


export class DefineCompanyMasterModel {
    id: number;
    companyname: string;
    companycode: string;
    address: string;
    city: string;
    pincode: string;
    state: string;
    country: string;
    telephone: string;
    companywebsite: string;
    defaultcurrency: string;
    defaultcurrencyid: number;
    currencyName:string;
    installationapproval: string;
    userid:number;
    gstnumber:string;
    // serviceheadname: string;
    // serviceheadnameid: number;
    // resourcelst: DropDownModel[] = [];
    defautcurrlst: DropDownModel[] = [];
    taxgroupflag: boolean;
    updownexelflag: boolean;
    mashindescriptionflag: boolean;

    integratedsaleservice: boolean;
    mcstatus: boolean;
    showbrand: boolean;
    salesfunnel: boolean;
    mobileapplication: boolean;
    mobilecallclose: boolean;
    sparelist: boolean;
    mobileattachments: boolean;

    percentage: number;
    nodallowedbeforeservicecall: number;
    nodallwedbeforedcr: number;
    noofdaysescalate: number;
    bucketname: string;
    amazons3key: string;
    amazonserect3key: string;

    firstapproval: boolean;
    secondapproval: boolean;
    thirdapproval: boolean;
    fourthapproval: boolean;

    fortnightly: boolean;
    monthly: boolean;
    edittripdistance: boolean;

    complst:DefineCompanyMasterModel[] = [];
    maxvalues:string;
    type:String;
    moduleflagName:String;
    moduleflag:String;
    status:string;
    chkselect:boolean;
    customervalue:string;
    typeflag:boolean;
    length: number;
    
}