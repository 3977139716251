<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Daily Production Plan</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%;padding-top: 5px;">
            <div class="col l12 m12 s12"   style="text-align: center;padding: 0px;">
                <span style="font-size: 16px;">Plan Conversion </span>
            </div>
            
            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span style="font-size: 16px;">Production Plan ID <br>
                        <b>{{model.planID}}</b></span>
                </div>
                <div class="input-field col l2 m2 s2" style="margin: 0px">
                    <span style="font-size: 16px;">Date<br>
                        <b>{{model.plandate |date : "dd-MM-yyyy"}}</b> 
                    </span>

                </div>
                <div class="input-field col l2 m2 s2" style="margin: 0px">
                    <span style="font-size: 16px;">Inventory Localtion<br>
                        <b>{{model.location}}</b> 
                    </span>
                </div>
            </div>
            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <table>
                    <thead style="background: gainsboro;">
                        <th width="10%" style="text-align: center">Item Code</th>
                        <th width="20%" style="text-align: center">Item Name</th>
                        <th width="5%" style="text-align: center">UOM</th>
                        <th width="10%" style="text-align: center">Quantity Required</th>
                        <th width="10%" style="text-align: center">Balance</th>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let lst of model.lstplanitems | paginate: { itemsPerPage: 10, currentPage: p };let i=index;">
                            <td style="text-align: center">{{lst.itemcode}}</td>
                            <td style="text-align: center">{{lst.itemname}}</td>
                            <td style="text-align: center">{{lst.uom}}</td>
                            <td style="text-align: center">{{lst.quantityreqired}}</td>
                            <td style="text-align: center">{{lst.balancequantity}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 9px;padding-left: 14px;">
                    <span>Conversion Template</span>
                    <select (change)=" onSelectconversion($event.target.value)">
                        <option value="0">Select Template</option>
                        <option *ngFor="let type of model.lstconversiontemplate" [value]="type.id"
                            [selected]="type.id==conversionmodel.id">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s3 l3 m3" style="text-align: start;margin-top: 8px;">
                    <span>Input Item <br></span>
                    <span style="font-size: 16px;"> {{conversionmodel.itemname}}
                    </span>
                </div>
                <div class="input-field col s1 l1 m1" style="text-align: start;margin-top: 9px;">
                    <span>UOM<br></span>
                    <span style="font-size: 16px;"> {{conversionmodel.uom}}
                    </span>
                </div>
                <div class="input-field col s2 l2 m2" style="text-align: start;margin-top: 12px;">
                    <input type="text" [(ngModel)]="conversionmodel.quantity" #name="ngModel" class="" id="Quantity"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="Quantity">Quantity</label>
                </div>

                <div class=" input-field col s1 l1 m1" style="margin-top: 13px;">
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnadd()">Add</button>&nbsp;&nbsp;
                </div>

            </div>

            <div class="row">
                <div class="col s12 m12 l12 registerBgShadow"
                    style="padding-left: 9px;padding-right: 9px;padding-top: 5px;">
                    <div class="col l12 m12 s12"
                        style="background: gainsboro; padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;">
                        <div class="col l4 m4 s4" style="text-align:left;padding-left: 22px;">
                            <span>Conversion Template</span>
                        </div>
                        <div class="col l3 m3 s3" style="text-align:left;">
                            <span>Input Item</span>
                        </div>
                        <div class="col l1 m1 s1" style="text-align:left;">
                            <span>Quantity</span>
                        </div>
                        <div class="col l1 m1 s1" style="text-align:left;">
                            <span>UOM</span>
                        </div>
                        <div class="col l1 m1 s1" style="text-align:left;">
                            <span>Stock</span>
                        </div>
                        <div class="col l1 m1 s1" style="text-align:left;">
                            <span>Action</span>
                        </div>
                    </div>
                    <mat-accordion style="padding: 0px;">
                        <mat-expansion-panel hideToggle
                            *ngFor="let lst of model.lstitemconversion | paginate: { itemsPerPage: 10, currentPage: p };let i = index "
                            style="padding: 1px;">
                            <mat-expansion-panel-header style="padding-right: 9px;">
                                <div class="col l12 m12 s12"
                                    style="margin-top:20px;color: black;font-weight: bold;font-weight: bold; margin-bottom: 5px;">
                                    <div class="row">
                                        <div class=" col l4 m4 s4" style="text-align:left;">
                                            <span>{{lst.formulaname}}</span>
                                        </div>
                                        <div class=" col l3 m3 s3" style="text-align:left;">
                                            <span>{{lst.itemname}}</span>
                                        </div>
                                        <div class=" col l1 m1 s1" style="text-align:left;width:105px;">
                                            <span>{{lst.quantity| number:'1.2-2'}}</span>
                                        </div>
                                        <div class=" col l1 m1 s1" style="text-align:left;">
                                            <span>{{lst.uom}}</span>
                                        </div>
                                        <div class=" col l1 m1 s1" style="text-align:left;">
                                            <span>{{lst.stock | number:'1.2-2'}}</span>
                                        </div>
                                        <div class="col s2 m2 l2" style="text-align:right;width: 168px;">
                                            <span style="padding-right: 5px;"><img src="../../../assets/images/delete.png"
                                                    (click)="btndelete(lst.id,i)" data-toggle="tooltip"
                                                    data-placement="top" title="Edit" width="20px"
                                                    style="height: 25px;width: 25px;" /></span>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div *ngIf="lst.lstoutputmeteriallst.length>0" class="registerBgShadow col l12 m12 s12"
                                style="text-align: center; margin-top: 10px;">

                                <div class="row">
                                    <div class="col l4 m4 s4" style="text-align:center;color: rgb(0, 136, 199);">
                                        <span>Output Item</span>
                                    </div>
                                    <div class="col s2" style="text-align:center">
                                        <span>Quantity / UOM</span>
                                    </div>
                                    <div class="col s4" style="text-align:center">
                                        <span>Balance</span>
                                    </div>
                                </div>

                                <div class="row" *ngFor="let sublst of lst.lstoutputmeteriallst">
                                    <div class="col l4 m4 s4" style="text-align:center">
                                        <span>{{sublst.omitemname}}</span>
                                    </div>
                                    <div class="col s2" style="text-align:center">
                                        <span>{{sublst.quantityconversion | number:'1.2-2'}}/ {{sublst.omuom}}</span>
                                    </div>
                                    <div class="col s4" style="text-align:center">
                                        <span>{{sublst.ombalance | number:'1.2-2'}}</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="lst.lstoutputmeteriallst.length==0" class="col l11 m11 s11 registerBgShadow"
                                style="margin-left:50px;text-align: center;">
                                <div class="col l12 m12 s12" style="color: red">
                                    <samp> List Not Found </samp>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </mat-accordion>
                </div>
            </div>


            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                    (click)="back()">Back</button>
                    
                    <button type="button" (click)="btnsaveplanconversion()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;"> Next >> Raw Material
                        Requisition</button>
                </div>
            </div>


        </div>
    </div>
</div>