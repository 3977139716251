<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">

            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="row" style="margin-top: 9px;">
                        <div class="col s1">
                        </div>
                        <div class="input-field col s3" style="text-align: left;padding-left: 0px;margin-top: 0px;">
                            <span>Document name</span>
                            <select (change)=" onSelectDocument($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name"
                                    [selected]="type.name == model.document">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class=" input-field col s4 m4 l4" style="margin-top: 17px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.prefixCode" #prefixCode="ngModel" id="prefixCode" type="text"
                                data-toggle="tooltip" data-placement="top" title="Prefix Code" required>
                            <label id="prefixCode" for="prefixCode">Prefix Code</label>
                        </div>
                        <div class=" input-field col s3" style=" margin-top: 17px;">
                            <input [(ngModel)]="model.bsrno" #bsrno="ngModel" type="number" min="0"
                                oninput="validity.valid||(value='');" id="first1" data-toggle="tooltip"
                                data-placement="top" title="Serial No" style="text-align: right;" required>
                            <label id="lable1" for="first1">Serial No</label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s1" style="margin-top: 20px;padding-right: 0px;">
                        <input [(ngModel)]="model.checkselectbran" #checkselectbran="ngModel" type="checkbox"
                            name="Allsku" style="text-align: 5px" (click)="onSelectcheckselectbran($event)">
                        Branch
                    </div>
                    <div class=" input-field col s1" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.bransequence" #bransequence="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first2" data-toggle="tooltip" data-placement="top"
                            title="Sequence" (ngModelChange)="onChangeSequence($event);" style="text-align: right;"
                            required>
                        <label id="lable2" for="first2">Sequence </label>
                    </div>
                    <div class="col s1">
                    </div>
                    <div class="input-field col s1" style="margin-top: 20px;padding-right: 0px;">
                        <input [(ngModel)]="model.chkselectPro" #chkselectPro="ngModel" type="checkbox" name="Allsku"
                            style="text-align: 5px" (click)="onSelectchkselectPro($event)">
                        Product
                    </div>
                    <div class=" input-field col s1" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.prodsequence" #prodsequence="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first3" data-toggle="tooltip" data-placement="top"
                            title="Sequence" (ngModelChange)="onChangeProSequence($event);" style="text-align: right;"
                            required>
                        <label id="lable3" for="first3">Sequence </label>
                    </div>
                    <div class="col s1">
                    </div>
                    <div class="input-field col s2" style="margin-top: 20px;">
                        <input [(ngModel)]="model.checkselecfina" #checkselecfina="ngModel" type="checkbox"
                            name="Allsku" style="text-align: 0px"
                            (click)="onSelectcheckselecfina($event)">&nbsp;&nbsp;&nbsp;&nbsp;Financial Year
                    </div>
                    <div class=" input-field col s1" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.financsequence" #financsequence="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first4" data-toggle="tooltip" data-placement="top"
                            title="Sequence" ngModel maxlength="35" (ngModelChange)="onChangefinSequence($event);"
                            style="text-align: right;" required>
                        <label id="lable4" for="first4">Sequence</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col s1">
                    </div>
                    <div class="input-field col s1" style="margin-top: 20px;">
                        <input [(ngModel)]="model.chekselectother" #chekselect="ngModel" type="checkbox" name="Allsku"
                            style="text-align: 5px" (click)="onSelectchekselectother($event)">
                        Other
                    </div>
                    <div class=" input-field col s1" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.othersequence" #othersequence="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first5" data-toggle="tooltip" data-placement="top"
                            title="Sequence" ngModel maxlength="35" (ngModelChange)="onChangeOtherSequence($event);"
                            required>
                        <label id="lable5" for="first5">Sequence </label>
                    </div>
                    <div class="col s1"></div>
                    <div class=" input-field col s4" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.otherdetails" #otherdetails="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" type="text" id="first6"
                            data-toggle="tooltip" data-placement="top" title="Other Details" style="text-align: right;"
                            required>
                        <label id="lable6" for="first6">Other Details </label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l11 m11 s11" style="text-align: right;margin-top: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                            (click)=" cancle()">Cancel</button>
                        <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                            style="margin-left: 5px;">Save</button>
                    </div>
                    <div class="col s1">
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col s1">
                        </div>
                        <div class="col l10 m10 s10" style="padding: 0px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="30%" style="text-align: left;padding-left: 10px;">Document</th>
                                    <th width="40%" style="text-align: left;">Prefix</th>
                                    <th width="20%" style="text-align: right;padding-right: 15px;">Running Sr. No.</th>
                                    <!-- <th width="15%" style="text-align: right;padding-right: 10px;">Sequence</th> -->
                                    <th width="10%" style="text-align: left">Actions</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of enqlist | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.document}}</td>
                                        <td style="text-align: left;">{{lst.conctPrefix}}</td>
                                        <td style="text-align: right;padding-right: 15px;">{{lst.bsrno}}</td>
                                        <!-- <td style="text-align: right;padding-right: 10px;">{{lst.sequence}}</td> -->
                                        <td style="text-align: left"> <span><img src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst)" data-toggle="tooltip" data-placement="top"
                                                    title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                                    src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                    width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                        <div class="col s1">
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>