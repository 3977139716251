export class DefineResourceAllowanceModel{
    srno:number
    resource:string;
    name:string
    id:number;
    designationName:string;
    userid:number;
    txtserch:string;
    txtselect:string;
    allowancelst:AllowanceList[];
}
export interface AllowanceList{
    id:number;
    allowanceid:number;
    chkselect:boolean;
    allowncetype:string;
    frequncy:string;
    freqUnit:string;
    value:string;
    type:string;
    unit:string;
    unitid:number;
    calculation:string;
    mapUse:boolean;
    rate:number;
   
    linkedAllowance:string;
}
