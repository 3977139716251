export class DefineProductcategoryModel{
    productCategoryName:String;
    description:string;
    productCategoryCode:string;
    noOfInstVisit:number;
    noOfAMCVisit:number;
    discount:number;
    id: number;
    subcategoryname:string;
    itemSubCatlst:DefineProductcategoryModel[]=[];
    chkselecttype:boolean;
    itemsubcategoryid:number;
    itemsubcategoryname:string;
   
}