export class DefineServiceChargesModel{
    id: number;
    productcategoryid:number;
    prodCatname: string;
    proid:number;
    productName: string;
    productid:number;
    itemcategoryid: number;
    itemsubcategoryid: number;
    itemcategory: string;
    itemsubcategory: string;
    chklist:boolean;
    itemmasterid: number;
    
    type:string;
    itemcode:string;
	description:string; 
	uom:string;
    quantity:number;
    rate:number;
    userID:number;
    
    spareName:string;
    spareCategoryid: number;
    spareCategoryName:string;
    spareProName:string;
    gstGrouppercentage:number;
    gstheaderID:number;
    servicelst: DefineServiceChargesModel[]=[];
    itemlst:DefineServiceChargesModel[]=[];
    selectall:boolean;
    
}  