import { AccountThreeSixtyModel } from "src/app/Transactions/AccountThreeSixty/AccountThreeSixtyModel";

export class CustomerMergeModel{
    fromcustomerName: String;
    fromcustomerID:number;
    fromcustomertype:string;
    tocustomerName:string;
    tocustomerID:number;
    tocustomertypename:string;
    fromcustomerId: number;
    supervisiorAuth: string;
    itemcategoryid: number;
    status: string;
    opportunitylst:CustomerMergeModel[]=[];
    installationlst:CustomerMergeModel[]=[];
    installtionnumber:string;
    oanumber:string;
    servicecalllst:CustomerMergeModel[]=[];
    accountlst:CustomerMergeModel[]=[];
    tocmlocality:string;
}
    