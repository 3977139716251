<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="col s3 l3 m3">
                    </div>
                    <div class="input-field col s2 l2 m2" style="text-align: left">
                        <select (change)="onSelectSrch($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center">
                        <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Here</label>
                    </div>
                    <div class="col s2 l2 m2">
                    </div>
                    <div class=" input-field col s2 l2 m2" style="margin-top: 10px">
                        <button type="button" (click)="btnsearch()" class="waves-effect waves-light btn">Go</button>
                        <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                            (click)="btnnew()">New</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="35%" style="text-align: center">Code</th>
                            <th width="35%" style="text-align: center">Name</th>
                            <th width="30%" style="text-align: center">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of uomllst | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: center">{{lst.code}}</td>
                                <td style="text-align: center">{{lst.name}}</td>
                                <td style="text-align: center"><span><img src="../../../assets/images/edit.png"
                                            (click)="btnedit(lst.id)" data-toggle="tooltip" data-placement="top"
                                            title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                            src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Delete"
                                            width="20px" /></span></td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>

        </div>
    </div>
</div>