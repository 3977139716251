import { DropDownModel } from 'src/app/_models/DropDownModel';

export class DefineAllowanceModel {
    id: number;
    code: string;
    name:string;
    allwaname:string;
    frequency: string;
    type:string;
    calculate:string;
    unitid:number;
  
    allowmodellst:DefineAllowanceModel[]=[];
    unitlst:DropDownModel[]=[];

    userid:number;
    txtserch: string;
    txtselect: string;
}