import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DefineVendorModel } from "../Master/Vendor Master/DefineVendorModel";
import { SaveSuccessModel } from "../_models/SaveSucessModel";
import { common } from "./Common";
import { HandleError, HttpErrorHandler } from "./http-error-handler.service";

@Injectable()

export class VendorService {

    private handleError: HandleError;
    private url: String = common.s1;
    private customersUrl = this.url;
    getCollectionofLibrary: any;
    //private headers = new Headers({'Content-Type': 'application/json'});
    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
    }

    savevendor(usr: DefineVendorModel): Observable<SaveSuccessModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
                'dbName': localStorage.getItem('dbName')
            })
        };
        return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/vendorapi/savevendor', usr, httpOptions1);
    }


}