<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Manage Sales Product Category</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12">
                <div class="row">
                    <div class="col l1 m1 s1"></div>
                    <div class="input-field  col s3" style=" margin-top: 30px;">
                        <input [(ngModel)]="model.productCategoryCode" #productCategoryCode="ngModel" type="text"
                            id="productCategoryCode" data-toggle="tooltip" data-placement="top"
                            title="Product Category Code">
                        <label for="productCategoryCode" class="active">Code</label>
                    </div>
                    <div class="input-field  col s3" style=" margin-top: 30px;">
                        <input [(ngModel)]="model.productCategoryName" #productCategoryName="ngModel" type="text"
                            id="productCategoryName" data-toggle="tooltip" data-placement="top"
                            title="Product Category Name">
                        <label for="productCategoryName" class="active">Name</label>
                    </div>
                    <div class="input-field  col s4" style=" margin-top: 30px;">
                        <input [(ngModel)]="model.description" #description="ngModel" type="text" id="description"
                            data-toggle="tooltip" data-placement="top" title="Description">
                        <label for="description" class="active">Description</label>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row">
                    <div class="col l1 m1 s1"></div>
                    <!-- <div class="input-field  col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.noOfAMCVisit" #noOfAMCVisit="ngModel" type="number" id="noOfAMCVisit"
                            data-toggle="tooltip" data-placement="top" title="No Of AMCVisit">
                        <label for="noOfAMCVisit" class="active">AMC Visit</label>
                    </div>
                    <div class="input-field  col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.noOfInstVisit" #noOfInstVisit="ngModel" type="number"
                            id="noOfInstVisit" data-toggle="tooltip" data-placement="top" title="No Of InstVisit">
                        <label for="noOfInstVisit" class="active">Installation Visit</label>
                    </div> -->
                    <div class="input-field  col s4" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.discount" #discount="ngModel" type="number" id="discount"
                            data-toggle="tooltip" data-placement="top" title="discount">
                        <label for="discount" class="active">Discount</label>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>


                <!-- <div class="row" style="margin:0px; padding-left: 0px;padding-right: 0px;">
                    <div class="col m1 l1 s1"></div>
                    <div class="col l10 m10 s10" style="margin-top: 0px;padding-left: 0px;padding-right: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="10%" style="text-align: left">Select</th>
                                <th width="90%" style="text-align: left">Item Sub Category</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of itemSubCatlst | paginate: { itemsPerPage: 10, currentPage: p } ; let i = index ">
                                    <td style="text-align: center;"><input type="checkbox"
                                            [(ngModel)]="lst.chkselecttype" #chkselecttype="ngModel"></td>
                                    <td style="text-align: left">{{lst.itemsubcategoryname}}</td>

                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                    <div class="col m1 l1 s1"></div>
                </div> -->


                <div class="col l12 m12 s12" style="padding-left: 0px;">
                    <div class="col l12 m12 s12" style="padding:12px; background-color: gainsboro">
                        <div class="col l12 m12 s12" style="text-align: center;">
                            <span style="font-size: 15px;color: rgb(0, 136, 199); font-weight: bold">Item Sub Category</span>
                        </div>
                    </div>
                    <div class="col l12 m12 s12" style="overflow-y: auto; height: 300px;padding: 3%;">
                        <div *ngFor="let lst of itemSubCatlst">
                            <div class="col l4 m4 s4">
                                <div class="col l2 m2 s2">
                                    <input type="checkbox" [(ngModel)]="lst.chkselecttype" #chkselecttype="ngModel">
                                </div>
                                <div class="col l10 m10 s10">
                                    <span>{{lst.itemsubcategoryname}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="input-field col l11 m11 s11" style="text-align: right;padding-right: 0px;">
                            <button type="button" class="waves-effect cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)"
                                class="waves-ripple waves-light btn">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>