<app-header></app-header>
<script>
    function myFunction(x) {
        unhighlight()
        x.style.backgroundColor = '#cccccc';
    }
</script>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Resource Rights</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="col s12 l12 m12">
                <div class="tabtab">
                    <button class="tablinks" id="defaulttab" onclick="openCity(event, 'Geography')">Geography</button>
                    <button class="tablinks" id="procat" onclick="openCity(event, 'Product categories')">Product
                        Categories</button>
                    <button class="tablinks" id="cty" onclick="openCity(event, 'Customer types')">Customer
                        Types</button>
                    <button class="tablinks" id="appro" onclick="openCity(event, 'Approvals')">Approvals</button>
                </div>
            </div>
            <div id="Geography" class="tabcontent col l12 m12 s12 registerBgShadow" style="margin-bottom: 9px;">
                <div class="row" style="padding: 20px;">
                    <div class="col l6 m6 s6" style="text-align: left">
                        <span style="font-size: 20px;">Resource Geography</span>
                    </div>
                    <div class="col l6 m6 s6" style="text-align: right;">
                        <span style="font-size: 160%; ">{{resname}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l1 m1 s1"></div>
                    <div class="col s10 input-field" style="margin: auto;padding: 0px;">
                        <input type="text" #inputTrigger matInput data-toggle="tooltip" data-placement="top"
                            title="Regions" placeholder="Select Regions" [matAutocomplete]="autoComplete"
                            [formControl]="itemControl" [matChipInputFor]="chipList" id="Regions" name="Regions"
                            style="font-size: 1rem;">
                        <mat-autocomplete #autoComplete="matAutocomplete" style="font-size: 30px;">
                            <mat-option *ngFor="let item of filteredItems" [value]="selectedItems">
                                <div (click)="optionClicked($event, item);">
                                    <mat-checkbox [checked]="item.selected" (change)="toggleSelection(item)"
                                        (click)="$event.stopPropagation()">
                                        {{ item.item }}
                                    </mat-checkbox>
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-chip-list #chipList class="input-field">
                            <mat-chip *ngFor="let item of selectedItems" [selectable]="selectable"
                                style="border-radius: initial;" [removable]="removable" (removed)="remove(item)">
                                {{ item.item }}
                            </mat-chip>

                        </mat-chip-list>
                    </div>

                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col l12 m12 s12" style="background: gainsboro; ">
                            <div class="col l1 m1 s1"
                                style="text-align: left;padding-left: 32px; color: rgb(0, 136, 199); font-weight: bold; padding-top: 20px;"
                                width="10%">
                                <span>Select</span>
                            </div>
                            <div class="col l5 m5 s5"
                                style="text-align: left;padding-left: 24px; color: rgb(0, 136, 199); font-weight: bold;padding-top: 20px;"
                                width="10%">
                                <span>Area Name</span>
                            </div>
                            <div class="col l4 m4 s4">

                            </div>
                            <div class=" col l2 m2 s2"
                                style="text-align: right;color: rgb(0, 136, 199); font-weight: bold;padding-top: 20px;">
                                <input type="checkbox" (change)="onselectallLocales($event)"
                                    [(ngModel)]="model.allLocaleInRegion" #allcheckselect="ngModel">Select All Locales
                            </div>
                        </div>
                        <mat-accordion>
                            <mat-expansion-panel hideToggle
                                *ngFor="let lst of arealst | paginate: { itemsPerPage: 10, currentPage: p };let i = index"
                                style="padding-left: 1px;">
                                <mat-expansion-panel-header>
                                    <div class=" col l12 m12 s12" style="padding: 1%;">
                                        <div class=" col l1 m1 s1" style="text-align: left">
                                            <span><input type="checkbox" [(ngModel)]="lst.chkselectarea"
                                                    #chkselectarea="ngModel"></span>
                                        </div>
                                        <div class=" col l5 m5 s5" style="text-align: left"
                                            (click)="onclickarea(lst.areaname)">
                                            <span>{{lst.areaname+"("+lst.regionname+")"}}</span>

                                        </div>
                                        <!-- <div class=" col l5 m5 s4" style="text-align: left">
                                            <span>{{lst.regionname}}</span>
                                        </div> -->
                                        <div class="input-field col l4 m4 s4" style="margin: 0px;">
                                            <select (change)="onSelectCity($event.target.value,i)">
                                                <option selected value="0">Select City</option>
                                                <option *ngFor="let type of lst.citylst" [value]="type.cityid"
                                                    [selected]="type.cityid == model.cityid">
                                                    {{type.cityname}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                                <div class="col l12 m12 s12 registerBgShadow" style="padding: 9px;">
                                    <div class="col l10 m10 s10"
                                        style="background: aliceblue;padding-top: 5px;padding-bottom: 5px;margin-bottom: 5px;">
                                        <span>Locales of {{lst.areaname}}</span>
                                    </div>
                                    <div class=" col l2 m2 s2" style="text-align: right">
                                        <input type="checkbox" (change)="onselectall($event)"
                                            [(ngModel)]="model.allcheckselect" #allcheckselect="ngModel">
                                    </div>
                                    <!-- <div *ngFor="let sublst of lst.localelst"> -->
                                    <div *ngFor="let sublst of newlocallst;let i = index">
                                        <div class="col  l4 m4 s4">
                                            <div class=" col l2 m2 s2" style="text-align: left">
                                                <input type="checkbox" [disabled]="lst.chkselectarea==false"
                                                    (change)="onselectlocal($event,sublst.localeid,i)"
                                                    [(ngModel)]="sublst.chkselectlocale" #chkselectlocale="ngModel">
                                            </div>
                                            <div class="col l10 m10 s10" style="text-align: left">
                                                <span>{{sublst.localename}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>

                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;margin-bottom: 9px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsaveAndNext()"
                            class="waves-ripple waves-light btn">Next</button>
                    </div>
                </div>
            </div>

            <div id="Product categories" class="tabcontent col l12 m12 s12 registerBgShadow"
                style="margin-bottom: 9px;">
                <div class="row" style="padding: 20px;">
                    <div class="col l6 m6 s6" style="text-align: left">
                        <span style="font-size: 20px;">Resource Product Categories</span>
                    </div>
                    <div class="col l6 m6 s6" style="text-align: right;">
                        <span style="font-size: 160%; ">{{resname}}</span>
                    </div>
                </div>
                <div class="row" style="padding-left: 9px;padding-right: 9px;">
                    <div class="col l12 m12 s12" style="overflow-y: scroll; height: 350px;">
                        <div class="row">
                            <div class="col l12 m12 s12"
                                style="text-align: center;background: gainsboro;padding: 10px;margin-bottom: 9px; color: rgb(0, 136, 199); font-weight: bold"
                                width="10%">
                                <span>Product Category</span>
                            </div>
                        </div>
                        <div *ngFor="let lst of prodcatlst">
                            <div class="col l6 m6 s6">
                                <div class="col l1 m1 s1" style="text-align: left">
                                    <span><input type="checkbox" [(ngModel)]="lst.chkselectcat"
                                            #chkselectcat="ngModel"></span>
                                </div>
                                <div class="col l10 m10 s10" style="text-align: left">
                                    <span>{{lst.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;margin-bottom: 9px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsaveProductCat()"
                            class="waves-ripple waves-light btn">Next</button>
                    </div>
                </div>
            </div>


            <div id="Customer types" class="tabcontent col l12 m12 s12 registerBgShadow" style="margin-bottom: 9px;">
                <div class="row" style="padding: 20px;">
                    <div class="col l6 m6 s6" style="text-align: left">
                        <span style="font-size: 20px;">Resource Customer Types</span>
                    </div>
                    <div class="col l6 m6 s6" style="text-align: right;">
                        <span style="font-size: 160%;">{{resname}}</span>
                    </div>
                </div>
                <div class="row" style="padding-left: 9px;padding-right: 9px;">
                    <div class="col l12 m12 s12" style="overflow-y: scroll; height: 350px;">
                        <div class="row">
                            <div class="col l12 m12 s12"
                                style="text-align: center;margin-bottom: 9px; background: gainsboro;padding: 10px;color: rgb(0, 136, 199); font-weight: bold"
                                width="10%">
                                <span>Customer Type</span>
                            </div>
                        </div>
                        <div *ngFor="let lst of resourcectype">
                            <div class="col l6 m6 s6">
                                <div class="col l1 m1 s1" style="text-align: left">
                                    <span><input type="checkbox" [(ngModel)]="lst.chkselecttype"
                                            #chkselecttype="ngModel"></span>
                                </div>
                                <div class="col l10 m10 s10" style="text-align: left">
                                    <span>{{lst.type}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;margin-bottom: 9px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsaveCustomertype()"
                            class="waves-ripple waves-light btn">Next</button>
                    </div>
                </div>
            </div>

            <div id="Approvals" class="tabcontent col l12 m12 s12 registerBgShadow" style="margin-bottom: 9px;">

                <div class="row" style="padding: 20px;">
                    <div class="col l6 m6 s6" style="text-align: left">
                        <span style="font-size: 20px;">Resource Approvals</span>
                    </div>
                    <div class="col l6 m6 s6" style="text-align: right;">
                        <span style="font-size: 160%;">{{resname}}</span>
                    </div>
                </div>

                <div class="row" style="padding-left: 9px;padding-right: 9px;" *ngIf="approvallst.length>0">
                    <div class="col l1 m1 s1"></div>
                    <div class="col l10 m10 s10">
                        <div class="row" *ngFor="let lst of approvallst; let i = index">
                            <div class="col l2 m2 s2" style="padding-top: 10px;">
                                <span>{{lst.type}}</span>
                            </div>
                            <div class="col l6 m6 s6 input-field" style="margin: 0px;padding-top: 2px;">
                                <select (change)="onSelectApprover($event.target.value, i)">
                                    <option [value]="0" selected>Select one</option>
                                    <option *ngFor="let type of resourcelst" [value]="type.id"
                                        [selected]="type.id==lst.approverid">
                                        {{type.name}}</option>
                                </select>
                            </div>
                            <div class="col l3 m3 s3 input-field" style="margin: 0px">
                                <input type="number" [(ngModel)]="lst.sequance" #sequance="ngModel"
                                    style="text-align: right;">
                                <label id="lable1" for="first1">Hierarchy</label>
                            </div>
                        </div>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>

                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;margin-bottom: 9px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsaveApprovals()"
                            class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>