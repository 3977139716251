import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaymentScreenModel } from "../Transactions/PaymentScreen/PaymentScreenModel";
import { PublicScreenModel } from "../_models/PublicScreenModel";
import { common } from "./Common";
import { HandleError, HttpErrorHandler } from "./http-error-handler.service";
import { User } from '../_models';
import { SaveSuccessModel } from "../_models/SaveSucessModel";

@Injectable()
export class PublicServices {

  private headers = new Headers({
    'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Token': localStorage.getItem('Token'),
    'dbName': localStorage.getItem('dbName')
  });
  private handleError: HandleError;
  private url: String = common.s1;
  private customersUrl = this.url;
  //private headers = new Headers({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
  }
  checkpublicsite(user: PublicScreenModel): Observable<PublicScreenModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PublicScreenModel>(this.customersUrl + 'api/publicTranscationApi/finddetails', user, httpOptions1);
  }

  getQuotationInfoForRetailerApprove(user: User): Observable<PaymentScreenModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PaymentScreenModel>(this.customersUrl + 'api/publicTranscationApi/getQuotationInfoForRetailerApprove', user, httpOptions1);
  }


  approveRetailerQuotation(model: PaymentScreenModel): Observable<PaymentScreenModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'dbName': localStorage.getItem('dbName'),
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PaymentScreenModel>(this.customersUrl + 'api/publicTranscationApi/approveRetailerQuotation', model, httpOptions1);

  }
  getServiceQuotationInfo(user: User): Observable<PaymentScreenModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PaymentScreenModel>(this.customersUrl + 'api/publicTranscationApi/getServiceQuotationInfo', user, httpOptions1);
  }
  approveServiceQuotation(model: PaymentScreenModel): Observable<PaymentScreenModel> {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'dbName': localStorage.getItem('dbName'),
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('Token'),
      })
    };
    return this.http.post<PaymentScreenModel>(this.customersUrl + 'api/publicTranscationApi/approveServiceQuotation', model, httpOptions1);

  }

}
