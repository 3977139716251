import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, AuthenticationService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { installationcustomerlst, productinstallation } from './CreateInstallationModel';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { InvoiceProducts } from 'src/app/Transactions/DispatchOrder/DispatchOrderModel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';

declare var $: any;

@Component({
  selector: 'app-CreateInstallation',
  templateUrl: 'CreateInstallation.html',
})
export class CreateInstallation implements OnInit {
  constructor(private route: ActivatedRoute,
    private notifyService: NotificationService,
    private masterservice: MasterServices,
    private router: Router,
    private modalService: BsModalService,
    private transactionService: TransactionServices,
    private installationService: InstallationService,
    private deviceService: DeviceDetectorService,
    private authenticationService: AuthenticationService) {
  }
  noofinstall: number = 0;
  model: any = {};
  forflag: string;
  assignTo: string;
  user = new User();
  teammodel = new TeamModel();
  sendmodel = new ProspectModel();
  i: number;
  currentindex: number;
  lstbranch: DropDownModel[] = [];
  lstassigned: DropDownModel[] = [];
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  userList2: any;
  demolst: productinstallation[] = [];
  lstinstallation: installationcustomerlst[] = [];
  // lstinstallation1: installationcustomerlst[] = [];
  invoiceProducts: InvoiceProducts[] = []
  p: any;
  addr: string;
  pincode: string;
  localityID: number;
  areaID: number;
  locality: string;
  city: string;
  state: string;
  country: string;
  locale: string;
  areaname: string;
  regionname: string;
  googlemap: string;
  mobile: string;
  email: string;
  unit: string;
  unitName: string;
  name: string;
  assignToflag: number;
  buildingName: string;
  noofinstallperproduct: number = 0;
  lstlocalityforproduct: DropDownModel[] = [];
  lstproduct: productinstallation[];
  lstinteger: any[] = [];
  lstproducttoadd: productinstallation[] = [];
  selected: boolean = true;
  installedfourkit: number;
  id: number;
  compinstalltion: number;
  val: number;
  hideflag: boolean = true;
  savehideflag: boolean = false;
  isMobile: boolean;
  deviceInfo = null;
  mainmenus: Mainmenumodel[] = [];
  username: string;
  showBtn: boolean = false;

  ngOnInit() {
    this.mainmenus = JSON.parse(localStorage.getItem('menu'));
    this.username = localStorage.getItem('fullname');

    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    // console.log(this.deviceInfo);
    // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice);

    this.teammodel.userid = Number(localStorage.getItem("userid"));
    this.route.queryParams.subscribe(params => {
      this.model.orderaccID = + params["id"];
      this.forflag = params["forflag"];
      this.model.forflag = params["forflag"];
      this.model.approvalno = + params["approvalnumber"];
    });

    if (this.forflag == "new") {
      this.installationService.getinstallationdetails(this.model)
        .subscribe(data => {
          this.model = data;
          this.lstproduct = [];
          this.name = this.model.coustomername;
          this.email = this.model.email;
          this.mobile = this.model.mobile;
          this.unit = this.model.unit;
          this.addr = this.model.address;
          this.buildingName = this.model.buildingName;
          this.pincode = this.model.pincode
          this.localityID = this.model.localityID
          this.areaID = this.model.areaID
          this.locality = this.model.locality
          this.city = this.model.city
          this.state = this.model.state
          this.country = this.model.country
          this.locale = this.model.locale
          this.areaname = this.model.areaname
          this.regionname = this.model.regionname
          this.googlemap = this.model.googlemap;
          for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {
            for (let j = this.model.lstinvocicedproduct[i].quantity; j > 0; j--) {
              this.lstproduct.push({
                productid: this.model.lstinvocicedproduct[i].productid,
                id: null,
                invoiceprodid: this.model.lstinvocicedproduct[i].id,
                productcategorie: this.model.lstinvocicedproduct[i].productcategorie,
                productcategorieid: this.model.lstinvocicedproduct[i].productcategorieid,
                quantity: this.model.lstinvocicedproduct[i].quantity,
                enterquantity: null,
                uom: this.model.lstinvocicedproduct[i].uom,
                maxquantity: this.model.lstinvocicedproduct[i].maxquantity,
                productname: this.model.lstinvocicedproduct[i].productname,
                assignedtoname: this.model.lstinvocicedproduct[i].assignedtoname,
                assignedtoID: this.model.lstinvocicedproduct[i].assignedtoID,
                installtiondate: null,
                installtionnumber: null,
                completflag: false,
                warrantyMonth: this.model.lstinvocicedproduct[i].warrantyMonth,
                pid: null,
                checkselect: true,
                remaininstallation: null,
                serialnumber: this.model.lstinvocicedproduct[i].serialnumber,
                warrantyenddate: null,
                approveby: null,
                accountwisemcstatus: this.model.lstinvocicedproduct[i].accountwisemcstatus,

              })
            }
          }
          if (this.lstproduct != null) {
            this.model.chkall = true;
          }



          this.getAssignedto(this.model.areaID);
          if (this.model.assigndate == null) {
            this.model.assigndate = new Date();
          }
          this.sendmodel.pincode = data.pincode;
          this.lstlocalityforproduct = [];
          this.lstlocalityforproduct.push({ id: 0, name: "Select Locality" });
          this.transactionService.getlocality(this.sendmodel)
            .subscribe(data => {
              data.forEach(item => {
                this.lstlocalityforproduct.push({ id: item.id, name: item.name });
              });
            });

          this.lstinstallation.push({
            id: null,
            customerid: this.model.customerid,
            accountwiseMacID: null,
            coustomername: this.model.coustomername,
            unit: null,
            mobile: this.model.mobile1,
            email: this.model.email,
            lstproduct: this.demolst,
            address: this.model.address,
            pincode: this.model.pincode,
            localityID: this.model.localityID,
            areaID: this.model.areaID,
            locality: this.model.locality,
            city: this.model.city,
            state: this.model.state,
            country: this.model.country,
            locale: this.model.locale,
            areaname: this.model.areaname,
            regionname: this.model.regionname,
            mobile1: this.model.mobile1,
            mobile2: this.model.mobile2,
            landline: this.model.landline,
            website: this.model.website,
            googlemap: this.model.googlemap,
            checkselect: false,
            buildingName: this.model.buildingName,
          });



        });

      // this.installationService.getdispatchprodcut(this.model)
      // .subscribe(data => {
      //   this.model = data;

      // });

    }
    this.lstinstallation = [];
    if (this.forflag == "edit" || this.forflag == "close") {
      this.installationService.geteditinstalltion(this.model)
        .subscribe(data => {
          this.model = data;
          this.model.grandtotal = data.grandtotal;
          this.lstproduct = data.lstproduct
          this.lstinstallation = data.lstinstallation;
          this.getAssignedto(this.model.areaID);
          // this.onSelectlstassigned(this.id);
          if (this.model.assigndate == null) {
            this.model.assigndate = new Date();
          }
          // console.log(this.model.lstinvocicedproduct.length)

          this.lstinstallation.forEach(element => {
            element.lstproduct.forEach(productelement => {
              console.log("---", productelement.warrantyenddate)
              if (productelement.warrantyenddate == null) {
                var date1 = new Date(productelement.installtiondate)
                productelement.warrantyenddate = new Date(date1.setMonth(date1.getMonth() + productelement.warrantyMonth))
              }
            });
          });
          this.sendmodel.pincode = data.pincode;
          this.lstlocalityforproduct = [];
          this.lstlocalityforproduct.push({ id: 0, name: "Select Locality" });
          this.transactionService.getlocality(this.sendmodel)
            .subscribe(data => {
              data.forEach(item => {
                this.lstlocalityforproduct.push({ id: item.id, name: item.name });
              });
            });

          if (this.lstproduct != null) {
            this.model.chkall = true;
          }
        });
    }

    this.getBranches();

  }

  openproduct(productmodel: TemplateRef<any>) {
    this.lstproduct = [];
    this.name = this.model.coustomername;
    this.email = this.model.email;
    this.mobile = this.model.mobile;
    this.unit = this.model.unit;
    this.addr = this.model.address;
    this.buildingName = this.model.buildingName;
    this.pincode = this.model.pincode
    this.localityID = this.model.localityID
    this.areaID = this.model.areaID
    this.locality = this.model.locality
    this.city = this.model.city
    this.state = this.model.state
    this.country = this.model.country
    this.locale = this.model.locale
    this.areaname = this.model.areaname
    this.regionname = this.model.regionname
    this.googlemap = this.model.googlemap;
    // this.currentindex = i;
    // if (this.lstinstallation[i].lstproduct.length != 0) {
    //   this.lstproduct = this.lstinstallation[i].lstproduct;
    //   this.lstinstallation[i].lstproduct.forEach(element => {
    //     this.lstinteger.push(element.productid);
    //   });
    for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {
      // if (this.model.lstinvocicedproduct[i].quantity > 0 && this.lstinteger.indexOf(this.model.lstinvocicedproduct[i].productid) == -1) {
      this.lstproduct.push({
        productid: this.model.lstinvocicedproduct[i].productid,
        id: null,
        invoiceprodid: this.model.lstinvocicedproduct[i].id,
        productcategorie: this.model.lstinvocicedproduct[i].productcategorie,
        productcategorieid: this.model.lstinvocicedproduct[i].productcategorieid,
        quantity: this.model.lstinvocicedproduct[i].quantity,
        enterquantity: null,
        uom: this.model.lstinvocicedproduct[i].uom,
        maxquantity: this.model.lstinvocicedproduct[i].maxquantity,
        productname: this.model.lstinvocicedproduct[i].productname,
        assignedtoname: this.model.lstinvocicedproduct[i].assignedtoname,
        assignedtoID: this.model.lstinvocicedproduct[i].assignedtoID,
        installtiondate: null,
        installtionnumber: null,
        completflag: false,
        warrantyMonth: this.model.lstinvocicedproduct[i].warrantyMonth,
        pid: null,
        checkselect: false,
        remaininstallation: null,
        serialnumber: this.model.lstinvocicedproduct[i].serialnumber,
        warrantyenddate: null,
        approveby: null,
        accountwisemcstatus: this.model.lstinvocicedproduct[i].accountwisemcstatus,

      })

    }
    for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {
      this.model.lstinvocicedproduct[i].remaininstallation = this.model.lstinvocicedproduct[i].quantity - this.model.lstinvocicedproduct[i].enterquantity
    }
    // }

    for (let i = 0; i < this.lstproduct.length; i++) {
      this.lstproduct[i].enterquantity = 0;
    }

    if (this.model.totalquantity > 0) {
      this.modalRef = this.modalService.show(productmodel, {
        class: 'modal-lg'
      });
    } else {
      // this.hideflag = false;
      this.notifyService.showRroor("Quantity not Available", "Opps")
    }


  }



  getPincodeLikeforproduct($event) {
    let userId = (<HTMLInputElement>document.getElementById('pincode2')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getpincodelike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadpincodeforproduct(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadpincodeforproduct(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
    if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
      this.lstlocalityforproduct = [];
    }
  }


  typeaheadpincodeforproduct(id, name) {
    this.sendmodel.pincode = name;
    this.lstlocalityforproduct = [];
    this.lstlocalityforproduct.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.sendmodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalityforproduct.push({ id: item.id, name: item.name });
        });
      });

  }
  getBranches() {
    this.lstbranch = []
    this.lstbranch.push({ id: 0, name: "Select Branch" })

    this.masterservice.getAllBranches(this.user)
      .subscribe(value => {
        value.forEach(element => {
          this.lstbranch.push({
            id: element.id, name: element.name
          })
          if (this.lstbranch.length == 2) {
            this.model.branchID = element.id;
            for (let i = 0; i < this.lstbranch.length; i++) {
              this.model.branchname = this.lstbranch[i].name
            }
          }
        });
      });

  }
  btnback() {
    this.router.navigate(['/Installation Dashbord']);
  }

  editaddress(index, changaddress) {
    this.lstproduct = [];
    this.name = this.lstinstallation[index].coustomername;
    this.email = this.lstinstallation[index].email;
    this.mobile = this.lstinstallation[index].mobile;
    this.unit = this.lstinstallation[index].unit;
    this.addr = this.lstinstallation[index].address;
    this.buildingName = this.lstinstallation[index].buildingName;
    this.pincode = this.lstinstallation[index].pincode
    this.localityID = this.lstinstallation[index].localityID
    this.areaID = this.lstinstallation[index].areaID
    this.locality = this.lstinstallation[index].locality
    this.city = this.lstinstallation[index].city
    this.state = this.lstinstallation[index].state
    this.country = this.lstinstallation[index].country
    this.locale = this.lstinstallation[index].locale
    this.areaname = this.lstinstallation[index].areaname
    this.regionname = this.lstinstallation[index].regionname
    this.googlemap = this.lstinstallation[index].googlemap;
    this.currentindex = index;
    // if (this.lstinstallation[index].lstproduct.length != 0) {
    //   this.lstproduct = this.lstinstallation[index].lstproduct;
    //   this.lstinstallation[index].lstproduct.forEach(element => {
    //     this.lstinteger.push(element.productid);
    //   });


    for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {
      // if (this.model.lstinvocicedproduct[i].quantity > 0 && this.lstinteger.indexOf(this.model.lstinvocicedproduct[i].productid) == -1) {
      // alert(this.model.lstinvocicedproduct[i].productid)
      this.lstproduct.push({
        productid: this.model.lstinvocicedproduct[i].productid,
        id: null,
        productcategorie: this.model.lstinvocicedproduct[i].productcategorie,
        productcategorieid: this.model.lstinvocicedproduct[i].productcategorieid,
        quantity: this.model.lstinvocicedproduct[i].quantity,
        enterquantity: this.model.lstinvocicedproduct[i].enterquantity,
        uom: this.model.lstinvocicedproduct[i].uom,
        maxquantity: this.model.lstinvocicedproduct[i].maxquantity,
        productname: this.model.lstinvocicedproduct[i].productname,
        assignedtoname: this.model.lstinvocicedproduct[i].assignedtoname,
        assignedtoID: this.model.lstinvocicedproduct[i].assignedtoID,
        installtiondate: null,
        installtionnumber: null,
        completflag: false,
        warrantyMonth: this.model.lstinvocicedproduct[i].warrantyMonth,
        pid: null,
        checkselect: false,
        remaininstallation: null,
        serialnumber: this.model.lstinvocicedproduct[i].serialnumber,
        warrantyenddate: null,
        approveby: null,
        invoiceprodid: this.model.lstinvocicedproduct[i].id,
        accountwisemcstatus: this.model.lstinvocicedproduct[i].accountwisemcstatus,

      })
    }
    // }
    // }
    this.modalRef = this.modalService.show(changaddress, {
      class: 'modal-lg'
    });
  }
  btnchangeproductaddress() {

    if (this.currentindex != null) {

      this.lstproducttoadd = [];
      this.lstinstallation[this.currentindex].coustomername = this.name;
      this.lstinstallation[this.currentindex].email = this.email;
      this.lstinstallation[this.currentindex].mobile = this.mobile;
      this.lstinstallation[this.currentindex].unit = this.unit;
      this.lstinstallation[this.currentindex].address = this.addr;
      this.lstinstallation[this.currentindex].buildingName = this.buildingName
      this.lstinstallation[this.currentindex].pincode = this.pincode;
      this.lstinstallation[this.currentindex].localityID = this.localityID;
      this.lstinstallation[this.currentindex].areaID = this.areaID;
      this.lstinstallation[this.currentindex].locality = this.locality;
      this.lstinstallation[this.currentindex].city = this.city;
      this.lstinstallation[this.currentindex].state = this.state;
      this.lstinstallation[this.currentindex].country = this.country;
      this.lstinstallation[this.currentindex].locale = this.locale;
      this.lstinstallation[this.currentindex].areaname = this.areaname;
      this.lstinstallation[this.currentindex].regionname = this.regionname;
      this.lstinstallation[this.currentindex].googlemap = this.googlemap;


      for (let i = 0; i < this.lstproduct.length; i++) {
        if (this.lstproduct[i].enterquantity > 0) {
          for (let j = 0; j < this.lstproduct[i].enterquantity; j++) {
            this.lstproducttoadd.push({
              productid: this.lstproduct[i].productid,
              id: this.lstproduct[i].id,
              productcategorie: this.lstproduct[i].productcategorie,
              productcategorieid: this.lstproduct[i].productcategorieid,
              quantity: this.lstproduct[i].quantity,
              enterquantity: this.lstproduct[i].enterquantity,
              uom: this.lstproduct[i].uom,
              maxquantity: this.lstproduct[i].maxquantity,
              productname: this.lstproduct[i].productname,
              assignedtoname: this.lstproduct[i].assignedtoname,
              assignedtoID: this.lstproduct[i].assignedtoID,
              installtiondate: null,
              installtionnumber: null,
              completflag: false,
              warrantyMonth: this.lstproduct[i].warrantyMonth,
              pid: null,
              checkselect: false,
              remaininstallation: null,
              serialnumber: this.lstproduct[i].serialnumber,
              warrantyenddate: null,
              approveby: null,
              invoiceprodid: null,
              accountwisemcstatus: this.model.lstinvocicedproduct[i].accountwisemcstatus,

            })
          };

          for (let j = 0; j < this.model.lstinvocicedproduct.length; j++) {
            if (this.model.lstinvocicedproduct[j].productid == this.lstproduct[i].productid) {
              this.model.lstinvocicedproduct[j].quantity = this.model.lstinvocicedproduct[j].quantity - this.lstproduct[i].enterquantity;
            }
          }
          this.lstinstallation[this.currentindex].checkselect = true;
        }

      }
      this.lstinstallation[this.currentindex].lstproduct = this.lstproducttoadd;
      this.notifyService.showSuccess("Address successfully modified ", "success");
      this.modalRef.hide();
    }
  }
  onSelectproductlocality(id) {
    this.sendmodel.localityID = id;
    this.localityID = id;
    // this.locality = this.localitymap.get(id);
    // this.sendmodel.locality = this.localitymap.get(id);
    this.transactionService.getgeographydetails(this.sendmodel)
      .subscribe(data => {
        this.city = data.city;
        this.state = data.state;
        this.country = data.country;
        this.locale = data.locale;
        this.areaname = data.areaname;
        this.areaID = data.areaID1;
        this.locality = data.locality;
        this.regionname = data.regionname;
      });
  }
  getAssignedto(id) {
    this.teammodel.areaid = id;
    this.teammodel.pcInt = [];
    this.teammodel.pincode = this.model.pincode;
    this.teammodel.userid;

    this.model.lstinvocicedproduct.forEach(element => {
      this.teammodel.pcInt.push(element.productcategorieid);
    });
    this.lstassigned = [];
    this.lstassigned.push({ id: 0, name: "Select Assigned To" })
    this.transactionService.getAssignedtoListInst(this.teammodel)
      .subscribe(data => {

        data.forEach(item => {
          this.lstassigned.push({ id: item.id, name: item.name });
        });
        if (this.lstassigned.length == 2) {
          this.lstassigned.forEach(item => {
            if (item.id == this.teammodel.userid) {

              this.savehideflag = true;
            }
          })
        }

      });

  }

  onSelectlstassigtoproduct(id, index, index2) {

    this.lstinstallation[index].lstproduct[index2].assignedtoID = id;
    // alert(this.lstinstallation[index].lstproduct[index2].assignedtoID);


  }
  onSelectlstassigtounitproduct(id, index) {
    this.lstproduct[index].assignedtoID = id;
  }
  onSelectproductlstassig(id, index) {
    this.lstproduct[index].assignedtoID = id;
  }
  onSelectlstassigned(id) {
    if (id == 0) {
      this.notifyService.showRroor("Select Assigned To !!", "Oops!");
      return;
    }
    this.model.assignedtoID = id;
    // this.lstproduct.assignedtoID

    for (let i = 0; i <= this.lstproduct.length; i++) {
      if (this.lstproduct[i].completflag == false) {
        this.lstproduct[i].assignedtoID = this.model.assignedtoID;
      }

    }

    for (let i = 0; i <= this.lstinstallation.length; i++) {
      for (let j = 0; j <= this.lstinstallation[i].lstproduct.length; j++) {
        this.lstinstallation[i].lstproduct[j].assignedtoID = this.model.assignedtoID;
        // alert(this.lstinstallation[i].lstproduct[j].assignedtoID)
      }
    }

  }
  onSelectbranch(id) {

    this.model.branchID = id;
    for (let i = 0; i < this.lstbranch.length; i++) {
      if (this.lstbranch[i].id == id) {
        this.model.branchname = this.lstbranch[i].name
      }
    }
  }

  deleteproduct(index, index2, productid) {
    if (this.lstinstallation[index].lstproduct.length == 1) {
      this.notifyService.showRroor("can't delete at least one product required ", "Opps");
      return;
    }
    this.lstinstallation[index].lstproduct.splice(index2, 1);
    let loction = this.model.lstinvocicedproduct.findIndex(x => x.productid === productid);
    this.model.lstinvocicedproduct[loction].quantity++;

  }
  btnadd() {
    var total = 0;
    var found = this.lstproduct.find(function (x) { return x.checkselect === true; });
    if (!found) {
      this.notifyService.showRroor("Please select atleast one Checkbox", " Oops");
      return;
    }
    if (this.forflag == "new") {
      this.lstinstallation = [];
    }

    // "A" + 1 + (Number(i) + 1),
    this.lstinstallation.push({
      id: null,
      customerid: this.model.customerid,
      accountwiseMacID: null,
      coustomername: this.model.coustomername,
      unit: null,
      mobile: this.model.mobile1,
      email: this.model.email,
      lstproduct: this.demolst,
      address: this.model.address,
      pincode: this.model.pincode,
      localityID: this.model.localityID,
      areaID: this.model.areaID,
      locality: this.model.locality,
      city: this.model.city,
      state: this.model.state,
      country: this.model.country,
      locale: this.model.locale,
      areaname: this.model.areaname,
      regionname: this.model.regionname,
      mobile1: this.model.mobile1,
      mobile2: this.model.mobile2,
      landline: this.model.landline,
      website: this.model.website,
      googlemap: this.model.googlemap,
      checkselect: false,
      buildingName: this.model.buildingName,
    });
    this.modalRef.hide();
    for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {

      this.lstinstallation[i].lstproduct = [];

      for (let j = 0; j < this.lstproduct.length; j++) {
        if (total < this.noofinstall && this.lstproduct[j].checkselect) {
          this.lstinstallation[i].lstproduct.push({
            productid: this.model.lstinvocicedproduct[j].productid,
            id: null,
            productcategorie: this.model.lstinvocicedproduct[j].productcategorie,
            productcategorieid: this.model.lstinvocicedproduct[j].productcategorieid,
            quantity: this.model.lstinvocicedproduct[j].quantity,
            enterquantity: this.model.lstinvocicedproduct[j].enterquantity,
            uom: this.model.lstinvocicedproduct[j].uom,
            maxquantity: this.model.lstinvocicedproduct[j].maxquantity,
            productname: this.model.lstinvocicedproduct[j].productname,
            assignedtoname: this.model.lstinvocicedproduct[j].assignedtoname,
            assignedtoID: this.model.lstinvocicedproduct[j].assignedtoID,
            installtiondate: null,
            installtionnumber: null,
            completflag: false,
            warrantyMonth: this.model.lstinvocicedproduct[i].warrantyMonth,
            pid: this.model.lstinvocicedproduct[j].productid,
            checkselect: false,
            remaininstallation: null,
            serialnumber: this.model.lstinvocicedproduct[i].serialnumber,
            warrantyenddate: null,
            approveby: null,
            accountwisemcstatus: this.model.lstinvocicedproduct[i].accountwisemcstatus,
            invoiceprodid: this.model.lstinvocicedproduct[j].id
          });
          total++;
        }
      }

    }



    this.modalRef.hide();
    for (let i = 0; i <= this.lstinstallation.length; i++) {
      for (let j = 0; j <= this.lstinstallation[i].lstproduct.length; j++) {
        this.lstinstallation[i].lstproduct[j].assignedtoID = this.model.assignedtoID;
        // alert( this.lstinstallation[i].lstproduct[j].assignedtoID)
      }
    }
    // for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {
    //   this.lstinstallation.push({
    //     id: null,
    //     customerid: this.model.customerid,
    //     accountwiseMacID: null,
    //     coustomername: this.model.coustomername,
    //     unit: this.model.unit,
    //     mobile: this.model.mobile1,
    //     email: this.model.email,
    //     lstproduct: this.model.lstinvocicedproduct,
    //     address: this.model.address,
    //     pincode: this.model.pincode,
    //     localityID: this.model.localityID,
    //     areaID: this.model.areaID,
    //     locality: this.model.locality,
    //     city: this.model.city,
    //     state: this.model.state,
    //     country: this.model.country,
    //     locale: this.model.locale,
    //     areaname: this.model.areaname,
    //     regionname: this.model.regionname,
    //     mobile1: this.model.mobile1,
    //     mobile2: this.model.mobile2,
    //     landline: this.model.landline,
    //     website: this.model.website,
    //     googlemap: this.model.googlemap,
    //     checkselect: false,
    //     buildingName: this.model.buildingName,
    //   });


    //   this.lstinstallation[i].lstproduct = [];
    //   for (let j = 0; j < this.model.lstinvocicedproduct.length; j++) {
    //     // alert( this.model.lstinvocicedproduct[j].productid)
    //     if (i< this.model.lstinvocicedproduct[j].enterquantity) {
    //     this.lstinstallation[i].lstproduct.push({
    //       productid: this.model.lstinvocicedproduct[j].productid,
    //       id: null,
    //       productcategorie: this.model.lstinvocicedproduct[j].productcategorie,
    //       productcategorieid: this.model.lstinvocicedproduct[j].productcategorieid,
    //       quantity: this.model.lstinvocicedproduct[j].quantity,
    //       enterquantity: this.model.lstinvocicedproduct[j].enterquantity,
    //       uom: this.model.lstinvocicedproduct[j].uom,
    //       maxquantity: this.model.lstinvocicedproduct[j].maxquantity,
    //       productname: this.model.lstinvocicedproduct[j].productname,
    //       assignedtoname: this.model.lstinvocicedproduct[j].assignedtoname,
    //       assignedtoID: this.model.lstinvocicedproduct[j].assignedtoID = 1,
    //       installtiondate: null,
    //       installtionnumber: null,
    //       completflag: false,
    //       warrantyMonth: null,
    //       pid: this.model.lstinvocicedproduct[j].productid,
    //       checkselect: false,
    //       remaininstallation: null,
    //       serialnumber: this.model.lstinvocicedproduct[i].serialnumber,
    //       warrantyenddate: null,
    //       approveby: null,
    //       invoiceprodid:this.model.lstinvocicedproduct[j].id
    //     })
    //   }
    // }

    //   for (let i = 0; i < this.lstinstallation.length; i++) {
    //     for (let j = 0; j < this.lstinstallation[i].lstproduct.length; j++) {
    //       this.lstinstallation[i].lstproduct[j].assignedtoID = 1;
    //     }
    //   }
    // }
    // this.modalRef.hide();
  }
  btnsave() {
   
    if (this.model.assignedtoID == null) {
      this.notifyService.showRroor("Please Select Assign To", "Opps");
      return;
    }
    if (this.model.branchID == 0) {
      this.notifyService.showRroor("Select Status !!", "Oops!");
      return;
    }
    if (this.model.branchID == null) {
      this.notifyService.showRroor("Please Select Branch", "Opps");
      return;
    }

    // if (this.lstinstallation.length <= 0) {
    //   this.notifyService.showRroor("Please Select At least One installation", "Opps");
    //   return;
    // }
    let flag2: boolean = false;
    this.lstinstallation.forEach(element => {
      if (element.checkselect) {
        flag2 = true;
      }
    });
    // if (!flag2) {
    //   this.notifyService.showRroor("Please Select At least one installation Product", "Opps");
    //   return;
    // }
    this.model.userid = Number(localStorage.getItem("userid"));
    this.model.lstinstallation.length
    this.model.lstinstallation = [];
    this.model.lstinstallation = this.lstinstallation;
    this.model.lstproduct = this.lstproduct;
    this.showBtn = true;
    this.installationService.CreateInstallation(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          this.noofinstall = this.compinstalltion
          this.notifyService.showSuccess("Installation Create Successfully", "Success");
          this.router.navigate(['/Installation Dashbord']);
        }
      });


  }

  btnclosecall() {

    // this.model.userid = Number(localStorage.getItem("userid"));
    // this.model.lstinstallation = this.lstinstallation;
    // this.installationService.btnclosecall(this.model)
    //   .subscribe(data => {
    //     if (data.status == "OK") {
    //       this.notifyService.showSuccess("Installation Create Successfully", "Success");
    //       this.router.navigate(['/Installation Dashbord']);
    //     }
    //   });

    this.model.userid = Number(localStorage.getItem("userid"));
    this.model.lstinstallation = this.lstinstallation;
    this.model.id = this.model.orderaccID;
    this.installationService.closecall(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("Installation Close Successfully", "Success");
          this.router.navigate(['/Installation Dashbord']);
        }
      });


  }
  btnclose() {
    this.modalRef.hide();
  }
  quantityupdate() {
    // this.noofinstallperproduct=0;
    if (this.noofinstall > this.model.totalquantity) {
      this.notifyService.showRroor("Not More than total Quantity", "Opps");
      this.noofinstall = this.model.totalquantity;
    }
    this.noofinstallperproduct = this.noofinstall / this.model.lstinvocicedproduct.length;
    Math.round(this.noofinstallperproduct)
    this.model.lstinvocicedproduct.forEach(element => {
      if (this.noofinstallperproduct >= element.maxquantity) {
        element.enterquantity = element.maxquantity;
        this.noofinstallperproduct = this.noofinstallperproduct + (this.noofinstallperproduct - element.maxquantity);
      } else {
        element.enterquantity = this.noofinstallperproduct;
      }
    });
  }


  productquantity(productid, index) {
    for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {
      if (this.model.lstinvocicedproduct[i].productid == productid) {
        if (this.lstproduct[index].enterquantity > this.model.lstinvocicedproduct[i].quantity) {
          this.notifyService.showRroor("Not More than Quantity", "Opps");
          this.lstproduct[index].enterquantity = this.model.lstinvocicedproduct[i].quantity;

        }
      }
    }
  }
  Enterquantity(id, index) {
    this.noofinstall = 0;
    for (let i = 0; i < this.lstproduct.length; i++) {
      if (this.lstproduct[i].checkselect == true || this.lstproduct[i].enterquantity != 0) {
        this.noofinstall = Number(this.noofinstall) + Number(this.lstproduct[i].enterquantity);
        this.lstproduct[i].checkselect = true
      }
    }

    for (let i = 0; i < this.model.lstinvocicedproduct.length; i++) {
      // if (this.lstproduct[index].checkselect == false) {
      //   this.notifyService.showRroor("Please select atleast one product !", "Opps");
      // }
      if (this.lstproduct[index].enterquantity > this.lstproduct[index].quantity) {
        this.lstproduct[index].enterquantity = this.lstproduct[index].quantity
        this.notifyService.showRroor("Not More than Quantity", "Opps");
      }
      this.lstproduct[index].remaininstallation = this.lstproduct[index].quantity - this.lstproduct[index].enterquantity

    }
    this.model.lstinvocicedproduct = this.lstproduct;

  }
  enteredMonth() {
    this.lstinstallation.forEach(element => {
      element.lstproduct.forEach(productelement => {
        console.log("---", productelement.warrantyenddate)
        if (productelement.warrantyMonth != null) {
          var date1 = new Date(productelement.installtiondate)
          productelement.warrantyenddate = new Date(date1.setMonth(date1.getMonth() + productelement.warrantyMonth))
        }
      });
    });
  }
  btnselectAllProduct($event) {
    for (let i = 0; i < this.lstproduct.length; i++) {
      if ($event.target.checked) {
        this.lstproduct[i].checkselect = true;
      } else {
        this.lstproduct[i].checkselect = false;
      }
    }
  }
  redirectUser(mainmenulabel: string) {////Resp
    this.router.navigate(['/' + mainmenulabel]);
  }
  btnlogout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}