import { OnInit, Component, OnDestroy } from '@angular/core';
import { MasterServices, AlertService, TransactionServices } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { DefinePipelineModel } from './DefinePipelineModel';
import { CustomerTypeModel } from './DefinePipelineModel';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
@Component({
    selector: 'app-login',
    templateUrl: 'DefinePipeline.html'
})
export class DefinePipeline implements OnInit,OnDestroy {
    checkselect: boolean;
    chkselect: boolean;
    model = new DefinePipelineModel();
    id: number;
    pagename: string;
    sub: any;
    str: string;
    modellst: DefinePipelineModel[] = [];
    customerTypelst:CustomerTypeModel[]=[];
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
    }
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "Quotation Preparation" },
        { id: 2, "name": "Quotation Submission" },
        { id: 3, "name": "Quotation Decision" },
        { id: 4, "name": "No Action" },
        { id: 5, "name": "Assign To" },
        
    ];
    ngOnInit(): void {

        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getCodeByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.modellst = data.modellst;
                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";

        } else {
            this.getCode();
        }
    }
    getCode() {
        this.Masterservice.getCode(this.model)
            .subscribe(value => {
                this.modellst = value;
            });
    }

    onSelectAction(name, id) {
        for (let j = 0; j < this.modellst.length; j++) {
            if (this.modellst[j].tbtask == id) {
                this.modellst[j].action = name
            }
        }
    }


    onSelectModule(id) {

    }

    btnsave($event) {
        if (this.model.stageCode == null || this.model.stageCode == "") {
            this.notifyService.showRroor("Enter  StageCode !!", "Oops!");
            return;
        }
        if (this.model.description == null || this.model.description == "") {
            this.notifyService.showRroor("Enter  Description !!", "Oops!");
            return;
        }
        if (this.model.percentProbability == null) {
            this.notifyService.showRroor("Enter Percent Probability  !!", "Oops!");
            return;
        }
        if (this.model.hirarchy == null) {
            this.notifyService.showRroor("Enter hirarchy  !!", "Oops!");
            return;
        }

        var found = this.modellst.find(function (x) { return x.checkselect === true; });
        if (!found) {
            this.notifyService.showRroor("Please select atleast one Checkbox", " Oops");
            return;
        }
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].checkselect == true) {
                if (this.modellst[i].sequence == null) {
                    this.notifyService.showRroor("Please enter Sequence of selected columns", " Oops");
                    return;
                }
                if (this.modellst[i].action == null) {
                    this.notifyService.showRroor("Please Select Action of selected columns", " Oops");
                    return;
                }
            }
        }


        this.model.modellst = this.modellst
        this.Masterservice.savepipeline(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['Pipeline']);
                    } else {
                        if (data.message == null) {
                            this.notifyService.showRroor("Something wrong", "Oops !")
                        } else {
                            this.notifyService.showRroor(data.message, "Oops !")
                        }

                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    // onselecttaskName($event) {
    //     if ($event.target.checked) {
    //         for (let i = 0; i < this.model.modellst.length; i++) {
    //             this.model.modellst[i].checkselect = true;
    //         }
    //     } else {
    //         // this.model.modulelst = [];
    //         for (let i = 0; i < this.model.modellst.length; i++) {
    //             this.model.modellst[i].checkselect = false;
    //         }
    //     }
    // }

    cancle() {
        this.router.navigate(["/Pipeline"]);
    }
}