import { OnInit, Component, TemplateRef } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { DispatchOrderHomeModel } from './DispatchOrderHomeModel';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models/user';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { InvoiceModel } from './DispatchOrderHomeModel';
import { ReportServices } from 'src/app/_services/ReportServices';
import convertor from 'rupees-to-words';

import { isTryStatement } from 'typescript';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
    selector: 'app-DispatchOrderHome',
    templateUrl: 'DispatchOrderHome.html',
})
export class DispatchOrderHome implements OnInit {
    constructor(private masterservice: MasterServices, private reportServices: ReportServices, private modalService: BsModalService, private transactionservice: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private deviceService: DeviceDetectorService) {
    }

    searchtype: DropDownModel[] = [
        { id: 0, name: "All" },
        { id: 1, name: "Customer Name" },
        { id: 2, name: "Customer Type" },
        { id: 3, name: "Mobile Number" }
    ]
    user = new User();
    modalRef: BsModalRef;
    orderlist: DispatchOrderHomeModel[] = [];
    orderlist1: DispatchOrderHomeModel[] = [];
    grandtotalinwords: string;
    model = new DispatchOrderHomeModel();
    invModel = new InvoiceModel();
    isMobile: boolean;
    chkopen: boolean;
    deviceInfo = null;
    p: any;
    q: any;
    sum: number = 1;
    ngOnInit(): void {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getAllAcceptedOrders()

    }
    getAllAcceptedOrders() {
        this.orderlist = [];
        this.user.pageno = this.sum;
        this.user.userid = this.model.userid;
        this.transactionservice.getAllAcceptedOrders(this.user)
            .subscribe(
                data => {
                    this.orderlist = data
                    for (let i = 0; i < this.orderlist.length; i++) {
                        this.user.totalcount = this.orderlist[i].totalcount;
                    }
                    this.orderlist1 = data
                });
    }

    onSelectSrch(val) {
        this.model.txtselect = val;

    }
    // this.orderlist1.push({"txtserch":item.txtserch,"srno":item.srno,"id":item.id,"oanumber":item.oanumber,"customername":item.customername,"customertype":item.customertype,"status":item.status,"oadate":item.oadate,"ordercomplete":item.ordercomplete,"invNo":item.invNo,"invDate":item.invDate,"invId":item.invId,"grandtotal":item.grandtotal,"retail":item.retail,"quotationID":item.quotationID,"txtselect":item.txtselect,"invoicelst":item.invoicelst});
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            // this.getLocalities(0, 10);
            this.getAllAcceptedOrders();
            this.model.txtserch = null;
        } else {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                this.notifyService.showRroor("Enter Criteria To Search", "Oops");
                return;
            }
            this.user.message = this.model.txtselect
            this.user.sendstring = this.model.txtserch

            this.transactionservice.getOrdersSearchLike(this.user)
                .subscribe(value => {
                    this.orderlist = value;
                    for (let i = 0; i < this.orderlist.length; i++) {
                        this.user.totalcount = this.orderlist[i].totalcount;
                    }
                    this.orderlist1 = value
                });
        }
        // if (this.model.txtselect == "All") {
        //     this.getAllAcceptedOrders();
        //     this.model.txtserch = null;
        // }
        // else if (this.model.txtselect == "Customer Name") {
        // if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.orderlist1 = [];
        //         this.orderlist.forEach((item) => {
        //             if(item.customername!=null){
        //             if ((item.customername.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.orderlist1.push({"txtserch":item.txtserch,"srno":item.srno,"id":item.id,"oanumber":item.oanumber,"customername":item.customername,"customertype":item.customertype,"status":item.status,"oadate":item.oadate,"ordercomplete":item.ordercomplete,"invNo":item.invNo,"invDate":item.invDate,"invId":item.invId,"grandtotal":item.grandtotal,"retail":item.retail,"quotationID":item.quotationID,"txtselect":item.txtselect,"invoicelst":item.invoicelst,"telephone":item.telephone,"count":item.count});
        //             }
        //         }
        //         });
        //         this.orderlist = this.orderlist1;

        //     }
        // }
        // else if (this.model.txtselect == "Customer Type") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {

        //         this.orderlist1 = [];
        //         this.orderlist.forEach((item) => {
        //             if(item.customertype!=null){
        //                 if ((item.customertype.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                     this.orderlist1.push({"txtserch":item.txtserch,"srno":item.srno,"id":item.id,"oanumber":item.oanumber,"customername":item.customername,"customertype":item.customertype,"status":item.status,"oadate":item.oadate,"ordercomplete":item.ordercomplete,"invNo":item.invNo,"invDate":item.invDate,"invId":item.invId,"grandtotal":item.grandtotal,"retail":item.retail,"quotationID":item.quotationID,"txtselect":item.txtselect,"invoicelst":item.invoicelst,"telephone":item.telephone,"count":item.count});
        //                 }
        //             }

        //         });
        //         this.orderlist = this.orderlist1;
        //     }
        // }
        // else if (this.model.txtselect == "Mobile Number") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {

        //         this.orderlist1 = [];
        //         this.orderlist.forEach((item) => {
        //             if(item.telephone!=null){
        //                 if ((item.telephone.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                     this.orderlist1.push({"txtserch":item.txtserch,"srno":item.srno,"id":item.id,"oanumber":item.oanumber,"customername":item.customername,"customertype":item.customertype,"status":item.status,"oadate":item.oadate,"ordercomplete":item.ordercomplete,"invNo":item.invNo,"invDate":item.invDate,"invId":item.invId,"grandtotal":item.grandtotal,"retail":item.retail,"quotationID":item.quotationID,"txtselect":item.txtselect,"invoicelst":item.invoicelst,"telephone":item.telephone,"count":item.count});
        //                 }
        //             }
        //         });
        //         this.orderlist = this.orderlist1;
        //     }
        // }

    }

    btnprintInvo(Invoice: TemplateRef<any>, invId) {
        this.invModel = null;
        let user = new User();
        user.trnid = invId;
        this.reportServices.getInvoiceInfo(user)
            .subscribe(
                data => {
                    this.invModel = data
                    this.grandtotalinwords = convertor(this.invModel.grandtotal.toFixed(2));
                    this.invModel.netamount = 0 ;
                    for(let i = 0 ; i < this.invModel.invoiceProducts.length; i++)
                    {
                        this.invModel.netamount = this.invModel.netamount + this.invModel.invoiceProducts[i].totalproamount
                        this.invModel.netamount = Math.round(this.invModel.netamount);
                        this.invModel.totalgst =this.invModel.totalgst+ this.invModel.invoiceProducts[i].taxamount
                        this.invModel.totalcount=this.invModel.totalcount+this.invModel.invoiceProducts[i].noGstAmmount
                        this.invModel.invoiceProducts[i].totalproamount = Math.round(this.invModel.invoiceProducts[i].totalproamount)
                    }
                    this.invModel.grandtotal = this.invModel.netamount - this.invModel.discountamount;
                });


        this.modalRef = this.modalService.show(Invoice, {
            class: 'modal-lg'
        });


    }
    onchkopen(id) {
        for (let i = 0; i < this.orderlist.length; i++) {
            if (this.orderlist[i].id == id) {
                this.orderlist[i].chkopen = !this.orderlist[i].chkopen;
            }
        }
    }
    btnedit(editid, quotationID) {
        // let navigationExtras: NavigationExtras = {
        //     queryParams: {
        //         "editid": editid,
        //         "quoid": quotationID,
        //     }
        // };

        this.router.navigate(['/editDispatch', editid])
    }

    btnprint() {
        var divToPrint = document.getElementById('inv');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    showPageIndex(pageIndex) {
        this.sum = pageIndex;
        this.user.pageno = this.sum;
        // this.user.pageSize = this.opportunitieslst[this.opportunitieslst.length - 1].id + 9;
        if (this.model.txtselect == null && this.model.txtserch == null) {
            this.getAllAcceptedOrders();
        }
        else {
            this.user.message = this.model.txtselect
            this.user.sendstring = this.model.txtserch
            this.transactionservice.getOrdersSearchLike(this.user)
                .subscribe(value => {
                    this.orderlist = value;
                    for (let i = 0; i < this.orderlist.length; i++) {
                        this.user.totalcount = this.orderlist[i].totalcount;
                    }
                    this.orderlist1 = value
                });
        }
    }
}