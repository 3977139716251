import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { DealersDetailModel, DefinedlrcontModel } from "src/app/DealersDetail/DealersDetailModel";
import { ProspectModel } from "src/app/Transactions/Sales-Transaction/ProspectModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService, TransactionServices, DealersService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { VendorService } from "src/app/_services/VendorService";
import { usertwofieldModel } from "../ResourceMaster/usertwofieldModel";
import { Contacts, DefineVendorModel } from "./DefineVendorModel";

declare var $: any;
@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineVendor.html'
})
export class DefineVendor implements OnInit {
    constructor(private Masterservice: MasterServices, private vendorService: VendorService, private transactionService: TransactionServices, private modalService: BsModalService, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private dealersService: DealersService,) {
    }
    // lstlocalityforproduct: DropDownModel[];

    keyword = 'name';
    pincodelist = [];

    p: any;
    sub: any;
    id: number;
    userList2: any;
    modalRef: BsModalRef;
    chkgst: boolean;
    user = new User();
    localitymap = new Map();
    model = new DefineVendorModel();
    dmodel = new DealersDetailModel();
    modelc = new DefinedlrcontModel();
    lstlocality: DropDownModel[] = [];
    lstlocalitys: DropDownModel[] = [];
    designationlst: usertwofieldModel[] = [];

    contactlst1: Contacts[] = []
    pros = new ProspectModel();
    contactmo = new Contacts();

    ngOnInit(): void {
        this.getDept();
        this.dmodel.vendor = "V";
        this.dmodel.userId = Number(localStorage.getItem("userid"));

        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.dmodel.id = this.id;

            this.dealersService.getdealersdetailsById(this.dmodel)
                .subscribe(
                    data => {
                        this.dmodel = data;

                        if (data.chkwihitins) {

                            this.model.chkwihitins = true;
                            this.model.gsttype = "InS"
                        } else if (data.chkoutsides) {
                            this.model.chkoutsides = true;
                            this.model.gsttype = "OutS"
                        }

                        this.contactlst1 = data.contactlst1;
                        this.dmodel.pincode = data.pincode;
                        this.pros.pincode = data.pincode;
                        
                        this.lstlocality = [];
                        this.lstlocalitys.push({ id: 0, name: "Select Locality" });
                        this.transactionService.getlocality(this.pros)
                            .subscribe(data => {
                                data.forEach(item => {
                                    this.lstlocalitys.push({ id: item.id, name: item.name });
                                });
                            });
                        this.dmodel.localityID = data.localityID

                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('code'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('name'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('address'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('landline'));
            l4.className = "active";
            let l5 = (<HTMLInputElement>document.getElementById('email'));
            l5.className = "active";
            let l6 = (<HTMLInputElement>document.getElementById('website'));
            l6.className = "active";
            let l7 = (<HTMLInputElement>document.getElementById('lable13'));
            l7.className = "active";
            let l8 = (<HTMLInputElement>document.getElementById('lable15'));
            l8.className = "active";

        }

    }



    // typeaheadOnSelectPincode(id, name) {
    //     // this.dmodel.id = id;
    //     this.dmodel.pincode = name;
    //     this.lstlocalitys = [];
    //     this.pros.pincode = name;
    //     this.lstlocalitys.push({ id: 0, name: "Select Locality" });
    //     this.transactionService.getlocality(this.pros)
    //         .subscribe(data => {
    //             data.forEach(item => {
    //                 this.lstlocalitys.push({ id: item.id, name: item.name });
    //             });
    //         });
    // }
    onSelectlocality1(id) {
        if (id == 0) {
            this.notifyService.showRroor("Select Locality!!", "Oops!");
            return;
        }
        // alert(this.pros.pincode) 
        this.dmodel.localityID = id;
        this.pros.localityID = id;
        this.dmodel.locality = this.localitymap.get(id);
        this.pros.locality = this.localitymap.get(id);
        this.transactionService.getgeographydetails(this.pros)
            .subscribe(data => {
                this.dmodel.city = data.city;
                this.dmodel.cityid = data.cityid;
                this.dmodel.state = data.state;
                this.dmodel.country = data.country;
            });
    }

    btnAddContact(contactmodel: TemplateRef<any>) {

        this.modalRef = this.modalService.show(contactmodel, {
            class: 'modal-lx'
        });
    }
    onSelectdesiganation(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Designation !!", "Oops!");
            return;
        }

        for (let i = 0; i < this.designationlst.length; i++) {
            if (this.designationlst[i].id == value) {
                this.contactmo.designationID = this.designationlst[i].id;
                this.contactmo.designation = this.designationlst[i].name
            }
        }

    }

    getDept() {
        let user = new User();
        this.designationlst = [];
        this.Masterservice.getDept(user)
            .subscribe((value) => {
                this.designationlst = value.desiglst
            });
    }
    btnaddcontact() {
        if (this.contactmo.contactName == null) {
            this.notifyService  .showRroor("Enter Name  !!", "Oops!");
            return;
        }
        if (this.contactmo.designation == null) {
            this.notifyService.showRroor("Select Designation  !!", "Oops!");
            return;
        }
        if (this.contactmo.mobileNo1 == null) {
            this.notifyService.showRroor("Enter Mobile Number  !!", "Oops!");
            return;
        }
        if (this.contactmo.email == null) {
            this.notifyService.showRroor("Enter Email Address !!", "Oops!");
            return;
        }

        this.contactlst1.push({
            id: null,
            contactName: this.contactmo.contactName,
            designation: this.contactmo.designation,
            designationID: this.contactmo.designationID,
            mobileNo1: this.contactmo.mobileNo1,
            email: this.contactmo.email,
            chkcontact: true

        })
        this.contactmo.contactName = null,
            this.contactmo.designation = null,
            this.contactmo.designationID = null,
            this.contactmo.mobileNo1 = null,
            this.contactmo.email = null,
            this.contactmo.chkcontact = false

        this.modalRef.hide()
    }

    onchkwihitins() {
        this.dmodel.chkoutsides = false
        this.dmodel.chkwihitins = true
        this.dmodel.gsttype = "InS"

    }
    onchkoutsides() {
        this.dmodel.chkwihitins = false
        this.dmodel.chkoutsides = true
        this.dmodel.gsttype = "OutS"

    }
    cancle() {
        this.router.navigate(["/Vendor"]);
    }
    btnsave($event) {

        if (this.dmodel.code == null) {
            this.notifyService.showRroor("Please Enter Vendor Code  !!", "Oops!");
            return;
        }
        if (this.dmodel.name == null) {
            this.notifyService.showRroor("Please Enter Vendor Name  !!", "Oops!");
            return;
        }
        if (this.dmodel.address == null) {
            this.notifyService.showRroor("Please Enter Address  !!", "Oops!");
            return;
        }
        if (this.dmodel.pincode == null) {
            this.notifyService.showRroor("Please Enter Pincode  !!", "Oops!");
            return;
        }
        if (this.dmodel.localityID == null) {
            this.notifyService.showRroor("Please Select Locality  !!", "Oops!");
            return;
        }
        if (this.dmodel.landline == null) {
            this.notifyService.showRroor("Please Enter Landline Number !!", "Oops!");
            return;
        }
        if (this.dmodel.email == null) {
            this.notifyService.showRroor("Enter Email Address !!", "Oops!");
            return;
        }
        if (this.dmodel.website == null) {
            this.notifyService.showRroor("Please Enter Website !!", "Oops!");
            return;
        }
        this.dmodel.userId = Number(localStorage.getItem("userid"));
        this.dmodel.contactlst1 = [];
        this.dmodel.contactlst1 = this.contactlst1
        this.dealersService.savedealersdetail(this.dmodel)
            .subscribe(
                data => {

                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Vendor']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
    selectEvent(item) {
        this.pros.pincode = item.name;
        this.dmodel.pincode = item.name;
        this.lstlocalitys = [];
        this.lstlocalitys.push({ id: 0, name: "Select Locality" });
        this.transactionService.getlocality(this.pros)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocalitys.push({ id: item.id, name: item.name });
                });
                this.dmodel.pincode = item.name;
            });
    }

    onChangeSearch(search: string) {

        this.user.message = search;
        this.transactionService.getpincodelike(this.user)
            .subscribe(data => {
                this.pincodelist = [];
                data.forEach((item) => this.pincodelist.push({ "id": item.id, "name": item.name }));

            });

    }
    onFocused(e) {

    }
}
