import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { Location, } from '@angular/common';
import { AlertService, MasterServices, TransactionServices, AuthenticationService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";

import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { DefineOrderAcceptanceModel, producModel } from "./DefineOrderAcceptanceModel";
import { truncate, truncateSync } from "fs";
import { QuotationHistory } from '../Quotation/QuotationModel';
import { ProspectModel } from '../Sales-Transaction/ProspectModel';
import { ReportServices } from "src/app/_services/ReportServices";
import { DeviceDetectorService } from 'ngx-device-detector';

var converter = require('number-to-words');
import convertor from 'rupees-to-words';
import { ThrowStmt } from "@angular/compiler";
declare var $: any;
@Component({
    selector: 'app-order',
    templateUrl: 'DefineOrderAcceptance.html'
})
export class DefineOrderAcceptance implements OnInit {
    user = new User();
    model = new DefineOrderAcceptanceModel();
    pomodel = new ProspectModel();
    id: number;
    modalRef: BsModalRef;
    modalRef2: BsModalRef;
    modalRef5: BsModalRef;
    modellst: producModel[] = [];
    addresslst: producModel[] = [];
    termmodlst: producModel[] = [];
    lstlocality: DropDownModel[] = [];
    userList2: DropDownModel[] = [];
    localitymap = new Map();
    qversionlst: QuotationHistory[] = []
    pricevalue: number;
    alltotal: number;
    oadate: Date;
    avialablecredit: number;
    dealerflag: boolean;
    dealerflag1: string;
    afterbal: boolean;
    resourcelist = [];
    keyword = 'name';
    public termscondtion: string;
    public indexno: number;
    public termsid: number;
    public pcheckselect: boolean = true;
    public termscondtions: boolean = false;
    alltotalword: String;
    totalgstvalue: number;
    public i;
    action: string;
    sub: any;
    public chkNewAdderss: boolean = false;
    pincodelist1 = [];
    totalqty: number;
    isMobile: boolean;
    deviceInfo = null;
    username: string;
    showBtn: boolean = false;
    paymenttermslst: DropDownModel[] = [
        { id: 1, "name": "Against Order" },
        { id: 2, "name": "Against Invoice" },
        { id: 3, "name": "Against Installation" }
    ]
    constructor(private deviceService: DeviceDetectorService, private Masterservice: MasterServices, private reportServices: ReportServices, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, private authenticationService: AuthenticationService) {
    }
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.username = localStorage.getItem('fullname');

        this.oadate = new Date()
        // this.modalRef.hide();
        this.route.queryParams.subscribe(params => {
            this.id = + params["id"];
            this.dealerflag = params["dealerflag"]
            this.action = params["flag"]
        });
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        // this.model.creditdays = 0;
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.model.dealerflag = this.dealerflag;

            this.pricevalue = 0;
            this.totalgstvalue = 0;
            this.alltotal = 0;
            this.transactionService.getOrderByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.gsttype = data.gsttype;
                        if (this.model.gsttype == "OutS") {
                            this.model.chkoutsides = true;
                        } else if (this.model.gsttype == "Ins") {
                            this.model.chkwihitins = true;
                        }

                        if (this.model.panno != null) {
                            this.model.chkpan = true;
                        }

                        this.model.modellst = data.modellst
                        this.termmodlst = data.termmodlst;
                        this.onChangeOfqantity()
                        this.qversionlst = data.qversionlst
                        // if (this.model.avialablecredit < this.model.alltotal) {
                        //     this.model.limit = true;
                        // }
                        // if (this.model.afterordercredit > 0) {
                        //     this.model.afterbal = true;
                        // }

                        if (this.model.bcreditlimit < 0) {
                            this.model.limit = true;
                        }
                        if (this.model.afterordercredit < 0) {
                            this.model.afterbal = true;
                        }
                        if (this.model.avialablecredit == null || this.model.avialablecredit == 0) {
                            this.model.avialablecredit = 0.00;
                        }

                        this.model.id = this.id;
                        this.model.dealerflag = data.dealerflag;
                        this.transactionService.getaddressbycustomer(this.model)
                            .subscribe(
                                data => {
                                    this.model.addresslst = data.addresslst

                                    if (this.model.newgsttype == "OutS") {
                                        this.model.newchkoutsides = true;
                                    } else if (this.model.newgsttype == "Ins") {
                                        this.model.newchkwihitins = true;
                                    }
                                    if (this.model.accountid != null) {
                                        for (let i = 0; i < this.model.addresslst.length; i++) {
                                            if (this.model.addresslst[i].accountid == this.model.accountid) {
                                                this.model.addresslst[i].checkselect = true;
                                            }
                                        }
                                    }
                                },
                                error => {
                                    this.alertService.error(error);
                                });

                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";
            let l11 = (<HTMLInputElement>document.getElementById('lable11'));
            l11.className = "active";
            let l12 = (<HTMLInputElement>document.getElementById('lable12'));
            l12.className = "active";

        }

    }

    getPincodeLike($event) {
        let userId = (<HTMLInputElement>document.getElementById('pincode1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getpincodelike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
        if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
            this.lstlocality = [];
        }
    }
    typeaheadOnSelect(id, name) {
        this.pomodel.pincode = name;
        this.model.pincode = name;
        this.lstlocality = [];
        this.transactionService.getlocality(this.pomodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocality.push({ id: item.id, name: item.name });
                    this.localitymap.set(item.id, item.name)
                });
            });
    }

    onSelectLocality(id) {
        this.model.localityid = id;
        this.pomodel.localityID = id;
        this.model.locality = this.localitymap.get(id);

        this.transactionService.getgeographydetails(this.pomodel)
            .subscribe(data => {
                this.model.locality = data.locality;
            });
    }
    // onchkwihitins($event) {

    //     this.model.gsttype = "InS";

    // }
    // onchkoutsides($event) {
    //     this.model.gsttype = "OutS";
    // }
    onchkwihitins($event) {
        this.model.gsttype = "";
        if ($event.target.checked) {
            this.model.gsttype = "InS"
        }
        else {
            this.model.gsttype = ""
        }
    }
    onchkoutsides($event) {
        this.model.gsttype = "";
        if ($event.target.checked) {
            this.model.gsttype = "OutS"
        }
        else {
            this.model.gsttype = ""
        }
    }
    onconsigneechkwihitins($event) {
        this.model.consigneegsttype = "";
        if ($event.target.checked) {
            this.model.consigneegsttype = "Within State"
        }
        else {
            this.model.consigneegsttype = ""
        }

    }
    onconsigneechkoutsides($event) {

        this.model.consigneegsttype = "";
        if ($event.target.checked) {
            this.model.consigneegsttype = "Outside State"
        }
        else {
            this.model.consigneegsttype = ""
        }

    }

    btnConsigneeModal(ConsigneeModal: TemplateRef<any>) {

        this.modalRef5 = this.modalService.show(ConsigneeModal, {
            class: 'modal-lg'
        });
    }

    // btnsaveConsignee() {
    //     this.model.address = this.model.caddress + ", " + this.model.pincode
    //     this.modalRef5.hide()
    // }
    btnsaveConsigneenew() {
        this.model.address1 = this.model.consigneename + "," + this.model.consigneeaddress + "," + this.model.consigneepincode
            + "," + this.model.consigneechkwihitins + "," + this.model.consigneechkoutsides + "," + this.model.consigneegstno + "," +
            this.model.consigneegsttype;
        // this.model.consigneeID = this.model.accountid;
        if (this.model.consigneegsttype == "Within State") {

            this.model.chkwihitins = this.model.consigneechkwihitins;
            this.model.chkwihitins = true;
            this.model.chkoutsides = false;
        }
        else if (this.model.consigneegsttype == "Outside State") {
            this.model.chkoutsides = this.model.consigneechkoutsides;
            this.model.chkoutsides = true;
            this.model.chkwihitins = false;
        }
        this.model.gstno = this.model.consigneegstno;
        this.modalRef5.hide()


    }


    openshippingmodel(value, changemodel: TemplateRef<any>) {
        this.model.id = this.id;
        this.model.dealerflag = this.dealerflag;
        this.transactionService.getaddressbycustomer(this.model)
            .subscribe(
                data => {
                    this.model.addresslst = data.addresslst;
                    if (this.model.accountid != null) {
                        for (let i = 0; i < this.model.addresslst.length; i++) {
                            // alert(this.model.addresslst[i].id)
                            if (this.model.addresslst[i].accountid == this.model.accountid) {
                                // alert(this.model.addresslst[i].cheselect)
                                this.model.addresslst[i].checkselect = true;
                            }
                        }
                    }
                });
        this.modalRef = this.modalService.show(changemodel, {
            class: 'modal-lg'
        });


    }
    openshippingmodelformobile(value) {
        this.model.id = this.id;
        this.model.dealerflag = this.dealerflag;
        this.transactionService.getaddressbycustomer(this.model)
            .subscribe(
                data => {
                    this.model.addresslst = data.addresslst;
                    if (this.model.accountid != null) {
                        for (let i = 0; i < this.model.addresslst.length; i++) {
                            // alert(this.model.addresslst[i].id)
                            if (this.model.addresslst[i].accountid == this.model.accountid) {
                                // alert(this.model.addresslst[i].cheselect)
                                this.model.addresslst[i].checkselect = true;
                            }
                        }
                    }
                });
    }

    onselectaddress(value, $event, i) {

        for (let i = 0; i < this.model.modellst.length; i++) {
            if (this.model.addresslst[i].accountid == value) {
                this.model.addresslst[i].cheselect = true;

                this.model.accountid = value;
            } else {
                this.model.addresslst[i].cheselect = false;
            }
        }
    }
    closemodel() {
        this.modalRef.hide();
    }

    onSelectProduct($event, id) {
        for (let i = 0; i < this.model.modellst.length; i++) {
            if (this.model.modellst[i].quotationProID == id) {
                if ($event.target.checked) {
                    this.model.modellst[i].procheckselect = true;
                } else {
                    this.model.modellst[i].procheckselect = false;
                }
            }
        }
        this.onChangeOfqantity();
    }

    onChangeOfqantity() {
        this.pricevalue = 0;
        this.totalgstvalue = 0;
        this.alltotal = 0;
        this.model.alltotal = 0;
        this.model.pricevalue = 0;
        this.model.totalgstvalue = 0;

        for (let i = 0; i < this.model.modellst.length; i++) {
            if (this.model.modellst[i].procheckselect && this.model.modellst[i].ratewithdiscount == null) {
                // this.model.modellst[i].discountAmount = (this.model.modellst[i].pricevalue * this.model.modellst[i].discount * this.model.modellst[i].quantity) / 100;
                this.model.modellst[i].discountAmount = ((((this.model.modellst[i].pricevalue * this.model.modellst[i].quantity * this.model.modellst[i].gstrate) / 100 ) + (this.model.modellst[i].pricevalue * this.model.modellst[i].quantity)) * this.model.modellst[i].discount / 100); 
                let tax1 = (this.model.modellst[i].price * this.model.modellst[i].gstrate) / 100;
                this.model.modellst[i].gstamount = (tax1 * this.model.modellst[i].quantity)
                this.model.modellst[i].gstamount = Math.round(this.model.modellst[i].gstamount);
                this.model.modellst[i].totalAmount = (this.model.modellst[i].price * this.model.modellst[i].quantity) + this.model.modellst[i].gstamount
                this.totalgstvalue = this.totalgstvalue + this.model.modellst[i].gstamount
                this.model.totalgstvalue = this.totalgstvalue
                this.pricevalue = this.pricevalue + (this.model.modellst[i].quantity * this.model.modellst[i].price);
                this.model.pricevalue = this.pricevalue;
                this.alltotal = Math.round(this.totalgstvalue + this.pricevalue)
                this.model.alltotal = this.alltotal
                this.alltotalword = convertor(this.alltotal.toFixed(2));
                this.model.afterordercredit = this.model.bcreditlimit - this.model.alltotal;
                // this.model.afterordercredit = this.model.avialablecredit - this.model.alltotal;
            }
            else if (this.model.modellst[i].procheckselect && this.model.modellst[i].ratewithdiscount != null) {
                this.model.modellst[i].discountAmount = (this.model.modellst[i].ratewithdiscount * this.model.modellst[i].discount * this.model.modellst[i].quantity) / 100;
                let tax1 = (this.model.modellst[i].ratewithdiscount * this.model.modellst[i].gstrate) / 100;
                this.model.modellst[i].gstamount = (tax1 * this.model.modellst[i].quantity)
                this.model.modellst[i].totalAmount = (this.model.modellst[i].ratewithdiscount * this.model.modellst[i].quantity) + this.model.modellst[i].gstamount

                this.totalgstvalue = this.totalgstvalue + this.model.modellst[i].gstamount
                this.model.totalgstvalue = this.totalgstvalue
                this.pricevalue = this.pricevalue + (this.model.modellst[i].quantity * this.model.modellst[i].ratewithdiscount);
                this.model.pricevalue = this.pricevalue;
                this.alltotal = Math.round(this.totalgstvalue + this.pricevalue);
                this.model.alltotal = this.alltotal
                this.alltotalword = convertor(this.alltotal.toFixed(2));
                this.model.afterordercredit = this.model.bcreditlimit - this.model.alltotal;
                // this.model.afterordercredit = this.model.avialablecredit - this.model.alltotal;
            }
        }
    }
    onselectall($event) {
        if ($event.target.checked) {
            for (let i = 0; i < this.modellst.length; i++) {
                this.modellst[i].procheckselect = true
            }
        } else {
            for (let i = 0; i < this.modellst.length; i++) {
                this.modellst[i].procheckselect = false
            }
        }
    }

    btnhistory(VersionHistory: TemplateRef<any>) {


        this.modalRef2 = this.modalService.show(VersionHistory, {
            class: 'modal-lg'
        });
    }
    onSelectPayment(val) {
        if (val == "0") {
            this.notifyService.showRroor("Please select payment type!", "Oops!");
            this.model.paymentType = null;
            return;
        }

        this.model.paymentType = val;
    }

    btnsaveaddress(i) {
        // for (let i = 0; i < this.model.modellst.length; i++) {
        if (this.model.addresslst[i].checkselect == true) {
            // this.model.address = this.model.modellst[i].address + "," + this.model.modellst[i].localityName + "," + this.model.modellst[i].pincode;
            this.model.address = this.model.addresslst[i].address;
            this.model.accountid = this.model.addresslst[i].accountid;
            this.model.shipingaddid = this.model.addresslst[i].shipingaddid;

        }
        // }
        if (this.model.address != "" && this.model.address != null) {
            this.notifyService.showSuccess("Address Changed Successfully", "Done");
        }

        this.modalRef.hide();
    }
    print() {

    }

    cancle() {
        this.router.navigate(["/Order Acceptace"]);

    }
    btnopenmodel(i, quotaTermsconid, termmodel: TemplateRef<any>) {
        this.model.termscondtion = this.termmodlst[i].termscondtion;
        this.termscondtion = this.termmodlst[i].termscondtion;

        this.indexno = i;
        this.termsid = quotaTermsconid;
        this.modalRef = this.modalService.show(termmodel, {
            class: 'modal-lg'
        });


    }
    btnsaveterm() {

        if (this.model.termscondtion == this.termmodlst[this.indexno].termscondtion) {
            this.modalRef.hide();

        } else {
            this.model.quotaTermsconid = this.termsid
            this.transactionService.saveterms(this.model)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess(data.message, "Done!");
                            this.ngOnInit();
                            this.termscondtions = true
                            this.modalRef.hide();
                        }
                    });



        }

    }
    btnsave() {
        this.onChangeOfqantity()
        if (this.model.paymentType == null) {
            this.notifyService.showRroor("Please select payment type!", "Oops!");
            return;
        }
        // if (this.model.ponumber == null) {
        //     this.notifyService.showRroor("Please enter PO Number!", "Oops!");
        //     return;
        // }
        // if (this.model.podate == null) {
        //     this.notifyService.showRroor("Please enter PO Date!", "Oops!");
        //     return;
        // }
        if (this.model.creditdays == null && this.model.paymentType != "Against Order") {
            this.notifyService.showRroor("Please enter Credit Days!", "Oops!");
            return;
        }
        this.model.userid = Number(localStorage.getItem('userid'));
        this.model.termscondtions = this.termscondtions;
        // for (let i = 0; i < this.model.modellst.length; i++) {
        //     this.model.modellst[i].testqty = this.model.modellst[i].quantity - this.model.modellst[i].quotationQuantity
        //     if (this.model.modellst[i].quantity > this.model.modellst[i].testqty || this.model.modellst[i].quantity == this.model.modellst[i].testqty ) {
        //         this.notifyService.showRroor("Quantity is greater than order quantity", "Oops!");
        //         this.model.modellst[i].quantity = null;
        //         return;
        //     }
        // }
        // if (this.dealerflag) {
            for (let i = 0; i < this.model.modellst.length; i++) {
                if (this.model.modellst[i].quantity > this.model.modellst[i].balance && this.model.modellst[i].balance != null) {
                    this.notifyService.showRroor("Quantity is greater than order quantity", "Oops!");
                    this.model.modellst[i].quantity = null;
                    return;
                }
                else if (this.model.modellst[i].quantity > this.model.modellst[i].testqty) {
                        this.notifyService.showRroor("Quantity is greater than order quantity", "Oops!");
                        this.model.modellst[i].quantity = null;
                        return;
                    } 
                // }
            }
        // }
        // else {
            // for (let i = 0; i < this.model.modellst.length; i++) {
            //     if (this.model.modellst[i].quantity > this.model.modellst[i].testqty) {
            //         this.notifyService.showRroor("Quantity is greater than order quantity", "Oops!");
            //         this.model.modellst[i].quantity = null;
            //         return;
            //     }
            // }
        // }
        this.showBtn = true;
        this.transactionService.saveOrderAcceptance(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                        this.router.navigate(["/Order Acceptace"]);

                    }
                });


    }
    selectEvent(item) {
        this.model.consigneeID = item.id;
        this.model.consigneename = item.name;
    }
    onChangeSearch(search: string) {
        this.user.message = search;
        this.reportServices.getParantName(this.user)
            .subscribe(data => {
                this.resourcelist = [];
                data.forEach((item) => this.resourcelist.push({ "name": item.name, "id": item.id }));

            });

    }

    onFocused(e) {
        // do something
    }
    btnupdategsttype() {

        this.model.Userid = Number(localStorage.getItem('userid'));
        this.model.termscondtions = this.termscondtions;
        this.transactionService.updategsttype(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                        // this.router.navigate(["/Order Acceptace"]);

                    }
                });
    }

    // }

    openlocationmodel(locationmodel: TemplateRef<any>) {
        if (this.model.id == null) {
            this.notifyService.showRroor("Please Enter Dealer Details !!", "Oops!");
            return;
        }
        // this.index = null;
        // this.modell.shortname = null;
        // this.modell.chkselectvalue = null;
        // this.modell.address = null;
        // this.modell.landmark = null;
        // this.modell.pincode = null;
        // this.modell.locality = null;
        // this.modell.locale = null;
        // this.modell.city = null;
        // this.modell.state = null;
        // this.modell.country = null;
        // this.modell.maplocation = null;
        // this.modell.mobile = null;
        // this.modell.phone = null;
        // this.modell.email = null;
        // this.modell.localityID = null;
        // this.modell.lstaddress = null;
        // this.lstlocality = null;
        this.modalRef = this.modalService.show(locationmodel, {
            class: 'modal-lg'
        });
    }
    selectEvent1(item) {
        this.model.newPincode = item.name;
        this.pomodel.pincode = item.name;
        this.lstlocality = [];
        this.lstlocality.push({ id: 0, name: "Select Locality" });
        this.transactionService.getlocality(this.pomodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocality.push({ id: item.id, name: item.name });
                });
                this.pomodel.pincode = item.id
                this.pomodel.pincode = item.name
                this.model.newPincode = this.pomodel.pincode;
                this.model.newPincode = this.pomodel.pincode;
            });
    }

    onChangeSearch1(search: string) {
        this.user.message = search;
        this.transactionService.getpincodelike(this.user)
            .subscribe(data => {
                this.pincodelist1 = [];
                data.forEach((item) => this.pincodelist1.push({ "id": item.id, "name": item.name }));
            });
    }

    onFocused1(e) {
        // do something
    }
    onSelectlocality1(id, name) {
        this.model.localityid1 = id;
        this.model.locality1 = this.localitymap.get(id);
        this.pomodel.localityID = id;
        this.pomodel.locality = this.localitymap.get(id);


        this.transactionService.getgeographydetails(this.pomodel)
            .subscribe(data => {
                this.model.city = data.city;
                this.model.state = data.state;
                this.model.country = data.country;
                this.model.locale = data.locale;
            });

    }
    btnsaveNewAddress() {
        this.model.addresslst.push({

            id: null,
            accountid: null,
            creditdays: null,
            accWiseMcDetlsID: null,
            localityName: null,
            pincode: null,
            address: this.model.shortname + "," + this.model.newaddress + "," + this.model.locality1 + "," + this.model.newPincode,
            cheselect: null,
            price: null,
            gstamount: null,
            gstrate: null,
            prouctname: null,
            pricevalue: null,
            quantity: null,
            quotationQuantity: null,
            totalAmount: null,
            checkselect: null,
            termscondtion: null,
            quotationProID: null,
            termsid: null,
            procheckselect: null,
            orderchecksele: null,
            termsheding: null,
            quotaTermsconid: null,
            discount: null,
            discountAmount: null,
            shipingaddid: null,
            shortname: null,
            newaddress: null,
            email: null,
            landmark: null,
            newPincode: null,
            locality1: null,
            locale: null,
            city: null,
            state: null,
            country: null,
            maplocation: null,
            mobile: null,
            phone: null,
            localityid1: null,
            displayaddress: this.model.shortname + "," + this.model.newaddress + "," + this.model.locality1 + "," + this.model.newPincode,
            newgstno: null,
            newchkoutsides: null,
            newchkwihitins: null,
            newgsttype: null,
            ratewithdiscount: null,
            testqty: null,
            balance: null,
        });

        this.model.chkNewAdderss = false;
        this.transactionService.savechangeAddress(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")

                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    btnedit(i) {

        this.model.chkNewAdderss = true;
        this.user.id = i;
        this.model.id = this.model.addresslst[i].shipingaddid;
        this.model.shortname = this.model.addresslst[i].shortname;
        this.model.newaddress = this.model.addresslst[i].newaddress;
        // this.model.newaddress = this.model.addresslst[i].address;
        this.model.email = this.model.addresslst[i].email;
        this.model.newPincode = this.model.addresslst[i].newPincode;
        this.model.landmark = this.model.addresslst[i].landmark;
        this.model.locality1 = this.model.addresslst[i].locality1;
        this.model.localityid = this.model.addresslst[i].localityid1;
        this.model.locale = this.model.addresslst[i].locale;
        this.model.city = this.model.addresslst[i].city;
        this.model.state = this.model.addresslst[i].state;
        this.model.country = this.model.addresslst[i].country;
        this.model.landmark = this.model.addresslst[i].landmark;
        this.model.mobile = this.model.addresslst[i].mobile;
        this.model.phone = this.model.addresslst[i].phone;
        this.model.newgstno = this.model.addresslst[i].newgstno;
        this.model.newgsttype = this.model.addresslst[i].newgsttype;

    }
    btndelts(i) {

        this.model.id = i;
        this.model.id = this.model.addresslst[i].shipingaddid;
        this.transactionService.dltaddress(this.model)
            .subscribe(value => {
                this.notifyService.showSuccess(value.message, " Success");
                this.ngOnInit()
            });

    }
    btndefault(i) {
        this.model.chkselectvalue = true;
    }
    onNewchkwihitins($event) {
        if ($event.target.checked) {
            this.model.newgsttype = "Ins"
        }
    }
    onNewchkoutsides($event) {
        if ($event.target.checked) {
            this.model.newgsttype = "Outs"
        }
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

}