<app-header></app-header>
<div class="container">
  <div class="row">
    <div class="col l12 m12 s12">
      <div class="col l2 m2 s2"></div>
      <div class="heading col l8 m8 s8">
        <span style="font-size: 25px;">GST Group</span>
      </div>
      <div class="col l2 m2 s2"></div>
    </div>
    <div class="col l12 m12 s12 registerBgShadow">
      <div class="row" style="margin-top: 10px;">
        <!-- <div class="col l2 m2 s2"></div> -->
        <div class="col l8 m8 s8" style="padding-left: 14px;">
          <span style="font-size: 18px;">Gst Header:&nbsp;<span>{{model.gsthrname}}</span> </span><br>
          <span style="font-size: 18px;">Percentage:&nbsp;<span>{{percentage}}%</span></span>
        </div>
        <div class="col l2 m2 s2"></div>
      </div>

      <div class="row" style="padding-left: 5px;">
        <!-- <div class="col l2 m2 s2"></div> -->
        <div class="input-field col l6 m6 s6" >
          <input [(ngModel)]="model.displayname" #displayname="ngModel" type="text" class="" id="display"
            data-toggle="tooltip" data-placement="top" title="Display Name" name="displayname">
          <label for="display" class="active">Display Name</label>
        </div>
        <div class="col l2 m2 s12"></div>
      </div>

      <div class="row">
        <div class="col l6 m6 s6">
          <input type="checkbox" [(ngModel)]="model.chkinstate" #chkinstate="ngModel"
            (click)="onchkinstate($event)" />&nbsp;Within State
        </div>
        <div class="col l6 m6 s6">
          <input type="checkbox" [(ngModel)]="model.chkoutstate" #chkoutstate="ngModel"
            (click)="onchkoutstate($event)" />&nbsp;Outside State
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col l6 m6 s6">
          <table>
            <thead style="background: gainsboro;">
              <th width="20%" style="text-align: center"></th>
              <th width="50%" style="text-align: left">Name</th>
              <th width="30%" style="text-align: left">Percentage&nbsp;(%)</th>
            </thead>
            <tbody>
              <tr *ngFor="let lst of lsttax | paginate: { itemsPerPage: 10, currentPage: p } ;let i = index;">
                <td style="text-align: center"> <input type="checkbox" class="filled-in" [(ngModel)]="lst.cheselect"
                    #cheselect="ngModel">
                  <label id="lable1" for="first1"> </label>
                </td>
                <td style="text-align: left">{{lst.taxElementName}}</td>
                <td style="text-align: left;">
                  <input [(ngModel)]="lst.persentage" placeholder="Percentage" #persentage="ngModel" type="number"
                    data-toggle="tooltip" data-placement="top"
                    title="Percentage" id="persentage" name="percentage" style="text-align: right;margin: 0px"
                    (ngModelChange)="onChangePercentage()" [disabled]="lst.cheselect==false">
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div class="col l6 m6 s6">
          <table>
            <thead style="background: gainsboro;">
              <th width="20%" style="text-align: left"></th>
              <th width="50%" style="text-align: left">Name</th>
              <th width="30%" style="text-align: left">Percentage&nbsp;(%)</th>
            </thead>
            <tbody>
              <tr *ngFor="let lst of lsttax2 | paginate: { itemsPerPage: 10, currentPage: p } ;let i = index;">
                <td style="text-align: center"> <input type="checkbox" class="filled-in" [(ngModel)]="lst.cheselect"
                    #cheselect="ngModel">
                  <label id="lable1" for="first1"> </label>
                </td>
                <td style="text-align: left">{{lst.taxElementName}}</td>
                <td style="text-align: left;">
                  <input [(ngModel)]="lst.persentage" placeholder="Percentage" #persentage="ngModel" type="number"
                   data-toggle="tooltip" data-placement="top"
                    title="Percentage" id="persentage" name="percentage" style="text-align: right;margin: 0px"
                    (ngModelChange)="onChangePercentage2()" [disabled]="lst.cheselect==false">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="input-field col l12 m12 s12" style="text-align: right">
          <button type="button" class="waves-effect cancelbutton btn" style="margin-left: 5px;"
            (click)="cancle()">Cancel</button>&nbsp;&nbsp;
            <button type="button" (click)="btnsave()" class="waves-effect waves-light btn"
            style="margin-left: 5px;">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>