import { OnInit, Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { DefinePurchaseOrderModel } from './DefinePurchaseOrderModel';
import { BsModalService } from 'ngx-bootstrap';
import * as moment from 'moment';
import { GoodsNoteDefineModel } from '../GoodsNote/GoodsNoteDefineModel';

declare var $: any;
@Component({
    selector: 'app-DefinePurchaseOrder',
    templateUrl: 'DefinePurchaseOrder.html',
})
export class DefinePurchaseOrder implements OnInit {
    keyword1 = 'itemname';
    keyword2 = 'vendorname';
    // model= GoodsNoteDefineModel;
    user = new User();
    model: any = {};
    model1 = new DefinePurchaseOrderModel();
    p: any;
    public i;
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    mrpmap = new Map();
    userList2: any;
    userList = [];
    userListVendor = [];
    id: number;
    itemname: string;
    vendorname: string;
    itemcodemap = new Map();
    hsnCodemap = new Map();
    uommap = new Map();
    gstmap = new Map();
    amount: number;
    netamount: number;
    vendorlist: DefinePurchaseOrderModel[] = [];
    totalgst: number;
    sub: any;
    action: string;
    orderlstall: DefinePurchaseOrderModel[] = [];
    modalRef: any;
    rejected1: GoodsNoteDefineModel;
    public termscondtion: string;
    public indexno: number;
    public termsid: number;
    public cattermconId: number;
    constructor(private Masterservice: MasterServices,
        private modalService: BsModalService,
        private alertService: AlertService,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.vendorlist = [];
        // this.gettermconditions();
        this.getitemCategory();
        var id = document.getElementById("defaulttab");
        id.click();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.route.queryParams.subscribe(params => {
            this.id = + params["id"];
            this.action = params["flag"];
        });


        if (this.id != null && this.id > 0) {

            this.model.id = this.id;
            this.transactionService.getpurchaseOrderByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.vendorlist = data.vendormodellst
                        this.orderlstall = data.orderlstall
                        this.netamount = this.model.netamount
                        this.onChangeOfqantity();
                        this.onChangeOfrate();
                    },
                    error => {
                        this.alertService.error(error);
                    });
            // let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            // l1.className = "active";
            // let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            // l2.className = "active";
            // let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            // l3.className = "active";
            // let l10 = (<HTMLInputElement>document.getElementById('lable10'));
            // l10.className = "active";
        }
        this.model.podate = new Date();
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }

    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.itemsubcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.itemsubcategory = this.subCatlst[i].name
            }
        }
    }

    getVendorLike($event) {

        let userId = (<HTMLInputElement>document.getElementById('vendorname1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getVendorLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "vendorname": item.vendorname, "vendorid": item.vendorid }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].vendorname, this.userList2[i].vendorid)
                        searchDataObj[this.userList2[i].vendorname] = null;
                        if (i == 0) {
                            names = this.userList2[i].vendorname;
                        } else {
                            names = names + "," + this.userList2[i].vendorname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect(vendorid, vendorname) {
        this.model.vendorid = vendorid;
        this.model.vendorname = vendorname;
        this.transactionService.getvenaddress(this.model)
            .subscribe(value => {
                this.model.address = value.address
                this.model.dispatchaddress = value.dispatchaddress
                this.model.vendorname = value.vendorname

            })

    }

    getItemLike($event) {

        let userId = (<HTMLInputElement>document.getElementById('itmname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "hsnCode": item.hsnCode, "uom": item.uom, "gstGrouppercentage": item.gstGrouppercentage }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].itemname, this.userList2[i].itemid)
                        this.itemcodemap.set(this.userList2[i].itemname, this.userList2[i].itemcode)
                        this.hsnCodemap.set(this.userList2[i].itemname, this.userList2[i].hsnCode)
                        this.uommap.set(this.userList2[i].itemname, this.userList2[i].uom)
                        this.gstmap.set(this.userList2[i].itemname, this.userList2[i].gstGrouppercentage)

                        searchDataObj[this.userList2[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList2[i].itemname;
                        } else {
                            names = names + "," + this.userList2[i].itemname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect1(itemid, itemname) {
        this.model.itemid = itemid;
        this.model.itemname = itemname;
        this.model.itemcode = this.itemcodemap.get(itemname);
        this.model.hsnCode = this.hsnCodemap.get(itemname);
        this.model.uom = this.uommap.get(itemname);
        this.model.gstGrouppercentage = this.gstmap.get(itemname);
    }
    btnadd() {
        if (this.model.vendorid == null) {
            this.notifyService.showRroor("List Is Null.", " Oops");
            return;
        }

        if (this.model.itemcategoryid == null) {
            this.notifyService.showRroor("Please Select Itemcategory", " Oops");
            return;
        }
        if (this.model.itemsubcategoryid == null) {
            this.notifyService.showRroor("Please Select ItemSubcategory", " Oops");
            return;
        }
        if (this.model.itemname == null) {
            this.notifyService.showRroor("Please Select Itemname", " Oops");
            return;
        }

        // if (this.model.quantity == 0 || this.model.quantity == null) {
        //     this.notifyService.showRroor("Please Select Itemname", " Oops");
        //     this.model.quantity > 0;
        //     return;
            
        // }

        for (let i = 0; i < this.vendorlist.length; i++) {
            if (this.vendorlist[i].itemid == this.model.itemid) {
                this.notifyService.showRroor("Item already present", " Oops");
                return;
            }
        }
        // this.userList2.forEach(item=>{
        //     this.model.itemname = item.itemname
        //     this.model.itemid = item.itemid;
        // });
        this.vendorlist.push({
            id: null,
            itemsubcategory: this.model.itemsubcategory,
            rate: 0,
            address: "",
            itemcategoryid: this.model.itemcategoryid,
            itemcategory: this.model.itemcategory,
            itemsubcategoryid: this.model.itemsubcategoryid,
            itemname: this.model1.itemname,
            itemid: this.model.itemid,
            vendorname: this.model.vendorname,
            dispatchaddress: "",
            itemcode: this.model.itemcode,
            hsnCode: this.model.hsnCode,
            quantity: 0,
            gstGrouppercentage: this.model.gstGrouppercentage,
            uom: this.model.uom,
            uomid: this.model.uomid,
            dimensions: null,
            diamensionUnit: null,
            amount: this.model.amount,
            netamount: this.model.netamount,
            podate: this.model.podate,
            userid: this.model.userid,
            vendormodellst: null,
            vendorid: this.model.vendorid,
            receivedQuantity: null,
            checkselect: false,
            tbpohdr: null,
            orderlstall: null,
            termcondition: "",
            categorytermconid: null,
            pohdrid: null,
            potermsid: null,
            actuStock: null,
            availablestock: null,
            stockforsale: null,
            requisitionQuantity: null,
            stockforsaletoloc: null,
            gstamount: this.model.gstamount,
            stocklocationlst: this.model.stocklocationlst
        })
        this.onChangeOfqantity();
        this.onChangeOfrate();

    }

    onChangeOfqantity() {
        this.amount = 0;
        this.netamount = 0;
        for (let i = 0; i < this.vendorlist.length; i++) {
            let tax1 = (this.vendorlist[i].gstGrouppercentage * this.vendorlist[i].rate) / 100;
            this.vendorlist[i].gstamount = (tax1 * this.vendorlist[i].quantity)

            this.amount = this.vendorlist[i].quantity * this.vendorlist[i].rate
            // this.model.amount = this.amount
            this.vendorlist[i].amount = this.amount + this.vendorlist[i].gstamount
            this.netamount = this.netamount + this.vendorlist[i].amount;
            this.model.netamount = this.netamount
        }
    }
    onChangeOfrate() {
        this.amount = 0;
        this.netamount = 0;
        this.totalgst = 0;
        for (let i = 0; i < this.vendorlist.length; i++) {
            let tax1 = (this.vendorlist[i].gstGrouppercentage * this.vendorlist[i].rate) / 100;
            this.vendorlist[i].gstamount = (tax1 * this.vendorlist[i].quantity)
            this.amount = this.vendorlist[i].quantity * this.vendorlist[i].rate
            // this.vendorlist[ind].amount = this.amount
            this.vendorlist[i].amount = this.amount + this.vendorlist[i].gstamount
            this.netamount = this.netamount + this.vendorlist[i].amount;
            this.model.netamount = this.netamount
            this.totalgst = this.vendorlist[i].amount * this.vendorlist[i].gstGrouppercentage / 100
            this.model.totalgst = this.totalgst
        }

    }
    btndelete(id, index) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            if (id != null) {
                this.i = confirm("Do you want to delete this record ?")
                if (this.i == true) {
                    this.model.id = id
                    this.model.userId = Number(localStorage.getItem("userid"));
                    this.transactionService.deleteOrder(this.model)
                        .subscribe(value => {
                            if (value.status == "OK") {
                                this.notifyService.showSuccess("Deleted Successfully", " Success");
                                this.ngOnInit()
                                // var id = document.getElementById("id");
                                // id.click();
                                // this.vendorlist.splice(index, 1);
                            }
                        });
                }
            }
            else {
                for (let i = 0; i < this.vendorlist.length; i++) {
                    if (this.vendorlist[i].id == id) {
                        this.vendorlist.splice(this.vendorlist.indexOf(this.vendorlist[i]), 1);
                        this.notifyService.showSuccess("Item Removed Successfully", " Success");
                    }
                }
            }
        }
    }

    btncancle() {
        this.router.navigate(["/SearchPurchaseOrder"])
    }


    btnsave($event) {
        if (this.model.vendorname == null ) {
            this.notifyService.showRroor("Please Select Supplier.", " Oops");
            return;
        }
        for (let j = 0; j < this.vendorlist.length; j++) {
            if (this.vendorlist[j].quantity == null ||this.vendorlist[j].quantity == 0) {
                this.notifyService.showRroor("Please Enter Quantity.", " Oops");
                this.vendorlist[j].quantity = 1 ;
                return;
            }
            if (this.vendorlist[j].rate == null) {
                this.notifyService.showRroor("Please Enter Rate.", " Oops");
                return;
            }
           
    
        }

        for (let j = 0; j < this.vendorlist.length; j++) {
            if (this.action == "edit") {
                this.vendorlist[j].quantity = this.vendorlist[j].quantity - this.model.receivedQuantity
            }
        }
        this.model.vendormodellst = this.vendorlist
        for (let i = 0; i < this.model.vendormodellst.length; i++) {
            this.model.vendormodellst[i].vendorname = this.vendorname;
            this.model.vendormodellst[i].uomid = this.model.uomid
        }
        this.model.itemname = this.itemname;
        // this.model.vendorname = this.vendorname;
        this.transactionService.savePurchaseOrder(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.model.id = Number(data.message)
                        var id = document.getElementById("Term");
                        id.click();                    

                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    btnaddterm() {

        if (this.model.vendorid == null) {
            this.notifyService.showRroor("List Is Null.", " Oops");
            return;
        }


        if (this.model.termcondition == null) {
            this.notifyService.showRroor("Please Enter Term Condition.", " Oops");
            return;
        }


        for (let i = 0; i < this.orderlstall.length; i++) {
            if (this.orderlstall[i].termcondition == this.model.termcondition) {
                this.notifyService.showRroor("This Term Condition is already present", " Oops");
                return;
            }
        }

        this.orderlstall.push({
            id:this.model.id,
            itemsubcategory: this.model.itemsubcategory,
            rate: 0,
            address: "",
            itemcategoryid: this.model.itemcategoryid,
            itemcategory: this.model.itemcategory,
            itemsubcategoryid: this.model.itemsubcategoryid,
            itemname: this.model.itemname,
            itemid: this.model.itemid,

            vendorname: this.model.vendorname,
            dispatchaddress: "",
            itemcode: this.model.itemcode,
            hsnCode: this.model.hsnCode,
            quantity: 0,
            gstGrouppercentage: this.model.gstGrouppercentage,
            uom: this.model.uom,
            uomid: this.model.uomid,
            dimensions: null,
            diamensionUnit: null,
            amount: this.model.amount,
            netamount: this.model.netamount,
            podate: this.model.podate,
            userid: this.model.userid,
            vendormodellst: null,
            vendorid: this.model.vendorid,
            receivedQuantity: null,
            checkselect: false,
            tbpohdr: null,
            orderlstall: null,
            termcondition: this.model.termcondition,
            categorytermconid: null,
            pohdrid: null,
            potermsid: null,
            actuStock: null,
            availablestock: null,
            stockforsale: null,
            requisitionQuantity: null,
            stockforsaletoloc: null,
            gstamount: this.model.gstamount,
            stocklocationlst: this.model.stocklocationlst

        })


        // this.transactionService.savetermcondition(this.model)
        //     .subscribe(
        //         data => {
        //             if (data.status == "OK") {
        //                 this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
        //                 // this.router.navigate(['Campaign']);
        //             } else {
        //                 this.notifyService.showRroor(data.message, "Oops !")
        //             }
        //         },
        //         error => {
        //             alert("Something Wrong ")
        //         });
    }
    btnopenmodel(i, categorytermconid, potermsid, termmodel: TemplateRef<any>) {
        this.model.termcondition = this.orderlstall[i].termcondition;
        this.termscondtion = this.orderlstall[i].termcondition;

        this.indexno = i;
        this.termsid = potermsid;
        this.cattermconId = categorytermconid;


        this.modalRef = this.modalService.show(termmodel, {
            class: 'modal-lg'
        });

    }
    closemodel() {
        // if( this.rejected1.= 0){
        this.modalRef.hide();
        // }
    }
    btntermcon() {
  
        this.transactionService.getcatrgoryterms(this.model)
            .subscribe(
                data => {
                    this.orderlstall = data
                },
                error => {
                    this.alertService.error(error);
                });
            


    }
    btnsaveterm() {
        if (this.model.termcondition == this.orderlstall[this.indexno].termcondition) {
            this.modalRef.hide();
     
        } else {
            this.model.potermsid = this.termsid
            this.model.categorytermconid = this.cattermconId
            this.transactionService.savecategoryterms(this.model)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess(data.message, "Done!");
                            this.ngOnInit();
                            this.modalRef.hide();

                        }
                    

                    });
                   


        }

    }
    btnfinalize($event) {
       
        this.model.orderlstall = this.orderlstall
        for (let i = 0; i < this.model.orderlstall.length; i++) {
            this.model.orderlstall.vendorid=this.vendorname;
            this.model.orderlstall[i].vendorname = this.vendorname;
            // this.model.orderlstall[i].vendorname = this.
        }

        this.transactionService.savefinalize(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit();
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                    this.router.navigate(['/SearchPurchaseOrder'])
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    btnsavepoterm($event) {

        this.model.orderlstall = this.orderlstall
        for (let i = 0; i < this.model.orderlstall.length; i++) {
            this.model.orderlstall[i].vendorname = this.vendorname;
        }

        this.transactionService.saveAllpotermscondition(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
        this.router.navigate(['/SearchPurchaseOrder'])
    }
    btncanclepo() {
        this.router.navigate(["/SearchPurchaseOrder"])
    }
    onChangeSearchitem(search: string) {
        this.user.message = search;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        this.user.totalItems = this.model.totalItems;
        this.user.userid = this.model.itemcategoryid
        // this.user.suppilerid = this.model.vendorid;
        if (search.length >= 3) {
            this.transactionService.getItemLikePO(this.user)
                .subscribe(data => {
                    this.userList = [];
                    data.forEach((item) => this.userList.push({ itemname: item.itemname, itemid: item.itemid, itemcode: item.itemcode, hsnCode: item.hsnCode, uom: item.uom, uomid: item.uomid, gstGrouppercentage: item.gstGrouppercentage }));
                });


        }
    }
    selectEvent(item) {
        this.model.itemid = item.itemid;
        this.model.itemcode = item.itemcode;
        // this.model.itemname = item.itemname;
        this.model1.itemname = item.itemname;
        this.model.hsnCode = item.hsnCode;
        this.model.uom = item.uom;
        this.model.uomid = item.uomid;
        this.model.gstGrouppercentage = item.gstGrouppercentage;
    }

    onFocusedforitem(e) {
        // do something
    }
    btndeleteterms(id, index) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true)
        alert(id)
         {
            if (id != null) {
                this.i = confirm("Do you want to delete this record ?")
                if (this.i == true) {
                    this.model.id = id
                    this.model.userId = Number(localStorage.getItem("userid"));
                    this.transactionService.deletetermsandcond(this.model)
                        .subscribe(value => {
                            this.notifyService.showSuccess(value.message, " Success");
                            this.ngOnInit()
                        });
                }
            } else {
                for (let i = 0; i < this.orderlstall.length; i++) {
                    if (this.orderlstall[i].categorytermconid == id) {
                        this.orderlstall.splice(this.orderlstall.indexOf(this.orderlstall[i]), 1);
                        this.notifyService.showSuccess("Term And Condition Removed Successfully", " Success");
                    }
                }
            }
        }
    }
    onChangeSearchVendor(search: string) {
        this.user.message = search;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        this.user.totalItems = this.model.totalItems;
        if (search.length >= 3) {
            this.transactionService.getVendorLike(this.user)
                .subscribe(data => {
                    // this.userListVendor = [];
                    data.forEach((item) => this.userListVendor.push({ "vendorname": item.vendorname, "vendorid": item.vendorid }));
                });
        }
    }
    selectEventVendor(item) {
        this.model.vendorid = item.vendorid;
        this.model.vendorname = item.vendorname;
        this.vendorname = this.model.vendorname
        this.transactionService.getvenaddress(this.model)
            .subscribe(value => {
                this.model.address = value.address
                this.model.dispatchaddress = value.dispatchaddress
                this.model.vendorname = value.vendorname

            })
    }
    onFocusedforvendor(e) {
        // do something
    }
    selectPODate(date) {
        let pastdate = moment(new Date(), "DD/MM/YYYY").subtract(this.model.nodallwedbeforedcr + 1, 'days').toDate()
        if (date < new Date()) {
            this.notifyService.showRroor("Previous date cannot be selected", "oops");
            this.model.podate = new Date()
            return;
        }
    }
}

