import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { DefineInventoryLocModel } from "./DefineInventoryLocModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineInventoryLoc.html'
})
export class DefineInventoryLoc implements OnInit {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {
    }
    model = new DefineInventoryLocModel();
    itmCatlst: DropDownModel[] = [];
    resourcelst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    user = new User();
    sub: any;
    p: any;
    id: number;
    index: number;
    itemslst: DefineInventoryLocModel[] = [];
    itemslst1: DefineInventoryLocModel[] = [];
    public i;
    temp: boolean = true;

    ngOnInit(): void {
        this.temp = true;
        this.getitemCategory();
        this.getresourcelstIL();
        this.model.userid = Number(localStorage.getItem("userid"));

        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getInventoryLocByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.model.resourcelst = data.resourcelst;

                    },
                    error => {
                        this.alertService.error(error);
                    });

            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
        }


    }

    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }
    


    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcate = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.subcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.subcategory = this.subCatlst[i].name
            }
        }
    }
    btnadd($event) {
        if (this.model.locationcode == null) {
            this.notifyService.showRroor("Enter Location Code  !!", "Oops!");
            return;
        }
        if (this.model.locationname == null) {
            this.notifyService.showRroor("Enter Location Name  !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.model.itemslst.length; i++) {
            if (this.model.itemslst[i].subcatId == this.model.subcategoryid) {
                this.notifyService.showRroor("Subcategory already in list!", "Oops!");
                return;
            }
        }
        if (this.model.itemcategoryid == null) {
            this.notifyService.showRroor("Select Item Category Name  !!", "Oops!");
            return;
        }
        if (this.model.subcategoryid == null) {
            this.notifyService.showRroor("Select Sub Category Name  !!", "Oops!");
            return;
        }
        this.model.itemslst.push({
            id: null,
            itemcateId: this.model.itemcategoryid,
            subcatId: this.model.subcategoryid,
            itemcateName: this.model.itemcate,
            subcatName: this.model.subcategory,
            srno: this.model.itemslst.length + 1,
            userid: this.model.userid
        })


    }
    onRowMaterial() {
        if (this.model.chkrowMaterial = true) {
            this.model.chkProductionLoc = false;
            this.model.chkDespatchLoc = false;
            this.model.chkSpareloc = false;
        }
    }
    onProductionLoc() {
        if (this.model.chkProductionLoc = true) {
            this.model.chkrowMaterial = false;
            this.model.chkDespatchLoc = false;
            this.model.chkSpareloc = false;
        }
    }
    onDespatchLoc() {
        if (this.model.chkDespatchLoc = true) {
            this.model.chkProductionLoc = false;
            this.model.chkrowMaterial = false;
            this.model.chkSpareloc = false;
        }
    }
    onSpareloc() {
        if (this.model.chkSpareloc = true) {
            this.model.chkProductionLoc = false;
            this.model.chkrowMaterial = false;
            this.model.chkDespatchLoc = false;
        }
    }
    btnsave($event) {
        // this.model.resourcelst = this.resourcelst;
        if (this.model.itemslst.length <= 0) {
            this.notifyService.showRroor("Add at least One Location !!", "Oops!");
            return;
        }

        this.Masterservice.saveinventorylocation(this.model)
            .subscribe(
                data => {

                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Inventory Location']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });


    }
    cancle() {
        this.router.navigate(["Inventory Location"]);
    }
    btndelts(deleteid, subcatid) {
        // this.i = confirm("Do you want to delete this record ?")
        // if (this.i == true) {
        if (deleteid != null) {
            this.i = confirm("Do you want to delete this record ?")
            if (this.i == true) {
                this.model.id = deleteid
                this.Masterservice.dltInventorylst(this.model)
                    .subscribe(value => {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    });
            }
        } else {
            for (let i = 0; i < this.model.itemslst.length; i++) {
                if (this.model.itemslst[i].subcatId == subcatid) {
                    this.model.itemslst.splice(this.model.itemslst.indexOf(this.model.itemslst[i]), 1);
                    this.notifyService.showSuccess("Product Removed Successfully", " Success");
                }
            }
        }
        // }
    }
    getresourcelstIL() {
        let user = new User();
        this.resourcelst = [];
        this.Masterservice.getresourcelstIL(user)
            .subscribe((value) => {
                this.resourcelst = value
                this.model.resourcelst = this.resourcelst;
            });
    }
    onSelectresource(value) {
        // this.resourcelst = [];
        this.model.storekeeperid = value;
        for (let i = 0; i < this.resourcelst.length; i++) {
            if (this.resourcelst[i].id == value) {
                this.model.storekeepername = this.resourcelst[i].name
            }
        }
    }
}