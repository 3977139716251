<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Item Master</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="l12 m12 s12">
                    <!-- <div class="col s2 l2 m2"> </div> -->
                    <div class="input-field col s3 l3 m3" style="text-align: left;padding-left: 14px;">
                        <span>Search Criteria</span>
                        <select (change)=" onSelectItem($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center;margin-top:30px;">
                        <input [(ngModel)]="model.txtsearch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Text">
                        <label for="search">Search Text</label>
                    </div>
                    <div class=" input-field col s3 l3 m3" style="margin-top: 30px">
                        <button type="button" (click)="btnsearch($event)" class="waves-effect waves-light btn"
                            style="margin-left: 5px;">Go</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnadd($event)" class="waves-effect waves-light btn">New</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">

                        <div class="col l12 m12 s12">

                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="7%" style="text-align: left">Sr.No</th>
                                    <th width="15%" style="text-align: left;padding-left: 10px;">Item code</th>
                                    <th width="29%" style="text-align: left">Item Name</th>
                                    <th width="17%" style="text-align: left">Item Category</th>
                                    <th width="17%" style="text-align: left">Item Sub Category</th>
                                    <th width="7%" style="text-align: left">UOM</th>
                                    <th width="8%" style="text-align: center">Actions</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of itemmasterlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                        <td style="text-align: left;padding-left: 10px;">{{lst.itemcode}}</td>
                                        <td style="text-align: left">{{lst.itemname}}</td>
                                        <td style="text-align: left">{{lst.itemcategory}}</td>
                                        <td style="text-align: left">{{lst.itemsubcategory}}</td>
                                        <td style="text-align: left">{{lst.inventoryunit}}</td>
                                        <td style="text-align: center"> <span><img src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst.id)" data-toggle="tooltip" data-placement="top"
                                                    title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                                    src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                    width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>