import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, DatePipe } from '@angular/common';
import { User } from 'src/app/_models';
import { DefineSourceModel } from './DefineSourceModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';

@Component({
    selector: 'app-AMCd',
    //  moduleId: module.id,
    templateUrl: 'DefineSource.html'
})
export class DefineSource implements OnInit, OnDestroy {
    user = new User();
    model = new DefineSourceModel();
    sub: any;
    id: number;
    Date= new Date();
    pagename: string;
    constructor(private Masterservice: MasterServices, private datePipe: DatePipe, private route: ActivatedRoute, private alertService: AlertService, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }
    public campaignstatuslst: DropDownModel[] = [
        { id: 1, "name": "Active" },
        { id: 2, "name": "Inactive" },
    ];


    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }


    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.pagename = localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;

            this.model.id = this.id;
            this.Masterservice.getsourceByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        if (data.startDate != null) {
                            this.model.startDate = new Date(data.startDate);
                        }
                        if (data.endDate != null) {
                            this.model.endDate = new Date(data.endDate);
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            // let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            // l2.className = "active";
            // let l3 = (<HTMLInputElement>document.getElementById('label3'));
            // l3.className = "active";

        }
        this.model.startDate = new Date();
        this.model.endDate = new Date();
    }
    onSelectcampaignstatus(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Status !!", "Oops!");
            return;
        }
        if (value == "Active") {
            this.model.campaignStatus = 'Y'
        } else
            if (value == "Inactive") {
                this.model.campaignStatus = 'N'
            }
    }
    btnsave($event) {
        if (this.model.campaignCode == null || this.model.campaignCode == "") {
            this.notifyService.showRroor("Enter SourceCode !!", "Oops!");
            return;
        }
        if (this.model.campaignName == null || this.model.campaignName == "") {
            this.notifyService.showRroor("Enter SourceName !!", "Oops!");
            return;
        }

        if (this.model.campaignStatus == null) {
            this.notifyService.showRroor("Select Status !!", "Oops!");
            return;
        }

        if (this.model.description == null || this.model.description == "") {
            this.notifyService.showRroor("Enter Description !!", "Oops!");
            return;
        }

        this.Masterservice.savesourcemaster(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Campaign']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });


    }

    cancle() {
        this.router.navigate(["/Campaign"]);
    }
    selectToDate(date) {
        // let lastdate = moment(new Date(), "DD/MM/YYYY").subtract(this.model.noofdays + 1, 'days').toDate()
        
        if (date < this.model.startDate) {
            this.notifyService.showRroor("End Date should be grether than Start Date", "oops");
            this.model.endDate = this.model.startDate
            return;
        }


    }
    selectStartDate(date)
    {
        if(date < new Date())
        {
            this.notifyService.showRroor("Previous date cannot be select", "oops");
            this.model.startDate = new Date();
            return;
        }
    }

    
}