import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterServices, AlertService, TransactionServices } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { DefineAccountMasterModel, DefineAccountmodel } from './DefineAccountMasterModel';
import { Location, DatePipe } from '@angular/common';
import { usertwofieldModel } from '../ResourceMaster/usertwofieldModel';
import { DefineCategoryTermsConModel } from '../CategoryTermConditionMaster/DefineCategoryTermsConModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
// import { usertwofieldModel } from './usertwofieldModel';
declare var $: any;
@Component({
    selector: 'app-login',
    templateUrl: 'DefineAccountMaster.html'
})
export class DefineAccountMaster implements OnInit, OnDestroy {
    model = new DefineAccountMasterModel();
    sub: any;
    branchlst: usertwofieldModel[] = [];
    designationlst: usertwofieldModel[] = [];
    lstlocality: DropDownModel[] = [];
    pomodel = new ProspectModel();
    industorylst: DefineAccountMasterModel[] = [];
    consistutelst: DefineAccountMasterModel[] = [];
    annualrevenuelst: DefineAccountMasterModel[] = [];
    noofemplst: DefineAccountMasterModel[] = [];
    contactlst: DefineAccountmodel[] = [];
    custtypelst: DefineCategoryTermsConModel[] = [];
    i: number = 0;
    j: any;
    user = new User();
    userList2: any;
    lastkeydown2: number = 0;
    id: number;
    customername: string;
    pagename: string;
    chknoemp: boolean;
    chkannualtrnovr: boolean;
    chkindtrytyp: boolean;
    mandemp: boolean;
    mandanualtrnovr: boolean;
    mandindsttyp: boolean
    localitymap = new Map();

    chksuperVisiorAuth: boolean;
    mandSupervisiorAuth: boolean;

    constructor(private Masterservice: MasterServices,
        private alertService: AlertService,
        private userService: MasterServices,
        private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
    }
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");


    }

    public salutationlst: DropDownModel[] = [
        { id: 1, "name": "Choose one" },
        { id: 2, "name": "Mr." },
        { id: 3, "name": "Ms." },
    ];

    ngOnInit() {
        $('#staticUserIdsSecondWay').autocomplete({
            source: ['1101', '1202', '1303']
        });
        this.pagename = localStorage.getItem("pagename");
        this.getcusttype();
        this.getDept();
        this.getindustorylst();

        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.user.userid = Number(localStorage.getItem('userid'));
        this.chknoemp = false;
        this.chkindtrytyp = false;
        this.chkannualtrnovr = false;
        this.chksuperVisiorAuth = false;
        this.route.queryParams.subscribe(params => {
            this.id = + params["id"];
            this.customername = params["name"];
        });
        // this.sub = this.route.params.subscribe(params => {
        //     this.id = + params["id"];
        // });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getAccountDetailsByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        if (data.gsttype == "InS") {
                            this.model.chkwihitins = true;
                            this.model.gsttype = "InS"
                        } else if (data.gsttype == "OutS") {
                            this.model.chkoutsides = true;
                            this.model.gsttype = "OutS"
                        }
                        this.onselectCustomerType(data.customerTypeId);
                        this.typeaheadOnSelect(null, data.pincode);
                        this.onSelectLocality(data.localityID)
                        this.contactlst = data.contactlst;
                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";
            let l5 = (<HTMLInputElement>document.getElementById('lable5'));
            l5.className = "active";
            // let l6 = (<HTMLInputElement>document.getElementById('lable6'));
            // l6.className = "active";
            let l7 = (<HTMLInputElement>document.getElementById('lable7'));
            l7.className = "active";
            let l8 = (<HTMLInputElement>document.getElementById('lable8'));
            l8.className = "active";
            let l9 = (<HTMLInputElement>document.getElementById('lable9'));
            l9.className = "active";
            let l10 = (<HTMLInputElement>document.getElementById('lable10'));
            l10.className = "active";
            let l11 = (<HTMLInputElement>document.getElementById('lable11'));
            l11.className = "active";
            let l12 = (<HTMLInputElement>document.getElementById('lable12'));
            l12.className = "active";
            let l13 = (<HTMLInputElement>document.getElementById('lable13'));
            l13.className = "active";
            let l14 = (<HTMLInputElement>document.getElementById('buildingName'));
            l14.className = "active";
        }

    }

    getDept() {
        let user = new User();
        this.branchlst = [];
        this.designationlst = [];
        this.Masterservice.getDept(user)
            .subscribe((value) => {
                this.branchlst = value.branlst
                this.designationlst = value.desiglst

            });
    }
    getcusttype() {
        let user = new User();
        this.custtypelst = [];
        // this.categorylst = [];
        this.Masterservice.getcategcustype(user)
            .subscribe((value) => {
                this.custtypelst = value.customertypelst;
                // this.categorylst = value.industrylst;
            });
    }
    getindustorylst() {
        let user = new User();
        this.industorylst = [];
        this.consistutelst = [];
        this.annualrevenuelst = [];
        this.noofemplst = [];

        this.Masterservice.getindustorylst(user)
            .subscribe((value) => {
                this.industorylst = value.industorylst
                this.consistutelst = value.consistutelst
                this.annualrevenuelst = value.annualrevenuelst
                this.noofemplst = value.noofemplst

            });
    }

    onSelectbranch(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Branch !!", "Oops!");
            return;
        }
        this.model.branch = value
    }
    onselectCustomerType(value) {
        if (value == 0) {
            this.model.customerTypeId = null;
            this.notifyService.showRroor("Select Customer Type !!", "Oops!");
            return;
        }
        if (value == "Select Customer Type") {
            this.chknoemp = false;
            this.chkindtrytyp = false;
            this.chkannualtrnovr = false;
            this.chksuperVisiorAuth = false

            this.mandanualtrnovr = false;
            this.mandemp = false;
            this.mandindsttyp = false;
            this.mandSupervisiorAuth = false
        }
        this.model.customerTypeId = value;
        this.user.trnid = value;
        this.Masterservice.getAlldataBytypeid(this.user)
            .subscribe(value => {
                this.chknoemp = value.chknoofemployee;
                this.chkindtrytyp = value.chkindstype;
                this.chkannualtrnovr = value.chkanualtrnovr;
                this.mandanualtrnovr = value.mandchktrnovr;
                this.mandemp = value.mandchkemp;
                this.mandindsttyp = value.mandindtype;
                this.model.industry = value.industryid;
                this.chksuperVisiorAuth = value.chksupervisingAuth;
                this.mandSupervisiorAuth = value.manSupervising;
            });



    }

    onchkwihitins($event) {
        this.model.wigstflag = "InS";
        this.model.gsttype = "InS";

    }
    onchkoutsides($event) {
        this.model.outgstflag = "OutS";
        this.model.gsttype = "OutS";
    }

    onSelectindustry(value) {
        if (value == 0) {
            this.notifyService.showRroor(" Select Industry!!", "Oops!");
            return;
        }
        this.model.industry = value
    }
    onSelectconstitute(value) {
        if (value == 0) {
            this.notifyService.showRroor(" Select Constitute!!", "Oops!");
            return;
        }

        this.model.constitute = value
    }

    onSelectannualrevenue(value) {
        if (value == 0) {
            this.notifyService.showRroor(" Select Annual Revenue!!", "Oops!");
            return;
        }
        this.model.annualrevenue = value
    }
    onSelectnoofemple(value) {
        if (value == 0) {
            this.notifyService.showRroor(" Select  No Of Employees!!", "Oops!");
            return;
        }

        this.model.noofemployee = value
    }
    btncancle() {
        this.router.navigate(["/Accounts"]);
    }
    onSelectdesiganation(value, id) {
        if (value == 0) {
            this.notifyService.showRroor(" Select Designation!!", "Oops!");
            return;
        }
        for (let j = 0; j < this.contactlst.length; j++) {
            if (this.contactlst[j].contactid == id) {
                this.contactlst[j].designation = value
            }
        }
    }

    onSelectsalutation(value, id) {
        for (let j = 0; j < this.contactlst.length; j++) {
            if (this.contactlst[j].contactid == id) {
                this.contactlst[j].salutation = value
            }
        }
    }
    addContact() {
        this.contactlst.push({
            checkselect: null,
            salutation: null,
            contactname: null,
            designation: null,
            mobileno: null,
            contactemail: null,
            extension: null,
            contactid: null,
        })
        this.i++
    }


    btnsave($event) {
        if (this.model.customerTypeId == null) {
            this.notifyService.showRroor("Please select Customer Type !!", " Oops!");
            return;
        }
        if (this.model.code == null || this.model.code == "") {
            this.notifyService.showRroor("Enter The Code !!", " Oops!");
            return;
        }
        if (this.model.name == null || this.model.name == "") {
            this.notifyService.showRroor("Enter The Name !!", " Oops!");
            return;
        }
        if (this.model.address == null || this.model.address == "") {
            this.notifyService.showRroor("Enter The Address !!", " Oops!");
            return;
        }
        // if (this.model.branch == null) {
        //     this.notifyService.showRroor("Select Branch !!", " Oops!");
        //     return;
        // }
        if (this.model.locality == null || this.model.locality == "") {
            this.notifyService.showRroor("Enter The Locality !!", " Oops!");
            return;
        }
        if (this.model.pincode == null || this.model.pincode == "") {
            this.notifyService.showRroor("Enter The Pincode !!", " Oops!");
            return;
        }
        // if (this.model.city == null || this.model.city == "") {
        //     this.notifyService.showRroor("Enter The City !!", " Oops!");
        //     return;
        // }
        if (this.model.state == null || this.model.state == "") {
            this.notifyService.showRroor("Enter The State !!", " Oops!");
            return;
        }
        if (this.model.country == null || this.model.country == "") {
            this.notifyService.showRroor("Enter The Country !!", " Oops!");
            return;
        }
        if (this.model.panno == null || this.model.panno == "") {
            this.notifyService.showRroor("Enter The Pan Number !!", " Oops!");
            return;
        }
        if (this.model.telephone1 == null || this.model.telephone1 == "") {
            this.notifyService.showRroor("Enter The Primary Mobile No !!", " Oops!");
            return;
        }
        if (this.model.email == null || this.model.email == "") {
            this.notifyService.showRroor("Enter The Email !!", " Oops!");
            return;
        }
        // if (this.model.website == null || this.model.website == "") {
        //     this.notifyService.showRroor("Enter The Website !!", " Oops!");
        //     return;
        // }
        // if (this.model.industry == null) {
        //     this.notifyService.showRroor("Select Industry !!", " Oops!");
        //     return;
        // }

        // for (let i = 0; i < this.contactlst.length; i++) {
        //     if (this.contactlst.length <= 0) {
        //         this.notifyService.showRroor("Please enter Contact Details !!", " Oops!");
        //         return;
        //     }
        //     // if (this.contactlst[i].salutation == null || this.contactlst[i].salutation == "") {
        //     //     this.notifyService.showRroor("Please Select Salutation !!", " Oops!");
        //     //     return;
        //     // }

        //     if (this.contactlst[i].contactname == null || this.contactlst[i].contactname == "") {
        //         this.notifyService.showRroor("Please enter Contact Name !!", " Oops!");
        //         return;
        //     }

        //     if (this.contactlst[i].designation == null || this.contactlst[i].designation == "") {
        //         this.notifyService.showRroor("Please Select Designation !!", " Oops!");
        //         return;
        //     }

        //     if (this.contactlst[i].contactemail == null || this.contactlst[i].contactemail == "") {
        //         this.notifyService.showRroor("Please enter Contact Email !!", " Oops!");
        //         return;
        //     }
        //     if (this.contactlst[i].mobileno == null || this.contactlst[i].mobileno == "") {
        //         this.notifyService.showRroor("Please enter Contact Mobile No !!", " Oops!");
        //         return;
        //     }
        //     // if (this.contactlst[i].extension == null || this.contactlst[i].extension == "") {
        //     //     this.notifyService.showRroor("Please enter the Extension !!", " Oops!");
        //     //     return;
        //     // }
        // }
        this.model.contactlst = this.contactlst
        this.Masterservice.saveaccount(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(["Accounts"]);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }


    getSuperAuthorities($event) {
        let userId = (<HTMLInputElement>document.getElementById('supervisiorAuth')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.trnid = this.model.customerTypeId
        var obj = this;
        if (userId.length >= 3) {
            this.Masterservice.getSuperAuthoritiesLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.onselectSuperAuth(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.onselectSuperAuth(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    onselectSuperAuth(id, name) {
        alert(id + ".." + name)
        this.model.supervisiorAuthid = id;
        this.model.supervisiorAuth = name;
    }


    getPincodeLike($event) {
        let userId = (<HTMLInputElement>document.getElementById('pincode1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getpincodelike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
        if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
            this.lstlocality = [];
        }
    }
    typeaheadOnSelect(id, name) {
        this.pomodel.pincode = name;
        this.model.pincode = name;
        this.lstlocality = [];
        this.transactionService.getlocality(this.pomodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstlocality.push({ id: item.id, name: item.name });
                    this.localitymap.set(item.id, item.name)
                });
            });
    }

    onSelectLocality(id) {
        this.model.localityID = id;
        this.pomodel.localityID = id;
        this.model.locality = this.localitymap.get(id);

        this.transactionService.getgeographydetails(this.pomodel)
            .subscribe(data => {
                this.model.city = data.city;
                this.model.state = data.state;
                this.model.country = data.country;
                this.model.locality = data.locality;
                this.model.localeid = data.localeID
            });
    }
}

