import { OnInit, Component, TemplateRef, PLATFORM_ID } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { GoodsNoteHomeModel } from './GoodsNoteHomeModel';
import { InventoryServices } from 'src/app/_services/InventoryServices';
import * as moment from 'moment';
import { BsModalService } from "ngx-bootstrap";


@Component({
    selector: 'app-GoodsNoteHome',
    templateUrl: 'GoodsNoteHome.html',
})
export class GoodsNoteHome implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private inventoryServ: InventoryServices,
        private modalService: BsModalService, ) {
        this.pagename = this.router.url;
    }
    user = new User();
    model = new GoodsNoteHomeModel();
    grnlst: GoodsNoteHomeModel[] = [];
    grnlst1: GoodsNoteHomeModel[] = [];
    pagename: string;
    p: any;
    ngOnInit(): void {
        
        this.user.pagename = null;
        if (this.pagename != null) {
            this.user.pagename = this.pagename.replace('/', '');
            this.user.pagename = this.user.pagename.replace('/', '');
            this.user.pagename = this.user.pagename.replace('%20', ' ');
            this.user.pagename = this.user.pagename.replace('%20', ' ');
        }
        this.getinventorylst()
        this.model.startdate = new Date()
        this.model.enddate = new Date()
    }

    searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "GRN Number" },
        { id: 3, "name": "Party Name" },
        { id: 4, "name": "Invoice Number" },
        { id: 5, "name": "Invoice Date" },
    ];

    getinventorylst() {
        this.inventoryServ.getAllGrns(this.user)
            .subscribe(value => {
                this.grnlst = [];
                this.grnlst = value
            });
    }


    btnGRN($event) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "pagename": "Goods Receipt Note",
                "action":"New"
            }
        };
        this.router.navigate(['/GIN'], navigationExtras);
    }

    onEdit(grnnumber,poid) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "grnnumber": grnnumber,
                "poid":poid,
                // "pagename": this.user.pagename
                "pagename": "Goods Inward Note",
                "action":"New"
            }
        };
        this.router.navigate(['/GIN'], navigationExtras);
    }


    btndelts(grnnumber) {
        // this.user.userid = this.model.userid;
        // this.user.message = grnnumber;
        // this.i = confirm("Do you want to delete this record ?");
        // if (this.i == true) {
        //     this.transactionServices.deleteGRN(this.user)
        //         .subscribe(
        //             data => {
        //                 if (data.status == "No") {
        //                     this.notifyService.showRroor("Something Wrong !", "Error !");
        //                 }
        //                 else {
        //                     if (data.message == "OK") {
        //                         this.notifyService.showSuccess("Data Deleted Successfully !", "Success !")
        //                         let navigationExtras: NavigationExtras = {
        //                             queryParams: {
        //                                 "pagename": 'Inventory Receipt Note'
        //                             }
        //                         };
        //                         this.router.navigate(['/testpage'], navigationExtras);
        //                     }
        //                 }
        //             },
        //             error => {
        //             });
        // }
    }


    onSelect(value) {
        for (let i = 0; i < this.searchtype.length; i++) {
            if (this.searchtype[i].name == value) {
                this.model.txtselect = this.searchtype[i].name;
            }
        }
    }
    btnView(grnnumber,poid) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "grnnumber": grnnumber,
                "poid":poid,
                // "pagename": this.user.pagename
                "pagename": "Goods Receipt Note",
                "action":"Approve"
            }
        };
        this.router.navigate(['/ViewGoodsReceiptNote'], navigationExtras);

    }
    btnsearch() {
        if (this.model.suppliercheckselect) {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
                return;
            }
            this.grnlst1 = [];
            this.grnlst.forEach((item) => {
                if ((item.supplierName.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                    this.grnlst1.push({ "id": item.id, "srno": item.srno, "supplierName": item.supplierName, "grnstatus": item.grnstatus, "pono": item.pono, "enddate": item.enddate, "grnno": item.grnno, "grndate": item.grndate, "txtserch": item.txtserch, "txtselect": item.txtselect, "reference": item.reference, "suppliercheckselect": item.suppliercheckselect, "grncheckselect": item.grncheckselect, "datecheckselect": item.datecheckselect, "startdate": item.startdate, "ponocheckselect": item.ponocheckselect, "txtserchpono": item.txtserchpono, "txtserchgrn": item.txtserchgrn });
                }
            });
            this.grnlst = this.grnlst1;

        }
        if (this.model.ponocheckselect) {
            if (this.model.txtserchpono == null || this.model.txtserchpono == "") {
                this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
                return;
            }
            this.grnlst1 = [];
            this.grnlst.forEach((item) => {
                if ((item.pono.toLowerCase()).match(this.model.txtserchpono.toLowerCase().trim())) {
                    this.grnlst1.push({ "id": item.id, "srno": item.srno, "supplierName": item.supplierName, "grnstatus": item.grnstatus, "pono": item.pono, "enddate": item.enddate, "grnno": item.grnno, "grndate": item.grndate, "txtserch": item.txtserch, "txtselect": item.txtselect, "reference": item.reference, "suppliercheckselect": item.suppliercheckselect, "grncheckselect": item.grncheckselect, "datecheckselect": item.datecheckselect, "startdate": item.startdate, "ponocheckselect": item.ponocheckselect, "txtserchpono": item.txtserchpono, "txtserchgrn": item.txtserchgrn });
                }
            });
            this.grnlst = this.grnlst1;
        }
        if (this.model.grncheckselect) {
            if (this.model.txtserchgrn == null || this.model.txtserchgrn == "") {
                this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
                return;
            }
            this.grnlst1 = [];
            this.grnlst.forEach((item) => {
                if ((item.grnno.toLowerCase()).match(this.model.txtserchgrn.toLowerCase().trim())) {
                    this.grnlst1.push({ "id": item.id, "srno": item.srno, "supplierName": item.supplierName, "grnstatus": item.grnstatus, "pono": item.pono, "enddate": item.enddate, "grnno": item.grnno, "grndate": item.grndate, "txtserch": item.txtserch, "txtselect": item.txtselect, "reference": item.reference, "suppliercheckselect": item.suppliercheckselect, "grncheckselect": item.grncheckselect, "datecheckselect": item.datecheckselect, "startdate": item.startdate, "ponocheckselect": item.ponocheckselect, "txtserchpono": item.txtserchpono, "txtserchgrn": item.txtserchgrn });
                }
            });
            this.grnlst = this.grnlst1;

        }
        if (this.model.datecheckselect) {
            if (this.model.startdate.toString() == null || this.model.startdate.toString() == "") {
                this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
                return;
            }
            if (this.model.enddate.toString() == null || this.model.enddate.toString() == "") {
                this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
                return;
            }
            let startdate = moment(this.model.startdate).format('YYYY-MM-DD');
            let todate = moment(this.model.enddate).format('YYYY-MM-DD');
            if (this.model.startdate && this.model.enddate) {
                this.grnlst1 = [];
                this.grnlst.forEach((item) => {
                    if (item.grndate.toString() <= todate && item.grndate.toString() >= startdate) {
                        this.grnlst1.push({ "id": item.id, "srno": item.srno, "supplierName": item.supplierName, "grnstatus": item.grnstatus, "pono": item.pono, "enddate": item.enddate, "grnno": item.grnno, "grndate": item.grndate, "txtserch": item.txtserch, "txtselect": item.txtselect, "reference": item.reference, "suppliercheckselect": item.suppliercheckselect, "grncheckselect": item.grncheckselect, "datecheckselect": item.datecheckselect, "startdate": item.startdate, "ponocheckselect": item.ponocheckselect, "txtserchpono": item.txtserchpono, "txtserchgrn": item.txtserchgrn });
                    }
                });
                this.grnlst = this.grnlst1;
            }
        }
        //     if (this.model.txtselect == "All") {
        //         this.getinventorylst();
        //         this.model.txtserch = null;
        //     }
        //     else if (this.model.txtselect == "GRN Number") {
        //         if (this.model.txtserch == null || this.model.txtserch == "") {
        //             this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
        //             return;
        //         }
        //         else {
        //             this.list1 = [];
        //             this.list.forEach((item) => {
        //                 if ((item.grnnumber.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                     this.list1.push({
        //                         "grnstatus": item.grnstatus,
        //                         "id": item.id,
        //                         "invoiceno": item.invoiceno,
        //                         "partyname": item.partyname,
        //                         "srno": item.srno,
        //                         "txtselect": item.txtselect,
        //                         "txtserch": item.txtserch,
        //                         "invoDate": item.invoDate,
        //                         "grnnumber": item.grnnumber,
        //                         "reference": item.reference
        //                     });
        //                 }
        //             });

        //             if (this.list1.length <= 10) {
        //                 this.dataTable.SETLIM(this.list1.length);
        //                 this.reloadItems({ "offset": 0, "limit": this.list1.length });
        //             }
        //             else {
        //                 this.reloadItems({ "offset": 0, "limit": 10 });
        //             }
        //         }
        //     } else if (this.model.txtselect == "Party Name") {
        //         if (this.model.txtserch == null || this.model.txtserch == "") {
        //             this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
        //             return;
        //         }
        //         else {
        //             this.list1 = [];
        //             this.list.forEach((item) => {
        //                 if ((item.partyname.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                     this.list1.push({
        //                         "grnstatus": item.grnstatus,
        //                         "id": item.id,
        //                         "invoiceno": item.invoiceno,
        //                         "partyname": item.partyname,
        //                         "srno": item.srno,
        //                         "txtselect": item.txtselect,
        //                         "txtserch": item.txtserch,
        //                         "invoDate": item.invoDate,
        //                         "grnnumber": item.grnnumber,
        //                         "reference": item.reference
        //                     });
        //                 }
        //             });

        //         }
        //     } else if (this.model.txtselect == "Invoice Number") {
        //         if (this.model.txtserch == null || this.model.txtserch == "") {
        //             this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
        //             return;
        //         }
        //         else {
        //             this.list1 = [];
        //             this.list.forEach((item) => {
        //                 if ((item.invoiceno.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                     this.list1.push({
        //                         "grnstatus": item.grnstatus,
        //                         "id": item.id,
        //                         "invoiceno": item.invoiceno,
        //                         "partyname": item.partyname,
        //                         "srno": item.srno,
        //                         "txtselect": item.txtselect,
        //                         "txtserch": item.txtserch,
        //                         "invoDate": item.invoDate,
        //                         "grnnumber": item.grnnumber,
        //                         "reference": item.reference
        //                     });
        //                 }
        //             });
        //         }
        //     } else if (this.model.txtselect == "Invoice Date") {
        //         if (this.model.txtserch == null || this.model.txtserch == "") {
        //             this.notifyService.showRroor("Enter Criteria To Search !", "Oops !");
        //             return;
        //         }
        //         else {
        //             this.list1 = [];
        //             this.list.forEach((item) => {
        //                 if ((item.invoDate.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                     this.list1.push({
        //                         "grnstatus": item.grnstatus,
        //                         "id": item.id,
        //                         "invoiceno": item.invoiceno,
        //                         "partyname": item.partyname,
        //                         "srno": item.srno,
        //                         "txtselect": item.txtselect,
        //                         "txtserch": item.txtserch,
        //                         "invoDate": item.invoDate,
        //                         "grnnumber": item.grnnumber,
        //                         "reference": item.reference
        //                     });
        //                 }
        //             });
        //         }
        //     }
    }
    selectStartDate(startdate) {
        if (startdate < new Date()) {
          this.notifyService.showRroor("Previous date cannot be selected", "oops");
          this.model.startdate = new Date()
          return;
        }
      }
      selectEndDate(enddate) {
        if (enddate < this.model.startdate) {
          this.notifyService.showRroor("To Date should be grether than From Date", "oops");
          this.model.enddate = this.model.startdate
          return;
        }
      }
}