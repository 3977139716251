import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterServices } from 'src/app/_services/MasterServices';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { User } from 'src/app/_models';
import { TargetHomeModel } from './ TargetHomeModel';
import { TransactionServices } from 'src/app/_services/TransactionServices';
import { BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: ' TargetHome.html'
})
export class  TargetHome implements OnInit {


    p:any;
    id: number;
    user = new User();
    model = new  TargetHomeModel();
    modellst:TargetHomeModel[]=[];
    sub: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, private transactionservice: TransactionServices ) {
    }

    ngOnInit(): void {
       
        this.getAllTargetyear();
    }

    getAllTargetyear() {
        this.model.id=this.id;
        this.transactionservice.getAllTargetyear(this.model)
            .subscribe(value => {
                this.modellst = value;
                // this.reslst1 = [];
                // value.forEach((item) => this.reslst1.push({ "id": item.id, "name": item.name, "designationName": item.designationName, "activeInactive": item.activeInactive, "txtserch": item.txtserch, "txtselect": item.txtselect, "deptName": item.deptName, "branchname": item.branchname, srno: null }));
                // this.reslst = this.reslst1;
            })
    }

    btnnew() {
        this.router.navigate(['/newTargetHome']);
    }
  
    btnedit(resourceid,teamMasterID,financialyearid) {
        this.router.navigate(['/editTargetHome', resourceid,teamMasterID,financialyearid]);

    }

    btndelts(id) {
        // this.i = confirm("Do you want to delete this record ?")
        // if (this.i == true) {
        //     this.model.id = id
        //     this.Masterservice.deleteUser(this.model)
        //         .subscribe(value => {
        //             this.notifyService.showSuccess(value.message, " Success");
        //             this.ngOnInit()
        //         });
        // }
    }
}