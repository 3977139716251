<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Corporate Customer Procurement Summary</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2" style="padding-top: 9px;">
                    <span>From Date</span>
                    <input type="text" placeholder="From Date" [(ngModel)]="model.frmdate" class="form-control"
                        bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                </div>
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2" style="padding-top: 9px;">
                    <span>To Date</span>
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" class="form-control"
                        bsDatepicker (ngModelChange)="selectToDate(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                </div>
            </div>
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class=" input-field col s4" style="margin-bottom:0px;margin-top:0px;padding-left: 5px;">
                    <!-- *ngIf="edit"  -->
                    <span>Customer Type</span>
                    <select (change)=" onSelectcustmertype($event.target.value)">
                        <option *ngFor="let type of lstcustomertype" [selected]="type.name==model.customertypename"
                            [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div>

                <div class="input-field  col s4" style="padding: 0px;margin: 0px;">
                    <!-- *ngIf="model.industrytypebyCtypeflag&&edit"> -->
                    <div class=" input-field col s12" style="padding: 0px;margin-bottom:0px;margin-top:0px;">
                        <span>Industry Type</span>
                        <select (change)=" onSelectindustry($event.target.value)">
                            <option *ngFor="let type of lstindustry" [value]="type.id"
                                [selected]="type.id==model.industrytypeID">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="input-field col l2 m2 s2" style="text-align: right;margin-top: 17px;">
                    <button (click)="btnshow(CorporateSmryReport)" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Show</button>
                </div>

            </div>
        </div>




        <ng-template #CorporateSmryReport>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row" id="LibDocs">
                <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                    <div class="modal-content " style="text-align:center;padding: 0px;margin: 24px;">
                        <span style="font-size: 20px;text-decoration: underline">Corporate Customer Procurement Summary</span>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>

                            </div>
                        </div>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 16px">{{companyaddress}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                        <div class="col l12 m12 s12" style="padding-right: 0px;">
                            <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                                <span><img src="../../../assets/images/printer.png"
                                    (click)="openPrint()" data-toggle="tooltip"
                                    data-placement="top" title="Print" width="30px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/excel2.png"
                                        onclick="tableToExcel('excel')" data-toggle="tooltip"
                                        data-placement="top" title="Excel" width="30px" /></span>
                            </div>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                            id="excel">
                            <tbody>
                                <tr>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>From
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.frmdate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    <td width="20%" style="text-align: left;border: 1px solid black;"><span>To
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.todate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                            id="excel">
                            <thead style="background: gainsboro;">
                                <th width="3%" style="text-align: left;padding-left: 10px;">Sr.No.</th>
                                <th width="16%" style="text-align: left">Supervising Authority</th>
                                <th width="12%" style="text-align: left">Customer Name</th>
                                <th width="12%" style="text-align: left">Supplier</th>
                                <th width="14%" style="text-align: left">Item</th>
                                <th width="6%" style="text-align: center">Rate</th>
                                <th width="6%" style="text-align: center">Quantity</th>
                                <th width="14%" style="text-align: center">Invoice No.</th>
                                <th width="9%" style="text-align: left">Invoice Date</th>
                                <th width="8%" style="text-align: right;padding-right: 10px;">Amount</th>

                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of corporatelst">
                                    <td style="text-align: left;;padding-left: 10px;">{{lst.serialNo}}</td>
                                    <td style="text-align: left">{{lst.supervisingAuth}}</td>
                                    <td style="text-align: left">{{lst.partyName}}</td>
                                    <td style="text-align: left">{{lst.suppiler}}</td>
                                    <td style="text-align: left">{{lst.item}}</td>
                                    <td style="text-align: right">{{lst.rate}}</td>
                                    <td style="text-align: right">{{lst.qty}}</td>
                                    <td style="text-align: right">{{lst.invoiceNo}}</td>
                                    <td style="text-align: left">{{lst.invoiceDate | date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align: right;padding-right: 10px;">&nbsp;&#8377;{{lst.amount}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="padding-right: 0px;">
                    <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="modalRef.hide()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>