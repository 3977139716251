﻿<div *ngIf="isMobile == false">
    <div class="row" style="max-width:900px;">
        <div style="width: 900px;margin-top: 60px;">
            <div class="col s2 l3 m3"></div>
            <div class="input-field col l6 s8 m6 registerBgShadow "
                style="height: 100%;padding-top: 35px; background: #f7f7f7;">
                <div class="center-align">
                    <img id="profile-img" src="../../assets/images/easy-dry-logo.png" width="50%" />
                </div>
                <form (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate style="margin-top: 10px;">
                    <div class="col l12 s12 m12" style="margin-bottom: 3%">
                        <div class="row">
                            <div class="input-field col l12 s12 m12" style=" margin-left: 27px;width: 85%;;">
                                <input name="username" [(ngModel)]="model.usernameOrEmail" #username="ngModel" id="user"
                                    type="text" class="validate" required autofocus>
                                <div *ngIf="f.submitted && !username.valid" class="help-block"
                                    style="padding-top: 3px;color: red">Username is
                                    required</div>
                                <label for="user">Username</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col l12 s12 m12"
                                style="margin-left: 27px;width: 85%;; margin-bottom: 35px;">
                                <input type="password" class="validate" name="password" [(ngModel)]="passaw" id="pass"
                                    #pass="ngModel" required autofocus>
                                <div *ngIf="f.submitted && !pass.valid" class="help-block"
                                    style="padding-top: 3px;color: red">Password is
                                    required</div>
                                <label for="pass">Password</label>
                            </div>
                        </div>
                        <div class="row" style=" padding: 1%;">
                            <div class="center-align">
                                <button class="waves-effect waves-light btn" type="submit">Log In</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row">
                    <div class="col l12 s12 m12"
                        style=" padding-top: 1%;padding-bottom: 1%;padding-left: 4%;padding-right: 4%;">
                        <div class="col l9 m9 s9" style="text-align: right; margin-top: 10%;">
                            <span style="font-weight: bold">Powered By:</span>&nbsp;&nbsp;
                        </div>
                        <div class="col l3 m3 s3" style="text-align: right">
                            <img id="profile-img" src="../../assets/images/RavishSysLogo_2.png" width="110%" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s2 l3 m3"></div>
        </div>
    </div>
</div>

<div *ngIf="isMobile == true">
    <div class="row" style="max-width:900px;">
        <div style="margin-top: 60px;">
            <div class="col s2 l3 m3"></div>
            <div class="input-field col l6 s8 m6 registerBgShadow "
                style="height: 100%;padding-top: 35px; background: #f7f7f7;">
                <div class="center-align">
                    <img id="profile-img" src="../../assets/images/easy-dry-logo.png" width="50%" />
                </div>
                <form (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate style="margin-top: 10px;">
                    <div class="col l12 s12 m12" style="margin-bottom: 3%">
                        <div class="row">
                            <div class="input-field col l12 s12 m12" style=" margin-left: 27px;width: 85%;;">
                                <input name="username" [(ngModel)]="model.usernameOrEmail" #username="ngModel" id="user"
                                    type="text" class="validate" required autofocus>
                                <div *ngIf="f.submitted && !username.valid" class="help-block"
                                    style="padding-top: 3px;color: red">Username is
                                    required</div>
                                <label for="user">Username</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col l12 s12 m12"
                                style="margin-left: 27px;width: 85%;; margin-bottom: 35px;">
                                <input type="password" class="validate" name="password" [(ngModel)]="passaw" id="pass"
                                    #pass="ngModel" required autofocus>
                                <div *ngIf="f.submitted && !pass.valid" class="help-block"
                                    style="padding-top: 3px;color: red">Password is
                                    required</div>
                                <label for="pass">Password</label>
                            </div>
                        </div>
                        <div class="row" style=" padding: 1%;">
                            <div class="center-align">
                                <button class="waves-effect waves-light btn" type="submit">Log In</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row">
                    <div class="col l12 s12 m12"
                        style=" padding-top: 1%;padding-bottom: 1%;padding-left: 4%;padding-right: 4%;">
                        <div class="col l9 m9 s9" style="text-align: right; margin-top: 10%;">
                            <span style="font-weight: bold">Powered By:</span>&nbsp;&nbsp;
                        </div>
                        <div class="col l3 m3 s3" style="text-align: right;margin-top: 17px;">
                            <img id="profile-img" src="../../assets/images/RavishSysLogo_2.png" width="110%" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s2 l3 m3"></div>
        </div>
    </div>
</div>