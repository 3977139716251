export class DefineAccountMasterModel {

    id: number;
    code: String;
    name: string;
    address: string;
    buildingName:string;
    unitNumber:String;
    branch: String;
    locality: string;
    pincode: string;
    city: string;
    state: string;
    country: string;
    panno: string;
    telephone1: string;
    telephone2: string;
    email: String;
    website: string;
    industry: number;
    constitute: string;
    annualrevenue: string;
    customerTypeId:number;
    gstinno:string;
    wigstflag:string;
    outgstflag:string;
    noofemployee: number;
    contactlst: DefineAccountmodel[] = [];
    localename:string;
    localeid:number;

    industorylst: DefineAccountMasterModel[] = [];
    consistutelst: DefineAccountMasterModel[] = [];
    annualrevenuelst: DefineAccountMasterModel[] = [];
    noofemplst: DefineAccountMasterModel[] = [];
    localityID:number;


    chkwihitins:boolean;
    chkoutsides:boolean;
    gsttype:string;
    landline:string;

    supervisiorAuth:string;
    supervisiorAuthid:number;
}
export class DefineAccountmodel {
    contactid: number;
    checkselect: boolean;
   
    salutation: string;
    contactname: string;
    designation: string;
    mobileno: string;
    contactemail: string;
    extension: number;

}