<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Account 360 </span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">

                    <!-- <div class=" input-field col s4" style="margin-top: 20px;margin-bottom: 0px;padding-left: 14px;">
                        <span>Customer Name</span>
                        <input type="text" class="autocomplete" [(ngModel)]="model.customerName" id="customerName1"
                            placeholder="Customer Name" (keyup)="getcustomernameLike($event)">
                        <input type="hidden" id="acc-id" />
                    </div> -->
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col s6 m6 l6" style=" font-size: 15px;margin-top: 20px;">
                            <span style="font-size: 15px;">Customer Name:&nbsp; <b>{{model.customername}}</b></span>
                        </div>
                        <div class="col s6 m6 l6" style="margin-top: 20px;text-align: start">
                            <span style="font-size: 15px;">Customer Type:&nbsp;<b>{{model.customertypename}}</b></span>
                        </div>

                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col s6 m6 l6">
                            <span style="font-size: 15px;">
                                Address:&nbsp;<span><b>{{model.address}}</b></span></span>
                        </div>
                        <div class="col s3 m3 l3" style="margin: 0px;">
                            <span style="font-size: 15px;">Pincode:&nbsp;<span><b>{{model.pincode}}</b></span></span>
                        </div>
                        <div class="col s3 m3 l3" style="margin: 0px;">
                            <span style="font-size: 15px;">Locality:&nbsp;<span><b>{{model.locality}}</b></span></span>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <table>
                            <tbody>
                                <tr style="background: gainsboro;">
                                    <td width="17%"> <span
                                            style="font-size: 15px;">Locale:<br><span><b>{{model.locale}}</b></span></span>
                                    </td>
                                    <td width="17%"><span
                                            style="font-size: 15px;">City:<br><span><b>{{model.city}}</b></span></span>
                                    </td>
                                    <td width="17%"><span
                                            style="font-size: 15px;">State:<br><span><b>{{model.state}}</b></span></span>
                                    </td>
                                    <td width="15%"><span
                                            style="font-size: 15px;">Country:<br><span><b>{{model.country}}</b></span></span>
                                    </td>
                                    <td width="17%"> <span
                                            style="font-size: 15px;">Area:<br><span><b>{{model.areaname}}</b></span></span>
                                    </td>
                                    <td width="17%"> <span
                                            style="font-size: 15px;">Region:<br><span><b>{{model.regionname}}</b></span></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col s4 l4 m4">
                            <span style="font-size: 15px;">Mobile 1:&nbsp;<span><b>{{model.mobile1}}</b></span></span>
                        </div>
                        <div class="col s4 l4 m4">
                            <span style="font-size: 15px;">Mobile 2:&nbsp;<span><b>{{model.mobile2}}</b></span></span>
                        </div>
                        <div class="col s4 l4 m4">
                            <span style="font-size: 15px;">Landline:&nbsp;<span><b>{{model.landline}}</b></span></span>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <div class="col s4 l4 m4">
                            <span style="font-size: 15px;">Email-id:&nbsp;<span><b>{{model.email}}</b></span></span>
                        </div>
                        <div class=" input-field col s5 l5 m5" style="margin: 0px;">
                            <span style="font-size: 15px;">Website:&nbsp;<span><b>{{model.website}}</b></span></span>
                        </div>
                    </div>
                    <div class="input-field col s12 " style="margin: 0px;padding: 0px;">
                        <div class="input-field col s6 " style="padding-top: 10px;padding: 0px;margin-bottom: 5px;">
                            <b style="font-size: 15px;">Contacts</b>
                        </div>
                    </div>
                    <div class="row" style="padding-left: 10px;padding-right: 10px;">
                        <!-- <b style="font-size: 15px;">Contacts</b> -->
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="20%" style="text-align: left;padding-left: 10px;">Name</th>
                                <th width="10%" style="text-align: left" *ngIf="model.chkdesignation">Designation</th>
                                <th width="10%" style="text-align: left">Mobile</th>
                                <th width="20%" style="text-align: left">E-Mail</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of model.lstcontact; let i = index">
                                    <td style="text-align: left;padding-left: 10px;">
                                        <span>{{lst.contactname}}</span>
                                    </td>
                                    <td class=" input-field " style="text-align: left;" *ngIf="model.chkdesignation">
                                        <span>{{lst.contactdesignation}}</span>
                                    </td>
                                    <td style="text-align: left">
                                        <span>{{lst.contactmobile}}</span>
                                    </td>
                                    <td style="text-align: left;">
                                        <span> {{lst.contactemail}}</span>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="input-field col s12 " style="margin: 0px;padding: 0px;"
                    *ngIf="this.forflag!='newprospect'">
                    <div class="input-field col s6 " style="padding-top: 10px;padding: 0px;">
                        <b style="font-size: 15px;">Product Portfolio</b>
                    </div>
                    <div class="input-field col s6" style="text-align: end;padding: 0px;" *ngIf="edit">
                        <button type="button" class="waves-ripple waves-light btn" style="margin-right: 0px;"
                            (click)="openlocationmodel(locationmodel,null)">Add
                            Product</button>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px; "
                    *ngIf="this.forflag!='newprospect'">

                    <table>
                        <thead style="background: gainsboro;">
                            <th width="20%" style="text-align: left">Product Model</th>
                            <th width="15%" style="text-align: left">Location</th>
                            <th width="15%" style="text-align: left">Installation Date</th>
                            <th width="15%" style="text-align: left">Status</th>
                            <!-- <th width="10%" style="text-align: center">Installed On</th> -->
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of model.lstproduct; let i = index">
                                
                                <td class=" input-field" style="text-align: left">
                                    <span *ngIf="lst.added"> {{lst.name}} </span>
                                </td>

                                <td style="text-align: left">
                                    <span> {{lst.location}} </span>
                                </td>
                                <td style="text-align: left">
                                    <span> {{lst.installationDate| date:'d MMMM y'}} </span>
                                </td>
                                <td style="text-align: left">
                                    <span> {{lst.serviceCallStatusName}} </span>
                                </td>
                               

 
                            </tr>
                        </tbody>
                    </table>
                </div>
                    <div class="input-field col s12 " style="margin: 0px;padding: 0px;">
                        <div class="input-field col s6 " style="padding-top: 10px;padding: 0px;">
                            <b style="font-size: 15px;">Service Call</b>
                        </div>
                        <div class="input-field col s6 " style="padding: 0px;text-align: end;">
                            <button type="button" class="waves-ripple waves-light btn" (click)="btnbookservice()">Book
                                Service</button>
                        </div>
                    </div>
                    <div class="row" style="margin-left: 0px;margin-right: 0px;">
                        <div class="col s12 m12 l12 registerBgShadow"
                            style="padding-top: 5px;padding-left: 5px;padding-right: 5px;">
                            <div class="col l12 m12 s12"
                                style="background: gainsboro; padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;">
                                <div class="col l1 m1 s1" style="text-align:left;padding-left: 10px;">
                                    <span>Sr. No.</span>
                                </div>
                                <div class="col l3 m3 s3" style="text-align:left;">
                                    <span>Service Call No.</span>
                                </div>
                                <div class="col l3 m3 s3" style="text-align:left;">
                                    <span>Resource</span>
                                </div>
                                <div class="col l3 m3 s3" style="text-align:left;">
                                    <span>Status</span>
                                </div>
                                <div class="col l2 m2 s2" style="text-align:left;">
                                    <span>Date</span>
                                </div>
                            </div>
                            <mat-accordion style="padding: 0px;">
                                <mat-expansion-panel *ngFor="let lst of  model.servicelst ;let i = index "
                                    style="padding: 1px;">
                                    <mat-expansion-panel-header>
                                        <div class="col l12 m12 s12"
                                            style="margin-top:20px;color: black;font-weight: bold;">
                                            <div class="row">
                                                <div class=" col l1 m1 s1" style="text-align:left;padding-left: 10px;">
                                                    <span>{{lst.serialNo}}</span>
                                                </div>
                                                <div class=" col l3 m3 s3" style="text-align:left;">
                                                    <span>{{lst.servicecallno}}</span>
                                                </div>
                                                <div class=" col l3 m3 s3" style="text-align:left;">
                                                    <span>{{lst.name}}</span>
                                                </div>
                                                <div class=" col l3 m3 s3" style="text-align:left;">
                                                    <span>{{lst.status}}</span>
                                                </div>
                                                <div class=" col l2 m2 s2" style="text-align:left;">
                                                    <span>{{lst.callBookDate | date :"dd-MM-yyyy"}}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div *ngIf="lst.dcrHistorylst.length>0" class="registerBgShadow col l12 m12 s12"
                                        style="text-align: center; margin-top: 10px;">
                                        <div class="row">
                                            <div class="col l2 m2 s2" style="text-align:left">
                                                <span> <b>DCR date</b></span>
                                            </div>
                                            <div class="col s3" style="text-align:left">
                                                <span> <b>Activity</b></span>
                                            </div>
                                            <div class="col s3" style="text-align:left">
                                                <span> <b>Resource</b></span>
                                            </div>
                                            <div class="col s4" style="text-align:left;padding-right: 0px;">
                                                <span> <b>Remarks</b></span>
                                            </div>
                                        </div>
                                        <div class="row" *ngFor="let sublst of lst.dcrHistorylst">
                                            <div class="col l2 m2 s2" style="text-align:left">
                                                <span>{{sublst.dcrdate | date:"dd-MM-yyyy"}}</span>
                                            </div>
                                            <div class="col s3" style="text-align:left">
                                                <span>{{sublst.activity}}</span>
                                            </div>
                                            <div class="col s3" style="text-align:left">
                                                <span>{{sublst.assignedByName}}</span>
                                            </div>
                                            <div class="col s4" style="text-align:left;padding-right: 0px;">
                                                <span>{{sublst.remarks}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="lst.dcrHistorylst.length==0" class="col l11 m11 s11 registerBgShadow"
                                        style="margin-left:50px;text-align: center;">
                                        <div class="col l12 m12 s12" style="color: red">
                                            <samp> No Book Call </samp>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="input-field col s12 "
                        style="margin: 0pxlstproduct;padding: 0px;margin-top: 0px;margin-bottom: 0px;">
                        <div class="input-field col s6 " style="padding-top: 10px;padding: 0px;">
                            <b style="font-size: 15px;">Leads</b>
                        </div>
                        <div class="input-field col s6 " style="padding: 0px;text-align: end;">
                            <button type="button" (click)="btncreatelead()" class="waves-ripple waves-light btn"
                                style="margin-right: 0px;">Create Lead</button>
                        </div>
                    </div>
                    <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 0px">
                        <div class="row">
                            <div class="col s12 m12 l12 registerBgShadow" style="padding:5px;">
                                <div class="col l12 m12 s12"
                                    style="background: gainsboro; padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;">
                                    <div class="col l3 m3 s3" style="text-align:left;">
                                        <span>Enquiry Number</span>
                                    </div>
                                    <div class="col l2 m2 s2" style="text-align:left;">
                                        <span>Assigned To</span>
                                    </div>
                                    <div class="col l2 m2 s2" style="text-align:left;">
                                        <span>Stage</span>
                                    </div>
                                    <div class="col l1 m1 s1" style="text-align:left;">
                                        <span>City</span>
                                    </div>
                                    <div class="col l2 m2 s2" style="text-align:left;">
                                        <span>Locality</span>
                                    </div>
                                    <div class="col l2 m2 s2" style="text-align:left;">
                                        <span>Status</span>
                                    </div>
                                </div>
                                <mat-accordion style="padding: 0px;">
                                    <mat-expansion-panel *ngFor="let lst of model.opportunitieslst ;let i = index "
                                        style="padding: 1px;">
                                        <mat-expansion-panel-header>
                                            <div class="col l12 m12 s12"
                                                style="margin-top:20px;color: black;font-weight: bold;">
                                                <div class="row">
                                                    <div class=" col s3  l3 m3 " style="text-align:left;">
                                                        <span>{{lst.enquiryName}}</span>
                                                    </div>
                                                    <div class=" col s2  l2 m2 " style="text-align:left;">
                                                        <span>{{lst.assignedByName}}</span>
                                                    </div>
                                                    <div class=" col l2 m2 s2" style="text-align:left;">
                                                        <span>{{lst.stage}}</span>
                                                    </div>
                                                    <div class=" col l1 m1 s1" style="text-align:left;">
                                                        <span>{{lst.city}}</span>
                                                    </div>
                                                    <div class=" col l2 m2 s2" style="text-align:left;">
                                                        <span>{{lst.locallity}}</span>
                                                    </div>
                                                    <div class=" col l2 m2 s2" style="text-align:left;">
                                                        <span>{{lst.status}}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="lst.dcrHistorylst.length>0" class="registerBgShadow col l12 m12 s12"
                                            style="text-align: center; margin-top: 10px;">
                                            <div class="row">
                                                <div class="col l2 m2 s2" style="text-align:left">
                                                    <span> <b>DCR date</b></span>
                                                </div>
                                                <div class="col l2 m2 s2" style="text-align:left">
                                                    <span> <b>Activity</b></span>
                                                </div>
                                                <div class="col l3 m3 s3" style="text-align:left">
                                                    <span> <b>Resource</b></span>
                                                </div>
                                                <div class="col l5 m5 s5" style="text-align:left;padding-right: 0px;">
                                                    <span> <b>Remarks</b></span>
                                                </div>
                                            </div>
                                            <div class="row" *ngFor="let sublst of lst.dcrHistorylst">
                                                <div class="col l2 m2 s2" style="text-align:left">
                                                    <span>{{sublst.dcrdate | date:"dd-MM-yyyy"}}</span>
                                                </div>
                                                <div class="col l2 m2 s2" style="text-align:left">
                                                    <span>{{sublst.activity}}</span>
                                                </div>
                                                <div class="col l3 m3 s3" style="text-align:left">
                                                    <span>{{sublst.assignedByName}}</span>
                                                </div>
                                                <div class="col l5 m5 s5" style="text-align:left;padding-right: 0px;">
                                                    <span>{{sublst.remarks}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="lst.dcrHistorylst.length==0"
                                            class="col l11 m11 s11 registerBgShadow"
                                            style="margin-left:50px;text-align: center;">
                                            <div class="col l12 m12 s12" style="color: red">
                                                <samp> NO DCR </samp>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align: right;margin-top: 12px;">
                            <button (click)="btncancle(pageno)" type="button"
                                class="waves-effect cancelbutton btn">Back</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>