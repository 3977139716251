<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;"> Payment Receipts</span>
            </div>
            <div class="col l2 m2 s2">
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row" style=margin-left:0px;margin-right:0px;>
                <div class="input-field col l3 s12 m3" style="margin:left 0px; margin: right 0px;margin-top: 10px;">
                    <span>Search Criteria</span>
                    <select (change)="onSelect($event.target.value)">
                        <option selected disabled>Choose One</option>
                        <option *ngFor="let type of searchtype" [value]="type.name">{{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 s12 m3" style="text-align: center;margin-top: 25px">
                    <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search Field</label>
                </div>
                <div class="right-align col l3 m4 s12">
                    <button type="button" (click)="btnsearch($event)" class="waves-effect waves-light btn"style="margin-top:25px;">Go</button>&nbsp;&nbsp;
                    <button type="button" class="waves-effect waves-light btn" 
                        style ="margin-top: 25px;" (click)="btnnew($event)">New</button>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left">Sr.no</th>
                            <th width="30%" style="text-align: left">Party</th>
                            <th width="20%" style="text-align: left">Payment Number</th>
                            <th width="15%" style="text-align: left">Payment Type</th>
                            <th width="15%" style="text-align: left">Payment Date</th>
                            <th width="10%" style="text-align: center">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of paymentreceipts | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: left">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.partyname}}</td>
                                <td style="text-align: left">{{lst.paymentNo}}</td>
                                <td style="text-align: left">{{lst.paymenttype}}</td>
                                <td style="text-align: left">{{lst.paymentDate | date : "dd-MM-yyyy"}}</td>
                                <td style="text-align: center"><span class="pointer"><img
                                            src="../../../assets/images/view.png"
                                            (click)="btnview(lst.id, paymentreceipt)" title="View"
                                            style="width: 26%" /></span>&nbsp;&nbsp;
                                            <!-- *ngIf="user.isapproval=='Y'" -->
                                    <span class="pointer"><img  *ngIf="user.isapproval=='Y'"
                                            src="../../../assets/images/delete.png"  style="width: 26%"
                                            (click)="btndelts(lst.id)" title="Delete" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align: right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>