import { Component, OnInit, Inject, forwardRef } from "@angular/core";

import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router/";
import { BehaviorSubject, from } from "rxjs";
import { Location, getNumberOfCurrencyDigits } from '@angular/common';

import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import { DropDownModel } from "../../_models/DropDownModel";
import { AngularWaitBarrier } from "blocking-proxy/built/lib/angular_wait_barrier";
import { User } from 'src/app/_models';
import { AlertService, AuthenticationService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { ResetPasswordModel } from './ResetPasswordModel';
import { EncrDecrService } from 'src/app/_services/EncrDecrService';


declare var $: any;
@Component({
    selector: "app-login",
    //  moduleId: module.id,
    templateUrl: "ResetPassword.html",
})
export class ResetPassword implements OnInit {

    str: any;
    itemCount = 0;
    user = new User();
    model: any = {};
    pagename: string;
    lstuser: DropDownModel[] = [];
    id: number;

    constructor(
        private Masterservice: MasterServices,
        private alertService: AlertService,
        private userService: MasterServices,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private notifyService: NotificationService,
        private EncrDecr: EncrDecrService
    ) {  this.pagename=this.router.url}

    ngOnInit() {
        this.model.userid = Number(localStorage.getItem("userid"));
        // localStorage.setItem("pagename",this.pagename);  
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        // this.user.token = localStorage.getItem('Token');
        // this.Masterservice.checklogin(this.model).subscribe(
        //     data => {
        //         if (data.message == 'login again') {
        //             this.notifyService.showRroor("Session Expired !", "Error !");
        //             this.authenticationService.logout();
        //             this.router.navigate(['/Redirect']);
        //         }
        //     });
        this.getalluser();
    }

    getalluser() {
        let user = new User();
        this.Masterservice.getActiveuser(this.model)
        .subscribe((value) => {
            this.lstuser = [];
            value.forEach((item) =>
                this.lstuser.push({ id: item.id, name: item.name })
            );
        });
    }
    onSelectlstuser(id) {
        // if(id==0){
        //     this.notifyService.showRroor("Select User!!", "Oops!");
        //     return; 
        // }
       
        this.model.id = id;
    }
    btnsave() {
      
        if (this.model.pass == null || this.model.pass == "") {
            this.notifyService.showRroor("Password Reqired !!", " Oops!");
            return;
        } else {
            var encrypted = this.EncrDecr.set('UMJ3wfzn@kMpARd', this.model.pass);
            this.model.password = encrypted;
        }
        this.Masterservice.savepassword(this.model).subscribe(
            data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess(data.message, "Done!");
                    this.router.navigate(["/Home"]);
                } else {
                    this.notifyService.showRroor(data.message, " Oops!");
                }
            });
    }



    cancle() {
        this.router.navigate(["CRMHome"]);

    }

}