export class ProductResourceRightsModel {
    userid: number;
    resourceID: number;
    pcategorylst: ProductCategory[];
    resourcectype: ResourceCType[];
}
export interface ProductCategory {
    id: number;
    name: string;
    chkselectcat: boolean;
}
export interface ResourceCType {
    id: number;
    type: string;
    chkselecttype: boolean;
}