import { OnInit, Component, OnDestroy } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { CityMasterModel } from './CityMasterModel';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
@Component({
    selector: 'app-city',
    //  moduleId: module.id,
    templateUrl: 'DefineCityMaster.html'
})
export class DefineCityMaster implements OnInit,OnDestroy {
    user = new User();
    model = new CityMasterModel();
    public countrylst: DropDownModel[] = [];
    public statelst: DropDownModel[] = [];
    public regionlst: DropDownModel[] = [];
    id: number;
    pagename:string;

    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
    }
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getAllCountries();
        localStorage.setItem("pagename",this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.Masterservice.getCityById(user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.getStatesByCountry(this.model.countryid)
                    });
        }
    }

    getAllCountries() {
        this.countrylst = []
        this.Masterservice.getAllCountries(this.user)
            .subscribe(value => {
                value.forEach((item) => this.countrylst.push({ id: item.id, name: item.name }));
            });
    }

    onSelectCountry(val) {
        if(val==0){
            this.notifyService.showRroor("Select Country!!", "Oops!");
            return; 
        }
        this.model.countryid = val
        this.getStatesByCountry(val)
    }

    getStatesByCountry(cntryid) {
        this.statelst = []
        this.user.trnid = cntryid
        this.Masterservice.getStatesByCountry(this.user)
            .subscribe(value => {
                value.forEach((item) => this.statelst.push({ id: item.id, name: item.name }));
            });
    }
    onSelectState(val) {
        if(val==0){
            this.notifyService.showRroor("Select State!!", "Oops!");
            return; 
        }
        this.model.stateid = val
    
    }

    btnsaveCity() {
        if (this.model.code == null) {
            this.notifyService.showRroor("Please enter City code", "Oops!");
            return;
        }
        if (this.model.name == null) {
            this.notifyService.showRroor("Please enter City name", "Oops!");
            return;
        }
        if (this.model.countryid == null) {
            this.notifyService.showRroor("Please select country", "Oops!");
            return;
        }
        if (this.model.stateid == null) {
            this.notifyService.showRroor("Please select state", "Oops!");
            return;
        }

        this.Masterservice.btnsaveCity(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['/City']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    btnback() {
        this.router.navigate(["City"]);
    }
}