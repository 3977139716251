<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{page}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>

        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row">
                <div class="col s12 m12 l12">
                    <!-- <div class="col s1 l3 m3">
                    </div> -->
                    <div class="input-field col s3 l3 m2" style="margin-top: 9px; padding-left: 5px;">
                        <span>Search Criteria</span>
                        <select (change)="onSelect($event.target.value)" data-toggle="tooltip" data-placement="top"
                            title="Search Type">
                            <option selected>Choose</option>
                            <option>All</option>
                            <option *ngFor="let type of searchLst" [value]="type.colname">
                                {{type.name}} </option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center;margin-top: 26px">
                        <input type="text" [(ngModel)]="model.txtserch" #name="ngModel" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class="input-field col s3 l3 m3" style="margin-top: 26px">
                        <button type="button" (click)="btnsearch()"
                            class="waves-ripple waves-light btn">Go</button>&nbsp;&nbsp;
                        <button type="button" class="waves-ripple waves-light btn" style="margin-left: 5px;"
                            (click)="btnnew()">New</button>
                    </div>
                    <!-- <div class="col l1 m1">
                    </div> -->
                    <!-- <div class="col s2 l2 m2" style="margin-top: 7px;text-align: right">
                    </div> -->
                </div>
            </div>

            <div class="row" style="margin-left: 0px;margin-right: 0px;">
                <table>
                    <thead>
                        <tr style="background: gainsboro;">
                            <td style="text-align: left;color: rgb(0, 136, 199);font-weight: bold" width="10%">Sr.No
                            </td>
                            <td *ngFor="let lst of headerlst"
                                style="text-align: left;color: rgb(0, 136, 199); font-weight: bold">
                                {{lst.headerDspname}}</td>
                            <td style="text-align: left;color: rgb(0, 136, 199); font-weight: bold" width="15%">
                                Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of masterDatalst | paginate: { itemsPerPage: 10, currentPage: p }">
                            <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                            <td style="text-align: left;" *ngIf="lst.column1!=null">{{lst.column1}}</td>
                            <td style="text-align: left;" *ngIf="lst.column2!=null">{{lst.column2}}</td>
                            <td style="text-align: left;" *ngIf="lst.column3!=null">{{lst.column3}}</td>
                            <td style="text-align: left;" *ngIf="lst.column4!=null">{{lst.column4}}</td>
                            <td style="text-align: left;" *ngIf="lst.column5!=null">{{lst.column5}}</td>
                            <td style="text-align: left;">
                                <span><img src="../../../assets/images/edit.png" (click)="btnedit(lst.id)" title="Edit"
                                        width="20px" /></span>&nbsp;&nbsp;
                                <span><img src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                        title="Delete" width="20px" /></span>&nbsp;&nbsp;
                                <!-- <span><img src="../../../assets/images/listicon.png" title="Delete"
                                        width="20px" /></span> -->

                            </td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align: right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </span>
            </div>
        </div>

        <!-- <ng-template #coupenmodel>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="registerBgShadow">
                <div class="modal-content " style="text-align: center;">
                    <span style="font-size: 20px;">{{pagename1}}</span><br>
                </div>

                <div class="row">
                    <span style="margin-left:950px;"><img src="../../../assets/images/excel2.png"
                            onclick="tableToExcel('excel')" data-toggle="tooltip" data-placement="top" title="Excel"
                            width="30px" /></span>

                    <div class="col l12 m12 s12">
                        <div class="col l6 m6 s6">
                            <label>Scheme Code:&nbsp;&nbsp;<b>{{model.code}}</b></label>
                        </div>
                        <div class="col l6 m6 s6">
                            <label>Scheme Name:&nbsp;&nbsp;<b>{{model.name}}</b></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="">
                        <div class="col l12 m12 s12">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                                id="excel">
                                <thead style="background: gainsboro;">
                                    <th width="10%" style="text-align: center">Sr.No.</th>
                                    <th width="30%" style="text-align: center">Coupon Code</th>
                                    <th width="30%" style="text-align: center">Quantity</th>
                                    <th width="20%" style="text-align: center" *ngIf="model2.schemeUnlimited == true">
                                        Coupen Days</th>
                                    <th width="30%" style="text-align: center" *ngIf="model2.schemeUnlimited == false">
                                        Validity</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lstcoupen of coupenlst | paginate: { itemsPerPage: 10, currentPage: q }">
                                        <td style="text-align: center">{{lstcoupen.srno}}</td>
                                        <td style="text-align: center">{{lstcoupen.code}}</td>
                                        <td style="text-align: center">{{lstcoupen.coupenDays}}</td>
                                        <td style="text-align: center" *ngIf="model2.schemeUnlimited == true">
                                            {{lstcoupen.days}}</td>
                                        <td style="text-align: center" *ngIf="model2.schemeUnlimited == false">
                                            {{lstcoupen.todate | date:"dd-MM-yyyy"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="q = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template> -->

    </div>
</div>