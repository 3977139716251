import { OnInit, TemplateRef } from "@angular/core";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AlertService } from "src/app/_services/alert.service";
import { MasterServices } from "src/app/_services/MasterServices";
import { NotificationService } from "src/app/_services/notification.service";
import { TransactionServices } from "src/app/_services/TransactionServices";
import { Location, } from '@angular/common';
import { User } from "src/app/_models";
import { DefinePuchaseOrderModel, productModels } from "./DefinePuchaseOrderModel";
import { Products } from "../DailyCall/DailyCallModel";
import { CurrencyModel, QuotationProductModel } from "../Quotation/QuotationModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { DefineProductModel } from "src/app/Master/ProductMaster/DefineProductModel";
import { DealersService } from "src/app/_services/DealersService";
import { DatePipe } from "@angular/common";
import * as moment from 'moment';
import { SchemeConfigurateModel } from "../Scheme_Configurator/SchemeConfigurateModel";
import convertor from 'rupees-to-words';

// var converter = require('number-to-words');
var convert = require('decimal-number-to-words');
@Component({
    selector: 'app-order',
    templateUrl: 'DefinePuchaseOrder.html'
})
export class DefinePuchaseOrder implements OnInit {
    user = new User();
    model: any = {};
    id: number;
    p: any;
    procatlst: Products[] = [];
    modellst: productModels[] = [];
    modalRef: BsModalRef;
    productcatelst: DropDownModel[] = [];
    procatid: number
    procatname: string;
    productid: number;
    prouctname: string;
    currencyid: number;
    gstrate: number;
    currencyname: string;
    productlst: Products[] = [];
    uom: string;
    price: number;
    minimumPrice: number;
    discount: number;
    gstamount: number;
    uomid: number;
    currencylst: CurrencyModel[] = [];
    procurrencylst: CurrencyModel[] = [];
    currcymodel = new CurrencyModel();
    sub: any;
    amount: number;
    netorder: number;
    gst: number;
    total: number;
    alltotalword: string;
    dates = new Date();
    productcategoryid: number;
    currentdate = new Date();
    value: number;
    totalnewvalue: number;
    offereddiscount: number;
    totalamount: number;
    newdiscountamt: number;
    netamount: number;
    ratewithdiscount: number;
    newprice: number;
    discountAmount: number;
    schemetype: string;
    acceptedall: string;
    iseditflag: boolean;
    freefreightvalue : number;
    schemeModel = new SchemeConfigurateModel();
    constructor(private Masterservice: MasterServices,
        private datePipe: DatePipe,
        private dealersService: DealersService,
        private modalService: BsModalService,
        private alertService: AlertService,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private location: Location,) {
    }

    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem('userid'));
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {

            this.model.id = this.id;
            this.dealersService.getpurchaseordByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.modellst = data.modellst
                        this.model.chkschemeflag = data.chkschemeflag;
                        this.model.schemetype = data.schemetype;
                        this.model.orderaccp = data.orderaccp;
                        this.netorder = this.model.netorder
                        this.model.acceptedall = data.acceptedall;
                        this.gst = this.model.gst
                        this.model.approvedorderflg = data.approvedorderflg;
                        //this.model.iseditflag = data.iseditflag;
                        // this.total = this.model.total
                        // this.alltotalword = convert.toWords(this.model.total.toFixed(2))
                        for (let i = 0; i < this.modellst.length; i++) {
                            // this.modellst[i].discountAmount = this.schemeModel.schemeDiscount
                            this.model.discountAmount = this.modellst[i].discountAmount
                            this.model.customnumber = this.model.discountAmount
                            if(this.model.schemetype != 'Custom')
                            {
                                this.total = this.modellst[i].value
                            }
                            
                            // this.alltotalword = convertor(this.total.toFixed(2));
                            this.model.customnumber = this.modellst[i].customnumber
                          
                        }
                      
                        // this.dates = new Date(this.model.lastpaymentdate)
                        // this.dates.setDate(this.dates.getDate() + this.model.creditdays);
                        // if (this.dates < this.model.dateTime) {
                        //     this.model.approvedorderflg = "P"
                        // } else {
                        //     this.model.approvedorderflg = "A"
                        // }
                        this.schemeModel.discountType = this.model.schemetype
                        this.onChangeOfqantity();

                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";
        }


        this.model.accountid = Number(localStorage.getItem('userid'));
        this.dealersService.getcustomerdetail(this.model)
            .subscribe(
                data => {
                    this.model.freightvalue = data.freightvalue;
                    this.freefreightvalue =  this.model.freightvalue - 1;
                    this.model.accountid = data.accountid;
                    this.model.customername = data.customername;
                    this.model.address = data.address;
                    this.model.invoiceDate = data.invoiceDate;
                    this.model.grandtotal = data.grandtotal;
                    this.model.creditamount = data.creditamount;
                    this.model.creditdays = data.creditdays;
                    this.model.ctype = data.ctype;
                    this.model.ctypeid = data.ctypeid;
                    this.model.dateTime = new Date();
                    this.model.chkschemeflag = data.chkschemeflag;
                });
        this.getproductcat();
        this.getCurrencyOfProduct();
        // this.onChangeOfqantity();
      
    }

    openshippingmodel(value, changemodel: TemplateRef<any>) {

        this.dealersService.getaddressofcustomers(this.model)
            .subscribe(
                data => {
                    this.model.addresslst = data.addrlst
                    if (this.model.accountid != null) {
                        for (let i = 0; i < this.model.addresslst.length; i++) {
                            if (this.model.addresslst[i].accountid == this.model.accountid) {
                                this.model.addresslst[i].cheselect = true;
                            }
                        }
                    }
                },
                error => {
                    this.alertService.error(error);
                });
        this.modalRef = this.modalService.show(changemodel, {
            class: 'modal-lg'
        });
    }

    onselectaddress(value, $event) {

        for (let i = 0; i < this.model.addresslst.length; i++) {
            if (this.model.addresslst[i].shipingaddid == value) {
                this.model.addresslst[i].cheselect = true;
                this.model.accountid = value
            } else {
                this.model.addresslst[i].cheselect = false;
            }
        }
    }
    closemodel() {
        this.modalRef.hide();
    }

    btnsaveaddress() {
        for (let i = 0; i < this.model.addresslst.length; i++) {
            if (this.model.addresslst[i].cheselect == true) {
                this.model.address = "";
                this.model.address = this.model.addresslst[i].localityName + "," + this.model.addresslst[i].city + "," + this.model.addresslst[i].state + "," + this.model.addresslst[i].pincode;
                this.model.address = this.model.addresslst[i].address;
                this.model.city = this.model.addresslst[i].city;
                this.model.state = this.model.addresslst[i].state;
                this.model.pincode = this.model.addresslst[i].pincode;
                this.model.accountid = this.model.addresslst[i].accountid;

                if (this.model.address != "" && this.model.address != null) {
                    this.notifyService.showSuccess("Address Changed Successfully", "Done");
                }
            }
        }
        this.modalRef.hide();
    }
    getproductcat() {
        this.productcatelst = [];
        this.dealersService.getproductcat(this.user)
            .subscribe(data => {

                this.productcatelst = data.producttlst;

            });

    }
    onSelectprocategory(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Product Category!!", "Oops!");
            return;
        }
        this.model.productcategoryid = value
        this.user.trnid = value
        this.user.id = this.model.accountid
        this.productlst = [];
        this.dealersService.getProductsByPCat(this.user)
            .subscribe(
                data => {
                    this.productlst = data

                });
    }
    onSelectproduct(val) {
        for (let j = 0; j < this.productlst.length; j++) {
            if (this.productlst[j].productid == val) {
                this.productid = this.productlst[j].productid
                this.prouctname = this.productlst[j].prouctname
                this.uom = this.productlst[j].uom
                this.uomid = this.productlst[j].uomid
                this.gstamount = this.productlst[j].gstamount
                this.gstrate = this.productlst[j].gstrate
                this.price = this.productlst[j].price
                this.discount = this.productlst[j].discount
                this.minimumPrice = this.productlst[j].minimumPrice
                this.getCurrencyOfProduct();
            }
        }

    }
    getCurrencyOfProduct() {
        this.currencylst = [];
        this.currcymodel = null;
        let user = new User();
        user.trnid = this.productid;
        this.transactionService.getCurrencyOfProduct(user)
            .subscribe(
                data => {
                    this.currencylst = data.currencylst
                    this.currcymodel = data;
                });
    }
    onSelectcurrency(value) {
        this.currencylst = [];
        for (let j = 0; j < this.currencylst.length; j++) {
            this.currencyid = this.currencylst[j].id;
            this.currencyname = this.currencylst[j].name;
        }
    }
    btnAddProduct() {
        if (this.productid != null) {
            for (let i = 0; i < this.modellst.length; i++) {
                if (this.modellst[i].productid == this.productid) {
                    this.notifyService.showRroor("Product already present", " Oops");
                    return;
                }
            }
            // for (let i = 0; i < this.modellst.length; i++) {
            //     if( this.modellst.splice(this.modellst.indexOf(this.modellst[i], 1))){
            //      this.notifyService.showRroor("Product Remove Successfully", " Oops");
            //      return;
            //     }
            //  }



            this.modellst.push({
                id: null,
                productcategoryid: this.productcategoryid,
                proucatename: this.procatname,
                productid: this.productid,
                prouctname: this.prouctname,
                discount: this.discount,
                quantity: 1,
                uom: this.uom,
                uomid: this.uomid,
                gstrate: this.gstrate,
                gstamount: this.gstamount,
                value: this.value,
                price: this.price,
                minimumPrice: this.minimumPrice,
                procheckselect: true,
                modellst: null,
                shipingaddid: null,
                allcheckselect: false,
                discountAmount: this.discountAmount,
                netamount: this.netamount,
                totalnewvalue: this.totalnewvalue,
                newdiscountamt: this.newdiscountamt,
                ratewithdiscount: this.ratewithdiscount,
                newprice: this.newprice,
                iseditflag: this.iseditflag,
                approvedorderflg:null,
                customnumber:null,
            });
        } else {
            this.notifyService.showRroor("Please select product!", "Oops!");
            return;
        }
        if (this.model.schmemCode != null) {
            this.btnApplyScheme();
        }
        this.onChangeOfqantity();
    }
    onselectall($event) {
        if ($event.target.checked) {
            for (let i = 0; i < this.modellst.length; i++) {
                this.modellst[i].procheckselect = true
            }
        } else {
            for (let i = 0; i < this.modellst.length; i++) {
                this.modellst[i].procheckselect = false
            }
        }
    }
    onChangeOfqantity() {
        for (let i = 0; i < this.modellst.length; i++) {
            // if (this.model.orderaccp == "Company" && this.action != "Approve") {
            //     if (this.modellst[i].revisedDiscount > 0) {
            //         if (this.modellst[i].discount > this.modellst[i].revisedDiscount) {
            //             this.modellst[i].approvedQuo = false
            //         } else {
            //             this.modellst[i].approvedQuo = true
            //         }
            //     } else {
            //         if (this.modellst[i].discount > this.modellst[i].discountAllowed) {
            //             this.modellst[i].approvedQuo = false
            //         } else {
            //             this.modellst[i].approvedQuo = true
            //         }
            //     }
            // } else {

            // this.modellst[i].discount = 0;
            // this.modellst[i].discountAmount = 0;
            if (this.model.chkschemeflag != 'Y') {

                this.modellst[i].discount = 0;
                this.modellst[i].discountAmount = 0;
                this.modellst[i].customnumber = 0;
            }
            if (this.schemeModel.schemeDiscount != null && this.schemeModel.schemeDiscount > 0) {
                if (this.schemeModel.discountType == "percentage") {
                    //Scheme Discount Type Percentage
                    this.modellst[i].discount = this.schemeModel.schemeDiscount;

                }
                else {
                    this.modellst[i].discountAmount = this.schemeModel.schemeDiscount
                    // this.discountAmount = this.modellst[i].discountAmount
                }
                // if (this.model.schmemCode != null) {
                //     this.netorder = this.netorder + this.model.netorder;
                //     // this.productEnquirylst[i].totalamount = this.totalamount;
                //     // this.productEnquirylst[i].totalamount = Math.round(this.productEnquirylst[i].totalamount);
                //     // this.amountInWords = convertor(Math.round(this.productEnquirylst[i].totalamount).toFixed(2));
                //     // this.model.quotationAmount = this.totalamount
                // }
            }
            if (this.model.customnumber != null && this.model.customnumber > 0) {
                if (this.schemeModel.discountType == "Custom") {
                    this.modellst[i].customnumber = this.model.customnumber
                }
            }

            if (this.modellst[i].discount != null && this.modellst[i].discount > 0 && this.model.schemetype == "percentage" || this.schemeModel.discountType == "percentage") {
                // && this.model.schemetype == "percentage" || this.model.chkschemeflag == 'N'
                //Scheme Discount Type Percentage
                let listtotal = 0;
                let fulldiscount = 0;
                let fulltotal = 0;
                let fulltotaldiscount = 0;
                let lsdiscount = 0;
                this.netorder = 0;
                this.gst = 0;
                this.total = 0;
                for (let i = 0; i < this.modellst.length; i++) {
                    listtotal = this.modellst[i].minimumPrice * this.modellst[i].quantity;
                    fulltotal = fulltotal + listtotal;
                    lsdiscount = this.modellst[i].discount;
                }
                fulltotaldiscount = fulltotal * lsdiscount / 100;
                for (let i = 0; i < this.modellst.length; i++) {
                    if (this.model.orderaccp == "Company") {
                        if (this.schemeModel.maxdiscount != null) {
                            if (this.schemeModel.maxdiscount < fulltotaldiscount) {
                                // fulltotaldiscount = this.schemeModel.maxdiscount
                                this.modellst[i].discount = this.schemeModel.maxdiscount * 100 / fulltotal;
                            }
                        }
                    }
                }
                this.modellst[i].discountAmount = (this.modellst[i].minimumPrice * this.modellst[i].quantity) * this.modellst[i].discount / 100;
                this.modellst[i].newprice = ((this.modellst[i].minimumPrice * this.modellst[i].quantity) - this.modellst[i].discountAmount) / this.modellst[i].quantity;
                this.modellst[i].ratewithdiscount = this.modellst[i].newprice;
                let tax1 = (this.modellst[i].newprice * this.modellst[i].gstrate) / 100;
                this.modellst[i].gstamount = (tax1 * this.modellst[i].quantity)
                this.modellst[i].netamount = (this.modellst[i].newprice * this.modellst[i].quantity)
                this.modellst[i].value = (this.modellst[i].newprice * this.modellst[i].quantity) + this.modellst[i].gstamount;
                this.modellst[i].value = Math.round(this.modellst[i].value);
                this.total = this.total + this.modellst[i].value;
                this.alltotalword = convertor(Math.round(this.total).toFixed(2));
                this.netorder = this.netorder + (this.modellst[i].minimumPrice * this.modellst[i].quantity);
                this.model.netorder = this.netorder;
                this.gst = this.gst + this.modellst[i].gstamount;
                this.model.gst = Math.round(this.gst);
                this.model.total = this.total;
                this.model.discountAmount = this.modellst[i].discountAmount;

                // let discount = this.modellst[i].price * this.modellst[i].discount / 100;
                // this.modellst[i].discountAmount = discount * this.modellst[i].quantity;

                // if (this.schemeModel.maxdiscount != null) {
                //     if (this.schemeModel.maxdiscount < this.modellst[i].discountAmount) {
                //         this.modellst[i].discountAmount = this.schemeModel.maxdiscount
                //         let disAmount = this.modellst[i].discountAmount / this.modellst.length
                //         this.modellst[i].discount = disAmount * 100 / this.modellst[i].price
                //     }
                // }

            }
            else if (this.modellst[i].discountAmount != null && this.modellst[i].discountAmount > 0) {
                //Scheme Discount Type Value
                this.totalnewvalue = 0;
                let disAmount = 0;
                this.total = 0;
                this.netorder = 0;
                this.gst = 0;
                for (let i = 0; i < this.modellst.length; i++) {
                    let tax1 = (this.modellst[i].minimumPrice * this.modellst[i].quantity * this.modellst[i].gstrate) / 100;
                    this.modellst[i].gstamount = tax1
                    this.modellst[i].netamount = (this.modellst[i].minimumPrice * this.modellst[i].quantity)
                    this.modellst[i].totalnewvalue = (this.modellst[i].minimumPrice * this.modellst[i].quantity) + this.modellst[i].gstamount;
                    this.totalnewvalue = this.totalnewvalue + this.modellst[i].totalnewvalue;
                    disAmount = this.modellst[i].discountAmount
                }
                for (let i = 0; i < this.modellst.length; i++) {
                    this.offereddiscount = disAmount * 100 / this.totalnewvalue;
                    this.modellst[i].newprice = this.offereddiscount;
                    this.modellst[i].discount = this.modellst[i].newprice
                    this.modellst[i].newdiscountamt = (this.modellst[i].totalnewvalue * this.modellst[i].discount) / 100;
                    this.modellst[i].netamount = (this.modellst[i].minimumPrice * this.modellst[i].quantity);
                    this.modellst[i].ratewithdiscount = this.modellst[i].netamount - ((this.modellst[i].minimumPrice * this.modellst[i].quantity * this.modellst[i].discount) / 100)
                    let tax1 = (this.modellst[i].ratewithdiscount * this.modellst[i].gstrate) / 100;
                    this.modellst[i].gstamount = tax1;
                    this.modellst[i].value = this.modellst[i].ratewithdiscount + this.modellst[i].gstamount;
                    this.modellst[i].value = Math.round(this.modellst[i].value)
                    this.modellst[i].ratewithdiscount = this.modellst[i].ratewithdiscount / this.modellst[i].quantity;
                    this.total = this.total + this.modellst[i].value;
                    this.alltotalword = convertor(Math.round(this.total).toFixed(2));
                    this.netorder = this.netorder + (this.modellst[i].minimumPrice * this.modellst[i].quantity);
                    this.model.netorder = this.netorder;
                    this.model.total = this.total;
                    this.gst = this.gst + this.modellst[i].gstamount;
                    this.model.gst = Math.round(this.gst);
                    this.model.discountAmount = this.modellst[i].discountAmount;

                }
            }
            else if(this.modellst[i].customnumber != null && this.modellst[i].customnumber > 0)
            {
                //Scheme Discount Type Custom
                this.totalnewvalue = 0;
                let disAmount = 0;
                this.total = 0;
                this.netorder = 0;
                this.gst = 0;
                for (let i = 0; i < this.modellst.length; i++) {
                    let tax1 = (this.modellst[i].minimumPrice * this.modellst[i].quantity * this.modellst[i].gstrate) / 100;
                    this.modellst[i].gstamount = tax1
                    this.modellst[i].netamount = (this.modellst[i].minimumPrice * this.modellst[i].quantity)
                    this.modellst[i].totalnewvalue = (this.modellst[i].minimumPrice * this.modellst[i].quantity) + this.modellst[i].gstamount;
                    this.totalnewvalue = this.totalnewvalue + this.modellst[i].totalnewvalue;
                    disAmount = this.modellst[i].customnumber
                }
                for (let i = 0; i < this.modellst.length; i++) {
                    this.offereddiscount = disAmount * 100 / this.totalnewvalue;
                    this.modellst[i].newprice = this.offereddiscount;
                    this.modellst[i].discount = this.modellst[i].newprice
                    this.modellst[i].newdiscountamt = (this.modellst[i].totalnewvalue * this.modellst[i].discount) / 100;
                    this.modellst[i].netamount = (this.modellst[i].minimumPrice * this.modellst[i].quantity);
                    this.modellst[i].ratewithdiscount = this.modellst[i].netamount - ((this.modellst[i].minimumPrice * this.modellst[i].quantity * this.modellst[i].discount) / 100)
                    let tax1 = (this.modellst[i].ratewithdiscount * this.modellst[i].gstrate) / 100;
                    this.modellst[i].gstamount = tax1;
                    this.modellst[i].value = this.modellst[i].ratewithdiscount + this.modellst[i].gstamount;
                    this.modellst[i].value = Math.round(this.modellst[i].value)
                    this.modellst[i].ratewithdiscount = this.modellst[i].ratewithdiscount / this.modellst[i].quantity;
                    this.total = this.total + this.modellst[i].value;
                    this.alltotalword = convertor(Math.round(this.total).toFixed(2));
                    this.netorder = this.netorder + (this.modellst[i].minimumPrice * this.modellst[i].quantity);
                    this.model.netorder = this.netorder;
                    this.model.total = this.total;
                    this.gst = this.gst + this.modellst[i].gstamount;
                    this.model.gst = Math.round(this.gst);
                    this.model.discountAmount = this.modellst[i].customnumber;
                    this.total = this.model.total

                }
            }

            if (this.model.schmemCode == null) {
                let amount = 0;
                let disAmount = 0;
                // this.netorder = 0;
                this.netorder = 0;
                this.gst = 0;
                this.total = 0;
                this.model.discountAmount = 0;
                for (let i = 0; i < this.modellst.length; i++) {
                    this.modellst[i].ratewithdiscount = null;
                    amount = this.modellst[i].quantity * this.modellst[i].minimumPrice;
                    this.modellst[i].netamount = amount;
                    this.modellst[i].gstamount = amount * this.modellst[i].gstrate / 100;
                    this.modellst[i].value = Math.round(amount + this.modellst[i].gstamount);
                    this.netorder = this.netorder + this.modellst[i].netamount;
                    this.model.netorder = this.netorder
                    this.gst = this.gst + this.modellst[i].gstamount;
                    this.model.gst = Math.round(this.gst);
                    this.total = this.total + this.modellst[i].value;
                    this.model.total = Math.round(this.total);
                    this.alltotalword = convertor(this.model.total.toFixed(2));
                    this.modellst[i].discount = disAmount;
                }
            }
        }
    }
    btnsave() {
        this.model.invoiceDate = this.dates
        this.dates.setDate(this.dates.getDate() + this.model.creditdays);

        // if (this.model.currentCreditlimit > this.model.total) {
        //     this.model.approvedorderflg = "P";
        // } else {
        //     this.model.approvedorderflg = "A";
        // }
        // this.dates = new Date(this.model.lastpaymentdate)
        // this.dates.setDate(this.dates.getDate() + this.model.creditdays);
        // if (this.dates < this.model.dateTime) {
        //     this.model.approvedorderflg = "P"
        // } else {
        //     this.model.approvedorderflg = "A"
        // }
        for (let j = 0; j < this.modellst.length; j++) {
            if (this.modellst.length <= 0) {
                this.notifyService.showRroor("List is Null", " Oops");
                return;
            }
        }

        var found = this.modellst.find(function (x) { return x.procheckselect === true; });
        if (!found) {
            this.notifyService.showRroor("Please select atleast one Checkbox", " Oops");
            return;
        }

        for (let j = 0; j < this.modellst.length; j++) {
            if (this.modellst[j].procheckselect == true) {
                if (this.modellst[j].quantity == null) {
                    this.notifyService.showRroor("Enter Quantity  !!", "Oops!");
                    return;
                }
            }
        }
        if (this.model.creditamount == 0 && this.model.creditdays == 0) {
            this.model.approvedorderflg = "P";
        } else
            if (this.model.grandtotal > this.model.creditamount) {
                this.model.approvedorderflg = "P";
            } else if (this.dates > this.currentdate) {
                this.model.approvedorderflg = "P";
            } else {
                this.model.approvedorderflg = "A";
                this.router.navigate(["/Payment"])
                this.value = this.model.grandtotal - this.model.creditamount
                this.model.subtotal = this.value
            }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.modellst = this.modellst
        for(let i = 0 ; i < this.model.modellst.length ; i++)
        {
            if(this.model.schemetype == "value" || this.model.schemetype == "percentage")
            {
                this.model.modellst[i].customnumber = null;
            }
        }
        this.dealersService.savepurchaseOrder(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(["/Dealer Purchase Order"]);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });


    }
    cancle() {
        this.router.navigate(["/Dealer Purchase Order"]);
    }

    btnApplyScheme() {
        if (this.modellst.length < 0) {
            this.notifyService.showRroor("Please select at least one Product", "Oops!");
            return;
        }
        if (this.model.schmemCode == null || this.model.schmemCode == "") {
            this.notifyService.showRroor("Please enter scheme code", "Oops!");
            return;
        }
        this.schemeModel.schemeDiscount = 0;
        this.schemeModel.maxdiscount = 0;
        this.model.modellst = this.modellst;
        // this.model.schemetype = "percentage";
        if(this.schemeModel.discountType == "Custom")
        {
            
            if(this.model.customnumber > this.totalamount)
            {
                this.notifyService.showRroor("Please Enter Valid Custom Number", "Oops!");
                return;
            }
        }
        this.transactionService.btnApplyschemecodedealer(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.schemeModel = data;
                        this.notifyService.showSuccess(data.message, "Done!");
                        this.onChangeOfqantity();
                    } else if (data.status == "NO") {
                        this.notifyService.showRroor(data.message, "Done!");
                        return;
                    }
                });
    }

    removeScheme() {
        this.model.schmemCode = null;
        this.schemeModel.schemeDiscount = 0;
        this.schemeModel.maxdiscount = 0;
        this.schemeModel.discountType = null;
        this.model.customnumber = null;
        this.model.schemetype = null;
        this.onChangeOfqantity();

    }
    selectSchemeCode(data) {
        this.model.schmemCode = data;
        this.model.modellst = null;
        this.model.customnumber = null;
        if (this.model.schmemCode.length > 4) {
            this.Masterservice.getSchemeType(this.model)
                .subscribe(
                    data => {
                        this.schemeModel = data;
                    })
        }
    }


}
