import { OnInit, Component, TemplateRef } from '@angular/core';
import { AuthenticationService, MasterServices, TransactionServices } from 'src/app/_services';
import { Router, NavigationExtras } from '@angular/router';
import { User } from 'src/app/_models';
import { OpportunitiesModel } from './OpportunitiesModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { QuotReportModel } from '../Quotation/QuotationModel';
import { ReportServices } from 'src/app/_services/ReportServices';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import convertor from 'rupees-to-words';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DailyCallModel } from '../DailyCall/DailyCallModel';
import { CallCenterDashboardModel } from '../CallCenterDashboard/CallCenterDashboardModel';
import { NotificationService } from 'src/app/_services/notification.service';
import { data } from 'jquery';

@Component({
    selector: 'app-Opportunities',
    templateUrl: 'Opportunities.html',
})
export class Opportunities implements OnInit {

    constructor(private masterservice: MasterServices,
        private transactionservice: TransactionServices,
        private router: Router,
        private reportServices: ReportServices,
        private modalService: BsModalService,
        private deviceService: DeviceDetectorService,
        private authenticationService: AuthenticationService,
        private notifyService: NotificationService) {
    }

    model = new OpportunitiesModel();
    cmodel = new CallCenterDashboardModel();
    dmodel = new DailyCallModel();
    user = new User();
    quoReport = new QuotReportModel();
    qutReportlst: QuotReportModel[];
    reporttandclst: QuotReportModel[];
    opportunitieslst: OpportunitiesModel[] = [];
    opportunitieslst1: OpportunitiesModel[] = [];
    stageslst: DropDownModel[] = []
    p: any;
    CurrentDate = new Date();
    modalRef: BsModalRef;
    chkopen: boolean;
    isMobile: boolean;
    deviceInfo = null;
    username: string;
    mainmenus: Mainmenumodel[] = [];
    count: number;
    sum: number = 1;
    totalItems: any;
    searchtype: DropDownModel[] = [
        { id: 0, name: "Select Search Criteria" },
        { id: 1, name: "All" },
        { id: 2, name: "Enquiry Number" },
        { id: 3, name: "Enquiry Name" },
        { id: 4, name: "Customer Name" },
        { id: 5, name: "Mobile Number" },
        { id: 6, name: "Stage" },
        { id: 7, name: "Assigned To" },
    ];

    modelst: CallCenterDashboardModel[] = [];
    lstproduct: CallCenterDashboardModel[] = [];
    searchstring: string;
    q: any;
    pageno: string;

    ngOnInit(): void {
        this.mainmenus = JSON.parse(localStorage.getItem('menu'));
        this.username = localStorage.getItem('fullname');

        this.user.userid = Number(localStorage.getItem("userid"));
        this.user.accesstype = localStorage.getItem('accesstype');

        if (this.user.userid != null) {
            this.getAllOpportunitiesOfResource()
        }
        this.getAllStages();
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();


        // this search for callcenter screen 
        this.lstproduct = [];
        this.searchstring = localStorage.getItem("searchstring");
        this.pageno = localStorage.getItem("pageno");
        this.cmodel.id = Number(localStorage.getItem("userid"));

        let re = /\ /gi;

        this.cmodel.txtserach2 = this.searchstring;
        // this.model.pageno = this.pageno;
        // this.model.txtserach2 = this.model.txtserach2.replace(re, ',');
        this.transactionservice.getcustomerBySearch(this.cmodel)
            .subscribe(value => {
                this.q = this.pageno;
                this.modelst = value;
            });
        let l1 = (<HTMLInputElement>document.getElementById('search'));
        l1.className = "active";
    }

    btnsearch2() {
        let re = /\ /gi;
        // this.model.txtserach2 = this.model.txtserach2.replace(re, ',');
        this.transactionservice.getcustomerBySearch(this.cmodel)
            .subscribe(value => {
                this.modelst = value
                if (this.modelst.length <= 0) {
                    this.notifyService.showRroor("Customer Is Not Available !", "Opps");
                    return
                }
            });
    }
    btnView(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "flag": "view"
            }
        };
        this.router.navigate(['/updateDCR'], navigationExtras);
        
    }


    getAllStages() {
        this.masterservice.getAllStages(this.user)
            .subscribe(
                data => {
                    this.stageslst = data
                });
    }

    getAllOpportunitiesOfResource() {
        this.opportunitieslst = []
        this.user.pageno = this.sum;
        this.user.totalItems;
            // this.user.calltype = localStorage.getItem('customerid');
        this.transactionservice.getAllOpportunitiesOfResource(this.user)
            .subscribe(
                data => {
                    
                    this.opportunitieslst = data
                    // if(this.opportunitieslst1.){

                    // }
                    this.opportunitieslst1 = data
                    for (let i = 0; i < this.opportunitieslst.length; i++) {
                        this.user.totalItems = this.opportunitieslst[0].totalItems;
                    }
                    // this.model.openchk = true;
                });
              
    }

    onSelectSrch(val) {
        this.model.txtselect = val
    }

    onSelectStages(val) {
        this.model.txtserch = val
    }

    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllOpportunitiesOfResource()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect != null) {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            this.user.message = this.model.txtselect
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            // getopportunitiesSearchLike
            this.transactionservice.getAllOpportunitiesOfResource(this.user)
                .subscribe(value => {
                    this.opportunitieslst = value;
                    this.opportunitieslst1 = value
                });
        }
        if (this.model.openchk) {
            this.model.txtserch = "Open"
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            this.transactionservice.getAllOpportunitiesOfResource(this.user)
                .subscribe(value => {
                    this.opportunitieslst = value;
                    this.opportunitieslst1 = value
                });
        }
        if (this.model.closechk) {
            this.model.txtserch = "Close"
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            this.transactionservice.getAllOpportunitiesOfResource(this.user)
                .subscribe(value => {
                    this.opportunitieslst = value;
                    this.opportunitieslst1 = value
                });
        }
        if (this.model.wonchk) {
            this.model.txtserch = "Won"
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            this.transactionservice.getAllOpportunitiesOfResource(this.user)
                .subscribe(value => {
                    this.opportunitieslst = value;
                    this.opportunitieslst1 = value
                });
        }
        if (this.model.lostchk) {
            this.model.txtserch = "Lost"
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            this.transactionservice.getAllOpportunitiesOfResource(this.user)
                .subscribe(value => {
                    this.opportunitieslst = value;
                    this.opportunitieslst1 = value
                });
        }
        if (this.model.abandonedchk) {
            this.model.txtserch = "Abandoned"
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            this.transactionservice.getAllOpportunitiesOfResource(this.user)
                .subscribe(value => {
                    this.opportunitieslst = value;
                    this.opportunitieslst1 = value
                });
        }
        let l1 = (<HTMLInputElement>document.getElementById('lable1'));
        l1.className = "active";
    }

    btnAssignEnquiry(accountid, id) {

        let accoutid: any = accountid;
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "flag": btoa("reassinglead"),
                "id": btoa(accoutid),
                "leadid": id
            }
        };
        this.router.navigate(['/prospect'], navigationExtras);

        // let navigationExtras: NavigationExtras = {
        //     queryParams: {
        //         "id": id,
        //     }
        // };
        // this.router.navigate(['/reassignEnquiry'], navigationExtras);
    }

    btnDCR(id) {
    
        if(this.opportunitieslst[0].dealerflag=='Y'){
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    "id": id,
                    "activityid": null
                }
            };
            this.router.navigate(['/Open / Lost Enquiries Report'], navigationExtras);
        }else{
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "activityid": null
            }
        };
        
        this.router.navigate(['/updateDCR'], navigationExtras);
    }
}
    btnDCR1(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "activityid": null
            }
        };
        this.router.navigate(['/Open / Lost Enquiries Report'], navigationExtras);
    }
    btnprint(QuotationReport: TemplateRef<any>, id) {
        this.quoReport = null;
        let user = new User();
        // user.trnid = this.model.quotationid;
        // this.model.quotationid;
        user.trnid = id;
        this.reportServices.getQuotationReport(user)
            .subscribe(
                data => {

                    this.quoReport = data
                    this.qutReportlst = data.qutReportlst;
                    this.reporttandclst = data.reporttandclst;

                    this.quoReport.totalamount = 0;
                    this.quoReport.totalnetamount = 0;
                    this.quoReport.totaltaxamount = 0;
                    for (let i = 0; i < this.qutReportlst.length; i++) {
                        this.quoReport.totaltaxamount = this.quoReport.totaltaxamount + this.qutReportlst[i].taxamount;
                        this.quoReport.totalnetamount = this.quoReport.totalnetamount + this.qutReportlst[i].netamount;
                        this.quoReport.totalamount = this.quoReport.totalamount + this.qutReportlst[i].totalproamount;
                        // this.quoReport.totalinwords = converter.toWords(this.quoReport.totalamount);
                        this.quoReport.totalinwords = convertor(this.quoReport.totalamount.toFixed(2));
                    }
                });

        this.modalRef = this.modalService.show(QuotationReport, {
            class: 'modal-lg'
        });
    }
    openPrint() {
        var divToPrint = document.getElementById('inv');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    onchkopen() {

        this.chkopen = !this.chkopen;

    }
    redirectUser(mainmenulabel: string) {
        this.router.navigate(['/' + mainmenulabel]);
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    btnprintMobile(id) {
        this.quoReport = null;
        let user = new User();
        // user.trnid = this.model.quotationid;
        // this.model.quotationid;
        user.trnid = id;
        this.reportServices.getQuotationReport(user)
            .subscribe(
                data => {

                    this.quoReport = data
                    this.qutReportlst = data.qutReportlst;
                    this.reporttandclst = data.reporttandclst;
                    this.quoReport.totalamount = 0;
                    this.quoReport.totalnetamount = 0;
                    this.quoReport.totaltaxamount = 0;
                    for (let i = 0; i < this.qutReportlst.length; i++) {
                        this.quoReport.totaltaxamount = this.quoReport.totaltaxamount + this.qutReportlst[i].taxamount;
                        this.quoReport.totalnetamount = this.quoReport.totalnetamount + this.qutReportlst[i].netamount;
                        this.quoReport.totalamount = this.quoReport.totalamount + this.qutReportlst[i].totalproamount;
                        // this.quoReport.totalinwords = converter.toWords(this.quoReport.totalamount);
                        this.quoReport.totalinwords = convertor(this.quoReport.totalamount.toFixed(2));
                    }
                });

    }
    // btnaddressMobile(AddressReport: TemplateRef<any>, id) {
    //     let user = new User();
    //     user.trnid = id;
    //     this.transactionservice.getDataByOpportunityId(user)
    //         .subscribe(
    //             data => {
    //                 this.dmodel = data;
    //             })
    // }
    showPageIndex(pageIndex) {
        this.sum = pageIndex;
        this.getAllOpportunitiesOfResource();
    }
    btnprevious() {
        this.sum = this.sum - 1;
        this.getAllOpportunitiesOfResource();
    }
    btnnext() {
        this.sum = this.sum + 1;
        this.getAllOpportunitiesOfResource();
    }
    last() {
        this.sum = Math.round(this.user.totalItems / 10);
        this.getAllOpportunitiesOfResource();
    }
    first() {
        this.sum = 1;
        this.getAllOpportunitiesOfResource();
    }

    btnnewprospect() {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "flag": btoa("newprospect"),
            }
        };
        this.router.navigate(['/prospect'], navigationExtras);
    }
    btngetdetails(accountid) {
        let i = 0;
        for (let j = 0; j < this.modelst.length; j++) {
            if (this.modelst[j].accountid == accountid) {
                i = j;
            }
        }
        this.cmodel.accountid = accountid;
        this.modelst[i].lstproduct = [];
        this.transactionservice.getsubcallcen(this.cmodel)
            .subscribe(value => {
                this.modelst[i].lstproduct = value
            });
    }
    btnedit(accountid, i) {
        let accoutid: any = accountid;
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "flag": btoa("edit"),
                "id": btoa(accoutid),
                "searchstring": this.model.txtserach2
            }
        };
        this.router.navigate(['/prospect'], navigationExtras);
    }

    btncreatelead(accountid, i) {
        let accoutid: any = accountid;
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "flag": btoa("createlead"),
                "id": btoa(accoutid),
                "searchstring": this.model.txtserach2
            }
        };
        this.router.navigate(['/prospect'], navigationExtras);
    }

    btnopenaccount360(accountid) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": btoa(accountid),
                "searchstring": this.model.txtserach2,
                "pageno": this.pageno
            }
        };
        this.router.navigate(['/AccountThreeSixty'], navigationExtras);
    }
    

}