<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">

            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="input-field col s3 m3 l3"
                            style="margin-right :10px; margin-top: 9px;padding-left: 14px;">
                            <span>From Date</span>
                            <input type="text" placeholder="From Date" [(ngModel)]="model.yearFrom" id="from"
                                class="form-control" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                                (ngModelChange)="selectFromDate(model.yearFrom)">
                        </div>
                        <div class="input-field col s3 m3 l3" style="margin-left :10px;margin-top: 9px;">
                            <span>To Date</span>
                            <input type="text" placeholder="To Date" [(ngModel)]="model.yearTo" id="to"
                                class="form-control" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }"
                                (ngModelChange)="selectToDate(model.yearTo)">
                        </div>
                        <div class="input-field col s3" style="margin-top: 30px;">
                            <input [(ngModel)]="model.chkselecurrentYear" #checkselectbran="ngModel" type="checkbox"
                                style="text-align: 5px">&nbsp;&nbsp;&nbsp;&nbsp;
                            Current Year
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l12 m12 s12">
                            <!-- <div class="col s1">
                            </div> -->
                            <div class="col l12 m12 s12" style="padding-left: 0px;padding-right: 0px;">
                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="20" style="text-align: left">From Date</th>
                                        <th width="20" style="text-align: left">To Date</th>
                                        <th width="10" style="text-align: left">Financial Year</th>
                                        <th width="20" style="text-align: left">Current Year</th>
                                        <th width="30" style="text-align: left">Actions</th>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let lst of financlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td style="text-align: left">{{lst.yearFrom | date:"dd-MM-yyyy"}}</td>
                                            <td style="text-align: left">{{lst.yearTo | date:"dd-MM-yyyy"}}</td>
                                            <td style="text-align: left">{{lst.financialYear}}</td>
                                            <td style="text-align: left">{{lst.currentYear}}</td>
                                            <td style="text-align: left">
                                                <span *ngIf="lst.currentYear=='Yes'"><img
                                                        src="../../../assets/images/edit.png" (click)="btnedit(lst)"
                                                        data-toggle="tooltip" data-placement="top" title="Edit"
                                                        width="20px" /></span>&nbsp;&nbsp;
                                                <!-- <span *ngIf="lst.currentYear=='Yes'"><img
                                                        src="../../../assets/images/delete.png"
                                                        (click)="btndelts(lst.financialyearid)" data-toggle="tooltip"
                                                        data-placement="top" title="Delete" width="20px" /></span> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <span style="text-align:right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </span>
                            </div>
                        </div>
                        <div class="col s1">
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align: right">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)"
                                class="waves-ripple waves-light btn">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>