export class ConversionTemplateModel{
    id:number;
    srno:number;
    shortname:string;
    formulaname:string;
    itemcategoryid:number;
    itemsubcategoryid:number;
    itemname:string;
    itemid:number;
    quantity:number;
    usedquantity:number;
    uom:string;
    stock:number;
    balance:number;
    uomid:number;
    dimensions:string;


    omitemcategoryid:number;
    omitemsubcategoryid:number;
    omitemname:string;
    omitemcode:string;
    omitemid:number;
    omquantity:number;
    omuom:string;
    omstock:number;
    ombalance:number;
    omuomid:number;
    omdimensions:string;
    diamensionUnit:string;
    omusedquantity:number;

    quantityconversion:number
    itemreiredquantity:number;
    
    
    
    lstoutputmeteriallst:ConversionTemplateModel[]=[];

}