<app-header></app-header>
<div class="container" style="width:81%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Marketing Budget</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="row" style="margin: 0px;">
                    <div class="col l4 m4 s4" style="margin-top: 10px;">
                        <div class="col l4 m4 s4" style="margin-top: 31px;padding-right: 0px;padding-left: 0px;">
                            <span><b>All Regions :</b></span>
                        </div>
                        <div class=" input-field col m7 l7 s7" style="margin-top:12px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.allreginvalue" #allreginvalue="ngModel" type="number" min="0"
                                oninput="validity.valid||(value='');" id="first1" data-toggle="tooltip"
                                data-placement="top" title="All Region"
                                (ngModelChange)="onChangeOfallregionvalue($event);"
                                style="margin-bottom: 0px;text-align: right;" required>
                            <label id="lable1" for="first1">Value(&#8377;)</label>
                        </div>
                    </div>
                    <div class="input-field col s3" style="margin-bottom: 0px;margin-top: 5px;">
                        <span>Financial Year</span>
                        <select (change)="onSelectFinalyear($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Calender">
                            <option selected value="0">Select Financial Year</option>
                            <option *ngFor="let lst of finanyealst" [value]="lst.id"
                                [selected]="lst.id==model.financialyearid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s4" style="margin-bottom: 0px;margin-top: 5px;">
                        <span>Region</span>
                        <select (change)="onSelectRegion($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Region">
                            <option selected>Select Region</option>
                            <option *ngFor="let type of regionlst" [value]="type.id"
                                [selected]="type.id==model.regionId">
                                {{type.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <!--<div class="row" style="margin: 0px">
                    <div class="col s2" style="text-align: right;margin-bottom: 0px;">
                        <div class="row">
                            <div class=" input-field col l10 m10 s10"
                                style="margin-top: 30px;text-align: right; padding: 0px;margin-bottom: 0px">
                                <input [(ngModel)]="model.percentage" #percentage="ngModel" type="number" min="0"
                                    oninput="validity.valid||(value='');" maxlength="100" id="first2"
                                    data-toggle="tooltip" data-placement="top" title="Percentage"
                                    style="text-align: right;" (ngModelChange)="onChangeOfregionpercent();" ngModel
                                    maxlength="100" required style="margin-bottom: 0px;">
                                <label id="lable2" for="first2" class style="left: 0px;">Percentage</label>
                            </div>
                            <div class=" input-field col s1" style="margin-top: 40px;padding: 0px;">&#37;</div>
                        </div>
                    </div>
                    <div class=" input-field col s2 " style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.regionvalue" #regionvalue="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first3" data-toggle="tooltip" data-placement="top"
                            title="Region Value" style="text-align: right;"
                            (ngModelChange)="onChangeOfregionvalue($event);" ngModel required>
                        <label id="lable3" for="first3">Region Value</label>
                    </div>
                </div>-->
            </div>

            <div class="row">
                <div class="col s12 m12 l12 registerBgShadow">

                    <div class="col l12 m12 s12"
                        style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 9px;">
                        <div class="col l1 m1 s1" style="text-align:center;">
                            <span></span>
                        </div>
                        <div class="col l4 m4 s4" style="text-align:left;">
                            <span>Region</span>
                        </div>
                        <div class="col l3 m3 s3" style="text-align:left;">
                            <span>% Allocation</span>
                        </div>
                        <div class="col l3 m3 s3" style="text-align:left;padding-right: 10px;">
                            &#8377;<span>Allocation</span>
                        </div>

                    </div>

                    <mat-accordion style="padding: 0px;">

                        <mat-expansion-panel *ngFor="let main of mregionlst;let i = index" style="padding-left: 1px;">
                            <mat-expansion-panel-header style="padding-left: 10px;padding-right: 10px;"
                                (click)="getareabyregion(i)">
                                <div class="col l12 m12 s12">
                                    <div class="row">
                                        <div class="input-field col l1 m1 s1" style="text-align: left;margin-top: 30px">
                                            <!-- <input type="checkbox" [(ngModel)]="main.arecheselect"
                                                #arecheselect="ngModel" (click)="onSelectArecheselect(i,$event)"> -->
                                        </div>
                                        <div class=" col l4 m4 s4" style="text-align:left;margin-top: 30px">
                                            <span>{{main.regionName}}</span>
                                        </div>
                                        <div class="col l3 m3 s3" style="padding: 2%;">
                                            <div class="row">
                                                <div class="input-field col l10 m10 s10 "
                                                    style="text-align: right;padding: 0px;">
                                                    <input [(ngModel)]="main.percentage" #percentage="ngModel"
                                                        step="0.01" type="number" data-toggle="tooltip"
                                                        data-placement="top" id="first2" pattern="[0123456789][0-9]{9}"
                                                        maxlength="100" (ngModelChange)="onChangeOfRegionpercentage(i);"
                                                        style="text-align: right;" title="Percentage Allocated" required
                                                        *ngIf="alert==false" min="0">
                                                    <!-- <label id="lable2" for="first2">% Allocation</label> -->

                                                    <input [(ngModel)]="main.percentage" #percentage="ngModel"
                                                        step="0.01" type="number" data-toggle="tooltip"
                                                        data-placement="top" id="first2" pattern="[0123456789][0-9]{9}"
                                                        maxlength="100" (ngModelChange)="onChangeOfRegionpercentage(i);"
                                                        style="text-align: right;color: red;"
                                                        title="Percentage Allocated" required *ngIf="alert==true" min="0">

                                                </div>
                                                <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                                    &#37;</div>
                                            </div>
                                        </div>
                                        <div class=" input-field col l3 m3 s3 " style="text-align: left;padding: 2%;">
                                            <input [(ngModel)]="main.regionvalue" #regionvalue="ngModel" type="number"
                                                step="0.01" data-toggle="tooltip" data-placement="top" id="first4"
                                                min="0" (ngModelChange)="onChangeOfRegionvalue(i,$event);"
                                                oninput="validity.valid||(value='');" title="Region Value"
                                                style="text-align: right;" required min="0">
                                            <!-- <label id="lable4" for="first4">Area Value</label> -->
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>


                            <div class="col l12 m12 s12"
                                style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 9px;">
                                <div class="col l1 m1 s1" style="text-align:center;">
                                    <span></span>
                                </div>
                                <div class="col l4 m4 s4" style="text-align:left;">
                                    <span>Area</span>
                                </div>
                                <div class="col l3 m3 s3" style="text-align:left;">
                                    <span>% Allocation</span>
                                </div>
                                <div class="col l3 m3 s3" style="text-align:left;padding-right: 10px;">
                                    &#8377;<span>Allocation</span>
                                </div>

                            </div>
                            <mat-expansion-panel
                                *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: p } ;let j = index "
                                style="padding-left: 1px;">

                                <mat-expansion-panel-header style="padding-left: 10px;padding-right: 10px;">
                                    <div class="col l12 m12 s12">
                                        <div class="row">
                                            <div class="input-field col l1 m1 s1"
                                                style="text-align: left;margin-top: 30px;padding-top: 10px;">
                                                <input type="checkbox" [(ngModel)]="lst.arecheselect"
                                                    #arecheselect="ngModel" (click)="onSelectArecheselect(i,j,$event)">
                                            </div>
                                            <div class=" col l4 m4 s4" style="text-align:left;margin-top: 30px">
                                                <span>{{lst.areaName}}</span>
                                            </div>
                                            <div class="col l3 m3 s3" style="padding: 2%;">
                                                <div class="row">
                                                    <div class="input-field col l10 m10 s10"
                                                        style="text-align: right;padding: 0px;"
                                                        *ngIf="main.percentage > 0 ">
                                                        <input [(ngModel)]="lst.percentage1" #percentage1="ngModel"
                                                            step="0.01" type="number" data-toggle="tooltip"
                                                            data-placement="top" id="first2"
                                                            pattern="[0123456789][0-9]{9}" maxlength="100"
                                                            (ngModelChange)="onChangeOfareapercentage(i,j);"
                                                            style="text-align: right;" title="Percentage Allocated"
                                                            required min="0">
                                                        <!-- <label id="lable2" for="first2">% Allocation</label> -->
                                                    </div>
                                                    <div class=" input-field col s1"
                                                        style="margin-top:21px;padding: 0px;">
                                                        &#37;</div>
                                                </div>
                                            </div>
                                            <div class=" input-field col l3 m3 s3"
                                                style="text-align: left;padding: 2%;">
                                                <input [(ngModel)]="lst.areavalue" #areavalue="ngModel" type="number"
                                                    step="0.01" data-toggle="tooltip" data-placement="top" id="first4"
                                                    min="0" (ngModelChange)="onChangeOfareavalue(i,j);"
                                                    oninput="validity.valid||(value='');" title="Area Value"
                                                    style="text-align: right;" required min="0">
                                                <!-- <label id="lable4" for="first4">Area Value</label> -->
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>

                                <!-- *ngIf="main.lst.citylst.length>0" -->
                                <div class="registerBgShadow col l12 m12 s12"
                                    style="text-align: center; margin-top: 10px;">
                                    <div class="col l12 m12 s12"
                                        style="background: gainsboro;padding: 1%; color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 9px;">
                                        <div class=" col s1" style="text-align:center;">
                                            <span></span>
                                        </div>
                                        <div class=" col s4" style="text-align:left;margin-top: 10px">
                                            <span>Cities</span>
                                        </div>
                                        <div class="col s2"
                                            style="text-align:left;margin-top: 10px;padding-right: 18px;">
                                            <span>% Allocation</span>
                                        </div>
                                        <div class="col s2" style="text-align:left;margin-top: 10px">
                                            &#8377;<span>Allocation</span>
                                        </div>
                                        <div class="col s2" style="text-align:center;margin-top: 10px">
                                            <span>Action</span>
                                        </div>

                                    </div>

                                    <div class="row" *ngFor="let sublst of lst.citylst; let k = index"
                                        style="padding: 1%;">
                                        <div class=" input-field col l1" style="text-align: left;margin-top: 30px">
                                            <input type="checkbox" [(ngModel)]="sublst.checkselect"
                                                #checkselect="ngModel" (click)="onSelectCheckselect(i,j,k,$event)">
                                        </div>
                                        <div class="col l4 m4 s4" style="text-align:left;margin-top: 30px">
                                            <span>{{sublst.cityname}}</span>
                                        </div>
                                        <div class="col s2">
                                            <div class="row">
                                                <div class=" input-field col s10 "
                                                    style="text-align: right;padding: 0px;">
                                                    <input [(ngModel)]="sublst.percentage2" #percentage2="ngModel"
                                                        step="0.01" type="number" id="first5" data-toggle="tooltip"
                                                        data-placement="top" style="text-align: right;"
                                                        (ngModelChange)="onChangeOfcitypercentage(i,j,k);"
                                                        title="Percentage Allocated" required min="0">
                                                    <label id="lable5" for="first5"></label>
                                                </div>
                                                <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                                    &#37;</div>
                                            </div>
                                        </div>
                                        <div class=" input-field col s2 " style="text-align: left;">
                                            <input [(ngModel)]="sublst.cityvalue" #cityvalue="ngModel" type="number"
                                                step="0.01" data-toggle="tooltip" data-placement="top" id="first6"
                                                min="0" (ngModelChange)="onChangeOfcityvalue(i,j,k);"
                                                oninput="validity.valid||(value='');" title="City value"
                                                style="text-align: right;" required>
                                            <!-- <label id="lable6" for="first6">City value</label> -->
                                        </div>
                                        <div class=" input-field col s2" style="margin-top: 22px;text-align: right;">
                                            <span style="margin-right: 35px;"><img src="../../../assets/images/edit.png"
                                                    (click)="btnopenmodel(i,j,k,templateModel)" title="Edit"
                                                    width="20px" /></span>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div *ngIf="lst.citylst.length==0" class="col l11 m11 s11 registerBgShadow"
                                    style="margin-left:50px;text-align: center;">
                                    <div class="col l12 m12 s12" style="color: red">
                                        <samp> List Not Found </samp>
                                    </div>
                                </div> -->
                            </mat-expansion-panel>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                        style="margin-right: 5px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #templateModel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-content " style="text-align: center;">
        <span style="font-size: 20px;">Marketing Heads</span>

    </div>

    <div class="row ">
        <div class=" col s3" style="text-align:left;">
            <span><b>Region Name: </b></span><span>{{regionname}}</span>
        </div>
        <div class=" col s3" style="text-align:left;">
            <span><b>Area Name: </b></span><span>{{areaname}}</span>
        </div>
        <div class=" col s4" style="text-align:left;">
            <span><b>City Name: </b></span><span>{{cityname}}</span>
        </div>
    </div>

    <div class="row">
        <div class="col s12 m12 l12 registerBgShadow">

            <div class="col l12 m12 s12"
                style=" margin-top:9px;background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;">
                <!-- <div class="col s2" style="text-align:center;">
                    <span> </span>
                </div> -->
                <div class="col l4 m4 s4" style="text-align:left;">
                    <span>Marketing Cost Heads </span>
                </div>
                <div class="col s3" style="text-align:left;">
                    &#37;<span> Allocation</span>
                </div>
                <div class="col s3" style="text-align:left;">
                    &#8377;<span> Allocation</span>
                </div>


            </div>
            <mat-accordion style="padding: 0px;">
                <mat-expansion-panel
                    *ngFor="let lst of costheadlst | paginate: { itemsPerPage: 10, currentPage: p } ;let k = index "
                    style="padding-left: 1px;">
                    <mat-expansion-panel-header>
                        <div class="col l12 m12 s12">
                            <div class="row">
                                <!-- <div class="input-field col l2" style="text-align: left;margin-top: 30px">
                                    <input type="checkbox" [(ngModel)]="lst.costcheselect" #arecheselect="ngModel"
                                        (click)="onSelectArecheselect(i,$event)">
                                </div> -->
                                <div class=" col s4" style="text-align:left;margin-top: 30px">
                                    <span>{{lst.costhead}}</span>
                                </div>
                                <div class="col s3" style="text-align: right;margin-top: 25px">
                                    <div class="row">
                                        <div class=" input-field col s10 " style="text-align: right;padding: 0px;">
                                            <input [(ngModel)]="lst.costpercentage" #costpercentage="ngModel"
                                                step="0.01" type="number" data-toggle="tooltip" data-placement="top"
                                                id="first7" (ngModelChange)="onChangeOfcostpercentage(k);"
                                                style="text-align: right;" title="Percentage Allocated" required min="0">
                                            <label id="lable7" for="first7">% Allocation</label>
                                        </div>
                                        <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                            &#37;</div>
                                    </div>
                                </div>
                                <div class=" input-field col s3 " style="text-align: center;margin-top: 30px">
                                    <input [(ngModel)]="lst.costvalue" #costvalue="ngModel" type="number" step="0.01"
                                        data-toggle="tooltip" data-placement="top" id="first8" min="0"
                                        (ngModelChange)="onChangeOfcostvalue(k);" oninput="validity.valid||(value='');"
                                        title="Cost value" style="text-align: right;" required>
                                    <label id="lable8" for="first8">Cost value</label>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel-header>


                    <div *ngIf="lst.costSubheadlst.length > 0" class="registerBgShadow col l12 m12 s12"
                        style="text-align: center; margin-top: 10px;">
                        <div class="col l12 m12 s12"
                            style="background: gainsboro;padding: 1%; color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 9px;">
                            <div class=" col s1" style="text-align:left;">
                                <span></span>
                            </div>
                            <div class=" col s4" style="text-align: left">
                                <span>Sub Heads</span>
                            </div>
                            <div class="col s1" style="text-align: left">
                                <span>UOM</span>
                            </div>
                            <div class="col s1" style="text-align: left">
                                <span>Qty</span>
                            </div>
                            <div class="col s1" style="text-align: left">
                                <span>Rate</span>
                            </div>
                            <div class="col s2" style="text-align: left">
                                <span>% Allocation</span>
                            </div>
                            <div class="col s2" style="text-align: left">
                                &#8377;<span>Allocation</span>
                            </div>
                            <!-- <div class="col s2" style="text-align:center;margin-top: 10px">
                                <span>Action</span>
                            </div> -->

                        </div>

                        <div class="row" *ngFor="let sublst of lst.costSubheadlst; let j = index">
                            <div class=" input-field col l1" style="text-align: center;margin-top: 30px">
                                <input type="checkbox" [(ngModel)]="sublst.subcheckselect" #subcheckselect="ngModel"
                                    (click)="onSelectsubcheckselect(k,$event,j)">
                            </div>
                            <div class="col l4 m4 s4" style="text-align:left;margin-top: 30px">
                                <span>{{sublst.costsubname}}</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:left;margin-top: 30px">
                                <span>{{sublst.uomName}}</span>
                            </div>
                            <div class="input-field col l1 m1 s1" style="text-align:left;margin-top: 12px">
                                <input *ngIf = "sublst.valueselect == 'N' || sublst.rate != null" [(ngModel)]="sublst.quantity" #quantity="ngModel" type="number"
                                    data-toggle="tooltip" data-placement="top" id="first11" min="0"
                                    (ngModelChange)="onChangeOfQuantity(k,j);" oninput="validity.valid||(value='');"
                                    title="Quantity" style="text-align: right" required>
                                <label  *ngIf = "sublst.valueselect == 'N'" id="lable11" for="first11">Quantity</label>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:left;margin-top: 30px">
                                <span>{{sublst.rate}}</span>
                            </div>
                            <div class="col l2 m2 s2">
                                <div class="row">
                                    <div class="input-field col s10" style="text-align: right;padding: 0px;">
                                        <input [(ngModel)]="sublst.subpercentage" #subpercentage="ngModel" type="number"
                                            step="0.01" id="first9" data-toggle="tooltip" data-placement="top"
                                            style="text-align: right;" (ngModelChange)="onChangeOfsubpercentage(k,j);"
                                            title="Percentage Allocated" required min="0">
                                        <label id="lable9" for="first9">% Allocation</label>
                                    </div>
                                    <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                        &#37;</div>
                                </div>
                            </div>
                            <div class="input-field col l2 m2 s2" style="text-align: right;">
                                <input [(ngModel)]="sublst.subheadvalue" #subheadvalue="ngModel" type="number"
                                    step="0.01" data-toggle="tooltip" data-placement="top" id="first10" min="0"
                                    (ngModelChange)="onChangeOfsubheadvalue(k,j);" oninput="validity.valid||(value='');"
                                    title="Sub Head value" style="text-align: right" required>
                                <label id="lable10" for="first10">Sub Head value</label>
                            </div>
                            <!-- <div class=" input-field col s2 "><span style="margin-right: 35px;"><img
                                        src="../../../assets/images/edit.png"
                                        (click)="btnopenmodel(model.id,templateModel)" title="Edit"
                                        width="20px" /></span>
                            </div> -->
                        </div>
                    </div>

                    <div *ngIf="lst.costSubheadlst.length==0" class="col l11 m11 s11 registerBgShadow"
                        style="margin-left:50px;text-align: center;">
                        <div class="col l12 m12 s12" style="color: red">
                            <samp> List Not Found </samp>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <span style="text-align:right">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </span>
        </div>
    </div>
</ng-template>