export class SchemeHomeModel{
    id:number;
    srno:number;
    code:string;
    name:string;
    txtserch:string;
    txtselect:string;
    customerType:string;
    
}
export class CoupenModel
{
    id:number;
    srno:number;
    code:string;
    coupenDays:number;
    days:number;
    todate:Date;
    code1:string;
    name:string;
}