export class InstallationSummaryModel {
    frmdate: Date;
    todate: Date;
    companyname: string;
    companyaddress: string;

    regionid: number;
    areaid: number;
    branchid: number;
    resourceID: number;

    name: String;//region
    areaname: string;
    branchname: string;
    resourcename: string;

    areaintlst: number[];
    resourceintlst: number[];
    branchintlst: number[];

    status: string;

    srno:number;
    date:Date;
    customerName:string;
    orderAcceptanceNo:string;
    custType:string;
    product:string;
    instaStatus:string;
    assignTo:string;

}


