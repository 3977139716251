<div *ngIf="isMobile == true ">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol">
        <h1 class="header center">Despatch</h1>
        <div class="container ">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="srchBy p-0">
                        <div class="col s12 sectionSrch">

                            <label class="w-35 font-weight-bold">Name:</label>
                            <label class="widthR-60">{{model.customername}}</label>

                            <label class="w-35 font-weight-bold">Order No.:</label>
                            <label class="widthR-60">{{model.oanumber}}</label>

                            <label class="w-35 font-weight-bold">Order Date:</label>
                            <label class="widthR-60">{{model.oadate | date:"dd-MM-yyyy"}}</label>

                            <label class="w-100 font-weight-bold dis-block"
                                style="padding-left: 5px;">Address:&nbsp;</label>
                            <label class="w-100 dis-block" style="padding-left: 5px;">{{model.address}}</label>

                        </div>
                        <label class="w-100 border-bottom"></label>

                        <div class="printBtnRow center-align m-btm">
                            <button *ngIf="model.supplierflag"
                                class="btn btn-blue waves-effect waves-light m-btm modal-trigger" href="#addsupplier"
                                style="margin-bottom: 10px;">Add
                                Supplier</button>

                            <!-- <button *ngIf="this.action!='view'"
                                class="btn btn-blue waves-effect waves-light m-btm modal-trigger" href="#modal1"
                                style="margin-bottom: 10px;">Change
                                Address</button>

                            <button *ngIf="this.action!='view'"
                                class="btn btn-blue waves-effect waves-light m-btm modal-trigger" href="#modal1"
                                style="margin-bottom: 10px;">Consignee
                                Address</button> -->
                        </div>

                        <div class="col s12 sectionSrch" *ngIf="model.supplierId!=null">
                            <div class="introTab grayBg">
                                <label class="w-100 font-weight-bold blueTxt" style="margin-bottom: 5px;">Supplier
                                    Name:</label>
                                <label class="w-100" style="margin-bottom: 5px;">{{model.supplierName}}</label>

                                <label class="w-100 font-weight-bold blueTxt" style="margin-bottom: 5px;">Supplier
                                    Address</label>
                                <label class="w-100 pl-2" style="margin-bottom: 5px;">{{model.supplierAddrs}}</label>

                                <label class="w-100 pl-2" style="margin-bottom: 5px;"><img
                                        src="../../../assets/images/delete.png" alt="remove" width="25px;"
                                        (click)="btnRemoveSupplier()" /></label>

                            </div>
                        </div>

                        <label class="w-100 font-weight-bold dis-block" *ngIf="model.invIntegration"
                            style="padding-left: 10px;margin-bottom: 0px;">Issue
                            Location</label>
                        <label class="w-100 dis-block" *ngIf="model.invIntegration"
                            style="padding-left: 10px;margin-bottom: 0px;">
                            <div class="input-field">
                                <select (change)="onSelectInventoryLocationFrom($event.target.value )"
                                    data-toggle="tooltip" data-placement="top" title="Inventory Location">
                                    <option selected value="0">Select From Inventory Location</option>
                                    <option *ngFor="let lst of invFromlocations" [value]="lst.invFromlocationid"
                                        [selected]="lst.invFromlocationid== model.invFromlocationid">
                                        {{lst.invFromlocation}}
                                    </option>
                                </select>
                            </div>
                        </label>

                        <label class="w-100 font-weight-bold dis-block" *ngIf="model.invIntegration"
                            style="padding-left: 10px;margin-bottom: 0px;">Supply
                            Location</label>
                        <label class="w-100 dis-block" *ngIf="model.invIntegration"
                            style="padding-left: 10px;margin-bottom: 0px;">
                            <div class="input-field">
                                <select (change)="onSelectInventoryToLocation($event.target.value)"
                                    data-toggle="tooltip" data-placement="top" title="Inventory Location">
                                    <option selected value="0">Select To Inventory Location</option>
                                    <option *ngFor="let lst of invTolocations" [value]="lst.id"
                                        [selected]="lst.invTolocationid== model.invTolocationid">
                                        {{lst.invTolocation}}
                                    </option>
                                </select>
                            </div>
                        </label>

                    </div>

                    <div class="col s12">
                        <div class="w-100 m-btm left-align">
                            <label><input type="checkbox" [(ngModel)]="model.chkall" #name="ngModel"
                                    (click)="btnselectAllProduct($event)">
                                &nbsp;&nbsp;&nbsp;<b>Select All</b></label>
                        </div>
                        <div class="tabInnerBox" *ngFor="let prolst of invoiceProducts">
                            <div class="introTab">

                                <label class="w-50"><input type="checkbox" [(ngModel)]="prolst.chkselectpc"
                                        #chkselectpc="ngModel" (click)="btnselectProduct($event, prolst.id)"></label>
                                <label class="w-50 font-weight-bold blueTxt valignTxt">&nbsp;</label>

                                <label class="w-100 font-weight-bold blueTxt dis-block">Product</label>
                                <label class="w-100 dis-block">{{prolst.name}}</label>

                                <label class="w-50 font-weight-bold blueTxt" *ngIf="model.invIntegration">Available
                                    Stock</label>
                                <label class="w-50 valignTxt"
                                    *ngIf="model.invIntegration && prolst.availablestock < prolst.balancequantity">{{prolst.availablestock
                                    | number:'1.2-2'}}</label>
                                <label class="w-50 valignTxt"
                                    *ngIf="model.invIntegration && prolst.availablestock >= prolst.balancequantity">{{prolst.availablestock
                                    | number:'1.2-2'}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Order Quantity</label>
                                <label class="w-50">{{prolst.orderquantity | number:'1.2-2'}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Balance Quantity</label>
                                <label class="w-50">{{prolst.balancequantity | number:'1.2-2'}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Supply Quantity</label>
                                <label class="w-50">
                                    <input type="number" [(ngModel)]="prolst.supplyquantity" #supplyquantity="ngModel"
                                        style="text-align: right" placeholder="Supply Quantity" data-toggle="tooltip"
                                        data-placement="top" title="Supply Quantity"
                                        (ngModelChange)="onChangeQuantity()" min="0"
                                        oninput="validity.valid||(value='');" [max]="prolst.balancequantity"></label>

                                <label class="w-50 font-weight-bold blueTxt">Serial #</label>
                                <label class="w-50">
                                    <img src="../../../assets/images/icons8-barcode-96.png"
                                        (click)="openserialNumber(prolst.id)" href="#serialnumber" class="modal-trigger"
                                        data-toggle="tooltip" data-placement="top" title="Edit" width="20px"
                                        *ngIf="prolst.serialno == true else other_content" />
                                    <ng-template #other_content><b>NA</b></ng-template>
                                </label>

                                <label class="w-50 font-weight-bold blueTxt">Net Amount</label>
                                <label class="w-50">{{prolst.netamount | number:'1.2-2'}}</label>

                                <label class="w-50 font-weight-bold blueTxt" *ngIf="model.gstseprate">GST Amount</label>
                                <label class="w-50" *ngIf="model.gstseprate">{{prolst.taxamount |
                                    number:'1.2-2'}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Amount</label>
                                <label class="w-50">{{prolst.totalproamount | number:'1.2-2'}}</label>

                            </div>
                        </div>
                    </div>
                    <div class="w-100 m-btm left-align">
                        <label class="w-100 dis-block blueTxt font-weight-bold valignTxt">Amount In Words: </label>
                        <label class="w-100 dis-block">&nbsp;<span>{{grandtotalinwords |
                                uppercase}}</span>&nbsp;ONLY</label>

                        <label class="w-35 blueTxt font-weight-bold">Net Amount: </label>
                        <label class="w-50">&nbsp;<span>{{totalnetamount |
                                number:'1.2-2'}}</span></label>

                        <label class="w-35 blueTxt font-weight-bold" *ngIf="model.gstseprate">Total GST: </label>
                        <label class="w-50" *ngIf="model.gstseprate">&nbsp;<span>{{totalgst |
                                number:'1.2-2'}}</span></label>

                        <label class="w-35 blueTxt font-weight-bold">Grand Total: </label>
                        <label class="w-50 ">&nbsp;<span>{{grandtotal | number:'1.2-2'}}</span></label>
                    </div>

                    <div class="tabInnerBox m-btm">
                        <div class="col12">
                            <label class="w-100 dis-block font-weight-bold">Despatch Details</label>
                            <label class="w-100">
                                <textarea id="textarea1" title=" Despatch Details" class="materialize-textarea"
                                    [(ngModel)]="model.disdetails" #disdetails="ngModel" id="DespatchDetails"
                                    style="margin-bottom: 0px;"></textarea>
                            </label>
                        </div>
                    </div>

                    <div class="s12 right">
                        <button type="button" class="btn grayButton" (click)="btnback()">Cancel</button>&nbsp;
                        <button type="button" class="btn btn-blue modal-trigger" href="#Invoice">Confirm</button>&nbsp;
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div id="serialnumber" class="modal">
        <div class="modal-content">
            <h4 class="orgTxt center-align">Serial Number</h4>
            <div class="row">

                <div class="col s12 sectionSrch">

                    <label class="w-100 font-weight-bold dis-block" style="padding-left: 5px;">Product Name
                        :&nbsp;</label>
                    <label class="w-100 dis-block" style="padding-left: 5px;">{{model.name}}</label>

                </div>
                <div class="tabInnerBox m-btm" *ngFor="let lst of model.serialProducts">
                    <div class="introTab">
                        <label class="w-50 font-weight-bold blueTxt">Sr.No.</label>
                        <label class="w-50 ">{{lst.srno}}</label>

                        <label class="w-100  font-weight-bold blueTxt dis-block">Serial Number
                        </label>
                        <label class="w-100 dis-block"> <input type="text" placeholder="Serial Number" id="serialno"
                                [(ngModel)]="lst.serialno" #serialno="ngModel"
                                (ngModelChange)="getserialnumberexist(lst.serialno,lst.pid)"></label>
                    </div>
                </div>

            </div>
            <hr>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                    style="margin-left: 5px;">Cancel</a>&nbsp;&nbsp;

                <button type="button" (click)="btnsaveserial()"
                    class="modal-close waves-effect btn btn-blue">Save</button>
            </div>
        </div>
    </div>

    <div id="Invoice" class="modal">
        <div class="modal-content">
            <h4 class="orgTxt center-align">Invoice</h4>
            <div class="row">

                <div class="tabInnerBox m-btm" *ngFor="let prolst of invoiceProducts">
                    <div class="introTab">
                        <label class="w-50 font-weight-bold blueTxt ">Product</label>
                        <label class="w-50 ">{{prolst.name}}</label>

                        <label class="w-100  font-weight-bold blueTxt dis-block">Order Quantity</label>
                        <label class="w-100 dis-block">{{prolst.orderquantity | number:'1.2-2'}}</label>

                        <label class="w-50  font-weight-bold blueTxt ">Balance Quantity</label>
                        <label class="w-50 ">{{prolst.balancequantity | number:'1.2-2'}}</label>

                        <label class="w-50  font-weight-bold blueTxt ">Supply Quantity</label>
                        <label class="w-50 ">{{prolst.supplyquantity |
                            number:'1.2-2'}}</label>

                        <label class="w-50  font-weight-bold blueTxt ">Net Amount</label>
                        <label class="w-50 ">{{prolst.netamount | number:'1.2-2'}}</label>

                        <label class="w-50  font-weight-bold blueTxt" *ngIf="model.gstseprate">GST Amount</label>
                        <label class="w-50" *ngIf="model.gstseprate">{{prolst.taxamount | number:'1.2-2'}}</label>

                        <label class="w-50  font-weight-bold blueTxt ">Amount</label>
                        <label class="w-50 ">{{prolst.totalproamount | number:'1.2-2'}}</label>
                    </div>
                </div>

                <div class="grayBg" style="box-shadow: rgb(99 99 99 / 45%) 2px 2px 2px 2px;">

                    <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Net
                        Amount:</label>
                    <label class="widthR-60" style="text-align: left;">&#x20b9; {{totalnetamount |
                        number:'1.2-2'}}</label>

                    <label class="w-35 font-weight-bold blueTxt" style="text-align: left;"
                        *ngIf="model.gstseprate">Total GST
                        Amount:</label>
                    <label class="widthR-60" style="text-align: left;" *ngIf="model.gstseprate">&#x20b9; {{totalgst |
                        number:'1.2-2'}}</label>

                    <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Grand
                        Total:</label>
                    <label class="widthR-60" style="text-align: left;">&#x20b9; {{grandtotal |
                        number:'1.2-2'}}</label>
                </div>

            </div>
            <hr>
            <div class="modal-footer">

                <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                    style="margin-left: 5px;">Cancel</a>&nbsp;&nbsp;

                <button type="button" class="btn btn-blue" (click)="btnsaveDespatch()"
                    [disabled]="showBtn == true">Save</button>
            </div>
        </div>
    </div>

    <div id="addsupplier" class="modal">
        <div class="modal-content">
            <h4 class="orgTxt center-align">Supplier</h4>
            <div class="row">
                <div class="ng-autocomplete pinRowgrp input-field col l12 m12 s12">
                    <ng-autocomplete [data]="supplierName" type="text" [(ngModel)]="model.supplierName"
                        placeholder="Supplier Name" [searchKeyword]="keyword4" [minQueryLength]="3" id="supervisiorAuth"
                        (inputChanged)='onChangeSearch4($event)' (selected)='selectEvent4($event)'
                        (inputFocused)='onFocused4($event)' [itemTemplate]="itemTemplate4"
                        [notFoundTemplate]="notFoundTemplate4">
                    </ng-autocomplete>

                    <ng-template #itemTemplate4 let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate4 let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <div class="col s12 sectionSrch">

                    <label class="w-100 font-weight-bold dis-block" style="padding-left: 5px;">Supplier
                        Address:&nbsp;</label>
                    <label class="w-100 dis-block" style="padding-left: 5px;">{{model.supplierAddrs}}</label>

                    <label class="w-35 font-weight-bold">Gst Type:</label>
                    <label class="widthR-60">{{model.gsttype}}</label>

                    <label class="w-35 font-weight-bold">Gst No.:</label>
                    <label class="widthR-60">{{model.gstinno}}</label>
                </div>

            </div>
            <hr>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                    style="margin-left: 5px;">Cancel</a>&nbsp;&nbsp;

                <a href="#!" class="modal-close waves-effect btn btn-blue">Add</a>
            </div>
        </div>
    </div>

</div>


<div *ngIf="isMobile == false ">
    <app-header></app-header>
    <div class="container" style="width: 80%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Despatch</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="col l12 m12 s12">
                    <div class="row" style="margin-top: 15px;">
                        <div class="col l8 m8 s8">
                            <div class="row">
                                <div class="col l12 m12 s12">
                                    <span style="font-size: 15px;font-weight: bold">Customer
                                        Name:&nbsp;<span>{{model.customername}}</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l12 m12 s12">
                                    <span style="font-size: 15px;font-weight: bold">Order
                                        No.:&nbsp;<span>{{model.oanumber}}</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l12 m12 s12">
                                    <span style="font-size: 15px;font-weight: bold">Order
                                        Date:&nbsp;<span>{{model.oadate | date:"dd-MM-yyyy"}}</span></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l12 m12 s12">
                                    <span
                                        style="font-size: 15px;font-weight: bold">Address:&nbsp;<span>{{model.address}}</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="col l4 m4 s4">
                            <div class="row">
                                <div class="col l6 m6 s6" style="text-align: left;padding-right:0px;padding-left: 6px;">
                                    <button class="waves-effect waves-light btn"
                                        (click)="btnchangeaddress(shippingmodel)">Change
                                        Address</button>
                                </div>
                                <div class="input-field col l6 m6 s6 "
                                    style="text-align:right;padding-left: 0px;padding-right: 0px;margin: 0px;">
                                    <button type="button" (click)="btnConsigneeModal(ConsigneeModal)"
                                        class="waves-effect waves-light btn">Consignee Address
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="input-field col l7 m7 s7" style="margin: 0px">
                                    <!-- <span>Inventory Locations</span>
                                <select (change)="onSelectLocation($event.target.value)">
                                    <option selected value="0">Select Inventory Location</option>
                                    <option *ngFor="let locations of inventorylocations" [value]="locations.id">
                                        {{locations.name}}</option>
                                </select> -->
                                </div>

                                <div class="col l5 m5 s5" *ngIf="model.supplierflag"
                                    style="margin-top: 18px;padding: 0px;text-align: right">
                                    <button class="waves-effect waves-light btn"
                                        (click)="btnChnageSupplier(SupplierModal)">Add Supplier</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="model.supplierId!=null" style="background: gainsboro;">
                        <div class="col l5 m5 s5" style="text-align: left;padding: 7px;">
                            <span style="font-size: 15px;font-weight: bold">Supplier Name:&nbsp;
                                <span>{{model.supplierName}}</span></span>
                        </div>
                        <div class="col l6 m6 s6" style="text-align: left;padding: 7px;">
                            <span style="font-size: 15px;font-weight: bold">Supplier Address:&nbsp;
                                <span>{{model.supplierAddrs}}</span></span>
                        </div>
                        <div class="col l1 m1 s1" style="text-align: right;margin-top: 4px;">
                            <span><img src="../../../assets/images/delete.png" alt="remove" width="25px;"
                                    (click)="btnRemoveSupplier()" /></span>
                        </div>
                    </div>

                    <div class="row" *ngIf="model.invIntegration">
                        <div class="input-field col s4" style="margin-top: 0px;margin-bottom: 0px;">
                            <span>Issue Location</span>
                            <select (change)="onSelectInventoryLocationFrom($event.target.value )" data-toggle="tooltip"
                                data-placement="top" title="Inventory Location">
                                <option selected value="0">Select From Inventory Location</option>
                                <option *ngFor="let lst of invFromlocations" [value]="lst.invFromlocationid"
                                    [selected]="lst.invFromlocationid== model.invFromlocationid">
                                    {{lst.invFromlocation}}
                                </option>
                            </select>
                        </div>
                        <div class="col s3"></div>
                        <div class="input-field col s4" style="margin-top: 0px;margin-bottom: 0px;">
                            <span>Supply Location</span>
                            <select (change)="onSelectInventoryToLocation($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Inventory Location">
                                <option selected value="0">Select To Inventory Location</option>
                                <option *ngFor="let lst of invTolocations" [value]="lst.id"
                                    [selected]="lst.invTolocationid== model.invTolocationid">
                                    {{lst.invTolocation}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="3%" style="text-align: center"><input type="checkbox"
                                        [(ngModel)]="model.chkall" #name="ngModel"
                                        (click)="btnselectAllProduct($event)"></th>
                                <th width="36%" style="text-align: left">Product</th>
                                <th width="8%" style="text-align: right" *ngIf="model.invIntegration">Available Stock
                                </th>
                                <th width="8%" style="text-align: right">Order Quantity</th>
                                <th width="8%" style="text-align: right">Balance Quantity</th>
                                <th width="8%" style="text-align: right">Supply Quantity</th>
                                <th width="7%" style="text-align: right">Serial #</th>
                                <th width="10%" style="text-align: right">Net Amount</th>
                                <th width="12%" style="text-align: right" *ngIf="model.gstseprate">GST Amount</th>
                                <th width="10%" style="text-align: right;padding-right: 10px;">Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let prolst of invoiceProducts">
                                    <td style="text-align: center"><input type="checkbox"
                                            [(ngModel)]="prolst.chkselectpc" #chkselectpc="ngModel"
                                            (click)="btnselectProduct($event, prolst.id)"></td>
                                    <td style="text-align: left;font-size: 14px;">{{prolst.name}}</td>
                                    <td style="text-align: right;font-size: 14px;background-color: rgb(252, 195, 195);"
                                        *ngIf="model.invIntegration && prolst.availablestock < prolst.balancequantity">
                                        {{prolst.availablestock | number:'1.2-2'}}
                                    </td>
                                    <td style="text-align: right;font-size: 14px;"
                                        *ngIf="model.invIntegration && prolst.availablestock >= prolst.balancequantity">
                                        {{prolst.availablestock | number:'1.2-2'}}
                                    </td>
                                    <td style="text-align: right;font-size: 14px;">
                                        {{prolst.orderquantity | number:'1.2-2'}}
                                    </td>
                                    <td style="text-align: right;font-size: 14px;">
                                        {{prolst.balancequantity | number:'1.2-2'}}</td>
                                    <td style="margin: 0px"><input type="number" [(ngModel)]="prolst.supplyquantity"
                                            #supplyquantity="ngModel" style="text-align: right"
                                            placeholder="Supply Quantity" data-toggle="tooltip" data-placement="top"
                                            title="Supply Quantity" (ngModelChange)="onChangeQuantity()" min="0"
                                            oninput="validity.valid||(value='');" [max]="prolst.balancequantity">
                                    </td>
                                    <td style="text-align:center"><span><img
                                                src="../../../assets/images/icons8-barcode-96.png"
                                                (click)="openmodel(coupenmodel,prolst.id)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px"
                                                *ngIf="prolst.serialno == true else other_content" />
                                            <ng-template #other_content><b>NA</b></ng-template>
                                        </span>
                                    </td>
                                    <td style="text-align: right;font-size: 14px;" *ngIf="prolst.ratewithdiscount==null">{{prolst.netamount | number:'1.2-2'}}
                                    </td>
                                    <td style="text-align: right;font-size: 14px;" *ngIf="prolst.ratewithdiscount!=null">{{prolst.ratewithdiscount | number:'1.2-2'}}
                                    </td>
                                    <td style="text-align: right;font-size: 14px;" *ngIf="model.gstseprate">
                                        {{prolst.taxamount | number:'1.2-2'}}
                                    </td>
                                    <td style="text-align: right;font-size: 14px;padding-right: 10px;">
                                        {{prolst.totalproamount | number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="background: gainsboro;padding: 9px">
                        <div class="col l6 m6 s6" style="padding-left: 0px;">
                            <span style="font-size: 14px;font-weight: bold">Amount in
                                Words:&nbsp;<span>{{grandtotalinwords | uppercase}}&nbsp;ONLY</span></span>
                        </div>
                        <div class="col l6 m6 s6" style="text-align: right;padding-right: 0px;">
                            <span style="font-size: 14px;font-weight: bold">Net
                                Amount:&nbsp;<span>{{totalnetamount | number:'1.2-2'}}</span></span>&nbsp;&nbsp;&nbsp;
                            <span style="font-size: 14px;font-weight: bold" *ngIf="model.gstseprate">Total GST
                                Amount:&nbsp;<span>{{totalgst | number:'1.2-2'}}</span></span>&nbsp;&nbsp;&nbsp;
                            <span style="font-size: 14px;font-weight: bold">Grand
                                Total:&nbsp;<span>{{grandtotal | number:'1.2-2'}}</span></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class=" input-field col l9  m7 s8"
                            style="text-align:left;margin-top: 10px;margin-bottom: 0px;">
                            <textarea id="textarea1" title=" Despatch Details" class="materialize-textarea"
                                [(ngModel)]="model.disdetails" #disdetails="ngModel" id="DespatchDetails"
                                style="margin-bottom: 0px;"></textarea>
                            <label id="lable3" for="textarea1">Despatch Details</label>
                        </div>
                        <div class="input-field col l3 m5 s5" style="text-align:right;padding-right:0px;">
                            <button (click)="btnback()" type="button"
                                class="waves-effect cancelbutton btn">Cancel</button>
                            &nbsp;&nbsp;
                            <button (click)="confirm(Invoice)" type="button"
                                class="waves-effect waves-light btn">Confirm</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #coupenmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">{{pagename1}}</span><br>
            </div>
            <div class="row">

                <div class="col l12 m12 s12">
                    <div class="col l6 m6 s6">
                        <label>Product Name:&nbsp;&nbsp;<b>{{model.name}}</b></label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="">
                    <div class="col l12 m12 s12">
                        <table border="1px solid black;"
                            style="width: 50%;border-collapse: collapse; padding-top: 10px;margin-left: 25%;"
                            cellpadding="7" id="excel">
                            <thead style="background: gainsboro;">
                                <th width="10%" style="text-align: center">Sr.No.</th>
                                <th width="30%" style="text-align: center">Serial Number</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of model.serialProducts">
                                    <td style="text-align: center">{{lst.srno}}</td>
                                    <td style="text-align: center">
                                        <input type="text" placeholder="Serial Number" id="serialno"
                                            [(ngModel)]="lst.serialno" #serialno="ngModel"
                                            (ngModelChange)="getserialnumberexist(lst.serialno,lst.pid)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right;padding-right: 0px; ">
                        <button (click)="modalRef.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnsaveserial()" type="button"
                            class="waves-effect waves-light btn">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #shippingmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Shipping Address</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
                <div class="row">
                    <div class="row col l12 m12 s12" *ngFor="let lst of orderacc.addresslst">
                        <div class="row">
                            <div class=" col l0 m0 s10" style="text-align: left;">
                                <label><input name="grup1" type="radio" (click)="onselectaddress(model.accid,$event)"
                                        [checked]="lst.cheselect">
                                    <span><b>Address:-</b></span>&nbsp;&nbsp;&nbsp;<span> {{lst.address}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s1">
                            </div>
                            <div class=" col l11 m11 s11" style="text-align: left;">
                                <div class=" col l3 m3 s3">
                                    <span><b>Locality:-</b></span>&nbsp;&nbsp;&nbsp;<span> {{lst.localityName}}</span>
                                </div>

                                <div class=" col l3 m3 s3" style="text-align: left;">
                                    <span><b>Pincode:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.pincode}}</span>
                                </div>
                                <div class=" col l2 m2 s2" style="text-align: left;">
                                    <span><b>City:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.city}}</span>
                                </div>
                                <div class=" col l3 m3 s3" style="text-align: left;">
                                    <span><b>State:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.state}}</span>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row col s12" style="text-align: center;padding-top: 16px;">
                <button type="button" (click)="btnsaveaddress()"
                    class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;&nbsp;
            </div>
        </div>
    </ng-template>

    <ng-template #Invoice>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-content registerBgShadow " style="text-align: center;padding-bottom: 0px;">
            <h4>Invoice</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 0px;padding-top: 21px;">
                <div class="row">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="30%" style="text-align: left;">Product</th>
                            <th width="10%" style="text-align: right;">Order Quantity</th>
                            <th width="10%" style="text-align: right;">Balance Quantity</th>
                            <th width="10%" style="text-align: right;">Supply Quantity</th>
                            <th width="13%" style="text-align: right;">Net Amount</th>
                            <th width="12%" style="text-align: right;" *ngIf="model.gstseprate">GST Amount</th>
                            <th width="13%" style="text-align: right;padding-right: 10px;">Amount</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prolst of invoiceProducts">
                                <td style="text-align: left;font-size: 14px;">{{prolst.name}}</td>
                                <td style="text-align: right;font-size: 14px;">
                                    {{prolst.orderquantity | number:'1.2-2'}}
                                </td>
                                <td style="text-align: right;font-size: 14px;">
                                    {{prolst.balancequantity | number:'1.2-2'}}</td>
                                <td style="text-align: right;font-size: 14px;">{{prolst.supplyquantity |
                                    number:'1.2-2'}}
                                </td>
                                <td style="text-align: right;font-size: 14px;">{{prolst.netamount | number:'1.2-2'}}
                                </td>
                                <td style="text-align: right;font-size: 14px;" *ngIf="model.gstseprate">
                                    {{prolst.taxamount | number:'1.2-2'}}
                                </td>
                                <td style="text-align: right;font-size: 14px;padding-right: 10px;">
                                    {{prolst.totalproamount | number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" style="background: gainsboro;padding: 15px">
                    <div class="col l1 m1 s1">

                    </div>
                    <div class="col l11 m11 s11" style="text-align: right">
                        <span style="font-size: 14px;font-weight: bold">Net
                            Amount:&nbsp;<span>{{totalnetamount |
                                number:'1.2-2'}}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style="font-size: 14px;font-weight: bold" *ngIf="model.gstseprate">Total GST
                            Amount:&nbsp;<span>{{totalgst | number:'1.2-2'}}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style="font-size: 14px;font-weight: bold">Grand
                            Total:&nbsp;<span>{{grandtotal | number:'1.2-2'}}</span></span>
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right;padding-right: 0px; ">
                        <button (click)="modalRef2.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnsaveDespatch()" [disabled]="showBtn == true" type="button"
                            class="waves-effect waves-light btn">Save</button>
                    </div>

                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #SupplierModal>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef3.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-content registerBgShadow " style="text-align: center;padding-bottom: 0px;">
            <h4>Supplier</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 0px;padding-top: 21px;">
                <div class="row">
                    <div class="col l1"></div>
                    <div class="input-field col l6 m6 s6">
                        <input type="text" id="dynamicUserIdsSecondWay" [(ngModel)]="model.supplierName" type="text"
                            class="autocomplete" name="Name" placeholder="Supplier Name"
                            (keyup)="getUserIdsSecondtWay($event)" data-toggle="tooltip" data-placement="top"
                            title="Supplier Name" />
                        <input type="hidden" id="cust-id" /><br>

                    </div>

                    <div class="col l1"></div>
                </div>
                <div class="row">
                    <div class="col l1"></div>
                    <div class="col l5 m5 s5" style="text-align: left">
                        <span style="font-size: 14px;">Supplier
                            Address:&nbsp;<Span>{{model.supplierAddrs}}</Span></span>
                    </div>
                    <div class="col l2 m2 s2" style="text-align: left">
                        <span style="font-size: 14px;">GST Type:&nbsp;<span>{{model.gsttype}}</span></span>
                    </div>
                    <div class="col l3 m3 s3" style="text-align: left">
                        <span style="font-size: 14px;">GST No.:&nbsp;<span>{{model.gstinno}}</span></span>
                    </div>
                    <div class="col l1"></div>
                </div>

                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right;padding-right: 0px; ">
                        <button (click)="modalRef3.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnAddSupplier()" type="button"
                            class="waves-effect waves-light btn">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #ConsigneeModal>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Consignee Address</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
                <div class="row">
                    <div class="col l1"></div>
                    <div class="input-field col l5 m5 s5">
                        <input type="text" [(ngModel)]="model.caddress" #caddress="ngModel" id="caddress"
                            data-toggle="tooltip" data-placement="top" title="Address">
                        <label class="active" for="caddress">Address</label>
                    </div>
                    <!-- <div class=" input-field col m2 s2 l2">
                    <input type="text" class="autocomplete" [(ngModel)]="model.pincode" id="pincode1"
                        (keyup)="getPincodeLike($event)" data-toggle="tooltip" data-placement="top" title="PIN Code" />
                    <input type="hidden" id="acc-id" />
                    <label class="active" for="first1">PIN Code</label>
                </div> -->
                    <div class="ng-autocomplete pinRowgrp input-field col m2 s2 l2">
                        <ng-autocomplete [data]="pincodelist" type="text" [(ngModel)]="model.pincode"
                            placeholder="PIN Code" [searchKeyword]="keyword" [minQueryLength]="3" id="pincode1"
                            (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                            (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate2 let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>

                    <div class=" input-field col s3 m3 l3" style="margin: 0px;">
                        <span>Locality</span>
                        <select (change)="onSelectLocality($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Locality" data-toggle="tooltip" data-placement="top"
                            title="Locality">
                            <option selected value="0">Select Locality</option>
                            <option *ngFor="let lst of lstlocality" [value]="lst.id"
                                [selected]="lst.id==model.localityID">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col l1"></div>
                </div>

                <div class="row">
                    <div class="col l1"></div>
                    <div class="col l2 m2 s2" style="margin-top: 9px;">
                        <span><b>GST Type</b></span>
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkwihitins"
                                (click)="onchkwihitins($event)" />
                            <span style="font-size: 104%">Within State</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 30px;">
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkoutsides"
                                (click)="onchkoutsides($event)" />
                            <span style="font-size: 104%">Outside State</span>
                        </label>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.gstno" #gstno="ngModel" id="gstinno" type="text" data-toggle="tooltip"
                            data-placement="top" title="GSTIN Number" ngModel maxlength="50" required>
                        <label class="active" for="gstinno">GSTIN Number</label>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class="row">
                    <div class="row col s12" style="text-align: right;padding-top: 16px;">
                        <button type="button" (click)="modalRef.hide()"
                            class="waves-effect cancelbutton btn">Close</button>
                        &nbsp;&nbsp; <button type="button" (click)="btnsaveConsignee()"
                            class="waves-effect waves-light btn">OK</button>

                    </div>
                </div>

            </div>
        </div>
    </ng-template>
</div>