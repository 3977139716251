<div *ngIf="isMobile == true">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol">
        <h1 class="header center">Service</h1>
        <div class="container">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="col s12">
                        <div class="tabInnerBox">
                            <div class="introTab">

                                <label class="w-100 font-weight-bold blueTxt dis-block">Customer Name</label>
                                <label class="w-100 dis-block">{{model.customername}}</label>

                                <label class="w-100 font-weight-bold blueTxt dis-block">Address</label>
                                <label class="w-100 dis-block">{{model.address}}</label>

                                <label class="w-100 font-weight-bold blueTxt dis-block">Google Map Location</label>
                                <label class="w-100 dis-block"> {{model.googlemap}}</label>

                            </div>
                        </div>
                        <div id="openGnrtDiv" class="openGnrtOTP"
                            style="display:block;margin-top: 10px;margin-bottom: 10px;" *ngIf="qOTP">
                            <label class="w-100 font-weight-bold">Service Approval OTP: </label>
                            <label class="w-50">
                                <input [(ngModel)]="model.serviceOTP" #serviceOTP="ngModel"
                                    (ngModelChange)="verifyOTP()" type="text" id="serviceOTP" title="Name" maxlength="6"
                                    placeholder="Enter OTP">
                            </label>
                            <label class="w-50">
                                <button type="button" *ngIf="showOtp == false" (click)="showotp()"
                                    class="btn btn-blue">Show
                                    OTP</button>&nbsp;
                                <button type="button" *ngIf="showOtp == true"
                                    class="btn btn-blue"><b>{{sermodel.Otpshow}}</b></button>
                            </label>
                        </div>
                        <div class="tabInnerBox">
                            <div class="introTab" *ngFor="let lst of model.lstcontact; let i = index">
                                <label class="w-100 font-weight-bold dis-block">
                                    Contact
                                </label>
                                <label class="w-50 font-weight-bold blueTxt">Name</label>
                                <label class="w-50">{{lst.contactname}}</label>

                                <label class="w-50 font-weight-bold blueTxt"
                                    *ngIf="model.chkdesignation">Designation</label>
                                <label class="w-50" *ngIf="model.chkdesignation">{{lst.contactdesignation}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Mobile Number</label>
                                <label class="w-50 valignTxt">{{lst.contactmobile}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Email Id</label>
                                <label class="w-50">{{lst.contactemail}}</label>
                            </div>
                        </div>
                        <!-- <div style="margin-top:20px;"> -->
                        <div class="tabInnerBox">
                            <div class="introTab" *ngFor="let lst of model.lstproduct; let i = index">
                                <label class="w-100 font-weight-bold dis-block">Product Portfolio</label>
                                <label class="w-50 font-weight-bold blueTxt">Call Number</label>
                                <label class="w-50">{{lst.callserviceno}}</label>

                                <label class="w-100 font-weight-bold blueTxt dis-block">Product Model</label>
                                <label class="w-100 valignTxt dis-block">{{lst.name}}</label>

                                <label class="w-50 font-weight-bold blueTxt ">Resource</label>
                                <label class="w-50 valignTxt">{{lst.resourcename}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Status</label>
                                <label class="w-50">{{lst.status}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Action</label>
                                <label class="w-50"><img src="../../../assets/images/dcr-detail.png"
                                        class="modal-trigger" href="#dcrModalOpn" style="width:25px;"
                                        (click)="btngetDCRDetailsMobile(dcrModalOpn)"></label>


                            </div>
                            <!-- Modal Structure -->
                            <div>
                                <div id="dcrModalOpn" class="modal">
                                    <div class="modal-content">
                                        <h4 class="orgTxt">Details</h4>
                                        <div class="row">
                                            <div class="col s12">
                                                <label class="w-100 font-weight-bold dis-block">DCR History</label>
                                                <div class="introTab"
                                                    style="box-shadow: rgb(99 99 99 / 50%) 0px 2px 8px 0px;margin-top: 7px;"
                                                    *ngFor="let history of dcrHistorylst">

                                                    <label class="w-50 font-weight-bold blueTxt"
                                                        style="margin-top: 5px;">DCR Date</label>
                                                    <label class="w-50">{{history.dcrdate | date:"dd-MM-yyyy"}}</label>

                                                    <label
                                                        class="w-100 font-weight-bold blueTxt dis-block">Activity</label>
                                                    <label class="w-100 dis-block">{{history.activity}}</label>

                                                    <label
                                                        class="w-100 font-weight-bold blueTxt dis-block">Owner</label>
                                                    <label class="w-100 dis-block">{{history.assignedByName}}</label>

                                                    <label
                                                        class="w-100 font-weight-bold blueTxt dis-block">Description</label>
                                                    <label class="w-100 dis-block"
                                                        style="margin-bottom: 5px;">{{history.remarks}}</label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <a href="#!"
                                                class="modal-close btn btn-blue waves-effect waves-green btn-flat"
                                                style="margin-right: 5px;">Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabInnerBox" *ngIf="model.reistallation">
                            <div class="introTab">
                                <label class="w-100 font-weight-bold blueTxt dis-block">Re-Installation
                                    Address:</label>
                                <label class="w-100 dis-block">{{model.address}}</label>

                                <label class="w-100 font-weight-bold blueTxt dis-block">Pincode</label>
                                <label class="w-100 dis-block">{{model.pincode}}</label>

                                <label class="w-100 font-weight-bold blueTxt dis-block">Locality</label>
                                <label class="w-100 dis-block"> {{model.locality}}</label>

                                <div class="introTab grayBg" style="box-shadow:rgba(99, 99, 99, 0.5) 0px 2px 8px 0px">
                                    <label class="w-35 font-weight-bold blueTxt">Locale:</label>
                                    <label class="w-50 pl-2">{{model.locale}}</label>
                                    <label class="w-35 font-weight-bold blueTxt">City</label>
                                    <label class="w-50 pl-2">{{model.city}}</label>
                                    <label class="w-35 font-weight-bold blueTxt">State</label>
                                    <label class="w-50 pl-2">{{model.state}}</label>
                                    <label class="w-35 font-weight-bold blueTxt">Country</label>
                                    <label class="w-50 pl-2">{{model.country}}</label>
                                    <label class="w-35 font-weight-bold blueTxt">Area</label>
                                    <label class="w-50 pl-2">{{model.areaname}}</label>
                                    <label class="w-35 font-weight-bold blueTxt">Region</label>
                                    <label class="w-50 pl-2">{{model.regionname}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="tabInnerBox">
                            <div class="introTab">
                                <label class="w-100 font-weight-bold dis-block blueTxt"> Call Booked Date & Time</label>
                                <label class="w-100 dis-block">{{model.callbookeddate | date:'MMM d, y, h:mm:
                                    a'}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Call Commit Date</label>
                                <label class="w-50">{{model.callcommitdate| date:'d MMMM y'}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Call Date</label>
                                <label class="w-50"><input type="text" placeholder=" Date" [(ngModel)]="model.calldate"
                                        class="form-control" bsDatepicker
                                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true}"></label>

                                <label class="w-50 font-weight-bold blueTxt">DCR Start Time</label>
                                <label class="w-50"> <input id="timepicker_ampm_dark" class="timepicker" type="time"
                                        [(ngModel)]="model.calltime" #calltime="ngModel"></label>

                                <label class="w-100 font-weight-bold dis-block blueTxt"
                                    *ngIf="changeActivity&&forflag!='view'">Activity</label>
                                <label class="w-100 dis-block" *ngIf="changeActivity&&forflag!='view'">
                                    <div class="input-field">
                                        <select (change)="onSelectactivity($event.target.value)" data-toggle="tooltip"
                                            data-placement="top" title="Activity">
                                            <option *ngFor="let lst of lstactivity" [value]="lst.id"
                                                [selected]="lst.id==model.activityID">
                                                {{lst.name}}
                                            </option>
                                        </select>
                                    </div>
                                </label>

                                <label class="w-50 font-weight-bold blueTxt"
                                    *ngIf="!changeActivity||forflag=='view'">Activity</label>
                                <label class="w-50"
                                    *ngIf="!changeActivity||forflag=='view'"><b>{{model.activity}}</b></label>

                                <label class="w-100 font-weight-bold dis-block blueTxt"
                                    *ngIf="model.flag == 'A'">Assigned
                                    To</label>
                                <label *ngIf="model.flag == 'A'" class="w-100 dis-block">
                                    <div class="input-field">
                                        <select (change)=" onSelectassigned($event.target.value)">
                                            <option *ngFor="let type of lstassigned" [value]="type.id"
                                                [selected]="type.id==leadID">
                                                {{type.name}}</option>
                                        </select>
                                    </div>
                                </label>

                                <label class="w-50 font-weight-bold blueTxt">Problem Reported</label>
                                <label class="w-50">{{model.problemreported}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Working Status</label>
                                <!-- <label class="w-50">{{model.serviceCallStatusName}}</label> -->
                                <label class="w-50">{{model.mcstatus}}</label>
                            </div>
                        </div>

                        <label *ngIf="model.showspare" class="w-100 font-weight-bold dis-block"
                            style="text-align: left;">Service Quotation</label>

                        <label class="w-35 font-weight-bold" *ngIf="model.showspare">Product
                            Category</label>
                        <label class="input-field widthR-60" *ngIf="model.showspare">

                            <select (change)="onSelectProCat($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Product Category">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of spareProductcatelst" [value]="lst.id"
                                    [selected]="lst.id==pid">
                                    {{lst.name}}</option>
                            </select>

                        </label>
                        <label class="w-35 font-weight-bold" *ngIf="model.showspare">Products</label>
                        <label class="input-field widthR-60" *ngIf="model.showspare">

                            <select (change)="onSelectPro($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Products">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of productlst" [value]="lst.pid" [selected]="lst.pid==pid">
                                    {{lst.description}}</option>
                            </select>

                        </label>

                        <!-- <label class="w-100 currencyCntr"
                                *ngIf="action!='View' && action!='Decision' && currcymodel.id!=null">Currency :
                                {{currcymodel.name}}</label> -->

                        <hr *ngIf="model.showspare">

                        <div class="s12" style="margin-bottom: 55px;" *ngIf="model.showspare">
                            <button type="button" (click)="btnAddProduct()"
                                class="btn btn-blue waves-effect waves-light right">Add</button>
                        </div>
                        <div *ngIf="model.showspare">
                            <div class="tabInnerBox" *ngFor="let lst of model.lstspare; let i = index">
                                <div class="introTab introTabSrch">

                                    <div class="clear"></div>
                                    <label class="w-100 dis-block">
                                        <input [(ngModel)]="lst.checkselect" #checkselect="ngModel" type="checkbox"
                                            name="Select" style="text-align: 20px; ">
                                        <label for="chkfollowup"></label>
                                    </label>
                                    <label class="widthR-40 font-weight-bold blueTxt">Description</label>
                                    <label class="widthR-60">{{lst.sparedescription}}</label>

                                    <label class="widthR-40 font-weight-bold blueTxt">Type</label>
                                    <label class="widthR-60"> {{lst.type}}</label>

                                    <label class="widthR-40 font-weight-bold blueTxt">Quantity</label>
                                    <label class="w-20"> <input [(ngModel)]="lst.quantity" #calltime="ngModel"
                                            type="number" min=0 id="quantity" (ngModelChange)="onChangeOfPrice(lst.id,lst.discount)"
                                            title="Quantity" maxlength="35" style="text-align: end;"
                                            [disabled]="lst.checkselect == false">
                                    </label>
                                    <label class="w-40"></label>


                                    <label class="widthR-40 font-weight-bold blueTxt">UOM</label>
                                    <label class="widthR-60">{{lst.uom}}</label>

                                    <label class="widthR-40 font-weight-bold blueTxt">Rate</label>
                                    <label class="widthR-60">{{lst.rate | number: '1.2-2'}}</label>

                                    <label class="widthR-40 font-weight-bold blueTxt">Amount</label>
                                    <label class="widthR-60">{{lst.amount | number : '1.2-2'}}</label>

                                </div>

                            </div>
                            <div class="tabInnerBox">
                                <label class="widthR-100 font-weight-bold blueTxt dis-block"
                                    style="text-align: left;">Total
                                    In Words</label>
                                <label class="widthR-100 dis-block" style="text-align: left;">{{model.totalamountwords |
                                    uppercase}}&nbsp;ONLY</label>

                                <label class="widthR-40 font-weight-bold blueTxt" style="display: inline-flex;">Sub
                                    Total</label>
                                <label class="widthR-60" style="display: inline-flex;">&#x20b9; {{model.total| number :
                                    '1.2-2'}}</label>

                                <label class="widthR-40 font-weight-bold blueTxt"
                                    style="display: inline-flex;">GST</label>
                                <label class="widthR-60" style="display: inline-flex;">&#x20b9; {{model.totalgstamount|
                                    number : '1.2-2'}}</label>

                                <label class="widthR-40 font-weight-bold blueTxt"
                                    style="display: inline-flex;">Total</label>
                                <label class="widthR-60" style="display: inline-flex;">&#x20b9; {{model.totalamount|
                                    number
                                    : '1.2-2'}}</label>
                            </div>
                        </div>
                        <label class="w-35 dateBox" *ngIf="model.showspare">
                            <input [(ngModel)]="model1.schmemCode" #schmemCode="ngModel" type="text" id="schmemCode"
                                data-toggle="tooltip" style="text-align: end" data-placement="top"
                                [disabled]="action=='View'||action=='Decision' || codehide == true" title="Scheme Code"
                                style="position: relative;" placeholder="scheme Code" (ngModelChange)="selectSchemeCode(model1.schmemCode)">
    
                        </label>
                        <label class="w-10" *ngIf="model.showspare">
                            <span *ngIf="model1.schmemCode!=null"><img src="../../../assets/images/cancel.png" width="40%"
                                    (click)="removeScheme()" /></span>
                        </label>
                        <label class="w-35 dateBox" *ngIf="schemeModel.discountType == 'Custom' || model1.schemetype == 'Custom'">
                            <input [(ngModel)]="model1.customnumber" #customnumber="ngModel" type="text" id="customnumber"
                            data-toggle="tooltip" style="position: relative;" data-placement="top" placeholder="CustomNumber">

                        </label>
                      
                        <button (click)="btnApplyScheme()" type="button" *ngIf="model.showspare"
                            [disabled]="model1.schmemCode==null" class="btn grayButtonRes">Calculate</button>

                        <div class="tabInnerBox grayBg" *ngIf="model.showspare">
                            <label class="w-100 font-weight-bold dis-block">Terms And Conditions</label>
                            <div class="introTab " *ngFor="let lst of model.tandclst">
                                <lable class="w-100"><input [(ngModel)]="lst.chkselecttc" #chkselecttc="ngModel"
                                        type="checkbox" class="" id="chkselecttc">
                                </lable>

                                <label class="widthR-40 font-weight-bold blueTxt">Heading</label>
                                <label class="widthR-60"> {{lst.heading}}</label>

                                <label class="widthR-40 font-weight-bold blueTxt">Description</label>
                                <label class="widthR-60">{{lst.descrption}}</label>

                            </div>
                        </div>

                        <div class="col s12 introTab " style="margin-top:20px;" *ngIf="!model.close">
                            <label class="w-100 dis-block font-weight-bold text-left">Remark</label>
                            <div class="input-field col s12" style="margin-top:0px;">
                                <textarea [(ngModel)]="model.remark" class="materialize-textarea" #remark="ngModel"
                                    type="text" id="remark" title="Remark" placeholder="Enter Remark" rows="5">
                            </textarea>
                            </div>
                        </div>

                        <div class="col s12 introTab " style="margin-top:20px;" *ngIf="model.close">
                            <label class="w-100 dis-block font-weight-bold text-left">Observation & Analysis</label>
                            <div class="input-field col s12" style="margin-top:0px;">
                                <textarea [(ngModel)]="model.observationandanalysis" class="materialize-textarea"
                                    #remark="ngModel" type="text" id="remark" title="Observation & Analysis"
                                    placeholder="Observation & Analysis" rows="5">
                            </textarea>
                            </div>

                            <label class="w-100 font-weight-bold dis-block ">Problem Category</label>
                            <label class="w-100 dis-block">
                                <div class="input-field">
                                    <select (change)="onSelectlstproblem($event.target.value)" data-toggle="tooltip"
                                        data-placement="top" title="Problem Category">
                                        <option *ngFor="let lst of lstproblem" [value]="lst.id"
                                            [selected]="lst.id==model.problemcategoryid">
                                            {{lst.name}}
                                        </option>
                                    </select>
                                </div>
                            </label>
                        </div>

                        <div class="clear "></div>
                        <div class="col s12 m-btm oprnDiv m-btm">
                            <label class="w-50 smFlow" *ngIf="forflag!='view'&&!model.closeotpverify">
                                <input [(ngModel)]="model.follow" #checkselect="ngModel"
                                    (click)="OnfollowChecked($event)" type="checkbox" name="Follow"
                                    style="text-align: 20px;">
                                <label for="chkfollowup">Follow up</label>
                            </label>
                            <label class="w-50" *ngIf="forflag!='view'&&!model.closeotpverify">
                                <input [(ngModel)]="model.close" #checkselect="ngModel" (click)="Onclosechecked($event)"
                                    type="checkbox" name="Close" style="text-align: 20px; ">
                                <label for="chkInstallation ">Close Call</label>
                            </label>

                            <!-- Follow up click div opens -->
                            <div id="openFollowUpDiv m4" *ngIf="model.follow">
                                <div class="trangel1 "></div>
                                <div class="openDateDiv srchBy container ">
                                    <label class="widthR-60 font-weight-bold ">Followup Date</label>
                                    <label class="widthR-40 "> <input type="text" placeholder="Followup Date"
                                            [(ngModel)]="model.followupdate" id="date" class="form-control"
                                            (ngModelChange)="selectFollowupDate(model.followupdate)" bsDatepicker
                                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true }">
                                    </label>

                                    <label class="w-35 font-weight-bold ">Activity</label>
                                    <label class="widthR-60 ">
                                        <div class="input-field ">
                                            <select (change)="onSelectfollowupactivity($event.target.value)"
                                                data-toggle="tooltip" data-placement="top" title="Activity">
                                                <option *ngFor="let lst of lstactivity" [value]="lst.id"
                                                    [selected]="lst.id==model.followupactivityID">
                                                    {{lst.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Confirmed Installation click div -->
                            <div id="openInstallationDiv">
                                <!-- <div class="trangel2" *ngIf="model.close"></div> -->
                                <div class="openDateDiv" *ngIf="model.close">
                                    <button *ngIf="model.close&&forflag!='view'&&!model.closeotpverify"
                                        (click)="btngenrateOTP()" type="button" class="btn grayButtonRes">
                                        Generate OTP
                                    </button>

                                    <div class="row ">
                                        <div class="s12">
                                            <label class="w-100 dateBox2 ">
                                                <input
                                                    *ngIf="model.closureOTPsend!=null&&model.close&&!model.closeotpverify"
                                                    [(ngModel)]="model.closureOTP" #serviceOTP="ngModel"
                                                    (ngModelChange)="verifyClosureOTP()" type="text" id="closureOTP"
                                                    title="Name" maxlength="6" placeholder="Enter OTP"
                                                    style="position: relative;">

                                            </label>
                                            <label class="w-50"
                                                *ngIf="model.closureOTPsend!=null&&model.close&&!model.closeotpverify">
                                                <button type="button" *ngIf="genOtp == false" (click)="closureOTP()"
                                                    class="btn btn-blue">Show
                                                    OTP</button>&nbsp;
                                                <button type="button" *ngIf="genOtp == true"
                                                    class="btn btn-blue"><b>{{sermodel.closureOTP}}</b></button>
                                            </label>
                                            <!-- <div style="text-align: end;" *ngIf="model.close&&forflag!='view'&&model.closeotpverify">
                                                <button type="button" *ngIf="genOtp == false" (click)="closureOTP()" class="btn btn-blue">Show
                                                    OTP</button>&nbsp;
                                                <button type="button" *ngIf="genOtp == true"
                                                    class="btn btn-blue"><b>{{sermodel.closureOTP}}</b></button>&nbsp;
                                            </div> -->


                                            <label *ngIf="model.closeotpverify&&model.close"
                                                class="w-100 font-weight-bold">Service Report Confirmed By: </label><br>
                                            <label *ngIf="model.closeotpverify&&model.close"
                                                class="w-100">{{model.contactname}}</label><br>
                                            <label *ngIf="model.closeotpverify&&model.close"
                                                class="w-100"><b>{{model.sendclosureOTP}}</b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <label class="w-35 font-weight-bold" *ngIf="model.closeotpverify&&model.close">Product
                            Category</label>
                        <label class="input-field widthR-60" *ngIf="model.closeotpverify&&model.close">

                            <select (change)="onSelectProCat($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Product Category">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of spareProductcatelst" [value]="lst.id"
                                    [selected]="lst.id==pid">
                                    {{lst.name}}</option>
                            </select>

                        </label>
                        <label class="w-35 font-weight-bold" *ngIf="model.closeotpverify&&model.close">Products</label>
                        <label class="input-field widthR-60" *ngIf="model.closeotpverify&&model.close">

                            <select (change)="onSelectPro($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Products">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of productlst" [value]="lst.pid" [selected]="lst.pid==pid">
                                    {{lst.description}}</option>
                            </select>

                        </label>

                        <!-- <label class="w-100 currencyCntr"
                                *ngIf="action!='View' && action!='Decision' && currcymodel.id!=null">Currency :
                                {{currcymodel.name}}</label> -->

                        <hr *ngIf="model.closeotpverify&&model.close">

                        <div class="s12" style="margin-bottom: 55px;" *ngIf="model.closeotpverify&&model.close">
                            <button type="button" (click)="btnAddProduct()"
                                class="btn btn-blue waves-effect waves-light right">Add</button>
                        </div>

                        <label *ngIf="model.closeotpverify&&model.close" class="w-100 font-weight-bold dis-block"
                            style="text-align: left;">Final
                            Service Invoice</label>

                        <div *ngIf="model.closeotpverify&&model.close">
                            <div class="tabInnerBox" *ngFor="let lst of model1.lstspare; let i = index">
                                <div class="introTab introTabSrch">

                                    <div class="clear"></div>
                                    <label class="w-100 dis-block">
                                        <input [(ngModel)]="lst.checkselect" #checkselect="ngModel" type="checkbox"
                                            name="Select" style="text-align: 20px; ">
                                        <label for="chkfollowup"></label>
                                    </label>
                                    <label class="w-100 font-weight-bold blueTxt dis-block">Spare Description</label>
                                    <label class="w-100 dis-block">{{lst.sparedescription}}</label>

                                    <label class="widthR-40 font-weight-bold blueTxt">Quantity</label>
                                    <label class="w-20"> <input [(ngModel)]="lst.quantity" #calltime="ngModel"
                                            type="number" min=0 id="quantity" (ngModelChange)="onChangeOfPrice(lst.id,lst.discount)"
                                            title="Quantity" maxlength="35" style="text-align: end;"
                                            *ngIf="!model.invocesend" [disabled]="lst.checkselect == false">
                                        <span *ngIf="model.invocesend">{{lst.quantity}}</span>
                                    </label>
                                    <label class="w-40"></label>

                                    <label class="widthR-40 font-weight-bold blueTxt">UOM</label>
                                    <label class="widthR-60">{{lst.uom}}</label>

                                    <label class="widthR-40 font-weight-bold blueTxt">Rate</label>
                                    <label class="widthR-60">{{lst.rate | number: '1.2-2'}}</label>

                                    <label class="widthR-40 font-weight-bold blueTxt">Amount</label>
                                    <label class="widthR-60">{{lst.amount | number : '1.2-2'}}</label>

                                </div>

                            </div>
                            <div class="tabInnerBox">
                                <label class="widthR-100 font-weight-bold blueTxt dis-block"
                                    style="text-align: left;">Total
                                    In Words</label>
                                <label class="widthR-100 dis-block" style="text-align: left;">{{model.totalamountwords |
                                    uppercase}}&nbsp;ONLY</label>

                                <label class="widthR-40 font-weight-bold blueTxt" style="display: inline-flex;">Sub
                                    Total</label>
                                <label class="widthR-60" style="display: inline-flex;">&#x20b9; {{model.total| number :
                                    '1.2-2'}}</label>

                                <label class="widthR-40 font-weight-bold blueTxt" style="display: inline-flex;"
                                    *ngIf="this.showgst">GST</label>
                                <label class="widthR-60" style="display: inline-flex;" *ngIf="this.showgst">&#x20b9;
                                    {{model.totalgstamount| number :
                                    '1.2-2'}}</label>

                                <label class="widthR-40 font-weight-bold blueTxt"
                                    style="display: inline-flex;">Total</label>
                                <label class="widthR-60" style="display: inline-flex;">&#x20b9; {{model.totalamount|
                                    number
                                    : '1.2-2'}}</label>
                            </div>
                        </div>
                        <label class="w-35 dateBox" *ngIf="model.closeotpverify&&model.close">
                            <input [(ngModel)]="model1.schmemCode" #schmemCode="ngModel" type="text" id="schmemCode"
                                data-toggle="tooltip" style="text-align: end" data-placement="top"
                                [disabled]="action=='View'||action=='Decision' || codehide == true" title="Scheme Code"
                                style="position: relative;" placeholder="scheme Code" (ngModelChange)="selectSchemeCode(model1.schmemCode)">
    
                        </label>
                        <label class="w-10" *ngIf="model.closeotpverify&&model.close">
                            <span *ngIf="model1.schmemCode!=null"><img src="../../../assets/images/cancel.png" width="40%"
                                    (click)="removeScheme()" /></span>
                        </label>
                        <label class="w-35 dateBox" *ngIf="model.closeotpverify&&model.close&&schemeModel.discountType == 'Custom' || model1.schemetype == 'Custom'">
                            <input [(ngModel)]="model1.customnumber" #customnumber="ngModel" type="text" id="customnumber"
                            data-toggle="tooltip" style="text-align: end" data-placement="top" placeholder="CustomNumber">
                        </label>
                        <button (click)="btnApplyScheme()" type="button" *ngIf="model.closeotpverify&&model.close"
                            [disabled]="model1.schmemCode==null" class="btn grayButtonRes">Calculate</button>


                        <div class="s12 right mt-top">
                            <button type="button" (click)="btnback()" id="close" class="btn grayButton"
                                style="margin-right:6px;">Cancel</button>
                            <button type="button" class="btn btn-blue" (click)="btnsave()"
                                *ngIf="!model.invocesend">Save</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isMobile == false">
    <app-header></app-header>
    <div class="container" style="width: 90%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Service</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="row">
                    <div class=" col l12 m12 s12">
                        <div class=" col l12 m12 s12" style="padding: 0px;">
                            <div class="col l9 m9 s9" style="padding-top: 10px;padding-left: 0px;padding-right: 0px;">
                                <div class="row">
                                    <div class="col l12 m12 s12">
                                        <span style="font-size: 15px;font-weight: bold">Customer
                                            Name:&nbsp;<span
                                                style="font-size: 15px;font-weight: bold">{{model.customername}}</span></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col l12 m12 s12">
                                        <span style="font-size: 15px;font-weight: bold">Address:&nbsp;<span
                                                style="font-size: 15px;font-weight: bold">{{model.address}}</span></span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col l12 m12 s12">
                                        <span style="font-size: 15px;font-weight: bold">Google Map
                                            Location:&nbsp;<span
                                                style="font-size: 15px;font-weight: bold">{{model.googlemap}}</span></span>
                                    </div>
                                </div>
                            </div>

                            <div class="row col l3 m3 s3" *ngIf="qOTP" style="padding-left: 0px;padding-right: 0px;">
                                <div class="row">
                                    <div class=" col l8 m8 s8"
                                        style="text-align: end;font-size: 15px;padding-top: 33px;font-weight: bold;">
                                        <span>Service Approval OTP:&nbsp;</span>
                                    </div>
                                    <div class=" input-field col l4 m4 s4">
                                        <input [(ngModel)]="model.serviceOTP" #serviceOTP="ngModel"
                                            (ngModelChange)="verifyOTP()" type="text" id="serviceOTP" title="Name"
                                            maxlength="6">
                                        <label for="serviceOTP">OTP</label>

                                        <!-- <span style="margin-left: 5px;"><img src="../../../assets/images/edit.png"
                                        (click)="resendOTP()" data-toggle="tooltip"
                                        data-placement="top" title="Resend" width="20px" /></span> -->
                                    </div>
                                </div>

                                <div class="row">
                                    <div style="text-align: end;">
                                        <button type="button" *ngIf="showOtp == false" (click)="showotp()"
                                            class="btn btn-blue">Show
                                            OTP</button>&nbsp;
                                        <button type="button" *ngIf="showOtp == true"
                                            class="btn btn-blue"><b>{{sermodel.Otpshow}}</b></button>&nbsp;
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <b style="font-size: 15px;">Contacts</b>
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20%" style="text-align: left;padding-left: 10px;">Name</th>
                                    <th width="10%" style="text-align: left" *ngIf="model.chkdesignation">Designation
                                    </th>
                                    <th width="10%" style="text-align: left">Mobile</th>
                                    <th width="20%" style="text-align: left">E-Mail</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of model.lstcontact; let i = index">
                                        <td style="text-align: left;padding-left: 10px;">
                                            <span>{{lst.contactname}}</span>
                                        </td>
                                        <td class=" input-field " style="text-align: left" *ngIf="model.chkdesignation">
                                            <span>{{lst.contactdesignation}}</span>
                                        </td>
                                        <td style="text-align: left">
                                            <span>{{lst.contactmobile}}</span>
                                        </td>
                                        <td style="text-align: left">
                                            <span>{{lst.contactemail}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <b style="font-size: 15px;">Product Portfolio</b>
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="10%" style="text-align: left;padding-left: 10px;">Call</th>
                                    <th width="20%" style="text-align: left">Product Model</th>
                                    <th width="20%" style="text-align: left">Resource</th>
                                    <th width="10%" style="text-align: left">Status</th>
                                    <th width="10%" style="text-align: center">Action</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of model.lstproduct; let i = index">
                                        <td class=" input-field" style="text-align: left;padding-left: 10px;">
                                            <span> {{lst.callserviceno}} </span>
                                        </td>

                                        <td style="text-align: left;">
                                            <span> {{lst.name}} </span>
                                        </td>

                                        <td style="text-align: left;">
                                            <span> {{lst.resourcename}} </span>
                                        </td>

                                        <td style="text-align: left;">
                                            <span> {{lst.status}} </span>
                                        </td>
                                        <td style="text-align: center;">
                                            <span><img src="../../../assets/images/dcr-detail.png"
                                                    (click)="btngetDCRDetails(DCRMode)" data-toggle="tooltip"
                                                    data-placement="top" title="DCR Details"
                                                    style="height: 25px;width: 25px;" width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row" style="padding-top: 10px;">
                            <div class="col s6 m6 l6" *ngIf="model.reistallation">
                                <span style="font-size: 15px;">Re-Installation
                                    Address:&nbsp;<span><b>{{model.addresss}}</b></span></span>
                            </div>
                            <div class="col s3 m3 l3" style="margin: 0px;" *ngIf="model.reistallation">
                                <span
                                    style="font-size: 15px;">Pincode:&nbsp;<span><b>{{model.pincode}}</b></span></span>
                            </div>
                            <div class="col s3 m3 l3" *ngIf="model.reistallation" style="margin: 0px;">
                                <span
                                    style="font-size: 15px;">Locality:&nbsp;<span><b>{{model.locality}}</b></span></span>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.reistallation">
                            <table>
                                <tbody>
                                    <tr style="background: gainsboro;">
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Locale:<br><span><b>{{model.locale}}</b></span></span>
                                        </td>
                                        <td width="17%"><span
                                                style="font-size: 15px;">City:<br><span><b>{{model.city}}</b></span></span>
                                        </td>
                                        <td width="17%"><span
                                                style="font-size: 15px;">State:<br><span><b>{{model.state}}</b></span></span>
                                        </td>
                                        <td width="15%"><span
                                                style="font-size: 15px;">Country:<br><span><b>{{model.country}}</b></span></span>
                                        </td>
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Area:<br><span><b>{{model.areaname}}</b></span></span>
                                        </td>
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Region:<br><span><b>{{model.regionname}}</b></span></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class=" col l12 m12 s12" style=" padding-top: 0px;">
                            <div class=" input-field col s3" style="margin: 0px ;padding-left: 0px;">
                                <b>Call Booked Date & Time</b><br>
                                <span>{{model.callbookeddate | date:'MMM d, y, h:mm: a'}}</span>
                            </div>
                            <div class=" input-field col s3" style="margin: 0px">
                                <b>Call Commit Date</b>
                                <br>
                                <span>{{model.callcommitdate| date:'d MMMM y'}}</span>
                            </div>
                            <div class=" input-field col s3" style="margin: 0px">
                                <b>Call Date</b>
                                <br>
                                <input type="text" placeholder=" Date" [(ngModel)]="model.calldate" class="form-control"
                                    bsDatepicker
                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true}">
                                <!-- <span *ngIf="model.sendserviceOTP!=null||forflag=='view'">{{model.calldate | date:'d MMMM y'}}</span> -->
                            </div>
                            <div class=" input-field col s3" style="margin-top: 0px;margin-bottom: 0px;">

                                <div class=" input-field col s6" style="margin: 0px">
                                    <b>DCR Start Time</b>
                                    <!-- <b *ngIf="model.sendserviceOTP!=null||forflag=='view'">Call Start Time</b> -->
                                    <!-- <input [(ngModel)]="model.calltime" 
                                    #calltime="ngModel" type="text" id="calltime" title="Name" maxlength="35"> -->
                                    <!-- <span *ngIf="model.sendserviceOTP!=null||forflag=='view'">{{model.calltime}}</span> -->
                                    <label for="timepicker_ampm_dark"></label>
                                    <input id="timepicker_ampm_dark" class="timepicker" type="time"
                                        [(ngModel)]="model.calltime" #calltime="ngModel">
                                </div>
                                <!-- <div class=" input-field col s6" style="padding-top: 20px;">
                                IST
                                </div> -->

                            </div>
                        </div>
                        <div class="row col l12 m12 s12 " style="padding-top: 0px;padding-left: 0px;">
                            <div class="input-field col s3" style="margin: 0px">
                                <span>Activity</span><br>
                                <select *ngIf="changeActivity&&forflag!='view'"
                                    (change)="onSelectactivity($event.target.value)" data-toggle="tooltip"
                                    data-placement="top" title="Activity">
                                    <option *ngFor="let lst of lstactivity" [value]="lst.id"
                                        [selected]="lst.id==model.activityID">
                                        {{lst.name}}
                                    </option>
                                </select>
                                <span *ngIf="!changeActivity||forflag=='view'"
                                    style="font-size: 16px;"><b>{{model.activity}}</b></span>
                            </div>
                            <div class=" input-field col s3"
                                style="margin-bottom: 0px;margin-top: 0px;padding-left: 14px;"
                                *ngIf="model.flag == 'A'">
                                <!-- *ngIf="model.actionTaken == 'A'" -->
                                <span>Assigned To</span>
                                <select (change)=" onSelectassigned($event.target.value)">
                                    <option *ngFor="let type of lstassigned" [value]="type.id"
                                        [selected]="type.id==leadID">
                                        {{type.name}}</option>
                                </select>
                            </div>
                            <div class="input-field col s3" style="margin-top: 15px;padding-left: 15px;">
                                <b>Problem Reported</b><br>
                                <span>{{model.problemreported}}</span>
                            </div>
                            <div class="input-field col s3" style="margin-top: 15px">
                                <b>Working Status</b><br>
                                <!-- <span>{{model.serviceCallStatusName}}</span> -->
                                <span>{{model.mcstatus}}</span>
                            </div>

                        </div>
                        <div class="row" *ngIf="model.showspare"
                            style="padding-left: 10px;padding-right: 10px; padding-top: 10px;">
                            <b style="font-size: 15px;">Service Quotation</b>

                            <div class="row">
                                <div class="input-field col l3 m3 s3">
                                    <span>Product Category</span>
                                    <select (change)="onSelectProCat($event.target.value)" data-toggle="tooltip"
                                        data-placement="top" title="Product Category">
                                        <option selected>Select One</option>
                                        <option *ngFor="let lst of spareProductcatelst" [value]="lst.id"
                                            [selected]="lst.id==pid">
                                            {{lst.name}}</option>
                                    </select>
                                </div>
                                <div class="input-field col l4 m4 s4">
                                    <span>Product</span>
                                    <select data-toggle="tooltip" data-placement="top" title="Products"
                                        (change)="onSelectPro($event.target.value)">
                                        <option selected>Select One</option>
                                        <option *ngFor="let lst of productlst" [value]="lst.pid"
                                            [selected]="lst.pid==pid">
                                            {{lst.description}}</option>
                                    </select>
                                </div>


                                <!-- <div class="input-field col l2 m2 s2" *ngIf="currencylst.length>0">
                                <span>Currency</span>
                                <select (change)="onSelectCurrency($event.target.value)" data-toggle="tooltip"
                                    data-placement="top" title="Currency">
                                    <option selected>Select One</option>
                                    <option *ngFor="let lst of currencylst" [value]="lst.id">
                                        {{lst.name}}</option>
                                </select>
                            </div> -->
                                <div class="col l2 m2 s2"
                                    style="margin-top: 30px;text-align: right;padding-right: 0px;">
                                    <button (click)="btnAddProduct()" type="button"
                                        class="waves-effect waves-light btn">Add</button>
                                </div>
                            </div>
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="5%" style="text-align: left"></th>
                                    <th width="35%" style="text-align: left">Description</th>
                                    <th width="10%" style="text-align: left">Type</th>
                                    <th width="10%" style="text-align: left" *ngIf="this.showgst">GST %</th>
                                    <th width="10%" style="text-align: left">Quantity</th>
                                    <th width="10%" style="text-align: right">UOM</th>
                                    <th width="10%" style="text-align: right">Rate</th>
                                    <th width="15%" style="text-align: right;padding-right: 10px;">Amount</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of model1.lstspare; let i = index">
                                        <td class=" input-field" style="text-align: left">
                                            <input [(ngModel)]="lst.checkselect" #checkselect="ngModel" type="checkbox"
                                                name="Select" (click)="Onucheckselect(i)" style="text-align: 20px; ">
                                        </td>
                                        <td class=" input-field" style="text-align: left">
                                            <span> {{lst.sparedescription}} </span>
                                        </td>
                                        <td class=" input-field" style="text-align: left">
                                            <span> {{lst.type}} </span>
                                        </td>
                                        <td class=" input-field" style="text-align: left" *ngIf="this.showgst">
                                            <span> {{lst.gstpersentage}} </span>
                                        </td>
                                        <td class=" input-field" style="text-align: left;padding: 0px;">
                                            <input [(ngModel)]="lst.quantity" #calltime="ngModel" type="number" min=0
                                                id="quantity" (ngModelChange)="onChangeOfPrice(lst.id,lst.discount)" title="Quantity"
                                                maxlength="35" style="text-align: end;"
                                                [disabled]="lst.checkselect == false">
                                        </td>
                                        <td class=" input-field" style="text-align: right;">
                                            <span> {{lst.uom}} </span>
                                        </td>
                                        <td class=" input-field" style="text-align: right;">
                                            <span> {{lst.rate | number: '1.2-2'}} </span>
                                        </td>
                                        <td class=" input-field" style="text-align: right;padding-right: 10px;">
                                            <span> {{lst.amount | number : '1.2-2'}} </span>
                                        </td>
                                </tbody>
                            </table>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="model.showspare">
                            <div class=" input-field col s6">
                                <span style="font-size: 14px;font-weight: bold">Total - &nbsp;{{model.totalamountwords |
                                    uppercase}}&nbsp;ONLY</span>
                            </div>
                            <div class=" input-field col s6" style=" text-align: right;">
                                <b style="font-size: 14px;"> Sub-Total : &#x20b9; {{model.total| number :
                                    '1.2-2'}}</b>&nbsp;&nbsp;&nbsp;
                                <b style="font-size: 14px;" *ngIf="this.showgst"> GST : &#x20b9; {{model.totalgstamount|
                                    number :
                                    '1.2-2'}}</b>&nbsp;&nbsp;&nbsp;
                                <b style="font-size: 14px;"> Total : &#x20b9; {{model.totalamount| number :
                                    '1.2-2'}}</b>
                            </div>
                        </div>
                        <div class="row" *ngIf="model.showspare">
                            <!-- *ngIf="model.chkschemeflag =='Y'" -->
                            <div class="input-field col l3 m3 s3">
                                <input [(ngModel)]="model1.schmemCode" #schmemCode="ngModel" type="text" id="schmemCode"
                                    data-toggle="tooltip" style="text-align: end" data-placement="top"
                                    [disabled]="action=='View'||action=='Decision' || codehide == true" title="Scheme Code"  (ngModelChange)="selectSchemeCode(model1.schmemCode)">
                                <label for="schmemCode">Scheme Code</label>
                            </div>
                            <div class="col l1 m1 s1" style="margin-top: 12px;" *ngIf="model1.schmemCode!=null">
                                <span><img src="../../../assets/images/cancel.png" width="50%"
                                        (click)="removeScheme()" /></span>
                            </div>
                            <div class="input-field col l2 m2 s2" *ngIf="schemeModel.discountType == 'Custom' || model1.schemetype == 'Custom'">
                                <input [(ngModel)]="model1.customnumber" #customnumber="ngModel" type="text" id="customnumber"
                                    data-toggle="tooltip" style="text-align: end" data-placement="top" placeholder="CustomNumber">
                            </div>
    
                            <div class="col l2 m2 s2" style="margin-top: 10px;">
                                <button (click)="btnApplyScheme()" type="button" [disabled]="model1.schmemCode==null"
                                    class="waves-effect waves-light btn">Calculate</button>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 0px;margin-left: 0px;margin-right: 0px;"
                            *ngIf="model.showspare">
                            <div class="col l12 m12 s12" style="padding: 0px;">
                                <b style="font-size: 15px;">Terms And Conditions</b>
                                <table>
                                    <thead style="background-color: gainsboro;">
                                        <th width="10%" style="text-align: left"></th>
                                        <th width="30%" style="text-align: left">Heading</th>
                                        <th width="60%" style="text-align: left">Description</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of model.tandclst">
                                            <td style="text-align: left; padding: 0px"><input
                                                    [(ngModel)]="lst.chkselecttc" #chkselecttc="ngModel" type="checkbox"
                                                    class="" id="chkselecttc"></td>
                                            <td style="text-align: left; padding: 0px;font-size: 14px">
                                                {{lst.heading}}
                                            </td>
                                            <td style="text-align: left; padding: 0px">
                                                {{lst.descrption}}
                                                <!-- <input [(ngModel)]="lst.descrption"
                                                #descrption="ngModel" type="text" class="" id="descrption"
                                                data-toggle="tooltip" data-placement="top" title="Description" (ngModelChange)="onchagnedesrption()" [disabled]="!lst.chkselecttc"> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;" *ngIf="!model.close">
                            <div class=" input-field col s6" style="padding-left: 5px;margin-bottom: 0px;">
                                <textarea [(ngModel)]="model.remark" class="materialize-textarea" #remark="ngModel"
                                    type="text" id="remark" title="Remark" rows="5">
                            </textarea>
                                <label id="lable7" for="remark">Remark</label>
                            </div>
                            <div class=" input-field col s3" *ngIf="model.flag == 'Q'&&model.status != 'O'&&!resendOTP">
                                <button (click)="btnsendquotation()" type="button"
                                    class="waves-effect  waves-light  btn">Send Quotation</button>&nbsp;
                            </div>
                            <div class=" input-field col s3">
                                <button (click)="btnsendquotation()" type="button" *ngIf="resendOTP"
                                    class="waves-effect  waves-light btn">Re Send Quotation</button>&nbsp;
                            </div>

                        </div>
                        <div class="row col l12 m12 s12 " *ngIf="model.close" style="padding-left: 5px;">
                            <div class="input-field col s5"
                                style="margin-top: 17px; padding-left:0px;margin-bottom: 0px;">
                                <textarea [(ngModel)]="model.observationandanalysis" class="materialize-textarea"
                                    #remark="ngModel" type="text" id="remark" title="Observation & Analysis" rows="5">
                        </textarea>
                                <label id="lable7" for="remark" class style="left: 0px;">Observation & Analysis</label>
                            </div>
                            <div class="input-field col s3" style="margin: 0px;">
                                <span>Problem Category</span><br>
                                <select (change)="onSelectlstproblem($event.target.value)" data-toggle="tooltip"
                                    data-placement="top" title="Problem Category">
                                    <option *ngFor="let lst of lstproblem" [value]="lst.id"
                                        [selected]="lst.id==model.problemcategoryid">
                                        {{lst.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row col l12 m12 s12 "
                            style="padding-top: 0px;padding-left: 0px;padding-right: 0px;">
                            <div class="input-field col s2" style="margin: 0px;padding-left: 0px;"
                                *ngIf="forflag!='view'&&!model.closeotpverify">
                                <input [(ngModel)]="model.follow" #checkselect="ngModel"
                                    (click)="OnfollowChecked($event)" type="checkbox" name="Follow"
                                    style="text-align: 20px;">&nbsp;&nbsp;<lable
                                    *ngIf="!model.follow;else other_content">Follow Up</lable>
                                <ng-template #other_content><b *ngIf="model.follow">Follow Up</b></ng-template>

                            </div>
                            <div class="input-field col s2" style="margin: 0px"
                                *ngIf="forflag!='view'&&!model.closeotpverify">
                                <input [(ngModel)]="model.close" #checkselect="ngModel" (click)="Onclosechecked($event)"
                                    type="checkbox" name="Close" style="text-align: 20px; ">&nbsp;&nbsp;<lable
                                    *ngIf="!model.close;else other_content">Close call</lable>
                                <ng-template #other_content><b *ngIf="model.close">Close call</b></ng-template>
                            </div>
                            <div class="input-field col s2" *ngIf="model.close&&forflag!='view'&&!model.closeotpverify"
                                style="margin-top: 5px;margin-bottom: 0px;">
                                <button (click)="btngenrateOTP()" type="button" class="waves-effect waves-light btn">
                                    Generate OTP</button>&nbsp;&nbsp;
                            </div>
                            <div class=" col l4 m4 s4" style="margin-top: 5px;padding-left: 1px;"
                                *ngIf="model.closeotpverify&&model.close">
                                <span style="font-size: 14px;"><span style="color: red;">Service Report Confirmed By:
                                    </span>{{model.contactname}}</span>
                            </div>
                            <div class="input-field col l2 m2 s2"
                                style="text-align: start;margin-top: 5px;margin-bottom: 0px;"
                                *ngIf="model.close&&!model.closeotpverify&&sermodel.closureOTP !=null">
                                <input [(ngModel)]="model.closureOTP" #serviceOTP="ngModel"
                                    (ngModelChange)="verifyClosureOTP()" type="text" id="closureOTP" title="Name"
                                    maxlength="6">
                                <label for="closureOTP">OTP</label>
                            </div>
                            <div class="input-field col l2 m2 s2" style="text-align: start;margin-top: 5px;"
                                *ngIf="model.closeotpverify&&model.close">
                                <span><b>{{model.sendclosureOTP}}</b></span>
                            </div>
                            <div class=" col l4 m4 s4" style="text-align: end;padding: 0px;"
                                *ngIf="model.close&&forflag!='view'&&!model.closeotpverify&&sermodel.closureOTP !=null">
                                <button type="button" *ngIf="genOtp == false" (click)="closureOTP()"
                                    class="btn btn-blue">Show
                                    OTP</button>

                                <!-- <div style="text-align: end;" *ngIf="model.close&&forflag!='view'&&model.closureOTPsend!=null&&!model.closeotpverify">
                                <button type="button" *ngIf="genOtp == false" (click)="closureOTP()" class="btn btn-blue">Show
                                    OTP</button>&nbsp; -->

                                <button type="button" *ngIf="genOtp == true"
                                    class="btn btn-blue"><b>{{sermodel.closureOTP}}</b></button>
                            </div>
                        </div>
                        <div class="row" *ngIf="model.closeotpverify&&model.close">
                            <div class="input-field col l3 m3 s3">
                                <span>Product Category</span>
                                <select (change)="onSelectProCat($event.target.value)" data-toggle="tooltip"
                                    data-placement="top" title="Product Category">
                                    <option selected>Select One</option>
                                    <option *ngFor="let lst of spareProductcatelst" [value]="lst.id"
                                        [selected]="lst.id==pid">
                                        {{lst.name}}</option>
                                </select>
                            </div>
                            <div class="input-field col l4 m4 s4">
                                <span>Product</span>
                                <select data-toggle="tooltip" data-placement="top" title="Products"
                                    (change)="onSelectPro($event.target.value)">
                                    <option selected>Select One</option>
                                    <option *ngFor="let lst of productlst" [value]="lst.pid"
                                        [selected]="lst.pid==pid">
                                        {{lst.description}}</option>
                                </select>
                            </div>


                            <!-- <div class="input-field col l2 m2 s2" *ngIf="currencylst.length>0">
                            <span>Currency</span>
                            <select (change)="onSelectCurrency($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Currency">
                                <option selected>Select One</option>
                                <option *ngFor="let lst of currencylst" [value]="lst.id">
                                    {{lst.name}}</option>
                            </select>
                        </div> -->
                            <div class="col l2 m2 s2"
                                style="margin-top: 30px;text-align: right;padding-right: 0px;">
                                <button (click)="btnAddProduct()" type="button"
                                    class="waves-effect waves-light btn">Add</button>
                            </div>
                        </div>




                        <div class="row" *ngIf="model.closeotpverify&&model.close"
                            style="padding-left: 10px;padding-right: 10px; padding-top: 10px;">
                            <b style="font-size: 15px;">Final Service Invoice</b>
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="5%" style="text-align: left"></th>
                                    <th width="35%" style="text-align: left">Spare Description</th>
                                    <th width="10%" style="text-align: left" *ngIf="this.showgst">GST %</th>
                                    <th width="10%" style="text-align: left">Quantity</th>
                                    <th width="10%" style="text-align: right">UOM</th>
                                    <th width="10%" style="text-align: right">Rate</th>
                                    <th width="15%" style="text-align: right;padding-right: 10px;">Amount</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of model1.lstspare; let i = index">
                                        <td style="text-align: left">
                                            <input [(ngModel)]="lst.checkselect" #checkselect="ngModel" type="checkbox"
                                                name="Select" style="text-align: 20px;" [disabled]="model.invocesend">
                                        </td>
                                        <td style="text-align: left">
                                            <span> {{lst.sparedescription}} </span>
                                        </td>
                                        <td style="text-align: left" *ngIf="this.showgst">
                                            <span> {{lst.gstpersentage}} </span>
                                        </td>
                                        <td style="text-align: left;   padding: 0px;">
                                            <input [(ngModel)]="lst.quantity" #calltime="ngModel" type="number" min=0
                                                id="quantity" (ngModelChange)="onChangeOfPrice(lst.id,lst.discount)" title="Quantity"
                                                maxlength="35" style="text-align: end;" *ngIf="!model.invocesend"
                                                [disabled]="lst.checkselect == false">
                                            <span *ngIf="model.invocesend">{{lst.quantity}}</span>
                                        </td>
                                        <td style="text-align: right">
                                            <span> {{lst.uom}} </span>
                                        </td>
                                        <td style="text-align: right">
                                            <span> {{lst.rate | number: '1.2-2'}} </span>
                                        </td>
                                        <td style="text-align: right;padding-right: 10px">
                                            <span> {{lst.amount | number : '1.2-2'}} </span>
                                        </td>
                                </tbody>
                            </table>
                        </div>
                        <div class="row" *ngIf="model.closeotpverify&&model.close"
                            style="padding-left: 10px;padding-right: 10px;">
                            <div class=" input-field col s6">
                                <span style="font-size: 14px;font-weight: bold">Total - &nbsp;{{model.totalamountwords |
                                    uppercase}}&nbsp;ONLY</span>
                            </div>
                            <div class=" input-field col s6" style=" text-align: right;">
                                <b style="font-size: 14px;"> Sub - Total :&nbsp;&#8377;{{model.total| number :
                                    '1.2-2'}}</b>&nbsp;&nbsp;&nbsp;
                                <b style="font-size: 14px;" *ngIf="this.showgst"> GST
                                    :&nbsp;&#8377;{{model.totalgstamount| number :
                                    '1.2-2'}}</b>&nbsp;&nbsp;&nbsp;
                                <b style="font-size: 14px;"> Total :&nbsp;&#x20b9;{{model.totalamount| number :
                                    '1.2-2'}}</b>

                            </div>
                        </div>
                        <div class="row"  *ngIf="model.closeotpverify&&model.close">
                            <!-- *ngIf="model.showspare" -->
                            <!-- *ngIf="model.chkschemeflag =='Y'" -->
                            <div class="input-field col l3 m3 s3">
                                <input [(ngModel)]="model1.schmemCode" #schmemCode="ngModel" type="text" id="schmemCode"
                                    data-toggle="tooltip" style="text-align: end" data-placement="top"
                                    [disabled]="action=='View'||action=='Decision' || codehide == true" title="Scheme Code" (ngModelChange)="selectSchemeCode(model1.schmemCode)">
                                <label for="schmemCode">Scheme Code</label>
                            </div>
                            <div class="col l1 m1 s1" style="margin-top: 12px;" *ngIf="model1.schmemCode!=null">
                                <span><img src="../../../assets/images/cancel.png" width="50%"
                                        (click)="removeScheme()" /></span>
                            </div>
                            <div class="input-field col l2 m2 s2" *ngIf="schemeModel.discountType == 'Custom' || model1.schemetype == 'Custom'">
                                <input [(ngModel)]="model1.customnumber" #customnumber="ngModel" type="text" id="customnumber"
                                    data-toggle="tooltip" style="text-align: end" data-placement="top" placeholder="CustomNumber">
                            </div>
                            <div class="col l2 m2 s2" style="margin-top: 10px;">
                                <button (click)="btnApplyScheme()" type="button" [disabled]="model1.schmemCode==null"
                                    class="waves-effect waves-light btn">Calculate</button>
                            </div>
                        </div>
                        <div class="row col l12 m12 s12 " style="padding-left: 0px;">
                            <div class="col l6 m6 s6" *ngIf="model.follow" style="padding-left: 5px;">
                                <div class="input-field col l4 m4 s4" style="padding-left: 0px;">
                                    <span>Followup Date</span>
                                    <input type="text" placeholder="Followup Date" [(ngModel)]="model.followupdate"
                                        id="date" class="form-control"
                                        (ngModelChange)="selectFollowupDate(model.followupdate)" bsDatepicker
                                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue',adaptivePosition: true }">
                                </div>
                                <!-- <div class=" input-field col l4 m4 s4">
                                <span>AssignTo</span>
                                <select (change)="onSelectAssignto($event.target.value)">
                                    <option *ngFor="let lst of lstassigned" [value]="lst.id"
                                        [selected]="lst.id==model.followupassingto">
                                        {{lst.name}}</option>
                                </select>
                            </div> -->
                                <div class=" input-field col l4 m4 s4">
                                    <!-- <span>Activity</span><br>
                                <span>{{model.activity}}</span> -->
                                    <span>Activity</span><br>
                                    <select (change)="onSelectfollowupactivity($event.target.value)"
                                        data-toggle="tooltip" data-placement="top" title="Activity">
                                        <option *ngFor="let lst of lstactivity" [value]="lst.id"
                                            [selected]="lst.id==model.followupactivityID">
                                            {{lst.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row col l10 m12 s12 " style="padding: 0px;">
                            <!-- <div class="col l6 m6 s6" style="padding: 0px;"> -->
                            <div class=" row">
                                <!-- <div class="left-align col l3 m4 s4" *ngIf="model.closeotpverify&&model.close"
                                    style="padding-left: 9px;">
                                    <button (click)="btnsendinvoince()" type="button"
                                        class="waves-effect waves-light btn">Send
                                        Invoice</button>&nbsp;&nbsp;
                                </div> -->
                                <!-- <div class=" col l2 m3 s3" *ngIf="model.invocesend&&model.close" style="margin-top: 18px;">
                                    <span style="font-size: 15px;">Payment Status</span>
                                </div>
                                <div class="row col l2 m3 s3" *ngIf="model.invocesend&&model.close" style="margin-top: 18px;">
                                    <input [(ngModel)]="model.cashcheckselect" #cashcheckselect="ngModel"  (click)="cashcodesave()"type="checkbox"
                                        name="Select">&nbsp;Cash Received
                                </div>
                                <div class="row col l2 m3 s3" *ngIf="model.invocesend&&model.close">
                                    <input [(ngModel)]="model.cashreceiverOTP"
                                        #cashreceiverOTP="ngModel" (ngModelChange)="verifyOTP()" type="text"
                                        id="serviceOTP" placeholder="OTP" maxlength="6"> -->
                                <!-- <label for="cashreceiverOTP">Name</label> -->
                                <!-- </div> -->
                            </div>
                            <!-- </div> -->
                        </div>


                        <div class="row">
                            <div class="input-field col l12 m12 s12" style="text-align: right">
                                <button (click)="btnback()" type="button"
                                    class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                                <button (click)="btnsave()" *ngIf="!model.invocesend" type="button"
                                    class="waves-effect waves-light btn">
                                    Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-template #DCRMode>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="registerBgShadow">

            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">Details</span><br>

            </div>
            <div class="row">

                <div class="row" style="padding-left: 9px;padding-right: 9px;padding-bottom: 9px;">
                    <b style="font-size: 15px;">DCR History</b>
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="20%" style="text-align: left;padding-left: 10px;">DCR Date</th>
                            <th width="20%" style="text-align: left">Activity</th>
                            <th width="20%" style="text-align: left">Owner</th>
                            <th width="30%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let history of dcrHistorylst">
                                <td style="text-align: left;padding-left: 10px;">{{history.dcrdate | date:"dd-MM-yyyy"}}
                                </td>

                                <td style="text-align: left">{{history.activity}}</td>
                                <td style="text-align: left">{{history.assignedByName}}</td>
                                <td style="text-align: left">{{history.remarks}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </ng-template>
</div>