<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Production Booking</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%;padding-top: 5px;">
            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <div class="col l12 m12 s12">
                    <div class="row" style="margin-left: 0px;margin-right:0px;">
                        <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 9px;padding-left: 14px;">
                            <span>Search Criteria</span>
                            <select (change)=" onSelect($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col s3 l3 m3" style="text-align: right;margin-top: 26px;">
                            <input type="text" [(ngModel)]="txtserch" #name="ngModel" class="" id="search"
                                data-toggle="tooltip" data-placement="top" title="Search Field">
                            <!-- [(ngModel)]="model.txtserch"txtserch #name="ngModel" -->
                            <label for="search">Search Text</label>
                        </div>

                        <div class=" input-field col s4 l4 m4" style="margin-top: 26px;">
                            <button type="button" (click)="btnsearch()"
                                class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                        </div>
                    </div>
                    <div class="row">

                        <div class="col l12 m12 s12">
                            <div class="col l1">
                            </div>
                            <div class="col l10 m10 s10" style="height: 500px; overflow: auto;">
    
                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="20" style="text-align: center">Production Plan ID</th>
                                        <th width="20" style="text-align: center">Production Date</th>
                                        <th width="10" style="text-align: center">Location</th>
                                        <th width="10" style="text-align: center">Status</th>
                                        <th width="30" style="text-align: center">Actions</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of lstplan">
                                            <td style="text-align: center">{{lst.planID}}</td>
                                            <td style="text-align: center">{{lst.plandate | date :  "dd-MM-yyyy"}}</td>
                                            <td style="text-align: center">{{lst.location}}</td>
                                            <td style="text-align: center">{{lst.status}}</td>
                                            <td style="text-align: center"> <span><img src="../../../assets/images/CreateDocument.png"
                                                        (click)="btncreate(lst.id)" data-toggle="tooltip" data-placement="top"
                                                        title="Create Booking" width="20px" /></span>&nbsp;&nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
    
                            </div>
                        </div>
                        <div class="col l1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>