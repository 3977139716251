import { OnInit, Component } from '@angular/core';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { CustomerTypeWorkflowModel } from './CustomerTypeWorkflowModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
@Component({
    selector: 'app-typeWorkFlow',
    templateUrl: 'CustomerTypeWorkflow.html'
})
export class CustomerTypeWorkflow implements OnInit {
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
        this.pagename = this.router.url
    }
    user = new User();
    model = new CustomerTypeWorkflowModel();
    custTypelst: DropDownModel[] = [];
    designationlst: DropDownModel[] = [];

    companyName: string;
    industryTypeLst: DropDownModel[] = []
    pagename: string;


    ngOnInit(): void {
        this.companyName = localStorage.getItem("companyName");
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getAllCustomertypes();
        this.getAllIndustryTypes()
        this.getDesignation();
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }
    getDesignation() {
        let user = new User();
        this.designationlst = [];
        this.Masterservice.getDept(user)
            .subscribe((value) => {
                this.designationlst = value.desiglst
            });
    }
    getAllCustomertypes() {
        this.custTypelst = []
        this.Masterservice.getAllCustomertypes(this.user)
            .subscribe(value => {
                value.forEach((item) => this.custTypelst.push({ id: item.id, name: item.name }));
            });
    }

    getAllIndustryTypes() {
        this.industryTypeLst = []
        this.Masterservice.getAllIndustryTypes(this.user)
            .subscribe(value => {
                value.forEach((item) => this.industryTypeLst.push({ id: item.id, name: item.name }));
            });
    }

    onselectIndustry(val) {
        if (val == 0) {
            this.notifyService.showRroor("select Industry Type", "Oops!");
            return;
        }

        this.model.industryid = val
    }

    onselectcustype(val) {
        if (val == 0) {
            this.model.typeid = null
            this.notifyService.showRroor("Please select Customer type", "Oops!");
            return;
        }
        this.model.typeid = val
        this.getAlldataBytypeid(val);
    }

    onchksendq($event) {
        if (!$event.target.checked) {
            this.model.chkemail = false;
            this.model.chksms = false;
        }
    }



    onchkSERsendq($event) {
        if (!$event.target.checked) {
            this.model.chkSERemail = false;
            this.model.chkSERsms = false;
        }
    }



    getAlldataBytypeid(typeid) {
        this.user.trnid = typeid;
        this.Masterservice.getAlldataBytypeid(this.user)
            .subscribe(value => {
                this.model = value;
                this.model.userid = Number(localStorage.getItem("userid"));
                if (this.model.ordraccptnce == "Company") {
                    this.model.chkOAbycmpy = true;
                    this.model.chkOAbyndcust = false
                }
                if (this.model.ordraccptnce == "EndCustomer") {
                    this.model.chkOAbycmpy = false;
                    this.model.chkOAbyndcust = true;
                }
                if (this.model.installation == "Company") {
                    this.model.chkINScmpny = true;
                    this.model.chkINScust = false;
                    this.model.chkINSdealer = false;
                    // if (this.model.chkUnitinstallation == true) {
                    //     this.model.chkUnitinstallation = true
                    // } else {
                    //     this.model.chkUnitinstallation = false
                    // }
                    
                    if (this.model.chkINSApprovcmpny == true) {
                        this.model.chkINScmpny = true;
                        this.model.chkINSApprovcmpny = true

                        if (this.model.chkfield == true) {
                            this.model.chkINScmpny = true;
                            this.model.chkINSApprovcmpny = true
                            this.model.chkfield = true
                        } else {
                            this.model.chkfield = false
                          
                        }
                        if (this.model.chksupervisor == true) {
                            this.model.chkINScmpny = true;
                            this.model.chkINSApprovcmpny = true
                            this.model.chksupervisor = true
                        } else {
                            this.model.chksupervisor = false
                        
                        }

                    } else {
                        this.model.chkINSApprovcmpny = false
                       
                    }
                   
                    if (this.model.chkCustomer == true) {
                        this.model.chkINScmpny = true;
                        this.model.chkCustomer = true
                    } else {
                        this.model.chkCustomer = false
                       
                    }
                }
                if (this.model.installation == "Customer") {
                    this.model.chkINScmpny = false;
                    this.model.chkINScust = true;
                    this.model.chkINSdealer = false;
                  
                }
                if (this.model.installation == "Dealer") {
                    this.model.chkINScmpny = false;
                    this.model.chkINScust = false;
                    this.model.chkINSdealer = true;
                  
                }

                if (this.model.service == "Company") {
                    this.model.chkSERcmpny = true;
                    this.model.chkSERcust = false;
                    this.model.chkSERdealer = false
                }
                if (this.model.service == "Customer") {
                    this.model.chkSERcmpny = false;
                    this.model.chkSERcust = true;
                    this.model.chkSERdealer = false;

                    this.model.chkSERTC = false;
                    this.model.chkSERsendQuo = false;
                    this.model.chkSERshwgst = false;
                    this.model.chkSERemail = false;
                    this.model.chkSERsms = false
                }
                if (this.model.service == "Dealer") {
                    this.model.chkSERcmpny = false;
                    this.model.chkSERcust = false;
                    this.model.chkSERdealer = true;

                    this.model.chkSERTC = false;
                    this.model.chkSERsendQuo = false;
                    this.model.chkSERshwgst = false;
                    this.model.chkSERemail = false;
                    this.model.chkSERsms = false
                }

                if (this.model.payment == "Against Order") {
                    this.model.chkagnstOr = true;
                    this.model.chkagnstInv = false;
                    this.model.chkagnstInst = false;
                }
                if (this.model.payment == "Against Invoice") {
                    this.model.chkagnstOr = false;
                    this.model.chkagnstInv = true;
                    this.model.chkagnstInst = false;
                }
                if (this.model.payment == "Against Installation") {
                    this.model.chkagnstOr = false;
                    this.model.chkagnstInv = false;
                    this.model.chkagnstInst = true;
                }
            });
    }
    onchkfield($event) {
        this.model.chksupervisor = false

       
        this.model.chkfield = true
        this.model.fieldflag = "Field";

        this.model.chkINSApprovcmpny = true
        this.model.chkINScmpny = true;
        this.model.supervisorflag = null;

    }
    onchksupervisor($event) {
        this.model.chkfield = false

     
        this.model.chksupervisor = true
        this.model.supervisorflag = "Supervisor";

        this.model.chkINSApprovcmpny = true
        this.model.chkINScmpny = true;
        this.model.fieldflag = null;


    }
    onchkCustomer($event) {
        this.model.chkINSApprovcmpny = false

        this.model.chkCustomer = true
        this.model.chkINScmpny = true;
        this.model.customerflag = "Customer";

       
        this.model.easydryflag = null;

    }
    onchkINSApprovecmpny($event) {
        this.model.chkCustomer = false
      
        this.model.chkINSApprovcmpny = true
        this.model.chkINScmpny = true;
        this.model.easydryflag = "Company";

       
        this.model.customerflag = null;


    }

    onchkOAbycmpy($event) {
        this.model.chkOAbycmpy = true;
        this.model.chkOAbyndcust = false
        this.model.ordraccptnce = "Company"
    }

    onchkOAbyndcust($event) {
        this.model.chkOAbycmpy = false;
        this.model.chkOAbyndcust = true;
        this.model.chkSupplier = false;

        this.model.ordraccptnce = "EndCustomer"
    }

    //GROUP2
    onchkINScmpny($event) {
        this.model.chkINScmpny = true;
        this.model.chkINScust = false;
        this.model.chkINSdealer = false
        this.model.installation = "Company";
    }
    onchkINScust($event) {
        this.model.chkINScmpny = false;
        this.model.chkINScust = true;
        this.model.chkINSdealer = false;
        this.model.chkagnstInst = false;
        this.model.installation = "Customer";
        if (this.model.payment == "Against Installation") {
            this.model.payment = null;
        }

    }
    onchkINSdealer($event) {
        this.model.chkINScmpny = false;
        this.model.chkINScust = false;
        this.model.chkINSdealer = true;
        this.model.chkagnstInst = false;
        this.model.installation = "Dealer";
        if (this.model.payment == "Against Installation") {
            this.model.payment = null;
        }

    }

    //GROUP3
    onchkSERcmpny($event) {
        this.model.chkSERcmpny = true;
        this.model.chkSERcust = false;
        this.model.chkSERdealer = false
        this.model.service = "Company";
    }
    onchkSERcust($event) {
        this.model.service = "Customer";

        this.model.chkSERcmpny = false;
        this.model.chkSERcust = true;
        this.model.chkSERdealer = false;

        this.model.chkSERTC = false;
        this.model.chkSERsendQuo = false;
        this.model.chkSERshwgst = false;
        this.model.chkSERemail = false;
        this.model.chkSERsms = false

    }
    onchkSERdealer($event) {
        this.model.service = "Dealer";

        this.model.chkSERcmpny = false;
        this.model.chkSERcust = false;
        this.model.chkSERdealer = true;

        this.model.chkSERTC = false;
        this.model.chkSERsendQuo = false;
        this.model.chkSERshwgst = false;
        this.model.chkSERemail = false;
        this.model.chkSERsms = false
    }

    //Group4
    onchkagnstOr($event) {
        this.model.chkagnstOr = true
        this.model.chkagnstInst = false;
        this.model.chkagnstInv = false;
        this.model.payment = "Against Order"
    }
    onchkagnstInv($event) {
        this.model.chkagnstOr = false
        this.model.chkagnstInst = false;
        this.model.chkagnstInv = true;
        this.model.payment = "Against Invoice"
    }
    onchkagnstInst($event) {
        this.model.chkagnstOr = false
        this.model.chkagnstInst = true;
        this.model.chkagnstInv = false;
        this.model.payment = "Against Installation"
    }
    onselectDesignation(val) {
        if (val == 0) {
            this.notifyService.showRroor("select Designation", "Oops!");
            return;
        }
        this.model.designationid = val
    }

    btnsaveWorkflow() {
        if (this.model.chksendQuo == true) {
            if (this.model.chkemail == false && this.model.chksms == false) {
                this.notifyService.showRroor("Please select one option in Sales for send quotation", "Oops!");
                return;
            }
        }
        if (this.model.ordraccptnce == null) {
            this.notifyService.showRroor("Please select order acceptance", "Oops!");
            return;
        }
        if (this.model.installation == null) {
            this.notifyService.showRroor("Please select installation", "Oops!");
            return;
        }
        if (this.model.service == null) {
            this.notifyService.showRroor("Please select service", "Oops!");
            return;
        }
        if (this.model.chkSERsendQuo == true) {
            if (this.model.chkSERsms == false && this.model.chkSERemail == false) {
                this.notifyService.showRroor("Please select one option in Service for send quotation", "Oops!");
                return;
            }
        }

        this.Masterservice.saveCustomerTypeWorkflow(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['/landpage']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }


    btnback() {
        this.router.navigate(['/landpage']);
    }

}