<app-header></app-header>
<div class="container" style="width:60%">
    <div class="row">
        <div class="col l2"></div>
        <div class="heading col l8">
            <span style="font-size: 25px;">{{pagename}}</span>
        </div>
        <div class="col l2"></div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row" style="margin-top:10px">
                <div class="col l2">
                </div>
                <div class="input-field col l4" style="margin-top: 9px;">
                    <span>User</span>
                    <select (change)="onSelectlstuser($event.target.value)">
                        <!-- <option selected value="0">Select User</option> -->
                        <option *ngFor="let type of lstuser" [value]="type.id">
                            {{type.name}} </option>
                    </select>
                </div>

                <div class="input-field col l5 m5 s12" style="text-align: right; margin-top: 26px;">
                    <input [(ngModel)]="model.pass" #pass="ngModel" type="text" class="" data-toggle="tooltip"
                        appForbiddenName="bob" data-placement="top" title="Password" id="password" name="password"
                        maxlength="20" required>
                    <label id="lable3" for="password">Password</label>
                    <div class="col l1"></div>
                </div>
            </div>


            <div class="row" style="padding-bottom: 5px;">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button type="button" class="waves-effect cancelbutton btn" style="margin-left: 5px;"
                        (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave()" class="waves-effect waves-light btn"
                       >Save</button>
                </div>
            </div>
        </div>
    </div>
</div>