<div class="container" style="width: 90%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m12 s12" style="padding-left: 0px;padding-right: 0px;">
                <!-- <span style="font-size: 25px;">{{formname}}</span> -->
                <span style="font-size: 100%; font-weight: bold">{{model.companyname}}<span></span></span>
                <div>
                    <span style="font-size: 85%; font-weight: inherit">{{model.companyaddress}}&nbsp;Phone No:&nbsp;{{model.telephone}}<span></span></span>
                    <!-- <span style="font-size: 25px;">{{formtype}}</span> -->
                </div>
                <!-- <span style="font-size: 25px;">{{formtype}}</span> -->
            </div>
            <!-- <div class="col l2 m2 s2"></div> -->
        </div>
        <!-- <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 100%; font-weight: bold">{{model.companyaddress}}<span></span></span>
                <span style="font-size: 25px;">{{formtype}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div> -->
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="col l12 m12 s12">
                <div class="row">
                    <div class="col l2 m2 s2"></div>
                    <div class="heading col l8 m8 s8">
                        <!-- <span style="font-size: 100%; font-weight: bold">{{model.companyname}}<span></span></span> -->
                        <span style="font-size: 100%; font-weight: bold">{{formname}}</span>
                    </div>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12">

                <div class="row" style="margin-top: 2%">

                    <div class="row">
                        <div class="col l12 m12 s12" *ngIf="formtype=='Invoice'">
                            <span style="font-size: 90%;font-weight: bold">Invoice #:&nbsp;</span><span>{{model.invoiceno}}</span>
                        </div>
                        <div class="col l12 m12 s12" *ngIf="formtype=='INSInvoice'">
                            <span style="font-size: 90%;font-weight: bold">Invoice #:&nbsp;</span><span>{{model.invoiceno}}</span>
                        </div>
                        <div class="col l12 m12 s12" *ngIf="formtype=='Sales Quotation'">
                            <span style="font-size: 90%;font-weight: bold">Quotation #:&nbsp;</span><span>{{model.quoCode}}</span>
                        </div>
                        <div class="col l12 m12 s12" *ngIf="formtype=='Service Quotation'">
                            <span style="font-size: 90%;font-weight: bold">Quotation #:&nbsp;</span><span>{{model.quoCode}}</span>
                        </div>
                        <div class="col l12 m12 s12">
                            <span style="font-size: 90%;font-weight: bold">Date:&nbsp;</span><span>{{model.datetime
                                |date:"dd-MM-yyyy"}}</span>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 90%;font-weight: bold">Customer
                                Name:&nbsp;</span>
                            <span>{{model.accname}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 90%;font-weight: bold">Address:&nbsp;</span>
                            <span>{{model.address}}</span>
                        </div>
                    </div>
                    <!-- <div class="row">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 90%;font-weight: bold">Payment
                                    Terms:&nbsp;</span><span>{{model.paymentTerms}}</span>
                            </div>
                        </div> -->
                    <!-- </div> -->
                </div>

                <div class="row" style="border-bottom: 1px groove;">

                    <!--Sales QUOTATION TABLE -->
                    <div class="col l12 m12 s12" style="background-color: gainsboro;padding: 5px;"
                        *ngIf="formtype=='Sales Quotation'">
                        <div *ngFor="let lst of productEnquirylst">
                            <div class="col l12 m12 s12" style="background-color: aliceblue">
                                <div class="row">
                                    <div class="col l6 m6 l6" style="text-align: left; margin-top: 1%;">
                                        <span style="font-size: 15px;">{{lst.name}}</span>
                                    </div>
                                    <!-- <div class="col l3 m3 s3"></div> -->
                                    <!-- <div class="col l6 m6 s6" style="text-align: right">
                                        <span style="font-size: 15px;">Total Product Amount</span><br />
                                        <span style="font-size: 15px;">{{lst.totalproamount | number:'1.2-2'}}</span>
                                    </div> -->
                                </div>
                            </div>

                            <table>
                                <thead>
                                    <th width="10%" style="text-align: right">Quantity</th>
                                    <th width="10%" style="text-align: right">UOM</th>
                                    <th width="20%" style="text-align: right">Price</th>
                                    <!-- <th width="20%" style="text-align: right">Discount Offered</th> -->
                                    <th width="20%" style="text-align: right" *ngIf="model.gstseperate==true">Tax Amount
                                    </th>
                                    <!-- <th width="20%" style="text-align: right">Offered Price</th> -->
                                    <th width="20%" style="text-align: right">Amount</th>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="10%" style="text-align: right;font-size: 15px;">{{lst.quantity}}</td>
                                        <td width="10%" style="text-align: right;font-size: 15px">{{lst.uom}}</td>
                                        <td width="20%" style="text-align: right;font-size: 15px;">
                                            {{lst.offeredPrice | number:'1.2-2'}}</td>
                                        <!-- <td width="20%" style="text-align: right;font-size: 15px">{{lst.discount}}
                                        </td> -->
                                        <td width="20%" style="text-align: right;font-size: 15px;"
                                            *ngIf="model.gstseperate==true">
                                            {{lst.taxamount | number:'1.2-2'}}</td>
                                        <td width="20%" style="text-align: right; font-size: 15px;">
                                            {{lst.totalproamount | number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!--Service QUOTATION TABLE -->
                    <div class="col l12 m12 s12" style="background-color: gainsboro;padding: 5px;"
                        *ngIf="formtype=='Service Quotation'">
                        <div *ngFor="let lst of productEnquirylst">
                            <div class="col l12 m12 s12" style="background-color: aliceblue">
                                <div class="row">
                                    <div class="col l6 m6 l6" style="text-align: left; margin-top: 1%;">
                                        <span style="font-size: 15px;">{{lst.name}}</span>
                                    </div>
                                </div>
                            </div>

                            <table>
                                <thead>
                                    <th width="10%" style="text-align: right">Quantity</th>
                                    <th width="10%" style="text-align: right">UOM</th>
                                    <th width="20%" style="text-align: right">Price</th>
                                    <th width="20%" style="text-align: right" *ngIf="model.gstseperate==true">Tax Amount
                                    </th>
                                    <th width="20%" style="text-align: right">Amount</th>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="10%" style="text-align: right;font-size: 15px;">{{lst.quantity}}</td>
                                        <td width="10%" style="text-align: right;font-size: 15px">{{lst.uom}}</td>
                                        <td width="20%" style="text-align: right;font-size: 15px;">
                                            {{lst.offeredPrice | number:'1.2-2'}}</td>
                                        <!-- <td width="20%" style="text-align: right;font-size: 15px">{{lst.discount}}
                                    </td> -->
                                        <td width="20%" style="text-align: right;font-size: 15px;"
                                            *ngIf="model.gstseperate==true">
                                            {{lst.taxamount | number:'1.2-2'}}</td>
                                        <td width="20%" style="text-align: right; font-size: 15px;">
                                            {{lst.totalproamount | number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <!-- SERVICE Invoice TABLE -->
                    <div class="col l12 m12 s12" *ngIf="formtype=='Invoice'" style="padding: 0px;">
                        <table>
                            <thead style="background-color: gainsboro;">
                                <th width="51%" style="text-align: left;">Spare Description</th>
                                <!-- <th width="10%" style="text-align: center">GST</th> -->
                                <th width="9%" style="text-align: center">Qty.</th>
                                <th width="10%" style="text-align: left">UOM</th>
                                <th width="10%" style="text-align: center">Rate</th>
                                <th width="20%" style="text-align: center">Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of sparelst">
                                    <td style="text-align: left;">{{lst.sparedescription}}</td>
                                    <!-- <td style="text-align: right">{{lst.gstpersentage | number:'1.2-2'}}</td> -->
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <!-- <td style="text-align: right">{{lst.rate | number:'1.2-2'}}</td> -->
                                    <td style="text-align: right">{{lst.rateWithGst | number:'1.2-2'}}</td>

                                    <td style="text-align: right">{{lst.amount | number:'1.2-2'}}</td>

                                </tr>
                            </tbody>
                        </table>
                        <div class="col l8 m8 s6">
                            <span style="font-size: 90%; font-weight: bold">Amount In
                                Words:<br><span>{{amountInWords | uppercase}}</span>&nbsp;ONLY</span>

                        </div>
                        <div class="right-align col l4 m4 s6">
                            <span style="font-size: 90%; font-weight: bold">
                                <!-- <span>Sub-Total:&nbsp;&#8377;&nbsp;<span>{{totalamount |
                                        number:'1.2-2'}}</span></span><br> -->
                                <!-- <span>GST:&nbsp;&#8377;&nbsp;<span>{{taxamount |  number:'1.2-2'}}</span></span><br> -->
                                <span>Total :&nbsp;&#8377;&nbsp;<span>{{finalamount | number:'1.2-2'}}</span></span>
                            </span>
                        </div>
                    </div>

                    <!-- INSTALLATION TABLE -->
                    <div class="col l12 m12 s12" *ngIf="formtype=='INSInvoice'" style="padding: 0px;">

                        <table>
                            <thead style="background-color: gainsboro;">
                                <th width="40%" style="text-align: left;">Product Name</th>
                                <!-- <th width="10%" style="text-align: center">GST</th> -->
                                <th width="10%" style="text-align: center">Quantity</th>
                                <th width="10%" style="text-align: left">UOM</th>
                                <th width="10%" style="text-align: center">Rate</th>
                                <th width="20%" style="text-align: center">Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of sparelst">
                                    <td style="text-align: left;">{{lst.sparedescription}}</td>
                                    <!-- <td style="text-align: right">{{lst.gstpersentage | number:'1.2-2'}}</td> -->
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <td style="text-align: right">{{lst.rateWithGst | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.amount | number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="col l8 m8 s6">
                            <span style="font-size: 90%; font-weight: bold">Amount In
                                Words:<br><span>{{amountInWords | uppercase}}</span>&nbsp;ONLY</span>

                        </div>
                        <div class="right-align col l4 m4 s6">
                            <span style="font-size: 90%; font-weight: bold">
                                <span>Sub-Total:&nbsp;&#8377;&nbsp;<span>{{totalamount |
                                        number:'1.2-2'}}</span></span><br>
                                <!-- <span>GST:&nbsp;&#8377;&nbsp;<span>{{model.gstamount |
                                        number:'1.2-2'}}</span></span><br> -->
                                <span>Total:&nbsp;&#8377;&nbsp;<span>{{finalamount | number:'1.2-2'}}</span></span>
                            </span>
                        </div>
                    </div>


                    <!-- SERVICE Quotation TABLE -->
                    <div class="col l12 m12 s12" *ngIf="formtype=='Service Quotation'" style="padding: 0px;">
                        <table>
                            <thead style="background-color: gainsboro;">
                                <th style="text-align: left;">Spare Description</th>
                                <th  style="text-align: center" *ngIf="model.gstseperate==true">GST</th>
                                <th style="text-align: right">Qty</th>
                                <th  style="text-align: left">UOM</th>
                                <th  style="text-align: right">Rate</th>
                                <th  style="text-align: right">Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of serviceQuotationlst">
                                    <td style="text-align: left;">{{lst.sparedescription}}</td>
                                    <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.taxamount | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <td style="text-align: right" *ngIf="model.gstseperate==false">{{lst.rateWithGst | number:'1.2-2'}}</td>
                                    <td style="text-align: right" *ngIf="model.gstseperate==true">{{lst.listprice | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.amount | number:'1.2-2'}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <div class="col l8 m8 s6">
                            <span style="font-size: 90%; font-weight: bold">Amount In
                                Words:<br><span>{{amountInWords | uppercase}}</span>&nbsp;ONLY</span>

                        </div>
                        <div class="right-align col l4 m4 s6">
                            <span style="font-size: 90%; font-weight: bold">
                                <span>Sub-Total:&nbsp;&#8377;&nbsp;<span>{{model.subtotal}}</span></span><br>
                                <span>GST:&nbsp;&#8377;&nbsp;<span>{{model.gstamount}}</span></span><br>
                                <span>Total:&nbsp;&#8377;&nbsp;<span>{{model.grandtotal}}</span></span>
                            </span>
                        </div>-->
                    </div>
                </div>

                <!--Sales QUOTATION DIVs -->
                <div class="row" style="background-color: gainsboro" *ngIf="formtype=='Sales Quotation'">
                    <!-- <div class="col l8 m8 s8" *ngFor="let lst of productEnquirylst">
                        <span style="font-size: 90%; font-weight: bold">Discount
                            Amount:&nbsp;&#8377;<span>{{discountAmt | number:'1.2-2'}}</span></span>
                    </div> -->
                    <div class="col l8 m8 s8">
                        <span style="font-size: 90%; font-weight: bold">Amount In
                            Words:<br><span>{{amountInWords | uppercase}}</span>&nbsp;ONLY</span>
                    </div>
                    <div class="col l4 m4 s4" style="text-align: right;">
                        <span style="font-size: 90%; font-weight: bold">Total Amount:<br>&#8377;<span>{{totalamount |
                                number:'1.2-2'}}</span></span>
                    </div>
                    <!-- <div class="col l8 m8 s8" *ngFor="let lst of productEnquirylst">
                        <span style="font-size: 90%; font-weight: bold">Discount
                            Offered:&nbsp;&#8377;<span>{{lst.discount | number:'1.2-2'}}</span></span>
                    </div> -->
                </div>
                <div class="row" *ngIf="formtype=='Sales Quotation'">
                    <div class="col l5 m6 s12">
                        <span style="font-size: 90%; font-weight: bold;margin-top: 5px">Applied Scheme
                            Code:</span>&nbsp;<span>{{model.schmemCode}}</span>
                    </div>
                    <div class="input-field col l1 m1 s1"
                        style="text-align: right;margin-top: 5px;padding-left: 5px;padding-top: 1px;">
                        <input type="checkbox" [(ngModel)]="model.chkterms" #chkterms="ngModel">
                    </div>
                    <div class="col l3 m5 s11" style="margin-top: 5px;padding-right: 0px;">
                        <span style="font-size: 90%; font-weight: bold">I Agree to the Terms and Conditions</span>
                    </div>
                    <!-- <div class="col l3 m12 s12" style="padding-right:0px;text-align: right;"
                        *ngIf="model.approvalCode==null&& formtype=='Sales Quotation'">
                        <button type="button" (click)="approveQuot()" [disabled]="model.chkterms==false"
                            class="waves-effect  waves-light btn">Approve
                            Quotation</button>
                    </div> -->
                </div>
                <!-- <div class="row" *ngIf="model.approvalCode==null && formtype=='Service Quotation'">
                    <div class="col l12 m12 s12" style="padding-right:0px;text-align: right;">
                        <button type="button" (click)="approveSerQuot()" [disabled]="model.chkterms==false"
                            class="waves-effect  waves-light btn">Approve
                            Quotation</button>
                    </div>
                </div> -->


                <!--SERVICE QUOTATION DIVs -->
                <div class="row" style="background-color: gainsboro" *ngIf="formtype=='Service Quotation'">
                    <div class="col l8 m8 s8">
                        <span style="font-size: 90%; font-weight: bold">Amount In
                            Words:<br><span>{{amountInWords | uppercase}}</span>&nbsp;ONLY</span>
                    </div>
                    <div class="col l4 m4 s4" style="text-align: right;">
                        <span style="font-size: 90%; font-weight: bold" *ngIf="model.gstseperate==true">Sub-Total:&nbsp;&#8377;&nbsp;<span>{{totalamount
                                | number:'1.2-2'}}</span></span><br>
                        <span style="font-size: 90%; font-weight: bold" *ngIf="model.gstseperate==true">GST:&nbsp;&#8377;&nbsp;<span>{{taxamount |
                                number:'1.2-2'}}</span></span><br>
                        <span style="font-size: 90%; font-weight: bold">Total Amount:&nbsp;&#8377;<span>{{finalamount |
                                number:'1.2-2'}}</span></span>
                    </div>
                    <!-- <div class="col l8 m8 s8">
                        *ngFor="let lst of serviceQuotationlst"
                        <span style="font-size: 90%; font-weight: bold">Discount
                            Offered:&nbsp;&#8377;<span>{{discountAmt | number:'1.2-2'}}</span></span>
                    </div> -->
                </div>
                <div class="row" *ngIf="formtype=='Service Quotation'">
                    <!-- <div class="col l5 m6 s12">
                        <span style="font-size: 90%; font-weight: bold;margin-top: 5px">Applied Scheme
                            Code:</span>&nbsp;<span>{{model.schmemCode}}</span>
                    </div> -->
                    <!-- <div class="input-field col l1 m1 s1"
                        style="text-align: right;margin-top: 5px;padding-left: 5px;padding-top: 1px;">
                        <input type="checkbox" [(ngModel)]="model.chkterms" #chkterms="ngModel">
                    </div> -->
                    <!-- <div class="col l3 m5 s11" style="margin-top: 5px;padding-right: 0px;">
                        <span style="font-size: 90%; font-weight: bold">I Agree to the Terms and Conditions</span>
                    </div> -->
                    <!-- <div class="col l3 m12 s12" style="padding-right:0px;text-align: right;"
                        *ngIf="model.approvalCode==null">
                        <button type="button" (click)="approveQuot()"
                            [disabled]="model.chkterms==false && model.approvalCode!= null"
                            class="waves-effect  waves-light btn">Approve
                            Quotation</button>
                    </div> -->
                </div>



                <div class="row" *ngIf="formtype=='Sales Quotation'">
                    <div class="col l8 m8 s12" style="padding-top: 5px;">
                        <span style="font-size: 90%; font-weight: bold">The Approval Number
                            is :</span>&nbsp;<span><b>{{model.approvalCode}}</b></span><br>

                        <span style="font-size: 90%; font-weight: bold">Thank You! Our Service Technician Will Contact
                            You to Schedule the Installation.</span>
                    </div>
                    <!-- <div class="right-align col l4 m4 s4" style="padding-right:0px;">
                        <button type="button" (click)="approveQuot()" [disabled]="model.chkterms==false"
                            class="waves-effect  waves-light btn">Approve
                            Quotation</button>
                    </div> -->
                </div>


                <!--SERVICE QUOTATION DIVs -->
                <div class="row" *ngIf="formtype=='Service Quotation'">
                    <div class="col l8 m8 s12" style="padding-top: 5px;">
                        <!-- <span style="font-size: 90%; font-weight: bold">The Approval Number
                            is :</span>&nbsp;<span><b>{{model.approvalCode}}</b></span><br> -->

                        <span style="font-size: 90%; font-weight: bold">Thank You! Our Service Technician Will Start the
                            Repairs</span>
                    </div>
                </div>


                <!-- SERVICE Invoice DIVs -->
                <!-- <div class="row" *ngIf="formtype=='Invoice'" style="border-bottom: 1px groove;    margin-top: 5px;">
                    <div class="col l11 m11 s9">
                        <span style="font-size: 90%; font-weight: bold">We thank you for giving us this Opportunity to
                            serve you!</span><br>
                        <span style="font-size: 90%; font-weight: bold">Please Pay Using this <b>Pay</b> Button</span>
                    </div>
                    <div class="col l1 m1 s3" style="text-align: right;padding-bottom: 5px;padding-right: 0px;">
                        <button type="button" class="waves-effect waves-light  btn" (click)="pay()">Pay</button>
                    </div>
                </div> -->

                <!-- INSTALLATION INSInvoice DIVs -->
                <!-- <div class="row" *ngIf="formtype=='INSInvoice'" style="border-bottom: 1px groove;    margin-top: 5px;">
                    <div class="col l11 m11 s9">
                        <span style="font-size: 90%; font-weight: bold">We thank you for giving us this Opportunity to
                            serve you!</span><br>
                        <span style="font-size: 90%; font-weight: bold">Please Pay Using this <b>Pay</b> Button</span>
                    </div>
                    <div class="col l1 m1 s3" style="text-align: right;padding-bottom: 5px;padding-right: 0px;">
                        <button type="button" class="waves-effect waves-light  btn" (click)="pay()">Pay</button>
                    </div>
                </div> -->

                <!-- <div class="row" *ngIf="formtype=='Invoice'">
                    <div class="col l8 m8 s8">
                        <span style="font-size: 15px; font-weight: bold">In case you have paid cash, please ask our
                            technician to share the cash code</span>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 10px;">
                        <input  [(ngModel)]="model.cashcode" #cashcode="ngModel" type="text" id="cashcode">
                        <label for="cashcode">Cash Code</label>
                    </div>
                    <div class="col l1 m1 s1" style="text-align: right">
                        <button type="button"  (click)="SubmitcashCode()" class="waves-effect waves-light  btn">Submit</button>
                    </div>
                </div> -->



                <!-- SERVICE Invoice DIVs -->
                <!-- <div class="row" *ngIf="formtype=='Invoice'">
                    <div class="col l12 m12 s12">
                        <span style="font-size: 90%; font-weight: bold">Enter the details below, If you Pay by
                            Cheque.</span>
                    </div>
                    <div class="col l11 m12 s12" style="padding: 0px;">
                        <div class="input-field col l5 m6 s12">
                            <input type="text" id="bankname" [(ngModel)]="model.bankname" #bankname="ngModel">
                            <label for="bankname">Bank Name</label>
                        </div>
                        <div class="input-field col l3 m6 s12">
                            <input type="text" id="chequeno" [(ngModel)]="model.chequeno" #chkterms="ngModel">
                            <label for="chequeno">Cheque No.</label>
                        </div>
                        <div class="input-field col l2 m6 s6">
                            <input type="text" id="chequedate" [(ngModel)]="model.chequedate" #chequedate="ngModel">
                            <label for="date">Date</label>
                        </div>
                        <div class="input-field col l2 m6 s6">
                            <input type="text" id="chamount" [(ngModel)]="model.chamount" #chamount="ngModel">
                            <label for="chamount">Amount</label>
                        </div>
                    </div>
                    <div class="col l1 m12 s12" style="padding: 0px;text-align: right;">
                        <div class="input-field col l1 m12 s12"
                            style="text-align: right;padding-left: 6px;padding-right: 0px;">
                            <button type="button" class="waves-effect waves-light  btn"
                                (click)="update()">Update</button>
                        </div>
                    </div>

                </div> -->

                <!-- <div class="row" *ngIf="formtype=='INSInvoice'">
                    <div class="col l12 m12 s12">
                        <span style="font-size: 90%; font-weight: bold">Enter the details below, If you Pay by
                            Cheque.</span>
                    </div>
                    <div class="col l11 m12 s12" style="padding: 0px;">
                        <div class="input-field col l5 m6 s12">
                            <input type="text" id="bankname" [(ngModel)]="model.bankname" #bankname="ngModel">
                            <label for="bankname">Bank Name</label>
                        </div>
                        <div class="input-field col l3 m6 s12">
                            <input type="text" id="chequeno" [(ngModel)]="model.chequeno" #chkterms="ngModel">
                            <label for="chequeno">Cheque No.</label>
                        </div>
                        <div class="input-field col l2 m6 s6">
                            <input type="text" id="chequedate" [(ngModel)]="model.chequedate" #chequedate="ngModel">
                            <label for="date">Date</label>
                        </div>
                        <div class="input-field col l2 m6 s6">
                            <input type="text" id="chamount" [(ngModel)]="model.chamount" #chamount="ngModel">
                            <label for="chamount">Amount</label>
                        </div>
                    </div>
                    <div class="col l1 m12 s12" style="padding: 0px;text-align: right;">
                        <div class="input-field col l1 m12 s12"
                            style="text-align: right;padding-left: 6px;padding-right: 0px;">
                            <button type="button" class="waves-effect waves-light  btn"
                                (click)="update()">Update</button>
                        </div>
                    </div>

                </div> -->


                <div class="row">
                    <div class="col l6 m6 s6" *ngIf="formtype=='Invoice' || formtype=='INSInvoice'"
                        style="text-align: left; padding-left:0px;padding-bottom:5px;">
                        <span style="font-size: 9px;">* inclusive of GST</span><br>
                        <span style="font-size: 9px;">GST Number:&nbsp;{{model.gstno}}</span>
                    </div>
                    <div class="col l6 m6 s6" *ngIf="formtype=='Invoice' || formtype=='INSInvoice'"
                        style="text-align: right; padding-left:0px;padding-bottom:5px;">
                        <button type="button" class="waves-effect cancelbutton btn" (click)="cancel()">Close</button>

                    </div>

                    <div class="col l12 m12 s12" *ngIf="formtype=='Sales Quotation' || formtype=='Service Quotation'"
                        style="text-align: right; padding-left:0px;padding-bottom:5px;">
                        <button type="button" class="waves-effect cancelbutton btn" (click)="cancel()">Close</button>

                    </div>
                    <!-- <div class="col l8 m8 s8" style="text-align: right;padding-right:0px;padding-bottom:5px;"
                        *ngIf="formtype=='Sales Quotation'&&model.approvalCode!=null">
                        <button type="button" class="waves-effect  waves-light btn" (click)="makePayment()">Make
                            Payment</button>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</div>