import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { DefineProductcategoryModel } from "./DefineProductcategoryModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineProductcategory.html'
})
export class DefineProductcategory implements OnInit {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) { }

    model = new DefineProductcategoryModel();
    // model :any={};
    itemSubCatlst: DefineProductcategoryModel[] = [];
    id: number;
    sub: any;
    p: any;
    user = new User();


    ngOnInit(): void {

        this.getAllitemSubCategory();
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getProductCateByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.itemSubCatlst = data.itemSubCatlst
                    },
                    error => {
                        this.alertService.error(error);
                    });
        }
    }
    getAllitemSubCategory() {
        this.Masterservice.getAllitemSubCategory(this.model)
            .subscribe(data => {
                this.itemSubCatlst = data
            })
    }

    btnsave($event) {
        this.model.itemSubCatlst = this.itemSubCatlst;

        // let i = 0;
        // alert(this.model.itemSubCatlst[i].itemsubcategoryid);

        if (this.model.productCategoryCode == null || this.model.productCategoryCode == "") {
            this.notifyService.showRroor("Enter Product Category Code !!", "Oops!");
            return;
        }
        if (this.model.productCategoryName == null || this.model.productCategoryName == "") {
            this.notifyService.showRroor("Enter Product Category Name!!", "Oops!");
            return;
        }
        if (this.model.description == null || this.model.description == "") {
            this.notifyService.showRroor("Enter Description !!", "Oops!");
            return;
        }
        // if (this.model.noOfAMCVisit == null) {
        //     this.notifyService.showRroor("Enter No.Of AMC Visit!!", "Oops!");
        //     return;
        // }
        // if (this.model.noOfInstVisit == null) {
        //     this.notifyService.showRroor("Enter No.Of Installation Visit!!", "Oops!");
        //     return;
        // }                             
        if (this.model.discount == null) {
            this.notifyService.showRroor("Enter Discount !!", "Oops!");
            return;
        }

        this.Masterservice.saveproductCat(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['Manage Category']);
                    } else {
                        if (data.message == null) {
                            this.notifyService.showRroor("Something wrong", "Oops !")
                        } else {
                            this.notifyService.showRroor(data.message, "Oops !")
                        }
                    }
                },
                error => {
                    alert("Something Wrong ")
                });


    }
    cancle() {
        this.router.navigate(["/Manage Category"]);
    }
}
