import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CompanyService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { DefineResourceAllowanceModel, AllowanceList } from './DefineResourceAllowanceModel';
import { User } from 'src/app/_models/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
declare var $: any;
@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineResourceAllowance.html'
})
export class DefineResourceAllowance implements OnInit,OnDestroy {
    modalRef: BsModalRef;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private notifyService: NotificationService) {
    }
    userList2: any;
    user = new User();
    model = new DefineResourceAllowanceModel();
    allowancelst: AllowanceList[] = [];
    id: number;
    resname: string;
    linkedlst: LinkedAllowanceMode[] = [];
    linkedid: number;
    linkedids: string[] = [];
    resourcelist = [];
    keyword = 'name';
    linkedidstr: string;
    pagename: string;
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }

    ngOnInit() {
        this.id = null;
        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.route.queryParams.subscribe(params => {
            this.id = + params["id"];
            this.resname = params["name"];
        });
        if (this.id != null && this.id > 0) {
            this.typeaheadOnSelect(this.id, this.resname)
        }
    }

    getResourceLike($event) {
        let userId = (<HTMLInputElement>document.getElementById('resAllo')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.Masterservice.getResourceLikeForAllownce(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect(id, name) {
        this.model.id = id;
        this.model.resource = name;
        this.Masterservice.getAllowanceByResource(this.model)
            .subscribe(value => {
                this.model.designationName = value.designationName
                this.allowancelst = value.allowancelst
            })
    }

    addLinkedAllows(allowid, type, lnkedallo, LinkedAllowance: TemplateRef<any>) {
        this.linkedlst = [];
        this.linkedid = allowid;
        this.linkedidstr = lnkedallo
        if (this.linkedidstr != null) {
            for (let i = 0; i < this.allowancelst.length; i++) {
                if (this.linkedidstr.indexOf(this.allowancelst[i].allowanceid.toString()) > -1 && this.allowancelst[i].allowanceid != allowid) {
                    this.linkedlst.push({
                        alloid: this.allowancelst[i].allowanceid,
                        allowaType: this.allowancelst[i].allowncetype,
                        chkselect: true
                    })
                }
            }
            for (let i = 0; i < this.allowancelst.length; i++) {
                if (this.allowancelst[i].type == type && this.allowancelst[i].allowanceid != allowid) {
                    if (this.linkedidstr.indexOf(this.allowancelst[i].allowanceid.toString()) < -1) {
                        this.linkedlst.push({
                            alloid: this.allowancelst[i].allowanceid,
                            allowaType: this.allowancelst[i].allowncetype,
                            chkselect: false
                        })
                    }
                }
            }
        } else {
            for (let i = 0; i < this.allowancelst.length; i++) {
                if (this.allowancelst[i].type == type && this.allowancelst[i].allowanceid != allowid) {
                    this.linkedlst.push({
                        alloid: this.allowancelst[i].allowanceid,
                        allowaType: this.allowancelst[i].allowncetype,
                        chkselect: false
                    })
                }
            }
        }

        this.modalRef = this.modalService.show(LinkedAllowance, {
            class: 'modal-lg'
        });
    }

    oncheckLinked($event, alloid) {
        for (let i = 0; i < this.allowancelst.length; i++) {
            if (this.allowancelst[i].allowanceid == alloid) {
                if ($event.target.checked) {
                    this.allowancelst[i].chkselect = true;
                } else {
                    this.allowancelst[i].chkselect = false;
                }
            }
        }
    }

    saveLinkedAllo(linkedid) {
        for (let j = 0; j < this.allowancelst.length; j++) {
            if (this.allowancelst[j].allowanceid == linkedid) {
                this.allowancelst[j].linkedAllowance = null;
                for (let i = 0; i < this.linkedlst.length; i++) {
                    if (this.linkedlst[i].chkselect == true) {
                        if (this.allowancelst[j].linkedAllowance == null) {
                            this.allowancelst[j].linkedAllowance = linkedid + "," + this.linkedlst[i].alloid;
                        } else {
                            this.allowancelst[j].linkedAllowance = this.allowancelst[j].linkedAllowance + "," + this.linkedlst[i].alloid
                        }
                    }
                }
            }
        }
        this.modalRef.hide()
    }

    btnsave() {

        if (this.model.id == null) {
            this.notifyService.showRroor("Please select resource", "Oops!");
            return;
        }
        var found = this.allowancelst.find(function (x) { return x.chkselect === true; });
        if (!found) {
            this.notifyService.showRroor("Please select atleast one Allowance", " Oops");
            return;
        }
        this.model.allowancelst = this.allowancelst;
        this.Masterservice.saveResourceAllowance(this.model)
            .subscribe(value => {
                if (value.status == "OK") {
                    this.notifyService.showSuccess(value.message, "Done!");
                    this.router.navigate(['/Allowance']);
                }
            })
    }

    cancle() {
        this.router.navigate(['/Resource Allowance']);
    }
      //For Autocomplete
  selectEvent(item) {
    this.model.id = item.id;
    this.model.resource = item.name;
    this.Masterservice.getAllowanceByResource(this.model)
        .subscribe(value => {
            this.model.designationName = value.designationName
            this.allowancelst = value.allowancelst
        })


  
}

onChangeSearch(search: string) {

    this.user.message = search;
    this.Masterservice.getResourceLikeForAllownce(this.user)
        .subscribe(data => {
            this.resourcelist = [];
            data.forEach((item) => this.resourcelist.push({ "id": item.id, "name": item.name }));

        });

}

onFocused(e) {
    // do something
}

}

export interface LinkedAllowanceMode {
    alloid: number;
    allowaType: string;
    chkselect: boolean
}