<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Stock Ledger</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;margin-top: 12px;">
                <div class="col l1 m1 s1"></div>
                <!-- <div class="input-field col l6 m6 s6" style="padding-bottom: 0px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.itemname" #itemname="ngModel" type="text" class="" id="itemname"
                        data-toggle="tooltip" data-placement="top" title="Item Name" style="margin-top:1px;">
                    <label for="itemname" class="active">Item Name</label>
                </div> -->

                <!-- <div class="input-field col l6 m6 s6" style="padding-bottom: 0px;margin-bottom: 0px;">
                    <input type="text" class="autocomplete" id="itemname" data-toggle="tooltip" data-placement="top"
                        title="Item Name" style="margin-top:1px;" (keyup)="getItemName($event)">
                    <input type="hidden" id="acc-id" />
                    <label for="itemname" class="active" style="left: 9px;">Item Name</label>
                </div> -->
                <div class="ng-autocomplete pinRowgrp input-field col l6 m6 s6"  style="padding-bottom: 0px;margin-bottom: 0px;">
                    <ng-autocomplete [data]="productList2" type="text" placeholder="Item Name"  id="itemname"
                      [searchKeyword]="keyword" [minQueryLength]="3"  
                      (inputChanged)='onChangeSearchproduct($event)' (selected)='selectEvent($event)'   (inputFocused)='onFocusedforproduct($event)'
                      [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
  
                    <ng-template #itemTemplate let-item>
                      <a  [innerHTML]="item.name"></a>
                    </ng-template>
  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  
                </div>

                <div class="input-field col l3 m3 s3" style="margin-top: 33px;text-align: right;">
                    <input [(ngModel)]="model.chkAllitem" #chkAllitem="ngModel" (change)=onAllitem() type="checkbox"
                        style="text-align: 5px">&nbsp;&nbsp;
                    All Item
                </div>
            </div>

            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2">
                    <span>From Date</span>
                    <input type="text" placeholder="From Date" [(ngModel)]="model.frmdate" class="form-control"
                        bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                </div>
                <div class="col l1 m1 s1"></div>
                <div class="col l2 m2 s2">
                    <span>To Date</span>
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" class="form-control"
                        bsDatepicker (ngModelChange)="selectToDate(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">

                </div>
                <div class="input-field col l4 m4 s4" style="text-align: end; margin-top: 12px;">
                    <!-- <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp; -->
                    <button (click)="btnshow(StockLedgerModel)" type="button" class="waves-effect waves-light btn"
                        style="margin-left: 5px;">Show</button>
                </div>
            </div>
        </div>

        <ng-template #StockLedgerModel>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row" id="LibDocs">
                <div class="col l12 m12 s12  modal-header" style="padding: 0px;">
                    <div class="modal-content " style="text-align:center;padding: 0px;margin: 24px;">
                        <span style="font-size: 20px;text-decoration: underline">Stock Ledger Report</span>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 20px;font-weight: bold;">{{companyname}}</span>

                            </div>
                        </div>
                        <div class="row" style="margin: 0px;">
                            <div class="col l12 m12 s12">
                                <span style="font-size: 16px">{{companyaddress}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col l12 m12 s12" style="padding: 0px;margin-bottom: 9px;">
                        <div class="col l12 m12 s12" style="padding-right: 0px;">
                            <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                                <span><img src="../../../assets/images/printer.png"
                                    (click)="openPrint()" data-toggle="tooltip"
                                    data-placement="top" title="Print" width="30px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/excel2.png"
                                        onclick="tableToExcel('excel')" data-toggle="tooltip"
                                        data-placement="top" title="Excel" width="30px" /></span>
                            </div>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                            id="excel">
                            <tbody>
                                <tr>
                                    <td width="19%" style="text-align: left;border: 1px solid black;"> <span>From
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.frmdate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                    <td width="18%" style="text-align: left;border: 1px solid black;"><span>To
                                            Date:-</span>
                                        &nbsp;<span><b>{{model.todate |
                                                date:"dd-MM-yyyy"}}</b></span></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>


                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                            id="excel">
                            <thead style="background: gainsboro;">
                                <th width="4%" style="text-align: left;padding-left: 10px;">Sr.No</th>
                                <th width="7%" style="text-align: left">Date</th>
                                <th width="15%" style="text-align: left">Product Name</th>
                                <th width="8%" style="text-align: center">Type</th>
                                <th width="13%" style="text-align: center">Party</th>
                                <th width="10%" style="text-align: center">Reference No</th>
                                <th width="8%" style="text-align: center">Batch No</th>
                                <th width="9%" style="text-align: center">Previous Stock</th>
                                <th width="9%" style="text-align: center">Transaction</th>
                                <th width="9%" style="text-align: center">Updated Stock</th>
                                <th width="9%" style="text-align: center">Current Stock</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of stocklst ">
                                    <td style="text-align: left;padding-left: 10px;">{{lst.startDate |
                                        date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align: left">{{lst.itemname}}</td>
                                    <td style="text-align: left">{{lst.customername}}</td>
                                    <td style="text-align: right;padding-right: 10px;">{{lst.quotationValue}}</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12" style="padding-right: 0px;">
                    <div class="input-field col l12 m12 s12" style="text-align: end;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="modalRef.hide()">Back</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>

        </ng-template>

    </div>
</div>