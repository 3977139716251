<div *ngIf="isMobile == true">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol">
        <h1 class="header center">Service Call Dashboard</h1>
        <div class="container ">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <h6 class="header left">Total Service Call:&nbsp;{{modelst.length}}</h6>
                    <div class="srchBy p-0">
                        <label for="">Search Criteria</label>
                        <div class="input-field col s12">

                            <select (change)="onSelectSrch($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col s12">
                            <div class="w-80 left smallInputNew">
                                <label for="first-name" class="mat-label m-0">Search Text</label>
                                <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="mat-input "
                                    id="first-name" data-toggle="tooltip" data-placement="top" title="Search Field">
                            </div>
                            <div class="w-20 right">
                                <img src="../../../assets/images/search.png" style="margin-right: 8px;" width="25px"
                                    (click)="btnsearch($event)">
                                <!-- <img src="../../../assets/images/search-filter.jpg" width="25px" id="openChkBlist"
                                    (click)="onchkopen()"> -->
                            </div>
                        </div>
                    </div>
                    <div class="col s12">
                        <div class="tabInnerBox" *ngFor="let lst of modelst">
                            <div class="introTab">
<<<<<<< HEAD
                                <label class="w-50 font-weight-bold blueTxt valignTxt">Sr.#</label>
                                <label class="w-50">{{lst.serialNo}}</label>
=======
                                <!-- <label class="w-50 font-weight-bold blueTxt valignTxt">Sr.#</label>
                                <label class="w-50">{{lst.serialNo}}</label> -->

                                <label class="w-50 font-weight-bold blueTxt valignTxt">Call Date</label>
                                <label class="w-50">{{lst.callDate | date:"dd-MM-yyyy"}}</label>
>>>>>>> main

                                <label class="w-50 font-weight-bold blueTxt">Service Call #</label>
                                <label class="w-50">{{lst.servicecallno}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Customer</label>
                                <label class="w-50"> {{lst.customername}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Mobile #</label>
                                <label class="w-50">
                                    <a href="tel:{{lst.telephone1}}">{{lst.telephone1}}</a>
                                </label>

                                <label class="w-50 font-weight-bold blueTxt">Resource</label>
                                <label class="w-50 valignTxt">{{lst.name}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Call Commit Date</label>
<<<<<<< HEAD
                                <label class="w-50">{{lst.callDate}}</label>
=======
                                <label class="w-50">{{lst.callcommitDate | date:"dd-MM-yyyy"}}</label>
>>>>>>> main

                                <label class="w-50 font-weight-bold blueTxt">Actions</label>
                                <label class="w-50 dcrDiv">
                                    <span style="margin-left: 5px;" *ngIf="!lst.callclose"><img
                                            src="../../../assets/images/DCR-Entry.png" (click)="btngetdetails(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Service DCR"
                                            width="20px" /></span>
                                    <span style="margin-left: 5px;" *ngIf="lst.callclose"><img
                                            src="../../../assets/images/dcr-detail.png" (click)="btnview(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="DCR Details" width="20px"
                                            style="height: 25px;width: 25px;" /></span>
                                    <span style="margin-left: 5px;" *ngIf="lst.callclose && lst.invSend == 'Y'"><img
                                            src="../../../assets/images/file-preview.png"
                                            (click)="btnprintMobile(lst.quotationid)" data-toggle="tooltip"
                                            data-placement="top" title="View Quotation" width="20px"
                                            class="modal-trigger" style="height: 25px;width: 25px;"
                                            href="#printQut" /></span>


                                </label>
                            </div>
                        </div>
                        <div class="s12 right" style="margin-top: 10px;">
                            <lable (click)="first()" class="w-30"
                                style="font-size: 20px;margin-right: 17px;color: #645959;" *ngIf="this.sum > 1">
                                <b>First</b>
                            </lable>
                            &nbsp;&nbsp;
                            <lable class="w-30">
                                <span *ngIf="this.sum > 1" style="margin-right: 17px;"><img
                                        src="../../../assets/images/previous_icon.png" width="35px"
                                        data-toggle="tooltip" data-placement="top" (click)="btnprevious()"
                                        data-placement="top" title="Previous Page"></span>&nbsp;&nbsp;

                                <span><img src="../../../assets/images/next_icon.png" width="35px" data-toggle="tooltip"
                                        data-placement="top" (click)="btnnext()" data-placement="top"
                                        title="Next Page"></span>&nbsp;&nbsp;
                            </lable>
                            <lable (click)="last()" class="w-30"
                                style="font-size: 20px;color: #645959; margin-left: 17px;" *ngIf="this.sum > 1">
                                <b>Last</b>
                            </lable>
                        </div>


                    </div>
                    <div id="printQut" class="modal">
                        <div class="modal-content">
                            <h4 class="orgTxt center-align">Quotation</h4>
                            <div class="row">
                                <div class="tabInnerBox m-btm">
                                    <div class="introTab">
                                        <label class="w-100 font-weight-bold dis-block">Company Name:</label>
                                        <label class="w-100 dis-block">{{model.companyname}}</label>
                                        <label class="w-100 font-weight-bold dis-block">Company Address:</label>
                                        <label class="w-100 valignTxt dis-block">{{model.companyaddress}}</label>

                                    </div>
                                </div>
                                <div class="tabInnerBox m-btm" *ngFor="let lst of lstspare">
                                    <div class="introTab">
                                        <label class="w-50 font-weight-bold blueTxt ">Sr. No.</label>
                                        <label class="w-50 ">{{lst.srno}}</label>

                                        <label class="w-100  font-weight-bold blueTxt dis-block">Description of
                                            Goods</label>
                                        <label class="w-100 dis-block">{{lst.sparedescription}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">HSN</label>
                                        <label class="w-50 ">{{lst.hsnCode}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">Quantity</label>
                                        <label class="w-50 ">{{lst.quantity | number:'1.2-2'}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">Rate</label>
                                        <label class="w-50 ">{{lst.rate | number:'1.2-2'}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">UMO</label>
                                        <label class="w-50 ">{{lst.uom}}</label>

                                        <label class="w-50  font-weight-bold blueTxt ">Total Amount</label>
                                        <label class="w-50 ">{{lst.amount | number:'1.2-2'}}</label>
                                    </div>
                                </div>
                                <!-- <div class="w-100 dis-block">
                                    <span class="font-weight-bold center-align">TOTAL AMOUNT:
                                        <span>{{quoReport.totalinwords |
                                            uppercase}}&nbsp;ONLY</span></span>
                                    <label class="font-weight-bold">Terms And Conditions</label>
                                </div> -->

                                <div class="grayBg" style="box-shadow: rgb(99 99 99 / 45%) 2px 2px 2px 2px;">
                                    <label class="widthR-100 font-weight-bold blueTxt dis-block"
                                        style="text-align: left;">Total In Words :</label>
                                    <label class="widthR-100 dis-block" style="text-align: left;">{{model.totalinwords |
                                        uppercase}}&nbsp;ONLY</label>
                                    <!-- class="w-35 font-weight-bold blueTxt valignTxt" -->
                                    <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Total
                                        :</label>
                                    <label class="widthR-60" style="text-align: left;">&#x20b9; {{model.totalamount
                                        |
                                        number:'1.2-2'}}</label>
                                    <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Discount
                                        :</label>
                                    <label class="widthR-60" style="text-align: left;">&#x20b9; {{model.discountAmount |
                                        number:'1.2-2'}}</label>
                                </div>


                                <!-- <div class="tabInnerBox grayBg m-btm" *ngFor="let lst of reporttandclst">
                                    <div class="col12">
                                        <label class="w-50 font-weight-bold">Heading</label>
                                        <label class="w-50">&nbsp;{{lst.heading}}</label>
                                        <label class="w-50 font-weight-bold">Description</label>
                                        <label class="w-50">&nbsp;{{lst.descrption}}</label>
                                    </div>
                                </div> -->
                            </div>
                            <div style="margin-top:50px;">
                                <hr>
                                <h4 class="orgTxt center-align">Invoice</h4>
                                <div class="row">
                                    <div class="tabInnerBox m-btm">
                                        <div class="introTab">
                                            <label class="w-100 font-weight-bold dis-block">Company Name:</label>
                                            <label class="w-100 dis-block">{{model.companyname}}</label>
                                            <label class="w-100 font-weight-bold dis-block">Company Address:</label>
                                            <label class="w-100 valignTxt dis-block">{{model.companyaddress}}</label>

                                        </div>
                                        <div class="tabInnerBox m-btm">
                                            <div class="introTab">
                                                <label class="w-100 font-weight-bold dis-block">Invoice No:</label>
                                                <label class="w-100 dis-block">{{model.invoicenumber}}</label>
                                                <label class="w-100 font-weight-bold dis-block">Invoice Date:</label>
                                                <label class="w-100 valignTxt dis-block">{{model.datetime |
                                                    date:"dd-MM-yyyy"}}</label>

                                                <label class="w-100 font-weight-bold dis-block">Contact Person :</label>
                                                <label class="w-100 valignTxt dis-block">{{model.iname}}</label>
                                                <label class="w-100 font-weight-bold dis-block">Address:</label>
                                                <label class="w-100 valignTxt dis-block">{{model.address}}</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="tabInnerBox m-btm" *ngFor="let lst of lstspare">
                                        <div class="introTab">
                                            <label class="w-50 font-weight-bold blueTxt ">Sr. No.</label>
                                            <label class="w-50 ">{{lst.srno}}</label>

                                            <label class="w-100  font-weight-bold blueTxt dis-block">Description of
                                                Goods</label>
                                            <label class="w-100 dis-block">{{lst.sparedescription}}</label>

                                            <label class="w-50  font-weight-bold blueTxt ">HSN</label>
                                            <label class="w-50 ">{{lst.hsnCode}}</label>

                                            <label class="w-50  font-weight-bold blueTxt ">Quantity</label>
                                            <label class="w-50 ">{{lst.quantity | number:'1.2-2'}}</label>

                                            <label class="w-50  font-weight-bold blueTxt ">Rate</label>
                                            <label class="w-50 ">{{lst.rate | number:'1.2-2'}}</label>

                                            <label class="w-50  font-weight-bold blueTxt ">UMO</label>
                                            <label class="w-50 ">{{lst.uom}}</label>

                                            <label class="w-50  font-weight-bold blueTxt ">Total Amount</label>
                                            <label class="w-50 ">{{lst.amount | number:'1.2-2'}}</label>
                                        </div>
                                    </div>
                                    <!-- <div class="w-100 dis-block">
                                    <span class="font-weight-bold center-align">TOTAL AMOUNT:
                                        <span>{{quoReport.totalinwords |
                                            uppercase}}&nbsp;ONLY</span></span>
                                    <label class="font-weight-bold">Terms And Conditions</label>
                                </div> -->

                                    <div class="grayBg" style="box-shadow: rgb(99 99 99 / 45%) 2px 2px 2px 2px;">
                                        <label class="widthR-100 font-weight-bold blueTxt dis-block"
                                            style="text-align: left;">Total In Words :</label>
                                        <label class="widthR-100 dis-block"
                                            style="text-align: left;">{{model.totalinwords |
                                            uppercase}}&nbsp;ONLY</label>
                                        <!-- class="w-35 font-weight-bold blueTxt valignTxt" -->
                                        <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Total
                                            :</label>
                                        <label class="widthR-60" style="text-align: left;">&#x20b9; {{model.totalamount
                                            |
                                            number:'1.2-2'}}</label>
                                        <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Discount
                                            :</label>
                                        <label class="widthR-60" style="text-align: left;">&#x20b9;
                                            {{model.discountAmount | number:'1.2-2'}}</label>
                                    </div>


                                    <!-- <div class="tabInnerBox grayBg m-btm" *ngFor="let lst of reporttandclst">
                                    <div class="col12">
                                        <label class="w-50 font-weight-bold">Heading</label>
                                        <label class="w-50">&nbsp;{{lst.heading}}</label>
                                        <label class="w-50 font-weight-bold">Description</label>
                                        <label class="w-50">&nbsp;{{lst.descrption}}</label>
                                    </div>
                                </div> -->
                                </div>
                            </div>
                            <hr>
                            <div class="modal-footer">
                                <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                                    style="margin-left: 5px;">Cancel</a>
                                <!-- <a href="#!" (click)="openPrint()"
                                    class="btn btn-blue waves-effect waves-green btn-flat">Print</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<<<<<<< HEAD

<div *ngIf="isMobile == false">
    <app-header></app-header>
    <div class="container" style="width: 90%;">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Service Call Dashboard</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%">
                <div class="row" style="margin-top: 2%;padding-left: 5px;padding-right: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <div class="col l6 m5 s6">
                        <span style="font-size: 15px;font-weight: bold">Total
                            Service Call:&nbsp;<span>{{user.totalItems}}</span></span>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <div class="input-field col l3 m3 s3" style="margin-top: 14px;margin-bottom:0px;padding-left: 5px;">
                        <span>Search Criteria</span>
                        <select (change)="onSelectSrch($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 30px;margin-bottom:0px;">
                        <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Text">
                        <label for="search">Search Text</label>
                    </div>

                    <div class="input-field col l1 m1 s1" style="text-align: end;margin-top: 30px;margin-bottom:0px;">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Search</button>

                    </div>
                    <div class="col l2 m2 s2">

                    </div>
                    <div class="col l1"></div>
                </div>
                <div class="row" style="margin-top: 1%; margin-bottom: 1%">
                    <div class="col l12 m12 s12">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="7%" style="text-align: center">Sr.#</th>
                                <th width="15%" style="text-align: left">Service Call #</th>
                                <th width="25%" style="text-align: left">Customer Name</th>
                                <th width="15%" style="text-align: left">Mobile #</th>
                                <th width="20%" style="text-align: left">Resource</th>
                                <th width="11%" style="text-align: left">Call Commit Date</th>
                                <th width="11%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of modelst | paginate: { itemsPerPage: 10, currentPage: p, totalItems:user.totalItems };">
                                    <td style="text-align: center">{{lst.serialNo}}</td>
                                    <td style="text-align: left">{{lst.servicecallno}}</td>
                                    <td style="text-align: left">{{lst.customername}}</td>
                                    <td style="text-align: left">{{lst.telephone1}}</td>
                                    <td style="text-align: left">{{lst.name}}</td>
                                    <td style="text-align: left">{{lst.callDate}}</td>
                                    <td style="text-align: center">
                                        <span *ngIf="!lst.callclose"><img src="../../../assets/images/DCR-Entry.png"
                                                (click)="btngetdetails(lst.id)" data-toggle="tooltip"
                                                data-placement="top" title="Service DCR" width="20px" /></span>
                                        <span *ngIf="lst.callclose"><img src="../../../assets/images/dcr-detail.png"
                                                (click)="btnview(lst.id)" data-toggle="tooltip" data-placement="top"
                                                title="DCR Details" width="20px"
                                                style="height: 25px;width: 25px;" /></span>
                                        <span *ngIf="lst.callclose && lst.invSend == 'Y'"><img src="../../../assets/images/file-preview.png"
                                                data-toggle="tooltip" data-placement="top" title="View Quotation"
                                                width="25px" style="height: 25px;width: 25px;"
                                                (click)="btnprint(QuotationReport,lst.quotationid)" /></span>
                                        <!-- <span style="margin-left: 5px; display: none;" *ngIf="!lst.callclose"><img src="../../../assets/images/EditEnquiry.png" width="20px"
                                                data-toggle="tooltip" data-placement="top" title="Reassign Service Call"  (click)="btnAssignEnquiry(lst.id,model.accountid)" /></span> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)">
                            </pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #QuotationReport>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-content registerBgShadow" id="inv">
                <h5 style="text-align: center;">Quotation</h5>
                <div class="col l12 m12 s12" style="border: 1px solid black;">
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyname}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyaddress}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                                <tbody>
                                    <tr>
                                        <td width="50%">Service No:&nbsp;{{model.servicecallno}}</td>
                                        <td width="50%">Quotation Date:&nbsp;{{model.callBookDate | date:"dd-MM-yyyy"}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">Contact Person:&nbsp;{{model.contactname}}</td>
                                        <td width="50%">Product Name:&nbsp;{{model.name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <thead>
                                <th style="text-align: left;padding-left: 10px;padding-right: 0px;">Sr.No.</th>
                                <th style="text-align: left">Description of Goods</th>
                                <th style="text-align: left">HSN</th>
                                <th style="text-align: right">Quantity</th>
                                <th style="text-align: right">Rate</th>
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th> -->
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th> -->
                                <th style="text-align: right">UOM</th>
                                <!-- <th style="text-align: right">Discount</th> -->
                                <th style="text-align: right;padding-right: 10px;padding-left: 0px">Total Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of lstspare">
                                    <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                    <td style="text-align: left">{{lst.sparedescription}}</td>
                                    <td style="text-align: left">{{lst.hsnCode}}</td>
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.rate | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.uom}}</td>
                                    <td style="text-align: right;padding-right: 10px;">{{lst.amount | number:'1.2-2'}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px;">Discount :&nbsp;&nbsp;&#x20b9;{{model.discountAmount | number:'1.2-2'}}</td>
                                    <td style="text-align: right">Total Amount</td>
                                    <td></td>
                                    <td></td>
                                    <!-- <td></td> -->
                                    <!-- <td *ngIf="model.gstseperate==true"></td>
                                    <td *ngIf="model.gstseperate==true"></td> -->
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px;">&#x20b9;{{model.totalamount |
                                        number:'1.2-2'}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="margin:0px;border:1px solid black">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{model.totalinwords |
                                    uppercase}}&nbsp;ONLY</span></span>

                        </div>
                    </div>
                    <!-- <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                            <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                        </div>
                    </div>
    
                    <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                        style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reporttandclst">
                                <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                                <td style="text-align: left">{{lst.descrption}}</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>

            <div class="col l12 m12 s12">
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right ">
                        <button (click)="modalRef.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                    </div>
                </div>
            </div>
            <div class="modal-content registerBgShadow" id="invoice">
                <h5 style="text-align: center;">Invoice</h5>
                <div class="col l12 m12 s12" style="border: 1px solid black;">
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyname}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyaddress}}</span>
                        </div>
                    </div>


                    <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12">

                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                                <tbody>
                                    <tr>
                                        <td width="50%">Invoice No:&nbsp;{{model.invoicenumber}}</td>
                                        <td width="50%">Invoice Date:&nbsp;{{model.datetime | date:"dd-MM-yyyy"}}</td>
                                    </tr>
                                    <tr>
                                        <td width="50%">Contact Person:&nbsp;{{model.iname}}</td>
                                        <td width="50%">Product Name:&nbsp;{{model.name}}</td>
                                    </tr>
                                    <tr>
                                        <td width="100%" colspan="2">Address:&nbsp;{{model.address}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <thead>
                                <th style="text-align: left;padding-left: 10px;padding-right: 0px;">Sr.No.</th>
                                <th style="text-align: left">Description of Goods</th>
                                <th style="text-align: left">HSN</th>
                                <th style="text-align: right">Quantity</th>
                                <th style="text-align: right">Rate</th>
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th> -->
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th> -->
                                <th style="text-align: right">UOM</th>
                                <!-- <th style="text-align: right">Discount</th> -->
                                <th style="text-align: right;padding-right: 10px;padding-left: 0px">Total Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of lstspareivoice">
                                    <td style="text-align: left;">{{lst.srno}}</td>
                                    <td style="text-align: left">{{lst.sparedescription}}</td>
                                    <td style="text-align: left">{{lst.hsnCode}}</td>
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.rate | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.uom}}</td>
                                    <td style="text-align: right;padding-right: 10px">{{lst.amount | number:'1.2-2'}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px;">Discount :&nbsp;&nbsp;&#x20b9;{{model.discountAmount | number:'1.2-2'}}</td>
                                    <td style="text-align: right">Total Amount</td>
                                    <td></td>
                                    <td></td>
                                    <!-- <td></td> -->
                                    <!-- <td *ngIf="model.gstseperate==true"></td>
                                    <td *ngIf="model.gstseperate==true"></td> -->
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px;">&#x20b9;{{model.totalamount |
                                        number:'1.2-2'}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="margin:0px;border:1px solid black">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{model.totalinwords |
                                    uppercase}}&nbsp;ONLY</span></span>

                        </div>
                    </div>
                    <!-- <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                            <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                        </div>
                    </div>
    
                    <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                        style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reporttandclst">
                                <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                                <td style="text-align: left">{{lst.descrption}}</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>

=======

<div *ngIf="isMobile == false">
    <app-header></app-header>
    <div class="container" style="width: 90%;">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Service Call Dashboard</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%">
                <div class="row" style="margin-top: 2%;padding-left: 5px;padding-right: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <div class="col l6 m5 s6">
                        <span style="font-size: 15px;font-weight: bold">Total
                            Service Call:&nbsp;<span>{{user.totalItems}}</span></span>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <div class="input-field col l3 m3 s3" style="margin-top: 14px;margin-bottom:0px;padding-left: 5px;">
                        <span>Search Criteria</span>
                        <select (change)="onSelectSrch($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 30px;margin-bottom:0px;">
                        <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Text">
                        <label for="search">Search Text</label>
                    </div>

                    <div class="input-field col l1 m1 s1" style="text-align: end;margin-top: 30px;margin-bottom:0px;">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Search</button>

                    </div>
                    <div class="col l2 m2 s2">

                    </div>
                    <div class="col l1"></div>
                </div>
                <div class="row" style="margin-top: 1%; margin-bottom: 1%">
                    <div class="col l12 m12 s12">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="6%" style="text-align: center">Sr.#</th>
                                <th width="13%" style="text-align: left">Service Call #</th>
                                <th width="20%" style="text-align: left">Customer Name</th>
                                <th width="10%" style="text-align: left">Call Date</th>
                                <th width="15%" style="text-align: left">Mobile #</th>
                                <th width="15%" style="text-align: left">Resource</th>
                                <th width="11%" style="text-align: left">Call Commit Date</th>
                                <th width="10%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of modelst | paginate: { itemsPerPage: 10, currentPage: p, totalItems:user.totalItems };">
                                    <td style="text-align: center">{{lst.serialNo}}</td>
                                    <td style="text-align: left">{{lst.servicecallno}}</td>
                                    <td style="text-align: left">{{lst.customername}}</td>
                                    <td style="text-align: left">{{lst.callDate | date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align: left">{{lst.telephone1}}</td>
                                    <td style="text-align: left">{{lst.name}}</td>
                                    <td style="text-align: left">{{lst.callcommitDate | date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align: center">
                                        <span *ngIf="!lst.callclose"><img src="../../../assets/images/DCR-Entry.png"
                                                (click)="btngetdetails(lst.id)" data-toggle="tooltip"
                                                data-placement="top" title="Service DCR" width="20px" /></span>
                                        <span *ngIf="lst.callclose"><img src="../../../assets/images/dcr-detail.png"
                                                (click)="btnview(lst.id)" data-toggle="tooltip" data-placement="top"
                                                title="DCR Details" width="20px"
                                                style="height: 25px;width: 25px;" /></span>
                                        <span *ngIf="lst.callclose && lst.invSend == 'Y'"><img src="../../../assets/images/file-preview.png"
                                                data-toggle="tooltip" data-placement="top" title="View Quotation"
                                                width="25px" style="height: 25px;width: 25px;"
                                                (click)="btnprint(QuotationReport,lst.quotationid)" /></span>
                                        <!-- <span style="margin-left: 5px; display: none;" *ngIf="!lst.callclose"><img src="../../../assets/images/EditEnquiry.png" width="20px"
                                                data-toggle="tooltip" data-placement="top" title="Reassign Service Call"  (click)="btnAssignEnquiry(lst.id,model.accountid)" /></span> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)">
                            </pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #QuotationReport>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-content registerBgShadow" id="inv">
                <h5 style="text-align: center;">Quotation</h5>
                <div class="col l12 m12 s12" style="border: 1px solid black;">
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyname}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyaddress}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12">
                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                                <tbody>
                                    <tr>
                                        <td width="50%">Service No:&nbsp;{{model.servicecallno}}</td>
                                        <td width="50%">Quotation Date:&nbsp;{{model.callBookDate | date:"dd-MM-yyyy"}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">Contact Person:&nbsp;{{model.contactname}}</td>
                                        <td width="50%">Product Name:&nbsp;{{model.name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <thead>
                                <th style="text-align: left;padding-left: 10px;padding-right: 0px;">Sr.No.</th>
                                <th style="text-align: left">Description of Goods</th>
                                <th style="text-align: left">HSN</th>
                                <th style="text-align: right">Quantity</th>
                                <th style="text-align: right">Rate</th>
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th> -->
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th> -->
                                <th style="text-align: right">UOM</th>
                                <!-- <th style="text-align: right">Discount</th> -->
                                <th style="text-align: right;padding-right: 10px;padding-left: 0px">Total Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of lstspare">
                                    <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                    <td style="text-align: left">{{lst.sparedescription}}</td>
                                    <td style="text-align: left">{{lst.hsnCode}}</td>
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.rate | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.uom}}</td>
                                    <td style="text-align: right;padding-right: 10px;">{{lst.amount | number:'1.2-2'}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px;">Discount :&nbsp;&nbsp;&#x20b9;{{model.discountAmount | number:'1.2-2'}}</td>
                                    <td style="text-align: right">Total Amount</td>
                                    <td></td>
                                    <td></td>
                                    <!-- <td></td> -->
                                    <!-- <td *ngIf="model.gstseperate==true"></td>
                                    <td *ngIf="model.gstseperate==true"></td> -->
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px;">&#x20b9;{{model.totalamount |
                                        number:'1.2-2'}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="margin:0px;border:1px solid black">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{model.totalinwords |
                                    uppercase}}&nbsp;ONLY</span></span>

                        </div>
                    </div>
                    <!-- <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                            <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                        </div>
                    </div>
    
                    <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                        style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reporttandclst">
                                <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                                <td style="text-align: left">{{lst.descrption}}</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>

            <div class="col l12 m12 s12">
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right ">
                        <button (click)="modalRef.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="openPrint()" type="button" class="waves-effect waves-light btn">Print</button>
                    </div>
                </div>
            </div>
            <div class="modal-content registerBgShadow" id="invoice">
                <h5 style="text-align: center;">Invoice</h5>
                <div class="col l12 m12 s12" style="border: 1px solid black;">
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyname}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin: 0px;">
                        <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                            <span style="font-size: 16px">{{model.companyaddress}}</span>
                        </div>
                    </div>


                    <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12">

                            <table border="1px solid black;"
                                style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                                <tbody>
                                    <tr>
                                        <td width="50%">Invoice No:&nbsp;{{model.invoicenumber}}</td>
                                        <td width="50%">Invoice Date:&nbsp;{{model.datetime | date:"dd-MM-yyyy"}}</td>
                                    </tr>
                                    <tr>
                                        <td width="50%">Contact Person:&nbsp;{{model.iname}}</td>
                                        <td width="50%">Product Name:&nbsp;{{model.name}}</td>
                                    </tr>
                                    <tr>
                                        <td width="100%" colspan="2">Address:&nbsp;{{model.address}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <thead>
                                <th style="text-align: left;padding-left: 10px;padding-right: 0px;">Sr.No.</th>
                                <th style="text-align: left">Description of Goods</th>
                                <th style="text-align: left">HSN</th>
                                <th style="text-align: right">Quantity</th>
                                <th style="text-align: right">Rate</th>
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Net Amount</th> -->
                                <!-- <th style="text-align: right" *ngIf="model.gstseperate==true">Tax</th> -->
                                <th style="text-align: right">UOM</th>
                                <!-- <th style="text-align: right">Discount</th> -->
                                <th style="text-align: right;padding-right: 10px;padding-left: 0px">Total Amount</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of lstspareivoice">
                                    <td style="text-align: left;">{{lst.srno}}</td>
                                    <td style="text-align: left">{{lst.sparedescription}}</td>
                                    <td style="text-align: left">{{lst.hsnCode}}</td>
                                    <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.rate | number:'1.2-2'}}</td>
                                    <td style="text-align: right">{{lst.uom}}</td>
                                    <td style="text-align: right;padding-right: 10px">{{lst.amount | number:'1.2-2'}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px;">Discount :&nbsp;&nbsp;&#x20b9;{{model.discountAmount | number:'1.2-2'}}</td>
                                    <td style="text-align: right">Total Amount</td>
                                    <td></td>
                                    <td></td>
                                    <!-- <td></td> -->
                                    <!-- <td *ngIf="model.gstseperate==true"></td>
                                    <td *ngIf="model.gstseperate==true"></td> -->
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px;">&#x20b9;{{model.totalamount |
                                        number:'1.2-2'}}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" style="margin:0px;border:1px solid black">
                        <div class="col l12 m12 s12">
                            <span style="font-size: 15px">Total Amount in Words:&nbsp;<span>{{model.totalinwords |
                                    uppercase}}&nbsp;ONLY</span></span>

                        </div>
                    </div>
                    <!-- <div class="row" style="margin:0px;">
                        <div class="col l12 m12 s12" style="margin-top: 5px;margin-bottom: 5px;padding-left: 9px;">
                            <span style="font-size: 15px;"><b>Terms and Conditions</b></span>
                        </div>
                    </div>
    
                    <table style="margin-bottom: 2%;margin-top: 9px;" border="1px solid black;"
                        style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                        <thead style="background-color: gainsboro;">
                            <th width="30%" style="text-align: left;padding-left: 10px;">Heading</th>
                            <th width="70%" style="text-align: left">Description</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reporttandclst">
                                <td style="text-align: left;padding-left: 10px;">{{lst.heading}}</td>
                                <td style="text-align: left">{{lst.descrption}}</td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>

>>>>>>> main
            <div class="col l12 m12 s12">
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align:right ">
                        <button (click)="modalRef.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="openPrint1()" type="button" class="waves-effect waves-light btn">Print</button>
                    </div>
                </div>
            </div>

        </ng-template>
    </div>
</div>