<app-header></app-header>
<div class="container" style="width: 80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Material Requisition Home</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="col l12 m12 s12">
                <div class="row">
                    <div class="input-field col l3 m3 s3" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.requestorcheckselect" #requestorcheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Requestor
                    </div>
                    <div class="input-field col l2 m2 s2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.statuscheckselect" #statuscheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Status
                    </div>
                    <div class="input-field col l2 m2 s2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.datecheckselect" #datecheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Date Range
                    </div>
                    <div class="col l1 m1 s1"></div>
                    <div class="input-field col l2 m2 s2" style="margin-top: 30px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.reqnocheckselect" #reqnocheckselect="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;Requisition #
                    </div>
                </div>


                <div class="row">
                    <div class="input-field col s3 l3 m3" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class="input-field col s2 l2 m2" style="margin-bottom: 0px;">
                        <input [(ngModel)]="model.txtserchstatus" #txtserchstatus="ngModel" type="text" class=""
                            id="searchstatus" data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class="col s3 m3 l3" style="padding: 0px;margin-top: 2px;">
                        <div class="input-field col s6 m6 l6" style=" margin-top: 10px;margin-bottom: 0px;">
                            <input type="text" placeholder="From Date" [(ngModel)]="model.startdate" id="start"
                                data-toggle="tooltip" data-placement="top" title="From Date" class="form-control"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">

                        </div>
                        <div class="input-field col s6 m6 l6" style=" margin-top: 10px;margin-bottom: 0px;">

                            <input type="text" placeholder="To Date" [(ngModel)]="model.enddate" id="start"
                                data-toggle="tooltip" data-placement="top" title="To Date" class="form-control"
                                bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">
                        </div>
                    </div>
                    <div class="input-field col s2 l2 m2" style=" text-align: left;margin-bottom: 0px;">
                        <input [(ngModel)]="model.txtserchreqno" #txtserchreqno="ngModel" type="text" class=""
                            id="searchpo" data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class="col  s1 l1 m1" style=" margin-top: 15px;"> <span><img
                                src="../../../assets/images/search.png" (click)="btnsearch()" data-toggle="tooltip"
                                data-placement="top" title="Edit" width="35px" /></span>
                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s12 l12 m12"
                        style="margin-top: 5px;text-align: right;padding-right: 0px;">
                        <button type="button" class="waves-effect waves-light btn" style="margin-right: 0px;"
                            (click)="btnnew()">New Requisition</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12" style="padding: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="22%" style="text-align: left;padding-left: 10px;">Requisition #</th>
                                <th width="12%" style="text-align: left">Requisition Date</th>
                                <th width="32%" style="text-align: left">Requestor Name</th>
                                <th width="22%" style="text-align: left">Status</th>
                                <th width="12%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of requisionlst | paginate: { itemsPerPage: 10, currentPage: p }; let i= index">
                                    <td style="text-align: left">{{lst.requisitionNo}}</td>
                                    <td style="text-align: left">{{lst.requisitionDate | date: 'dd-MM-yyyy'}}</td>
                                    <td style="text-align: left">{{lst.requestor}}</td>
                                    <td style="text-align: left">{{lst.status}}</td>
                                    <td style="text-align: center">
                                        <span *ngIf="lst.status=='Completed'"><img src="../../../assets/images/view.png"
                                                (click)="btnopenissuemodel(lst.id,lst.status,i,issuemodel)"
                                                data-toggle="tooltip" data-placement="top" title="View Material Issue"
                                                width="20px" /></span>&nbsp;&nbsp;
                                        <span *ngIf="lst.status=='Pending'"><img src="../../../assets/images/review.png"
                                                (click)="btnopenmodel(lst.id,lst.status,issuemodel)"
                                                data-toggle="tooltip" data-placement="top" title="Issue Model"
                                                width="20px" /></span>&nbsp;&nbsp;
                                        <span *ngIf="lst.status=='Pending'"><img src="../../../assets/images/delete.png"
                                                (click)="btndelete(i,lst.id)" data-toggle="tooltip" data-placement="top"
                                                title="Delete" width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #issuemodel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>   
    <div class="registerBgShadow">
        <div class="modal-content" style="text-align: center;">
            <span style="font-size: 25px;">Material Issue Note</span>
        </div>
        <div class="row">
            <div class=" input-field col s2 " style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>Issue Date</b></span></div>
                <span>{{issueDate | date:"dd-MM-yyyy"}}</span>
            </div>

            <div class=" input-field col s2" style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>MI #</b></span></div>
                <span>{{model.miid}}</span>
            </div>
            <div class=" input-field col s2" style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>Issuer</b></span></div>
                <span>{{model.requestors}}</span>
            </div>
            <div class=" input-field col s2 " style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>Requisition Date</b></span></div>
                <span>{{model.requisitionDate | date:"dd-MM-yyyy"}}</span>
            </div>

            <div class=" input-field col s2" style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>Requisition #</b></span></div>
                <span>{{model.requisitionNo}}</span>
            </div>
            <div class=" input-field col s2" style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>Requestor</b></span></div>
                <span>{{model.requestor}}</span>
            </div>
        </div>
        <div class="row">

            <div class=" input-field col l4 m4 s4" style="margin-top: 9px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>From Inventory Location</b></span></div>
                <span>{{model.locationname}}</span>
            </div>
            <div class="col l3 m3 s3"></div>
            <div class=" input-field col l4 m4 s4" style="margin-top: 9px;margin-bottom: 0px;font-size: 15px;">
                <div><span><b>To Inventory Location</b></span></div>
                <span>{{model.tolocationname}}</span>
            </div>

        </div>

        <div class="row">
            <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 9px;text-align: left;"
                *ngIf="model.status==Pending">
                <span>Item Category</span>
                <select (change)="onSelectitemCat($event.target.value)">
                    <option selected>Select Item Category</option>
                    <option *ngFor="let type of itmCatlst" [value]="type.id"
                        [selected]="type.id == model.itemcategoryid">
                        {{type.name}}</option>
                </select>
            </div>
            <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 9px;text-align: left;"
                *ngIf="model.status==Pending">
                <span>Sub Category</span>
                <select (change)="onSelectSubCat($event.target.value)">
                    <option selected>Select Sub Category</option>
                    <option *ngFor="let type of subCatlst" [value]="type.id"
                        [selected]="type.id == model.itemsubcategoryid">
                        {{type.name}}</option>
                </select>
            </div>
            <div class=" input-field col s4" style="margin-top: 25px;margin-bottom: 0px;" *ngIf="model.status==Pending">
                <input type="text" class="autocomplete" [(ngModel)]="model.itemname" id="itmname"
                    placeholder="Item Name" (keyup)="getItemLike($event)" />
                <input type="hidden" id="acc-id" />
            </div>
            <div class="row col s2" style="text-align: right;padding-top: 25px;" *ngIf="model.status==Pending">
                <button type="button" (click)="btnadd()" class="waves-effect waves-light btn">Add</button>
            </div>
        </div>

        <div class="row">
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="col l12 m12 s12" style="padding-left: 0px;padding-right: 0px;;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="8%" style="text-align: left">Item Code</th>
                                <th width="20%" style="text-align: left">Item Name</th>
                                <th width="8%" style="text-align: left">Unit</th>
                                <th width="15%" style="text-align: right">Quantity Requisitioned</th>
                                <th width="8%" style="text-align: left">Balance</th>
                                <th width="12%" style="text-align: right">Available Stock</th>
                                <th width="12%" style="text-align: right">Issued Quantity</th>
                                <th width="10%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of requisitionlst | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                                    <td style="text-align: left">{{lst.itemcode}}</td>
                                    <td style="text-align: left">{{lst.itemname}}</td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <td style="text-align: right">{{lst.quantity}}</td>
                                    <td style="text-align: right">{{lst.balquantity}}</td>
                                    <td style="text-align: right">{{lst.stockforsalefloc}}</td>
                                    <td style="text-align: right"> <input [(ngModel)]="lst.issuedquantity"
                                            #issuedquantity="ngModel" type="number" class=""
                                            oninput="validity.valid||(value='');" [disabled]="lst.status=='Completed' || lst.complete == 'Y'"
                                            min="0" id="quantity" data-toggle="tooltip" data-placement="top"
                                            (ngModelChange)="onChangeOfqantity(i)" title="Issued Quantity"
                                            style="margin-top:1px;text-align: right">
                                    </td>
                                    <td style="text-align: center">
                                        <span><img src="../../../assets/images/delete.png" *ngIf = "lst.status!='Completed'"
                                                (click)="btndeleteissue(lst.id,i)" data-toggle="tooltip"
                                                data-placement="top" title="Delete" width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="input-field col l12 m12 s12" style="text-align: right;margin-top: 0px;">
                <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                    (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                    style="margin-left: 5px;">Save</button>
            </div>
        </div>
    </div>

</ng-template>