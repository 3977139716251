export class SearchItemModel{
    itemcode: string;
    itemname: string;
    itemcategory:string;
    itemsubcategory:string;
    inventoryunit:string;
    txtsearch:string;
    txtselect: string;
    id: number;
    srno: number;
}