import { OnInit, Component, Optional, Self, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, DealersService, AuthenticationService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { DailyCallModel, Enquiries, ActivityModel, TeamMembers, Contacts, Products } from './DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { usertwofieldModel } from 'src/app/Master/ResourceMaster/usertwofieldModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import * as moment from 'moment';
import { OpportunitiesModel } from '../Opportunities/OpportunitiesModel';
import { TeamModel } from '../Teams/TeamModel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Mainmenumodel } from 'src/app/_models/Mainmenumodel';
declare var $: any;
export class ItemList {
    constructor(public item: string, public selected?: boolean) {
        if (selected === undefined) selected = false;
    }
}
@Component({
    selector: 'app-DailyCall',
    templateUrl: 'DailyCall.html',
    providers: [{ provide: MatFormFieldControl, useExisting: DailyCall }]
})
export class DailyCall implements OnInit {
    userList2: any;
    keyword1 = "name";
    keyword2 = "name";

    constructor(@Optional() @Self() public ngControl: NgControl,
        private elRef: ElementRef<HTMLElement>,
        private fm: FocusMonitor,
        private Masterservice: MasterServices,
        private route: ActivatedRoute,
        private CompanyService: CompanyService,
        private notifyService: NotificationService,
        private transactionservice: TransactionServices,
        private modalService: BsModalService,
        private dealersService: DealersService,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private authenticationService: AuthenticationService) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    @ViewChild('inputTrigger', { read: MatAutocompleteTrigger }) inputTrigger: MatAutocompleteTrigger;
    private changeCallback: Function;
    private touchedCallback: Function;
    private changeCallbacks: Function;
    private touchedCallbacks: Function;
    stateChanges = new Subject<void>();
    selectedItems: ItemList[] = new Array<ItemList>();
    itemControl = new FormControl();
    @Input() autocomplete: any[];
    @Input() autocompletes: any[];
    @Input() set value(value: any) {
        if (value) {
            this.selectedItems = value;
        }
        this.stateChanges.next();
    }
    get value() {
        return this.selectedItems;
    }
    focused = false;
    autocompleteChoices: any;
    innerValue = '';
    lastFilter = '';
    filteredItems: ItemList[];
    allitemsItems: ItemList[];
    items = [];
    isAllSelected = false;
    resourcemap = new Map<string, number>();
    isDisabled = false;
    quotationid: number;
    saveButton: boolean

    productList2 = [];
    productList3 = [];
    productmap = new Map();
    localeid: number;
    modalRef: BsModalRef;

    model = new DailyCallModel();
    contactmo = new Contacts();

    user = new User();
    enquirelst: Enquiries[] = [];
    activitylst: ActivityModel[] = [];
    memberlst: TeamMembers[] = [];
    contactlst: Contacts[] = []
    id: number;

    allproductlst: Products[] = []
    productlst: Products[] = [];
    currentDate = new Date();
    procatlst: Products[] = [];
    productEnquirylst: Products[];
    opportunitieslst: OpportunitiesModel[] = [];
    designationlst: usertwofieldModel[] = [];
    jointworkint: number[] = []
    flagmodule = new CoustomerflagModel();
    productcomp: boolean = false;
    productmodel: boolean = false;
    activityid: number;
    action: string;
    dcrHistorylst: DailyCallModel[] = [];
    lstassigned: DropDownModel[] = [];
    userid: number;
    teammodel = new TeamModel();
    pincode: string;
    procatid: number;
    procatname: string;
    deviceInfo = null;
    isMobile = null;
    mainmenus: Mainmenumodel[] = [];
    username: string;
    assignedanduserid: boolean = false;
    ngOnInit(): void {
        this.mainmenus = JSON.parse(localStorage.getItem('menu'));
        this.username = localStorage.getItem('fullname');

        console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        console.log(this.deviceInfo);
        console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        console.log(isDesktopDevice);

        this.saveButton = true;
        this.user.userid = Number(localStorage.getItem("userid"));
        this.model.userid = Number(localStorage.getItem("userid"));
        this.userid = this.model.userid
        this.getAssignedto(this.localeid, this.userid);
        this.user.accesstype = localStorage.getItem("accesstype");
        this.route.queryParams.subscribe(params => {
            this.id = params["id"];
            this.action = params["flag"]
            this.activityid = params["activityid"];
            this.model.activityid = this.activityid
        });
        this.model.followupdate = this.currentDate

        this.getDept();
        this.itemControl.valueChanges.pipe(
            startWith<string | ItemList[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter))
        ).subscribe(data => this.filteredItems = data);
        if (this.id != null && this.id > 0) {
            this.filteredItems = [];
            this.user.trnid = this.id
            this.transactionservice.getDataByOpportunityId(this.user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.localeid = data.localeid;
                        this.pincode = data.pincode;
                        this.getAssignedto(this.pincode, this.userid);
                        if (this.activityid != null) {
                            this.model.activityid = this.activityid
                        }
                        for (let i = 0; i < this.model.enquirylst.length; i++) {
                            this.model.enquiryName = this.model.enquirylst[i].name
                            this.model.enquiryid = this.model.enquirylst[i].id

                        }
                        this.model.ctype = data.ctype;
                        this.model.dcrdate = new Date();
                        this.model.noofdays = data.noofdays;
                        this.enquirelst = data.enquirylst;
                        this.activitylst = data.activitylst;
                        this.contactlst = data.contactlst;
                        this.procatlst = data.procatlst;
                        // this.allproductlst = data.productlst;
                        this.productEnquirylst = data.productEnquirylst;
                        this.dcrHistorylst = data.dcrHistorylst
                        this.model.lstproductintersted = data.lstproductintersted;

                        this.productEnquirylst.push({
                            id: null,
                            chkselectpro: null,
                            pcid: null,
                            pcname: null,
                            pid: null,
                            name: null,
                            description: null,
                            procatlst: null,
                            productlst: null,
                            prosprocatid: null,
                            currency: null,
                            minimumPrice: null,
                            currencylst: null,
                            currencyid: null,
                            uom: null,
                            productid: null,
                            gstamount: null,
                            discount: null,
                            uomid: null,
                            price: null,
                            gstrate: null,
                            prouctname: "",
                            discountAllowed: null,
                            taxpercent: null,
                            added: false,
                            currencyName: null,
                            categoriesdescription: null,
                            type: null,
                            spareid: null,
                            gstid: null,
                            spareProName: null,
                            itemcode: null,
                            rate: null,
                            gstGrouppercentage: null,
                            gstheaderID: null,
                            discountAllowedNew: null,
                            quantity: 0
                        })
                        data.memberlst.forEach((item) =>
                            this.memberlst.push({ id: item.id, name: item.name }));
                        for (let i = 0; i < this.memberlst.length; i++) {
                            this.filteredItems.push(new ItemList(this.memberlst[i].name));
                            this.resourcemap.set(this.memberlst[i].name, this.memberlst[i].id)
                        }
                        this.itemControl.valueChanges.pipe(
                            startWith<string | ItemList[]>(''),
                            map(value => typeof value === 'string' ? value : this.lastFilter),
                            map(filter => this.filter(filter))
                        ).subscribe(data => this.items = this.filteredItems);
                        this.allitemsItems = this.filteredItems;
                        this.getflag();
                    });
        }
        // this.transactionservice.getAllOpportunitiesOfResource(this.user)
        //     .subscribe(
        //         data => {
        //             this.opportunitieslst = data
        //         });
    }
    onSelectDesign(val, i) {
        for (let j = 0; j < this.designationlst.length; j++) {
            if (this.designationlst[j].id == val) {
                this.contactlst[i].designation = this.designationlst[j].name
            }
        }
    }

    getflag() {
        this.flagmodule.flagname = "Product Compulsory";
        this.CompanyService.getmoduleflag(this.flagmodule).subscribe((value) => {
            if (value.flagresult == "Y") {
                this.productcomp = true;
            }
        });
        this.flagmodule.flagname = "Product Model";
        this.CompanyService.getmoduleflag(this.flagmodule).subscribe((value) => {
            if (value.flagresult == "Y") {
                this.productmodel = true;
            }
        });
    }

    selectDcrDate(date) {
        let lastdate = moment(new Date(), "DD/MM/YYYY").subtract(this.model.noofdays + 1, 'days').toDate()
        if (date < lastdate) {
            this.notifyService.showRroor("Selected DCR date is not allowed", "oops");
            this.model.dcrdate = new Date()
            return;
        }
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.dcrdate = new Date()
            return;
        }


    }

    selectFollowUpDate(date) {

        let pastdate = moment(new Date(), "DD/MM/YYYY").subtract(1, 'days').toDate()

        if (this.model.followupdate < pastdate) {
            this.notifyService.showRroor("Past date is not allowed for Follow up Date", "oops");
            // this.model.followupdate = this.currentDate
            this.model.followupdate = new Date()
            return;
        }

    }


    displayText(value: any): string {
        return value.text;
    }

    writeValue(value: any) {
        if (!this.autocomplete) {
            this.value = value;
        }
    }
    registerOnChange(fn: Function) {
        this.changeCallback = fn;
    }
    registerOnTouched(fn: Function) {
        this.touchedCallback = fn;
    }
    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    displayTexts(value: any): string {
        return value.text;
    }

    // writeValues(value: any) {
    //     if (!this.autocomplete) {
    //         this.values = value;
    //     }
    // }
    registerOnChanges(fn: Function) {
        this.changeCallback = fn;
    }
    registerOnToucheds(fn: Function) {
        this.touchedCallbacks = fn;
    }
    setDisabledStates(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    inputHandler(event: Event) {
        this.value = (<HTMLInputElement>event.target).value;
        if (this.changeCallback) {
            this.changeCallback(this.value);
        }
    }

    autocompleteHandler(event: Event) {
        const text = (<HTMLInputElement>event.target).value;
        if (this.autocomplete) {
            if (text) {
                this.autocompleteChoices = text;
            } else if (this.changeCallback) {
                this.innerValue = '';
                this.changeCallback(null);
            }
        }
        if (this.autocompletes) {
            if (text) {
                this.autocompleteChoices = text;
            } else if (this.changeCallbacks) {
                this.innerValue = '';
                this.changeCallbacks(null);
            }
        }
    }

    clicker() {
        this.inputTrigger.openPanel();
    }
    filter(filter: string): ItemList[] {
        this.lastFilter = filter;
        if (filter) {
            return this.items.filter(option => {
                return option.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            })
        } else {
            this.items = this.allitemsItems;
            return this.items;
        }

    }

    optionClicked(event: Event, item: ItemList) {
        event.stopPropagation();
        this.toggleSelection(item);
    }

    toggleSelection(item: ItemList) {

        item.selected = !item.selected;
        if (item.selected) {
            var i = this.selectedItems.find(value => value.item === item.item);
            if (this.jointworkint.length <= 2) {
                if (!i) {
                    this.selectedItems.push(item);
                }
            } else {
                this.notifyService.showRroor("Only 3 Joint work are allowed", "oops");
                return;
            }
            this.updateList();
            this.changeCallback(this.selectedItems);
        } else {
            if (!this.isAllSelected) {
                const i = this.selectedItems.findIndex(value => value.item === item.item);
                this.selectedItems.splice(i, 1);
                this.updateList();
                this.changeCallback(this.selectedItems);
            }
        }
    }

    updateList() {
        this.jointworkint = []
        for (let i = 0; i < this.selectedItems.length; i++) {
            this.jointworkint.push(this.resourcemap.get(this.selectedItems[i].item))
        }
    }

    ngOnDestroy() {
        this.fm.stopMonitoring(this.elRef.nativeElement);
        this.stateChanges.complete();
    }

    autocompleteBlur(event: Event) {
        (<HTMLInputElement>event.target).value = this.innerValue;
        this.setTouched();
    }

    updateOption(event: MatAutocompleteSelectedEvent) {
        if (this.changeCallback) {
            const { value, text } = event.option.value;
            this.value = text;
            this.changeCallback(value);
        }
    }

    setTouched() {
        if (this.touchedCallback) {
            this.touchedCallback();
            this.touchedCallbacks();
        }
    }

    onchkbyvisit($event) {
        this.model.connection = "";
        if ($event.target.checked) {
            this.model.connection = "By Visit"
        } else {
            this.model.connection = ""
        }
    }
    onchkbycall($event) {
        this.model.connection = "";
        if ($event.target.checked) {
            this.model.connection = "By Phone/Email"
        } else {
            this.model.connection = ""
        }
    }

    getCustomerLike($event) {

    }

    btnAddContact(contactmodel: TemplateRef<any>) {
        this.modalRef = this.modalService.show(contactmodel, {
            class: 'modal-lx'
        });
    }

    onSelectdesiganation(value) {
        this.contactmo.designation = value;
    }
    btnaddcontact() {
        if (this.contactmo.contactname == null) {
            this.notifyService.showRroor("Please Enter Contact Name", "Opps");
            return;
        }


            return;
        if (this.contactmo.mobileno == null) {
            this.notifyService.showRroor("Please Enter Mobile Number", "Opps");
            return;
        }
        if (this.contactmo.email == null) {
            this.notifyService.showRroor("Please Enter Email", "Opps");
            return;
        }
        this.contactlst.push({
            id: null,
            contactname: this.contactmo.contactname,
            designation: this.contactmo.designation,
            mobileno: this.contactmo.mobileno,
            email: this.contactmo.email,
            chkcontact: false
        })

        this.contactmo.contactname = null;
        this.contactmo.designation = null
        this.contactmo.mobileno = null
        this.contactmo.email = null;
        this.modalRef.hide();
    }

    getDept() {
        let user = new User();
        this.designationlst = [];
        this.Masterservice.getDept(user)
            .subscribe((value) => {
                this.designationlst = value.desiglst
            });
    }

    btnselectContact($event, ind) {
        for (let i = 0; i < this.contactlst.length; i++) {
            this.contactlst[i].chkcontact = false;
        }
        this.contactlst[ind].chkcontact = true;
    }

    onSelectEnquiry(val) {
        if (val == 0) {
            this.notifyService.showRroor("Please Select Enquiry!!", "Oops!");
            return;
        }

        if (val != "Choose One") {

            this.user.trnid = val;
            this.user.id = this.model.accid;
            this.transactionservice.getDataByOpportunityId(this.user)
                .subscribe(
                    data => {
                        this.model.currentstage = data.currentstage
                        this.model.enquiryid = data.enquiryid
                        this.model.stageid = data.stageid;
                        this.model.quostatus = data.quostatus;
                        this.model.quoid = data.quoid;
                        this.model.ctype = data.ctype;

                        this.model.quotationChanged = data.quotationChanged;
                        if (data.activityid != null) {
                            this.model.activityid = data.activityid

                        } else {
                            this.model.activityid = null;
                        }
                        this.model.actionTaken = data.actionTaken
                        this.dcrHistorylst = data.dcrHistorylst;
                        this.productEnquirylst = data.productEnquirylst;
                        this.productEnquirylst.push({
                            id: null,
                            chkselectpro: null,
                            pcid: null,
                            pcname: null,
                            pid: null,
                            name: null,
                            description: null,
                            procatlst: null,
                            productlst: null,
                            prosprocatid: null,
                            currency: null,
                            minimumPrice: null,
                            currencylst: null,
                            currencyid: null,
                            uom: null,
                            productid: null,
                            gstamount: null,
                            discount: null,
                            uomid: null,
                            price: null,
                            gstrate: null,
                            prouctname: "",
                            discountAllowed: null,
                            taxpercent: null,
                            added: false,
                            currencyName: null,
                            categoriesdescription: null,
                            type: null,
                            spareid: null,
                            gstid: null,
                            spareProName: null,
                            itemcode: null,
                            rate: null,
                            gstGrouppercentage: null,
                            gstheaderID: null,
                            discountAllowedNew: null,
                            quantity: 0
                        })
                        this.filteredItems = [];
                        this.memberlst = []
                        data.memberlst.forEach((item) =>
                            this.memberlst.push({ id: item.id, name: item.name }));
                        for (let i = 0; i < this.memberlst.length; i++) {
                            this.filteredItems.push(new ItemList(this.memberlst[i].name));
                            this.resourcemap.set(this.memberlst[i].name, this.memberlst[i].id)
                        }
                        this.itemControl.valueChanges.pipe(
                            startWith<string | ItemList[]>(''),
                            map(value => typeof value === 'string' ? value : this.lastFilter),
                            map(filter => this.filter(filter))
                        ).subscribe(data => this.items = this.filteredItems);
                        this.allitemsItems = this.filteredItems;
                    });

        }
    }

    onSelectActivity(val) {

        this.model.activityid = null;
        this.model.activity = null

        if (val == 0) {
            this.notifyService.showRroor("Select Activity!!", "Oops!");
            return;
        }
        if (this.model.connection == null) {
            this.notifyService.showRroor("Select Call Type First!!", "Oops!");
            return;
        }

        if (val != "Choose One") {
            for (let i = 0; i < this.activitylst.length; i++) {

                if (this.activitylst[i].id == val) {
                    this.model.activityid = this.activitylst[i].id
                    if (this.activitylst[i].name == 'Assign Opportunity' && this.lstassigned.length == 1) {
                        this.notifyService.showRroor("No resource available for this pincode", " Oops");
                    }


                    if (this.model.activityid == 3) {
                        this.model.quosubflag = true;
                    }
                    this.model.activity = this.activitylst[i].name
                    this.model.nextstage = this.activitylst[i].stage
                    this.model.nextstageid = this.activitylst[i].stageid
                    this.model.actionTaken = this.activitylst[i].actionflag
                }
            }
        }


        if (this.model.quoid != null) {   //&& this.model.quotationChanged == true
            this.quotationid = this.model.quoid
            if (this.model.quostatus == "Edit") {
                for (let i = 0; i < this.model.assignto.length; i++) {
                    if (this.model.assignto[i] == this.user.userid) {
                        this.assignedanduserid = true;
                    }
                }
                if (this.assignedanduserid == true) {
                    if (this.model.actionTaken == 'P') {

                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                "enqid": this.model.enquiryid,
                                "flag": "Edit",
                                "nextStage": this.model.nextstageid,
                                "Activityid": this.model.activityid,
                                "connection": this.model.connection
                            }

                        };
                        this.router.navigate(['/PrepareQuotation'], navigationExtras);
                    } else if (this.model.actionTaken == 'Q') {
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                "enqid": this.model.enquiryid,
                                "flag": "Decision",
                                "nextStage": this.model.nextstageid,
                                "Activityid": this.model.activityid,
                                "connection": this.model.connection
                            }
                        };
                        this.router.navigate(['/PrepareQuotation'], navigationExtras);
                    } else if (this.model.actionTaken == 'S') {

                    }
                } else {
                    this.notifyService.showRroor("Quotation activity cannot be perform!!", "Oops!!");
                    this.model.activityid = null;
                }
            }
            else {
                if (this.model.actionTaken != 'N') {
                    this.notifyService.showRroor("Quotation is Under Approval!!", "Oops!!");
                    this.model.activityid = null;
                }
            }
        } else {
            if (this.model.quoid == null && this.model.actionTaken == 'P') {

                for (let i = 0; i < this.model.assignto.length; i++) {
                    if (this.model.assignto[i] == this.user.userid) {
                        this.assignedanduserid = true;
                    }
                }
                if (this.assignedanduserid == true) {
                    let navigationExtras: NavigationExtras = {
                        queryParams: {
                            "enqid": this.model.enquiryid,
                            "flag": "New",
                            "nextStage": this.model.nextstageid,
                            "Activityid": this.model.activityid,
                            "connection": this.model.connection
                        }
                    };
                    this.router.navigate(['/PrepareQuotation'], navigationExtras);
                } else {
                    this.notifyService.showRroor("Quotation activity cannot be perform!!", "Oops!!");
                    this.model.activityid = null;
                    return;
                }
            }
        }
    }
    btnSendQuo() {
        this.model.quoid = this.quotationid
        this.transactionservice.btnSendQuotation(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                    }
                });

    }

    btnEditQuo(status) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                "enqid": this.model.enquiryid,
                "flag": status,
                "Activityid": this.model.activityid
            }
        };
        this.router.navigate(['/PrepareQuotation'], navigationExtras);
    }


    getintrestedproductcatego($event, ind) {
        let userId = (<HTMLInputElement>document.getElementById('productcategos')).value;
        this.productList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionservice.getproductCategorieslike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id, "description": item.description }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.productList2.length; i++) {
                        map.set(this.productList2[i].name, this.productList2[i].id);
                        this.productmap.set(this.productList2[i].name, this.productList2[i].description)
                        searchDataObj[this.productList2[i].name] = null;
                        if (i == 0) {
                            names = this.productList2[i].name;
                        } else {
                            names = names + "," + this.productList2[i].name;
                        }
                    }

                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Categories");
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Categories");
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    getinteredproductmodel($event, ind) {
        if (this.productEnquirylst[ind].pcid == null) {
            this.notifyService.showRroor("Please Select Product Category", "Opps");
            return;
        }
        let userId = (<HTMLInputElement>document.getElementById('product')).value;
        this.productList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.productcategoryId = this.productEnquirylst[ind].pcid;
        var obj = this;
        if (userId.length >= 3) {
            this.dealersService.getProductLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.productList2.push({ "name": item.productName, "id": item.productid, "description": item.description }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.productList2.length; i++) {
                        map.set(this.productList2[i].name, this.productList2[i].id);
                        this.productmap.set(this.productList2[i].name, this.productList2[i].description)
                        searchDataObj[this.productList2[i].name] = null;
                        if (i == 0) {
                            names = this.productList2[i].name;
                        } else {
                            names = names + "," + this.productList2[i].name;
                        }
                    }

                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Product");
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelectInterstedProduct(map.get(txt), txt, ind, "Product");
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelectInterstedProduct(id, name, ind, value) {
        if (value == "Product") {

            this.productEnquirylst[ind].productid = id;
            this.productEnquirylst[ind].name = name;
            this.productEnquirylst[ind].description = this.productmap.get(name);
        }
        else {
            this.productEnquirylst[ind].pcid = id;

            this.productEnquirylst[ind].pcname = name;
            this.productEnquirylst[ind].categoriesdescription = this.productmap.get(name);
        }
    }
    btnaddproductintersted(index, flag) {

        if (flag == true) {
            if (this.productEnquirylst[index].productid == null || this.productEnquirylst[index].productid == 0) {
                this.notifyService.showRroor("Select Product", "Opps");
                return;
            }
        } else {
            if (this.productEnquirylst[index].pcid == null || this.productEnquirylst[index].pcid == 0) {
                this.notifyService.showRroor("Select Product Categories ", "Opps");
                return;
            }
        }
        this.productEnquirylst[index].added = true;
        this.productEnquirylst.push({
            id: null,
            chkselectpro: null,
            pcid: null,
            pcname: null,
            pid: null,
            name: null,
            description: null,
            procatlst: null,
            productlst: null,
            prosprocatid: null,
            currency: null,
            minimumPrice: null,
            currencylst: null,
            currencyid: null,
            uom: null,
            productid: null,
            gstamount: null,
            discount: null,
            uomid: null,
            price: null,
            gstrate: null,
            prouctname: "",
            discountAllowed: null,
            taxpercent: null,
            added: null,
            currencyName: null,
            categoriesdescription: null,
            type: null,
            spareid: null,
            gstid: null,
            spareProName: null,
            itemcode: null,
            rate: null,
            gstGrouppercentage: null,
            gstheaderID: null,
            discountAllowedNew: null,
            quantity: 0
        })
    }
  onSelectActivity2(val) {
        if (val == 0) {
            this.notifyService.showRroor("Select Activity!!", "Oops!");
            return;
        }

        this.model.followuptask = val
    }

    onSelectAssignto(val) {
        if (val == 0) {
            this.notifyService.showRroor("Select AssignTo!!", "Oops!");
            return;
        }
        this.model.assignTo = val
    }

    onchkFollowUp($event) {
        if ($event.target.checked) {
            this.model.chkfollowup = true;
            this.model.followupdate = this.currentDate
            this.model.chkclose = false
        } else {
            this.model.chkfollowup = false;
        }
    }

    onchkClose($event) {
        if ($event.target.checked) {
            this.model.chkfollowup = false;
            this.model.chkclose = true
        } else {
            this.model.chkclose = false
        }
    }

    btnsaveDailyCall() {
        // alert(this.model.quoid );
        // alert(this.model.actionTaken);
        // alert(this.model.activityid);
        this.saveButton = false;
        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.model.dcrdate == null) {
            this.saveButton = true;
            this.notifyService.showRroor("Please enter DCR date", "Oops!");
            return;
        }
        if (this.model.connection == null) {
            this.saveButton = true;
            this.notifyService.showRroor("Please select call type", "Oops!");
            return;
        }
        if (this.model.enquiryid == null) {
            this.saveButton = true;
            this.notifyService.showRroor("Please select Enquiry", "Oops!");
            return;
        }
        if (this.model.activityid == null) {
            this.saveButton = true;
            this.notifyService.showRroor("Please select Activity", "Oops!");
            return;
        }
        if (this.model.remarks == null) {
            this.saveButton = true;
            this.notifyService.showRroor("Please enter remarks", "Oops!");
            return;
        }
        if (this.model.quoid != null && this.model.actionTaken == 'S' && this.model.activityid == 3) {
            this.btnSendQuo();
        }
        if (this.jointworkint.length > 0) {
            if (this.jointworkint[0] != null) {
                this.model.joint1 = this.jointworkint[0]
            }
            if (this.jointworkint[1] != null) {
                this.model.joint2 = this.jointworkint[1]
            }
            if (this.jointworkint[2] != null) {
                this.model.joint3 = this.jointworkint[2]
            }
        }

        var found = this.contactlst.find(function (x) { return x.chkcontact === true; });
        if (!found) {
            this.saveButton = true;
            this.notifyService.showRroor("Please select atleast one Contact Person", " Oops");
            return;
        }

        this.model.productEnquirylst = this.productEnquirylst;
        this.model.contactlst = this.contactlst
        this.model.quoid = this.quotationid;
        this.transactionservice.btnsaveDailyCall(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                        this.saveButton = true;
                        this.ngOnInit();
                        // this.router.navigate(['/Opportunities'])
                    }
                });
    }

    btnback() {
        this.router.navigate(['/Opportunities']);
    }
    onChangeSearchproductcatego(search: string) {
        this.user.message = search;
        this.transactionservice.getproductCategorieslike(this.user)
            .subscribe(data => {
                this.productList2 = [];
                data.forEach((item) => this.productList2.push({ "name": item.name, "id": item.id, "description": item.description }));

            });

    }
    selectEvent(item) {

        if (item.value == "Product") {
            this.productEnquirylst[item.ind].productid = item.id;
            this.productEnquirylst[item.ind].description = item.name;
        }
        else {
            this.productEnquirylst[item.ind].pcid = item.id;

            this.productEnquirylst[item.ind].pcname = item.name;
            this.productEnquirylst[item.ind].categoriesdescription = item.name;
        }

    }
    onFocusedforproductcatego(e) {
        // do something
    }
    onChangeSearchproductmodel(search: string, item) {
        this.user.message = search;
        this.user.productcategoryId = this.productEnquirylst[item.ind].pcid;
        this.dealersService.getProductLike(this.user)
            .subscribe(data => {
                this.productList3 = [];
                data.forEach((item) => this.productList3.push({ "name": item.name, "id": item.id, "description": item.description }));

            });

    }
    selectEventproductmodel(item) {

        if (item.value == "Product") {
            this.productEnquirylst[item.ind].productid = item.id;
            this.productEnquirylst[item.ind].description = this.productmap.get(item.name);
        }
        else {
            this.productEnquirylst[item.ind].pcid = item.id;
            this.productEnquirylst[item.ind].pcname = item.name;
            this.productEnquirylst[item.ind].categoriesdescription = this.productmap.get(item.name);

        }
    }
    onFocusedSearchproductmodel(e) {
        // do something
    }
    onSelectassigned(id) {

        this.model.newassignedtoid = id;

    }
    getAssignedto(id, id2) {
        this.model.localeid = id;
        // this.teammodel.pcInt = [];
        // this.model.lstproductintersted.forEach(element => {
        //     this.teammodel.pcInt.push(element.productCategoryID);
        // });
        // this.model.userid = id2;
        // alert(this.model.userid)
        this.model.pincode = id;
        this.teammodel.pincode = this.model.pincode;
        // alert(this.teammodel.pincode)
        this.teammodel.customertype = this.model.ctypeid;
        this.teammodel.userid = id2;
        this.lstassigned = [];
      if (this.lstassigned.length == 1) {
            this.notifyService.showRroor("No resource available for this pincode", " Oops");
        }
        this.lstassigned.push({ id: 0, name: "Select Assigned To" })
        // this.transactionservice.getAssignedtoList1(this.model)
        this.transactionservice.getAssignedtoListSales(this.teammodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstassigned.push({ id: item.id, name: item.name });
                });
            });
    }
    btndelts(index) {
        this.productEnquirylst.splice(index, 1);
        this.notifyService.showSuccess("Product Deleted ", "Success");
    }
    btnresendotp() {
        this.transactionservice.btnSendQuotation(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                    }
                });
    }
    onSelectcategory(value, ind) {
        for (let i = 0; i < this.procatlst.length; i++) {
            if (this.procatlst[i].pcid == value) {
                this.procatid = this.procatlst[i].pcid;
                this.procatname = this.procatlst[i].pcname;
            }
        }
        this.productEnquirylst[ind].pcid = this.procatid;
        this.productEnquirylst[ind].pcname = this.procatname;
        this.productEnquirylst[ind].categoriesdescription = this.procatname;
    }
    redirectUser(mainmenulabel: string) {
        this.router.navigate(['/' + mainmenulabel]);
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    forclose() {
        if (this.model.remarks == null) {
            this.notifyService.showRroor("Please enter remarks", "Oops!");
            return;
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.model.productEnquirylst = this.productEnquirylst;
        this.model.contactlst = this.contactlst
        this.model.quoid = this.quotationid;
        this.transactionservice.ForeCloseDailyCall(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                      
                    }
                    this.router.navigate(['/Opportunities']);
    })
    
}


}