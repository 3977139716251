import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { DesignationAllowanceModel } from './DesignationAllowanceModel';
import { User } from 'src/app/_models/user';

@Component({
    selector: 'app-login',
    templateUrl: 'DesignationAllowance.html'
})
export class DesignationAllowance implements OnInit {
    sum: any;


    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
            this.pagename=this.router.url
        }
    model = new DesignationAllowanceModel();
    id: number;
    sub: any;
    str: string;
    user: any;
    modellst: DesignationAllowanceModel[] = [];
    pagename: string;


    ngOnInit(): void {
        // this.sub = this.route.params.subscribe(params => {
        //     this.id = + params["id"];
        // });
        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        let user = new User();
        user.trnid = this.id;
        user.trnid = 27;
        this.Masterservice.getdesignationallowbyid(user)
            .subscribe(
                data => {
                    this.modellst = data.modellst
                },
                error => {
                    this.alertService.error(error);
                });

    }

    onSelectModule(id) {

    }

    btnsave($event) {
        // if (this.model.stageCode == null || this.model.stageCode == "") {
        //     this.notifyService.showRroor("Select  StageCode !!", "Oops!");
        //     return;
        // }

        // this.model.modellst = this.modellst
        // this.Masterservice.savepipeline(this.model)
        //     .subscribe(
        //         data => {
        //             if (data.status == "OK") {
        //                 this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
        //                 this.router.navigate(['SearchPipeline']);
        //             } else {
        //                 this.notifyService.showRroor(data.message, "Oops !")
        //             }
        //         },
        //         error => {
        //             alert("Something Wrong ")
        //         });

    }


    cancle() {
        this.router.navigate(["/Designation"]);
    }
}