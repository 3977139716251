<app-header></app-header>
<div class="container" style="width: 80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Material Requisition Note</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class=" input-field col l2 m2 s2 " style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                    <div><span>Requisition Date</span></div>
                    <span><b>{{requisitionDate | date:"dd-MM-yyyy"}}</b></span>
                </div>

                <div class=" input-field col l2 m2 s2" style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                    <div><span>Requisition #</span></div>
                    <span><b>{{model.requisitionId}}</b></span>
                </div>
                <div class=" input-field col l4 m4 s4" style="margin-top: 17px;margin-bottom: 0px;font-size: 15px;">
                    <div><span>Requestor</span></div>
                    <span><b>{{model.requestor}}</b></span>
                </div>
            </div>
            <div class="row">
                <div class="input-field col s4">
                    <span>From Inventory Location</span>
                    <select (change)="onSelectInventoryLocationFrom($event.target.value )" data-toggle="tooltip"
                        data-placement="top" title="Inventory Location">
                        <option selected value="0">Select From Inventory Location</option>
                        <option *ngFor="let lst of locationlst" [value]="lst.id" [selected]="lst.id== model.locationid">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="col s3"></div>
                <div class="input-field col s4">
                    <span>To Inventory Location</span>
                    <select (change)="onSelectInventoryLocationTo($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Inventory Location">
                        <option selected value="0">Select To Inventory Location</option>
                        <option *ngFor="let lst of tolocationlst" [value]="lst.id"
                            [selected]="lst.id== model.tolocationid">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top:0px;">
                    <span>Item Category</span>
                    <select (change)="onSelectitemCat($event.target.value)">
                        <option selected>Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id"
                            [selected]="type.id == model.itemcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top:0px;">
                    <span>Sub Category</span>
                    <select (change)="onSelectSubCat($event.target.value)">
                        <option selected>Select Sub Category</option>
                        <option *ngFor="let type of subCatlst" [value]="type.id"
                            [selected]="type.id == model.itemsubcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class=" input-field col s4" style="margin-top:16px;margin-bottom: 0px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.itemname" id="itmname"
                        placeholder="Item Name" (keyup)="getItemLike($event)" />
                    <input type="hidden" id="acc-id" />
                </div>
                <div class="row col s2" style="text-align: right;padding-top:0px;margin-top:16px">
                    <button type="button" (click)="btnadd()" class="waves-effect waves-light btn">Add</button>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12 ">

                    <!-- <div class="col l12 m12 s12"
                        style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px; margin-top: 5px;">
                        <div class="col s1" style="text-align:center;">
                            <span>Item Code</span>
                        </div>
                        <div class="col s4" style="text-align:left;">
                            <span>Item Name</span>
                        </div>
                        <div class="col s2" style="text-align:center;">
                            <span>Unit</span>
                        </div>
                        <div class="col s3" style="text-align:center;">
                            <span>Available Stock</span>
                        </div>
                        <div class="col s2" style="text-align:center;">
                            <span>Action</span>
                        </div>
                    </div> -->

                    <!-- <mat-accordion style="padding: 0px;">
                        <mat-expansion-panel
                            *ngFor="let lst of requisitionlst | paginate: { itemsPerPage: 10, currentPage: p };let i = index "
                            style="padding: 1px;">
                            <mat-expansion-panel-header style="padding-right: 9px;padding-left: 9px;">
                                <div class="col l12 m12 s12" style="margin-top:20px;color: black;">
                                    <div class="row">
                                        <div class=" col l1 m3 s3" style="text-align:left;">
                                            <span>{{lst.itemcode}}</span>
                                        </div>
                                        <div class=" col l4 m4 s4" style="text-align:left;">
                                            <span>{{lst.itemname}}</span>
                                        </div>
                                        <div class=" col l2 m1 s1" style="text-align:left;">
                                            <span>{{lst.uom}}</span>
                                        </div>
                                        <div class=" col l3 m3 s3" style="text-align:left;">
                                            <span>{{lst.stockforsale}}</span>
                                        </div>
                                        <div class="col s2 m1 l2"
                                            style="text-align:left;padding-right: 0px;padding-left: 0px;">
                                            <span><img src="../../../assets/images/delete.png"
                                                    (click)="btndelete(lst.id,i)" data-toggle="tooltip"
                                                    data-placement="top" title="Delete" width="20px;" /></span>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div *ngIf="lst.stocklocationlst.length>0" class="registerBgShadow col l12 m12 s12"
                                style="text-align: center; margin-top: 10px;">
                                <div class="row" *ngFor="let sublst of lst.stocklocationlst; let j = index" style="padding-top: 9px;">
                                    <div class="col l1 s1 m1" style="text-align:left">
                                        <input type="checkbox" [(ngModel)]="sublst.checkselect" #checkselect="ngModel"
                                        (click)="onSelectCheckselect(i,$event,j)">
                                    </div>
                                    <div class="col l4 m4 s4" style="text-align:left">
                                        <span>From Location : </span>
                                        <span>{{sublst.locationname}}</span>
                                    </div>
                                    <div class="col l2 s2 m2" style="text-align:left">
                                        <span>Available Stock : </span>         
                                        <span>{{sublst.stockforsale}}</span>
                                    </div>
                                    <div class="col s3" style="text-align:left">
                                        <input [(ngModel)]="sublst.quantity" #quantity="ngModel" type="number" class=""
                                            [disabled]="this.action=='view'" oninput="validity.valid||(value='');"
                                            min="0" id="quantity" data-toggle="tooltip" data-placement="top"
                                            title="Quantity Requisitioned" (ngModelChange)="onChangeOfqantity(i,j)" placeholder="Quantity Requisitioned"
                                            style="margin-top:1px;text-align: right;margin-bottom: 0px;">
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="lst.stocklocationlst.length==0" class="col l11 m11 s11 registerBgShadow"
                                style="margin-left:50px;text-align: center;">
                                <div class="col l12 m12 s12" style="color: red">
                                    <samp> List Not Found </samp>
                                </div>
                            </div>
                        </mat-expansion-panel>
                       
                    </mat-accordion> -->

                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left;padding-left: 10px;">Item Code</th>
                            <th width="35%" style="text-align: left;">Item Name</th>
                            <th width="10%" style="text-align: left">Unit</th>
                            <!-- <th width="17%" style="text-align: left">Available Stock</th> -->
                            <th width="18%" style="text-align: center">Quantity Requisitioned</th>
                            <th width="10%" style="text-align: center">Action</th>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let lst of requisitionlst | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                                <td style="text-align: left;padding-left: 10px;">{{lst.itemcode}}</td>
                                <td style="text-align: left">{{lst.itemname}}</td>
                                <td style="text-align: left">{{lst.uom}}</td>
                                <!-- <td style="text-align: left">{{lst.stockforsale}}</td> -->
                                <td style="text-align: right">
                                    <input [(ngModel)]="lst.quantity" #quantity="ngModel" type="number" class=""
                                        [disabled]="this.action=='view'" oninput="validity.valid||(value='');" min="0"
                                        id="quantity" data-toggle="tooltip" data-placement="top" title="quantity"
                                        (ngModelChange)="onChangeOfqantity()" style="margin-top:1px;text-align: right;">
                                </td>
                                <td style="text-align: center">
                                    <span><img src="../../../assets/images/delete.png" (click)="btndelete(lst.id,i)"
                                            data-toggle="tooltip" data-placement="top" title="Delete"
                                            width="20px;" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>

                </div>
            </div>

            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>&nbsp;&nbsp;
                </div>
            </div>
        </div>
    </div>
</div>


<!-- 
<ng-template #termmodel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-content registerBgShadow " style="text-align: center;">
        <h4>Terms And Conditions</h4>
        <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
            <div class="row">
                <input [(ngModel)]="model.termcondition" #termcondition="ngModel" type="text" id="first10"
                    data-toggle="tooltip" data-placement="top" title="Termscondtion" [disabled]="this.action=='view'" [disabled]="this.action=='edit'" >
                <label id="lable10" for="first10">Termscondtion </label>
            </div>
        </div>
        <div class="row col s12" style="text-align: center;padding-top: 16px;" >
            <button type="button" (click)="btnsaveterm()" [disabled]="this.action=='edit'"
                class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;&nbsp;
        </div>
    </div>

</ng-template> -->