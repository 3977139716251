import { OnInit, Component } from '@angular/core';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { BsModalService } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { SchemeHomeModel,CoupenModel } from './SchemeHomeModel';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { TemplateRef } from '@angular/core';
import { BsModalRef} from "ngx-bootstrap";
import { SchemeConfigurateModel } from './SchemeConfigurateModel';

@Component({
    selector: 'app-SchemeHome',
    templateUrl: 'SchemeHome.html',
})
export class SchemeHome implements OnInit {

    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private transactionservice: TransactionServices) {
        this.pagename = router.url
    }
    searchtype: DropDownModel[] = [
        { "id": 0, "name": "All" },
        { "id": 1, "name": "Scheme Code" },
        { "id": 2, "name": "Scheme Name" },
        { "id": 3, "name": "Customer Type" },
    ];
    pagename: string
  
    model = new SchemeHomeModel();
    model1 = new CoupenModel();
    model2 = new SchemeConfigurateModel();
    schemelst: SchemeHomeModel[] = [];
    coupenlst:CoupenModel[] = [];
    schemelst1: SchemeHomeModel[] = [];
    user = new User();
   // searchtype: DropDownModel[] = [];
    p:any;
    q:any;
    public i;
    modalRef: BsModalRef;
    pagename1 : string = "Coupon List"
    ngOnInit(): void {
        this.user.userid = Number(localStorage.getItem("userid"));
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.getAllSchemeConfiguration()
       

    }

    getAllSchemeConfiguration() {
        this.schemelst = [];
        this.Masterservice.getAllSchemeConfiguration(this.user)
            .subscribe(
                data => {
                    
                    this.schemelst = data
                    this.schemelst1 = data
                });
    }
    getAllCoupenConfiguration(id)
    {
      
        this.coupenlst = [];
        this.user.trnid = id;
        this.Masterservice.getAllCoupenConfiguration(this.user)
        .subscribe(
            data => {
                this.coupenlst = data
                this.coupenlst.forEach(item=>
                    {
                        this.model.code = item.code1;
                        this.model.name = item.name;
                    })
            });

    }

    btnNewSch($event) {
        this.router.navigate(['newScheme']);
    }

    onSelectSrch(value) {
        this.model.txtselect = value
    }

    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllSchemeConfiguration()
            this.model.txtserch = null;
        }
        else if(this.model.txtselect == "Scheme Code")
        {
            if(this.model.txtserch == "" || this.model.txtserch == null)
            {
                alert("Enter Criteria To Search")
                return;
            }
            else
            {
      
                this.schemelst1 = [];
                this.schemelst.forEach((item) => {
                    if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.schemelst1.push({ "id": item.id,"srno":item.srno,"code":item.code, "name":item.name, "txtserch": null, "txtselect": null, "customerType": item.customerType });
                    }
                });
                this.schemelst = this.schemelst1;
            }
        }
        else if(this.model.txtselect == "Scheme Name")
        {
            if(this.model.txtserch == "" || this.model.txtserch == null)
            {
                alert("Enter Criteria To Search")
                return;
            }
            else
            {
                this.schemelst1 = [];
                this.schemelst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.schemelst1.push({ "id": item.id,"srno":item.srno,"code":item.code, "name":item.name, "txtserch": null, "txtselect": null, "customerType": item.customerType });
                    }
                });
                this.schemelst = this.schemelst1;
            } 
        }
        else if(this.model.txtselect == "Customer Type")
        {
            if(this.model.txtserch == "" || this.model.txtserch == null)
            {
                alert("Enter Criteria To Search")
                return;
            }
            else
            {
                this.schemelst1 = [];
                this.schemelst.forEach((item) => {
                    if ((item.customerType.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.schemelst1.push({ "id": item.id,"srno":item.srno,"code":item.code, "name":item.name, "txtserch": null, "txtselect": null, "customerType": item.customerType });
                    }
                });
                this.schemelst = this.schemelst1;
            } 
        }

    }
    btnedit(id) {
        this.router.navigate(['/editScheme', id]);
    }
    btncoupenlist(id)
    {
        this.router.navigate(['/coupenlist', id]);
    }
   
    openproduct(coupenmodel: TemplateRef<any>,id) {
        this.modalRef = this.modalService.show(coupenmodel, {
            class: 'modal-lg'
          });
        
        this.getAllCoupenConfiguration(id);
    }
    btndelts(val) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.user.trnid = val
            this.Masterservice.deleteScheme(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }
    }
}