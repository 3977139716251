<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col s12 m12 l12">
                    <div class="input-field col s3 l2 m2" style="margin-top: 9px;padding-left: 5px;">
                        <span>Search Criteria</span>
                        <select (change)="onSelectOpt($event.target.value)" data-toggle="tooltip" data-placement="top"
                            title="Type">
                            <option *ngFor="let type of searchLst" [value]="type.name">
                                {{type.name}} </option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center;margin-top: 26px">
                        <input type="text" [(ngModel)]="model.txtserch" #name="ngModel" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class="input-field col s5 l5 m5" style="margin-top: 26px">
                        <button type="button" (click)="btnsearch()" class="waves-ripple waves-light btn">Go</button>&nbsp;&nbsp;
                        <button type="button" class="waves-ripple waves-light btn" style="margin-left: 5px;"
                            (click)="btnnew()">New</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left">Sr.No.</th>
                            <th width="70%" style="text-align: left">Resources</th>
                            <th width="20%" style="text-align: left">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reslst | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: left">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.name}}</td>
                                <td style="text-align: left"><span><img src="../../../assets/images/edit.png"
                                            (click)="btnedit(lst.id, lst.name)" title="Edit"
                                            width="13%" /></span>&nbsp;&nbsp;<span><img
                                            src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                            title="Delete" width="13%" /></span></td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align: right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>