import { DropDownModel } from 'src/app/_models/DropDownModel';

export class DefinePipelineModel{
    id: number;
    stagecode: string;
    description: string;
    sequence:number;
    checkselect:boolean;
    percentProbability: number;
    hirarchy: number;
    chkselectF: string;
    chkselectP: string;
  
    tbtask:number;
    modellst:DefinePipelineModel[]=[];
    customerTypelst:CustomerTypeModel[]=[];
    Action: string;
    taskName:string;
    stageCode: any;
    txtselect: any;
    action: string;

   
}
export class CustomerTypeModel
{
 
    custypeId:number;
	customertypename:string;
}