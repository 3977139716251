<app-header></app-header>
<div class="container" style="width:100%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Target Management</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">

            <div class="row">

                <div class="input-field col s4">
                    <span>Region</span>
                    <select (change)="onSelectRegion($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Region">
                        <option selected>Select Region</option>
                        <option *ngFor="let type of regionlst" [value]="type.id" [selected]="type.id==model.regionId">
                            {{type.name}}
                        </option>
                    </select>
                </div>

                <div class="input-field col s4">
                    <span>Area</span>
                    <select (change)="onSelectArea($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Area">
                        <option selected>Select Area</option>
                        <option *ngFor="let type of arealst" [value]="type.id" [selected]="type.id==model.areaid">
                            {{type.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col s4">
                    <span>Team</span>
                    <select (change)="onSelectTeam($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Team">
                        <option selected>Select Team</option>
                        <option *ngFor="let type of teamlst" [value]="type.id" [selected]="type.id==model.teamMasterID">
                            {{type.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="input-field col s4" style="margin-top:0px;">
                    <span>Resource</span>
                    <select (change)="onSelectlstuser($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Resource">
                        <option selected>Select Resource</option>
                        <option *ngFor="let lst of lstuser" [value]="lst.id" [selected]="lst.id==model.tbAssignBy">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class="col s1"></div>
                <div style="margin-left: 8px;"><span>Target Type</span></div>
                <div class="col s1"></div>
                <div class=" col s2" style=" margin-top: 10px;">
                    <label><input name="grup2" type="radio" (click)="onSelectsales($event.target.value)"
                            [checked]="model.sales">
                        <span>Sales</span></label>
                </div>

                <div class=" col s2" style=" margin-top: 10px;">
                    <label><input name="grup2" type="radio" (click)="onSelectactivity($event.target.value)"
                            [checked]="model.activity">
                        <span>Activity</span></label>
                </div>
            </div>


            <div class="row">
                <div class="col l11 m11 s11">
                    <Span style="text-size-adjust:16px">Locale Coverd</Span>
                    <div>
                        <label id="lable17" for="first17">{{model.localename}}</label>
                    </div>
                    <div><span style="margin-right: 35px;"><img src="../../../assets/images/edit.png"
                                (click)="btnopenmodel(model.tbAssignBy,localemodel)" title="Edit" width="2%" /></span>
                    </div>
                </div>
            </div>







            <div class="row">
                <div class="input-field col s3" style="margin-left: 10px;">
                    <span>Financial Year</span>
                    <select (change)="onSelectFinalyear($event.target.value)" data-toggle="tooltip"
                        data-placement="top" title="Calender">
                        <option selected value="0">Select Financial Year</option>
                        <option *ngFor="let lst of finanyealst" [value]="lst.id"
                            [selected]="lst.id==model.id">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
               <!-- <div class="input-field col s3" style="margin-left: 10px;">
                        <span>Financial Year</span>
                        <select (change)="onSelectFinalyear($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Calender">
                            <option selected value="0">Select Financial Year</option>
                            <option *ngFor="let lst of finanyealst" [value]="lst.id"
                                [selected]="lst.id==model.financialyearid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div> -->
                <div class="input-field col s4">
                    <span>Currency</span>
                    <select (change)="onSelectCurrency($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Currency">
                        <option selected value="0">Select Currency</option>
                        <option *ngFor="let lst of defaultcurrencylst" [value]="lst.id"
                            [selected]="lst.id==model.defaultcurrency">
                            {{lst.name}}
                        </option>
                    </select>
                </div>

            </div>

            <div class="row">
                <div class="col s12 m12 l12 registerBgShadow">
                    <div class="col l12 m12 s12" style="background: gainsboro; padding: 1%;">
                        <div class="col l4 m4 s4" style="text-align:center;">
                            <span>Product Category</span>
                        </div>
                    </div>

                    <mat-accordion>
                        <mat-expansion-panel
                            *ngFor="let lst of model.targetlst| paginate: { itemsPerPage: 10, currentPage: p } ;let i = index "
                            style="padding-left: 1px;">
                            <mat-expansion-panel-header>
                                <div class="col l12 m12 s12" style="margin-top:25px">
                                    <div class=" col l4 m4 s4" style="text-align:center;">
                                        <span>{{lst.productcategoryname}}</span>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>


                            <div *ngIf="lst.promodellst" class="registerBgShadow col l12 m12 s12"
                                style="text-align: center; margin-top: 10px;">
                                <div class="col l12 m12 s12">
                                    <div class=" col s3" style="text-align:center;">
                                        <span>Product</span>
                                    </div>
                                    <div class=" col s2" style="text-align:center;">
                                        <span>Q1</span>
                                    </div>
                                    <div class=" col s2" style="text-align:center;">
                                        <span>Q2</span>
                                    </div>
                                    <div class=" col s2" style="text-align:center;">
                                        <span>Q3</span>
                                    </div>
                                    <div class=" col s2" style="text-align:center;">
                                        <span>Q4</span>
                                    </div>

                                </div>

                                <div class="row" *ngFor="let sublst of lst.promodellst">
                                    <div class="col l3 m3 s3" style="text-align:center;margin-top:30px">
                                        <span>{{sublst.productCode}}</span>
                                    </div>
                                    <div class="col l2 m2 s2" style="text-align:center">
                                        <td class="row">
                                            <div class="row col l12 m12 s12">
                                                <div class="col s2">
                                                    T
                                                </div>
                                                <div class="row col s5" style="text-align:center" *ngIf="model.q1flag">
                                                    <input [(ngModel)]="sublst.q1t1" #q1t1="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');" placeholder="Qty"
                                                        id="first1" data-toggle="tooltip" data-placement="top"
                                                        title="Targeted Qty" required>
                                                    <label id="lable1" for="first1"></label>
                                                </div>
                                                <div class="row col s5" style="text-align:center" *ngIf="!model.q1flag">
                                                    <label id="lable1" for="first1">{{sublst.q1t1}}</label>
                                                </div>


                                                <div class="row col s5" *ngIf="model.q1flag">
                                                    <input [(ngModel)]="sublst.q1v1" #q1v1="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');"
                                                        placeholder="Value" id="first2" data-toggle="tooltip"
                                                        data-placement="top" title="Targeted Value" required>
                                                    <label id="lable2" for="first2"></label>
                                                </div>
                                                <div class="row col s5" style="text-align:center" *ngIf="!model.q1flag">
                                                    <label id="lable2" for="first2">{{sublst.q1v1}}</label>
                                                </div>



                                                <div class="col s2">
                                                    A
                                                </div>
                                                <div class="row col s5" style="text-align:center">
                                                    <label id="lable3" for="first3">{{sublst.q1t2}}</label>
                                                </div>
                                                <div class="row col s5" style="text-align:center">
                                                    <label id="lable4" for="first4">{{sublst.q1v2}}</label>
                                                </div>

                                            </div>
                                        </td>
                                    </div>

                                    <div class="col l2 m2 s2" style="text-align:center">
                                        <td class="row">
                                            <div class="row col l12 m12 s12">

                                                <div class="row col s6" *ngIf="model.q2flag">
                                                    <input [(ngModel)]="sublst.q2t3" #q2t3="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');" id="first5"
                                                        data-toggle="tooltip" data-placement="top" placeholder="Qty"
                                                        title="Targeted Qty" required>
                                                    <label id="lable5" for="first5"></label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" style="text-align:center" *ngIf="!model.q2flag">
                                                    <label id="lable5" for="first5">{{sublst.q2t3}}</label>
                                                </div>

                                                <div class="row col s6" *ngIf="model.q2flag">
                                                    <input [(ngModel)]="sublst.q2v3" #q2v3="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');"
                                                        placeholder="Value" id="first6" data-toggle="tooltip"
                                                        data-placement="top" title="Targeted Value" required>
                                                    <label id="lable6" for="first6"></label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" *ngIf="!model.q2flag">
                                                    <label id="lable6" for="first6">{{sublst.q2t3}}</label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" style="text-align:center">
                                                    <label id="lable7" for="first7">{{sublst.q2t4}}</label>
                                                </div>
                                                <div class="col s1"></div>
                                                <div class="row col s3" style="text-align:center">
                                                    <label id="lable8" for="first8">{{sublst.q2v4}}</label>
                                                </div>

                                            </div>
                                        </td>
                                    </div>

                                    <div class="col l2 m2 s2" style="text-align:center">
                                        <td class="row">
                                            <div class="row col l12 m12 s12">
                                                <div class="row col l6 m6 s6" *ngIf="model.q3flag">
                                                    <input [(ngModel)]="sublst.q3t5" #q3t5="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');" id="first9"
                                                        data-toggle="tooltip" data-placement="top" placeholder="Qty"
                                                        title="Targeted Qty" required>
                                                    <label id="lable9" for="first9"></label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" style="text-align:center" *ngIf="!model.q3flag">
                                                    <label id="lable9" for="first9">{{sublst.q3t5}}</label>
                                                </div>
                                                <div class="row col l6 m6 s6" *ngIf="model.q3flag">
                                                    <input [(ngModel)]="sublst.q3v5" #q3v5="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');"
                                                        placeholder="Value" id="first10" data-toggle="tooltip"
                                                        data-placement="top" title="Targeted Value" required>
                                                    <label id="lable10" for="first10"></label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" *ngIf="!model.q3flag">
                                                    <label id="lable10" for="first10">{{sublst.q3t5}}</label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" style="text-align:center">
                                                    <label id="lable11" for="first11">{{sublst.q3t6}}</label>
                                                </div>
                                                <div class="col s1"></div>
                                                <div class="row col s3" style="text-align:center">
                                                    <label id="lable12" for="first12">{{sublst.q3v6}}</label>
                                                </div>

                                            </div>
                                        </td>
                                    </div>


                                    <div class="col l2 m2 s2" style="text-align:center">
                                        <td class="row">
                                            <div class="row col l12 m12 s12">
                                                <div class="row col l6 m6 s6" *ngIf="model.q4flag">
                                                    <input [(ngModel)]="sublst.q4t7" #q4t7="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');" id="first13"
                                                        data-toggle="tooltip" data-placement="top" placeholder="Qty"
                                                        title="Targeted Qty" required>
                                                    <label id="lable13" for="first13"></label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" style="text-align:center" *ngIf="!model.q4flag">
                                                    <label id="lable13" for="first13">{{sublst.q4t7}}</label>
                                                </div>
                                                <div class="row col l6 m6 s6" *ngIf="model.q4flag">
                                                    <input [(ngModel)]="sublst.q4v7" #q4v7="ngModel" type="number"
                                                        min="0" oninput="validity.valid||(value='');"
                                                        placeholder="Value" id="first14" data-toggle="tooltip"
                                                        data-placement="top" title="Targeted Value" required>
                                                    <label id="lable14" for="first14"></label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" *ngIf="!model.q4flag">
                                                    <label id="lable14" for="first14">{{sublst.q4v7}}</label>
                                                </div>
                                                <div class="col s2"></div>
                                                <div class="row col s4" style="text-align:center">
                                                    <label id="lable15" for="first15">{{sublst.q4t8}}</label>
                                                </div>
                                                <div class="col s1"></div>
                                                <div class="row col s3" style="text-align:center">
                                                    <label id="lable16" for="first16">{{sublst.q4v8}}</label>
                                                </div>

                                            </div>
                                        </td>
                                    </div>



                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
            <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 0px;">
                <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                    (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn">Save</button>

            </div>

        </div>
    </div>
</div>


<ng-template #localemodel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-content " style="text-align: center;">
        <span style="font-size: 16px;">Locales</span>

    </div>


    <div class="row">
        <div class="col l11 m11 s11">
            <Span style="text-size-adjust:16px">Locales </Span>
            <div>
                <label id="lable18" for="first18">{{model.localenames}}</label>
            </div>

        </div>
    </div>


</ng-template>