import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router/';
import { log } from 'console';
import { MasterServices } from '../../_services/MasterServices';
import { MenuServices } from 'src/app/_services/MenuServices';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.css']
})
export class AddMenuComponent implements OnInit {

  model: any = {};
  marked = false;
  landpage = false;
  menuid: any;
  menuName = [];
  modulelst: DropDownModel[];
  user: User;
  private sub: any;
  menuname: String;
  foredit: string;
  subpage: boolean = false;
  id: number;
  from: string;
  finalsequence:number=0;
  forstr: string;
  constructor(
    private router: Router, private Masterservice: MasterServices,
    private menuServices: MenuServices,
    private notifyService: NotificationService,
    private route: ActivatedRoute, ) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = + params['id'];
      this.from = params['from'];
      this.forstr = params['forstr'];
    });
    this.menuServices.getmodulelst(this.model)
    .subscribe(value => {
      this.modulelst = value;
    });
    this.model.from=this.from;
    if (this.id != null && this.from == "group") {
      this.model.resourcegroupid = this.id;
      this.model.userId =0;
    } else  if(this.model.from == "User"){
      this.model.userId = this.id
    }
    if (this.forstr == "new") {
      this.model.parentId = localStorage.getItem("parentid");
      

    }
    if (this.forstr == "edit") {
      this.model.id = localStorage.getItem("menuid");

      this.menuServices.getallmenudata(this.model)
        .subscribe(value => {
    
    this.model.modulename=value.modulename;
    this.model.pagename=value.pagename;
    this.model.sequence=value.sequence;
    this.model.userId=value.userId;
    this.model.id=value.id;
    this.menuName=value.pagelst;
    this.model.pageid=value.pageid;
    this.model.parentId=value.parentId;
    this.model.moduleid=value.moduleid;
    this.finalsequence=  this.model.sequence;
        this.model.resourcegroupid=value.resourcegroupid
          if (value.isSubMenu == "Y") {
            this.marked = true;
            this.subpage=true;
          }else{
            this.subpage=false;
          }
          if (value.landPage == "Y") {
            this.landpage = true;
          }
        });
    }else{
      this.model.parentId = localStorage.getItem('parentid');
      this.model.sequence = localStorage.getItem('sequence')
    }
     this.menuname=localStorage.getItem("Menuname");
     let l1 = (<HTMLInputElement>document.getElementById('lable4'));
     l1.className = "active";
    //   if (this.id != null && this.id > 0) {
    //     this.model.userId = this.id
    //   }else{

    
    //   }

    //   if (this.from != null && this.from!=""&&this.from=="group") {
    //     this.model.from = this.from
    //     this.model.resourcegroupid=this.id;
    //     this.model.userId=0;
    //   }
    //   this.model.parentId = localStorage.getItem('menuid');
    //   this.menuname=localStorage.getItem("Menuname");

    //  if(this.forstr=="edit"){
    //   this.menuServices.getallmenudata(this.model)
    //   .subscribe(value => {
    //     this.model=value;
    //     alert(value.isSubMenu);
    //     alert(value.landPage)

    //     if(value.isSubMenu=="Y"){
    //      this.marked=true
    //     }
    //     if(value.landPage=="Y"){
    //       this.landpage=true;
    //     }
    //   });

    //  }


  }
  toggleVisibility(e) {
    this.marked = e.target.checked;
  }
  togglelandpage(e) {
    this.landpage = e.target.checked;
  }
  cancle() {
    if (this.from == "group") {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.model.resourcegroupid,
          'from': "group"
        }
      };
      this.router.navigate(['contextmenue'], navigationExtras);
    }
     else if(this.from == "User"){
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.model.userId,
          'from': "User"
        }
      };
      this.router.navigate(['contextmenue'], navigationExtras);
    }

    // this.router.navigate(['contextmenucomponent']);

  }


  changeseq(){
   if(this.model.sequence<this.finalsequence){
     this.notifyService.showRroor("Sequence is Not Less Than Current","Oops");
     this.model.sequence=this.finalsequence;
     return;
   }
  }
  btnsave() {

    if(this.model.sequence<this.finalsequence){
      this.notifyService.showRroor("Sequence is Not Less Than Current","Oops");
      this.model.sequence=this.finalsequence;
      return;
    }
    this.model.groupId = localStorage.getItem('groupId');
    if (this.landpage) {
      this.model.landPage = 'Y';
    }
    if (this.marked) {
      this.model.isSubMenu = 'Y';
    }
    this.model.menuId = this.menuid;
    this.menuServices.savemenubyuser(this.model)
      .subscribe(value => {


        if (value.status == "OK") {
          this.notifyService.showSuccess(value.message, "  Success");
          if (this.model.from == "group") {
            let navigationExtras: NavigationExtras = {
              queryParams: {
                'id': this.id,
                'from': "group"
              }
            };
            this.router.navigate(['contextmenue'], navigationExtras);
          } else {
            let navigationExtras: NavigationExtras = {
              queryParams: {
                'id': this.model.userId,
                'from': this.model.from
              }
            };
            this.router.navigate(['contextmenue'], navigationExtras);
          }

        }
        //console.log('value  ' + value.message);
        //this.router.navigate(['home']);
      });

  }

  onChangeMenu(menuvalue) {
    this.model.pageid=menuvalue;
  }
  onChangeModule(moduleid) {
    this.model.moduleid = moduleid;
   
    this.menuServices.getmenuName(this.model)
      .subscribe(value => {
        this.menuName = value;
      });
  }

  toggleIssubmenu($event) {
    if ($event.target.checked == true) {
      this.subpage = true;
    } else {
      this.subpage = false;
    }
  }

}
