import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterServices, CompanyService, AlertService } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common';
import { User } from 'src/app/_models';
import { DefineAccountwisemachinedetailModel } from './DefineAccountwisemachinedetailModel';

declare var $: any;


@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineAccountwisemachinedetail.html'
})
export class DefineAccountwisemachinedetail implements OnInit,OnDestroy {
    sub: any;
    id: number;
    model = new DefineAccountwisemachinedetailModel();
    Date = new Date();
    userList2: any;
    machiestatuslst: DefineAccountwisemachinedetailModel[] = [];
    localelist: DefineAccountwisemachinedetailModel[] = [];
    productlist: DefineAccountwisemachinedetailModel[] = [];
    amctypelist: DefineAccountwisemachinedetailModel[] = [];
    user: any;
    users = new User();
    acclst: DefineAccountwisemachinedetailModel[] = [];
    pagename: string;
    // public machiestatuslst: DropDownModel[] = [
    //     { id: 0, "name": "Select Designation" },
    // ];
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location) { }
        
        ngOnDestroy(): void {
            localStorage.removeItem("pagename");
        }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });

        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;

            this.Masterservice.getaccwisedetailByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                    },
                    error => {
                        this.alertService.error(error);
                    });

            
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";
            let l5 = (<HTMLInputElement>document.getElementById('lable5'));
            l5.className = "active";
            let l6 = (<HTMLInputElement>document.getElementById('lable6'));
            l6.className = "active";
            let l7 = (<HTMLInputElement>document.getElementById('lable7'));
            l7.className = "active";
            let l8 = (<HTMLInputElement>document.getElementById('lable8'));
            l8.className = "active";
            let l9 = (<HTMLInputElement>document.getElementById('lable9'));
            l9.className = "active";
            
        }
        this.getmcstatus()
    }

    getmcstatus() {
        let user = new User();
        this.machiestatuslst = [];
        this.localelist = [];
        this.productlist = [];
        this.amctypelist = [];


        this.Masterservice.getmcstatus(user)
            .subscribe((value) => {
                this.machiestatuslst = value.mcstatuslst
                this.localelist = value.localelst
                this.productlist = value.productlst
                this.amctypelist = value.amctypelst


            });
    }


    onSelectmachinestatus(value) {
        if(value==0){
            this.notifyService.showRroor("Select Machine Status!!", "Oops!");
            return; 
        }
      
        this.model.tbservicecallstatus = value;
    }
    onSelectlocale(value) {
        if(value==0){
            this.notifyService.showRroor("Select Locale!!", "Oops!");
            return; 
        }
      
        this.model.localeName = value;
    }
    onSelectproduct(value) {
        if(value==0){
            this.notifyService.showRroor("Select Product!!", "Oops!");
            return; 
        }
        this.model.productid = value;
        this.Masterservice.getAccountwiseproduct(this.model)
            .subscribe(value => {
                this.model.categoryname = value.categoryname;
            })

    }
    onSelectamctype(value) {
        if(value==0){
            this.notifyService.showRroor("Select Amc Type!!", "Oops!");
            return; 
        }
        this.model.amcname = value;
    }

    btnsave($event) {
        
        if (this.model.accountid == null) {
            this.notifyService.showRroor("Enter Customername !!", "Oops!");
            return;
        }
        if (this.model.tbservicecallstatus == null) {
            this.notifyService.showRroor("Select Machine Status !!", "Oops!");
            return;
        }
        if (this.model.localeName == null) {
            this.notifyService.showRroor("Select Locale !!", "Oops!");
            return;
        }
        if (this.model.productid == null) {
            this.notifyService.showRroor("Select Product Model !!", "Oops!");
            return;
        }
        if (this.model.orderNo == null) {
            this.notifyService.showRroor("Enter orderNo  !!", "Oops!");
            return;
        }
        if (this.model.mcRefNo == null) {
            this.notifyService.showRroor("Enter valid Machine Ref.No !!", "Oops!");
            return;
        }
        if (this.model.serialNo == null) {
            this.notifyService.showRroor("Enter serialNo  !!", "Oops!");
            return;
        }
        if (this.model.warrantyMonths == null) {
            this.notifyService.showRroor("Enter warrantyMonths  !!", "Oops!");
            return;
        }
        if (this.model.amcname == null) {
            this.notifyService.showRroor("Select AMC Type  !!", "Oops!");
            return;
        }
        if (this.model.amcMcAmount == null) {
            this.notifyService.showRroor("Enter valid amcMcAmount !!", "Oops!");
            return;
        }
        if (this.model.installationHourCount == null) {
            this.notifyService.showRroor("Enter installationHourCount   !!", "Oops!");
            return;
        }
        if (this.model.siteAddr == null || this.model.siteAddr == "") {
            this.notifyService.showRroor("Select siteAddr !!", "Oops!");
            return;
        }
        if (this.model.remarks == null || this.model.remarks == "") {
            this.notifyService.showRroor("Select  remarks !!", "Oops!");
            return;
        }
       

        {
            this.Masterservice.savemcdtl(this.model)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                            this.router.navigate(['/Accountwise Machine Details']);
                        } else {
                            this.notifyService.showRroor(data.message, "Oops !")
                        }
                    },
                    error => {
                        alert("Something Wrong ")
                    });

        }
    }

    getUserIdsSecondtWay1($event) {
        let userId = (<HTMLInputElement>document.getElementById('dynamicUserIdsSecondWay1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.users.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.Masterservice.getAccountLikeForMchDtl(this.users)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect(id, name) {
        this.model.accountid = id;
        this.model.name = name;
        this.Masterservice.getAccountwismc(this.model)
            .subscribe(value => {
                this.model.address = value.address

            })

    }


    onChangeorderdate(date) {
        if (date > new Date()) {
            this.model.orderdate = new Date();
            this.notifyService.showRroor("You cannot select future date", "Oops !");
            return;
        }
    }
    onChangeinstallationdate(date) {
        if (date > new Date()) {
            this.model.installationdate = new Date();
            this.notifyService.showRroor("You cannot select future date", "Oops !");
            return;
        }
    }
    onChangewarrentystartdate(date) {
        if (date > new Date()) {
            this.model.warrentystartdate = new Date();
            this.notifyService.showRroor("You cannot select future date", "Oops !");
            return;
        }
    }
    onChangewarrentyenddate(date) {
        if (date > new Date()) {
            this.model.warrentyenddate = new Date();
            this.notifyService.showRroor("You cannot select future date", "Oops !");
            return;
        }
    }

    cancle() {
        this.router.navigate(["/Accountwise Machine Details"]);
    }

}