export class GSTgroupmastermodel {
    gsthrname: string;
    id: number;
    displayname: string;
    gsthdrid: number;
    userid: number;
    lsttax: gstelement[];
    lsttax2: gstelement[];
    chkinstate: boolean;
    chkoutstate: boolean;
}

export interface gstelement {
    id: number;
    taxelementid: number;
    gsthdrid: number;
    taxElementName: string;
    cheselect: boolean;
    persentage: number;
    value:number;

}
