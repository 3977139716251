import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { User } from "src/app/_models";
import { MasterServices, CompanyService, AlertService, } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { CustomerMergeModel } from "./CustomerMergeModel";
import { TransactionServices } from "src/app/_services";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { data } from "jquery";
import { __values } from "tslib";

@Component({
    selector: 'app-login',
    templateUrl: 'CustomerMerge.html'
})
export class CustomerMerge implements OnInit {
    userList2: any[];
    user = new User();
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute, private transactionService: TransactionServices,
        private router: Router) {
        this.pagename = this.router.url
    }
    model = new CustomerMergeModel();
    edit: boolean = false;
    fromcustomer = [];
    tocustomer = [];
    supervisiorAuth1 = [];
    supervisiorAuth = [];
    opportunitylst: CustomerMergeModel[] = [];
    keyword = 'name';
    keyword1 = 'name';
    AccountThreeSixtyModel: CustomerMergeModel[] = [];
    p: any;
    pagename: string;
    tocustomerName:string;
    fromcustomerName: String;
    ngOnInit(): void {

    }

    onChangeSearch(search: string) {
        this.user.message = search;


        // this.user.trnid = this.model.fromcustomerID

        this.Masterservice.getfromcustomerLike(this.user)
            .subscribe(data => {
                this.supervisiorAuth = [];
                data.forEach((item) => this.supervisiorAuth.push({ id: item.id, name: item.name })
                );
            });

    }
    selectEvent(item) {
        this.model.fromcustomerID = item.id;
        this.fromcustomerName = item.name;
        this.user.trnid = this.model.fromcustomerID;

        this.model.opportunitylst = [];
        this.model.installationlst = [];

        this.transactionService.getCustomerOpportunity(this.user)
            .subscribe(value => {
                this.model.opportunitylst = value.opportunitylst
                for (let i = 0; i < this.model.opportunitylst.length; i++) {
                    if (this.model.opportunitylst[i].status == 'Y') {
                        this.model.opportunitylst[i].status = "Open"

                    } else if (this.model.opportunitylst[i].status == 'N') {
                        this.model.opportunitylst[i].status = "Close"
                    }
                    else if (this.model.opportunitylst[i].status == 'W') {
                        this.model.opportunitylst[i].status = "Won"
                    }
                    else if (this.model.opportunitylst[i].status == 'L') {
                        this.model.opportunitylst[i].status = "Lost"
                    }
                    else if (this.model.opportunitylst[i].status == 'A') {
                        this.model.opportunitylst[i].status = "Abandoned"
                    }

                }
            });


        this.transactionService.getCustomerInstallation(this.user)
            .subscribe(value2 => {
                this.model.installationlst = value2.installationlst
                for (let i = 0; i < this.model.installationlst.length; i++) {
                    for (let i = 0; i < this.model.installationlst.length; i++)
                        if (this.model.installationlst[i].status == 'Y') {
                            this.model.installationlst[i].status = "Open"

                        } else if (this.model.installationlst[i].status == 'N') {
                            this.model.installationlst[i].status = "Close"
                        }

                }
            });


        this.transactionService.getfindservicecustomermearge(this.user)
            .subscribe(value => {
                this.model.servicecalllst = value.servicecalllst
                for (let i = 0; i < this.model.servicecalllst.length; i++) {
                    if (this.model.servicecalllst[i].status == 'Y') {
                        this.model.servicecalllst[i].status = "Not send"
                    } else if (this.model.servicecalllst[i].status == 'N') {
                        this.model.servicecalllst[i].status = "Invoice send done"
                    }
                }

            });

        this.Masterservice.getcustomerinfocustomermerarge(this.user)
            .subscribe(value => {
                this.model.accountlst = value.accountlst
                for (let i = 0; i < this.model.accountlst.length; i++) {

                }
            })




    }
    onFocused(e) {

    }

    onChangeSearch1(search: string) {
        this.user.message = search;
        this.user.trnid = this.model.tocustomerID

        this.Masterservice.getTocustomerLike(this.user)
            .subscribe(data => {
                this.supervisiorAuth1 = [];
                data.forEach((item) => this.supervisiorAuth1.push({ id: item.id, name: item.name })
                );
            });

    }
    selectEvent1(item) {
        this.model.tocustomerID = item.id;
        this.tocustomerName = item.name;

    }
    onFocused1(e) {

    }

    // getCustomerOpportunity() {

    // }
    selectEvent2(item) {
        this.model.fromcustomerID = item.id;
        this.model.fromcustomerName = item.name;
    }

    savecustomermerge() {

        if (this.model.tocustomerName == null) {
            this.notifyService.showRroor("Pls Select To Custmoer", "Oops!");
            return;
        }
        if (this.model.fromcustomerName == null) {
            this.notifyService.showRroor("Select Target Currency  !!", "Oops!");
            return;
        }
        this.model.tocustomerName =  this.tocustomerName;
        this.model.fromcustomerName = this.fromcustomerName;
        this.transactionService.savecustomermerge(this.model)
        .subscribe(
            data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                    this.router.navigate(['CRMHome']);
                } else {
                    this.notifyService.showRroor(data.message, "Oops !")
                }
            },
            error => {
                alert("Something Wrong ")
            });
    }

    cancle() {
        this.router.navigate(["CRMHome"]);


    }


}