<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="l12 m12 s12">
                    <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 9px;padding-left: 14px;">
                        <span>Search Criteria</span>
                        <select (change)=" onSelectProduct($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center;margin-top: 26px">
                        <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class=" input-field col s5 l5 m5" style="margin-top: 26px">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                        <button type="button" *ngIf="this.model.id==0" class="waves-effect waves-light btn"
                            style="margin-left: 5px;margin-right: 9px;" (click)="btnnew()">New</button>
                        <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                            (click)="openPrint()">Print</button>
                    </div>
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <div class="col l12 m12 s12">
                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="7%" style="text-align: left">Sr.No.</th>
                                        <th width="30%" style="text-align: left">Product Category</th>
                                        <th width="40%" style="text-align: left">Model</th>
                                        <!-- <th width="10%" style="text-align: left">Hour Flag</th> -->
                                        <th width="10%" style="text-align: left">Installation</th>
                                        <th width="13%" style="text-align: left">Actions</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of prolst | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td style="text-align: left">{{lst.srno}}</td>
                                            <td style="text-align: left">{{lst.name}}</td>
                                            <td style="text-align: left">{{lst.productCode}}</td>
                                            <!-- <td style="text-align: left">{{lst.hourFlag}}</td> -->
                                            <td style="text-align: left">{{lst.installationStatus}}</td>
                                            <td style="text-align: left"> <span><img
                                                        src="../../../assets/images/edit.png" (click)="btnedit(lst.id)"
                                                        data-toggle="tooltip" data-placement="top" title="Edit"
                                                        width="20px" /></span>&nbsp;&nbsp;<span><img
                                                        src="../../../assets/images/delete.png"
                                                        (click)="btndelts(lst.id)" data-toggle="tooltip"
                                                        data-placement="top" title="Delete" width="20px" /></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <span style="text-align:right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row" Style="display: none;" id="LibDocs">
                        <div class="col l12 m12 s12">
                            <div class="col s1">
                            </div>
                            <div class="col l10 m10 s10">
                                <table border="1px solid black;"
                                    style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                                    id="excel">
                                    <thead style="background: gainsboro;">
                                        <th width="7%" style="text-align: left">Sr.No.</th>
                                        <th width="17%" style="text-align: left">Product Category</th>
                                        <th width="38%" style="text-align: left">Model</th>
                                        <th width="38%" style="text-align: left">Description</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of prolst ">
                                            <td style="text-align: left">{{lst.srno}}</td>
                                            <td style="text-align: left">{{lst.name}}</td>
                                            <td style="text-align: left">{{lst.productCode}}</td>
                                            <td style="text-align: left">{{lst.description}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col s1">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>