<div *ngIf="isMobile==true">

    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>
        <nav class="grayTheme">
            <div class="nav-wrapper">
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a
                    (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>&nbsp;
            </li>
        </ul>
        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="selection no-pad-bot OprntCol" id="div1" style="padding: top 7px;">
        <h1 class="header center" style="margin-bottom:9px;">Despatch Orders</h1>
        <div class="container" style="width:94%;">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="srchBy p-0">
                        <div class="input-field col s12" style="margin-bottom:0px;">
                            <select (change)="onSelectSrch($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                            <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                                data-toggle="tooltip" data-placement="top" title="Search Text">
                            <div>
                                <div class="input-field col l12 m12 s12:w-20 right"
                                    style="text-align: end;margin-top: 30px;margin-bottom:0px;text-align:left;">
                                    <img src="../../../assets/images/search.png" style="margin-right: 8px;" width="25px"
                                        (click)="btnsearch($event.target.value)">
                                </div>
                            </div>

                            <div class="col s12">

                                <div class="tabInnerBox padding 20px" *ngFor="let ordrlst of orderlist">
                                    <div class="introTab">

                                        <label class="w-100 dis-block font-weight-bold ">{{ordrlst.srno}}</label>

                                        <label class="w-100 dis-block font-weight-bold ">{{ordrlst.oanumber}}</label>
                                        <label class="w-100 dis-block font-weight-bold ">{{ordrlst.oadate |
                                            date:"dd-MM-yyyy"}}</label>
                                        <label
                                            class="w-100 dis-block font-weight-bold ">{{ordrlst.customername}}</label>
                                        <label
                                            class="w-100 dis-block font-weight-bold ">{{ordrlst.customertype}}</label>
                                        <label class="w-100 dis-block font-weight-bold ">
                                            <a href="{{ordrlst.telephone}}">{{ordrlst.telephone}}</a></label>
                                        <label
                                            class="w-100 dis-block font-weight-bold  margin-down 20px ">{{ordrlst.status}}&nbsp;&nbsp;&nbsp;
                                            <span><img src="../../../assets/images/search-filter.jpg" width="25px"
                                                    id="ordrlst" (click)="onchkopen(ordrlst.id)" /></span>
                                            <span *ngIf="ordrlst.ordercomplete==false"><img
                                                    src="../../../assets/images/edit.png"
                                                    (click)="btnedit(ordrlst.id,ordrlst.quotationID)"
                                                    data-toggle="tooltip" data-placement="top" title="Edit"
                                                    width="20px margin-down 0px" /></span>

                                        </label>
                                        <div *ngFor="let sublst of ordrlst.invoicelst">
                                            <div class="introTab">
                                                <div *ngIf="ordrlst.chkopen">

                                                    <!-- <label class="w-100 dis-block font-weight-bold ">{{sublst.srno}}</label> -->
                                                    <label
                                                        class="w-100 dis-block font-weight-bold ">{{sublst.invNo}}</label>
                                                    <!-- <label class="w-100 dis-block font-weight-bold ">{{sublst.invDate |
                                                    date:"dd-MM-yyyy"}}</label> -->
                                                    <label class="w-100 dis-block font-weight-bold ">{{sublst.grandtotal
                                                        |
                                                        number:'1.2-2'}}</label>
                                                    <label><span><img src="../../../assets/images/printer.png"
                                                                data-toggle="tooltip" data-placement="top" title="Print"
                                                                width="20px" title="View Quotation" width="25px"
                                                                class="modal-trigger" href="#printQut"
                                                                (click)="btnprintInvo(Invoice,sublst.invId)" />
                                                        </span></label>



                                                </div>


                                            </div>
                                        </div>
                                    </div>














                                </div>









                                <!-- <div *ngIf="ordrlst.invoicelst.length==0" class="col l11 m11 s11 registerBgShadow"
                                    style="margin-left:50px;text-align: center;">
                                    <div class="col l12 m12 s12" style="background: aliceblue;text-align: center">
                                        <span>Invoices of {{ordrlst.oanumber}}</span>

                                    </div> -->







                            </div>
                            <div id="printQut" class="modal">
                                <div class="modal-content">
                                    <h4 class="orgTxt center-align">Tax Invoice</h4>
                                    <div class="row">
                                        <div class="tabInnerBox m-btm">
                                            <div class="introTab">
                                                <label class="w-100 font-weight-bold dis-block">Companyname:</label>
                                                <label class="w-100 dis-block">{{invModel.companyname}}</label>
                                                <label class="w-100 font-weight-bold dis-block">Company Address:</label>
                                                <label
                                                    class="w-100 valignTxt dis-block">{{invModel.companyaddress}}&nbsp;Phone
                                                    No:&nbsp;{{invModel.telephone}}</label>

                                                <label
                                                    class="w-100 font-weight-bold dis-block">GSTIN:&nbsp;{{invModel.companygstno}}</label>

                                            </div>
                                        </div>
                                        <!-- <div class="tabInnerBox m-btm" *ngFor="let lst of qutReportlst">
                                            <div class="introTab">
                                                <label class="w-50 font-weight-bold blueTxt ">Sr. No.</label>
                                                <label class="w-50 ">{{lst.srno}}</label>
        
                                                <label class="w-100  font-weight-bold blueTxt dis-block">Description of
                                                    Goods</label>
                                                <label class="w-100 dis-block">{{lst.name}}</label>
        
                                                <label class="w-50  font-weight-bold blueTxt ">HSN</label>
                                                <label class="w-50 ">{{lst.hsn}}</label>
        
                                                <label class="w-50  font-weight-bold blueTxt ">Quantity</label>
                                                <label class="w-50 ">{{lst.quantity | number:'1.2-2'}}</label>
        
                                                <label class="w-50  font-weight-bold blueTxt ">Rate</label>
                                                <label class="w-50 ">{{lst.amount | number:'1.2-2'}}</label>
        
                                                <label class="w-50  font-weight-bold blueTxt ">UMO</label>
                                                <label class="w-50 ">{{lst.uom}}</label>
        
                                                <label class="w-50  font-weight-bold blueTxt ">Total Amount</label>
                                                <label class="w-50 ">{{lst.totalproamount | number:'1.2-2'}}</label>
                                            </div>
                                        </div> -->


                                        <!-- <div class="grayBg" style="box-shadow: rgb(99 99 99 / 45%) 2px 2px 2px 2px;">
                                            <label class="widthR-100 font-weight-bold blueTxt dis-block"
                                                style="text-align: left;">Total In Words :</label>
                                            <label class="widthR-100 dis-block"
                                                style="text-align: left;">{{quoReport.totalinwords |
                                                uppercase}}&nbsp;ONLY</label>
                                          
                                            <label class="w-35 font-weight-bold blueTxt" style="text-align: left;">Total
                                                :</label>
                                            <label class="widthR-60" style="text-align: left;">&#x20b9; {{quoReport.totalamount
                                                |
                                                number:'1.2-2'}}</label>
                                        </div> -->


                                    </div>
                                    <div class="row">
                                        <div class="tabInnerBox m-btm">
                                            <div class="introTab">
                                                <label class="w-100 valignTxt dis-block">Invoice
                                                    No:&nbsp;{{invModel.invNo}}</label>
                                                <label class="w-100 font-weight-bold dis-block">Invoice Date:&nbsp;{{invModel.invDate | date:"dd-MM-yyyy"}}</label>
                                    <label class="w-100 valignTxt dis-block">Purchase order No:&nbsp;{{invModel.pono}}</label> 
                                    <label class="w-100 valignTxt dis-block">Purchase order Date:&nbsp;{{invModel.podate |
                                        date:"dd-MM-yyyy"}}</label> 
                                    <label class="w-100 valignTxt dis-block">Place of issue:&nbsp;{{invModel.place}}</label> 
                                    <label class="w-100 valignTxt dis-block">Terms of Payment:&nbsp;{{invModel.pterms}}</label> 
                                    <label width="50%"><span style="font-size: 16px">Name and Address</span><br><br>
                                        <span>{{invModel.customername}}</span><br>
                                        <span>{{invModel.customeraddress}}</span><br>
                                        <span>{{invModel.customerPhone}}</span><br>
                                        <span>GSTIN:&nbsp;{{invModel.customergstno}}</span></label> 
                                    <label width="50%"> <span style="font-size: 16px">Name and Invoice
                                        Address</span><br><br>
                                    <span>{{invModel.customerInvName}}</span><br>
                                    <span>{{invModel.customerInvaddress}}</span><br>
                                    <span>{{invModel.customerInvPhNo}}</span><br>
                                    <span>GSTIN:&nbsp;{{invModel.customerInvgstNo}}</span></label> 
                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let lst of invModel.invoiceProducts">
                                        <div class="tabInnerBox m-btm">
                                            <div class="introTab">
                                                <label class="w-100 valignTxt dis-block">Sr.No{{lst.srno}}</label>
                                                <label class="w-100 valignTxt dis-block">Description of Goods{{lst.name}}</label>
                                                <label class="w-100 valignTxt dis-block">HSN{{lst.hsn}}</label>
                                                <label class="w-100 valignTxt dis-block">Quantity{{lst.supplyquantity+" "+"["+lst.uom+"]"}}</label>
                                                <label class="w-100 valignTxt dis-block"*ngIf="lst.ratewithdiscount == null">Rate
                                                    {{lst.offeredPrice | number:'1.2-2'}}</label>
                                                <label class="w-100 valignTxt dis-block" *ngIf="lst.ratewithdiscount != null">GST % 
                                                    {{lst.ratewithdiscount | number:'1.2-2'}}</label>
                                                <label class="w-100 valignTxt dis-block">GST{{lst.gstpersentage}}&#37;</label>
                                                <label class="w-100 valignTxt dis-block">Total Amount {{lst.taxamount | number:'1.2-2'}}</label>
                                                <label class= "text-align: right;padding-right: 10px;">{{lst.totalproamount |
                                                    number:'1.2-2'}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <hr>
                                    <div class="modal-footer">
                                        <a href="#!" class="modal-close waves-effect btn waves-green btn-flat"
                                            style="margin-left: 5px;">Cancel</a>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>









<div *ngIf="isMobile==false">
    <app-header></app-header>
    <div class="container" style="width: 80%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Despatch Orders</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <!-- <div class="col l12 m12 s12" style="padding-left: 1px;"> -->
                <div class="row" style="padding-left: 10px;">
                    <!-- <div class="col l1"></div> -->
                    <div class="input-field col l3 m3 s3" style="margin-top: 14px;margin-bottom:0px;padding-left: 5px;">
                        <span>Search Criteria</span>
                        <select (change)="onSelectSrch($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-top: 30px;margin-bottom:0px;">
                        <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Text">
                        <label for="search">Search Text</label>
                    </div>

                    <div class="input-field col l1 m1 s1" style="text-align: end;margin-top: 30px;margin-bottom:0px;">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Search</button>

                    </div>
                    <div class="col l2 m2 s2">

                    </div>
                    <div class="col l1"></div>
                </div>

                <div class="row" style="padding-left: 1px;padding-right: 1px;">
                    <div class="col l12 m12 s12" style="padding-bottom: 10px;">
                        <div class="col l12 m12 s12" style="background: gainsboro; padding: 10px;">
                            <div class="col l1 m1 s1" style="text-align: left;width: 68px;">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">Sr.No.</span>
                            </div>
                            <div class="col l3 m3 s3" style="text-align: left;width: 167px;">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">OA Number</span>
                            </div>
                            <div class="col l2 m2 s2" style="text-align: left;width:118px;">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">OA Date</span>
                            </div>
                            <div class="col l3 m3 s3" style="text-align: left;width:250px;">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">Customer Name</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align: left;width:111px;">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">Customer Type</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align: left;width:111px;">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">Mobile Number</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align: left">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">Status</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align: center">
                                <span style="color: rgb(0, 136, 199);font-weight: bold">Actions</span>
                            </div>
                        </div>
                        <mat-accordion>
                            <mat-expansion-panel hideToggle
                                *ngFor="let ordrlst of orderlist | paginate: { itemsPerPage: 10, currentPage: p, totalItems:user.totalcount }"
                                style="padding-left: 1px;">
                                <mat-expansion-panel-header style="padding-left: 0px;padding-right: 0px">
                                    <div class=" col l12 m12 s12" style="padding: 1%;">
                                        <div class="col l1 m1 s1" style="text-align: left;width: 68px;">
                                            <span>{{ordrlst.srno}}</span>
                                        </div>
                                        <div class="col l3 m3 s3" style="text-align: left;width: 167px;">
                                            <span>{{ordrlst.oanumber}}</span>
                                        </div>
                                        <div class="col l2 m2 s2" style="text-align: left;width:118px;">
                                            <span>{{ordrlst.oadate | date:"dd-MM-yyyy"}}</span>
                                        </div>
                                        <div class="col l3 m3 s3" style="text-align: left;width:250px;">
                                            <span>{{ordrlst.customername}}</span>
                                        </div>
                                        <div class="col l1 m1 s1" style="text-align: left;width:111px;">
                                            <span>{{ordrlst.customertype}}</span>
                                        </div>
                                        <div class="col l1 m1 s1" style="text-align: left;width:111px;">
                                            <span>{{ordrlst.telephone}}</span>
                                        </div>
                                        <div class="col l1 m1 s1" style="text-align: left">
                                            <span>{{ordrlst.status}}</span>
                                        </div>
                                        <div class="col l1 m1 s1" style="text-align: center">
                                            <span *ngIf="ordrlst.ordercomplete==false"><img
                                                    src="../../../assets/images/edit.png"
                                                    (click)="btnedit(ordrlst.id,ordrlst.quotationID)"
                                                    data-toggle="tooltip" data-placement="top" title="Edit"
                                                    width="20px" /></span>
                                        </div>
                                    </div>
                                </mat-expansion-panel-header>
                                <div class="col l11 m11 s11 registerBgShadow">

                                    <div *ngFor="let sublst of ordrlst.invoicelst">
                                        <div class="col l1 m1 s1" style="text-align: center">
                                            <span>{{sublst.srno}}</span>
                                        </div>
                                        <div class="col l3 m3 s3" style="text-align: center">
                                            <span>{{sublst.invNo}}</span>
                                        </div>
                                        <div class="col l3 m3 s3" style="text-align: center">
                                            <span>{{sublst.invDate | date:"dd-MM-yyyy"}}</span>
                                        </div>
                                        <div class="col l3 m3 s3" style="text-align: center">
                                            <span>{{sublst.grandtotal | number:'1.2-2'}}</span>
                                        </div>
                                        <div class="col l2 m2 s2" style="text-align: center">
                                            <span><img src="../../../assets/images/printer.png"
                                                    (click)="btnprintInvo(Invoice,sublst.invId)" data-toggle="tooltip"
                                                    data-placement="top" title="Print" width="20px" /></span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="ordrlst.invoicelst.length==0" class="col l11 m11 s11 registerBgShadow"
                                    style="margin-left:50px;text-align: center;">
                                    <div class="col l12 m12 s12" style="background: aliceblue;text-align: center">
                                        <span>Invoices of {{ordrlst.oanumber}}</span>
                                    </div>
                                </div>
                                <!-- <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span> -->
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)">
                        </pagination-controls>
                    </span>
                </div>
                <!-- </div> -->
            </div>
        </div>
    </div>



    <ng-template #Invoice>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align:right ">
                    <button (click)="modalRef.hide()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnprint()" type="button" class="waves-effect waves-light btn">Print</button>
                </div>
            </div>
        </div>
        <div class="modal-content registerBgShadow" id="inv">
            <h5 style="text-align: center;">Tax Invoice</h5>
            <div class="col l12 m12 s12" style="border: 1px solid black;">
                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                        <span style="font-size: 16px">{{invModel.companyname}}</span>
                    </div>
                </div>
                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                        <span style="font-size: 16px">{{invModel.companyaddress}}&nbsp;Phone
                            No:&nbsp;{{invModel.telephone}}</span>
                    </div>
                </div>
                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12" style="border-bottom: 1px solid black; text-align: center;">
                        <span style="font-size: 16px">GSTIN:&nbsp;<span>{{invModel.companygstno}}</span></span>
                    </div>
                </div>


                <div class="row" style="margin: 0px;">
                    <div class="col l12 m12 s12">

                        <table border="1px solid black;"
                            style="width: 100%;border-collapse: collapse; padding-top: 10px;" cellpadding="7">
                            <tbody>
                                <tr>
                                    <td width="50%">Invoice No:&nbsp;{{invModel.invNo}}</td>
                                    <td width="50%">Invoice Date:&nbsp;{{invModel.invDate | date:"dd-MM-yyyy"}}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Purchase order No:&nbsp;{{invModel.pono}}</td>
                                    <td width="50%">Purchase order Date:&nbsp;{{invModel.podate |
                                        date:"dd-MM-yyyy"}}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Place of issue:&nbsp;{{invModel.place}}</td>
                                    <td width="50%">Terms of Payment:&nbsp;{{invModel.pterms}}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><span style="font-size: 16px">Name and Address</span><br><br>
                                        <span>{{invModel.customername}}</span><br>
                                        <span>{{invModel.customeraddress}}</span><br>
                                        <span>{{invModel.customerPhone}}</span><br>
                                        <span>GSTIN:&nbsp;{{invModel.customergstno}}</span>
                                    </td>
                                    <td width="50%"> <span style="font-size: 16px">Name and Invoice
                                            Address</span><br><br>
                                        <span>{{invModel.customerInvName}}</span><br>
                                        <span>{{invModel.customerInvaddress}}</span><br>
                                        <span>{{invModel.customerInvPhNo}}</span><br>
                                        <span>GSTIN:&nbsp;{{invModel.customerInvgstNo}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row" style="margin: 0px;">
                    <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px;"
                        cellpadding="7">
                        <thead>
                            <th style="text-align: left;padding-left: 10px;">Sr.No.</th>
                            <th style="text-align: left">Description of Goods</th>
                            <th style="text-align: left">HSN</th>
                            <th style="text-align: right">Quantity</th>
                            <th style="text-align: right">Rate</th>
                            <th style="text-align: right">GST %</th>
                            <th style="text-align: right">GST</th>
                            <!-- <th style="text-align:right">Ammout</th> -->
                            <th style="text-align: right;padding-right: 10px;">Total Amount</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of invModel.invoiceProducts">
                                <td style="text-align: left;padding-left: 10px;">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.name}}</td>
                                <td style="text-align: left">{{lst.hsn}}</td>
                                <td style="text-align: right">{{lst.supplyquantity+" "+"["+lst.uom+"]"}}</td>
                                <td style="text-align: right;">
                                    {{lst.offeredPrice | number:'1.2-2'}}</td>
                                    <!-- *ngIf="lst.ratewithdiscount == null" -->
                                <!-- <td style="text-align: right" *ngIf="lst.ratewithdiscount != null">
                                    {{lst.ratewithdiscount | number:'1.2-2'}}</td> -->
                                <td style="text-align: right">{{lst.gstpersentage}}&#37;</td>
                                <td style="text-align: right">{{lst.taxamount | number:'1.2-2'}}</td>
                                <!-- <td style="text-align: right;padding-right: 10px;">{{lst.totalproamount | number:'1.2-2'}}</td> -->
                                <td style="text-align: right;padding-right: 10px;">{{lst.noGstAmmount | number:'1.2-2'}}</td> 
                                
                            </tr>
                            <tr>
                                
                                <td></td>
                                <td style="text-align: right;"><B>Without GST</B></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px; ">{{invModel.totalgst |
                                    number:'1.2-2'}}</td>
                                    
                                    <td style="text-align: right;padding-right: 10px; ">{{invModel.totalcount |
                                         number:'1.2-2'}}</td>
                                </tr>
                                <!-- <tr>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;">Gst Total</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;padding-right: 10px; ">{{invModel.totalcount |
                                         number:'1.2-2'}}</td>
                                    </tr> -->
                                <tr>
                                </tr>
                                <tr>
                                </tr>
                                <tr>
                                <td></td>
                                <td style="text-align: right;"><B>Gross Invoice</B></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px; ">{{invModel.netamount |
                                    number:'1.2-2'}}</td>
                            </tr>
                            <tr *ngFor="let tax of invModel.taxlst">
                                <td></td>
                                <td style="text-align: right;">{{tax.taxElementName}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td> </td>
                                <td style="text-align: right; padding-right: 10px;">{{tax.value | number:'1.2-2'}}
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                                <td style="text-align: right"><B>Discount Amount</B></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{invModel.discountamount |
                                    number:'1.2-2'}}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="text-align: right"><B>Net Amount</B></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;padding-right: 10px;">{{invModel.grandtotal |
                                    number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" style="margin: 0px; border:1px solid black">
                    <div class="col l12 m12 s12">
                        <span style="font-size: 15px">Total value in words:&nbsp;<span>{{grandtotalinwords |
                                uppercase}}&nbsp;ONLY</span></span>
                    </div>
                </div>
                <div class="row" style="margin: 0px;">
                    <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px;"
                        cellpadding="7">
                        <tbody>
                            <tr>
                                <td width="60%">
                                    <span style="font-weight: bold">Bank Details</span><br>
                                    <span>Name of the Account:Easy Dry Systems Pvt Ltd</span><br>
                                    <span>Name of the Bank:ICICI Bank</span><br>
                                    <span>Current Account No.:033805007645</span><br>
                                    <span>Branch:Kothrud,Pune.</span><br>
                                    <span>IFSC Code:ICIC0000338</span>
                                </td>

                                <td width="40%">
                                    <span>For <b>{{invModel.companyname}}</b></span><br><br><br><br><br>

                                    <span>Authorised Signatory</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col l12 m12 s12">
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align:right ">
                    <button (click)="modalRef.hide()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnprint()" type="button" class="waves-effect waves-light btn">Print</button>
                </div>
            </div>
        </div>
    </ng-template>
</div>