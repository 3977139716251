import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SalesReturnModel } from "./SalesReturnModel";
@Component({
    selector: 'app-DefinePaymentReceivableReceipt',
    templateUrl: 'SalesReturn.html',
})
export class SalesReturn implements OnInit {
    constructor(
        private transactionservice: TransactionServices,
        private router: Router,
        private notifyService: NotificationService,
    ) { }
    keyword = 'name';
    model = new SalesReturnModel();
    user = new User();
    customerlst = [];
    invlst: SalesReturnModel[];
    invdtllst: SalesReturnModel[];
    locationlst: DropDownModel[];
    customerName: string;
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getInventoryLocation();
    }

    onChangeSearch(search: string) {
        this.user.message = search;
        this.user.trnid = this.model.customerid
        this.transactionservice.getCustomerInvLike(this.user)
            .subscribe(data => {
                this.customerlst = [];
                data.forEach((item) => this.customerlst.push({ id: item.id, name: item.name })
                );
            });
    }
    selectEvent(item) {
        this.model.customerid = item.id;
        this.model.customerName = item.name;
        this.model.custName = this.model.customerName;

        this.invlst = [];
        this.user.trnid = this.model.customerid
        this.transactionservice.getInvoicelst(this.user)
            .subscribe((value) => {
                this.invlst = value
            });
    }

    onFocused(e) {
    }
    onSelectInvoice(value) {
        this.model.invoiceNo = value;
        for (let i = 0; i < this.invlst.length; i++) {
            if (this.invlst[i].invoiceNo == value) {
                this.model.invoceDate = this.invlst[i].invoceDate;
                this.model.id = this.invlst[i].id;
            }
        }
        this.user.userid = this.model.customerid;
        this.user.trnid = this.model.id;
        this.transactionservice.getInvoicedtl(this.user)
            .subscribe((value) => {
                this.invdtllst = value

                for (let i = 0; i < this.invdtllst.length; i++) {
                    this.model.totalAmount = this.invdtllst[i].totalAmount;
                }
            });
    }
    btnselectAllProduct($event) {
        for (let i = 0; i < this.invdtllst.length; i++) {
            if ($event.target.checked) {
                this.invdtllst[i].chkselectpc = true;
            } else {
                this.invdtllst[i].chkselectpc = false;
                if (this.invdtllst[i].returnedquantity != null) {
                    this.invdtllst[i].returnedquantity = 0;
                }
            }
        }
    }
    btnselectProduct($event, id) {
        for (let i = 0; i < this.invdtllst.length; i++) {
            if (this.invdtllst[i].id == id) {
                if ($event.target.checked == true) {
                    this.invdtllst[i].chkselectpc = true;
                } else {
                    this.invdtllst[i].chkselectpc = false;
                }
            }
        }
    }
    getInventoryLocation() {
        this.locationlst = [];
        this.transactionservice.getInventoryLocation(this.user)
            .subscribe(data => {
                this.locationlst = data.locationLst
            });
    }
    onSelectInventoryLocation(val) {
        this.model.locationid = val;
    }
    onChangeQuantity() {
        this.model.crdtTotalAmount = 0;
        for (let i = 0; i < this.invdtllst.length; i++) {
            this.invdtllst[i].gstretun = this.invdtllst[i].gstPer;
            this.invdtllst[i].netAmount = this.invdtllst[i].returnedquantity * this.invdtllst[i].rate;
            this.invdtllst[i].tax = this.invdtllst[i].netAmount * this.invdtllst[i].gstretun / 100;
            this.invdtllst[i].crdtAmount = Math.round(this.invdtllst[i].netAmount + this.invdtllst[i].tax);
            this.model.crdtTotalAmount = Math.round(this.model.crdtTotalAmount + this.invdtllst[i].crdtAmount);
            this.model.taxTotal = Math.round(this.model.taxTotal + this.invdtllst[i].tax);
        }
    }

    btnsave() {
        this.model.customerName = this.model.custName
        this.model.invdtllst = this.invdtllst;
        this.transactionservice.saveSalesReturn(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['/CRMHome']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
    cancle() {
        this.router.navigate(['/CRMHome']);
    }
}