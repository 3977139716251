<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="l12 m12 s12">
                    <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 9px;padding-left: 14px;">
                        <span>Search Criteria</span>
                        <select (change)="onSelectSrch($event.target.value)">
                            <option *ngFor="let type of searchtype" [value]="type.name">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col s3 l3 m3" style="text-align: center;margin-top: 26px;">
                        <input [(ngModel)]="model.txtserch" #txtserch="ngModel" type="text" class="" id="search"
                            data-toggle="tooltip" data-placement="top" title="Search Field">
                        <label for="search">Search Text</label>
                    </div>
                    <div class=" input-field col s5 l5 m5" style="margin-top: 26px;">
                        <button type="button" (click)="btnsearch($event)"
                            class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                            (click)="btnnew()">New</button>&nbsp;&nbsp;
                        <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                            (click)="openPrint()">Print</button>
                    </div>
                </div>
            </div>

            <!-- <div class="row" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                (scrolled)="onScroll()" style="height: 400px; overflow-y: scroll;"> -->
            <div class="col l12 m12 s12">
                <!-- <div style="overflow-y: auto; height: 450px;"> -->
                <table>
                    <thead style="background: gainsboro;">
                        <th width="40%" style="text-align: left">Locality</th>
                        <th width="20%" style="text-align: left">Pincode</th>
                        <th width="30%" style="text-align: left">City</th>
                        <th width="10%" style="text-align: left">Actions</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of localitylst  | paginate: { itemsPerPage: 10, currentPage: p, totalItems:user.totalItems}">
                            <td style="text-align: left">{{lst.locality}}</td>
                            <td style="text-align: left">{{lst.pincode}}</td>
                            <td style="text-align: left">{{lst.city}}</td>
                            <td style="text-align: left"><span><img src="../../../assets/images/edit.png"
                                        (click)="btnedit(lst.id)" data-toggle="tooltip" data-placement="top"
                                        title="Edit" width="20px" /></span>&nbsp;&nbsp;<span><img
                                        src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                        data-toggle="tooltip" data-placement="top" title="Delete" width="20px" /></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align:right">
                    <pagination-controls (pageChange)="p = $event" (click)="showPageIndex(p)"></pagination-controls>
                </span>
                <!-- </div> -->
                <!-- <div class="row" style="margin-top: 10px;">
                        <div class="col s8">
                        </div>
                        <div class="col s4" style="text-align: center;">
                            <button *ngIf="Curent>10" type="button" (click)="btngetprev()"
                                class="waves-effect waves-light btn">
                                << Pre</button> <button type="button" class="waves-effect waves-light btn"
                                    style="margin-left: 5px;" (click)="btngetNext()">Next >>
                            </button>
                        </div>
                    </div> -->
            </div>

            <div class="row" style="display:none" id="LibDocs">
                <div class="col l12 m12 s12">
                    <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px; "
                        cellpadding="7" id="excel">
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: left">Sr.No.</th>
                            <th width="15%" style="text-align: left">Locality</th>
                            <th width="8%" style="text-align: left">Pincode</th>
                            <th width="15%" style="text-align: left">City</th>
                            <th width="15%" style="text-align: left">State</th>
                            <th width="15%" style="text-align: left">Country</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of localitylst">
                                <td style="text-align: left">{{lst.srno}}</td>
                                <td style="text-align: left">{{lst.locality}}</td>
                                <td style="text-align: left">{{lst.pincode}}</td>
                                <td style="text-align: left">{{lst.city}}</td>
                                <td style="text-align: left">{{lst.state}}</td>
                                <td style="text-align: left">{{lst.country}}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>