import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService, DealersService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchServiceChargesModel } from "./SearchServiceChargesModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'SearchServiceCharges.html'
})
export class SearchServiceCharges implements OnInit {
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private dealersService: DealersService) {
    }
    public i;
    p: any;
    id: number;
    model = new SearchServiceChargesModel();
    servicelst: SearchServiceChargesModel[] = [];
    servicelst1: SearchServiceChargesModel[] = [];
    user = new User();
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Sales Category" },
        { id: 3, "name": "Item Name" },
    ];
    ngOnInit(): void {
        this.getAllServiceCharges();
    }
    getAllServiceCharges() {
        this.Masterservice.getAllServiceCharges(this.user)
            .subscribe(value => {
                this.servicelst = value;
                this.servicelst1 = [];
                value.forEach((item) => this.servicelst1.push({ "id": item.id,"productid":item.productid, "productName": item.productName, "prodCatname": item.prodCatname, "productcategoryid":item.productcategoryid, "txtsearch": item.txtsearch, "txtselect": item.txtselect }));           
                this.servicelst = this.servicelst1;
            });
    }
    onSelectsearch(value) {
        this.model.txtselect = value;
    }
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllServiceCharges()
            this.model.txtsearch = null;
        }
        else if (this.model.txtselect == "Sales Category") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Sales Category to Search")
                return;
            }
            else {
                this.servicelst1 = [];
                this.servicelst.forEach((item) => {
                    if ((item.prodCatname.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.servicelst1.push({ "id": item.id, "productName": item.productName, "productid":item.productid,"prodCatname": item.prodCatname,  "txtsearch": item.txtsearch, "productcategoryid":item.productcategoryid,"txtselect": item.txtselect });
                    }
                });
                this.servicelst = this.servicelst1;
            }
        }
        else if (this.model.txtselect == "Item Name") {
            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Item Name To Search")
                return;
            }
            else {

                this.servicelst1 = [];
                this.servicelst.forEach((item) => {
                    if ((item.productName.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.servicelst1.push({ "id": item.id, "productName": item.productName, "productid":item.productid,"prodCatname": item.prodCatname, "txtsearch": item.txtsearch,"productcategoryid":item.productcategoryid, "txtselect": item.txtselect });
                    }
                });
                this.servicelst = this.servicelst1;
            }
        }
    }
    btnadd($event) {
        this.router.navigate(['/newDefineServiceCharges']);
    }
    btnedit(id) {
        this.router.navigate(['/editDefineServiceCharges', id]);
    }
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
                this.Masterservice.dltServiceChargLoc(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }

    }

}