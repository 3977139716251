export class CustomerTypeWorkflowModel {
    id: number;
    typeid: number;
    userid: number;

    chkSalesEnq:boolean;
    chkdealer:boolean;
   

    chkTC: boolean;
    chksendQuo: boolean;
    chkemail: boolean;
    chksms: boolean;
    chkshwgst: boolean;
    chkschemeflag:boolean;

    //group1
    chkOAbycmpy: boolean;
    chkOAbyndcust: boolean;
    ordraccptnce: string;

    chkSupplier:boolean;

    //group2
    chkINScmpny: boolean;
    chkINScust: boolean;
    chkINSdealer: boolean;
    installation: string;
    chkUnitinstallation:boolean;

    //group3
    chkSERcmpny: boolean;
    chkSERcust: boolean;
    chkSERdealer: boolean;
    service: string;

    chkSERTC: boolean;
    chkSERsendQuo: boolean;
    chkSERemail: boolean;
    chkSERsms: boolean;
    chkSERshwgst: boolean;

    //group4
    chkagnstOr: boolean;
    chkagnstInv: boolean;
    chkagnstInst: boolean;
    payment: string;



    //DemoGraphics
    chknoofemployee:boolean;
    mandchkemp:boolean;
    chkanualtrnovr:boolean;
    mandchktrnovr:boolean;
    chkindstype:boolean;
    mandindtype:boolean;
    industryid:number;
    industrytypename:string;

    chkdesignation:boolean;
    mandDesignation:boolean;
    designationid:number;
    designationname:string;

    chksupervisingAuth:boolean;
    manSupervising:boolean;

    chkINSApprovcmpny:boolean;
    easydryflag:string;
    fieldflag:string;
    supervisorflag:string;
    customerflag:string;
    chkfield:boolean;
    chksupervisor:boolean;
    chkCustomer:boolean;
    chkSalesEnqdel:boolean;

}