export class DefinePaymentReceivableReceiptModel{
    id:number;
    userid:number;
    partyname: string;
    partyid: number;
    invoicelst: Invoicelist[];
    bankname: string;
    chequeNo:string;
    paymenttype: string;
    paymentDate: Date;
    bankcharges: number;
    netAmount: number;
    grossAmount: number;
    balanceAmount:number;
    remarks:string;
    customertypeName:string;
    gstno:string;
}

export class Invoicelist {
    srno: number;
    id: number;
    invNo: string;
    invDate: Date;
    grandtotal: number;
    paidamount:number;
    balanceAmount:number;
}