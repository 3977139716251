import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { BsCurrentDateViewComponent } from "ngx-bootstrap/datepicker/themes/bs/bs-current-date-view.component";
import { AreaModel1 } from "src/app/Transactions/Teams/TeamModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel";
import { OpportunityReportModel } from "../Opportunity Report/OpportunityReportModel";
import { OrdInHandReportModel } from "../Order In Hand Report/OrdInHandReportModel";
import { FiringformModel } from "./FiringformModel";

@Component({
    selector: 'app-login',
    templateUrl: 'Firingform.html'
})
export class Firingform implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices,
        private modalService: BsModalService,
        private reportServices: ReportServices,
        private companyservice: CompanyService,
        private notifyService: NotificationService,
        private alertService: AlertService,
        private userService: MasterServices,
        private route: ActivatedRoute,
        private router: Router) {
    }
    fmodel = new FiringformModel();
    model = new DCRreportModel();
    omodel = new OrdInHandReportModel();
    opmodel = new OpportunityReportModel();
    user = new User();
    regionlst: DropDownModel[] = [];
    arealst: AreaModel1[] = [];
    resourcelst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    citylst: DropDownModel[] = [];
    teamlst: DropDownModel[] = [];
    companyname1: string;
    companyaddress: String;

    id: number;
    srlst: DCRreportModel[] = [];
    dsrlst: DCRreportModel[] = [];
    eqrlst: DCRreportModel[] = [];

    regionName: string;
    areaName: string;
    cityName: string
    branchName: string;
    resourceName: string;
    teamName: string;
    p: any;
    timing = new Date();
    username: string;
    pagename: string;
    routingname: string;
    regionid: number;
    areaid: number;
    cityid: number;
    branchid: number;
    teamid: number;
    resourceID: number;
    statusid: number;
    frmdate: Date;
    todate: Date;

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Open" },
        { id: 3, "name": "Close" },
    ];
    public exewiseQuotation: DropDownModel[] = [
        { id: 1, "name": "Open" },
        { id: 2, "name": "Lost" },

    ];
    public exewiseQuotnDtl: DropDownModel[] = [
        { id:1,"name":"All"},
        { id: 2, "name": "Undecided" },
        { id: 3, "name": "Won" },
        { id: 4, "name": "Postponed" },
        { id: 5, "name": "Completed" },
        { id:6,"name":"Lost"},

    ];
    public opportunity: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Open" },
        { id: 3, "name": "Close" },
        { id: 4, "name": "Accepted" },
        { id: 5, "name": "Rejected" },
        { id: 6, "name": "Abandoned" },
    ];
    public instaSummary: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Unassigned" },
        { id: 3, "name": "Installed" },
        { id: 4, "name": "In progress" },
        
    ];
    public opencloseenquiry: DropDownModel[] = [
        { id: 1, "name": "Open" },
        { id: 2, "name": "Rejected" },
        { id: 3, "name": "In Progress" }
    ];

    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.user.userid = Number(localStorage.getItem("userid"));
        this.routingname = localStorage.getItem('commonrouting');
        this.model.regionid = Number(localStorage.getItem("regionid"));
        this.model.areaid = Number(localStorage.getItem("areaid"));
        this.omodel.cityid = Number(localStorage.getItem("cityid"));
        this.model.branchid = Number(localStorage.getItem("branchid"));
        this.opmodel.teamid = Number(localStorage.getItem("teamid"));
        this.model.resourceID = Number(localStorage.getItem("resourceID"));
        this.model.status = localStorage.getItem('status');
        this.model.teamtype = localStorage.getItem('teamtype');
        this.regionName = localStorage.getItem('regionName');
        this.areaName = localStorage.getItem('areaName');
        this.branchName = localStorage.getItem('branchName');
        this.model.frmdate = new Date()
        this.model.frmdate.setMonth(this.model.frmdate.getMonth()-1)
        this.model.todate = new Date()
        this.getRegions();
    }
    selectFromDate(From) {
        if (From > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.frmdate = new Date()
            return;
        }
    }
    selectToDate(date) {
        if (date < this.model.frmdate) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.todate = new Date()
            return;
        }
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.todate = new Date()
            return;
        }
    }

    getRegions() {
        this.regionlst = []
        this.Masterservice.getAllRegions(this.user)
            .subscribe(value => {
                this.regionlst = value
            });

        if (this.model.regionid != null) {
            this.regionid = this.model.regionid
            this.user.trnid = this.model.regionid
            this.Masterservice.getAreaByRegion(this.user)
                .subscribe(value => {
                    this.arealst = value
                });

            if (this.model.areaid != null) {
                this.areaid = this.model.areaid
                this.citylst = [];
                this.model.areaintlst = [];
                this.model.areaintlst.push(this.model.areaid)
                this.reportServices.getCityByarea(this.model)
                    .subscribe(value => {
                        this.citylst = value
                    });

                if (this.omodel.cityid != null) {
                    this.cityid = this.omodel.cityid
                    this.branchlst = [];
                    this.omodel.cityintlst = [];
                    this.omodel.cityintlst.push(this.omodel.cityid)
                    this.reportServices.getBranchBycity(this.omodel)
                        .subscribe(value => {
                            this.branchlst = value
                        });

                    if (this.model.branchid != null) {
                        this.branchid = this.model.branchid
                        this.teamlst = [];
                        this.model.branchintlst = [];
                        this.model.teamtype = localStorage.getItem('teamtype');
                        this.model.branchintlst.push(this.model.branchid)
                        this.model.userid = this.user.userid
                        this.reportServices.getTeamByBranch(this.model)
                            .subscribe(value => {
                                this.teamlst = value
                            });

                        if (this.opmodel.teamid != null) {
                            this.teamid = this.opmodel.teamid
                            this.resourcelst = [];
                            this.opmodel.teamintlst = [];
                            this.opmodel.teamintlst.push(this.opmodel.teamid)
                            this.opmodel.userid = this.user.userid
                            this.reportServices.getResourceByTeam(this.opmodel)
                                .subscribe(value => {
                                    this.resourcelst = value
                                });

                            this.resourceID = this.model.resourceID
                            this.model.resourceintlst = [];
                            this.model.resourceintlst.push(this.model.resourceID)
                        }

                    }
                }
            }
        }

    }
    onSelectRegions(val) {
        this.arealst = [];
        if (val == 0) {
            this.notifyService.showRroor("Select Region!!", "Oops!");
            return;
        }
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.model.name = this.user.message;
                this.regionName = this.user.message;
            }

        } else {
            this.model.regionid = val
            for (let i = 0; i < this.regionlst.length; i++) {
                if (this.regionlst[i].id == val) {
                    this.regionName = this.regionlst[i].name
                    this.regionid = this.regionlst[i].id
                }
            }
            this.user.trnid = val;
        }

        this.Masterservice.getAreaByRegion(this.user)
            .subscribe(value => {
                this.arealst = value
            });
    }
    onSelectArea(val) {
        this.model.areaintlst = [];
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.areaName = this.user.message
            }

            for (let i = 0; i < this.arealst.length; i++) {
                this.model.areaintlst.push(this.arealst[i].areaid)
            }
        } else {
            this.model.areaid = val;
            for (let i = 0; i < this.arealst.length; i++) {
                if (this.arealst[i].areaid == val) {
                    this.areaName = this.arealst[i].areaname
                    this.areaid = this.arealst[i].areaid
                }
            }
            this.model.areaintlst.push(this.model.areaid)
        }
        // this.branchlst = [];
        // this.reportServices.getBranchByarea(this.model)
        //     .subscribe(value => {
        //         this.branchlst = value
        //     });
        this.citylst = [];
        this.reportServices.getCityByarea(this.model)
            .subscribe(value => {
                this.citylst = value
            });
    }
    onSelectCity(val) {
        this.omodel.cityintlst = [];
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.cityName = this.user.message
            }
            for (let i = 0; i < this.citylst.length; i++) {
                this.omodel.cityintlst.push(this.citylst[i].id)
            }
        } else {
            this.omodel.cityid = val;
            for (let i = 0; i < this.citylst.length; i++) {
                if (this.citylst[i].id == val) {
                    this.cityName = this.citylst[i].name
                    this.cityid = this.citylst[i].id
                }
            }
            this.omodel.cityintlst.push(this.omodel.cityid)
        }
        this.branchlst = [];
        this.reportServices.getBranchBycity(this.omodel)
            .subscribe(value => {
                this.branchlst = value
            });

    }
    onSelectBranches(val) {
        this.model.branchintlst = []
        // this.model.branchname=val
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.branchName = this.user.message
            }

            for (let i = 0; i < this.branchlst.length; i++) {
                this.model.branchintlst.push(this.branchlst[i].id)
            }
        } else {
            this.model.branchid = val
            this.model.branchname=val
           for (let i = 0; i < this.branchlst.length; i++) {
                if (this.branchlst[i].id == val) {
                    this.branchName = this.branchlst[i].name
                    this.branchid = this.branchlst[i].id
                }
            }
            this.model.branchintlst.push(this.model.branchid)
        }
        // this.reportServices.getResourceByBranch(this.model)
        //     .subscribe(value => {
        //         this.resourcelst = value
        //     });
        if (this.routingname == "Installation Summary Report") {
            
            this.model.teamtype = "installation";
        }
        else if (this.routingname == "Completed Enquiries") {
            this.model.teamtype = "service";
        }
        else {
            this.model.teamtype = "sales";
        }
        if(this.routingname == "Pending / In Progress / Rejected Enquiries")
        {
            this.model.teamtype = "service";
        }
        this.model.userid = this.user.userid
        this.reportServices.getTeamByBranch(this.model)
            .subscribe(value => {
                this.teamlst = value
            });
    }
    onSelectTeam(val) {
        this.opmodel.teamintlst = []
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.teamName = this.user.message
            }

            for (let i = 0; i < this.teamlst.length; i++) {
                this.opmodel.teamintlst.push(this.teamlst[i].id)
                
            }
        }
        else {
            this.opmodel.teamid = val;
        //    this.opmodel.customerName =val;
            for (let i = 0; i < this.teamlst.length; i++) {
                if (this.teamlst[i].id == val) {
                    this.teamName = this.teamlst[i].name
                    this.teamid = this.teamlst[i].id
                }
            }
            this.opmodel.teamintlst.push(this.opmodel.teamid)
        }
        this.opmodel.userid = this.user.userid
        this.reportServices.getResourceByTeam(this.opmodel)
            .subscribe(value => {
                this.resourcelst = value
            });
    }
    onSelectResource(val) {
        this.model.resourceintlst = []
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.resourceName = this.user.message
            }

            for (let i = 0; i < this.resourcelst.length; i++) {
                this.model.resourceintlst.push(this.resourcelst[i].id)
            }
        } else {
            for (let i = 0; i < this.resourcelst.length; i++) {
                if (this.resourcelst[i].id == val) {
                    this.resourceID = this.resourcelst[i].id
                }
            }
            this.model.resourceID = val;
            this.model.resourceintlst.push(this.model.resourceID)
        }

    }
    onSelectStatus(value) {
        this.model.status = value;
    }
    btnshow() {
        localStorage.setItem("regionName", this.regionName);
        localStorage.setItem("areaName", this.areaName);
        localStorage.setItem("branchName", this.branchName);
        localStorage.setItem('testObject', JSON.stringify(this.model));
        localStorage.setItem("status", this.model.status);

        if (this.model.frmdate != null) {
            localStorage.setItem("frmdate", this.model.frmdate.toString());
        }
        if (this.model.todate != null) {
            localStorage.setItem("todate", this.model.todate.toString());
        }
        if (this.regionid != null) {
            localStorage.setItem('regionid', this.regionid.toString());
        }
        if (this.areaid != null) {
            localStorage.setItem('areaid', this.areaid.toString());
        }
        if (this.cityid != null) {
            localStorage.setItem("cityid", this.cityid.toString());
        }
        if (this.branchid != null) {
            localStorage.setItem("branchid", this.branchid.toString());
        }
        if (this.teamid != null) {
            localStorage.setItem("teamid", this.teamid.toString());
        }
        if (this.resourceID != null) {
            localStorage.setItem("resourceID", this.resourceID.toString());
        }
        if (this.model.teamtype != null) {
            localStorage.setItem("teamtype", this.model.teamtype);
        }
        this.router.navigate(['/' + this.routingname]);

    }


}