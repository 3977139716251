<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Source Detail</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field  col s5" style="margin-top: 30px;">
                    <input [(ngModel)]="model.name" #name="ngModel" type="text" id="name" data-toggle="tooltip"
                        data-placement="top" title="Detail">
                    <label id="lable1" for="First1">Detail</label>
                </div>
                <div class="input-field  col s3" style="margin-top: 30px;">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                        style="margin-right: 5px;">Add</button>
                </div>
            </div>
            <div class="row">
                <div class="col l1"></div>
                <div class="col l10 m10 s10">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="80%" style="text-align: left">Detail</th>
                            <th width="20%" style="text-align: left">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of campaigndetaillst | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: left">{{lst.name}}</td>

                                <td style="text-align: left">
                                    <span><img src="../../../assets/images/edit.png" (click)="btnedit(lst.id,lst.name)"
                                            data-toggle="tooltip" data-placement="top" title="Edit"
                                            width="20px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Delete"
                                            width="20px" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
                <div class="col l1"></div>
            </div>
        </div>
    </div>
</div>