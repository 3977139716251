<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8" style="text-align: center;">
                <span style="font-size: 25px;">Select User</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row" style="margin-top:5px">
                <div class="col s4">
                </div>
                <div class="input-field col s4">
                    <select (change)="onSelectuser($event.target.value)">
                        <option *ngFor="let type of lstuser" [value]="type.id">
                            {{type.name}} </option>
                    </select>
                </div>
                <div class="col s4"></div>
                <div class="col l12 m12 s12">
                    <div class="row" style="margin-top:5px">
                        <div class="col s5">
                        </div>
                        <div class="input-field col s4">
                            <div class=" input- field col  s3" style="margin-top: 10px">
                                <button type="button" (click)="btngo()" class="waves-effect waves-light btn">Go</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>