
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { TransactionServices } from "src/app/_services";
import { MasterServices } from "src/app/_services/MasterServices";
import { NotificationService } from "src/app/_services/notification.service";
import { Location, } from '@angular/common';
import { OrderHomepageModel } from "./OrderHomepageModel";
import { User } from "src/app/_models";
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { DeviceDetectorService } from "ngx-device-detector";
import { last, first } from "rxjs/operators";
import { Mainmenumodel } from "src/app/_models/Mainmenumodel";
@Component({
    selector: 'app-order',
    templateUrl: 'OrderHomepage.html'
})
export class OrderHomepage implements OnInit {
    p: any;
    user = new User();
    id: number;
    status: string;
    model = new OrderHomepageModel();
    orderlst: OrderHomepageModel[] = [];
    orderlst1: OrderHomepageModel[] = [];
    sum: number = 1;
    isMobile: boolean;
    deviceInfo = null;
    mainmenus: Mainmenumodel[] = [];

    searchtype: DropDownModel[] = [
        { id: 0, name: "Select One" },
        { id: 1, name: "All" },
        { id: 2, name: "Customer type" },
        { id: 3, name: "Customer name" },
    ];
    constructor(private Masterservice: MasterServices,
        private transactionService: TransactionServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private location: Location,
        private deviceService: DeviceDetectorService) {
    }

    ngOnInit(): void {


        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        this.mainmenus = JSON.parse(localStorage.getItem('menu'));


        this.getAllOrder()
        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.model.typeCust == 'Retail' || this.model.typeCust == 'Defence' || this.model.typeCust == 'Institutional') {
            this.model.allchk = true;
        }
        else if (this.model.typeCust == 'Defence' || this.model.typeCust == 'Institutional') {
            this.model.pendingchk = true;
        }
        else {
            this.model.approvechk = true;
        }
    }

    getAllOrder() {
        this.model.userid = Number(localStorage.getItem("userid"));
        this.orderlst = [];
        this.model.pageno = this.sum;
        this.transactionService.getAllOrder(this.model)
            .subscribe(value => {
                this.orderlst = value
                this.orderlst1 = value
                for (let i = 0; i < this.orderlst.length; i++) {
                    this.model.totalcount = this.orderlst[i].totalcount;
                }
                for (let i = 0; i < this.orderlst.length; i++) {
                    if (this.orderlst[i].typeCust == "P" || this.orderlst[i].approveorderflag == "N") {
                        this.orderlst[i].status = "Pending"
                    } else {
                        this.orderlst[i].status = "Approved"
                    }
                    if (this.orderlst[i].typeCust == "Dealer" && this.orderlst[i].approveorderflag == "P") {
                        this.orderlst[i].status = "Pending"
                    }
                    else if (this.orderlst[i].typeCust == "Dealer" && this.orderlst[i].approveorderflag == "A") {
                        this.orderlst[i].status = "Approved"
                    }
                }
            })
    }
    btnedit(id, dealerflag) {

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "dealerflag": dealerflag,
            }
        };
        this.router.navigate(['/editDefineOrder'], navigationExtras);
    }
    btnView(id, dealerflag) {

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "id": id,
                "dealerflag": dealerflag,
                "flag": "view",
            }
        };
        this.router.navigate(['/editDefineOrder'], navigationExtras);
    }
    btnsampleIn() {
        this.router.navigate(["/SampleInvoice"])
    }

    onSelectSrch(val) {
        this.model.txtselect = val;

    }

    btnsearch($event) {
        if (this.model.txtselect == "All") {
            // this.getLocalities(0, 10);
            this.getAllOrder();
            this.model.txtserch = null;
        } else {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                this.notifyService.showRroor("Enter Criteria To Search", "Oops");
                return;
            }
            this.model.message = this.model.txtselect
            this.model.sendstring = this.model.txtserch

            this.transactionService.getOrdersAcceptanceLike(this.model)
                .subscribe(value => {
                    this.orderlst = value;
                    for (let i = 0; i < this.orderlst.length; i++) {
                        this.model.totalcount = this.orderlst[i].totalcount;
                    }
                    this.orderlst = value
                    for (let i = 0; i < this.orderlst.length; i++) {
                        if (this.orderlst[i].typeCust == "P" || this.orderlst[i].approveorderflag == "N") {
                            this.orderlst[i].status = "Pending"
                        } else {
                            this.orderlst[i].status = "Approved"
                        }
                    }
                });
        }
        // if (this.model.txtselect == "All") {
        //     this.getAllOrder()
        //     this.model.txtserch = null;
        // }
        // else if (this.model.txtselect == "Customer type") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.orderlst1 = [];
        //         this.orderlst.forEach((item) => {
        //             if ((item.typeCust.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {

        //                 this.orderlst1.push({ "id": item.id, "quotationID": item.quotationID, "accountid": item.accountid, "txtserch": null, "txtselect": null, "srno": item.srno, "quotationCode": item.quotationCode, "customername": item.customername, "dateTime": item.dateTime, "totalAmount": item.totalAmount, "typeCust": item.typeCust, "status": item.status, "dealerflag": item.dealerflag, "userid": item.userid, "approveorderflag": item.approveorderflag, "pendingchk": item.pendingchk, "approvechk": item.approvechk, "allchk": item.allchk });

        //             }
        //         });
        //         this.orderlst = this.orderlst1;
        //     }
        // } else if (this.model.txtselect == "Customer name") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.orderlst1 = [];
        //         this.orderlst.forEach((item) => {
        //             if ((item.customername.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {

        //                 this.orderlst1.push({ "id": item.id, "quotationID": item.quotationID, "accountid": item.accountid, "txtserch": null, "txtselect": null, "srno": item.srno, "quotationCode": item.quotationCode, "customername": item.customername, "dateTime": item.dateTime, "totalAmount": item.totalAmount, "typeCust": item.typeCust, "status": item.status, "dealerflag": item.dealerflag, "userid": item.userid, "approveorderflag": item.approveorderflag, "pendingchk": item.pendingchk, "approvechk": item.approvechk, "allchk": item.allchk });

        //             }
        //         });
        //         this.orderlst = this.orderlst1;
        //     }
        // }
        // if (this.model.allchk) {
        //     this.model.txtserch = "All"
        //     this.orderlst1 = [];
        //     this.orderlst.forEach((item) => {
        //         if ((item.status.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //             this.orderlst1.push({ "id": item.id, "quotationID": item.quotationID, "accountid": item.accountid, "txtserch": null, "txtselect": null, "srno": item.srno, "quotationCode": item.quotationCode, "customername": item.customername, "dateTime": item.dateTime, "totalAmount": item.totalAmount, "typeCust": item.typeCust, "status": item.status, "dealerflag": item.dealerflag, "userid": item.userid, "approveorderflag": item.approveorderflag, "pendingchk": item.pendingchk, "approvechk": item.approvechk, "allchk": item.allchk });
        //         }
        //     });
        //     this.orderlst = this.orderlst1;

        // }
        // if (this.model.approvechk) {
        //     this.model.txtserch = "Approved"
        //     this.orderlst1 = [];
        //     this.orderlst.forEach((item) => {
        //         if ((item.status.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //             this.orderlst1.push({ "id": item.id, "quotationID": item.quotationID, "accountid": item.accountid, "txtserch": null, "txtselect": null, "srno": item.srno, "quotationCode": item.quotationCode, "customername": item.customername, "dateTime": item.dateTime, "totalAmount": item.totalAmount, "typeCust": item.typeCust, "status": item.status, "dealerflag": item.dealerflag, "userid": item.userid, "approveorderflag": item.approveorderflag, "pendingchk": item.pendingchk, "approvechk": item.approvechk, "allchk": item.allchk });
        //         }
        //     });
        //     this.orderlst = this.orderlst1;

        // }
        // if (this.model.pendingchk) {
        //     this.model.txtserch = "Pending"
        //     this.orderlst1 = [];
        //     this.orderlst.forEach((item) => {
        //         if ((item.status.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //             this.orderlst1.push({ "id": item.id, "quotationID": item.quotationID, "accountid": item.accountid, "txtserch": null, "txtselect": null, "srno": item.srno, "quotationCode": item.quotationCode, "customername": item.customername, "dateTime": item.dateTime, "totalAmount": item.totalAmount, "typeCust": item.typeCust, "status": item.status, "dealerflag": item.dealerflag, "userid": item.userid, "approveorderflag": item.approveorderflag, "pendingchk": item.pendingchk, "approvechk": item.approvechk, "allchk": item.allchk });
        //         }
        //     });
        //     this.orderlst = this.orderlst1;

        // }
    }
    showPageIndex(pageIndex) {
        this.sum = pageIndex;
        this.model.pageno = this.sum;
        this.getAllOrder();
        // this.user.pageSize = this.opportunitieslst[this.opportunitieslst.length - 1].id + 9;
        if (this.model.txtserch == null && this.model.txtselect == null) {
            this.getAllOrder();
        }
        else {
            this.model.message = this.model.txtselect
            this.model.sendstring = this.model.txtserch
            this.transactionService.getOrdersAcceptanceLike(this.model)
                .subscribe(value => {
                    this.orderlst = value;
                    for (let i = 0; i < this.orderlst.length; i++) {
                        this.model.totalcount = this.orderlst[i].totalcount;
                    }
                    // this.orderlst = value
                    for (let i = 0; i < this.orderlst.length; i++) {
                        if (this.orderlst[i].typeCust == "P" || this.orderlst[i].approveorderflag == "N") {
                            this.orderlst[i].status = "Pending"
                        } else {
                            this.orderlst[i].status = "Approved"
                        }
                    }
                });


        }
        (pageIndex)
        this.sum = pageIndex;
        this.model.pageno = this.sum;

    }
   
    btnprevious() {
        this.sum = this.sum - 1;
        this.getAllOrder();
    }
    btnnext() {
        this.sum = this.sum + 1;
        this.getAllOrder();
    }
    last() {
        this.sum = Math.round(this.user.totalItems / 10);
        this.getAllOrder();
    }
    first() {
        this.sum = 1;
        this.getAllOrder();
    }
}

