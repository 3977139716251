// import { User } from "../_models/user";
import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router/';
// import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { User } from '../_models';
import { DropDownModel } from '../_models/DropDownModel';
import { AlertService, AuthenticationService, MasterServices } from '../_services';
import { EncrDecrService } from '../_services/EncrDecrService';
import { NotificationService } from '../_services/notification.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Mainmenumodel } from '../_models/Mainmenumodel';
// import { NotificationService } from '../notification.service';

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'login.component.html',
})

export class LoginComponent implements OnInit {
    model: any = {};
    user = new User();
    returnUrl: string;
    passaw: any;
    allpatientnames: DropDownModel[] = [];
    private subscription: Subscription;
    private timer: Observable<any>;
    public showloader: boolean = false;
    deviceInfo = null;
    isMobile = null;
    mainmenus: Mainmenumodel[] = [];
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private EncrDecr: EncrDecrService,
        private notifyService: NotificationService,
        private deviceService: DeviceDetectorService,
        private masterServices: MasterServices) { }

    ngOnInit() {
        const user = new User();
        //this.authenticationService.logout();

        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        // console.log(this.deviceInfo);
        console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        console.log(isDesktopDevice);
    }
    login() {
        if (this.model.usernameOrEmail == null) {
            alert("Username is Required");
            return;
        }
        if (this.passaw == null) {
            alert("Password is Required");
            return;
        } else {
            var encrypted = this.EncrDecr.set('UMJ3wfzn@kMpARd', this.passaw);
            this.model.password = encrypted
        }
        this.authenticationService.logout();
        const user = new User();
        this.authenticationService.login(this.model)
            .then(
                data => {
                    if (data.message === 'SUCCESS') {
                        localStorage.setItem('profile', JSON.stringify(this.model));
                        localStorage.setItem('username', data.username);
                        localStorage.setItem('firstName', data.firstName);
                        localStorage.setItem('userid', data.id.toString());
                        localStorage.setItem('Token', data.token);
                        localStorage.setItem('dbName', data.dbName);
                        localStorage.setItem('fullname', data.fullname);
                        localStorage.setItem('companyName', data.companyName);
                        localStorage.setItem('companyCode', data.companyCode);
                        localStorage.setItem('accesstype', data.accesstype);
                        localStorage.setItem('companyaddress', data.companyaddress);
                        localStorage.setItem('telephone1', data.telephone1);
                        localStorage.setItem('usergroupid', data.usergroupid);

                        var user = new User();
                        user.userid = Number(localStorage.getItem('userid'));
                        const isMobile = this.deviceService.isMobile();
                        if (this.isMobile) {
                            // this.router.navigate(["mobilelandpage"]);

                            var user = new User();
                            this.model.username = localStorage.getItem('username');
                            this.model.accessToken = localStorage.getItem('Token');
                            this.model.userid = localStorage.getItem('userid');
                            this.model.usergroupid = localStorage.getItem('usergroupid');
                            this.masterServices.getmenubyusermobile(this.model)
                                .subscribe(mainumodel => {
                                    localStorage.setItem('menu', JSON.stringify(mainumodel));
                                    this.mainmenus = mainumodel;
                                    this.router.navigate([data.landingpage]);
                                });

                        }
                        else {
                            if (data.landingpage == null) {
                                this.router.navigate(["landpage"]);

                            } 
                            else{
                                this.router.navigate([data.landingpage]);
                            }
                        }
                    } else {
                        this.notifyService.showRroor(data.message, "Oops!");
                        alert("Invalid Username Or Password")
                    }
                },
                error => {
                    this.alertService.error(error);
                });
        this.authenticationService.logisLoginValueset();

    }

}
