import { OnInit, Component } from '@angular/core';
import { TransactionServices, DealersService } from 'src/app/_services';
import { BsModalService } from 'ngx-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { NotificationService } from 'src/app/_services/notification.service';
import { CallCenterDashboardModel } from '../CallCenterDashboard/CallCenterDashboardModel';

@Component({
    selector: 'app-ReassEnquiry',
    templateUrl: 'ReassEnquiry.html',
})
export class ReassEnquiry implements OnInit {
    constructor(
        private transactionservice: TransactionServices,
        private modalService: BsModalService,
        private dealersService: DealersService,
        private router: Router,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
    ) { }

    model : any={};
    user = new User();
    id: number;
    accountid:number;
    assignlst: DropDownModel[] = []
    modelst: CallCenterDashboardModel[] = [];
    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.id = params["id"];
          
        });

        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.id != null && this.id > 0) {
            // this.user.trnid = this.id
            
            this.transactionservice.getservicecallReassign(this.model)
                .subscribe(
                    data => {
                        this.model=data
                        this.assignlst = data.memberlst
                    });
        }
    }

    onSelectAssignTo(value) {
        this.model.assignTo = value
        alert(value.accountid)    
    }

    btnsaveReAssign() {
        this.transactionservice.btnsaveReAssignTo(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                        this.router.navigate(['/Service Call']);
                    }
                });
    }

    btnback() {
        this.router.navigate(['/Service Call']);
    }


}