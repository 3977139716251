import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AlertService, CompanyService, MasterServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ServiceDashboardModel } from "./ServiceDashboardModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'ServiceDashboard.html'
})
export class ServiceDashboard implements OnInit {
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private transactionservice: TransactionServices,) {
    }

    servicelst: ServiceDashboardModel[];
    nextdayslst: ServiceDashboardModel[];
    installationlst: ServiceDashboardModel[];
    approvedlst: ServiceDashboardModel[];
    valueall: number;
    value: number;
    

    ngOnInit(): void {

    }








}