import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { User } from '../_models/index';
import { common } from './Common';
import { Mainmenumodel } from '../_models/Mainmenumodel';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthenticationService {

  pagename:string;
  constructor(private http: Http,
    private route: ActivatedRoute,
    private router: Router, ) {
  }
  private loginsucess: boolean;
  private url: String = common.s1;
  private headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true' });


  get isLoggedIn() {
    if (localStorage.getItem('currentUserSUCCESS')) {
      return true;
    } else {
      return false;
    }
  }
  login(model: User) {
    const url = this.url + 'api/auth/signin';
    return this.http
      .post(url, JSON.stringify(model), { headers: this.headers })
      .toPromise()
      .then(response => response.json() as User)
      .catch(this.handleError);
  }


  logout(): string {
    this.loginsucess = false;
    localStorage.removeItem('profile');
    localStorage.removeItem('menu');

    localStorage.removeItem('username');
    localStorage.removeItem('userid');

    localStorage.removeItem('currentUser');
    localStorage.removeItem('Token');
    localStorage.removeItem('dbName');
    localStorage.removeItem("level1");
    localStorage.removeItem("level2");

    return 'Success';
  }

  logisLoginValueset() {
    this.loginsucess = true;
  }

  private handleError(error: any): Promise<any> {
    console.error('Error', error);
    return Promise.reject(error.message || error);
  }
}
