import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { User } from 'src/app/_models';
import { SearchCurrencyExchangeModel } from './SearchCurrencyExchangeModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';

@Component({
    selector: 'app-login',
    templateUrl: 'SearchCurrencyExchange.html'
})
export class SearchCurrencyExchange implements OnInit {
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
            this.pagename=this.router.url
        }
    sum: any;
    user = new User();
    model: any = {};
    Date = new Date();
    cuexchlst: SearchCurrencyExchangeModel[] = [];
    defaultcurrencylst: DropDownModel[] = [];
    targetcurrencylst: DropDownModel[] = [];
    pagename: string;
    ngOnInit(): void {
        this.model.exchangeRate=new Date();
        this.model.exchangeRate=null;
        this.getAllCurrencyEx();
        this.getdefaultcurr();
        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }

    getAllCurrencyEx() {
        this.Masterservice.getAllCurrencyEx(this.model)
            .subscribe(value => {
                this.cuexchlst = value;
            })
    }
    onScroll() {
        this.sum = this.sum + 1;
        this.user.pageno = this.sum + 1;
        this.user.pageSize = 5;
        this.getAllCurrencyEx();
    }
    getdefaultcurr() {
        let user = new User();
        this.defaultcurrencylst = [];
        this.targetcurrencylst = [];
        this.Masterservice.getdefaultcurr(user)
            .subscribe((value) => {
                this.defaultcurrencylst = value.deafcurlst
                this.targetcurrencylst = value.targetcurlst
            });
    }
    onChangeCreateddate(date) {
        if (date > new Date()) {
            this.model.asonDate = new Date();
            this.notifyService.showRroor("You cannot select future date", "Oops !");
            return;
        }
    }
    onSelectDefaultcurrency(value) {
        if(value==0){
            this.notifyService.showRroor("Select Default Currency!!", "Oops!");
            return; 
        }
        this.model.defaultCurrency = value;
    }
    onSelectTargetcurrency(value) {
        if(value==0){
            this.notifyService.showRroor("Select Target Currency!!", "Oops!");
            return; 
        }
        this.model.targetCurrency = value;
    }
    btndelts(id) {
        this.model.id = id
        this.Masterservice.deleteCurrencyex(this.model)
            .subscribe(value => {
                this.notifyService.showSuccess(value.message, " Success");
                this.ngOnInit()
            });
    }
    btnedit(lst) {
        this.model.id = lst.id;
        this.model.currencyName = lst.currencyName;
        this.model.exchangeRate = lst.exchangeRate;
        this.model.asonDate = lst.asonDate;
        this.model.asonDate = new Date(lst.asonDate);
        this.model.targetCurrencyName = lst.targetCurrencyName;
        this.model.defltcurrency = lst.currencyName;
        this.model.defaultCurrency = lst.defaultCurrency
        this.model.targetCurrency = lst.targetCurrency
    }

    btnsave() {
        if (this.model.defaultCurrency == null) {
            this.notifyService.showRroor("Select Default Currency  !!", "Oops!");
            return;
        }
        if (this.model.targetCurrency == null) {
            this.notifyService.showRroor("Select Target Currency  !!", "Oops!");
            return;
        }
        if (this.model.defaultCurrency == this.model.targetCurrency) {
            this.notifyService.showRroor(" Default Currency  And Target Currency Not be Same  !!", "Oops!");
            return;
        }
       
        if (this.model.exchangeRate == null) {
            this.notifyService.showRroor("Enter ExchangeRate  !!", "Oops!");
            return;
        }
      


        this.Masterservice.savecurrencyex(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit()
                    } else if(data.status=="Repeat"){
                        this.notifyService.showRroor("Exchange Currency already Present", "Oops !")
                    }else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    cancle() {
        this.router.navigate(["CRMHome"]);

    }
}
