<app-header></app-header>
<div class="container" style="width:100%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Service DashBoard</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow" style="padding-top: 5px;padding-bottom: 9px;">
            <div class="row">
                <div class="row">
                    <div class="col s9"><span
                            style="font-size: 17px;font-weight: bold;padding-left: 9px;">Overdue</span>
                        <span style="font-size: 15px;margin-left: 815px;"><a>See All{{value}} </a> </span>
                        <!-- (click)="openOverduemodel(overduemodel)" -->
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="10%" style="text-align: left">Date</th>
                                    <th width="25%" style="text-align: left">Name</th>
                                    <th width="25%" style="text-align: left">Type</th>
                                    <th width="15%" style="text-align: left">Overdue</th>
                                    <th width="15%" style="text-align: left">Owner</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of servicelst; let i = index">
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.sdate | date: 'dd-MM-yyyy'}}</p>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customername}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.type}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.overdue}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.owner}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span> -->
                        </div>
                    </div>
                    <div class="col s3">
                    </div>
                </div>
                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Calls-Next 30 Days</span>
                        <span style="font-size: 15px;margin-left: 732px;"><a>See All{{valueall}}</a></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9">

                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="12%" style="text-align: left;padding-left: 10px;">Call No.</th>
                                    <th width="10%" style="text-align: left">Date</th>
                                    <th width="24%" style="text-align: left">Customer</th>
                                    <th width="24%" style="text-align: left">Product Model</th>
                                    <th width="10%" style="text-align: left">Stage</th>
                                    <th width="10%" style="text-align: left">Status</th>
                                    <th width="10%" style="text-align: left">Owner</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of nextdayslst">


                                        <td
                                            style="text-align: left;padding-left: 10px;padding-top: 0px;padding-bottom: 0px;">
                                            <a (click)="onclickenquiryid(lst.id)">{{lst.callno}}</a>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.sdate | date: 'dd-MM-yyyy'}}</p>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customername}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.productModel}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.stageCode}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.status}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">{{lst.owner}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span> -->
                        </div>

                    </div>
                    <div class="col s3">

                    </div>

                </div>
                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Installations</span><span
                            style="font-size: 15px;margin-left: 785px;"><a>See All</a> </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l9 m9 s9">

                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20%" style="text-align: left;">Installation Number</th>
                                    <th width="10%" style="text-align: left">Date</th>
                                    <th width="25%" style="text-align: left">Customer</th>
                                    <th width="25%" style="text-align: left">Product Model</th>
                                    <th width="20%" style="text-align: left">Owner</th>

                                </thead>
                                <tbody>

                                    <tr *ngFor="let lst of installationlst">
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.instaNo}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.sdate | date: 'dd-MM-yyyy'}}</p>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.customername}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.productModel}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">{{lst.owner}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="col s3">
                    </div>
                </div>
                <div class="row">
                    <div class="col s9">
                        <span style="font-size: 17px;font-weight: bold;padding-left: 9px;">Approvals</span><span
                            style="font-size: 15px;padding-left: 800px;"><a>See All </a> </span>
                    </div>
                </div>

                <div class="row">
                    <div class="col l9 m9 s9">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="20%" style="text-align: left">Date</th>
                                    <th width="35%" style="text-align: left">Contact Name</th>
                                    <th width="35%" style="text-align: left">Stage</th>
                                    <th width="10%" style="text-align: left">Action</th>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of approvedlst">
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <p>{{lst.stdate | date: 'dd-MM-yyyy'}}</p>
                                        </td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.contactname}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            {{lst.documentType}}</td>
                                        <td style="text-align: left;padding-top: 0px;padding-bottom: 0px;">
                                            <span><img src="../../../assets/images/edit.png"
                                                    (click)="btnedit(lst.prospectOpporId)" data-toggle="tooltip"
                                                    data-placement="top" title="Edit" width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col s3">
                    </div>
                </div>






            </div>
        </div>


    </div>
</div>