
export class  OrderHomepageModel {
    id:number;
    quotationID:number;
    srno:number;
    accountid:number;
    quotationCode:string;
    customername:string;
    dateTime:Date;
    totalAmount:number;
    status:string;
    typeCust:string;
    txtserch:string;
    dealerflag:boolean;
    userid:number;
    approveorderflag:string;
    approvechk:boolean;
    pendingchk:boolean;
    allchk:boolean;
    txtselect: string;
    message:string;
    sendstring:string;
    pageno:number;
    totalcount:number;
}