<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field  col s5" style="margin-top: 30px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.campaignCode" #campaignCode="ngModel" type="text" id="campaignCode" data-toggle="tooltip"
                        data-placement="top" maxlength="15" title="Source Code">
                    <label id="lable1" for="First1" style="color: red;">Source Code [Max. Length 15 Character]</label>
                </div>
                <div class="input-field  col s5" style="margin-top: 30px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.campaignName" #campaignName="ngModel" type="text" id="campaignName" data-toggle="tooltip"
                    data-placement="top" title="Source Name">
                <label id="lable2" for="First2" style="color: red;">Source Name</label>
                </div>
                <div class="col l1"></div>

            </div>
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col s3" style="margin-bottom: 0px;">
                    <span style="color: red;" >Status</span>
                    <select (change)="onSelectcampaignstatus($event.target.value)">
                        <option selected value = "0">Select Status</option>
                        <option *ngFor="let type of campaignstatuslst" [value]="type.name"
                            [selected]="type.name == model.campaignStatus">
                            {{type.name}}</option>
                    </select>
                </div>

                <div class="input-field col s3" style="text-align: left;margin-bottom: 0px;">
                    <span>Start Date</span>
                    <input type="text" placeholder="Start Date" [(ngModel)]="model.startDate"
                       id="start" class="form-control" (ngModelChange)="selectStartDate(model.startDate)"
                        bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }">
                </div>
                <div class="input-field col s3" style="text-align: left;margin-bottom: 0px;">
                    <span>End Date</span>
                    <input type="text" placeholder="End Date" [(ngModel)]="model.endDate"
                       id="end" class="form-control"
                        bsDatepicker (ngModelChange)="selectToDate(model.endDate)" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }">
                </div>
                <div class="col l1"></div>
            </div>
            <div class="row"> 
                <div class="col l1"></div>
                <div class=" input-field col s5" style="margin-bottom: 0px;">
                    <input [(ngModel)]="model.description" #description="ngModel" type="text" 
                    onkeypress="return /[A-Za-z_ ]/i.test(event.key)" id="description" data-toggle="tooltip"
                    data-placement="top" title="Description">
                    <label id="lable3" for="first3" style="color: red;" >Description </label>
                </div>
                <div class="col l1"></div>
                <div class="input-field col l4" style="margin-top: 25px;margin-bottom: 0px;" >
                    <input [(ngModel)]="model.chkselect"  #chkselect="ngModel"
                        type="checkbox" style="text-align: 5px">&nbsp;&nbsp;&nbsp;&nbsp; Detail
                </div>
            </div>

            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                    (click)=" cancle()">Cancel</button>
                    <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>
                                      
                </div>
            </div>

        </div>
    </div>
</div>