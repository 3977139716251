<app-header></app-header>
<div class="container" style="width: 80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Spare Issue</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class=" input-field col s4" style="margin-top: 20px;margin-bottom: 0px;padding-left: 14px;">
                    <span>Resource Name</span>
                    <input type="text" class="autocomplete" [(ngModel)]="model.resourceName" id="resourceName1"
                        placeholder="Resource Name" (keyup)="getresourceNameLike($event)">
                    <input type="hidden" id="acc-id" />
                </div>
                <div class="input-field col s4" style="text-align: left;margin-bottom: 0px;margin-top: 20px;">
                    <span>Inventory Location</span>
                    <select (change)="onSelectInventoryLocation($event.target.value )" data-toggle="tooltip"
                        data-placement="top" title="Inventory Location">
                        <option selected value="0">Select Inventory Location</option>
                        <option *ngFor="let lst of locationlst" [value]="lst.id" [selected]="lst.id== model.locationid">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
                <div class=" input-field col s3" style=" margin-top: 45px;">
                    <div class="switch">
                        <label>
                            <b>Spare Return</b>
                            <input type="checkbox" [(ngModel)]="model.spareReturnchecked"
                                (change)="onselectReturn($event)" #sparereturn="ngModel">
                            <span class="lever"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 0px;padding-left: 14px;">
                    <span>Item Category</span>
                    <select (change)="onSelectitemCat($event.target.value)">
                        <option selected>Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id"
                            [selected]="type.id == model.itemcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;margin-top: 0px;">
                    <span>Sub Category</span>
                    <select (change)="onSelectSubCat($event.target.value)">
                        <option selected>Select Sub Category</option>
                        <option *ngFor="let type of subCatlst" [value]="type.id"
                            [selected]="type.id == model.itemsubcategoryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class=" input-field col s3" style="margin-top: 17px;margin-bottom: 0px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.itemname" id="itmename"
                        placeholder="Item Name" (keyup)="getItemLike($event)" />
                    <input type="hidden" id="acc-id" />
                </div>
                <div class=" input-field col s2" style="margin-top: 17px;margin-bottom: 0px;">
                    <input [(ngModel)]="model.issuedquantity" #issuedquantity="ngModel" type="number" id="first7"
                        title="Quantity" data-toggle="tooltip" data-placement="top" ngModel maxlength="10"
                        style="text-align: right;" required>
                    <label id="lable7" for="first7">Quantity</label>
                </div>

                <div class="row col s1" style="text-align: right;padding-top: 17px;">
                    <button type="button" (click)="btnadd()" class="waves-effect waves-light btn">Add</button>

                </div>
            </div>

            <div class="row">
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="10%" style="text-align: left;padding-left: 10px;">Item Code</th>
                                    <th width="35%" style="text-align: left;">Item Name</th>
                                    <th width="10%" style="text-align: left">Unit</th>
                                    <th width="17%" style="text-align: center;">Available Stock</th>
                                    <th width="18%" style="text-align: center">Issued Quantity</th>
                                    <th width="10%" style="text-align: center">Balance</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of sparelst  | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.itemcode}}</td>
                                        <td style="text-align: left">{{lst.itemname}}</td>
                                        <td style="text-align: left">{{lst.uom}}</td>
                                        <td style="text-align: right;padding-right: 10px;">{{lst.stockforsale}}</td>
                                        <td style="text-align: right;padding-top: 0px;padding-bottom: 0px;"><input
                                                [(ngModel)]="lst.issuedquantity" #issuedquantity="ngModel" type="number"
                                                class="" [disabled]="this.action=='view'"
                                                oninput="validity.valid||(value='');" min="0" id="issuedquantity"
                                                data-toggle="tooltip" data-placement="top" title="Quantity"
                                                (ngModelChange)="onChangeOfqantity(i)"
                                                style="margin-top:1px;text-align: right;margin-bottom: 5px;">
                                        </td>
                                        <td style="text-align: right;padding-right: 15px;">{{lst.balance}}
                                            <!-- <span><img
                                                    src="../../../assets/images/delete.png"
                                                    (click)="btndelete(lst.id,i)" data-toggle="tooltip"
                                                    data-placement="top" title="Delete" width="15px" /></span> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 9px;margin-top: 0px;">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>
                </div>
            </div>

        </div>
    </div>
</div>