import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, ProductionService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../../Transactions/DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InstallationService } from 'src/app/_services/Installation.Service';
import { TeamModel } from 'src/app/Transactions/Teams/TeamModel';
import { ProspectModel } from 'src/app/Transactions/Sales-Transaction/ProspectModel';
var converter = require('number-to-words');
import { Pipe } from '@angular/core';
import { DefineServiceChargesModel } from 'src/app/Master/ServiceChergesMaster/DefineServiceChargesModel';
import { ConversionTemplateModel } from '../Conversion Template/ConversionTemplateModel';
declare var $: any;

@Component({
    selector: 'app-PlanItemConversion',
    templateUrl: 'PlanItemConversion.html',
})
export class PlanItemConversion implements OnInit {
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private masterservice: MasterServices,
        private transactionService: TransactionServices,
        private ProductionService: ProductionService) {
    }
    model: any = {};
    user = new User();
    conversionmodel = new ConversionTemplateModel();
    conversionid: number = 0;
    p: any;
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.model.planhdrId = params["id"];
        });

        if (this.model.planhdrId != null) {
            this.ProductionService.getConversionplandetails(this.model)
                .subscribe(
                    data => {
                        this.model = data;


                    });
        }
    }

    onSelectconversion(id) {
        if (id == 0) {
            this.conversionmodel = null;
        }
        this.conversionid = id;
        this.user.trnid = this.conversionid;
        this.ProductionService.getconversiondetails(this.user)
            .subscribe(
                data => {
                    this.conversionmodel = data;
                });
    }
    btnadd() {
        if (this.conversionmodel.id == null) {
            this.notifyService.showRroor("Please select conversion Template ", "Opps");
            return;
        }
        if (this.conversionmodel.quantity == null || this.conversionmodel.quantity == 0) {
            this.notifyService.showRroor("Please Enter Quantity", "Opps");
            return;
        }

        this.conversionmodel.lstoutputmeteriallst.forEach(element => {
            element.quantityconversion = this.conversionmodel.quantity * element.omquantity;
            this.model.lstplanitems.forEach(itemelement => {
                if (itemelement.itemid == element.itemid && element.quantityconversion > 0) {
                    itemelement.balancequantity = itemelement.balancequantity - element.quantityconversion;
                    element.ombalance = itemelement.balancequantity;
                    element.itemreiredquantity = itemelement.quantityreqired;
                    if (itemelement.balancequantity < 0) {
                        itemelement.balancequantity = 0;
                        element.ombalance = 0;
                    }
                }
            });
        });
        this.model.lstitemconversion.push({
            id: this.conversionmodel.id,
            formulaname: this.conversionmodel.formulaname,
            itemname: this.conversionmodel.itemname,
            itemid: this.conversionmodel.itemid,
            quantity: this.conversionmodel.quantity,
            uom: this.conversionmodel.uom,
            stock: this.conversionmodel.stock,
            lstoutputmeteriallst: this.conversionmodel.lstoutputmeteriallst
        });
        this.conversionmodel.id = null;
        this.conversionmodel.itemid = null;
        this.conversionmodel.itemname = null;
        this.conversionmodel.uom = null;
        this.conversionmodel.quantity = null;
    }
    back() {
        this.router.navigate(['/Production Plan']);
    }

    btndelete(id, i) {
        if (id == null) {
            this.model.lstitemconversion.splice(i, 1);
            this.notifyService.showSuccess("Template Deleted Successfully", "Opps");
            return;
        } else {
            this.user.trnid = id;
            this.ProductionService.deleteconversionFormula(this.user)
                .subscribe(
                    data => {
                        if (data.status == "OK") {
                            this.notifyService.showSuccess("Template Deleted Successfully", "Opps");
                            this.model.lstitemconversion.splice(i, 1);

                            return;
                        }
                    });
        }

    }
    btnsaveplanconversion() {

        let flag = false;
        this.model.lstplanitems.forEach(element => {
            if (element.balancequantity > 0) {
                flag = true;
            }
        });

        if (flag) {
            this.notifyService.showRroor("Please Add All Items To Conversion", "Opps");
            return;
        }

        this.ProductionService.saveplanconversion(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Plan Conversion Save", "Opps");
                        this.model.id = data.message;
                        let navigationExtras: NavigationExtras = {
                            queryParams: {
                                "id":  this.model.id,
                            }
                        };
                        this.router.navigate(['/Create Plan Requisition'],navigationExtras);
                    }
                });
    }
}