import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { DefineitemSubCategoryModel } from "./DefineitemSubCategoryModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineitemSubCategory.html'
})
export class DefineitemSubCategory implements OnInit {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {
    }
    model = new DefineitemSubCategoryModel();
    itmCatlst: DropDownModel[] = [];
    user = new User();
    id: number;
    sub: any;
    ngOnInit(): void {
        this.getitemCategory();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getItemCateByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                    },
                    error => {
                        this.alertService.error(error);
                    });
        }
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }
    onSelectitemCat(value) {
        this.model.itemcategoryid = value;
    }
    cancle() {
        this.router.navigate(["/Item Subcategory"]);
    }
    btnsave($event) {
        if (this.model.itemcategoryid == null || this.model.itemcategoryid == 0) {
            this.notifyService.showRroor("Select Item Category  !!", "Oops!");
            return;
        }
        if (this.model.subcategorycode == null || this.model.subcategorycode == "") {
            this.notifyService.showRroor("Enter Sub Category Code  !!", "Oops!");
            return;
        }
        if (this.model.subcategoryname == null || this.model.subcategoryname == "") {
            this.notifyService.showRroor("Enter Sub Category Name !!", "Oops!");
            return;
        }

        this.Masterservice.saveitemsubcatgry(this.model)
            .subscribe(
                data => {

                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['/Item Subcategory']);
                    } else if (data.status == "Repete") {
                        this.notifyService.showRroor("Item Sub Category already present ", "Oops !")
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
}