<div *ngIf="isMobile == true ">

    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol">
        <h1 class="header center">Create Installation</h1>
        <div class="container ">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="srchBy p-0">
                        <div class="col s12 sectionSrch">
                            <label class="w-35 font-weight-bold">OA / Approval No & Date :</label>
                            <label class="widthR-60">{{model.approvalnumber}} - {{model.approvaldate |
                                date:'d MMMM y'}}</label>
                            <label class="w-35 font-weight-bold">Customer Name:</label>
                            <label class="widthR-60">{{model.coustomername}}</label>
                            <label class="w-35 font-weight-bold">Address :</label>
                            <label class="widthR-60">{{model.address}}</label>
                            <label class="w-35 font-weight-bold">Pincode :</label>
                            <label class="widthR-60">{{model.pincode}}</label>
                            <label class="w-35 font-weight-bold">Locality :</label>
                            <label class="widthR-60">{{model.locality}}</label>
                        </div>
                    </div>
                    <div class="col s12">
                        <div class="tabInnerBox grayBg">
                            <div class="introTab">
                                <label class="w-35 font-weight-bold blueTxt">Locale:</label>
                                <label class="w-50 pl-2">{{model.locale}}</label>
                                <label class="w-35 font-weight-bold blueTxt">City</label>
                                <label class="w-50 pl-2">{{model.city}}</label>
                                <label class="w-35 font-weight-bold blueTxt">State</label>
                                <label class="w-50 pl-2">{{model.state}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Country</label>
                                <label class="w-50 pl-2">{{model.country}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Area</label>
                                <label class="w-50 pl-2">{{model.areaname}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Region</label>
                                <label class="w-50 pl-2">{{model.regionname}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m-btm">
                        <div class="tabInnerBox">
                            <div class="introTab">
                                <label class="w-35 font-weight-bold blueTxt">Mobile:</label>
                                <label class="w-50 pl-2">{{model.mobile1}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Landline</label>
                                <label class="w-50 pl-2">{{model.landline}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Email-id</label>
                                <label class="w-50 pl-2">{{model.email}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Website</label>
                                <label class="w-50 pl-2">{{model.website}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m-btm">
                        <label class="w-100 dateBox">
                            <input type="text" class="" [(ngModel)]="model.googlemap" #googlemap="ngModel"
                                placeholder="Google Map Location" style="position: relative;">
                            <!-- <button type="button" class="btn grayButtonRes"><i class="fa fa-map-marker"
                                    aria-hidden="true"></i> Location</button> -->
                        </label>
                    </div>

                    <div class="col s12 srchBy sectionSrch m-btm">
                        <label class="w-35 font-weight-bold valignTxt">Branch</label>
                        <label class="widthR-60">
                            <div class="input-field">
                                <select (change)=" onSelectbranch($event.target.value)">
                                    <option selected="">Choose One</option>
                                    <option *ngFor="let type of lstbranch" [value]="type.id"
                                        [selected]="type.id==model.branchID">{{type.name}}</option>

                                </select>
                            </div>
                        </label>
                        <label class="w-35 font-weight-bold valignTxt">Assigned To</label>
                        <label class="widthR-60">
                            <div class="input-field">
                                <select (change)=" onSelectlstassigned($event.target.value)">
                                    <option *ngFor="let type of lstassigned" [value]="type.id"
                                        [selected]="type.id==model.assignedtoID">{{type.name}}</option>

                                </select>
                            </div>
                        </label>

                        <label class="w-100 font-weight-bold valignTxt dis-block">Preferred Installation:</label>
                        <label class="w-100 dis-block">{{model.preferredInst+", "+model.preferredInstDate+",
                            "+model.preferredInstTime}}</label>

                        <label class="w-35 font-weight-bold valignTxt">Assign Date:</label>
                        <label class="widthR-60">
                            {{model.assigndate | date:'d MMMM y'}}
                        </label>
                    </div>
                    <div class="col s12">
                        <div class="w-100 m-btm left-align" *ngIf="this.forflag != 'close'">
                            <label><input type="checkbox" [(ngModel)]="model.chkall" #chkall="ngModel"
                                    (click)="btnselectAllProduct($event)">
                                &nbsp;&nbsp;&nbsp;<b>Select All</b></label>
                        </div>
                        <div class="tabInnerBox" *ngFor="let lst of lstproduct">
                            <div class="introTab">

                                <label class="w-50"> <input type="checkbox" [(ngModel)]="lst.checkselect"
                                        #checkselect="ngModel" [disabled]="lst.completflag == true"></label>
                                <label class="w-50 font-weight-bold blueTxt valignTxt">&nbsp;</label>
                                <label class="w-50 font-weight-bold blueTxt">Product Category</label>
                                <label class="w-50">{{lst.productcategorie}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Product Model</label>
                                <label class="w-50 valignTxt">{{lst.productname}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Serial Number</label>
                                <label class="w-50">{{lst.serialnumber}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Assigned To</label>
                                <label class="w-50">{{lst.assignedtoname}}</label>

                                <label class="w-50 font-weight-bold blueTxt">Assign To</label>
                                <label class="w-50">
                                    <div class="input-field">
                                        <select (change)=" onSelectlstassigtounitproduct($event.target.value,i)">
                                            <option *ngFor="let type of lstassigned" [value]="type.id"
                                                [selected]="type.id==lst.assignedtoID"
                                                [disabled]="lst.completflag == true">
                                                {{type.name}}</option>

                                        </select>
                                    </div>
                                </label>

                            </div>
                        </div>
                    </div>

                    <div class="s12 m-btm srchBy mt-top" *ngIf="this.forflag == 'close'">
                        <label class="w-100 orgTxt font-weight-bold" style="font-size: 15px;">Unit Product
                            Installation</label>
                        <div class="s12 mt-top" *ngFor="let lst of lstinstallation" style="margin-top: 0px;">
                            <ul class="collapsible">
                                <li>
                                    <!-- <div class="collapsible-header accordianLbl">
                                        <label>
                                            <input type="checkbox" [(ngModel)]="lst.checkselect"
                                                *ngIf="lst.lstproduct.length>=0" #checkselect="ngModel" id="checkboxid">
                                            <span for="checkboxid">{{model.coustomername}}</span>
                                        </label>
                                        <label class="w-50">{{model.address}}</label>
                                        <br>
                                        <label class="w-50">{{model.mobile1}}</label>
                                    </div> -->
                                    <div class="collapsible-header accordianLbl">
                                        <div class="row">
                                            <div class="col sN1">
                                                <input type="checkbox" [(ngModel)]="lst.checkselect"
                                                    *ngIf="lst.lstproduct.length>=0" #checkselect="ngModel"
                                                    id="checkboxid">
                                            </div>
                                            <div class="userNme col s4">{{model.coustomername}}</div>
                                            <!-- <div class="addrs col s7">{{model.unit}}</div> -->
                                            <!-- <div class="mblnmbr col s4">{{model.mobile1}}</div> -->
                                            <!-- <div class="col l3 m3 s3" style="text-align: left;">
                                                <span>Invoice Total: &#8377;{{model.grandtotal | number: '1.2-2'}}</span>
                                                </div> -->
                                        </div>

                                    </div>
                                    <label class="w-35 font-weight-bold valignTxt">Invoice Total</label>
                                    <label class="widthR-60">&#8377;{{model.grandtotal | number: '1.2-2'}}</label>
                                    <div class="collapsible-body" *ngIf="!lst.lstproduct.length==0"
                                        style="display: block;">
                                        <div class="row s12" *ngFor="let sublst of lst.lstproduct;let j=index">

                                            <label class="w-35 font-weight-bold valignTxt">Product Model:</label>
                                            <label class="widthR-60">{{sublst.productname}}</label>

                                            <label class="w-35 font-weight-bold valignTxt"
                                                *ngIf="forflag=='close'&&sublst.serialnumber!=null">Serial#</label>
                                            <label class="widthR-60"
                                                *ngIf="forflag=='close'&&sublst.serialnumber!=null">{{sublst.serialnumber}}</label>

                                            <label class="w-35 font-weight-bold valignTxt"
                                                *ngIf="forflag=='close'">Installation By:</label>
                                            <label class="widthR-60"
                                                *ngIf="forflag=='close'">{{sublst.assignedtoname}}</label>

                                            <label class="w-35 font-weight-bold valignTxt"
                                                *ngIf="forflag=='close'">Installation Date:</label>
                                            <label class="widthR-60">
                                                <label class="widthR-60"
                                                    *ngIf="forflag=='close'">{{sublst.installtiondate | date:'d MMMM
                                                    y'}}
                                                </label>
                                            </label>

                                            <!-- <label class="w-35 font-weight-bold valignTxt">Warranty in Months:</label>
                                            <label class="widthR-60">
                                                <input type="text" class="form-control mb-1" placeholder="12"
                                                    [(ngModel)]="sublst.warrantyMonth" #warrantyMonths="ngModel"
                                                    (ngModelChange)="enteredMonth(sublst.warrantyMonth,j)" min="0"
                                                    oninput="validity.valid||(value='');" ngModel
                                                    [disabled]="sublst.accountwisemcstatus">
                                            </label>
                                            <div *ngIf="warrantyMonths.invalid && (warrantyMonths.dirty || warrantyMonths.touched) "
                                                class="alert alert-danger" style="color: red">
                                                <div *ngIf="warrantyMonths.errors.required">
                                                    Warranty Required
                                                </div>
                                                <div [hidden]="!warrantyMonths.errors?.pattern">
                                                    Invalid Warranty
                                                </div>
                                            </div> -->

                                            <label class="w-35 font-weight-bold valignTxt">Warranty End Date:</label>
                                            <label class="widthR-60">{{sublst.warrantyenddate | date:'d MMMM
                                                y'}}</label>

                                            <label class="w-35 font-weight-bold valignTxt">Approved By:</label>
                                            <label class="widthR-60">{{sublst.assignedtoname}}</label>

                                            <label class="w-35 font-weight-bold valignTxt">Close</label>
                                            <label class="widthR-60 font-weight-bold">
                                                <input type="checkbox" [(ngModel)]="sublst.checkselect"
                                                    #checkselect="ngModel" type="checkbox"
                                                    [disabled]="sublst.accountwisemcstatus">
                                            </label>
                                            <hr>

                                        </div>
                                        <div *ngIf="lst.lstproduct.length==0" class="col l11 m11 s11 registerBgShadow"
                                            style="margin-left:50px;text-align: center;">
                                            <div class="col l12 m12 s12" style="color: red">
                                                <samp> List Not Found </samp>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div class="s12 right mt-top">
                        <button type="button" (click)="btnback()" id="close"
                            class="btn grayButton">Cancel</button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-blue" (click)="btnsave()" *ngIf="forflag!='close'"
                            [disabled]="savehideflag || showBtn == true">Save</button>&nbsp;&nbsp;
                        <button type="button" class="btn btn-blue" (click)="btnclosecall()"
                            [disabled]="model.installationclosed" *ngIf="forflag=='close'">Close</button>
                    </div>


                </div>
            </div>




        </div>
    </div>

</div>

<div *ngIf="isMobile == false ">
    <app-header></app-header>
    <div class="container" style="width: 90%;">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Create Installation</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%;padding-top: 5px;">
                <div class="row" style="margin-top: 1%;padding-left:5px;">
                    <div class="col s6 m6 l6" style=" font-size: 15px;margin-top: 2px;">
                        <span>OA / Approval No & Date</span> <br><b>{{model.approvalnumber}} - {{model.approvaldate |
                            date:'d MMMM y'}}</b>
                    </div>
                    <div class="col s6 m6 l6" style=" font-size: 15px;margin-top: 2px;">
                        <span>Customer Name:</span> <br> <b>{{model.coustomername}}</b>
                    </div>
                </div>
                <div class="row" style="margin-top: 1%;padding-top: 10px;padding-left:5px;">
                    <div class="col s6 m6 l6">
                        <span style="font-size: 15px;">
                            Address:&nbsp;<br><span><b>{{model.address}}</b></span></span>
                    </div>
                    <div class="col s3 m3 l3" style="margin: 0px;">
                        <span style="font-size: 15px;">Pincode:&nbsp;<br><span><b>{{model.pincode}}</b></span></span>
                    </div>
                    <div class="col s3 m3 l3" style="margin: 0px;">
                        <span style="font-size: 15px;">Locality:&nbsp;<br><span><b>{{model.locality}}</b></span></span>
                    </div>
                </div>
                <div class="row" style="padding-left: 10px;padding-right: 10px;padding-top: 10px;">
                    <table>
                        <tbody>
                            <tr style="background: gainsboro;">
                                <td width="17%"> <span
                                        style="font-size: 15px;">Locale:&nbsp;<span><b>{{model.locale}}</b></span></span>
                                </td>
                                <td width="17%"><span
                                        style="font-size: 15px;">City:&nbsp;<span><b>{{model.city}}</b></span></span>
                                </td>
                                <td width="17%"><span
                                        style="font-size: 15px;">State:&nbsp;<span><b>{{model.state}}</b></span></span>
                                </td>
                                <td width="15%"><span
                                        style="font-size: 15px;">Country:&nbsp;<span><b>{{model.country}}</b></span></span>
                                </td>
                                <td width="17%"> <span
                                        style="font-size: 15px;">Area:&nbsp;<span><b>{{model.areaname}}</b></span></span>
                                </td>
                                <td width="17%"> <span
                                        style="font-size: 15px;">Region:&nbsp;<span><b>{{model.regionname}}</b></span></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" style="padding-right: 10px;padding-top: 10px;padding-left:5px;">
                    <div class="col s3 l3 m3">
                        <span style="font-size: 15px;">Mobile :&nbsp;<br><span><b>{{model.mobile1}}</b></span></span>
                    </div>
                    <div class="col s3 l3 m3">
                        <span style="font-size: 15px;">Landline:&nbsp;<br><span><b>{{model.landline}}</b></span></span>
                    </div>
                    <div class="col s3 l3 m3">
                        <span style="font-size: 15px;">Email-id:&nbsp;<br><span><b>{{model.email}}</b></span></span>
                    </div>
                    <div class="col s3 l3 m3">
                        <span style="font-size: 15px;">Website:&nbsp;<br><span><b>{{model.website}}</b></span></span>
                    </div>
                </div>
                <div class="row" style="padding-left:5px;">
                    <div class="input-field col s6 l6 m6" style="margin-bottom:0px;">
                        <!-- padding-top: 10px -->
                        <input [(ngModel)]="model.googlemap" #googlemap="ngModel" type="text" id="googlemap"
                            title="Google map" maxlength="15">
                        <label id="lable5" class="active" for="googlemap">Google Map
                            Location</label>
                    </div>
                    <div class="input-field col s6 l6 m6" style="margin-bottom:0px;">
                    </div>

                </div>
                <div class="row" style="padding-left: 5px;padding-right: 10px;">
                    <div class=" input-field col s4">
                        <span>Branch</span>
                        <select (change)=" onSelectbranch($event.target.value)">
                            <option *ngFor="let type of lstbranch" [value]="type.id"
                                [selected]="type.id==model.branchID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s4">
                        <span>Assigned To</span>
                        <select (change)=" onSelectlstassigned($event.target.value)">
                            <option *ngFor="let type of lstassigned" [value]="type.id"
                                [selected]="type.id==model.assignedtoID">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 10px;">
                        <span>Assign Date:&nbsp;<br><span><b>{{model.assigndate | date:'d MMMM y'}}</b></span></span>
                    </div>
                </div>

                <div class="row" style="padding-left: 10px;padding-right: 10px;padding-top: 10px;">
                    <div class="row">

                        <div class="col s12 m12 l12 registerBgShadow" style="padding-right: 9px;padding-left: 9px;">
                            <div class="input-field col l6 m6 s6 " style="padding-top: 10px;padding: 0px;">
                                <b style="font-size: 15px;">Unit Product Installation</b>
                            </div>
                            <!-- <div class=" col l4  m2 s2" style="padding-top: 10px;padding: 0px;margin-top: 15px;text-align: end;" >
                           <b><span *ngFor="let lst of lstproduct">{{lst.remaininstallation}} &nbsp;</span> <span>Products To Assign</span></b>
                        </div> -->
                            <div class="input-field col l6 m2 s2"
                                style="text-align: end;padding-right: 3px;display: none;">
                                <button type="button" class="waves-ripple waves-light btn" style="margin-right: 5px;"
                                    (click)="openproduct(productmodel)" [disabled]="model.totalquantity == 0">Add
                                    Products</button>
                            </div>

                            <div class="col l12 m12 s12 registerBgShadow" style="margin-top: 1%">

                                <div class="row" *ngIf="this.forflag != 'close'">
                                    <div class="col l12 m12 s12" style="margin-top:9px;margin-bottom: 9px;">
                                        <table>
                                            <thead style="background: gainsboro;">
                                                <th width="5%" style="text-align: center"><input type="checkbox"
                                                        [(ngModel)]="model.chkall" #chkall="ngModel"
                                                        (click)="btnselectAllProduct($event)"></th>
                                                <th width="12%" style="text-align: left">Product Category</th>
                                                <th width="30%" style="text-align: left">Product Model</th>
                                                <th width="10%" style="text-align: left">Serial Number</th>
                                                <th width="18%" style="text-align: left">Assigned To</th>
                                                <th width="25%" style="text-align: left">Assign To</th>

                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let lst of lstproduct | paginate: { itemsPerPage: 10, currentPage: p };let i = index">
                                                    <td style="text-align: center;">
                                                        <input type="checkbox" [(ngModel)]="lst.checkselect"
                                                            #checkselect="ngModel" [disabled]="lst.completflag == true">
                                                    </td>
                                                    <td style="text-align: left">{{lst.productcategorie}}</td>
                                                    <td style="text-align: left">{{lst.productname}}</td>
                                                    <td style="text-align: left">{{lst.serialnumber}}</td>
                                                    <td style="text-align: left">{{lst.assignedtoname}}</td>

                                                    <td class="input-field"
                                                        style="text-align: left;text-align:center;margin-top: 0px;">
                                                        <select
                                                            (change)=" onSelectlstassigtounitproduct($event.target.value,i)">
                                                            <option *ngFor="let type of lstassigned" [value]="type.id"
                                                                [selected]="type.id==lst.assignedtoID"
                                                                [disabled]="lst.completflag == true">
                                                                {{type.name}}</option>
                                                        </select>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                        <div class="row">
                                            <div class="col l12 m9 s9" style="padding-top: 5px;">
                                                <span style="font-size: 15px;">Preferred Installation
                                                    :&nbsp;<span><b>{{model.preferredInst+",
                                                            "+model.preferredInstDate+",
                                                            "+model.preferredInstTime}}</b></span></span>
                                            </div>
                                        </div>
                                        <span style="text-align:right">
                                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <mat-accordion *ngIf="this.forflag == 'close'">
                                <mat-expansion-panel hideToggle
                                    *ngFor="let lst of lstinstallation | paginate: { itemsPerPage: 10, currentPage: p };let i=index"
                                    style="padding-left: 1px;">
                                    <mat-expansion-panel-header>
                                        <div class="col l12 m12 s12"
                                            style="margin-top:20px;color: black;font-weight: bold;">
                                            <div class="row">
                                                <div class=" col l1  m1 s1">
                                                    <input [(ngModel)]="lst.checkselect"
                                                        *ngIf="lst.lstproduct.length>=0" #checkselect="ngModel"
                                                        type="checkbox" name="Select" style="text-align: 20px; ">&nbsp;
                                                </div>
                                                <div class=" col l3 m3 s3" style="text-align:left;">
                                                    <span>Name :</span><span>{{model.coustomername}}</span>
                                                </div>
                                                <div class=" col s2  m2 s2" style="text-align:left;">
                                                    <span>Unit # :</span> <span>{{model.unit}}</span>
                                                </div>
                                                <!-- <div class=" col l2 m2 s2" style="text-align:left;">
                                                    <span>Mobile :</span><span>{{model.mobile1}}</span>
                                                </div> -->
                                                <div class="col l3 m3 s3" style="text-align: left;">
                                                    <span>Invoice Total: &#8377;{{model.grandtotal | number:
                                                        '1.2-2'}}</span>
                                                </div>
                                                <div class=" col l3 m3 s3" style="text-align:left;">
                                                    <span>Email :</span><span>{{model.email}}</span>
                                                </div>
                                                <div class="col s1 m1 l1" style="text-align:right;display: none;">
                                                    <span><img src="../../../assets/images/edit.png"
                                                            data-toggle="tooltip" data-placement="top" title="Edit"
                                                            width="20px" (click)="editaddress(i,changaddress)" /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <div class="registerBgShadow col l12 m12 s12"
                                        style="text-align: center; margin-top: 10px;" *ngIf="!lst.lstproduct.length==0">
                                        <div class="row" *ngFor="let sublst of lst.lstproduct;let j=index"
                                            style="padding-left: 9px;padding-right: 9px;">

                                            <div class="row">

                                                <div class="col l4 m2 s2" style="text-align:left;">
                                                    <span>Product Model
                                                        &nbsp;<br><span><b>{{sublst.productname}}</b></span></span>
                                                </div>
                                                <div class="col l2 m2 s2" style="text-align:left;"
                                                    *ngIf="forflag=='close'&&sublst.serialnumber!=null">
                                                    <span>Serial#
                                                        &nbsp;<br><span><b>{{sublst.serialnumber}}</b></span></span>
                                                </div>
                                                <div class="col l2 m2 s2" style="text-align:left;"
                                                    *ngIf="forflag=='close'">
                                                    <span>Installation Date
                                                        &nbsp;<br><span><b>{{sublst.installtiondate | date:'d MMMM
                                                                y'}}</b></span></span>
                                                </div>
                                                <div class="col l3 m2 s2" style="text-align:left;"
                                                    *ngIf="forflag=='close'">
                                                    <span>Installation By
                                                        &nbsp;<br><span><b>{{sublst.assignedtoname}}</b></span></span>
                                                </div>
                                                <div class="col l1 m2 s2" style="text-align:left;"
                                                    *ngIf="forflag=='close'">
                                                    <span>Close &nbsp;<br>
                                                        <input type="checkbox" [(ngModel)]="sublst.checkselect"
                                                            #checkselect="ngModel" type="checkbox"
                                                            style="text-align: 20px;"
                                                            [disabled]="sublst.accountwisemcstatus"></span>
                                                </div>

                                                <div class="input-field col l4 s4 s4"
                                                    style="text-align:center;margin-top: 0px;" *ngIf="forflag!='close'">
                                                    <select
                                                        (change)=" onSelectlstassigtoproduct($event.target.value,i,j)">
                                                        <option *ngFor="let type of lstassigned" [value]="type.id"
                                                            [selected]="type.id==sublst.assignedtoID">
                                                            {{type.name}}</option>
                                                    </select>
                                                </div>

                                                <div class="col l2 s2 s2" style="text-align:center;padding-right: 0px;"
                                                    *ngIf="forflag!='close'">
                                                    <span style="margin-left: 5px;"><img
                                                            src="../../../assets/images/delete.png" title="Delete"
                                                            width="15%"
                                                            (click)="deleteproduct(i,j,sublst.productid)" /></span>
                                                </div>

                                            </div>
                                            <div class="row" *ngIf="forflag=='close'">
                                                <div class="col l2 m3 s3" style="text-align:left;">
                                                    <label id="lable4" for="first4" style="font-size:15px"
                                                        class="active">Warranty(In
                                                        Months)</label>
                                                    <input [(ngModel)]="sublst.warrantyMonth" #warrantyMonths="ngModel"
                                                        (ngModelChange)="enteredMonth(sublst.warrantyMonth,j)"
                                                        type="number" min="0" oninput="validity.valid||(value='');"
                                                        id="first4" data-toggle="tooltip" data-placement="top"
                                                        style="text-align: right;" title="Warranty" ngModel required
                                                        [disabled]="sublst.accountwisemcstatus">

                                                    <div *ngIf="warrantyMonths.invalid && (warrantyMonths.dirty || warrantyMonths.touched) "
                                                        class="alert alert-danger" style="color: red">
                                                        <div *ngIf="warrantyMonths.errors.required">
                                                            Warranty Required
                                                        </div>
                                                        <div [hidden]="!warrantyMonths.errors?.pattern">
                                                            Invalid Warranty
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col l3 m3 s3" style="text-align:left;">
                                                    <span>Warranty End Date
                                                        &nbsp;<br><span><b>{{sublst.warrantyenddate | date:'d MMMM
                                                                y'}}</b></span></span>

                                                </div>
                                                <div class="col l3 m3 s3" style="text-align:left;">
                                                    <span>Approved By
                                                        &nbsp;<br><span><b>{{sublst.assignedtoname}}</b></span></span>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>
                                    </div>

                                    <div *ngIf="lst.lstproduct.length==0" class="col l11 m11 s11 registerBgShadow"
                                        style="margin-left:50px;text-align: center;">
                                        <div class="col l12 m12 s12" style="color: red">
                                            <samp> List Not Found </samp>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <!-- <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span> -->

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;">
                        <button (click)="btnback()" type="button"
                            class="waves-effect cancelbutton waves-light btn">Back</button>&nbsp;&nbsp;
                        <button (click)="btnsave()" type="button" class="waves-effect waves-light btn"
                            *ngIf="forflag!='close'"
                            [disabled]="savehideflag || showBtn == true">Save</button>&nbsp;&nbsp;
                        <button (click)="btnclosecall()" type="button" class="waves-effect waves-light   btn"
                            *ngIf="forflag=='close'" [disabled]="model.installationclosed">Close</button>
                        <!-- model.installationclosed ||  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #productmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">Add Product</span><br>
            </div>
            <div class="row">
                <div class="">
                    <div class="col l12 m12 s12">
                        <div class="row">
                            <div class=" input-field col s12" style="margin-top:0px;">
                                <div class=" input-field col s6">
                                    <Span>Customer Name : {{name}}</Span>
                                </div>
                            </div>
                            <div class=" input-field col s12" style=" margin-top: 0px;">
                                <Span> Principal Address: {{addr}}</Span>
                            </div>


                            <div class="row" style="padding-left: 10px;padding-right: 10px;margin-top:0px;">

                                <div class=" input-field col s4" style=" margin-top: 30px;">
                                    <input [(ngModel)]="unit" #Unit="ngModel" type="text" id="Unit" title="Name">
                                    <label id="Unit" for="Unit" class="active"> Unit</label>
                                </div>
                                <div class=" input-field col s3" style=" margin-top: 30px;">
                                    <input [(ngModel)]="buildingName" #address="ngModel" type="text" id="buildingName"
                                        title="Building Name" maxlength="100">
                                    <label id="lable11" for="buildingName" class="active"> Building Name</label>
                                </div>
                                <div class=" input-field col s2" style=" margin-top: 30px;">
                                    <input type="text" class="autocomplete" [(ngModel)]="pincode" id="pincode2"
                                        placeholder="Pincode" (keyup)="getPincodeLikeforproduct($event)" />
                                    <input type="hidden" id="acc-id" />
                                </div>
                                <div class=" input-field col s3" style=" margin-top: 13px;">
                                    <span>Locality</span>
                                    <select (change)=" onSelectproductlocality($event.target.value)">
                                        <option *ngFor="let type of lstlocalityforproduct" [value]="type.id"
                                            [selected]="type.name==locality">
                                            {{type.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row" style="padding-left: 10px;padding-right: 10px;">
                                <table>
                                    <tbody>
                                        <tr style="background: gainsboro;">
                                            <td width="17%"> <span
                                                    style="font-size: 15px;">Locale:&nbsp;<span><b>{{locale}}</b></span></span>
                                            </td>
                                            <td width="17%"><span
                                                    style="font-size: 15px;">City:&nbsp;<span><b>{{city}}</b></span></span>
                                            </td>
                                            <td width="17%"><span
                                                    style="font-size: 15px;">State:&nbsp;<span><b>{{state}}</b></span></span>
                                            </td>
                                            <td width="15%"><span
                                                    style="font-size: 15px;">Country:&nbsp;<span><b>{{country}}</b></span></span>
                                            </td>
                                            <td width="17%"> <span
                                                    style="font-size: 15px;">Area:&nbsp;<span><b>{{areaname}}</b></span></span>
                                            </td>
                                            <td width="17%"> <span
                                                    style="font-size: 15px;">Region:&nbsp;<span><b>{{regionname}}</b></span></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class=" input-field col s12">


                                <table>
                                    <thead style="background: gainsboro;">
                                        <!-- <th width="5%" style="text-align: center"> </th> -->
                                        <th width="5%" style="text-align: center"></th>
                                        <th width="30%" style="text-align: center">Product</th>
                                        <th width="10%" style="text-align: center">Number Of Installtion</th>
                                        <th width="15%" style="text-align: center">Balance To Assign</th>
                                        <th width="15%" style="text-align: center">Number Of Installtion</th>

                                        <th width="25%" style="text-align: center" *ngIf="lstinstallation.length>1">
                                            Assign
                                            To
                                        </th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of lstproduct;let i = index">
                                            <td style="text-align: center;"><input type="checkbox"
                                                    [(ngModel)]="lst.checkselect" #checkselect="ngModel"></td>
                                            <td style="text-align: center">
                                                {{lst.productname}}
                                            </td>
                                            <td style="text-align: center">
                                                {{lst.quantity}}
                                            </td>
                                            <td style="text-align: center">
                                                {{lst.remaininstallation}}
                                            </td>
                                            <td style="text-align: center">
                                                <input [(ngModel)]="lst.enterquantity" style="text-align: center;"
                                                    (ngModelChange)="Enterquantity(lst.productid,i)"
                                                    #enterquantity="ngModel" type="text" title="Quantity">
                                            </td>
                                            <td class="input-field" style="text-align: center"
                                                *ngIf="lstinstallation.length>1">
                                                <select (change)=" onSelectproductlstassig($event.target.value,i)">
                                                    <option *ngFor="let type of lstassigned" [value]="type.id"
                                                        [selected]="type.id==lst.assignedtoID">
                                                        {{type.name}}</option>
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <!-- <div class="row">
                            <div class="input-field col l12 m12 s12" style="text-align: right">
                                <button (click)="btnclose()" type="button"
                                    class="waves-effect cancelbutton waves-light btn">Cancel</button>&nbsp;&nbsp;
                                <button (click)="btnchangeproductaddress()" type="button"
                                    class="waves-effect waves-light btn">Add</button>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </div>


                <!-- <div class="row ">
            <div class=" input-field col s3">
                <input [(ngModel)]="unit" #Unit="ngModel" type="text" id="Unit" title="Name">
                <label id="Unit" for="Unit" class="active"> Unit</label>
            </div>
        </div> -->
                <div class="row ">
                    <div class=" input-field col s12" style="margin: 0px;text-align: right;">
                        <!-- <div class=" input-field col s2" style="margin-top: 15px;">
                   Total Number Of Installation
                </div> -->
                        <div class="col s9"></div>
                        <div class=" input-field col s3" style="text-align: right;">
                            <input [(ngModel)]="noofinstall" (ngModelChange)="quantityupdate()" #Nameid="ngModel"
                                type="text" id="Nameid" title="Number Of Installation">
                            <label id="Nameid" for="Nameid" class="active">Total Number Of Installation</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align: right">
                            <button (click)="btnclose()" type="button"
                                class="waves-effect cancelbutton waves-light btn">Cancel</button>
                            &nbsp;&nbsp;<button (click)="btnadd()" type="button"
                                class="waves-effect waves-light btn">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #changaddress>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="registerBgShadow">
            <div class="modal-content " style="text-align: center;">
                <span style="font-size: 20px;">Change Address</span><br>
            </div>
            <div class="row">
                <div class="">
                    <div class="col l12 m12 s12">
                        <div class="row">
                            <div class=" input-field col s12" style="margin-top:0px;">
                                <div class=" input-field col s6">
                                    <input [(ngModel)]="name" #contactname="ngModel" type="text" id="contactname"
                                        title="Name">
                                    <label id="Nameid" for="contactname" class="active"> Name</label>
                                </div>
                                <div class=" input-field col s6">
                                    <input [(ngModel)]="unit" #Unit="ngModel" type="text" id="Unit" title="Name">
                                    <label id="Unit" for="Unit" class="active"> Unit</label>
                                </div>
                            </div>
                            <div class=" input-field col s12" style="margin-top:0px;margin-bottom: 0px;">
                                <div class=" input-field col s6">
                                    <input [(ngModel)]="mobile" #contactmobile="ngModel" type="text" id="contactmobile"
                                        title="Mobile" pattern="[6789][0-9]{9}" maxlength="10">
                                    <label id="Mobileid" for="contactmobile" class="active"> Mobile</label>
                                    <!-- <div *ngIf="contactmobile.errors &&(contactmobile.touched || contactmobile.dirty)" class="aler alert-danger"
                                    style="padding: 10px; color: red">
                                    <div [hidden]="!contactmobile.errors?.pattern">
                                        Invalid mobile Number
                                    </div>
                                </div> -->
                                </div>
                                <div class=" input-field col s6">
                                    <input [(ngModel)]="email" #contactemail="ngModel" type="text" id="contactemail"
                                        title="E-Mail">
                                    <label id="contactemails" for="contactemail" class="active"> E-Mail</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;margin-top:0px;">
                            <div class=" input-field col s4" style=" margin-top: 30px;">
                                <input [(ngModel)]="addr" #address="ngModel" type="text" id="address10" title="Address"
                                    maxlength="100">
                                <label id="lable10" for="address" class="active">Principal Address</label>
                            </div>
                            <div class=" input-field col s3" style=" margin-top: 30px;">
                                <input [(ngModel)]="buildingName" #address="ngModel" type="text" id="buildingName"
                                    title="Building Name" maxlength="100">
                                <label id="lable11" for="buildingName" class="active"> Building Name</label>
                            </div>
                            <div class=" input-field col s2" style=" margin-top: 30px;">
                                <input type="text" class="autocomplete" [(ngModel)]="pincode" id="pincode2"
                                    placeholder="Pincode" (keyup)="getPincodeLikeforproduct($event)" />
                                <input type="hidden" id="acc-id" />
                            </div>
                            <div class=" input-field col s3" style=" margin-top: 13px;">
                                <span>Locality</span>
                                <select (change)=" onSelectproductlocality($event.target.value)">
                                    <option *ngFor="let type of lstlocalityforproduct" [value]="type.id"
                                        [selected]="type.name==locality">
                                        {{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 10px;padding-right: 10px;">
                            <table>
                                <tbody>
                                    <tr style="background: gainsboro;">
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Locale:&nbsp;<span><b>{{locale}}</b></span></span>
                                        </td>
                                        <td width="17%"><span
                                                style="font-size: 15px;">City:&nbsp;<span><b>{{city}}</b></span></span>
                                        </td>
                                        <td width="17%"><span
                                                style="font-size: 15px;">State:&nbsp;<span><b>{{state}}</b></span></span>
                                        </td>
                                        <td width="15%"><span
                                                style="font-size: 15px;">Country:&nbsp;<span><b>{{country}}</b></span></span>
                                        </td>
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Area:&nbsp;<span><b>{{areaname}}</b></span></span>
                                        </td>
                                        <td width="17%"> <span
                                                style="font-size: 15px;">Region:&nbsp;<span><b>{{regionname}}</b></span></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class=" input-field col s12">


                            <table>
                                <thead style="background: gainsboro;">
                                    <!-- <th width="5%" style="text-align: center"> </th> -->
                                    <th width="30%" style="text-align: center">Product Categories</th>
                                    <th width="30%" style="text-align: center">Product</th>
                                    <th width="10%" style="text-align: center">UOM</th>
                                    <th width="5%" style="text-align: center">Quantity</th>
                                    <th width="15%" style="text-align: center" *ngIf="lstinstallation.length>1">Assign
                                        To
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of lstproduct;let i = index">
                                        <td style="text-align: center">
                                            {{lst.productcategorie}}
                                        </td>
                                        <td style="text-align: center">
                                            {{lst.productname}}
                                        </td>
                                        <td style="text-align: center">
                                            {{lst.uom}}
                                        </td>
                                        <td style="text-align: center">
                                            <input [(ngModel)]="lst.enterquantity" style="text-align: end;"
                                                (ngModelChange)="productquantity(lst.productid,i)"
                                                #enterquantity="ngModel" type="text" title="Quantity">
                                        </td>
                                        <td class="input-field" style="text-align: center"
                                            *ngIf="lstinstallation.length>1">
                                            <select (change)=" onSelectproductlstassig($event.target.value,i)">
                                                <option *ngFor="let type of lstassigned" [value]="type.id"
                                                    [selected]="type.id==lst.assignedtoID">
                                                    {{type.name}}</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="input-field col l12 m12 s12" style="text-align: right">
                                <button (click)="btnclose()" type="button"
                                    class="waves-effect cancelbutton waves-light btn">Cancel</button>&nbsp;&nbsp;
                                <button (click)="btnchangeproductaddress()" type="button"
                                    class="waves-effect waves-light btn">Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>