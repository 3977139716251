import { Component, OnInit } from '@angular/core';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, DatePipe } from '@angular/common'
import { User } from 'src/app/_models';
import { SearchAccountWiseMachineDetailModel } from './SearchAccountWiseMachineDetailModel';
declare var $: any;
@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'SearchAccountWiseMachineDetail.html'
})

export class SearchAccountWiseMachineDetail implements OnInit {
    model: any = {};
    sum: any;
    user = new User();
    userList2: any;
    lstflag:boolean=false;
    accwiselst: SearchAccountWiseMachineDetailModel[] = [];
    pagename: string;

    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, ) {
        this.pagename=this.router.url
    }
    ngOnInit(): void {
        this.user.pageno = 0;
        this.user.pageSize = 20;
        this.getAccountwismcDetail();
        localStorage.setItem("pagename",this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
    }

    getAccountwismcDetail() {
       

    }
    onScroll() {
        this.sum = this.sum + 1;
        this.user.pageno = this.sum + 1;
        this.user.pageSize = 5;
        this.getAccountwismcDetail();
    }

    getUserIdsSecondtWay1($event) {
        let userId = (<HTMLInputElement>document.getElementById('dynamicUserIdsSecondWay1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.Masterservice.getAccountLikeForMchDtl(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect(id, name) {
        this.accwiselst = [];
        this.user.trnid = id;
        this.model.accountid = id;
        this.model.name = name;
        this.lstflag= false;
        this.Masterservice.getAccountwismcDetail(this.model)
            .subscribe(value => {
                this.accwiselst = value
                
            })

            if(this.accwiselst.length<=0){
               
                this.lstflag=true;
            }
    }



    btndelts(id) {
        this.model.id = id
        this.Masterservice.deleteaccwisemc(this.model)
            .subscribe(value => {
                this.notifyService.showSuccess(value.message, " Success");
                this.accwiselst = [];
                this.model.name = "";
            });
    }
    btnedit(id) {
        this.router.navigate(['/DefineAccountwisemachinedetail', id]);
    }
    btnnew() {
        this.router.navigate(['/DefineAccountwisemachinedetail']);
    }
    cancle() {
        this.router.navigate(["/CRMHome"]);
    }
    
}