<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Daily Production Plan</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow " style="margin-top: 1%;padding-top: 5px;">
            <div class="col l12 m12 s12"   style="text-align: center;padding: 0px;">
                <span style="font-size: 16px;">Create Plan</span>
            </div>
           
            <div class="row" style="margin-top: 1%;padding-left:5px;">
                <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span style="font-size: 16px;">Production Plan ID <br>
                    <b>{{model.planID}}</b></span>
                </div>
                <div class="input-field col l2 m2 s2" style="margin: 0px">
                    <span style="font-size: 16px;">Date<br>
                        <input type="text" placeholder="Follow up Date" [(ngModel)]="model.plandate" id="date"
                        class="form-control" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue',placement:'top' }"
                        (ngModelChange)="changedate()" >
                    </span>

                </div>
                <div class="input-field col l5 m5 s5" style="margin: 0px">
                    <span>Inventory Locations</span>
                    <select (change)="onSelectLocation($event.target.value)">
                        <option selected value="0">Select Inventory Location</option>
                        <option *ngFor="let locations of inventorylocations" [value]="locations.id"
                        [selected]="locations.id==model.locationid">
                            {{locations.name}}</option>
                    </select>
                </div>
            </div>
            <div class=row>
                <div class="input-field col l3 m3 s3">
                    <span>Item Category</span>
                    <select (change)="onSelectitemCat($event.target.value)">
                        <option selected value="0">Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3">
                    <span>Sub Category</span>
                    <select (change)="onSelectSubCat($event.target.value)">
                        <option selected value="0">Select Sub Category</option>
                        <option *ngFor="let type of subCatlst" [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class=" input-field col l3 m3 s3" style="padding-top: 14px;">
                    <input type="text" class="autocomplete" [(ngModel)]="model.itemname" id="productname"
                        data-toggle="tooltip" data-placement="top" title="Product Name" (keyup)="getItemLike()" />
                    <label for="item" class="active">Search Item</label>
                </div>
                <div class="input-field  col l2 m2 s2" style="padding-top: 14px;">
                    <input [(ngModel)]="model.quantity" style="text-align: end;" onkeypress="return /[0-9]/i.test(event.key)" id="qua"
                        type="text" title="Quantity" maxlength="10">
                    <label for="qua" class="active">Quantity</label>
                </div>
                <div class="input-field  col l1 m1 s1">
                    <button type="button" (click)="btnAdd()" class="waves-ripple waves-light btn">Add</button>
                </div>
            </div>
            <div class=row>
                <div class="col l12 m12 s12" style="margin-top: 25px; padding-left: 18px;padding-right: 18px;">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="10%" style="text-align: center">Item Code</th>
                            <th width="20%" style="text-align: center">Item Name</th>
                            <th width="5%" style="text-align: center">UOM</th>
                            <th width="10%" style="text-align: center">Production Quantity</th>
                            <th width="10%" style="text-align: center">Avaliable Stock</th>
                            <th width="10%" style="text-align: center">Production Plan</th>
                            <th width="5%" style="text-align: center">Action</th>

                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let lst of model.lstitem | paginate: { itemsPerPage: 10, currentPage: p };let i=index;">
                                <td style="text-align: center">{{lst.itemcode}}</td>
                                <td style="text-align: center">{{lst.itemname}}</td>
                                <td style="text-align: center">{{lst.uom}}</td>
                                <td style="text-align: center">
                                    <input [(ngModel)]="lst.productionquantity"  style="text-align: end;" onkeypress="return /[0-9]/i.test(event.key)" id="qua"
                                    type="text" title="Production Quantity" maxlength="10"  (ngModelChange)="onChangeOfqantity(i)">
                                </td>
                                <td style="text-align: center">{{lst.availablestock | number:'1.2-2'}}</td>
                                <td style="text-align: center">
                                    <input [(ngModel)]="lst.productionplanquantity"  style="text-align: end;" onkeypress="return /[0-9]/i.test(event.key)" id="quaty"
                                    type="text" title="Plan Quantity" maxlength="10">
                                </td>

                                <td style="text-align: center"> <span><img src="../../../assets/images/delete.png"
                                      data-toggle="tooltip" (click)="deleleitem(lst.productionplandtlid,i)"
                                            data-placement="top" title="delete" width="20px" /></span>&nbsp;&nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right" >
                    <button (click)="btnback()" type="button"
                        class="waves-effect cancelbutton waves-light btn">Back</button>
                    &nbsp;&nbsp;
                    <button (click)="btncancle()" type="button"
                    class="waves-effect cancelbutton waves-light btn">Cancle</button>
                &nbsp;&nbsp;<button (click)="btnsave()" type="button"
                        class="waves-effect waves-light   btn">Next >> Conversion
                        Templates</button>
                </div>
            </div>




        </div>
    </div>
</div>