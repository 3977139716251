export class StockLedgerReportModel{
    id: number;
    frmdate: Date;
    todate: Date;
    itemname:string;
    chkAllitem:string;
    itemid:number;

    srno: number;
    date:Date;
    productName:string;
    type:string;
    party:string;
    referenceNo:number;
    batch:string;
    previousStock:string;
    transaction:string;
    updatedStock:string;
    currentStock:string;

    companyname:string;
    companyaddress:string;
}