
import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { SearchAllowanceMasterModel } from './SearchAllowanceMasterModel';

@Component({
    selector: 'app-AMCd',
    //  moduleId: module.id,
    templateUrl: 'SearchAllowanceMaster.html'
})
export class SearchAllowanceMaster implements OnInit {
    user = new User();
    p:any;
    model = new SearchAllowanceMasterModel();
    allowlst:SearchAllowanceMasterModel[]=[];
    allowlst1:SearchAllowanceMasterModel[]=[];
    id: number;
    public i;
    pagename: string;
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, ) {
        this.pagename=this.router.url
    }
    
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Code" },
        { id: 3, "name": "Name" }
    ];

    ngOnInit(): void {
        this.getAllallowance();
        localStorage.setItem("pagename",this.pagename);
        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
}

getAllallowance() {
    this.allowlst = []
    this.Masterservice.getAllallowance(this.user)
        .subscribe(value => {
            this.allowlst = [];
            this.allowlst1 = [];
            value.forEach((item) => this.allowlst1.push({ "id": item.id , "userId":item.userId, "name": item.name, "code": item.code,  "txtserch": item.txtserch, "txtselect": item.txtselect, "userid":item.userid }));
            this.allowlst = this.allowlst1; 
        });
}

btnnew() {
    this.router.navigate(['/DefineAllowance']);
}

btnedit(value) {
    this.router.navigate(['/editallowance', value]);
}

btndelts(id) {
    this.i = confirm("Do you want to delete this record ?")
    if (this.i == true) {   
        this.model.id = id
        this.model.userId=Number(localStorage.getItem("userid"));
        this.Masterservice.deleteAllowance(this.model)
            .subscribe(value => {
                if (value.status == "OK") {
                    this.notifyService.showSuccess("Allowance Deleted Successfully", " Success");
                    this.ngOnInit()
                }
            });
    }
 
}

onSelectAllowance(value) {
    this.model.txtselect = value;
}

btnsearch($event) {
    if (this.model.txtselect == "All") {
        this.getAllallowance()
        this.model.txtserch = null;
    }
   
    else if (this.model.txtselect == "Code") {
        if (this.model.txtserch == null || this.model.txtserch == "") {
            alert("Enter Criteria To Search")
            return;
        }
        else {
            this.allowlst1 = [];
            this.allowlst.forEach((item) => {
                if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                    this.allowlst1.push({  "id": item.id ,"name": item.name,"userId":item.userId, "code": item.code,  "txtserch": item.txtserch, "txtselect": item.txtselect,"userid":item.userid });
                }
            });
            this.allowlst = this.allowlst1;
        }
 
    }
    else if (this.model.txtselect == "Name") {
        if (this.model.txtserch == null || this.model.txtserch == "") {
            alert("Enter Criteria To Search")
            return;
        }
        else {
            this.allowlst1 = [];
            this.allowlst.forEach((item) => {
                if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                    this.allowlst1.push({ "id": item.id ,"name": item.name,"userId":item.userId, "code": item.code,  "txtserch": item.txtserch, "txtselect": item.txtselect,"userid":item.userid });
                }
            });
            this.allowlst = this.allowlst1;
        }
    }
}


}