<app-header></app-header>
<div class="container" style="width:90%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Dealer Purchase Order</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l6 m6 s6">
                    <div class="row">
                        <div class="row">
                            <div class="col s11" style="margin-top: 30px;margin-left: 20px;">
                                <label id="lable1" for="first1"><b>{{model.customername}}</b></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s7" style="margin-left: 0px;padding-left: 0px;margin-top: 10px;">
                                <span style="font-size: 14px;margin-left: 30px;"><b>Shipping Address</b></span>
                            </div>
                            <div class="col s4" style="margin-right: 20px;text-align: right;padding-left: 24px;"
                                *ngIf="model.id == null">
                                <button type="button" (click)="openshippingmodel(model.accountid,changemodel)"
                                    class="waves-effect waves-light btn" style="margin-right: 5px; left:35%;">Change
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col s1">
                            </div>
                            <div class="col s11" style="margin-left: 40px;font-size: 45px;">
                                <label id="lable2" for="first2">{{model.address}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col l6 m6 s6">
                    <div class="row">
                        <div class="row">
                            <div class="col s7"></div>
                            <div class="col s4" style="margin-top: 20px;text-align: right;">
                                <span style="font-size: 15px;"><b>Payment Terms</b></span>
                            </div>
                            <div class="col s1"></div>
                        </div>
                        <div class="row">
                            <div class="col s6">
                            </div>
                            <div class="col s5" style="margin-top: 1px;font-size: 15px;text-align: right;">
                                <div><span>Credit Days :</span> <label id="lable3"
                                        for="first3">&nbsp;{{model.creditdays}}</label></div>
                            </div>
                            <div class="col s1"></div>
                        </div>

                        <div class="row">
                            <div class="col s5">
                            </div>
                            <div class="col s6" style="font-size: 15px;text-align: right;">
                                <div><span>Available Credit Limit :</span><label id="lable4"
                                        for="first4">&#8377;&nbsp;{{model.creditamount}}</label></div>
                            </div>
                            <div class="col s1"></div>
                        </div>
                        <div class="row">
                            <div class="col s1"></div>
                            <div class="col s10" style="font-size: 15px;text-align: right;">
                                <!-- margin-left: 75%; -->
                                <span>Date Time :</span>
                                {{model.dateTime | date:"dd-MM-yyyy"}}
                            </div>
                            <div class="col s1"></div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12"
                        style="font-size: 15px;margin-left: 0px;text-align: right;padding-right: 57px;">
                        <span>Last Invoice :</span>
                        [{{model.invoiceDate | date:"dd-MM-yyyy"}}&nbsp;]<span>&#8377;{{model.grandtotal}}</span>
                    </div>
                    <!-- <div class="col s1"></div> -->
                </div>
                <hr>
                <div class="row">
                    <!-- <div class="col s1"></div> -->
                    <div class="input-field col s4" style="margin-left: 12px;" *ngIf="model.id == null">
                        <span>Category</span>
                        <select (change)="onSelectprocategory($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Product Category">
                            <option selected value="0">Select Category</option>
                            <option *ngFor="let lst of productcatelst" [value]="lst.id"
                                [selected]="lst.id==productcategoryid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" *ngIf="model.id == null">
                        <span>Product</span>
                        <select (change)="onSelectproduct($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Products">
                            <option selected>Select One</option>
                            <option *ngFor="let plst of productlst" [value]="plst.productid"
                                [selected]="plst.productid==productid">
                                {{plst.prouctname}}</option>
                        </select>
                    </div>


                    <div class="input-field col l2 m2 s2" *ngIf="currencylst.length>0">
                        <span>Currency</span>
                        <select (change)="onSelectcurrency($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Currency">
                            <option selected>Select One</option>
                            <option *ngFor="let lst of currencylst" [value]="lst.id">
                                {{lst.name}}</option>
                        </select>
                    </div>

                    <!-- <div class="input-field col l2 m2 s2" *ngIf="currcymodel.id != null" style="margin-top: 35px">
                        <span style="font-size: 17px">Currency:&nbsp;<span>{{currcymodel.name}}</span></span>
                    </div> -->
                    <div class="col l2 m2 s2" style="margin-top: 30px" *ngIf="model.id == null">
                        <button (click)="btnAddProduct()" type="button"
                            class="waves-effect waves-light btn">Add</button>
                    </div>
                </div>
                <div class="row" style="margin:0px; padding-left: 9px;padding-right: 9px;">
                    <div class="col l12 m12 s12" style="margin-top: 0px;padding-left: 0px;padding-right: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="5%" style="text-align: center;"><input type="checkbox" class="filled-in"
                                        [(ngModel)]="model.allcheckselect" (change)="onselectall($event)"
                                        #allcheckselect="ngModel"></th>
                                <th width="25%" style="text-align: left">Product</th>
                                <th width="13%" style="text-align: left">UOM</th>
                                <th width="10%" style="text-align: right">Order Quantity</th>
                                <th width="10%" style="text-align: right">Price</th>
                                <th width="7%" style="text-align: right">Discount</th>
                                <th width="7%" style="text-align: right">GST %</th>
                                <th width="10%" style="text-align: right">GST Amount</th>
                                <th width="10%" style="text-align: right;padding-right: 10px;">Value</th>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: p } ; let i = index ">
                                    <td style="text-align: center;"><input type="checkbox"
                                            [(ngModel)]="lst.procheckselect" #procheckselect="ngModel"></td>
                                    <td style="text-align: left">{{lst.prouctname}}</td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <!-- <td style="text-align: right" *ngIf="lst.iseditflag != false">{{lst.quantity}}</td> -->
                                    <!-- *ngIf="lst.iseditflag == false" -->
                                    <td style="text-align: right; margin: 10px;">
                                        <input [(ngModel)]="lst.quantity" #quantity="ngModel" type="number" min="1"
                                            oninput="validity.valid||(value='');" id="quantity" data-toggle="tooltip"
                                            data-placement="top" placeholder="Order quantity"
                                            (ngModelChange)="onChangeOfqantity(lst.id,lst.discount)"
                                            style="text-align: right;" [disabled]="lst.iseditflag == true">
                                    </td>
                                    <!-- [disabled]="lst.iseditflag != false || model.approvedorderflg == 'C'" -->
                                    <td style="text-align: right" *ngIf="lst.ratewithdiscount == null">{{lst.minimumPrice | number:'1.2-2' }}</td>
                                    <td style="text-align: right" *ngIf="lst.ratewithdiscount != null">{{lst.ratewithdiscount | number:'1.2-2' }}</td>
                                    <td style="text-align: right">{{lst.discount | number:'1.2-2' }}</td>
                                    <td style="text-align: right">{{lst.gstrate | number:'1.2-2' }}</td>
                                    <td style="text-align: right">{{lst.gstamount | number:'1.2-2' }}</td>
                                    <td style="text-align: right;padding-right: 10px;">{{lst.value | number:'1.2-2'}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>

                <div class="row" style="padding-left: 5px;" *ngIf="model.chkschemeflag =='Y'">
                    <!-- *ngIf="model.chkschemeflag =='Y'" -->
                    <div class="input-field col l2 m2 s2" style="margin-left: 9px;margin-top: 0px;">
                        <input [(ngModel)]="model.schmemCode" #schmemCode="ngModel" type="text" id="schmemCode"
                            data-toggle="tooltip" style="text-align: end" data-placement="top" title="Scheme Code"
                            (ngModelChange)="selectSchemeCode(model.schmemCode)">
                        <label for="schmemCode">Scheme Code</label>
                    </div>
                    <div class="input-field col l2 m2 s2" style="margin-top: 0px;"
                        *ngIf="schemeModel.discountType == 'Custom' || model.schemetype == 'Custom'">
                        <input [(ngModel)]="model.customnumber" #customnumber="ngModel" type="text" id="customnumber"
                            data-toggle="tooltip" style="text-align: end" data-placement="top"
                            placeholder="CustomNumber">
                    </div>
                    <div class="col l1 m1 s1" style="margin-top: 0px;" *ngIf="model.schmemCode!=null">
                        <span><img src="../../../assets/images/cancel.png" width="50%"
                                (click)="removeScheme()" /></span>
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 0px;">
                        <button (click)="btnApplyScheme()" type="button" class="waves-effect waves-light btn"
                            [disabled]="model.schmemCode==null ||  model.approvedorderflg == 'C'">Calculate</button>
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 23px;">
                        <b>Discount Value:</b> {{model.discountAmount}}
                    </div>
                </div>

                <div class="row">
                    <div class="col l12 m12 s12" style="text-align: left;">
                        <span style="font-size: 15px;padding-left: 12px;"><b>Amount in Words:</b>&nbsp;</span>
                        <span style="font-size: 15px;"><b>{{alltotalword | uppercase}} ONLY&nbsp;&nbsp;&nbsp;</b></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="text-align: right;padding-right: 9px;">
                        <span style="font-size: 15px; font-weight: bold;padding-right: 35px;">Net
                            Order:&nbsp;<span>{{netorder |
                                number:'1.2-2'}}</span>
                        </span>
                        <span style="font-size: 15px; font-weight: bold;padding-right: 35px;">Gst:&nbsp;<span>{{ gst |
                                number:'1.2-2'}}</span>
                        </span>
                        <span style="font-size: 15px; font-weight: bold;padding-right: 10px;">Total:&nbsp;<span>{{ total
                                |
                                number:'1.2-2'}}&nbsp;</span>
                        </span>
                    </div>
                    <!-- <div class="col l2 m2 s2" style="text-align: right;">
                        
                    </div>
                    <div class="col l2 m2 s2" style="text-align: right;">
                       
                    </div> -->
                </div>
                <!-- <div class="row">
                    <div class="col l1"> </div>
                    <div class="col l11 m11 s11" style="text-align: right;">
                        <span style="font-size: 15px;">Amount in Words:&nbsp;</span><span
                            style="font-size: 15px;">{{alltotalword | uppercase}} Only&nbsp;&nbsp;&nbsp;</span>
                    </div>
                </div> -->
                <hr>

                <div class="row">
                    <!-- <div class="col s1"> </div> -->
                    <div class="col s12" style="margin-left: 20px;">
                        <span style="font-size: 15px;font-style: normal;">Freight For Orders Above</span><span
                            style="font-size: 15px;">&nbsp;&nbsp;&#8377;{{model.freightvalue}}</span> <span
                            style="font-size: 15px;font-style: normal;">&nbsp;At No Extra Cost</span>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col s1"></div> -->
                    <div class="col s12" style="margin-left: 20px;">
                        <label> <span style="font-size: 15px;font-style: normal;">Freight On "To Pay" Basis For Order
                                Less Than
                                or Equal To</span><span style="font-size: 15px;">&nbsp;&nbsp;&#8377;{{freefreightvalue}}
                            </span></label>
                    </div>
                </div>


                <div class="row" style="padding-right:0px;margin-right: 0px;">
                    <!-- <div class="col s1"> </div> -->
                    <div class="col l12 m12 s12" style="padding-left:25px">
                        <div class="input-field col l9"
                            style="margin-left: 0px;padding-left: 0px;padding-right: 0px;font-size: 15px;">
                            <input [(ngModel)]="model.termchecselect" #termchecselect="ngModel" type="checkbox"
                                style="text-align: 20px; ">
                            I Agree To The <a><span>Terms & Conditions</span></a>
                        </div>
                        <div class="input-field col s3" style="text-align: right;padding-left: 0px;padding-right: 0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button *ngIf="model.termchecselect" type="button" (click)="btnsave()"
                                class="waves-ripple waves-light btn" [disabled]="model.approvedorderflg == 'C'"
                                style="margin-right: 0px;">Process</button>
                        </div>
                        <!-- <div class="input-field col s2" style="text-align: right" >
                        
                    </div> -->
                    </div>
                </div>




            </div>
        </div>
    </div>



    <ng-template #changemodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-content registerBgShadow " style="text-align: center;">
            <h4>Shipping Address</h4>
            <div class=" col l12 m12 s12 " style="padding-left: 44px;padding-top: 21px;">
                <div class="row">
                    <div class="row col l12 m12 s12" *ngFor="let lst of model.addresslst">
                        <div class="row">
                            <div class=" col l0 m0 s10" style="text-align: left;">
                                <label><input name="grup1" type="radio"
                                        (click)="onselectaddress(lst.shipingaddid,$event)" [checked]="lst.cheselect">
                                    <span><b>Address:-</b></span>&nbsp;&nbsp;&nbsp;<span
                                        style="background-color: aliceblue;"> {{lst.address
                                        }}</span></label>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col s1">
                            </div>
                            <div class=" col l11 m11 s11" style="text-align: left;">
                                <div class=" col l3 m3 s3">
                                    <span><b>Locality:-</b></span>&nbsp;&nbsp;&nbsp;<span> {{lst.localityName}}</span>
                                </div>

                                <div class=" col l3 m3 s3" style="text-align: left;">
                                    <span><b>Pincode:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.pincode}}</span>
                                </div>
                                <div class=" col l2 m2 s2" style="text-align: left;">
                                    <span><b>City:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.city}}</span>
                                </div>
                                <div class=" col l3 m3 s3" style="text-align: left;">
                                    <span><b>State:-</b></span>&nbsp;&nbsp;&nbsp;<span>{{lst.state}}</span>
                                </div>
                            </div>
                        </div> -->
                        <hr>
                    </div>


                </div>
            </div>
            <div class="row col s12" style="text-align: center;padding-top: 16px;">
                <button type="button" (click)="btnsaveaddress()"
                    class="waves-effect waves-light btn">Add</button>&nbsp;&nbsp;&nbsp;

            </div>
        </div>
    </ng-template>
</div>