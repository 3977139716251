import { OnInit, Component, TemplateRef } from '@angular/core';
import { MasterServices, TransactionServices, CompanyService, AlertService } from 'src/app/_services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { Products, Contacts } from '../DailyCall/DailyCallModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CoustomerflagModel } from 'src/app/_models/CoustomerflagModel';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { SchemeConfigurateModel } from './SchemeConfigurateModel';
import { AreaModel1 } from '../Teams/TeamModel';
import { AreaModel } from 'src/app/Master/ResourceRights/ResourceRightsModel';
import * as moment from 'moment';
var converter = require('number-to-words');
@Component({
  selector: 'app-SchemeConfigurate',
  templateUrl: 'SchemeConfigurate.html',
})
export class SchemeConfigurate implements OnInit {
  constructor(private Masterservice: MasterServices, private modalService: BsModalService, private CompanyService: CompanyService, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private transactionservice: TransactionServices, private alertService: AlertService) {
  }
  model = new SchemeConfigurateModel();
  user = new User();
  lstcustomertype: DropDownModel[] = [];
  lstcountry: DropDownModel[] = [];
  lstregion: DropDownModel[] = [];
  lstarea: AreaModel1[] = [];
  lstcity: DropDownModel[] = [];
  sub: any;
  id: number;
  action: string;

  pagename: string = "Scheme Configurator"
  ngOnInit() {
    this.model.userid = Number(localStorage.getItem("userid"));
    this.sub = this.route.params.subscribe(params => {
      this.id = + params["id"];
      this.action = params["flag"]
    });

    if (this.id != null && this.id > 0) {
      let user = new User();
      this.user.trnid = this.id;
      this.model.id = this.id;
      //this.model.productid = this.id;
      this.Masterservice.getSchemeConfigureByid(this.model)
        .subscribe(
          data => {
            this.model = data;
            this.model.id = data.id;
            if (data.fromdate != null) {
              this.model.fromdate = new Date(data.fromdate);
            }
            if (data.todate != null) {
              this.model.todate = new Date(data.todate);
            }
            if (data.areaid != null) {
              this.model.areaid = data.areaid;
            }
            for (let i = 0; i < this.lstarea.length; i++) {
              this.lstarea[i].areaname = this.model.areaname;
            }

            if (data.cityid != null) {
              this.model.cityid = data.cityid;
            }
            // for (let j = 0; j < this.lstcity.length; j++) {
            //   this.lstcity[j].name = this.model.cityname

            // }
            //this.itemlst = data.itemlst

          },
          error => {
            this.alertService.error(error);
          });
      let l1 = (<HTMLInputElement>document.getElementById('lable1'));
      l1.className = "active";
      let l2 = (<HTMLInputElement>document.getElementById('lable2'));
      l2.className = "active";
      let l3 = (<HTMLInputElement>document.getElementById('lable3'));
      l3.className = "active";
      let l4 = (<HTMLInputElement>document.getElementById('lable4'));
      l4.className = "active";
      let l5 = (<HTMLInputElement>document.getElementById('lable5'));
      l5.className = "active";
      let l6 = (<HTMLInputElement>document.getElementById('lable6'));
      l6.className = "active";
      let l7 = (<HTMLInputElement>document.getElementById('lable7'));
      l7.className = "active";
      // let l8 = (<HTMLInputElement>document.getElementById('lable8'));
      // l8.className = "active";
      // let l9 = (<HTMLInputElement>document.getElementById('lable9'));
      // l9.className = "active";
      // let l10 = (<HTMLInputElement>document.getElementById('lable10'));
      // l10.className = "active";
      // let l11 = (<HTMLInputElement>document.getElementById('lable11'));
      // l11.className = "active";

    }

    this.getcustomertype();
    this.getAllCountries();
    this.getRegions();
    this.getArea();
    this.model.fromdate = new Date();
    this.model.todate = new Date();
    this.Masterservice.getAllCitiesByCountries(this.user)
      .subscribe(value => {
        this.lstcity = [];
        this.lstcity.push({ id: 0, name: "Select One" });
        value.forEach((item) => this.lstcity.push({ id: item.id, name: item.name }));
        for (let j = 0; j < this.lstcity.length; j++) {
          this.lstcity[j].id = this.model.cityid
          this.lstcity[j].name = this.model.cityname
        }

      });
  }
  getcustomertype() {
    this.model.userid = Number(localStorage.getItem('userid'));
    this.lstcustomertype = [];
    let user = new User();
    this.lstcustomertype.push({ id: 0, name: "Select Customer Type" });
    this.Masterservice.getAllCustomertypes(user)
      .subscribe(data => {
        data.forEach(item => {
          this.lstcustomertype.push({ id: item.id, name: item.name });
        });
      });
  }

  onSelectcustmertype(id) {
    this.model.customertypeid = id;
  }

  getAllCountries() {
    this.lstcountry = [];
    this.lstcountry.push({ id: null, name: "Select One" });
    this.Masterservice.getAllCountries(this.user)
      .subscribe(value => {
        value.forEach((item) => this.lstcountry.push({ id: item.id, name: item.name }));

      });

  }

  onSelectcountry(id) {
    this.model.countryid = id;
    this.user.trnid = id;
    this.Masterservice.getAllCitiesByCountries(this.user)
      .subscribe(value => {
        this.lstcity = [];
        this.lstcity.push({ id: 0, name: "Select One" });
        value.forEach((item) => this.lstcity.push({ id: item.id, name: item.name }));


      });


  }

  getRegions() {
    this.lstregion = [];
    this.lstregion.push({ id: null, name: "Select One" });
    this.Masterservice.getAllRegions(this.user)
      .subscribe(value => {
        value.forEach((item) => this.lstregion.push({ id: item.id, name: item.name }));
      });
  }
  getArea() {
    this.lstarea = [];
    this.lstarea.push({ areaid: 0, areaname: "Select One", chkselectarea: null, citylst: null });
    this.Masterservice.getAreaByRegion(this.user)
      .subscribe(value => {
        value.forEach((item) => this.lstarea.push({ areaid: item.areaid, areaname: item.areaname, chkselectarea: null, citylst: null }));
      });

  }

  onSelectRegions(id) {
    this.lstarea = [];
    this.model.regionid = id;
    this.user.trnid = id;
    this.lstarea.push({ areaid: 0, areaname: "Select One", chkselectarea: null, citylst: null });
    this.Masterservice.getAreaByRegion(this.user)
      .subscribe(value => {
        value.forEach((item) => this.lstarea.push({ areaid: item.areaid, areaname: item.areaname, chkselectarea: null, citylst: null }));
      });
  }

  onSelectArea(id) {
    this.model.areaid = id;

  }

  onSelectcity(id) {
    this.model.cityid = id;
  }


  onchkpercentage() {
    this.model.percentageflag = true;
    this.model.valueflag = false
  }

  onchkvalue() {
    this.model.percentageflag = false;
    this.model.valueflag = true
  }

  onchkSingle() {
    this.model.single = true;
    this.model.serial = false;
    this.model.couponType = "single";
  }

  onchkSerial() {
    this.model.single = false;
    this.model.serial = true;
    this.model.couponType = "serial";
    this.onchkqualimited();
  }

  onchkQuanUnlimited() {
    this.model.quanType = "unlimited"
    this.model.quaUnlimited = true;
    this.model.qualimited = false;
    this.model.qualimitedvalue = null;
  }

  onchkqualimited() {
    this.model.quanType = "limited"
    this.model.quaUnlimited = false;
    this.model.qualimited = true;
  }

  onchkschemeUnlimited() {
    this.model.schemeUnlimited = true;
    this.model.schemelimited = false;
    this.model.schemeValidity = "unlimited"
    this.model.fromdate = null;
    this.model.todate = null;
  }

  onchkschemelimited() {
    this.model.schemeValidity = "limited"
    this.model.schemeUnlimited = false;
    this.model.schemelimited = true;
    this.model.schemedays = null;
  }

  chkForclose($event) {
    if ($event.target.checked) {
      this.model.forcloseflag = true
    } else {
      this.model.forcloseflag = false
    }

  }

  btnsaveScheme() {
    if (this.model.code == null) {
      this.notifyService.showRroor("Please Enter Scheme Code", "Oops!");
      return;
    }
    if (this.model.name == null) {
      this.notifyService.showRroor("Please Enter Scheme Name", "Oops!");
      return;
    }

    if (this.model.geographicflag == true) {
      if (this.model.countryid == null) {
        this.notifyService.showRroor("Please select country", "Oops!");
        return;
      } else {
        if (this.model.cityid == null) {
          this.notifyService.showRroor("Please select city", "Oops!");
          return;
        }
      }
      if (this.model.regionid == null) {
        this.notifyService.showRroor("Please select region", "Oops!");
        return;
      } else {
        if (this.model.areaid == null) {
          this.notifyService.showRroor("Please select area", "Oops!");
          return;
        }
      }

    }

    if (this.model.customertypeid == null) {
      this.notifyService.showRroor("Please Select Customer Type", "Oops!");
      return;
    }
    if (this.model.percentageflag == false && this.model.valueflag == false) {
      this.notifyService.showRroor("Please Select Discount Type", "Oops!");
      return;
    }
    if (this.model.percentageflag == true) {
      if (this.model.percentage == null) {
        this.notifyService.showRroor("Please enter percentage", "Oops!");
        return;
      }
      if (this.model.percentage < 1) {
        this.notifyService.showRroor("Please enter a valid percentage", "Oops!");
        return;
      }
      // if (this.model.percentagevalueflag == true) {
      if (this.model.perTrnValue == null) {
        this.notifyService.showRroor("Please enter trn value for percentage", "Oops!");
        return;
      }
      // }
      if (this.model.percentagevaluediscflag == true) {
        if (this.model.perMaxValue == null) {
          this.notifyService.showRroor("Please enter Max Discount value", "Oops!");
          return;
        }
      }
    } else
      if (this.model.valueflag == true && this.model.customflag == false) {
        if (this.model.value == null) {
          this.notifyService.showRroor("Please enter value", "Oops!");
          return;
        }
        if (this.model.value < 1) {
          this.notifyService.showRroor("Please Enter a valid value", "Oops!");
          return;
        }
        // if (this.model.trnvalueflag == true) {
        if (this.model.trnvalue == null) {
          this.notifyService.showRroor("Please enter a trn value for value", "Oops!");
          return;
        }
        //  }
      }

    if (this.model.single == false && this.model.serial == false) {
      this.notifyService.showRroor("Please select coupon tyoe", "Oops!");
      return;
    }
    if (this.model.quaUnlimited == false && this.model.qualimited == false) {
      this.notifyService.showRroor("Please select quantity", "Oops!");
      return;
    }
    if (this.model.qualimited == true) {
      if (this.model.qualimited == null) {
        this.notifyService.showRroor("Please enter limited quantity value", "Oops!");
        return;
      }
    }

    if (this.model.schemeUnlimited == false && this.model.schemelimited == false) {
      this.notifyService.showRroor("Please enter scheme validity", "Oops!");
      return;
    }

    // if (this.model.schemeUnlimited == true) {
    //   if (this.model.schemedays == null) {
    //     this.notifyService.showRroor("Please enter scheme days", "Oops!");
    //     return;
    //   }
    // }
    if (this.model.schemelimited == true) {
      if (this.model.fromdate == null || this.model.todate == null) {
        this.notifyService.showRroor("Please select From date and To date", "Oops!");
        return;
      }
    }
    if (this.model.trnvalueflag == true) {
      if (this.model.trnvalue < this.model.value) {
        this.notifyService.showRroor("Order value must be greater than discount value", "Oops!");
        return;
      }
    }
    if (this.model.percentageflag != false && this.model.valueflag != false) {
      this.notifyService.showRroor("Please select atleast one scheme type", "Oops!");
      return;
    }
    if (this.model.serial != false && this.model.single != false) {
      this.notifyService.showRroor("Please select atleast one coupon type", "Oops!");
      return;
    }
    if (this.model.single != false) {
      if (this.model.quaUnlimited != false && this.model.qualimited != false) {
        this.notifyService.showRroor("Please select atleast one quantity type", "Oops!");
        return;
      }
      if (this.model.qualimitedvalue != null && this.model.qualimitedvalue == null) {
        this.notifyService.showRroor("Please enter a quantity value", "Oops!");
        return;
      }
    }
    if (this.model.serial != false) {
      if (this.model.qualimitedvalue == null) {
        this.notifyService.showRroor("Please enter a quantity value", "Oops!");
        return;
      }
    }
    if(this.model.prefix == null)
    {
      this.notifyService.showRroor("Please Enter Prefix", "Oops!");
        return;
    }
    if(this.model.prefix != null && this.model.single == true)
    {
      if(this.model.postfix == null)
      {
        this.notifyService.showRroor("Please Enter Postfix", "Oops!");
        return;        
      }
    }


    this.Masterservice.saveNewScheme(this.model)
      .subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess(data.message, "Done!");
          this.router.navigate(['Scheme Configuration']);
        } else if (data.status == "NO") {
          this.notifyService.showRroor(data.message, "Oops!");
        } else {
          this.notifyService.showRroor("Something wrong while creating scheme", "Oops!");
        }
      });

  }

  btnback() {
    this.router.navigate(['Scheme Configuration']);
  }
  btnstopScheme() {
    this.user.trnid = this.id;
    this.model.id = this.id;
    this.Masterservice.getstopSchemeConfigureByid(this.model)
      .subscribe(
        data => {
          this.model = data;
        });
  }
  selectFromDate(From) {
    if (From < new Date()) {
      this.notifyService.showRroor("Previous date cannot be selected", "oops");
      this.model.fromdate = new Date()
      return;
    }
  }
  selectToDate(todate) {
    if (todate < this.model.fromdate) {
      this.notifyService.showRroor("To Date should be grether than From Date", "oops");
      this.model.todate = this.model.fromdate
      return;
    }
  }
  verifyprefix(data) {
    this.model.prefix = data;
    if (this.model.prefix == null || this.model.prefix == "") {
      this.notifyService.showRroor("Please Enter Prefix", "Oops!");
      return;
    }
    this.Masterservice.verifyprefix(this.model)
      .subscribe(
        data => {
          this.model.status = data.status;
          if (data.status == "NO") {
            this.notifyService.showRroor(data.message, "Oops!");
            return;
          }
          else if (data.status == "OK") {
            this.notifyService.showSuccess(data.message, "Done!");
            return;
          }

        })
  }

}


