<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Service Charges Template</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12">
                <div class=row>
                    <div class="input-field col l5 m5 s5" style="padding-left: 5px;">
                        <span>Sales Category</span>
                        <select (change)="onSelectcategory($event.target.value)">
                            <option selected>Select Sales Category</option>
                            <option *ngFor="let type of productcatelst" [value]="type.id"
                                [selected]="type.id == model.productcategoryid">
                                {{type.name}}</option>
                        </select>
                    </div>

                    <div class=" input-field col l6 m6 s6" style="margin-top: 3%">
                        <input type="text" class="autocomplete" [(ngModel)]="model.productName" id="productname1"
                            data-toggle="tooltip" data-placement="top" title="Product Name" (keyup)="getProductLike()"
                            placeholder="Item" />
                        <input type="hidden" id="acc-id" />
                        <label for="item" class="active">Item</label>
                    </div>
                    <!-- <div class="ng-autocomplete pinRowgrp input-field col l6 m6 s6" style="margin-top: 3%" >
                        <ng-autocomplete [data]="userList2" type="text" placeholder="Product Name"  [(ngModel)]="model.productName" id="productname1"
                          [searchKeyword]="keyword1" [minQueryLength]="3"  
                          (inputChanged)='onChangeSearchproduct($event)'  (selected)='selectEvent1($event)'  (inputFocused)='onFocusedforproduct($event)'
                          [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>
      
                        <ng-template #itemTemplate let-item>
                          <a  [innerHTML]="item.productName"></a>
                        </ng-template>
      
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      
                    </div> -->

                </div>
                <div class=row>
                    <!-- <div class="input-field col l1 m1 s1" style="margin-top: 4%;padding-left: 5px;">
                        Spare List
                    </div> -->
                    <!-- <div class="input-field col l4 m4 s4">
                            <span>Spare List Category</span>
                            <select (change)="onSelectitemCat($event.target.value)">
                                <option selected>Select Spare</option>
                                <option *ngFor="let type of itmCatlst" [value]="type.id"
                                    [selected]="type.id == model.itemcategoryid">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="col l1 m1 s1"></div>
                        <div class="input-field col l4 m4 s4">
                            <span>Sub Category</span>
                            <select (change)="onSelectSubCat($event.target.value)">
                                <option selected>Select Sub Category</option>
                                <option *ngFor="let type of subCatlst" [value]="type.id"
                                    [selected]="type.id == model.itemsubcategoryid">
                                    {{type.name}}</option>
                            </select>
                        </div> -->
                    <div class=row>
                        <div class="col l5 m5 s5">
                            <div class="input-field col l8 m8 s8" style="padding-left: 5px;">
                                <span>Spare List Category</span>
                                <select (change)="onSelectSparecategory($event.target.value)">
                                    <option selected>Select Sales Category</option>
                                    <option *ngFor="let type of spareProductcatelst" [value]="type.id"
                                        [selected]="type.id == model.spareCategoryid">
                                        {{type.name}}</option>
                                </select>
                            </div>
                            <!-- <div class="col l4 m4 s4" style="margin-top: 40px;margin-left: 0px;">
                                <input type="checkbox" [(ngModel)]="model.selectall" #selectall="ngModel"
                                    (click)="selectallproduct($event)">&nbsp;&nbsp;Select All
                            </div> -->
                        </div>
                        <div class=" input-field col l6 m6 s6" style="margin-top: 3%">
                            <input type="text" class="autocomplete" [(ngModel)]="model.spareProName" id="productname"
                                data-toggle="tooltip" data-placement="top" title="Product Name"
                                (keyup)="getProductLikeSpareItem()" placeholder="Item" />
                            <input type="hidden" id="acc-id" />
                            <label for="item" class="active">Item</label>
                        </div>
                        <!-- <div class="ng-autocomplete pinRowgrp input-field col l6 m6 s6" style="margin-top: 3%" >
                                <ng-autocomplete [data]="userList3" type="text" placeholder="Item Name"  [(ngModel)]="model.spareProName" id="productname"
                                  [searchKeyword]="keywordproduct" [minQueryLength]="3"  
                                  (inputChanged)='onChangeSearchproduct1($event)' (selected)='selectEvent($event)'   (inputFocused)='onFocusedforproduct1($event)'
                                  [itemTemplate]="itemTemplate1" [notFoundTemplate]="notFoundTemplate1">
                                </ng-autocomplete>
                                
                                <ng-template #itemTemplate1 let-item>
                                  <a  [innerHTML]="item.itemcode"></a>
                                </ng-template>
              
                                <ng-template #notFoundTemplate1 let-notFound>
                                  <div [innerHTML]="notFound"></div>
                                </ng-template>
                              
                            </div> -->

                    </div>
                    <!-- <div class="input-field col l2 m2 s2" style="text-align: right">
                        <button type="button" (click)="btnadd($event)" class="waves-effect waves-light btn"
                            style="margin-left: 20px;" style="margin-top: 21px">Add</button>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col l12 m12 s12" style="padding-left: 0px;padding-right: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="5%" style="text-align: left"><input [(ngModel)]="model.chklist"
                                        #chklist="ngModel" type="checkbox" (click)="checkall($event)"
                                        style="text-align: 5px"></th>
                                <th width="7%" style="text-align: left">Type</th>
                                <th width="25%" style="text-align: left">Item Code</th>
                                <th width="25%" style="text-align: left">Description</th>
                                <th width="15%" style="text-align: left">UOM</th>
                                <th width="11%" style="text-align: center">Quantity</th>
                                <th width="12%" style="text-align: center">Rate</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of itemlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                    <td style="text-align: left"><input [(ngModel)]="lst.chklist" #chklist="ngModel"
                                            type="checkbox" style="text-align: 5px"
                                            (click)="onchkselect(lst.itemmasterid,$event)"></td>
                                    <td style="text-align: left">{{lst.type}}</td>
                                    <td style="text-align: left">{{lst.itemcode}}</td>
                                    <td style="text-align: left">{{lst.description}}</td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <td style="text-align: right"><input [(ngModel)]="lst.quantity" #quantity="ngModel"
                                            type="number" class="" id="quantity" data-toggle="tooltip"
                                            data-placement="top" title="quantity"
                                            style="margin-top:1px;text-align: right;" min="0"></td>
                                    <td style="text-align: right;padding-right: 10px;">{{lst.rate}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 0px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                            style="margin-left: 5px;">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>