<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>

        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="l12 m12 s12">
                    <div class="row" style="margin-left: 0px;margin-right:0px;">
                        <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 9px;padding-left: 14px;">
                            <span>Search Criteria</span>
                            <select (change)=" onSelect($event.target.value)">
                                <option *ngFor="let type of searchtype" [value]="type.name">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="input-field col s3 l3 m3" style="text-align: right;margin-top: 26px;">
                            <input type="text" [(ngModel)]="model.txtserch" #name="ngModel" class="" id="search"
                                data-toggle="tooltip" data-placement="top" title="Search Field">
                            <!-- [(ngModel)]="model.txtserch"txtserch #name="ngModel" -->
                            <label for="search">Search Text</label>
                        </div>

                        <div class=" input-field col s4 l4 m4" style="margin-top: 26px;">
                            <button type="button" (click)="btnsearch($event)"
                                class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                            <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                                (click)="btnnew()">New</button>&nbsp;&nbsp;
                            <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                                (click)="openPrint()">Print</button>
                        </div>
                    </div>



                    <!-- style="height: 500px;overflow-y: scroll;margin-top: 35px;" -->
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <div class="col l12 m12 s12">
                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="40%" style="text-align: left;padding-left: 10px;">Account Name</th>
                                        <th width="30%" style="text-align: left">Account Code</th>
                                        <th width="20%" style="text-align: left">Status</th>
                                        <th width="10%" style="text-align: center">Actions</th>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let lst of accontlst  | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td style="text-align: left;padding-left: 10px;">{{lst.name}}</td>
                                            <td style="text-align: left">{{lst.code}}</td>
                                            <td style="text-align: left">{{lst.isAccount}}</td>
                                            <td style="text-align: center"> <span><img
                                                        src="../../../assets/images/edit.png"
                                                        (click)="btnedit(lst.id,lst.name)" data-toggle="tooltip"
                                                        data-placement="top" title="Edit"
                                                        width="20px" /></span>&nbsp;&nbsp;<span><img
                                                        src="../../../assets/images/delete.png"
                                                        (click)="btndelts(lst.id)" data-toggle="tooltip"
                                                        data-placement="top" title="Delete" width="20px" /></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <span style="text-align:right">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="display: none;" id="LibDocs">
                        <div class="col l12 m12 s12  modal-header">
                            <div class="col l12 m12 s12">

                                <table border="1px solid black;"
                                    style="width: 100%;border-collapse: collapse; padding-top: 10px; " cellpadding="7"
                                    id="excel">
                                    <thead style="background: gainsboro;">
                                        <th width="15%" style="text-align: left">Account Name</th>
                                        <th width="15%" style="text-align: left">Account Code</th>
                                        <th width="15%" style="text-align: left">Status</th>
                                        <th width="15%" style="text-align: left">Locality</th>
                                        <th width="20%" style="text-align: left">Telephone 1</th>
                                        <th width="20%" style="text-align: left">Customer Type</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of accontlst ">
                                            <td style="text-align: left">{{lst.name}}</td>
                                            <td style="text-align: left">{{lst.code}}</td>
                                            <td style="text-align: left">{{lst.isAccount}}</td>
                                            <td style="text-align: left">{{lst.locality}}</td>
                                            <td style="text-align: left">{{lst.telephone1}}</td>
                                            <td style="text-align: left">{{lst.customerType}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>