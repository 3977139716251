import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CompanyService, MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { User } from 'src/app/_models';
import { DefineProductModel } from './DefineProductModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { NullTemplateVisitor } from '@angular/compiler';
import { threadId } from 'worker_threads';

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineProduct.html'
})
export class DefineProduct implements OnInit, OnDestroy {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location) { }

    sub: any;
    id: number;
    user = new User();
    Date = new Date();
    model = new DefineProductModel();
    promodellst: DefineProductModel[] = [];
    proDiscountlst: DefineProductModel[] = [];
    productcatelst: DropDownModel[] = [];

    pagename: string;
    currentdate = new Date();
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }

    ngOnInit(): void {

        this.pagename = localStorage.getItem("pagename");
        this.model.effctiveDate = this.currentdate;
        this.pagename = localStorage.getItem("pagename");

        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getProductcat();
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getProductByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.promodellst = data.promodellst
                        this.model.effctiveDate = new Date(data.effctiveDate);
                        if (data.effctiveDate != null) {
                            this.model.effctiveDate = new Date(data.effctiveDate);
                        } else {
                            this.model.effctiveDate = new Date();
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";

        } else {
            this.getproduct();

        }
    }
    getProductcat() {
        this.Masterservice.getProductcat(this.user)
            .subscribe(value => {
                this.productcatelst = value.procategorylst;
            });

    }
    getproduct() {
        this.Masterservice.getproduct(this.model)
            .subscribe(value => {
                this.promodellst = value;
            });
    }

    onSelectcategory(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Sales Product Category!!", "Oops!");
            return;
        }
        this.model.productcategoryid = value;

        this.Masterservice.getproductDiscount(this.model)
            .subscribe(value => {
                this.proDiscountlst = value;

            });
        if (this.model.productcategoryid = value) {
            for (let i = 0; i < this.proDiscountlst.length; i++) {
                if (this.proDiscountlst[i] == value) {
                    this.model.discount = this.proDiscountlst[i].discount
                  
                }
            }
        }
    }




    onselectdef(index) {
        for (let i = 0; i < this.promodellst.length; i++) {
            if (this.promodellst[i].checkselect) {
                this.promodellst.forEach(element => {
                    element.defcheckselect = false;
                });
                this.promodellst[index].defcheckselect = true;
                this.model.defcheckselect = true;
            }
           
        }
    }
    onselectcheckselect(index) {
        for (let i = 0; i < this.promodellst.length; i++) {
            if (!this.promodellst[i].checkselect) {
                this.promodellst[index].defcheckselect = false;
            }
        }
        if (this.promodellst.length == 1) {
            this.promodellst[index].defcheckselect = true;
        }
    }

    btnsave($event) {

        for (let i = 0; i < this.promodellst.length; i++) {
            if (this.promodellst[i].minimumPrice != null) {
                this.model.minimumPrice = this.promodellst[i].minimumPrice;
            }
        }

        if (this.model.productcategoryid == null || this.model.productcategoryid == 0) {
            this.notifyService.showRroor("Select Sales Product Category !!", "Oops!");
            return;
        }
        if (this.model.productCode == null || this.model.productCode == "") {
            this.notifyService.showRroor("Enter Product Model Name!!", "Oops!");
            return;
        }
        if (this.model.description == null || this.model.description == "") {
            this.notifyService.showRroor("Enter Description !!", "Oops!");
            return;
        }
        // if (this.model.discount == null) {
        //     this.notifyService.showRroor("Enter Valid Discount !!", "Oops!");
        //     return;
        // }

        // if (this.model.warrantyMonths == null) {
        //     this.notifyService.showRroor("Enter Warranty Months   !!", "Oops!");
        //     return;
        // }
        var found = this.promodellst.find(function (x) { return x.checkselect === true; });
        if (!found) {
            this.notifyService.showRroor("Please select atleast one currency", " Oops");
            return;
        }
        for (let i = 0; i < this.promodellst.length; i++) {
            if (this.promodellst[i].checkselect == true) {
                if (this.promodellst[i].minimumPrice == null) {
                    this.notifyService.showRroor("Please enter MinimumPrice of selected columns", " Oops");
                    return;
                }
            }
        }
        this.model.promodellst = this.promodellst
        this.model.userid = Number(localStorage.getItem("userid"));
        this.Masterservice.saveproduct(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['Manage Product']);
                    } else {
                        if (data.message == null) {
                            this.notifyService.showRroor("Something wrong", "Oops !")
                        } else {
                            this.notifyService.showRroor(data.message, "Oops !")
                        }
                    }
                },
                error => {
                    alert("Something Wrong ")
                });


    }

    // onChangedate(date) {
    //     // if (date > new Date()) {
    //     //     this.model.effctiveDate = new Date();
    //     // }
    // }

    // onChangestartdate(date) {
    // }
    // onChangedate(date) {
    // }

    cancle() {
        this.router.navigate(["/Manage Product"]);
    }

}



