import { StringifyOptions } from 'querystring';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { CurrencyModel } from '../Quotation/QuotationModel';
import { productmodel } from '../Sales-Transaction/ProspectModel';

export class DailyCallModel {
    id: number;
    noofdays: number;
    dcrdate: Date = new Date();
    address: string;
    chkbyvisit: boolean;
    chkbycall: boolean;
    connection: string;
    accountname: string;
    quosubflag: boolean;
    accid: number;
    ctypeid: number;
    ctype: string;
    stageid: number;
    accountid: string;
    assignedBy: number;
    userid: number;
    assignedByName: string;
    enqstatus: string;
    enquiryName: string;
    servicecallNo: string
    enquirylst: Enquiries[];
    activitylst: ActivityModel[];
    memberlst: TeamMembers[];
    contactlst: Contacts[];
    productEnquirylst: Products[];
    productlst: Products[];
    procatlst: Products[];
    sendquotation: boolean
    enquiryid: number;
    activity: string;
    activityid: number;
    currentstage: string;
    nextstage: string;
    nextstageid: number;
    actionTaken: string;
    remarks: string;

    chkfollowup: boolean;
    chkclose: boolean;
    followupdate: Date;
    assignTo: number;
    followuptask: number;
    productmodel: string;
    joint1: number;
    joint2: number;
    joint3: number;

    quostatus: string;
    quotationChanged: boolean;
    quoid: number;
    chkdesignation: boolean;
    dcrHistorylst: DailyCallModel[] = []
    contactID: number;
    sourcedetailID: number;
    sourceID: number;
    lstproductintersted: productmodel[] = [];
    localeid: number;
    newassignedtoid: number;
    pincode: string;
    previousremarks: string;
    city: string;
    localeName: String;
    unitno: String;
    buildingName: String;
    locallity: string;
    otpverified: boolean;
    assignto: number[] = [];
    chkwon: boolean;
    chklost: boolean;
    chkaband: boolean;
    decision: string;
    lossreason: number;
    dealerammount: any;
    pid: number;
    pcid: any;
    pcname: any;
    productid: any;

}
export interface Enquiries {
    id: number;
    name: string;
    enqdate: Date;
}
export interface ActivityModel {
    id: number;
    name: string;
    stage: string;
    stageid: number;
    actionflag: string
}

export class TeamMembers {
    name: string;
    id: number;
}

export class Contacts {
    chkcontact: boolean;
    id: number;
    contactname: string;
    designation: string;
    mobileno: string;
    email: string;
}

export class Products {
    id: number;
    chkselectpro: boolean
    pcid: number;
    pcname: string;
    pid: number;
    name: number
    description: string;
    productlst: Products[];
    procatlst: Products[];
    prosprocatid: number;
    uom: string;
    uomid: number;
    gstamount: number;
    gstrate: number;
    discount: number;
    quantity: number;
    price: number;
    productid: number;
    prouctname: string;
    currency: string;
    currencyName: string;
    currencyid: number;
    minimumPrice: number;
    discountAllowed: number;
    taxpercent: number;
    added: boolean
    currencylst: CurrencyModel[] = [];
    categoriesdescription: string;
    type: string;
    spareid: number;
    gstid: number;

    spareProName: string;
    itemcode: string;
    rate: number;
    gstGrouppercentage: number;
    gstheaderID: number;
    discountAllowedNew:number;
}

export class ProductMo {
    pid: number;
    name: number
}

