import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { AlertService, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { MarketBudgetGeoperTemModel } from "../MarketingBudgGeoPercentageallocTem/MarketBudgetGeoperTemModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { User } from "src/app/_models";
import { TemplateRef } from "@angular/core";
import { CostheadModel } from "./DefineMarketingBudgetModel";
import { MarketingServices } from "src/app/_services/MarketingServices";
@Component({
    selector: 'app-order',
    templateUrl: 'DefineMarketingBudget.html'
})
export class DefineMarketingBudget implements OnInit {

    constructor(
        private Marketingservice: MarketingServices,
        private modalService: BsModalService,
        private alertService: AlertService,
        private transactionService: TransactionServices,
        private notifyService: NotificationService,
        private router: Router) {
    }
    model: any = {};
    user = new User();
    modalRef: BsModalRef;
    finanyealst: DropDownModel[] = [];
    regionlst: DropDownModel[] = [];
    modellst: MarketBudgetGeoperTemModel[] = [];
    mregionlst: MarketBudgetGeoperTemModel[] = [];
    costheadlst: CostheadModel[];
    regremainpercent: number;
    remainpercentage: number;
    userList2: any;
    sub: any;
    id: number;
    percentages: number;
    subtotal: number;
    remainvalue: number;
    remainpercent: number;
    remainpercentage1: number;
    remainvalue1: number;
    areavalue: number;
    total1: number;
    total: number;
    length: number;
    totalvalue: number;
    totalvalue1: number;
    subvalueTotal: number;
    costvalueTotal: number;
    areaname: string;
    cityname: string;
    costpercentTotal: number;
    public i: any;
    p: any;
    totalper: number;
    totalperValue: number;
    alert: boolean;
    regionname: string;
    newQuantity: number;
    subvalueRemain: number;
    regionid: number;
    areaid: number;
    cityid: number;

    ngOnInit(): void {
        this.getfinancialyears();
        this.getregionlst();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.alert = false;
    }

    getfinancialyears() {
        let user = new User();
        this.finanyealst = [];
        this.transactionService.getfinancialyears(user)
            .subscribe(value => {
                this.finanyealst = value.finanyealst
            })

    }
    onSelectFinalyear(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Financial Year", "Oops!");
            return;
        }
        this.model.financialyearid = value
    }
    getregionlst() {
        let user = new User();
        this.regionlst = [];
        this.transactionService.getregionlst(user)
            .subscribe((value) => {
                this.regionlst = value.reglst;

            });
    }
    onSelectRegion(regionId) {
        if (regionId == 0) {
            this.notifyService.showRroor("Select Region", "Oops!");
            return;
        }
        this.model.regionId = regionId;
        this.transactionService.getmrketingBudget(this.model)
            .subscribe(value => {
                this.model = value
                // this.modellst = value.modelst
                this.mregionlst = value.mregionlst;

                for (let i = 0; i < this.mregionlst.length; i++) {
                    this.mregionlst[i].regionvalue = this.model.allreginvalue * this.mregionlst[i].percentage / 100;
                    for (let j = 0; j < this.modellst.length; j++) {
                        this.modellst[j].areavalue = this.mregionlst[i].regionvalue * this.modellst[j].percentage1 / 100;
                        for (let k = 0; k < this.modellst[j].citylst.length; k++) {
                            this.modellst[j].citylst[k].cityvalue = this.modellst[j].areavalue * this.modellst[j].citylst[k].percentage2 / 100;
                        }
                    }
                }

            });
        let l1 = (<HTMLInputElement>document.getElementById('lable1'));
        l1.className = "active";
        let l2 = (<HTMLInputElement>document.getElementById('lable2'));
        l2.className = "active";
        let l3 = (<HTMLInputElement>document.getElementById('lable3'));
        l3.className = "active";
        // let l4 = (<HTMLInputElement>document.getElementById('lable4'));
        // l4.className = "active";
        let l8 = (<HTMLInputElement>document.getElementById('lable8'));
        l8.className = "active";
        let l10 = (<HTMLInputElement>document.getElementById('lable10'));
        l10.className = "active";
        let l11 = (<HTMLInputElement>document.getElementById('lable11'));
        l11.className = "active";


        this.modellst = [];
        this.transactionService.getareaByregion(this.model)
            .subscribe(value => {
                this.model = value
                this.modellst = value.modelst

            });
    }
    onChangeOfallregionvalue($event) {
        for (let i = 0; i < this.modellst.length; i++) {
            this.model.regionvalue = this.model.allreginvalue * this.model.percentage / 100
            this.model.percentage = this.model.regionvalue * 100 / this.model.allreginvalue
            this.modellst[i].areavalue = this.model.regionvalue * this.modellst[i].percentage1 / 100
            for (let j = 0; j < this.modellst[i].citylst.length; j++) {
                this.modellst[i].citylst[j].cityvalue = this.modellst[i].areavalue * this.modellst[i].citylst[j].percentage2 / 100
            }
        }
    }
    // onChangeOfregionvalue($event) {
    //     this.model.percentage = this.model.regionvalue * 100 / this.model.allreginvalue
    //     this.model.allreginvalue = this.model.regionvalue / this.model.percentage * 100

    //     for (let i = 0; i < this.modellst.length; i++) {
    //         this.modellst[i].areavalue = this.model.regionvalue * this.modellst[i].percentage1 / 100
    //         for (let j = 0; j < this.modellst[i].citylst.length; j++) {
    //             this.modellst[i].citylst[j].percentage2 = 0;
    //             this.modellst[i].citylst[j].cityvalue = this.modellst[i].areavalue * this.modellst[i].citylst[j].percentage2 / 100
    //             // this.modellst[i].citylst[j].percentage2 = this.modellst[i].citylst[j].cityvalue * 100 / this.modellst[i].areavalue
    //         }
    //     }

    // }

    // onChangeOfregionpercent() {
    //     if (this.model.percentage > 100) {
    //         this.model.percentage = null;
    //         this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
    //         return;
    //     }
    //     for (let i = 0; i < this.modellst.length; i++) {

    //         this.model.regionvalue = this.model.allreginvalue * this.model.percentage / 100
    //         this.modellst[i].areavalue = this.model.regionvalue * this.modellst[i].percentage1 / 100
    //         for (let j = 0; j < this.modellst[i].citylst.length; j++) {
    //             this.modellst[i].citylst[j].cityvalue = this.modellst[i].areavalue * this.modellst[i].citylst[j].percentage2 / 100
    //         }
    //     }
    // }
    onChangeOfareapercentage(index1, index) {
        this.total = 0;
        for (let i = 0; i < this.modellst.length; i++) {
            this.modellst[index].areavalue = this.mregionlst[index1].regionvalue * this.modellst[index].percentage1 / 100
            // if (this.modellst[i].citylst != null) {
            //     for (let j = 0; j < this.modellst[i].citylst.length; j++) {
            //         this.modellst[index].citylst[j].percentage2 = 0;
            //         this.modellst[index].citylst[j].cityvalue = null;
            //     }
            // }
        }
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[index].percentage1 != null) {
                this.modellst[index].arecheselect = true;
                this.total = Number(this.total) + Number(this.modellst[i].percentage1);
                if (this.total > 100) {
                    this.modellst[index].percentage1 = null;
                    this.modellst[index].areavalue = 0;
                    this.modellst[index].percentage1 = 0;

                    for (let j = 0; j < this.modellst[i].citylst.length; j++) {
                        this.modellst[index].citylst[j].percentage2 = 0;
                        this.modellst[index].citylst[j].cityvalue = 0;
                    }
                    this.notifyService.showRroor("Area Percentage is not greater than 100", "Oops!");
                    return;
                }
            }
        }
    }
    onChangeOfareavalue(index1, index) {
        this.totalvalue = 0;
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[index].arecheselect == true) {
                if (this.modellst[index].areavalue != null) {
                    this.modellst[index].arecheselect = true;

                } else {
                    this.modellst[index].arecheselect = false;
                }
                this.modellst[index].percentage1 = this.modellst[index].areavalue * 100 / this.mregionlst[index1].regionvalue
            }
        }
        for (let j = 0; j < this.modellst.length; j++) {
            this.totalvalue = Number(this.totalvalue) + Number(this.modellst[j].areavalue);
            if (this.totalvalue > this.mregionlst[index1].regionvalue) {
                this.modellst[index].areavalue = null;
                this.modellst[index].percentage1 = null;
                this.notifyService.showRroor("Area Value is not greater than Region Value", "Oops!");
                return;
            }

            // for (let k = 0; k < this.modellst[j].citylst.length; k++) {
            //     // this.modellst[i].citylst[j].cityvalue = this.modellst[i].areavalue * this.modellst[i].citylst[j].percentage2 / 100;
            //     this.modellst[index].citylst[k].cityvalue = 0;
            //     this.modellst[index].citylst[k].percentage2 = 0;
            // }
        }
    }
    onChangeOfcitypercentage(index1, index, ind) {
        this.total1 = 0;
        if (this.modellst[index].citylst[ind].percentage2 > 100) {
            this.modellst[index].citylst[ind].percentage2 = null;
            this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
            return;
        }

        for (let i = 0; i < this.modellst[index].citylst.length; i++) {

            if (this.modellst[index].citylst[i].percentage2 != null) {
                this.modellst[index].citylst[ind].checkselect = true
                this.modellst[index].citylst[ind].cityvalue = this.modellst[index].areavalue * this.modellst[index].citylst[ind].percentage2 / 100;

                this.total1 = +this.total1 + +this.modellst[index].citylst[i].percentage2
                if (this.total1 > 100) {
                    this.modellst[index].citylst[ind].percentage2 = null;
                    this.modellst[index].citylst[ind].cityvalue = 0;
                    this.notifyService.showRroor("Percentage is not greater than 100% ", "Oops!");
                    return;
                }

            }
        }
    }
    onChangeOfcityvalue(index1, index, ind) {
        this.totalvalue1 = 0;
        this.totalvalue = 0;

        for (let i = 0; i < this.modellst.length; i++) {
            for (let j = 0; j < this.modellst[index].citylst.length; j++) {
                if (this.modellst[index].citylst[ind].cityvalue != null) {
                    this.modellst[index].citylst[ind].checkselect = true
                    this.modellst[index].citylst[ind].percentage2 = Number(this.modellst[index].citylst[ind].cityvalue * 100 / this.modellst[index].areavalue)
                }
            }
        }

        for (let j = 0; j < this.modellst[index].citylst.length; j++) {
            this.totalvalue1 = +this.totalvalue1 + +this.modellst[index].citylst[j].cityvalue
            // alert(this.totalvalue1)
        }
        if (this.totalvalue1 > this.modellst[index].areavalue) {
            this.modellst[index].areavalue = this.totalvalue1
        }
        for (let j = 0; j < this.modellst[index].citylst.length; j++) {
            this.modellst[index].citylst[j].percentage2 = this.modellst[index].citylst[j].cityvalue * 100 / this.modellst[index].areavalue
        }
        for (let i = 0; i < this.modellst.length; i++) {
            this.totalvalue = Number(this.totalvalue) + Number(this.modellst[i].areavalue);
            this.model.regionvalue = this.totalvalue
            this.model.percentage = this.model.regionvalue * 100 / this.model.allreginvalue
            this.model.allreginvalue = this.model.regionvalue / this.model.percentage * 100
        }
        for (let i = 0; i < this.modellst[index].citylst.length; i++) {
            this.modellst[index].citylst[i].cityvalue = this.modellst[index].areavalue * this.modellst[index].citylst[i].percentage2 / 100
            // alert(this.modellst[index].citylst[i].cityvalue)
        }
    }
    onSelectArecheselect(index1, index, $event) {
        this.totalvalue = 0;
        if (!$event.target.arecheselect) {
            this.modellst[index].areavalue = null;
            this.modellst[index].percentage1 = null;
            this.modellst[index].arecheselect = false;

            for (let i = 0; i < this.modellst[index].citylst.length; i++) {
                this.modellst[index].citylst[i].cityvalue = null;
                this.modellst[index].citylst[i].percentage2 = null;
                this.modellst[index].citylst[i].checkselect = false;
            }
            if (this.costheadlst != null) {
                for (let i = 0; i < this.costheadlst.length; i++) {
                    this.costheadlst[i].costpercentage = null;
                    this.costheadlst[i].costvalue = null;

                    for (let j = 0; j < this.costheadlst[i].costSubheadlst.length; j++) {
                        this.costheadlst[i].costSubheadlst[j].subheadvalue = null;
                        this.costheadlst[i].costSubheadlst[j].subpercentage = null;
                        this.costheadlst[i].costSubheadlst[j].subcheckselect = false;
                    }
                }
            }

        }
    }
    onSelectCheckselect(index1, index, ind, $event) {
        this.totalvalue = 0;
        this.totalvalue1 = 0;
        if (!$event.target.checkselect) {
            this.modellst[index].citylst[ind].percentage2 = null;
            this.modellst[index].citylst[ind].cityvalue = null;
            this.modellst[index].citylst[ind].checkselect = false;

            if (this.costheadlst != null) {
                for (let i = 0; i < this.costheadlst.length; i++) {
                    this.costheadlst[i].costpercentage = null;
                    this.costheadlst[i].costvalue = null;

                    for (let j = 0; j < this.costheadlst[i].costSubheadlst.length; j++) {
                        this.costheadlst[i].costSubheadlst[j].subheadvalue = null;
                        this.costheadlst[i].costSubheadlst[j].subpercentage = null;
                        this.costheadlst[i].costSubheadlst[j].subcheckselect = false;
                    }
                }
            }
        }
    }

    btnopenmodel(index1, index, ind, templateModel: TemplateRef<any>) {
        this.model.marketingBudgethdr = this.modellst[index].citylst[ind].marketingBudgethdr
        this.model.marketingBudgetdtlid = this.modellst[index].citylst[ind].marketingBudgetdtlid
        this.model.city = this.modellst[index].citylst[ind].city
        this.Marketingservice.getallcostheads(this.model)
            .subscribe(
                value => {
                    // this.model = value
                    this.costheadlst = value.costheadlst
                    this.regionname = this.mregionlst[index1].regionName;
                    this.areaname = this.modellst[index].areaName
                    this.cityname = this.modellst[index].citylst[ind].cityname

                    this.regionid = index1;
                    this.areaid = index
                    this.cityid = ind

                    for (let i = 0; i < this.costheadlst.length; i++) {
                        this.costheadlst[i].costvalue = this.modellst[index].citylst[ind].cityvalue * this.costheadlst[i].costpercentage / 100;
                        // alert(this.costheadlst[i].costpercentage)
                    }

                    for (let i = 0; i < this.costheadlst.length; i++) {
                        for (let j = 0; j < this.costheadlst[i].costSubheadlst.length; j++) {
                            if (this.costheadlst[i].costSubheadlst[j].subpercentage != null) {
                                this.costheadlst[i].costSubheadlst[j].subcheckselect = true
                            }
                        }
                    }
                    for (let i = 0; i < this.costheadlst.length; i++) {
                        for (let j = 0; j < this.costheadlst[i].costSubheadlst.length; j++) {
                            this.costheadlst[i].costSubheadlst[j].subheadvalue = this.costheadlst[i].costvalue * this.costheadlst[i].costSubheadlst[j].subpercentage / 100
                        }

                    }

                },

                error => {
                    this.alertService.error(error);
                });
        let l1 = (<HTMLInputElement>document.getElementById('lable1'));
        l1.className = "active";
        // let l2 = (<HTMLInputElement>document.getElementById('lable2'));
        // l2.className = "active";
        // let l3 = (<HTMLInputElement>document.getElementById('lable3'));
        // l3.className = "active";
        this.modalRef = this.modalService.show(templateModel, {
            class: 'modal-lg'
        });
    }

    onChangeOfcostpercentage(index) {
        this.costpercentTotal = 0;
        if (this.model.costpercentage > 100) {
            this.model.percentage = null;
            this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
            return;
        }

        this.costheadlst[index].costvalue = this.modellst[this.areaid].citylst[this.cityid].cityvalue * this.costheadlst[index].costpercentage / 100

        for (let i = 0; i < this.costheadlst.length; i++) {
            this.costpercentTotal = Number(this.costpercentTotal) + Number(this.costheadlst[i].costpercentage);
            if (this.costpercentTotal > 100) {
                this.costheadlst[index].costpercentage = null;
                this.costheadlst[index].costvalue = null;
                this.notifyService.showRroor("Percentage Allocation is not greater than 100%", "Oops!");
                return;
            }
        }


    }
    onChangeOfcostvalue(index) {
        this.costvalueTotal = 0
        this.costheadlst[index].costpercentage = this.costheadlst[index].costvalue * 100 / this.modellst[this.areaid].citylst[this.cityid].cityvalue
        for (let i = 0; i < this.costheadlst.length; i++) {
            this.costvalueTotal = Number(this.costvalueTotal) + Number(this.costheadlst[i].costvalue)
            if (this.costvalueTotal > this.modellst[this.areaid].citylst[this.cityid].cityvalue) {
                this.costheadlst[index].costpercentage = null;
                this.costheadlst[index].costvalue = null;
                this.notifyService.showRroor("Cost-Head Value should not be greater than City Value", "Oops!");
                return;
            }
        }
    }
    onChangeOfsubpercentage(index, ind) {
        this.subtotal = 0;
        this.totalvalue1 = 0;
        if (this.model.subpercentage > 100) {
            this.model.percentage = null;
            this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
            return;
        }

        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            if (this.costheadlst[index].costSubheadlst[ind].subpercentage != null) {
                this.costheadlst[index].costSubheadlst[ind].subcheckselect = true
            }
            this.subtotal = +this.subtotal + +this.costheadlst[index].costSubheadlst[j].subpercentage;
            // alert(this.subtotal)
            if (this.subtotal > 100) {
                this.costheadlst[index].costSubheadlst[j].subpercentage = 0;
                this.costheadlst[index].costSubheadlst[j].subheadvalue = null;
                this.notifyService.showRroor("Percentage Allocation is not greater than 100%", "Oops!");
                return;
            }
            this.costheadlst[index].costSubheadlst[ind].subheadvalue = this.costheadlst[index].costvalue * this.costheadlst[index].costSubheadlst[ind].subpercentage / 100
        }
    }
    onChangeOfsubheadvalue(index, ind) {
        this.subvalueTotal = 0;
        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            if (this.costheadlst[index].costSubheadlst[ind].subheadvalue != null) {
                this.costheadlst[index].costSubheadlst[ind].subpercentage = null;
                this.costheadlst[index].costSubheadlst[ind].subcheckselect = true
            }
            this.costheadlst[index].costSubheadlst[ind].subpercentage = this.costheadlst[index].costSubheadlst[ind].subheadvalue * 100 / this.costheadlst[index].costvalue
        }
        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[j].subheadvalue
        }
        if (this.subvalueTotal > this.costheadlst[index].costvalue) {
            this.costheadlst[index].costSubheadlst[ind].subpercentage = 0;
            this.costheadlst[index].costSubheadlst[ind].subheadvalue = null;
            this.notifyService.showRroor("Value is not greater than Cost Value", "Oops!");
            return;
        }
    }

    onSelectsubcheckselect(index, $event, ind) {
        if (!$event.target.checkselect) {
            this.costheadlst[index].costSubheadlst[ind].subcheckselect = false;
            this.costheadlst[index].costSubheadlst[ind].subpercentage = null;
            this.costheadlst[index].costSubheadlst[ind].subheadvalue = null;
        }
    }
    closemodel() {
        this.modalRef.hide();
    }
    cancle() {
        this.router.navigate(["/CRMHome"])
    }
    btnsave() {
        // for (let i=0;i<this.mregionlst[i].length;i++){
        //     let percentageSum = 0;
        //     percentageSum = percentageSum + this.mregionlst[i].percentage;
        //     if(percentageSum != 100){
        //         this.notifyService.showRroor("Sum of region percentage is Not greater than 100 % ", "Oops!");
        //         return;
        //     }
        // }


        // if (this.modellst == null || this.modellst.length <= 0) {
        //     this.notifyService.showRroor("Please Add List ", "Oops!");
        //     return;
        // }
        if (this.alert == true) {
            this.notifyService.showRroor("Sum of region percentage is Not greater than 100 % ", "Oops!");
            return;
        }
        if (this.mregionlst == null || this.mregionlst.length <= 0) {
            this.notifyService.showRroor("Please Add List ", "Oops!");
            return;
        }
        // this.model.modellst = this.modellst
        this.model.mregionlst = this.mregionlst
        this.model.costheadlst = this.costheadlst
        this.Marketingservice.savemrkbudget(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit()
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    onChangeOfRegionpercentage(index1) {
        this.totalper = 0;
        for (let i = 0; i < this.mregionlst.length; i++) {
            if (this.mregionlst[i].percentage > 100) {
                this.mregionlst[i].percentage = null;
                this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
                return;
            }
            this.alert = false;
            this.totalper = this.totalper + +this.mregionlst[i].percentage;
            if (this.totalper > 100) {
                this.alert = true;
                this.notifyService.showRroor("Region percentage is Not greater than 100%", "Oops!");
                return;
            }

            this.mregionlst[i].regionvalue = this.model.allreginvalue * this.mregionlst[i].percentage / 100
            for (let j = 0; j < this.modellst.length; j++) {
                this.modellst[j].areavalue = this.mregionlst[i].regionvalue * this.modellst[j].percentage1 / 100
                // for (let k = 0; k < this.modellst[i].citylst.length; k++) {
                //     this.modellst[i].citylst[j].cityvalue = this.modellst[i].areavalue * this.modellst[i].citylst[j].percentage2 / 100
                // }
            }
        }

    }
    onChangeOfRegionvalue(index1, $event) {
        this.totalperValue = 0;
        for (let i = 0; i < this.mregionlst.length; i++) {

            this.totalperValue = this.totalperValue + +this.mregionlst[i].regionvalue;
            if (this.totalperValue > this.model.allreginvalue) {
                this.mregionlst[index1].regionvalue = 0;
                this.mregionlst[index1].percentage = 0;
                this.notifyService.showRroor("Region Value is Not greater than Total Region Value", "Oops!");
                return;
            }
            this.mregionlst[i].percentage = this.mregionlst[i].regionvalue * 100 / this.model.allreginvalue

            // for (let j = 0; j < this.modellst.length; j++) {
            //     this.mregionlst[i].modelst[j].areavalue = this.mregionlst[i].regionvalue * this.mregionlst[i].modelst[j].percentage1 / 100
            //     for (let j = 0; j < this.modellst[i].citylst.length; j++) {
            //         this.modellst[i].citylst[j].percentage2 = 0;
            //         this.modellst[i].citylst[j].cityvalue = this.modellst[i].areavalue * this.modellst[i].citylst[j].percentage2 / 100
            //         // this.modellst[i].citylst[j].percentage2 = this.modellst[i].citylst[j].cityvalue * 100 / this.modellst[i].areavalue
            //     }
            // }
        }
    }
    getareabyregion(index) {
        this.modellst = [];
        this.model.regionId = this.mregionlst[index].regionId;
        this.transactionService.getareaByregionForBudget(this.model)
            .subscribe(value => {
                this.model = value
                this.modellst = value.modellst

                for (let i = 0; i < this.mregionlst.length; i++) {
                    this.mregionlst[i].regionvalue = this.model.allreginvalue * this.mregionlst[i].percentage / 100;
                    for (let j = 0; j < this.modellst.length; j++) {
                        this.modellst[j].regionvalue = this.model.allreginvalue * this.mregionlst[index].percentage / 100;
                        this.modellst[j].percentage = this.mregionlst[index].percentage;
                        this.modellst[j].areavalue = this.mregionlst[index].regionvalue * this.modellst[j].percentage1 / 100;
                        for (let k = 0; k < this.modellst[j].citylst.length; k++) {
                            this.modellst[j].citylst[k].cityvalue = this.modellst[j].areavalue * this.modellst[j].citylst[k].percentage2 / 100;
                        }
                    }
                }

            });
    }
    onChangeOfQuantity(index, ind) {

        this.subvalueTotal = 0;
        this.newQuantity = 0;
        this.subvalueRemain = 0;
        for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
            if (this.costheadlst[index].costSubheadlst[ind].subheadvalue != null) {
                this.newQuantity = this.costheadlst[index].costSubheadlst[ind].quantity * this.costheadlst[index].costSubheadlst[ind].rate;
                this.costheadlst[index].costSubheadlst[ind].subheadvalue = this.newQuantity;
            }
            this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[j].subheadvalue
            if (this.subvalueTotal > this.costheadlst[index].costvalue) {
                this.costheadlst[index].costSubheadlst[ind].subpercentage = 0;
                this.costheadlst[index].costSubheadlst[ind].subheadvalue = 0;
                this.costheadlst[index].costSubheadlst[ind].quantity = 0;
                this.notifyService.showRroor("Sub-Head Value is not greater than Cost Value", "Oops!");

                this.subvalueTotal = 0;
                for (let a = 0; a < this.costheadlst[index].costSubheadlst.length; a++) {

                    this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[a].subheadvalue
                }
                this.subvalueRemain = Number(this.costheadlst[index].costvalue) - Number(this.subvalueTotal);
                this.costheadlst[index].costSubheadlst[ind].subheadvalue = this.subvalueRemain;
                this.costheadlst[index].costSubheadlst[ind].subpercentage = this.costheadlst[index].costSubheadlst[ind].subheadvalue * 100 / this.costheadlst[index].costvalue
                this.costheadlst[index].costSubheadlst[ind].quantity = Math.floor(this.costheadlst[index].costSubheadlst[ind].subheadvalue / this.costheadlst[index].costSubheadlst[ind].rate);
                return;
            }
        }
        this.onChangeOfsubheadvalue(index, ind);


        // this.subvalueTotal = 0;
        // for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
        //     if (this.costheadlst[index].costSubheadlst[ind].subheadvalue != null) {
        //         this.costheadlst[index].costSubheadlst[ind].subpercentage = null;
        //         this.costheadlst[index].costSubheadlst[ind].subcheckselect = true
        //     }
        //     this.costheadlst[index].costSubheadlst[ind].subpercentage = this.costheadlst[index].costSubheadlst[ind].subheadvalue * 100 / this.costheadlst[index].costvalue
        // }
        // for (let j = 0; j < this.costheadlst[index].costSubheadlst.length; j++) {
        //     this.subvalueTotal = + this.subvalueTotal + + this.costheadlst[index].costSubheadlst[j].subheadvalue
        // }
        // if (this.subvalueTotal > this.costheadlst[index].costvalue) {
        //     this.costheadlst[index].costSubheadlst[ind].subpercentage = 0;
        //     this.costheadlst[index].costSubheadlst[ind].subheadvalue = null;
        //     this.notifyService.showRroor("Value is not greater than Cost Value", "Oops!");
        //     return;
        // }
    }
}