<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">

                <div class="input-field col s3 l3 m3" style="text-align: left;margin-top: 9px;padding-left: 14px;">
                    <span>Search Criteria</span>
                    <select (change)=" onSelectuser($event.target.value)">
                        <option *ngFor="let type of searchtype" [value]="type.name">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col s3 l3 m3" style=" margin-top: 26px; text-align: right ">
                    <!-- <span>Search Text</span> -->
                    <input [(ngModel)]="model.txtserch" #name="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Search Field">
                    <label for="search">Search Text</label>
                </div>
                <div class=" input-field col s5 l5 m5" style="margin-top: 26px">
                    <button type="button" (click)="btnsearch($event)"
                        class="waves-effect waves-light btn">Go</button>&nbsp;&nbsp;
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="btnnew()">New</button>&nbsp;&nbsp;
                    <button type="button" class="waves-effect waves-light btn" style="margin-left: 5px;"
                        (click)="openPrint()">Print</button>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="25%" style="text-align: left">Name</th>
                            <th width="23%" style="text-align: left">Designation</th>
                            <th width="19%" style="text-align: left">Department</th>
                            <th width="8%" style="text-align: left">Status</th>
                            <th width="13%" style="text-align: left">Branch</th>
                            <th width="12%" style="text-align: left">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reslst | paginate: { itemsPerPage: 10, currentPage: p }">
                                <td style="text-align: left">{{lst.name}}</td>
                                <td style="text-align: left">{{lst.designationName}}</td>
                                <td style="text-align: left">{{lst.deptName}}</td>
                                <td style="text-align: left">{{lst.activeInactive}}</td>
                                <td style="text-align: left">{{lst.branchname}}</td>
                                <td style="text-align: left">
                                    <span><img src="../../../assets/images/edit.png" (click)="btnedit(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Edit"
                                            width="20px" /></span>&nbsp;&nbsp;
                                    <span><img src="../../../assets/images/earth.png" width="20px"
                                            (click)="btnAddGeography(lst.id, lst.name)" data-toggle="tooltip"
                                            data-placement="top" title="Resource Rights" /></span>&nbsp;&nbsp;
                                    <!-- <span><img src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                            data-toggle="tooltip" data-placement="top" title="Delete"
                                            width="20px" /></span> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>


            <div class="row" style="display: none" id="LibDocs">
                <div class="col l12 m12 s12">
                    <table border="1px solid black;" style="width: 100%;border-collapse: collapse; padding-top: 10px; "
                        cellpadding="7" id="excel">
                        <thead style="background: gainsboro;">
                            <th width="25%" style="text-align: left">Name</th>
                            <th width="25%" style="text-align: left">Designation</th>
                            <th width="25%" style="text-align: left">Department</th>
                            <th width="10%" style="text-align: left">Status</th>
                            <th width="15%" style="text-align: left">Branch</th>

                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of reslst">
                                <td style="text-align: left">{{lst.name}}</td>
                                <td style="text-align: left">{{lst.designationName}}</td>
                                <td style="text-align: left">{{lst.deptName}}</td>
                                <td style="text-align: left">{{lst.activeInactive}}</td>
                                <td style="text-align: left">{{lst.branchname}}</td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>