import { DropDownModel } from "src/app/_models/DropDownModel";


export class DefineitemSubCategoryModel{
    itemcategoryname: string;
    subcategorycode:string;
    subcategoryname:string;
    chkSaleproduct:boolean;
    chkProductspare:boolean;
    itemcategoryid: number;
    id: number;
    userid: number;
}