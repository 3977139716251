import { TemplateRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { SearchSourceModel } from "src/app/Master/SourceMaster/SearchSourceModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { User } from "src/app/_models/user";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel";
import { ReferencersReportModel } from "./ReferencersReportModel";

@Component({
    selector: 'app-login',
    templateUrl: 'ReferencersReport.html'
})
export class ReferencersReport implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices,
        private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService,
        private userService: MasterServices, private route: ActivatedRoute, private router: Router) {
    }

    model = new ReferencersReportModel();
    dmodel = new DCRreportModel();
    user = new User();
    referencerslst: DropDownModel[] = [];
    companyname: string;
    companyaddress: String;
    resourceName: string;
    statusflag: string;
    timing = new Date();
    username: string;
    reflst: ReferencersReportModel[] = [];
    lstsourcedetails: DropDownModel[] = [];
    lstsource: DropDownModel[] = [];
    lstsource1: SearchSourceModel[] = [];

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Open" },
        { id: 3, "name": "Undecided" },
        { id: 4, "name": "Accepted" },
        { id: 5, "name": "Rejected" },
        { id: 6, "name": "Postponed" },
        { id: 7, "name": "Under Installation" },
        { id: 8, "name": "Installed" }

    ];
    ngOnInit(): void {
        this.getsource();
        this.lstsourcedetails.push({ id: 0, name: "Select Source Name" });

    }
    selectFromDate(From) {
        if (From > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.frmdate = new Date()
            return;
        }
    }
    selectToDate(date) {
        if (date < this.model.frmdate) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.todate = new Date()
            return;
        }
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.todate = new Date()
            return;
        }
    }
    onSelectReference(val) {
        this.model.referenceintlst = []
        if (val == "All") {
            this.user.message = val;
            if (this.user.message == val) {
                this.resourceName = this.user.message
            }
            for (let i = 0; i < this.referencerslst.length; i++) {
                this.model.referenceintlst.push(this.referencerslst[i].id)
            }
        } else {
            this.model.resourceID = val;
            this.model.referenceintlst.push(this.model.resourceID)
        }
    }
    onSelectStatus(value) {
        this.model.status = value;
    }
    btnshow(ReferenceReportModel: TemplateRef<any>) {

        if (this.model.frmdate == null) {
            this.notifyService.showRroor(" Please select From Date !!", "Oops!");
            return;
        }
        if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
            return;
        }
        if (this.model.sourcedetailID == null || this.model.sourcedetailID == 0) {
            this.notifyService.showRroor("Please select Source Details !!", "Oops!");
            return;
        }
        if (this.model.status == null) {
            this.notifyService.showRroor("Please select Status !!", "Oops!");
            return;
        }

        this.reportServices.showRefrencersReport(this.model)
            .subscribe(
                data => {
                    this.reflst = data;
                    for (let i = 0; i < this.reflst.length; i++) {
                        this.companyname = this.reflst[0].companyname;
                        this.companyaddress = this.reflst[0].companyaddress;
                        if (this.reflst[i].status == 'Y' && this.reflst[i].currentStage == "LEAD") {
                            this.reflst[i].status = "Open"
                        } 
                        else if (this.reflst[i].status == 'Y' && this.reflst[i].currentStage == "OPPTY") {
                            this.reflst[i].status = "Undecided"
                        }
                        else if (this.reflst[i].status == 'N') {
                            this.reflst[i].status = "Close"
                        }
                        else if (this.reflst[i].status == 'L') {
                            this.reflst[i].status = "Lost"
                        }
                        else if (this.reflst[i].status == 'A') {
                            this.reflst[i].status = "Postponed"
                        }
                        else if (this.reflst[i].instStatus == 'Y') {
                            this.reflst[i].status = "Completed"
                        } 
                        else if (this.reflst[i].instStatus == 'N') {
                            this.reflst[i].status = "Under Installation"
                        }
                        else if (this.reflst[i].status == 'W') {
                            this.reflst[i].status = "Won"
                        }

                    }
                    this.modalRef = this.modalService.show(ReferenceReportModel, {
                        class: 'modal-lg'
                    });
                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        // WindowObject.close();
    }
    getsource() {
        this.lstsource = [];
        this.lstsource1 = [];
        let user = new User();
        this.lstsource.push({ id: 0, name: "Select Source" });
        this.Masterservice.getAllSource(user)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstsource.push({ id: item.id, name: item.name });
                    this.lstsource1 = data;
                });
            });

    }
    onSelectsource(id) {
        this.lstsourcedetails = [];
        let user = new User();
        user.trnid = id;
        this.model.sourceID = id;
        this.lstsourcedetails.push({ id: 0, name: "Select Source Name" });
        this.Masterservice.getsourceDetailBysourceid(user)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstsourcedetails.push({ id: item.id, name: item.name });
                });
            });
    }
    onSelectsourcedetails(id) {
        this.model.sourcedetailID = id;
    }

}