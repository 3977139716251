import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { ITreeOptions, TreeModel, TreeNode, TREE_ACTIONS } from "angular-tree-component";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, MenuServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { MaterialInventoryHomeModel } from "./MaterialInventoryHomeModel";

declare var $: any;

@Component({
    selector: 'app-MaterialInventoryHome',
    templateUrl: 'MaterialInventoryHome.html',
    styles: [
        `.menu {
          position: absolute;
          background: rgba(255, 255, 255, 0.9);
          padding: 7px;
          border-radius: 5px;
          box-shadow: 0 0 2px 2px rgba(0,0,0,0.2);
        }`,
        `ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }`,
        `li {
          padding: 7px;
          border-radius: 3px;
          cursor: pointer;
        }`,
        `li:hover {
          background-color: aliceblue;
        }`,
    ]
})
export class MaterialInventoryHome implements OnInit {
    user = new User();
    model: any = {};
    model1 = new MaterialInventoryHomeModel();
    p: any;
    public i;
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    itemnamelst: MaterialInventoryHomeModel[] = [];
    itemnamelst1: MaterialInventoryHomeModel[] = [];
    locationlst: MaterialInventoryHomeModel[] = [];
    mrpmap = new Map();
    userList2: any;
    id: number;
    itemname: string;
    itemcodemap = new Map();
    hsnCodemap = new Map();
    uommap = new Map();
    gstmap = new Map();
    contextMenu: { node: TreeNode, x: number, y: number } = null;
    sourceNode: TreeNode = null;

    // treeControl: TreeNode = null;
    editNode: TreeNode = null;
    doCut = false;
    nodes = [];
    children = [];
    private sub: any;
    str: string;
    keyword1 = 'itemname';

    
    constructor(
        private router: Router, private notifyService: NotificationService, private transactionService: TransactionServices, private Masterservice: MasterServices, private menuServices: MenuServices, private route: ActivatedRoute,) {
    }
    ngOnInit(): void {

        this.getitemCategory();

        this.transactionService.getitemcategoryandSub(this.model)
            .subscribe(value => {
                this.nodes = value;
            });
            



    }
    options: ITreeOptions = {
        actionMapping: {
            mouse: {
                contextMenu: (treeModel: TreeModel, treeNode: TreeNode, e: MouseEvent) => {
                    e.preventDefault();

                    if (this.contextMenu && treeNode === this.contextMenu.node) {
                        return this.closeMenu();
                    }
                    this.contextMenu = {
                        node: treeNode,
                        x: e.pageX,
                        y: e.pageY
                    };
                },
                click: (treeModel: TreeModel, treeNode: TreeNode, e: MouseEvent) => {
                    this.closeMenu();
                    TREE_ACTIONS.TOGGLE_ACTIVE(treeModel, treeNode, e);
                }
            }
        }
    };
    onClick = (id) => {
        this.itemnamelst = [];
        this.model.itemsubcategoryid = id;
        this.model.itemname = this.model1.itemname
        this.transactionService.getitemnames(this.model)
            .subscribe((value) => {
                this.itemnamelst = value
                this.itemnamelst1 = [];
                value.forEach((item) => this.itemnamelst1.push({
                    "id": item.id,
                    "itemcategoryid": item.itemcategoryid,
                    "itemsubcategoryid": item.itemsubcategoryid,
                    "itemname": item.itemname,
                    "txtserch": item.txtserch,
                    "txtselect": item.txtselect,
                    "itemid": item.itemid,
                    "stockforsale": item.stockforsale,
                    itemnamelst: null,
                    "locationname": item.locationname,
                    "locationlst": item.locationlst,
                    "uom": item.uom
                }));
                this.itemnamelst = this.itemnamelst1;

            });

    }

    closeMenu = () => {
        this.contextMenu = null;
    }
    stopEdit = () => {
        this.editNode = null;
    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
         });
    }

    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.itemsubcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.itemsubcategory = this.subCatlst[i].name
            }
        }
    }
    getItemLike($event) {

        let userId = (<HTMLInputElement>document.getElementById('itmname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLikePO(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "hsnCode": item.hsnCode, "uom": item.uom, "gstGrouppercentage": item.gstGrouppercentage }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].itemname, this.userList2[i].itemid)
                        this.itemcodemap.set(this.userList2[i].itemname, this.userList2[i].itemcode)
                        this.hsnCodemap.set(this.userList2[i].itemname, this.userList2[i].hsnCode)
                        this.uommap.set(this.userList2[i].itemname, this.userList2[i].uom)
                        this.gstmap.set(this.userList2[i].itemname, this.userList2[i].gstGrouppercentage)

                        searchDataObj[this.userList2[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList2[i].itemname;
                        } else {
                            names = names + "," + this.userList2[i].itemname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect1(itemid, itemname) {
        this.model.itemid = itemid;
        this.model.itemname = itemname;
        this.model.itemcode = this.itemcodemap.get(itemname);
        this.model.hsnCode = this.hsnCodemap.get(itemname);
        this.model.uom = this.uommap.get(itemname);
        this.model.gstGrouppercentage = this.gstmap.get(itemname);
    }
    onSelectSrch(val) {
        this.model.txtselect = val
    }
    btnSearch(lst) {      
        if (this.model.itemname == null || this.model.itemname == "") {
            alert("Enter Criteria To Search")
            return;
        }
            // this.model.itemsubcategoryid = this.model.itemsubcategoryid;
            // this.transactionService.getitemnames(this.model)
            // .subscribe((value) => {
            //     this.itemnamelst =value
            //     this.itemnamelst1 = [];
            //     value.forEach((item) => this.itemnamelst1.push({ "id": item.id, "itemcategoryid": item.itemcategoryid, "itemsubcategoryid": item.itemsubcategoryid, "itemname": item.itemname, "txtserch": item.txtserch, "txtselect": item.txtselect, "itemid": item.itemid, "stockforsale": item.stockforsale, itemnamelst: null }));
            //     this.itemnamelst = this.itemnamelst1;

            // });
            if (this.model.itemname) {
            this.itemnamelst1 = [];
                lst.forEach((item) => {
                    if ((item.itemname.toString() == this.model.itemname.toString())) {
                        this.itemnamelst1.push({
                            "id": item.id,
                            "itemcategoryid": item.itemcategoryid,
                            "itemsubcategoryid": item.itemsubcategoryid,
                            "itemname": item.itemname,
                            "txtserch": item.txtserch,
                            "txtselect": item.txtselect,
                            "itemid": item.itemid,
                            "stockforsale": item.stockforsale,
                            itemnamelst: null,
                            "locationname": item.locationname,
                            "locationlst": item.locationlst,
                            "uom": item.uom
                        });
                    }
                });

                this.itemnamelst = this.itemnamelst1;
            // if (this.model.itemname == null || this.model.itemname == "") {
            //     alert("Enter Criteria To Search")
            //     return;
            // } else {
            //     this.itemnamelst1 = [];
            //     this.itemnamelst.forEach((item) => {
            //         if ((item.itemname.toLowerCase()).match(this.model.itemname.toLowerCase().trim())) {
            //             this.itemnamelst1.push({ "id": item.id, "itemcategoryid": item.itemcategoryid, "itemsubcategoryid": item.itemsubcategoryid, "itemname": item.itemname, "txtserch": item.txtserch, "txtselect": item.txtselect, "itemid": item.itemid, "stockforsale": item.stockforsale, itemnamelst: null });
            //         }
            //     });
        
            //     this.itemnamelst = this.itemnamelst1;
            }
        // }
    }

    //For autocomplete
    onChangeSearchmaterialinventory(search: string) {
        this.user.message = search;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        // this.promodel.productid = this.productid;
        this.transactionService.getItemLike(this.user)
            .subscribe(data => {
                this.userList2 = [];
                data.forEach((item) => this.userList2.push({ "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "hsnCode": item.hsnCode, "uom": item.uom, "gstGrouppercentage": item.gstGrouppercentage }));

            });

    }
    selectEvent(item) {
        this.model.itemid = item.id;
        this.model1.itemname = item.itemname;
        this.model.itemcode = this.itemcodemap.get(item.itemname);
        this.model.hsnCode = this.hsnCodemap.get(item.itemname);
        this.model.uom = this.uommap.get(item.itemname);
        this.model.gstGrouppercentage = this.gstmap.get(item.itemname);
    }

    onFocusedforSearchmaterialinventory(e) {
        // do something
    }
}



function uuid() {
    return Math.floor(Math.random() * 10000000000000);
}
