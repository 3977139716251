import { DefineMaterialRequisitionModel } from "src/app/Transactions-Production/Material Requisition/DefineMaterialRequisitionModel";

export class DefinePurchaseOrderModel {
id: number;

itemsubcategory:string;
rate:number;
address:string;
itemcategoryid:number;
itemcategory:string;
itemsubcategoryid:number;
itemname:string;
itemid:number;
vendorname:string;
itemcode:string;
uom:string;
netamount:number;
amount:number;
gstamount:number;
podate:Date;
uomid:number;
quantity:number;
gstGrouppercentage:number;
hsnCode:string;
userid:number;
vendorid:number;
vendormodellst:DefinePurchaseOrderModel[];
orderlstall:DefinePurchaseOrderModel[];
dispatchaddress:string;
dimensions:string;
diamensionUnit:string;
receivedQuantity:number;
stockforsale:number;
checkselect:boolean;
tbpohdr:number;
termcondition:string;
potermsid:number;
pohdrid:number;
categorytermconid:number;
requisitionQuantity:number;
stockforsaletoloc:number;
actuStock:number;

availablestock:number;
stocklocationlst:DefineMaterialRequisitionModel[];

}