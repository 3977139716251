<app-header></app-header>
<div class="container" style="width: 81%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Marketing Budget-Dealer Allocations</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="row" style="margin-left: 0px;margin-right: 0px;">
                    <div class=" col s4" style="text-align:left;margin-top: 30px;padding-left: 14px;">
                        <span><b>All Region:</b></span><span>{{model.allreginvalue}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s3" style="margin-left: 10px;">
                        <span>Financial Year</span>
                        <select (change)="onSelectFinalyear($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Financial Year">
                            <option selected value="0">Select Financial Year</option>
                            <option *ngFor="let lst of finanyealst" [value]="lst.id"
                                [selected]="lst.id==model.financialyearid">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s4">
                        <span>Region</span>
                        <select (change)="onSelectRegion($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Region">
                            <option selected>Select Region</option>
                            <option *ngFor="let type of regionlst" [value]="type.id"
                                [selected]="type.id==model.regionId">
                                {{type.name}}
                            </option>
                        </select>
                    </div>
                    <div class=" col s2" style="text-align:left;margin-top: 40px">
                       <b>&#8377;</b><span>{{model.regionvalue}}</span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col s12 m12 l12 registerBgShadow">

                    <div class="col l12 m12 s12"
                        style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px; margin-top: 5px;">
                        <div class="col s1" style="text-align:center;">
                            <span> </span>
                        </div>
                        <div class="col s4" style="text-align:left;">
                            <span>Area</span>
                        </div>
                        <div class="col s2" style="text-align:center;">
                            &#8377;<span>Allocation</span>
                        </div>
                        <div class="col s3" style="text-align:center;">
                            <span>City</span>
                        </div>
                        <div class="col s2" style="text-align:center;">
                            <span>City Value</span>
                        </div>
                    </div>

                    <mat-accordion style="padding: 0px;">
                        <mat-expansion-panel
                            *ngFor="let lst of modellst | paginate: { itemsPerPage: 10, currentPage: p } ;let i = index "
                            style="padding-left: 1px;">
                            <mat-expansion-panel-header>
                                <div class="col l12 m12 s12">
                                    <div class="row">
                                        <div class="input-field col l1" style="text-align: left;margin-top: 30px">
                                            <input type="checkbox" [(ngModel)]="lst.arecheselect"
                                                #arecheselect="ngModel" (click)="onSelectArecheselect(i,$event)">
                                        </div>
                                        <div class=" col s4" style="text-align:left;margin-top: 30px">
                                            <span>{{lst.areaName}}</span>
                                        </div>
                                        <div class=" col s2" style="text-align:right;margin-top: 30px">
                                            <span>{{lst.areavalue}}</span>
                                        </div>
                                        <div class="input-field col s3 " style="margin-top: 12px;">
                                            <select (change)="onSelectcity($event.target.value,i)" data-toggle="tooltip"
                                                data-placement="top" title="City" style="margin-top: 7px;margin-bottom: 4px;">
                                                <option selected value="0">Select City</option>
                                                <option *ngFor="let lst of lst.citylst" [value]="lst.city"
                                                    [selected]="lst.city==lst.city">
                                                    {{lst.cityname}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class=" col s2" style="text-align:right;margin-top: 30px">
                                            <span>{{lst.cityvalue}}</span>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>

                            <!-- *ngIf="lst.dealerlst.length >0" -->
                            <div *ngIf="lst.dealerlst.length >0" class="registerBgShadow col l12 m12 s12"
                                style="text-align: center; margin-top: 10px;">
                                <div class="col l12 m12 s12"  style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 5px;">
                                    <div class=" col s1" style="text-align:center;">
                                        <span></span>
                                    </div>
                                    <div class=" col s4" style="text-align:left;margin-top: 10px">
                                        <span>Dealer Name</span>
                                    </div>
                                    <!-- <div class="col s2" style="text-align:center;margin-top: 10px">
                                        <span>Previous Year Sale</span>
                                    </div> -->
                                    <div class="col s2" style="text-align:center;margin-top: 10px">
                                        &#37;<span>Allocation</span>
                                    </div>
                                    <div class="col s2" style="text-align:center;margin-top: 10px">
                                        &#8377;<span>Allocation</span>
                                    </div>
                                    <div class="col s2" style="text-align:center;margin-top: 10px">
                                        <span>Action</span>
                                    </div>

                                </div>

                                <div class="row" *ngFor="let sublst of lst.dealerlst; let j = index">
                                    <div class=" input-field col l1" style="text-align: left;margin-top: 30px;padding-left: 25px;" >
                                        <input type="checkbox" [(ngModel)]="sublst.checkselect" #checkselect="ngModel"
                                            (click)="onSelectCheckselect(i,$event,j)">
                                    </div>
                                    <div class="col l4 m4 4" style="text-align:left;margin-top: 30px">
                                        <span>{{sublst.name}}</span>
                                    </div>
                                    <!-- <div class="col l2 m2 s2" style="text-align:center;margin-top: 30px">
                                        <span>{{sublst.sale}}</span>
                                    </div> -->

                                    <div class="col s2" style="text-align: right;">
                                        <div class="row">
                                            <div class=" input-field col s10 " style="text-align: right;padding: 0px;">
                                                <input [(ngModel)]="sublst.percentage" #percentage="ngModel"
                                                    type="text" id="first5" data-toggle="tooltip" data-placement="top"
                                                    style="text-align: right;"
                                                    (ngModelChange)="onChangeOfpercentage(i,j);"
                                                    title="Percentage Allocated" required>
                                                <label id="lable5" for="first5">% Allocation</label>
                                            </div>
                                            <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                                &#37;</div>
                                        </div>
                                    </div>
                                    <div class=" input-field col s2 " style="text-align: right;">
                                        <input [(ngModel)]="sublst.dealervalue" #dealervalue="ngModel" type="number"
                                            data-toggle="tooltip" data-placement="top" id="first1" min="0"
                                            (ngModelChange)="onChangeOfdealervalue(i,j);"
                                            oninput="validity.valid||(value='');" title="Dealer value" style="text-align: right;" required>
                                        <label id="lable1" for="first1">Dealer value</label>
                                    </div>
                                    <div class=" input-field col s2 " style="margin-top: 22px;"><span style="margin-right: 35px;"><img
                                                src="../../../assets/images/edit.png"
                                                (click)="btnopenmodel(i,j,templateModel)" title="Edit"
                                                width="20px" /></span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="lst.dealerlst.length==0" class="col l11 m11 s11 registerBgShadow"
                                style="margin-left:50px;text-align: center;">
                                <div class="col l12 m12 s12" style="color: red">
                                    <samp> List Not Found </samp>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <span style="text-align:right">
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align: right">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn"
                        style="margin-left: 5px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #templateModel>
    <div class="col l12 m12 s12  modal-header" style="text-align: end">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-content " style="text-align: center;">
        <span style="font-size: 20px;">Marketing Heads</span>

    </div>

    <div class="row ">
        <div class=" col s4" style="text-align:left;">
            <span><b>Area Name: </b></span><span>{{areaname}}</span>
        </div>
        <div class=" col s4" style="text-align:left;">
            <span><b>City Name: </b></span><span>{{cityname}}</span>
        </div>
        <div class=" col s4" style="text-align:left;">
            <span ><b>Dealer Name: </b></span><span>{{name}}</span>
        </div>
    </div>
   
    <div class="row">
        <div class="col s12 m12 l12 registerBgShadow">

            <div class="col l12 m12 s12" style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 5px;">
                <!-- <div class="col s2" style="text-align:center;">
                    <span> </span>
                </div> -->
                <div class="col l4 m4 s4" style="text-align:left;padding-left: 20px;">
                    <span>Marketing Cost Heads</span>
                </div>
                <div class="col s3" style="text-align:right;padding-right: 38px;">
                    &#37;<span>Allocation</span>
                </div>
                <div class="col s3" style="text-align:right;padding-right: 30px;">
                    &#8377;<span>Allocation</span>
                </div>


            </div>
            <mat-accordion style="padding: 0px;">
                <mat-expansion-panel
                    *ngFor="let lst of costheadlst | paginate: { itemsPerPage: 10, currentPage: p } ;let k = index "
                    style="padding-left: 1px;">
                    <mat-expansion-panel-header  >
                        <div class="col l12 m12 s12">
                            <div class="row">
                                <!-- <div class="input-field col l2" style="text-align: left;margin-top: 30px">
                                    <input type="checkbox" [(ngModel)]="lst.costcheselect" #arecheselect="ngModel"
                                        (click)="onSelectArecheselect(i,$event)">
                                </div> -->
                                <div class=" col s4" style="text-align:left;margin-top: 30px">
                                    <span>{{lst.costhead}}</span>
                                </div>
                                <div class="col s3" style="text-align: right;">
                                    <div class="row">
                                        <div class=" input-field col s10 " style="text-align: right;padding: 0px;">
                                            <input [(ngModel)]="lst.costpercentage" #costpercentage="ngModel"
                                                type="text" data-toggle="tooltip" data-placement="top" id="first7"
                                                (ngModelChange)="onChangeOfcostpercentage(k);"
                                                style="text-align: right;" title="Percentage Allocated" required>
                                            <label id="lable7" for="first7">% Allocation</label>
                                        </div>
                                        <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                            &#37;</div>
                                    </div>
                                </div>
                                <div class=" input-field col s3 " style="text-align: center;">
                                    <input [(ngModel)]="lst.costvalue" #costvalue="ngModel" type="number"
                                        data-toggle="tooltip" data-placement="top" id="first8" min="0"
                                        (ngModelChange)="onChangeOfcostvalue();" oninput="validity.valid||(value='');"
                                        title="Cost value" style="text-align: right;" required>
                                    <label id="lable8" for="first8">Cost value</label>
                                </div>

                            </div>
                        </div>
                    </mat-expansion-panel-header>


                    <div *ngIf="lst.costSubheadlst.length>0" class="registerBgShadow col l12 m12 s12"
                        style="text-align: center; margin-top: 10px;">
                        <div class="col l12 m12 s12" style="background: gainsboro;padding: 1%;color: rgb(0, 136, 199);font-weight: bold; margin-bottom: 5px;margin-top: 5px;">
                            <div class=" col s1" style="text-align:center;">
                                <span></span>
                            </div>
                            <div class=" col s4" style="text-align:left;margin-top: 10px;">
                                <span>Sub Heads</span>
                            </div>
                            <div class="col s1" style="text-align: left">
                                <span>UOM</span>
                            </div>
                            <div class="col s1" style="text-align: left">
                                <span>Qty</span>
                            </div>
                            <div class="col s1" style="text-align: left">
                                <span>Rate</span>
                            </div>
                            <div class="col s2" style="text-align:right;margin-top: 10px;padding-left: 12px;">
                                <span>% Allocation</span>
                            </div>
                            <div class="col s2" style="text-align:right;margin-top: 10px;">
                                &#8377;<span>Allocation</span>
                            </div>
                            <!-- <div class="col s2" style="text-align:center;margin-top: 10px">
                                <span>Action</span>
                            </div> -->

                        </div>

                        <div class="row" *ngFor="let sublst of lst.costSubheadlst; let j = index">
                            <div class=" input-field col l1" style="text-align: left;margin-top: 30px; padding-left: 25px;">
                                <input type="checkbox" [(ngModel)]="sublst.subcheckselect" #subcheckselect="ngModel"
                                    (click)="onSelectsubcheckselect(k,$event,j)">
                            </div>
                            <div class="col l4 m4 s4" style="text-align:left;margin-top: 30px;padding-left: 22px;">
                                <span>{{sublst.costsubname}}</span>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:left;margin-top: 30px">
                                <span>{{sublst.uomName}}</span>
                            </div>
                            <div class="input-field col l1 m1 s1" style="text-align:left;margin-top: 12px">
                                <input *ngIf = "sublst.valueselect == 'N' || sublst.rate != null" [(ngModel)]="sublst.quantity" #quantity="ngModel" type="number"
                                    data-toggle="tooltip" data-placement="top" id="first11" min="0"
                                    (ngModelChange)="onChangeOfQuantity(k,j);" oninput="validity.valid||(value='');"
                                    title="Quantity" style="text-align: right" required>
                                <label  *ngIf = "sublst.valueselect == 'N'" id="lable11" for="first11">Quantity</label>
                            </div>
                            <div class="col l1 m1 s1" style="text-align:left;margin-top: 30px">
                                <span>{{sublst.rate}}</span>
                            </div>
                            <div class="col s2" style="text-align: right;">
                                <div class="row">
                                    <div class=" input-field col s10 " style="text-align: right;padding: 0px;">
                                        <input [(ngModel)]="sublst.subpercentage" #subpercentage="ngModel" type="text"
                                            id="first9" data-toggle="tooltip" data-placement="top"
                                            style="text-align: right;" (ngModelChange)="onChangeOfsubpercentage(k,j);"
                                            title="Percentage Allocated" required>
                                        <label id="lable9" for="first9">% Allocation</label>
                                    </div>
                                    <div class=" input-field col s1" style="margin-top:21px;padding: 0px;">
                                        &#37;</div>
                                </div>
                            </div>
                            <div class=" input-field col s2 " style="text-align: right;">
                                <input [(ngModel)]="sublst.subheadvalue" #subheadvalue="ngModel" type="number"
                                    data-toggle="tooltip" data-placement="top" id="first10" min="0"
                                    (ngModelChange)="onChangeOfsubheadvalue(k,j);" oninput="validity.valid||(value='');"
                                    title="Sub Head value" style="text-align: right;" required>
                                <label id="lable10" for="first10">Sub Head Value</label>
                            </div>
                            <!-- <div class=" input-field col s2 "><span style="margin-right: 35px;"><img
                                        src="../../../assets/images/edit.png"
                                        (click)="btnopenmodel(model.id,templateModel)" title="Edit"
                                        width="20px" /></span>
                            </div> -->
                        </div>
                    </div>

                    <div *ngIf="lst.costSubheadlst.length==0" class="col l11 m11 s11 registerBgShadow"
                        style="margin-left:50px;text-align: center;">
                        <div class="col l12 m12 s12" style="color: red">
                            <samp> List Not Found </samp>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <span style="text-align:right">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </span>
        </div>
    </div>
</ng-template>