import { Component, OnInit, Inject, forwardRef } from "@angular/core";
import { MasterModel } from "../../_models/MasterModel";
import { EventEmitter } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router/";
import { BehaviorSubject, from } from "rxjs";
import {
  AlertService,
  AuthenticationService,
  MasterServices,
} from "../../_services";
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import { User } from "../../_models/user";
import { DropDownModel } from "../../_models/DropDownModel";
import { AngularWaitBarrier } from "blocking-proxy/built/lib/angular_wait_barrier";

import { SelectUserModel } from './SelectUserModel';
import { NotificationService } from 'src/app/_services/notification.service';
declare var $: any;
@Component({
  selector: "app-login",
  //  moduleId: module.id,
  templateUrl: "SelectUser.html",
})
export class SelectUser implements OnInit {
  sum = 0;
  posts = " "
    .repeat(100)
    .split("")
    .map((s, i) => i + 1);

  str: string;
  itemCount = 0;
  user = new User();
  model: any = {};
  private alllist: DropDownModel[] = [];
  private alllist1: DropDownModel[] = [];
  public lstuser: DropDownModel[] = [];

  constructor(
    private Masterservice: MasterServices,
    private alertService: AlertService,
    private userService: MasterServices,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private location: Location,
    private notifyService: NotificationService,

  ) { }

  ngOnInit() {
    this.user.token = localStorage.getItem('Token');
   
    this.getalluser();
  }
  reloadItems(params) { }
  getalluser() {
    this.lstuser = [];
    let user = new User();
    this.lstuser.push({id:0,name:"Select User"});
    this.Masterservice.getallActiveuser(user).subscribe((value) => {
      //this.docuser = [];
    
      value.forEach((item) =>
        this.lstuser.push({ id: item.id, name: item.name })
      );
      // tslint:disable-next-line:comment-format
      //this.lstuser = this.lstuser;
    });


  }
  onSelectuser(id) {
    if(id==0){
      this.notifyService.showRroor("Please Select Valid User","Oops");
      return;
    }
    this.model.id = id;

  }
  btngo() {
    if(this.model.id ==0){
      this.notifyService.showRroor("Please Select Valid User","Oops");
      return;
    }
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'id': this.model.id,
        'from': "User"
      }
    };
    this.router.navigate(['contextmenue'], navigationExtras);
  }
}
