<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Item Master</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row col l12 m12 s12" style="padding:0px;">
                <div class=row>
                    <div class="input-field col l12" style="margin-top:10px;padding-left: 14px;">
                        <input [(ngModel)]="model.chkcpyitem" #chkcpyitem="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;&nbsp;&nbsp;Copy Item Master
                    </div>
                </div>
                <div class=row *ngIf=" model.chkcpyitem" style="padding-left: 5px;margin-left: 0px;margin-right: 0px;">
                    <!-- <div class="input-field col l11 m11 s11" style="margin-top: 0px;margin-bottom: 0px;padding-left: 0px;">
                        <input type="text" class="autocomplete" id="cpyitem" data-toggle="tooltip" data-placement="top"
                            title="Item Name" style="margin-top:1px;" (keyup)="getItemLike1($event)">
                        <input type="hidden" id="acc-id" />
                        <label for="itmname" class="active" style="left: 0px;">Item Name</label>
                    </div> -->
                    <div class="ng-autocomplete pinRowgrp input-field col l11 m11 s11"
                        style="margin-top: 0px;margin-bottom: 0px;padding-left: 0px;">
                        <ng-autocomplete [data]="userList2" type="text" placeholder="Item Name" id="cpyitem"
                            [searchKeyword]="keyword" [minQueryLength]="3" (inputChanged)='onChangeSearch($event)'
                            (selected)='selectEventforitem($event)' (inputFocused)='onFocused($event)'
                            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>

                    </div>

                </div>

                <div class=row style="margin-top: 2px;padding-left: 5px;">
                    <div class="input-field col l4 m4 s4">
                        <input [(ngModel)]="model.itemcode" #itemcode="ngModel" type="text" class="" id="itemcode"
                            data-toggle="tooltip" data-placement="top" title="Item Code" style="margin-top:1px;">
                        <label for="itemcode" class="active">Item Code</label>
                    </div>
                    <div class="col l1"></div>
                    <div class="input-field col l6 m6 s6">
                        <input [(ngModel)]="model.itemname" #itemname="ngModel" type="text" class="" id="itemname"
                            data-toggle="tooltip" data-placement="top" title="Item Name" style="margin-top:1px;">
                        <label for="itemname" class="active">Item Name</label>
                    </div>
                    <div class="col l1"></div>
                </div>
                <div class=row style="padding-left: 5px;">
                    <div class="input-field col l11 m11 s11" style="margin-top:2px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.description" #description="ngModel" type="text" class=""
                            id="description" data-toggle="tooltip" data-placement="top" title="Description"
                            style="margin-top:1px;">
                        <label for="Description" class="active">Description</label>
                    </div>
                    <!-- <div class="col l2"></div> -->
                </div>
                <div class=row>
                    <div class="col l1 m1 s1" style="margin-top: 10px;">
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkbyitem"
                                (click)="onchkbyitem($event)" />
                            <span style="font-size: 104%">Item</span>
                        </label>&nbsp;&nbsp;
                    </div>
                    <div class="col l2 m2 s2" style="margin-top: 10px;">
                        <label>
                            <input name="group1" type="radio" [checked]="model.chkbyservice"
                                (click)="onchkbyservice($event)" />
                            <span style="font-size: 104%">Service</span>
                        </label>
                    </div>
                    <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;margin-top: 0px;">
                        <span>Item Category</span>
                        <select (change)="onSelectitemCat($event.target.value)">
                            <option selected>Select Item Category</option>
                            <option *ngFor="let type of itmCatlst" [value]="type.id"
                                [selected]="type.id == model.itemcategoryid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l4 m4 s4" style="margin-bottom: 0px;margin-top: 0px;">
                        <span>Sub Category</span>
                        <select (change)="onSelectSubCat($event.target.value)">
                            <option selected>Select Sub Category</option>
                            <option *ngFor="let type of subCatlst" [value]="type.id"
                                [selected]="type.id == model.itemsubcategoryid">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <div class=row>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;padding-left: 14px;">
                        <span>Inventory Unit</span>
                        <select (change)="onSelectInvUnit($event.target.value)">
                            <option selected>Select Inventory Unit</option>
                            <option *ngFor="let type of unitttlst" [value]="type.id"
                                [selected]="type.id == model.inventoryunitid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                        <span>Production Unit</span>
                        <select (change)="onSelectProdUnit($event.target.value)">
                            <option selected>Select Production Unit</option>
                            <option *ngFor="let type of unitttlst" [value]="type.id"
                                [selected]="type.id == model.productionunitid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                        <span>GST Group</span>
                        <select (change)="onSelectGstGrp($event.target.value)">
                            <option selected>Select GST Group</option>
                            <option *ngFor="let type of taxmodellst" [value]="type.id"
                                [selected]="type.id == model.gstGroupid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="input-field col l3 m3 s3"
                        style="margin-top: 28px;margin-bottom: 0px;padding-right: 14px;" *ngIf="model.itemtype=='Item'">
                        <input [(ngModel)]="model.hsnCode" #hsnCode="ngModel" type="text" class="" id="hsnCode"
                            data-toggle="tooltip" data-placement="top" title="HSN Code" style="margin-top:1px;">
                        <label for="hsnCode" class="active">HSN Code</label>
                    </div>
                    <div class="input-field col l3 m3 s3"
                        style="margin-top:28px;margin-bottom: 0px;padding-right: 14px;"
                        *ngIf="model.itemtype=='Service'">
                        <input [(ngModel)]="model.sacCode" #sacCode="ngModel" type="text" class="" id="sacCode"
                            data-toggle="tooltip" data-placement="top" title="SAC Code" style="margin-top:1px;">
                        <label for="sacCode" class="active">SAC Code</label>
                    </div>
                </div>

                <div class=row *ngIf="model.itemtype=='Item'" style="padding-left: 5px;">
                    <div class="input-field col l2 m2 s2" style="margin-top:17px;">
                        <input [(ngModel)]="model.reorderLevel" #reorderLevel="ngModel" type="number" class=""
                            id="reorderLevel" data-toggle="tooltip" data-placement="top" title="Reorder Level"
                            style="margin-top:1px;" required ng-pattern="/([1-9][0-9]*||[0][1-9][0-9]*)/" min="0">
                        <label for="reorderLevel" class="active">Reorder Level</label>
                    </div>
                    <div class="input-field col l2 m2 s2" style="margin-top: 17px;">
                        <input [(ngModel)]="model.minimumOrderQty" #minimumOrderQty="ngModel" type="number" class=""
                            id="minimumOrderQty" data-toggle="tooltip" data-placement="top"
                            title="Minimum Order Quantity" style="margin-top:1px;" min="0">
                        <label for="minimumOrderQty" class="active">Minimum Order Qty</label>
                    </div>
                    <div class="input-field col l1 m1 s1" style="margin-top:40px;">
                        <input [(ngModel)]="model.chklengt" #chklengt="ngModel" type="checkbox"
                            style="text-align:1px" [disabled]="temp == true">&nbsp;&nbsp;&nbsp;&nbsp;L
                    </div>
                    <div class="input-field col l1 m1 s1" style="margin-top: 17px;">
                        <input [(ngModel)]="model.lengt" #lengt="ngModel" type="number" class="" id="lengt"
                            data-toggle="tooltip" data-placement="top" title="Length" style="margin-top:1px;"
                            [disabled]="!model.chklengt">
                        <label for="lengt" class="active"></label>
                    </div>
                    <div class="input-field col l1 m1 s1" style="margin-top:40px;">
                        <input [(ngModel)]="model.chkbreadth" #chkbreadth="ngModel" type="checkbox"
                            style="text-align: 5px" [disabled]="temp == true">&nbsp;&nbsp;&nbsp;&nbsp;B
                    </div>
                    <div class="input-field col l1 m1 s1" style="margin-top:17px;">
                        <input [(ngModel)]="model.breadth" #breadth="ngModel" type="number" class="" id="breadth"
                            data-toggle="tooltip" data-placement="top" title="Breadth" style="margin-top:1px;"
                            [disabled]="!model.chkbreadth">
                        <label for="breadth" class="active"></label>
                    </div>
                    <div class="input-field col l1 m1 s1" style="margin-top:40px;">
                        <input [(ngModel)]="model.chkheight" #chkheight="ngModel" type="checkbox"
                            style="text-align: 5px" [disabled]="temp == true">&nbsp;&nbsp;&nbsp;&nbsp;H
                    </div>
                    <div class="input-field col l1 m1 s1" style="margin-top:17px;">
                        <input [(ngModel)]="model.height" #height="ngModel" type="number" class="" id="height"
                            data-toggle="tooltip" data-placement="top" title="Height" style="margin-top:1px;"
                            [disabled]="!model.chkheight">
                        <label for="height" class="active"></label>
                    </div>
                    <div class="input-field col l2 m2 s2" style="margin-top:0px;">
                        <span>Dimension Unit</span>
                        <select (change)="onSelectDimUnit($event.target.value)"  [disabled]="temp == true">
                            <option selected>Select Dimension Unit</option>
                            <option *ngFor="let type of unitttlst" [value]="type.id"
                                [selected]="type.id == model.diamensionUnitid">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div>
                <!-- <div class=row *ngIf="model.itemtype=='Item'">
                    <div class="input-field col l12" style="margin-top: 1px;">
                        <input [(ngModel)]="model.chkUntConversion" #chkUntConversion="ngModel" type="checkbox"
                            style="text-align: 5px">&nbsp;&nbsp;&nbsp;&nbsp;Unit Conversion
                    </div>
                </div>
                <div class=row style="padding-left:5px;">
                    <div class="input-field col l2 m2 s2" *ngIf=" model.chkUntConversion" style="margin-top: 15px;">
                        <input [(ngModel)]="model.ucQuantity" #ucQuantity="ngModel" type="number" class=""
                            id="ucQuantity" data-toggle="tooltip" data-placement="top" title="Unit Conversion Quantity"
                            style="margin-top:1px;" min="0">
                        <label for="ucQuantity" class="active">Quantity</label>
                    </div>
                    <div class="input-field col l3 m3 s3" *ngIf=" model.chkUntConversion" style="margin-top:0px;">
                        <span>Inventory Unit</span>
                        <select (change)="onSelectUCInvUnit($event.target.value)">
                            <option selected>Select Inventory Unit</option>
                            <option *ngFor="let type of unitttlst" [value]="type.id"
                                [selected]="type.id == model.ucInvenUnitid">
                                {{type.name}}</option>
                        </select>
                    </div>
                    <div class="col l2" style="margin-top: 3%;" *ngIf=" model.chkUntConversion">
                        <span> &nbsp;&nbsp;Converts To</span>
                    </div>
                    <div class="input-field col l2 m2 s2" *ngIf=" model.chkUntConversion" style="margin-top: 15px;">
                        <input [(ngModel)]="model.convQuantity" #convQuantity="ngModel" type="number" class=""
                            id="convQuantity" data-toggle="tooltip" data-placement="top" title="Convert Quantity"
                            style="margin-top:1px;" min="0">
                        <label for="convQuantity" class="active">Quantity</label>
                    </div>
                    <div class="input-field col l3 m3 s3" *ngIf=" model.chkUntConversion" style="margin-top:0px;">
                        <span>Inventory Unit</span>
                        <select (change)="onSelectConvInvUnit($event.target.value)">
                            <option selected>Select Inventory Unit</option>
                            <option *ngFor="let type of unitttlst" [value]="type.id"
                                [selected]="type.id == model.convInvenUnitid">
                                {{type.name}}</option>
                        </select>
                    </div>
                </div> -->
                <div class=row *ngIf="model.itemtype=='Item'">
                    <div class="input-field col l10">
                        <input [(ngModel)]="model.chkbom" #chkbom="ngModel" type="checkbox"
                            style="text-align: 5px" [disabled]="temp == true">&nbsp;&nbsp;&nbsp;&nbsp;BOM
                    </div>
                    <div class="input-field col l2 m2 s2" style="text-align: right" *ngIf=" model.chkbom">
                        <button type="button" (click)="openlocationmodel(locationmodel)"
                            class="waves-effect waves-light btn" style="margin-left: 20px;">Add</button>
                    </div>
                </div>
                <div class="row" *ngIf="model.itemtype=='Item'">
                    <div class="col l12 m12 s12">

                        <div class="col l12 m12 s12" *ngIf=" model.chkbom" style="padding:0px;">

                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="30%" style="text-align: left;padding-left: 10px;">Item Code</th>
                                    <th width="32%" style="text-align: left">Item Name</th>
                                    <th width="20%" style="text-align: left">UOM</th>
                                    <th width="10%" style="text-align: center">Qty</th>
                                    <th width="8%" style="text-align: center">Actions</th>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let lst of itemMasterlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                        <td style="text-align: left;padding-left: 10px;">{{lst.itemcode}}</td>
                                        <td style="text-align: left">{{lst.item}}</td>
                                        <td style="text-align: left">{{lst.uom}}</td>
                                        <td style="text-align: right"><input [(ngModel)]="lst.quantity"
                                                #quantity="ngModel" type="number" class="" id="quantity"
                                                data-toggle="tooltip" data-placement="top" title="quantity"
                                                style="margin-top:1px;text-align: right;" min="0"></td>
                                        <td style="text-align: center"> <span><img
                                                    src="../../../assets/images/delete.png" (click)="btndelts(lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Delete"
                                                    width="20px" /></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span style="text-align:right">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 0px;">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)"
                                class="waves-ripple waves-light btn">Save</button>
                        </div>
                        <!-- <div class="input-field col l6 m6 s6" style="text-align: left">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <ng-template #locationmodel>
            <div class="col l12 m12 s12  modal-header" style="text-align: end">
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-content " style="text-align:center;">
                <span style="font-size: 20px;">BOM Item</span>
            </div>
            <div class="row">
                <div class="input-field col l5 m5 s5">
                    <span>Item Category</span>
                    <select (change)="onSlctitemCat($event.target.value)">
                        <option selected>Select Item Category</option>
                        <option *ngFor="let type of itmCatlst" [value]="type.id"
                            [selected]="type.id == model1.itemcatid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l5 m5 s5">
                    <span>Sub Category</span>
                    <select (change)="onSlctSubCat($event.target.value)">
                        <option selected>Select Sub Category</option>
                        <option *ngFor="let type of subCatlst" [value]="type.id"
                            [selected]="type.id == model1.subitemcatid">
                            {{type.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="input-field col l10 m10 s10">
                    <!-- <input [(ngModel)]="model1.itmname" #itmname="ngModel" type="text" class="autocomplete" id="itmname"
                        data-toggle="tooltip" data-placement="top" title="Item Name" style="margin-top:1px;"
                        (keyup)="getItemLike($event)">
                    <input type="hidden" id="acc-id" />
                    <label for="Item Name" class="active">Item Name</label> -->
                    <div class="ng-autocomplete pinRowgrp input-field" style="margin-top:1px;">
                        <ng-autocomplete [data]="userList2" type="text" placeholder="Item Name" id="itmname"
                            [searchKeyword]="keyword1" [minQueryLength]="3" [(ngModel)]="model1.itmname"
                            #itmname="ngModel" (inputChanged)='onChangeSearchitem($event)'
                            (selected)='selectEvent($event)' (inputFocused)='onFocuseditem($event)'
                            [itemTemplate]="itemTemplate1" [notFoundTemplate]="notFoundTemplate1">
                        </ng-autocomplete>

                        <ng-template #itemTemplate1 let-item>
                            <a [innerHTML]="item.name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate1 let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>

                    </div>
                </div>


                <div class="input-field col l2 m2 s2" style="text-align: right;padding-right: 18px;"
                    *ngIf=" model.chkbom">
                    <button type="button" (click)="btnadd1()" class="waves-effect waves-light btn"
                        style="margin-left: 20px;">Add</button>
                </div>
            </div>
            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="col l12 m12 s12" style="padding-left: 0px;">
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="25%" style="text-align: left;padding-left: 10px;">Item Code</th>
                                <th width="46%" style="text-align: left">Item Name</th>
                                <th width="8%" style="text-align: center">Qty</th>
                                <th width="13%" style="text-align: left">UOM</th>
                                <th width="8%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of modelItemlst | paginate: { itemsPerPage: 10, currentPage: p }">
                                    <td style="text-align: left;padding-left: 10px;">{{lst.itemcode}}</td>
                                    <td style="text-align: left">{{lst.item}}</td>
                                    <td style="text-align: right"> <input [(ngModel)]="lst.quantity" #quantity="ngModel"
                                            type="number" class="" id="quantity" data-toggle="tooltip"
                                            data-placement="top" title="quantity"
                                            style="margin-top:1px;text-align: right"></td>
                                    <td style="text-align: left">{{lst.uom}}</td>
                                    <td style="text-align: center"><span><img src="../../../assets/images/delete.png"
                                                (click)="delBomlst(lst.itemid)" data-toggle="tooltip"
                                                data-placement="top" title="Delete" width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span style="text-align:right">
                            <pagination-controls (pageChange)="p = $event"></pagination-controls>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col l12 m12 s12">
                    <div class="input-field col l12 m12 s12" style="text-align: right;padding-right:9px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="modalRef.hide()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="mbtnsave($event)"
                            class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>

        </ng-template>
    </div>
</div>