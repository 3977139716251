import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchProductcategoryModel } from "./SearchProductcategoryModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'SearchProductcategory.html'
})
export class SearchProductcategory implements OnInit {
    constructor(private Masterservice: MasterServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) { }

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Product Category Code" },
        { id: 3, "name": "Product Category Name" },

    ];

    public i;
    model = new SearchProductcategoryModel();
  
    p: any;
    proCatlst: SearchProductcategoryModel[] = [];
    proCatlst1: SearchProductcategoryModel[] = [];

    ngOnInit(): void {
        this.getAllProductCat();
        
    }
    getAllProductCat() {

        this.Masterservice.getAllProductCat(this.model)
            .subscribe(value => {
                this.proCatlst = value
                this.proCatlst1 = [];
                value.forEach((item) => this.proCatlst1.push({ "id": item.id, "productCategoryName": item.productCategoryName, "productCategoryCode": item.productCategoryCode, "description": item.description, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect }));
                this.proCatlst = this.proCatlst1;

            })
    }
    onSelectProduct(value) {
        this.model.txtselect = value;
    }
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllProductCat()
            this.model.txtsearch = null;
        }
        else if (this.model.txtselect == "Product Category Code") {

            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Product Category Code to Search")
                return;
            }
            else {
                this.proCatlst1 = [];
                this.proCatlst.forEach((item) => {
                    if ((item.productCategoryCode.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.proCatlst1.push({ "id": item.id, "productCategoryName": item.productCategoryName, "productCategoryCode": item.productCategoryCode, "description": item.description, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect });
                    }
                });
                this.proCatlst = this.proCatlst1;
            }
        }
        else if (this.model.txtselect == "Product Category Name") {
            if (this.model.txtsearch == null || this.model.txtsearch == "") {
                alert("Enter Product Category Name To Search")
                return;
            }
            else {
              
                this.proCatlst1 = [];
                this.proCatlst.forEach((item) => {
                    if ((item.productCategoryName.toLowerCase()).match(this.model.txtsearch.toLowerCase().trim())) {
                        this.proCatlst1.push({ "id": item.id, "productCategoryName": item.productCategoryName, "productCategoryCode": item.productCategoryCode, "description": item.description, "srno": item.srno, "txtsearch": item.txtsearch, "txtselect": item.txtselect });
                    }
                });
                this.proCatlst = this.proCatlst1;
            }
        }


    }
    btnnew(){
        this.router.navigate(['/DefineProductcategory']);
    }
    btnedit(id) {
        this.router.navigate(['/mangeprocat', id]);
    }
    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
                this.Masterservice.dltProductCategory(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }
    }
}