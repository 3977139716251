import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterServices, AlertService, TransactionServices } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { DefineCompanyMasterModel } from './DefineCompanyMasterModel';
import { Location, DatePipe } from '@angular/common';
import { usertwofieldModel } from '../ResourceMaster/usertwofieldModel';
// import { usertwofieldModel } from './usertwofieldModel';
declare var $: any;
@Component({
    selector: 'app-login',

    templateUrl: 'DefineCompanyMaster.html'
})
export class DefineCompanyMaster implements OnInit, OnDestroy {
    model = new DefineCompanyMasterModel();
    sub: any;
    user = new User();
    id: number;
    p: any;
    pagename: string;
    length: number;
    customername: string;
    complst: DefineCompanyMasterModel[] = [];
    industorylst: DefineCompanyMasterModel[] = [];
    defautcurrencylst: DropDownModel[] = [];
    // serviceheadlst: DropDownModel[] = [];
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
    }
    public instalationaprovlst: DropDownModel[] = [
        { id: 2, "name": "Owner" },
        { id: 3, "name": "Assigner" },
    ];
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit() {
        this.pagename = localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        var id = document.getElementById("defaulttab");
        id.click();
        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];

        });


        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.id = this.id;
            this.Masterservice.getCustomerDetailsByid(this.model)
                .subscribe(
                    data => {
                        this.model = data;
                        this.complst = data.complst;
                        for (let i = 0; i < this.complst[i].length; i++)
                            if (this.complst[i].customervalue == "Y") {
                                this.complst[i].typeflag = true;
                            }


                    },
                    error => {
                        this.alertService.error(error);
                    });
            let l1 = (<HTMLInputElement>document.getElementById('lable1'));
            l1.className = "active";
            let l2 = (<HTMLInputElement>document.getElementById('lable2'));
            l2.className = "active";
            let l3 = (<HTMLInputElement>document.getElementById('lable3'));
            l3.className = "active";
            let l4 = (<HTMLInputElement>document.getElementById('lable4'));
            l4.className = "active";
            let l5 = (<HTMLInputElement>document.getElementById('lable5'));
            l5.className = "active";
            let l6 = (<HTMLInputElement>document.getElementById('lable6'));
            l6.className = "active";
            let l7 = (<HTMLInputElement>document.getElementById('lable7'));
            l7.className = "active";
            let l8 = (<HTMLInputElement>document.getElementById('lable8'));
            l8.className = "active";
            let l9 = (<HTMLInputElement>document.getElementById('lable9'));
            l9.className = "active";
            let l10 = (<HTMLInputElement>document.getElementById('lable10'));
            l10.className = "active";
            let l11 = (<HTMLInputElement>document.getElementById('lable11'));
            l11.className = "active";
            let l12 = (<HTMLInputElement>document.getElementById('lable12'));
            l12.className = "active";
            let l13 = (<HTMLInputElement>document.getElementById('lable13'));
            l13.className = "active";
            let l14 = (<HTMLInputElement>document.getElementById('lable14'));
            l14.className = "active";
            let l15 = (<HTMLInputElement>document.getElementById('lable15'));
            l15.className = "active";
            let l16 = (<HTMLInputElement>document.getElementById('lable16'));
            l16.className = "active";
            let l17 = (<HTMLInputElement>document.getElementById('lable17'));
            l17.className = "active";
        }


        this.getdefaultcurrency();

    }

    getdefaultcurrency() {
        let user = new User();
        this.defautcurrencylst = [];
        // this.serviceheadlst = [];
        this.Masterservice.getDefaultcurrency(user)
            .subscribe((value) => {
                this.defautcurrencylst = value.defautcurrlst;
                // this.serviceheadlst = value.defautcurrencylst;
            });
    }



    onSelectdefaultcurrency(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select  Default Currency!!", "Oops!");
            return;
        }

        this.model.defaultcurrencyid = value;
    }

    btncancle() {
        this.router.navigate(["Company"]);
    }
    // onselectinstalationapproval(value) {
    //     this.model.installationapproval = value;
    // }
    // onSelectservicehead(value) {

    //     this.model.serviceheadnameid = value;
    // }
    savecomp() {
        this.model.complst = this.complst
        this.Masterservice.savecompany(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(["Company"]);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    // onChangefort() {
    //     this.model.monthly = false
    // }
    // onChangemonth() {
    //     this.model.fortnightly = false;
    // }
}