import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { User } from 'src/app/_models';
import { CallCenterDashboardModel } from './CallCenterDashboardModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { productmodel, ProspectModel } from '../Sales-Transaction/ProspectModel';
import { OnDestroy } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-login',
  //  moduleId: module.id,
  templateUrl: 'CallCenterDashboard.html'
})

export class CallCenterDashboard implements OnInit, OnDestroy {
  p: any;
  model: any = {};
  user = new User();
  modalRef2: BsModalRef;
  userList2: any;
  // calllst: CallCenterDashboardModel[] = [];
  // subacclst:CallCenterDashboardModel[] = [];
  // callcenlst:CallCenterDashboardModel[] = [];
  modelst: CallCenterDashboardModel[] = [];
  lstindex: number;
  name: string;
  addr: string;
  pincode: string;
  localityID: number;
  areaID: number;
  locality: string;
  city: string;
  state: string;
  country: string;
  locale: string;
  areaname: string;
  regionname: string;
  googlemap: string;
  lstproduct: CallCenterDashboardModel[] = [];
  lstlocalityforproduct: DropDownModel[] = [];
  sendmodel = new ProspectModel();
  productmodel = new productmodel();
  serviceNO: string;
  serviceID: number;
  var1: number;
  lstproduct1: productmodel[] = [];
  searchstring: string;
  sum: number = 1;
  pageno: string;

  constructor(private Masterservice: MasterServices, private transactionService: TransactionServices, private modalService: BsModalService, private datePipe: DatePipe, private alertService: AlertService, private transactionservice: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
  }
  ngOnDestroy() {
    localStorage.removeItem("searchstring");
  }

  ngOnInit(): void {
    //this.transactionservice.getcustomerBySearch(this.model);
    this.lstproduct = [];
    this.searchstring = localStorage.getItem("searchstring");
    this.pageno = localStorage.getItem("pageno");
    this.model.id = localStorage.getItem("userid");

    let re = /\ /gi;

    this.model.txtserach2 = this.searchstring;
    this.model.pageno = this.pageno;
    // this.model.txtserach2 = this.model.txtserach2.replace(re, ',');
    this.transactionservice.getcustomerBySearch(this.model)
      .subscribe(value => {
        this.p = this.pageno;
        this.modelst = value;
      //   for (let i = 0; i < this.modelst.length; i++) {
      //     this.model.totalItems = this.pageno;
      // }
        
      });
      let l1 = (<HTMLInputElement>document.getElementById('search'));
      l1.className = "active";

  }


  btnsearch() {
    let re = /\ /gi;
    // this.model.txtserach2 = this.model.txtserach2.replace(re, ',');
    this.transactionservice.getcustomerBySearch(this.model)
      .subscribe(value => {
        this.modelst = value
        if (this.modelst.length <= 0) {
          this.notifyService.showRroor("Customer Is Not Available !", "Opps");
          return
        }
      });



  }
  btngetdetails(accountid) {
    let i = 0;
    for (let j = 0; j < this.modelst.length; j++) {
      if (this.modelst[j].accountid == accountid) {
        i = j;
      }
    }
    this.model.accountid = accountid;
    this.modelst[i].lstproduct = [];
    this.transactionservice.getsubcallcen(this.model)
      .subscribe(value => {
        this.modelst[i].lstproduct = value
      });
  }

  getPincodeLikeforproduct($event) {
    let userId = (<HTMLInputElement>document.getElementById('pincode2')).value;
    this.userList2 = [];
    let searchDataObj = {};
    this.user.message = userId;
    var obj = this;
    if (userId.length >= 3) {
      this.transactionService.getpincodelike(this.user)
        .subscribe(data => {
          data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
          let map = new Map();
          var names = "";
          for (let i = 0; i < this.userList2.length; i++) {
            map.set(this.userList2[i].name, this.userList2[i].id)
            searchDataObj[this.userList2[i].name] = null;
            if (i == 0) {
              names = this.userList2[i].name;
            } else {
              names = names + "," + this.userList2[i].name;
            }
          }
          let customername;
          $(document).ready(function () {
            $(".only-numeric").bind("keypress", function (e) {
              var keyCode = e.which ? e.which : e.keyCode

              if (!(keyCode >= 48 && keyCode <= 57)) {
                $(".error").css("display", "inline");
                return false;
              } else {
                $(".error").css("display", "none");
              }
            });
          });
          $(document).ready(function () {
            $('input.typeahead').autocomplete({
              onAutocomplete: function (txt) {
                obj.typeaheadpincodeforproduct(map.get(txt), txt);
              },
            });
            $('input.autocomplete').autocomplete({
              data: searchDataObj,
              onAutocomplete: function (txt) {
                obj.typeaheadpincodeforproduct(map.get(txt), txt);
              },
            });
          });
        },
          () => {
            alert("fail");
          });
    }
    if (this.model.pincode.length < 6 || this.model.pincode.length > 6) {
      this.lstlocalityforproduct = [];
    }
  }
  btnedit(accountid, i) {
    let accoutid: any = accountid;

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "flag": btoa("edit"),
        "id": btoa(accoutid),
        "searchstring": this.model.txtserach2
      }
    };
    this.router.navigate(['/prospect'], navigationExtras);
    // this.router.navigate(['/editcallcen', accountid]);
  }
  btncreatelead(accountid, i) {
    let accoutid: any = accountid;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "flag": btoa("createlead"),
        "id": btoa(accoutid),
        "searchstring": this.model.txtserach2
      }
    };
    this.router.navigate(['/prospect'], navigationExtras);
  }

  btnopenaccount360(accountid) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "id": btoa(accountid),
        "searchstring": this.model.txtserach2,
        "pageno":this.pageno
      }
    };
    this.router.navigate(['/AccountThreeSixty'], navigationExtras);
  }

  btnrefresh() {


  }

  btnaddprospect() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "flag": btoa("addprospect"),
      }
    };
    this.router.navigate(['/prospect'], navigationExtras);
  }
  btnnewprospect() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "flag": btoa("newprospect"),

      }
    };
    this.router.navigate(['/prospect'], navigationExtras);
  }
  btnservicecall(accountid, i, productid, id,servicecallModel: TemplateRef<any>) {
    this.sendmodel.accountid = accountid;
    this.sendmodel.productID = productid;
    this.transactionService.checkServicecall(this.sendmodel)
      .subscribe(data => {
        if (data.serviceCallnumber != null) {
          this.serviceNO = data.serviceCallnumber;
          this.serviceID = data.serviceCallID;
          this.modalRef2 = this.modalService.show(servicecallModel, {
            class: 'modal-lg'
          });

        } else {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              "flag": btoa("bookservicecall"),
              "id": btoa(accountid),
              "productid": btoa(productid),
              "searchstring": this.model.txtserach2,
              "machineid":btoa(id)
            }
          };
          this.router.navigate(['/prospect'], navigationExtras);
        }
      });




  }

  btnlocation(accountmodel: TemplateRef<any>, productid, id, i) {
    this.modelst[i].lstproduct.forEach(element => {
      if (element.productid == productid) {
        this.name = this.modelst[i].name
        this.lstindex = element.id;
        this.addr = element.address;
        this.pincode = element.pincode;
        this.localityID = element.localityID;
        this.areaID = element.areaID;
        this.locality = element.locality;
        this.sendmodel.pincode = element.pincode;
        this.sendmodel.localityID = element.localityID;
        this.city = element.city;
        this.state = element.state;
        this.country = element.country;
        this.locale = element.locale;
        this.areaname = element.areaname;
        this.regionname = element.regionname;
        this.googlemap = element.googlemap;
        this.transactionService.getlocality(this.sendmodel)
          .subscribe(data => {
            data.forEach(item => {
              this.lstlocalityforproduct.push({ id: item.id, name: item.name });
            });
          });
      }
    });
    this.modalRef2 = this.modalService.show(accountmodel, {
      class: 'modal-lg'
    });
  }

  btnaddproductaddress() {
    if (this.pincode == null) {
      this.notifyService.showRroor("Please Select Pincode", "Opps");
      return
    }
    if (this.localityID == null) {
      this.notifyService.showRroor("Please Select Locality", "Opps");
      return
    }
    if (this.addr == null) {
      this.notifyService.showRroor("Please Enter Address", "Opps");
      return
    }
    this.productmodel.id = this.lstindex;
    this.productmodel.address = this.addr;
    this.productmodel.pincode = this.pincode;
    this.productmodel.locality = this.locality;
    this.productmodel.localityID = this.localityID;
    this.productmodel.state = this.state;
    this.productmodel.areaID = this.areaID;
    this.productmodel.areaname = this.areaname;
    this.productmodel.googlemap = this.googlemap;
    this.productmodel.regionname = this.regionname;

    this.transactionService.saveproductaddress(this.productmodel)
      .subscribe(data => {
        if (data.status == "OK") {
          this.notifyService.showSuccess("Saved successfully", "Opps");
          this.modalRef2.hide();
          this.btnsearch();
        }
      });
  }

  onSelectproductlocality(id) {
    this.sendmodel.localityID = id;
    this.localityID = id;
    // this.locality = this.localitymap.get(id);
    // this.sendmodel.locality = this.localitymap.get(id);
    this.transactionService.getgeographydetails(this.sendmodel)
      .subscribe(data => {
        this.city = data.city;
        this.state = data.state;
        this.country = data.country;
        this.locale = data.locale;
        this.areaname = data.areaname;
        this.areaID = data.areaID1;
        this.regionname = data.regionname;
        this.locality = data.locality;
        this.sendmodel.locality = data.locality;

      });
  }
  btnclose() {
    this.modalRef2.hide();
  }

  typeaheadpincodeforproduct(id, name) {
    this.sendmodel.pincode = name;
    this.lstlocalityforproduct = [];
    this.lstlocalityforproduct.push({ id: 0, name: "Select Locality" });
    this.transactionService.getlocality(this.sendmodel)
      .subscribe(data => {
        data.forEach(item => {
          this.lstlocalityforproduct.push({ id: item.id, name: item.name });
        });
      });

  }
  btnservicecalledit() {
    this.modalRef2.hide();
    let productid: any = this.sendmodel.productID;
    let accoutid: any = this.sendmodel.accountid;
    let servicecallID: any = this.serviceID;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "flag": btoa("bookservicecall"),
        "id": btoa(accoutid),
        "serviceCallnumber": servicecallID,
        "productid": btoa(productid)
      }
    };
    this.router.navigate(['/prospect'], navigationExtras);
  }
  showPageIndex(pageIndex)
  {
    this.pageno = pageIndex;
   }
}