<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l2 m2 s2"></div>
        <div class="heading col l8 m8 s8">
            <span style="font-size: 25px;">Sales Return</span>
        </div>
        <div class="col l2 m2 s2"></div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row">
                <div class="ng-autocomplete pinRowgrp input-field col l4 m4 s4">
                    <ng-autocomplete [data]="customerlst" type="text" [(ngModel)]="model.customerName"
                        placeholder="Customer Name" [searchKeyword]="keyword" [minQueryLength]="3" id="supervisiorAuth"
                        (inputChanged)='onChangeSearch($event)' (selected)='selectEvent($event)'
                        (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                    <span>Invoice Number</span>
                    <select (change)="onSelectInvoice($event.target.value)" data-toggle="tooltip" data-placement="top"
                        title="Invoice Number">
                        <option selected disabled>Select Invoice</option>
                        <option *ngFor="let type of invlst" [value]="type.invoiceNo">{{type.invoiceNo}}</option>
                    </select>
                </div>
                <div class="input-field col l2 m2 s2">
                    <span style="font-size: 15px;">Invoice Date:&nbsp;<br><span
                            style="font-size: 15px;font-weight: bold">{{model.invoceDate |
                            date : "dd-MM-yyyy"}}</span>
                    </span>
                </div>
                <div class="input-field col l2 m2 s2">
                    <span style="font-size: 15px;">Credit Note Date:&nbsp;<br><span
                            style="font-size: 15px;font-weight: bold">{{model.creditNoteDate |
                            date : "dd-MM-yyyy"}}</span>
                    </span>
                </div>

            </div>
            <div class="row">
                <div class="input-field col l3 m3 s3" style="margin: 0px;">
                    <span>Inventory Location</span>
                    <select (change)="onSelectInventoryLocation($event.target.value )" data-toggle="tooltip"
                        data-placement="top" title="Inventory Location">
                        <option selected value="0">Select Inventory Location</option>
                        <option *ngFor="let lst of locationlst" [value]="lst.id" [selected]="lst.id == model.locationid">
                            {{lst.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row" style="margin:0px">
                <table>
                    <thead style="background: gainsboro;">
                        <th width="8%" style="text-align: center"><input type="checkbox" [(ngModel)]="model.chkall"
                                #name="ngModel" (click)="btnselectAllProduct($event)"></th>
                        <th width="32%" style="text-align: left">Product</th>
                        <th width="8%" style="text-align: center">Uom</th>
                        <th width="10%" style="text-align: center">Invoice Quantity</th>
                        <th width="10%" style="text-align: center">Return Quantity</th>
                        <th width="10%" style="text-align: center">Rate</th>
                        <th width="8%" style="text-align: center">Gst %</th>
                        <th width="10%" style="text-align: center">Amount</th>
                        <th width="10%" style="text-align: center">Credit Amount</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lst of invdtllst">
                            <td style="text-align: center"><input type="checkbox" [(ngModel)]="lst.chkselectpc"
                                    #chkselectpc="ngModel" (click)="btnselectProduct($event, lst.id)"></td>
                            <td style="text-align: left">{{lst.productName}}</td>
                            <td style="text-align: center">{{lst.uom}}</td>
                            <td style="text-align: right">{{lst.quantity | number:'1.2-2'}}</td>
                            <td style="margin: 0px">
                                <input type="number" [(ngModel)]="lst.returnedquantity" #returnedquantity="ngModel"
                                    style="text-align: right" placeholder="" data-toggle="tooltip" data-placement="top"
                                    title="Return Quantity" (ngModelChange)="onChangeQuantity()" min="0"
                                    oninput="validity.valid||(value='');" [max]="lst.quantity">

                            <td style="text-align: right">&#8377;&nbsp;{{lst.rate | number:'1.2-2'}}</td>
                            <td style="text-align: right">{{lst.gstPer}}&nbsp;&#37;</td>
                            <td style="text-align: right;">&#8377;&nbsp;{{lst.amount |
                                number:'1.2-2'}}</td>
                            <td style="text-align: right;padding-right: 9px;">&#8377;&nbsp;{{lst.crdtAmount |
                                    number:'1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td colspan="7" style="text-align: right;background-color: whitesmoke;"><b>Total Amount</b>
                            </td>
                            <td style="text-align: right;padding-right: 9px;background-color: whitesmoke;">
                                <b>&#8377;&nbsp;{{model.totalAmount | number:'1.2-2'}}</b>
                            </td>
                            <td style="text-align: right;padding-right: 9px;background-color: whitesmoke;">
                                <b>&#8377;&nbsp;{{model.crdtTotalAmount | number:'1.2-2'}}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row" style="margin: 0px;">
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;padding-right: 9px;">
                        <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                            (click)="cancle()">Cancel</button>&nbsp;&nbsp;
                        <button type="button" (click)="btnsave()" class="waves-ripple waves-light btn">Save</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>