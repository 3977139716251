<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row" style="margin-top: 2%">
                <div class="col l1"></div>
                <div class="input-field col l5 m5 s5">
                    <input [(ngModel)]="model.code" #code="ngModel" type="text" class="" id="amccode"
                        data-toggle="tooltip" data-placement="top" title="AMC Code">
                    <label for="amccode" class="active">AMC Code</label>
                </div>
                <div class="input-field col l5 m5 s5">
                    <input [(ngModel)]="model.name" #name="ngModel" type="text" class="" id="amcname"
                        data-toggle="tooltip" data-placement="top" title="AMC Name">
                    <label for="amcname" class="active">AMC Name</label>
                </div>
                <div class="col l1"></div>
            </div>

            <div class="row">
                <div class="col l1"></div>
                <div class="col l6 m6 s6">
                    <label>
                        <input name="group1" type="radio" [checked]="model.lab" (click)="oncheckLab($event)" />
                        <span>Labour</span>
                    </label>&nbsp;&nbsp;
                    <label>
                        <input name="group1" type="radio" [checked]="model.com" (click)="onCheckCom($event)" />
                        <span>Comprehensive</span>
                    </label>
                </div>
            </div>

            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right;padding-right: 0px;">
                    <button (click)="btnback()" type="button" class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnsaveAmcType()" type="button" class="waves-effect waves-light btn">Save</button>
                </div>
                <!-- <div class="input-field col l6 m6 s6" style="text-align: left">
                    
                </div> -->
               
            </div>
        </div>
    </div>
</div>