import { NumericLiteral } from 'typescript';
import { Contacts, Products } from '../DailyCall/DailyCallModel';
import { sparemodel } from '../Sales-Transaction/ProspectModel';

export class QuotationModel {
    id: number;
    userid: number;
    connection:string;
    activityid: number;
    actionTaken: string;
    quotationid: number;
    quoCode: string;
    accid: number;
    accname: string;
    contactpersonid: number;
    ownerIdofOppor: number;
    ownernameofOppor: string;
    address: string;
    currencyName:string;
    enqId: string;
    quoDate: Date;
    ctypeid: number;
    ctype: string;
    creditdays: number;
    quoStatus: string;
    contactperson:string;
    quotationAmount: number;

    installInstru: string;
    installionDate: Date;
    installionTime: string;

    nextstageid: number;
    chkscheduleInstal: boolean

    contactlst: Contacts[];
    productEnquirylst: QuotationProductModel[];
    productlst: Products[];
    procatlst: Products[];
    tandclst: TermsAndConditionsModel[] = [];
    changetandclt:TermsAndConditionsModel[] = []

    salesTC: boolean;
    paymentType: string;
    gstseperate: boolean;
    orderaccp: string;
    chkschemeflag:string;
    sendquotation: boolean

    chkwon: boolean;
    chklost: boolean;
    chkaband: boolean;
    decision: string;
    decisionDate: Date = new Date();
    lossreason: number;
    remarks: string;
    approvedQuo: boolean;
    schmemCode: string;
    status: string;
    otp: string;
    otpverified: boolean;

    qhistorylst: QuotationHistory[] = []
    qversionlst: QuotationHistory[] = []
    //quotationReport
    discountAmount:number;
    tax:number;
    previousremarks:string;
    lstproductintersted:productmodel[]=[];
    unitno:string;
    buildingName:string;
    city:string;
    pincode:string;
    discount:number;
    showOtp:boolean;
    Otpshow: string;
    fulldiscount:number;
    schemetype:string;
    quoid:number;
    txtselect:string;
    txtserch:string;
    customerName:string;
    enquiryName:string;
    quostage:string;
    srno:number;
    installation:String;
    dearflag:boolean;
    lstspare:sparemodel[]=[];
    totalamount:number;
    total:number;
    totalgstamount:number;
    totalamountwords:string;
    customnumber:number;
}

export class CurrencyModel {
    id: number;
    name: string;
    code: string;
    amount: number;
    currencylst: CurrencyModel[] = []
}

export class QuotationProductModel {
    id: number;
    pcid: number;
    currencyName:string;
    pcname: string;
    pid: number;
    name: number;
    totalamount:number;
    uom: string;
    listprice: number;
    taxamount: number;
    offeredPrice: number;
    totalproamount: number;
    totalproamountnew:number;
    discount: number;
    discountAmount: number;
    discountAllowed: number;
    revisedDiscount:number;
    quantity: number;
    netamount: number;
    currencyid: number;
    currencylst: CurrencyModel[] = [];
    targetcurrencyid: number;
    taxpercent: number;
    approvedQuo: boolean;
    amount:number;
    ratewithdiscount:number;
    offereddiscount:number;
    newdiscountamt:number;
    tax1:number;
    totalnewamt:number;
    fulldiscount:number;
    listtotal:number;
    rateWithGst: number;
    discountAllowedNew:number;
    customnumber:number;
}

export class TermsAndConditionsModel {
    id: number;
    quotermsid: number;
    heading: string;
    descrption: string;
    chkselecttc: boolean;
    prevDis:string;
}

export class QuotationHistory {
    id: number;
    createdBy: string;
    createdDate: Date;
    customerName: string;
    customerAddress: string;
    quotationno: string;
    enquiryName: string;
    remarks: string;
    historyproductlst: QuotationProductModel[] = [];
    historyTermslst: TermsAndConditionsModel[] = [];
}
export class QuotReportModel {

    companyname: String;
    companyaddress: String;
    quotationCode: String;
    quoDate: Date;
    paymentTerm: String;
    contactPerson: String;
    qutReportlst: QuotReportModel[];
    reporttandclst:QuotReportModel[];
    name: string;
    quantity: number;
    hsn:String;
    uom: String;
    listprice: number;
    taxamount: number;
    totalproamount:number;
    amount:number;
    discount:number;
    netamount:number;
    totalinwords: string;
    totalamount:number;
    totalnetamount:number;
    totaltaxamount:number;
    heading:String;
	descrption: String ;

}
export class productmodel{
    accountid:number;
    checkselect:boolean;
    id:number;
    productid:number;
    name:string
    productCategoryID:number;
    categoryname:string;
    categoriesdescription:string;
    description:string;
    purdate:Date;
    installationDate:Date;
    added:boolean;
    location:string;

    address:string;
    pincode:string;
    localityID:number;
    areaID:number;
    locality:string;
    city:string;
    state:string;
    country:string;
    locale:string;
    areaname:string;
    regionname:string;
    serviceCallStatusName:string;
    googlemap:string

    amcWarrantyEndDate:Date
    amcEndDate:Date
    warrentyEndDate:Date;
    srlno:string;
    serialnoFlag:boolean;
    status:string;
    serviceCallStatusID:number;
}