import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
// import { DesignationAllowanceModel } from './DesignationAllowanceModel';
import { User } from 'src/app/_models/user';
import { ReassignModel } from './Reassignmodel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { map } from 'jquery';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { TeamModel } from '../Teams/TeamModel';
declare var $: any;



@Component({
    selector: 'app-login',
    templateUrl: 'Reassign.html'
})
export class Reassign implements OnInit {
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService, private transactionservice: TransactionServices) {
        this.pagename = this.router.url
    }
    model = new ReassignModel();
    id: number;
    pagename: string;
    userList2: any;
    user = new User();
    lstopportunitylst: ReassignModel[] = [];
    servicecalllst: ReassignModel[] = [];
    installationlst: ReassignModel[] = [];
    resourcelst: ReassignModel[] = [];
    p: any;
    q: any;
    r: any;
    teamlst: TeamModel[] = [];
    teamlst1: TeamModel[] = [];
    teammodel = new TeamModel();
    lstassigned: DropDownModel[] = [];


    // public assignlst: DropDownModel[] = [
    //     { id: 0, "name": "Vaibhav Baraskar" },
    //     { id: 1, "name": "Prashant Sohoni" },
    //     { id: 2, "name": "Kishor Bhilare" },

    // ];



    ngOnInit(): void {
        // this.sub = this.route.params.subscribe(params => {
        //     this.id = + params["id"];
        // });
        this.model.userid = Number(localStorage.getItem("userid"));
        let user = new User();
        user.trnid = this.id;
        // user.trnid = 27;
        // this.Masterservice.getdesignationallowbyid(user)
        //     .subscribe(
        //         data => {
        //             this.modellst = data.modellst
        //         },
        //         error => {
        //             this.alertService.error(error);
        //         });
        this.getAllTeams();


    }

    // onSelectModule(id) {

    // }

    // btnsave($event) {
    //     // if (this.model.stageCode == null || this.model.stageCode == "") {
    //     //     this.notifyService.showRroor("Select  StageCode !!", "Oops!");
    //     //     return;
    //     // }

    //     // this.model.modellst = this.modellst
    //     // this.Masterservice.savepipeline(this.model)
    //     //     .subscribe(
    //     //         data => {
    //     //             if (data.status == "OK") {
    //     //                 this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
    //     //                 this.router.navigate(['SearchPipeline']);
    //     //             } else {
    //     //                 this.notifyService.showRroor(data.message, "Oops !")
    //     //             }
    //     //         },
    //     //         error => {
    //     //             alert("Something Wrong ")
    //     //         });

    // }


    cancle() {
        this.router.navigate(["/landpage"]);
    }
    btnsave() {
        if (this.model.assignedtoID == null) {
            this.notifyService.showRroor("Please Select AssignTo Name", "Oops!");
            return;
        }
        this.transactionservice.btnsaveReassign(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Done!");
                        this.router.navigate(["/landpage"]);
                    }
                })
    }
    getresourcename($event) {
        let userId = (<HTMLInputElement>document.getElementById('resourcename')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.teamid = this.model.teamid;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getresourcenamelike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "name": item.name, "id": item.id }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].name, this.userList2[i].id)
                        searchDataObj[this.userList2[i].name] = null;
                        if (i == 0) {
                            names = this.userList2[i].name;
                        } else {
                            names = names + "," + this.userList2[i].name;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect(id, name) {
        this.model.id = id;
        this.model.userid = id;
        this.model.name = name;
        // this.lstopportunitylst = [];
        this.servicecalllst = [];
        // this.lstopportunitylst.push({ id: 0, name: "Select Locality",userid:null,resourcename:null,enquiryno:null,date:null, customername:null,stage:null,assignlst:null,teamcode:null,chksale:null,chkservice:null,chkinstallation:null,servicecallno:null,callDate:null,callclose:null,lstopportunitylst:null,lst:null});
        this.transactionService.getOpportunityInfolst(this.model)
            .subscribe(data => {
                this.model.lstopportunitylst = data.lst;
                this.model.servicecalllst = data.lst1;
                for (let i = 0; i < this.model.lstopportunitylst.length; i++) {
                    this.model.stage = this.model.lstopportunitylst[i].stage
                }
                for (let i = 0; i < this.model.servicecalllst.length; i++) {
                    if (this.model.servicecalllst[i].callclose == true) {
                        this.model.servicecalllst[i].status = 'OpenServiceCall';
                        this.model.status = this.model.servicecalllst[i].status
                    }
                }
                this.model.installationlst = data.lst2;
                for (let i = 0; i < this.model.installationlst.length; i++) {
                    if (this.model.installationlst[i].status == '1') {
                        this.model.installationlst[i].status = 'Pending';
                        this.model.status = this.model.installationlst[i].status
                    }
                }
            });

        this.teammodel.id = this.model.teamid
        this.teammodel.customertypeid = this.model.userid;
        //   this.teammodel.typeInt.push(this.model.userid);
        this.lstassigned = [];
        this.transactionService.getAssignedtoListForReassign(this.teammodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstassigned.push({ id: item.id, name: item.name });
                });
            });


    }
    onSelectAssignTo(id) {
        if (this.model.stage == 'LEAD' || this.model.stage == 'OPPTY') {
            this.model.assignedtoID = id;
            for (let i = 0; i < this.model.lstopportunitylst.length; i++) {
                // for (let j = 0; j <= this.lstinstallation[i].lstproduct.length; j++) {
                this.model.lstopportunitylst[i].assignedtoID = this.model.assignedtoID;
                // alert(this.lstinstallation[i].lstproduct[j].assignedtoID)
                // }
            }
        }
        if (this.model.status == 'OpenServiceCall') {
            this.model.assignedtoID = id;
            for (let i = 0; i < this.model.servicecalllst.length; i++) {
                // for (let j = 0; j <= this.lstinstallation[i].lstproduct.length; j++) {
                this.model.servicecalllst[i].assignedtoID = this.model.assignedtoID;
                // alert(this.lstinstallation[i].lstproduct[j].assignedtoID)
                // }
            }
        }
        if (this.model.status == 'Pending') {
            this.model.assignedtoID = id;
            for (let i = 0; i < this.model.installationlst.length; i++) {
                // for (let j = 0; j <= this.lstinstallation[i].lstproduct.length; j++) {
                this.model.installationlst[i].assignedtoID = this.model.assignedtoID;
                // alert(this.lstinstallation[i].lstproduct[j].assignedtoID)
                // }
            }
        }

    }

    onSelectTeam(id) {
        this.model.teamid = id;
        // this.model.userid = id;
        this.transactionService.getTeamResource(this.model)
            .subscribe(data => {
                this.resourcelst = data.resourcelst
            })
    }
    getAllTeams() {
        this.teamlst = [];
        this.transactionservice.getAllTeams(this.user)
            .subscribe(value => {
                this.teamlst = value
                this.teamlst1 = value
            })
    }
    onSelectAssignToforSales(id, index) {
        this.lstopportunitylst[index].assignedtoID = id;
    }
    onSelectAssignToforService(id, index) {
        this.servicecalllst[index].assignedtoID = id;
    }
    onSelectAssignToforInstallation(id, index) {
        this.installationlst[index].assignedtoID = id;
    }
}