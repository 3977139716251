<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l12 m12 s12">

                    <div class="row" style="margin-top: 9px;">
                        <div class="col l1"></div>
                        <div class="input-field col s3 m3 l3" style="margin: 0px;">
                            <span> Customer Type</span>
                            <select (change)="onselectCustomerType($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Customer Type">
                                <option selected value="0">Select Customer Type</option>
                                <option *ngFor="let lst of custtypelst" [value]="lst.id"
                                    [selected]="lst.id==model.customerTypeId">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>

                        <div class=" input-field col s3 m3 l3" style="margin-top: 17px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.code" #code="ngModel"
                                onkeypress="return /[A-Za-z_ ]/i.test(event.key)" type="text" id="code" title="Code"
                                maxlength="35">
                            <label id="lable1" for="first1">Code </label>
                        </div>
                        <div class=" input-field col s4 m4 l4" style="margin-top: 17px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.name" #name="ngModel" type="text" id="Name" data-toggle="tooltip"
                                data-placement="top" title="Name" onkeypress="return /[A-Za-z_ ]/i.test(event.id)"
                                ngModel maxlength="35" required>
                            <label id="lable2" for="first1">Name </label>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row">
                        <div class="col l1"></div>
                        <div class=" input-field col s6 m6 l6" style="margin-top: 17px;margin-bottom: 0px;">
                            <textarea id="textarea1" title=" Address" class="materialize-textarea"
                                [(ngModel)]="model.address" #address="ngModel" id="Address"
                                style="margin-bottom: 0px;"></textarea>
                            <label id="lable3" for="textarea1">Address</label>
                        </div>
                        <div class=" input-field col s4 m4 l4" style="margin-top: 17px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.buildingName" #buildingName="ngModel" id="buildingName"
                                type="text" data-toggle="tooltip" data-placement="top" title="Building Name" required>
                            <label id="buildingName" for="buildingName">Building Name</label>
                        </div>
                        <!-- <div class="input-field col s3 m3 l3" style="margin: 0px;">
                            <span>Branch</span>
                            <select (change)="onSelectbranch($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Branch">
                                <option selected value="0">Select Branch</option>
                                <option *ngFor="let lst of branchlst" [value]="lst.id"
                                    [selected]="lst.id==model.branch">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div> -->
                        <div class="col l1"></div>
                    </div>
                    <div class="row">
                        <div class="col l1"></div>
                        <div class=" input-field col m2 s2 l2" style="margin-top: 17px;">
                            <input [(ngModel)]="model.unitNumber" #unitNumber="ngModel" id="unitNumber" type="text"
                                data-toggle="tooltip" data-placement="top" title="Unit Number" required>
                            <label id="unitNumber" for="unitNumber">Unit Number</label>
                        </div>
                        <div class=" input-field col m1 s1 l1" style="margin-top: 17px;padding-right: 0px;">
                            <input type="text" class="autocomplete" [(ngModel)]="model.pincode" id="pincode1"
                                (keyup)="getPincodeLike($event)" />
                            <input type="hidden" id="acc-id" />
                            <label class="active" for="first1">PIN Code</label>
                        </div>
                        <div class=" input-field col s3 m3 l3" style="margin: 0px;">
                            <span>Locality</span>
                            <select (change)="onSelectLocality($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Locality">
                                <option selected value="0">Select Locality</option>
                                <option *ngFor="let lst of lstlocality" [value]="lst.id"
                                    [selected]="lst.id==model.localityID">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                        <div class=" input-field col s4 m4 l4"
                            style="margin-top: 22px;font-size: 15px;padding-right: 0px;">
                            <span><b>State:</b>&nbsp;<span>{{model.state}}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;<span><b>Country:</b>&nbsp;<span>{{model.country}}</span></span>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row">
                        <div class="col l1"></div>

                        <div class=" input-field col s3 m3 l3" style="margin: 0px;">
                            <input [(ngModel)]="model.telephone1" #telephone1="ngModel" id="Telephone1" type="number"
                                data-toggle="tooltip" data-placement="top" title="telephone1" ngModel maxlength="35"
                                required>
                            <label class="active" for="first1">Primary Mobile No</label>
                        </div>
                        <div class=" input-field col s3 m3 l3" style="margin: 0px;">
                            <input [(ngModel)]="model.telephone2" #telephone2="ngModel" type="number" id="Telephone2"
                                data-toggle="tooltip" data-placement="top" title="Secondary Mobile No" ngModel
                                maxlength="35" required>
                            <label class="active" for="Telephone2">Secondary Mobile No</label>
                        </div>
                        <div class=" input-field col s4 m4 l4" style="margin: 0px;">
                            <input [(ngModel)]="model.landline" #landline="ngModel" type="number" id="landline"
                                data-toggle="tooltip" data-placement="top" title="Landline No" ngModel maxlength="35"
                                required>
                            <label class="active" for="landline">Landline No</label>
                        </div>
                        <div class="col l1"></div>
                    </div>
                    <div class="row">
                        <div class="col l1"></div>
                        <div class=" input-field col s3" style="margin-bottom: 0px;">
                            <input [(ngModel)]="model.panno" #panno="ngModel" id="panno" type="text"
                                data-toggle="tooltip" data-placement="top" title="PAN Number" ngModel maxlength="35"
                                required>
                            <label class="active" for="panno">PAN Number</label>
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 9px;">
                            <span><b>GST Type</b></span>
                            <label>
                                <input name="group1" type="radio" [checked]="model.chkwihitins"
                                    (click)="onchkwihitins($event)" />
                                <span style="font-size: 104%">Within State</span>
                            </label>&nbsp;&nbsp;
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 30px;">
                            <label>
                                <input name="group1" type="radio" [checked]="model.chkoutsides"
                                    (click)="onchkoutsides($event)" />
                                <span style="font-size: 104%">Outside State</span>
                            </label>
                        </div>
                        <div class="input-field col l3 m3 s3" style="margin-bottom: 0px;">
                            <input [(ngModel)]="model.gstinno" #gstinno="ngModel" id="gstinno" type="text"
                                data-toggle="tooltip" data-placement="top" title="GSTIN Number" ngModel maxlength="50"
                                required>
                            <label class="active" for="gstinno">GSTIN Number</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l1"></div>
                        <div class=" input-field col s3" style="margin-top: 17px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.email" #email="ngModel" id="Email " type="text"
                                data-toggle="tooltip" data-placement="top" title="email" ngModel maxlength="35"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                            <label id="lable12" for="first1">Email </label>
                            <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                                style="color: red">
                                <div *ngIf="email.errors.required">
                                    Email Required
                                </div>
                                <div [hidden]="!tcemail.errors?.pattern">
                                    Invalid Email-Id
                                </div>
                            </div>
                        </div>
                        <div class=" input-field col s3" style="margin-top: 17px;margin-bottom: 0px;">
                            <input [(ngModel)]="model.website" #website="ngModel" id="Website" type="text"
                                data-toggle="tooltip" data-placement="top" title="website" ngModel maxlength="35"
                                required>
                            <label id="lable13" for="first1">Website </label>
                        </div>
                        <div class="input-field col s4 " style="margin: 0px;">
                            <span>Constitute</span>
                            <select (change)="onSelectconstitute($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Constitute">
                                <option selected value="0">Select Constitute</option>
                                <option *ngFor="let lst of consistutelst" [value]="lst.id"
                                    [selected]="lst.id==model.constitute">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l1"></div>
                        <div class="input-field col s3 " *ngIf="chkindtrytyp==true" style="margin: 0px;">
                            <span>Industry</span>
                            <select (change)="onSelectindustry($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Industry">
                                <option selected value="0">Select Industry</option>
                                <option *ngFor="let lst of industorylst" [value]="lst.id"
                                    [selected]="lst.id==model.industry">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                        <div class="input-field col s3 " *ngIf="chkannualtrnovr==true" style="margin: 0px;">
                            <span>Annual Revenue</span>
                            <select (change)="onSelectannualrevenue($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="Annual Revenue">
                                <option selected value="0">Select Annual Revenue</option>
                                <option *ngFor="let lst of annualrevenuelst" [value]="lst.id"
                                    [selected]="lst.id==model.annualrevenue">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                        <div class="input-field col s4 " *ngIf="chknoemp==true" style="margin: 0px;">
                            <span>No. Of Employees</span>
                            <select (change)="onSelectnoofemple($event.target.value)" data-toggle="tooltip"
                                data-placement="top" title="No Of Employees">
                                <option selected value="0">Select No Of Employees</option>
                                <option *ngFor="let lst of noofemplst" [value]="lst.id"
                                    [selected]="lst.id==model.noofemployee">
                                    {{lst.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l1"></div>
                        <div class="input-field col l4 m4 s4" *ngIf="chksuperVisiorAuth">
                            <input type="text" class="autocomplete" [(ngModel)]="model.supervisiorAuth"
                                id="supervisiorAuth" (keyup)="getSuperAuthorities($event)" data-toggle="tooltip"
                                data-placement="top" title="Supervising Authority" />
                            <input type="hidden" id="acc-id" />
                            <label class="active" for="supervisiorAuth">Supervising Authority</label>
                        </div>
                        <div class="col l1"></div>
                    </div>

                    <div class="row" style="margin: 0%;">
                        <div class="right-align col l12 m12 s12" style="padding-right: 0px;">
                            <button class="waves-ripple waves-light btn" type="button" (click)="addContact()">
                                Add Contact</button>
                        </div>
                    </div>
                    <div class="row" style=" margin-top: 10px;">
                        <div class="col l12 m12 s12">
                            <div class="col l12 m12 s12" style="padding: 0px;">
                                <table>
                                    <thead style="background: gainsboro;">
                                        <th width="1%" style="text-align: center"> </th>
                                        <th width="8%" style="text-align: left">Salutation</th>
                                        <th width="25%" style="text-align: left">Contact Name</th>
                                        <th width="15%" style="text-align: left"> Designation</th>
                                        <th width="14%" style="text-align: left"> Mobile No.</th>
                                        <th width="24%" style="text-align: left"> Email</th>
                                        <th width="13%" style="text-align: right;padding-right: 10px;"> Extension No.</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of contactlst ; let i = index">
                                            <td style="text-align: center;"><input type="checkbox"
                                                    [(ngModel)]="lst.checkselect" #checkselect="ngModel">
                                            </td>
                                            <td class="input-field " style="text-align: left;">
                                                <select (change)="onSelectsalutation($event.target.value, lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Salutation">
                                                    <!-- <option selected disabled>Choose</option> -->
                                                    <option *ngFor="let type of salutationlst" [value]="type.name"
                                                        [selected]="type.name==lst.salutation">
                                                        {{type.name}} </option>
                                                </select>
                                            </td>
                                            <td style="text-align: left;"><input [(ngModel)]="lst.contactname"
                                                    #contactname="ngModel" type="text" id="Contact"
                                                    data-toggle="tooltip" data-placement="top" title="Contact Name"
                                                    placeholder="Contact name">
                                            </td>
                                            <td class="input-field " style="text-align: left;">
                                                <select (change)="onSelectdesiganation($event.target.value, lst.id)"
                                                    data-toggle="tooltip" data-placement="top" title="Designation">
                                                    <option selected value="0">Select Designation</option>
                                                    <option *ngFor="let type of designationlst" [value]="type.name"
                                                        [selected]="type.name==lst.designation">
                                                        {{type.name}} </option>
                                                </select>
                                            </td>
                                            <td style="text-align: right;"><input [(ngModel)]="lst.mobileno"
                                                    #mobileno="ngModel" type="number" min=0 id="Contact"
                                                    data-toggle="tooltip" data-placement="top" title="Mobile No"
                                                    placeholder="Mobile No">
                                            </td>
                                            <td style="text-align: left;"><input [(ngModel)]="lst.contactemail"
                                                    #contactemail="ngModel" type="text" id="Email" data-toggle="tooltip"
                                                    data-placement="top" title="Email" placeholder="Email"
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                                                <div *ngIf="contactemail.invalid && (contactemail.dirty || contactemail.touched) "
                                                    class="alert alert-danger" style="color: red">
                                                    <div *ngIf="contactemail.errors.required">
                                                        Email Required
                                                    </div>
                                                    <div [hidden]="!tcemail.errors?.pattern">
                                                        Invalid Email-Id
                                                    </div>
                                                </div>
                                            </td>
                                            <td style="text-align: right;padding-right: 10px;"><input [(ngModel)]="lst.extension"
                                                    #extension="ngModel" type="text" id="extension"
                                                    data-toggle="tooltip" data-placement="top" title="Extension No"
                                                    placeholder="Extension No" style="text-align: right">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align: right">
                            <button type="button" class="waves-ripple cancelbutton btn" style="margin-left: 5px;"
                                (click)="btncancle()">Cancel</button>&nbsp;&nbsp;
                            <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                                style="margin-left: 5px;">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>