import { Component, OnInit, TemplateRef } from '@angular/core';
import { MasterServices, AlertService, TransactionServices, MenuServices } from 'src/app/_services';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { User } from 'src/app/_models';
import { SearchUserGroupModel } from './SearchUserGroupModel';
import { Location, DatePipe } from '@angular/common'
import { BsModalRef } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';

// import { usertwofieldModel } from './usertwofieldModel';

@Component({
    selector: 'app-login',
    templateUrl: 'SearchUserGroup.html'
})
export class SearchUserGroup implements OnInit {
    p:any;
    model: any = {};
    public i: any = 0;
    grouplst: SearchUserGroupModel[] = [];
    grouplst1: SearchUserGroupModel[] = [];

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Group" },

    ];
    modalRef: BsModalRef;

    constructor(
        private route: ActivatedRoute,
        private menuServices: MenuServices,
        private notifyService: NotificationService,
        private modalService: BsModalService,
        private router: Router, private location: Location,) {
    }
    ngOnInit() {
        this.getAllgroup();
    }
    getAllgroup() {
        this.menuServices.getAllgroup(this.model)
            .subscribe(value => {
                this.grouplst = value;
                this.grouplst1 = [];
                value.forEach((item) => this.grouplst1.push({ "id": item.id, "groupname": item.groupname, "srno": item.srno, "updategroupname": item.updategroupname }));
                this.grouplst = this.grouplst1;
            })

    }
    btnedit(id, name, usergroup: TemplateRef<any>) {
        this.model.id = id;
        this.model.updategroupname = name;
        this.modalRef = this.modalService.show(usergroup, {
            class: 'modal-lg'
        });
        let l1 = (<HTMLInputElement>document.getElementById('lable1'));
        l1.className = "active";

    }

    btnnew(usergroup: TemplateRef<any>) {
        this.model.id = null;
        this.model.updategroupname = null;
        this.modalRef = this.modalService.show(usergroup, {
            class: 'modal-lg'
        });
    }
    btndelet(id) {
        this.model.id = id;
        this.i = confirm("Do you want to delete this record ?");
        if (this.i == true) {
            this.menuServices.deletegroup(this.model)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, "Done");
                        this.ngOnInit()
                    }
                })
        } else {
            alert("You have selected cancel...");
        }
    }

    btnadd() {
        if (this.model.updategroupname == null || this.model.updategroupname == "") {
            this.notifyService.showRroor("Enter User Group", "oops");
            this.modalRef.hide();
            return;
        }
        this.menuServices.SaveUserGroup(this.model)
            .subscribe(value => {
                if (value.status == "OK") {
                    this.notifyService.showSuccess(value.message, "Done");
                    this.modalRef.hide();
                    this.ngOnInit()
                }
                if (value.status == "NO") {
                    this.notifyService.showRroor(value.message, "Oops");
                }
            })
    }

    btnmenu(id) {
        let navigationExtras: NavigationExtras = {
            queryParams: {
                'id': id,
                'from': "group"
            }
        };
        this.router.navigate(['contextmenue'], navigationExtras);
    }
    onSelect(value) {
        this.model.txtselect = value;
    }
     btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllgroup()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Group") {
    
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.grouplst1 = [];
                this.grouplst.forEach((item) => {
                    if ((item.groupname.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.grouplst1.push({"id": item.id, "groupname": item.groupname, "srno": item.srno, "updategroupname": item.updategroupname});
                    }
                });
                this.grouplst = this.grouplst1;
            }
        }
     
    
    }
}