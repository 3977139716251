import { OnInit, Component } from '@angular/core';
import { User } from 'src/app/_models';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { LocalityModel } from './LocalityModel';
import { DropDownModel } from 'src/app/_models/DropDownModel';
@Component({
    selector: 'app-localS',
    //  moduleId: module.id,
    templateUrl: 'SearchLocality.html'
})
export class SearchLocality implements OnInit {
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
        this.pagename = this.router.url
    }
    p: any;
    infiniteScrollDistance: any;
    infiniteScrollThrottle: any;
    user = new User();
    model = new LocalityModel();
    localitylst: LocalityModel[] = [];
    localitylst1: LocalityModel[] = [];
    public i;
    public j: any = 0;
    // public Curent: any = 10;
    sum: number = 1;
    totalItems: any;
    pagename: string;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Locality" },
        { id: 3, "name": "Pincode" },
        { id: 4, "name": "City" }
    ];
    ngOnInit(): void {

        this.model.userid = Number(localStorage.getItem("userid"));
        this.user.userid = Number(localStorage.getItem("userid"));
        localStorage.setItem("pagename", this.pagename);
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        //  this.getLocalities(0, 10);
        // this.user.pageno = 0;
        // this.user.pageSize = 10;
        this.getLocalities();
    }
    // getLocalities(start, end) {
    // onScroll() {
    //     this.sum = this.sum + 1;
    //     this.user.pageno = this.sum + 1;
    //     this.user.pageSize = 20;
    //     this.getLocalities();
    // }
    getLocalities() {
        this.localitylst = [];
        // this.user.start = start
        // this.user.end = end;
        this.user.pageno = this.sum;
        this.user.totalItems;
        this.Masterservice.getLocalities(this.user)
            .subscribe(value => {
                this.localitylst = value.localitylst;
                this.localitylst1 = value.localitylst;
                this.user.totalItems = value.totalItems;
                // value.forEach((item) =>
                //     this.localitylst.push({
                //         "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "locality": item.locality,
                //         "cityid": item.cityid, "countryid": null, "stateid": null, "userid": null, "localeid": null, "pincode": item.pincode,
                //         "city": item.city, "state": item.state, "country": item.country, "srno": item.srno, "totalItems": item.totalItems
                //     }));
                this.localitylst1 = this.localitylst;
                if (this.localitylst.length == 0) {
                    this.localitylst = this.localitylst1;
                }
            });
    }

    onSelectSrch(val) {
        this.model.txtselect = val;
    }
    // btngetprev() {
    //     this.j = this.Curent - 20;
    //     this.Curent = this.Curent - 10;
    //     this.getLocalities(this.j, this.Curent);
    // }
    // btngetNext() {
    //     this.j = this.Curent
    //     this.Curent = this.Curent + 10;
    //     this.getLocalities(this.j, this.Curent);
    // }
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            // this.getLocalities(0, 10);
            this.getLocalities();
            this.model.txtserch = null;
        } else {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                this.notifyService.showRroor("Enter Criteria To Search", "Oops");
                return;
            }
            this.user.message = this.model.txtselect
            this.user.sendstring = this.model.txtserch
            this.user.pageno = this.sum;
            this.Masterservice.getLocalitiesSearchLike(this.user)
                .subscribe(value => {
                    this.localitylst = value;
                    this.localitylst1 = value
                    for (let i = 0; i < this.localitylst.length; i++) {
                        this.user.totalItems = this.localitylst[10].totalItems;
                    }
                });
        }
        // else if (this.model.txtselect == "Code") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.localitylst1 = [];
        //         this.localitylst.forEach((item) => {
        //             if ((item.code.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.localitylst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "locality": item.locality, "cityid": item.cityid, "countryid": null, "stateid": null, "userid": null, "localeid": null, "pincode": null, "city": item.city,"state": item.state,"country": item.country});
        //             }
        //         });
        //         this.localitylst = this.localitylst1;
        //     }
        // }
        // else if (this.model.txtselect == "Name") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.localitylst1 = [];
        //         this.localitylst.forEach((item) => {
        //             if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.localitylst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "locality": item.locality, "cityid": item.cityid, "countryid": null, "stateid": null, "userid": null, "localeid": null, "pincode": null, "city": item.city,"state": item.state,"country": item.country });
        //             }
        //         });
        //         this.localitylst = this.localitylst1;
        //     }
        // } else if (this.model.txtselect == "Locality") {
        //     if (this.model.txtserch == null || this.model.txtserch == "") {
        //         alert("Enter Criteria To Search")
        //         return;
        //     }
        //     else {
        //         this.localitylst1 = [];
        //         this.localitylst.forEach((item) => {
        //             if ((item.locality.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
        //                 this.localitylst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": null, "txtselect": null, "locality": item.locality, "cityid": item.cityid, "countryid": null, "stateid": null, "userid": null, "localeid": null, "pincode": null, "city": item.city,"state": item.state,"country": item.country});
        //             }
        //         });
        //         this.localitylst = this.localitylst1;
        //     }
        // }
    }

    btnnew() {
        this.router.navigate(['/newLocality']);
    }

    btnedit(val) {
        this.router.navigate(['/editLocality', val]);
    }

    btndelts(val) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {

            this.user.trnid = val
            this.Masterservice.deleteLocality(this.user)
                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, " Success");
                        this.ngOnInit()
                    }
                });
        }

    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    showPageIndex(pageIndex) {
        this.sum = pageIndex;
        if (this.model.txtselect == null && this.model.txtserch == null) {
            this.getLocalities();
        } else {
            if (this.model.txtselect == "All") {
                // this.getLocalities(0, 10);
                this.getLocalities();
                this.model.txtserch = null;
            } else {
                if (this.model.txtserch == null || this.model.txtserch == "") {
                    this.notifyService.showRroor("Enter Criteria To Search", "Oops");
                    return;
                }
                this.user.message = this.model.txtselect
                this.user.sendstring = this.model.txtserch
                this.user.pageno = this.sum;
                this.Masterservice.getLocalitiesSearchLike(this.user)
                    .subscribe(value => {
                        this.localitylst = value;
                        this.localitylst1 = value
                        for (let i = 0; i < this.localitylst.length; i++) {
                            this.user.totalItems = this.localitylst[10].totalItems;
                        }
                    });
            }
        }

    }
}