import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { DealersDetailModel } from "src/app/DealersDetail/DealersDetailModel";
import { SearchDealerModel } from "src/app/DealersDetail/SearchDealerModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService, DealersService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchVendorModel } from "./SearchVendorModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'SearchVendor.html'
})
export class SearchVendor implements OnInit {
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private dealersService: DealersService) {
    }
    public i;
    p: any;
    id: number;
    user = new User();
    model = new SearchVendorModel();
    dmodel = new SearchDealerModel();
    contactlst: SearchVendorModel[] = [];
    contactlst1: SearchVendorModel[] = [];
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Vendor Code" },
        { id: 3, "name": "Vendor Name" },
    ];
    ngOnInit(): void {
        this.getAllVendorDetails();
    }
    getAllVendorDetails() {
        this.user.message = "V";
        this.dealersService.getAllVendorDetails(this.user)
            .subscribe(value => {
                this.contactlst = value;
                this.contactlst1 = [];
                value.forEach((item) => this.contactlst1.push({ "id": item.id, "code": item.code, "name": item.name, "txtserch": item.txtserch, "txtselect": item.txtselect }));
                this.contactlst = this.contactlst1;
            });
    }
    onSelectsearch(value) {
        this.dmodel.txtselect = value;
    }
    btnsearch($event) {
        if (this.dmodel.txtselect == "All") {
            this.getAllVendorDetails()
            this.dmodel.txtserch = null;
        }
        else if (this.dmodel.txtselect == "Vendor Code") {

            if (this.dmodel.txtserch == null || this.dmodel.txtserch == "") {
                alert("Enter Vendor Code to Search")
                return;
            }
            else {
                this.contactlst1 = [];
                this.contactlst.forEach((item) => {
                    if ((item.code.toLowerCase()).match(this.dmodel.txtserch.toLowerCase().trim())) {
                        this.contactlst1.push({ "id": item.id, "code": item.code, "name": item.name, "txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.contactlst = this.contactlst1;
            }
        }
        else if (this.dmodel.txtselect == "Vendor Name") {
            if (this.dmodel.txtserch == null || this.dmodel.txtserch == "") {
                alert("Enter Vendor Name To Search")
                return;
            }
            else {

                this.contactlst1 = [];
                this.contactlst.forEach((item) => {
                    if ((item.name.toLowerCase()).match(this.dmodel.txtserch.toLowerCase().trim())) {
                        this.contactlst1.push({ "id": item.id, "name": item.name, "code": item.code, "txtserch": item.txtserch, "txtselect": item.txtselect });
                    }
                });
                this.contactlst = this.contactlst1;
            }
        }
    }
    btnadd($event) {
        this.router.navigate(['/newDefineVendor']);
    }
    btnedit(id) {
        this.router.navigate(['/editDefineVendor', id]);
    }
    btndelts(id) {
        this.dmodel.id = id
        this.i = confirm("Do you want to delete this record ?");
        if (this.i == true) {
            this.dealersService.deletedealersdetail(this.dmodel)

                .subscribe(value => {
                    if (value.status == "OK") {
                        this.notifyService.showSuccess(value.message, "Delete Successfully !");
                        this.ngOnInit()
                    }
                });

        }
    }
}