import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';
import { common } from './Common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../_models';
import { Observable } from 'rxjs';
import { DropDownModel } from '../_models/DropDownModel';
import { PoModel, GoodsNoteDefineModel } from '../InventoryManagement/GoodsNote/GoodsNoteDefineModel';
import { SaveSuccessModel } from '../_models/SaveSucessModel';
import { GoodsNoteHomeModel } from '../InventoryManagement/GoodsNote/GoodsNoteHomeModel';
import { SerachPurchaseReturnModel } from '../InventoryManagement/PurchaseReturn/SearchPurchaseReturnModel';
import { DefinePurchaseReturnModel } from '../InventoryManagement/PurchaseReturn/DefinePurchaseReturnModel';

@Injectable()
export class InventoryServices {
   
    private handleError: HandleError;
    private url: String = common.s1;
    private customersUrl = this.url;
    getCollectionofLibrary: any;
    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {

    }

    getVendorNameLike(user: User): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/inventory/getVendorNameLike', user, httpOptions1);
    }

    getAllOpenPOBySupplierid(user: User): Observable<PoModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<PoModel[]>(this.customersUrl + 'api/inventory/getAllOpenPOBySupplierid', user, httpOptions1);
    }

    getAllDataOfPO(user: User): Observable<GoodsNoteDefineModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<GoodsNoteDefineModel>(this.customersUrl + 'api/inventory/getAllDataOfPO', user, httpOptions1);
    }

    getGRNbyGrnid(user: User): Observable<GoodsNoteDefineModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<GoodsNoteDefineModel>(this.customersUrl + 'api/inventory/getGRNbyGrnid', user, httpOptions1);
    }

    getAllItemsBySubcatid(user: User): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/inventory/getAllItemsBySubcatid', user, httpOptions1);
    }

    saveGRN(model: GoodsNoteDefineModel): Observable<SaveSuccessModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/inventory/saveGRN', model, httpOptions1);
    }

    getAllGrns(user: User): Observable<GoodsNoteHomeModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<GoodsNoteHomeModel[]>(this.customersUrl + 'api/inventory/getAllGrns', user, httpOptions1);
    }

    saveGIN(model: GoodsNoteDefineModel): Observable<SaveSuccessModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<SaveSuccessModel>(this.customersUrl + 'api/inventory/saveGIN', model, httpOptions1);
    }

    getAllReturns(user: User): Observable<SerachPurchaseReturnModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<SerachPurchaseReturnModel[]>(this.customersUrl + 'api/inventory/getAllReturns', user, httpOptions1);
    }
    getSupplierNameForReturn(user: User): Observable<DropDownModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DropDownModel[]>(this.customersUrl + 'api/inventory/getSupplierNameForReturn', user, httpOptions1);
    }

    getAllItemsOfSupplier(user: User): Observable<DefinePurchaseReturnModel> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<DefinePurchaseReturnModel>(this.customersUrl + 'api/inventory/getAllItemsOfSupplier', user, httpOptions1);
    }
    viewGRN(user: User): Observable<GoodsNoteHomeModel[]> {
        const httpOptions1 = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Token'),
            })
        };
        return this.http.post<GoodsNoteHomeModel[]>(this.customersUrl + 'api/inventory/viewGRN', user, httpOptions1);
    }

    


}