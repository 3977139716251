import { TemplateRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AreaModel1 } from "src/app/Transactions/Teams/TeamModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel";
import { InstallationSummaryModel } from "./InstallationSummaryModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'InstallationSummary.html'
})
export class InstallationSummary implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {
            
        }
    model = new InstallationSummaryModel();
    dmodel = new DCRreportModel();
    user = new User();
    regionlst: DropDownModel[] = [];
    arealst: AreaModel1[] = [];
    resourcelst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    companyname: string;
    companyaddress: string;

    id: number;
    inslst: InstallationSummaryModel[] = [];

    regionName: string;
    areaName: string;
    branchName: string;
    resourceName: string;
    username: string;
    timing = new Date();

    // public searchtype: DropDownModel[] = [
    //     { id: 1, "name": "Unassigned / open" },
    //     { id: 2, "name": "Installed" },
    //     { id: 3, "name": "In progress" },
    // ];

    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.areaName = localStorage.getItem('areaName');
        this.regionName = localStorage.getItem('regionName');
        this.branchName = localStorage.getItem('branchName');
        var getObj = JSON.parse(localStorage.getItem('testObject'));
        this.model = getObj;
        this.btnshow();
    }
 
    btnshow() {

        this.reportServices.showInstaSummaryReport(this.model)
            .subscribe(
                data => {
                    this.inslst = data;
                    for (let i = 0; i < this.inslst.length; i++) {
                        this.companyname = this.inslst[0].companyname
                        this.companyaddress = this.inslst[0].companyaddress;
                        if (this.inslst[i].instaStatus == 'Y') {
                            this.inslst[i].instaStatus = "Installed"
                        } else if (this.inslst[i].instaStatus == 'N') {
                            this.inslst[i].instaStatus = "In progress"
                        }
                    }
                });
    }

    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        //WindowObject.close();
    }
    btnback() {
        this.router.navigate(["/InstallationSummaryReport"]);
    }
}

