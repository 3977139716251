import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { Products } from "src/app/Transactions/DailyCall/DailyCallModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { User } from "src/app/_models/user";
import { MasterServices, CompanyService, AlertService, DealersService, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { DefineServiceChargesModel } from "./DefineServiceChargesModel";
declare var $: any;
@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DefineServiceCharges.html'
})
export class DefineServiceCharges implements OnInit {
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private dealersService: DealersService, private transactionService: TransactionServices) {
        this.pagename = this.router.url;
    }
    p: any;
    sub: any;
    id: number;
    productid: number;
    userList2: any;
    userList3: any;
    uommap = new Map();
    typemap = new Map();
    itemcodemap = new Map();
    descriptionmap = new Map();
    currentmap = new Map();
    mrpmap = new Map();
    gstgrppercentage = new Map();
    gstheaderID = new Map();
    pagename: string;
    user = new User();
    model = new DefineServiceChargesModel();
    productcatelst: DropDownModel[] = [];
    spareProductcatelst: DropDownModel[] = [];
    // itmCatlst: DropDownModel[] = [];
    // subCatlst: DropDownModel[] = [];
    itemlst: DefineServiceChargesModel[] = [];
    servicelst: DefineServiceChargesModel[] = [];
    servicelst1: DefineServiceChargesModel[] = [];
    keyword1 = 'productName';
    keywordproduct = 'spareProName';
    productlst: Products[] = [];
    ngOnInit(): void {
        this.model.userID = Number(localStorage.getItem("userid"));
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.getProductcat();
        this.getSpareListCat();
        // this.getitemCategory();

        this.sub = this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.model.productid = this.id;
            this.Masterservice.getServiceChargesByid(this.model)
                .subscribe(
                    data => {
                        this.model.selectall = false;
                        this.model = data;
                        this.itemlst = data.itemlst

                    },
                    error => {
                        this.alertService.error(error);
                    });
        }
    }

    getProductcat() {

        this.Masterservice.getProductcat(this.user)
            .subscribe(data => {
                this.productcatelst = data.procategorylst;
            });
    }
    onSelectcategory(value) {
        this.model.productcategoryid = value;

        for (let i = 0; i < this.productcatelst.length; i++) {
            if (this.productcatelst[i].id == value) {
                this.model.prodCatname = this.productcatelst[i].name
            }
        }
        if (this.model.productName != null) {
            this.userList2 = [];
            this.model.productName = this.userList2;
        }

    }

    getProductLike() {

        let userId = (<HTMLInputElement>document.getElementById('productname1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.productcategoryId = this.model.productcategoryid;
        var obj = this;
        if (userId.length >= 3) {
            this.dealersService.getProductLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "productName": item.productName, "proid": item.proid }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].productName, this.userList2[i].proid)
                        this.currentmap.set(this.userList2[i].productName, this.userList2[i].currencyName)
                        this.mrpmap.set(this.userList2[i].productName, this.userList2[i].mrp)
                        searchDataObj[this.userList2[i].productName] = null;
                        if (i == 0) {
                            names = this.userList2[i].productName;
                        } else {
                            names = names + "," + this.userList2[i].productName;
                        }
                    }
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);

                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);

                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect1(val1, val2) {
        this.model.proid = val1;
        this.model.productName = val2;
    }
    getProductLikeSpareItem() {

        let userId = (<HTMLInputElement>document.getElementById('productname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        // alert(this.model.spareCategoryid)
        this.user.spareCategoryid = this.model.spareCategoryid;
        // alert( this.user.spareCategoryid)
        var obj = this;
        if (userId.length >= 3) {
            this.Masterservice.getProductLikeSpareItem(this.user)
                .subscribe(data => {

                    data.forEach((item) => this.userList2.push({ "spareProName": item.spareProName, "productid": item.productid, "uom": item.uom, "rate": item.rate, "description": item.description, "type": item.type, "itemcode": item.itemcode, "gstgrppercentage": item.gstGrouppercentage, "gstheaderID": item.gstheaderID }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].spareProName, this.userList2[i].productid)
                        this.uommap.set(this.userList2[i].spareProName, this.userList2[i].uom)
                        this.mrpmap.set(this.userList2[i].spareProName, this.userList2[i].rate)
                        this.typemap.set(this.userList2[i].spareProName, this.userList2[i].type)
                        this.descriptionmap.set(this.userList2[i].spareProName, this.userList2[i].description)
                        this.itemcodemap.set(this.userList2[i].spareProName, this.userList2[i].itemcode)
                        this.gstgrppercentage.set(this.userList2[i].spareProName, this.userList2[i].gstgrppercentage)
                        this.gstheaderID.set(this.userList2[i].spareProName, this.userList2[i].gstheaderID)
                        searchDataObj[this.userList2[i].spareProName] = null;
                        if (i == 0) {
                            names = this.userList2[i].spareProName;
                        } else {
                            names = names + "," + this.userList2[i].spareProName;
                        }
                    }
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);

                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);

                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect(val1, val2) {
        this.model.productid = val1;
        this.model.spareProName = val2;
        this.model.rate = this.mrpmap.get(val2);
        this.model.uom = this.uommap.get(val2);
        this.model.type = this.typemap.get(val2);
        this.model.description = this.descriptionmap.get(val2);
        this.model.itemcode = this.itemcodemap.get(val2);
        this.model.gstGrouppercentage = this.gstgrppercentage.get(val2);
        this.model.gstheaderID = this.gstheaderID.get(val2);
        for (let i = 0; i < this.itemlst.length; i++) {
            if (this.itemlst[i].productid == this.model.productid) {
                this.notifyService.showRroor("Product already present", " Oops");
                return;
            }
        }
        // alert(this.model.selectall);
        // if (this.model.selectall == false) {
            this.itemlst.push({
                id: null,
                productcategoryid: this.model.productcategoryid,//
                prodCatname: this.model.prodCatname,//
                productName: this.model.productName,//
                productid: this.model.productid,//
                itemcategoryid: null,
                itemsubcategoryid: null,
                itemcategory: null,
                itemsubcategory: null,
                chklist: true,
                itemmasterid: null,
                proid: this.model.proid,
                type: this.model.type,
                itemcode: this.model.itemcode,
                description: this.model.description,
                uom: this.model.uom,
                quantity: 1,
                rate: this.model.rate,
                userID: null,
                spareName: this.model.spareName,//
                spareCategoryid: this.model.spareCategoryid,
                spareCategoryName: this.model.spareCategoryName,
                spareProName: this.model.spareProName,
                gstGrouppercentage: this.model.gstGrouppercentage,
                servicelst: null,
                gstheaderID: this.model.gstheaderID,
                itemlst: null,
                selectall: null

            })
        // } else if (this.model.selectall == true) {
        //     for (let i = 0; i < this.productlst.length; i++) {
        //         this.itemlst.push({
        //             id: null,
        //             productcategoryid: this.model.productcategoryid,//
        //             prodCatname: this.model.prodCatname,//
        //             productName: this.model.productName,//
        //             productid: this.productlst[i].productid,//
        //             itemcategoryid: null,
        //             itemsubcategoryid: null,
        //             itemcategory: null,
        //             itemsubcategory: null,
        //             chklist: true,
        //             itemmasterid: null,
        //             proid: this.model.proid,
        //             type: this.productlst[i].type,
        //             itemcode: this.model.itemcode,
        //             description: this.productlst[i].description,
        //             uom: this.productlst[i].uom,
        //             quantity: 1,
        //             rate: this.productlst[i].rate,
        //             userID: null,
        //             spareName: this.model.spareName,//
        //             spareCategoryid: this.model.spareCategoryid,
        //             spareCategoryName: this.model.spareCategoryName,
        //             spareProName: this.productlst[i].spareProName,
        //             gstGrouppercentage: this.model.gstGrouppercentage,
        //             servicelst: null,
        //             gstheaderID: this.model.gstheaderID,
        //             itemlst: null,
        //             selectall: null
    
        //         })

        //     }
        // }
    }
    getSpareListCat() {
        this.Masterservice.getSpareListCat(this.user)
            .subscribe(data => {
                this.spareProductcatelst = data.procategorylst;
            });
    }
    onSelectSparecategory(value) {
        this.model.spareCategoryid = value;

        for (let i = 0; i < this.spareProductcatelst.length; i++) {
            if (this.spareProductcatelst[i].id == value) {
                this.model.spareName = this.spareProductcatelst[i].name
            }

        }
        if (this.model.spareProName != null) {
            this.userList2 = [];
            this.model.spareProName = this.userList2;
        }

        this.productlst = [];
        this.user.trnid = value;
        this.transactionService.getProductsByISC(this.user)
            .subscribe(
                data => {
                    this.productlst = data
                });
    }
    // onSelectitemCat(value) {  p
    //     let user = new User();
    //     this.subCatlst = [];
    //     this.model.itemcategoryid = value;
    //     for (let i = 0; i < this.itmCatlst.length; i++) {
    //         if (this.itmCatlst[i].id == value) {
    //             this.model.itemcategory = this.itmCatlst[i].name
    //         }
    //     }
    //     user.trnid = value
    //     //alert(value);
    //     this.Masterservice.getSubCategorychked(user)
    //         .subscribe((value) => {
    //             this.subCatlst = value
    //         });
    // }
    // onSelectSubCat(value) {
    //     this.model.itemsubcategoryid = value;

    //     for (let i = 0; i < this.subCatlst.length; i++) {
    //         if (this.subCatlst[i].id == value) {
    //             this.model.itemsubcategory = this.subCatlst[i].name
    //         }
    //     }

    //     this.Masterservice.getProductItemBysubCatid(this.model)
    //         .subscribe(value => {

    //             this.servicelst = value
    //             this.servicelst1 = [];
    //             value.forEach((item) => this.servicelst1.push({ "id": item.id, "userID": item.userID, "productcategoryid": item.productcategoryid, "prodCatname":item.prodCatname,"productid": item.productid, "productName": item.productName, "itemcategoryid": item.itemcategoryid, "itemsubcategoryid": item.itemsubcategoryid, "itemcategory": item.itemcategory, "itemsubcategory": item.itemsubcategory, "chklist": item.chklist, "type": item.type, "itemcode": item.itemcode, "description": item.description, "uom": item.uom, "quantity": item.quantity, "rate": item.rate, "itemmasterid": item.itemmasterid, "servicelst": item.servicelst }));
    //             this.servicelst = this.servicelst1;
    //         })
    // }
    // getitemCategory() {
    //     let user = new User();
    //     this.itmCatlst = [];
    //     this.Masterservice.getitemCategory(user)
    //         .subscribe((value) => {
    //             this.itmCatlst = value
    //         });
    // } p


    onchkselect(id, $event) {
        if ($event.target.checked) {
            // alert(this.servicelst1.length)
            for (let i = 0; i < this.servicelst1.length; i++) {
                // if (this.servicelst1[i].itemmasterid == id) {
                //     this.servicelst.push({
                //         "id": null,
                //         "userID": null,
                //         "productcategoryid": null,
                //         "productid": null,
                //         "productName": null,
                //         "itemcategoryid": null,
                //         "itemsubcategoryid": null,
                //         "itemcategory": null,
                //         "itemsubcategory": null,
                //         "chklist": null,
                //         "type": this.servicelst1[i].type,
                //         "itemcode": this.servicelst1[i].itemcode,
                //         "description": this.servicelst1[i].description,
                //         "uom": this.servicelst1[i].uom,
                //         "quantity": null,
                //         "rate": this.servicelst1[i].rate,
                //         "itemmasterid": this.servicelst1[i].itemmasterid,
                //         "servicelst": this.servicelst1[i].servicelst
                //     })
                // }
            }
        }
    }
    cancle() {
        this.router.navigate(["/Service Charges Template"]);
    }
    btnsave($event) {

        if (this.model.productcategoryid == null) {
            this.notifyService.showRroor("Please Select Sales Category  !!", "Oops!");
            return;
        }
        if (this.model.productName == null) {
            this.notifyService.showRroor("Please Enter Product Name  !!", "Oops!");
            return;
        }
        // if (this.model.itemcategoryid == null) {
        //     this.notifyService.showRroor("Please Select Item Category  !!", "Oops!");
        //     return;
        // }
        // if (this.model.itemsubcategoryid == null) {
        //     this.notifyService.showRroor("Please Select Item Sub Category  !!", "Oops!");
        //     return;
        // }

        // if (this.model.servicelst == null) {
        //     this.notifyService.showRroor("Please Add At Least One Item  !!", "Oops!");
        //     return;
        // }
        this.model.itemlst = this.itemlst;
        this.Masterservice.saveServiceCharges(this.model)
            .subscribe(
                data => {

                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(['Service Charges Template']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    //For Autocomplete
    onChangeSearchproduct(search: string) {
        this.user.message = search;
        //  this.promodel.productid = this.productid;
        this.user.productcategoryId = this.model.productcategoryid;
        this.dealersService.getProductLike(this.user)
            .subscribe(data => {
                this.userList2 = [];
                data.forEach((item) => this.userList2.push({ "productcatname": item.productcatname, "productName": item.productName, "productid": item.productid, "currencyName": item.currencyName, "mrp": item.mrp, "description": item.description, "serialnoFlag": item.serialnoFlag }));

            });

    }
    onChangeSearchproduct1(searchproduct: string) {

        this.user.message = searchproduct;
        //  this.promodel.productid = this.productid;
        this.user.spareCategoryid = this.model.spareCategoryid;
        this.Masterservice.getProductLikeSpareItem(this.user)
            .subscribe(data => {
                this.userList3 = [];
                data.forEach((item) => this.userList3.push({ "spareProName": item.spareProName, "productid": item.productid, "uom": item.uom, "rate": item.rate, "description": item.description, "type": item.type, "itemcode": item.itemcode }));



            });




    }
    selectEvent(item) {
        this.model.productid = item.productid;
        this.model.spareProName = item.spareProName;
        this.model.rate = item.rate;
        this.model.uom = item.uom;
        this.model.type = item.type;
        this.model.description = item.description;
        this.model.itemcode = item.itemcode;
        this.itemlst.push({
            id: null,
            productcategoryid: this.model.productcategoryid,//
            prodCatname: this.model.prodCatname,//
            productName: this.model.productName,//
            productid: this.model.productid,//
            itemcategoryid: null,
            itemsubcategoryid: null,
            itemcategory: null,
            itemsubcategory: null,
            chklist: true,
            itemmasterid: null,
            proid: this.model.proid,
            type: this.model.type,
            itemcode: this.model.itemcode,
            description: this.model.description,
            uom: this.model.uom,
            quantity: null,
            rate: this.model.rate,
            userID: null,
            spareName: this.model.spareName,//
            spareCategoryid: this.model.spareCategoryid,
            spareCategoryName: this.model.spareCategoryName,
            spareProName: this.model.spareProName,
            gstGrouppercentage: this.model.gstGrouppercentage,
            gstheaderID: this.model.gstheaderID,
            servicelst: null,
            itemlst: null,
            selectall: null

        })



    }
    selectEvent1(item) {
        this.model.proid = item.productid
        this.model.productName = item.productName;
    }

    onFocusedforproduct(e) {
        // do something
    }
    onFocusedforproduct1(e) {
        // do something
    }
    checkall($event) {

        if ($event.target.checked) {
            for (let i = 0; i < this.itemlst.length; i++) {
                this.itemlst[i].chklist = true;
            }
        }
        else {
            this.uncheckall();
        }




    }
    uncheckall() {
        for (let i = 0; i < this.itemlst.length; i++) {
            this.itemlst[i].chklist = false;

        }
    }
    selectallproduct($event) {
        if ($event.target.checked) {
            this.model.selectall = true;
        }
    }
}