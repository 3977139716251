// import { DatePipe } from "@angular/common";
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "src/app/_models";
import { AuthenticationService, InstallationService, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { Definethreefieldmodel } from "src/app/_models/Definethreefieldmodel";
import { TeamModel } from "src/app/Transactions/Teams/TeamModel";
import { contactmodel } from "src/app/Transactions/Sales-Transaction/ProspectModel";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InvoiceModel } from "src/app/Transactions/DispatchOrder/DispatchOrderHomeModel";
import * as moment from 'moment';
import { DeviceDetectorService } from "ngx-device-detector";
import { Mainmenumodel } from "src/app/_models/Mainmenumodel";
@Component({
    selector: 'app-order',
    templateUrl: 'InstallationDCR.html'
})
export class InstallationDCR implements OnInit {
    now: string;
    user = new User();
    model: any = {};
    public p: any;
    sub: any;
    dcrstarttime: any;
    action: string;
    resendOTP: boolean = false;
    changeActivity: boolean = true;
    modelc: contactmodel[] = [];
    id: number
    currentDate = new Date();
    invModel = new InvoiceModel();
    modalRef: BsModalRef;
    lstactivity: Definethreefieldmodel[] = [];
    teammodel = new TeamModel();
    activitylst: Definethreefieldmodel[] = [];
    lstassigned: DropDownModel[] = [];
    allcomplete: boolean = false;
    userid: number;
    flag2: boolean = false;
    isMobile: boolean;
    deviceInfo = null;
    mainmenus: Mainmenumodel[] = [];
    username: string;
    showOtp: boolean = false;
    showBtn: boolean = false;
    constructor(private route: ActivatedRoute,
        private router: Router,
        private notifyService: NotificationService,
        private transactionservice: TransactionServices,
        private installationService: InstallationService,
        private deviceService: DeviceDetectorService,
        private authenticationService: AuthenticationService) {

    }
    ngOnInit(): void {
        this.mainmenus = JSON.parse(localStorage.getItem('menu'));
        this.username = localStorage.getItem('fullname');

        console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        // console.log(this.deviceInfo);
        // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        // console.log(isDesktopDevice);
        this.route.queryParams.subscribe(params => {
            this.id = + params["id"];

        });
        // setInterval(() => {
        //     this.model.dcrstarttime = new Date().toString().split(' ')[4];
        //   }, 1);
        this.model.userid = Number(localStorage.getItem("userid"));
        this.userid = Number(localStorage.getItem("userid"));
        if (this.id != null && this.id > 0) {
            this.user.trnid = this.id;
            this.user.userid = this.model.userid;
            this.installationService.GetinstallationDCRdetails(this.user)
                .subscribe(data => {

                    this.model = data;
                    this.model.lstproduct = data.lstproduct
                    this.model.unitName = data.unit;
                    this.model.buildingName = data.buildingName;
                    this.model.grandtotal = data.grandtotal;
                    this.model.dcrdate = new Date();
                    this.model.followupdate = new Date();
                    if (this.model.lstcontact.length == 1) {
                        this.model.lstcontact.forEach(element => {
                            element.checkselectcon = true;

                            //this.model.checkselectcon = true;
                        });
                    }
                    this.model.dcrstarttime = new Date().toString().split(' ')[4];
                    this.model.lstproduct.forEach(productelement => {
                        if (productelement.warrantyenddate == null) {
                            var date1 = new Date(productelement.installtiondate)
                            productelement.warrantyenddate = new Date(date1.setMonth(date1.getMonth() + productelement.warrantyMonth))
                        }
                    });
                    if (this.model.lstproduct != null) {
                        this.model.chkall = true;
                        this.model.lstproduct.forEach(element => {
                            element.completflag = true;
                        });
                        // this.model.lstproduct.completflag = true;
                        // this.btnselectAllProduct($event);
                    }
                    this.model.lstcontact.forEach(element => {
                        this.model.mobile = element.contactmobile;
                    });
                });
        }
        this.getallactivity();

    }
    getallactivity() {
        this.lstactivity.push({ id: 0, name: "Select Activity", code: "" });
        this.user.statusCode = "I";
        this.transactionservice.getallactivity(this.user)
            .subscribe(data => {
                data.forEach(item => {
                    if (item.code == "In") {
                        this.lstactivity.push({ id: item.id, name: item.name, code: item.code })
                    }
                });
            });
        this.activitylst = this.lstactivity
    }


    getAssignedto(id) {
        this.teammodel.areaid = id;
        this.teammodel.pcInt = [];
        this.model.lstinvocicedproduct.forEach(element => {
            this.teammodel.pcInt.push(element.productcategorieid);
        });
        this.lstassigned = [];
        this.lstassigned.push({ id: 0, name: "Select Assigned To" })
        this.transactionservice.getAssignedtoList(this.teammodel)
            .subscribe(data => {
                data.forEach(item => {
                    this.lstassigned.push({ id: item.id, name: item.name });
                });
            });
    }

    onSelectAssignto(id) {
        if (id == 0) {
            this.notifyService.showRroor("Select Assigned To !!", "Oops!");
            return;
        }
        this.model.assignedtoID = id;
    }
    cancle() {
        this.router.navigate(['/Installation Dashbord']);
        // this.router.navigate(['/InstallationDCR']);

    }
    btnsave() {

        if (this.model.dcrdate == null) {
            this.notifyService.showRroor("Select DCR Date", "Opps");
            return;
        }
        else if (this.model.dcrdate > new Date()) {
            this.notifyService.showRroor("Please Enter Valid DCR Date", "Opps");
            return;
        }
        if (this.model.dcrstarttime == null) {
            this.notifyService.showRroor("Select DCR Time", "Opps");
            return;
        }
        if (this.model.activityid == null) {
            this.notifyService.showRroor("Select Activity", "Opps");
            return;
        }
        if (this.model.follocheckselect) {
            if (this.model.followupdate == null) {
                this.notifyService.showRroor("Select Follow Up Date ", "Opps");
                return;
            }
            // if (this.model.assignedtoID == null) {
            //     this.notifyService.showRroor("Select Assigned To", "Opps");
            //     return;
            // }
        }
        if (this.model.closureOTPsend == true && this.model.closeotpverify == false) {
            this.notifyService.showRroor("Please Enter and Verify OTP first ", "Opps");
            return;
        }
        if (this.model.lstcontact.length == 1) {
            this.model.lstcontact.forEach(element => {
                element.checkselectcon = true;

                //this.model.checkselectcon = true;
            });
        }
        else if (this.model.contactID == null) {
            this.notifyService.showRroor("Select Contact Person", "Opps");
            return;
        }
        if (this.model.closeotpverify == true) {
            this.btnsendinvoince();
        }
        this.model.showOtp = this.showOtp;
        this.showBtn = true;
        this.installationService.saveinstalltionDCR(this.model)
            .subscribe(data => {
                this.model.dcrdate = new Date();
                this.model.dcrstarttime = new Date().toString().split(' ')[4];
                if (data.status == "OK") {
                    this.notifyService.showSuccess("DCR saved successfully", "Success");

                    // this.router.navigate(['/Installation']);
                    this.router.navigate(['/Installation Dashbord']);

                }
            });


    }
    onSelectactivity(value) {
        this.model.activityid = value;

    }
    onSelectfollowUPactivity(id) {

    }
    btngenrateOTP() {


        if (this.model.dcrdate == null) {
            this.notifyService.showRroor("Please Select DCR Date", "Opps");
            return;
        }
        else if (this.model.dcrdate > new Date()) {
            this.notifyService.showRroor("Please Enter Valid DCR Date", "Opps");
            return;
        }
        if (this.model.dcrstarttime == null) {
            this.notifyService.showRroor("Please Select DCR Time", "Opps");
            return;
        }
        if (this.model.activityid == null) {
            this.notifyService.showRroor("Please Select Activity", "Opps");
            return;
        }
        if (this.model.lstcontact.length == 1) {
            this.model.lstcontact.forEach(element => {
                element.checkselectcon = true;
                //this.model.checkselectcon = true;
            });
        }
        else if (this.model.contactID == null) {
            this.notifyService.showRroor("Select Contact Person", "Opps");
            return;
        }
        this.model.closureOTPsend = true;
        this.model.lstproduct;
        this.installationService.genrateOTP(this.model)
            .subscribe(data => {
                if (data.status == "OK") {

                    this.notifyService.showSuccess("OTP genrate successfully", "Success");

                    this.user.trnid = this.model.id;

                    this.installationService.GetinstallationDCRdetails(this.user)
                        .subscribe(data => {
                            this.model = data;
                            this.model.dcrdate = new Date();
                            this.model.dcrstarttime = new Date().toString().split(' ')[4];
                            this.model.lstproduct = data.lstproduct;
                            this.model.lstspare = this.model.lstproduct;

                            if (data.contactID != null) {
                                data.lstcontact.forEach(element => {
                                    if (element.id == data.contactID || element.id != null) {
                                        element.checkselectcon = true;
                                        this.model.contactID = element.id;
                                        this.model.contactname = element.contactname;
                                    }
                                });
                            }
                            if (this.model.lstcontact.length == 1) {
                                this.model.lstcontact.forEach(element => {
                                    element.checkselectcon = true;
                                    //this.model.checkselectcon = true;
                                });
                            }
                        });

                }
            });

    }
    oncompleteSelect($event) {
        let flag2: boolean = false;
        if ($event.target.checked) {
            if (this.model.lstcontact.length == 1) {
                this.model.lstcontact.forEach(element => {
                    element.checkselectcon = true;
                });
            }

            this.model.lstproduct.forEach(element => {
                if (!element.completflag || this.model.remainInst == false) {
                    flag2 = true;
                }
            });
            if (flag2) {
                this.notifyService.showRroor("Please Complete all installtions", "Opps");
                //this.model.completflag=false;
                this.allcomplete = false;
                this.model.complcheckselect = false;
            } else {
                this.allcomplete = true;
            }
        }

        // if (this.model.chksupervisor) {
        //     if (this.model.designation != 20) {
        //         this.notifyService.showRroor("Installation Compeleted By Supervisor Only", "Opps");
        //         this.model.complcheckselect = false;
        //     }
        // }
    }
    onproductcomplet($event) {
        let flag2: boolean = false;
        if ($event.target.checked) {
            this.model.lstproduct.forEach(element => {
                if (!element.completflag) {
                    flag2 = true;
                }
            });
            if (!flag2) {
                this.model.completflag = true;
            }
        } else {
            this.model.completflag = false;
        }
    }
    Oncheckselectcontact(index) {
        for (let i = 0; i < this.model.lstcontact.length; i++) {
            this.model.lstcontact[i].checkselect = false;
        }
        this.model.lstcontact[index].checkselect = true;

        this.model.contactID = this.model.lstcontact[index].id;
        this.model.contactname = this.model.lstcontact[index].contactname;
    }
    verifyClosureOTP() {

        if (this.model.closureOTP.length == 6) {
            this.model.accountid = this.model.customerID
            this.model.customertypeID = this.model.custypeid
            this.installationService.verifyClosureOTP(this.model).subscribe(data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess("OTP verifed", "Success");
                    this.model.closeotpverify = true;
                    this.model.sendinvoice = false;
                    this.model.lstproduct.forEach(element => {
                        if (element.completflag == true) {
                            this.model.sendinvoice = true;
                        }
                        else {
                            this.model.sendinvoice = false;
                        }

                    });
                    //   this.changeActivity = false;
                } else {
                    this.notifyService.showRroor("Enter Valid OTP", "Success");
                }
            })
        }
    }
    btnsendinvoince() {

        this.transactionservice.SaveInstallationinvoice(this.model)
            .subscribe(data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess("Invoice Send By SMS", "Success");
                    this.model.invocesend = true;
                }
            });

    }

    // btnprintInvo(Invoice: TemplateRef<any>) {

    //     this.invModel = null;
    //     // let user = new User()
    //     this.reportServices.getINSInvoiceInfo(this.model)
    //         .subscribe(
    //             data => {
    //                 this.invModel = data
    //             });

    //     this.modalRef = this.modalService.show(Invoice, {
    //         class: 'modal-lg'
    //     });
    // }

    btnprint() {
        var divToPrint = document.getElementById('inv');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
    cashcodesave() {
        this.transactionservice.SendAndsaveInstallaCashOTP(this.model)
            .subscribe(data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess("OTP Send", "Success");

                }
            });
    }
    verifyOTP() {
        if (this.model.cashreceiverOTP.length == 6) {
            this.transactionservice.VerifyOTP(this.model).subscribe(data => {
                if (data.status == "OK") {
                    this.notifyService.showSuccess("OTP verifed", "Success");
                    this.changeActivity = true;
                    this.resendOTP = false;
                    this.lstactivity.forEach(element => {
                        if (element.code == "R") {
                            this.model.activityID = element.id;
                            this.model.showspare = false;
                        }
                        if (this.model.reistallation && element.code == "Re") {
                            this.model.activityID = element.id;
                        }
                    });
                } else {
                    this.notifyService.showRroor("Enter Valid OTP", "Success");
                }
            });


        }
    }
    enteredMonth() {
        this.model.lstproduct.forEach(element => {
            //  console.log("---", productelement.warrantyenddate)
            if (element.warrantyMonth != null) {
                var date1 = new Date(element.installtiondate)
                element.warrantyenddate = new Date(date1.setMonth(date1.getMonth() + element.warrantyMonth))
            }
        });
    }
    selectDCRDate(date) {

        let pastdate = moment(new Date(), "DD/MM/YYYY").subtract(this.model.nodallwedbeforedcr + 1, 'days').toDate()
        //alert(pas tdate);
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.dcrdate = new Date()
            return;
        }
        else if (date <= pastdate) {
            this.notifyService.showRroor("Previous date cannot be selected", "oops");
            this.model.dcrdate = new Date()
            return;
        }
    }
    btnselectAllProduct($event) {
        for (let i = 0; i < this.model.lstproduct.length; i++) {
            if ($event.target.checked) {
                this.model.lstproduct[i].completflag = true;
            } else {
                this.model.lstproduct[i].completflag = false;
            }
        }
    }

    selectToDate(date) {
        if (date < new Date()) {
            this.notifyService.showRroor("Previous date Cannot be Select", "oops");
            this.model.followupdate = new Date();
            return;
        }
    }
    redirectUser(mainmenulabel: string) {////Resp
        this.router.navigate(['/' + mainmenulabel]);
    }
    btnlogout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    showotp() {
        this.showOtp = true;
    }
}