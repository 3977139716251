<app-header></app-header>
<div class="container" style="width: 80%;">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{user.pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col s12 m12 l12 registerBgShadow">
            <div class="row" style="margin-top: 2%" *ngIf="grnnumber==null">
                <div class="input-field col l2 m2 s2">
                    <input type="checkbox" [(ngModel)]="model.chkverbal" #chkverbal="ngModel"
                        (click)="onchkVerbalPO($event)">&nbsp;&nbsp;Verbal PO
                </div>
                <div class="input-field col l5 m5 s5">
                    <input type="text" id="dynamicUserIdsSecondWay" [(ngModel)]="model.supplierName" type="text"
                        class="autocomplete" name="Name" (keyup)="getUserIdsSecondtWay($event)" />
                    <label for="dynamicUserIdsSecondWay">Supplier Name</label>
                </div>
                <div class="input-field col l3 m3 s3" *ngIf="!model.chkverbal" style="margin: 0px">
                    <span>PO</span>
                    <select (change)="onselectPO($event.target.value)" name="PO" data-toggle="tooltip"
                        data-placement="top" title="PO">
                        <option selected value="0">Select PO Number</option>
                        <option *ngFor="let lst of polst" [value]="lst.id" [selected]="lst.id==model.poid">
                            {{lst.pono}}</option>
                    </select>
                </div>
                <div class="col l2 m2 s2" *ngIf="!model.chkverbal">
                    <span style="font-size: 13px;">PO
                        Date<span><br><b>{{model.podate | date:"dd-MM-yyyy"}}</b></span></span>
                </div>
            </div>

            <div class="row" *ngIf="grnnumber!=null" style="padding: 10px;">
                <div class="col l3 m3 s3">
                    <span style="font-size: 14px;font-weight: bold">Supplier
                        Name:&nbsp;<span>{{model.supplierName}}</span></span>
                </div>
                <div class="col l3 m3 s3">
                    <span style="font-size: 14px;font-weight: bold">PO Number:
                        &nbsp;<span>{{model.pono}}</span></span>
                </div>
                <div class="col l3 m3 s3">
                    <span style="font-size: 14px;font-weight: bold">Invoice
                        Number:&nbsp;<span>{{model.invoiceno}}</span></span>
                </div>
                <div class="col l3 m3 s3">
                    <span style="font-size: 14px;font-weight: bold">Invoice
                        Date:&nbsp;<span>{{model.invodate | date:"dd-MM-yyyy"}}</span></span>
                </div>
            </div>

            <div class="row" *ngIf="grnnumber!=null" style="padding: 10px;">
                <div class="col s5 m5 l5">
                    <span style="font-size: 14px;font-weight: bold">Inventory
                        Location:&nbsp;<span>{{model.locationname}}</span></span>
                </div>
                <div class="col s4 m4 l4">
                    <span style="font-size: 14px;font-weight: bold">GRN Number:&nbsp;<span>{{model.grnno}}</span></span>
                </div>
                <div class="col l3 m3 s3">
                    <span style="font-size: 14px;font-weight: bold">GRN
                        Date:&nbsp;<span>{{model.grndate | date:"dd-MM-yyyy"}}</span></span>
                </div>
            </div>

            <div class="row" *ngIf="grnnumber==null">
                <div class="input-field col l3 m3 s3">
                    <input type="text" [(ngModel)]="model.invoiceno" #invoiceno="ngModel" data-toggle="tooltip"
                        data-placement="top" title="Invoice Number" id="invoiceno" name="invoiceno">
                    <label id="lable3" for="invoiceno">Invoice Number</label>
                </div>
                <div class="input-field col l2 m2 s2">
                    <input type="text" name="invodate" #invodate="ngModel" placeholder="Invoice Date"
                        [(ngModel)]="model.invodate" class="form-control" data-toggle="tooltip" data-placement="top"
                        title="Invoice Date" bsDatepicker placement="bottom"
                        (ngModelChange)="selectInvoiceDate(model.invodate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true}">
                </div>
                <div class="input-field col l3 m3 s3" style="margin: 0px;">
                    <span>Inventory Locations</span>
                    <select (change)="onselectInventoryLocation($event.target.value)" name="Inventory Locations"
                        data-toggle="tooltip" data-placement="top" title="Inventory Locations">
                        <option selected value="0">Select Inventory Locations</option>
                        <option *ngFor="let lst of inventoryLocations" [value]="lst.id"
                            [selected]="lst.id==model.localtionid">
                            {{lst.name}}</option>
                    </select>
                </div>
                <div class="input-field col l4 m4 s4" *ngIf="model.chkverbal==false">
                    <input type="text" id="dynamicUserIdsSecondWay1" [(ngModel)]="model.itemname" id="itmname"
                        type="text" class="autocomplete" name="Name" (keyup)="dynamicUserIdsSecondWay1($event)" />
                    <label for="dynamicUserIdsSecondWay1">Search Item Name</label>
                </div>
            </div>

            <div class="row" *ngIf="model.chkverbal&&grnnumber==null">
                <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span>Item Category</span>
                    <select (change)="onselectItemCate($event.target.value)" name="Item Category" data-toggle="tooltip"
                        data-placement="top" title="PO">
                        <option *ngFor="let lst of itemcatlst" [value]="lst.id">
                            {{lst.name}}</option>
                    </select>
                </div>
                <div class="input-field col l4 m4 s4" style="margin: 0px">
                    <span>Item Sub Category</span>
                    <select (change)="onselectItemSubCate($event.target.value)" name="Item Sub Category"
                        data-toggle="tooltip" data-placement="top" title="PO">
                        <option *ngFor="let lst of itemSubcatlst" [value]="lst.id">
                            {{lst.name}}</option>
                    </select>
                </div>
                <div class="input-field col l4 m4 s4" style="margin: 0px">
                    <span>Item</span>
                    <select (change)="onselectItem($event.target.value)" name="Item" data-toggle="tooltip"
                        data-placement="top" title="PO">
                        <option *ngFor="let lst of itemlst" [value]="lst.id">
                            {{lst.name}}</option>
                    </select>
                </div>
                <div class="col l1 m1 s1" style="margin-top: 18px;  text-align: right;">
                    <button (click)="btnAddItem()" type="button" id="btnAddItem"
                        class="waves-effect waves-light btn">Add</button>
                </div>
            </div>


            <div class="row" style="margin-top: 10px" *ngIf="grnnumber==null" style="
            padding-top: 1%;
            padding-right: 1%;
            padding-bottom: 1%;
            padding-left: 1%;
        ">
                <table>
                    <thead style="background: gainsboro;">
                        <th width="25%" style="text-align: left">Item Name</th>
                        <th width="7%" style="text-align: left">Unit</th>
                        <th width="7%" style="text-align: right" *ngIf="!model.chkverbal">PO Qty</th>
                        <th width="7%" style="text-align: right">Invoice Qty</th>
                        <th width="7%" style="text-align: right">Received Qty</th>
                        <th width="10%" style="text-align: right">Rate</th>
                        <th width="7%" style="text-align: right">GST</th>
                        <th width="10%" style="text-align: right">GST Amount</th>
                        <th width="10%" style="text-align: right">Total Amount</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prod of productslst">
                            <td style="text-align: left">{{prod.itemname}}</td>
                            <td style="text-align: left">{{prod.unit}}</td>
                            <td style="text-align: right" *ngIf="!model.chkverbal">{{prod.poqnty | number:'1.2-2' }}
                            </td>
                            <td><input type="number" [(ngModel)]="prod.invoiceqnty" data-toggle="tooltip"
                                    data-placement="top" title="Invoice Quantity" placeholder="Invoice Quantity"
                                    style="text-align: end;margin: 0px" min="0">
                            </td>
                            <td><input type="number" [(ngModel)]="prod.recvqnty" data-toggle="tooltip"
                                    data-placement="top" title="Received Quantity" (ngModelChange)="calculate()"
<<<<<<< HEAD
                                    placeholder="Received Quantity" style="text-align: end;margin: 0px" min="0">
=======
                                    placeholder="Received Quantity" style="text-align: end;margin: 0px" min="0" [max]="prod.balanceqnty">
>>>>>>> main
                            </td>
                            <td style="text-align: right"> <input type="number" [(ngModel)]="prod.rate"
                                    data-toggle="tooltip" data-placement="top" title="Rate"
                                    (ngModelChange)="calculate()" placeholder="Rate" style="text-align: end;margin: 0px"
                                    min="0">
                            </td>
                            <td style="text-align: right" *ngIf="!model.chkverbal">{{prod.gstperc | number:'1.2-2'}}
                            </td>
                            <td style="text-align: right" class="input-field" *ngIf="model.chkverbal">
                                <select (change)="onselectTax($event.target.value, prod.itemid, i)" name="Reject Reason"
                                    data-toggle="tooltip" data-placement="top" title="Reject Reason">
                                    <option [value]="0">Select One</option>
                                    <option *ngFor="let lst of taxmodellst; let i = index" [value]="lst.id"
                                        [selected]="lst.id==prod.gstgrpid">
                                        {{lst.name}}</option>
                                </select>
                            </td>
                            <td style="text-align: right">{{prod.gstamnt | number:'1.2-2'}}</td>
                            <td style="text-align: right">{{prod.totalamnt | number:'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align: right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </span>
            </div>

            <div class="row" style="margin-top: 10px" *ngIf="grnnumber!=null" style="
            padding-top: 1%;
            padding-right: 1%;
            padding-bottom: 1%;
            padding-left: 1%;
        ">
                <table>
                    <thead style="background: gainsboro;">
                        <th width="15%" style="text-align: left">Item Name</th>
                        <th width="5%" style="text-align: left">Unit</th>
                        <th width="5%" style="text-align: right" *ngIf="!model.chkverbal">PO Qty</th>
                        <th width="7%" style="text-align: right">Invoice Qty</th>
                        <th width="7%" style="text-align: right">Received Qty</th>
                        <th width="7%" style="text-align: right" *ngIf="this.action == 'New'">Approve Qty</th>
                        <th width="4%" style="text-align: right" *ngIf="this.action == 'New'">Rejected Qty</th>
                        <th width="10%" style="text-align: right" *ngIf="this.action == 'New'">Rejection Reason</th>
                        <th width="5%" style="text-align: right">Rate</th>
                        <th width="7%" style="text-align: right">GST</th>
                        <th width="8%" style="text-align: right">GST Amount</th>
                        <th width="10%" style="text-align: right">Total Amount</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let prod of productslst1">
                            <td style="text-align: left">{{prod.itemname}}</td>
                            <td style="text-align: left">{{prod.unit}}</td>
                            <td style="text-align: right" *ngIf="!model.chkverbal">{{prod.poqnty | number:'1.2-2' }}
                            </td>
                            <td style="text-align: right">{{prod.invoiceqnty | number:'1.2-2'}}</td>
                            <td style="text-align: right">{{prod.recvqnty | number:'1.2-2'}}</td>
                            <td *ngIf="this.action == 'New'"><input type="number" [(ngModel)]="prod.approvqnty"
                                    (ngModelChange)="calculate()" data-toggle="tooltip" data-placement="top"
                                    title="Approve Quantity" placeholder="Approve Quantity" min="0"
                                    [max]="prod.recvqnty" style="text-align: end;margin: 0px">
                            </td>
                            <td style="text-align: right" *ngIf="this.action == 'New'">{{prod.rejectqnty |
                                number:'1.2-2'}}</td>
                            <td style="text-align: right" class="input-field" style="margin: 0px"
                                *ngIf="this.action == 'New'">
                                <select (change)="onselectReject($event.target.value)" name="Reject Reason"
                                    data-toggle="tooltip" data-placement="top" title="Reject Reason"
                                    *ngIf="prod.rejectqnty>0">
                                    <option [value]="0">Select One</option>
                                    <option *ngFor="let lst of rejectResonslst" [value]="lst.id">
                                        {{lst.name}}</option>
                                </select>
                            </td>
                            <td style="text-align: right">{{prod.rate | number:'1.2-2'}}</td>
                            <td style="text-align: right">{{prod.gstperc | number:'1.2-2'}}</td>
                            <td style="text-align: right">{{prod.gstamnt | number:'1.2-2'}}</td>
                            <td style="text-align: right">{{prod.totalamnt | number:'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
                <span style="text-align: right">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </span>
            </div>

            <div class="row">
                <div class="col l12 m12 s12" style="text-align: right">
                    <button (click)="btnback()" type="button" class="waves-effect cancelbutton btn">Cancel</button>
                    &nbsp;
                    <button (click)="saveInvent($event)" type="button" id="btnsave1"
                        class="waves-effect waves-light btn" *ngIf="this.action == 'New'">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>