<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Locality</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col l5 m5 s5" style="margin-bottom:0px;">
                    <span>Country</span>
                    <select (change)="onSelectCountry($event.target.value)">
                        <option selected value="0">Select Country</option>
                        <option *ngFor="let type of countrylst" [value]="type.id"
                            [selected]="type.id == model.countryid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l5 m5 s5" style="margin-bottom:0px;">
                    <span>State</span>
                    <select (change)="onSelectState($event.target.value)">
                        <option *ngFor="let type of statelst" [value]="type.id" [selected]="type.id == model.stateid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="col l1"></div>
            </div>
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col l5 m5 s5" style="margin-bottom:0px;">
                    <span>City</span>
                    <select (change)="onSelectCity($event.target.value)">
                        <option *ngFor="let type of citylst" [value]="type.id" [selected]="type.id == model.cityid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l5 m5 s5" style="margin-bottom:0px;">
                    <span>Locale</span>
                    <select (change)="onSelectlocale($event.target.value)">
                        <option *ngFor="let type of localelst" [value]="type.id" [selected]="type.id == model.localeid">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="col l1"></div>
            </div>
            <div class="row">
                <div class="col l1"></div>
                <div class="input-field col l4 m4 s4">
                    <input [(ngModel)]="model.code" #code="ngModel" type="text" class="" id="code" data-toggle="tooltip"
                        data-placement="top" title="Code">
                    <label for="code" class="active">Code</label>
                </div>
                <div class="input-field col l4 m4 s4">
                    <input [(ngModel)]="model.name" #name="ngModel" type="text" class="" id="name" data-toggle="tooltip"
                        data-placement="top" title="Name">
                    <label for="name" class="active">Name</label>
                </div>
                <div class="input-field col l2 m2 s2">
                    <input [(ngModel)]="model.pincode" #name="ngModel" type="number" class="" id="pin"
                        data-toggle="tooltip" data-placement="top" title="Pin Code"
                        onKeyPress="if(this.value.length==6) return false;">
                    <label for="pin" class="active">Pin Code</label>
                </div>
                <div class="col l1"></div>
            </div>
            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right">
                    <button (click)="btnback()" type="button"
                        class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                    <button (click)="btnsaveLocality()" type="button" class="waves-effect waves-light btn">Save</button>
                </div>

            </div>
        </div>
    </div>
</div>