import { TemplateRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { User } from "src/app/_models";
import { CompanyService, AlertService,DealersService } from "src/app/_services";
import { MasterServices } from "src/app/_services/MasterServices";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { StockLedgerReportModel } from "./StockLedgerReportModel";
declare var $: any;
@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'StockLedgerReport.html'
})
export class StockLedgerReport implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router,private dealersService: DealersService) {

    }
    stocklst: StockLedgerReportModel[] = [];
    model = new StockLedgerReportModel();
    user = new User();
    p: any;
    val: string;
    productList2: any;
    companyname: string;
    companyaddress: String;
    keyword = 'name';
    ngOnInit() {
        
    }
    getItemName($event) {
        let userId = (<HTMLInputElement>document.getElementById('itemname')).value;
        this.productList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.reportServices.getItemName(this.user)
                .subscribe(data => {
                    data.forEach((item) => 
                    this.productList2.push({ "id": item.id, "name": item.name }));
                    
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.productList2.length; i++) {
                        map.set(this.productList2[i].name.trim(), this.productList2[i].id);
                        searchDataObj[this.productList2[i].name] = null;
                        if (i == 0) {
                            names = this.productList2[i].name;
                        } else {
                            names = names + "," + this.productList2[i].name;
                        }
                    }

                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt.trim()), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });

                    
                });
        }
    }

    typeaheadOnSelect(id, name) {
        this.model.itemid = id;
    }
    selectToDate(date) {
        if (date > new Date()) {
            this.notifyService.showRroor("Future date Cannot be Select", "oops");
            this.model.todate = new Date()
            return;
        }
    }

    onAllitem() {
        if (this.model.chkAllitem = "All") {
            this.val = this.model.chkAllitem;
            this.user.message = this.val;
        }
    }

    btnshow(StockLedgerModel: TemplateRef<any>) {
        if (this.model.frmdate == null) {
            this.notifyService.showRroor(" Please select From Date !!", "Oops!");
            return;
        }
        if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
            return;
        }
        this.reportServices.showStockLedgerReport(this.model)
            .subscribe(
                data => {
                    this.stocklst = data;
                    // this.companyname = this.stocklst[0].companyname;
                    // this.companyaddress = this.stocklst[0].companyaddress;

                    this.modalRef = this.modalService.show(StockLedgerModel, {
                        class: 'modal-lg'
                    });
                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
       // WindowObject.close();
    }
    onChangeSearchproduct(search:string) {
        this.user.message = search;
      //  this.promodel.productid = this.productid;
        this.reportServices.getItemName(this.user)
            .subscribe(data => {
                this.productList2 = [];
                data.forEach((item) => this.productList2.push({ "id": item.id, "name": item.name }));
      
            });
      
      }
      selectEvent(item)
      {
        this.model.itemid = item.id;
      }

      onFocusedforproduct(e) {
        // do something
      }
}