import { OnInit, Component, OnDestroy } from '@angular/core';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { LocalityModel } from './LocalityModel';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
@Component({
    selector: 'app-localD',
    //  moduleId: module.id,
    templateUrl: 'DefineLocality.html'
})
export class DefineLocality implements OnInit,OnDestroy {
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router) {
    }
    user = new User();
    model = new LocalityModel();
    public countrylst: DropDownModel[] = [];
    public statelst: DropDownModel[] = [];
    public citylst: DropDownModel[] = [];
    public localelst: DropDownModel[] = [];
    id: number;
    pagename: string;
    ngOnDestroy(): void {
        localStorage.removeItem("pagename");
    }
    ngOnInit(): void {
        this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
        this.model.userid = Number(localStorage.getItem("userid"));
        this.getAllCountries();
        this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            let user = new User();
            user.trnid = this.id;
            this.Masterservice.getLocalityById(user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.getStatesByCountry(this.model.countryid)
                        this.getAllCitiesByState(this.model.stateid)
                        this.getAllLocalesByCity(this.model.cityid)
                    });
        }

    }

    getAllCountries() {
        this.countrylst = []
        this.Masterservice.getAllCountries(this.user)
            .subscribe(value => {
                value.forEach((item) => this.countrylst.push({ id: item.id, name: item.name }));
            });
    }

    onSelectCountry(val) {
        if (val == 0) {
            this.notifyService.showRroor("Please Select Country!", "Oops!");
            return;
        }
        this.model.countryid = val
        this.getStatesByCountry(val)
    }

    getStatesByCountry(cntryid) {
        this.statelst = []
        this.user.trnid = cntryid
        this.Masterservice.getStatesByCountry(this.user)
            .subscribe(value => {
                this.statelst.push({ id: 0, name: "Choose One" })
                value.forEach((item) => this.statelst.push({ id: item.id, name: item.name }));
            });
    }

    onSelectState(val) {
        this.model.stateid = val;
        for(let i = 0;i<this.statelst.length;i++){
            if (this.statelst[i].id == val) {
                this.model.state = this.statelst[i].name
            }
        }
        this.getAllCitiesByState(val);
    }

    getAllCitiesByState(stateid) {
        this.citylst = [];
        this.citylst.push({ id: 0, name: "Choose One" });
        this.user.trnid = stateid;
        this.Masterservice.getAllCitiesByState(this.user)
            .subscribe(value => {
                value.forEach((item) => this.citylst.push({ id: item.id, name: item.name }));
            });
    }

    onSelectCity(cityid) {
        this.model.cityid = cityid
        for(let i = 0;i<this.citylst.length;i++){
            if (this.citylst[i].id == cityid) {
                this.model.city = this.citylst[i].name
            }
        }
        this.getAllLocalesByCity(cityid)
    }

    getAllLocalesByCity(cityid) {
        this.localelst = [];
        this.localelst.push({ id: 0, name: "Choose One" });
        this.user.trnid = cityid;
        this.Masterservice.getAllLocalesByCity(this.user)
            .subscribe(value => {
                value.forEach((item) => this.localelst.push({ id: item.id, name: item.name }));
            });
    }

    onSelectlocale(id) {
        this.model.localeid = id;
        for (let i = 0; i < this.localelst.length; i++) {
            if (this.localelst[i].id == id) {
                this.model.locale = this.localelst[i].name
            }
        }
    }

    btnsaveLocality() {

        if (this.model.countryid == null) {
            this.notifyService.showRroor("Please select country", "Oops!");
            return;
        }
        if (this.model.stateid == null) {
            this.notifyService.showRroor("Please select state", "Oops!");
            return;
        }
        if (this.model.cityid == null) {
            this.notifyService.showRroor("Please select city", "Oops!");
            return;
        }
        if (this.model.localeid == null) {
            this.notifyService.showRroor("Please select locale", "Oops!");
            return;
        }
        if (this.model.code == null || this.model.code == "") {
            this.notifyService.showRroor("Please enter Locality code", "Oops!");
            return;
        }
        if (this.model.name == null || this.model.name == "") {
            this.notifyService.showRroor("Please enter Locality name", "Oops!");
            return;
        }
        if (this.model.pincode == null || this.model.pincode == "") {
            this.notifyService.showRroor("Please enter Pin code", "Oops!");
            return;
        }
        this.Masterservice.btnsaveLocality(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !")
                        this.router.navigate(['/Locality']);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    btnback() {
        this.router.navigate(['/Locality']);
    }

}