<app-header></app-header>
<div class="container" style="width: 80%">
    <div class="row">
        <div class="col l12 m12 s12">
            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">Marketing Proposal</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row" style="margin-top: 2%" *ngIf="operationType=='new'">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l1 m1 s1" style="margin:0px">
                    <label>
                        <input name="group1" type="radio" [checked]="model.chkevent" (click)="onchkevent($event)" />
                        <span style="font-size: 104%">Event</span>
                    </label>
                </div>
                <div class="input-field col l2 m2 s2" style="margin:0px">
                    <label style="left: 20px;">
                        <input name="group1" type="radio" [checked]="model.chkstandalon"
                            (click)="onchkstandalon($event)" />
                        <span style="font-size: 104%">Standalone Spends</span>
                    </label>
                </div>
                <div class="input-field col l3 m3 s3" style="margin:0px;left: 0px;">
                    <label>
                        <input name="group1" type="radio" [checked]="model.chkcollateral"
                            (click)="onchkcollateral($event)" />
                        <span style="font-size: 104%">Collateral Issues</span>
                    </label>
                </div>
                <div class="col l4 m4 s4" style="margin-top: 1%">
                    <span style="font-size: 15px">Initiated by:&nbsp;<span>{{loginUser}}</span></span>
                </div>

                <div class="col l1 m1 s1"></div>
            </div>

            <div class="row" *ngIf="operationType!='new'" style="padding: 7px;">
                <div class="col l4 m4 s4">
                    <span style="font-size: 16px;font-weight: bold">Proposal
                        type:&nbsp;<span>{{model.proposaltype}}</span></span>

                </div>

                <div class="col l4 m4 s4" *ngIf="model.proposaltype=='Event'">
                    <span style="font-size: 16px;font-weight: bold">Event type:&nbsp;
                        <span>{{model.eventtype}}</span></span>

                </div>

                <div class="col l4 m4 s4">
                    <span style="font-size: 16px;font-weight: bold">Initiated
                        by:&nbsp;<span>{{model.resource}}</span></span>

                </div>
            </div>
            <div class="row" style="margin-top: 2%" *ngIf="operationType=='new'&&model.proposaltype=='Event'">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l5 m5 s5" style="margin:0px">
                    <span>Event Type</span>
                    <select (change)="onSelectEventtype($event.target.value)">
                        <option selected value='0'> Select Event Type</option>
                        <option *ngFor="let type of eventtypelst" [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l5 m5 s5">
                    <input [(ngModel)]="model.shortname" #shortname="ngModel" type="text" class="" id="search"
                        data-toggle="tooltip" data-placement="top" title="Event Short Name">
                    <label for="search">Event Short Name</label>
                </div>
            </div>

            <!-- <div class="row" *ngIf="operationType!='new'" style="padding: 7px;">
                <div class="col l4 m4 s4" *ngIf="model.proposaltype=='Event'">
                    <span style="font-size: 15px">Short Name:&nbsp;<span>{{model.shortname}}</span></span>

                </div>

                <div class="col l4 m4 s4">
                    <span style="font-size: 15px">Region:&nbsp; <span>{{model.region}}</span></span>

                </div>

                <div class="col l4 m4 s4">
                    <span style="font-size: 15px">Area:&nbsp; <span>{{model.area}}</span></span>

                </div>
            </div> -->
            <div class="row" *ngIf="operationType!='new'" style="padding: 7px;">
                <!-- <div class="col l4 m4 s4">
                    <span style="font-size: 15px">Locality:&nbsp; <span>{{model.locality}}</span></span>

                </div>

                <div class="col l4 m4 s4">
                    <span style="font-size: 15px">Pincode:&nbsp;<span>{{model.pincode}}</span></span>

                </div> -->

                <div class="col l4 m4 s4">
                    <span style="font-size: 15px">City:&nbsp; <span>{{model.city}}</span></span>

                </div>
            </div>
            <div class="row" *ngIf="operationType=='new'">
                <div class="col l1 m1 s1"></div>
                <!-- <div class="input-field col l5 m5 s5">
                    <input type="text" class="autocomplete" id="resAllos" placeholder="Pincode"
                        (keyup)="getPincodeLikes($event)" />
                    <input type="hidden" id="acc-id" />
                </div> -->
                <div class="input-field col l5 m5 s5">
                    <select (change)="onSelectCity($event.target.value)">
                        <option selected value="0">Select City</option>
                        <option *ngFor="let type of citylst" [value]="type.id">
                            <!-- [selected]="type.cityid == model.cityid" -->
                            {{type.name}}
                        </option>
                    </select>
                </div>
                <!-- <div class="ng-autocomplete pinRowgrp" class=" input-field col s4" style="margin-bottom: 0px;margin-top: 17px;" >
                    <ng-autocomplete [data]="pincodelist" type="text" [(ngModel)]="model.pincode" placeholder="Enter the pincode"
                      [searchKeyword]="keyword" [minQueryLength]="3"
                      (inputChanged)='onChangeSearch($event)'  (selected)='selectEvent($event)'  (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
  
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
 -->
                <!-- <div class="input-field col l5 m5 s5" style="margin:0px">
                    <span>Locality</span>
                    <select (change)="onSelectLocality($event.target.value)">
                        <option selected value='0'> Select Locality</option>
                        <option *ngFor="let type of lstlocality" [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div> -->

                <div class="col l1 m1 s1"></div>
            </div>
            <div class="row" style="margin-top: 2%" *ngIf="operationType!='approve'">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l5 m5 s5" style="margin:0px">
                    <textarea id="textarea1" title="Description" class="materialize-textarea"
                        [(ngModel)]="model.description" #description="ngModel" id="Description"></textarea>
                    <label for="Description" class="active">Description</label>
                </div>
                <div class="input-field col l5 m5 s5" style="margin:0px" *ngIf="model.proposaltype=='Event'">
                    <textarea id="textarea1" title="Expected Outcome" class="materialize-textarea"
                        [(ngModel)]="model.expectedoutcome" #expectedoutcome="ngModel" id="Expected"></textarea>
                    <label for="Expected" class="active">Expected Outcome</label>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>


            <div class="row" style="margin-top: 2%" *ngIf="operationType=='approve'">
                <div class="col l1 m1 s1"></div>
                <div class="col l5 m5 s5">
                    <span style="font-size: 15px">Description:&nbsp;<span>{{model.description}}</span></span>
                </div>
                <div class="col l5 m5 s5" *ngIf="model.proposaltype=='Event'">
                    <span style="font-size: 15px">Expected Outcome:&nbsp;<span>{{model.expectedoutcome}}</span></span>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>

            <div class="row" *ngIf="operationType=='approve'">
                <div class="col l1 m1 s1"></div>
                <div class="col l5 m5 s5">
                    <span style="font-size: 15px">Approved Number:&nbsp;<span>{{model.approvalNo}}</span></span>
                </div>
                <div class="col l5 m5 s5" *ngIf="model.proposaltype=='Event'">
                    <span style="font-size: 15px">Approved
                        By:&nbsp;<span>{{model.approvedBy}}&nbsp;&nbsp;{{model.approvedDate}}</span></span>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>


            <div class="row" *ngIf="operationType=='pending'">
                <div class="col l6 m6 s6">
                    <span *ngIf="model.dealerid!=null">Dealer Name:&nbsp;<span>{{model.sponser}}</span></span>
                </div>
                <div class="col l6 m6 s6">
                    <span>Target Achievement:</span>
                </div>
            </div>

            <div class="row" *ngIf="operationType=='new'">
                <div class="col l1 m1 s1"></div>
                <div class="col l5 m5 s5">
                    <div class="row">
                        <div class="input-field col l6 m6 s6" style="margin:0px">
                            <label>
                                <input name="group2" type="radio" [checked]="model.chkinternal"
                                    (click)="onchkinternal($event)" />
                                <span style="font-size: 104%">Internal</span>
                            </label>
                        </div>
                        <div class="input-field col l6 m6 s6" style="margin:0px">
                            <label>
                                <input name="group2" type="radio" [checked]="model.chkdealer"
                                    (click)="onchkdealer($event)" />
                                <span style="font-size: 104%">Dealer</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col l5 m5 s5">
                    <div class="row">
                        <div class="input-field  col l6 m6 s6" style="margin:0px">
                            <span style="font-size: 15px">Dealer Name</span>
                            <select (change)="onSelectDealer($event.target.value)" [disabled]="!model.chkdealer">
                                <option selected value='0'> Select Dealer Name</option>
                                <option *ngFor="let type of dealerlst" [value]="type.id">
                                    {{type.name}}</option>
                            </select>
                        </div>
                        <div class="col l6 m6 s6" style="margin-top: 5%">
                            <span style="font-size: 15px">Target Achievement:</span>
                        </div>
                    </div>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>



            <div class="row" *ngIf="operationType!='approve'&&model.proposaltype!='Collateral'">
                <div class="col l1 m1 s1"></div>
                <!-- Standalone -->
                <div class="input-field col l2 m2 s2" style="margin: 0px" *ngIf="model.proposaltype!='Event'">
                    <label>
                        <input name="group3" type="radio" [checked]="model.chkRecurring"
                            (click)="onchkRecurring($event)" />
                        <span style="font-size: 104%">Recurring</span>
                    </label>
                </div>
                <div class="input-field col l1 m1 s1" *ngIf="model.proposaltype!='Event'">
                    <input type="checkbox" [(ngModel)]="model.chkdays" [disabled]="model.chknonschedule"
                        #chkdays="ngModel" (click)="onchkdays($event)">&nbsp;&nbsp;Days
                </div>
                <div class="input-field col l1 m1 s1" *ngIf="model.proposaltype!='Event'">
                    <input type="checkbox" [(ngModel)]="model.chkmonths" [disabled]="model.chknonschedule"
                        #chkmonths="ngModel" (click)="onchkmonths($event)">&nbsp;&nbsp;Months
                </div>
                <div class="input-field col l2 m2 s2" style="margin: 0px" *ngIf="model.proposaltype!='Event'">
                    <label>
                        <input name="group3" type="radio" [checked]="model.chknonschedule"
                            (click)="onchknonschedule($event)" />
                        <span style="font-size: 104%">Non-Scheduled</span>
                    </label>
                </div>
                <!--Event Type Simple Date Picker -->
                <div class="input-field col l2 m2 s2" style="margin:0px" *ngIf="model.proposaltype=='Event'">
                    <input type="text" placeholder="From Date" [(ngModel)]="model.fromdate" id="fromdate"
                        class="form-control" bsDatepicker (ngModelChange)="fromDateOnchange(model.fromdate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true}">
                </div>
                <div class="input-field col l2 m2 s2" style="margin:0px" *ngIf="model.proposaltype=='Event'">
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" id="todate" class="form-control"
                        bsDatepicker (ngModelChange)="toDateOnchange(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' , adaptivePosition: true }">
                </div>
                <!-- On Check Days -->
                <div class="input-field col l2 m2 s2" style="margin:0px"
                    *ngIf="model.chkdays&&model.proposaltype!='Event'||model.chknonschedule">
                    <input type="text" placeholder="From Date" [(ngModel)]="model.fromdate" id="fromdate"
                        class="form-control" bsDatepicker (ngModelChange)="fromDateOnchange(model.fromdate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' , adaptivePosition: true }">
                </div>
                <div class="input-field col l2 m2 s2" style="margin:0px"
                    *ngIf="model.chkdays&&model.proposaltype!='Event'">
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" id="todate" class="form-control"
                        bsDatepicker (ngModelChange)="toDateOnchange(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">
                </div>
                <!-- On Check Months -->
                <div class="input-field col l2 m2 s2" style="margin:0px"
                    *ngIf="model.chkmonths&&model.proposaltype!='Event'">
                    <input type="text" placeholder="From Date" [(ngModel)]="model.fromdate" id="fromdate"
                        class="form-control" bsDatepicker (ngModelChange)="selectFrommonth(model.fromdate)"
                        [bsConfig]="{ dateInputFormat: 'MMM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">

                </div>
                <div class="input-field col l2 m2 s2" style="margin:0px"
                    *ngIf="model.chkmonths&&model.proposaltype!='Event'">
                    <input type="text" placeholder="To Date" [(ngModel)]="model.todate" id="todate" class="form-control"
                        bsDatepicker (ngModelChange)="selecttomonth(model.todate)"
                        [bsConfig]="{ dateInputFormat: 'MMM-YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">
                </div>

                <div class="col l5 m5 s5" style="margin-top: 1%" *ngIf="model.proposaltype=='Event'">
                    <span style="font-size: 15px">{{model.daysDuration | titlecase}}&nbsp;Days</span>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>

            <div class="row" *ngIf="operationType!='approve'&&model.proposaltype!='Collateral'">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l4 m4 s4">
                    <input [(ngModel)]="model.des1" #des1="ngModel" type="text" class="" id="des1" data-toggle="tooltip"
                        data-placement="top" title="Expense Description">
                    <label for="des1">Expense Description</label>
                </div>
                <div class="input-field col l2 m2 s2">
                    <input [(ngModel)]="model.amnt" #amnt="ngModel" type="number" class="" id="amnt"
                        data-toggle="tooltip" data-placement="top" title="Amount" min="0">
                    <label for="amnt">Amount</label>
                </div>
                <div class="input-field col l2 m2 s2" *ngIf="model.proposaltype=='Event' && model.chkinternal"
                    style="margin: 0px;">Sponsor:&nbsp;&nbsp;<b>{{comapnyname}}</b>
                </div>
                <div class="input-field col l2 m2 s2" style="margin: 0px"
                    *ngIf="model.proposaltype=='Event' && model.chkdealer">
                    <span>Sponsor</span>
                    <select (change)="onSelectSponser($event.target.value)">
                        <option *ngFor="let type of sponserlst" [value]="type.name">
                            {{type.name}}</option>
                    </select>
                </div>

                <div class="col l1 m1 s1"></div>
            </div>


            <!-- For Only Collateral -->
            <div class="row" *ngIf="operationType!='approve'&&model.proposaltype=='Collateral'">
                <div class="col l1 m1 s1"></div>
                <!-- <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span>Cost-Head</span>
                    <select (change)="onSelectCostHead($event.target.value)">
                        <option *ngFor="let type of costheadlst" [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin: 0px">
                    <span>Sub-Head</span>
                    <select (change)="onSelectSubHead($event.target.value)">
                        <option *ngFor="let type of mrktmodellst" [value]="type.id">
                            {{type.name}}</option>
                    </select>
                </div> -->
                <div class="input-field col l3 m3 s3" style="margin-top: 20px;">
                    <span>Marketing Cost Head</span>
                    <select (change)="onSelectmrktcost($event.target.value)">
                        <option selected value='0'> Select Marketing Cost Head</option>
                        <option *ngFor="let type of mrktcostlst" [value]="type.id">
                            <!-- [selected]="type.id == model.costheadid" -->
                            {{type.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l3 m3 s3" style="margin-top: 20px;">
                    <span>Marketing Cost SubHead</span>
                    <select (change)="onSelectmrktcostsubhead($event.target.value)">
                        <option selected value='0'> Select Marketing Cost SubHead</option>
                        <option *ngFor="let type of subcostheadlst" [value]="type.id">
                            <!-- [selected]="type.id == model.costsubheadid" -->
                            {{type.name}}
                        </option>
                    </select>
                </div>
                <div class="input-field col l1 m1 s1" style="margin-top: 50px;">
                    <b>{{model.uomname}}</b>
                </div>
                <div class="input-field col l2 m2 s2" style="margin-top: 36px;margin-bottom: 0px;"
                    *ngIf="model.chkselectvalue == true">
                    <input [(ngModel)]="model.amnt" #amnt="ngModel" type="number" class=""
                        oninput="validity.valid||(value='');" id="amnt" min="0" data-toggle="tooltip"
                        data-placement="top" title="Amount">
                    <label for="amnt">Amount</label>
                </div>
                <div class="input-field col l2 m2 s2" style="margin-top: 36px;margin-bottom: 0px;"
                    *ngIf="model.chkselectvalue == false">
                    <input [(ngModel)]="model.qty" #qty="ngModel" type="number" class=""
                        oninput="validity.valid||(value='');" id="qty" min="0" data-toggle="tooltip"
                        data-placement="top" title="Quantity">
                    <label for="qty">Qty</label>
                </div>
                <div class="col l1 m1 s1"></div>
            </div>



            <div class="row">
                <div class="col l3 m3 s3" style="margin-top: 5px;">
                    <span style="font-size: 15px;font-weight: bold" *ngIf="model.eventproposedby=='D'">Dealer
                        Contribution:&nbsp;<span>{{model.dealercontri}}</span></span>
                    <!-- model.proposaltype=='Event'&& -->
                </div>
                <div class="col l4 m4 s4" style="margin-top: 5px;">
                    <span
                        style="font-size: 15px;font-weight: bold">{{comapnyname}}&nbsp;Contribution:&nbsp;<span>{{model.companycotri}}</span></span>
                    <!-- *ngIf="model.proposaltype=='Event'" -->
                </div>
                <div class="col l3 m3 s3" style="margin-top: 5px;">
                    <span style="font-size: 15px;font-weight: bold">Proposal
                        Total:&nbsp;<span>{{model.totalcontri}}</span></span>
                </div>
                <div class="col l2 m2 s2" style="text-align: right" *ngIf="operationType!='approve'">
                    <button (click)="btnaddExpense()" type="button" class="waves-effect waves-light btn">Add
                        Expense</button>
                </div>
            </div>

            <!-- New Proposal and Type Event -->
            <div class="row" *ngIf="operationType=='new'&&model.proposaltype=='Event'">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="40%" style="text-align: center">Description</th>
                            <th width="25%" style="text-align: center">Amount</th>
                            <th width="25%" style="text-align: center">Sponsor</th>
                            <th width="10%" style="text-align: center">Action</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of expenselst; let i = index">
                                <td style="text-align: center">{{lst.description}}</td>
                                <td style="text-align: center">{{lst.amount}}</td>
                                <td class="input-field" style="text-align: center">{{lst.sponser}}</td>
                                <td style="text-align: center">
                                    <span><img src="../../../assets/images/delete.png" alt="Delete"
                                            (click)="btndeleteExpense(lst.id)" width="25%" /></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <!-- Approve Proposal And Type Event -->
            <div class="row" *ngIf="operationType=='pending'&&model.proposaltype=='Event'">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">
                            <th width="20%" style="text-align: center">Description</th>
                            <th width="15%" style="text-align: center">Sponsor</th>
                            <th width="15%" style="text-align: center">Sub-Head</th>
                            <th width="8%" style="text-align: center">Unit</th>
                            <th width="7%" style="text-align: center">Qty</th>
                            <th width="15%" style="text-align: center">Amount</th>
                            <th width="10%" style="text-align: center">Balance</th>
                            <th width="15%" style="text-align: center">Total Balance</th>
                            <th width="10%" style="text-align: center">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of expenselst; let i = index">
                                <td style="text-align: center">{{lst.description}}</td>
                                <!-- <td>
                                    <select (change)="onSelectSponser($event.target.value)">
                                        <option *ngFor="let type of sponserlst" [value]="type.name">
                                            {{type.name}}</option>
                                    </select>
                                </td> -->
                                <td class="input-field" style="text-align: center">
                                    <select (change)="onSelectSponser($event.target.value)"
                                        *ngIf="lst.sponser!=comapnyname">
                                        <option *ngFor="let type of sponserlst" [value]="type.name">
                                            {{type.name}}</option>
                                    </select><span *ngIf="lst.sponser==comapnyname">{{lst.sponser}}</span>
                                </td>

                                <td class="input-field" style="text-align: center">
                                    <select (change)="onSelectSubhead($event.target.value,i)" style="margin: 0px;">
                                        <option>Choose One</option>
                                        <option *ngFor="let type of model.mrktmodellst" [value]="type.id">
                                            <!-- [selected]="type.id==lst.id" -->
                                            {{type.name}}
                                        </option>

                                    </select>

                                </td>
                                <td style="text-align: center">{{lst.unit}}</td>
                                <!-- <td style="text-align: center">{{lst.qty}}</td> -->
                                <td *ngIf="lst.chkselectvalue == false">{{lst.qty}}</td>
                                <td style="text-align: center" *ngIf="lst.chkselectvalue == true">
                                    <input [(ngModel)]="lst.qty" #qty="ngModel" type="number" class="" id="search"
                                        data-toggle="tooltip" data-placement="top" min="0" title="Quantity"
                                        style="margin: 0px;text-align: right;">
                                </td>

                                <!-- <td style="text-align: center" *ngIf="lst.sponser!=comapnyname" >{{lst.amount}}</td> -->
                                <!-- *ngIf="lst.sponser==comapnyname" -->
                                <td *ngIf="lst.chkselectvalue == false"><input [(ngModel)]="lst.amount" #amnt="ngModel"
                                        type="number" class="" id="search " data-toggle="tooltip" data-placement="top"
                                        (ngModelChange)="calculateAmount()" min="0" title="Amount"
                                        style="margin: 0px;text-align: right;">
                                </td>
                                <td *ngIf="lst.chkselectvalue == true" style="text-align: center">{{lst.amount}}</td>
                                <td style="text-align: center" *ngIf="lst.balance!= null&&model.proposaltype=='Event'">
                                    {{lst.balance}}
                                </td>
                                <td style="text-align: center"
                                    *ngIf="lst.balance == null &&model.proposaltype=='Event'">{{lst.subheadvalue}}</td>
                                <td style="text-align: center"
                                    *ngIf="operationType=='pending'&&model.proposaltype=='Event'">
                                    {{lst.totalsubheadvalue}}</td>
                                <td style="text-align: center" *ngIf="lst.balance != null || lst.subheadvalue != null">
                                    <span>
                                        <img src="../../../assets/images/delete.png" alt="Delete" width="20px"
                                            (click)="btndeleteforapproveExpense(lst.id,i)" />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <!-- Proposal Disbursment And Type Event -->

            <div class="row" *ngIf="operationType=='approve'&&model.proposaltype=='Event'">
                <div class="col l12 m12 s12">
                    <table>
                        <thead style="background: gainsboro;">

                            <th width="20%" style="text-align: center">Description</th>
                            <th width="15%" style="text-align: center">Sponsor</th>
                            <th width="15%" style="text-align: center">Sub-Head</th>
                            <th width="10%" style="text-align: center">Unit</th>
                            <th width="10%" style="text-align: center">Approved Amount</th>
                            <th width="10%" style="text-align: center">Privious Disbursed Amt</th>
                            <th width="10%" style="text-align: center">Disbursed Amount</th>
                            <th width="5%" style="text-align: center">Balance</th>
                            <!-- <th width="5%" style="text-align: center">Actions</th> -->
                        </thead>
                        <tbody>
                            <tr *ngFor="let lst of expenselst;let j = index">

                                <td style="text-align: center">{{lst.description}}</td>
                                <td style="text-align: center">{{lst.sponser}}</td>
                                <td style="text-align: center">{{lst.subhead}}</td>
                                <td style="text-align: center">{{lst.unit}}</td>
                                <td style="text-align: center">{{lst.approvedamount}}</td>
                                <td style="text-align: center">{{lst.priviousdisburseamt}}</td>
                                <td style="text-align: center"> <input [(ngModel)]="lst.disbursedamount" #amnt="ngModel"
                                        type="number" class="" id="search" data-toggle="tooltip" data-placement="top"
                                        (ngModelChange)="onChangedisbursedamt(j)" title="Amount" style="margin: 0px">
                                </td>
                                <!-- *ngIf="alert==false" -->
                                <!-- *ngIf="alert==true" -->
                                <td style="text-align: center">{{lst.balance}}</td>
                                <!-- <td style="text-align: center">
                                    <span>
                                        <img src="../../../assets/images/delete.png" alt="Delete" width="25%" />
                                    </span>&nbsp;&nbsp;
                                    <span>
                                        <img src="../../../assets/images/edit.png" alt="Edit" width="20px" />
                                    </span>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <!-- New Proposal And Type Standalone -->

            <div class="row" *ngIf="operationType=='new'&&model.proposaltype=='Standalone'">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">

                                    <th width="25%" style="text-align: center"
                                        *ngIf="model.chkdays||model.chknonschedule">Date</th>
                                    <th width="25%" style="text-align: center" *ngIf="model.chkmonths">Month</th>

                                    <th width="40%" style="text-align: center">Description</th>
                                    <th width="25%" style="text-align: center">Proposed Amount</th>
                                    <th width="10%" style="text-align: center">Action</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of expenselst;let i = index">

                                        <td style="text-align: center" *ngIf="model.chkdays||model.chknonschedule">
                                            {{lst.expenseDate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: center" *ngIf="model.chkmonths">{{lst.expenseMonth}}</td>
                                        <td style="text-align: center">{{lst.description}}</td>
                                        <td style="text-align: center">{{lst.amount}}</td>
                                        <td><span>
                                                <img src="../../../assets/images/delete.png" alt="Delete" width="25px"(click)="btndeleteExpense(lst.id)" />
                                            </span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Approve Proposal and Type Standalone -->
            <div class="row" *ngIf="operationType=='pending'&&model.proposaltype=='Standalone'">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">

                                    <th width="5%" style="text-align: center"
                                        *ngIf="model.chkdays||model.chknonschedule">Date</th>
                                    <th width="20%" style="text-align: center" *ngIf="model.chkmonths">Month</th>

                                    <th width="15%" style="text-align: center">Description</th>
                                    <th width="10%" style="text-align: center">Sub-Head</th>
                                    <th width="10%" style="text-align: center">Available Budget</th>
                                    <th width="5%" style="text-align: center">Balance</th>
                                    <th width="10%" style="text-align: center">Qty</th>
                                    <th width="10%" style="text-align: right">Proposed Amount</th>
                                    <th width="5%" style="text-align: center">Action</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of expenselst;let i = index">

                                        <td style="text-align: center" *ngIf="model.chkdays||model.chknonschedule">
                                            {{lst.expenseDate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: center" *ngIf="model.chkmonths">{{lst.expenseMonth}}</td>

                                        <td style="text-align: center">{{lst.description}}</td>
                                        <td class="input-field" style="text-align: center">
                                            <select (change)="onSelectSubhead1($event.target.value,i)"
                                                style="margin: 0px;">
                                                <option>Choose One</option>
                                                <option *ngFor="let type of model.mrktmodellst" [value]="type.id">
                                                    {{type.costhead}}&nbsp;&nbsp;-&nbsp;&nbsp;{{type.name}}</option>
                                            </select>
                                        </td>
                                        <td style="text-align: center">{{lst.subheadvalue}}</td>
                                        <td style="text-align: center">{{lst.balance}}</td>
                                        <td *ngIf="lst.chkselectvalue == false">{{lst.qty}}</td>
                                        <td style="text-align: center" *ngIf="lst.chkselectvalue == true">
                                            <input [(ngModel)]="lst.qty" #qty="ngModel" type="number" class=""
                                                id="search" data-toggle="tooltip" data-placement="top" min="0"
                                                title="Quantity" style="margin: 0px;text-align: right;">
                                        </td>
                                        <!-- <td style="text-align: right"><input type="number" [(ngModel)]="lst.amount"
                                                #amount="ngModel" placeholder="Amount"></td> -->
                                        <td *ngIf="lst.chkselectvalue == false"><input [(ngModel)]="lst.amount"
                                                #amnt="ngModel" type="number" class="" id="search "
                                                data-toggle="tooltip" data-placement="top"
                                                (ngModelChange)="calculateAmount()" min="0" title="Amount"
                                                style="margin: 0px;text-align: right;">
                                        </td>
                                        <td *ngIf="lst.chkselectvalue == true" style="text-align: center">{{lst.amount}}
                                        </td>




                                        <td><span>
                                                <img src="../../../assets/images/delete.png" alt="Delete" width="25px"
                                                    (click)="btndeleteforapproveExpense(lst.id,i)" />
                                            </span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <!--Proposal Disbursment and Type Standalone -->

            <div class="row" *ngIf="operationType=='approve'&&model.proposaltype=='Standalone'">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="15%" style="text-align: center"
                                        *ngIf="model.chkdays||model.chknonschedule">Date</th>
                                    <th width="20%" style="text-align: center" *ngIf="model.chkmonths">Month</th>

                                    <th width="20%" style="text-align: center">Description</th>
                                    <th width="12%" style="text-align: center">Approved Amount</th>
                                    <th width="15%" style="text-align: center">Sub-Head</th>

                                    <th width="15%" style="text-align: center">Privious Disbursed Amount</th>
                                    <th width="10%" style="text-align: center">Disbursed Amount</th>
                                    <th width="10%" style="text-align: center">Balance</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of expenselst;let i = index">
                                        <td style="text-align: center" *ngIf="model.chkdays||model.chknonschedule">
                                            {{lst.expenseDate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: center" *ngIf="model.chkmonths">{{lst.expenseMonth}}</td>

                                        <td style="text-align: center">{{lst.description}}</td>
                                        <td style="text-align: center">{{lst.approvedamount}}</td>
                                        <td style="text-align: center">{{lst.subhead}}</td>

                                        <td>{{lst.priviousdisburseamt}}</td>
                                        <td style="text-align: center"><input type="number"
                                                [(ngModel)]="lst.disbursedamount" #disbursedamount="ngModel"
                                                placeholder="Amount" (ngModelChange)="onChangedisbursedamt(i)"></td>
                                        <!-- (ngModelChange)="calculateAmount()" -->
                                        <td>{{lst.balance}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- New Proposal and Collateral Type -->
            <!-- <div class="row" *ngIf="operationType=='new'&&model.proposaltype=='Collateral'">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="25%" style="text-align: center"
                                        *ngIf="model.chkdays||model.chknonschedule">Date</th>
                                    <th width="25%" style="text-align: center" *ngIf="model.chkmonths">Month</th>

                                    <th width="20%" style="text-align: center">Description</th>
                                    <th width="20%" style="text-align: center">Approved Amount</th>
                                    <th width="25%" style="text-align: center">Disbursed Amount</th>
                                    <th width="10%" style="text-align: center">Balance</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of expenselst">
                                        <td style="text-align: center" *ngIf="model.chkdays||model.chknonschedule">
                                            {{lst.expenseDate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: center" *ngIf="model.chkmonths">{{lst.expenseMonth}}</td>

                                        <td style="text-align: center">{{lst.description}}</td>
                                        <td>{{lst.approvedamount}}</td>
                                        <td style="text-align: center"><input type="number"
                                                [(ngModel)]="lst.disbursedamount" #disbursedamount="ngModel"
                                                placeholder="Amount"></td>
                                        <td>{{lst.balance}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row" *ngIf="operationType=='new'&& model.proposaltype=='Collateral'">
                <!-- operationType=='new'&& -->
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th></th>
                                    <th width="15%" style="text-align: center">Cost Head</th>
                                    <th width="15%" style="text-align: center">Sub Head</th>

                                    <th width="15%" style="text-align: center">UOM</th>
                                    <th width="10%" style="text-align: center">Budgeted</th>
                                    <th width="15%" style="text-align: center">Proposed</th>
                                    <th width="10%" style="text-align: center">Balance</th>
                                    <th width="10%" style="text-align: center">Rate</th>
                                    <th width="10%" style="text-align: center">Amount</th>
                                </thead>
                                <tbody>
                                    <!-- *ngFor="let lst of expenselst" -->
                                    <tr *ngFor="let lst of expenselst; let i = index">
                                        <td><input type="checkbox" [(ngModel)]="lst.chkselect" #chkselect="ngModel"
                                                (click)="Onucheckselect(i)"></td>
                                        <td style="text-align: center">{{lst.costhead}}</td>
                                        <td style="text-align: center">{{lst.subhead}}</td>
                                        <td style="text-align: center">{{lst.unit}}</td>
                                        <td style="text-align: center">{{lst.budgeted}}</td>
                                        <td style="text-align: center">{{lst.qty}}</td>
                                        <td style="text-align: center">{{lst.balance}}</td>
                                        <td style="text-align: center">{{lst.rate}}</td>
                                        <td style="text-align: center">{{lst.finalamount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="operationType=='pending'&& model.proposaltype=='Collateral'">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th></th>
                                    <th width="15%" style="text-align: center">Cost Head</th>
                                    <th width="15%" style="text-align: center">Sub Head</th>

                                    <th width="15%" style="text-align: center">UOM</th>
                                    <th width="10%" style="text-align: center">Budgeted</th>
                                    <th width="15%" style="text-align: center">Proposed</th>
                                    <th width="10%" style="text-align: center">Balance</th>
                                    <th width="10%" style="text-align: center">Rate</th>
                                    <th width="10%" style="text-align: center">Amount</th>
                                </thead>
                                <tbody>
                                    <!-- *ngFor="let lst of expenselst" -->
                                    <tr *ngFor="let lst of expenselst; let i = index">
                                        <td><input type="checkbox" [(ngModel)]="lst.chkselect" #chkselect="ngModel"
                                                (click)="Onucheckselect(i)"></td>
                                        <td style="text-align: center">{{lst.costhead}}</td>
                                        <td style="text-align: center">{{lst.subhead}}</td>
                                        <td style="text-align: center">{{lst.unit}}</td>
                                        <td style="text-align: center">{{lst.budgeted}}</td>
                                        <td style="text-align: center">
                                            <input type="number" [(ngModel)]="lst.qty" #qty="ngModel" min="0"
                                                placeholder="Proposed" style="text-align: right;"
                                                (ngModelChange)="onChangeQuantity(i)">
                                        </td>
                                        <td style="text-align: center"><span
                                                *ngIf="lst.alert == false">{{lst.balance}}</span><span
                                                *ngIf="lst.alert == true" style="color: red;">{{lst.balance}}</span>
                                        </td>
                                        <td style="text-align: center">{{lst.rate}}</td>
                                        <td style="text-align: center">{{lst.finalamount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="operationType=='approve'&& model.proposaltype=='Collateral'">
                <div class="col l12 m12 s12">
                    <div class="row">
                        <div class="col l12 m12 s12">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th></th>
                                    <th width="15%" style="text-align: center">Cost Head</th>
                                    <th width="15%" style="text-align: center">Sub Head</th>
                                    <th width="15%" style="text-align: center">UOM</th>
                                    <th width="10%" style="text-align: center">Privious Disbursed Amt</th>
                                    <th width="10%" style="text-align: center">Disbursed</th>
                                    <th width="15%" style="text-align: center">Approved</th>
                                    <th width="10%" style="text-align: center">Balance</th>
                                    <th width="10%" style="text-align: center">Rate</th>
                                    <th width="10%" style="text-align: center">Amount</th>
                                </thead>
                                <tbody>
                                    <!-- *ngFor="let lst of expenselst" -->
                                    <tr *ngFor="let lst of expenselst; let i = index">
                                        <td><input type="checkbox" [(ngModel)]="lst.chkselect" #chkselect="ngModel"
                                                (click)="Onucheckselect(i)"></td>
                                        <td style="text-align: center">{{lst.costhead}}</td>
                                        <td style="text-align: center">{{lst.subhead}}</td>
                                        <td style="text-align: center">{{lst.unit}}</td>
                                        <td style="text-align: center">{{lst.priviousdisburseamt}}</td>
                                        <td style="text-align: center"><input type="number"
                                                [(ngModel)]="lst.disbursedamount" #disbursedamount="ngModel" min="0"
                                                placeholder="Disbursed Amount"
                                                (ngModelChange)="onChangedisbursedamt(i)"></td>
                                        <td style="text-align: center">{{lst.approvedamount}}</td>
                                        <td style="text-align: center"><span
                                                *ngIf="lst.alert == false">{{lst.balance}}</span><span
                                                *ngIf="lst.alert == true" style="color: red;">{{lst.balance}}</span>
                                        </td>
                                        <!-- <td style="text-align: center">{{lst.balance}}</td> -->
                                        <td style="text-align: center">{{lst.rate}}</td>
                                        <td style="text-align: center">{{lst.finalamount}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="operationType=='pending'">
                <div class="col l1 m1 s1"></div>
                <div class="input-field col l2 m2 s2">
                    <label>
                        <input name="group4" type="radio" [checked]="model.chkApproved"
                            (click)="onchkApproved($event)" />
                        <span style="font-size: 104%">Approve</span>
                    </label>
                </div>
                <div class="input-field col l2 m2 s2">
                    <label>
                        <input name="group4" type="radio" [checked]="model.chkReject" (click)="onchkReject($event)" />
                        <span style="font-size: 104%">Reject</span>
                    </label>
                </div>


                <div class="col l1 m1 s1"></div>
            </div>

            <div class="row" *ngIf="operationType=='approve'">
                <div class="col l2 m2 s2" style="margin-top: 2%;text-align: center;">
                    <input type="checkbox" [(ngModel)]="model.closeEvent" #closeEvent="ngModel">&nbsp;Close
                    Event
                </div>
                <div class="input-field col l5 m5 s5">
                    <input [(ngModel)]="model.paymentdetails" #paymentdetails="ngModel" type="text" class=""
                        id="paymentdetails" data-toggle="tooltip" data-placement="top" title="Payment details entry"
                        style="margin: 0px">
                    <label for="paymentdetails">Payment details entry</label>
                </div>
                <div class="input-field col l5 m5 s5" *ngIf="model.proposaltype=='Event'">
                    <input [(ngModel)]="model.collateralsIissuesnotes" #collateralsIissuesnotes="ngModel" type="text"
                        class="" id="collateralsIissuesnotes" data-toggle="tooltip" data-placement="top"
                        title="Collaterals issue notes" style="margin: 0px">
                    <label for="collateralsIissuesnotes">Collaterals issue notes</label>
                </div>
            </div>


            <div class="row">
                <div class="input-field col l12 m12 s12" style="text-align:right">
                    <button (click)="btnback()" type="button" class="waves-effect cancelbutton btn">Cancel</button>
                    &nbsp;&nbsp; <button (click)="btnsaveMarketingproposal()" *ngIf="operationType!='pending'"
                        type="button" class="waves-effect waves-light btn">Save</button>
                    <button (click)="btnApprove()" *ngIf="operationType=='pending'" type="button"
                        class="waves-effect waves-light btn">Approve</button>
                </div>
            </div>

        </div>
    </div>
</div>