import { QuotationProductModel } from '../Quotation/QuotationModel';

export class PaymentScreenModel {
    id:number;
    userid: number;
    accid: number;
    accname: string;
    address: string;
    ctypeid: number;
    ctype: string;
    quoCode: string;
    invoiceno: string;
    docDate: Date;
    datetime:Date ;  
    paymentTerms: string;
    cashcode:string;
    approvalCode: string;
    schmemCode: string;
    chkterms: boolean

    bankname: string;
    chequeno: string;
    chequedate: Date
    chamount: number;

    subtotal: number;
    gstamount: number;
    grandtotal: number;

    productEnquirylst: QuotationProductModel[];
    sparelst: SpareModel[] = [];
    serviceQuotationlst:QuotationProductModel[];
    gstseperate:boolean;
    companyname:string;
    companyaddress:string;
    gstno:string;
    telephone:string;
}

export class SpareModel {
    id: number;
    spareid: number;
    sparedescription: string;
    uom: string;
    quantity: number;
    totalamount:number;
    gstamount: number;
    gstpersentage: number;
    rate: number;
    taxpercent:number;
    listprice:number;
    amount: number;
    spareProductid:number;
    rateWithGst:number;
    finalamount:number;
    ratewithDiscount:number;
}