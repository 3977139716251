import { Component } from "@angular/core";
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { SearchUOMModel } from "./SearchUOMModel";
import { Location } from '@angular/common';
import { User } from "src/app/_models";


@Component({
    selector: 'app-DTypeMaster',
    //  moduleId: module.id,
    templateUrl: 'SearchUOM.html'
})
export class SearchUOM implements OnInit
{
    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location) {
        this.pagename=this.router.url
    }
    p: any;
    user = new User();
    model=new SearchUOMModel();
    uomllst: SearchUOMModel[] = [];
    pagename: string;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Code" },
        { id: 3, "name": "Name" }
    ];
    ngOnInit(): void {
        this.getAllUOM()
        localStorage.setItem("pagename",this.pagename);
    }
    getAllUOM() {
        this.Masterservice.getAllUOM(this.user)
            .subscribe(value => {
                this.uomllst = value;
                //this.typelst1 = value;
            });
    }
    onSelectSrch(value){

    }

    btnsearch(){
    
    }

    btnnew(){

    }
    
    
}