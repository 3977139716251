import { DatePipe } from '@angular/common';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/_models';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { FinancialyearModel } from './FinancialyearModel';
import { createFalse } from 'typescript';
import { getMatFormFieldDuplicatedHintError } from '@angular/material/form-field';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
@Component({
    selector: 'app-login',
    templateUrl: 'Financialyear.html'
})


export class Financialyear implements OnInit {

    model: any = {};
    sum: any;
    user = new User();
    sub: any;
    id: number;
    p: any;
    Date = new Date();
    public i;
    pagename: string;

    financlst: FinancialyearModel[] = [];
    
    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
            this.pagename=this.router.url
        }


    ngOnInit(): void {
        this.getfinancialyear();
        this.model.userid = Number(localStorage.getItem("userid"));
       // localStorage.setItem("pagename",this.pagename);
       if (this.pagename != null) {
        this.pagename = this.pagename.replace('/', '');
        this.pagename = this.pagename.replace('/', '');
        this.pagename = this.pagename.replace('%20', ' ');
        this.pagename = this.pagename.replace('%20', ' ');

        this.model.yearFrom = new Date()
        this.model.yearTo = new Date()
    }

    }
    getfinancialyear() {
        this.Masterservice.getfinancialyear(this.model)
            .subscribe(value => {
                this.financlst = value;
                // for(let i = 0 ; i < this.financlst.length; i++)
                // {
                //     this.model.id = this.financlst[i].financialyearid;
                // }

            })
    }

    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.Masterservice.deletefinanalyer(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }
    }
    btnedit(lst) {
        this.model.id = lst.financialyearid;
        this.model.yearFrom = new Date(lst.yearFrom);
        this.model.yearTo = new Date(lst.yearTo);
        this.model.financialYear = lst.financialYear;
        this.model.currentYear = lst.currentYear;
        if (this.model.currentYear == "Yes") {
            this.model.chkselecurrentYear = true;
        } else if (this.model.currentYear == "No") {
            this.model.chkselecurrentYear = false;
        }


    }
    btnsave($event) {
        if (this.model.yearFrom == null) {
            this.notifyService.showRroor("Select From Date  !!", "Oops!");
            return;
        }
        if (this.model.yearTo == null ) {
            this.notifyService.showRroor("Select To Date  !!", "Oops!");
            return;
        }
        if (this.model.yearFrom > this.model.yearTo) {
            this.notifyService.showRroor("End Date Should be after Start Date!", "Opps!");
            return;
        }
        // if (this.model.yearFrom || this.model.yearTo == this.model.yearFrom || this.model.yearTo) {
        //     this.notifyService.showRroor("Date is Already Created!", "Opps!");
        //     return;
        // }

        // this.model.id = this.id;
        this.Masterservice.savefinancialyear(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit();
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    cancle() {
        this.router.navigate(["/CRMHome"]);
    }
    selectFromDate(date)
    {
        if (date < new Date()) {
            this.notifyService.showRroor("Previous date cannot be selected", "oops");
            this.model.yearFrom = new Date()
            return;
          }
    }
    selectToDate(date)
    {
        if (date < this.model.yearFrom) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.yearTo = this.model.yearFrom
            return;
          }
    }

}
