export class DefineAMCTypeModel{
    id:number;
    code:string;
    name:string;
    lab:boolean;
    com:boolean
    amcFlag:string;
    txtserch:string;
    txtselect:string;
    userid:number;
}