import { OnInit, Component } from '@angular/core';
import { MasterServices, TransactionServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { AreaModel1, ProductCategory1, ResourceModel1, LocalePincodeModel, TeamModel } from './TeamModel';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { ResourceCType } from 'src/app/Master/ResourceRights/ProductResourceRightsModel';

declare var $: any;
@Component({
    selector: 'app-nTeam',
    templateUrl: 'DefineTeam.html'
})
export class DefineTeam implements OnInit {
    constructor(private Masterservice: MasterServices,
        private route: ActivatedRoute,
        private notifyService: NotificationService,
        private router: Router,
        private transactionservice: TransactionServices) {
    }
    model: any = {};
    model1: any = {};

    // model = new TeamModel();
    user = new User();
    id: number;
    arealst: AreaModel1[] = [];
    arealst1: AreaModel1[] = [];
    procatlst: ProductCategory1[] = [];
    procatlst1: ProductCategory1[] = [];
    resoureslst: ResourceModel1[] = [];
    citylst: AreaModel1[] = [];
    lastkeydown2: number = 0;

    areaInt: number[] = [];
    pcInt: number[] = [];
    typeInt: number[] = [];

    regionlst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    customertyps: ResourceCType[] = [];
    localpincodelst: LocalePincodeModel[] = [];
    checkedloc: number[] = [];
    uncheckedloc: number[] = [];

    checkedloc1: object[] = [];
    uncheckedloc1: object[] = [];
    allcheckselect: boolean;
    allCitycheckselect:boolean;
    locationlst: DropDownModel[];
    tolocationlst: DropDownModel[];
    showBtn: boolean = false;
    itemlst: TeamModel[] = [];
    temp: boolean = true;

    ngOnInit(): void {
        this.temp = true;
        this.getRegions();
        this.getBranches();
        this.getInventoryLocation();
        this.model.userid = Number(localStorage.getItem("userid"));
        this.route.params.subscribe(params => {
            this.id = + params["id"];
        });
        if (this.id != null && this.id > 0) {
            this.user.trnid = this.id
            this.transactionservice.getTeamById(this.user)
                .subscribe(
                    data => {
                        this.model = data;
                        this.arealst = data.arealst
                        this.model.cityid = data.cityid;
                        this.procatlst = data.procatlst
                        this.resoureslst = data.resoureslst;
                        this.customertyps = data.customertyps;
                        this.model.teamcode = data.teamcode;
                        this.localpincodelst = data.localpincodelst;
                        this.model1.name = data.name;

                        if (this.model.tolocationid != null) {
                            this.tolocationlst = [];
                            for (let i = 0; i < this.locationlst.length; i++) {
                                if (this.locationlst[i].id != this.model.locationid) {

                                    this.tolocationlst.push({
                                        id: this.locationlst[i].id,
                                        name: this.locationlst[i].name,
                                    });
                                    // this.model.tolocationid = this.locationlst[i].id
                                    // this.model.tolocationname = this.locationlst[i].name
                                }

                            }
                        }
                        this.getLocaleAndPincodeByCity();
                    });


        } else {
            this.getProductcategories();
            this.getcustomertyps()
        }

    }

    getProductcategories() {
        this.procatlst = [];
        this.Masterservice.getAllProductCategorylst(this.user)
            .subscribe(value => {
                this.procatlst = value
            });
    }

    getBranches() {
        this.branchlst = []
        this.Masterservice.getAllBranches(this.user)
            .subscribe(value => {
                this.branchlst = value
            });
    }

    getRegions() {
        this.regionlst = []
        this.Masterservice.getAllRegions(this.user)
            .subscribe(value => {
                this.regionlst = value
            });
    }

    getcustomertyps() {

        this.customertyps = [];
        this.Masterservice.getAllcustomertyps(this.user)
            .subscribe(value => {
                this.customertyps = value
            });
    }

    onSelectRegions(val) {
        if (val == 0) {
            this.notifyService.showRroor("Select Region!!", "Oops!");
            return;
        }
        this.arealst = [];
        this.model.regionid = val
        this.user.trnid = val;
        this.Masterservice.getAreaByRegion(this.user)
            .subscribe(value => {
                this.arealst = value
            });
    }

    onSelectCity1()
    {
       // alert("LL");
        this.allcheckselect = true;
    this.allCitycheckselect = true;
        for (let i = 0; i < this.arealst.length; i++) {
            this.areaInt.push(this.arealst[i].areaid);
            this.arealst[i].chkselectarea=true;
             for (let j = 0; i < this.arealst[i].citylst.length; j++) {
                this.onSelectCity2(this.arealst[i].areaid,this.arealst[i].citylst[j].cityid);
             }
    }
    

   
}

    onSelectBranches(val) {
        if (val == 0) {
            this.notifyService.showRroor("Select Branch !!", "Oops!");
            return;
        }
        this.model.branchid = val
    }

    getAutoc1($event) {
        let userId = (<HTMLInputElement>document.getElementById('autoc1')).value;
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length > 3) {
            if ($event.timeStamp - this.lastkeydown2 > 200) {
                let map = new Map();
                console.log('VALU ' + userId);
                var names = "";
                for (let i = 0; i < this.arealst.length; i++) {
                    map.set(this.arealst[i].areaname, this.arealst[i].areaname)
                    searchDataObj[this.arealst[i].areaname] = null;
                    if (i == 0) {
                        names = this.arealst[i].areaname;
                    } else {
                        names = names + "," + this.arealst[i].areaname;
                    }
                }
                $(document).ready(function () {
                    $(".only-numeric").bind("keypress", function (e) {
                        var keyCode = e.which ? e.which : e.keyCode
                        if (!(keyCode >= 48 && keyCode <= 57)) {
                            $(".error").css("display", "inline");
                            return false;
                        } else {
                            $(".error").css("display", "none");
                        }
                    });
                });
                $(document).ready(function () {
                    $('input.typeahead').autocomplete({
                        onAutocomplete: function (txt) {
                            obj.autoc1(map.get(txt), 1);
                        },
                    });
                    console.log(searchDataObj)
                    $('input.autocomplete').autocomplete({
                        data: searchDataObj,
                        onAutocomplete: function (txt) {
                            obj.autoc1(map.get(txt), 1);
                        },
                    });
                });
            }
        }
    }

    autoc1(val, val2) {
        this.arealst1 = [];
        for (let i = 0; i < this.arealst.length; i++) {
            if (this.arealst[i].areaname == val) {
                this.arealst1.push({
                    areaname: this.arealst[i].areaname,
                    areaid: this.arealst[i].areaid,
                    chkselectarea: this.arealst[i].chkselectarea,
                    citylst: null,
                })
            }
        }
        for (let i = 1; i < this.arealst.length; i++) {
            if (this.arealst1[0].areaname != this.arealst[i - 1].areaname) {
                this.arealst1.push({
                    areaname: this.arealst[i].areaname,
                    areaid: this.arealst[i].areaid,
                    chkselectarea: this.arealst[i].chkselectarea,
                    citylst: null,
                })
            }
        }
        this.arealst = [];
        this.arealst = this.arealst1
    }


    getAutoc2($event) {
        let userId = (<HTMLInputElement>document.getElementById('autoc2')).value;
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length > 3) {
            if ($event.timeStamp - this.lastkeydown2 > 200) {
                let map = new Map();
                console.log('VALU ' + userId);
                var names = "";
                for (let i = 0; i < this.procatlst.length; i++) {
                    map.set(this.procatlst[i].pcname, this.procatlst[i].pcname)
                    searchDataObj[this.procatlst[i].pcname] = null;
                    if (i == 0) {
                        names = this.procatlst[i].pcname;
                    } else {
                        names = names + "," + this.procatlst[i].pcname;
                    }
                }
                $(document).ready(function () {
                    $(".only-numeric").bind("keypress", function (e) {
                        var keyCode = e.which ? e.which : e.keyCode
                        if (!(keyCode >= 48 && keyCode <= 57)) {
                            $(".error").css("display", "inline");
                            return false;
                        } else {
                            $(".error").css("display", "none");
                        }
                    });
                });
                $(document).ready(function () {
                    $('input.typeahead').autocomplete({
                        onAutocomplete: function (txt) {
                            obj.autoc2(map.get(txt), 1);
                        },
                    });
                    console.log(searchDataObj)
                    $('input.autocomplete').autocomplete({
                        data: searchDataObj,
                        onAutocomplete: function (txt) {
                            obj.autoc2(map.get(txt), 1);
                        },
                    });
                });
            }
        }
    }

    autoc2(val, val2) {
        this.arealst1 = [];
        for (let i = 0; i < this.procatlst.length; i++) {
            if (this.procatlst[i].pcname == val) {
                this.procatlst1.push({
                    pcname: this.procatlst[i].pcname,
                    pcid: this.procatlst[i].pcid,
                    chkselectpc: this.procatlst[i].chkselectpc
                })
            }
        }

        for (let i = 1; i < this.procatlst.length; i++) {
            if (this.procatlst1[0].pcname != this.procatlst[i - 1].pcname) {
                this.procatlst1.push({
                    pcname: this.procatlst[i].pcname,
                    pcid: this.procatlst[i].pcid,
                    chkselectpc: this.procatlst[i].chkselectpc
                })
            }
        }
        this.procatlst = [];
        this.procatlst = this.procatlst1
    }

    btnShowResources() {
         
        this.areaInt = [];
        this.pcInt = [];
       // alert("this.arealst.length "+this.arealst.length);
        for (let i = 0; i < this.arealst.length; i++) {
           
                this.areaInt.push(this.arealst[i].areaid);
            
        }
        for (let i = 0; i < this.procatlst.length; i++) {
            if (this.procatlst[i].chkselectpc == true) {
                this.pcInt.push(this.procatlst[i].pcid);
            }
        }
        for (let i = 0; i < this.customertyps.length; i++) {
            if (this.customertyps[i].chkselecttype == true) {
                this.typeInt.push(this.customertyps[i].id)
            }
        }

        this.model.areaInt = this.areaInt;
        this.model.pcInt = this.pcInt;
        this.model.typeInt = this.typeInt;


        this.transactionservice.getResourceForTeam(this.model)
            .subscribe(value => {
                this.resoureslst = value
                for (let i = 0; i < this.resoureslst.length; i++) {
                    if (this.resoureslst[i].callEscalationDays == null) {
                        this.resoureslst.push({
                            resourceID: null,
                            name: null,
                            chkselectres: null,
                            hierarchy: null,
                            callEscalationDays: 1,
                            approval: null,
                            discount: null,
                            chkassignto: null,
                        })
                    }

                }

            });
    }

    btnsaveTeam() {


        if (this.model.teamcode == null) {
            this.notifyService.showRroor("Please enter Team code!", "Oops !")
            return;
        }
        if (this.model.regionid == null) {
            this.notifyService.showRroor("Please select Region!", "Oops !")
            return;
        }
        if (this.model.branchid == null) {
            this.notifyService.showRroor("Please select Branch!", "Oops !")
            return;
        }
        if (this.model.chkInstallation == true && this.model.invIntegration) {

            if (this.model.locationid == null) {
                this.notifyService.showRroor("Please select Issue Location", "Oops!");
                return;
            }

            if (this.model.tolocationid == null) {
                this.notifyService.showRroor("Please select Supply Location", "Oops!");
                return;
            }

        }
        if (this.resoureslst.length == 0) {
            this.notifyService.showRroor("Please select at least one resource", "Oops!");
            return;
        }
        if (this.resoureslst.length == 1) {
            for (let i = 0; i < this.resoureslst.length; i++) {
                if (this.resoureslst[i].hierarchy == null) {
                    this.notifyService.showRroor("Please select hierarchy", "Oops !")
                    return;
                }
            }
        }
        this.model.arealst = this.arealst;
        this.model.procatlst = this.procatlst;
        this.model.resoureslst = this.resoureslst;
        this.model.localpincodelst = this.localpincodelst;
        this.model.savedlocale = this.checkedloc;
        this.model.unsavedlocale = this.uncheckedloc;

        this.model.savedlocale1 = this.checkedloc1;
        this.model.unsavedlocale1 = this.uncheckedloc1;
        this.model.customertyps = this.customertyps;
        this.model.allcheckselect = this.allcheckselect;
        this.showBtn = true;
        this.model.storekeeperid = this.model1.storekeeperid;
        this.model.name = this.model1.name;
        this.transactionservice.saveTeamData(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess(data.message, "Success !");
                        this.router.navigate(['/Team'])
                    } else {
                        this.notifyService.showRroor("Something Wrong !", "Oops !")
                    }
                });
    }

    cancle() {
        this.router.navigate(['/Team'])
    }

    // onclickarea(val) {
    //     this.model.areaid = val;
    // }

    onSelectCity(val, val2) {
        this.model.cityid = val;
        this.model.areaid = val2;
        this.getLocaleAndPincodeByCity();
    }
    onSelectCity2(val, val2) {
        this.model.cityid = val;
        this.model.areaid = val2;
        this.getLocaleAndPincodeByCity2();
    }
    getLocaleAndPincodeByCity2() {
        //this.localpincodelst = [];
        this.user.cityid = this.model.cityid;
        this.user.areaid = this.model.areaid;
        this.user.trnid = this.id;
        
        this.transactionservice.getLocaleAndPincode(this.user)
            .subscribe(data => {
                for (let i = 0; i < data.localpincodelst.length; i++) {
                    const locPin = new LocalePincodeModel();
            locPin.pincode = data.localpincodelst[i].pincode;
            locPin.localeid = data.localpincodelst[i].localeid;

            
                 this.checkedloc1.push(locPin);
                
                //sthis.localpincodelst.push(locPin);
                }
                
            })
        }
    getLocaleAndPincodeByCity() {
        this.localpincodelst = [];
        this.user.cityid = this.model.cityid;
        this.user.areaid = this.model.areaid;
        this.user.trnid = this.id;
        
        this.transactionservice.getLocaleAndPincode(this.user)
            .subscribe(data => {
                this.localpincodelst = data.localpincodelst
                this.model.allcheckselect = data.allcheckselect
                this.allcheckselect = data.allcheckselect
            })
    }
    // onselectlocal($event, localeid, i) {
    //     if ($event.target.checked) {
    //         this.model.localeid = localeid;
    //         this.checkedloc.push(localeid)
    //         const index = this.uncheckedloc.indexOf(localeid, 0);
    //         if (index > -1) {
    //             this.uncheckedloc.splice(index, 1);
    //         }
    //     }
    //     else {
    //         const index = this.checkedloc.indexOf(localeid, 0);
    //         if (index > -1) {
    //             this.checkedloc.splice(index, 1);
    //         }
    //         this.model.localeid = localeid;
    //         this.uncheckedloc.push(localeid)
    //     }
    // }
    onselectlocalandpincode($event, localeid, pincode, i) {
        if ($event.target.checked) {

            const locPin = new LocalePincodeModel();
            locPin.pincode = pincode;
            locPin.localeid = localeid;

            this.model.localeid = localeid;
            this.checkedloc1.push(locPin)
            const index = this.uncheckedloc1.indexOf(locPin, 0);
            if (index > -1) {
                this.uncheckedloc1.splice(index, 1);
            }
        }
        else {
            const locPin = new LocalePincodeModel();
            locPin.pincode = pincode;
            locPin.localeid = localeid;
            const index = this.checkedloc1.indexOf(locPin, 0);
            if (index > -1) {
                this.checkedloc1.splice(index, 1);
            }
            this.model.localeid = localeid;
            this.uncheckedloc1.push(locPin)
        }
    }

    onselectall($event) {
        if ($event.target.checked) {
            for (let i = 0; i < this.localpincodelst.length; i++) {
                this.localpincodelst[i].chkselectlocale = true;
                const locPin = new LocalePincodeModel();
                locPin.pincode = this.localpincodelst[i].pincode;
                locPin.localeid = this.localpincodelst[i].localeid;
                this.model.localeid = this.localpincodelst[i].localeid
                this.checkedloc1.push(locPin)
                const index = this.uncheckedloc1.indexOf(locPin, 0);
                if (index > -1) {
                    this.uncheckedloc1.splice(index, 1);
                }
            }
        } else {
            for (let i = 0; i < this.localpincodelst.length; i++) {
                this.localpincodelst[i].chkselectlocale = false;
                const locPin = new LocalePincodeModel();
                locPin.pincode = this.localpincodelst[i].pincode;
                locPin.localeid = this.localpincodelst[i].localeid;
                this.model.localeid = this.localpincodelst[i].localeid;
                this.uncheckedloc1.push(locPin)
                const index = this.checkedloc1.indexOf(locPin, 0);
                if (index > -1) {
                    this.checkedloc1.splice(index, 1);
                }

                // this.modelItemlst.splice(this.modelItemlst.indexOf(this.modelItemlst[i]), 1);

            }
        }
    }
    getInventoryLocation() {
        this.locationlst = [];
        this.tolocationlst = [];
        this.transactionservice.getInventoryLocation(this.model)
            .subscribe(data => {
                this.model = data;
                this.locationlst = data.locationLst
                // this.tolocationlst = data.locationLst
            });
    }
    onSelectInventoryLocationFrom(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id == value) {
                this.model.locationid = this.locationlst[i].id;
                this.model.locationname = this.locationlst[i].name;
                // this.model.tolocationname = this.locationlst[i].name;
            }
        }
        this.tolocationlst = [];
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id != this.model.locationid) {

                this.tolocationlst.push({
                    id: this.locationlst[i].id,
                    name: this.locationlst[i].name,
                });
                // this.model.tolocationid = this.locationlst[i].id
                // this.model.tolocationname = this.locationlst[i].name
            }

        }
    }
    onSelectInventoryLocationTo(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.tolocationlst.length; i++) {
            if (this.tolocationlst[i].id == value) {
                this.model.tolocationname = this.tolocationlst[i].name;
            }
        }
        this.model.tolocationid = value;
        this.Masterservice.getstorekeeper(this.model)
            .subscribe((value) => {
                this.model1 = value

            });

    }
    onOrderAccept() {
        if (this.model.chkOrderAccept) {
            this.model.chkInstallation = false;
            this.model.chkSale = false;
            this.model.chkService = false;
            }
      }
    oninstall() {
        if (this.model.chkInstallation) {
            this.model.chkOrderAccept = false;
        }
    }
    onsale() {
        if (this.model.chkSale) {
            this.model.chkOrderAccept = false;
        }
    }
    onchkService() {
        if (this.model.chkService) {
            this.model.chkOrderAccept = false;
            this.model.chkInstallation = false;

        }
    }
}
