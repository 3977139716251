import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AlertService, DealersService, MasterServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { Location, } from '@angular/common';
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { StockadjustmentModel } from "./StockadjustmentModel";
declare var $: any;
@Component({
    selector: 'app-order',
    templateUrl: 'Stockadjustment.html'
})
export class Stockadjustment implements OnInit {
    user = new User();
    model: any = {};
    id: number;
    p: any;
    locationlst: DropDownModel[];
    stocklist: StockadjustmentModel[] = [];
    itmCatlst: DropDownModel[] = [];
    subCatlst: DropDownModel[] = [];
    mrpmap = new Map();
    userList2: any;
    itemname: string;
    itemcodemap = new Map();
    uommap = new Map();
    actuStockmap = new Map();
    public i;
    keyword1 = 'itemname';
    public adjusttypelst: DropDownModel[] = [
        { id: 0, "name": "Select Adjustment Type" },
        { id: 2, "name": "Debit" },
        { id: 3, "name": "Credit" },
     ];
    sub: any;
    constructor(private Masterservice: MasterServices, private datePipe: DatePipe, private dealersService: DealersService, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }

    ngOnInit(): void {
        this.stocklist = [];
        this.getInventoryLocation();
        this.getauthorizedBy();
        this.getitemCategory();
        this.model.userid = Number(localStorage.getItem("userid"));


    }
    getInventoryLocation() {
        this.locationlst = [];

        this.transactionService.getInventoryLocation(this.user)
            .subscribe(data => {
                this.model = data;
                this.locationlst = data.locationLst
            });
    }

    onSelectInventoryLocation(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Location !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.locationlst.length; i++) {
            if (this.locationlst[i].id == value) {
                this.model.locationid = this.locationlst[i].id;
                this.model.locationname = this.locationlst[i].name;
            }
        }


    }
    getauthorizedBy() {

        this.model.userid = Number(localStorage.getItem('userid'));
        this.transactionService.getauthorizedBy(this.model)
            .subscribe(data => {
                this.model.authorizedby = data.authorizedby;

            });

    }
    getitemCategory() {
        let user = new User();
        this.itmCatlst = [];
        this.Masterservice.getitemCategory(user)
            .subscribe((value) => {
                this.itmCatlst = value
            });
    }

    onSelectitemCat(value) {
        let user = new User();
        this.subCatlst = [];
        this.model.itemcategoryid = value;
        for (let i = 0; i < this.itmCatlst.length; i++) {
            if (this.itmCatlst[i].id == value) {
                this.model.itemcategory = this.itmCatlst[i].name
            }
        }
        user.trnid = value
        this.Masterservice.getSubCategory(user)
            .subscribe((value) => {
                this.subCatlst = value
            });
    }
    onSelectSubCat(value) {
        this.model.itemsubcategoryid = value;

        for (let i = 0; i < this.subCatlst.length; i++) {
            if (this.subCatlst[i].id == value) {
                this.model.itemsubcategory = this.subCatlst[i].name
            }
        }
    }
    getItemLike($event) {
        alert(this.model.locationid)
        let userId = (<HTMLInputElement>document.getElementById('itmname')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        this.user.locationID = this.model.locationid
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getItemLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "uom": item.uom, "actuStock": item.actuStock }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].itemname, this.userList2[i].itemid)
                        this.uommap.set(this.userList2[i].itemname, this.userList2[i].uom)
                        this.itemcodemap.set(this.userList2[i].itemname, this.userList2[i].itemcode)
                        this.actuStockmap.set(this.userList2[i].itemname, this.userList2[i].actuStock)

                        searchDataObj[this.userList2[i].itemname] = null;
                        if (i == 0) {
                            names = this.userList2[i].itemname;
                        } else {
                            names = names + "," + this.userList2[i].itemname;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect1(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }

    typeaheadOnSelect1(itemid, itemname) {
        this.model.itemid = itemid;
        this.model.itemname = itemname;
        this.model.itemcode = this.itemcodemap.get(itemname);
        this.model.uom = this.uommap.get(itemname);
        this.model.actuStock = this.actuStockmap.get(itemname);
    }

    btnadd() {

        if (this.model.locationname == null) {
            this.notifyService.showRroor("Please Select Inventory Location", " Oops");
            return;
        }
        for (let i = 0; i < this.stocklist.length; i++) {
            if (this.stocklist[i].itemid == this.model.itemid) {
                this.notifyService.showRroor("Item already present", " Oops");
                return;
            }
        }
        if (this.model.itemcategoryid == null) {
            this.notifyService.showRroor("Please Select Itemcategory", " Oops");
            return;
        }
        if (this.model.itemsubcategoryid == null) {
            this.notifyService.showRroor("Please Select ItemSubcategory", " Oops");
            return;
        }
        if (this.model.itemname == null) {
            this.notifyService.showRroor("Please Select Itemname", " Oops");
            return;
        }

        this.stocklist.push({
            id: this.model.id,
            locationid: this.model.locationid,
            locationname: this.model.locationname,
            userid: this.model.userid,
            quantity: this.model.quantity,
            itemcategoryid: this.model.itemcategoryid,
            itemsubcategoryid: this.model.itemsubcategoryid,
            itemname: this.model.itemname,
            itemcategory: this.model.itemcategory,
            itemsubcategory: this.model.itemsubcategory,
            itemid: this.model.itemid,
            uom: this.model.uom,
            type: this.model.type,
            adjustquantity: this.model.adjustquantity,
            authorizedby: this.model.authorizedby,
            actuStock: this.model.actuStock,
            newstock: this.model.newstock,
            remark: this.model.remark,
            itemcode: this.model.itemcode,
            locationLst: null,
            tolocationlst: null,
            requestor: "",
            name: this.model.name,
            tolocationname: "",
            invIntegration: this.model.invIntegration,
        })
        this.onChangeOfqantity();

    }
    onSelectadjustmentType(value) {
        this.model.type = value;

        this.onChangeOfqantity();


    }
    onChangeOfqantity() {
        for (let i = 0; i < this.stocklist.length; i++) {
            if (this.stocklist[i].adjustquantity == null) {
                this.stocklist[i].newstock = null
            }
            if (this.model.type == "Debit") {
                if (this.stocklist[i].actuStock == 0) {

                    this.notifyService.showRroor("Debit is Not Done", " Oops");
                    return;
                }
                if (this.stocklist[i].actuStock < this.stocklist[i].adjustquantity) {
                    this.stocklist[i].adjustquantity = null
                    this.stocklist[i].newstock = null
                    this.notifyService.showRroor("Quantity is not greater than actual stock For Debit", " Oops");
                    return;
                }

                if (this.stocklist[i].adjustquantity) {
                    this.stocklist[i].newstock = this.stocklist[i].actuStock - this.stocklist[i].adjustquantity
                }
            }

            if (this.model.type == "Credit") {

                for (let i = 0; i < this.stocklist.length; i++) {
                    if (this.stocklist[i].adjustquantity) {
                        this.stocklist[i].newstock = this.stocklist[i].actuStock + this.stocklist[i].adjustquantity
                    }
                }
            }
        }
    }

    btndelete(id, index) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            if (id != null) {
                this.i = confirm("Do you want to delete this record ?")
                if (this.i == true) {
                    this.model.id = id
                    this.model.userId = Number(localStorage.getItem("userid"));
                    this.transactionService.deletestock(this.model)
                        .subscribe(value => {
                            if (value.status == "OK") {
                                this.notifyService.showSuccess("Deleted Successfully", " Success");
                                this.ngOnInit()
                                // var id = document.getElementById("id");
                                // id.click();
                                // this.vendorlist.splice(index, 1);
                            }
                        });
                }
            }
            else {
                for (let i = 0; i < this.stocklist.length; i++) {
                    if (this.stocklist[i].id == id) {
                        this.stocklist.splice(this.stocklist.indexOf(this.stocklist[i]), 1);
                        this.notifyService.showSuccess("Item Removed Successfully", " Success");
                    }
                }
            }
        }

        // this.model.userid = Number(localStorage.getItem("userid"));
        // this.i = confirm("Do you want to delete this record ?")
        // if (this.i == true) {
        //     this.model.id = this.id
        //     this.transactionService.deletestock(this.model)
        //         .subscribe(value => {
        //             if (value.status == "OK") {
        //                 this.notifyService.showSuccess(value.message, " Success");
        //                 // this.ngOnInit()
        //                 // this.stocklist.splice(index, 1);
        //             }
        //         });
        // }

    }
    btncancle() {
        this.router.navigate(["/landpage"])
    }
    btnsave($event) {
        if (this.model.locationname == null) {
            this.notifyService.showRroor("Please Select Inventory Location", " Oops");
            return;
        }
        if (this.stocklist.length <= 0) {
            this.notifyService.showRroor("Please Add Stock Adjustment Details!!", "Oops!");
            return;
        }
        if (this.model.type == null) {
            this.notifyService.showRroor("Please Select Adjustment Type", " Oops");
            return;
        }
        for (let i = 0; i < this.stocklist.length; i++) {
            if (this.stocklist[i].adjustquantity == null) {
                this.notifyService.showRroor("Please Enter Adjustment Quantity", " Oops");
                return;
            }

            if (this.stocklist[i].remark == null) {
                this.notifyService.showRroor("Please Enter Remark", " Oops");
                return;
            }
        }

        this.model.locationlst = this.stocklist
        this.model.userid = Number(localStorage.getItem("userid"));

        this.transactionService.savestockadjustment(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit();

                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

    //For Autocomplete
    onChangeSearchstock(search: string) {
        this.user.message = search;
        this.user.id = this.model.itemcategoryid;
        this.user.trnid = this.model.itemsubcategoryid;
        this.user.locationID = this.model.locationid;
        this.user.userid = this.model.itemcategoryid;
        this.transactionService.getItemLikePO(this.user)
            .subscribe(data => {
                this.userList2 = [];
                data.forEach((item) => this.userList2.push({ "id": item.id, "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "uom": item.uom, "actuStock": item.actuStock }));


            });


    }
    selectEvent(item) {
        this.model.itemid = item.itemid;
        this.model.id = item.id
        this.transactionService.getItemLike(this.user)
            .subscribe(data => {
                this.userList2 = [];
                data.forEach((item) => this.userList2.push({ "id": item.id, "itemname": item.itemname, "itemid": item.itemid, "itemcode": item.itemcode, "uom": item.uom, "actuStock": item.actuStock }));
                this.model.itemname = item.itemname;

            });

        this.model.itemcode = item.itemcode;
        this.model.uom = item.uom;
        this.model.actuStock = item.actuStock;

    }

    onFocusedforstock(e) {
        // do something
    }
}