<div *ngIf="isMobile == true">
    <div class="row grayBg" style="height: 95px;">
        <a href="#!" class="brand-logo">
            <img class="responsive-img logoImg-left" src="../../../assets/images/RavishSysLogo.png">
            <img class="responsive-img logoImg-right" src="../../../assets/images/easy-dry-logo.png">
        </a>

        <nav class="grayTheme">
            <div class="nav-wrapper">
                <!-- <div data-target="mobile-demo" class="sidenav-trigger" style="top: 20px;"><i class="material-icons">Menu</i>
                </div> -->
                <a data-target="mobile-demo" class="sidenav-trigger">
                    <i class="fa fa-bars" aria-hidden="true" style="margin-top: 22px;"></i>
                </a>
            </div>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li *ngFor="let menu of mainmenus"><a (click)="redirectUser(menu.mainmenulink)">{{menu.mainmenulabel}}</a>
            </li>
        </ul>

        <div class="top-header-social-info">
            <span class="userName" style="color: #ad5030;margin-right: 10px;"><b>Welcome {{username}} </b></span>
            <a>
                <img src="../../../assets/images/logout.jpeg" data-toggle="tooltip" data-placement="top" width="20px"
                    aria-hidden="true" (click)="btnlogout()" />
            </a>
        </div>
    </div>

    <div class="section no-pad-bot OprntCol">
        <h1 class="header center">Daily Call</h1>
        <div class="container">
            <div class="row center">
                <div class="col s12 m4 innerCnt">
                    <div class="srchBy p-0">
                        <div class="col s12 sectionSrch">
                            <label class="w-50 font-weight-bold blueTxt">Customer Name</label>
                            <label class="w-50">{{model.accountname}}</label>
                            <label class="w-100 font-weight-bold dis-block" style="padding-left: 5px;">Address :</label>
                            <label class="w-100 dis-block" style="padding-left: 5px;">{{model.unitno + ", "+
                                model.buildingName + ", " + model.address + ", " + model.locallity + ", " + model.city +
                                ", " + model.pincode}}</label>
                            <label class="w-50 font-weight-bold">DCR Date</label>
                            <label class="w-50">
                                <div class="input-field">
                                    <!-- <input placeholder="01/08/2021" id="first_name" type="text" class="validate"> -->
                                    <!-- <i class="fa fa-calendar inpClndr"></i> -->
                                    <input type="text" placeholder="DCR Date" [(ngModel)]="model.dcrdate" id="date"
                                        class="form-control" bsDatepicker (ngModelChange)="selectDcrDate(model.dcrdate)"
                                        style="width:100px;border-radius: 4px;"
                                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' , adaptivePosition: true }">

                                </div>
                            </label>
                            <label class="w-50 font-weight-bold blueTxt" *ngIf="this.action=='view'">DCR Date</label>
                            <label class="w-50" *ngIf="this.action=='view'">{{model.dcrdate |
                                date:"dd-MM-yyyy"}}</label>

                            <label class="w-100 font-weight-bold dis-block" *ngIf="this.action!='view'">Enquiry</label>
                            <label class="w-100 dis-block" *ngIf="this.action!='view'">
                                <div class="input-field">
                                    <select (change)="onSelectEnquiry($event.target.value)">
                                        <option selected value="0">Select Enquiry</option>
                                        <option *ngFor="let lst of enquirelst" [value]="lst.id"
                                            [selected]="lst.id==model.enquiryid">
                                            {{lst.name}}&nbsp;&nbsp;&nbsp;Dated :&nbsp;{{lst.enqdate |
                                            date:"dd-MM-yyyy"}}</option>
                                    </select>
                                </div>
                            </label>

                            <label class="w-50 font-weight-bold blueTxt" *ngIf="this.action=='view'">Enquiry</label>
                            <label class="w-50" *ngIf="this.action=='view'">{{model.enquiryName}}</label>

                            <label class="w-50 font-weight-bold valignTxt" *ngIf="this.action!='view'">Call Type</label>
                            <label class="w-50" *ngIf="this.action!='view'">
                                <label class="w-50"> <input name="group1" type="checkbox" [checked]="model.chkbyvisit"
                                        (click)="onchkbyvisit($event)" />
                                    <label for="test">Visit</label></label>
                                <label class="w-50"><input name="group1" type="checkbox" [checked]="model.chkbycall"
                                        (click)="onchkbycall($event)" />
                                    <label for="test">Phone/Email</label></label>
                            </label>
                            <label class="w-50 font-weight-bold" *ngIf="this.action!='view'">Activity</label>
                            <label class="w-50" *ngIf="this.action!='view'">
                                <div class="input-field">
                                    <select (change)="onSelectActivity($event.target.value)"
                                        [disabled]="model.connection == null">
                                        <option selected value="0">Select Activity</option>
                                        <option *ngFor="let lst of activitylst" [value]="lst.id"
                                            [selected]="lst.id==model.activityid">
                                            {{lst.name}}</option>
                                    </select>
                                </div>
                            </label>

                            <label class="w-50 font-weight-bold" *ngIf="model.actionTaken == 'A'">Assigned To</label>
                            <label class="w-50" *ngIf="model.actionTaken == 'A'">
                                <div class="input-field">
                                    <select (change)=" onSelectassigned($event.target.value)">
                                        <option *ngFor="let type of lstassigned" [value]="type.id"
                                            [selected]="type.id==leadID">
                                            {{type.name}}</option>
                                    </select>
                                </div>
                            </label>

                            <label class="w-100 border-bottom"></label>
                            <button *ngIf="model.quoid!=null && model.quostatus=='Edit'"
                                class="btn waves-effect waves-light right" type="button" (click)="btnEditQuo('Edit')"
                                [disabled]="model.actionTaken =='S'">Edit
                                Quotation</button>
                        </div>
                        <div class="col s12 sectionSrch mt-top">
                            <label class="w-50 font-weight-bold">Current Stage:</label>
                            <label class="w-50 orgTxt">{{model.currentstage}}</label>

                            <label class="w-50 font-weight-bold" *ngIf="model.nextstage!=null">Stage Move to:</label>
                            <label class="w-50 orgTxt" *ngIf="model.nextstage!=null">{{model.nextstage}}</label>

                            <label class="w-50 font-weight-bold" *ngIf="this.action!='view'">Remark</label>
                            <label class="w-50" *ngIf="this.action!='view'">
                                <div class="input-field">
                                    <textarea id="textarea1" title="Remarks" class="materialize-textarea"
                                        [(ngModel)]="model.remarks" #remarks="ngModel" id="Remarks"></textarea>
                                </div>
                            </label>
                            <label class="w-50">Previous Remark</label>
                            <label class="w-50 orgTxt">{{model.previousremarks}}</label>

                            <!-- <label class="w-50 font-weight-bold">Joint Work</label>
                            <label class="w-50">
                                <div class="input-field">
                                    <input placeholder="01/08/2021" id="first_name" type="text" class="validate">
                                </div>
                            </label> -->
                            <label class="w-100 border-bottom"></label>

                            <button *ngIf="this.action!='view'" class="btn waves-effect waves-light right modal-trigger"
                                href="#modal1">Add
                                Contact</button>
                            <!-- Modal Structure -->
                            <div id="modal1" class="modal">
                                <div class="modal-content">
                                    <h4 class="orgTxt">Add Contact</h4>
                                    <div class="row">
                                        <!-- <form class="col s12"> -->
                                        <div class="row">
                                            <div class="input-field col s12">
                                                <!-- <input id="first_name" type="text" class="validate"> -->
                                                <input [(ngModel)]="contactmo.contactname" #contactname="ngModel"
                                                    type="text" id="contactname" title="Name">
                                                <label for="first_name">Name</label>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="model.chkdesignation">
                                            <div class="input-field col s12">
                                                <!-- <input id="last_name" type="text" class="validate"> -->
                                                <!-- <label for="last_name">Designation</label> -->
                                                <select (change)="onSelectdesiganation($event.target.value)"
                                                    data-toggle="tooltip" data-placement="top" title="Designation">
                                                    <option selected disabled>Select Designation</option>
                                                    <option *ngFor="let type of designationlst" [value]="type.name"
                                                        [selected]="type.name==contactmo.designation">
                                                        {{type.name}} </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="input-field col s12">
                                                <!-- <input id="mobile" type="text" class="validate"> -->
                                                <input [(ngModel)]="contactmo.mobileno" #mobileno="ngModel"
                                                    pattern="[6789][0-9]{9}" type="text" id="contactmobile"
                                                    title="Mobile" maxlength="10">
                                                <div *ngIf="mobileno.errors &&(mobileno.touched || mobileno.dirty)"
                                                    class="aler alert-danger" style="padding: 10px; color: red">
                                                    <div [hidden]="!mobileno.errors?.pattern">
                                                        Invalid mobile Number
                                                    </div>
                                                </div>
                                                <label for="mobile">Mobile</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="input-field col s12">
                                                <!-- <input id="email" type="email" class="validate"> -->
                                                <input [(ngModel)]="contactmo.email"
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" #email="ngModel"
                                                    type="text" id="contactemail" title="E-Mail" required>
                                                <div *ngIf="email.invalid && (email.dirty || email.touched) "
                                                    class="alert alert-danger" style="color: red">
                                                    <div *ngIf="email.errors.required">
                                                        Email Required
                                                    </div>
                                                    <div [hidden]="!email.errors?.pattern">
                                                        Invalid Email-Id
                                                    </div>
                                                </div>
                                                <label for="email">Email</label>
                                            </div>
                                        </div>
                                        <!-- </form> -->
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <a href="#!" class="modal-close btn btn-blue waves-effect waves-green btn-flat"
                                        style="margin-left: 5px;">Cancel</a>
                                    <a href="#!" class="waves-effect btn waves-green btn-flat"
                                        (click)="btnaddcontact()">Add</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12" *ngFor="let lst of contactlst ; let i = index">
                        <div class="tabInnerBox">
                            <div class="introTab">
                                <label class="w-35 font-weight-bold blueTxt valignTxt">Select</label>
                                <label class="widthR-60">
                                    <!-- <input type="checkbox" id="test5" /> -->
                                    <input type="checkbox" [(ngModel)]="lst.chkcontact" #chkcontact="ngModel"
                                        (click)="btnselectContact($event, i)">
                                    <label for="test5"></label>
                                </label>
                                <label class="w-35 font-weight-bold blueTxt">Name</label>
                                <label class="widthR-60">{{lst.contactname}}</label>
                                <label class="w-35 font-weight-bold blueTxt"
                                    *ngIf="model.chkdesignation">Designation</label>
                                <label class="widthR-60" *ngIf="model.chkdesignation">{{lst.designation}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Mobile</label>
                                <label class="widthR-60">{{lst.mobileno}}</label>
                                <label class="w-35 font-weight-bold blueTxt">Email</label>
                                <label class="widthR-60">{{lst.email}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col s12">
                        <label class="prvMark">Product Categories Interested:</label>
                        <div class="tabInnerBox" *ngFor="let lst of productEnquirylst; let i = index">
                            <div class="introTab">
                                <label class="widthR-60 font-weight-bold blueTxt">Product Categories</label>
                                <label class="w-35" *ngIf="!lst.added && this.action!='view'">
                                    <!-- <input type="text"
                                        class="autocomplete" id="productcategos" placeholder="Product Categories"
                                        (keyup)="getintrestedproductcatego($event,i)" />
                                    <input type="hidden" id="pro-id" />  -->

                                    <div class="input-field">
                                        <select (change)=" onSelectcategory($event.target.value,i)">
                                            <option selected value="0">Select Product Category</option>
                                            <option *ngFor="let type of procatlst" [value]="type.pcid"
                                                [selected]="type.pcid==leadID">
                                                {{type.pcname}}</option>
                                        </select>
                                    </div>
                                </label>
                                <label class="w-35" *ngIf="lst.added">{{lst.pcname}}</label>

                                <label class="widthR-60 font-weight-bold blueTxt">Description</label>
                                <label class="w-35">{{lst.categoriesdescription}}</label>

                                <label class="widthR-60 font-weight-bold blueTxt" *ngIf="productmodel">Product
                                    Model</label>
                                <label class="w-35" *ngIf="productmodel && !lst.added&&this.action!='view'"></label>
                                <label class="w-35"
                                    *ngIf="productmodel && lst.added&&this.action!='view'">{{lst.name}}</label>

                                <label class="widthR-60 font-weight-bold blueTxt">Action</label>
                                <!-- <label class="w-35" *ngIf="lst.added&&this.action!='view'" ><i class="fa fa-trash" (click)="btndelts(i)"></i></label>
                                <label class="w-35" *ngIf="!lst.added&&this.action!='view'"><i class="fa fa-pencil" (click)="btnaddproductintersted(i,productmodel)"></i></label> -->

                                <span class="w-35" *ngIf="!lst.added&&this.action!='view'"><img
                                        src="../../../assets/images/CreateDocument.png"
                                        (click)="btnaddproductintersted(i,productmodel)" data-toggle="tooltip"
                                        data-placement="top" title="Edit" width="20px" /></span>
                                <span class="w-35" *ngIf="lst.added&&this.action!='view'"><img
                                        src="../../../assets/images/delete.png" (click)="btndelts(i)"
                                        data-toggle="tooltip" data-placement="top" title="Delete" width="20px" /></span>

                            </div>
                        </div>
                    </div>

                    <div class="col s12">
                        <label class="w-100 fllwUp">
                            <!-- <input type="checkbox" id="test5" /> -->
                            <input [(ngModel)]="model.chkfollowup" #chkfollowup="ngModel" type="checkbox"
                                (click)="onchkFollowUp($event)" id="test5">
                            <label for="test5" class="orgTxt font-weight-bold">Follow Up</label>
                        </label>
                        <div class="trangel" *ngIf="model.chkfollowup&&this.action!='view'"></div>
                        <div class="col s12 srchBy sectionSrch rdBox" *ngIf="model.chkfollowup&&this.action!='view'">
                            <label class="w-50 font-weight-bold">Follow up Date</label>
                            <label class="w-50">
                                <div class="input-field">
                                    <!-- <input placeholder="01/08/2021" id="first_name" type="text" class="validate"> -->
                                    <!-- <i class="fa fa-calendar inpClndr"></i> -->
                                    <input type="text" placeholder="Follow up Date" [(ngModel)]="model.followupdate"
                                        id="date" class="form-control" bsDatepicker
                                        (ngModelChange)="selectFollowUpDate(model.followupdate)"
                                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }">

                                </div>
                            </label>

                            <label class="w-50 font-weight-bold">Activity</label>
                            <label class="w-50">
                                <div class="input-field">
                                    <select (change)="onSelectActivity2($event.target.value)">
                                        <option selected value="0">Select Activity</option>
                                        <option *ngFor="let lst of activitylst" [value]="lst.id">
                                            {{lst.name}}</option>
                                    </select>
                                </div>
                            </label>
                        </div>

                    </div>
                    <div class="Left">
                        <button type="button" class="btn btn-blue" (click)="forclose()">foreclose</button>
                    </div>
                    <!-- <div><button type="button" class="btn btn-blue"
                            (click)="forclose()">forclose</button>
                            </div> -->
                    <!-- <div class="col s12">
                        
                        <div class="tabInnerBox">
                            <div class="introTab">
                                <label class="w-35 font-weight-bold blueTxt">DCR Date</label>
                                <label class="widthR-60 ">LEAD</label>
                                <label class="w-35  font-weight-bold blueTxt">Activity</label>
                                <label class="widthR-60 ">Assign Opportunity</label>
                                <label class="w-35  font-weight-bold blueTxt">Resource</label>
                                <label class="widthR-60 ">Prashant Sohoni</label>
                                <label class="w-35  font-weight-bold blueTxt">Remarks</label>
                                <label class="widthR-60 ">Ok</label>
                            </div>
                        </div>
                    </div> -->
                    <div class="col s12" style="margin-bottom: 12px;">
                        <label class="prvMark">DCR History:</label>
                        <div class="tabInnerBox" *ngFor="let history of dcrHistorylst">
                            <div class="introTab">
                                <label class="w-35 font-weight-bold blueTxt">DCR Date</label>
                                <label class="widthR-60 "> {{history.dcrdate | date:"dd-MM-yyyy"}}</label>
                                <label class="w-35  font-weight-bold blueTxt">Stage</label>
                                <label class="widthR-60 ">{{history.currentstage}}</label>
                                <label class="w-100 dis-block font-weight-bold blueTxt">Activity</label>
                                <label class="w-100 dis-block ">{{history.activity}}</label>
                                <label class="w-35  font-weight-bold blueTxt">Resource</label>
                                <label class="widthR-60">{{history.assignedByName}}</label>
                                <label class="w-35 font-weight-bold blueTxt valignTxt">Remarks</label>
                                <label class="widthR-60">{{history.remarks}}</label>
                            </div>
                        </div>
                    </div>

                    <hr style="display: block;
                    unicode-bidi: isolate;
                    margin-block-start: 0.5em;
                    margin-block-end: 0.5em;
                    margin-inline-start: auto;
                    margin-inline-end: auto;
                    overflow: hidden;
                    border-style: inset;
                    border-width: 1px;">
                    <div class="s12 right">
                        <button type="button" (click)="btnback()"
                            class="btn grayButton">Cancel</button>&nbsp;&nbsp;&nbsp;
                        <!-- onclick="manageViews(true)" id="close" -->
                        <button *ngIf="model.enqstatus=='Open' && saveButton" type="button" class="btn btn-blue"
                            (click)="btnsaveDailyCall()">Save</button>

                    </div>


                </div>
            </div>
        </div>
    </div>
    <script>
        $(document).ready(function () {
            $('.modal-trigger').leanModal();
        });
    </script>
</div>

<div *ngIf="isMobile == false">

    <app-header></app-header>
    <div class="container" style="width: 80%">
        <div class="row">
            <div class="col l12 m12 s12">
                <div class="col l2 m2 s2"></div>
                <div class="heading col l8 m8 s8">
                    <span style="font-size: 25px;">Daily call</span>
                </div>
                <div class="col l2 m2 s2"></div>
            </div>
            <div class="col l12 m12 s12 registerBgShadow">
                <div class="col l12 m12 s12">
                    <div class="row" style="margin-top: 2%">
                        <div class="col l10 m10 s10">
                            <span style="font-weight: bold; font-size: 15px">Customer
                                Name:&nbsp;<span>{{model.accountname}}</span></span><br />
                            <span style="font-weight: bold; font-size: 15px">Address:&nbsp;<span>{{model.unitno + ", "+
                                    model.buildingName + ", " + model.address + ", " + model.locallity + ", " +
                                    model.city + ", " + model.pincode}}</span></span>
                        </div>
                        <div class="input-field col l2 m2 s2" style="margin: 0%;" *ngIf="this.action!='view'">
                            <span>DCR Date</span>
                            <input type="text" placeholder="DCR Date" [(ngModel)]="model.dcrdate" id="date"
                                class="form-control" bsDatepicker (ngModelChange)="selectDcrDate(model.dcrdate)"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }">
                        </div>
                        <div class="input-field col l2 m2 s2" style="margin: 0%;" *ngIf="this.action=='view'">
                            <span>DCR Date :</span>
                            <b>{{model.dcrdate | date:"dd-MM-yyyy"}}</b>
                        </div>

                    </div>
                    <div class="row">
                        <div class="input-field col l4 m4 s4" style="margin: 0px;" *ngIf="this.action!='view'">
                            <span>Enquiry</span>
                            <select (change)="onSelectEnquiry($event.target.value)">
                                <option selected value="0">Select Enquiry</option>
                                <option *ngFor="let lst of enquirelst" [value]="lst.id"
                                    [selected]="lst.id==model.enquiryid">
                                    {{lst.name}}&nbsp;&nbsp;&nbsp;Dated :&nbsp;{{lst.enqdate | date:"dd-MM-yyyy"}}
                                </option>
                            </select>
                        </div>
                        <div class="input-field col l4 m4 s4" style="margin: 0%;" *ngIf="this.action=='view'">
                            <span>Enquiry :</span>
                            {{model.enquiryName}}
                        </div>
                        <div class="col l1 m1 s1" *ngIf="this.action!='view'">
                            <span>Call Type</span>
                            <label>
                                <input name="group1" type="radio" [checked]="model.chkbyvisit"
                                    (click)="onchkbyvisit($event)" />
                                <span style="font-size: 104%">Visit</span>
                            </label>&nbsp;&nbsp;
                        </div>
                        <div class="col l2 m2 s2" style="margin-top: 2%;" *ngIf="this.action!='view'">
                            <label>
                                <input name="group1" type="radio" [checked]="model.chkbycall"
                                    (click)="onchkbycall($event)" />
                                <span style="font-size: 104%">Phone / Email</span>
                            </label>
                        </div>
                        <div class="input-field col l3 m4 s4" style="margin: 0px;" *ngIf="this.action!='view'">
                            <span>Activity</span>
                            <select (change)="onSelectActivity($event.target.value)"
                                [disabled]="model.connection == null">
                                <option selected value="0">Select Activity</option>
                                <option *ngFor="let lst of activitylst" [value]="lst.id"
                                    [selected]="lst.id==model.activityid">
                                    {{lst.name}}</option>
                            </select>
                        </div>
                        <div class=" input-field col l2 m2 s2"
                            style="margin-bottom: 0px;margin-top: 0px;padding-left: 20px;"
                            *ngIf="model.actionTaken == 'A'">
                            <span>Assigned To</span>
                            <!-- <span *ngIf="model.userid">{{type.name}}</span> -->
                            <select (change)=" onSelectassigned($event.target.value)">
                                <option *ngFor="let type of lstassigned" [value]="type.id" [selected]="type.id==leadID">
                                    {{type.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col l12 m12 s12" style=" margin-top: 2%;text-align:right;padding-right: 0px;">
                        <div class="col l10 m10 s10" *ngIf="model.quoid!=null">

                            <button type="button" (click)="btnEditQuo('Edit')" *ngIf="model.quostatus=='Edit'"
                                class="waves-effect waves-light btn" [disabled]="model.actionTaken =='S'">Edit
                                Quotation</button>&nbsp;&nbsp;
                            <!-- <button type="button" (click)="btnSendQuo()" *ngIf="model.quosubflag && model.ctype=='Retail'"
                        class="waves-effect waves-light btn">Send Quotation
                    </button> -->
                            <button type="button" (click)="btnEditQuo('View')" *ngIf="model.quostatus!='Edit'"
                                class="waves-effect waves-light btn">View
                                Quotation</button>
                        </div>
                        <div class="col l2 m2 s2">
                            <button type="button" class="waves-effect waves-light btn" (click)="btnresendotp()"
                                *ngIf="model.otpverified == true">Resend OTP</button>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col l4 m4 s4">
                            <span style="font-weight: bold; font-size: 15px">Current
                                Stage:&nbsp;<span style="color: blue">{{model.currentstage}}</span></span>
                        </div>
                        <div class="col l4 m4 s4">
                            <span style="font-weight: bold; font-size: 15px" *ngIf="model.nextstage!=null">Stage Move
                                to:&nbsp;<span style="color: green">{{model.nextstage}}</span></span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col l6 m6 s6" style="margin-bottom:0px;margin-top: 13px;"
                            *ngIf="this.action!='view'">
                            <textarea id="textarea1" title="Remarks" class="materialize-textarea"
                                [(ngModel)]="model.remarks" #remarks="ngModel" id="Remarks" maxlength="1000"></textarea>
                            <label for="Remarks">Remarks</label>
                        </div>
                        <div class="col s6 m6 l6 input-field" style="margin: auto;padding: 2px;"
                            *ngIf="this.action!='view'">
                            <input type="text" #inputTrigger matInput data-toggle="tooltip" data-placement="top"
                                title="Joint Work" [matAutocomplete]="autoComplete" [formControl]="itemControl"
                                [matChipInputFor]="chipList" id="Resource" name="Resource" style="font-size: 1rem;">
                            <label for="Resource">Joint Work</label>
                            <mat-autocomplete #autoComplete="matAutocomplete" style="font-size: 30px;">
                                <mat-option *ngFor="let item of filteredItems" [value]="selectedItems">
                                    <div (click)="optionClicked($event, item);">
                                        <mat-checkbox [checked]="item.selected" (change)="toggleSelection(item)"
                                            (click)="$event.stopPropagation()">
                                            {{ item.item }}
                                        </mat-checkbox>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-chip-list #chipList class="input-field">
                                <mat-chip *ngFor="let item of selectedItems" [selectable]="selectable"
                                    style="border-radius: initial;" [removable]="removable" (removed)="remove(item)">
                                    {{ item.item }}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l9 m9 s9">
                            <span style="font-size: 15px">Previous
                                Remark:&nbsp;<span>{{model.previousremarks}}</span></span>
                        </div>
                        <div class="col l3 m3 s3" style="text-align: end; margin-bottom: 5px;padding-right: 0px;"
                            *ngIf="this.action!='view'">
                            <button type="button" (click)="btnAddContact(contactmodel)"
                                class="waves-effect waves-light btn">Add
                                Contact</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l12 m12 s12" style="padding-left: 0px;padding-right: 0px;">
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="7%" style="text-align: center">Select</th>
                                    <th width="30%" style="text-align: left">Name</th>
                                    <th width="23%" style="text-align: left" *ngIf="model.chkdesignation">Designation
                                    </th>
                                    <th width="10%" style="text-align: left">Mobile No.</th>
                                    <th width="30%" style="text-align: left">Email</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lst of contactlst ; let i = index">
                                        <td style="text-align: center"><input type="checkbox"
                                                [(ngModel)]="lst.chkcontact" #chkcontact="ngModel"
                                                (click)="btnselectContact($event, i)"></td>
                                        <td style="text-align: left">{{lst.contactname}}</td>
                                        <td style="text-align: left" *ngIf="model.chkdesignation">{{lst.designation}}
                                        </td>
                                        <td style="text-align: left">{{lst.mobileno}}</td>
                                        <td style="text-align: left">{{lst.email}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <b style="font-size: 15px;">Product Categories Interested</b>
                        <table>
                            <thead style="background: gainsboro;">
                                <th width="20%" style="text-align: left;padding-left: 10px;">Product Categories
                                </th>
                                <th width="20%" style="text-align: left" *ngIf="productmodel">Product Model</th>
                                <th width="30%" style="text-align: left">Description</th>
                                <th width="10%" style="text-align: center">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lst of productEnquirylst; let i = index">
                                    <td class=" input-field" style="text-align: left;padding-left: 10px;"
                                        *ngIf="!lst.added&&this.action!='view'">
                                        <input type="text" class="autocomplete" id="productcategos"
                                            placeholder="Product Categories"
                                            (keyup)="getintrestedproductcatego($event,i)" />
                                        <input type="hidden" id="pro-id" />
                                        <!-- <ng-autocomplete [data]="productList2" type="text" placeholder="Product Categories"
                                  [searchKeyword]="keyword1" [minQueryLength]="3"  id="productcategos"
                                  (inputChanged)='onChangeSearchproductcatego($event)' (selected)='selectEvent($event)' (inputFocused)='onFocusedforproductcatego($event)'
                                  [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
              
                                <ng-template #itemTemplate let-item>
                                  <a  [innerHTML]="item.name"></a>
                                </ng-template>
              
                                <ng-template #notFoundTemplate let-notFound>
                                  <div [innerHTML]="notFound"></div>
                                </ng-template> -->
                                    </td>
                                    <td *ngIf="lst.added" style="text-align: left;padding-left: 10px;">
                                        {{ lst.pcname}}
                                    </td>
                                    <td class=" input-field" style="text-align: left"
                                        *ngIf="productmodel&&!lst.added&&this.action!='view'">
                                        <input type="text" class="autocomplete" id="product" placeholder="Product Model"
                                            (keyup)="getinteredproductmodel($event,i)" />
                                        <input type="hidden" id="pro-id" />

                                    </td>
                                    <td *ngIf="productmodel&&lst.added&&this.action!='view'" style="text-align: left">
                                        {{ lst.name}}
                                    </td>
                                    <td style="text-align: left">
                                        <!-- <span> {{lst.description}} </span> -->
                                        <span> {{lst.categoriesdescription}} </span>
                                    </td>
                                    <td style="text-align: center">
                                        <span *ngIf="!lst.added&&this.action!='view'"><img
                                                src="../../../assets/images/CreateDocument.png"
                                                (click)="btnaddproductintersted(i,productmodel)" data-toggle="tooltip"
                                                data-placement="top" title="Edit" width="20px" /></span>
                                        <span *ngIf="lst.added&&this.action!='view'"><img
                                                src="../../../assets/images/delete.png" (click)="btndelts(i)"
                                                data-toggle="tooltip" data-placement="top" title="Delete"
                                                width="20px" /></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                        <div class="input-field col l2 m2 s2" style="margin-top: 2%;">
                            <span> <input [(ngModel)]="model.chkfollowup" #chkfollowup="ngModel" type="checkbox"
                                    style="text-align: 5px" (click)="onchkFollowUp($event)">&nbsp;<label>Follow
                                    Up</label></span>
                        </div>
                        <div class="input-field col l2 m2 s2" style="margin-top: 0px"
                            *ngIf="model.chkfollowup&&this.action!='view'">
                            <span>Follow up Date</span>
                            <input type="text" placeholder="Follow up Date" [(ngModel)]="model.followupdate" id="date"
                                class="form-control" bsDatepicker
                                (ngModelChange)="selectFollowUpDate(model.followupdate)"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' ,adaptivePosition: true }">
                        </div>
                        <div class=" input-field col l3 m3 s3" style="display: none">
                            <span>Assign To</span>
                            <select (change)="onSelectAssignto($event.target.value)">
                                <option selected value="0">Select AssignTo</option>
                                <option *ngFor="let lst of memberlst" [value]="lst.id">
                                    {{lst.name}}</option>
                            </select>

                        </div>
                        <div class=" input-field col l3 m3 s3" style="margin: 0px;"
                            *ngIf="model.chkfollowup&&this.action!='view'">
                            <span>Activity</span>
                            <select (change)="onSelectActivity2($event.target.value)">
                                <option selected value="0">Select Activity</option>
                                <option *ngFor="let lst of activitylst" [value]="lst.id">
                                    {{lst.name}}</option>
                            </select>
                        </div>

                        <div class="input-field col l2 m2 s2" style="margin-top: 2%;" *ngIf="this.action!='view'">
                            <span *ngIf="model.actionTaken=='N'"> <input [(ngModel)]="model.chkclose"
                                    #chkclose="ngModel" type="checkbox" style="text-align: 5px"
                                    (click)="onchkClose($event)">&nbsp;<label>Close
                                    Enquiry</label></span>
                        </div>
                    </div>

                    <div class="row" *ngIf="dcrHistorylst.length>0">
                        <div class="col l12 m12 s12" style="padding-left: 0px;padding-right: 0px;">
                            <span style="font-weight: bold; font-size: 15px">DCR History</span>
                            <table>
                                <thead style="background: gainsboro;">
                                    <th width="9%" style="text-align: left;padding-left: 10px;">DCR Date</th>
                                    <th width="9%" style="text-align: left">Stage</th>
                                    <th width="20%" style="text-align: left">Activity</th>
                                    <th width="20%" style="text-align: left">Resource</th>
                                    <th width="42%" style="text-align: left">Remarks</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let history of dcrHistorylst">
                                        <td style="text-align: left;padding-left: 10px;">
                                            {{history.dcrdate | date:"dd-MM-yyyy"}}</td>
                                        <td style="text-align: left">{{history.currentstage}}</td>
                                        <td style="text-align: left">{{history.activity}}</td>
                                        <td style="text-align: left">{{history.assignedByName}}</td>
                                        <td style="text-align: left">{{history.remarks}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-field col l12 m12 s12" style="text-align:right ;padding-right: 0px;">
                            <div class="left">
                                <button type="button" class="btn btn-blue" (click)="forclose()">foreclose</button>
                            </div>
                            <button (click)="btnback()" type="button"
                                class="waves-effect cancelbutton btn">Cancel</button>
                            &nbsp;&nbsp;
                            <span *ngIf="model.enqstatus=='Open'">
                                <button (click)="btnsaveDailyCall()" type="button" *ngIf="saveButton"
                                    class="waves-effect waves-light btn">Save</button>
                                    
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-template #contactmodel>
        <div class="col l12 m12 s12  modal-header" style="text-align: end">
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="registerBgShadow">
            <div class="modal-content" style="text-align: center;">
                <span style="font-size: 20px;">Add Contact</span><br>
            </div>
            <div class="row ">
                <div class=" input-field col s12 m12 l12" style="margin-top: 0%;margin-bottom: 0%;">
                    <div class="col l1 m1 s1"></div>
                    <div class=" input-field col s5 m5 l5" style=" margin-top: 17px;">
                        <input [(ngModel)]="contactmo.contactname" #contactname="ngModel" type="text" id="contactname"
                            title="Name">
                        <label for="contactname">Name</label>
                    </div>
                    <div class=" input-field col s5 m5 l5" style=" margin-top: 0px;" *ngIf="model.chkdesignation">
                        <span>Designation</span>
                        <select (change)="onSelectdesiganation($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Designation">
                            <option selected disabled>Select Designation</option>
                            <option *ngFor="let type of designationlst" [value]="type.name"
                                [selected]="type.name==contactmo.designation">
                                {{type.name}} </option>
                        </select>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class=" input-field col s12" style="margin-top: 0%;margin-bottom: 0%;">
                    <div class="col l1 m1 s1"></div>
                    <div class=" input-field col s5 m5 l5">
                        <input [(ngModel)]="contactmo.mobileno" #mobileno="ngModel" pattern="[6789][0-9]{9}" type="text"
                            id="contactmobile" title="Mobile" maxlength="10">
                        <label for="contactmobile" class="active">Mobile</label>
                        <div *ngIf="mobileno.errors &&(mobileno.touched || mobileno.dirty)" class="aler alert-danger"
                            style="padding: 10px; color: red">
                            <div [hidden]="!mobileno.errors?.pattern">
                                Invalid mobile Number
                            </div>
                        </div>
                    </div>
                    <div class=" input-field col s5 m5 l5">
                        <input [(ngModel)]="contactmo.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            #email="ngModel" type="text" id="contactemail" title="E-Mail" required>
                        <label for="contactemail">Email-id</label>
                        <div *ngIf="email.invalid && (email.dirty || email.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="email.errors.required">
                                Email Required
                            </div>
                            <div [hidden]="!email.errors?.pattern">
                                Invalid Email-Id
                            </div>
                        </div>
                    </div>
                    <div class="col l1 m1 s1"></div>
                </div>
                <div class="row">
                    <div class="input-field col l12 m12 s12" style="text-align: right;margin-top: 0%;">
                        <button (click)="modalRef.hide()" type="button"
                            class="waves-effect cancelbutton btn">Cancel</button>&nbsp;&nbsp;
                        <button (click)="btnaddcontact()" type="button"
                            class="waves-effect waves-light btn">Add</button>

                    </div>




                </div>
            </div>
        </div>
    </ng-template>
</div>