import { OnInit, Component } from '@angular/core';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { MasterServices } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { SearchPipelineModel } from './SearchPipelineModel';
import { User } from 'src/app/_models';


@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'SearchPipeline.html'
})

export class SearchPipeline implements OnInit {

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Code" },
        { id: 3, "name": "Description" },

    ];
    p: any;
    model: any = {};
    sum: any;
    user = new User();
    pipelinelst: SearchPipelineModel[] = [];
    pipelinelst1: SearchPipelineModel[] = [];
    id: boolean;
    pagename: string;

    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, ) {
        this.pagename=this.router.url
    }


    ngOnInit(): void {
        this.user.pageno = 0;
        this.user.pageSize = 20;
        this.getAll();
        localStorage.setItem("pagename",this.pagename);
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }  
    }

    getAll() {

        this.Masterservice.getAll(this.model)
            .subscribe(value => {
                this.pipelinelst = [];
                this.pipelinelst1 = [];
                value.forEach((item) => this.pipelinelst1.push({ "id": item.id, "stageCode": item.stageCode, "txtserch": item.txtserch, "txtselect": item.txtselect, "description": item.description }));
                this.pipelinelst = this.pipelinelst1;
            })
    }
    onScroll() {
        this.sum = this.sum + 1;
        this.user.pageno = this.sum + 1;
        this.user.pageSize = 5;
        this.getAll();
    }

    onSelect(value) {
        this.model.txtselect = value;
    }


    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAll()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Code") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.pipelinelst1 = [];
                this.pipelinelst.forEach((item) => {
                    if ((item.stageCode.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.pipelinelst1.push({ "id": item.id, "stageCode": item.stageCode, "txtserch": item.txtserch, "txtselect": item.txtselect, "description": item.description });
                    }
                });
                this.pipelinelst = this.pipelinelst1;
            }
        } else if (this.model.txtselect == "Description") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.pipelinelst1 = [];
                this.pipelinelst.forEach((item) => {
                    if ((item.description.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.pipelinelst1.push({ "id": item.id, "stageCode": item.stageCode, "txtserch": item.txtserch, "txtselect": item.txtselect, "description": item.description });
                    }
                });
                this.pipelinelst = this.pipelinelst1;
            }
        }
    }
    btndelts(id) {
        this.model.id = id
        this.id = confirm("Do you want to delete this record!")
        this.Masterservice.deleteCode(this.model)
            .subscribe(value => {
                this.notifyService.showSuccess(value.message, " Success");
                this.ngOnInit()
            });
    }
    btnedit(id) {
        this.router.navigate(['/DefinePipeline', id]);
    }
    btnnew() {
        this.router.navigate(['/DefinePipeline']);
    }







}