import { Component, OnInit } from "@angular/core";
import { Location, } from '@angular/common';
import { AlertService, DealersService, MasterServices, TransactionServices } from "src/app/_services";
import { BsModalService } from "ngx-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/_services/notification.service";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { Products } from "../DailyCall/DailyCallModel";
import { productModels } from "../DealerPurchaseOrder/DefinePuchaseOrderModel";
import { productInvoice } from "./SampleInvoiceModel";
import { MarketingProposalModel } from "src/app/Transaction-Marketing/Marketing/MarketingProposalModel";
var converter = require('number-to-words');
declare var $: any;
@Component({
    selector: 'app-SampleInvoice',
    templateUrl: 'SampleInvoice.html'
})
export class SampleInvoice implements OnInit {
    user = new User();
    model: any = {};
    userList2: any;
    productcatelst: DropDownModel[] = [];
 
    mrktcostlst: DropDownModel[] = [];
    subcostheadlst: MarketingProposalModel[] = [];
    procatid: number
    procatname: string;
    productid: number;
    prouctname: string;
    currencyid: number;
    gstrate: number;
    uom: string;
    price: number;
    discount: number;
    gstamount: number;
    uomid: number;
    modellst: productModels[] = [];
    productcategoryid: number;
    value: number;
    p: any
    amount: number;
    netorder: number;
    gst: number;
    total: number;
    allcheck:boolean;
    productlist:productInvoice[]=[];
    constructor(private Masterservice: MasterServices, private dealersService: DealersService, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }
    ngOnInit(): void {
        this.getmrktcosthead();
        this.getproductcategorys();
              
    }
    getCustomerLike($event) {

        let userId = (<HTMLInputElement>document.getElementById('customerName1')).value;
        this.userList2 = [];
        let searchDataObj = {};
        this.user.message = userId;
        var obj = this;
        if (userId.length >= 3) {
            this.transactionService.getCustomerLike(this.user)
                .subscribe(data => {
                    data.forEach((item) => this.userList2.push({ "customername": item.customername, "accountid": item.accountid }));
                    let map = new Map();
                    var names = "";
                    for (let i = 0; i < this.userList2.length; i++) {
                        map.set(this.userList2[i].customername, this.userList2[i].accountid)
                        searchDataObj[this.userList2[i].customername] = null;
                        if (i == 0) {
                            names = this.userList2[i].customername;
                        } else {
                            names = names + "," + this.userList2[i].customername;
                        }
                    }
                    let customername;
                    $(document).ready(function () {
                        $(".only-numeric").bind("keypress", function (e) {
                            var keyCode = e.which ? e.which : e.keyCode

                            if (!(keyCode >= 48 && keyCode <= 57)) {
                                $(".error").css("display", "inline");
                                return false;
                            } else {
                                $(".error").css("display", "none");
                            }
                        });
                    });
                    $(document).ready(function () {
                        $('input.typeahead').autocomplete({
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                        $('input.autocomplete').autocomplete({
                            data: searchDataObj,
                            onAutocomplete: function (txt) {
                                obj.typeaheadOnSelect(map.get(txt), txt);
                            },
                        });
                    });
                },
                    () => {
                        alert("fail");
                    });
        }
    }
    typeaheadOnSelect(accountid, customername) {
        this.model.accountid = accountid;
        this.model.customername = customername;
        this.transactionService.getvenaddress(this.model)
            .subscribe(value => {
                this.model.address = value.address
            })

    }
    getmrktcosthead() {
        let user = new User();
        this.mrktcostlst = [];

        this.Masterservice.getmrktcosthead(user)
            .subscribe((value) => {
                this.mrktcostlst = value.mrklst


            });
    }

    onSelectmrktcost(costheadid) {
        if (costheadid == 0) {
            this.notifyService.showRroor("Select Cost Head !!", "Oops!");
            return;
        }
        this.user.trnid = costheadid;
        this.model.costheadid = costheadid;
        this.Masterservice.getmrktcostSubhead(this.user)
            .subscribe((value) => {
                this.subcostheadlst = value.mrkcostsublst
            });
    }

    onSelectmrktcostsubhead(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Cost Sub Head !!", "Oops!");
            return;
        }
        for (let i = 0; i < this.subcostheadlst.length; i++) {
            if (this.subcostheadlst[i].id == value) {
                this.model.costsubheadid = this.subcostheadlst[i].id;
                this.model.costSubhead = this.subcostheadlst[i].costSubhead
            }
        }
    }
    getproductcategorys() {
        this.productcatelst = [];
        this.transactionService.getproductcategorys(this.user)
            .subscribe(data => {

                this.productcatelst = data.productcategorylst;
                
            });

    }
    onSelectprocategory(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Product Category!!", "Oops!");
            return;
        }
        for (let i = 0; i < this.productcatelst.length; i++) {
            if (this.productcatelst[i].id == value) {
                this.model.productcategoryid = this.productcatelst[i].id;
                this.model.proucatename = this.productcatelst[i].name
            }
        }
        this.model.productcategoryid = value
        this.user.trnid = value
        this.productlist = [];
        this.transactionService.getProductsByprodcategory(this.user)
            .subscribe(
                data => {
                    this.productlist = data

                });
    }
    onSelectproduct(val) {
        for (let j = 0; j < this.productlist.length; j++) {
            if (this.productlist[j].productid == val) {
                this.productid = this.productlist[j].productid
                this.prouctname = this.productlist[j].prouctname
                this.uom = this.productlist[j].uom
                this.uomid = this.productlist[j].uomid
                this.gstamount = this.productlist[j].gstamount
                this.gstrate = this.productlist[j].gstrate
                this.price = this.productlist[j].price
                this.discount = this.productlist[j].discount
            }
        }

    }
    btnAddProduct() {
        if (this.model.productcategoryid == null) {
            this.notifyService.showRroor("Please Select Productcategory !", " Oops");
            return;
        }
        if (this.productid == null) {
            this.notifyService.showRroor("Please Select Product !", " Oops");
            return;
        }
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].productid == this.productid) {
                this.notifyService.showRroor("Product already present", " Oops");
                return;
            }
        }
        this.modellst.push({
            id: null,
            productcategoryid: this.productcategoryid,
            proucatename: this.procatname,
            productid: this.productid,
            prouctname: this.prouctname,
            discount: this.discount,
            quantity: null,
            uom: this.uom,
            uomid: this.uomid,
            gstrate: this.gstrate,
            gstamount: this.gstamount,
            value: this.value,
            price: this.price,
            procheckselect: false,
            modellst: null,
            shipingaddid: null,
            allcheckselect:false,
            discountAmount:null,
            netamount:null,
            totalnewvalue:null,
            newdiscountamt:null,
            ratewithdiscount:null,
            newprice:null,
            minimumPrice:null,
            iseditflag:null,
            approvedorderflg:null,
            customnumber:null,
        });
      
          
    }
    onChangeOfqantity(lst, index) {
        this.netorder = 0;
        this.gst = 0;
        this.total = 0;
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].procheckselect) {
                let amount = this.modellst[index].quantity * this.modellst[index].price;
                this.modellst[index].gstamount = amount * this.modellst[index].gstrate / 100;
                
                this.modellst[index].value = amount + this.modellst[index].gstamount;
                for (let i = 0; i < this.modellst.length; i++) {
                    this.netorder = this.netorder + amount;
                    this.model.netorder = this.netorder
                    this.gst = this.gst + this.modellst[i].gstamount;
                    this.model.gst = this.gst
                    this.total = this.total + this.modellst[i].value;
                    this.model.total = this.total

                }
            }
        }
    }
    onselectall($event){
        
        if($event.target.checked){
            for (let i = 0; i < this.modellst.length; i++) {
                this.modellst[i].procheckselect = true
            }
        }else{
            for (let i = 0; i < this.modellst.length; i++) {
                this.modellst[i].procheckselect = false
            }
        }
    }
    cancle() {
        this.router.navigate(["/Order Acceptace"]);
    }
    btnsave() {
        if (this.model.customername == null || this.model.customername=="") {
            this.notifyService.showRroor("Please select Customer !", " Oops");
            return;
        }
        if (this.model.marketingcostid == null) {
            this.notifyService.showRroor("Please select CostHead !", " Oops");
            return;
        }
        if (this.model.costsubheadid == null) {
            this.notifyService.showRroor("Please select Cost Sub Head !", " Oops");
            return;
        }
        for (let j = 0; j < this.modellst.length; j++) {
            if (this.modellst.length <= 0) {
                this.notifyService.showRroor("List is Null", " Oops");
            return; 
            }
        }

        var found = this.modellst.find(function (x) { return x.procheckselect === true; });
        if (!found) {
            this.notifyService.showRroor("Please select atleast one Checkbox", " Oops");
            return;
        }

        for (let j = 0; j < this.modellst.length; j++) {
            if (this.modellst[j].procheckselect == true) {
                if (this.modellst[j].quantity == null) {
                    this.notifyService.showRroor("Enter Quantity  !!", "Oops!");
                    return;
                }
            }
        }
        this.model.userid = Number(localStorage.getItem('userid'));
        this.model.modellst = this.modellst
        this.transactionService.saveSampleInvoice(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.router.navigate(["/Order Acceptace"]);
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }

}