import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { AlertService } from "src/app/_services/alert.service";
import { DealersService } from "src/app/_services/DealersService";
import { MasterServices } from "src/app/_services/MasterServices";
import { NotificationService } from "src/app/_services/notification.service";
import { TransactionServices } from "src/app/_services/TransactionServices";
import { Location, } from '@angular/common';
import { FinancialyearModel } from "src/app/Master/FinancialYear/FinancialyearModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { User } from "src/app/_models";
import { MarketBudgetGeoperTemModel } from "./MarketBudgetGeoperTemModel";
import { Financialyear } from "src/app/Master/FinancialYear/Financialyear";
import { MarketingServices } from "src/app/_services/MarketingServices";
declare var $: any;
@Component({
    selector: 'app-order',
    templateUrl: 'MarketBudgetGeoperTem.html'
})
export class MarketBudgetGeoperTem implements OnInit {
    model: any = {};
    finanyealst: DropDownModel[] = [];
    regionlst: DropDownModel[] = [];
    modellst: MarketBudgetGeoperTemModel[] = [];
    remainpercent: number;
    remainpercentage: number;
    userList2: any;
    sub: any;
    user = new User();
    id: number;
    percentages: number;
    total: number;
    remainvalue: number;
    remainpercentage1: number;
    remainvalue1: number;
    total1: number;
    length: number;
    public i: any;
    p: any;

    constructor(private Masterservice: MasterServices, private Marketingservice: MarketingServices, private dealersService: DealersService, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }

    ngOnInit(): void {
        this.getfinancialyears();
        this.getregionlst();
        this.model.userid = Number(localStorage.getItem("userid"));


    }

    getfinancialyears() {
        let user = new User();
        this.finanyealst = [];
        this.transactionService.getfinancialyears(user)
            .subscribe(value => {
                this.finanyealst = value.finanyealst
            })
    }
    onSelectFinalyear(value) {
        if (value == 0) {
            this.notifyService.showRroor("Select Financial Year", "Oops!");
            return;
        }
        this.model.financialyearid = value
    }
    getregionlst() {
        let user = new User();
        this.regionlst = [];

        this.transactionService.getregionlst(user)
            .subscribe((value) => {
                this.regionlst = value.reglst;

            });
    }
    onSelectRegion(regionId) {
        this.model.regionId = regionId;
        this.modellst = [];
        this.remainpercent = 0;
        this.transactionService.getareaByregion(this.model)
            .subscribe(value => {
                this.model = value
                this.modellst = value.modelst
                // this.modellst = value.modellst

                this.total = 0;
                this.remainvalue = 0;
                this.remainpercent = 0;
                for (let i = 0; i < this.modellst.length; i++) {
                    if (this.modellst[i].percentage1 != null) {
                        this.modellst[i].arecheselect = true;
                        this.total = Number(this.total) + Number(this.modellst[i].percentage1);
                        this.remainvalue = this.model.percentage - this.total;
                        if (this.remainvalue >= 0) {
                            this.remainpercent = this.remainvalue;
                        }
                    }
                    this.total1 = 0;
                    this.remainvalue1 = 0;
                    for (let j = 0; j < this.modellst[i].citylst.length; j++) {
                        if (this.modellst[i].citylst[j].percentage2 != null) {
                            this.modellst[i].citylst[j].checkselect = true
                            this.total1 = +this.total1 + +this.modellst[i].citylst[j].percentage2
                            this.remainvalue1 = this.modellst[j].percentage1 - this.total1
                            if (this.remainvalue1 >= 0) {
                                this.modellst[i].remainpercentage1 = this.remainvalue1
                            }
                        }
                    }
                }
            });
    }
    onChangeOfregionpercent() {
        this.total = 0;
        this.remainvalue = 0;
        this.remainpercent = 0;

        if (this.model.percentage > 100) {
            this.model.percentage = null;
            this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
            return;
        }

        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].percentage1 != null) {
                this.modellst[i].arecheselect = true;
                this.total = Number(this.total) + Number(this.modellst[i].percentage1);
                this.remainvalue = this.model.percentage - this.total;
                if (this.remainvalue >= 0) {
                    this.remainpercent = this.remainvalue;
                } else {
                    this.notifyService.showRroor("Percentage is greater than Remaining percentage!!", "Oops!");
                    this.modellst[i].percentage1 = this.remainpercent;
                    this.remainpercent = 0;
                }
            }
        }

    }
    onChangeOfareapercentage(index) {
        this.total = 0;
        this.remainvalue = 0;
        this.remainpercent = 0;
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].percentage1 != null) {
                this.modellst[i].arecheselect = true;
                this.total = Number(this.total) + Number(this.modellst[i].percentage1);
                // this.remainvalue = this.model.percentage - this.total;                this.remainvalue = this.model.percentage - this.total;
                this.remainvalue = 100 - this.total;
                if (this.remainvalue >= 0) {
                    this.remainpercent = this.remainvalue;
                }
                else {
                    this.notifyService.showRroor("Percentage is greater than Remaining percentage!!", "Oops!");
                    this.modellst[index].percentage1 = this.remainpercent;
                    this.remainpercent = 0;
                    if (this.modellst[index].percentage1 >= 0) {
                        this.modellst[index].arecheselect = true;
                    }
                }
            }
            if (this.modellst[index].citylst[i].percentage2 >= 0) {
                this.modellst[index].citylst[i].checkselect = false;
                this.modellst[index].citylst[i].percentage2 = 0;
            }

        }

        if (!this.modellst[index].arecheselect) {
            this.modellst[index].percentage1 = null;
        }


    }
    onChangeOfcitypercentage(index, ind) {
        this.total1 = 0;
        this.remainvalue1 = 0;
        this.remainpercentage1 = 0;

        for (let i = 0; i < this.modellst[index].citylst.length; i++) {
            if (this.modellst[index].citylst[i].percentage2 != null) {
                this.modellst[index].citylst[ind].checkselect = true
                this.total1 = +this.total1 + +this.modellst[index].citylst[i].percentage2

                // this.remainvalue1 = this.modellst[index].percentage1 - this.total1
                this.remainvalue1 = 100 - this.total1
                if (this.remainvalue1 >= 0) {
                    this.modellst[index].remainpercentage1 = this.remainvalue1
                }
                else {

                    this.notifyService.showRroor("Percentage is greater than Remaining percentage!!", "Oops!");
                    this.modellst[index].citylst[i].percentage2 = this.remainpercentage1;
                    this.modellst[index].remainpercentage1 = 0;
                    if (this.modellst[index].citylst[i].percentage2 >= 0) {
                        this.modellst[index].citylst[i].checkselect = false;
                    }
                }
            }
        }
        if (!this.modellst[index].citylst[ind].checkselect) {
            this.modellst[index].citylst[ind].percentage2 = null;
        }

    }
    onSelectArecheselect(index, $event) {
        this.total = 0;
        this.remainvalue = 0;
        this.remainpercent = 0;
        if (!$event.target.checkselect) {
            this.modellst[index].arecheselect = false;
            this.modellst[index].percentage1 = 0;

            for (let i = 0; i < this.modellst[index].citylst.length; i++) {
                this.modellst[index].citylst[i].checkselect = false
                this.modellst[index].citylst[i].percentage2 = 0;
            }

        }
        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].arecheselect) {
                this.total = this.total + this.modellst[i].percentage1;
                this.remainvalue = this.model.percentage - this.total;
                if (this.remainvalue >= 0) {
                    this.remainpercent = this.remainvalue;
                }
            }
        }

    }
    onSelectCheckselect(index, $event, ind) {
        this.total1 = 0;
        this.remainvalue1 = 0;
        this.remainpercentage1 = 0;
        if (!$event.target.checkselect) {
            this.modellst[index].citylst[ind].checkselect = false
            this.modellst[index].citylst[ind].percentage2 = 0
        }

        for (let i = 0; i < this.modellst[index].citylst.length; i++) {
            if (this.modellst[index].citylst[i].checkselect) {
                this.total1 = this.total1 + this.modellst[index].citylst[i].percentage2
                this.remainvalue1 = this.modellst[index].percentage1 - this.total1
                if (this.remainvalue1 >= 0) {
                    this.modellst[index].remainpercentage1 = this.remainvalue1
                }
            }
        }
    }
    cancle() {
        this.router.navigate(["/CRMHome"])
    }
    btnsave() {
        if (this.modellst == null || this.modellst.length <= 0) {
            this.notifyService.showRroor("Please Add List ", "Oops!");
            return;
        }
        if (this.model.percentage == null || this.model.percentage == "") {
            this.notifyService.showRroor("Please Enter percentage", "Oops!");
            return;
        }
        var found = this.modellst.find(function (x) { return x.arecheselect === true; });
        if (!found) {
            this.notifyService.showRroor("Please select atleast one Checkbox", " Oops");
            return;
        }
        if (this.model.percentage > 100) {
            this.notifyService.showRroor("Percentage is Not greater than 100%", "Oops!");
            return;
        }

        for (let i = 0; i < this.modellst.length; i++) {
            if (this.modellst[i].arecheselect == true) {
                if (this.modellst[i].percentage1 == null || this.model.percentage1 == "") {
                    this.notifyService.showRroor("Please Enter % Allocate", "Oops!");
                    return;
                }
            }
        }

        this.model.modellst = this.modellst
        this.Marketingservice.savemrktingBudgetTemplate(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.ngOnInit()
                    } else {
                        this.notifyService.showRroor(data.message, "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });
    }
}