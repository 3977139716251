import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { AlertService, MasterServices, TransactionServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common'
import { User } from 'src/app/_models';
import { SearchEnquirynameModel } from './SearchEnquirynameModel';

@Component({
    selector: 'app-login',
    templateUrl: 'SearchEnquiryname.html'
})
export class SearchEnquiryname implements OnInit {

    model: any = {};
    sum: any;
    user = new User();
    sub: any;
    id: number;
    p: any;
    pagename: string;
    enqlist: SearchEnquirynameModel[] = [];

    constructor(private Masterservice: MasterServices, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router, private location: Location, private datePipe: DatePipe, private transactionService: TransactionServices, private notifyService: NotificationService) {
        this.pagename = this.router.url
    }

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "Select Document Name" },
        { id: 2, "name": "Enquiry" },
        { id: 3, "name": "Quotation" },
        { id: 4, "name": "Service" },
        { id: 5, "name": "Order Acceptance" },
        { id: 6, "name": "Invoice" },
        { id: 7, "name": "Payment" },
        { id: 8, "name": "Service Quotation" },
        { id: 9, "name": "Dealer Purchase Order" },
        { id: 10, "name": "Purchase Order" },
        { id: 11, "name": "Good Receipt Note" },
        { id: 12, "name": "Goods Inward Note" },
        { id: 13, "name": "Stock Adjustment" },
        { id: 14, "name": "Stock" },
        { id: 15, "name": "Requisition" },
        { id: 16, "name": "Plan" },
        { id: 17, "name": "Payment Transaction" },
        { id: 18, "name": "Installation" },
        { id: 19, "name": "Material Issue" },
        { id: 20, "name": "Sales Return" },

    ];

    ngOnInit(): void {
        this.model.checkselectbran = false;
        this.model.checkselecfina = false;
        this.model.chekselectother = false;
        this.model.chkselectPro = false
        this.getAllDocumentNum();
        this.model.userid = Number(localStorage.getItem("userid"));
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }

    getAllDocumentNum() {
        this.enqlist = [];
        this.Masterservice.getAllDocumentNum(this.model)
            .subscribe(value => {
                this.enqlist = value;


            })
    }
    onScroll() {
        this.sum = this.sum + 1;
        this.user.pageno = this.sum + 1;
        this.user.pageSize = 5;
        this.getAllDocumentNum();
    }

    onSelectDocument(value) {
        this.model.document = value;
    }
    onChangeSequence($event) {
        if (this.model.bransequence == this.model.prodsequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.bransequence == this.model.othersequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.bransequence == this.model.financsequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }

    }
    onChangeProSequence($event) {
        if (this.model.prodsequence == this.model.bransequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.prodsequence == this.model.othersequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.prodsequence == this.model.financsequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
    }
    onChangefinSequence($event) {
        if (this.model.financsequence == this.model.bransequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.financsequence == this.model.othersequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.financsequence == this.model.prodsequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }

    }
    onChangeOtherSequence($event) {
        if (this.model.othersequence == this.model.bransequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.othersequence == this.model.financsequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
        if (this.model.othersequence == this.model.prodsequence) {
            this.notifyService.showRroor("Sequence Cannot be Same !!", "Oops!");
            return;
        }
    }
    onSelectcheckselectbran($event) {
        if (!$event.target.checkselect) {
            this.model.checkselectbran = false;
            this.model.bransequence = null;
        }
    }
    onSelectchkselectPro($event) {
        if (!$event.target.checkselect) {
            this.model.chkselectPro = false;
            this.model.prodsequence = null;
        }
    }
    onSelectcheckselecfina($event) {
        if (!$event.target.checkselect) {
            this.model.chekselectother = false;
            this.model.financsequence = null;
        }
    }
    onSelectchekselectother($event) {
        if (!$event.target.checkselect) {
            this.model.checkselecfina = false;
            this.model.othersequence = null;
        }
    }


    btndelts(id) {
        this.model.id = id
        this.Masterservice.deleteDocumentnum(this.model)
            .subscribe(value => {
                this.notifyService.showSuccess(value.message, " Success");
                this.ngOnInit()
            });
    }
    btnedit(lst) {

        this.model.id = lst.id;
        this.model.document = lst.document;
        this.model.prefixCode = lst.prefixCode;

        if (lst.prefix == "Product") {
            this.model.chkselectPro = true
            this.model.prodsequence = lst.sequence;

            this.model.checkselectbran = false
            this.model.bransequence = null;

            this.model.checkselecfina = false
            this.model.financsequence = null;

            this.model.chekselectother = false
            this.model.othersequence = null;

        }
        if (lst.prefix == "Branch") {
            this.model.checkselectbran = true
            this.model.bransequence = lst.sequence;

            this.model.chkselectPro = false
            this.model.prodsequence = null;

            this.model.checkselecfina = false
            this.model.financsequence = null;

            this.model.chekselectother = false
            this.model.othersequence = null;


        }
        if (lst.prefix == "Financial Year") {
            this.model.checkselecfina = true
            this.model.financsequence = lst.sequence;

            this.model.chkselectPro = false
            this.model.prodsequence = null;

            this.model.checkselectbran = false
            this.model.bransequence = null;

            this.model.chekselectother = false
            this.model.othersequence = null;

        }
        if (lst.prefix == "Other") {
            this.model.chekselectother = true
            this.model.othersequence = lst.sequence;

            this.model.chkselectPro = false
            this.model.prodsequence = null;

            this.model.checkselectbran = false
            this.model.bransequence = null;

            this.model.checkselecfina = false
            this.model.financsequence = null;


        }
        this.model.bsrno = lst.bsrno;
        this.model.enquiryFlag = lst.enquiryFlag;
        this.model.serialNo = lst.serialNo


    }
    btnsave($event) {

        let flag = false;
        if (this.model.document == null) {
            this.notifyService.showRroor("Select Document Name  !!", "Oops!");
            return;
        }
        if (this.model.prefixCode == null) {
            this.notifyService.showRroor("Enter Prefix Code  !!", "Oops!");
            return;
        }
        if (this.model.bsrno == null) {
            this.notifyService.showRroor("Enter Serial No.  !!", "Oops!");
            return;
        }
        if (this.model.checkselectbran == false &&
            this.model.checkselecfina == false &&
            this.model.chekselectother == false &&
            this.model.chkselectPro == false) {
            flag = true;
        }
        if (flag) {
            this.notifyService.showRroor("Select At least one Number Type  !!", "Oops!");
            return;
        }

        if (this.model.checkselectbran && this.model.bransequence == null) {
            this.notifyService.showRroor("Ener Branch Sequence !!", "Oops!");
            return;
        }
        if (this.model.chkselectPro && this.model.prodsequence == null) {
            this.notifyService.showRroor("Ener Product Sequence !!", "Oops!");
            return;
        }
        if (this.model.checkselecfina && this.model.financsequence == null) {
            this.notifyService.showRroor("Ener Finalyear Sequence !!", "Oops!");
            return;
        }
        if (this.model.chekselectother && this.model.othersequence == null) {
            this.notifyService.showRroor("Ener Other Sequence !!", "Oops!");
            return;
        }

        if (this.model.checkselectbran) {

            if (this.model.bransequence == this.model.prodsequence || this.model.bransequence == this.model.financsequence || this.model.bransequence == this.model.othersequence) {
                this.notifyService.showRroor("Please Enter valid sequence Number !!", "Oops!");
                return;
            }
        }

        if (this.model.chkselectPro) {
            if (this.model.bransequence == this.model.prodsequence || this.model.prodsequence == this.model.financsequence || this.model.prodsequence == this.model.othersequence) {
                this.notifyService.showRroor("Please Enter valid sequence Number !!", "Oops!");
                return;
            }
        }

        if (this.model.checkselecfina) {
            if (this.model.financsequence == this.model.prodsequence || this.model.financsequence == this.model.bransequence || this.model.financsequence == this.model.othersequence) {
                this.notifyService.showRroor("Please Enter valid sequence Number !!", "Oops!");
                return;
            }
        }

        if (this.model.chekselectother) {
            if (this.model.othersequence == this.model.prodsequence || this.model.othersequence == this.model.financsequence || this.model.bransequence == this.model.othersequence) {
                this.notifyService.showRroor("Please Enter valid sequence Number !!", "Oops!");
                return;
            }
        }

        this.Masterservice.savedocument(this.model)
            .subscribe(
                data => {
                    if (data.status == "OK") {
                        this.notifyService.showSuccess("Data Saved Successfully ! ", "Success !")
                        this.model.checkselecfina = false;
                        this.model.chkselectPro = false;
                        this.model.checkselectbran = false;
                        this.model.chekselectother = false;
                        this.model.bsrno = null;
                        this.model.id = null
                        this.model.document = null;
                        this.model.prefix = null;
                        this.model.prefixCode = null;
                        this.model.sequence = null;
                        this.model.otherdetails = null;
                        this.model.serialNo = null;
                        this.model.othersequence = null;
                        this.model.financsequence = null;
                        this.model.prodsequence = null;
                        this.model.enquiryFlag = null;
                        this.model.userid = null;
                        this.model.branch = null;
                        this.model.financialyear = null;
                        this.model.product = null;
                        this.model.bransequence = null;

                        this.ngOnInit();
                    } else {
                        this.notifyService.showRroor("Data NOt Saved! ", "Oops !")
                    }
                },
                error => {
                    alert("Something Wrong ")
                });

    }
    cancle() {
        this.router.navigate(["/CRMHome"]);
    }


}