import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { AlertService, DealersService, MasterServices, TransactionServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { Location, } from '@angular/common';
import { User } from "src/app/_models";
import { SearchPurchaseorderModel } from "./SearchPurchaseorderModel";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { DefinePuchaseOrderModel, productModels } from "./DefinePuchaseOrderModel";
import * as moment from 'moment';


var converter = require('number-to-words');
@Component({
    selector: 'app-order',
    templateUrl: 'SearchPurchaseorder.html'
})
export class SearchPurchaseorder implements OnInit {

    constructor(private Masterservice: MasterServices, private dealersService: DealersService, private modalService: BsModalService, private alertService: AlertService, private transactionService: TransactionServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location,) {
    }
    p: any;
    user = new User();
    model = new SearchPurchaseorderModel();
    id: number;
    model1: DefinePuchaseOrderModel;
    modalRef: BsModalRef;
    modellst: SearchPurchaseorderModel[] = [];
    dealermodelst: SearchPurchaseorderModel[] = [];
    dealermodelst1: SearchPurchaseorderModel[] = [];
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Status" },
        { id: 3, "name": "PO Number" },
        { id: 4, "name": "Date" },

    ];
    ngOnInit(): void {
        this.model.userid = Number(localStorage.getItem('userid'));
        this.getAllpurchesorder();
    }

    getAllpurchesorder() {

        this.dealersService.getAllpurchesorder(this.model)
            .subscribe(value => {
                this.dealermodelst = value
                for (let i = 0; i < this.dealermodelst.length; i++) {
                    if (this.dealermodelst[i].approvedorderflg == "P") {
                        this.dealermodelst[i].approvedorderflg = "Pending";
                    } else if (this.dealermodelst[i].approvedorderflg == "A") {
                        this.dealermodelst[i].approvedorderflg = "Approved";
                    }
                    else if (this.dealermodelst[i].approvedorderflg == "C") {
                        this.dealermodelst[i].approvedorderflg = "Closed";
                    }
                }
                this.dealermodelst1 = [];
                value.forEach((item) => this.dealermodelst1.push({
                    "id": item.id, "dateTime": item.dateTime, "approvedorderflg": item.approvedorderflg, "srno": item.srno,
                    "txtselect": item.txtselect, "txtserch": item.txtserch, "userid": item.userid, "dealerPurchesNO": item.dealerPurchesNO, "value": item.value, "iseditflag": item.iseditflag, "approvedflag": item.approvedflag
                }));
                this.dealermodelst = this.dealermodelst1;
                this.modellst = value;
            })
    }

    btnedit(id) {
        this.router.navigate(['/editDefinePuchaseOrder', id]);
    }
    btnnew() {
        this.router.navigate(['/newDefinePuchaseOrder']);
    }
    onSelectuser(value) {
        this.model.txtselect = value;
    }
    btnsearch($event) {
        if (this.model.txtselect == "All") {
            this.getAllpurchesorder()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Status") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.dealermodelst1 = [];
                this.dealermodelst.forEach((item) => {
                    if ((item.approvedorderflg.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.dealermodelst1.push({
                            "id": item.id, "dateTime": item.dateTime, "approvedorderflg": item.approvedorderflg, "srno": item.srno,
                            "txtselect": item.txtselect, "txtserch": item.txtserch, "userid": item.userid, "dealerPurchesNO": item.dealerPurchesNO, "value": item.value, "iseditflag": item.iseditflag, "approvedflag": item.approvedflag
                        });
                    }
                });
                this.dealermodelst = this.dealermodelst1;
            }
        }
        else if (this.model.txtselect == "PO Number") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.dealermodelst1 = [];
                this.dealermodelst.forEach((item) => {
                    if ((item.dealerPurchesNO.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.dealermodelst1.push({
                            "id": item.id, "dateTime": item.dateTime, "approvedorderflg": item.approvedorderflg, "srno": item.srno,
                            "txtselect": item.txtselect, "txtserch": item.txtserch, "userid": item.userid, "dealerPurchesNO": item.dealerPurchesNO, "value": item.value, "iseditflag": item.iseditflag, "approvedflag": item.approvedflag
                        });
                    }
                });
                this.dealermodelst = this.dealermodelst1;
            }
        }
        else if (this.model.txtselect == "Date") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.dealermodelst1 = [];
                this.dealermodelst.forEach((item) => {
                    let todate = moment(item.dateTime).format('DD-MM-YYYY');
                    if ((todate.toString()).match(this.model.txtserch)) {
                        this.dealermodelst1.push({
                            "id": item.id, "dateTime": item.dateTime, "approvedorderflg": item.approvedorderflg, "srno": item.srno,
                            "txtselect": item.txtselect, "txtserch": item.txtserch, "userid": item.userid, "dealerPurchesNO": item.dealerPurchesNO, "value": item.value, "iseditflag": item.iseditflag, "approvedflag": item.approvedflag
                        });
                    }
                });
                this.dealermodelst = this.dealermodelst1;
            }
        }

    }
    view(History: TemplateRef<any>, id) {
        this.model.id = id;

        this.dealersService.vieworders(this.model)
            .subscribe(value => {
                this.modellst = value

            })
        this.modalRef = this.modalService.show(History, {
            class: 'modal-lg'
        })
    }
    btnclose(id) {
        this.model.id = id;
        this.dealersService.closeorders(this.model)
            .subscribe(value => {
                this.dealermodelst = value

            })
        this.router.navigate(['/editDefinePuchaseOrder', id]);
    }
}