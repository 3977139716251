<app-header></app-header>
<div class="container" style="width:70%">
    <div class="row">
        <div class="col l12 m12 s12">

            <div class="col l2 m2 s2"></div>
            <div class="heading col l8 m8 s8">
                <span style="font-size: 25px;">{{pagename}}</span>
            </div>
            <div class="col l2 m2 s2"></div>
        </div>
        <div class="col l12 m12 s12 registerBgShadow">
            <div class="row">

                <div class="col s1 l1 m1">
                </div>
                <div class=" input-field col s5 " style=" margin-top: 30px;">
                    <input type="text" class="autocomplete" id="dynamicUserIdsSecondWay1" placeholder="Customer Name"
                        (keyup)="getUserIdsSecondtWay1($event)" />
                    <input type="hidden" id="acc-id" />
                </div>
                <div class=" input-field col s5" style=" margin-top: 30px;">
                    <label id="lable1" for="textarea1">Address</label>
                    <span>{{model.address}}</span>
                </div>
                <div class="col s1 l1 m1">
                </div>
            </div>


            <div class="row">
                <!-- <div class="col l12 m12 s12"> -->
                    <div class="col s1">
                    </div>
                    <div class="input-field col s5" style="margin-top: 0px;margin-bottom: 0px;">
                        <span>Machine Status</span>
                        <select (change)="onSelectmachinestatus($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Machine Status">
                            <option Selected value="0">Select Machine Status</option>
                            <option *ngFor="let lst of machiestatuslst" [value]="lst.id"
                                [selected]="lst.name==model.serviceCallStatusName">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="input-field col s5 " style="margin-top: 0px;margin-bottom: 0px;">
                        <span>Locale</span>
                        <select (change)="onSelectlocale($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Locale">
                            <option Selected value="0">Select Locale</option>
                            <option *ngFor="let lst of localelist" [value]="lst.id"
                                [selected]="lst.name==model.localeName">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col s1">
                    </div>
                <!-- </div> -->
            </div>

            <div class="row">
                <!-- <div class="col l12 m12 s12"> -->
                    <div class="col s1">
                    </div>
                    <div class="input-field col s5" style="margin-top: 0px;margin-bottom: 0px;">
                        <span>Product Model</span>
                        <select (change)="onSelectproduct($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Product Model">
                            <option Selected value="0">Select Product</option>
                            <option *ngFor="let lst of productlist" [value]="lst.id"
                                [selected]="lst.name==model.productCode">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class=" input-field col s5" style="margin-top: 0px;margin-bottom: 0px;">
                        <label id="lable2" for="textarea1">Product Category</label>
                        <span>{{model.categoryname}}</span>
                    </div>
                    <div class="col s1">
                    </div>
                <!-- </div> -->
            </div>


            <div class="row">
                <!-- <div class="col l12 m12 s12"> -->
                    <div class="col s1">
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.orderNo" #orderNo="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first3" data-toggle="tooltip" data-placement="top"
                            title="Order No" ngModel maxlength="35" required>
                        <label id="lable3" for="first3">Order No</label>
                        <div *ngIf="orderNo.invalid && (orderNo.dirty || orderNo.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="orderNo.errors.required">
                                Order No Required
                            </div>
                            <div [hidden]="!orderNo.errors?.pattern">
                                Invalid Order No
                            </div>
                        </div>
                    </div>

                    <div class="input-field col s4" style="margin-right :10px; margin-top: 0px;">
                        <input type="text" placeholder="Order Date" id="start" name="orderdate"
                            [(ngModel)]="model.orderdate" class="form-control"
                            (ngModelChange)="onChangeorderdate(model.orderdate)" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                    </div>

                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.mcRefNo" #mcRefNo="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first4" data-toggle="tooltip" data-placement="top"
                            title="Machine Ref. No" ngModel maxlength="35" required>
                        <label id="lable4" for="first4">Machine Ref. No</label>
                        <div *ngIf="mcRefNo.invalid && (mcRefNo.dirty || mcRefNo.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="mcRefNo.errors.required">
                                Machine Ref. No Required
                            </div>
                            <div [hidden]="!mcRefNo.errors?.pattern">
                                Invalid Machine Ref. No
                            </div>
                        </div>
                    </div>
                    <div class="col s1">
                    </div>
                <!-- </div> -->
            </div>

            <div class="row">
                <!-- <div class="col l12 m12 s12"> -->
                    <div class="col s1">
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.serialNo" #serialNo="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first5" data-toggle="tooltip" data-placement="top"
                            title="Serial No" ngModel maxlength="35" required>
                        <label id="lable5" for="first5">Serial No</label>
                        <div *ngIf="serialNo.invalid && (serialNo.dirty || serialNo.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="serialNo.errors.required">
                                Serial No Required
                            </div>
                            <div [hidden]="!serialNo.errors?.pattern">
                                Invalid Serial No
                            </div>
                        </div>
                    </div>

                    <div class="input-field col s4" style="margin-right :10px; margin-top: 0px;">
                        <input type="text" placeholder="Installation Date" id="start" name="installationdate"
                            [(ngModel)]="model.installationdate" class="form-control"
                            (ngModelChange)="onChangeinstallationdate(model.installationdate)" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                    </div>

                    <div class=" input-field col s3" style=" margin-top: 0px;margin-bottom: 0px;">
                        <input [(ngModel)]="model.warrantyMonths" #warrantyMonths="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first6" data-toggle="tooltip" data-placement="top"
                            title="Warranty" ngModel maxlength="35" required style="margin-bottom: 0px;">  
                        <label id="lable6" for="first6">Warranty</label>(In Months)
                        <div *ngIf="warrantyMonths.invalid && (warrantyMonths.dirty || warrantyMonths.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="warrantyMonths.errors.required">
                                Warranty Required
                            </div>
                            <div [hidden]="!warrantyMonths.errors?.pattern">
                                Invalid Warranty
                            </div>
                        </div>
                    </div>
                    <div class="col s1">
                    </div>
                <!-- </div> -->
            </div>

            <div class="row">
                <!-- <div class="col l12 m12 s12"> -->
                    <div class="col s1">
                    </div>
                    <div class="input-field col s3" style="margin-right :10px; margin-top: 17px;">
                        <input type="text" placeholder="Warranty Start Date" id="start" name="warrentystartdate"
                            [(ngModel)]="model.warrentystartdate" class="form-control"
                            (ngModelChange)="onChangewarrentystartdate(model.warrentystartdate)" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                    </div>

                    <div class="input-field col s4" style="margin-right :10px; margin-top: 17px;">
                        <input type="text" placeholder="Warranty End Date" id="start" name="warrentyenddate"
                            [(ngModel)]="model.warrentyenddate" class="form-control"
                            (ngModelChange)="onChangewarrentyenddate(model.warrentyenddate)" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue' }">
                    </div>

                    <div class="input-field col s3 " style="padding-right: 18px;margin-top: 0px;">
                        <span>Amc Type</span>
                        <select (change)="onSelectamctype($event.target.value)" data-toggle="tooltip"
                            data-placement="top" title="Locale">
                            <option Selected value="0">Select Amc Type</option>
                            <option *ngFor="let lst of amctypelist" [value]="lst.id"
                                [selected]="lst.name==model.amcname">
                                {{lst.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col s1">
                    </div>
                <!-- </div> -->
            </div>

            <div class="row">
                <!-- <div class="col l12 m12 s12"> -->
                    <div class="col s1">
                    </div>
                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.amcMcAmount" #amcMcAmount="ngModel" type="number" min="0"
                            oninput="validity.valid||(value='');" id="first7" data-toggle="tooltip" data-placement="top"
                            title="Amc Amount" ngModel maxlength="35" required>
                        <label id="lable7" for="first7">Amc Amount</label>
                        <div *ngIf="amcMcAmount.invalid && (amcMcAmount.dirty || amcMcAmount.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="amcMcAmount.errors.required">
                                Amc Amount Required
                            </div>
                            <div [hidden]="!amcMcAmount.errors?.pattern">
                                Invalid Amc Amount
                            </div>
                        </div>
                    </div>

                    <div class=" input-field col s3" style=" margin-top: 0px;">
                        <input [(ngModel)]="model.installationHourCount" #installationHourCount="ngModel" type="number"
                            min="0" oninput="validity.valid||(value='');" id="first8" data-toggle="tooltip"
                            data-placement="top" title="Hour Count" ngModel maxlength="35" required>
                        <label id="lable8" for="first8">Hour Count</label>
                        <div *ngIf="installationHourCount.invalid && (installationHourCount.dirty || installationHourCount.touched) "
                            class="alert alert-danger" style="color: red">
                            <div *ngIf="installationHourCount.errors.required">
                                Hour Count Required
                            </div>
                            <div [hidden]="!installationHourCount.errors?.pattern">
                                Invalid Hour Count
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>


            <div class="row">
                <!-- <div class="col l12 m12 s12"> -->
                    <div class="col s1">
                    </div>
                    <div class=" input-field col s5" style=" margin-top: 0px;">
                        <textarea id="textarea1" title=" Address" class="materialize-textarea"
                            [(ngModel)]="model.siteAddr" id="first10" #siteAddr="ngModel"></textarea>
                        <label id="lable10" for="textarea1">Site Address</label>
                    </div>
                    <div class=" input-field col s5" style=" margin-top: 0px;padding-right: 0px;">
                        <input [(ngModel)]="model.remarks" #remarks="ngModel"
                            onkeypress="return /[A-Za-z_ ]/i.test(event.key)" type="text" id="first9"
                            data-toggle="tooltip" data-placement="top" title="Remark" ngModel maxlength="35" required>
                        <label id="lable9" for="first9">Remarks</label>
                        <div *ngIf="remarks.invalid && (remarks.dirty || remarks.touched) " class="alert alert-danger"
                            style="color: red">
                            <div *ngIf="remarks.errors.required">
                                Remarks Required
                            </div>
                            <div [hidden]="!remarks.errors?.pattern">
                                Invalid Remark
                            </div>
                        </div>
                    </div>
                    <div class="col s1">
                    </div>
                <!-- </div> -->
            </div>



            <div class="row">
                <div class="input-field col l11 m11 s11" style="text-align: right;padding-right: 0px;">
                    <button type="button" class="waves-ripple cancelbutton btn" style="margin-right: 5px;"
                        (click)=" cancle()">Cancel</button>&nbsp;&nbsp;
                    <button type="button" (click)="btnsave($event)" class="waves-ripple waves-light btn"
                        style="margin-right: 5px;">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>