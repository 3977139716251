import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService } from "ngx-bootstrap";
import { ResourceCType } from "src/app/Master/ResourceRights/ProductResourceRightsModel";
import { AreaModel1 } from "src/app/Transactions/Teams/TeamModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { DCRreportModel } from "../DCR Report/DCRreportModel";
import { FiringformModel } from "../Firing Form/FiringformModel";
import { OpportunityReportModel } from "../Opportunity Report/OpportunityReportModel";
import { OrdInHandReportModel } from "../Order In Hand Report/OrdInHandReportModel";
import { PerformanceReprotofTechniciansModel } from "./PerformanceReprotofTechniciansModel";

@Component({
    selector: 'app-login',
    templateUrl: 'PerformanceReprotofTechnicians.html'
})
export class PerformanceReprotofTechnicians implements OnInit {
    modalRef: any;
    // model = new AreaWisePerformanceReportModel();
    // model = new TeamWisePerformanceReportModel();
    model = new PerformanceReprotofTechniciansModel();
    fmodel = new FiringformModel();
    dmodel = new DCRreportModel();
    omodel = new OrdInHandReportModel();
    // lpmodel = new LocalePincodeModel();
    user = new User();
    regionlst: DropDownModel[] = [];
    arealst: AreaModel1[] = [];
    resourcelst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    citylst: DropDownModel[] = [];
    localeslst: DropDownModel[] = [];
    regionName: string;
    areaName: string;
    cityName: string
    branchName: string;
    teamName: string;
    resourceName: string;
    companyname: String;
    companyaddress: String;
    id: number;
    username: string;
    timing = new Date();
    taemPerformancelst: PerformanceReprotofTechniciansModel[] = [];
    opmodel = new OpportunityReportModel();
    customertyps: ResourceCType[] = [];
    teamlst: DropDownModel[] = [];
    constructor(private Masterservice: MasterServices,
        private modalService: BsModalService,
        private reportServices: ReportServices,
        private notifyService: NotificationService,) {
    }
    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.user.userid = Number(localStorage.getItem("userid"));
        this.getRegions();
        // this.getcustomertyps();
        this.getteam();
    }
    selectFromDate(From) {
        if (From > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.frmdate = new Date()
            return;
        }
    }
    selectToDate(date) {
        if (date < this.model.frmdate) {
            this.notifyService.showRroor("To Date should be grether than From Date", "oops");
            this.model.todate = new Date()
            return;
        }
        if (date > new Date()) {
            this.notifyService.showRroor("Future date cannot be selected", "oops");
            this.model.todate = new Date()
            return;
        }
    }

    getRegions() {
        this.regionlst = []
        this.Masterservice.getAllRegions(this.user)
            .subscribe(value => {
                this.regionlst = value
            });
    }
    onSelectRegions(val) {
        this.arealst = [];
        if (val == 0) {
            this.notifyService.showRroor("Select Region!!", "Oops!");
            return;
        }
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.model.name = this.user.message;
                this.regionName = this.user.message;
            }

        } else {
            this.model.regionid = val
            for (let i = 0; i < this.regionlst.length; i++) {
                if (this.regionlst[i].id == val) {
                    this.regionName = this.regionlst[i].name
                }
            }
            this.user.trnid = val;
        }

        this.Masterservice.getAreaByRegion(this.user)
            .subscribe(value => {
                this.arealst = value
            });
    }
    onSelectArea(val) {
        this.dmodel.areaintlst = [];
        if (val == "All") {
            this.user.message = val;

            if (this.user.message == val) {
                this.areaName = this.user.message
            }

            for (let i = 0; i < this.arealst.length; i++) {
                this.dmodel.areaintlst.push(this.arealst[i].areaid)
            }
        } else {
            this.dmodel.areaid = val;
            for (let i = 0; i < this.arealst.length; i++) {
                if (this.arealst[i].areaid == val) {
                    this.areaName = this.arealst[i].areaname
                }
            }
            this.dmodel.areaintlst.push(this.dmodel.areaid)
        }

        this.citylst = [];
        this.reportServices.getCityByarea(this.dmodel)
            .subscribe(value => {
                this.citylst = value
            });
    }
    onSelectCity(val) {
        this.model.cityintlst = [];

        this.model.cityid = val;
        for (let i = 0; i < this.citylst.length; i++) {
            if (this.citylst[i].id == val) {
                this.cityName = this.citylst[i].name
            }
        }
        this.model.cityintlst.push(this.model.cityid)

        this.localeslst = [];
        this.reportServices.getLocaleByCity(this.model)
            .subscribe(value => {
                this.localeslst = value

            });

        for (let i = 0; i < this.localeslst.length; i++) {
            this.model.localeintlst.push(this.localeslst[i].id)
        }
        for (let i = 0; i < this.localeslst.length; i++) {
            this.model.localeint.push(this.localeslst[i].id)
        }
    }

    getteam() {
        this.dmodel.userid = this.user.userid
        this.reportServices.getTeamByUserId(this.dmodel)
            .subscribe(value => {
                this.teamlst = value
            });
    }

    onSelectTeam(val) {
        this.opmodel.teamintlst = []
        if (val == "All") {
            this.user.message = val;
            if (this.user.message == val) {
                this.teamName = this.user.message
            }
            for (let i = 0; i < this.teamlst.length; i++) {
                this.opmodel.teamintlst.push(this.teamlst[i].id)
            }
        }
        else {
            this.model.teamid = val;
            for (let i = 0; i < this.teamlst.length; i++) {
                if (this.teamlst[i].id == val) {
                    this.teamName = this.teamlst[i].name
                }
            }
            this.model.teamintlst.push(this.model.teamid)
        }
    }

    btnshow(TeamPerformanceReport: TemplateRef<any>) {
        if (this.model.frmdate == null) {
            this.notifyService.showRroor("Please select From Date !!", "Oops!");
            return;
        }
        if (this.model.todate == null) {
            this.notifyService.showRroor("Please select To Date  !!", "Oops!");
            return;
        }
        if (this.model.cityid == null) {
            this.notifyService.showRroor("Please select City  !!", "Oops!");
            return;
        }
        this.reportServices.showPerformanceReportOfServiceTeam(this.model)
            .subscribe(
                data => {
                    this.taemPerformancelst = data;
                    for (let i = 0; i < this.taemPerformancelst.length; i++) {
                        this.companyname = this.taemPerformancelst[0].companyname;
                        this.companyaddress = this.taemPerformancelst[0].companyaddress;
                    }
                    this.modalRef = this.modalService.show(TeamPerformanceReport, {
                        class: 'modal-lg'
                    });
                });
    }
    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
}