import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap";
import { InvoiceModel } from "src/app/Transactions/DispatchOrder/DispatchOrderHomeModel";
import { AreaModel1 } from "src/app/Transactions/Teams/TeamModel";
import { User } from "src/app/_models";
import { DropDownModel } from "src/app/_models/DropDownModel";
import { MasterServices, CompanyService, AlertService } from "src/app/_services";
import { NotificationService } from "src/app/_services/notification.service";
import { ReportServices } from "src/app/_services/ReportServices";
import { OpportunityReportModel } from "../Opportunity Report/OpportunityReportModel";
import { OrdInHandReportModel } from "../Order In Hand Report/OrdInHandReportModel";
import { DCRreportModel } from "./DCRreportModel";

@Component({
    selector: 'app-login',
    // moduleId: module.id,
    templateUrl: 'DCRreport.html'
})
export class DCRreport implements OnInit {
    modalRef: any;
    constructor(private Masterservice: MasterServices, private modalService: BsModalService, private reportServices: ReportServices, private companyservice: CompanyService, private notifyService: NotificationService, private alertService: AlertService, private userService: MasterServices, private route: ActivatedRoute,
        private router: Router) {

    }
    model = new DCRreportModel();
    omodel = new OrdInHandReportModel();
    opmodel = new OpportunityReportModel();
    user = new User();
    regionlst: DropDownModel[] = [];
    arealst: AreaModel1[] = [];
    resourcelst: DropDownModel[] = [];
    branchlst: DropDownModel[] = [];
    citylst: DropDownModel[] = [];
    teamlst: DropDownModel[] = [];
    companyname1: string;
    companyaddress: String;

    id: number;
    srlst: DCRreportModel[] = [];
    dsrlst: DCRreportModel[] = [];
    eqrlst: DCRreportModel[] = [];

    regionName: string;
    areaName: string;
    cityName: string
    branchName: string;
    resourceName: string;
    teamName: string;
    p: any;
    timing = new Date();
    username: string;
    pagename: string;
    routingname: string;
    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Open" },
        { id: 3, "name": "Close" },
    ];

    ngOnInit(): void {
        this.username = localStorage.getItem('fullname');
        this.user.userid = Number(localStorage.getItem("userid"));
        this.routingname = localStorage.getItem('commonrouting');
        this.regionName = localStorage.getItem('regionName');
        this.areaName = localStorage.getItem('areaName');
        this.branchName = localStorage.getItem('branchName');
        // var retrievedObject = localStorage.getItem('testObject');
        // console.log('retrievedObject: ', JSON.parse(retrievedObject));
        var getObj = JSON.parse(localStorage.getItem('testObject'));
        this.model = getObj;
        this.btnshow();

    }

    btnshow() {
        this.reportServices.showDcrReport(this.model)
            .subscribe(
                data => {
                    this.srlst = data;
                    for (let i = 0; i < this.srlst.length; i++) {
                        this.companyname1 = this.srlst[0].companyname
                        this.companyaddress = this.srlst[0].companyaddress;
                    }
                });
    }

    openPrint() {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        //WindowObject.close();
    }
    btnback() {
        this.router.navigate(["/DailyCallReport"]);
    }

}