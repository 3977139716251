import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterServices } from 'src/app/_services';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location, getNumberOfCurrencyDigits } from '@angular/common';
import { User } from 'src/app/_models';
import { DropDownModel } from 'src/app/_models/DropDownModel';
import { SearchProductModel } from './SearchProductModel';

@Component({
    selector: 'app-login',
    //  moduleId: module.id,
    templateUrl: 'SearchProduct.html'
})
export class SearchProduct implements OnInit {
    sum: number;

    constructor(private Masterservice: MasterServices, private route: ActivatedRoute, private notifyService: NotificationService, private router: Router, private location: Location, ) {
        this.pagename=this.router.url
    }
    p:any;
    user = new User();
    model: any = {};
    public i;
    prolst:SearchProductModel[] = [];
    prolst1:SearchProductModel[]=[];
    pagename: string;

    public searchtype: DropDownModel[] = [
        { id: 1, "name": "All" },
        { id: 2, "name": "Product Category" },
        { id: 3, "name": "Model" },

    ];
    ngOnInit(): void {
        this.getAllProduct();
        localStorage.setItem("pagename",this.pagename);  
        // this.pagename=localStorage.getItem("pagename");
        if (this.pagename != null) {
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('/', '');
            this.pagename = this.pagename.replace('%20', ' ');
            this.pagename = this.pagename.replace('%20', ' ');
        }
    }

    getAllProduct() {
        
        this.Masterservice.getAllProduct(this.model)
            .subscribe(value => {
                this.prolst = value
                this.prolst1 = [];
                value.forEach((item) => this.prolst1.push({ "id": item.id ,"name": item.name, "productCode": item.productCode, "hourFlag": item.hourFlag,"description":item.description,"txtserch": item.txtserch, "txtselect": item.txtselect ,"installationStatus":item.installationStatus ,"srno": item.srno}));
                this.prolst = this.prolst1;
               
            })
    }

    onScroll() {
        this.sum = this.sum + 1;
        this.user.pageno = this.sum + 1;
        this.user.pageSize = 5;
        this.getAllProduct();
    }

    btnnew() {
        this.router.navigate(['/DefineProduct']);
    }

    btnedit(id) {
        this.router.navigate(['/DefineProduct', id]);
     
    }

    btndelts(id) {
        this.i = confirm("Do you want to delete this record ?")
        if (this.i == true) {
            this.model.id = id
            this.Masterservice.deleteProduct(this.model)
                .subscribe(value => {
                    this.notifyService.showSuccess(value.message, " Success");
                    this.ngOnInit()
                });
        }
    }

    onSelectProduct(value) {
        this.model.txtselect = value;
    }


    btnsearch($event) {

        if (this.model.txtselect == "All") {
            this.getAllProduct()
            this.model.txtserch = null;
        }
        else if (this.model.txtselect == "Product Category") {

            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
                this.prolst1 = [];
                this.prolst.forEach((item) => {
                    if(item.name!=null){
                    if ((item.name.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                        this.prolst1.push({"id": item.id ,"name": item.name, "productCode": item.productCode,"description":item.description, "hourFlag": item.hourFlag, "txtserch": item.txtserch, "txtselect": item.txtselect,"installationStatus":item.installationStatus ,"srno": item.srno});
                    }
                }
                });
                this.prolst = this.prolst1;
               
            }
        }
        else if (this.model.txtselect == "Model") {
            if (this.model.txtserch == null || this.model.txtserch == "") {
                alert("Enter Criteria To Search")
                return;
            }
            else {
              
                this.prolst1 = [];
                this.prolst.forEach((item) => {
                    if(item.productCode!=null){
                        if ((item.productCode.toLowerCase()).match(this.model.txtserch.toLowerCase().trim())) {
                            this.prolst1.push({"id": item.id ,"name": item.name, "productCode": item.productCode, "hourFlag": item.hourFlag,"description":item.description,  "txtserch": item.txtserch, "txtselect": item.txtselect,"installationStatus":item.installationStatus ,"srno": item.srno});
                        }
                    }
                   
                });
                this.prolst = this.prolst1;
            }
        }
    }
    openPrint()
    {
        var divToPrint = document.getElementById('LibDocs');
        var WindowObject = window.open('', 'PrintWindow', 'width=1200,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        WindowObject.document.writeln(divToPrint.innerHTML);
        WindowObject.document.close();
        WindowObject.focus();
        WindowObject.print();
        WindowObject.close();
    }
}